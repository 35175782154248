import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../store/actions/authapi';
import { storeMx, page, page2, storeTab, storeCartCount } from '../../store/actions/sampleaction';
import PropTypes from 'prop-types';
import style from './Header.module.css';
import { Drawer } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import pnmLogo from '../../assets/header/Logo.png';
import iconmenu from '../../assets/header/iconmenu_.svg';
import accountLogo from '../../assets/header/accountLogo.svg';
import cartLogo from '../../assets/header/cartLogo.svg';
import getCartDataCount from '../../utils/CartDetails';
import { Searchbar } from '../search/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiMenu-paper': {
      borderRadius: '100px',
      backgroundColor: '#000000',
      boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75);',
      position: 'fixed'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export const Header = ({
  history,
  logoutUser,
  auth,
  match,
  storeMx,
  page,
  page2,
  storeTab,
  storeCartCount,
  sample,
  searchparams
}) => {
  const classes = useStyles();
  const url = match.path.replace('/', '');
  const [drawer, setDrawer] = useState(false);
  const [cartQty, setCartQty] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []);

  useEffect(() => {
    const getCartData = async () => {
      storeCartCount(await getCartDataCount(auth));
      setCartQty(sample.cartCount);
    };
    getCartData();
  }, []);

  useEffect(() => {
    setCartQty(sample.cartCount);
  }, [sample.cartCount]);

  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <div
          className={style.header}
          style={{
            backgroundColor: url == 'home' ? (isScrolled ? '#0B0E15' : 'transparent') : '#0B0E15',
            height: 'auto'
          }}>
          {/* Web screen header starts*/}
          <div className={style.headerContainerWeb}>
            <div onClick={() => history.push('/')}>
              <img src={pnmLogo} className={style.headerLogo} />
            </div>
            <Searchbar history={history} searchparams={searchparams} />
            <div>
              <span style={{ position: 'relative' }} onClick={() => history.push('/cart')}>
                <img src={cartLogo} className={style.cartLogo} />
                {cartQty > 0 && <span className={style.cartBadge}>{cartQty}</span>}
              </span>
              <img src={accountLogo} className={style.accountLogo} onClick={handleClickProfile} />
            </div>
          </div>
          <div
            className={style.bottomHeader}
            style={{
              backgroundColor: isScrolled && url == 'home' ? '#0B0E15' : 'transparent',
              display: isScrolled ? 'none' : 'flex'
            }}>
            <div className="d-flex">
              <div
                className={style.headerNavigation}
                onClick={() => history.push('/curated-designs')}>
                Curated Design
              </div>
              <div
                className={style.headerNavigation}
                style={{ borderLeft: '1px solid #DFD9D6', borderRight: '1px solid #DFD9D6' }}
                onClick={() => history.push('/shop')}>
                Shop Now
              </div>
              {auth.isAuthenticated ? (
                <div className={style.headerNavigation} onClick={() => history.push('/project')}>
                  My Projects
                </div>
              ) : (
                <div
                  className={style.headerNavigation}
                  onClick={() => history.push('/hire-designer')}>
                  Hire a Designer
                </div>
              )}
            </div>
          </div>
          {/* Web screen header ends*/}
          {/* Mobile screen header starts*/}
          <div className={style.headerContainerMob}>
            <div className={style.mobHeader}>
              <div>
                <img src={iconmenu} className={style.cartLogo} onClick={() => setDrawer(true)} />
              </div>
              <div onClick={() => history.push('/')}>
                <img src={pnmLogo} className={style.headerLogo} />
              </div>
              <div>
                <span style={{ position: 'relative' }} onClick={() => history.push('/cart')}>
                  <img src={cartLogo} className={style.cartLogo} />
                  {cartQty > 0 && <span className={style.cartBadge}>{cartQty}</span>}
                </span>
              </div>
            </div>
            <div>
              <Searchbar history={history} searchparams={searchparams}/>
            </div>
            <div
              className={style.bottomHeaderMob}
              style={{
                backgroundColor: isScrolled && url == 'home' ? '#0B0E15' : 'transparent',
                display: isScrolled ? 'none' : 'flex'
              }}>
              <div className="d-flex justify-content-center">
                <div
                  className={style.headerNavigation}
                  onClick={() => history.push('/curated-designs')}>
                  Curated Design
                </div>
                <div
                  className={style.headerNavigation}
                  style={{ borderLeft: '1px solid #DFD9D6', borderRight: '1px solid #DFD9D6' }}
                  onClick={() => history.push('/shop')}>
                  Shop Now
                </div>
                {auth.isAuthenticated ? (
                  <div className={style.headerNavigation} onClick={() => history.push('/project')}>
                    My Projects
                  </div>
                ) : (
                  <div
                    className={style.headerNavigation}
                    onClick={() => history.push('/hire-designer')}>
                    Hire a Designer
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Mobile screen header ends*/}
        </div>

        {/* <div
        className={style.header}
        style={{ backgroundColor: isScrolled ? '#0B0E15' : 'transparent' }}>
        <div className="d-flex ">
          <div className={style.headerNavigation} onClick={() => history.push('/explore')}>
            Curated Design
          </div>
          <div
            className={style.headerNavigation}
            style={{ borderLeft: '1px solid #DFD9D6', borderRight: '1px solid #DFD9D6' }}
            onClick={() => history.push('/shop')}>
            Shop Now
          </div>
          {auth.isAuthenticated ? (
            <div className={style.headerNavigation} onClick={() => history.push('/project')}>
              My Projects
            </div>
          ) : (
            <div className={style.headerNavigation} onClick={() => history.push('/how-it-works')}>
              Hire a Designer
            </div>
            )}
          </div>
        </div>
      </div> */}
        {/* Modal for profile icon in Web Screen*/}
        <div>
          {auth.isAuthenticated ? (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseProfile}
              className={classes.root}
              disableScrollLock={true}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  marginTop: '9vh',
                  width: '150px',
                  marginLeft: '-50px',
                  position: 'fixed'
                }
              }}>
              {/* <MenuItem onClick={() => history.push('/boards')}> &nbsp; &nbsp;My Favourites</MenuItem> */}

              <MenuItem onClick={() => history.push('/orders')}>
                {' '}
                &nbsp; &nbsp;My Orders &nbsp; &nbsp;
              </MenuItem>
              <MenuItem onClick={() => history.push('/account')}>
                {' '}
                &nbsp; &nbsp;Account Details
              </MenuItem>
              <MenuItem onClick={() => history.push('/questionnaire/space')}>
                {' '}
                &nbsp; &nbsp;Find my Style
              </MenuItem>
              <MenuItem
                onClick={() => {
                  storeMx({});
                  page(0);
                  page2(0);
                  storeTab('');
                  logoutUser(history);
                }}>
                {' '}
                &nbsp; &nbsp;Logout
              </MenuItem>
            </Menu>
          ) : (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseProfile}
              disableScrollLock={true}
              className={classes.root}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  marginTop: '9vh',
                  width: '150px',
                  marginLeft: '-50px',
                  position: 'fixed'
                }
              }}>
              <MenuItem onClick={() => history.push('/signup')}>
                {' '}
                &nbsp; &nbsp;Login/Signup
              </MenuItem>
              <MenuItem onClick={() => history.push('/query')}>&nbsp; &nbsp;Help</MenuItem>
            </Menu>
          )}
        </div>
        {/* Modal for profile icon in Web Screen ends*/}
        {/* Drawer for mobile screen*/}
        <div>
          {auth.isAuthenticated ? (
            <Drawer anchor={'left'} open={drawer}>
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    position: 'absolute',
                    right: '1%',
                    height: '35px',
                    width: '35px',
                    textAlign:'left',
                    opacity: 0.5
                  }}
                  onClick={() => setDrawer(false)}
                  src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
                  alt=""
                />
                <div
                  className="mt-5"
                  style={{ width: '250px', textAlign: 'center', fontWeight: 700 }}>
                  <p onClick={() => history.push('/shop')}>Shop</p>
                  <p onClick={() => history.push('/curated-designs')}>Curated Designs</p>
                  <p onClick={() => history.push('/project')}>My Projects</p>
                  <p onClick={() => history.push('/boards')}>My Boards</p>
                  <p onClick={() => history.push('/cart')}>Cart</p>
                  {/* <p onClick={() => history.push('/hire-designer')}>Hire a Designer </p> */}
                  <p onClick={() => history.push('/account')}>Account Details</p>
                  <p onClick={() => history.push('/orders')}>My Orders</p>
                  <p
                    onClick={() => {
                      storeMx({});
                      page(0);
                      page2(0);
                      storeTab('');
                      logoutUser(history);
                    }}>
                    Logout
                  </p>
                </div>
              </div>
            </Drawer>
          ) : (
            <Drawer anchor={'left'} open={drawer}>
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    position: 'absolute',
                    top: '5%',
                    right: '5%',
                    height: '35px',
                    width: '35px',
                    opacity: 0.5
                  }}
                  onClick={() => setDrawer(false)}
                  src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
                  alt=""
                />
                <div
                  className="mt-5"
                  style={{
                    width: '200px',
                    textAlign: 'left',
                    fontWeight: 700,
                    paddingLeft: '30px',
                    paddingTop: '50px'
                  }}>
                  {/* <p onClick={() => history.push('/pricing')}>Pricing</p> */}
                  <p onClick={() => history.push('/shop')}>Shop</p>
                  <p onClick={() => history.push('/curated-designs')}>Curated Design</p>
                  <p onClick={() => history.push('/hire-designer')}>Hire a Designer </p>
                  <p onClick={() => history.push('/cart')}>Cart</p>
                  <p
                    onClick={() => history.push('/login')}
                    style={{ fontSize: '14px', opacity: 0.5 }}>
                    Log in
                  </p>
                  <p
                    onClick={() => history.push('/signup')}
                    style={{ fontSize: '14px', opacity: 0.5 }}>
                    Sign up
                  </p>
                  <p
                    style={{ fontSize: '14px', opacity: 0.5 }}
                    onClick={() => history.push('/query')}>
                    Help
                  </p>
                </div>
              </div>
              <div className="align-items-center justify-content-center d-flex">
                <button
                  className="px-4 mt-1"
                  style={{
                    marginRight: '15px',
                    marginBottom: '15px',
                    paddingLeft: '0px',
                    height: '45px',
                    borderRadius: 30,
                    backgroundColor: '#000000',
                    color: '#ffffff',
                    border: 'none',
                    fontSize: '3vw'
                  }}
                  onClick={() =>
                    history.push({
                      pathname: '/signup',
                      state: { redirect: '/questionnaire/space' }
                    })
                  }>
                  Get started
                </button>
              </div>
            </Drawer>
          )}
        </div>
        {/* Drawer for mobile screen ends*/}
      </div>
    </>
  );
};

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  sample: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sample: state.sample
});

export default connect(mapStateToProps, {
  logoutUser,
  storeMx,
  page,
  page2,
  storeTab,
  storeCartCount
})(withRouter(Header));
