import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)({
  color: '#E29525',
  height: 4,
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#cac7c5'
  },
  '& .MuiSlider-thumb': {
    height: 13,
    width: 13,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1,
    fontSize: 8,
    background: 'unset',
    padding: 0,
    width: 18,
    height: 18,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#E29525',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
});

export default function RangeSlider({ value, handleChange, disabled, maxval }) {
  return (
    <>
      {maxval ? (
        <PrettoSlider
          min={0}
          max={maxval}
          //valueLabelDisplay="auto"
          aria-label="pretto slider"
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
      ) : (
        <PrettoSlider
          min={0}
          max={150000}
          //valueLabelDisplay="auto"
          aria-label="pretto slider"
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </>
  );
}
