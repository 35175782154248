import React, { useState, useEffect, useLayoutEffect } from 'react';
import style from './Partner.module.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import 'react-multi-carousel/lib/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import VendorList from './VendorList';
import axios from 'axios';
import ConsoleHelper from '../../utils/ConsoleHelper';

const BecomeAPartner = () => {
  const dimension = useMediaQuery();

  const [vendorsList, setVendorsList] = useState([]);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendor/list`);
        if (res.data.length !== 0) {
          setVendorsList([...res.data]);
        }
      } catch (err) {
        ConsoleHelper('errorrrr', err);
      }
    };
    fetchVendorData();
  }, []);

  return (
    <>
      <Header />
      <div className={style.wholeContainer}>
        <div className={style.bannerBg}>
          <h2 className={style.headerTitle}>
            {' '}
            A new space for <br /> customers to experience <br />
            your brand
          </h2>

          <button className={style.buttonBlack}>Learn More</button>
        </div>
        <div className={style.bgImages}>
          <img
            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/BannerImg.png"
            className={style.imgBan}
          />
          <p>
            Pixel & Mortar gives customers the next generation buying experience by letting them see
            3D models of your furniture, in their own space. We provide personalised design
            solutions, from understanding customer’s taste to designing their space with furniture
            from your brands, the entire process is completed online.
          </p>
          <img
            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Path2029.png"
            className={style.pathLeft}
          />
          <img
            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Path2030.png"
            className={style.pathRight}
          />
        </div>

        <div className={style.innerContainer}>
          <div className={style.greySection}>
            <div className="row">
              <div className="col-12 col-md-6" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <div className={style.firstLeft}>
                  <h2>Online Store</h2>
                  <p>
                    Get a personlized, online store for your brand where customers can view or buy
                    your products.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Group+3246.png"
                  className={style.secImg}
                />
              </div>
            </div>
          </div>

          <div className={style.greySection}>
            <div className="row">
              <div className="col-12 col-md-6" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <div className={style.firstLeft}>
                  <h2>Designer Recommendations</h2>
                  <p>
                    Customers will be introduced to your brand through mood boards that our team of
                    designers create using your products.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Screenshot+2021-11-07+at+8.29.53+PM.png"
                  className={style.secImg}
                />
              </div>
            </div>
          </div>

          <div className={style.greySection}>
            <div className="row">
              <div className="col-12 col-md-6" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <div className={style.firstLeft}>
                  <h2>
                    High Quality <br />
                    3D Models
                  </h2>
                  <p>
                    Our designers will created 3D models of all your products so customers can see
                    them in their own spaces, helping them make better decision while buying.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Screenshot+2021-11-07+at+8.29.43+PM.png"
                  className={style.secImg}
                />
              </div>
            </div>
          </div>

          <div className={style.greySection}>
            <div className="row">
              <div className="col-12 col-md-6" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <div className={style.firstLeft}>
                  <h2>
                    Product
                    <br /> insights
                  </h2>
                  <p>
                    Gain an understanding of your most popular products and see which ones were
                    viewed, liked and bought.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Group+3262.png"
                  className={style.secImg}
                />
              </div>
            </div>
          </div>

          <div className={style.greySection}>
            <div className="row">
              <div className="col-12" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <h2 className={style.showcase}>
                  Showcase your brand amongst <br />
                  the best in the business
                </h2>

                {vendorsList && vendorsList.length > 0 && (
                  <div className="container-fluid d-flex justify-content-center">
                    <div className={style.container5}>
                      <VendorList vendorsList={vendorsList} dimension={dimension} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={style.formSection}>
          <div className={style.innerForm}>
            <h3>
              {' '}
              Fill in this form to showcase
              <br /> your brand on India’s first
              <br />
              AR interior design platform
            </h3>
            <div className={style.form}>
              <input type="text" className={style.inputStyle} placeholder="Full Name*" />
              <input type="text" className={style.inputStyle} placeholder="Company Name*" />
              <input type="text" className={style.inputStyle} placeholder="Mobile*" />
              <input type="text" className={style.inputStyle} placeholder="Email*" />
              <button className={style.buttonBlack}>Verify with OTP</button>
            </div>
            <p className={style.submitTxt}>
              By clicking Submit, I consent to Pixel & Mortar’s Privacy Policy and Terms of Use
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BecomeAPartner;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
