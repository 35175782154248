export const space = [
  {
    id: 1,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco1.jpg',
    tags: ['art_deco']
  },
  {
    id: 2,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco10.jpg',
    tags: ['art_deco']
  },
  {
    id: 3,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco11.jpg',
    tags: ['art_deco']
  },
  {
    id: 4,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco12.jpg',
    tags: ['art_deco']
  },
  {
    id: 5,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco13.jpg',
    tags: ['art_deco']
  },
  {
    id: 6,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco14.jpg',
    tags: ['art_deco']
  },
  {
    id: 7,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco15.jpg',
    tags: ['art_deco']
  },
  {
    id: 8,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco16.jpg',
    tags: ['art_deco']
  },
  {
    id: 9,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco17.jpg',
    tags: ['art_deco']
  },
  {
    id: 10,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco18.jpg',
    tags: ['art_deco']
  },
  {
    id: 11,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco19.jpg',
    tags: ['art_deco']
  },
  {
    id: 12,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco2.jpg',
    tags: ['art_deco']
  },
  {
    id: 13,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco20.jpg',
    tags: ['art_deco']
  },
  {
    id: 14,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco3.jpg',
    tags: ['art_deco']
  },
  {
    id: 15,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco4.jpg',
    tags: ['art_deco']
  },
  {
    id: 16,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco5.jpg',
    tags: ['art_deco']
  },
  {
    id: 17,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco6.jpg',
    tags: ['art_deco']
  },
  {
    id: 18,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco7.jpg',
    tags: ['art_deco']
  },
  {
    id: 19,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco8.jpg',
    tags: ['art_deco']
  },
  {
    id: 20,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/art_deco/art_deco9.jpg',
    tags: ['art_deco']
  },
  {
    id: 21,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho1.jpg',
    tags: ['boho']
  },
  {
    id: 22,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho10.jpg',
    tags: ['boho']
  },
  {
    id: 23,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho11.jpg',
    tags: ['boho']
  },
  {
    id: 24,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho12.jpg',
    tags: ['boho']
  },
  {
    id: 25,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho13.jpg',
    tags: ['boho']
  },
  {
    id: 26,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho14.jpg',
    tags: ['boho']
  },
  {
    id: 27,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho15.jpg',
    tags: ['boho']
  },
  {
    id: 28,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho16.jpg',
    tags: ['boho']
  },
  {
    id: 29,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho17.jpg',
    tags: ['boho']
  },
  {
    id: 30,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho18.jpg',
    tags: ['boho']
  },
  {
    id: 31,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho19.jpg',
    tags: ['boho']
  },
  {
    id: 32,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho2.jpg',
    tags: ['boho']
  },
  {
    id: 33,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho20.jpg',
    tags: ['boho']
  },
  {
    id: 34,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho3.jpg',
    tags: ['boho']
  },
  {
    id: 35,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho4.jpg',
    tags: ['boho']
  },
  {
    id: 36,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho5.jpg',
    tags: ['boho']
  },
  {
    id: 37,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho6.jpg',
    tags: ['boho']
  },
  {
    id: 38,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho7.jpg',
    tags: ['boho']
  },
  {
    id: 39,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho8.jpg',
    tags: ['boho']
  },
  {
    id: 40,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/boho/boho9.jpg',
    tags: ['boho']
  },
  {
    id: 41,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian1.jpg',
    tags: ['classic_indian']
  },
  {
    id: 42,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian10.jpg',
    tags: ['classic_indian']
  },
  {
    id: 43,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian11.jpg',
    tags: ['classic_indian']
  },
  {
    id: 44,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian12.jpg',
    tags: ['classic_indian']
  },
  {
    id: 45,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian13.jpg',
    tags: ['classic_indian']
  },
  {
    id: 46,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian14.jpg',
    tags: ['classic_indian']
  },
  {
    id: 47,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian15.jpg',
    tags: ['classic_indian']
  },
  {
    id: 48,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian16.jpg',
    tags: ['classic_indian']
  },
  {
    id: 49,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian17.jpg',
    tags: ['classic_indian']
  },
  {
    id: 50,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian18.jpg',
    tags: ['classic_indian']
  },
  {
    id: 51,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian19.jpg',
    tags: ['classic_indian']
  },
  {
    id: 52,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian2.jpg',
    tags: ['classic_indian']
  },
  {
    id: 53,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian20.jpg',
    tags: ['classic_indian']
  },
  {
    id: 54,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian3.jpg',
    tags: ['classic_indian']
  },
  {
    id: 55,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian4.jpg',
    tags: ['classic_indian']
  },
  {
    id: 56,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian5.jpg',
    tags: ['classic_indian']
  },
  {
    id: 57,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian6.jpg',
    tags: ['classic_indian']
  },
  {
    id: 58,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian7.jpg',
    tags: ['classic_indian']
  },
  {
    id: 59,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian8.jpg',
    tags: ['classic_indian']
  },
  {
    id: 60,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/classic_indian/classic_indian9.jpg',
    tags: ['classic_indian']
  },
  {
    id: 61,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal1.jpg',
    tags: ['coastal']
  },
  {
    id: 62,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal10.jpg',
    tags: ['coastal']
  },
  {
    id: 63,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal11.jpg',
    tags: ['coastal']
  },
  {
    id: 64,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal12.jpg',
    tags: ['coastal']
  },
  {
    id: 65,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal13.jpg',
    tags: ['coastal']
  },
  {
    id: 66,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal14.jpg',
    tags: ['coastal']
  },
  {
    id: 67,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal15.jpg',
    tags: ['coastal']
  },
  {
    id: 68,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal16.jpg',
    tags: ['coastal']
  },
  {
    id: 69,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal17.jpg',
    tags: ['coastal']
  },
  {
    id: 70,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal18.jpg',
    tags: ['coastal']
  },
  {
    id: 71,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal19.jpg',
    tags: ['coastal']
  },
  {
    id: 72,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal2.jpg',
    tags: ['coastal']
  },
  {
    id: 73,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal20.jpg',
    tags: ['coastal']
  },
  {
    id: 74,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal3.jpg',
    tags: ['coastal']
  },
  {
    id: 75,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal4.jpg',
    tags: ['coastal']
  },
  {
    id: 76,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal5.jpg',
    tags: ['coastal']
  },
  {
    id: 77,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal6.jpg',
    tags: ['coastal']
  },
  {
    id: 78,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal7.jpg',
    tags: ['coastal']
  },
  {
    id: 79,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal8.jpg',
    tags: ['coastal']
  },
  {
    id: 80,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coastal/coastal9.jpg',
    tags: ['coastal']
  },
  {
    id: 81,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary1.jpg',
    tags: ['contemporary']
  },
  {
    id: 82,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary10.jpg',
    tags: ['contemporary']
  },
  {
    id: 83,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary11.jpg',
    tags: ['contemporary']
  },
  {
    id: 84,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary12.jpg',
    tags: ['contemporary']
  },
  {
    id: 85,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary13.jpg',
    tags: ['contemporary']
  },
  {
    id: 86,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary14.jpg',
    tags: ['contemporary']
  },
  {
    id: 87,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary15.jpg',
    tags: ['contemporary']
  },
  {
    id: 88,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary16.jpg',
    tags: ['contemporary']
  },
  {
    id: 89,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary17.jpg',
    tags: ['contemporary']
  },
  {
    id: 90,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary18.jpg',
    tags: ['contemporary']
  },
  {
    id: 91,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary19.jpg',
    tags: ['contemporary']
  },
  {
    id: 92,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary2.jpg',
    tags: ['contemporary']
  },
  {
    id: 93,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary20.jpg',
    tags: ['contemporary']
  },
  {
    id: 94,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary3.jpg',
    tags: ['contemporary']
  },
  {
    id: 95,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary4.jpg',
    tags: ['contemporary']
  },
  {
    id: 96,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary5.jpg',
    tags: ['contemporary']
  },
  {
    id: 97,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary6.jpg',
    tags: ['contemporary']
  },
  {
    id: 98,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary7.jpg',
    tags: ['contemporary']
  },
  {
    id: 99,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary8.jpg',
    tags: ['contemporary']
  },
  {
    id: 100,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/contemporary/contemporary9.jpg',
    tags: ['contemporary']
  },
  {
    id: 101,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic1.jpg',
    tags: ['eclectic']
  },
  {
    id: 102,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic10.jpg',
    tags: ['eclectic']
  },
  {
    id: 103,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic11.jpg',
    tags: ['eclectic']
  },
  {
    id: 104,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic12.jpg',
    tags: ['eclectic']
  },
  {
    id: 105,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic13.jpg',
    tags: ['eclectic']
  },
  {
    id: 106,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic14.jpg',
    tags: ['eclectic']
  },
  {
    id: 107,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic15.jpg',
    tags: ['eclectic']
  },
  {
    id: 108,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic16.jpg',
    tags: ['eclectic']
  },
  {
    id: 109,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic17.jpg',
    tags: ['eclectic']
  },
  {
    id: 110,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic18.jpg',
    tags: ['eclectic']
  },
  {
    id: 111,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic19.jpg',
    tags: ['eclectic']
  },
  {
    id: 112,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic2.jpg',
    tags: ['eclectic']
  },
  {
    id: 113,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic20.jpg',
    tags: ['eclectic']
  },
  {
    id: 114,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic3.jpg',
    tags: ['eclectic']
  },
  {
    id: 115,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic4.jpg',
    tags: ['eclectic']
  },
  {
    id: 116,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic5.jpg',
    tags: ['eclectic']
  },
  {
    id: 117,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic6.jpg',
    tags: ['eclectic']
  },
  {
    id: 118,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic7.jpg',
    tags: ['eclectic']
  },
  {
    id: 119,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic8.jpg',
    tags: ['eclectic']
  },
  {
    id: 120,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/eclectic/eclectic9.jpg',
    tags: ['eclectic']
  },
  {
    id: 121,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house1.jpg',
    tags: ['farm_house']
  },
  {
    id: 122,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house10.jpg',
    tags: ['farm_house']
  },
  {
    id: 123,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house11.jpg',
    tags: ['farm_house']
  },
  {
    id: 124,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house12.jpg',
    tags: ['farm_house']
  },
  {
    id: 125,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house13.jpg',
    tags: ['farm_house']
  },
  {
    id: 126,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house14.jpg',
    tags: ['farm_house']
  },
  {
    id: 127,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house15.jpg',
    tags: ['farm_house']
  },
  {
    id: 128,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house16.jpg',
    tags: ['farm_house']
  },
  {
    id: 129,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house17.jpg',
    tags: ['farm_house']
  },
  {
    id: 130,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house18.jpg',
    tags: ['farm_house']
  },
  {
    id: 131,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house19.jpg',
    tags: ['farm_house']
  },
  {
    id: 132,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house2.jpg',
    tags: ['farm_house']
  },
  {
    id: 133,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house20.jpg',
    tags: ['farm_house']
  },
  {
    id: 134,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house3.jpg',
    tags: ['farm_house']
  },
  {
    id: 135,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house4.jpg',
    tags: ['farm_house']
  },
  {
    id: 136,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house5.jpg',
    tags: ['farm_house']
  },
  {
    id: 137,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house6.jpg',
    tags: ['farm_house']
  },
  {
    id: 138,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house7.jpg',
    tags: ['farm_house']
  },
  {
    id: 139,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house8.jpg',
    tags: ['farm_house']
  },
  {
    id: 140,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/farm_house/farm_house9.jpg',
    tags: ['farm_house']
  },
  {
    id: 141,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic1.jpg',
    tags: ['gothic']
  },
  {
    id: 142,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic10.jpg',
    tags: ['gothic']
  },
  {
    id: 143,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic11.jpg',
    tags: ['gothic']
  },
  {
    id: 144,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic12.jpg',
    tags: ['gothic']
  },
  {
    id: 145,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic13.jpg',
    tags: ['gothic']
  },
  {
    id: 146,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic14.jpg',
    tags: ['gothic']
  },
  {
    id: 147,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic15.jpg',
    tags: ['gothic']
  },
  {
    id: 148,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic16.jpg',
    tags: ['gothic']
  },
  {
    id: 149,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic17.jpg',
    tags: ['gothic']
  },
  {
    id: 150,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic18.jpg',
    tags: ['gothic']
  },
  {
    id: 151,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic19.jpg',
    tags: ['gothic']
  },
  {
    id: 152,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic2.jpg',
    tags: ['gothic']
  },
  {
    id: 153,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic20.jpg',
    tags: ['gothic']
  },
  {
    id: 154,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic3.jpg',
    tags: ['gothic']
  },
  {
    id: 155,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic4.jpg',
    tags: ['gothic']
  },
  {
    id: 156,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic5.jpg',
    tags: ['gothic']
  },
  {
    id: 157,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic6.jpg',
    tags: ['gothic']
  },
  {
    id: 158,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic7.jpg',
    tags: ['gothic']
  },
  {
    id: 159,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic8.jpg',
    tags: ['gothic']
  },
  {
    id: 160,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/gothic/gothic9.jpg',
    tags: ['gothic']
  },
  {
    id: 161,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial1.jpg',
    tags: ['industrial']
  },
  {
    id: 162,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial10.jpg',
    tags: ['industrial']
  },
  {
    id: 163,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial11.jpg',
    tags: ['industrial']
  },
  {
    id: 164,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial12.jpg',
    tags: ['industrial']
  },
  {
    id: 165,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial13.jpg',
    tags: ['industrial']
  },
  {
    id: 166,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial14.jpg',
    tags: ['industrial']
  },
  {
    id: 167,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial15.jpg',
    tags: ['industrial']
  },
  {
    id: 168,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial16.jpg',
    tags: ['industrial']
  },
  {
    id: 169,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial17.jpg',
    tags: ['industrial']
  },
  {
    id: 170,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial18.jpg',
    tags: ['industrial']
  },
  {
    id: 171,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial19.jpg',
    tags: ['industrial']
  },
  {
    id: 172,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial2.jpg',
    tags: ['industrial']
  },
  {
    id: 173,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial20.jpg',
    tags: ['industrial']
  },
  {
    id: 174,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial3.jpg',
    tags: ['industrial']
  },
  {
    id: 175,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial4.jpg',
    tags: ['industrial']
  },
  {
    id: 176,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial5.jpg',
    tags: ['industrial']
  },
  {
    id: 177,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial6.jpg',
    tags: ['industrial']
  },
  {
    id: 178,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial7.jpg',
    tags: ['industrial']
  },
  {
    id: 179,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial8.jpg',
    tags: ['industrial']
  },
  {
    id: 180,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/industrial/industrial9.jpg',
    tags: ['industrial']
  },
  {
    id: 181,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern1.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 182,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern10.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 183,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern11.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 184,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern12.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 185,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern13.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 186,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern14.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 187,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern15.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 188,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern16.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 189,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern17.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 190,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern18.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 191,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern19.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 192,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern2.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 193,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern20.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 194,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern3.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 195,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern4.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 196,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern5.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 197,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern6.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 198,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern7.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 199,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern8.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 200,
    src:
      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mid_century_modern/mid_century_modern9.jpg',
    tags: ['mid_century_modern']
  },
  {
    id: 201,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic1.jpg',
    tags: ['minimalistic']
  },
  {
    id: 202,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic10.jpg',
    tags: ['minimalistic']
  },
  {
    id: 203,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic11.jpg',
    tags: ['minimalistic']
  },
  {
    id: 204,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic12.jpg',
    tags: ['minimalistic']
  },
  {
    id: 205,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic13.jpg',
    tags: ['minimalistic']
  },
  {
    id: 206,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic14.jpg',
    tags: ['minimalistic']
  },
  {
    id: 207,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic15.jpg',
    tags: ['minimalistic']
  },
  {
    id: 208,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic16.jpg',
    tags: ['minimalistic']
  },
  {
    id: 209,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic17.jpg',
    tags: ['minimalistic']
  },
  {
    id: 210,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic18.jpg',
    tags: ['minimalistic']
  },
  {
    id: 211,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic19.jpg',
    tags: ['minimalistic']
  },
  {
    id: 212,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic2.jpg',
    tags: ['minimalistic']
  },
  {
    id: 213,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic20.jpg',
    tags: ['minimalistic']
  },
  {
    id: 214,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic3.jpg',
    tags: ['minimalistic']
  },
  {
    id: 215,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic4.jpg',
    tags: ['minimalistic']
  },
  {
    id: 216,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic5.jpg',
    tags: ['minimalistic']
  },
  {
    id: 217,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic6.jpg',
    tags: ['minimalistic']
  },
  {
    id: 218,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic7.jpg',
    tags: ['minimalistic']
  },
  {
    id: 219,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic8.jpg',
    tags: ['minimalistic']
  },
  {
    id: 220,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/minimalistic/minimalistic9.jpg',
    tags: ['minimalistic']
  },
  {
    id: 221,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian1.jpg',
    tags: ['north_indian']
  },
  {
    id: 222,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian10.jpg',
    tags: ['north_indian']
  },
  {
    id: 223,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian11.jpg',
    tags: ['north_indian']
  },
  {
    id: 224,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian12.jpg',
    tags: ['north_indian']
  },
  {
    id: 225,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian13.jpg',
    tags: ['north_indian']
  },
  {
    id: 226,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian14.jpg',
    tags: ['north_indian']
  },
  {
    id: 227,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian15.jpg',
    tags: ['north_indian']
  },
  {
    id: 228,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian16.jpg',
    tags: ['north_indian']
  },
  {
    id: 229,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian17.jpg',
    tags: ['north_indian']
  },
  {
    id: 230,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian18.jpg',
    tags: ['north_indian']
  },
  {
    id: 231,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian19.jpg',
    tags: ['north_indian']
  },
  {
    id: 232,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian2.jpg',
    tags: ['north_indian']
  },
  {
    id: 233,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian20.jpg',
    tags: ['north_indian']
  },
  {
    id: 234,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian3.jpg',
    tags: ['north_indian']
  },
  {
    id: 235,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian4.jpg',
    tags: ['north_indian']
  },
  {
    id: 236,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian5.jpg',
    tags: ['north_indian']
  },
  {
    id: 237,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian6.jpg',
    tags: ['north_indian']
  },
  {
    id: 238,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian7.jpg',
    tags: ['north_indian']
  },
  {
    id: 239,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian8.jpg',
    tags: ['north_indian']
  },
  {
    id: 240,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/north_indian/north_indian9.jpg',
    tags: ['north_indian']
  },
  {
    id: 241,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art1.jpg',
    tags: ['pop_art']
  },
  {
    id: 242,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art10.jpg',
    tags: ['pop_art']
  },
  {
    id: 243,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art11.jpg',
    tags: ['pop_art']
  },
  {
    id: 244,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art12.jpg',
    tags: ['pop_art']
  },
  {
    id: 245,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art13.jpg',
    tags: ['pop_art']
  },
  {
    id: 246,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art14.jpg',
    tags: ['pop_art']
  },
  {
    id: 247,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art15.jpg',
    tags: ['pop_art']
  },
  {
    id: 248,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art16.jpg',
    tags: ['pop_art']
  },
  {
    id: 249,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art17.jpg',
    tags: ['pop_art']
  },
  {
    id: 250,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art18.jpg',
    tags: ['pop_art']
  },
  {
    id: 251,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art19.jpg',
    tags: ['pop_art']
  },
  {
    id: 252,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art2.jpg',
    tags: ['pop_art']
  },
  {
    id: 253,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art20.jpg',
    tags: ['pop_art']
  },
  {
    id: 254,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art3.jpg',
    tags: ['pop_art']
  },
  {
    id: 255,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art4.jpg',
    tags: ['pop_art']
  },
  {
    id: 256,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art5.jpg',
    tags: ['pop_art']
  },
  {
    id: 257,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art6.jpg',
    tags: ['pop_art']
  },
  {
    id: 258,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art7.jpg',
    tags: ['pop_art']
  },
  {
    id: 259,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art8.jpg',
    tags: ['pop_art']
  },
  {
    id: 260,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/pop_art/pop_art9.jpg',
    tags: ['pop_art']
  },
  {
    id: 261,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic1.jpg',
    tags: ['rustic']
  },
  {
    id: 262,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic10.jpg',
    tags: ['rustic']
  },
  {
    id: 263,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic11.jpg',
    tags: ['rustic']
  },
  {
    id: 264,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic12.jpg',
    tags: ['rustic']
  },
  {
    id: 265,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic13.jpg',
    tags: ['rustic']
  },
  {
    id: 266,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic14.jpg',
    tags: ['rustic']
  },
  {
    id: 267,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic15.jpg',
    tags: ['rustic']
  },
  {
    id: 268,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic16.jpg',
    tags: ['rustic']
  },
  {
    id: 269,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic17.jpg',
    tags: ['rustic']
  },
  {
    id: 270,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic18.jpg',
    tags: ['rustic']
  },
  {
    id: 271,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic19.jpg',
    tags: ['rustic']
  },
  {
    id: 272,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic2.jpg',
    tags: ['rustic']
  },
  {
    id: 273,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic20.jpg',
    tags: ['rustic']
  },
  {
    id: 274,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic3.jpg',
    tags: ['rustic']
  },
  {
    id: 275,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic4.jpg',
    tags: ['rustic']
  },
  {
    id: 276,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic5.jpg',
    tags: ['rustic']
  },
  {
    id: 277,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic6.jpg',
    tags: ['rustic']
  },
  {
    id: 278,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic7.jpg',
    tags: ['rustic']
  },
  {
    id: 279,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic8.jpg',
    tags: ['rustic']
  },
  {
    id: 280,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rustic/rustic9.jpg',
    tags: ['rustic']
  },
  {
    id: 281,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian1.jpg',
    tags: ['south_indian']
  },
  {
    id: 282,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian10.jpg',
    tags: ['south_indian']
  },
  {
    id: 283,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian11.jpg',
    tags: ['south_indian']
  },
  {
    id: 284,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian12.jpg',
    tags: ['south_indian']
  },
  {
    id: 285,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian13.jpg',
    tags: ['south_indian']
  },
  {
    id: 286,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian14.jpg',
    tags: ['south_indian']
  },
  {
    id: 287,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian15.jpg',
    tags: ['south_indian']
  },
  {
    id: 288,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian16.jpg',
    tags: ['south_indian']
  },
  {
    id: 289,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian17.jpg',
    tags: ['south_indian']
  },
  {
    id: 290,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian18.jpg',
    tags: ['south_indian']
  },
  {
    id: 291,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian2.jpg',
    tags: ['south_indian']
  },
  {
    id: 292,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian3.jpg',
    tags: ['south_indian']
  },
  {
    id: 293,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian4.jpg',
    tags: ['south_indian']
  },
  {
    id: 294,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian5.jpg',
    tags: ['south_indian']
  },
  {
    id: 295,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian6.jpg',
    tags: ['south_indian']
  },
  {
    id: 296,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian7.jpg',
    tags: ['south_indian']
  },
  {
    id: 297,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian8.jpg',
    tags: ['south_indian']
  },
  {
    id: 298,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/south_indian/south_indian9.jpg',
    tags: ['south_indian']
  }
];

export const resultImages = [
  {
    id: 1,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/south_indian.png',
    tags: ['south_indian'],
    description:
      "A style that stems from a love of traditional South Indian culture, mixed with aspirations to dwell in modern spaces. It's a cohesive blend of exotic, vibrant and interesting details, showcasing the traditional and cultural influences of each element."
  },
  {
    id: 2,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/rustic.png',
    tags: ['rustic'],
    description:
      'With an emphasis on natural, aged and distressed elements, this style is approachable and relaxed, with a muted, traditional colour palette. It consists of textured and rugged spaces with earthy and handmade pieces that shape a warm, welcoming atmosphere.'
  },
  {
    id: 3,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/pop_art.png',
    tags: ['pop_art'],
    description:
      'As an emotional and energetic style pop art-styled interiors include rainbow colours, flashy shapes, the use of plastic, repetitive elements. Decor inspired by the style looks unique, extraordinary, bold and with a keen sense of humor.'
  },
  {
    id: 4,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/north_indian.png',
    tags: ['north_indian'],
    description:
      'North Indian-styled spaces have their own charm from distressed wood elements, natural light play, textile display, motifs, vibrant colours and more. Desi touches are everywhere like carved Mughal archways, windows, lanterns and a little kitsch thrown in for good measure.'
  },
  {
    id: 5,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/minimalistic.png',
    tags: ['minimalistic'],
    description:
      'Think clean, modern lines, a tight edit, a concise colour palette, and ‘less is more’ silhouettes, layered into a warm, rich, and inviting home with a zen-like setting. This style is characterised by simplicity and a monochromatic palette with accents.'
  },
  {
    id: 6,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/mid_century_modern.png',
    tags: ['mid_century_modern'],
    description:
      'Simple lines, pure forms, natural hues and grounded essentials define Mid-Century Modern design. It’s all about natural style and ease of living, with minimal ornamentation, and high functionality elements.'
  },
  {
    id: 7,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/industrial.png',
    tags: ['industrial'],
    description:
      'The main element of Industrial style is the embracing of features that are typically concealed, like pipes and ducts, creating the signature ‘unfinished’ and ‘raw’ look. Contrasts define this style and it creates an understated depth, rustic charm, and a coarse lived-in feel.'
  },
  {
    id: 8,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/gothic.png',
    tags: ['gothic'],
    description:
      'Inspired by the Victorian era and Gothic churches, this design style is intricate and classic and defined by light play, ornate decorations and grandeur. It’s identifiable through dramatic carvings and patterns on all surfaces, moldings, spirals, stained glass and rich, dark colours.'
  },
  {
    id: 9,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/farmhouse.png',
    tags: ['farm_house'],
    description:
      'A balanced mix of straight lines, modern curves and iconic arches contrasted by the gradual curves, symmetry and functionalism found in nature.'
  },
  {
    id: 10,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/eclectic.png',
    tags: ['eclectic'],
    description:
      'Bringing in elements from different periods, mixed textures, and complementary colors are wonderful staples of eclectic flair. It’s all about that quirky aesthetic, cool character and warmth that comes with layering, experimentation and contrast play.'
  },
  {
    id: 11,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/contemporary.png',
    tags: ['contemporary'],
    description:
      'Defined by simplicity, subtle sophistication, deliberate use of texture, and clean lines, contemporary interiors are comfortable, welcoming and tend to highlight space rather than things. It also borrows many elements from other styles for an unique look.'
  },
  {
    id: 12,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/coastal.png',
    tags: ['coastal'],
    description:
      'A beach-inspired interior style, it relies on natural light, a soft palette with more whites, neutrals and ocean hues, an airy feel, natural textures and a few maritime motifs. It creates a relaxed, breezy appearance all year round.'
  },
  {
    id: 13,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/classical.png',
    tags: ['classic_indian'],
    description:
      'Deeply influenced by the classical period, its aesthetics and Western traditions, this style is all about geometry, symmetry and timelessness. Warm hues, signature decor and luxurious materials create a balanced and welcoming space that is luxurious and artistic yet tasteful.'
  },
  {
    id: 14,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/bohemian.png',
    tags: ['boho'],
    description:
      'Bohemian design likes coloring outside the lines, it’s vibrant, carefree, full of personality and doesn’t have any hard and fast rules. This style is perfect for travelers and adventure-seekers who want to fill their homes with life, culture and interesting items'
  },
  {
    id: 15,
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mx-result-icons/art_deco.png',
    tags: ['art_deco'],
    description:
      'One of the 20th century’s most iconic styles, it’s a symbol of old-school glamour and sophistication. This inherently luxurious style displays a love for drama, geometry, symmetry, jewel tones and ornate embellishments.'
  }
];

export const bucket = [
  {
    title: 'Ethnic',
    style: ['south_indian', 'north_indian', 'classic_indian'],
    description:
      'Influenced by traditions and geography, this warm interior style reflects deep-rooted culture and aesthetics, from the materials chosen, natural colour palette, artistic patterns, bright fabrics and carved motifs on furniture, decor and more.',
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Style+Bucket/Ethnic.jpg',
    priority: 1
  },
  {
    title: 'Modern',
    style: ['minimalistic', 'contemporary', 'mid_century_modern'],
    description:
      'In the mood for clean finishes, crisp lines, featuring lots of metal, glass and steel? Immerse yourself in the vibrant celebration of natural materials, earthy shades with minimal detailing. These urban spaces portray pristine simplicity in every facet.',
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Style+Bucket/Modern.jpg',
    priority: 2
  },
  {
    title: 'Glam',
    style: ['eclectic', 'pop_art', 'art_deco'],
    description:
      'Step into the lavish sets of the Great Gatsby, The Crown or FRIENDS. Think bold upscale flair or cosy quirk with a blend of luxurious fabrics, pops of colour, metal accents and signature decor.',
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Style+Bucket/Glam.jpg',
    priority: 3
  },
  {
    title: 'Rustic',
    style: ['industrial', 'gothic', 'rustic'],
    description:
      'The charm is in the copious modesty created. Spaces are distinctive, architectural and a mix of rural and urban worlds, old meets new, natural and man-made materials, colour and texture-play.',
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Style+Bucket/Rustic.jpg',
    priority: 4
  },
  {
    title: 'Organic',
    style: ['farm_house', 'boho', 'coastal'],
    description:
      'The idea of living in harmony with nature, centers on fluid lines and a neutral palette. Start with a canvas of modern architecture and layer on natural forms and organic materials to create a comfortable, balanced and effortless feel',
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Style+Bucket/Organic.jpg',
    priority: 5
  },
  {
    title: 'Transitional',
    style: [],
    description:
      'This is a marriage of two different styles, traditional and contemporary furniture, neutral and bold palettes, finishes, materials and fabrics, all culminating in one enchanting, timeless design. When done right, it is the epitome of understated sophistication.',
    src: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Style+Bucket/Transitional+Lamdscape.jpg'
  }
];

export const styleData = [
  {
    title: 'South Indian',
    value: 'south_indian',
    content: `<div>This style showcases traditional elements in South India and appeals to everyone who search for cultural experiences in modern spaces.</br>Things to look out for:</br><li>Wood and Brass</li>
    <li>Mustard yellow, Teracotta hues, Forest green & Indigo</li>
    <li>Motifs, Detailed carvings, Mouldings</li>
    <li>Cotton, Linen, Silk Fabrics</li>
    <li>Brocade, Hand block prints</li>
    </div> `,

    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/South+Indian.jpg',
    priority: 1
  },
  {
    title: 'Classic Indian',
    value: 'classic_indian',
    content: `
    This style is all about geometry, symmetry, timelessness and projecting traditions onto your space.<br/>
    Things to look out for:
    <li>Light pastel colours</li>
    <li>Olive, Beige, Teal</li>
    <li>Brocade and Florals</li>
   <li> Gold finish materials and textiles</li>
    
    
`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Classic.jpg',
    priority: 2
  },
  {
    title: 'North Indian',
    value: 'north_indian',
    content: `
North Indian focuses on mughal elements and displays of vibrant colours in your space.<br/>
Things to look out for:
<li>Tinted glass, Lanterns & Wood elements.</li>
<li>Sapphire Blue, Emerald Green, Ruby red, Amethyst Purple</li>
<li>Marble, Wood, Brass</li>
<li>Mouldings, Detailed carvings, Motifs</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/North+Indian+.jpg',
    priority: 3
  },
  {
    title: 'Minimalistic',
    value: 'minimalistic',
    content: `A warm, rich and inviting home with a zen-like setting are the characteristics of minimalistic interiors. 
Less is more is the accurate definition of a minimal look.<br/>

Things to look out for:
<li>Monochromatic palette</li>
<li>Wood and Glass</li>
<li>Focus on shape and form</li>
`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Minimal.jpg',
    priority: 4
  },
  {
    title: 'Contemporary',
    value: 'contemporary',
    content: `Contemporary interiors are comfortable, welcoming and tend to highlight space rather than things.
Clean, crisp and subtle sophistication are some of the attributes that define contemporary style.<br/>

Things to look out for:
<li>White, Grey and Black colours</li>
<li>Geometric patterns</li>
<li>Dark leather and Lightly textured fabrics</li>
<li>Metal, Wood and Glass forms</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Contemporary+.jpg',
    priority: 5
  },
  {
    title: 'Mid Century modern',
    value: 'mid_century_modern',
    content: `Simple lines, pure forms, natural hues and grounded essentials define Mid-Century Modern design.<br/>

Things to look out for:
<li>Organic and geometric shapes</li>
<li>Warn and earthy tones</li>
<li>Large sculptures, artwork, plants</li>
<li>Teak, Walnut, wood furnishings</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Mid+century+modern.jpg',
    priority: 6
  },
  {
    title: 'Eclectic',
    value: 'eclectic',
    content: `It’s all about that quirky aesthetic, cool character and warmth that comes with layering, experimentation and contrast play.<br/>

Things to look out for:
<li>Natural materials and colours</li>
<li>Leather, velvet, marble</li>
<li>Stone, Glass, Silver, Gold</li>
<li>Abstract geometry, Chevron prints</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Eclectic.jpg',
    priority: 7
  },
  {
    title: 'Pop Art',
    value: 'pop_art',
    content: `Decor inspired by the style looks unique, extraordinary, bold and with a keen sense of humor. <br/>

Things to look out for:
<li>Primary colours</li>
<li>Quirky shapes, Bold decors</li>
<li>Plastic and synthetic fibres</li>
<li>Soft organic furniture</li>
<li>Retro decors, stripes, bulky prints</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Pop+Art+copy.jpg',
    priority: 8
  },
  {
    title: 'Art Deco',
    value: 'art_deco',
    content: `One of the 20th century’s most iconic styles, it’s a symbol of old-school glamour and sophistication.<br/>

Things to look out for:
<li>Strong contrast colours</li>
<li>Geometric motifs</li>
<li>Shimmering metallics</li>
<li>Zigzags, chevrons, sunburst</li>
<li>Lush fabrics, velvets, leathers</li>
<li>Animal prints, furns</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Art+Deco.jpg',
    priority: 9
  },
  {
    title: 'Industrial',
    value: 'industrial',
    content: `The main element of Industrial style is the embracing of features that are typically concealed, like pipes and ducts, creating the signature ‘unfinished’ and ‘raw’ look.<br/>

Things to look out for:
<li>Neutral tones</li>
<li>Raw materials, exposed finishes</li>
<li>Iron, brass, glass</li>
<li>Machine part style</li>
<li>Dark metal, warehouse aesthetic</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Industrial.jpg',
    priority: 10
  },
  {
    title: 'Gothic',
    value: 'gothic',
    content: `Inspired by the Victorian era and Gothic churches, this design style is intricate and classic and defined by light play, ornate decorations and grandeur.<br/>

Things to look out for:
<li>Majestic colours like dark and warm</li>
<li>Light play, Ornate decorations</li>
<li>Geometric patterns, Carved floral details</li>
<li>Stained glass, heavy wood</li>
<li>Tufted fabrics</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Gothic.jpg',
    priority: 11
  },
  {
    title: 'Rustic',
    value: 'rustic',
    content: `With an emphasis on natural, aged and distressed elements, this style is approachable and relaxed, with a muted, traditional colour palette.<br/>

Things to look out for:
<li>Earthy, white and neutral colours</li>
<li>Aged, weathered furniture</li>
<li>Faux animal skin fabric</li>
<li>Natural organic textile</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Rustic.jpg',
    priority: 12
  },
  {
    title: 'Farm House',
    value: 'farm_house',
    content: `A balanced mix of straight lines, modern curves and iconic arches contrasted by the gradual curves, symmetry and functionalism found in nature.<br/>

Things to look out for:
<li>Warm, Cool Neutrals, Earth tones</li>
<li>Natural greens and blues</li>
<li>Natural wood and matte metal</li>
<li>Checks, Plaids, Rural prints</li>
<li>Light wood, Vintage decor</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Farm+House.jpg',
    priority: 13
  },
  {
    title: 'Bohemian',
    value: 'boho',
    content: `This style is perfect for travelers and adventure-seekers who want to fill their homes with life, culture and interesting items.<br/>

Things to look out for:
<li>Macrame and patterns</li>
<li>White, beige and browns</li>
<li>Kilim textiles, Aztec prints</li>
<li>Boho furniture, cane, jute</li>
<li>Beechwood, pinewood, planters</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Boho.jpg',
    priority: 14
  },
  {
    title: 'Coastal',
    value: 'coastal',
    content: `A beach-inspired interior style, it relies on natural light, a soft palette with more whites, neutrals and ocean hues, an airy feel, natural textures and a few maritime motifs.<br/>

Things to look out for:
<li>White, tans, beige</li>
<li>Corals and greys</li>
<li>Breezy fabrics, textured rugs</li>
<li>Flowly lines, mild patterns</li>
<li>Bamboo, oak, Mango wood</li>
<li>Gossamer drapes, seagrass, straw</li>
<li>Jute, Woven textiles</li>

`,
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Image/Coastal.jpg',
    priority: 15
  }
];

export const color = [
  {
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/warmtones.png',
    name: 'Warm'
  },
  {
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/cooltones.png',
    name: 'Cool'
  },
  {
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/neutraltones.png',
    name: 'Neutrals'
  },
  {
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Popcolours.png',
    name: 'Pops'
  }
];

export const design = [
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group_2700_dze9yj.png',
    title: 'Expert'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group_2698_iws3qo.png',
    title: 'Enthusiast'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Path_1642_sif1w7.png',
    title: 'Beginner'
  }
];

export const rooms = [
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2516.png',
    title: 'Living Room'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2483.png',
    title: 'Dining Room'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2474.png',
    title: 'Bed Room'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2946.png',
    title: 'Kids Room'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/pujaImg.jpeg',
    title: 'Puja Room'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3148.png',
    title: 'Foyer'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2956.png',
    title: 'Balcony'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2707.png',
    title: 'Study Entertainment Room'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2720.png',
    title: 'Others'
  }
];

export const intialRooms = [
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2483.png',
    title: 'Dining room'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2707.png',
    title: 'Rest Room'
  },
  {
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+2474.png',
    title: 'Bed room'
  },
  {
    image: '',
    title: 'No data'
  }
];
