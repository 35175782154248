import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import { Image } from 'semantic-ui-react';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ConsoleHelper from '../../utils/ConsoleHelper';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: '150px',
      position: 'relative',
      width: '80%',
      margin: 'auto',
      '@media (max-width: 600px)': {
        width: '100%',
      },
    },
    brandHeading: {
      fontFamily: 'Quicksand',
      marginBottom: '39px',
      textAlign: 'center',
      fontWeight: '700',
      fontSize: '28px',
    },
    vendorRow: {
      display: 'flex',
      justifyContent: 'center',
    },
    vendorContainer: {
      flex: '1',
      margin: '10px',
      cursor: 'pointer',
      textAlign: 'center',
    },
    paper: {
      position: 'relative',
      justifyContent: 'center',
      height: '170px',
      width: '200px',
      '@media (max-width: 600px)':{
        width: '110px',
        height: '110px'
      },
      left: '0px',
      top: '0px',
      borderRadius: '13px',
      border: '0.5px solid rgba(112, 112, 112, 0.4)',
      padding: '5px',
      transition: 'background-color 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#ececec',
      },
    },
    imagefit: {
      width: '100%',
      height: '100%',
    },
    vendorName: {
      fontFamily: 'Quicksand',
      position: 'relative',
      right: '15px',
    },
    showMoreButton: {
      display: 'block',
      padding: '10px',
      fontWeight: 'bold',
      cursor: 'pointer'
    },
  })
);

export default function ShopbyVendor({ loading }) {
  const classes = useStyles();
  const history = useHistory();
  const [vendorsList, setVendorsList] = useState([]);
  const [showAllVendors, setShowAllVendors] = useState(false);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendor/list`);
        if (res.data.length !== 0) {
          setVendorsList(res.data);
        }
      } catch (err) {
        ConsoleHelper('Error fetching vendor data:', err);
      }
    };
    fetchVendorData();
  }, []);

  const toggleShowAllVendors = () => {
    setShowAllVendors(prevState => !prevState);
  };

  const desktopColumns = 4;
  const desktopRows = 2;
  const mobileColumns = 3;
  const mobileRows = 3;

  const visibleVendorsDesktop = showAllVendors ? vendorsList : vendorsList.slice(0, desktopColumns * desktopRows);
  const visibleVendorsMobile = showAllVendors ? vendorsList : vendorsList.slice(0, mobileColumns * mobileRows);

  const columnsCount = window.innerWidth <= 600 ? mobileColumns : desktopColumns;
  const visibleVendors = window.innerWidth <= 600 ? visibleVendorsMobile : visibleVendorsDesktop;

  // Calculate the number of placeholders needed in the last row
  const placeholdersCount = columnsCount - (visibleVendors.length % columnsCount);
  const vendorsWithPlaceholders = [...visibleVendors, ...Array(placeholdersCount).fill(null)];

  const groupedVendors = [];
  for (let i = 0; i < vendorsWithPlaceholders.length; i += columnsCount) {
    groupedVendors.push(vendorsWithPlaceholders.slice(i, i + columnsCount));
  }

  return (
    <div className={classes.root}>
      <h5 className={classes.brandHeading}>Shop by Brand</h5>
      {loading ? (
        <div className="text-center">
          <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
        </div>
      ) : (
        <div>
          {groupedVendors.map((rowVendors, rowIndex) => (
            <div key={`row-${rowIndex}`} className={classes.vendorRow}>
              {rowVendors.map((data, colIndex) => (
                <div
                  key={`vendor-${data ? data.name : `placeholder-${colIndex}`}`}
                  onClick={() => data && history.push(`/shopbybrandFilter/brand/${data.name}`)}
                  className={classes.vendorContainer}
                >
                  {data && (
                    <Paper className={classes.paper}>
                      <Image
                        draggable={false}
                        className={classes.imagefit}
                        src={data.bit_img}
                        style={{
                          cursor: 'pointer',
                          maxWidth: '100%',
                        }}
                        alt={data.name}
                      />
                    </Paper>
                  )}
                  {/* <p className={classes.vendorName}>{data ? data.name : null}</p> */}
                </div>
              ))}
            </div>
          ))}
          {vendorsList.length > columnsCount && (
            <div className={classes.showMoreButton} onClick={toggleShowAllVendors}>
              {showAllVendors ? '...Show Less' : 'Show More ...'}
            </div>
          )}
        </div>
      )}
    </div>
  );
}