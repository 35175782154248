import React from 'react';
import style from './Button.module.css';
import classnames from 'classnames';

//implement proptypes

function PnMButton({ type, name, disabled, size, onClick, sx }) {
  return (
    <div
      style={sx}
      className={classnames(
        (type == 'filled' && style.filled) || (type == 'outlined' && style.outlined),
        size == 'sm' ? style.small : style.large,
        disabled && type == 'filled' && style.filledDisabled,
        disabled && type == 'outlined' && style.outlinedDisabled
      )}
      onClick={onClick}>
      {name}
    </div>
  );
}

export default PnMButton;
