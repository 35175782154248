import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Rooms.module.css';
import classnames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Concept from './Concept/Concept';
import Review from './Review/Review';
import Shop from './Review/Shop';
import Snackbar from '@material-ui/core/Snackbar';
import { useParams } from 'react-router-dom';
import hurryAnimation from '../../components/lottie/Hurray_Without.json';
import Lottie from 'react-lottie';
import { createTheme } from '@material-ui/core/styles';
import customerApiInstance from '../../utils/CustomerApiInstance';
import ConceptCarousel from './ConceptCarousel';
import carouselImage1 from '../../assets/images/ConceptCarousel/Carousel1.png';
import carouselImage2 from '../../assets/images/ConceptCarousel/Carousel2.png';
import carouselImage3 from '../../assets/images/ConceptCarousel/Carousel3.png';
import carouselImage4 from '../../assets/images/ConceptCarousel/Carousel4.png';
import carouselImage5 from '../../assets/images/ConceptCarousel/Carousel5.png';

const theme = createTheme({
  palette: {
    primary: { main: '#FF4E00' }, // Purple and green play nicely together.
    secondary: { main: '#11cb5f' } // This is just green.A700 as hex.
  }
});

const getStartedSteps = [
  {
    title: 'GET STARTED',
    image: carouselImage1,
    stepCount: 0,
    className: style.desktopCardImage1,
    text: 'Sit back while our designer getting cracking on your project.'
  },
  {
    title: 'CONCEPT BOARD',
    image: carouselImage2,
    stepCount: 1,
    className: style.desktopCardImage2,
    text:
      'Here, you will find 3 concept mood boards to toggle between and react on your favorite products. This will let our designers get closer to your tastes and liking.'
  },
  {
    title: 'REVIEW CONCEPT',
    image: carouselImage3,
    stepCount: 2,
    className: style.desktopCardImage3,
    text:
      'Based on your inputs, the designer puts together a moodboard. Now you can toggle between the alternatives that fits your taste and budget.'
  },
  {
    title: 'FINALISE CONCEPT',
    image: carouselImage4,
    stepCount: 3,
    className: style.desktopCardImage4,
    text:
      'Based on your choices, the finalize moodboard will be ready to shop away. Make sure you click on the product to see how it looks in AR.'
  },
  {
    title: 'SHOP',
    image: carouselImage5,
    stepCount: 4,
    className: style.desktopCardImage5,
    text: '...AND SHOP AWAY your favourite products!!!!'
  }
];

const GetStartedCard = props => {
  return (
    <div className={style.cardContainerDesktop}>
      <div className={style.stepCountContainerDesktop}>
        <div className={style.stepCountCircle}>
          {props.stepCount === 0 ? (
            <div className={style.activeStepCircle} />
          ) : (
            <span>{props.stepCount}</span>
          )}
        </div>
        <h5 className="text-center">{props.title}</h5>
      </div>
      <div className={style.cardContentWrapperDesktop}>
        <div className={`${style.cardContentsDesktop} ${props.className}`}>
          <div className={style.cardImageDesktop}>
            <img src={props.image} />
          </div>
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  );
};

const Rooms = ({ history, location }) => {
  const [notAuthorised, setNotAuthorised] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [projectData, setProjectData] = useState({ level: 0 });
  const [current, setCurrent] = useState('getting');
  const [lottie, setLottie] = useState(false);
  const [snack, setSnack] = useState(false);
  const [shopAwayDisable, setShopAwayDisable] = useState(false);
  const dimension = useMediaQuery();
  const { id } = useParams();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hurryAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  let headerShow = false;

  if (
    history.location.state &&
    history.location.state.showHeader &&
    history.location.state.showHeader === true
  ) {
    headerShow = true;
  }

  useEffect(() => {
    if (headerShow === true) {
      history.push({
        pathname: `/rooms/${id}`,
        state: { type: 'finalise', showHeader: headerShow }
      });
    }
  }, [headerShow]);

  function getLevel(job, reactions) {
    if (job == 'REVIEW_CONCEPT' && Object.keys(reactions).length === 0) {
      return 2;
    } else if (job == 'REVIEW_CONCEPT') {
      return 3;
    } else if (job == 'FINALIZE_CONCEPT') {
      return 4;
    } else if (job == 'COMPLETED') {
      return 5;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const fetchProject = async projectMoodboardsData => {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );
      if (res.data.message === 'Not Authorised!') {
        setNotAuthorised(true);
      } else {
        let project = res.data;
        let statusLevel = getLevel(project.job, project.product_reactions);
        project.level = statusLevel;

        setProjectData({ ...project });

        if (
          project.job === 'REVIEW_CONCEPT' &&
          Object.keys(project.product_reactions).length === 0
        ) {
          history.push({
            pathname: `/rooms/${id}`,
            state: { type: 'concept' }
          });
        } else if (project.job === 'REVIEW_CONCEPT') {
          history.push({ pathname: `/rooms/${id}`, state: { type: 'review' } });
        } else if (project.job === 'FINALIZE_CONCEPT') {
          history.push({
            pathname: `/rooms/${id}`,
            state: { type: 'finalise' }
          });
        } else if (project.job === 'COMPLETED') {
          history.push({ pathname: `/rooms/${id}`, state: { type: 'shop' } });
        } else {
          history.push({
            pathname: `/rooms/${id}`,
            state: { type: 'getting' }
          });
        }
      }
    };

    const fetchMoodboards = async () => {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/project/${id}`
      );

      if ([...res.data].length > 0) fetchProject([...res.data]);
    };
    id && fetchMoodboards();
  }, []);

  const handleRadio = type => {
    let currentLevel;
    if (projectData && Object.keys(projectData).length) {
      switch (type) {
        case 'getting':
          currentLevel = 1;
          if (projectData.level && projectData.level < currentLevel) {
            setSnack(true);
            setSnackMessage('The requested action can not be completed');
          } else {
            setCurrent(type);
            handleCheck(type);
          }
          break;
        case 'concept':
          currentLevel = 2;
          if (projectData.level < currentLevel) {
            setSnack(true);
            setSnackMessage('The requested action can not be completed');
          } else {
            setCurrent(type);
            handleCheck(type);
          }
          break;
        case 'review':
          currentLevel = 3;
          if (projectData.level < currentLevel) {
            setSnack(true);
            setSnackMessage('The requested action can not be completed');
          } else {
            setCurrent(type);
            handleCheck(type);
          }
          break;
        case 'finalise':
          currentLevel = 4;
          if (projectData.level < currentLevel) {
            setSnack(true);
            setSnackMessage('The requested action can not be completed');
          } else {
            setCurrent(type);
            handleCheck(type);
          }
          break;
        case 'shop':
          currentLevel = 5;
          if (projectData.level < currentLevel) {
            setSnack(true);
            setSnackMessage('The requested action can not be completed');
          } else {
            setCurrent(type);
            handleCheck(type);
          }
          break;
      }
    }
  };

  const handleCheck = e => {
    history.push({
      pathname: `/rooms/${id}`,
      state: { type: `${e}`, check: true, showHeader: headerShow }
    });
  };

  useEffect(() => {
    if (location.state?.type === 'concept') {
      setCurrent('concept');
    } else if (location.state?.type === 'review') {
      setCurrent('review');
    } else if (location.state?.type === 'finalise') {
      setCurrent('finalise');
    } else if (location.state?.type === 'shop') {
      setCurrent('shop');
    } else if (location.state?.type === 'getting') {
      setCurrent('getting');
    }
  }, [location]);

  return (
    <>
      {notAuthorised ? (
        <p textAlign="center">Not Authorised to see view this project!</p>
      ) : lottie ? (
        <div style={{ marginTop: '70px' }}>
          <p
            className={style.perfectStyle}
            style={{
              textAlign: 'center',
              fontSize: '18px',
              position: 'absolute',
              top: '80px',
              left: 0,
              right: 0,
              zIndex: 100
            }}>
            <i>Hurry! Time to shop</i>
          </p>
          <Lottie options={defaultOptions} height="70vh" width={500} />
        </div>
      ) : (
        <MuiThemeProvider theme={theme}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snack}
            onClose={() => setSnack(false)}
            message={snackMessage}
            autoHideDuration={2000}
          />
          <Header />

          {/* statusbar start */}
          {dimension[0] > 500 ? (
            <div
              style={{
                height: '53px',
                backgroundColor: '#F5F5F5',
                overflow: 'hidden',
                marginBottom: '-5px',
                marginTop: '75px'
              }}>
              <div
                className={classnames(
                  style.stepperContainer,
                  'd-flex flex-row justify-content-center align-items-center'
                )}
                style={{ zIndex: '10' }}>
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <FormControlLabel
                    value="getting"
                    onClick={e => {
                      handleRadio('getting');
                    }}
                    control={
                      projectData.level > 1 ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{
                            fontFamily: 'Quicksand',
                            padding: current === 'getting' ? '12px' : '9px'
                          }}
                        />
                      ) : (
                        <Radio color="primary" value="getting" checked={true} />
                      )
                    }
                    label={
                      <span
                        style={{
                          paddingTop: '5px',
                          fontSize: '15px',
                          fontWeight: 700,
                          fontFamily: 'Quicksand'
                        }}>
                        GET STARTED
                      </span>
                    }
                    labelPlacement="right"
                    style={{
                      borderBottom: current === 'getting' && '5px solid #000000',
                      fontFamily: 'Quicksand'
                    }}
                  />
                  <div className={style.dotted}>--------</div>
                </div>

                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{ marginLeft: '-30px' }}>
                  <FormControlLabel
                    value="concept"
                    onClick={e => {
                      handleRadio('concept');
                    }}
                    control={
                      projectData.level > 2 ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{
                            fontFamily: 'Quicksand',
                            padding: current === 'concept' ? '12px' : '9px'
                          }}
                        />
                      ) : (
                        <Radio color="primary" value="concept" checked={current === 'concept'} />
                      )
                    }
                    label={
                      <span
                        style={{
                          paddingTop: '5px',
                          fontSize: '15px',
                          fontWeight: 700,
                          fontFamily: 'Quicksand',
                          color:
                            current != 'concept' && projectData.level < 2 ? '#9D9D9D' : '#000000'
                        }}>
                        CONCEPT BOARD
                      </span>
                    }
                    labelPlacement="right"
                    style={{
                      borderBottom: current === 'concept' && '5px solid #000000'
                    }}
                  />
                  <div className={style.dotted}>--------</div>
                </div>

                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{ marginLeft: '-30px' }}>
                  <FormControlLabel
                    value="review"
                    onClick={e => {
                      handleRadio('review');
                    }}
                    control={
                      projectData.level > 3 ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{
                            fontFamily: 'Quicksand',
                            padding: current === 'review' ? '12px' : '9px'
                          }}
                        />
                      ) : (
                        <Radio color="primary" value="review" checked={current === 'review'} />
                      )
                    }
                    label={
                      <span
                        style={{
                          paddingTop: '5px',
                          fontSize: '15px',
                          fontWeight: 700,
                          fontFamily: 'Quicksand',
                          color:
                            current != 'review' && projectData.level < 3 ? '#9D9D9D' : '#000000'
                        }}>
                        REVIEW CONCEPT
                      </span>
                    }
                    labelPlacement="right"
                    style={{
                      borderBottom: current === 'review' && '5px solid #000000'
                    }}
                  />
                  <div className={style.dotted}>--------</div>
                </div>

                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{ marginLeft: '-30px' }}>
                  <FormControlLabel
                    value="finalise"
                    onClick={e => {
                      handleRadio('finalise');
                    }}
                    control={
                      projectData.level > 4 ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{
                            fontFamily: 'Quicksand',
                            padding: current === 'finalise' ? '12px' : '9px'
                          }}
                        />
                      ) : (
                        <Radio color="primary" value="finalise" checked={current === 'finalise'} />
                      )
                    }
                    label={
                      <span
                        style={{
                          paddingTop: '5px',
                          fontSize: '15px',
                          fontWeight: 700,
                          fontFamily: 'Quicksand',
                          color:
                            current != 'finalise' && projectData.level < 4 ? '#9D9D9D' : '#000000'
                        }}>
                        FINALISE CONCEPT
                      </span>
                    }
                    labelPlacement="right"
                    style={{
                      borderBottom: current === 'finalise' && '5px solid #000000'
                    }}
                  />
                </div>

                <div className="d-flex h-100 ml-5 flex-row justify-content-center align-items-center">
                  <button
                    className="p-2"
                    style={{
                      backgroundColor:
                        (projectData.job === 'COMPLETED' ||
                          projectData.job === 'FINALIZE_CONCEPT') &&
                        current === 'shop'
                          ? 'black'
                          : (projectData.job === 'COMPLETED' ||
                              projectData.job === 'FINALIZE_CONCEPT') &&
                            current !== 'shop' &&
                            !shopAwayDisable
                          ? '#389A4F'
                          : '#DEDEDE'
                    }}
                    onClick={e => {
                      if (
                        projectData.job === 'COMPLETED' ||
                        (projectData.job === 'FINALIZE_CONCEPT' && !shopAwayDisable)
                      ) {
                        setCurrent('shop');
                        handleRadio(e);

                        setLottie(true);
                        setTimeout(() => {
                          setLottie(false);
                          handleCheck('shop');
                        }, 4000);
                      }
                    }}>
                    <p
                      className="m-0"
                      style={{
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: '400'
                      }}>
                      SHOP AWAY
                    </p>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                height: 'auto',
                backgroundColor: '#F5F5F5',
                overflow: 'hidden',
                marginTop: '75px'
              }}>
              <div className="col-12 d-flex p-0 justify-content-center align-items-center">
                <div className="col d-flex flex-row justify-content-center align-items-center mt-4">
                  <FormControlLabel
                    value="getting"
                    onClick={e => {
                      handleRadio('getting');
                    }}
                    control={
                      projectData.level > 1 ? (
                        <FontAwesomeIcon icon={faCheck} style={{ width: '0.5em' }} />
                      ) : (
                        <Radio
                          color="primary"
                          value="getting"
                          checked={true}
                          className={classnames(style.navbarRadioBtn)}
                        />
                      )
                    }
                    label={
                      <p
                        style={{
                          paddingTop: '0px',
                          fontSize: '10px',
                          fontWeight: 600,
                          textAlign: 'center',
                          fontFamily: 'Quicksand'
                        }}>
                        GET STARTED
                      </p>
                    }
                    labelPlacement="bottom"
                  />
                </div>

                <div className="col d-flex flex-row justify-content-center align-items-center mt-4">
                  <FormControlLabel
                    value="concept"
                    onClick={e => {
                      handleRadio('concept');
                    }}
                    control={
                      projectData.level > 2 ? (
                        <FontAwesomeIcon icon={faCheck} style={{ width: '0.5em' }} />
                      ) : (
                        <Radio color="primary" value="concept" checked={current === 'concept'} />
                      )
                    }
                    label={
                      <p
                        style={{
                          paddingTop: '0px',
                          fontSize: '10px',
                          fontWeight: 600,
                          textAlign: 'center',
                          fontFamily: 'Quicksand'
                        }}>
                        CONCEPT BOARD
                      </p>
                    }
                    labelPlacement="bottom"
                  />
                </div>

                <div className="col d-flex flex-row justify-content-center align-items-center mt-4">
                  <FormControlLabel
                    value="review"
                    onClick={e => {
                      handleCheck('review');
                    }}
                    control={
                      projectData.level > 3 ? (
                        <FontAwesomeIcon icon={faCheck} style={{ width: '0.5em' }} />
                      ) : (
                        <Radio
                          color="primary"
                          value="review"
                          checked={current === 'review'}
                          className={classnames(style.navbarRadioBtn)}
                        />
                      )
                    }
                    label={
                      <p
                        style={{
                          paddingTop: '0px',
                          fontSize: '10px',
                          fontWeight: 600,
                          textAlign: 'center',
                          fontFamily: 'Quicksand'
                        }}>
                        REVIEW CONCEPT
                      </p>
                    }
                    labelPlacement="bottom"
                  />
                </div>

                <div className="col d-flex flex-row justify-content-center align-items-center mt-4">
                  <FormControlLabel
                    value="finalise"
                    onClick={e => {
                      handleCheck('finalise');
                    }}
                    control={
                      projectData.level > 4 ? (
                        <FontAwesomeIcon icon={faCheck} style={{ width: '0.5em' }} />
                      ) : (
                        <Radio
                          color="primary"
                          value="finalise"
                          checked={current === 'finalise'}
                          className={classnames(style.navbarRadioBtn)}
                        />
                      )
                    }
                    label={
                      <p
                        style={{
                          paddingTop: '0px',
                          fontSize: '10px',
                          fontWeight: 600,
                          textAlign: 'center',
                          fontFamily: 'Quicksand'
                        }}>
                        FINALISE CONCEPT
                      </p>
                    }
                    labelPlacement="bottom"
                  />
                </div>

                <div className="col-3 d-flex flex-row justify-content-center align-items-center pr-0">
                  <button
                    className="p-2 h-100 w-100"
                    style={{
                      backgroundColor:
                        (projectData.job === 'COMPLETED' ||
                          projectData.job === 'FINALIZE_CONCEPT') &&
                        current === 'shop'
                          ? 'black'
                          : (projectData.job === 'COMPLETED' ||
                              projectData.job === 'FINALIZE_CONCEPT') &&
                            current !== 'shop' &&
                            !shopAwayDisable
                          ? '#389A4F'
                          : '#DEDEDE'
                    }}
                    onClick={e => {
                      if (
                        projectData.job === 'COMPLETED' ||
                        (projectData.job === 'FINALIZE_CONCEPT' && !shopAwayDisable)
                      ) {
                        setCurrent('shop');
                        handleRadio(e);

                        handleCheck('shop');
                      }
                    }}>
                    <p
                      className="m-0"
                      style={{
                        color: 'white',
                        fontSize: '12px',
                        fontWeight: '300'
                      }}>
                      SHOP AWAY
                    </p>
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* statusbar end */}

          {/* page data start */}
          {current === 'getting' && (
            <div className={`row p-0 m-0 ${style.pageContentContainer}`}>
              <div className={`col-12 col-md-4 col-lg-3 col-xl-2 ${style.leftSlidebarContainer}`}>
                {/* curves image */}
                {dimension[0] > 500 && current === 'getting' && (
                  <img
                    style={{
                      position: 'absolute',
                      zIndex: -10,
                      left: -100,
                      top: 120
                    }}
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Group+3156.png"
                    at="curves svg"
                  />
                )}
                {dimension[0] > 500 ? (
                  <div className={style.desktopSidebar}>
                    <div>
                      <img
                        src="https://d3izcfql7lesks.cloudfront.net/Group_2867_y6l11g.png"
                        alt="illustration of keys"
                      />
                      <div
                        onClick={() => {
                          if (id) {
                            history.push({
                              pathname: `/room-profile/${id}`,
                              state: { navbar: true, project_id: id }
                            });
                          } else {
                            history.push('/room-profile');
                          }
                        }}>
                        <h4>Open your</h4>
                        <h4>room profile</h4>
                      </div>
                    </div>
                    <div>
                      <h5>Thanks for completing our brief questionnaire.</h5>
                      <p>Our designers will use your inputs to curate something special for you.</p>
                    </div>
                    <div>
                      <p>
                        Our designer will get back to you within 48 hours. If you have any queries,
                        call us on
                        <u>+91-9176976111</u>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={style.mobileSidebar}>
                    <div className={style.mobileSidebarSec1}>
                      <img
                        src="https://d3izcfql7lesks.cloudfront.net/Group_2867_y6l11g.png"
                        alt="illustration of keys"
                      />
                      <div
                        onClick={() => {
                          if (id) {
                            history.push({
                              pathname: `/room-profile/${id}`,
                              state: { navbar: true, project_id: id }
                            });
                          } else {
                            history.push('/room-profile');
                          }
                        }}>
                        <h4>Open your</h4>
                        <h4>room profile</h4>
                      </div>
                    </div>
                    <div className="text-center">
                      <h5>Thanks for completing our brief questionnaire.</h5>
                      <p>Our designers will use your inputs to curate something special for you.</p>
                    </div>
                    <div className="text-center">
                      <p>
                        Our designer will get back to you within 48 hours. If you have any queries,
                        call us on
                      </p>
                      <u>+91-9176976111</u>
                    </div>
                  </div>
                )}
              </div>
              <div className={`col-12 col-md-8 col-lg-9 col-xl-10 p-0 ${style.rightSideContainer}`}>
                {dimension[0] > 1240 ? (
                  <div className={style.rightSideContentWrapper}>
                    <div
                      className="text-center my-5"
                      style={{ fontSize: '30px', fontWeight: '700' }}>
                      The next steps to your dream room
                    </div>
                    <div className={`row ${style.cardsRowDesktop}`}>
                      {getStartedSteps.map((step, index) => (
                        <div className="col position-relative">
                          <GetStartedCard {...step} key={index} />
                          {index !== 4 && <div className={style.dottedLink}></div>}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <ConceptCarousel />
                )}
              </div>
            </div>
          )}

          {current === 'concept' && (
            <Concept
              page={'CONCEPT_BOARD'}
              projectDataRooms={projectData}
              setProjectDataRooms={setProjectData}
            />
          )}
          {current === 'review' && (
            <Review
              page={'REVIEW_CONCEPT'}
              projectDataRooms={projectData}
              setProjectDataRooms={setProjectData}
              setShopAwayDisable={setShopAwayDisable}
            />
          )}
          {current === 'finalise' && (
            <Review
              page={'FINALIZE_CONCEPT'}
              projectDataRooms={projectData}
              setProjectDataRooms={setProjectData}
              setShopAwayDisable={setShopAwayDisable}
            />
          )}
          {current === 'shop' && <Shop page={'SHOP'} />}
          {/* page data end */}

          <Footer />
        </MuiThemeProvider>
      )}
    </>
  );
};

export default Rooms;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
