import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function AboutusMobileCarousel() {
  return (
    <div>
      <Carousel pause={false} controls={false}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.1.png'}
            alt="First slide"
            style={{ objectFit: 'contain', height: '100px', width: '100px' }}
          />
          <div className="p-4 text-center">
            <h2> Passion</h2>
            <p style={{ fontSize: '24px', fontWeight: '600' }}>
              Do what you love with an abundance of enthusiasm and obsession.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.2.png'}
            alt="First slide"
            style={{ objectFit: 'contain', height: '100px', width: '100px' }}
          />
          <div className="p-4 text-center">
            <h2> Empathy</h2>
            <p style={{ fontSize: '24px', fontWeight: '600' }}>
              Do what you love with an abundance of enthusiasm and obsession.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.3.png'}
            alt="First slide"
            style={{ objectFit: 'contain', height: '100px', width: '100px' }}
          />
          <div className="p-4 text-center">
            <h2> Excellence</h2>
            <p style={{ fontSize: '24px', fontWeight: '600' }}>
              You are as good as your consistency. So never settle
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.4.png'}
            alt="First slide"
            style={{ objectFit: 'contain', height: '100px', width: '100px' }}
          />
          <div className="p-4 text-center">
            <h2> Collaboration</h2>
            <p style={{ fontSize: '24px', fontWeight: '600' }}>
              Connect with diverse people and co-create the future.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.5.png'}
            alt="First slide"
            style={{ objectFit: 'contain', height: '100px', width: '100px' }}
          />
          <div className="p-4 text-center">
            <h2> Impact</h2>
            <p style={{ fontSize: '24px', fontWeight: '600' }}>
              Anything you do, big or small must make a difference.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.6.png'}
            alt="First slide"
            style={{ objectFit: 'contain', height: '100px', width: '100px' }}
          />
          <div className="p-4 text-center">
            <h2> Curiosity</h2>
            <p style={{ fontSize: '24px', fontWeight: '600' }}>
              Be as excited as a child to learn new things.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default AboutusMobileCarousel;
