import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import './QueryForm.css';
import * as Icon from 'react-bootstrap-icons';
import classnames from 'classnames';
import customerApiInstance from '../../utils/CustomerApiInstance';
import Snackbar from '@material-ui/core/Snackbar';
import { Button, Spinner } from 'react-bootstrap';
import Arrow from '../../assets/arrow.png';
import FaqAccordion from './FaqAccordion';
import axios from 'axios';
import ConsoleHelper from '../../utils/ConsoleHelper';

const QueryForm = ({ history, auth }) => {
  const dimension = useMediaQuery();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState('');
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [expanded, setExpanded] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [allFaqData, setAllFaqData] = useState([]);
  const [faqDataSearch, setfaqDataSearch] = useState([]);
  const [queryArray, setQueryArray] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/v1/user/allfaq`, {
        params: {
          search: ''
        }
      })
      .then(function(response) {
        ConsoleHelper(response.data.data);
        setAllFaqData(response.data.data);
        let tab = [];
        let query = [];
        response.data.data.forEach((element, i) => {
          tab.push({ queryHeader: element.queryHeader, _id: element._id });
          query.push({ type: element.queryHeader, id: element._id });
          if (i == 0) {
            setActiveTab(element._id);
            setFaqData(element.subheadings);
          }
        });
        setTabs(tab);
        setQueryArray(query);
      })
      .catch(function(error) {
        ConsoleHelper(error);
      });
  }, []);

  const handleChangeActive = activeTab => {
    window.scrollTo(0, 0);
    setActiveTab(activeTab);
    setExpanded(null);
    allFaqData.forEach((element, i) => {
      if (element._id == activeTab) {
        setFaqData(element.subheadings);
      }
    });
  };

  const handleChangeSearch = value => {
    setSearchInput(value);
    let faqDataAfterSearch = [];
    if (value != '') {
      allFaqData.forEach((arr0, i) => {
        arr0.subheadings.forEach((arr1, j) => {
          if (arr1.queryTitle.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
            let tempObj = {};
            tempObj._id = arr1._id;
            tempObj.queryTitle = arr1.queryTitle;
            tempObj.qa = arr1.qa;
            faqDataAfterSearch.push(tempObj);
          }
        });
      });
    }

    setfaqDataSearch(faqDataAfterSearch);
  };

  const handleChangeInput = e => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === 'email') {
      setInputs({
        ...inputs,
        [name]: value
      });
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(value) === false) {
        setError('email');
        setErrorMessage('Please enter a valid email');
      } else {
        setError('');
      }
    } else {
      setInputs({
        ...inputs,
        [name]: value
      });
    }
  };

  const submitQuery = async () => {
    if (inputs && !inputs.name) {
      setError('name');
      setErrorMessage('Please enter the name');
    } else if (inputs && !inputs.email) {
      setError('email');
      setErrorMessage('Please enter a valid email');
    } else if (inputs && !inputs.query) {
      setError('query');
      setErrorMessage('Please enter the query');
    } else if (inputs && !inputs.queryType) {
      setError('queryType');
      setErrorMessage('Please enter the queryType');
    } else {
      setError('');
      setErrorMessage('');
    }
    setLoading(true);
    try {
      if (inputs.queryType && inputs.query) {
        inputs.query = inputs.queryType + ' : ' + inputs.query;
      }
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/query`,
        inputs
      );
      if (res.data.error) {
        setSnack(true);
        setSnackMessage('Ticket creation failed');
      } else {
        setSnack(true);
        setSnackMessage('Ticket created successfully');
        setInputs('');
      }
    } catch (e) {
      ConsoleHelper('error', e);
    }
    setLoading(false);
    setError('');
    setInputs('');
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header />
      <div className={classnames('container-fluid p-0 , mainContainer')}>
        {dimension[0] > 600 ? (
          <div className={classnames('bannerContainer')}>
            <div className="row w-100 h-100">
              <div
                className={classnames(
                  'bannerContainerCol1',
                  'col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'
                )}>
                <h4>Help Center / FAQs</h4>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center "></div>
            </div>
          </div>
        ) : (
          <div className={classnames('mobBannerContainer')}>
            <h2 className="p-4 text-center">Help Center / FAQs</h2>
            <img
              style={{ width: '100%' }}
              src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/faqbanner.png"
              alt="banner"
            />
            <div className="d-flex justify-content-center pt-4">
              <div
                className="d-flex justify-content-around mt-3"
                style={{
                  borderBottom: '0.5px solid #000000',
                  width: '80%'
                }}>
                <Icon.Search />
                <input
                  className={'searchInput'}
                  style={{
                    backgroundColor: '#F5F0EC',
                    color: '#000000',
                    marginBottom: '8px'
                  }}
                  type="text"
                  placeholder="Search"
                  onChange={e => {
                    handleChangeSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={classnames('d-flex flex-wrap p-4 pb-5')}>
              {tabs.map((tab, index) => (
                <button
                  className={classnames(activeTab === tab._id ? 'mobButtonActive' : 'mobButton')}
                  onClick={() => {
                    handleChangeActive(tab._id);
                  }}>
                  {tab.queryHeader}
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="d-flex">
          {dimension[0] > 600 && (
            <div className={classnames('sticky-top, sideBar')}>
              <div
                className="d-flex justify-content-between mt-3"
                style={{
                  borderBottom: '0.5px solid #707070',
                  marginBottom: '30px'
                }}>
                <Icon.Search />
                <input
                  className={'searchInput'}
                  type="text"
                  placeholder="Search"
                  onChange={e => {
                    handleChangeSearch(e.target.value);
                  }}
                />
              </div>
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                    cursor: 'pointer',
                    justifyContent: 'space-between'
                  }}
                  onClick={() => {
                    handleChangeActive(tab._id);
                  }}>
                  <h6 style={{ fontSize: '1vw' }} className={activeTab === tab._id && 'active'}>
                    {tab.queryHeader}
                  </h6>
                  {activeTab === tab._id && (
                    <img
                      style={{
                        objectFit: 'contain',
                        alignSelf: 'flex-start'
                      }}
                      src={Arrow}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          <div className={classnames('faqContainer')}>
            {faqDataSearch.length > 0 ? (
              faqDataSearch.map((data, index) => (
                <div>
                  <h4 className="p-2" style={{ marginTop: '4rem' }}>
                    {data.queryTitle}
                  </h4>
                  {data.qa?.map((questions, i) => (
                    <FaqAccordion
                      key={index + '-faq-' + i}
                      id={index + '-faq-' + i}
                      open={false}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      header={questions.question}
                      description={questions.answer}
                    />
                  ))}
                </div>
              ))
            ) : faqDataSearch.length == 0 && searchInput != '' ? (
              <div style={{ textAlign: dimension[0] > 600 ? 'left' : 'center' }}>
                <h6>
                  Search results for: <strong>{searchInput}</strong>
                </h6>
                <h2 className="mt-4">No Results</h2>
                <h6>
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      document.querySelector('#formFaq').scrollIntoView();
                    }}>
                    Click Here
                  </span>
                  and get in touch with us about your question
                </h6>
              </div>
            ) : (
              faqData?.map((data, index) => (
                <div>
                  <h4 className="p-2" style={{ marginTop: '4rem' }}>
                    {data.queryTitle}
                  </h4>
                  {data.qa?.map((questions, i) => (
                    <FaqAccordion
                      key={index + '-faq-' + i}
                      id={index + '-faq-' + i}
                      open={false}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      header={questions.question}
                      description={questions.answer}
                    />
                  ))}
                </div>
              ))
            )}
          </div>
        </div>
        <div id="formFaq" className={classnames('row', 'container1')}>
          <div
            className={classnames(
              'back1',
              'col-sm-12  col-md-12 col-lg-4 col-xl-4 flex-column justify-content-center align-items-center'
            )}>
            <div className="d-flex flex-column justify-content-center align-items-center text-center queryImageContainer">
              <img
                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/common-images/Group+3120.png"
                alt=""
                style={{ width: dimension[0] > 600 ? '35%' : '70%' }}
              />
              <div className={classnames('queryText')}>
                <h1>No Answer?</h1>
                <p>
                  None of our answers above answer your question?
                  <br />
                  Fill the below form with your question and we will get back to you shortly
                </p>
              </div>
            </div>
          </div>
          <div
            className={classnames(
              'col-sm-12  col-md-12 col-lg-8 col-xl-8 d-flex justify-content-center align-items-center'
            )}>
            <div className={classnames('queryForm')}>
              <div className={classnames('text1')}>
                Send your Query and we will get back to you with an answer
              </div>
              <div
                className={classnames(
                  'text1',
                  'mt-2 d-flex justify-content-start align-items-start flex-colum'
                )}>
                Enter all fields
              </div>
              <form className="mt-2">
                <input
                  className={classnames('formInputs', 'mt-2 p-2')}
                  name="name"
                  placeholder="Name"
                  required
                  value={inputs.name || ''}
                  onChange={handleChangeInput}
                />
                <div>
                  {error === 'name' && (
                    <span style={{ color: 'red', fontSize: '11px' }}>{errorMessage}</span>
                  )}
                </div>
                <input
                  className={classnames('formInputs', 'mt-2 p-2')}
                  name="email"
                  placeholder="Email address"
                  required
                  value={inputs.email || ''}
                  onChange={handleChangeInput}
                />
                <div>
                  {error === 'email' && (
                    <span style={{ color: 'red', fontSize: '12px' }}>{errorMessage}</span>
                  )}
                </div>
                <select
                  className={classnames('formInputs1', 'mt-2')}
                  name="queryType"
                  value={inputs.queryType || ''}
                  onChange={handleChangeInput}>
                  <option>Select query type</option>
                  {queryArray.map((query, i) => (
                    <option value={query.type}>{query.type}</option>
                  ))}
                </select>
                <textarea
                  rows="5"
                  className={classnames('formInputs', 'mt-2 p-2')}
                  name="query"
                  placeholder="Query"
                  required
                  value={inputs.query || ''}
                  onChange={handleChangeInput}></textarea>
                <div>
                  {error === 'query' && (
                    <span style={{ color: 'red', fontSize: '11px' }}>{errorMessage}</span>
                  )}
                </div>
                <div
                  className={classnames('d-flex justify-content-end align-items-end flex-colum')}
                  style={{ paddingRight: '4px' }}>
                  <Button
                    variant="dark"
                    disabled={error !== '' || loading}
                    className="button1"
                    type="submit"
                    onClick={() => {
                      submitQuery();
                    }}>
                    {loading && <Spinner as="span" size="sm" role="status" animation="border" />}
                    send
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default QueryForm;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
