import React, { useEffect, useState, useLayoutEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Giftcard.module.css';
import classnames from 'classnames';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  hideBorder: {
    '&.MuiAccordionSummary-content.Mui-expanded': {
      flexGrow: '0 !important'
    },
    '&.MuiExpansionPanel-root:before': {
      display: 'none'
    }
  }
});
const AccordionSummary = withStyles({
  content: {
    flexGrow: 0
  },
  root: {
    backgroundColor: 'white'
  }
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    backgroundColor: 'white'
  }
})(MuiAccordionDetails);

const Giftcards = ({ history }) => {
  const dimenions = useMediaQuery();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const card = [
    {
      title: 'P&M Exclusive',
      price: 'Rs.7,000/-',
      button: 'Gift an Exclusive',
      text: 'Elevate your space with a theme that reflects your personality.',
      points: [
        '1:1 online consultation with our designers',
        'Collaborate on 3 initial ideas delivered within 2 days',
        'Personalised design concept with hand-curated design picks',
        'Multiple revisions and access to the Design Team',
        'Online shopping list',
        'Purchasing service, access to hundreds of brands'
      ],
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/gift-icon.png'
    },
    {
      title: 'P&M Enhanced',
      price: 'Rs.10,000/-',
      button: 'Gift an Enhanced',
      text: 'Give your blank canvas the VIP treatment with a detailed transformation.',
      points: [
        '1:1 online consultation with our designers',
        'Collaborate on 3 initial ideas delivered within 2 days',
        'Personalised design concept with hand-curated design',
        'Use our online tools to perfect your layout',
        'Umlimited revisions and access to the Design Team',
        'Online shopping list',
        'Purchasing service, access to hundreds of brands',
        'Personal Ordering Team',
        'Shoppable 3D views of layout',
        'Custom floor plan'
      ],
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2802.png'
    },
    {
      title: 'Merch Credit',
      price: '',
      button: 'Gift an Shop Credit',
      text: 'Anyone shopping on Pixel & Mortar',
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+280211.png'
    }
  ];
  const classes = useStyles();

  return (
    <>
      <Header />
      {dimenions[0] > 500 && (
        <div
          className={classnames(
            style.container,
            'd-flex flex-column text-left justify-content-center align-items-start'
          )}
          style={{ marginTop: '3.8%' }}>
          <h1>
            Give your dearest ones<br></br>
            the gift of good design.
          </h1>
          <span>
            Everyone deserves to fall in love with their home, their safe haven, <br></br> so show
            someone that you care by <br></br> gifting them a P&M design.
          </span>
        </div>
      )}
      {dimenions[0] < 500 && (
        <div
          className={classnames(
            style.container,
            'd-flex flex-column justify-content-center align-items-start text-left'
          )}>
          <h1>
            Give your dearest ones<br></br>
            the gift of good design.
          </h1>
          <span>
            Everyone deserves to fall in love with their home, their safe haven, <br></br> so show
            someone that you care by gifting them a P&M design.
          </span>
        </div>
      )}
      <div className="d-flex flex-column text-left justify-content-center align-items-center mt-5">
        <h2 className={classnames('mt-5', style.tgf)}>Select your Gift package</h2>
        <img
          style={{
            marginTop: '-10px',
            marginLeft: '14%',
            marginBottom: '3%',
            width: '13%',
            zIndex: -10
          }}
          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/Path+1210.svg'}
          alt=""
        />
        <br></br>
        {dimenions[0] > 500 && (
          <div className="container">
            <div className="row mt-5">
              {card.map((data, index) => (
                <div
                  className={classnames(
                    'col d-flex flex-column text-left justify-content-start align-items-center',
                    style.card
                  )}>
                  <img src={data.image} style={{ transform: 'translateY(-130%)' }} alt="card " />
                  <h4 style={{ fontWeight: 600 }}>{data.title}</h4>
                  <h5 style={{ fontWeight: 200, height: '30px' }}>{data.price} </h5>
                  <span
                    style={{
                      textAlign: 'center',
                      marginTop: '50px',
                      height: '10vh'
                    }}>
                    {data.text}
                  </span>
                  <button
                    className={style.giftButton}
                    onClick={() => history.push('/gift-checkout')}>
                    {data.button}
                  </button>
                  <Accordion
                    style={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      marginTop: '5px',
                      display: index === 2 && 'none'
                    }}>
                    <AccordionSummary
                      style={{ flexGrow: 'none' }}
                      className={classes.hideBorder}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography>More details</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ widthL: '100px' }}>
                      <div className="row">
                        {data?.points?.map(points => (
                          <div
                            className="col-12 d-flex flex-row"
                            style={{
                              borderBottom: '1px solid rgba(0,0,0,0.2)',
                              marginTop: '10px',
                              marginBottom: '0px',
                              fontSize: '14px',
                              paddingBottom: '20px',
                              fontWeight: 800
                            }}>
                            {points}
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </div>
          </div>
        )}
        {dimenions[0] < 500 && (
          <div>
            {card.map((data, index) => (
              <div
                className={classnames(
                  'col d-flex flex-column text-left justify-content-start align-items-center'
                )}>
                <div className={classnames('row mt-5', style.card)}>
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2947.png"
                    alt="card "
                  />

                  <h4>{data.title}</h4>

                  <h5 style={{ fontWeight: 200, marginLeft: '5px' }}>{data.price}</h5>
                  <span
                    style={{
                      textAlign: 'center',
                      marginTop: '50px',
                      marginBottom: '30px'
                    }}>
                    {data.text}
                  </span>
                  <button
                    className={style.giftButton}
                    style={{ width: '100%' }}
                    onClick={() => history.push('/gift-checkout')}>
                    Gift an Exclusive
                  </button>
                  <Accordion
                    style={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      marginTop: '20px',
                      display: index === 2 && 'none'
                    }}>
                    <AccordionSummary
                      className={classes.hideBorder}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography>More details</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ widthL: '100px' }}>
                      <div className="row">
                        {data?.points?.map(points => (
                          <div
                            className="col-12 d-flex flex-row"
                            style={{
                              borderBottom: '1px solid rgba(0,0,0,0.2)',
                              marginTop: '10px',
                              marginBottom: '0px',
                              fontSize: '14px',
                              paddingBottom: '20px',
                              fontWeight: 800
                            }}>
                            {points}
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            ))}
          </div>
        )}
        <div
          className="container d-flex flex-column text-center justify-content-center align-items-center mt-5"
          style={{
            height: '50vh',
            backgroundColor: '#F5F0EC',
            maxWidth: '100%'
          }}>
          <img src={'https://d3izcfql7lesks.cloudfront.net/Group_2029_kx94h1.png'} alt="" />
          <span className="mt-5">
            <p className="mb-0">Instant Email</p>
            <br></br>
            Running short on time? Send a gift card via email <br></br> immediately or schedule it
            for a special day.
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Giftcards;
function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
