import React from 'react';
import style from './Footer.module.css';
import qrCode from '../../assets/footer/qrcode1.png';
import facebookLogo from '../../assets/footer/facebook.png';
import instagramLogo from '../../assets/footer/instagram.png';
import linkedinLogo from '../../assets/footer/linkedin.png';
import pinterestLogo from '../../assets/footer/pintrest.png';
import { NavLink } from 'react-router-dom';

const shop = [
  { text: 'Curated Design', route: '/curated-designs' },
  { text: 'Hire A Designer', route: '/hire-designer' },
  { text: 'Shop Products', route: '/shop' }
];
const about = [
  { text: 'About Pixel & Mortar', route: '/aboutus' },
  { text: 'Our Blog', url: 'https://www.designpechu.com/' },
  // { text: 'Refer and Earn', route: '/refer' },
  { text: 'Help', route: '/query' },
  { text: 'Terms & Conditions', route: '/terms' },
  { text: 'Privacy Policy', route: '/privacy' }
];

const aboutMob = [
  { text: 'About Pixel & Mortar', route: '/aboutus' },
  { text: 'Our Blog', url: 'https://www.designpechu.com/' },
  // { text: 'Refer and Earn', route: '/refer' },
  { text: 'Help', route: '/query' }
];

const contact = [
  { text: '+91-9176976111', route: '' },
  { text: 'hello@pixelandmortar.com', route: '' }
];

const logos = [
  { logo: facebookLogo, link: 'https://www.facebook.com/pixelandmortar' },
  { logo: instagramLogo, link: 'https://instagram.com/pixel.and.mortar?igshid=YmMyMTA2M2Y=' },
  { logo: linkedinLogo, link: 'https://in.linkedin.com/company/pixel-and-mortar' },
  { logo: pinterestLogo, link: 'https://pin.it/26qbzmu' }
];

function ListComp({ heading, listArr }) {
  return (
      <div>
          <p style={{ fontWeight: '600', fontSize: '16px', marginBottom: '0.4rem' }}>{heading}</p>
          {listArr?.map((elem, index) => (
              <div key={index}>
                  {elem.route ? (
                      <NavLink
                          to={elem.route}
                          style={{
                              fontWeight: '500',
                              fontSize: '13px',
                              color: '#DFD9D6',
                              cursor: 'pointer',
                              textDecoration: 'none'
                          }}>
                          {elem.text}
                      </NavLink>
                  ) : (
                      <a
                          href={elem.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                              fontWeight: '500',
                              fontSize: '13px',
                              color: '#DFD9D6',
                              cursor: 'pointer',
                              textDecoration: 'none'
                          }}>
                          {elem.text}
                      </a>
                  )}
              </div>
          ))}
      </div>
  );
}

function Footer() {
  return (
    <div className={style.footerContainer}>
      <div className={style.footerInnerContainer}>
        <div className={style.footerLeft}>
          <img
            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/Pixel+%26+Mortar+White.png"
            className={style.footerLogo}
            style={{ width: '190px', height: '70px' }}
          />
          <div style={{ fontSize: '30px', fontWeight: '300' }}>Room styling,</div>
          <div
            style={{
              fontSize: '30px',
              fontWeight: '600',
              lineHeight: '2rem',
              marginBottom: '1.5rem'
            }}>
            Customised for you
          </div>
          <div className={style.qrContainer}>
            <div style={{ display: 'grid', placeContent: 'center' }}>
              <div>Coming Soon</div>
              <div>pixel & mortar app</div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={qrCode} style={{ height: '90%' }} />
            </div>
          </div>
        </div>
        <div className={style.footerRight}>
          <ListComp heading={'SHOP'} listArr={shop} />
          <ListComp heading={'ABOUT'} listArr={about} />
          <ListComp heading={'CONTACT US'} listArr={contact} />
        </div>
        {/* Footer section for mobile*/}
        <div className={style.mobileFooterTop}>
          <ListComp heading={'SHOP'} listArr={shop} />
          <ListComp heading={'ABOUT'} listArr={aboutMob} />
        </div>
        <div className={style.mobileFooterBottom}>
          <ListComp heading={'CONTACT US'} listArr={contact} />
        </div>
        <div className={style.socialMediaContMob}>
          {logos.map((logo, index) => (
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              <img key={index} src={logo.logo} className="mr-2 ml-2 " />
            </a>
          ))}
        </div>
        <div className={style.socialMediaContMob}>
          <NavLink
            to={'/privacy'}
            className="mr-2"
            style={{
              fontWeight: '500',
              fontSize: '13px',
              color: '#DFD9D6',
              cursor: 'pointer',
              textDecoration: 'none'
            }}>
            Privacy Policy
          </NavLink>
          <NavLink
            to={'/terms'}
            className="ml-2"
            style={{
              fontWeight: '500',
              fontSize: '13px',
              color: '#DFD9D6',
              cursor: 'pointer',
              textDecoration: 'none'
            }}>
            Terms and Conditions
          </NavLink>
        </div>

        {/*Footer section for mobile ends*/}
      </div>
      <div className={style.copyRightTxt} style={{ fontSize: '15px' }}>
        © 2023 Pixel & Mortar Interior Services Private Limited. All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
