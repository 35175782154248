import React from 'react';
import style from './Rooms.module.css';
import Carousel from 'react-bootstrap/Carousel';
import carouselImage1 from '../../assets/images/ConceptCarousel/Carousel1.png';
import carouselImage2 from '../../assets/images/ConceptCarousel/Carousel2.png';
import carouselImage3 from '../../assets/images/ConceptCarousel/Carousel3.png';
import carouselImage4 from '../../assets/images/ConceptCarousel/Carousel4.png';
import carouselImage5 from '../../assets/images/ConceptCarousel/Carousel5.png';

const getStartedSteps = [
  {
    title: 'GET STARTED',
    image: carouselImage1,
    stepCount: 0,
    className: 'mobileCarouselImage1',
    text: 'Sit back while our designer getting cracking on your project.'
  },
  {
    title: 'CONCEPT BOARD',
    image: carouselImage2,
    stepCount: 1,
    className: 'mobileCarouselImage2',
    text:
      'Here, you will find 3 concept mood boards to toggle between and react on your favorite products. This will let our designers get closer to your tastes and liking.'
  },
  {
    title: 'REVIEW CONCEPT',
    image: carouselImage3,
    stepCount: 2,
    className: 'mobileCarouselImage3',
    text:
      'Based on your inputs, the designer puts together a moodboard. Now you can toggle between the alternatives that fits your taste and budget.'
  },
  {
    title: 'FINALISE CONCEPT',
    image: carouselImage4,
    stepCount: 3,
    className: 'mobileCarouselImage4',
    text:
      'Based on your choices, the finalize moodboard will be ready to shop away. Make sure you click on the product to see how it looks in AR.'
  },
  {
    title: 'SHOP',
    image: carouselImage5,
    stepCount: 4,
    className: 'mobileCarouselImage5',
    text: '...AND SHOP AWAY your favourite products!!!!'
  }
];

function ConceptCarousel() {
  return (
    <Carousel
      variant="dark"
      interval={null}
      controls={false}
      className={`p-0 ${style.conceptCarouselContainer}`}>
      {getStartedSteps.map((step, index) => {
        return (
          <Carousel.Item key={index}>
            <div className={`${style.conceptCarouselItem} ${style.firstCarousel}`}>
              <div className={style.stepCountContainerMobile}>
                <div
                  className={
                    step.stepCount === 0
                      ? `${style.firstStep} ${style.stepCountCircle}`
                      : step.stepCount === 4
                      ? `${style.lastStep} ${style.stepCountCircle}`
                      : style.stepCountCircless
                  }>
                  {step.stepCount === 0 ? (
                    <div className={style.activeStepCircle} />
                  ) : (
                    <span className={style.numberCount}>{step.stepCount}</span>
                  )}
                </div>
                <h5>{step.title}</h5>
              </div>
              <div className={`${style.carouselCardContainer} ${step.className}`}>
                <div className={style.carouselCardImageContainer}>
                  <img src={step.image} />
                </div>
                <p className="p-3">{step.text}</p>
              </div>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default ConceptCarousel;
