import React, { useEffect, useState, useLayoutEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import style from './GiftCheckout.module.css';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: 'Quicksand',
    fontWeight: 900
  },
  accordContent: {
    fontSize: 15,
    fontFamily: 'Quicksand'
  },
  accordContent_800: {
    fontWeight: 700,
    fontSize: 15,
    margin: '10%',
    paddingTop: '5%',

    fontFamily: 'Quicksand'
  },
  accordContent_credit: {
    fontWeight: 700,
    fontSize: 15,
    paddingTop: '5%',

    fontFamily: 'Quicksand'
  }
}));

const GiftCheckout = ({ history }) => {
  const classes = useStyles();
  const dimenions = useMediaQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: '#F7F7F7' }}>
      <Header />
      <div className="container" style={{ marginTop: '1vh' }}>
        <div style={{ paddingTop: '10%' }}></div>
        <p
          style={{ cursor: 'pointer', marginBottom: '0px', marginTop: '1%' }}
          onClick={() => history.push('/gifts')}>
          <img
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/Group+2947.svg'}
            alt=""
          />{' '}
          Back to gifts
        </p>
        <br></br>
        {dimenions[0] > 500 && (
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-8">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  style={{ backgroundColor: '#F5F0EC', border: 'none' }}
                  id="panel1a-header">
                  <Typography className={classes.heading}>
                    1 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Delivery Method
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className="d-flex flex-column justify-content-center align-items-start"
                  style={{ height: '20vh', backgroundColor: '#fff' }}>
                  <Typography className={classes.accordContent}>
                    <br></br>
                    Instant Email<br></br>
                    Send a gift card via email immediately<br></br>
                    or schedule it for a special day.
                  </Typography>
                  <div className=" row w-100 d-flex mb-4 justify-content-end align-items-end">
                    <button className={style.nextButton} style={{ float: 'right' }}>
                      {' '}
                      Next
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  style={{ backgroundColor: '#F5F0EC', border: 'none' }}
                  id="panel1a-header">
                  <Typography className={classes.heading}>
                    2 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Store Credit
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className="d-flex flex-column justify-content-center align-items-start"
                  style={{ height: '20vh', padding: '50px', backgroundColor: '#fff' }}>
                  <Typography className={classes.accordContent_credit}>
                    Gift some spending cash. (optional)
                  </Typography>
                  <select style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <option>Select Amount</option>
                  </select>
                  <div className=" row w-100 d-flex justify-content-end align-items-end">
                    <button
                      className={style.nextButton}
                      style={{ float: 'right', marginBottom: '5%' }}>
                      {' '}
                      Next
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  style={{ backgroundColor: '#F5F0EC', border: 'none' }}
                  id="panel1a-header">
                  <Typography className={classes.heading}>
                    3 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Personalise
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className="d-flex flex-column justify-content-center align-items-start"
                  style={{ height: 'auto', padding: '50px', backgroundColor: '#fff' }}>
                  <input
                    type="text"
                    className={style.personaliseInput}
                    placeholder="Who’s the recipient?"
                  />
                  <input
                    type="text"
                    className={style.personaliseInput}
                    placeholder="Recipient email address?"
                  />
                  <input
                    type="text"
                    className={style.personaliseInput}
                    placeholder="Hope you enjoy this P&M Gift Card!"
                  />
                  <div
                    className="d-flex flex-row justify-content-between align-items-center w-100"
                    style={{
                      backgroundColor: '#F5F0EC',
                      margin: '20px',
                      height: '50px',
                      padding: '10px'
                    }}>
                    <h6>Schedule it for a special day </h6>
                    <div className="d-flex justify-content-around aligin-items-center">
                      <select style={{ color: '#000' }}>
                        <option>6</option>
                      </select>
                      <select style={{ width: '120px', color: '#000' }}>
                        <option>January</option>
                      </select>
                      <select style={{ color: '#000' }}>
                        <option>2021</option>
                      </select>
                    </div>
                  </div>
                  <input
                    type="text"
                    className={style.personaliseInput}
                    placeholder="Giver’s Name"
                  />
                  <input
                    type="text"
                    className={style.personaliseInput}
                    placeholder="Giver’s email address"
                  />
                  <input
                    type="text"
                    className={style.personaliseInput}
                    placeholder="Giver’s Phone number"
                  />
                  <div className=" row w-100 d-flex ml-3 justify-content-end align-items-end">
                    <button className={style.nextButton} style={{ float: 'right' }}>
                      {' '}
                      Next
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  style={{ backgroundColor: '#F5F0EC', border: 'none' }}
                  id="panel1a-header">
                  <Typography className={classes.heading}>
                    4 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Payment
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className="d-flex flex-column justify-content-center align-items-start"
                  style={{
                    height: '30vh',
                    padding: '50px',
                    backgroundColor: '#fff'
                  }}></AccordionDetails>
              </Accordion>
            </div>
            <div className="col">
              <div className={style.summaryContainer}>
                <div
                  className="d-flex justify-content-start p-3 align-items-center"
                  style={{ borderBottom: '2px solid rgba(0,0,0,0.1)' }}>
                  <h5>Order Summary</h5>
                </div>
                <div className="d-flex mt-4 flex-column text-left justify-content-start align-items-center">
                  <div className="row justify-content-between w-80">
                    <p style={{ textAlign: 'left', fontWeight: 500 }}>Sub Total</p>
                    <p>1,20,000 </p>
                  </div>
                  <div className={style.divider} />
                </div>
                <div className="d-flex flex-column text-left justify-content-start align-items-center">
                  <div className="row justify-content-between w-80">
                    <p style={{ textAlign: 'left', fontWeight: 500 }}>P&M Credits</p>
                    <p>20,000 </p>
                  </div>
                  <div className={style.divider} />
                </div>
                <div className="d-flex flex-column text-left justify-content-start align-items-center">
                  <div className="row justify-content-between w-80">
                    <p style={{ textAlign: 'left', fontWeight: 500 }}>Promotional Discount</p>
                    <p>10,000 </p>
                  </div>
                  <div className={style.divider} />
                </div>
                <div className="d-flex flex-column text-left justify-content-start align-items-center">
                  <div className="row justify-content-between w-80">
                    <p style={{ textAlign: 'left', fontWeight: 800 }}>Total</p>
                    <p style={{ fontWeight: 800 }}>10,000 </p>
                  </div>
                </div>
                <div className={'d-flex justify-content-center mt-2 align-items-center'}>
                  <button className={style.continueButton}>Continue</button>
                </div>
                {/* <div
                  className={
                    'd-flex justify-content-center mt-2 align-items-center'
                  }>
                  <p style={{ fontSize: '12px' }}>
                    Do you have promo code?{' '}
                    <span
                      style={{ color: 'blue', textDecoration: 'underline' }}>
                      Add it here
                    </span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        )}
        {dimenions[0] < 500 && (
          <div>
            <div className="row">
              <div className="col-12">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{ backgroundColor: '#F5F0EC', border: 'none' }}
                    id="panel1a-header">
                    <Typography className={classes.heading}>
                      1 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Delivery
                      Method
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="d-flex flex-column justify-content-center align-items-start"
                    style={{ height: '20vh' }}>
                    <Typography className={classes.accordContent}>
                      Instant Email<br></br>
                      Send a gift card via email immediately<br></br>
                      or schedule it for a special day.
                    </Typography>
                    <div className=" row w-100 d-flex justify-content-end align-items-end">
                      <button className={style.nextButton} style={{ float: 'right' }}>
                        {' '}
                        Next
                      </button>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{ backgroundColor: '#F5F0EC', border: 'none' }}
                    id="panel1a-header">
                    <Typography className={classes.heading}>
                      2 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Store Credit
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="d-flex flex-column justify-content-center align-items-start"
                    style={{ height: '30vh', padding: '20px' }}>
                    <Typography className={classes.accordContent_credit} style={{ margin: '10px' }}>
                      Gift some spending cash. (optional)
                    </Typography>
                    <select style={{ marginTop: '20px', marginBottom: '20px' }}>
                      <option>Select Amount</option>
                    </select>
                    <div className=" row w-100 d-flex justify-content-end align-items-end">
                      <button className={style.nextButton} style={{ float: 'right' }}>
                        {' '}
                        Next
                      </button>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{ backgroundColor: '#F5F0EC', border: 'none' }}
                    id="panel1a-header">
                    <Typography className={classes.heading}>
                      3 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Personalise
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="d-flex flex-column justify-content-center align-items-start"
                    style={{ height: 'auto', padding: '50px' }}>
                    <input
                      type="text"
                      className={style.personaliseInput}
                      placeholder="Who’s the recipient?"
                    />
                    <input
                      type="text"
                      className={style.personaliseInput}
                      placeholder="Recipient email address?"
                    />
                    <input
                      type="text"
                      className={style.personaliseInput}
                      placeholder="Hope you enjoy this P&M Gift Card!"
                    />
                    <div
                      className="d-flex flex-row justify-content-between align-items-center w-100"
                      style={{
                        backgroundColor: '#F5F0EC',
                        margin: '20px',
                        height: '50px',
                        padding: '10px'
                      }}>
                      <h6>Schedule it for a special day </h6>
                      <div className="d-flex justify-content-around aligin-items-center">
                        <select>
                          <option>6</option>
                        </select>
                        <select>
                          <option>January</option>
                        </select>
                        <select>
                          <option>2021</option>
                        </select>
                      </div>
                    </div>
                    <input
                      type="text"
                      className={style.personaliseInput}
                      placeholder="Giver’s Name"
                    />
                    <input
                      type="text"
                      className={style.personaliseInput}
                      placeholder="Giver’s email address"
                    />
                    <input
                      type="text"
                      className={style.personaliseInput}
                      placeholder="Giver’s Phone number"
                    />
                    <div className=" row w-100 d-flex justify-content-end align-items-end">
                      <button className={style.nextButton} style={{ float: 'right' }}>
                        {' '}
                        Next
                      </button>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{ backgroundColor: '#F5F0EC', border: 'none' }}
                    id="panel1a-header">
                    <Typography className={classes.heading}>
                      4 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Payment
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="d-flex flex-column justify-content-center align-items-start"
                    style={{
                      height: '30vh',
                      padding: '50px'
                    }}></AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <div className={style.summaryContainer}>
                  <div
                    className="d-flex justify-content-start p-3 align-items-center"
                    style={{ borderBottom: '2px solid rgba(0,0,0,0.1)' }}>
                    <h5>Order Summary</h5>
                  </div>
                  <div className="d-flex mt-5 flex-column text-left justify-content-start align-items-center">
                    <div className="row justify-content-between w-80">
                      <p style={{ textAlign: 'left', fontWeight: 500 }}>Sub Total</p>
                      <p>1,20,000 </p>
                    </div>
                    <div className={style.divider} />
                  </div>
                  <div className="d-flex flex-column text-left justify-content-start align-items-center">
                    <div className="row justify-content-between w-80">
                      <p style={{ textAlign: 'left', fontWeight: 500 }}>P&M Credits</p>
                      <p>20,000 </p>
                    </div>
                    <div className={style.divider} />
                  </div>
                  <div className="d-flex flex-column text-left justify-content-start align-items-center">
                    <div className="row justify-content-between w-80">
                      <p style={{ textAlign: 'left', fontWeight: 500 }}>Promotional Discount</p>
                      <p>10,000 </p>
                    </div>
                    <div className={style.divider} />
                  </div>
                  <div className="d-flex flex-column text-left justify-content-start align-items-center">
                    <div className="row justify-content-between w-80">
                      <p style={{ textAlign: 'left', fontWeight: 800 }}>Total</p>
                      <p style={{ fontWeight: 800 }}>10,000 </p>
                    </div>
                  </div>
                  <div className={'d-flex justify-content-center mt-2 align-items-center'}>
                    <button className={style.continueButton}>Continue</button>
                  </div>
                  {/* <div
                    className={
                      'd-flex justify-content-center mt-2 align-items-center'
                    }>
                    <p style={{ fontSize: '12px' }}>
                      Do you have promo code?{' '}
                      <span
                        style={{ color: 'blue', textDecoration: 'underline' }}>
                        Add it here
                      </span>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: '10%',
          height: '50vh',
          backgroundImage:
            "url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Group+2948.png')"
        }}
        className="d-flex justify-content-center align-items-center flex-column">
        <h3>Need Help? We got you</h3>
        <div
          className="container d-flex justify-content-around text-center align-items-start mt-5"
          style={{ maxWidth: '90%' }}>
          <div>
            <img
              style={{ width: '50%', marginBottom: '2%' }}
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Group+2217.svg'}
              alt=""
            />
            <br></br>
            <p className="mt-4">
              Email Us <br></br> <span style={{ fontSize: '18px' }}>Send an Email</span>
            </p>
          </div>
          <div>
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Group+2216.svg'}
              alt=""
            />
            <br></br>
            <p className="mt-4">
              Call Us <br></br> <span style={{ fontSize: '18px' }}>99272 18261</span>
            </p>
          </div>
          <div>
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Path+1328.svg'}
              alt=""
            />
            <br></br>
            <p className="mt-4">
              Visit our Help Centre <br></br> <span style={{ fontSize: '18px' }}>Read FAQs</span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GiftCheckout;
function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    // ConsoleHelper(screenSize)
  }, [screenSize]);

  return screenSize;
}
