import React from 'react';
//import Masonry from '@mui/lab/Masonry';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PnMButton from '../button/PnMButton';
import { positions } from '@material-ui/system';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles((theme) => ({
  masonryContainer: {
    '& .masonryScroll': {
      animation: '$autoscroll 20s linear infinite'
    }
  },

  masonry: ({ columns, gap }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: `${gap}`
  }),

  '@keyframes autoscroll': {
    from: {
      transform: 'translateY(0)'
    },
    to: {
      transform: 'translateY(-100%)'
    }
  },

  masonryOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%'
  }
}));

function CuratedDesignsMasonry({ columns, gap, moodboardsArr, history, autoScroll, homePage }) {
  const classes = useStyles({ columns, gap });

  return (
    <div
      style={{
        width: '100%',
        position: 'relative'
      }}>
      <div
        className={autoScroll && classes.masonryContainer}
        style={{
          height: autoScroll && '90vh',
          overflow: autoScroll && 'hidden',
          position: 'relative',
          zIndex: 1
        }}>
        <div className={classNames(classes.masonry, 'masonryScroll')}>
          {moodboardsArr.map((moodboard, index) => (
            <div
              key={index}
              style={{
                // height: getHeight(index),
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/explore-detail/${moodboard?._id}`)}>
              <img
                src={moodboard.preview_url}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  marginBottom: '0.5rem',
                  height: '18px',
                  overflow: 'hidden',
                  // whiteSpace: 'nowrap',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  textOverflow: 'ellipsis',
                  lineHeight: 1,
                  marginTop: '0.2rem'
                }}>
                {moodboard.title}
              </p>
            </div>
          ))}
        </div>
        {/* two same masonry grid layout is for infinite scroll effect*/}
        {homePage && (
        <div
          className={classNames(classes.masonry, 'masonryScroll')}
          style={{ marginTop: `${gap}` }}>
          {moodboardsArr.map((moodboard, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/explore-detail/${moodboard?._id}`)}>
              <img
                src={moodboard.preview_url}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  marginBottom: '0.5rem',
                  height: '18px',
                  overflow: 'hidden',
                  // whiteSpace: 'nowrap',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  textOverflow: 'ellipsis',
                  lineHeight: 1,
                  marginTop: '0.2rem'
                }}>
                {moodboard.title}
              </p>
            </div>
          ))}
        </div>
      )}
      </div>
      <div
        className={classes.masonryOverlay}
        style={{
          height: autoScroll ? '90vh' : '100%'
          // background:
          //   autoScroll && 'linear-gradient(180deg, rgba(245, 245, 245, 0) 70%, #F5F5F5 100%'
        }}></div>
      {autoScroll && (
        <div
          style={{
            position: 'absolute',
            bottom: '0px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            zIndex: 2,
            background:
              autoScroll && 'linear-gradient(rgba(245, 245, 245, 0) 0%, rgb(245, 245, 245) 100%)'
          }}>
          <PnMButton type={'filled'} name={'View All'} size={'lg'} onClick={() =>
                          history.push(
                              `/curated-designs`
                          )
                        } />
        </div>
      )}
    </div>
  );
}

export default withRouter(CuratedDesignsMasonry);

{
  /* <Masonry
          columns={columns ? columns : 1}
          spacing={gap ? gap : 0}
          sx={{ margin: 0 }}
          className="masonryScroll">
          {moodboardsArr.map((moodboard, index) => (
            <div
              key={index}
              style={{
                // height: getHeight(index),
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/explore-detail/${moodboard?._id}`)}>
              <img
                src={moodboard.preview_url}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  marginBottom: '0.5rem',
                  height: '16px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  lineHeight: 1,
                  marginTop: '0.2rem'
                }}>
                {moodboard.title}
              </p>
            </div>
          ))}
        </Masonry> */
}
