import React, { useEffect, useState, useLayoutEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Referral.module.css';
import classnames from 'classnames';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import ConsoleHelper from '../../utils/ConsoleHelper';

const Referral = ({ auth }) => {
  const [referralurl, setreferralurl] = useState('');
  const [to, setto] = useState([]);
  const [message, setmessage] = useState('');
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        if (auth?.user?._id) {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth.user._id}/referral`
          );
          var base_url = window.location.origin;
          if (res.status == 200 && res.data.message == 'Success') {
            let url = `${base_url}/signup?code=${res.data.data.referral_code}`;
            setreferralurl(url);
          }
        }
      } catch (err) {
        ConsoleHelper(err);
      }
    };
    fetchData();

    // const res = await axios.get(
    //   `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth.user._id}/referral`
    // );
    // var base_url = window.location.origin;
    // if (res.status == 200 && res.data.message == "Success") {
    //   let url = `${base_url}/signup?code=${res.data.data.referral_code}`;
    //   setreferralurl(url);
    // }
  }, []);
  const dimentions = useMediaQuery();
  return (
    <div style={{ overflowY: 'hidden' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {loading && (
        <div style={{ position: 'fixed', top: '40%', left: '50%', opacity: 1 }}>
          <Loader type="ThreeDots" visible={true} color="#000000" height={100} width={100} />
        </div>
      )}
      <div className={style.wholeContainer} style={{ opacity: loading ? 0.5 : 1 }}>
        <Header />
        {dimentions[0] < 500 && (
          <div
            className="row p-0 m-0"
            style={{
              height: '100vh',
              overflowY: 'hidden',
              paddingTop: '3.8%'
            }}>
            <div
              className={classnames(
                'col-12 d-flex flex-column justify-content-center align-items-center',
                style.leftContainer
              )}>
              <div>
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/refferal+page+left+container+img.png"
                  alt=""
                />
              </div>
              <div style={{ marginTop: '50px', textAlign: 'center' }}>
                <h1>Refer & Earn</h1>
                <h6>
                  Refer friends to Pixel and Mortar and get<br></br>
                  spending cash to use for your home.
                </h6>
              </div>
            </div>
          </div>
        )}
        <div
          className="row p-0 "
          style={{ height: '100vh', overflowY: 'hidden', margin: '75px 0 0 0' }}>
          {dimentions[0] > 500 && (
            <div
              className={classnames(
                'col-5 d-flex flex-column justify-content-center align-items-center',
                style.leftContainer
              )}>
              <div>
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/refferal+page+left+container+img.png"
                  alt=""
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h1>Refer & Earn</h1>
                <h6>
                  Refer friends to Pixel and Mortar and get<br></br>
                  spending cash to use for your home.
                </h6>
              </div>
            </div>
          )}
          <div className="offset-1 col d-flex flex-column justify-content-center align-items-start">
            <div
              className="d-flex flex-column"
              style={{ width: '90%', position: 'relative', top: '10px' }}>
              <h6>Send your referral code</h6>
              <p>Enter multiple email addresses at once to increase your odds</p>
              <input
                className={style.input}
                type="text"
                required
                placeholder="To Email address"
                onChange={e => {
                  setto([e.target.value]);
                }}
              />
              <input className={style.input} type="text" placeholder="From Email address" />
              <textarea
                className={style.textArea}
                placeholder="Message (Optional)"
                onChange={e => {
                  setmessage(e.target.value);
                }}></textarea>
              <div className="d-flex justify-content-end">
                <button
                  className={style.sendButton}
                  onClick={async () => {
                    if (to !== '') {
                      setLoading(true);
                      const res = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth?.user?._id}/referral/email`,
                        { to: to, name: 'Pixel and mortor' }
                      );
                      if (res?.status == 200 && res.data.message == 'success') {
                        setLoading(false);
                        setSnack(true);
                        setSnackMessage('Referral mail send successfully');
                        setto('');
                        setmessage('');
                      } else {
                        setLoading(false);
                        setSnack(true);
                        setSnackMessage('Something went wrong .Try again!');
                      }
                    } else {
                      setSnack(true);
                      setSnackMessage('Enter required fields and Try again');
                    }
                  }}
                  style={{ float: 'right', width: '80px' }}>
                  Send
                </button>
              </div>
              <div className="mt-2">
                <div className="d-flex flex-row">
                  <img
                    style={{ width: '5%', height: '20px' }}
                    src={'https://d3izcfql7lesks.cloudfront.net/Group_2798_mxm9tf.png'}
                    alt=""
                  />
                  <p className="ml-3">Get Link</p>
                </div>
                <div className="d-flex flex-row" style={{ position: 'relative', top: '-10px' }}>
                  <input
                    type="text"
                    value={referralurl}
                    style={{
                      width: '80%',
                      borderRadius: '30px',
                      border: '0.5px solid #A5A5A5',
                      height: '50px',
                      fontSize: '12px',
                      padding: '0px 10px 0px 20px'
                    }}
                  />
                  <button
                    className="ml-3"
                    style={{
                      borderRadius: '30px',
                      backgroundColor: '#FFFFFF',
                      color: '#000000',
                      border: '0.5px solid #A5A5A5',
                      width: '100px',
                      height: '50px'
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(referralurl);
                      setSnack(true);
                      setSnackMessage('Referral link successfully copied');
                    }}>
                    Copy Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
Referral.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, {})(withRouter(Referral));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
