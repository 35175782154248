export function truncate(source, size) {
  return source.length > size ? source.slice(0, size - 1) + '…' : source;
}
export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
export function openin(url) {
  const win = window.open(url, '_self');
  if (win != null) {
    win.focus();
  }
}
export function openinnewtab(url) {
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
}
