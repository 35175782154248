import React, { useLayoutEffect, useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Pricing.module.css';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ScrollAnimation from 'react-animate-on-scroll';
import NewPackage from '../../components/newPackage/newPackage';

const themes = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    '&$expanded': {
      marginBottom: '0 !important'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  typo: {
    fontFamily: 'Quicksand',
    width: '60%',
    [themes.breakpoints.only('xs')]: {
      width: '95%',
      fontSize: '13px'
    }
  },
  typoHeader: {
    fontFamily: 'Quicksand',
    [themes.breakpoints.only('xs')]: {
      width: '95%',
      fontSize: '13px'
    }
  },
  faqBtn: {
    textAlign: 'center',
    marginBottom: '3em',
    marginTop: '3em',
    [themes.breakpoints.only('xs')]: {
      marginTop: '1em',
      marginBottom: '3em'
    }
  },
  '& .Mui-expanded': {
    marginBottom: 0
  },
  expanded: {
    marginBottom: 0
  },
  '& .MuiAccordionSummary-content': {
    marginBottom: 0
  }
}));

const Package = () => {
  const classes = useStyles();
  const dimension = useMediaQuery();
  const [expanded, setExpanded] = React.useState('panel1');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Header />
      <div
        className={classnames(
          'container d-flex flex-column align-items-center justify-content-center',
          style.container
        )}>
        <div
          className="container-fluid d-flex flex-column justify-content-center align-items-center"
          style={{
            maxWidth: '100%',
            paddingLeft: '0px',
            paddingRight: '0px',
            backgroundSize: '99%',
            backgroundColor: '#F7F7F7',
            marginTop: dimension[0] > 2000 && '4vh',
            backgroundPosition: '30% 80%',
            backgroundRepeat: 'no-repeat',
            backgroundImage:
              'url("https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/Group+2947.png")'
          }}>
          <div style={{ width: '75%' }}>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <h3
                className={classnames('pt-5 pb-4', style.header3)}
                style={{
                  textAlign: 'center'
                }}>
                Interior design you’ve always wanted,<br></br> at the price you never thought was
                possible.
              </h3>
              <p className={style.textSub}>Transform your room and watch it come alive in AR</p>
              <div className={style.prices}>
                <div className={style.strick}>₹ 10,000</div>
                <div className={style.original}>₹ 7,500</div>
              </div>
            </ScrollAnimation>
          </div>
          <NewPackage
            navbar={false}
            topbar={false}
            header={false}
            type={'home'}
            redirect={'/questionnaire'}
          />
          <div className="d-flex justify-content-center align-items-start w-100 mb-5">
            <Link to={{ pathname: '/pre-questionnaire' }}>
              <button className={style.designButton} style={{ fontWeight: 700 }}>
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className={classnames('faqSection', style.faqSection, 'pt-5')}>
        <div className="container mt-md-5" style={{ width: dimension[0] < 500 && '90%' }}>
          <h3 className="mb-5">
            <b>Frequently Asked Questions</b>
          </h3>
          <Accordion
            square
            expanded={expanded === 'panel1'}
            className="mb-3"
            onChange={handleChange('panel1')}>
            <AccordionSummary
              aria-controls="panel1d-content"
              style={{ minHeight: '37px' }}
              id="panel1d-header">
              <Typography className={classes.typoHeader}>
                <b>What’s my minimum budget supposed to be?</b>
              </Typography>
              <div>
                {expanded !== 'panel1' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                )}
                {expanded == 'panel1' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className="mb-3">
              <Typography className={classes.typo}>
                Now you can professionally design your space with a budget of Rs xxxx from one of
                the two packages we have for you. The Exclusive Package helps to design your space
                alongside a professional designer for just Rs xxxx. The Enhanced Package helps you
                visualise your space using AR for just Rs xxxxx. Visit our Design Packages to
                compare more details. (Link)
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            className="mb-3"
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}>
            <AccordionSummary
              aria-controls="panel2d-content"
              style={{ minHeight: '37px' }}
              id="panel2d-header">
              <Typography className={classes.typo}>
                <b>What payment modes do you accept?</b>
              </Typography>
              <div>
                {expanded !== 'panel2' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                )}
                {expanded === 'panel2' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className="mb-3">
              <Typography className={classes.typo}>
                We accept all online modes acceptable by Razor Pay, debit or credit cards, Wallets,
                UPI and Net Banking. However we do not offer cash and EMI options.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            className="mb-3"
            expanded={expanded === 'panel3'}
            style={{ minHeight: '37px' }}
            onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography className={classes.typo}>
                <b>Can I change/upgrade my package after payment?</b>
              </Typography>
              <div>
                {expanded !== 'panel3' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                )}
                {expanded === 'panel3' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className="mb-3">
              <Typography className={classes.typo}>
                Yes you can! If you’ve changed your mind about the amount of spatial assistance
                you’ll need while designing your room, you can definitely upgrade your package after
                you've paid. Our Exclusive Package helps you design your space alongside a
                professional designer for just Rs xxxx. The Enhanced Package helps you visualise
                your space using AR for just Rs xxxxx. Once you've got the package you want, you can
                launch your project by having a consultation with your designer.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            className="mb-3"
            expanded={expanded === 'panel4'}
            style={{ minHeight: '37px' }}
            onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography className={classes.typo}>
                <b>Can I buy multiple packages at the same time?</b>
              </Typography>
              <div>
                {expanded !== 'panel4' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                )}
                {expanded === 'panel4' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className="mb-3">
              <Typography className={classes.typo}>
                You can do multiple projects simultaneously. For every project, you will be assigned
                a designer, who will help you design your room accordingly.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            className="mb-3"
            expanded={expanded === 'panel6'}
            style={{ minHeight: '37px' }}
            onChange={handleChange('panel6')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography className={classes.typo}>
                <b>Can I buy the furniture separately?</b>
              </Typography>
              <div>
                {expanded !== 'panel6' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                )}
                {expanded === 'panel6' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className="mb-3">
              <Typography className={classes.typo}>
                Yes of course, you can purchase furniture using our discounted Pixel prices from the
                Shopping section directly. Our designers are here to guide you through the buying
                process with a curated shopping list, so you can outfit your space with great design
                and aesthetics sense.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            className="mb-3"
            expanded={expanded === 'panel5'}
            style={{ minHeight: '37px' }}
            onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography className={classes.typo}>
                <b>I have made the payment, when can I start the project?</b>
              </Typography>
              <div>
                {expanded !== 'panel5' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                )}
                {expanded === 'panel5' && (
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className="mb-3">
              <Typography className={classes.typo}>
                You can launch your project once payment confirmation is received. We will need all
                your Room Profile details to assign you a designer who really gets your style. Be
                sure to check your chat after you've submitted it, the designer will get in touch
                with you there.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <div className={classes.faqBtn}>
            <button className={classnames(style.button, 'mt-3')}>
              <b>Learn More</b>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Package;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
