import React, { useEffect } from 'react';
import style from './Careers.module.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';

const Careers = ({ history }) => {
  const data = [
    { title: 'Design Team', positions: ['Interior Designer', 'Home Stylist'] },
    { title: 'Merchandising', positions: ['Assistant Buyer', 'Manager'] },
    {
      title: 'Operations',
      positions: [
        'Operations Specialist',
        '3D Product Specialist',
        'Director of Customer Service',
        'Customer Experience Associate'
      ]
    },
    { title: 'Executive Team', positions: ['Engineer', 'Contractor'] },
    { title: 'Finance', positions: ['Engineer', 'Contractor'] }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className={style.container} style={{ paddingTop: '75px' }}>
        <div className="container" style={{ paddingTop: '200px', paddingBottom: '200px' }}>
          <h1 style={{ color: 'white', fontSize: '2.3vw' }}>
            Pixel & Mortar<br></br>
            Careers
          </h1>

          <button className={style.positionButton}>Open Position</button>
        </div>
      </div>
      <div
        style={{ position: 'relative', maxWidth: '1005' }}
        className="container d-flex justify-content-end mt-5">
        <img
          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+2002.png'}
          alt=""
          style={{ position: 'absolute', left: '-24%', top: '-55%', width: '20%' }}
        />
        <p style={{ width: '50%', textAlign: 'left' }}>
          Interested in all things home? P&M is always on the lookout for talented souls who love
          conceptualising spaces, decorating, web design, development and operations.
        </p>
      </div>
      <div className="container mt-5">
        {data.map((data, index) => (
          <div className="row mt-5">
            <div className="col-6" style={{ marginTop: '3%' }}>
              {data.title}
            </div>
            <div className="col-6 ">
              {data.positions.map(position => (
                <div
                  className="row"
                  style={{
                    marginTop: '30px',
                    paddingBottom: '20px',
                    borderBottom: '0.5px solid rgba(70,70,70, 0.2)'
                  }}>
                  <div className="col p-0">
                    <h4> {position}</h4>
                  </div>
                  <div>
                    <button
                      className={style.applyButton}
                      onClick={() => history.push('/careers-apply')}>
                      Apply
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <img
        className="mt-5"
        style={{ width: '100%' }}
        src={'https://d3izcfql7lesks.cloudfront.net/shutterstock_1044782092_okmz6v.png'}
        alt=""
      />
      <Footer />
    </>
  );
};

export default Careers;
