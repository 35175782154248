import React, {
  useLayoutEffect,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import {
  questionnaire,
  reset_answers,
  page,
  storeMx,
  page2,
  storeTab,
  storeMxAns,
  storeMxSkip,
  storeMxRetake
} from '../../store/actions/sampleaction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
const Footer = forwardRef(
  (
    {
      pg,
      setPg,
      active,
      questionnaire,
      space,
      disable,
      skip,
      reset_answers,
      page,
      storeMx,
      page2,
      storeTab,
      storeMxAns,
      storeMxSkip,
      storeMxRetake,
      roomsClicked,
      styleClickedData,
      colorSelected,
      intialRoomsClicked,
      name,
      style,
      email,
      validateEmail
    },
    ref
  ) => {
    const [width] = useMediaQuery();
    const [snack, setSnack] = useState(false);

    useEffect(() => {
      if (pg === 8) {
        setTimeout(() => {
          return setPg(9);
        }, 5000);
      } else if (pg === 6) {
        setTimeout(() => {
          return setPg(7);
        }, 5000);
      } else if (pg === 10) {
        setTimeout(() => {
          return setNext();
        }, 5000);
      }
    }, [pg]);

    const questionaireSubmitActions = () => {
      disable && setSnack(true);
      if (pg === 0 && space.length <= 4) {
        return setSnack(true);
      }
      if (pg === 0 && space.length >= 4) {
        return setPg(2);
      }
      if (pg === 1 && style.length < 1) {
        return setSnack(true);
      }
      if (pg === 2 && !colorSelected) {
        return setSnack(true);
      }

      if (roomsClicked.length < 1 && pg === 3) {
        return setSnack(true);
      }
      if (pg === 5 && name === '') {
        return setSnack(true);
      }
      if (pg === 6) {
        return setSnack(true);
      }
      if (pg === 7 && !validateEmail()) {
        return setSnack(true);
      }
      if (pg === 8) {
        return setSnack(true);
      }
      if (pg === 9) {
        page2(0);
      }
      pg === 10
        ? setNext()
        : skip === 'space'
        ? setPg(1)
        : skip === 'rooms'
        ? setPg(5)
        : setPg((pg = pg + 1));
    };

    const handleQuestionnaire = () => {
      questionaireSubmitActions();
    };

    useImperativeHandle(ref, () => ({
      handleQuestionnaire: handleQuestionnaire
    }));

    const setNext = () => {
      page2(0);
      storeTab('questionnaire2');
      questionnaire('questionnaire2');
    };

    const handleQuestionnaire2 = () => {
      if (pg === 0 && disable === false) {
        setPg((pg = pg + 1));
        setTimeout(() => {
          storeTab('package');
          questionnaire('package');
        }, 2000);
      } else {
        setPg((pg = pg + 1));
      }
    };

    return (
      <div className="questionaireFooter">
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={snack}
          onClose={() => setSnack(false)}
          message={
            pg === 0 && space && space.length <= 4
              ? 'Please select atleast 5 options to continue'
              : 'Please fill the option to continue'
          }
          autoHideDuration={2000}
        />
        {width > 500 ? (
          <>
            <p className="p-5 questionaireFooterPara">
              Powered By Eliya
              {/* MX for Pixel & Mortar */}
            </p>
            <div className="w-30 d-flex justify-content-between align-items-center mr-4">
              <span className="questionaireFooterSkip p-2">
                {space?.length == 0 && pg < 1 ? (
                  <p
                    className="mb-0"
                    onClick={() => {
                      setPg(
                        active === 'questionnaire2'
                          ? pg === 5
                            ? 0
                            : (pg = pg + 1)
                          : pg === 9
                          ? 0
                          : (pg = pg + 1)
                      );
                    }}>
                    {' '}
                    Skip this step
                  </p>
                ) : (
                  space?.length >= 0 &&
                  pg < 1 && (
                    <>
                      <p className="mb-0"> Selected Space {space?.length}</p>
                    </>
                  )
                )}
              </span>
              <span
                className="questionaireFooterSkip"
                style={{ display: disable && pg === 1 ? 'none' : 'block' }}
                onClick={() => {
                  if (pg === 9) {
                    storeMxRetake(true);
                    storeMx({});
                    reset_answers();
                    storeMxSkip(true);
                    storeMxAns({});
                    storeTab('questionnaire');
                    page(0);
                    questionnaire('questionnaire');
                    setPg(
                      active === 'questionnaire2'
                        ? pg === 5
                          ? 0
                          : (pg = pg + 1)
                        : pg === 9
                        ? 0
                        : (pg = pg + 1)
                    );
                  }
                }}>
                {pg === 9 ? 'Retake quiz' : null}
              </span>
              {active === 'questionnaire2' ? (
                <button
                  disabled={disable}
                  className="questionnaireNextBtn"
                  onClick={() => {
                    handleQuestionnaire2();
                    page2(pg);
                  }}>
                  <span
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      disable && setSnack(true);
                    }}>
                    {' '}
                  </span>
                  Next
                </button>
              ) : pg == 0 ? (
                <button
                  disabled={space && !disable && space?.length >= 5 ? disable : true}
                  className={`questionnaireNextBtn ${
                    !disable && space?.length >= 5 ? '' : 'questionnaireNextBtnDisable'
                  }`}
                  style={{
                    backgroundColor: disable ? '#a8a8a8 !important' : ''
                  }}
                  onClick={() => {
                    handleQuestionnaire();
                    page(pg);
                  }}>
                  {!disable && space?.length >= 5 && (
                    <span
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        disable && setSnack(true);
                      }}>
                      {' '}
                    </span>
                  )}
                  Next
                </button>
              ) : pg === 8 || pg === 6 || pg === 10 ? (
                ''
              ) : (
                <button
                  disabled={
                    (pg == 1 && styleClickedData?.length !== 0) ||
                    (pg == 2 && colorSelected?.length !== 0) ||
                    (pg == 3 && roomsClicked?.length !== 0) ||
                    (pg === 4 && intialRoomsClicked?.length !== 0) ||
                    (pg == 5 && name) ||
                    (pg == 5 && email) ||
                    (pg == 6 && email) ||
                    pg !== 8 ||
                    pg == 9
                      ? false
                      : true
                  }
                  className={'questionnaireNextBtn'}
                  style={{
                    backgroundColor: disable ? '#a8a8a8 !important' : ''
                  }}
                  onClick={() => {
                    handleQuestionnaire();
                    page(pg);
                  }}>
                  {!disable && space?.length >= 5 && (
                    <span
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        disable && setSnack(true);
                      }}>
                      {' '}
                    </span>
                  )}
                  Next
                </button>
              )}
            </div>
          </>
        ) : (
          <div className="form-inline">
            <div>
              <span
                className="questionaireFooterSkip"
                style={{ display: disable && pg === 1 ? 'none' : 'block' }}>
                {space?.length == 0 && pg < 1 && (
                  <p
                    className="mb-0"
                    onClick={() => {
                      setPg(
                        active === 'questionnaire2'
                          ? pg === 5
                            ? 0
                            : (pg = pg + 1)
                          : pg === 10
                          ? 0
                          : (pg = pg + 1)
                      );
                    }}>
                    {' '}
                    Skip this step
                  </p>
                )}
              </span>
              <p
                className={`questionaireFooterPara ${
                  space?.length == 0 && pg < 1 ? 'mt-2' : 'mt-4'
                }`}>
                Powered By Eliya
              </p>
            </div>{' '}
            <div className="form-inline">
              <span
                className="questionaireFooterSkip"
                style={{ display: disable && pg === 1 ? 'none' : 'block' }}
                onClick={() => {
                  if (pg === 9) {
                    storeMxRetake(true);
                    storeMx({});
                    reset_answers();
                    storeMxSkip(true);
                    storeMxAns({});
                    storeTab('questionnaire');
                    page(0);
                    questionnaire('questionnaire');
                    setPg(
                      active === 'questionnaire2'
                        ? pg === 5
                          ? 0
                          : (pg = pg + 1)
                        : pg === 9
                        ? 0
                        : (pg = pg + 1)
                    );
                  }
                }}>
                {pg === 9 ? 'Retake quiz' : null}
              </span>{' '}
              {active === 'questionnaire2' ? (
                <button
                  disabled={disable}
                  className="questionnaireNextBtn"
                  onClick={() => {
                    handleQuestionnaire2();
                    page2(pg);
                  }}
                  style={{ float: 'right' }}>
                  <span
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}
                    onClick={() => disable && setSnack(true)}>
                    {' '}
                  </span>
                  Next
                </button>
              ) : pg == 0 ? (
                <button
                  disabled={space && !disable && space?.length >= 5 ? disable : true}
                  className={`questionnaireNextBtn ${
                    !disable && space?.length >= 5 ? '' : 'questionnaireNextBtnDisable'
                  }`}
                  style={{
                    backgroundColor: disable ? '#a8a8a8 !important' : ''
                  }}
                  onClick={() => {
                    handleQuestionnaire();
                    page(pg);
                  }}>
                  {!disable && space?.length >= 5 && (
                    <span
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        disable && setSnack(true);
                      }}>
                      {' '}
                    </span>
                  )}
                  Next
                </button>
              ) : (
                <button
                  disabled={
                    (pg == 1 && styleClickedData?.length !== 0) ||
                    (pg == 2 && colorSelected?.length !== 0) ||
                    (pg == 3 && roomsClicked?.length >= 1) ||
                    (pg === 4 && intialRoomsClicked?.length !== 0) ||
                    (pg == 5 && name) ||
                    (pg == 5 && email) ||
                    (pg == 6 && email) ||
                    pg == 7 ||
                    pg == 9
                      ? false
                      : true
                  }
                  className="mt-4 questionnaireNextBtn"
                  onClick={() => {
                    handleQuestionnaire();
                    page(pg);
                  }}>
                  Next
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

Footer.propTypes = {
  questionnaire: PropTypes.string
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaireData.active,
  active: state.questionnaireData.active
});

export default connect(mapStateToProps, {
  questionnaire,
  reset_answers,
  page,
  storeMx,
  page2,
  storeTab,
  storeMxAns,
  storeMxSkip,
  storeMxRetake
})(Footer);
