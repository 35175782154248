import React, { useState } from 'react';
import style from './FilterBadge.module.css';

function FilterBadge({ name, active, onClick, sx, sxActive, hover, hoverColor }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      onMouseEnter={hover && handleMouseEnter}
      onMouseLeave={hover && handleMouseLeave}
      onTouchStart={hover && handleMouseEnter}
      onTouchEnd={hover && handleMouseLeave}
      onTouchCancel={hover && handleMouseLeave}
      className={active ? style.filterBadgeActive : style.filterBadge}
      onClick={onClick}
      style={
        active
          ? sxActive
          : {
              ...sx,
              backgroundColor: hover && isHovering && '#E29525',
              color: hover && isHovering && '#ffffff'
            }
      }>
      {name}
    </div>
  );
}

export default FilterBadge;
