import React, { useState } from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  FacebookIcon
} from 'react-share';
import { FloatingMenu, MainButton, ChildButton } from 'react-floating-button-menu';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CopyAllRoundedIcon from '@mui/icons-material/CopyAllRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import Snackbar from '@material-ui/core/Snackbar';
import './style.css';
import ConsoleHelper from '../utils/ConsoleHelper';

const Shareurl = ({ fabdirection, copiedUrl }) => {
  const [openshare, setopenshare] = React.useState(false);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <FloatingMenu
        slideSpeed={500}
        direction={fabdirection}
        spacing={8}
        isOpen={openshare}
        style={{ marginBottom: '10px' }}>
        <MainButton
          iconResting={<IosShareRoundedIcon />}
          iconActive={<HighlightOffRoundedIcon />}
          onClick={() => setopenshare(!openshare)}
          size={30}
          style={{ width: '40px', height: '40px', background: 'white' }}
        />
        <ChildButton
          icon={
            <FacebookShareButton url={copiedUrl}>
              <FacebookIcon
                size={30}
                round={true}
                bgStyle={{ fill: 'white' }}
                iconFillColor="black"
              />
            </FacebookShareButton>
          }
          onClick={() => ConsoleHelper('First button clicked')}
        />
        <ChildButton
          icon={
            <TwitterShareButton url={copiedUrl} title="P&M" hashtag="#PixelandMordar">
              <TwitterIcon
                size={30}
                round={true}
                bgStyle={{ fill: 'white' }}
                iconFillColor="black"
              />
            </TwitterShareButton>
          }
        />
        <ChildButton
          icon={
            <WhatsappShareButton title="P&M" url={copiedUrl}>
              <WhatsappIcon
                size={30}
                round={true}
                bgStyle={{ fill: 'white' }}
                iconFillColor="black"
              />
            </WhatsappShareButton>
          }
        />
        <ChildButton
          icon={
            <CopyAllRoundedIcon
              onClick={() => {
                navigator.clipboard.writeText(copiedUrl);
                setSnack(true);
                setSnackMessage('Llink successfully copied');
              }}
              style={{ background: 'white' }}
            />
          }
        />
      </FloatingMenu>
    </div>
  );
};
export default Shareurl;
