import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import style from './Signup.module.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser, loginUser, clearError } from '../../store/actions/authapi';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Snackbar from '@material-ui/core/Snackbar';
import { submitNewPassword } from 'supertokens-web-js/recipe/emailpassword';

const ResetPassword = ({ auth, history, clearError, location }) => {
  const dimension = useMediaQuery();
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const query = new URLSearchParams(useLocation().search);
  // const token = query.get('token');

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/');
    }
    let search = location.search;
    if (location.search.length !== 0) {
      setInputs({
        ...inputs,
        referral: search.slice(search.lastIndexOf('=')).replace('=', '')
      });
    }
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setLoading(false);
      history.push('/questionnaire/space');
    }
    if (auth.errors?.type === 'ERROR') {
      setLoading(false);
      setTimeout(() => {
        alert(auth.errors?.message);
      }, 500);
      clearError();
    }
  }, [auth]);

  const handleChangePassword = async e => {
    e.preventDefault();
    if (inputs.new_password !== inputs.confirm_password) {
      setSnack(true);
      setSnackMessage('Password doesnt match');
      setInputs({});
    } else {
      try {
        let response = await submitNewPassword({
          formFields: [
            {
              id: 'password',
              value: inputs.new_password
            }
          ]
        });

        if (response.status === 'FIELD_ERROR') {
          response.formFields.forEach(formField => {
            if (formField.id === 'password') {
              // New password did not meet password criteria on the backend.
              window.alert(formField.error);
            }
          });
        } else if (response.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
          // the password reset token in the URL is invalid, expired, or already consumed
          window.alert('Password reset failed. Please try again');
          window.location.assign('/login'); // back to the login scree.
        } else {
          window.alert('Password reset successful!');
          window.location.assign('/login');
        }
      } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          window.alert(err.message);
        } else {
          window.alert('Oops! Something went wrong.');
        }
      }
    }
  };

  const checkValidation = value => {
    if (value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
      return setError('');
    } else {
      return setError('password');
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {loading && (
        <div style={{ position: 'fixed', top: '40%', left: '50%', opacity: 1 }}>
          <Loader type="ThreeDots" visible={true} color="#000000" height={100} width={100} />
        </div>
      )}
      <div className={style.wholeContainer} style={{ opacity: loading ? 0.5 : 1 }}>
        <Header />
        <div className={classnames('container-fluid')}>
          <div className="row mt-0">
            <div className={classnames('col-lg-6 ', style.imgContainer)}></div>

            <div className={classnames('col-lg-6', style.container)}>
              <div
                className={classnames(
                  'container pl-1 pr-1 d-flex flex-column align-items-left justify-content-center',
                  dimension[0] > 2000 ? style.innerContainer1 : style.innerContainer
                )}
                style={{ height: dimension[1] - 80 }}>
                <form onSubmit={handleChangePassword}>
                  <h2 className={style.signup_text} style={{ fontSize: '26px', fontWeight: 500 }}>
                    Change your password
                  </h2>
                  <p className="mt-3 mb-0">
                    Please create a new password of your <br /> account
                  </p>
                  <p className="mt-3 mb-2" style={{ fontSize: '12px', opacity: 0.7 }}>
                    Use a combination of characters, letters and numbers.
                  </p>
                  <input
                    required
                    className={style.signup_input}
                    value={inputs.new_password || ''}
                    onChange={e => {
                      setInputs({ ...inputs, new_password: e.target.value });
                      checkValidation(e.target.value);
                    }}
                    type="password"
                    placeholder="New password"
                  />
                  <div>
                    {error === 'password' && (
                      <span style={{ color: 'red', fontSize: '11px' }}>
                        Password must contains atleast one capital letter, number and special
                        character
                      </span>
                    )}
                  </div>
                  <input
                    required
                    className={style.signup_input}
                    value={inputs.confirm_password || ''}
                    onChange={e => setInputs({ ...inputs, confirm_password: e.target.value })}
                    type="password"
                    placeholder="Confirm password"
                  />
                  <button
                    style={{ cursor: 'pointer' }}
                    className={style.signup_button}
                    type="submit">
                    ENTER
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  registerUser,
  loginUser,
  clearError
})(withRouter(ResetPassword));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
