import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import Header from '../../components/Header/Header';
import style from './Shopdetails.module.css';
import style1 from './Shop.module.css';
import './Shop.css';
import classnames from 'classnames';
import { Drawer, Grid } from '@material-ui/core';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import QRScanner from '../../components/layout/QrScanner';
import { makeStyles } from '@material-ui/core/styles';
import ReactPaginate from 'react-paginate';
import customerApiInstance from '../../utils/CustomerApiInstance';
import getCartDataCount from '../../utils/CartDetails';
import { storeCartCount } from '../../store/actions/sampleaction';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import arrow from '../../assets/images/Iconionic-ios.png';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { openin } from '../../utils/utils';
import { CloseOutlined } from '@material-ui/icons';
import ConsoleHelper from '../../utils/ConsoleHelper';
import ProductCard from '../../components/cards/ProductCard';
import ProductFilter from '../../components/ProductFilter/ProductFilter';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-root': {
    width: '83%'
  },
  '& .MuiInputBase-input': {
    '&:focus': {
      borderRadius: 23
    },
    borderRadius: 23,
    boxShadow: '0px 3px 6px #00000029',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '5px 17px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}));
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: theme.shadows[5]
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: 'none',
    width: 800,
    height: 500,
    padding: theme.spacing(2, 4, 3)
  },
  papers: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    width: '70%',
    height: 500,
    position: 'relative',
    outline: 'none'
  },
  displayrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  roombox: {
    fontSize: '15px',
    marginTop: '16px',
    fontWeight: 600,
    marginBottom: '51px'
  },
  drawerHeader: {
    float: 'right'
  }
}));

const shop_type = [
  { id: 1, value: 'living_room', type: 'Living Room' },
  { id: 2, value: 'dining_room', type: 'Dining Room' },
  { id: 3, value: 'bed_room', type: 'Bed Room' },
  { id: 4, value: 'kids_room', type: 'Kids Room' },
  { id: 5, value: 'puja_room', type: 'Puja Room' },
  { id: 6, value: 'foyer', type: 'Foyer' },
  { id: 7, value: 'balcony', type: 'Balcony' },
  {
    id: 8,
    value: 'study_entertainment_room',
    type: 'Study Entertainment Room'
  },
  { id: 9, value: 'other', type: 'Other' }
];

const ProductListBudget = ({ history, auth, storeCartCount }) => {
  const classes = useStyles();
  const params = useParams();
  const [filterOption, setFilterOption] = useState({
    AR: true,
    sortBy: 'view_count:desc',
    price_from: params.min,
    price_to: params.max,
    room_type: [],
    primary: true
  });

  const [totalPages, setTotalPages] = useState(0);
  const dimension = useMediaQuery();
  const [drawer, setDrawer] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [tempProductsList, setTempProductsList] = useState([]);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [prodQuantity] = useState(1);
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [savedProducts, setSavedProducts] = useState([]);
  const [likesChecked, setLikesChecked] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const [prodLoading, setProdLoading] = useState(false);
  const [bannerlist, setbannerlist] = useState([]);
  const [sortby, setsortby] = useState('');
  const [shopType] = useState(params?.category ? params.category : 1);
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();

  let prevPage = useRef(0);
  useEffect(() => {
    getgenricbanner();
  }, []);

  useEffect(() => {
    const roomType = params?.category ? params.category : 1;
    const room = shop_type?.find((item) => roomType == item.id && item.id)?.type;
    if (room) {
      setFilterOption({
        ...filterOption,
        room_type: [room]
      });
    }
  }, [shopType]);

  useEffect(() => {
    if (filterOption?.room_type?.length > 0) {
      let filters = { ...filterOption };
      getProductDetails(page, filters);
    }
  }, [page, filterOption, sortby]);

  useEffect(() => {
    fetchFavouriteData();
  }, [likesChecked]);

  function openinnewtab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  const handleQr = (productIds) => {
    ConsoleHelper('productIds', productIds);
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = (productIds) => {
    history.push(`/3d-model/${productIds}`);
  };

  const getProductDetails = async (page, filters) => {
    setProdLoading(true);
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
      params: { page, ...filters }
    });
    setProdLoading(false);
    setProductsList([...res.data.results]);
    setTempProductsList([...res.data.results]);
    setTotalRecords(res.data.totalResults);
    setTotalPages(res.data.totalPages);
    setLimit(res.data.limit);
    loadTableData();
  };

  const loadTableData = () => {
    if (page === prevPage.current) {
      setPage((prev1State) => prev1State + 1);
    }
  };

  const addToCart = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const buyNow = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push('/cart');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
        history.push('/cart');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const renderColumn = () => {
    return (
      <div className="row sm-pl-3">
        {productsList && productsList.length > 0 ? (
          productsList
            .filter((data) => {
              if (data.price === 0 && data.stock === 0) {
                return false;
              } else {
                return true;
              }
            })
            .map((data, index) => (
              <>
                {data?.stock > 0 ? (
                  <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">
                    <ProductCard
                      key={index}
                      data={data}
                      heart={auth && auth.user}
                      heartIsActive={savedProductIds?.includes(data._id)}
                      arIcon={data && data['3d_models']?.glbFileUrl}
                      // tag={'NEW LAUNCH'}
                      vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                      productImage={data.image[0]}
                      productName={data.name}
                      price={data.price}
                      pnmPrice={data.pixel_price}
                      onClickCart={() => addToCart(data)}
                      onClickBuyNow={() => buyNow(data)}
                      onClickHeart={() => handleFavourites(data)}
                      onClicAr={() =>
                        dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                      }
                      viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                      sx={{ margin: 'auto', width: '100%', maxWidth: '295px' }} // sx will apply style to the outermost container of Product Card (To over ride css)
                    />
                  </div>
                ) : null}
                {index === 8 ? (
                  <Grid container>
                    <Grid item xs={12} className={style1.imgtxtcontainer}>
                      <img
                        onClick={() =>
                          bannerlist[2]?.routing_url && openin(bannerlist[2].routing_url)
                        }
                        src={
                          bannerlist[2]?.img_url
                            ? bannerlist[2].img_url
                            : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/shutterstock_496612132.png'
                        }
                        alt=""
                        className={classnames(style.bannerImg, 'imagefitinsize mb-5 mt-3')}
                        style={{ width: '100%' }}
                      />
                      {dimension[0].width > 600 ? (
                        <p className={style1.txtoverimgrightlist1}>
                          {bannerlist[2]?.content
                            ? bannerlist[2].content
                            : `Get interiors
                designed online
                with the power of A.R.`}
                        </p>
                      ) : (
                        <p className={style1.txtoverimgrightlist1}>
                          {bannerlist[2]?.content
                            ? bannerlist[2].content
                            : `Get interiors
                designed online
                with the power of A.R.`}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </>
            ))
        ) : (
          <div className="col-12">
            <p>No Products Available </p>
          </div>
        )}
      </div>
    );
  };

  const addToFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        ConsoleHelper('response shop likesChecked', res.data);
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = (product) => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds((prev) => [...prev, product._id]);
      setSavedProducts([...savedProducts, product]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter((filter) => filter !== product._id));
      const removefav = savedProducts.filter((sav) => sav._id !== product._id);
      setSavedProducts([...removefav]);
      removeFromFavourites(product);
    }
  };

  const getgenricbanner = async () => {
    const res = await axios.get(`${process.env.REACT_APP_CMS_URL}/api/v1/user/genricbanner`);
    if (res.status == 200 && res?.data?.data?.length > 0) {
      let responsedata = res.data.data;
      responsedata.sort(function (a, b) {
        if (a.bannername < b.bannername) {
          return -1;
        }
        if (a.bannername > b.bannername) {
          return 1;
        }
        return 0;
      });
      setbannerlist([...responsedata]);
    } else {
      setbannerlist([]);
    }
  };

  const handleChangeInput = (e) => {
    let value = e.target.value;
    setsortby(value);
    if (value !== '') {
      setFilterOption({
        ...filterOption,
        sortBy: value
      });
    }
  };

  // const handleChangeshoptype = (event) => {
  //   setShopType(event.target.value);
  //   history.push(
  //     `/product_list_budget/${event.target.value}/budget=min:${params.min}&max:${params.max}`
  //   );
  // };

  const fetchFavouriteData = async () => {
    try {
      if (auth && auth.user) {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
        );
        let savedIds = [];
        if (likesChecked) {
          if (res?.data?.favProducts?.length > 0) {
            setProductsList([...res.data.favProducts]);
          }
        } else {
          setProductsList([...tempProductsList]);
        }

        if (res?.data?.favourites?.length !== 0) {
          res.data.favourites.map((dat) => {
            savedIds = dat.products.map((m) => {
              return m.product_id;
            });
          });
          setSavedProductIds([...savedIds]);
          const favouriteProductData = productsList.filter((prod) => savedIds.includes(prod._id));
          setSavedProducts([...favouriteProductData]);
        }
      }
    } catch (err) {}
  };

  return (
    <>
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snack}
          onClose={() => setSnack(false)}
          message={snackMessage}
          autoHideDuration={2000}
        />
        <Header />
        <div style={{ backgroundColor: 'white' }}>
          <div className={style.mainContainer}>
            {dimension[0] > 600 ? (
              <div className="row d-flex">
                <div className="col-md-3 col-lg-3 col-xs-12 p-0">
                  <div style={{ paddingTop: '110px' }}>
                    <ProductFilter
                      filterApplied={filterOption}
                      setFilterApplied={setFilterOption}
                      likesChecked={likesChecked}
                      setLikesChecked={setLikesChecked}
                    />
                  </div>
                </div>

                <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                  {params.category === 'Shop' ? (
                    <div className={style.typesSectionwom}>
                      <div className={classNames(style.roomType, classes.displayrow)}>
                        <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}>
                          {params.category}
                        </div>
                        <div className={classes.displayrow}>
                          <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                          <div style={{ cursor: 'pointer', color: 'gray' }}>{params?.tag}</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={style.typesSectionwom}>
                      <div className={classNames(style.roomType, classes.displayrow)}>
                        <div
                          className={classNames(classes.imgtag, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}
                          onClick={() => {
                            history.push({
                              pathname: `/shop`
                            });
                          }}>
                          Shop
                        </div>

                        <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}
                          onClick={() => history.goBack()}>
                          <img
                            src={arrow}
                            className={classNames(classes.imgtag, 'mr-3')}
                            style={{ color: 'gray' }}
                          />
                          {shopType && shop_type.find((item) => item.id == shopType)?.type}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-9">
                      <p
                        className="mt-3"
                        style={{
                          cursor: 'pointer',
                          fontFamily: 'Quicksand',
                          fontSize: '20px',
                          textAlign: 'left',
                          fontWeight: 'bold'
                        }}>
                        All Products &nbsp;
                        {page === totalPages
                          ? totalRecords + '/' + totalRecords
                          : productsList?.length === 0
                          ? '0'
                          : limit * page + '/' + totalRecords}
                      </p>
                    </div>
                    <div className="col-3 justify-content-end ">
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={sortby}
                        onChange={handleChangeInput}
                        displayEmpty
                        input={<BootstrapInput />}
                        className={style1.selectSort}>
                        <MenuItem value=""> Sort by </MenuItem>
                        <MenuItem value={'price:desc'}>High to Low</MenuItem>
                        <MenuItem value={'price:asc'}>Low to High</MenuItem>
                        <MenuItem value={'view_count:desc'}>Popularity</MenuItem>
                      </Select>
                    </div>
                  </div>
                  {prodLoading && (
                    <div>
                      <Loader
                        type="ThreeDots"
                        visible={true}
                        color="#000000"
                        height={50}
                        width={50}
                      />
                    </div>
                  )}
                  {!prodLoading && renderColumn()}
                  <ReactPaginate
                    forcePage={0}
                    onPageChange={({ selected }) => {
                      setPage(selected + 1);
                      window.scrollTo(0, 0);
                    }}
                    pageCount={totalPages}
                    breakLabel="..."
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    containerClassName={
                      ' justify-content-end pagination react-paginate pagination-sm p-1 mt-1'
                    }
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    previousLabel={productsList?.length > 0 && '<'}
                    nextLabel={productsList?.length > 0 && '>'}
                    breakClassName={'break-me'}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={style.nav_container} style={{ paddingTop: '110px' }}>
                  <div
                    className="row"
                    style={{
                      backgroundColor: '#F9FEFF',
                      padding: '8px 0px',
                      marginBottom: '16px'
                    }}>
                    <p
                      className="col-4 mb-0 d-flex justify-content-start"
                      style={{ fontWeight: 600, cursor: 'pointer' }}
                      onClick={() => setDrawer(true)}>
                      <img
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Group+3000.png"
                        className={style.toggleImg}
                      />
                      Refine
                    </p>
                    <p className=" col-7 p-0 d-flex justify-content-end">
                      <div className={classNames(style.roomType, classes.displayrow)}>
                        <div
                          className={classNames(classes.imgtag, 'mr-1')}
                          style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}
                          onClick={() => {
                            history.push({
                              pathname: `/shop`
                            });
                          }}>
                          Shop
                        </div>

                        <img
                          src={arrow}
                          className={classNames(classes.imgtag, 'mr-1')}
                          style={{ color: 'gray' }}
                        />
                        <div
                          className={classNames(classes.displayrow, 'mr-1')}
                          style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}
                          onClick={() => history.goBack()}>
                          {shopType && shop_type.find((item) => item.id == shopType)?.type}
                        </div>
                      </div>
                    </p>
                  </div>
                </div>

                <Drawer
                  anchor={'left'}
                  open={drawer}
                  docked={false}
                  onClose={() => setDrawer(false)}>
                  <div className={classnames(style.drawerContainer, ' mb-2 ml-2')}>
                    <>
                      <div className="row mt-3 mb-3 ml-1">
                        <h5
                          style={{
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            fontWeight: 'bold'
                          }}>
                          Filters
                        </h5>
                        <CloseOutlined
                          onClick={() => setDrawer(false)}
                          style={{ marginLeft: 'auto', marginRight: 0 }}
                        />
                      </div>
                      <div>
                        <ProductFilter
                          filterApplied={filterOption}
                          setFilterApplied={setFilterOption}
                          likesChecked={likesChecked}
                          setLikesChecked={setLikesChecked}
                          setDrawer={setDrawer}
                        />
                      </div>
                    </>
                  </div>
                </Drawer>
                <div className="row">
                  <div className="col-sm-12 col-xs-12">
                    <div className="row" style={{ marginTop: dimension[0] < 600 ? '25px' : '' }}>
                      <div className="col-6">
                        <p
                          className={classnames(style.product, 'mt-1')}
                          style={{
                            cursor: 'pointer',
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 'bold'
                          }}>
                          All Products&nbsp;
                          {page === totalPages
                            ? totalRecords + '/' + totalRecords
                            : productsList?.length === 0
                            ? '0'
                            : limit * page + '/' + totalRecords}
                        </p>
                      </div>
                      <div className="col-6 justify-content-end ">
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={sortby}
                          onChange={handleChangeInput}
                          displayEmpty
                          input={<BootstrapInput />}
                          className={style1.selectSort}>
                          <MenuItem value=""> Sort by</MenuItem>
                          <MenuItem value={'price:desc'}>High to Low</MenuItem>
                          <MenuItem value={'price:asc'}>Low to High</MenuItem>
                          <MenuItem value={'view_count:desc'}>Popularity</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div className="container-fluid ml-lg-3 pl-lg-2 ml-md-3 pl-md-2">
                      {prodLoading && (
                        <div>
                          <Loader
                            type="ThreeDots"
                            visible={true}
                            color="#000000"
                            height={50}
                            width={50}
                          />
                        </div>
                      )}
                      {!prodLoading && renderColumn()}
                      <ReactPaginate
                        forcePage={0}
                        onPageChange={({ selected }) => {
                          setPage(selected + 1);
                          window.scrollTo(0, 0);
                        }}
                        pageCount={totalPages}
                        breakLabel="..."
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        containerClassName={
                          ' justify-content-center pagination react-paginate pagination-sm p-1 mt-1'
                        }
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        previousLabel={'<'}
                        nextLabel={'>'}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <Footer />
        </div>
      </>
      {modalOpen && <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(ProductListBudget));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
