import React, { useState, useLayoutEffect, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Curatedpage.module.css';
import classnames from 'classnames';
import FilterBadge from '../../components/badge/FilterBadge';
import Slider from '../../components/slider/Slider';
import PnMButton from '../../components/button/PnMButton';
import axios from 'axios';
import CuratedDesignsMasonry from '../../components/Masonry/CuratedDesignsMasonry';
import Carousel from '../../components/carousel/Carousel';
import CustomStackedCarousel from '../../components/carousel/CustomStackedCarousel';
import RoomCard from '../../components/cards/RoomCard';
import Loader from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';

const roomsFilter = [
  { id: 1, value: 'living_room', type: 'Living Room' },
  { id: 2, value: 'dining_room', type: 'Dining Room' },
  { id: 3, value: 'bed_room', type: 'Bed Room' },
  { id: 4, value: 'kids_room', type: 'Kids Room' },
  { id: 5, value: 'puja_room', type: 'Puja Room' },
  { id: 6, value: 'foyer', type: 'Foyer' },
  { id: 7, value: 'balcony', type: 'Balcony' },
  {
    id: 8,
    value: 'study_entertainment_room',
    type: 'Study Entertainment Room'
  }
];

let stylesData = [
  {
    id: 1,
    name: 'South Indian',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/south+indian.svg'
  },
  {
    id: 2,
    name: 'North Indian',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/north+indian.svg'
  },
  {
    id: 3,
    name: 'Classic Indian',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/classical.svg'
  },
  {
    id: 4,
    name: 'Minimalistic',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/minimalistic.svg'
  },
  {
    id: 5,
    name: 'Mid Century',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/mid-century+modern.svg'
  },
  {
    id: 6,
    name: 'Art Deco',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/art+deco.svg'
  },
  {
    id: 7,
    name: 'Pop Art',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/pop-art.svg'
  },
  {
    id: 8,
    name: 'Industrial',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/industrial.svg'
  },
  {
    id: 9,
    name: 'Rustic',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/rustic.svg'
  },
  {
    id: 10,
    name: 'Gothic',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/gothic.svg'
  },
  {
    id: 11,
    name: 'Boho',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/bohemian.svg'
  },
  {
    id: 12,
    name: 'Farm House',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/farm+house.svg'
  },
  {
    id: 13,
    name: 'Coastal',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/coastal.svg'
  },
  {
    id: 14,
    name: 'Eclectic',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/eclectic.svg'
  },
  {
    id: 15,
    name: 'Contemporary',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/contemporary.svg'
  }
];

const carouselConfig = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 10,
  responsive: [
    {
      breakpoint: 3000,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 9,
        slidesToScroll: 9,
        initialSlide: 9
        //centerMode: true
      }
    },
    {
      breakpoint: 2500,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 8,
        slidesToScroll: 8,
        initialSlide: 8
        //centerMode: true
      }
    },
    {
      breakpoint: 2000,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 7,
        initialSlide: 7
        //centerMode: true
      }
    },
    {
      breakpoint: 1650,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 5
        //centerMode: true
      }
    },
    {
      breakpoint: 1150,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 4
        // centerMode: true
      }
    },
    {
      breakpoint: 950,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: false
      }
    },
    {
      breakpoint: 700,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 500,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1.6,
        slidesToScroll: 1,
        centerMode: false
      }
    }
  ]
};

export const data = [
  {
    cover: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/6476dee13e7e68001361fe5a.png',
    title: 'Organic Living Room- Teacher',
    _id: '6476dee13e7e68001361fe5a'
  },
  {
    cover: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/6454c78a49d8ee0013af2229.png',
    title: 'Doctors living room- Modern',
    _id: '6454c78a49d8ee0013af2229'
  },
  {
    cover: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/6450b5d049d8ee0013aee129.png',
    title: 'Businessmen Living room- contemporary',
    _id: '6450b5d049d8ee0013aee129'
  },
  {
    cover: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/644a11d349d8ee0013ae2e83.png',
    title: "Writer's Living room- Indian Classical",
    _id: '644a11d349d8ee0013ae2e83'
  },
  {
    cover: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/64476ed6c1d903001329b3bd.png',
    title: 'Rustic living',
    _id: '64476ed6c1d903001329b3bd'
  }
  //   {
  //     cover: 'https://images2.alphacoders.com/631/thumb-1920-631095.jpg',
  //     title: 'Steins Gate'
  //   },
  //   {
  //     cover: 'https://images4.alphacoders.com/665/thumb-1920-665242.png',
  //     title: 'One Punch Man'
  //   },
  //   {
  //     cover: 'https://images2.alphacoders.com/738/thumb-1920-738176.png',
  //     title: 'A Silent Voice'
  //   },
  //   {
  //     cover: 'https://images8.alphacoders.com/100/thumb-1920-1005531.jpg',
  //     title: 'Demon Slayer'
  //   },
  //   {
  //     cover: 'https://images2.alphacoders.com/582/thumb-1920-582804.png',
  //     title: 'Attack On Titan'
  //   }
];

export const CuratedPage = ({ history, auth }) => {
  const dimension = useMediaQuery();
  const [value, setValue] = useState([0, 450000]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [moodBoards, setMoodBoards] = useState([]);
  // const [filters, setFilters] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [loadingCarousel, setLoadingCarousel] = useState(false);
  const [carouselMoodBoards, setCarouselMoodBoards] = useState([]);
  const [selectedMoodboardRoom, setSelectedMoodboardRoom] = useState(roomsFilter[0]);
  const [pricedMoodBoards, setPricedMoodBords] = useState([]);
  const location = useLocation();
  const [snackMessage, setSnackMessage] = useState('');
  const [snack, setSnack] = useState(false);
  const [shopNowDisabled, setShopNowDisabled] = useState(false);

  const getPriceMoodBoadsList = async () => {
    // setLoading(true);
    try {
      let result = [];
      if (searchParams.get('room') !== null) {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/explore?rooms=${searchParams.get(
            'room'
          )}&price_from=${searchParams.get('minPrice')}&price_to=${searchParams.get('maxPrice')}`
        );
        result = res.data.results;
        setPricedMoodBords(result);
      } else {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/explore?rooms=${selectedRoom.type}&price_from=${value[0]}&price_to=${value[0]}`
        );
        result = res.data.results;
        setPricedMoodBords(result);
      }
      // setLoading(false)
    } catch (err) {
      console.log('error');
    }
  };
  useEffect(() => {
    getPriceMoodBoadsList();
  }, [selectedRoom]);

  useEffect(() => {
    getMasonryMoodboardsList();
  }, []);

  const getMasonryMoodboardsList = async () => {
    setLoading(true);
    try {
      let result = [];
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/explore?tags[]=Designer Pick&Page=1`
      );
      result = res.data.results;
      setMoodBoards(result);
      setLoading(false);
    } catch (err) {
      //ConsoleHelper('error', err);
      setLoading(false);
      setMoodBoards([]);
    }
  };

  useEffect(() => {
    const getStackedCarauselMoodboardsList = async () => {
      setLoadingCarousel(true);
      try {
        let result = [];
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/explore?tags[]=Get Inspired&Page=1&rooms[]=${selectedMoodboardRoom.type}`
        );
        result = res?.data?.results?.map((elem) => {
          elem.cover = elem.preview_url;
          return elem;
        });

        if (result.length >= 5) {
          setCarouselMoodBoards(result.slice(0, 5));
        } else {
          if (result.length % 2 === 1) {
            setCarouselMoodBoards(result);
          } else {
            if (result.length > 0) {
              result.push({
                cover:
                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/64476ed6c1d903001329b3bd.png',
                title: 'Rustic living',
                _id: '0'
              });
            }
            setCarouselMoodBoards(result);
          }
        }
        setLoadingCarousel(false);
      } catch (err) {
        setLoadingCarousel(false);
        setCarouselMoodBoards([]);
      }
    };
    getStackedCarauselMoodboardsList();
  }, [selectedMoodboardRoom]);
  const [showMasonry, setShowMasonry] = useState(false);
  const [showTrendingThemes, setShowTrendingThemes] = useState(true);

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const roomParam = searchParams.get('room');
    const minPriceParam = searchParams.get('minPrice');
    const maxPriceParam = searchParams.get('maxPrice');

    if (roomParam && minPriceParam && maxPriceParam) {
      setSelectedRoom({ type: roomParam });
      setValue([parseInt(minPriceParam), parseInt(maxPriceParam)]);
      setShowMasonry(true);
      setShowTrendingThemes(false);
      getPriceMoodBoadsList();
    }
  }, [searchParams.get('room')],[searchParams.get('minPrice')],[searchParams.get('maxPrice')]);
  const handleApplyClick = () => {
    if (selectedRoom && value[0] && value[1]) {
      setShowMasonry(true);
      setShowTrendingThemes(false);
    }
    searchParams.set('room', selectedRoom.type);
    searchParams.set('minPrice', value[0]);
    searchParams.set('maxPrice', value[1]);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString()
    });
    getPriceMoodBoadsList()
  };
  const handleClearAllClick = () => {
    if (selectedRoom) {
      setShowMasonry(false);
      setValue([0, 450000]);
      setSelectedRoom(null);
      setShowMasonry(false);
      setShowTrendingThemes(true);
    }
  };

  return (
    <>
      <Header />
      <div className={style.curatedMainCont}>
        <div className={style.filterCont}>
          <div className={style.filterInnerCont}>
            <div style={{ flex: '1.5' }} className="p-2">
              <div className={style.filterInnerHeading}>What's your dream space?</div>
              <div className={style.badgeContainer}>
                {roomsFilter.map((room, index) => (
                  <FilterBadge
                    key={index}
                    name={room.type}
                    active={selectedRoom?.type == room.type}
                    hover={true}
                    onClick={() => {
                      setSelectedRoom(selectedRoom?.type == room.type ? null : room);
                    }}
                  />
                ))}
              </div>
            </div>
            {dimension[0] > 600 && (
              <>
                <div style={{ flex: '1' }} className={classnames(style.budgetContainer, 'p-2')}>
                  <div
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      color: '#171717',
                      marginBottom: '1rem'
                    }}>
                    SET YOUR BUDGET
                  </div>
                  <div>
                    <Slider
                      value={value}
                      handleChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      disabled={selectedRoom == null}
                      maxval={450000}
                    />
                    <div className="d-flex">
                      <div className={style.sliderBox}>
                        <p className={style.sliderBoxInner}>Min.</p>
                        <div className="d-flex">
                          <span className={style.sliderBoxInnerValue}>Rs.</span>
                          <input
                            value={value[0]}
                            className={style.sliderInput}
                            type="number"
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) >= 0 &&
                                parseInt(e.target.value) <= 450000
                              ) {
                                setValue([parseInt(e.target.value), value[1]]);
                                setShopNowDisabled(false);
                              } else {
                                setValue([e.target.value, value[1]]);
                                setShopNowDisabled(true);
                                setSnack(true);
                                setSnackMessage('Please enter value between 0 - 4.5 lakh');
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className={style.sliderBox}>
                        <p className={style.sliderBoxInner}>Max.</p>
                        <div className="d-flex">
                          <span className={style.sliderBoxInnerValue}>Rs.</span>
                          <input
                            value={value[1]}
                            type="number"
                            className={style.sliderInput}
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) >= 0 &&
                                parseInt(e.target.value) <= 450000
                              ) {
                                setValue([value[0], parseInt(e.target.value)]);
                                setShopNowDisabled(false);
                              } else {
                                setValue([value[0], e.target.value]);
                                setShopNowDisabled(true);
                                setSnack(true);
                                setSnackMessage('Please enter value between 0 - 4.5 lakh');
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: '1' }} className={classnames(style.filterBtnCont, 'p-2')}>
                  <PnMButton
                    type={'outlined'}
                    name={'Clear All'}
                    size={'sm'}
                    sx={{ marginRight: '10px' }}
                    onClick={handleClearAllClick}
                    disabled={selectedRoom == null}
                  />
                  <PnMButton
                    type={'filled'}
                    name={'Apply'}
                    size={'sm'}
                    onClick={handleApplyClick}
                    disabled={selectedRoom == null}
                  />
                </div>
              </>
            )}
            {dimension[0] < 600 && selectedRoom != null && (
              <>
                <div
                  style={{ flex: '1' }}
                  className={classnames(style.budgetContainer, 'pl-2 pr-2')}>
                  <div
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      color: '#171717',
                      marginBottom: '1rem'
                    }}>
                    SET YOUR BUDGET
                  </div>
                  <div>
                    <Slider
                      value={value}
                      handleChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      disabled={selectedRoom == null}
                    />
                    <div className="d-flex">
                      <div className={style.sliderBox}>
                        <p className={style.sliderBoxInner}>Min.</p>
                        <p className={style.sliderBoxInnerValue}>Rs. {value[0]}</p>
                      </div>
                      <div className={style.sliderBox}>
                        <p className={style.sliderBoxInner}>Max.</p>
                        <p className={style.sliderBoxInnerValue}>Rs.{value[1]}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: '1' }} className={classnames(style.filterBtnCont, 'pl-2 pr-2')}>
                  <PnMButton
                    type={'outlined'}
                    name={'Clear All'}
                    size={'sm'}
                    sx={{ marginRight: '10px' }}
                    onClick={handleClearAllClick}
                    disabled={selectedRoom == null}
                  />
                  <PnMButton
                    type={'filled'}
                    name={'Apply'}
                    size={'sm'}
                    onClick={handleApplyClick}
                    disabled={selectedRoom == null}
                  />
                </div>
                {/* mobile view */}
                <div className={style.moodboardCont}>
                  {showMasonry && ( // Render CuratedDesignsMasonry only when showMasonry is true
                    <CuratedDesignsMasonry
                      moodboardsArr={pricedMoodBoards}
                      columns={2}
                      gap={'25px'}
                      autoScroll={false}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {/* web view */}
        {dimension[0] > 600 && (
          <div className={style.moodboardCont}>
            {showMasonry && ( // Render CuratedDesignsMasonry only when showMasonry is true
              <CuratedDesignsMasonry
                moodboardsArr={pricedMoodBoards}
                columns={3}
                gap={'25px'}
                autoScroll={false}
              />
            )}
          </div>
        )}
        {showTrendingThemes && (
          <div className={style.trendingThemesContainer}>
            <div className={style.trendingThemesContTitle}>Trending Themes</div>
            {stylesData.length > 0 && (
              <Carousel config={carouselConfig}>
                {stylesData.map((data, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                    <RoomCard
                      bg={'#ffffff'}
                      height={'185px'}
                      width={'185px'}
                      title={data.name}
                      image={data.image}
                      sx={{ margin: 'auto', padding: 0 }}
                      sxImage={{ width: '100%', height: '100%', marginBottom: 0 }}
                      onClick={() => history.push(`/explore/styles/${data.name}`)}
                      hover={false}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        )}
        {showTrendingThemes && (
          <div className={style.moodboardCont}>
            <p className={style.moodboardContTitle} onClick={() => history.push('/explore')}>
              300+ designs
            </p>
            {moodBoards.length == 0 &&
              (loading ? (
                <div className="d-flex justify-content-center mt-4 w-100">
                  <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-4 w-100">
                  {/* No Moodboards available */}
                </div>
              ))}
            {/*Curated design for web and tab */}
            {moodBoards.length > 0 && !loading && dimension[0] > 600 && (
              <CuratedDesignsMasonry
                moodboardsArr={moodBoards}
                columns={3}
                gap={'25px'}
                autoScroll={false}
              />
            )}
            {/*Curated design for mobile */}
            {moodBoards.length > 0 && !loading && dimension[0] < 600 && (
              <CuratedDesignsMasonry
                moodboardsArr={moodBoards}
                columns={2}
                gap={'20px'}
                autoScroll={false}
              />
            )}
          </div>
        )}
        
        {pricedMoodBoards.length == 0 &&
              (loading && (
                <div className="d-flex justify-content-center mt-4 w-100">
                  <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
                </div>
              
                // <div className="d-flex justify-content-center mt-4 w-100">
                //   {/* No Moodboards available */}
                // </div>
              ))}
        {showTrendingThemes && (
          <div className={style.stackedCarouselContainer}>
            <div className={style.stackedCarouselContainerInner}>
              <div className={style.stackedCarouselContTittle}>Get Inspired</div>
              <div className={style.stackedCarouselBadgeContainer}>
                {roomsFilter.map((room, index) => (
                  <FilterBadge
                    key={index}
                    name={room.type}
                    active={selectedMoodboardRoom?.type === room.type}
                    onClick={() => {
                      setSelectedMoodboardRoom(
                        selectedMoodboardRoom?.type === room.type ? null : room
                      );
                    }}
                    sxActive={{
                      backgroundColor: '#ffffff',
                      color: '#171717',
                      border: '1px solid #E3E3E3',
                      boxShadow: '0px 3px 8px 0px #00000014'
                    }}
                  />
                ))}
              </div>
            </div>
            <div className={style.stackedCarouselContainerInnerMost}>
              {carouselMoodBoards.length === 0 &&
                (loadingCarousel ? (
                  <div className="d-flex justify-content-center mt-4 w-100">
                    <Loader
                      type="ThreeDots"
                      visible={true}
                      color="#000000"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-4 w-100">
                    No Moodboards available
                  </div>
                ))}
              {carouselMoodBoards.length > 0 && !loadingCarousel && (
                <CustomStackedCarousel data={carouselMoodBoards} />
              )}
            </div>
          </div>
            )}
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CuratedPage));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
