import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Aboutus.module.css';
import Swipe from 'swipejs';
import classNames from 'classnames';
import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import SliderContainer from 'react-slick';
import AboutusMobileCarousel from './AboutusMobileCarousel';

const AboutUs = () => {
  const dimension = useMediaQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  window.mySwipe = new Swipe(document.getElementById('slider'), {
    startSlide: 0,
    speed: 400,
    auto: 3000,
    draggable: false,
    continuous: true,
    disableScroll: false,
    stopPropagation: false,
    ignore: '.scroller',
    callback: function(index, elem, dir) {},
    transitionEnd: function(index, elem) {}
  });

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img src={sliderArrowPrev} alt="prevArrow" {...props} />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img src={sliderArrowNext} alt="nextArrow" {...props} />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />
  };

  return (
    <>
      <Header />
      <div className={classNames('container-fluid pl-0 pr-0')}>
        <div className={classNames(style.aboutUsContainer1)}>
          <div className={classNames(style.innerContainer)}>
            <h4 style={{ fontWeight: '600' }}>
              We’re from the house of Pencil & Monk,
              <br /> an interior design firm that was founded
              <br /> in 2014 by Siddharth Chandrashekar,
              <br /> Film maker and Designer with over 25 <br />
              years of experience
            </h4>
          </div>
          <SliderContainer {...settings} className={classNames(style.slider)}>
            <div style={{ width: '100%' }}>
              <img
                style={{ width: '100%' }}
                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus/aboutus.jpg"
                alt=""
              />
            </div>
            <div style={{ width: '100%' }}>
              <img
                style={{ width: '100%' }}
                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus/aboutus2.jpg"
              />
            </div>
            <div style={{ width: '100%' }}>
              <img
                style={{ width: '100%' }}
                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus/aboutus3.jpg"
              />
            </div>
          </SliderContainer>
        </div>
        <div className={classNames(style.aboutUsContainer2)}>
          <div className={classNames(style.innerContainer)}>
            <div className={classNames('d-flex justify-content-center pt-5')}>
              <h2
                style={{
                  fontWeight: '600',
                  color: '#ffffff'
                }}>
                At Pixel & Mortar our goal is to
              </h2>
            </div>
            <div className="row mt-3">
              <div className="col-xl-4  col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column pt-5">
                <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.png" alt="" />
                <p className={classNames('text-center w-80 pt-3', style.aboutUsContainer2Text)}>
                  Involve the customer in
                  <br /> the interior design process,
                  <br /> making it fun, simple
                  <br /> and collaborative.
                </p>
              </div>
              <div className="col-xl-4  col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column pt-5">
                <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus2.png" alt="" />
                <p className={classNames('text-center  w-80 pt-3', style.aboutUsContainer2Text)}>
                  Utilize technology to
                  <br /> understand customers’ tastes
                  <br /> and deliver interiors based on
                  <br /> their needs.
                </p>
              </div>
              <div className="col-xl-4  col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column pt-5">
                <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus3.png" alt="" />
                <p className={classNames('text-center w-80 pt-3', style.aboutUsContainer2Text)}>
                  Create a marketplace,
                  <br /> for interior brands that coexist
                  <br /> and complement each other.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(style.aboutUsContainer3)}>
          {dimension[0] > 500 ? (
            <div className={classNames(style.innerContainer)}>
              <div className={classNames('d-flex justify-content-center')}>
                <h2
                  style={{
                    fontWeight: '600'
                  }}>
                  Our Values
                </h2>
              </div>
              <div className="row mt-3">
                <div className="col-xl-4 pt-4 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.1.png"
                    alt=""
                  />
                  <h4 className="text-center pt-3"> Passion</h4>
                  <p className={classNames('text-center ', style.aboutUsContainer3Text)}>
                    Do what you love with an abundance of enthusiasm and obsession.
                  </p>
                </div>
                <div className="col-xl-4 pt-4  col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.2.png"
                    alt=""
                  />
                  <h4 className="text-center pt-3"> Empathy</h4>
                  <p className={classNames('text-center ', style.aboutUsContainer3Text)}>
                    Understand the perspective of your customer and your team.
                  </p>
                </div>
                <div className="col-xl-4 pt-4  col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.3.png"
                    alt=""
                  />
                  <h4 className="text-center pt-3"> Excellence</h4>
                  <p className={classNames('text-center', style.aboutUsContainer3Text)}>
                    You are as good as your consistency. So never settle
                  </p>
                </div>
                <div className="col-xl-4 pt-4  col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.4.png"
                    alt=""
                  />
                  <h4 className="text-center pt-3"> Collaboration</h4>
                  <p className={classNames('text-center', style.aboutUsContainer3Text)}>
                    Connect with diverse people and co-create the future.
                  </p>
                </div>
                <div className="col-xl-4 pt-4  col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.5.png"
                    alt=""
                  />
                  <h4 className="text-center pt-3"> Impact</h4>
                  <p className={classNames('text-center', style.aboutUsContainer3Text)}>
                    Anything you do, big or small must make a difference.
                  </p>
                </div>
                <div className="col-xl-4 pt-4  col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus1.6.png"
                    alt=""
                  />
                  <h4 className="text-center pt-3"> Curiosity</h4>
                  <p className={classNames('text-center ', style.aboutUsContainer3Text)}>
                    Be as excited as a child to learn new things.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h2
                className="text-center mb-5 mt-5"
                style={{
                  fontWeight: '600'
                }}>
                Our Values
              </h2>
              <AboutusMobileCarousel />
            </div>
          )}
        </div>
        <div className={classNames(style.aboutUsContainer4)}>
          <div className="row mt-3">
            <div className="col-xl-6 pt-4 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column">
              <img
                style={{ width: '70%' }}
                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus2.2.png"
                alt=""
              />
            </div>
            <div className="col-xl-6 pt-4 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center flex-column">
              <div style={{ width: '70%' }}>
                <h3
                  style={{
                    fontSize: '20px',
                    fontWeight: 600
                  }}>
                  Join Our Team
                </h3>

                <p className={classNames('pt-3', style.aboutUsContainer3Text)}>
                  The Pixel and Mortar team is made up of an eclectic group of interior designers,
                  architects, 3D visual artists, techies and operation specialists
                </p>

                <button className={style.designButton} style={{ marginTop: '5%' }}>
                  Join Our Team
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
