import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import './MyOrders.css';
import { Drawer } from '@material-ui/core';
import Header from '../../components/Header/Header';

import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter, useHistory } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';
import Rating from 'react-rating';
import Snackbar from '@material-ui/core/Snackbar';
import customerApiInstance from '../../utils/CustomerApiInstance';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Modal as Modal1 } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { format } from 'date-fns';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import ConsoleHelper from '../../utils/ConsoleHelper';

function MyOrders({ auth }) {
  const copy = useRef();
  const dimension = useMediaQuery();
  const [drawer, setDrawer] = useState(false);
  let [cancelledOrders, setCancelledOrders] = useState([]);
  let [currentOrders, setCurrentOrders] = useState([]);
  let [pastOrders, setPastOrders] = useState([]);
  const [snackMessage, setSnackMessage] = useState('');
  const [snack, setSnack] = useState(false);
  const [activeTab, setActiveTab] = useState('current');
  const [allOrders, setAllOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState();
  const [currentOrderItem, setCurrentOrderItem] = useState();
  const [cancelOrderButton, setCancelOrderButton] = useState(false);
  const [currentOrderIndex, setCurrentOrderIndex] = useState();
  const [cancelReason, setCancelReason] = useState('');
  const [requestToCancel, setRequestToCancel] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [customerRating, setCustomerRating] = useState(0);
  const [isCancel, setIsCancel] = useState(false);
  const [proceedToCancel, setProceedToCancel] = useState(false);
  const [cancelStepOne, setCancelStepOne] = useState(false);
  const [cancelledDate, setCancelledDate] = useState('');
  const [cancelledTime, setCancelledTime] = useState('');
  const [productReviewDescription, setProductReviewDescription] = useState('');
  const [productReviewTitle, setProductReviewTitle] = useState('');
  const [productReviewName, setProductReviewName] = useState('');
  const [allPackages, setAllPackages] = useState([]);
  const [currentPackageOrder, setCurrentPackageOrder] = useState({});
  const [currentPackageItem, setCurrentPackageItem] = useState({});
  const [drawer1, setDrawer1] = useState(false);
  const [packages, setPackages] = useState([]);
  const [trackModal, setTrackModal] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const history = useHistory();
  const [redirect, setRedirect] = useState(
    history.location.state && history.location.state.redirectFrom ? true : false
  );
  const [orderId] = useState(
    history.location.state && history.location.state.orderId ? history.location.state.orderId : ''
  );
  const current_order_id =
    history.location.state && history.location.state.order_id
      ? history.location.state.order_id
      : '';
  const reasons = [
    { id: 1, title: 'I have changed my mind' },
    { id: 2, title: 'Not interested' },
    { id: 3, title: 'something else' }
  ];

  const useStyles1 = makeStyles({
    paper: {
      // backgroundImage: `url(${"https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4851.png"})`
    }
  });
  const classes1 = useStyles1();

  const useStyles2 = makeStyles({
    paper: {
      // backgroundImage: `url(${"https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4851.png"})`
    }
  });
  const classes2 = useStyles2();

  useEffect(() => {
    const getOrders = async () => {
      try {
        if (auth && auth.user) {
          setOrderLoading(true);
          let cancelled = [];
          let currentOrders1 = [];
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/order/user-orders/${auth.user._id}`
          );
          let orders = [...res.data.ordersData];
          let ordersTemp = [...res.data.ordersData];
          if (redirect === false) {
            const res2 = await customerApiInstance.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/package-order/user/${auth.user._id}`
            );
            setAllPackages([...res2.data]);
            setAllOrders([...orders]);
            for (let i = 0; i < ordersTemp.length; i++) {
              for (let j = 0; j < ordersTemp[i].orderItems.length; j++) {
                if (
                  ordersTemp[i].orderItems[j].status === 'CANCELLED' ||
                  ordersTemp[i].orderItems[j].status === 'UNACCEPTED'
                )
                  cancelled.push(ordersTemp[i].orderItems[j]);
              }
            }
            currentOrders1 = orders.filter(
              or =>
                (or.orderItems = or.orderItems.filter(
                  orIt =>
                    orIt.status === 'ACCEPTED' ||
                    orIt.status === 'DELIVERED' ||
                    orIt.status === 'SHIPPED' ||
                    orIt.status === 'ACKNOWLEDGED' ||
                    orIt.status === 'NEW'
                ))
            );

            let pastOrders1 = orders.filter(or => or.status === 'DELIVERED');
            setCurrentOrders([...currentOrders1]);
            setCancelledOrders([...cancelled]);
            setPastOrders([...pastOrders1]);
          } else if (redirect === true) {
            let currentOrders2 = orders.filter(or => or._id === orderId);
            setAllOrders([...currentOrders2]);
          }
        }
      } catch (e) {
        ConsoleHelper(e);
      }
    };
    getOrders();
    setOrderLoading(false);
  }, [redirect]);

  const cancelOrder = async (orderitemData, orderIndex) => {
    const cancelorderItem = {
      status: 'CANCELLED',
      cancelled_reason: cancelReason,
      cancelled_by: 'CUSTOMER',
      cancelled_at: new Date()
    };
    allOrders[orderIndex].orderItems = allOrders[orderIndex].orderItems.filter(
      ct => ct._id !== orderitemData._id
    );
    setAllOrders([...allOrders]);
    setCancelledOrders([...cancelledOrders, orderitemData]);
    const res = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/order/cancel-order-item/${orderitemData._id}`,
      cancelorderItem
    );
    if (Object.keys(res.data).length && res.data.hasOwnProperty('cancelled_at')) {
      const x = res.data.cancelled_at || res.data.updatedAt;
      let result = format(new Date(x), 'cccc do LLLL');
      let time = new Date(x);
      let timeValue = time.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
      setCancelledTime(timeValue);
      setCancelledDate(result);
    }
    setIsCancel(true);
    setCancelStepOne(false);
  };

  const archieveOrder = async (orderitemData, index, tab) => {
    const archieveorderItem = {
      isArchived: true
    };
    await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/order/archieve-order-item/${orderitemData._id}`,
      archieveorderItem
    );
    if (tab === 'current') {
      currentOrders[index].orderItems = currentOrders[index].orderItems.filter(
        ct => ct._id !== orderitemData._id
      );
      allOrders[index].orderItems = allOrders[index].orderItems.filter(
        ct => ct._id !== orderitemData._id
      );
      setAllOrders([...allOrders.flat()]);
      setCurrentOrders([...currentOrders.flat()]);
    } else if (tab === 'past') {
      pastOrders[index].orderItems = pastOrders[index].orderItems.filter(
        ct => ct._id !== orderitemData._id
      );
      setPastOrders([...pastOrders.flat()]);
    } else {
      let cancel = cancelledOrders.filter(cn => cn._id !== orderitemData._id);
      setCancelledOrders([...cancel]);
    }
    let currentTemp = currentOrders.filter(ct =>
      ct.orderItems.filter(or => or._id === orderitemData._id)
    );
    setCurrentOrders([...currentTemp]);
  };

  const getOrderPlaced = orderDetails => {
    if (Object.keys(orderDetails).length) {
      const x = orderDetails.createdAt;
      let result = format(new Date(x), 'cccc do LLLL');
      return result;
    }
  };

  const getCancelledOrder = orderItemData => {
    let order = allOrders.filter(all => all._id === orderItemData.order_id);
    if (order && order.length > 0) {
      setCurrentOrder({ ...order[0] });
    }
  };

  const getdeliveryDate = orderDetails => {
    if (
      Object.keys(orderDetails).length &&
      orderDetails.hasOwnProperty('shipment') &&
      orderDetails.shipment.length > 0 &&
      orderDetails.shipment[0].delivery_date
    ) {
      let x = orderDetails.shipment[0].delivery_date;
      let result = format(new Date(x), 'cccc do LLLL');
      return result;
    } else {
      return '--';
    }
  };

  const getCancelledDate = orderDetails => {
    if (Object.keys(orderDetails).length && orderDetails.hasOwnProperty('cancelled_at')) {
      const x = orderDetails.updatedAt;
      let result = format(new Date(x), 'cccc do LLLL');
      return result;
    } else {
      return '--';
    }
  };
  const reviewProduct = async orderItem => {
    const reviewBody = {
      customer_rating: customerRating,
      customer_review: {
        description: productReviewDescription,
        name: productReviewName,
        title: productReviewTitle
      }
    };
    await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/product/update/${orderItem.product_id._id}`,
      reviewBody
    );
    setReviewModal(false);
  };

  const getOrderStatus = data => {
    if (data.status === 'ACCEPTED') return 'Your item has been placed';
    if (data.status === 'DELIVERED') return 'Your item has been delivered';
    if (data.status === 'CANCELLED') return 'Your item has been cancelled';
    if (data.status === 'RETURNED') return 'Your item has been returned';
    if (data.status === 'SHIPPED') return 'Your item has been shipped';
    if (data.status === 'UNACCEPTED') return 'Your item has been cancelled';
  };

  const handleShareLink = () => {
    const url1 = copy.current.value;
    navigator.clipboard.writeText(url1);
    setSnack(true);
    setSnackMessage('Tracking link successfully copied');
  };

  useEffect(() => {
    const fetchPackages = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/packages/list`);
      let paidPackages = res.data.filter(pack => pack.identifier !== 'EXPRESS');
      setPackages([...paidPackages]);
    };
    fetchPackages();
  }, []);

  const getPackageImage = (packages, pack) => {
    if (packages.length > 0 && Object.keys(pack).length > 0) {
      const packageData = packages.find(pk => pk.title === pack.title);
      if (Object.keys(packageData).length) {
        return packageData.image;
      } else {
        return '';
      }
    }
  };

  const checkOrderTime = orderData => {
    let hours = moment().diff(moment(orderData.createdAt), 'hours');
    if (hours <= 24) return true;
    else return false;
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header />
      <div style={{ background: '#F1F3F6', minHeight: '100vh', marginTop: '75px' }}>
        <div
          className={
            cancelStepOne && dimension[0] < 500
              ? 'col-lg-10 col-md-11 col-sm-11 pb-5 m-auto'
              : isCancel === false && dimension[0] < 500
              ? 'col-11 pb-5 m-auto'
              : 'col-10 pb-5 m-auto'
          }>
          {cancelStepOne && (
            <>
              <div className="row mt-5 pt-5">
                <div className="col-lg-8 col-md-12 col-sm-12 flex-column">
                  <div className={cancelOrderButton ? 'cancelBody1 d-flex' : 'headerCancelation'}>
                    {proceedToCancel === false ? (
                      <h6 className=" m-0 pl-4">1. Easy Cancellation</h6>
                    ) : (
                      <>
                        <h6 className=" m-0 pl-4 pr-2">1. Easy Cancellation</h6>
                        <Icon.CheckCircleFill color="#77C8E0" />
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      paddingLeft: '35px',
                      paddingRight: '35px'
                    }}>
                    {proceedToCancel === false ? (
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6 mt-4 d-flex justify-content-lg-center justify-content-sm-center">
                          Reason for Cancellation*
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 mt-3 mb-2">
                          <div>
                            {reasons && reasons.length > 0 && (
                              <select
                                className="select"
                                onChange={e => {
                                  setCancelReason(reasons[e.target.value - 1].title);
                                }}>
                                {reasons.map((item, i) => (
                                  <option value={item.id} key={i}>
                                    {item.title}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                          <div className="mt-3">
                            <input
                              className="col-12 textBox bordered"
                              type="textarea"
                              value={cancelReason}
                              onChange={e => setCancelReason(e.target.value)}></input>
                          </div>
                          <div className="mt-3">
                            <p
                              style={{
                                fontSize: '18px',
                                fontFamily: 'QuickSand',
                                fontWeight: 'bold'
                              }}>
                              You saved Rs.{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                currentOrderItem.pixel_discount
                              )}{' '}
                              on this product!
                            </p>
                          </div>
                          <div>
                            <span
                              style={{
                                fontSize: '14px',
                                fontFamily: 'QuickSand',
                                fontWeight: 'semiBold',
                                color: '#818181'
                              }}>
                              If you cancel now, you may not be able to avail this deal again. Do
                              you still want to Cancel?
                            </span>
                          </div>
                          <div className="row mt-3 ml-2">
                            <div className="form-check" className="col-lg-4 col-md-4 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="no"
                                id="flexRadioDefault1"
                                // checked={(addressType === "home") || false}
                                onChange={e => {
                                  setCancelOrderButton(false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                                style={{
                                  marginLeft: '0px',
                                  fontSize: '14px',
                                  fontFamily: 'QuickSand'
                                }}>
                                Don't Cancel
                              </label>
                            </div>
                            <div className="form-check" className="col-lg-4 col-md-4 col-sm-6 ">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="yes"
                                id="flexRadioDefault1"
                                // checked={(addressType === "work") || false}
                                onChange={e => {
                                  setCancelOrderButton(true);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                                style={{
                                  marginLeft: '0px',
                                  fontSize: '14px',
                                  fontFamily: 'QuickSand'
                                }}>
                                Cancel order
                              </label>
                            </div>
                          </div>
                          <div className="mt-4 mb-3">
                            <button
                              className="p-2 col-lg-4 col-sm-4 continueButton"
                              onClick={() => {
                                if (cancelOrderButton === true) {
                                  setProceedToCancel(true);
                                }
                              }}>
                              CONTINUE
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-lg-10 col-md-10 col-sm-12 mt-4 mb-3 pl-4">
                          <span>Reason for Cancellation: {cancelReason}</span>
                        </div>
                        <div className="mt-4 mb-3 col-lg-2 col-md-12 col-sm-12 d-flex justify-content-end ">
                          <button
                            className="changeButton"
                            onClick={() => {
                              setProceedToCancel(false);
                            }}>
                            CHANGE
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="cancleItemCard ">
                    <h6 className="ml-sm-0 ml-lg-2 ml-md-2">Item Details</h6>
                    <div className="d-flex ">
                      <div className="mr-2 w-30">
                        <img
                          src={
                            currentOrderItem.product_id.image.length > 0
                              ? currentOrderItem.product_id.image[0]
                              : currentOrderItem.product_id.image
                          }
                          alt=""
                          width="100%"
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <div>
                          <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                            {' '}
                            {currentOrderItem.product_id && currentOrderItem.product_id.name
                              ? currentOrderItem.product_id.name
                              : '---'}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'medium',
                              color: 'grey'
                            }}>
                            {' '}
                            Qty {currentOrderItem.quantity}
                          </span>
                        </div>
                        <div style={{ position: 'absolute', bottom: '10px' }}>
                          <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                            {' '}
                            {currentOrderItem.order_value}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className={proceedToCancel ? 'headerCancelation' : 'cancelBody1'}>
                    <h6 className=" m-0 pl-4 ">2. Refund Status</h6>
                  </div>
                  {proceedToCancel && (
                    <div className=" cancelBody1 pl-4">
                      <div className="col-12 mt-3">
                        <span>
                          Rs. {new Intl.NumberFormat('en-IN').format(currentOrderItem.order_value)}{' '}
                          will be transferred within 4 business days (bank holidays to included).
                        </span>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-sm-12 mt-4 mb-3">
                        <button
                          className="p-2 continueButton"
                          onClick={() => {
                            setRequestToCancel(true);
                            cancelOrder(currentOrderItem, currentOrderIndex);
                          }}>
                          REQUEST CANCELLATION
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
            // <div className="row">
            //   <div className="col-lg-8 col-md-8 col-sm-12">
            //     <div
            //       className={`row p-2 w-100 mt-5 ${
            //         cancelOrderButton ? "cancelBody1" : "headerCancelation"
            //       }`}
            //     >
            //       {proceedToCancel === false ? (
            //         <h6 className=" m-0 pl-4">1. Easy Cancellation</h6>
            //       ) : (
            //         <>
            //           <h6 className=" m-0 pl-4 pr-2">1. Easy Cancellation</h6>
            //           <Icon.CheckCircleFill color="#77C8E0" />
            //         </>
            //       )}
            //     </div>
            //     <div className="row cancelBody">
            //       {proceedToCancel === false ? (
            //         <>
            //           <div className="col-lg-4 col-md-4 col-sm-6 mt-4 pl-4 d-flex justify-content-lg-end justify-content-sm-center">
            //             Reason for Cancellation*
            //           </div>
            //           <div className="col-lg-8 col-md-8 col-sm-12 mt-3 mb-2">
            //             <div>
            //               {reasons && reasons.length > 0 && (
            //                 <select
            //                   className="select"
            //                   onChange={(e) => {
            //                     setCancelReason(
            //                       reasons[e.target.value - 1].title
            //                     );
            //                   }}
            //                 >
            //                   {reasons.map((item, i) => (
            //                     <option value={item.id} key={i}>
            //                       {item.title}
            //                     </option>
            //                   ))}
            //                 </select>
            //               )}
            //             </div>
            //             <div className="mt-3">
            //               <input
            //                 className="col-12 textBox bordered"
            //                 type="textarea"
            //                 value={cancelReason}
            //                 onChange={(e) => setCancelReason(e.target.value)}
            //               ></input>
            //             </div>
            //             <div className="mt-3">
            //               <p
            //                 style={{
            //                   fontSize: "18px",
            //                   fontFamily: "QuickSand",
            //                   fontWeight: "bold",
            //                 }}
            //               >
            //                 You saved Rs.{" "}
            //                 {new Intl.NumberFormat("en-IN").format(
            //                   currentOrderItem.pixel_discount
            //                 )}{" "}
            //                 on this product!
            //               </p>
            //             </div>
            //             <div>
            //               <span
            //                 style={{
            //                   fontSize: "14px",
            //                   fontFamily: "QuickSand",
            //                   fontWeight: "semiBold",
            //                   color: "#818181",
            //                 }}
            //               >
            //                 If you cancel now, you may not be able to avail this
            //                 deal again. Do you still want to Cancel?
            //               </span>
            //             </div>
            //             <div className="row mt-3 ml-2">
            //               <div
            //                 class="form-check"
            //                 className="col-lg-4 col-md-4 col-sm-6"
            //               >
            //                 <input
            //                   class="form-check-input"
            //                   type="radio"
            //                   name="type"
            //                   value="no"
            //                   id="flexRadioDefault1"
            //                   // checked={(addressType === "home") || false}
            //                   onChange={(e) => {
            //                     setCancelOrderButton(false);
            //                   }}
            //                 />
            //                 <label
            //                   class="form-check-label"
            //                   for="flexRadioDefault1"
            //                   style={{
            //                     marginLeft: "0px",
            //                     fontSize: "14px",
            //                     fontFamily: "QuickSand",
            //                   }}
            //                 >
            //                   Don't Cancel
            //                 </label>
            //               </div>
            //               <div
            //                 class="form-check"
            //                 className="col-lg-4 col-md-4 col-sm-6 "
            //               >
            //                 <input
            //                   class="form-check-input"
            //                   type="radio"
            //                   name="type"
            //                   value="yes"
            //                   id="flexRadioDefault1"
            //                   // checked={(addressType === "work") || false}
            //                   onChange={(e) => {
            //                     setCancelOrderButton(true);
            //                   }}
            //                 />
            //                 <label
            //                   class="form-check-label"
            //                   for="flexRadioDefault1"
            //                   style={{
            //                     marginLeft: "0px",
            //                     fontSize: "14px",
            //                     fontFamily: "QuickSand",
            //                   }}
            //                 >
            //                   Cancel order
            //                 </label>
            //               </div>
            //             </div>
            //             <div className="mt-4 mb-3">
            //               <button
            //                 className="p-2 col-lg-4 col-sm-4 continueButton"
            //                 onClick={() => {
            //                   if (cancelOrderButton === true) {
            //                     setProceedToCancel(true);
            //                   }
            //                 }}
            //               >
            //                 Continue
            //               </button>
            //             </div>
            //           </div>
            //         </>
            //       ) : (
            //         <>
            //           <div className="col-lg-10 col-md-10 col-sm-12 mt-4 mb-3 pl-4">
            //             <span>{cancelReason}</span>
            //           </div>
            //           <div className="mt-4 mb-3 col-2 d-flex justify-content-end ">
            //             <button
            //               className="changeButton"
            //               onClick={() => {
            //                 setProceedToCancel(false);
            //               }}
            //             >
            //               Change
            //             </button>
            //           </div>
            //         </>
            //       )}
            //     </div>
            //     <div
            //       className={`row mt-5 ${
            //         proceedToCancel ? "headerCancelation" : "cancelBody1"
            //       }`}
            //     >
            //       <h5 className=" mt-3 pl-4 ">2. Refund Status</h5>
            //     </div>
            //     {proceedToCancel && (
            //       <div className=" cancelBody1 row pl-4">
            //         <div className="col-12 mt-3">
            //           <span>
            //             Rs.{" "}
            //             {new Intl.NumberFormat("en-IN").format(
            //               currentOrderItem.order_value
            //             )}{" "}
            //             will be transferred within 4 business days (bank
            //             holidays to included).
            //           </span>
            //         </div>
            //         <div className="col-lg-6 col-md-6 col-sm-12 col-sm-12 mt-4 mb-3">
            //           <button
            //             className="p-2 continueButton"
            //             onClick={() => {
            //               setRequestToCancel(true);
            //               cancelOrder(currentOrderItem, currentOrderIndex);
            //             }}
            //           >
            //             Request Cancellation
            //           </button>
            //         </div>
            //       </div>
            //     )}
            //   </div>
            //   <div className=" col-lg-4 col-md-4 col-sm-6 ">
            //     <div className="row mt-5 ml-sm-0 ml-lg-2 ml-md-2 cancelBody">
            //       <h5 className=" mt-3 mt-3 ml-sm-0 ml-lg-2 ml-md-2">
            //         Item Details
            //       </h5>
            //     </div>
            //     <div className="row cancelBody ml-2">
            //       <div className="col-4 p-0">
            //         <img
            //           src={
            //             currentOrderItem.product_id.image.length > 0
            //               ? currentOrderItem.product_id.image[0]
            //               : currentOrderItem.product_id.image
            //           }
            //           alt=""
            //           width="80%"
            //         />
            //       </div>
            //       <div className="col-8">
            //         <span style={{ fontSize: "14px", fontWeight: "medium" }}>
            //           {" "}
            //           {currentOrderItem.product_id &&
            //           currentOrderItem.product_id.name
            //             ? currentOrderItem.product_id.name
            //             : "---"}
            //         </span>
            //         <div>
            //           <span style={{ fontSize: "14px", fontWeight: "medium" }}>
            //             {" "}
            //             {currentOrderItem.quantity}
            //           </span>
            //         </div>
            //         <div>
            //           <span style={{ fontSize: "14px", fontWeight: "medium" }}>
            //             {" "}
            //             {currentOrderItem.order_value}
            //           </span>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </div>
          )}

          {requestToCancel && (
            <div className="row">
              <>
                <div className="col-lg-2 col-md-2"></div>
                <div className="col-lg-8 col-md-8">
                  <div className="row justify-content-start ">
                    <h4 className=" mt-5">Your order has been cancelled</h4>
                  </div>
                  {/* <div className="row cancelBody borderBottom">
                    <div className="col-10 p-3">
                      <span>Order placed : {getOrderPlaced(currentOrder)}</span>
                    </div>
                    <div className="col-2 p-3">
                      <u>
                        <span>Help</span>
                      </u>
                    </div>
                  </div> */}
                  <div className="row cancelBody">
                    <div className="col-12 p-0 order__container">
                      <div className="col-12 order__items">
                        <div>
                          Order Placed on
                          {' ' + getOrderPlaced(currentOrder)}
                        </div>
                        <div>
                          <u>
                            <span>Help</span>
                          </u>
                        </div>
                      </div>

                      <div className="row order__content1 p-3">
                        <div className=" col-4 pl-0">
                          <img
                            src={
                              currentOrderItem.product_id.image.length > 0
                                ? currentOrderItem.product_id.image[0]
                                : currentOrderItem.product_id.image
                            }
                            alt=""
                            width="100%"
                          />
                        </div>
                        <div className="col-8 order__info p-0">
                          <div className="order__name">
                            Cancelled on
                            {' ' + cancelledDate}
                          </div>
                          <div className="order__desc mt-3">{currentOrderItem.product_id.name}</div>
                          <div className="order__sold">
                            Sold by:{' '}
                            {currentOrderItem.vendor_id ? currentOrderItem.vendor_id.name : '---'}
                          </div>
                          <div className="order__price">
                            Rs.{' '}
                            {new Intl.NumberFormat('en-IN').format(currentOrderItem.order_value)}
                          </div>
                          <div className="order__id">ORDER # {currentOrderItem.order_id}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className=' cancelBody'> */}
                  <div className="row justify-content-center">
                    <div className="col-11" style={{ borderBottom: '0.5px solid #7372726b' }}></div>
                  </div>
                  <div className="row p-3 cancelBody ">
                    <div className=" col-lg-3 col-md-8 col-sm-6 p-0"></div>
                    <div className="col-lg-9 col-md-9 col-sm-12  order__info">
                      <div className="row pr-2 pl-2">
                        <span>
                          Your request is being processed. It may take up to 4 hours for your
                          refund(if any) to be initiated.
                        </span>
                      </div>
                      <div className="row mt-2 pl-2">
                        <span>
                          {cancelledDate} | {cancelledTime}{' '}
                        </span>
                      </div>
                      <div className="row mt-2 pl-2">
                        <span>Item cancellation requested by you</span>
                      </div>
                      <div className="col mt-2 mb-2 d-flex justify-content-end">
                        <span
                          onClick={() => {
                            setIsCancel(false);
                            setRequestToCancel(false);
                          }}>
                          <u>Go to orders</u>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            </div>
          )}
          {isCancel === false && (
            <>
              <div className="row">
                {redirect === false ? (
                  <div className="page__header">Your Orders</div>
                ) : (
                  <div className="page__header">Your Order: {current_order_id}</div>
                )}
              </div>
              {redirect === false ? (
                <div className="row order__tab">
                  <div
                    className={`${activeTab === 'current' && 'order__tab__active'}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setActiveTab('current');
                    }}>
                    Orders
                  </div>
                  <div
                    className={`${activeTab === 'live' && 'order__tab__active'}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setActiveTab('live');
                    }}>
                    On the way
                  </div>
                  <div
                    className={`${activeTab === 'cancelled' && 'order__tab__active'}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setActiveTab('cancelled');
                    }}>
                    Cancelled Orders
                  </div>
                </div>
              ) : (
                <div
                  className={`${activeTab === 'live' && 'order__tab__active'}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setRedirect(false);
                    history.push('/orders');
                  }}>
                  <u>My Orders</u>
                </div>
              )}
              {orderLoading && (
                <div>
                  <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
                </div>
              )}
              {!orderLoading && (
                <>
                  {activeTab === 'current' ? (
                    <div className="row" style={{ marginTop: 20 }}>

                      {allOrders.map((order, index) => {
                        return order.orderItems.map((data, i) => {
                          if (data.status !== 'CANCELLED' && data.is_archived !== true) {
                            return (
                              <div key={`orderItem-${i}`} className="col-12 p-0 order__container">
                                <div className="col-12 order__items">
                                  <div>
                                    Order Placed on {getOrderPlaced(order)}
                                    <span className="vl m-2 pl-2">{getOrderStatus(data)}</span>
                                  </div>
                                  {/* <div className='vl'>something</div> */}
                                  <div className="order__actions" style={{ cursor: 'pointer' }}>
                                    <div
                                      onClick={() => {
                                        setCurrentOrder({ ...order });
                                        setCurrentOrderItem({ ...data });
                                        setDrawer('true');
                                      }}>
                                      <span>
                                        {data.status !== 'CANCELLED'
                                          ? 'Order Details'
                                          : 'Refund Status'}
                                      </span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        if (order.zoho_invoice_url) {
                                          window.open(order.zoho_invoice_url);
                                        } else {
                                          setSnack(true);
                                          setSnackMessage('No invoice has been created');
                                        }
                                      }}>
                                      <span> {data.status !== 'CANCELLED' && 'Invoice'}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row order__content m-0">
                                  <div className="col-4 col-lg-2 p-0">
                                    <img
                                      src={
                                        data?.product_id?.image &&
                                        data?.product_id?.image?.length > 0
                                          ? data?.product_id?.image[0]
                                          : data?.product_id?.image
                                      }
                                      alt=""
                                      width="80%"
                                    />
                                  </div>
                                  <div className="col-8 col-lg-8 order__info">
                                    <div>
                                      <div className="order__name">
                                        {data.status !== 'DELIVERED'
                                          ? 'Arriving on' + ' ' + getdeliveryDate(data)
                                          : 'Your Product has been Delivered'}
                                      </div>
                                      <div className="order__desc mt-3">
                                        {data?.product_id?.name}
                                      </div>
                                      <div className="order__sold">
                                        Sold by: {data.vendor_id ? data?.vendor_id?.name : '---'}
                                      </div>
                                      <div className="order__price">
                                        Rs.{' '}
                                        {new Intl.NumberFormat('en-IN').format(data?.order_value)}
                                      </div>
                                    </div>
                                    <div className="order__id">ORDER # {data?.order_id}</div>
                                  </div>
                                  {data.status === 'DELIVERED' && (
                                    <div className="col-12 col-lg-2 order__act__btns">
                                      <div
                                        className="order__act__btn2"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          setReviewModal(true);
                                          setCurrentOrder({ ...order });
                                          setCurrentOrderItem({ ...data });
                                        }}>
                                        Rate & Review
                                      </div>
                                    </div>
                                  )}
                                  {data.status === 'CANCELLED' && (
                                    <div className="col-12 col-lg-2 order__act__btns">
                                      <div
                                        className="order__act__btn2"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          archieveOrder(data, index, activeTab);
                                        }}>
                                        Remove from the list
                                      </div>
                                    </div>
                                  )}
                                  {data.status !== 'DELIVERED' && data.status !== 'CANCELLED' && (
                                    <div className="col-12 col-lg-2 order__act__btns">
                                      <div
                                        className="order__act__btn1"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          // history.push(order.tracking_link)
                                          setTrackModal(true);
                                          setCurrentOrderItem({ ...data });
                                        }}>
                                        Track package
                                      </div>
                                      {data.status === 'NEW' && checkOrderTime(data) ? (
                                        <div
                                          className="order__act__btn"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            setCurrentOrderIndex(index);
                                            setCurrentOrderItem({ ...data });
                                            setCurrentOrder({ ...order });
                                            setCancelStepOne(true);
                                            setIsCancel(true);
                                          }}>
                                          Request Cancellation
                                        </div>
                                      ) : null}
                                      {/* <div className="order__act__btn" style={{ cursor: 'pointer' }} onClick={() => {
                                      archieveOrder(data, index, activeTab)
                                    }}>Archive Orders</div> */}
                                    </div>
                                  )}
                                  {currentOrder && currentOrderItem && (
                                    <div className="drawer1">
                                      <Drawer
                                        anchor={dimension[0] > 500 ? 'right' : 'left'}
                                        open={currentOrder && drawer}
                                        hideBackdrop={true}
                                        classes={{
                                          paper:
                                            dimension[0] > 500 ? classes1.paper : classes2.paper
                                        }}
                                        className="MuiBackdrop-root">
                                        <div className="">
                                          <div className="row">
                                            <div className="order__id d-flex col-6">
                                              ORDER # {data.order_id}
                                            </div>
                                            <div className=" order__id d-flex col-6 justify-content-end">
                                              <Icon.XCircleFill
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  setDrawer(false);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <br></br>
                                          <div className="drawer1Title">
                                            Order Placed on {getOrderPlaced(currentOrder)}
                                          </div>
                                          <div className="row order__content m-0">
                                            <div className="col-4 col-lg-2 p-0">
                                              <img
                                                src={
                                                  currentOrderItem &&
                                                  currentOrderItem.product_id.image.length > 0
                                                    ? currentOrderItem.product_id.image[0]
                                                    : currentOrderItem.product_id.image
                                                }
                                                alt=""
                                                width="80%"
                                              />
                                            </div>
                                            <div className="col-8 col-lg-8 order__info">
                                              <div>
                                                <div className="order__name">
                                                  {data.status !== 'DELIVERED'
                                                    ? 'Arriving on' +
                                                      ' ' +
                                                      getdeliveryDate(currentOrderItem)
                                                    : 'Your Product has been Delivered'}
                                                </div>
                                                <div className="order__desc mt-3">
                                                  {currentOrderItem.product_id.name}
                                                </div>
                                                <div className="order__sold">
                                                  Sold by:{' '}
                                                  {currentOrderItem.vendor_id
                                                    ? currentOrderItem.vendor_id.name
                                                    : '---'}
                                                </div>
                                                <div className="order__price">
                                                  Rs.{' '}
                                                  {new Intl.NumberFormat('en-IN').format(
                                                    currentOrderItem.order_value
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr></hr>
                                        </div>

                                        <div className="drawer1Title">Order Summary</div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Item Subtotal
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs.{' '}
                                            {new Intl.NumberFormat('en-IN').format(
                                              currentOrderItem.order_value
                                            )}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Shipping
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs. 0
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Discount
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            {data.pixel_discount
                                              ? `Rs. ${new Intl.NumberFormat('en-IN').format(
                                                  currentOrderItem.pixel_discount
                                                )}`
                                              : 0}
                                          </div>
                                          <hr></hr>
                                        </div>
                                        <hr></hr>
                                        {currentOrderItem.status === 'CANCELLED' ? (
                                          <>
                                            <div className=" d-flex justify-content-start drawer1Title">
                                              <Icon.CheckCircleFill color="#35C629" />
                                              <span className="pl-2"> Refund Completed</span>
                                            </div>
                                            <hr></hr>
                                          </>
                                        ) : (
                                          <>
                                            <div className=" d-flex justify-content-start drawer1Title">
                                              Shipping Address
                                            </div>
                                            <div className="d-flex justify-content-start drawer1Content">
                                              {currentOrder.address_id.address.line1} ,{' '}
                                              {currentOrder.address_id.address.line2} ,
                                              {currentOrder.address_id.address.city}
                                              <br></br>
                                              {currentOrder.address_id.address.state} ,{' '}
                                              {currentOrder.address_id.address.postcode}
                                              <hr></hr>
                                            </div>
                                            <hr></hr>
                                          </>
                                        )}

                                        <div className=" d-flex justify-content-start drawer1Title">
                                          Payment Method
                                        </div>
                                        <div className=" d-flex justify-content-start drawer1Content">
                                          Debit Card
                                        </div>
                                      </Drawer>
                                    </div>
                                  )}
                                </div>
                                <div className="row"></div>
                              </div>
                            );
                          }
                        });
                      })}

{allPackages.map((pack, indx) => {
                        return (
                          pack.order_items &&
                          pack.order_items.length > 0 &&
                          pack.order_items.map((data1, ind) => {
                            return (
                              <div key={`package-${ind}`} className="col-12 p-0 order__container">
                                <div className="col-12 order__items">
                                  <div>Order Placed on {getOrderPlaced(pack)}</div>
                                  {/* <div className='vl'>something</div> */}
                                  <div className="order__actions" style={{ cursor: 'pointer' }}>
                                    <div
                                      onClick={() => {
                                        setCurrentPackageOrder({ ...pack });
                                        setCurrentPackageItem({ ...data1 });
                                        // if (pack.address_id) {
                                        //   setPackageAddress(pack.address_id)
                                        // }
                                        setDrawer1('true');
                                      }}>
                                      <span>Order Details</span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        if (pack.zoho_invoice_url) {
                                          window.open(pack.zoho_invoice_url);
                                        } else {
                                          setSnack(true);
                                          setSnackMessage('No invoice has been created');
                                        }
                                      }}>
                                      <span>Invoice</span>
                                    </div>
                                    {/* <div> <a target="_blank" style={{ color: 'black' }} href={pack.zoho_invoice_url ? pack.zoho_invoice_url : 'http://localhost:3000'}>Invoice</a></div> */}
                                  </div>
                                </div>
                                <div className="row order__content m-0">
                                  <div className="col-4 col-lg-2 p-0">
                                    <img
                                      src={packages && getPackageImage(packages, data1)}
                                      alt=""
                                      width="80%"
                                    />
                                  </div>
                                  <div className="col-8 col-lg-8 order__info">
                                    <div>
                                      <div className="order__name">Package {data1.title}</div>
                                      <div className="order__desc mt-3">
                                        Room Type: {data1.room}
                                      </div>
                                      {/* <div className="order__sold">Sold by: {data.vendor_id ? data.vendor_id.name : '---'}</div> */}
                                      <div className="order__price">
                                        Rs. {new Intl.NumberFormat('en-IN').format(data1.price)}
                                      </div>
                                    </div>
                                    <div className="order__id">ORDER # {pack._id}</div>
                                  </div>
                                  <div className="col-12 col-lg-2 order__act__btns">
                                    <div
                                      className="order__act__btn1"
                                      style={{ cursor: 'pointer' }}
                                      onClick={async () => {
                                        const res = await customerApiInstance.get(
                                          `${process.env.REACT_APP_BACKEND_URL}/api/project-by-order`,
                                          {
                                            params: {
                                              order_sub_id: data1.order_sub_id,
                                              order_id: pack.order_id
                                            }
                                          }
                                        );
                                        if (res.data._id) {
                                          history.push(`/rooms/${res.data._id}`);
                                        }
                                      }}>
                                      View Project
                                    </div>
                                  </div>
                                  {currentPackageOrder && currentPackageItem && (
                                    <div className="drawer1">
                                      <Drawer
                                        anchor={dimension[0] > 500 ? 'right' : 'left'}
                                        open={currentPackageOrder && drawer1}
                                        classes={{
                                          paper:
                                            dimension[0] > 500 ? classes1.paper : classes2.paper
                                        }}
                                        className="MuiBackdrop-root">
                                        <div className="">
                                          <div className="row">
                                            <div className="order__id d-flex col-6">
                                              ORDER # {pack._id}
                                            </div>
                                            <div className=" order__id d-flex col-6 justify-content-end">
                                              <Icon.XCircleFill
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  setDrawer1(false);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <br></br>
                                          <div className="drawer1Title">
                                            Order Placed on {getOrderPlaced(pack)}
                                          </div>
                                          <div className="row order__content m-0">
                                            <div className="col-4 col-lg-2 p-0">
                                              {/* <img src={currentOrderItem && currentOrderItem.product_id.image.length > 0 ? currentOrderItem.product_id.image[0] : currentOrderItem.product_id.image} alt="" width="80%" /> */}
                                              <img
                                                src={
                                                  packages && currentPackageItem
                                                    ? getPackageImage(packages, currentPackageItem)
                                                    : null
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="col-8 col-lg-8 order__info ml-5">
                                              <div>
                                                <div className="order__desc">
                                                  P&M Package {currentPackageItem.title}
                                                </div>

                                                <div className="order__sold">
                                                  Room Type: {currentPackageItem.room}
                                                </div>
                                                <div className="order__price mt-5">
                                                  Rs.{' '}
                                                  {new Intl.NumberFormat('en-IN').format(
                                                    currentPackageItem.price
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr></hr>
                                        </div>

                                        <div className="drawer1Title">Order Summary</div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Item Subtotal
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs.{' '}
                                            {new Intl.NumberFormat('en-IN').format(
                                              currentPackageItem.price
                                            )}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Shipping
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs. 0
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Discount
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs. 0
                                          </div>
                                          <hr></hr>
                                        </div>
                                        <hr></hr>
                                        <div className=" d-flex justify-content-start drawer1Title">
                                          Shipping Address
                                        </div>
                                        <div className="d-flex justify-content-start drawer1Content">
                                          {pack.address_id && pack.address_id.address.line1} ,{' '}
                                          {pack.address_id && pack.address_id.address.line2} ,
                                          {pack.address_id && pack.address_id.address.city}
                                          <br></br>
                                          {pack.address_id && pack.address_id.address.state} ,{' '}
                                          {pack.address_id && pack.address_id.address.postcode}
                                          <hr></hr>
                                        </div>
                                        <hr></hr>

                                        <div className=" d-flex justify-content-start drawer1Title">
                                          Payment Method
                                        </div>
                                        <div className=" d-flex justify-content-start drawer1Content">
                                          Debit Card
                                        </div>
                                      </Drawer>
                                    </div>
                                  )}
                                </div>
                                <div className="row"></div>
                              </div>
                            );
                          })
                        );
                      })}
                    </div>
                  ) : activeTab === 'live' ? (
                    <div className="row" style={{ marginTop: 20 }}>
                      {currentOrders.map((order, index) => {
                        return order.orderItems.map((data, i) => {
                          if (data.status !== 'CANCELLED' && data.is_archived !== true) {
                            return (
                              <div key={i} className="col-12 p-0 order__container">
                                <div className="col-12 order__items">
                                  <div>
                                    Order Placed on {getOrderPlaced(order)}{' '}
                                    <span className="vl m-2 pl-2">{getOrderStatus(data)}</span>
                                  </div>
                                  <div className="order__actions">
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentOrder({ ...order });
                                        setCurrentOrderItem({ ...data });
                                        setDrawer('true');
                                      }}>
                                      <span>Order Details</span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        if (order.zoho_invoice_url) {
                                          window.open(order.zoho_invoice_url);
                                        } else {
                                          setSnack(true);
                                          setSnackMessage('No invoice has been created');
                                        }
                                      }}>
                                      <span>Invoice</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row order__content m-0">
                                  <div className="col-4 col-lg-2 p-0">
                                    <img
                                      src={
                                        data?.product_id?.image?.length > 0
                                          ? data.product_id.image[0]
                                          : data?.product_id?.image
                                      }
                                      alt=""
                                      width="80%"
                                    />
                                  </div>
                                  <div className="col-8 col-lg-8 order__info">
                                    <div>
                                      <div className="order__name">
                                        {data.status !== 'DELIVERED'
                                          ? 'Arriving on' + ' ' + getdeliveryDate(data)
                                          : 'Your Product has been Delivered'}
                                      </div>
                                      <div className="order__desc mt-3">
                                        {data?.product_id?.name}
                                      </div>
                                      <div className="order__sold">
                                        Sold by: {data.vendor_id ? data.vendor_id?.name : '---'}
                                      </div>
                                      <div className="order__price">
                                        Rs.{' '}
                                        {new Intl.NumberFormat('en-IN').format(data.order_value)}
                                      </div>
                                    </div>
                                    <div className="order__id">ORDER # {data.order_id}</div>
                                  </div>
                                  <div className="col-12 col-lg-2 order__act__btns">
                                    <div
                                      className="order__act__btn1"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setTrackModal(true);
                                        setCurrentOrderItem({ ...data });
                                      }}>
                                      Track package
                                    </div>
                                    {data.status !== 'ACCEPTED' && checkOrderTime(data) ? (
                                      <div
                                        className="order__act__btn"
                                        onClick={() => {
                                          setCurrentOrderIndex(index);
                                          setCurrentOrderItem({ ...data });
                                          setCurrentOrder({ ...order });
                                          setCancelStepOne(true);
                                          setIsCancel(true);
                                        }}>
                                        Request Cancellation
                                      </div>
                                    ) : null}
                                    {/* <div className="order__act__btn" style={{ cursor: 'pointer' }} onClick={() => {
                                      archieveOrder(data, index, activeTab)
                                    }}>Archive Orders</div> */}
                                  </div>
                                  {currentOrder && currentOrderItem && (
                                    <div className="drawer1">
                                      <Drawer
                                        anchor={dimension[0] > 500 ? 'right' : 'left'}
                                        open={currentOrderItem && drawer}
                                        hideBackdrop={true}
                                        classes={{
                                          paper:
                                            dimension[0] > 500 ? classes1.paper : classes2.paper
                                        }}
                                        className="MuiBackdrop-root">
                                        <div className="">
                                          <div className="row">
                                            <div className="order__id d-flex col-6">
                                              ORDER # {data.order_id}
                                            </div>
                                            <div className=" order__id d-flex col-6 justify-content-end">
                                              <Icon.XCircleFill
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  setDrawer(false);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <br></br>
                                          <div className="drawer1Title">
                                            Order Placed on {getOrderPlaced(currentOrderItem)}
                                          </div>
                                          <div className="row order__content m-0">
                                            <div className="col-4 col-lg-2 p-0">
                                              <img
                                                src={
                                                  currentOrderItem?.product_id?.image?.length > 0
                                                    ? currentOrderItem.product_id.image[0]
                                                    : currentOrderItem?.product_id?.image
                                                }
                                                alt=""
                                                width="80%"
                                              />
                                            </div>
                                            <div className="col-8 col-lg-8 order__info">
                                              <div>
                                                <div className="order__name">
                                                  {data.status !== 'DELIVERED'
                                                    ? 'Arriving on' +
                                                      ' ' +
                                                      getdeliveryDate(currentOrderItem)
                                                    : 'Your Product has been Delivered'}
                                                </div>
                                                <div className="order__desc mt-3">
                                                  {currentOrderItem?.product_id?.name || ''}
                                                </div>
                                                <div className="order__sold">
                                                  Sold by:{' '}
                                                  {currentOrderItem.vendor_id
                                                    ? currentOrderItem?.vendor_id?.name
                                                    : '---'}
                                                </div>
                                                <div className="order__price">
                                                  Rs.{' '}
                                                  {new Intl.NumberFormat('en-IN').format(
                                                    currentOrderItem.order_value
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr></hr>
                                        </div>

                                        <div className="drawer1Title">Order Summary</div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Item Subtotal
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs.{' '}
                                            {new Intl.NumberFormat('en-IN').format(
                                              currentOrderItem.order_value
                                            )}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Shipping
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs. 0
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Discount
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            {data.pixel_discount
                                              ? `Rs. ${new Intl.NumberFormat('en-IN').format(
                                                  currentOrderItem.pixel_discount
                                                )}`
                                              : 0}
                                          </div>
                                          <hr></hr>
                                        </div>
                                        <hr></hr>
                                        <div className=" d-flex justify-content-start drawer1Title">
                                          Shipping Address
                                        </div>
                                        <div className="d-flex justify-content-start drawer1Content">
                                          {currentOrder.address_id.address.line1} ,{' '}
                                          {currentOrder.address_id.address.line2} ,
                                          {currentOrder.address_id.address.city}
                                          <br></br>
                                          {currentOrder.address_id.address.state} ,{' '}
                                          {currentOrder.address_id.address.postcode}
                                          <hr></hr>
                                        </div>
                                        <hr></hr>
                                        <div className=" d-flex justify-content-start drawer1Title">
                                          Payment Method
                                        </div>
                                        <div className=" d-flex justify-content-start drawer1Content">
                                          Debit Card
                                        </div>
                                      </Drawer>
                                    </div>
                                  )}
                                </div>
                                <div className="row"></div>
                              </div>
                            );
                          }
                        });
                      })}
                    </div>
                  ) : (
                    <div className="row" style={{ marginTop: 20 }}>
                      {cancelledOrders.length > 0 &&
                        cancelledOrders.map((data, i) => {
                          if (data.is_archived !== true) {
                            return (
                              <div key={i} className="col-12 p-0 order__container">
                                <div className="col-12 order__items">
                                  <div>Order Placed on {getOrderPlaced(data)}</div>
                                  <div className="order__actions">
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentOrderItem({ ...data });
                                        getCancelledOrder(data);
                                        setDrawer('true');
                                      }}>
                                      Refund Status
                                    </div>
                                  </div>
                                </div>
                                <div className="row order__content m-0">
                                  <div className="col-4 col-lg-2 p-0">
                                    <img
                                      src={
                                        data.product_id?.image?.length > 0
                                          ? data.product_id?.image[0]
                                          : data?.product_id?.image
                                      }
                                      alt=""
                                      width="80%"
                                    />
                                  </div>
                                  <div className="col-8 col-lg-8 order__info">
                                    <div>
                                      <div className="order__name">
                                        Cancelled on
                                        {' ' + getCancelledDate(data)}
                                      </div>
                                      <div className="order__desc mt-3">
                                        {data.product_id?.name}
                                      </div>
                                      <div className="order__sold">
                                        Sold by: {data.vendor_id ? data.vendor_id?.name : '---'}
                                      </div>
                                      <div className="order__price">
                                        Rs.{' '}
                                        {new Intl.NumberFormat('en-IN').format(data.order_value)}
                                      </div>
                                    </div>
                                    <div className="order__id">ORDER # {data.order_id}</div>
                                  </div>
                                  <div className="col-12 col-lg-2 order__act__btns">
                                    <div
                                      className="order__act__btn"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        archieveOrder(data, i, activeTab);
                                      }}>
                                      Remove from the list
                                    </div>
                                  </div>
                                  {currentOrder && currentOrderItem && (
                                    <div className="drawer1">
                                      <Drawer
                                        anchor={dimension[0] > 500 ? 'right' : 'left'}
                                        open={currentOrderItem && drawer}
                                        hideBackdrop={true}
                                        classes={{
                                          paper:
                                            dimension[0] > 500 ? classes1.paper : classes2.paper
                                        }}
                                        className="MuiBackdrop-root">
                                        <div className="">
                                          <div className="row">
                                            <div className="order__id d-flex col-6">
                                              ORDER # {data.order_id}
                                            </div>
                                            <div className=" order__id d-flex col-6 justify-content-end">
                                              <Icon.XCircleFill
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  setDrawer(false);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <br></br>
                                          <div className="drawer1Title">
                                            Order Placed on {getOrderPlaced(currentOrderItem)}
                                          </div>
                                          <div className="row order__content m-0">
                                            <div className="col-4 col-lg-2 p-0">
                                              <img
                                                src={
                                                  currentOrderItem.product_id.image.length > 0
                                                    ? currentOrderItem.product_id.image[0]
                                                    : currentOrderItem.product_id.image
                                                }
                                                alt=""
                                                width="80%"
                                              />
                                            </div>
                                            <div className="col-8 col-lg-8 order__info">
                                              <div>
                                                <div className="order__name"></div>
                                                <div className="order__desc mt-3">
                                                  {currentOrderItem.product_id.name}
                                                </div>
                                                <div className="order__sold">
                                                  Sold by:{' '}
                                                  {currentOrderItem.vendor_id
                                                    ? currentOrderItem.vendor_id.name
                                                    : '---'}
                                                </div>
                                                <div className="order__price">
                                                  Rs.{' '}
                                                  {new Intl.NumberFormat('en-IN').format(
                                                    currentOrderItem.order_value
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr></hr>
                                        </div>

                                        <div className="drawer1Title">Order Summary</div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Item Subtotal
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs.{' '}
                                            {new Intl.NumberFormat('en-IN').format(
                                              currentOrderItem.order_value
                                            )}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Shipping
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            Rs. 0
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-start drawer1Content">
                                            Discount
                                          </div>
                                          <div className="col-6 d-flex justify-content-end order__price">
                                            {data.pixel_discount
                                              ? `Rs. ${new Intl.NumberFormat('en-IN').format(
                                                  currentOrderItem.pixel_discount
                                                )}`
                                              : 0}
                                          </div>
                                          <hr></hr>
                                        </div>
                                        <hr></hr>
                                        {currentOrderItem.status === 'CANCELLED' ? (
                                          <>
                                            <div className=" d-flex justify-content-start drawer1Title">
                                              <Icon.CheckCircleFill color="#35C629" />
                                              <span className="pl-2"> Refund Completed</span>
                                            </div>
                                            <hr></hr>
                                          </>
                                        ) : (
                                          <>
                                            <div className=" d-flex justify-content-start drawer1Title">
                                              Shipping Address
                                            </div>
                                            <div className="d-flex justify-content-start drawer1Content">
                                              {currentOrder.address_id.address.line1} ,{' '}
                                              {currentOrder.address_id.address.line2} ,
                                              {currentOrder.address_id.address.city}
                                              <br></br>
                                              {currentOrder.address_id.address.state} ,{' '}
                                              {currentOrder.address_id.address.postcode}
                                              <hr></hr>
                                            </div>
                                            <hr></hr>
                                          </>
                                        )}
                                        <hr></hr>
                                        <div className=" d-flex justify-content-start drawer1Title">
                                          Payment Method
                                        </div>
                                        <div className=" d-flex justify-content-start drawer1Content">
                                          Debit Card
                                        </div>
                                      </Drawer>
                                    </div>
                                  )}
                                </div>
                                <div className="row"></div>
                              </div>
                            );
                          }
                        })}
                      {cancelledOrders.length == 0 && (
                        <div className="text-center w-100 p-5">
                          <p style={{ fontWeight: '600' }}>
                            We aren’t finding any cancelled orders.{' '}
                            <span
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}>
                              View orders
                            </span>{' '}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={reviewModal}
        onHide={() => setReviewModal(false)}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className="modalHeader">
          <h4 className="mt-3 ml-5">Rate & Review</h4>
          <img
            className="mr-5 mt-4"
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setReviewModal(false)}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>
        <div className="modalRow">
          <div className="mt-3 ml-3 mr-3 cancelBody">
            <div className="row pt-3 ml-4"> Rate this product</div>
            <div className="row mt-3 pb-3 ml-2">
              <Rating
                name="moodboardRating"
                emptySymbol={
                  <img
                    src="https://d3izcfql7lesks.cloudfront.net/Path_1252_ixllee.svg"
                    style={{ width: '40px' }}
                    className="pl-3"
                  />
                }
                fullSymbol={
                  <img
                    src="https://d3izcfql7lesks.cloudfront.net/Path_1252_dp7bmx.svg"
                    style={{ width: '40px' }}
                    className="pl-3"
                  />
                }
                onClick={value => {
                  setCustomerRating(value);
                }}
                initialRating={customerRating}
              />
            </div>
          </div>
          <div className="mt-2 ml-3 mr-3  cancelBody">
            <div className="row pt-3 ml-4"> Review this product</div>
            <div className="row mt-3 ml-4 mr-4 bordered">
              <div className="pt-3 pl-3"> Description:</div>
              <div>
                <input
                  className="textBox2"
                  style={{ border: 'none' }}
                  type="textarea"
                  value={
                    currentOrderItem &&
                    currentOrderItem.product_id?.review &&
                    currentOrderItem.product_id?.review?.description &&
                    currentOrderItem.product_id?.review?.description
                      ? currentOrderItem.product_id?.review?.description
                      : productReviewDescription
                  }
                  onChange={e => setProductReviewDescription(e.target.value)}></input>
              </div>
            </div>
            <div className="row ml-4 mr-4 bordered">
              <div className="pt-3 pl-3"> Title(optional):</div>
              <div>
                {' '}
                <input
                  className="textBox3"
                  label="title"
                  style={{ border: 'none' }}
                  type="textarea"
                  value={
                    currentOrderItem &&
                    currentOrderItem?.product_id?.review &&
                    currentOrderItem.product_id?.review?.title &&
                    currentOrderItem.product_id?.review?.title
                      ? currentOrderItem.product_id?.review?.title
                      : productReviewTitle
                  }
                  onChange={e => setProductReviewTitle(e.target.value)}></input>
              </div>
            </div>
            <div className="row ml-4 mr-4 bordered">
              <div className="pt-3 pl-3"> Name:</div>
              <div>
                {' '}
                <input
                  className="textBox3"
                  label="Name"
                  style={{ border: 'none' }}
                  type="textarea"
                  value={
                    currentOrderItem &&
                    currentOrderItem.product_id?.review &&
                    currentOrderItem.product_id?.review.name &&
                    currentOrderItem.product_id?.review.name
                      ? currentOrderItem.product_id?.review?.name
                      : productReviewName
                  }
                  onChange={e => setProductReviewName(e.target.value)}></input>
              </div>
            </div>
            {/* <div className='row mt-5 mb-5'>
              <div classNam='col-lg-8 col-md-8'></div> */}
            <div className=" col-12 d-flex justify-content-end">
              <button
                className="continueButton mb-5 mt-3 ml-4 mr-2 "
                onClick={() => {
                  reviewProduct(currentOrderItem);
                }}>
                Submit
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      </Modal>
      <Modal1
        style={{ backgroundColor: 'transparent' }}
        show={trackModal}
        onClose={() => setTrackModal(false)}
        backdrop={true}
        centered
        // dialogClassName={classnames('d-flex justify-content-center')}
        keyboard={false}>
        <ModalHeader>
          <ModalTitle id="contained-modal-title-vcenter">Tracking Information</ModalTitle>
          <div className="float-right">
            <Icon.XCircle onClick={() => setTrackModal(false)} />{' '}
          </div>
        </ModalHeader>
        <ModalBody className="pr-4 pl-4">
          <label>
            <b>Tracking ID:</b>
          </label>
          <p>
            {currentOrderItem && currentOrderItem.shipment && currentOrderItem.shipment.length > 0
              ? currentOrderItem.shipment[0].tracking_id
              : 'NA'}
          </p>
          <input
            ref={copy}
            type="hidden"
            value={
              currentOrderItem && currentOrderItem.shipment && currentOrderItem.shipment.length > 0
                ? currentOrderItem.shipment[0].tracking_link
                : ''
            }
          />

          <div className=" d-flex">
            <div className="col p-0">
              <p>
                {' '}
                <b>Tracking Link</b>
              </p>
            </div>

            <div className="float-right" style={{ cursor: 'pointer' }}>
              <Icon.Link
                onClick={() => {
                  handleShareLink();
                }}
              />
            </div>
          </div>
          <p
            onClick={() => {
              if (
                currentOrderItem &&
                currentOrderItem.shipment &&
                currentOrderItem.shipment.length > 0 &&
                currentOrderItem.shipment[0].tracking_link
              ) {
                window.open(currentOrderItem.shipment[0].tracking_link);
              } else {
                return '';
              }
            }}
            style={{ fontSize: '14px', fontWeight: '700' }}>
            <u>
              {currentOrderItem && currentOrderItem.shipment && currentOrderItem.shipment.length > 0
                ? currentOrderItem.shipment[0].tracking_link
                : 'NA'}
            </u>
          </p>
        </ModalBody>
      </Modal1>
    </>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps)(withRouter(MyOrders));
function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
