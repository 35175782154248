import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import style from './Explore.module.css';
import Header from '../../components/Header/Header';
import axios from 'axios';
import { useHistory } from 'react-router';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import { Image } from 'semantic-ui-react';
import arrow from '../../assets/images/Iconionic-ios.png';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: '50px'
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '6px',
        height: '10px',
        marginLeft: '11px'
      }
    },
    paper: {
      width: '90%',
      overflow: 'hidden',
      margin: '0px auto'
    },
    imagefit: {
      width: '200px',
      height: '200px',
      objectFit: 'contain',
      cursor: 'pointer'
    }
  })
);

function ExploreTagsCategory() {
  const { tags, tagid } = useParams();
  const dimension = useMediaQuery();
  const history = useHistory();
  const classes = useStyles();
  const [categoryData, setCategoryData] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [breadCrumb, setBreadcrumb] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBreadcrumb(tags.split('&'));
    getTags();
  }, [tagid]);

  function getChildTag(alltagArr, tagId, type) {
    let child = alltagArr.filter(tag => {
      return tag.parent_id == tagId && tag.type.indexOf(type) > -1;
    });
    return child;
  }

  function getTags() {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/v1/tags`)
      .then(res => {
        let allTags = res.data.data;
        setAllTags(allTags);
        setLoading(false);
        let child = getChildTag(allTags, tagid, 'explore');
        setCategoryData(child);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <div style={{ minHeight: '100vh' }}>
        <div className={classnames('container', style.container)}>
          <div className={classnames(style.menu)}>
            <span
              onClick={() => {
                history.push(`/explore`);
              }}>
              Home
            </span>{' '}
            {breadCrumb &&
              breadCrumb.map((elem, index) => (
                <>
                  {'>'}{' '}
                  <span
                    key={index}
                    onClick={() => {
                      history.go(index + 1 - breadCrumb.length);
                    }}>
                    {elem}
                  </span>
                </>
              ))}
          </div>
          <div className={classnames(style.categoryHeading)}>
            {breadCrumb.length > 0 && breadCrumb[breadCrumb.length - 1].toUpperCase()}
          </div>
          <div style={{ width: dimension[0] > 700 ? '50%' : '100%' }}>
            Make room for some furniture Inspiration! Find everything for your home under one roof.
            From your bedroom to your balcony, we've got you covered!..
          </div>
          <div className="row" style={{ marginTop: '1.5rem' }}>
            {loading && (
              <div className="d-flex justify-content-center mt-4 w-100">
                <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
              </div>
            )}

            {!loading &&
              categoryData.map((data, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="col-xl-3 col-lg-3 col-md-4 col-6"
                      style={{ paddingTop: '15px' }}>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (getChildTag(allTags, data._id, 'explore').length > 0) {
                            history.push(`/explore-tag/${tags + '&' + data.name}/${data._id}`);
                          } else {
                            history.push(`/explore-tag/${tags + '&' + data.name}`);
                          }
                        }}>
                        <Paper className={classes.paper}>
                          <Image
                            draggable={false}
                            className={classes.imagefit}
                            src={data?.image ? data.image : ''}
                          />
                        </Paper>
                        <p
                          style={{ fontFamily: 'Quicksand', cursor: 'pointer' }}
                          className={classnames(style.displayrow, 'tagname')}>
                          {data.name} <img src={arrow} className={classes.imgtag} />
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExploreTagsCategory;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
