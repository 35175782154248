import React, { useState } from 'react';
import style from './RoomCard.module.css';

function RoomCard({ image, hoverImage, hover, title, height, width, onClick, sx, sxImage }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      className={style.roomCard}
      style={{
        height: height,
        width: width,
        ...sx,
        backgroundColor: hover && isHovering ? ' #949A86' : '#ffffff'
      }}
      onClick={onClick}
      onMouseEnter={hover ? handleMouseEnter : undefined}
      onMouseLeave={hover ? handleMouseLeave : undefined}
      onTouchStart={hover ? handleMouseEnter : undefined}
      onTouchEnd={hover ? handleMouseLeave : undefined}
      onTouchCancel={hover ? handleMouseLeave : undefined}>
      <img
        src={isHovering && hover && hoverImage ? hoverImage : image}
        className={style.cardImage}
        style={sxImage}
      />
      <div className="w-100 text-center">
        <span
          style={{
            fontWeight: '700',
            // fontSize:'12px',
            color: isHovering && hover ? '#ffffff' : '#171717'
          }}>
          {title}
        </span>
      </div>
    </div>
  );
}

export default RoomCard;
