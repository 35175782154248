import React, { useEffect } from 'react';
import classnames from 'classnames';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './CareerApply.module.css';
const CareerApply = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={style.mainContainer}>
      <Header />
      <div className={style.container} style={{ paddingTop: '3.8%' }}>
        <div
          className="container d-flex flex-column justify-content-start align-items-start"
          style={{ paddingTop: '120px', paddingBottom: '120px' }}>
          <h1>Interior Designer</h1>
          <p style={{ width: '30vw' }}>
            Are your days spent perusing the internet for new trends in home decor and furnishing,
            and then pinning your findings to one of your many Pinterest boards? Do you create mood
            boards to redecorate the rooms in your house or your friends each season? Are you
            constantly finding ways to go above and beyond for your design clients until they are
            besotted with their beautiful new room?
          </p>
        </div>
      </div>
      <div className="container mt-5">
        <div className="d-flex flex-column">
          <h5>If this describes you, then P&M Designer might be a great fit!</h5>
          <p>
            P&M is an online interior design service, looking to make home design dreams come true
            for all budgets and styles. We are growing our e-Design team!
          </p>
        </div>
        <div className={classnames(style.divider, 'mt-5')} />
        <div className="d-flex flex-column mt-5">
          <h5>What exactly does a Designer do?</h5>
          <ul style={{ marginLeft: '-20px', marginTop: '10px', lineHeight: '32px' }}>
            <li>
              Works with others on the design team and the executive team, through the P&M platform
              to create beautiful designs for clients pan-India.
            </li>
            <li>
              Maintains good relations with a wide range of vendors and sources furnishings,
              furniture and decor from them.
            </li>
            <li>
              Creates unique client experiences and are always searching for fresh ways to meet
              client requirements.
            </li>
            <li>
              Updated on all social media-related design events and is a big part of local and
              online communities where they bring to light the brand and service.
            </li>
            <li>Follows trends and news related to interior design.</li>
            <li>
              Communicates proactively and in a timely fashion with customers, especially responding
              to their inquiries
            </li>
            <li>
              Gives suggestions and constructive feedback to the P&M team so we can continually
              improve the whole experience.
            </li>
          </ul>
        </div>
        <div className={classnames(style.divider, 'mt-5')} />
        <div className="d-flex flex-column mt-5">
          <h5>Background on the P&M Online Design Team:</h5>
          <ul style={{ marginLeft: '-20px', marginTop: '10px', lineHeight: '32px' }}>
            <li>
              <span style={{ fontWeight: 900 }}>Professionals with Experience - </span>in interior
              design, customer service, and sales (Interior design degree needed)
            </li>
            <li>
              <span style={{ fontWeight: 900 }}>Software Pros - </span>Proficiency in the use of
              Adobe creative software like Photoshop, Illustrator, _____ and can create a room
              rendering from scratch.
            </li>
            <li>
              <span style={{ fontWeight: 900 }}>Technically Skilled - </span>Comfortable with new
              technologies and navigating online software and systems.
            </li>
            <li>
              <span style={{ fontWeight: 900 }}>Design Savvy - </span>Seek to create interesting and
              unique India-centric designs that only an experienced designer could craft. Also
              familiarity with popular India-based retailers and vendors.
            </li>
            <li>
              <span style={{ fontWeight: 900 }}>Driven - </span>Passionate about working
              independently growing your own business. This is an opportunity where we provide
              support and resources for all that you do.
            </li>
          </ul>
        </div>
        <div className={classnames(style.divider, 'mt-5')} />
        <div className="d-flex flex-column mt-5">
          <h5>Why you’ll love being an Online Designer for P&M?</h5>
          <ul style={{ marginLeft: '-20px', marginTop: '10px', lineHeight: '32px' }}>
            <li>The opportunity to build your personal brand, doing what you love.</li>
            <li>
              Work with a range of continuous clients, without spending money on sales and
              marketing, and without having to ever commute for meetings and appointments.
            </li>
            <li>Achieve flexibility and control over your schedule.</li>
            <li>
              Join a community of interior designers across the country who share the same
              interests, passions, skills and experiences.
            </li>
            <li>
              Have access to, source from, and receive trade discounts from hundreds of retail,
              trade and wholesale vendors across the country.
            </li>
          </ul>
        </div>
      </div>
      <div style={{ backgroundColor: '#FAFAFA', paddingTop: '100px', marginTop: '50px' }}>
        <div className="container d-flex flex-column">
          <div class="form-group d-flex flex-column">
            <label for="exampleInputEmail1">Full Name*</label>
            <input
              type="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              className={style.input}
            />
            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
          </div>

          <div class="form-group d-flex flex-column mt-4">
            <label for="exampleInputEmail1">Email*</label>
            <input
              type="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              className={style.input}
            />
            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
          </div>

          <div class="form-group d-flex flex-column mt-4">
            <label for="exampleInputEmail1">Phone*</label>
            <input
              type="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              className={style.input}
            />
            {/* <small style={{ color: 'red' }} id="emailHelp" class="form-text text-muted text-red">Phone field is not in the right format.</small> */}
          </div>

          <div class="form-group d-flex flex-column mt-4">
            <label for="exampleInputEmail1">Resume / CV</label>
            <p style={{ fontSize: '12px', marginTop: '10px' }}>
              <span className={style.underline}>Attach</span> or{' '}
              <span className={style.underline}>Dropbox</span> or{' '}
              <span className={style.underline}>Paste</span>
            </p>
          </div>

          <div class="form-group d-flex flex-column mt-4">
            <label for="exampleInputEmail1">Portfolio</label>
            <p style={{ fontSize: '12px', marginTop: '10px' }}>
              <span className={style.underline}>Attach</span> or{' '}
              <span className={style.underline}>Dropbox</span> or{' '}
              <span className={style.underline}>Paste</span>
            </p>
          </div>

          <div class="form-group d-flex flex-column mt-4">
            <label for="exampleInputEmail1">linkedin Profile</label>
            <input
              type="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              className={style.input}
            />
            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
          </div>

          <div class="form-group d-flex flex-column mt-4">
            <label for="exampleInputEmail1">Portfolio Website / Link</label>
            <input
              type="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              className={style.input}
            />
            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
          </div>
          <button className={style.submitButton}>Submit Application</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CareerApply;
