import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';

const ReferralInduction = () => {
  return (
    <>
      <Header />
      <div
        style={{
          backgroundColor: '#D2EDF5',
          width: '100%',
          height: '100vh',
          marginTop: '75px',
          position: 'relative'
        }}>
        <img
          style={{ position: 'absolute', width: '50%', right: 0, top: 0 }}
          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+2917.png'}
          alt=""
        />
        <img
          style={{
            position: 'absolute',
            width: '60%',
            height: '80%',
            bottom: 0,
            left: 0
          }}
          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Group+2949.png'}
          alt=""
        />
        <img
          style={{
            position: 'absolute',
            width: '40%',

            right: 0,
            bottom: 0
          }}
          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Mask+Group+5.png'}
          alt=""
        />
        <div
          className="container d-flex flex-column justify-content-center align-items-start"
          style={{ height: '100vh' }}>
          <h2>You have the best friends</h2>
          <p>
            Hey Sid has referred you to us!
            <br></br>
            <br></br>
            You get 10% off your first P&M purchase! <br></br>
            Avail it and bring the magic of design to your home.
          </p>
          <br></br>
          <button
            style={{
              border: 'none',
              backgroundColor: '#000000',
              color: '#ffffff',
              borderRadius: '30px',
              width: '140px',
              fontWeight: 700,
              padding: '5px 10px 5px 10px'
            }}>
            Get Started
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReferralInduction;
