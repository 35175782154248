export const furniture_images = [
  {
    title: 'Armchair',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/armchair.png'
  },
  {
    title: 'Dining Table',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/dining_table.png'
  },
  {
    title: 'Swing',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/swing.png'
  },
  {
    title: 'TV Unit',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/media_console.png'
  },
  {
    title: 'Led Tube Lights',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/led_tube_lights.png'
  },
  {
    title: 'Bar Unit',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/barunit.png'
  },
  {
    title: 'Side Table',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/side_table.png'
  },
  {
    title: 'Chandelier',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/chandelier.png'
  },
  {
    title: 'Pendant',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/pendant.png'
  },
  {
    title: 'Wall Scones',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/sconces.png'
  },
  {
    title: 'Book Shelf',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/bookcase.png'
  },
  {
    title: 'Planters',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/planters.png'
  },
  { title: 'Pouf', image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/pouf.png' },
  {
    title: 'Benches',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/benches.png'
  },
  {
    title: 'Recliner',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/recliner.png'
  },
  {
    title: 'Laptop Table',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/laptop_table.png'
  },
  {
    title: 'Study Table',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/study_table.png'
  },
  {
    title: 'Wall Shelf',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/wall_shelf.png'
  },
  {
    title: 'Wall Hanging',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/decor.png'
  },
  {
    title: 'Puja Unit',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/puja_unit.png'
  },
  { title: 'Crib', image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/crib.png' },
  {
    title: 'Paintings',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/wall_treatments.png'
  },
  {
    title: 'Key Hanger',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/key_hanger.png'
  },
  {
    title: 'Decor',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/decor.png'
  },
  { title: 'Fans', image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/fans.png' },
  {
    title: 'Curtains',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/curtains.png'
  },
  {
    title: 'Newspaper Stand',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/news_rack.png'
  },
  {
    title: 'Outdoor Furniture',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/outdoor_furniture.png'
  },
  {
    title: 'Dressing Unit',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/dressing_unit.png'
  },
  { title: 'Bed', image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/bed.png' },
  {
    title: 'Chest of Drawers',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/chest_of_drawers.png'
  },
  {
    title: 'Side Board',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/changing_staion.png'
  },
  {
    title: 'Accent Seating',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/accent_seating.png',
    fake: true
  },
  {
    title: 'Book Case',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/bookcase.png'
  },
  {
    title: 'Chair',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/chair.png',
    fake: true
  },
  {
    title: 'Changing Station',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/changing_staion.png'
  },
  {
    title: 'Coffee Table',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/coffee_table.png',
    fake: true
  },
  {
    title: 'Console Table',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/console_table.png',
    fake: true
  },
  {
    title: 'Crockery Unit',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/crocker_unit.png'
  },
  {
    title: 'Floor Lamp',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/floor_lamp.png'
  },
  {
    title: 'Media Console/TV Unit',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/media_console.png'
  },
  {
    title: 'Media Console',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/media_console.png'
  },
  {
    title: 'News Rack',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/news_rack.png'
  },
  {
    title: 'Rug',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/rug.png',
    fake: true
  },
  {
    title: 'Sconces',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/sconces.png'
  },
  {
    title: 'Sectional',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/sectional.png',
    fake: true
  },
  {
    title: 'Shoe Cabinet',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/shoe_cabinet.png',
    fake: true
  },
  {
    title: 'Sofa',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/sofa.png',
    fake: true
  },
  {
    title: 'Storage Boxes',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/storage_boxes.png',
    fake: true
  },
  {
    title: 'Table Lamp',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/table_lamp.png'
  },
  {
    title: 'Wall Treatments',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/wall_treatments.png'
  }
];

export const FURNITURE_ROOM_OBJECT = {
  'Living Room': [
    'Armchair',
    'Sofa',
    'Coffee Table',
    'Side Table',
    'Swing',
    'Media Console/TV Unit',
    'Rug',
    'Floor Lamp',
    'Table Lamp',
    'Chandelier',
    'Pendant',
    'Book Case',
    'Bar Unit',
    'Console Table',
    'Planters',
    'Pouf',
    'Benches',
    'Recliner',
    'Laptop Table',
    'Study Table',
    'Wall Shelf',
    'Decor',
    'Curtains',
    'Wall Treatments',
    'Fans',
    'Led Tube Lights',
    'Wall Scones'
  ],
  'Dining Room': [
    'Dining Table',
    'Chair',
    'Bar Unit',
    'Crockery Unit',
    'Table Lamp',
    'Chandelier',
    'Pendant',
    'Wall Scones',
    'Decor',
    'Curtains',
    'Wall Treatments',
    'Fans',
    'Led Tube Lights'
  ],
  'Bed Room': [
    'Bed',
    'Book Case',
    'Side Table',
    'Study Table',
    'Chair',
    'Sofa',
    'Armchair',
    'Recliner',
    'Bar Unit',
    'Media Console/TV Unit',
    'Dressing Unit',
    'Pouf',
    'Book Shelf',
    'Benches',
    'Chest of Drawers',
    'Rug',
    'Floor Lamp',
    'Table Lamp',
    'Chandelier',
    'Pendant',
    'Wall Scones',
    'Decor',
    'Wall Treatments',
    'Cushions',
    'Curtains',
    'Props',
    'Wall Hanging',
    'Fans',
    'Led Tube Lights'
  ],
  'Kids Room': [
    'Bed',
    'Book Case',
    'Dressing Unit',
    'Crib',
    'Changing Station',
    'Side Table',
    'Study Table',
    'Chair',
    'Media Console/TV Unit',
    'Armchair',
    'Recliner',
    'Dressing Table',
    'Pouf',
    'Benches',
    'Chest of Drawers',
    'Storage Boxes',
    'Rug',
    'Floor Lamp',
    'Table Lamp',
    'Chandelier',
    'Pendant',
    'Wall Scones',
    'Decor',
    'Curtains',
    'Wall Treatments',
    'Fans',
    'Led Tube Lights'
  ],
  'Study Entertainment Room': [
    'Armchair',
    'Sofa',
    'Coffee Table',
    'Side Table',
    'Media Console/TV Unit',
    'Rug',
    'Floor Lamp',
    'Table Lamp',
    'Chandelier',
    'Pendant',
    'Wall Scones',
    'Book Case',
    'Console Table',
    'Bar Unit',
    'Planters',
    'Pouf',
    'Benches',
    'Recliner',
    'Laptop Table',
    'Study Table',
    'Wall Shelf',
    'Storage Boxes',
    'Curtains',
    'Decor',
    'Wall Treatments',
    'Fans',
    'Led Tube Lights',
    'Rug'
  ],
  'Puja Room': [
    'Puja Unit',
    'Chandelier',
    'Pendant',
    'Wall Scones',
    'Planters',
    'Decor',
    'Curtains',
    'Storage Boxes',
    'Wall Treatments',
    'Fans',
    'Led Tube Lights'
  ],
  Foyer: [
    'Console Table',
    'Shoe Cabinet',
    'Wall Shelf',
    'Key Hanger',
    'Storage Boxes',
    'Chair',
    'Pouf',
    'Benches',
    'Rug',
    'Planters',
    'Table Lamp',
    'Chandelier',
    'Pendant',
    'Wall Scones',
    'Curtains',
    'Decor',
    'Paintings',
    'Vase',
    'Wallpaper',
    'News Rack',
    'Fans',
    'Led Tube Lights'
  ],
  Balcony: [
    'Outdoor Furniture',
    'Coffee Table',
    'Swing',
    'Pendant',
    'Planters',
    'Curtains',
    'Decor',
    'Wall Treatments',
    'Fans',
    'Led Tube Lights'
  ],
  Others: [
    'Sofa',
    'Sectional',
    'Chair',
    'Coffee Table',
    'Accent Seating',
    'Media Console',
    'Wall Treatments',
    'Swing',
    'Shoe Cabinet',
    'Cushions',
    'Side Table',
    'Rug',
    'Floor Lamp',
    'Study Table',
    'Table Lamp',
    'Wall Scones',
    'Wall Shelf',
    'Storage Boxes',
    'Armchair',
    'Bar Unit',
    'Bed',
    'Benches',
    'Book Case',
    'Chandelier',
    'Changing Station',
    'Chest of Drawers',
    'Console Table',
    'Crib',
    'Crockery Unit',
    'Curtains',
    'Decor',
    'Dining Table',
    'Dressing Unit',
    'Fans',
    'Key Hanger',
    'Laptop Table',
    'Led Tube Lights',
    'News Rack',
    'Outdoor Furniture',
    'Pendant',
    'Planters',
    'Pouf',
    'Puja Unit',
    'Recliner'
  ]
};
