import axios from 'axios';
import { toast } from 'react-toastify';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { storeMxAns } from '../actions/sampleaction';
import { signUp } from 'supertokens-web-js/recipe/emailpassword';
import { signIn } from 'supertokens-web-js/recipe/emailpassword';
import Session from 'supertokens-web-js/recipe/session';
import ConsoleHelper from '../../utils/ConsoleHelper';
require('dotenv').config();

export const registerUser = (
  userData,
  history,
  redirect,
  redirectedFrom,
  mxAnsData,
  disableLoader
) => async dispatch => {
  let res1;
  try {
    const response = await signUp({
      formFields: [
        {
          id: 'email',
          value: userData.email
        },
        {
          id: 'name',
          value: userData.name
        },
        {
          id: 'password',
          value: userData.password
        },
        {
          id: 'phone_number',
          value: ''
        },
        {
          id: 'referral',
          value: userData.referral || ''
        },
        {
          id: 'otp',
          value: userData.otp
        }
      ]
    });
    // ConsoleHelper(response);
    if (
      response.status == 'OK' &&
      response.user?.data?.message === 'Success' &&
      (redirectedFrom === true || Object.keys(mxAnsData).length > 0)
    ) {
      let body = { ...mxAnsData, email: userData.email };
      body._id = response.data._id;
      res1 = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`, body);
    }
    if (
      response.status == 'OK' &&
      response.user?.data?.message === 'Success' &&
      ((mxAnsData.hasOwnProperty('questionnaire') &&
        mxAnsData.questionnaire.hasOwnProperty('purchase') &&
        mxAnsData.questionnaire.purchase !== true) ||
        Object.keys(mxAnsData).length === 0)
    ) {
      dispatch({
        type: 'REGISTER_SUCCESS',
        payload: {
          user: response.user.data.user,
          token: response.user.data.token
        }
      });
      let styles = [];
      // let rooms = [];
      if (
        response.user.data.user.hasOwnProperty('questionnaire') &&
        Object.keys(response.user.data.user.questionnaire).length !== 0 &&
        response.user.data.user.questionnaire
      ) {
        //  rooms = [...response.user.data.user.questionnaire.rooms];
        if (
          response.user.data.user.questionnaire.hasOwnProperty('space') &&
          response.user.data.user.questionnaire.space.length > 0
        ) {
          response.user.data.user.questionnaire.space.map(sp => {
            styles = [...styles, sp.tag];
          });
        } else if (
          response.user.data.user.questionnaire.style &&
          response.user.data.user.questionnaire.style.length > 0
        ) {
          response.user.data.user.questionnaire.style.map(st => {
            styles = [...styles, st.tag];
          });
        }
      }
      if (redirect !== undefined) {
        history.push(redirect.redirect);
      }
      if (redirect === undefined) {
        const customerAuthenticationCart = localStorage.getItem('authentication');
        const customerCart = JSON.parse(localStorage.getItem('cartProducts'));
        if (
          (redirect !== undefined || redirect === undefined) &&
          customerAuthenticationCart === 'PENDING' &&
          customerCart.length > 0
        ) {
          const cartBody = {
            user_id: response.user.data.user._id,
            products: customerCart
          };
          const res1 = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
            cartBody
          );
          if (res1.data) {
            localStorage.removeItem('authentication');
            localStorage.removeItem('cartProducts');
            history.push('/cart');
          }
        } else if (
          response.user.data.user.questionnaire &&
          Object.keys(response.user.data.user.questionnaire).length !== 0 &&
          response.user.data.user.questionnaire
        ) {
          storeMxAns({});
          localStorage.removeItem('RedirectToSignup');
          //history.push(`explore/rooms/${rooms}`);
          history.push('/');
        } else {
          storeMxAns({});
          localStorage.removeItem('RedirectToSignup');
          //history.push('/explore');
          history.push('/');
        }
      }
    } else if (response?.user?.data?.message === 'Success' && res1?.data) {
      toast('Register successful');
      dispatch({
        type: 'REGISTER_SUCCESS',
        payload: {
          user: response.user.data.user,
          token: response.user.data.token
        }
      });

      if (res1.data.questionnaire.package.title !== 'Express') {
        history.push('/package-payment');
        storeMxAns({});
        localStorage.removeItem('RedirectToSignup');
      } else {
        let qns1 = JSON.parse(localStorage.getItem('MX-A-questionnaire'));
        qns1.status = 'PENDING_ALLOCATION';
        qns1.designer_allocation = 'UNASSIGNED';
        qns1.name = 'My' + ' ' + qns1.questionnaire.MX_A_INITAL_ROOM;
        let res2 = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
          qns1
        );
        let styles = [];
        // let rooms = [];
        if (
          res2 &&
          res2.data &&
          mxAnsData.questionnaire &&
          Object.keys(mxAnsData.questionnaire).length !== 0
        ) {
          // rooms = [...mxAnsData.questionnaire.rooms];
          if (
            mxAnsData.questionnaire.hasOwnProperty('space') &&
            mxAnsData.questionnaire.space.length > 0
          ) {
            mxAnsData.questionnaire.space.map(sp => {
              styles = [...styles, sp.tag];
            });
          } else if (
            mxAnsData.questionnaire.hasOwnProperty('style') &&
            mxAnsData.questionnaire.style.length > 0
          ) {
            mxAnsData.questionnaire.style.map(st => {
              styles = [...styles, st.tag];
            });
          }
          styles = styles.flat();
          styles = styles.filter((v, i, a) => a.indexOf(v) === i);
          storeMxAns({});
          localStorage.removeItem('RedirectToSignup');
          history.push('/');
          // history.push(`explore/rooms/${rooms}`);
        }
      }
    } else if (response.status == 'OK' && response.user?.data?.status === 'ERROR') {
      window.alert(response.user?.data?.message);
      disableLoader();
    } else {
      dispatch({ type: 'AUTH_ERROR', payload: response.user });
      if (response?.status == 'FIELD_ERROR') {
        window.alert(response?.formFields[0].error);
        history.push('/login');
      }
    }
  } catch (err) {
    ConsoleHelper(err);
  }
};
export const logoutUser = history => async dispatch => {
  await Session.signOut();
  dispatch({ type: 'LOGOUT' });
  dispatch({ type: 'RESET_QUESTIONNAIRE' });
  dispatch({ type: 'RESET_CUSTOMER' });
  localStorage.clear();
  localStorage.removeItem('persist:root');
  history.push('/login');
};

export const loginUser = (
  data,
  history,
  redirect,
  redirectedFrom,
  mxAnsData,
  disableLoader
) => async dispatch => {
  try {
    dispatch({ type: 'RESET_QUESTIONNAIRE' });

    let res1;
    const res = await signIn({
      formFields: [
        {
          id: 'email',
          value: data.email
        },
        {
          id: 'password',
          value: data.password
        }
      ]
    });
    // ConsoleHelper(res, "SIgnin res");
    if (
      res.status == 'OK' &&
      res?.user?.data?.message === 'Success' &&
      (redirectedFrom === true || Object.keys(mxAnsData).length > 0)
    ) {
      // ConsoleHelper(mxAnsData, 'mxAnsData')
      let body = { ...mxAnsData, email: data.email };
      body._id = res.user.data._id;
      res1 = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`, body);
    }
    if (
      res.status == 'OK' &&
      res?.user?.data?.message === 'Success' &&
      ((mxAnsData.hasOwnProperty('questionnaire') &&
        mxAnsData.questionnaire.hasOwnProperty('purchase') &&
        mxAnsData.questionnaire.purchase !== true) ||
        Object.keys(mxAnsData).length === 0)
    ) {
      toast('Login successful');
      localStorage.setItem('userName', data.email);
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          user: res?.user?.data?.user,
          token: res?.user?.data?.token
        }
      });
      dispatch({ type: 'SET_QUESTIONNAIRE', payload: 'questionnaire' });
      const customerAuthenticationCart = localStorage.getItem('authentication');
      const customerCart = JSON.parse(localStorage.getItem('cartProducts'));
      let styles = [];
      // let rooms = [];
      if (
        res?.user?.data?.user?.questionnaire &&
        Object.keys(res?.user?.data?.user?.questionnaire).length !== 0 &&
        res?.user?.data?.user?.questionnaire
      ) {
        //  rooms = [...res.user.data.user.questionnaire.rooms];
        if (
          res.user.data.user.questionnaire.hasOwnProperty('space') &&
          res.user.data.user.questionnaire.space.length > 0
        ) {
          res.user.data.user.questionnaire.space.map(sp => {
            styles = [...styles, sp.tag];
          });
        } else if (
          res.user.data.user.questionnaire.hasOwnProperty('style') &&
          res.user.data.user.questionnaire.style.length > 0
        ) {
          res.user.data.user.questionnaire.style.map(st => {
            styles = [...styles, st.tag];
          });
        }
        styles = styles.flat();
        styles = styles.filter((v, i, a) => a.indexOf(v) === i);
      }
      if (redirect !== undefined) {
        history.push(redirect.redirect);
      }
      if (redirect === undefined) {
        if (
          (redirect !== undefined || redirect === undefined) &&
          customerAuthenticationCart === 'PENDING' &&
          customerCart.length > 0
        ) {
          const cartBody = {
            user_id: res.user.data.user._id,
            products: customerCart
          };
          const res1 = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
            cartBody
          );
          if (res1.data) {
            localStorage.removeItem('authentication');
            localStorage.removeItem('cartProducts');
            history.push('/cart');
          }
        } else if (
          res.user.data.user.questionnaire &&
          Object.keys(res.user.data.user.questionnaire).length !== 0 &&
          res.user.data.user.questionnaire
        ) {
          //history.push(`explore/rooms/${rooms}`);
          storeMxAns({});
          localStorage.removeItem('RedirectToLogin');
          history.push('/');
        }
      }
    } else if (res.status == 'OK' && res.user.data.message === 'Success' && res1.data) {
      toast('Login successful');
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          user: res.user.data.user,
          token: res.user.data.token
        }
      });
      dispatch({ type: 'SET_QUESTIONNAIRE', payload: 'questionnaire' });
      if (res1.data.questionnaire.package.title !== 'Express') {
        history.push('/package-payment');
        storeMxAns({});
        localStorage.removeItem('RedirectToLogin');
      } else {
        let qns1 = JSON.parse(localStorage.getItem('MX-A-questionnaire'));
        qns1.status = 'PENDING_ALLOCATION';
        qns1.designer_allocation = 'UNASSIGNED';
        qns1.name = 'My' + ' ' + qns1.questionnaire.MX_A_INITAL_ROOM;
        let res2 = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
          qns1
        );
        let styles = [];
        // let rooms = [];
        if (
          res2 &&
          res2.data &&
          mxAnsData.questionnaire &&
          Object.keys(mxAnsData.questionnaire).length !== 0
        ) {
          //  rooms = [...mxAnsData.questionnaire.rooms];
          if (
            mxAnsData.questionnaire.hasOwnProperty('space') &&
            mxAnsData.questionnaire.space.length > 0
          ) {
            mxAnsData.questionnaire.space.map(sp => {
              styles = [...styles, sp.tag];
            });
          } else if (
            mxAnsData.questionnaire.hasOwnProperty('style') &&
            mxAnsData.questionnaire.style.length > 0
          ) {
            mxAnsData.questionnaire.style.map(st => {
              styles = [...styles, st.tag];
            });
          }
          styles = styles.flat();
          styles = styles.filter((v, i, a) => a.indexOf(v) === i);
          storeMxAns({});
          localStorage.removeItem('RedirectToLogin');
          history.push('/');
          // history.push(`explore/rooms/${rooms}`);
        }
      }
    }
    if (res?.status !== 'OK') {
      disableLoader();
      dispatch({ type: 'AUTH_ERROR', payload: res.data });
      if (res?.status == 'WRONG_CREDENTIALS_ERROR') {
        window.alert("Email and password doesn't match.");
      }
    }
  } catch (err) {
    disableLoader();
  }
};

export const clearError = () => dispatch => {
  dispatch({ type: 'AUTH_ERROR', payload: null });
};
