import React, { useState } from 'react';
//import style from "./Review.module.css";
import Canvas from './canvas';
// import { Modal } from "react-bootstrap";
// import classNames from "classnames";
import Box from '@material-ui/core/Box';
import { Dialog, IconButton } from '@material-ui/core';
import Canvaszoom from './canvaszoom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const InterActiveMoodboard = props => {
  const [modal, setModal] = useState(false);

  return (
    <>
      {!modal && (
        <Canvas
          data={props.data}
          setModal={setModal}
          modal={modal}
          projectData={props.projectData}
          saveReviewMoodboard={props.saveReviewMoodboard}
          handleCompleteSubmit={props.handleCompleteSubmit}
          setDeclineModal={props.setDeclineModal}
          page={props.page}
          auth={props.auth}
          history={props.history}
          addToCartProducts={props.addToCartProducts}
          setAddToCartProducts={props.setAddToCartProducts}
          dimension={props.dimension}
          savedProductIds={props.savedProductIds}
          handleFavourites={props.handleFavourites}
          disable={props.disable}
        />
      )}

      <Dialog
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        fullScreen>
        <Box bgcolor="#2C2C2C" sx={{ padding: '1rem !important', height: '100%' }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setModal(false);
            }}
            style={{ color: '#ffff', float: 'right' }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#ffffff'
            }}>
            <HighlightOffIcon />
          </IconButton>
          <br />
          <Canvaszoom
            data={props.data}
            modal={modal}
            projectData={props.projectData}
            saveReviewMoodboard={props.saveReviewMoodboard}
            handleCompleteSubmit={props.handleCompleteSubmit}
            setDeclineModal={props.setDeclineModal}
            page={props.page}
            auth={props.auth}
            history={props.history}
            addToCartProducts={props.addToCartProducts}
            setAddToCartProducts={props.setAddToCartProducts}
            dimension={props.dimension}
            savedProductIds={props.savedProductIds}
            handleFavourites={props.handleFavourites}
            disable={props.disable}
          />
        </Box>
      </Dialog>
    </>
  );
};
export default InterActiveMoodboard;
