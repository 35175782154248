import React, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import style from '../../pages/Shop/Shopdetails.module.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { QRCodeSVG } from 'qrcode.react';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import '@google/model-viewer';
import ConsoleHelper from '../../utils/ConsoleHelper';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: theme.shadows[5]
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: 'none',
    width: 800,
    height: 500,
    padding: theme.spacing(2, 4, 3)
  },
  papers: {
    backgroundColor: theme.palette.background.paper,
    //   boxShadow: theme.shadows[5],
    border: 'none',
    width: '70%',
    height: 500,
    position: 'relative',
    outline: 'none'
  },
  displayrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  roombox: {
    fontSize: '15px',
    marginTop: '16px',
    fontWeight: 600,
    marginBottom: '51px'
  }
}));

//   const handleQrClose = () => {
//     setQrOpen(false);
// };

const QRScanner = ({ id, modalOpen, setModalOpen, type }) => {
  ConsoleHelper('qr scan props', { modalOpen, id, type });

  const dimension = useMediaQuery();
  const classes = useStyles();
  const [productData, setProductData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleQrClose = () => {
    setModalOpen(false);
  };

  // ConsoleHelper(props?.id)
  useEffect(() => {
    if (id !== '' && type != 'Moodboard') {
      const getP = async () => {
        setIsLoading(true);
        // const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${productId}`);

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`);
        ConsoleHelper('res data', res.data);
        setProductData(res.data);
        setIsLoading(false);
      };
      getP();
    }
  }, []);
  // ConsoleHelper("length", Object.keys(productData).length);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        // open={qrOpen}
        open={modalOpen}
        onClose={modalOpen === true && handleQrClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={modalOpen}>
          {/* <Fade in={qrOpen}> */}
          {/* <QRScanner closeModal={props.closeModal}/> */}
          <div
            className={classnames(classes.papers, style.modalContainer)}
            style={{ height: dimension[0] > 500 ? '' : '100vh' }}>
            <button className={style.closeBtn} onClick={handleQrClose}>
              <img
                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/close.png"
                className={style.closeBtn}
              />
            </button>
            <div className="row">
              <div className="col-6">
                {dimension[0] > 500 && type != 'Moodboard' ? (
                  <div className={style.qrStyle}>
                    <center>
                      {/* <div>Please, scan the QR code below in your phone/tablet to enter the AR experience</div> */}
                      <div style={{ marginTop: '30px' }}>
                        <QRCodeSVG value={`${process.env.REACT_APP_CUSTOMER_URL}/3d-model/${id}`} />
                      </div>
                      {/* <QRCodeSVG value={`https://dev-customer-api.pixelandmortar.com/code/${productId}`} /> */}
                      {/* <QRCodeSVG value={`${process.env.REACT_APP_CUSTOMER_URL}/3d-model/${productId}`} /> */}
                    </center>
                  </div>
                ) : dimension[0] > 500 && type == 'Moodboard' ? (
                  <div className={style.qrStyle}>
                    <center>
                      {/* <div>Please, scan the QR code below in your phone/tablet to enter the AR experience</div> */}
                      <div style={{ marginTop: '30px' }}>
                        <QRCodeSVG value={`pmapp://explore?${id}`} />
                      </div>
                      {/* <QRCodeSVG value={`https://dev-customer-api.pixelandmortar.com/code/${productId}`} /> */}
                      {/* <QRCodeSVG value={`${process.env.REACT_APP_CUSTOMER_URL}/3d-model/${productId}`} /> */}
                    </center>
                  </div>
                ) : (
                  <div style={{ margin: 20 }}>
                    {isLoading ? (
                      <Spinner as="span" size="sm" role="status" animation="border" />
                    ) : id && productData && Object.keys(productData).length > 0 ? (
                      <>
                        <div>Product: {productData && productData.name}</div>
                        <div className="d-flex justify-content-center">
                          {productData && productData['3d_models'].glbFileUrl ? (
                            <model-viewer
                              src={productData['3d_models'].glbFileUrl}
                              alt="A 3D model of an astronaut"
                              ar
                              ar-modes="webxr scene-viewer quick-look"
                              environment-image="neutral"
                              auto-rotate
                              camera-controls
                              style={{
                                height: '400px'
                              }}
                              shadow-intensity="1"
                              ios-src={productData['3d_models'].usdzFileUrl}
                              ar-scale="fixed"
                            />
                          ) : (
                            <p
                              className="d-flex align-items-center"
                              style={{
                                height: '400px'
                              }}>
                              USDZ file is not supported by browsers, please upload a GLB file to
                              preview 3D model
                            </p>
                          )}
                        </div>
                      </>
                    ) : (
                      <div>Product doesn't exist</div>
                    )}
                  </div>
                )}
              </div>

              <div className="col-6">
                <div className={style.QrLeft}>
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Mask+Group+20.png"
                    style={{ width: '100%', height: '100%' }}
                  />
                  <div className={style.txtSec}>
                    <h4>Scan here to view selected item in AR mode</h4>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {/* <div
                    className={classnames(classes.papers, style.modalContainer)}
                    style={{ height: dimension[0] > 500 ? "" : "100vh" }}
                  >
                      <button className={style.closeBtn} onClick={()=> setQrOpen()}> 
                  <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/close.png" className={style.closeBtn}/>
                  </button>
                                <div className="row">
                                    <div className="col-6">
                                    </div>

                                    <div className="col-6">
                                        <div className={style.QrLeft}> 
                                        <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Mask+Group+20.png" style={{height:'100%'}}/>
                                        <div className={style.txtSec}>
                                            <h4>Scan here to view selected item in AR mode</h4>
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
                                        </div>
                                        </div>
                                    </div>

                                </div>
                        
                  </div> */}
    </>
  );
};

export default QRScanner;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
