import React from 'react';
import Carousel from 'react-grid-carousel';

const VendorList = ({ vendorsList, dimension }) => {
  return (
    <div className="row justify-content-end">
      <Carousel
        loop={true}
        autoplay={3000}
        hideArrow={true}
        className="d-flex"
        cols={6}
        rows={1}
        gap={30}
        responsiveLayout={[
          {
            breakpoint: 1200,
            cols: 5,
            gap: 10
          },
          {
            breakpoint: 990,
            cols: 3,
            gap: 10
          },
          {
            breakpoint: 600,
            cols: 3,
            gap: 10
          },
          {
            breakpoint: 500,
            cols: 3,
            gap: 10
          }
        ]}
        mobileBreakpoint={300}>
        {vendorsList.map((data, index) => (
          <Carousel.Item>
            <div className="d-flex justify-content-center h-100">
              <img
                style={{
                  width: dimension[0] < 500 ? '100%' : '100%',
                  objectFit: 'contain'
                }}
                key={index}
                src={data.bit_img}
                alt=""
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default VendorList;
