import React, { useState, useEffect } from 'react';
import style from './ProductFilter.module.css';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Typography from '@material-ui/core/Typography';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Slider from '../slider/Slider';
import clsx from 'clsx';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
//import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const useCheckboxStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    backgroundColor: '#F1EEEA',
    height: '16px',
    width: '16px',
    borderRadius: '4px',
    'input:hover ~ &': {
      backgroundColor: '#F1EEEA'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#EBEBEB',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%237B8569'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#EBEBEB'
    }
  }
});

function StyledCheckbox(props) {
  const classes = useCheckboxStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

const useStyles = makeStyles({
  filterContainer: {
    display: 'flex',
    width: '100%',
    padding: '15px',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid #DEE3EC',
    backgroundColor: '#ffffff',
    color: '#171717'
  },

  accordian: {
    '&.MuiAccordion-root': {
      marginBottom: '13px',
      border: '1px solid #DEE3EC !important',
      backgroundColor: 'transparent !important'
    },
    '&.MuiAccordion-rounded': {
      borderRadius: '2px !important'
    }
  }
});

function ProductFilter({
  filterApplied,
  setFilterApplied,
  likesChecked,
  setLikesChecked,
  setDrawer,
  searchparams,
  getProductDetails
}) {
  const classes = useStyles();
  const [filters, setFilters] = useState(
    filterApplied
      ? filterApplied
      : {
          AR: true
        }
  );
  const [myLikesChecked, setMyLikesChecked] = useState(likesChecked ? likesChecked : false);
  const [value, setValue] = useState([0, 150000]);
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [colors, setColors] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandinfo, setBrandinfo] = useState({});
  const [styles, setStyles] = useState([]);
  const [categories, setCategories] = useState([]);

  // console.log(filters);

  useEffect(() => {
    if (filterApplied) setFilters(filterApplied);
  }, [filterApplied]);

  useEffect(() => {
    const fetchFilterData = async () => {
      // const filter = { ...filters };
      // if (filter.hasOwnProperty('brand')) delete filter.brand;
      // if (filter.hasOwnProperty('vendor_id')) delete filter.vendor_id;
      // if (filter.hasOwnProperty('material')) delete filter.material;
      // if (filter.hasOwnProperty('style')) delete filter.style;
      // if (filter.hasOwnProperty('color')) delete filter.color;
      // if (filter.hasOwnProperty('color')) delete filter.color;
      // if (filter.hasOwnProperty('price_from')) delete filter.price_from;
      // if (filter.hasOwnProperty('price_to')) delete filter.price_to;
      // if (filter.hasOwnProperty('AR')) delete filter.AR;
      // if (filter.hasOwnProperty('category')) delete filter.category;
      // setMyLikesChecked(false);
      // setValue([0, 1500000]);
      // setLikesChecked(false);
      // setFilters(filter);
      // setFilterApplied(filter);
      // setDrawer && setDrawer(false);
      // setLoading(true);
      // console.log(searchparams.toString())
      const keys = [];
      const values = [];
      for (const [key, value] of searchparams.entries()) {
        keys.push(key);
        values.push(value);
      }
      // console.log(keys.length, 'first');
      const del = ['page', 'sortBy', 'style[]', 'material[]', 'color[]', 'price_from', 'price_to'];
      if (keys[0] != 'vendor_id') {
        del.push('vendor_id');
        del.push('brand');
      }
      const filteredKeys = keys.filter((d, index) => !del.includes(d));
      const filteredValues = values.filter((d, index) => !del.includes(keys[index]));

      const queryparam = new URLSearchParams();
      for (const key of filteredKeys) {
        queryparam.set(key, filteredValues[filteredKeys.indexOf(key)]);
      }
      if (searchparams.get('search')) {
        queryparam.set('name', searchparams.get('search'));
      }
      // console.log(queryparam.toString());

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/filterOptions?${queryparam.toString()}`
      );
      setLoading(false);

      if (res.status == 200) {
        if (res.data.colors) {
          setColors([...res.data.colors]);
        }
        if (res.data.material) {
          setMaterials([...res.data.material]);
        }
        if (res.data.brandinfo) {
          setBrands(Object.keys(res.data.brandinfo));
          setBrandinfo(res.data.brandinfo);
        }
        if (res.data.style) {
          setStyles([...res.data.style]);
        }
        if (res.data.category) {
          setCategories([...res.data.category]);
        }
      } else {
        setSnack(true);
        setSnackMessage('Someting went wrong while fetching filters');
      }
    };
    fetchFilterData();
  }, [searchparams.get('subcategory[]')]);

  const clearFilters = () => {
    const filter = { ...filters };
    if (filter.hasOwnProperty('brand')) delete filter.brand;
    if (filter.hasOwnProperty('vendor_id')) delete filter.vendor_id;
    if (filter.hasOwnProperty('material')) delete filter.material;
    if (filter.hasOwnProperty('style')) delete filter.style;
    if (filter.hasOwnProperty('color')) delete filter.color;
    if (filter.hasOwnProperty('price_from')) delete filter.price_from;
    if (filter.hasOwnProperty('price_to')) delete filter.price_to;
    if (filter.hasOwnProperty('AR')) delete filter.AR;
    if (filter.hasOwnProperty('category')) delete filter.category;
    setMyLikesChecked(false);
    setValue([0, 150000]);
    setLikesChecked(false);
    setFilters(filter);
    setFilterApplied(filter);
    setDrawer && setDrawer(false);
    const del = [
      'vendor_id',
      'material[]',
      'style[]',
      'color[]',
      'price_from',
      'price_to',
      'brand'
    ];
    del.forEach((d) => searchparams.delete(d));
    window.history.replaceState(null, '', `?${searchparams.toString()}`);
  };

  return (
    <div className={classes.filterContainer}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {loading ? (
        <div>
          <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
        </div>
      ) : (
        <>
          {brands.length > 1 && (
            <Accordion className={classes.accordian}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRightIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header">
                <span>Brand</span>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup col>
                  {brands.map((brand) => (
                    <FormControlLabel
                      control={<StyledCheckbox name="checkedA" />}
                      label={`${brand}`}
                      checked={filters.hasOwnProperty('brand') && filters.brand == brand}
                      onChange={() => {
                        if (filters.brand == brand) {
                          setFilters({ ...filters, brand: '', vendor_id: '' });
                          setFilterApplied({ ...filters, brand: '', vendor_id: '' });
                          searchparams.delete('vendor_id');
                          searchparams.delete('brand');
                          // searchparams.set('page', 1);
                          window.history.replaceState(null, '', `?${searchparams.toString()}`);
                          getProductDetails();
                        } else {
                          setFilters({ ...filters, brand: brand, vendor_id: brandinfo[brand] });
                          setFilterApplied({
                            ...filters,
                            brand: brand,
                            vendor_id: brandinfo[brand]
                          });
                          searchparams.set('vendor_id', brandinfo[brand]);
                          searchparams.set('brand', brand);
                          searchparams.set('page', 1);
                          window.history.replaceState(null, '', `?${searchparams.toString()}`);
                          getProductDetails();
                        }
                      }}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
          {categories.length > 1 && (
            <Accordion className={classes.accordian}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRightIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header">
                <span>Category</span>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup col>
                  {categories.map((category) => (
                    <FormControlLabel
                      control={<StyledCheckbox name="checkedA" />}
                      label={`${category}`}
                      checked={filters.hasOwnProperty('category') && filters.category == category}
                      onChange={() => {
                        if (filters.category == category) {
                          setFilters({ ...filters, category: '' });
                          setFilterApplied({ ...filters, category: '' });
                          // console.log(category, 'if');
                          searchparams.delete('category');
                          // searchparams.set('page', 1);
                          window.history.replaceState(null, '', `?${searchparams.toString()}`);
                          getProductDetails();
                        } else {
                          // console.log(category, 'else');
                          setFilters({ ...filters, category: category });
                          setFilterApplied({ ...filters, category: category });
                          searchparams.set('category', category);
                          searchparams.set('page', 1);
                          window.history.replaceState(null, '', `?${searchparams.toString()}`);
                          getProductDetails();
                        }
                      }}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
          {materials.length > 0 && (
            <Accordion className={classes.accordian}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRightIcon />}
                aria-label="Expand"
                aria-controls="additional-actions2-content"
                id="additional-actions2-header">
                <span>Material</span>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup col>
                  {materials.map((material) => (
                    <FormControlLabel
                      control={<StyledCheckbox name="checkedA" />}
                      label={`${material}`}
                      checked={
                        filters.hasOwnProperty('material') &&
                        filters.material.indexOf(material) > -1
                      }
                      onChange={() => {
                        if (filters.hasOwnProperty('material') && filters.material.length > 0) {
                          const index = filters.material.indexOf(material);

                          if (index > -1) {
                            const filteredMaterial = filters.material.filter(
                              (mtr) => mtr !== material
                            );
                            setFilters({
                              ...filters,
                              material: filteredMaterial
                            });
                            setFilterApplied({
                              ...filters,
                              material: filteredMaterial
                            });
                            var mat = filteredMaterial;
                            searchparams.delete('material[]');
                            mat.forEach((value) => {
                              searchparams.append('material[]', value);
                            });
                            // searchparams.set('material', [material]);
                            searchparams.set('page', 1);
                            window.history.replaceState(null, '', `?${searchparams.toString()}`);
                            getProductDetails();
                          } else {
                            setFilters({
                              ...filters,
                              material: [...filters.material, material]
                            });
                            setFilterApplied({
                              ...filters,
                              material: [...filters.material, material]
                            });
                            var mat = [...filters.material, material];
                            searchparams.delete('material[]');
                            mat.forEach((value) => {
                              searchparams.append('material[]', value);
                            });
                            // searchparams.set('material', [material]);
                            searchparams.set('page', 1);

                            window.history.replaceState(null, '', `?${searchparams.toString()}`);
                            getProductDetails();
                          }
                        } else {
                          setFilters({ ...filters, material: [material] });
                          setFilterApplied({ ...filters, material: [material] });
                          var mat = [material];
                          searchparams.delete('material[]');
                          mat.forEach((value) => {
                            searchparams.append('material[]', value);
                          });
                          searchparams.set('page', 1);

                          // searchparams.set('material', [material]);
                          window.history.replaceState(null, '', `?${searchparams.toString()}`);
                          getProductDetails();
                        }
                      }}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
          {styles.length > 0 && (
            <Accordion className={classes.accordian}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRightIcon />}
                aria-label="Expand"
                aria-controls="additional-actions3-content"
                id="additional-actions3-header">
                <span>Style</span>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup col>
                  {styles.map((style) => (
                    <FormControlLabel
                      control={<StyledCheckbox name="checkedA" />}
                      label={`${style}`}
                      checked={filters.hasOwnProperty('style') && filters.style.indexOf(style) > -1}
                      onChange={() => {
                        if (filters.hasOwnProperty('style') && filters.style.length > 0) {
                          const index = filters.style.indexOf(style);

                          if (index > -1) {
                            const filteredstyle = filters.style.filter((mtr) => mtr !== style);
                            setFilters({
                              ...filters,
                              style: filteredstyle
                            });
                            setFilterApplied({
                              ...filters,
                              style: filteredstyle
                            });
                            var sty = filteredstyle;
                            searchparams.delete('style[]');
                            sty.forEach((value) => {
                              searchparams.append('style[]', value);
                            });
                            searchparams.set('page', 1);

                            window.history.replaceState(null, '', `?${searchparams.toString()}`);
                            getProductDetails();
                          } else {
                            setFilters({
                              ...filters,
                              style: [...filters.style, style]
                            });
                            setFilterApplied({
                              ...filters,
                              style: [...filters.style, style]
                            });
                            var sty = [...filters.style, style];
                            searchparams.delete('style[]');
                            sty.forEach((value) => {
                              searchparams.append('style[]', value);
                            });
                            searchparams.set('page', 1);
                            window.history.replaceState(null, '', `?${searchparams.toString()}`);
                            getProductDetails();
                          }
                        } else {
                          setFilters({ ...filters, style: [style] });
                          setFilterApplied({ ...filters, style: [style] });
                          var sty = [style];
                          searchparams.delete('style[]');
                          sty.forEach((value) => {
                            searchparams.append('style[]', value);
                          });
                          searchparams.set('page', 1);
                          window.history.replaceState(null, '', `?${searchparams.toString()}`);
                          getProductDetails();
                        }
                      }}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
          {colors.length > 0 && (
            <Accordion className={classes.accordian}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRightIcon />}
                aria-label="Expand"
                aria-controls="additional-actions3-content"
                id="additional-actions3-header">
                <span>Colour</span>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup col>
                  {colors.map((color) => (
                    <FormControlLabel
                      control={<StyledCheckbox name="checkedA" />}
                      label={`${color}`}
                      checked={filters.hasOwnProperty('color') && filters.color.indexOf(color) > -1}
                      onChange={() => {
                        if (filters.hasOwnProperty('color') && filters.color.length > 0) {
                          const index = filters.color.indexOf(color);

                          if (index > -1) {
                            const filteredcolor = filters.color.filter((mtr) => mtr !== color);
                            setFilters({
                              ...filters,
                              color: filteredcolor
                            });
                            setFilterApplied({
                              ...filters,
                              color: filteredcolor
                            });
                            var col = filteredcolor;
                            searchparams.delete('color[]');
                            col.forEach((value) => {
                              searchparams.append('color[]', value);
                            });
                            searchparams.set('page', 1);
                            window.history.replaceState(null, '', `?${searchparams.toString()}`);
                            getProductDetails();
                          } else {
                            setFilters({
                              ...filters,
                              color: [...filters.color, color]
                            });
                            setFilterApplied({
                              ...filters,
                              color: [...filters.color, color]
                            });
                            var col = [...filters.color, color];
                            searchparams.delete('color[]');
                            col.forEach((value) => {
                              searchparams.append('color[]', value);
                            });
                            searchparams.set('page', 1);
                            window.history.replaceState(null, '', `?${searchparams.toString()}`);
                            getProductDetails();
                          }
                        } else {
                          setFilters({ ...filters, color: [color] });
                          setFilterApplied({ ...filters, color: [color] });
                          var col = [color];
                          searchparams.delete('color[]');
                          col.forEach((value) => {
                            searchparams.append('color[]', value);
                          });
                          searchparams.set('page',1);
                          window.history.replaceState(null, '', `?${searchparams.toString()}`);
                          getProductDetails();
                        }
                      }}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}

          <div className={style.filterBudgetCont}>
            <p className={style.filterBudgetHeader}>Set Your Budget</p>
            <div className={style.filterBudgetSliderCont}>
              <Slider
                value={value}
                handleChange={(event, newValue) => {
                  setValue(newValue);
                  setFilters({
                    ...filters,
                    price_from: newValue[0],
                    price_to: newValue[1]
                  });
                  searchparams.set('price_from', newValue[0]);
                  searchparams.set('price_to', newValue[1]);
                  searchparams.set('page',1);
                  window.history.replaceState(null, '', `?${searchparams.toString()}`);
                  setTimeout(() => {
                    getProductDetails();
                  }, 3000);
                }}
              />
            </div>
            <div className={style.filterBudgetRangeCont}>
              <div className={style.sliderBox}>
                <p className={style.sliderBoxInner}>Min.</p>
                <div className="d-flex">
                  <span className={style.sliderBoxInnerValue}>Rs.</span>
                  <input
                    value={value[0]}
                    className={style.sliderInput}
                    type="number"
                    onChange={(e) => {
                      if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 150000) {
                        setValue([parseInt(e.target.value), value[1]]);
                        setFilters({
                          ...filters,
                          price_from: parseInt(e.target.value),
                          price_to: value[1]
                        });
                        searchparams.set('price_from', e.target.value);
                        searchparams.set('price_to', value[1]);
                        searchparams.set('page',1);
                        window.history.replaceState(null, '', `?${searchparams.toString()}`);
                      } else {
                        setValue([e.target.value, value[1]]);
                        setFilters({
                          ...filters,
                          price_from: e.target.value,
                          price_to: value[1]
                        });
                        searchparams.set('price_from', e.target.value);
                        searchparams.set('price_to', value[1]);
                        searchparams.set('page',1);
                        window.history.replaceState(null, '', `?${searchparams.toString()}`);
                        setSnack(true);
                        setSnackMessage('Please enter value between 0 - 1.5 lakh');
                      }
                      setTimeout(() => {
                        getProductDetails();
                      }, 3000);
                      // getProductDetails()
                    }}
                  />
                </div>
              </div>
              <div className={style.sliderBox}>
                <p className={style.sliderBoxInner}>Max.</p>
                <div className="d-flex">
                  <span className={style.sliderBoxInnerValue}>Rs.</span>
                  <input
                    value={value[1]}
                    type="number"
                    className={style.sliderInput}
                    onChange={(e) => {
                      if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 150000) {
                        setValue([value[0], parseInt(e.target.value)]);
                        setFilters({
                          ...filters,
                          price_from: value[0],
                          price_to: parseInt(e.target.value)
                        });
                        searchparams.set('price_from', value[0]);
                        searchparams.set('price_to', e.target.value);
                        searchparams.set('page',1);
                        window.history.replaceState(null, '', `?${searchparams.toString()}`);
                      } else {
                        setValue([value[0], e.target.value]);
                        setFilters({
                          ...filters,
                          price_from: value[0],
                          price_to: e.target.value
                        });
                        searchparams.set('price_from', value[0]);
                        searchparams.set('price_to', e.target.value);
                        searchparams.set('page',1);
                        window.history.replaceState(null, '', `?${searchparams.toString()}`);
                        setSnack(true);
                        setSnackMessage('Please enter value between 0 - 1.5 lakh');
                      }
                      getProductDetails();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className={style.filterCheckboxCont}>
            <FormGroup col>
              <FormControlLabel
                control={<StyledCheckbox name="checkedA" />}
                label="My Likes"
                checked={myLikesChecked}
                onChange={(e) => setMyLikesChecked(e.target.checked)}
              />
              <FormControlLabel
                control={<StyledCheckbox name="checkedA" />}
                label="With AR"
                checked={filters.AR}
                onChange={(e) => setFilters({ ...filters, AR: e.target.checked })}
              />
            </FormGroup>
          </div> */}
          <div className={style.filterButtonsCont}>
            <div className={style.filterClearBtn} onClick={clearFilters}>
              Clear All Filters
            </div>
            {/* <div
              className={style.filterApplyBtn}
              onClick={() => {
                if (filters.hasOwnProperty('price_from') && filters.hasOwnProperty('price_to')) {
                  if (
                    filters.price_from !== '' &&
                    filters.price_to !== '' &&
                    !isNaN(filters.price_from) &&
                    !isNaN(filters.price_to) &&
                    parseFloat(filters.price_from) >= 0 &&
                    parseFloat(filters.price_from) <= 1500000 &&
                    parseFloat(filters.price_to) >= 0 &&
                    parseFloat(filters.price_to) <= 1500000
                  ) {
                    setFilterApplied(filters);
                    setLikesChecked(myLikesChecked);
                    setDrawer && setDrawer(false);
                  } else {
                    setSnack(true);
                    setSnackMessage('Enter a valid Min and Max budget');
                  }
                } else {
                  setFilterApplied(filters);
                  setLikesChecked(myLikesChecked);
                  setDrawer && setDrawer(false);
                }
              }}>
              Apply Filters
            </div> */}
          </div>
        </>
      )}
    </div>
  );
}

ProductFilter.propTypes = {
  filterApplied: PropTypes.object.isRequired,
  setFilterApplied: PropTypes.func.isRequired,
  setLikesChecked: PropTypes.func.isRequired
  // setDrawer: PropTypes.func.isRequired
};

export default ProductFilter;
