import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import axios from 'axios';
import Header from '../../components/Header/Header';
import Snackbar from '@material-ui/core/Snackbar';
import ReactPaginate from 'react-paginate';
import ARIcon from '../../assets/ARexpIcon.png';
import QRScanner from '../../components/layout/QrScanner';
import classnames from 'classnames';
import style from './Explore.module.css';
import Loader from 'react-loader-spinner';
import _3DIcon from '../../assets/3d-viewer.svg';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { set_explore_filter } from '../../store/actions/sampleaction';
import { connect } from 'react-redux';
import ConsoleHelper from '../../utils/ConsoleHelper';

function ExploreTagsMoodboards({ history, auth }) {
  const { tags } = useParams();
  const dimension = useMediaQuery();

  const [moodBoards, setMoodBoards] = useState([]);
  const [moodBoardsMobile, setMoodBoardsMobile] = useState([]);
  const [savedMoodBoardIds, setSavedMoodBoardIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [productIds, setProductId] = useState();
  const [snackMessage, setSnackMessage] = useState('');
  const [snack, setSnack] = useState(false);
  const [breadCrumb, setBreadcrumb] = useState([]);

  useEffect(() => {
    let filterArr = tags.split('&');
    setBreadcrumb(filterArr);

    let filter = '';

    filterArr.forEach(element => {
      filter = filter + `tags[]=${element}&`;
    });
    filter = filter + `page=${page}`;
    getProductList(filter);
  }, [page]);

  const getProductList = async filter => {
    if (filter) {
      setLoading(true);
      try {
        let result = [];
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/explore?${filter}`
        );
        result = res.data.results;
        ConsoleHelper(result);
        let chunckedMoodBoards = makeChunks(result, 8);
        setMoodBoards(chunckedMoodBoards);

        setMoodBoardsMobile(result);

        setTotalPage(res.data.totalPages);
        setLoading(false);
      } catch (err) {
        ConsoleHelper('error', err);
        setLoading(false);
      }
    }
  };

  function makeChunks(arr, chunkSize) {
    let chunkedArray = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
      let myChunk = arr.slice(i, i + chunkSize);
      chunkedArray.push(myChunk);
    }
    return chunkedArray;
  }

  const handleMoodBoardProducts = moodBoardId => {
    history.push(`/explore-detail/${moodBoardId}`);
  };

  const uniqueProducts = data1 => {
    let productIds = [];
    data1.products &&
      data1.products.map(prodArray => {
        prodArray.map(product => {
          productIds.push(product.product_id);
        });
      });
    let uniqueProductIds = [...new Set(productIds)];
    return uniqueProductIds.length;
  };

  const handleAddToBoard = async moodBoardData => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to save the Boards');
      return false;
    }
    setSavedMoodBoardIds([...savedMoodBoardIds, moodBoardData._id]);
    let productIds = [];
    moodBoardData.products &&
      moodBoardData.products.map(prodArray => {
        prodArray.map(product => {
          productIds.push(product.product_id);
        });
      });
    productIds = [...new Set(productIds)];
    const addToBoardData = {
      moodboard_id: moodBoardData._id,
      products: productIds,

      name: moodBoardData.title,
      user_id: auth.user._id
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/add`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('MoodBoard addedd to my Board successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };

  const handleQr = productIds => {
    ConsoleHelper('productIds', productIds);
    setQrModalOpen(true);
    setProductId(productIds);
  };

  function openinnewtab(url) {
    ConsoleHelper(url);
    window.location.assign(url);
  }

  return (
    <>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {qrModalOpen && (
        <QRScanner
          id={productIds}
          modalOpen={qrModalOpen}
          setModalOpen={setQrModalOpen}
          type={'Moodboard'}
        />
      )}
      <div style={{ minHeight: '100vh' }}>
        <div className={classnames('container', style.container)}>
          <div className={classnames(style.menu)}>
            <span
              onClick={() => {
                history.push(`/explore`);
              }}>
              Home
            </span>{' '}
            {breadCrumb &&
              breadCrumb.map((elem, index) => (
                <>
                  {'>'}{' '}
                  <span
                    key={index}
                    onClick={() => {
                      history.go(index + 1 - breadCrumb.length);
                    }}>
                    {elem}
                  </span>
                </>
              ))}
          </div>
          <div className={classnames(style.categoryHeading)}>
            {breadCrumb.length > 0 && breadCrumb[breadCrumb.length - 1].toUpperCase()}
          </div>
          <div style={{ width: dimension[0] > 700 ? '50%' : '100%' }}>
            Make room for some furniture Inspiration! Find everything for your home under one roof.
            From your bedroom to your balcony, we've got you covered!..
          </div>
          {loading ? (
            <div className="d-flex justify-content-center mt-4">
              <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
            </div>
          ) : (
            <div>
              {dimension[0] > 500 && (
                <div>
                  {moodBoards.length > 0 &&
                    moodBoards.map((data, index) => {
                      ConsoleHelper(data);
                      return (
                        <>
                          <div className={classnames('row')}>
                            <div className={classnames('col-8')}>
                              <div className={classnames('row h-100')}>
                                <div
                                  className={classnames('col-12')}
                                  style={{ paddingTop: '30px' }}>
                                  <div
                                    style={{
                                      marginBottom: '15px',
                                      height: '100%'
                                    }}
                                    className={data[0] ? style.item : 'd-none'}>
                                    <img
                                      src={data[0]?.preview_url}
                                      height={'100%'}
                                      width={'100%'}
                                      alt=""
                                    />
                                    <div className={style.content}>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-flex justify-content-between w-100 mx-2 mt-1"
                                        onClick={() =>
                                          auth.isAuthenticated
                                            ? handleMoodBoardProducts(data[0]?._id)
                                            : history.push(`/pre-explore-detail/${data[0]?._id}`)
                                        }>
                                        <div>
                                          <span
                                            style={{
                                              opacity: 1,
                                              color: '#ffffff',
                                              fontWeight: 700,
                                              fontSize: '16px',
                                              marginLeft: '7px'
                                            }}>
                                            {uniqueProducts(data[0] ? data[0] : 0)} Products
                                          </span>
                                          {data[0]?.hasOwnProperty('model_url') &&
                                            data[0]?.model_url != null && (
                                              <button
                                                className={style._3dIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  openinnewtab(`/3droom/${data[0]?._id}`);
                                                }}>
                                                <img
                                                  src={_3DIcon}
                                                  className={style.Icon3d}
                                                  style={{ width: '50px', height: '40px' }}
                                                />
                                              </button>
                                            )}
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              borderRadius: '30px',
                                              backgroundColor: '#DB5353',
                                              color: '#FFFFFF',
                                              fontSize: '12px',
                                              border: 'none',
                                              padding: '0px 15px 0px 15px',
                                              height: '30px'
                                            }}
                                            disabled={savedMoodBoardIds.includes(data[0]?._id)}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddToBoard(data[0]);
                                            }}>
                                            {savedMoodBoardIds.includes(data[0]?._id)
                                              ? 'Saved'
                                              : 'Save'}
                                          </button>
                                          {data[0]?.hasOwnProperty('models_status') &&
                                            data[0].models_status == 3 && (
                                              <button
                                                className={style.arIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  handleQr(data[0]?._id);
                                                }}>
                                                <img src={ARIcon} className={style.arIcon} />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classnames('col-4')}>
                              <div className={classnames('row')}>
                                <div
                                  className={classnames('col-12')}
                                  style={{ paddingTop: '30px' }}>
                                  <div
                                    style={{
                                      marginBottom: '15px',
                                      height: '100%'
                                    }}
                                    className={data[1] ? style.item : 'd-none'}>
                                    <img
                                      src={data[1]?.preview_url}
                                      height={'100%'}
                                      width={'100%'}
                                      alt=""
                                    />
                                    <div className={style.content}>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-flex justify-content-between w-100 mx-2 mt-1"
                                        onClick={() =>
                                          auth.isAuthenticated
                                            ? handleMoodBoardProducts(data[1]?._id)
                                            : history.push(`/pre-explore-detail/${data[1]?._id}`)
                                        }>
                                        <div>
                                          <span
                                            style={{
                                              opacity: 1,
                                              color: '#ffffff',
                                              fontWeight: 700,
                                              fontSize: '16px',
                                              marginLeft: '7px'
                                            }}>
                                            {uniqueProducts(data[1] ? data[1] : 0)} Products
                                          </span>
                                          {data[1]?.hasOwnProperty('model_url') &&
                                            data[1]?.model_url != null && (
                                              <button
                                                className={style._3dIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  openinnewtab(`/3droom/${data[1]?._id}`);
                                                }}>
                                                <img
                                                  src={_3DIcon}
                                                  className={style.Icon3d}
                                                  style={{ width: '50px', height: '40px' }}
                                                />
                                              </button>
                                            )}
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              borderRadius: '30px',
                                              backgroundColor: '#DB5353',
                                              color: '#FFFFFF',
                                              fontSize: '12px',
                                              border: 'none',
                                              padding: '0px 15px 0px 15px',
                                              height: '30px'
                                            }}
                                            disabled={savedMoodBoardIds.includes(data[1]?._id)}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddToBoard(data[1] ? data[1] : '');
                                            }}>
                                            {savedMoodBoardIds.includes(data[1]?._id)
                                              ? 'Saved'
                                              : 'Save'}
                                          </button>
                                          {data[1]?.hasOwnProperty('models_status') &&
                                            data[1].models_status == 3 && (
                                              <button
                                                className={style.arIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  handleQr(data[1]?._id);
                                                }}>
                                                <img src={ARIcon} className={style.arIcon} />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={classnames('col-12')}
                                  style={{ paddingTop: '30px' }}>
                                  <div
                                    style={{
                                      marginBottom: '15px',
                                      height: '100%'
                                    }}
                                    className={data[2] ? style.item : 'd-none'}>
                                    <img
                                      src={data[2]?.preview_url}
                                      height={'100%'}
                                      width={'100%'}
                                      alt=""
                                    />
                                    <div className={style.content}>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-flex justify-content-between w-100 mx-2 mt-1"
                                        onClick={() =>
                                          auth.isAuthenticated
                                            ? handleMoodBoardProducts(data[2]?._id)
                                            : history.push(`/pre-explore-detail/${data[2]?._id}`)
                                        }>
                                        <div>
                                          <span
                                            style={{
                                              opacity: 1,
                                              color: '#ffffff',
                                              fontWeight: 700,
                                              fontSize: '16px',
                                              marginLeft: '7px'
                                            }}>
                                            {uniqueProducts(data[2] ? data[2] : 0)} Products
                                          </span>
                                          {data[2]?.hasOwnProperty('model_url') &&
                                            data[2]?.model_url != null && (
                                              <button
                                                className={style._3dIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  openinnewtab(`/3droom/${data[2]?._id}`);
                                                }}>
                                                <img
                                                  src={_3DIcon}
                                                  className={style.Icon3d}
                                                  style={{ width: '50px', height: '40px' }}
                                                />
                                              </button>
                                            )}
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              borderRadius: '30px',
                                              backgroundColor: '#DB5353',
                                              color: '#FFFFFF',
                                              fontSize: '12px',
                                              border: 'none',
                                              padding: '0px 15px 0px 15px',
                                              height: '30px'
                                            }}
                                            disabled={savedMoodBoardIds.includes(data[2]?._id)}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddToBoard(data[2] ? data[2] : '');
                                            }}>
                                            {savedMoodBoardIds.includes(data[2]?._id)
                                              ? 'Saved'
                                              : 'Save'}
                                          </button>
                                          {data[2]?.hasOwnProperty('models_status') &&
                                            data[2].models_status == 3 && (
                                              <button
                                                className={style.arIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  handleQr(data[2]?._id);
                                                }}>
                                                <img src={ARIcon} className={style.arIcon} />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('row')}>
                            <div className={classnames('col-6')}>
                              <div className={classnames('row')}>
                                <div
                                  className={classnames('col-12')}
                                  style={{ paddingTop: '30px' }}>
                                  <div
                                    style={{
                                      marginBottom: '15px',
                                      height: '100%'
                                    }}
                                    className={data[3] ? style.item : 'd-none'}>
                                    <img
                                      src={data[3]?.preview_url}
                                      height={'100%'}
                                      width={'100%'}
                                      alt=""
                                    />
                                    <div className={style.content}>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-flex justify-content-between w-100 mx-2 mt-1"
                                        onClick={() =>
                                          auth.isAuthenticated
                                            ? handleMoodBoardProducts(data[3]?._id)
                                            : history.push(`/pre-explore-detail/${data[3]?._id}`)
                                        }>
                                        <div>
                                          <span
                                            style={{
                                              opacity: 1,
                                              color: '#ffffff',
                                              fontWeight: 700,
                                              fontSize: '16px',
                                              marginLeft: '7px'
                                            }}>
                                            {uniqueProducts(data[3] ? data[3] : 0)} Products
                                          </span>
                                          {data[3]?.hasOwnProperty('model_url') &&
                                            data[3]?.model_url != null && (
                                              <button
                                                className={style._3dIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  openinnewtab(`/3droom/${data[3]?._id}`);
                                                }}>
                                                <img
                                                  src={_3DIcon}
                                                  className={style.Icon3d}
                                                  style={{ width: '50px', height: '40px' }}
                                                />
                                              </button>
                                            )}
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              borderRadius: '30px',
                                              backgroundColor: '#DB5353',
                                              color: '#FFFFFF',
                                              fontSize: '12px',
                                              border: 'none',
                                              padding: '0px 15px 0px 15px',
                                              height: '30px'
                                            }}
                                            disabled={savedMoodBoardIds.includes(data[3]?._id)}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddToBoard(data[3] ? data[3] : '');
                                            }}>
                                            {savedMoodBoardIds.includes(data[3]?._id)
                                              ? 'Saved'
                                              : 'Save'}
                                          </button>
                                          {data[3]?.hasOwnProperty('models_status') &&
                                            data[3].models_status == 3 && (
                                              <button
                                                className={style.arIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  handleQr(data[3]?._id);
                                                }}>
                                                <img src={ARIcon} className={style.arIcon} />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classnames('col-6')}>
                              <div className={classnames('row')}>
                                <div
                                  className={classnames('col-12')}
                                  style={{ paddingTop: '30px' }}>
                                  <div
                                    style={{
                                      marginBottom: '15px',
                                      height: '100%'
                                    }}
                                    className={data[4] ? style.item : 'd-none'}>
                                    <img
                                      src={data[4]?.preview_url}
                                      height={'100%'}
                                      width={'100%'}
                                      alt=""
                                    />
                                    <div className={style.content}>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-flex justify-content-between w-100 mx-2 mt-1">
                                        <div>
                                          <span
                                            style={{
                                              opacity: 1,
                                              color: '#ffffff',
                                              fontWeight: 700,
                                              fontSize: '16px',
                                              marginLeft: '7px'
                                            }}>
                                            {uniqueProducts(data[4] ? data[4] : 0)} Products
                                          </span>
                                          {data[4]?.hasOwnProperty('model_url') &&
                                            data[4]?.model_url != null && (
                                              <button
                                                className={style._3dIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  openinnewtab(`/3droom/${data[4]?._id}`);
                                                }}>
                                                <img
                                                  src={_3DIcon}
                                                  className={style.Icon3d}
                                                  style={{ width: '50px', height: '40px' }}
                                                />
                                              </button>
                                            )}
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              borderRadius: '30px',
                                              backgroundColor: '#DB5353',
                                              color: '#FFFFFF',
                                              fontSize: '12px',
                                              border: 'none',
                                              padding: '0px 15px 0px 15px',
                                              height: '30px'
                                            }}
                                            disabled={savedMoodBoardIds.includes(data[4]?._id)}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddToBoard(data[4] ? data[4] : '');
                                            }}>
                                            {savedMoodBoardIds.includes(data[4]?._id)
                                              ? 'Saved'
                                              : 'Save'}
                                          </button>
                                          {data[4]?.hasOwnProperty('models_status') &&
                                            data[4].models_status == 3 && (
                                              <button
                                                className={style.arIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  handleQr(data[4]?._id);
                                                }}>
                                                <img src={ARIcon} className={style.arIcon} />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('row ')}>
                            <div className={classnames('col-4')}>
                              <div className={classnames('row')}>
                                <div
                                  className={classnames('col-12')}
                                  style={{ paddingTop: '30px' }}>
                                  <div
                                    style={{
                                      marginBottom: '15px',
                                      height: '100%'
                                    }}
                                    className={data[5] ? style.item : 'd-none'}>
                                    <img
                                      src={data[5]?.preview_url}
                                      height={'100%'}
                                      width={'100%'}
                                      alt=""
                                    />
                                    <div className={style.content}>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-flex justify-content-between w-100 mx-2 mt-1"
                                        onClick={() =>
                                          auth.isAuthenticated
                                            ? handleMoodBoardProducts(data[5]?._id)
                                            : history.push(`/pre-explore-detail/${data[5]?._id}`)
                                        }>
                                        <div>
                                          <span
                                            style={{
                                              opacity: 1,
                                              color: '#ffffff',
                                              fontWeight: 700,
                                              fontSize: '16px',
                                              marginLeft: '7px'
                                            }}>
                                            {uniqueProducts(data[5] ? data[5] : 0)} Products
                                          </span>
                                          {data[5]?.hasOwnProperty('model_url') &&
                                            data[5]?.model_url != null && (
                                              <button
                                                className={style._3dIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  openinnewtab(`/3droom/${data[5]?._id}`);
                                                }}>
                                                <img
                                                  src={_3DIcon}
                                                  className={style.Icon3d}
                                                  style={{ width: '50px', height: '40px' }}
                                                />
                                              </button>
                                            )}
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              borderRadius: '30px',
                                              backgroundColor: '#DB5353',
                                              color: '#FFFFFF',
                                              fontSize: '12px',
                                              border: 'none',
                                              padding: '0px 15px 0px 15px',
                                              height: '30px'
                                            }}
                                            disabled={savedMoodBoardIds.includes(data[5]?._id)}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddToBoard(data[5] ? data[5] : '');
                                            }}>
                                            {savedMoodBoardIds.includes(data[5]?._id)
                                              ? 'Saved'
                                              : 'Save'}
                                          </button>
                                          {data[5]?.hasOwnProperty('models_status') &&
                                            data[5].models_status == 3 && (
                                              <button
                                                className={style.arIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  handleQr(data[5]?._id);
                                                }}>
                                                <img src={ARIcon} className={style.arIcon} />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={classnames('col-12')}
                                  style={{ paddingTop: '30px' }}>
                                  <div
                                    style={{
                                      marginBottom: '15px',
                                      height: '100%'
                                    }}
                                    className={data[6] ? style.item : 'd-none'}>
                                    <img
                                      src={data[6]?.preview_url}
                                      height={'100%'}
                                      width={'100%'}
                                      alt=""
                                    />
                                    <div className={style.content}>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-flex justify-content-between w-100 mx-2 mt-1"
                                        onClick={() =>
                                          auth.isAuthenticated
                                            ? handleMoodBoardProducts(data[6]?._id)
                                            : history.push(`/pre-explore-detail/${data[6]?._id}`)
                                        }>
                                        <div>
                                          <span
                                            style={{
                                              opacity: 1,
                                              color: '#ffffff',
                                              fontWeight: 700,
                                              fontSize: '16px',
                                              marginLeft: '7px'
                                            }}>
                                            {uniqueProducts(data[6] ? data[6] : 0)} Products
                                          </span>
                                          {data[6]?.hasOwnProperty('model_url') &&
                                            data[6]?.model_url != null && (
                                              <button
                                                className={style._3dIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  openinnewtab(`/3droom/${data[6]?._id}`);
                                                }}>
                                                <img
                                                  src={_3DIcon}
                                                  className={style.Icon3d}
                                                  style={{ width: '50px', height: '40px' }}
                                                />
                                              </button>
                                            )}
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              borderRadius: '30px',
                                              backgroundColor: '#DB5353',
                                              color: '#FFFFFF',
                                              fontSize: '12px',
                                              border: 'none',
                                              padding: '0px 15px 0px 15px',
                                              height: '30px'
                                            }}
                                            disabled={savedMoodBoardIds.includes(data[6]?._id)}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddToBoard(data[6] ? data[6] : '');
                                            }}>
                                            {savedMoodBoardIds.includes(data[6]?._id)
                                              ? 'Saved'
                                              : 'Save'}
                                          </button>
                                          {data[6]?.hasOwnProperty('models_status') &&
                                            data[6].models_status == 3 && (
                                              <button
                                                className={style.arIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  handleQr(data[6]?._id);
                                                }}>
                                                <img src={ARIcon} className={style.arIcon} />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classnames('col-8')}>
                              <div className={classnames('row h-100')}>
                                <div
                                  className={classnames('col-12')}
                                  style={{ paddingTop: '30px' }}>
                                  <div
                                    style={{
                                      marginBottom: '15px',
                                      height: '100%'
                                    }}
                                    className={data[7] ? style.item : 'd-none'}>
                                    <img
                                      src={data[7]?.preview_url}
                                      height={'100%'}
                                      width={'100%'}
                                      alt=""
                                    />
                                    <div className={style.content}>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-flex justify-content-between w-100 mx-2 mt-1"
                                        onClick={() =>
                                          auth.isAuthenticated
                                            ? handleMoodBoardProducts(data[7]?._id)
                                            : history.push(`/pre-explore-detail/${data[7]?._id}`)
                                        }>
                                        <div>
                                          <span
                                            style={{
                                              opacity: 1,
                                              color: '#ffffff',
                                              fontWeight: 700,
                                              fontSize: '16px',
                                              marginLeft: '7px'
                                            }}>
                                            {uniqueProducts(data[7] ? data[7] : 0)} Products
                                          </span>
                                          {data[7]?.hasOwnProperty('model_url') &&
                                            data[7]?.model_url != null && (
                                              <button
                                                className={style._3dIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  openinnewtab(`/3droom/${data[7]?._id}`);
                                                }}>
                                                <img
                                                  src={_3DIcon}
                                                  className={style.Icon3d}
                                                  style={{ width: '50px', height: '40px' }}
                                                />
                                              </button>
                                            )}
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              borderRadius: '30px',
                                              backgroundColor: '#DB5353',
                                              color: '#FFFFFF',
                                              fontSize: '12px',
                                              border: 'none',
                                              padding: '0px 15px 0px 15px',
                                              height: '30px'
                                            }}
                                            disabled={savedMoodBoardIds.includes(data[7]?._id)}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddToBoard(data[7] ? data[7] : '');
                                            }}>
                                            {savedMoodBoardIds.includes(data[7]?._id)
                                              ? 'Saved'
                                              : 'Save'}
                                          </button>
                                          {data[7]?.hasOwnProperty('models_status') &&
                                            data[7].models_status == 3 && (
                                              <button
                                                className={style.arIconbtn}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  handleQr(data[7]?._id);
                                                }}>
                                                <img src={ARIcon} className={style.arIcon} />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {moodBoards.length == 0 && !loading && (
                    <h1 className="text-center">No Moodboards Available</h1>
                  )}
                  <ReactPaginate
                    forcePage={page - 1}
                    onPageChange={({ selected }) => setPage(selected + 1)}
                    pageCount={totalPage}
                    breakLabel="..."
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    containerClassName={
                      'justify-content-end pagination react-paginate pagination-sm p-1 mt-1'
                    }
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    previousLabel={'<'}
                    nextLabel={'>'}
                  />
                </div>
              )}
              {dimension[0] < 500 && (
                <>
                  {moodBoardsMobile.length > 0 &&
                    moodBoardsMobile?.map((data, index) => {
                      return (
                        <div
                          className={classnames('row')}
                          key={index}
                          style={{ marginTop: '3.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                          <div className={classnames('col-12 h-100')}>
                            <div
                              style={{
                                marginBottom: '5px',
                                height: '100%'
                              }}
                              className={data ? style.item : 'd-none'}>
                              <img src={data?.preview_url} height={'100%'} width={'100%'} alt="" />
                              <div className={style.content}>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  className="d-flex justify-content-between w-100 mx-2 mt-1"
                                  onClick={() =>
                                    auth.isAuthenticated
                                      ? handleMoodBoardProducts(data?._id)
                                      : history.push(`/pre-explore-detail/${data?._id}`)
                                  }>
                                  <div>
                                    <span
                                      style={{
                                        opacity: 1,
                                        color: '#ffffff',
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        marginLeft: '7px'
                                      }}>
                                      {uniqueProducts(data ? data : 0)} Products
                                    </span>
                                    {data?.hasOwnProperty('model_url') && data?.model_url != null && (
                                      <button
                                        className={style._3dIconbtn}
                                        onClick={e => {
                                          e.stopPropagation();
                                          openinnewtab(`/3droom/${data?._id}`);
                                        }}>
                                        <img
                                          src={_3DIcon}
                                          className={style.Icon3d}
                                          style={{ width: '50px', height: '40px' }}
                                        />
                                      </button>
                                    )}
                                  </div>
                                  <div>
                                    <button
                                      style={{
                                        borderRadius: '30px',
                                        backgroundColor: '#DB5353',
                                        color: '#FFFFFF',
                                        fontSize: '12px',
                                        border: 'none',
                                        padding: '0px 15px 0px 15px',
                                        height: '30px'
                                      }}
                                      disabled={savedMoodBoardIds.includes(data?._id)}
                                      onClick={e => {
                                        e.stopPropagation();
                                        handleAddToBoard(data ? data : '');
                                      }}>
                                      {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                                    </button>
                                    {data?.hasOwnProperty('models_status') &&
                                      data.models_status == 3 && (
                                        <button
                                          className={style.arIconbtn}
                                          onClick={e => {
                                            e.stopPropagation();
                                            openinnewtab(`pmapp://explore?${data?._id}`);
                                          }}>
                                          <img src={ARIcon} className={style.arIcon} />
                                        </button>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <span
                                  style={{
                                    opacity: 1,
                                    color: '#000000',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    marginLeft: '7px'
                                  }}>
                                  {uniqueProducts(data ? data : 0)} Products
                                </span>
                              </div>
                              <div>
                                {data?.hasOwnProperty('models_status') && data.models_status == 3 && (
                                  <button
                                    onClick={e => {
                                      e.stopPropagation();
                                      openinnewtab(`pmapp://explore?${data?._id}`);
                                    }}
                                    style={{ backgroundColor: '#ffffff' }}
                                    className="mr-2">
                                    <img src={ARIcon} style={{ width: '30px' }} />
                                  </button>
                                )}
                                <button
                                  style={{
                                    borderRadius: '30px',
                                    backgroundColor: '#DB5353',
                                    color: '#FFFFFF',
                                    fontSize: '12px',
                                    border: 'none',
                                    padding: '0px 15px 0px 15px',
                                    height: '30px'
                                  }}
                                  disabled={savedMoodBoardIds.includes(data?._id)}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleAddToBoard(data ? data : '');
                                  }}>
                                  {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {moodBoardsMobile.length == 0 && !loading && (
                    <h1 className="text-center">No Moodboards Available</h1>
                  )}
                  <ReactPaginate
                    forcePage={page - 1}
                    onPageChange={({ selected }) => setPage(selected + 1)}
                    pageCount={totalPage}
                    breakLabel="..."
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    containerClassName={
                      'justify-content-end pagination react-paginate pagination-sm p-1 mt-1'
                    }
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    previousLabel={'<'}
                    nextLabel={'>'}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      ;
    </>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { set_explore_filter })(withRouter(ExploreTagsMoodboards));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
