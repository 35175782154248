import React, { useState, useEffect, useLayoutEffect } from 'react';
import style from './Concept.module.css';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Carousel, { consts } from 'react-elastic-carousel';
import 'react-multi-carousel/lib/styles.css';
import Rating from 'react-rating';
import Snackbar from '@material-ui/core/Snackbar';
import customerApiInstance from '../../../utils/CustomerApiInstance';
import PrevArrow from '../../../assets/images/sliderArrowPrev.png';
import NextArrow from '../../../assets/images/sliderArrowNext.png';
import Lottie from 'react-lottie';
import ArtWorkAnimation from '../../../components/lottie/ArtWork.json';

const Concept = ({ auth, history, page, setProjectDataRooms, projectDataRooms }) => {
  const { id } = useParams();
  const [moodBoards, setMoodBoards] = useState([]);
  const [products, setProducts] = useState([]);
  const [likedProductIds, setLikedProductIds] = useState([]);
  const [dislikedProductIds, setDislikedProductIds] = useState([]);
  const [neutralProductIds, setNeutralProductIds] = useState([]);
  const [currentMoodboard, setCurrentMoodboard] = useState(0);
  const [moodboardInputs, setMoodBoardInputs] = useState([]);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [project, setProject] = useState({});
  const [lottie] = useState(false);
  const [tempProducts, setTempProducts] = useState([]);
  const [pagess, setPages] = useState(0);
  // ConsoleHelper(project);
  let byChecked = false;
  if (
    history.location.state &&
    history.location.state.check &&
    history.location.state.check === true
  ) {
    byChecked = true;
  }
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
  ];

  const dimension = useMediaQuery();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ArtWorkAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleLiked = product => {
    if (!likedProductIds.includes(product._id)) {
      setLikedProductIds(prev => [...prev, product._id]);
      if (dislikedProductIds.includes(product._id)) {
        setDislikedProductIds(dislikedProductIds.filter(filter => filter !== product._id));
      } else if (neutralProductIds.includes(product._id)) {
        setNeutralProductIds(neutralProductIds.filter(filter => filter !== product._id));
      }
    } else {
      setLikedProductIds(likedProductIds.filter(filter => filter !== product._id));
    }
  };
  const handleDisliked = product => {
    if (!dislikedProductIds.includes(product._id)) {
      setDislikedProductIds(prev => [...prev, product._id]);
      if (likedProductIds.includes(product._id)) {
        setLikedProductIds(likedProductIds.filter(filter => filter !== product._id));
      } else if (neutralProductIds.includes(product._id)) {
        setNeutralProductIds(neutralProductIds.filter(filter => filter !== product._id));
      }
    } else {
      setDislikedProductIds(dislikedProductIds.filter(filter => filter !== product._id));
    }
  };
  const handleNeutral = product => {
    if (!neutralProductIds.includes(product._id)) {
      setNeutralProductIds(prev => [...prev, product._id]);
      if (likedProductIds.includes(product._id)) {
        setLikedProductIds(likedProductIds.filter(filter => filter !== product._id));
      } else if (dislikedProductIds.includes(product._id)) {
        setDislikedProductIds(dislikedProductIds.filter(filter => filter !== product._id));
      }
    } else {
      setNeutralProductIds(neutralProductIds.filter(filter => filter !== product._id));
    }
  };

  useEffect(() => {
    const getMoodboards = async () => {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/concepts/${id}`
      );
      let mbrd = res.data.moodBoards;
      let prdts = res.data.products;
      let prds;
      let pd1 = [];
      mbrd &&
        mbrd.length > 0 &&
        mbrd.map(moodboard => {
          moodboard.shared_to_customer &&
            moodboard.shared_to_customer.products &&
            moodboard.shared_to_customer.products.length > 0 &&
            moodboard.shared_to_customer.products.map((item, index) => {
              item.map((data, key) => {
                prds = prdts.filter(pd => pd._id === data.product_id);

                if (prds && prds.length === 1) {
                  prds[0] = { ...prds[0], moodboard_id: moodboard._id };
                  pd1.push(prds[0]);
                }
              });
            });
        });
      pd1 = pd1.filter(
        (ele, ind) =>
          ind ===
          pd1.findIndex(elem => elem._id === ele._id && elem.moodboard_id === ele.moodboard_id)
      );
      setTempProducts([...pd1]);

      let md_prods = [];
      if (mbrd.length > 0) {
        let firsrMoodboard = mbrd[0];
        if (pd1 && pd1.length > 0) {
          md_prods = pd1.filter(pr => pr.moodboard_id === firsrMoodboard._id);
        }
      }
      if (md_prods && md_prods.length > 0) {
        setProducts([...md_prods]);
      } else {
        setProducts([]);
      }

      setMoodBoards(res.data.moodBoards);
      let liked = [];
      let disliked = [];
      let neutral = [];
      const res1 = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );
      if (
        res1.data.job === 'REVIEW_CONCEPT' &&
        byChecked === false &&
        checkMoodboardReviewStatus(res.data.moodBoards)
      ) {
        history.push({ pathname: `/rooms/${id}`, state: { type: 'review' } });
      }
      setProject({ ...res1.data });
      if (res1.data.product_reactions && Object.keys(res1.data.product_reactions).length) {
        liked = [...liked, ...res1.data.product_reactions.liked];
        setLikedProductIds([...liked]);
        disliked = [...disliked, ...res1.data.product_reactions.disliked];
        setDislikedProductIds([...disliked]);
        neutral = [...neutral, ...res1.data.product_reactions.neutral];
        setNeutralProductIds([...neutral]);
      }
    };
    getMoodboards();
  }, [byChecked]);

  const checkMoodboardReviewStatus = projectMoodboards => {
    let statusCount = projectMoodboards.filter(proj => proj.status === 'REVIEW_CONCEPT');
    if (statusCount && statusCount.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const renderColumns = numCols => {
    let cols = [];
    let rows = [];

    products &&
      products.length > 0 &&
      products.map(data => {
        cols.push(
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              width: '305px',
              height: '336px',
              margin: '20px',
              border: '0.5px solid #A3A3A3',
              borderRadius: '10px'
            }}>
            <div className="d-flex justify-content-center" style={{ height: '70%' }}>
              <img
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                src={data.image && data.image.length > 0 && data.image[0]}
                alt=""
              />
            </div>
            <div className="row w-100 mt-3">
              <div className="col text-center d-flex justify-content-center align-items-end">
                <img
                  onClick={() => {
                    if (
                      (project?.product_reactions &&
                        project?.product_reactions?.liked &&
                        project?.product_reactions.liked?.length > 0) ||
                      project?.product_reactions.disliked?.length > 0 ||
                      project?.product_reactions.neutral?.length > 0
                    ) {
                      // setSnack(true);
                      // setSnackMessage("Edit is not allowed ");
                    } else {
                      handleLiked(data, 0);
                    }
                  }}
                  src={
                    likedProductIds?.includes(data?._id)
                      ? // 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Icon+feather-smile-6.png'
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/perfect.png'
                      : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/perfect_default.png'
                  }
                  alt=""
                />
              </div>

              <div className="col text-center  d-flex justify-content-center align-items-end">
                <img
                  onClick={() => {
                    // ConsoleHelper("neurol", project.product_reactions);
                    if (
                      (project?.product_reactions &&
                        project?.product_reactions.neutral &&
                        project?.product_reactions.liked?.length > 0) ||
                      project?.product_reactions.disliked?.length > 0 ||
                      project?.product_reactions.neutral?.length > 0
                    ) {
                      // setSnack(true);
                      // setSnackMessage("Edit is not allowed ");
                    } else {
                      handleNeutral(data, 2);
                    }
                  }}
                  //   if (
                  //     project.product_reactions &&
                  //     project.product_reactions.neutral &&
                  //     project.product_reactions.neutral.length > 0
                  //   ) {
                  //     setSnack(true);
                  //     setSnackMessage("Edit is not allowed ");
                  //   } else {
                  //     handleNeutral(data, 2);
                  //   }
                  // }}
                  src={
                    neutralProductIds?.includes(data?._id)
                      ? // 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Icon+feather-smile-77.png'
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/okay.png'
                      : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/okay_default.png'
                  }
                  alt=""
                />
              </div>
              <div className="col text-center  d-flex justify-content-center align-items-end">
                <img
                  onClick={() => {
                    if (
                      (project?.product_reactions &&
                        project?.product_reactions?.disliked &&
                        // project.product_reactions.disliked.length > 0
                        project?.product_reactions.liked?.length > 0) ||
                      project?.product_reactions.disliked?.length > 0 ||
                      project?.product_reactions.neutral?.length > 0
                    ) {
                      // setSnack(true);
                      // setSnackMessage("Edit is not allowed ");
                    } else {
                      handleDisliked(data, 1);
                    }
                  }}
                  src={
                    dislikedProductIds?.includes(data?._id)
                      ? // 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Group+6260.png'
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/bad.png'
                      : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/bad_default.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        );
      });

    if (cols.length) {
      rows.push(
        <div className="row d-flex justify-content-center align-items-center" key={rows.length}>
          {cols}
        </div>
      );
    }
    return rows;
  };

  const getRatingValue = () => {
    let x;
    if (currentMoodboard >= 0 && moodBoards.length > 0) {
      if (moodBoards[currentMoodboard].customer_rating) {
        return moodBoards[currentMoodboard].customer_rating;
      }
    }
    x = moodboardInputs.findIndex(x => x._id === moodBoards[currentMoodboard]._id);
    if (x === -1) return 0;
    else {
      return moodboardInputs[x].customer_rating;
    }
  };

  const setCustomerRating = (name, value) => {
    const moodboard = moodBoards[currentMoodboard]._id;
    const existingMoodboard = moodboardInputs.findIndex(brd => brd._id === moodboard);
    if (existingMoodboard >= 0) {
      moodboardInputs[existingMoodboard].customer_rating = value;
      setMoodBoardInputs([...moodboardInputs]);
    } else {
      setMoodBoardInputs([...moodboardInputs, { _id: moodboard, customer_rating: value }]);
    }
  };
  useEffect(() => {}, [moodboardInputs]);

  const handleSubmit = async () => {
    const productReactionsData = {
      product_reactions: {
        liked: likedProductIds,
        disliked: dislikedProductIds,
        neutral: neutralProductIds
      },
      customer_feedback: 'COMPLETED',
      job: 'REVIEW_CONCEPT'
    };
    const res = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${id}`,
      productReactionsData
    );
    await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/bulkUpdate`,
      moodboardInputs
    );
    if (res.data) {
      if (res.data.job === 'REVIEW_CONCEPT') {
        setProjectDataRooms({ ...projectDataRooms, level: 3 });
        history.push({ pathname: `/rooms/${id}`, state: { type: 'review' } });
      } else {
        setSnack(true);
        setSnackMessage('Please stay on this page and refresh after few minutes');
      }
    }
  };

  const checkAllProductsReacted = () => {
    const reactedProducts =
      likedProductIds.length + dislikedProductIds.length + neutralProductIds.length;
    let uniqueProds = [...new Map(tempProducts.map(item => [item['_id'], item])).values()];
    if (reactedProducts === uniqueProds.length) return true;
    else return false;
  };

  const checkAllMoodboardsReacted = () => {
    if (moodBoards.length === moodboardInputs.length) return true;
    else return false;
  };

  const myArrow = ({ type, onClick }) => {
    const handleClick = () => {
      if (type === consts.PREV) {
        pagess !== 0 && setPages(pagess - 1);
        pagess !== 0 && carouselChange(pagess - 1);
      } else if (pagess + 1 <= products.length) {
        setPages(pagess + 1);
        carouselChange(pagess + 1);
      }
      onClick();
    };

    return (
      <button onClick={handleClick} style={{ backgroundColor: 'rgb(250, 250, 250)' }}>
        <img src={type === consts.PREV ? PrevArrow : NextArrow} />
      </button>
    );
  };

  const carouselChange = page => {
    if (moodBoards && moodBoards.length > 0) {
      let mb_id = moodBoards[page]._id;
      if (tempProducts && tempProducts.length > 0) {
        setProducts(tempProducts.filter(pr => pr.moodboard_id === mb_id));
      } else {
        setProducts([]);
      }
    }
  };

  return (
    <>
      {lottie ? (
        <div style={{ marginTop: '70px' }}>
          <Lottie options={defaultOptions} height="60vh" width={400} />
          <p
            className={style.perfectStyle}
            style={{
              textAlign: 'center',
              fontSize: '20px',
              position: 'relative',
              margin: '20px 0px'
            }}>
            <i>
              We're curating a "bag" full of
              <br /> products that you might love.
            </i>
          </p>
        </div>
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snack}
            onClose={() => setSnack(false)}
            message={snackMessage}
            autoHideDuration={2000}
          />
          <div
            style={{
              backgroundColor: '#FAFAFA',
              width: '100%',
              paddingTop: '35px',
              paddingBottom: dimension[0] > 500 ? '65px' : '20px'
            }}
            className={style.topContainer}>
            <div
              className="p-0 m-auto"
              style={{
                cursor: 'pointer',
                width: dimension[0] > 500 ? '60%' : '95%'
              }}>
              <Carousel
                breakPoints={breakPoints}
                renderPagination={({ pages, activePage, onClick }) => {
                  return (
                    <div className="row mt-4">
                      {pages.map((page, i) => {
                        const isActivePage = activePage === page;
                        //  let md_prods = [];
                        setCurrentMoodboard(activePage);

                        return (
                          <div className="col-4 p-0 d-flex justify-content-center">
                            <button
                              className={page === activePage ? style.button : style.button1}
                              key={page}
                              onClick={() => {
                                carouselChange(page);
                                setPages(page);
                                onClick(page);
                              }}
                              active={isActivePage}>
                              Concept {i + 1}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                renderArrow={myArrow}
                showArrows={true}>
                {moodBoards &&
                  moodBoards.map((board, index) => {
                    return (
                      <img
                        style={{ borderRadius: '6px' }}
                        className={style.container2Image}
                        src={
                          board.shared_to_customer
                            ? board.shared_to_customer.preview_url
                            : board.preview_url
                        }
                        alt=""
                      />
                    );
                  })}
              </Carousel>
            </div>
            <div className="container text-center mt-5">
              <p style={{ color: '#232424', fontWeight: 600 }}>
                Rate this concept board<br></br>
              </p>

              <Rating
                quiet={
                  moodBoards[currentMoodboard]?.customer_rating &&
                  moodBoards[currentMoodboard]?.customer_rating > 0
                }
                readonly={
                  moodBoards[currentMoodboard]?.customer_rating &&
                  moodBoards[currentMoodboard]?.customer_rating > 0
                }
                name="moodboardRating"
                emptySymbol={
                  <img
                    alt=""
                    src="https://d3izcfql7lesks.cloudfront.net/Path_1252_ixllee.svg"
                    className="pl-3"
                  />
                }
                fullSymbol={
                  <img
                    alt=""
                    src="https://d3izcfql7lesks.cloudfront.net/Path_1252_dp7bmx.svg"
                    className="pl-3"
                  />
                }
                onClick={value => {
                  if (
                    moodBoards[currentMoodboard]?.customer_rating &&
                    moodBoards[currentMoodboard]?.customer_rating > 0
                  ) {
                    // setSnack(true);
                    // setSnackMessage("Edit is not allowed");
                  } else {
                    setCustomerRating('moodboardRating', value);
                  }
                }}
                initialRating={getRatingValue()}
              />
            </div>
          </div>
          <div className="container text-center mt-5 mb-2">
            <p
              style={{
                color: '#0D0D0D',
                fontWeight: 'bold',
                fontSize: '20px',
                fontFamily: 'Quicksand'
              }}>
              Please react to all the furniture for us
              <br />
              to understand your style.
            </p>
          </div>
          <div className="container" style={{ maxWidth: '80%' }}>
            {products && products.length > 0 ? renderColumns() : 'No products Available'}
          </div>
          <div className="row w-100 d-flex justify-content-center">
            {!(
              moodBoards[currentMoodboard]?.customer_rating &&
              moodBoards[currentMoodboard]?.customer_rating > 0
            ) && (
              <button
                style={{ marginTop: '30px', marginBottom: '50px' }}
                className={style.submitButton}
                type="submit"
                onClick={() => {
                  if (checkAllProductsReacted() && checkAllMoodboardsReacted()) {
                    handleSubmit();
                  } else {
                    setSnack(true);
                    setSnackMessage('Please react to all products and moodboards');
                  }
                }}>
                Submit
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

Concept.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  rooms: state.questionnaireData.data
});

export default connect(mapStateToProps)(withRouter(Concept));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
