import React, { useEffect, useState } from 'react';
import { fabric } from 'fabric';
import ProductListing from './productsListing';
import style from './Review.module.css';
import classnames from 'classnames';
import { ArrowsFullscreen } from 'react-bootstrap-icons';
import axios from 'axios';
import BlueTick from '../../../assets/blueTick.svg';
import Carousel from 'react-bootstrap/Carousel';
import Snackbar from '@material-ui/core/Snackbar';
import customerApiInstance from '../../../utils/CustomerApiInstance';
import { storeCartCount } from '../../../store/actions/sampleaction';
import getCartDataCount from '../../../utils/CartDetails';
import { useParams } from 'react-router-dom';

const Canvas = props => {
  const { id } = useParams();
  const canvasRef = React.createRef();
  const fabricRef = React.useRef(null);
  const [activeObject, setActiveObject] = useState({});
  const [moodBoard, setMoodboard] = useState([]);
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [paginatedProductsMobile, setPaginatedProductsMobile] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const refCol = React.useRef(0);
  useEffect(() => {
    if (props.data) {
      fabricRef.current = new fabric.Canvas(canvasRef.current, {
        width: 600,
        height: 400,
        preserveObjectStacking: 'true'
      });

      const canvas = fabricRef.current;
      window.canvas = canvas;
      fabricRef.current.on('mouse:down', function(e) {
        if (e.target) {
          setActiveObject({ ...e.target });
        }
      });

      const offset = props.data.moodBoard.content.filter((item, index) => item.name === '');

      let staticJson = props.data.moodBoard.content.map((item, index) => {
        return {
          ...item,
          top: item.top - offset[0].top,
          left: item.left - offset[0].left,
          lockScalingX: true,
          lockScalingY: true,
          lockMovementX: true,
          lockMovementY: true,
          hasControls: false,
          hasBorders: false,
          hoverCursor: 'pointer',
          controlsAboveOverlay: 'true',
          interactive: false,
          selection: false,
          overlayImage: 'https://d3izcfql7lesks.cloudfront.net/Group_3047_ogvcuz.svg',
          selectable: item.name !== 'Background Image' && item.name !== '' && item.type === 'image'
        };
      });
      setMoodboard([...staticJson]);
      let temp = staticJson.slice();
      const products = temp.filter((item, index) => {
        if (
          item.visible === true &&
          item.type === 'image' &&
          item.id !== 'workarea' &&
          item.name !== 'Background Image'
        ) {
          return {
            item
          };
        }
      });

      props.setAddToCartProducts(products);
      let chunkedprod = makeChunks(products, 6);
      setPaginatedProducts(chunkedprod);
      setPaginatedProductsMobile(makeChunks(products, 3));
      if (chunkedprod.length > 0) handleProductSelect(chunkedprod[0][0]);
      staticJson = [...staticJson];
      const json = { objects: staticJson };
      canvas.loadFromJSON(json, canvas.renderAll.bind(canvas));
      resizeCanvas();
    }
  }, [window.innerWidth, props.data]);

  useEffect(() => {
    if (props.saveReviewMoodboard) {
      setTimeout(async () => {
        if (props.projectData.job === 'REVIEW_CONCEPT') {
          let option = {};
          if (option === void 0) {
            option = {
              name: 'New Image',
              format: 'png',
              quality: 1,
              multiplier: 2,
              width: 600,
              height: 400
            };
          }
          const dataUrl = fabricRef.current.toDataURL(option);
          function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',');
            const byteString =
              splitDataURI[0].indexOf('base64') >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

            const ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

            return new Blob([ia], { type: mimeString });
          }
          const formData = new FormData();
          formData.append('file', DataURIToBlob(dataUrl));
          await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/moodboard/save-review-concept-preview/${props.data.moodBoard._id}`,
            formData
          );
        }
      }, 1000);
    }
  }, [props.saveReviewMoodboard]);

  const outerCanvasContainer = document.getElementById('fabric-canvas-wrapper');
  const resizeCanvas = () => {
    const outerCanvasContainer = document.getElementById('fabric-canvas-wrapper');
    const ratio = fabricRef.current.getWidth() / fabricRef.current.getHeight();
    const containerWidth = outerCanvasContainer.clientWidth;
    const scale = containerWidth / fabricRef.current.getWidth();
    const zoom = fabricRef.current.getZoom() * scale;
    fabricRef.current.setDimensions({
      width: containerWidth,
      height: containerWidth / ratio
    });
    fabricRef.current.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    fabricRef.current.renderAll();
  };

  const handleVisibleAction = async (productId, uuid, layerId) => {
    let position;
    fabricRef.current.getObjects().map((i, index) => {
      if (i.uuid === uuid) {
        fabricRef.current.getObjects()[index].visible = false;
      }
    });
    let fabricActiveIndex = fabricRef.current
      .getObjects()
      .findIndex(i => i.productId === productId && i.uuid === uuid && i.layerId === layerId);
    if (fabricActiveIndex !== -1) {
      fabricRef.current.getObjects()[fabricActiveIndex].visible = true;
      fabricRef.current.setActiveObject(fabricRef.current.getObjects()[fabricActiveIndex]);
    }
    const alternateProducts = props.data.moodBoard.content.map((item, index) => {
      if (
        item.productId === productId &&
        item.uuid === uuid &&
        item.layerId === layerId &&
        fabricRef.current
      ) {
        item.visible = true;
        position = index;
        return item;
      } else if (item.uuid === uuid && fabricRef.current) {
        item.visible = false;
        return item;
      } else {
        return item;
      }
    });

    const offset = props.data.moodBoard.content.filter(item => item.name === '');
    const staticJson = alternateProducts.map((item, index) => {
      return {
        ...item,
        top: item.top - offset[0].top,
        left: item.left - offset[0].left,
        lockScalingX: true,
        lockScalingY: true,
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
        hasBorders: false,

        selectable: item.name !== 'Background Image' && item.name !== '' && item.type === 'image'
      };
    });
    setMoodboard([...alternateProducts]);
    const products = alternateProducts.filter((item, index) => {
      if (
        item.visible === true &&
        item.type === 'image' &&
        item.id !== 'workarea' &&
        item.name !== 'Background Image'
      ) {
        return {
          item
        };
      }
    });
    props.setAddToCartProducts(products);
    let chunkedprod = makeChunks(products, 6);
    setPaginatedProducts(chunkedprod);
    setPaginatedProductsMobile(makeChunks(products, 3));
    setActiveObject({ ...staticJson[position] });
    resizeCanvas();
    fabricRef.current.renderAll();
    fabricRef.current.requestRenderAll();
  };

  const handleMoodboardPrice = () => {
    if (moodBoard.length) {
      let products = [];
      let totalPrice = 0;
      moodBoard.map((item, index) => {
        if (item.visible && item.productId) {
          products = [...products, item.productId];
        }
      });
      props.data.moodBoardProducts.map((item, index) => {
        products.map((prod, key) => {
          if (item._id === prod) {
            totalPrice = totalPrice + item.price * (item.qty || 1);
          }
        });
      });
      return new Intl.NumberFormat('en-IN').format(totalPrice);
    } else {
      return 0;
    }
  };

  function makeChunks(arr, chunkSize) {
    let chunkedArray = [];

    for (let i = 0; i < arr?.length; i += chunkSize) {
      let myChunk = arr?.slice(i, i + chunkSize);
      chunkedArray.push(myChunk);
    }
    return chunkedArray;
  }

  const handleProductSelect = item => {
    setActiveObject({ ...item });
    let fabricActiveIndex = fabricRef.current
      .getObjects()
      .findIndex(i => i.uuid === item.uuid && i.layerId === item.layerId);
    if (fabricActiveIndex !== -1) {
      fabricRef.current.setActiveObject(fabricRef.current.getObjects()[fabricActiveIndex]);
    }
    fabricRef.current.renderAll();
  };

  const addToCart = async productData => {
    let userId;
    let prodBody;
    let auth = props.auth;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;
        const moodBoardProduct = [];
        props.addToCartProducts.map((item, index) => {
          productData.map((list, index) => {
            if (item.productId === list._id) {
              moodBoardProduct.push(list);
            }
          });
        });
        const isArray = moodBoardProduct instanceof Array;
        if (!isArray) {
          prodBody = {
            user_id: userId,
            products: [
              {
                product_id: moodBoardProduct._id,
                quantity: moodBoardProduct.quantity,
                rate: moodBoardProduct.price || moodBoardProduct.pixel_price,
                total_price:
                  moodBoardProduct.quantity *
                  (moodBoardProduct.price || moodBoardProduct.pixel_price),
                project_id: id
              }
            ]
          };
        } else {
          let allProducts = [];
          for (let i = 0; i < moodBoardProduct.length; i++) {
            allProducts.push({
              product_id: moodBoardProduct[i]._id,
              quantity: 1,
              rate: moodBoardProduct[i].price,
              total_price: 1 * (moodBoardProduct[i].price || moodBoardProduct[i].pixel_price),
              project_id: id
            });
          }

          prodBody = {
            user_id: userId,
            products: [...allProducts]
          };
        }
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          props.history.push({
            pathname: '/cart'
          });
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }
    storeCartCount(await getCartDataCount(auth));
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {props.dimension[0] > 500 ? (
        <div className={`row ${!props.modal || 'mx-2'}`}>
          <div className={`col-8`}>
            <div
              className="row px-3"
              style={{
                border: !props.modal && '1px solid #C3C3C3',
                position: 'relative'
              }}>
              <div
                className="my-3"
                style={{ width: '100%', height: '100%' }}
                id="fabric-canvas-wrapper">
                <canvas ref={canvasRef} />
              </div>

              {!props.modal && (
                <div
                  className="cursor-pointer "
                  style={{ position: 'absolute', right: '25px', top: '20px' }}
                  onClick={props.setModal}>
                  <ArrowsFullscreen />
                </div>
              )}
            </div>
            <div className="row mt-2">
              <div className="d-flex justify-content-between w-100">
                <div style={{ width: '29%' }}>
                  <div className={style.totalValue}>
                    <span style={{ fontSize: '12px' }}>Total value </span>
                    <span className="font-weight-bold" style={{ fontSize: '14px' }}>
                      &#8377; {handleMoodboardPrice()}
                    </span>
                  </div>
                </div>
                <div style={{ width: '70%' }}>
                  {props.page === 'REVIEW_CONCEPT' && (
                    <div className={style.confirmMoodboard}>
                      <div>
                        <img src="" />
                      </div>
                      <div>
                        <span> Would you like to confirm this moodboard?</span>
                      </div>
                      <div>
                        <button
                          className={style.confirmBtn}
                          onClick={props.handleCompleteSubmit}
                          disabled={props.disable.reviewYes}>
                          Yes
                        </button>
                        <button
                          className={style.declineBtn}
                          onClick={e => {
                            props.setDeclineModal(true);
                          }}
                          disabled={props.disable.reviewNo}>
                          No
                        </button>
                      </div>
                    </div>
                  )}
                  {props.page === 'FINALIZE_CONCEPT' && (
                    <div className={style.shopNowFinalize}>
                      <div>
                        <span style={{ fontWeight: '700' }}> Shop All Products</span>
                      </div>
                      <div>
                        <button
                          className={classnames(style.confirmBtn, 'ml-5')}
                          onClick={() => {
                            addToCart(props.data?.moodBoardProducts);
                          }}>
                          Shop Now
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div
              className="p-2"
              style={{
                height: '100%',
                overflowY: 'auto',
                backgroundColor: '#EFEBE7',
                fontSize: '13px'
              }}>
              <div className="font-weight-bold ml-2">PRODUCTS ON THE MOODBOARD</div>
              <div className="row w-100 m-auto">
                <div
                  className="col-4 p-2 d-flex justify-content-center align-items-center"
                  ref={refCol}></div>
              </div>
              <Carousel
                controls={false}
                indicators={false}
                touch={true}
                onSlide={(event, direction) => {
                  if (direction === 'left') {
                    if (pageNo - 1 < 0) {
                      setPageNo(paginatedProducts?.length - 1);
                    } else {
                      setPageNo(pageNo - 1);
                    }
                  } else if (direction === 'right') {
                    if (pageNo + 1 > paginatedProducts?.length - 1) {
                      setPageNo(0);
                    } else {
                      setPageNo(pageNo + 1);
                    }
                  }
                }}
                swipeable={true}>
                {paginatedProducts.map((products, index) => (
                  <Carousel.Item interval={1000000}>
                    <div
                      className="row w-100 m-auto"
                      style={{
                        minHeight: refCol.current.clientWidth * 2
                      }}>
                      {products.map((item, index) => (
                        <div
                          key={`moodboardProducts${item.id}`}
                          className="col-4 p-2 d-flex justify-content-center align-items-center"
                          style={{
                            height: refCol.current.clientWidth
                          }}>
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              height: '100%',
                              width: '100%',
                              cursor: 'pointer',
                              position: 'relative'
                            }}
                            onClick={() => {
                              handleProductSelect(item);
                            }}
                            title={item?.name}>
                            <img
                              src={item?.src}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                border: activeObject?.id == item.id ? '1px solid #949594' : 'none',
                                boxShadow:
                                  activeObject?.id == item.id
                                    ? ' 2px 2px 6px 0px rgba(0,0,0,0.5)'
                                    : ''
                              }}
                            />
                          </div>
                          {activeObject?.id == item.id && (
                            <div
                              style={{
                                position: 'absolute',
                                right: '10%',
                                bottom: '10%'
                              }}>
                              <img src={BlueTick} />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              {/* {paginatedProducts?.length > 1 && (
                <div className="row w-100 m-auto">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <div>
                      <img
                        src={paginationPrev}
                        onClick={(e) => {
                          if (pageNo > 0) setPageNo(pageNo - 1);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <span
                      className="ml-2 mr-2"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {" "}
                      {pageNo + 1}
                    </span>
                    <div>
                      <img
                        src={paginationNext}
                        onClick={(e) => {
                          if (pageNo < paginatedProducts.length - 1)
                            setPageNo(pageNo + 1);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              )} */}

              <div className="font-weight-bold mt-4 ml-2">ALTERNATE PRODUCT</div>
              <div>
                {activeObject?.productId && (
                  <ProductListing
                    height={outerCanvasContainer?.clientWidth / 3}
                    activeObject={activeObject}
                    moodboard={moodBoard}
                    products={props.data?.moodBoardProducts}
                    handleVisibleAction={handleVisibleAction}
                    auth={props.auth}
                    dimension={props.dimension}
                    savedProductIds={props.savedProductIds}
                    handleFavourites={props.handleFavourites}
                    history={props.history}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`row w-100 m-auto ${!props.modal || 'mx-2'}`}>
          <div className={`col-12`}>
            <div
              className="row w-100 m-auto px-2"
              style={{
                border: !props.modal && '1px solid #C3C3C3',
                position: 'relative'
              }}>
              <div
                className="my-1"
                style={{ width: '100%', height: '100%' }}
                id="fabric-canvas-wrapper">
                <canvas ref={canvasRef} />
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div
              className="p-2"
              style={{
                height: '100%',
                overflowY: 'auto',
                backgroundColor: '#EFEBE7',
                fontSize: '13px'
              }}>
              <div className="font-weight-bold ml-2">PRODUCTS ON THE MOODBOARD</div>
              <div className="row w-100 m-auto">
                <div
                  className="col-4 p-2 d-flex justify-content-center align-items-center"
                  ref={refCol}></div>
              </div>
              <Carousel
                controls={false}
                indicators={false}
                touch={true}
                onSlide={(event, direction) => {
                  if (direction === 'left') {
                    if (pageNo - 1 < 0) {
                      setPageNo(paginatedProductsMobile?.length - 1);
                    } else {
                      setPageNo(pageNo - 1);
                    }
                  } else if (direction === 'right') {
                    if (pageNo + 1 > paginatedProductsMobile?.length - 1) {
                      setPageNo(0);
                    } else {
                      setPageNo(pageNo + 1);
                    }
                  }
                }}
                swipeable={true}>
                {paginatedProductsMobile.map((products, index) => (
                  <Carousel.Item interval={1000000}>
                    <div className="row w-100 m-auto">
                      {products.map((item, index) => (
                        <div
                          key={`moodboardProducts${item.id}`}
                          className="col-4 p-2 d-flex justify-content-center align-items-center"
                          style={{
                            height: refCol.current.clientWidth
                          }}>
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              height: '100%',
                              width: '100%',
                              cursor: 'pointer',
                              position: 'relative'
                            }}
                            onClick={() => {
                              handleProductSelect(item);
                            }}
                            title={item?.name}>
                            <img
                              src={item?.src}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                border: activeObject?.id == item.id ? '1px solid #949594' : 'none',
                                boxShadow:
                                  activeObject?.id == item.id
                                    ? ' 2px 2px 6px 0px rgba(0,0,0,0.5)'
                                    : ''
                              }}
                            />
                          </div>
                          {activeObject?.id == item.id && (
                            <div
                              style={{
                                position: 'absolute',
                                right: '10%',
                                bottom: '10%'
                              }}>
                              <img src={BlueTick} />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              {paginatedProductsMobile?.length > 1 && (
                <div className="row w-100 m-auto">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    {paginatedProductsMobile.map((item, index) => (
                      <div
                        className={
                          'ml-2 ' +
                          (pageNo == index
                            ? classnames(style.paginationDotActive)
                            : classnames(style.paginationDot))
                        }></div>
                    ))}
                  </div>
                </div>
              )}

              <div className="font-weight-bold mt-4 ml-2">ALTERNATE PRODUCT</div>
              <div>
                {activeObject?.productId && (
                  <ProductListing
                    height={outerCanvasContainer?.clientWidth / 3}
                    activeObject={activeObject}
                    moodboard={moodBoard}
                    products={props.data?.moodBoardProducts}
                    handleVisibleAction={handleVisibleAction}
                    auth={props.auth}
                    dimension={props.dimension}
                    savedProductIds={props.savedProductIds}
                    handleFavourites={props.handleFavourites}
                    history={props.history}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className={style.totalValueMobile}>
              <span style={{ fontSize: '16px' }}>Total value </span>
              <span className="font-weight-bold" style={{ fontSize: '18px' }}>
                &#8377; {handleMoodboardPrice()}
              </span>
            </div>
          </div>
          <div className="col-12 mt-2">
            {props.page === 'REVIEW_CONCEPT' && (
              <div className={style.confirmMoodboardMobile}>
                <div>
                  <span> Would you like to confirm this moodboard?</span>
                </div>
                <div className="mt-3">
                  <button
                    className={style.confirmBtn}
                    onClick={props.handleCompleteSubmit}
                    disabled={props.disable.reviewYes}>
                    Yes
                  </button>
                  <button
                    className={style.declineBtn}
                    onClick={e => {
                      props.setDeclineModal(true);
                    }}
                    disabled={props.disable.reviewNo}>
                    No
                  </button>
                </div>
              </div>
            )}
            {props.page === 'FINALIZE_CONCEPT' && (
              <div className={style.shopNowFinalizeMobile}>
                <div>
                  <span style={{ fontWeight: '700' }}> Shop All Products</span>
                </div>
                <div>
                  <button
                    className={classnames(style.confirmBtn, 'mt-3')}
                    onClick={() => {
                      addToCart(props.data?.moodBoardProducts);
                    }}>
                    Shop Now
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Canvas;
