import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import * as Icon from 'react-bootstrap-icons';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { Modal } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as Mui from '@mui/material';
import emailjs from '@emailjs/browser';
import '@google/model-viewer';
import QRScanner from '../../components/layout/QrScanner';
import Shareurl from '../../components/Shareurl';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Product.module.css';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { storeCartCount } from '../../store/actions/sampleaction';
import getCartDataCount from '../../utils/CartDetails';
import './Product.css';
import ConsoleHelper from '../../utils/ConsoleHelper';
import Heart from 'react-heart';

const Accordion = Mui.styled(Mui.Accordion)({
  '& .MuiAccordion-root': {
    backgroundColor: '#fff !important'
  }
});

const Product = ({ auth, storeCartCount }) => {
  const getName = localStorage.getItem('MX-A');
  const userName = JSON.parse(getName);
  let userId;

  const { id } = useParams();
  const history = useHistory();
  const dimension = useMediaQuery();
  const form = useRef();

  const [productData, setProductData] = useState({});
  const [activeTab, setActiveTab] = useState('features');
  const [productDetails, setProductDetails] = useState({});
  const [prodQuantity, setProdQuantity] = useState(1);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [vendorName, setVendorName] = useState();
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  const [allBoards, setAllBoards] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const [currentData, setCurrentData] = useState('');
  const [newBoardCreated, setNewBoardCreated] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedpreview, setselectedpreview] = useState(0);
  const [shippingAddess, setShippingAddess] = useState('');
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();
  const [copiedUrl, setCopiedUrl] = useState('');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sendEmail = e => {
    e.preventDefault();

    emailjs.sendForm('service_dc6bf0u', 'template_byt0qp9', form.current, 'u-JUBaCmUc6toe0bh').then(
      result => {
        setSnack(true);
        setSnackMessage('Request AR send to Mail Successfully');
      },
      error => {
        setSnack(false);
        setSnackMessage('Request AR error');
        ConsoleHelper(error.text);
      }
    );
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`);
      if (res.data.vendor_id) {
        setVendorName(res.data.vendor_id.name);
        setShippingAddess(res?.data?.vendor_id?.shipping_returns_link);
      }
      setProductDetails({ ...res.data });
      setCurrentProduct({ ...res.data });
    } catch (err) {
      ConsoleHelper(err);
    }
  };
  const fetchFavouriteData = async () => {
    try {
      if (auth && auth.user) {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
        );
        let savedIds = [];

        if (res.data.favourites[0].length !== 0) {
          res.data.favourites.map(dat => {
            savedIds = dat.products.map(m => {
              return m.product_id;
            });
          });
          setSavedProductIds([...savedIds]);
        }
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
    }
  };
  const getCartData = async () => {
    let totalProducts = 0;
    if (auth && auth.user) {
      try {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/list`
        );
        if (res.data && res.data.cart.length > 0 && res.data.cart[0].products) {
          totalProducts = res.data.cart[0].products.length;
          localStorage.setItem('cartTotal', JSON.stringify(totalProducts));
        }
      } catch (error) {
        ConsoleHelper('error');
      }
    }
  };
  const list_products = async () => {
    try {
      if (auth && auth.user) {
        let boards1 = [];
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
        );
        let result = [...res.data];
        for (let i = 0; i < result.length; i++) {
          boards1.push({
            id: result[i]._id,
            moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
            title: result[i].name,
            moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
            products:
              result[i].products && result[i].products.length > 0 ? [...result[i].products] : []
          });
        }
        setAllBoards([...boards1]);
      }
    } catch (err) {
      ConsoleHelper(err);
    }
  };

  const getImage = () => {
    if (Object.keys(currentProduct).length && currentProduct.image.length > 0) {
      return currentProduct.image[0];
    }
  };

  const addToCart = async productData => {
    let prodBody;
    let userId;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;
        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixe_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
      ConsoleHelper(e);
    }
    storeCartCount(await getCartDataCount(auth));
  };

  const buyNow = async productData => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push('/cart');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }

        history.push('/cart');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const addQuantity = () => {
    return setProdQuantity(prodQuantity + 1);
  };

  const reduceQuantity = () => {
    if (prodQuantity === 1) {
      return setProdQuantity(1);
    } else {
      return setProdQuantity(prodQuantity - 1);
    }
  };

  const getP = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`);
    setProductData(res.data);

    let imagearr = [];
    if (productDetails?.image) {
      if (productDetails?.image.length > 0) {
        for (let i = 0; i < productDetails.image.length; i++) {
          imagearr.push({
            original: productDetails.image[i],
            thumbnail: productDetails.image[i]
          });
        }
        if (
          productDetails &&
          productDetails['3d_models'] &&
          productDetails['3d_models']?.glbFileUrl
        ) {
          imagearr.push({
            thumbnail: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shopPage/360-view-icon.jpg'
          });
        }
      }
    }
    setImagesArray([...imagearr]);
  };

  const productImage = data => {
    if (data?.image) {
      return data.image.length > 0 ? data.image[0] : data.image;
    }
    return '';
  };

  const getLength = data => {
    if (data?.dimensions) {
      return data?.dimensions.l;
    }
  };
  const getWidth = data => {
    if (data?.dimensions) {
      return data?.dimensions.b;
    }
  };
  const addToFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: userId,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
      ConsoleHelper(e);
    }
  };

  const removeFromFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
      ConsoleHelper(e);
    }
  };

  const handleQr = productIds => {
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = productIds => {
    history.push(`/3d-model/${productIds}`);
  };
  const handleFavourites = product => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds(prev => [...prev, product._id]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter(filter => filter !== product._id));
      removeFromFavourites(product);
    }
  };

  const checkProdsSaved = board => {
    let allProdIds = [];
    let otherBoards = [];
    let existingProd;
    otherBoards = allBoards.filter(allbrd => allbrd.id !== board.id);
    otherBoards.map(brd =>
      brd.products.map(prd => {
        allProdIds.push(prd._id);
      })
    );
    existingProd = allProdIds.some(x => x === currentProduct._id);
    return existingProd ? true : false;
  };
  const removeFromBoard = async boardData => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to remove from the Boards');
      return false;
    }
    let prodIds = [];
    boardData.products.map(pd => {
      prodIds.push(pd._id);
    });
    prodIds = prodIds.filter(pd => pd !== currentProduct._id);
    const removeBoardData = {
      moodboard_id: boardData.moodBoard_id,
      products: [...prodIds],
      name: boardData.title,
      user_id: auth.user._id
    };
    for (let i = 0; i < allBoards.length; i++) {
      if (allBoards[i].id === boardData.id) {
        allBoards[i].products = allBoards[i].products.filter(pd => pd._id !== currentProduct._id);
      }
    }
    setAllBoards([...allBoards]);

    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${boardData.id}`,
        removeBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('Product removed from Board successfully');
      }
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const saveToBoard = async boardData => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to save the Boards');
      return false;
    }

    let prodIds = [];
    boardData.products.map(pd => {
      prodIds.push(pd._id);
    });
    const addToBoardData = {
      moodboard_id: boardData.moodBoard_id,

      products: [...prodIds, currentProduct._id],

      name: boardData.title,
      user_id: auth.user._id
    };
    for (let i = 0; i < allBoards.length; i++) {
      if (allBoards[i].id === boardData.id) {
        allBoards[i].products.push(currentProduct);
      }
    }
    setAllBoards([...allBoards]);
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${boardData.id}`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('Product saved to Board successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const createNewBoard = async () => {
    const addToBoardData = {
      products: currentProduct._id,
      name: currentData,
      user_id: auth.user._id
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/add`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setNewBoardCreated(true);
        setModal({ ...modal, modal: false, name: '' });
        setSnack(true);
        setSnackMessage('Board created successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const newBoardCreated_listproduct = async () => {
    try {
      if (newBoardCreated) {
        let boards1 = [];
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
        );
        let result = [...res.data];
        for (let i = 0; i < result.length; i++) {
          boards1.push({
            id: result[i]._id,
            moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
            title: result[i].name,
            moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
            products:
              result[i].products && result[i].products.length > 0 ? [...result[i].products] : []
          });
        }
        setAllBoards([...boards1]);
        setNewBoardCreated(false);
      }
    } catch (err) {
      ConsoleHelper(err);
    }
  };
  useEffect(() => {
    newBoardCreated_listproduct();
  }, [newBoardCreated]);

  useEffect(() => {
    fetchData();
    getCartData();
    fetchFavouriteData();
    list_products();
    let url = window.location.href;
    setCopiedUrl(url);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (auth && auth.user) {
      userId = auth.user._id;
    }
  }, [auth]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getP();
  }, [productDetails]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header />
      <div
        style={{
          padding: '75px 2em 4em 2em',
          backgroundColor: '#F9F7F4',
          margin: '0',
          maxWidth: '100% !important',
          position: 'absolute',
          overflow: 'hidden'
        }}
        className={classnames(' w-100')}>
        <div
          className={style.productContainer}
          style={{
            marginTop: dimension[0] < 500 ? '1vh !important' : '',
            padding: '15px',
            paddingTop: '2em',
            width: dimension[0] < 500 ? '100%' : '100%',
            margin: dimension[0] < 500 ? '0 !important' : ''
          }}>
          <br></br>
          <div className="row">
            {productDetails.image && productDetails.image.length > 0 ? (
              <>
                <div className="col-lg-6 col-sm-12 mb-5" style={{ width: '100%' }}>
                  {dimension[0] < 500 ? (
                    <div className={'image-gallery'} aria-live="polite">
                      <div className="image-gallery-content bottom">
                        <div className="image-gallery-slide-wrapper left image-gallery-rtl">
                          <div className="image-gallery-swipe">
                            <div className="image-gallery-slides">
                              {imagesArray?.length > 0 &&
                                imagesArray.map((item, i) => {
                                  return selectedpreview + 1 === imagesArray.length ? (
                                    <div style={{ cursor: 'pointer' }}>
                                      <model-viewer
                                        src={
                                          productDetails && productDetails['3d_models']?.glbFileUrl
                                        }
                                        alt="A 3D model of an astronaut"
                                        ar
                                        ar-modes="webxr scene-viewer quick-look"
                                        environment-image="neutral"
                                        auto-rotate
                                        camera-controls
                                        style={{
                                          height: '400px',
                                          width: '100%'
                                        }}
                                        shadow-intensity="1"
                                        ios-src={productDetails['3d_models'].usdzFileUrl}
                                        ar-scale="fixed"
                                      />
                                    </div>
                                  ) : (
                                    selectedpreview == i && (
                                      <div
                                        key={`${i}`}
                                        aria-label={`Go to Slide ${i + 1}`}
                                        tabIndex="-1"
                                        className="image-gallery-slide  center "
                                        role="button"
                                        style={{
                                          display: 'inherit',
                                          transform: 'translate3d(0%, 0px, 0px)'
                                        }}>
                                        <img
                                          className="image-gallery-image"
                                          src={item.original}
                                          alt=""
                                          srcSet=""
                                          height=""
                                          width=""
                                          sizes=""
                                          title=""
                                          loading="eager"
                                        />
                                      </div>
                                    )
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div className="image-gallery-thumbnails-wrapper bottom thumbnails-swipe-vertical">
                          <div className="image-gallery-thumbnails">
                            <nav
                              className="image-gallery-thumbnails-container"
                              aria-label="Thumbnail Navigation"
                              style={{
                                transform: 'translate3d(0px, 0px, 0px)',
                                transition: 'all 450ms ease-out 0s',
                                overflowX: 'scroll'
                              }}>
                              {imagesArray?.length > 0 &&
                                imagesArray.map((item, i) => (
                                  <button
                                    type="button"
                                    tabIndex="0"
                                    aria-pressed="true"
                                    aria-label="Go to Slide 1"
                                    className={`image-gallery-thumbnail ${
                                      selectedpreview == i ? ' active' : ''
                                    }`}
                                    onClick={() => setselectedpreview(i)}>
                                    <span className="image-gallery-thumbnail-inner">
                                      <img
                                        className="image-gallery-thumbnail-image"
                                        src={item.thumbnail}
                                        onClick={() => {
                                          setselectedpreview(i);
                                        }}
                                      />
                                    </span>
                                  </button>
                                ))}
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={'image-gallery'} aria-live="polite">
                      <div className="image-gallery-content left">
                        <div className="image-gallery-thumbnails-wrapper left thumbnails-swipe-vertical">
                          <div className="image-gallery-thumbnails" style={{ height: '445px' }}>
                            <nav
                              className="image-gallery-thumbnails-container"
                              aria-label="Thumbnail Navigation"
                              style={{
                                transform: 'translate3d(0px, 0px, 0px)',
                                transition: 'all 450ms ease-out 0s'
                              }}>
                              {imagesArray?.length > 0 &&
                                imagesArray.map((item, i) => (
                                  <button
                                    type="button"
                                    tabIndex="0"
                                    aria-pressed="true"
                                    aria-label="Go to Slide 1"
                                    className={`image-gallery-thumbnail ${
                                      selectedpreview == i ? ' active' : ''
                                    }`}
                                    onClick={() => setselectedpreview(i)}>
                                    <span className="image-gallery-thumbnail-inner">
                                      <img
                                        className="image-gallery-thumbnail-image"
                                        src={item.thumbnail}
                                        onClick={() => setselectedpreview(i)}
                                      />
                                    </span>
                                  </button>
                                ))}
                            </nav>
                          </div>
                        </div>
                        <div className="image-gallery-slide-wrapper left image-gallery-rtl">
                          <div className="image-gallery-swipe">
                            <div className="image-gallery-slides">
                              {imagesArray?.length > 0 &&
                                imagesArray.map((item, i) => {
                                  return selectedpreview == imagesArray.length - 1 &&
                                    productDetails &&
                                    productDetails['3d_models']?.glbFileUrl ? (
                                    <div style={{ cursor: 'pointer' }}>
                                      <model-viewer
                                        src={
                                          productDetails && productDetails['3d_models']?.glbFileUrl
                                        }
                                        alt="A 3D model of an astronaut"
                                        ar
                                        ar-modes="webxr scene-viewer quick-look"
                                        environment-image="neutral"
                                        auto-rotate
                                        camera-controls
                                        style={{
                                          height: '400px',
                                          width: '100%'
                                        }}
                                        shadow-intensity="1"
                                        ios-src={productDetails['3d_models']?.usdzFileUrl}
                                        ar-scale="fixed"
                                      />
                                    </div>
                                  ) : (
                                    selectedpreview === i && (
                                      <div
                                        key={`${i}`}
                                        aria-label={`Go to Slide ${i}`}
                                        tabIndex="-1"
                                        className="image-gallery-slide  center "
                                        role="button"
                                        style={{
                                          display: 'inherit',
                                          transform: 'translate3d(0%, 0px, 0px)'
                                        }}>
                                        <img
                                          className="image-gallery-image"
                                          src={item.original}
                                          alt=""
                                          srcSet=""
                                          height=""
                                          width=""
                                          sizes=""
                                          title=""
                                          loading="eager"
                                        />
                                      </div>
                                    )
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="col-lg-6 col-sm-12">
                <img style={{ width: '100%' }} src={productImage(productDetails)} alt="" />
              </div>
            )}

            <div className="col-lg-6 col-md-12" ref={form}>
              <div className="d-flex">
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }} ref={form}>
                  {auth && auth.user && (
                    <div style={{ width: '1.5rem' }}>
                      <Heart
                        isActive={savedProductIds?.includes(productDetails._id) ? true : false}
                        onClick={() => handleFavourites(productDetails)}
                        animationScale={1.2}
                        animationTrigger="both"
                        animationDuration={0.2}
                        style={{
                          fill: savedProductIds?.includes(productDetails._id)
                            ? 'rgb(255, 196, 196)'
                            : '#fff'
                        }}
                      />
                    </div>
                  )}
                </div>
                <div style={{ padding: '10px' }} ref={form}>
                  <Shareurl fabdirection="right" copiedUrl={copiedUrl} />
                </div>
              </div>

              <h2 className={style.imageGalleryTitle}>
                <b>{productDetails.name}</b>
              </h2>
              <p className={style.imageGalleryPara}>
                <b>{vendorName}</b>
              </p>
              <br></br>
              <h1 className={style.galleryPrice}>
                Rs. {new Intl.NumberFormat('en-IN').format(productDetails.price)}
              </h1>
              <span className={style.taxes}>(Inclusive of all taxes)</span>
              <div className="row  mt-md-5 mb-2" style={{ height: 'auto', margin: '0' }}>
                <div className="col-md-lg-7 " style={{ width: '100%' }}>
                  <div className="row">
                    <div
                      className=" col-md-12 "
                      style={{
                        display: 'flex',
                        flexDirection: dimension[0] < 500 ? 'column' : 'row'
                      }}>
                      <button
                        style={{
                          width: dimension[0] < 500 ? '38%' : '',
                          marginRight: dimension[0] < 500 ? '' : '15px',
                          padding: '0px 4px',
                          borderRadius: 30,
                          border: '1px solid #707070',
                          backgroundColor: '#ffffff',
                          marginBottom: '15px'
                        }}>
                        <Icon.Dash
                          size={20}
                          className="ml-2 mr-2"
                          onClick={() => {
                            reduceQuantity();
                          }}
                        />
                        {prodQuantity}
                        <Icon.Plus
                          size={20}
                          className="ml-1 mr-2"
                          onClick={() => {
                            addQuantity();
                          }}
                        />
                      </button>
                      <button
                        className={style.addCart}
                        style={{
                          marginRight: dimension[0] < 500 ? '' : '15px',
                          marginBottom: '15px',
                          height: '41px',
                          borderRadius: 30,
                          backgroundColor: '#000000',
                          color: '#ffffff',
                          padding: '0px 24px',
                          fontWeight: 500,
                          fontSize: '15px',
                          border: 'none'
                        }}
                        onClick={() => buyNow(productDetails)}>
                        Buy Now
                      </button>
                      <button
                        style={{
                          height: '41px',
                          borderRadius: 30,
                          border: '1px solid #707070',
                          padding: '0px 24px',
                          backgroundColor: '#ffffff'
                        }}
                        onClick={() => {
                          addToCart(productDetails);
                        }}>
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <span className={style.productDetails}>
                Color: {productDetails.color} <br></br>
                Material: {productDetails.material} <br></br>
                Dimensions: {getLength(productDetails)} {" '' "}L * {getWidth(productDetails)}{' '}
                {" '' "}W
              </span>
              {productData['3d_models'] ? (
                <button
                  onClick={() =>
                    dimension[0] > 500
                      ? handleQr(productDetails?._id)
                      : mobileHandle3D(productDetails?._id)
                  }
                  className={style.arPart}>
                  <img
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Arpath.png"
                    className={style.arImg}
                  />
                  <p className={style.view}>{'View in AR'}</p>
                </button>
              ) : (
                <form ref={form} onSubmit={sendEmail}></form>
              )}
            </div>
          </div>
        </div>

        <form
          ref={form}
          onSubmit={sendEmail}
          style={{ visibility: 'hidden', position: 'absolute' }}>
          <input type="text" value={productDetails?.name} name="name" />
          <input type="text" value={productDetails?._id} name="productId" />
          <input type="text" value={productDetails?.category} name="category" />
          <input type="text" value={productDetails?.price} name="price" />
          <input type="text" value={userName?.name} name="userName" />
        </form>

        {dimension[0] < 500 ? (
          <>
            <Accordion
              square
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
              sx={{
                backgroundColor: 'white !important',
                width: '95%',
                margin: '0px auto !important',
                marginBottom: '11px !important'
              }}>
              <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Features</Typography>
                <div>
                  {expanded !== 'panel1' && (
                    <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                  )}
                  {expanded === 'panel1' && (
                    <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className="d-flex flex-column"
                  style={{
                    backgroundColor: '#ffffff',
                    height: 'auto',
                    width: '100%'
                  }}>
                  <p className="">
                    SKU: {productDetails.SKU ? productDetails.SKU : 'Nil'}
                    <br></br>
                    Material: {productDetails.material ? productDetails.material : 'Nil'}
                    <br></br>
                    Pattern: {productDetails.pattern ? productDetails.pattern : 'Nil'}
                    <br></br>
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              sx={{
                backgroundColor: 'white !important',
                width: '95%',
                margin: '0px auto !important',
                marginBottom: '11px !important'
              }}
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-header">
                <Typography sx={{ width: '95%', flexShrink: 0 }}> Product Description</Typography>
                <div>
                  {expanded !== 'panel2' && (
                    <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                  )}
                  {expanded === 'panel2' && (
                    <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className="d-flex flex-column align-items-start justify-content-start"
                  style={{
                    backgroundColor: '#ffffff',
                    height: 'auto',
                    width: '100%'
                  }}>
                  <p className="">{productDetails.description}</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              sx={{
                backgroundColor: 'white !important',
                width: '95%',
                margin: '0px auto !important',
                marginBottom: '11px !important'
              }}
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3bh-content" id="panel3bh-header">
                <Typography sx={{ width: '95%', flexShrink: 0 }}>
                  Return & Cancellation Policy
                </Typography>
                <div>
                  {expanded !== 'panel3' && (
                    <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                  )}
                  {expanded === 'panel3' && (
                    <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className="d-flex flex-column align-items-start justify-content-start"
                  style={{
                    backgroundColor: '#ffffff',
                    height: 'auto',
                    width: '100%'
                  }}>
                  <a href={shippingAddess} target="_blank" rel="noreferrer">
                    {shippingAddess}
                  </a>
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        ) : (
          <div
            className={classnames(
              style.tabContainer,
              'd-flex flex-column  justify-content-center'
            )}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-start'
              }}>
              <span
                style={{
                  width: dimension[0] < 500 ? '50%' : '',
                  marginLeft: dimension[0] < 500 ? '' : dimension[0] < 900 ? '4em' : '9em'
                }}>
                <h5
                  onClick={() => setActiveTab('features')}
                  style={{
                    backgroundColor: activeTab === 'features' && '#ffffff',
                    margin: '0'
                  }}>
                  Features
                </h5>
              </span>
              <span style={{ width: dimension[0] < 500 ? '50%' : '' }}>
                <h5
                  onClick={() => setActiveTab('description')}
                  style={{
                    backgroundColor: activeTab === 'description' && '#ffffff',
                    margin: '0'
                  }}>
                  Product Description
                </h5>
              </span>
              <span style={{ width: dimension[0] < 500 ? '50%' : '' }}>
                <h5
                  onClick={() => setActiveTab('return')}
                  style={{
                    backgroundColor: activeTab === 'return' && '#ffffff',
                    margin: '0'
                  }}>
                  Return & Cancellation Policy
                </h5>
              </span>
            </div>

            {activeTab === 'features' ? (
              <div
                className="d-flex flex-column"
                style={{
                  backgroundColor: '#ffffff',
                  height: 'auto',
                  width: '100%'
                }}>
                <p className="">
                  SKU: {productDetails.SKU ? productDetails.SKU : 'Nil'}
                  <br></br>
                  Material: {productDetails.material ? productDetails.material : 'Nil'}
                  <br></br>
                  Pattern: {productDetails.pattern ? productDetails.pattern : 'Nil'}
                  <br></br>
                </p>
              </div>
            ) : activeTab === 'description' ? (
              <div
                className="d-flex flex-column align-items-start justify-content-start"
                style={{
                  backgroundColor: '#ffffff',
                  height: 'auto',
                  width: '100%'
                }}>
                <p className="">{productDetails.description}</p>
              </div>
            ) : (
              activeTab === 'return' && (
                <div
                  className="d-flex flex-column align-items-start justify-content-start"
                  style={{
                    backgroundColor: '#ffffff',
                    height: 'auto',
                    width: '100%'
                  }}>
                  <p className="">
                    <a href={shippingAddess} target="_blank" rel="noreferrer">
                      {shippingAddess}
                    </a>
                  </p>
                </div>
              )
            )}
          </div>
        )}

        <Modal
          style={{ backgroundColor: 'transparent' }}
          show={modal.name === 'save'}
          onHide={() => setModal({ ...modal, modal: false })}
          backdrop={true}
          backdropClassName="backdrop"
          centered
          className={'modal modalSave'}
          keyboard={false}>
          <div className={style.modalHeader}>
            <h4 className={style.saveBoards}>Save to Board</h4>
            <img
              className="mr-5 mt-4"
              style={{ width: '14px', height: '14px', cursor: 'pointer' }}
              onClick={() => setModal({ ...modal, modal: false, name: '' })}
              src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
              alt=""
            />
          </div>

          <div className={classnames('row justify-content-center', style.modalRow)}>
            <div
              className={classnames(
                'col-md-6 pt-5 col-xs-12 pt-5 d-flex justify-content-center',
                style.imageCol
              )}>
              <img
                src={getImage()}
                alt=""
                style={{ width: '100%', minHeight: '70%', maxHeight: '80%' }}
              />
            </div>
            <div className={classnames('col-md-6 col-xs-12 ', style.createCol)}>
              <div className="row ">
                <span
                  className={classnames(
                    ' col-6 p-0 pt-4 pl-3  justify-content-start',
                    style.save1
                  )}>
                  Save to Board
                </span>
                <span className=" col-6 d-flex  mt-3 p-sm-0 p-xs-0  justify-content-end ">
                  <button
                    className={classnames('mr-lg-3 mr-md-3', style.createBoardButton)}
                    onClick={() => setModal({ ...modal, modal: true, name: 'create' })}>
                    Create Board
                  </button>
                </span>
              </div>
              <hr className="p-0"></hr>
              {allBoards.map(board => {
                return (
                  <div className="row mb-2">
                    <div className="col-6">
                      <img
                        src={
                          board.hasOwnProperty('moodBoard') && board.moodBoard
                            ? board.moodBoard.preview_url
                            : null
                        }
                        style={{
                          width: '100%',
                          minHeight: '70%',
                          maxHeight: '80%'
                        }}
                        alt=""
                      />
                    </div>
                    <div className="col-4 d-flex justify-content-left align-items-left">
                      <span style={{ fontSize: '12px', fontFamily: 'QuickSand' }}>
                        {' '}
                        {board.title}{' '}
                      </span>
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      {auth &&
                      auth.user &&
                      currentProduct &&
                      board.products.some(x => x._id === currentProduct._id) ? (
                        <Icon.HeartFill
                          style={{ fill: '#FFC4C4', borderColor: 'black' }}
                          onClick={() => {
                            if (checkProdsSaved(board)) {
                              removeFromBoard(board);
                            } else {
                              setSnack(true);
                              setSnackMessage('Please save the product to other boards');
                            }
                          }}></Icon.HeartFill>
                      ) : (
                        <Icon.Heart
                          onClick={() => {
                            saveToBoard(board);
                          }}></Icon.Heart>
                      )}
                    </div>
                    <hr></hr>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
        <Modal
          style={{ backgroundColor: 'transparent' }}
          show={modal.name === 'create'}
          onHide={() => setModal({ ...modal, modal: false, name: '' })}
          backdrop={true}
          backdropClassName="backdrop"
          centered
          className={'modal'}
          keyboard={false}>
          <div className={style.modalHeader}>
            <h4 className={style.createBoard}>Create Your Board</h4>
            <img
              className="mr-5 mt-4"
              style={{ width: '14px', height: '14px', cursor: 'pointer' }}
              onClick={() => setModal({ ...modal, modal: false, name: '' })}
              src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
              alt=""
            />
          </div>
          <div className={classnames('row justify-content-center', style.modalRow)}>
            <div className={classnames('col-md-4 col-xs-12 pt-5', style.imageCol)}>
              <img
                src={getImage()}
                alt=""
                style={{ width: '100%', minHeight: '70%', maxHeight: '80%' }}
              />
            </div>
            <div className={classnames('col-md-8 col-xs-12', style.createCol)}>
              <h6>Board Name</h6>
              <input
                type="text"
                placeholder="Enter Board name"
                onChange={e => setCurrentData(e.target.value)}
              />
              <button
                className={style.createBoardButton1}
                onClick={() => {
                  createNewBoard();
                }}>
                Create Board
              </button>
            </div>
          </div>
        </Modal>
        <Footer />
        {modalOpen && (
          <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />
        )}
      </div>
    </>
  );
};
Product.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(Product));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
