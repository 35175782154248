import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import style from './ExploreTags.module.css';
import PlaceholderImg from '../../assets/images/Explore/placeholderExp.png';
import BannerImg from '../../assets/images/Explore/exploreBanner.png';
import BannerImgMob from '../../assets/images/Explore/exploreBannerMob.png';
import SliderComponent from './SliderComponent';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import { Image } from 'semantic-ui-react';
import arrow from '../../assets/images/Iconionic-ios.png';
import Paper from '@material-ui/core/Paper';
import SliderContainer from 'react-slick';
import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import axios from 'axios';
import ARIcon from '../../assets/ARexpIcon.png';
import QRScanner from '../../components/layout/QrScanner';
import { set_explore_filter } from '../../store/actions/sampleaction';
import Snackbar from '@material-ui/core/Snackbar';
import _3DIcon from '../../assets/3d-viewer.svg';

import customerApiInstance from '../../utils/CustomerApiInstance';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConsoleHelper from '../../utils/ConsoleHelper';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      paddingTop: '35px',
      // margin: 'auto',
      background:
        'transparent linear-gradient(360deg, #000000ED 0%, #292929DE 72%, #54545400 100%) 0% 0% no-repeat padding-box'
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '6px',
        height: '10px',
        marginLeft: '11px'
      }
    },
    paper: {
      width: '90%',
      overflow: 'hidden',
      margin: '0px auto'
    },
    imagefit: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  })
);

const useStylesMob = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: '20px'
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '6px',
        height: '10px',
        marginLeft: '11px'
      }
    },
    paper: {
      width: '90%',
      overflow: 'hidden',
      margin: '0px auto'
    },
    imagefit: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  })
);

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowPrev} alt="prevArrow" {...props} />
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowNext} alt="nextArrow" {...props} />
);

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true
      }
    }
  ]
};

function BannerSliderComponent({ sliderData }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      {!sliderData.length > 0 ? (
        <div className="text-center">
          <Loader type="ThreeDots" visible={true} color="#ffffff" height={50} width={50} />
        </div>
      ) : (
        <div className={classes.flex}>
          <SliderContainer {...settings} style={{ width: '92%', margin: 'auto' }}>
            {sliderData.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/explore/rooms/${data.name}`);
                  }}>
                  <Paper className={classes.paper}>
                    <Image
                      draggable={false}
                      className={classes.imagefit}
                      src={data?.image ? data.image : ''}
                    />
                  </Paper>
                  <p
                    style={{
                      fontFamily: 'Quicksand',
                      cursor: 'pointer',
                      color: '#ffffff',
                      textOverflow: 'ellipsis'
                    }}
                    className={classnames(style.displayrow, 'tagname')}>
                    {data.name} <img src={arrow} className={classes.imgtag} />
                  </p>
                </div>
              );
            })}
          </SliderContainer>
        </div>
      )}
    </div>
  );
}

function BannerSliderComponentMobile({ sliderData }) {
  const classesMob = useStylesMob();

  const history = useHistory();
  return (
    <div className={classesMob.root}>
      {!sliderData.length > 0 ? (
        <div className="text-center">
          <Loader type="ThreeDots" visible={true} color="#ffffff" height={50} width={50} />
        </div>
      ) : (
        <div className={classesMob.flex}>
          <SliderContainer {...settings} style={{ width: '92%', margin: 'auto' }}>
            {sliderData.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/explore/rooms/${data.name}`);
                  }}>
                  <Paper className={classesMob.paper}>
                    <Image
                      draggable={false}
                      className={classesMob.imagefit}
                      src={data?.image ? data.image : ''}
                    />
                  </Paper>
                  <p
                    style={{ fontFamily: 'Quicksand', cursor: 'pointer', color: '#000000' }}
                    className={classnames(style.displayrow, 'tagname')}>
                    {data.name} <img src={arrow} className={classesMob.imgtag} />
                  </p>
                </div>
              );
            })}
          </SliderContainer>
        </div>
      )}
    </div>
  );
}

let roomsData = [
  {
    id: 1,
    name: 'Living Room',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/LivingRoom.svg'
  },
  {
    id: 2,
    name: 'Dining Room',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/DiningRoom.svg'
  },
  {
    id: 3,
    name: 'Bed room',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/BedRoom.svg'
  },
  {
    id: 4,
    name: 'Kids Room',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/KidsRooms.svg'
  },
  {
    id: 5,
    name: 'Study Entertainment Room',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/StudyEntertainmentRoom.svg'
  },
  {
    id: 6,
    name: 'Puja Room',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/PujaRoom.svg'
  },
  {
    id: 7,
    name: 'Foyer',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/Foyer.svg'
  },
  {
    id: 8,
    name: 'Balcony',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/Balcony.svg'
  }
  // { id: 9, name: 'Others', image: "https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/Others.svg" }
];

let stylesData = [
  {
    id: 1,
    name: 'South Indian',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/south+indian.svg'
  },
  {
    id: 2,
    name: 'North Indian',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/north+indian.svg'
  },
  {
    id: 3,
    name: 'Classic Indian',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/classical.svg'
  },
  {
    id: 4,
    name: 'Minimalistic',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/minimalistic.svg'
  },
  {
    id: 5,
    name: 'Mid Century',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/mid-century+modern.svg'
  },
  {
    id: 6,
    name: 'Art Deco',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/art+deco.svg'
  },
  {
    id: 7,
    name: 'Pop Art',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/pop-art.svg'
  },
  {
    id: 8,
    name: 'Industrial',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/industrial.svg'
  },
  {
    id: 9,
    name: 'Rustic',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/rustic.svg'
  },
  {
    id: 10,
    name: 'Gothic',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/gothic.svg'
  },
  {
    id: 11,
    name: 'Boho',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/bohemian.svg'
  },
  {
    id: 12,
    name: 'Farm House',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/farm+house.svg'
  },
  {
    id: 13,
    name: 'Coastal',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/coastal.svg'
  },
  {
    id: 14,
    name: 'Eclectic',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/eclectic.svg'
  },
  {
    id: 15,
    name: 'Contemporary',
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/contemporary.svg'
  }
];

function ExploreTags({ history, auth }) {
  const dimension = useMediaQuery();
  const [rootTags, setRootTags] = useState([]);
  const [childOfRootTags, setChildOfRootTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [styleMoodboard, setStyleMoodboard] = useState([]);
  const [roomsMoodboard, setRoomsMoodboard] = useState([]);
  const [inspirationMoodboard, setInspirationMoodboard] = useState([]);
  const [builderMoodboard, setBuilderMoodboard] = useState([]);
  const [topPicksMoodboard, setTopPicksMoodboard] = useState([]);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [productIds, setProductId] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingStyle, setLoadingStyle] = useState(false);
  const [loadingRooms, setLoadingRooms] = useState(false);
  const [loadingInspiration, setLoadingInspiration] = useState(false);
  const [loadingBuilder, setLoadingBuilder] = useState(false);
  const [loadingTopPicks, setLoadingTopPicks] = useState(false);
  const [savedMoodBoardIds, setSavedMoodBoardIds] = useState([]);

  useEffect(() => {
    getProductList();
    getTags();
  }, []);

  function getChildTag(alltagArr, tagId, type) {
    let child = alltagArr.filter((tag) => {
      return tag.parent_id == tagId && tag.type.indexOf(type) > -1;
    });
    return child;
  }

  function getTags() {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/v1/tags`)
      .then((res) => {
        let allTags = res.data.data;
        setAllTags(allTags);
        let rootTag = allTags.filter((tag) => {
          return tag.parent_id == '0' && tag.type.indexOf('explore') > -1;
        });
        setRootTags(rootTag);
        setLoading(false);

        let childTags = [];
        rootTag.forEach((root) => {
          let child = getChildTag(allTags, root._id, 'explore');
          childTags.push({ ...root, child: child });
        });
        setChildOfRootTags(childTags);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  function getMoodboardsIds(type) {
    return new Promise(function (resolve, reject) {
      axios.get(`${process.env.REACT_APP_CMS_URL}/api/v1/user/explore/featured/${type}`).then(
        (response) => {
          if (response?.status == 200) {
            var result = response.data.data;
            resolve(result);
          }
        },
        (error) => {
          reject({});
        }
      );
    });
  }

  const getMoodBoards = async (moodboardIds) => {
    let moodboardsData = [];
    let promises = [];
    for (let i = 0; i < moodboardIds.length; i++) {
      if (Object.keys(moodboardIds[i]).length > 0 && moodboardIds[i].moodboard_id != '') {
        const res = axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/${moodboardIds[i].moodboard_id}`
        );
        promises.push(res);
      }
    }
    moodboardsData = await Promise.allSettled(promises);
    return getMoodboardData(moodboardsData);
  };

  const getMoodboardData = (arr) => {
    let newArr = [];
    arr.forEach((elem) => {
      if (elem.status == 'fulfilled') {
        newArr.push(elem?.value?.data?.moodBoard);
      }
    });

    return newArr;
  };

  const getProductList = async () => {
    setLoadingStyle(true);
    setLoadingRooms(true);
    setLoadingInspiration(true);
    setLoadingBuilder(true);
    setLoadingTopPicks(true);
    const StyledMoodboardsIds = await getMoodboardsIds('style')
      .then(function (fulfilledResult) {
        return fulfilledResult;
      })
      .catch(function (err) {
        return err;
      });
    let styleMoodboardsData = [];
    styleMoodboardsData = await getMoodBoards(StyledMoodboardsIds);
    setStyleMoodboard([styleMoodboardsData]);
    setLoadingStyle(false);

    const RoomsMoodboardsIds = await getMoodboardsIds('rooms')
      .then(function (fulfilledResult) {
        return fulfilledResult;
      })
      .catch(function (err) {
        return err;
      });
    let roomsMoodboardsData = [];
    roomsMoodboardsData = await getMoodBoards(RoomsMoodboardsIds);
    setRoomsMoodboard([roomsMoodboardsData]);
    setLoadingRooms(false);

    const BuilderMoodboardsIds = await getMoodboardsIds('builder')
      .then(function (fulfilledResult) {
        return fulfilledResult;
      })
      .catch(function (err) {
        return err;
      });
    let builderMoodboardsData = [];
    builderMoodboardsData = await getMoodBoards(BuilderMoodboardsIds);

    setBuilderMoodboard([builderMoodboardsData]);
    setLoadingBuilder(false);

    const InspirationMoodboardsIds = await getMoodboardsIds('inspiration')
      .then(function (fulfilledResult) {
        return fulfilledResult;
      })
      .catch(function (err) {
        return err;
      });
    let inspirationMoodboardsData = [];
    inspirationMoodboardsData = await getMoodBoards(InspirationMoodboardsIds);
    setInspirationMoodboard([inspirationMoodboardsData]);
    setLoadingInspiration(false);

    const TopPicksMoodboardsIds = await getMoodboardsIds('toppicks')
      .then(function (fulfilledResult) {
        return fulfilledResult;
      })
      .catch(function (err) {
        return err;
      });
    let topPicksMoodboardsData = [];
    topPicksMoodboardsData = await getMoodBoards(TopPicksMoodboardsIds);
    setTopPicksMoodboard([topPicksMoodboardsData]);
    setLoadingTopPicks(false);
  };

  const handleAddToBoard = async (moodBoardData) => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to save the Boards');
      return false;
    }
    setSavedMoodBoardIds([...savedMoodBoardIds, moodBoardData._id]);
    let productIds = [];
    moodBoardData.products &&
      moodBoardData.products.map((prodArray) => {
        prodArray.map((product) => {
          productIds.push(product.product_id);
        });
      });
    productIds = [...new Set(productIds)];
    const addToBoardData = {
      moodboard_id: moodBoardData._id,
      products: productIds,

      name: moodBoardData.title,
      user_id: auth.user._id
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/add`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('MoodBoard addedd to my Board successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };

  const handleMoodBoardProducts = (moodBoardId) => {
    history.push(`/explore-detail/${moodBoardId}`);
  };

  const uniqueProducts = (data1) => {
    let productIds = [];
    data1.products &&
      data1.products.map((prodArray) => {
        prodArray.map((product) => {
          productIds.push(product.product_id);
        });
      });
    let uniqueProductIds = [...new Set(productIds)];
    return uniqueProductIds.length;
  };

  const handleQr = (productIds) => {
    setQrModalOpen(true);
    setProductId(productIds);
  };

  function openinnewtab(url) {
    window.location.assign(url);
  }

  return (
    <>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {qrModalOpen && (
        <QRScanner
          id={productIds}
          modalOpen={qrModalOpen}
          setModalOpen={setQrModalOpen}
          type={'Moodboard'}
        />
      )}
      <div
        style={{
          maxWidth: dimension[0] > 500 ? '80%' : '96%',
          marginTop: '15px',
          paddingBottom: '2rem'
        }}>
        <div className="d-flex justify-content-center text-center">
          <p className={style.heading}>
            <b>Search</b> for inspiration.<b>Save</b> your favourite product.
            <b>Buy</b> immediately
          </p>
        </div>
        <div
          className="row "
          style={{
            maxWidth: dimension[0] > 500 ? '80%' : '100%',
            margin: 'auto'
          }}>
          {loading ? (
            <div className="d-flex justify-content-center mt-4 w-100">
              <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
            </div>
          ) : (
            <>
              <div
                className="col-xl-3 col-lg-3 col-md-4 col-6 p-2 text-center"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(`/explore/styles`);
                }}>
                <img
                  src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/By+Style.svg'}
                  className={style.headingImg}
                />
                <p className={style.headingImgTittle}>By Style</p>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-4 col-6 p-2 text-center"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(`/explore/rooms`);
                }}>
                <img
                  src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/Room+Styles.svg'}
                  className={style.headingImg}
                />
                <p className={style.headingImgTittle}>By Room</p>
              </div>
              {rootTags.map((tag) => (
                <div
                  key={tag._id}
                  className="col-xl-3 col-lg-3 col-md-4 col-6 p-2 text-center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (getChildTag(allTags, tag._id, 'explore').length > 0) {
                      history.push(`/explore-tag/${tag.name}/${tag._id}`);
                    }
                  }}>
                  <img src={tag.image} className={style.headingImg} />
                  <p className={style.headingImgTittle}>{tag.name}</p>
                </div>
              ))}
            </>
          )}
        </div>
        <SliderComponent
          sliderData={stylesData}
          tittle={'Styles'}
          type={'other'}
          allTags={allTags}
        />
        {loadingStyle ? (
          <div className="d-flex justify-content-center mt-4">
            <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
          </div>
        ) : (
          <div>
            {dimension[0] > 500 && (
              <div>
                {styleMoodboard.length > 0 &&
                  styleMoodboard.map((data, index) => {
                    return (
                      <>
                        <div className={classnames('row')}>
                          <div className={classnames('col-8')}>
                            <div className={classnames('row h-100')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[0] ? style.item : 'd-none'}>
                                  <img
                                    src={data[0]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[0]?._id)
                                          : history.push(`/pre-explore-detail/${data[0]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[0] ? data[0] : 0)} Products
                                        </span>
                                        {data[0]?.hasOwnProperty('model_url') &&
                                          data[0]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[0]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[0]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[0]);
                                          }}>
                                          {savedMoodBoardIds.includes(data[0]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[0]?.hasOwnProperty('models_status') &&
                                          data[0].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[0]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-4')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%',
                                    backgroundColor: '#BDE4EE',
                                    borderRadius: '2px 2px 2px 2px'
                                  }}>
                                  <img
                                    src={PlaceholderImg}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                    style={{ visibility: 'hidden' }}
                                  />
                                  <div className={style.findStyle}>
                                    <div
                                      style={{ fontSize: 'larger', fontWeight: '700' }}
                                      onClick={() => history.push('/questionnaire/space')}>
                                      Find Your Style
                                    </div>
                                    <div style={{ fontSize: 'medium', fontWeight: '600' }}>
                                      Answer a few simple questions by clicking on images that
                                      visually appeal to you and your unique style.
                                    </div>
                                    <div>
                                      <button className={style.filterButton}>
                                        Find your style
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[1] ? style.item : 'd-none'}>
                                  <img
                                    src={data[1]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[1]?._id)
                                          : history.push(`/pre-explore-detail/${data[1]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[1] ? data[1] : 0)} Products
                                        </span>
                                        {data[1]?.hasOwnProperty('model_url') &&
                                          data[1]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[1]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[2]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[1] ? data[1] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[1]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[1]?.hasOwnProperty('models_status') &&
                                          data[1].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[1]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={classnames('row')}>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[2] ? style.item : 'd-none'}>
                                  <img
                                    src={data[2]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[2]?._id)
                                          : history.push(`/pre-explore-detail/${data[2]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[2] ? data[2] : 0)} Products
                                        </span>
                                        {data[2]?.hasOwnProperty('model_url') &&
                                          data[2]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[2]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[2]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[2] ? data[2] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[2]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[2]?.hasOwnProperty('models_status') &&
                                          data[2].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[2]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[3] ? style.item : 'd-none'}>
                                  <img
                                    src={data[3]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1">
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[3] ? data[3] : 0)} Products
                                        </span>
                                        {data[3]?.hasOwnProperty('model_url') &&
                                          data[3]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[3]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[4]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[3] ? data[3] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[3]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[3]?.hasOwnProperty('models_status') &&
                                          data[3].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[3]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
            {dimension[0] < 500 && (
              <>
                {styleMoodboard.length > 0 &&
                  styleMoodboard[0]?.map((data, index) => {
                    return (
                      <div
                        className={classnames('row')}
                        key={index}
                        style={{ marginTop: '3.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className={classnames('col-12')}>
                          <div
                            style={{
                              marginBottom: '5px',
                              height: '100%'
                            }}
                            className={data ? style.item : 'd-none'}>
                            <img src={data?.preview_url} height={'100%'} width={'100%'} alt="" />
                            <div className={style.content}>
                              <div
                                style={{ cursor: 'pointer' }}
                                className="d-flex justify-content-between w-100 mx-2 mt-1"
                                onClick={() =>
                                  auth.isAuthenticated
                                    ? handleMoodBoardProducts(data?._id)
                                    : history.push(`/pre-explore-detail/${data?._id}`)
                                }>
                                <div>
                                  <span
                                    style={{
                                      opacity: 1,
                                      color: '#ffffff',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      marginLeft: '7px'
                                    }}>
                                    {uniqueProducts(data ? data : 0)} Products
                                  </span>
                                  {data?.hasOwnProperty('model_url') && data?.model_url != null && (
                                    <button
                                      className={style._3dIconbtn}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openinnewtab(`/3droom/${data?._id}`);
                                      }}>
                                      <img
                                        src={_3DIcon}
                                        className={style.Icon3d}
                                        style={{ width: '50px', height: '40px' }}
                                      />
                                    </button>
                                  )}
                                </div>
                                <div>
                                  <button
                                    style={{
                                      borderRadius: '30px',
                                      backgroundColor: '#DB5353',
                                      color: '#FFFFFF',
                                      fontSize: '12px',
                                      border: 'none',
                                      padding: '0px 15px 0px 15px',
                                      height: '30px'
                                    }}
                                    disabled={savedMoodBoardIds.includes(data?._id)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddToBoard(data ? data : '');
                                    }}>
                                    {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                                  </button>
                                  {data?.hasOwnProperty('models_status') &&
                                    data.models_status == 3 && (
                                      <button
                                        className={style.arIconbtn}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openinnewtab(`pmapp://explore?${data?._id}`);
                                        }}>
                                        <img src={ARIcon} className={style.arIcon} />
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <span
                                style={{
                                  opacity: 1,
                                  color: '#000000',
                                  fontWeight: 700,
                                  fontSize: '16px',
                                  marginLeft: '7px'
                                }}>
                                {uniqueProducts(data ? data : 0)} Products
                              </span>
                            </div>
                            <div>
                              {data?.hasOwnProperty('models_status') && data.models_status == 3 && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openinnewtab(`pmapp://explore?${data?._id}`);
                                  }}
                                  style={{ backgroundColor: '#ffffff' }}
                                  className="mr-2">
                                  <img src={ARIcon} style={{ width: '30px' }} />
                                </button>
                              )}
                              <button
                                style={{
                                  borderRadius: '30px',
                                  backgroundColor: '#DB5353',
                                  color: '#FFFFFF',
                                  fontSize: '12px',
                                  border: 'none',
                                  padding: '0px 15px 0px 15px',
                                  height: '30px'
                                }}
                                disabled={savedMoodBoardIds.includes(data?._id)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddToBoard(data ? data : '');
                                }}>
                                {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
        <SliderComponent sliderData={roomsData} tittle={'Rooms'} type={'other'} allTags={allTags} />
        {loadingRooms ? (
          <div className="d-flex justify-content-center mt-4">
            <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
          </div>
        ) : (
          <div>
            {dimension[0] > 500 && (
              <div>
                {roomsMoodboard.length > 0 &&
                  roomsMoodboard.map((data, index) => {
                    return (
                      <>
                        <div className={classnames('row')}>
                          <div className={classnames('col-4')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[0] ? style.item : 'd-none'}>
                                  <img
                                    src={data[0]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[0]?._id)
                                          : history.push(`/pre-explore-detail/${data[0]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[0] ? data[0] : 0)} Products
                                        </span>
                                        {data[0]?.hasOwnProperty('model_url') &&
                                          data[0]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[0]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[0]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[0] ? data[0] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[0]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[0]?.hasOwnProperty('models_status') &&
                                          data[0].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[0]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[1] ? style.item : 'd-none'}>
                                  <img
                                    src={data[1]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[1]?._id)
                                          : history.push(`/pre-explore-detail/${data[1]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[1] ? data[1] : 0)} Products
                                        </span>
                                        {data[1]?.hasOwnProperty('model_url') &&
                                          data[1]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[1]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[1]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[1] ? data[1] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[1]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[1]?.hasOwnProperty('models_status') &&
                                          data[1].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[1]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-8')}>
                            <div className={classnames('row h-100')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[2] ? style.item : 'd-none'}>
                                  <img
                                    src={data[2]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[2]?._id)
                                          : history.push(`/pre-explore-detail/${data[2]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[2] ? data[2] : 0)} Products
                                        </span>
                                        {data[2]?.hasOwnProperty('model_url') &&
                                          data[2]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[2]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[2]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[2]);
                                          }}>
                                          {savedMoodBoardIds.includes(data[2]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[2]?.hasOwnProperty('models_status') &&
                                          data[2].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[2]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={classnames('row')}>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[3] ? style.item : 'd-none'}>
                                  <img
                                    src={data[3]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[3]?._id)
                                          : history.push(`/pre-explore-detail/${data[3]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[3] ? data[3] : 0)} Products
                                        </span>
                                        {data[3]?.hasOwnProperty('model_url') &&
                                          data[3]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[3]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[3]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[3] ? data[3] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[3]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[3]?.hasOwnProperty('models_status') &&
                                          data[3].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[3]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[4] ? style.item : 'd-none'}>
                                  <img
                                    src={data[4]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1">
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[4] ? data[4] : 0)} Products
                                        </span>
                                        {data[4]?.hasOwnProperty('model_url') &&
                                          data[4]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[4]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[4]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[4] ? data[4] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[4]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[4]?.hasOwnProperty('models_status') &&
                                          data[4].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[4]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
            {dimension[0] < 500 && (
              <>
                {roomsMoodboard.length > 0 &&
                  roomsMoodboard[0]?.map((data, index) => {
                    return (
                      <div
                        className={classnames('row')}
                        key={index}
                        style={{ marginTop: '3.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className={classnames('col-12')}>
                          <div
                            style={{
                              marginBottom: '5px',
                              height: '100%'
                            }}
                            className={data ? style.item : 'd-none'}>
                            <img src={data?.preview_url} height={'100%'} width={'100%'} alt="" />
                            <div className={style.content}>
                              <div
                                style={{ cursor: 'pointer' }}
                                className="d-flex justify-content-between w-100 mx-2 mt-1"
                                onClick={() =>
                                  auth.isAuthenticated
                                    ? handleMoodBoardProducts(data?._id)
                                    : history.push(`/pre-explore-detail/${data?._id}`)
                                }>
                                <div>
                                  <span
                                    style={{
                                      opacity: 1,
                                      color: '#ffffff',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      marginLeft: '7px'
                                    }}>
                                    {uniqueProducts(data ? data : 0)} Products
                                  </span>
                                  {data[0]?.hasOwnProperty('model_url') &&
                                    data[0]?.model_url != null && (
                                      <button
                                        className={style._3dIconbtn}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openinnewtab(`/3droom/${data[0]?._id}`);
                                        }}>
                                        <img
                                          src={_3DIcon}
                                          className={style.Icon3d}
                                          style={{ width: '50px', height: '40px' }}
                                        />
                                      </button>
                                    )}
                                </div>
                                <div>
                                  <button
                                    style={{
                                      borderRadius: '30px',
                                      backgroundColor: '#DB5353',
                                      color: '#FFFFFF',
                                      fontSize: '12px',
                                      border: 'none',
                                      padding: '0px 15px 0px 15px',
                                      height: '30px'
                                    }}
                                    disabled={savedMoodBoardIds.includes(data?._id)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddToBoard(data ? data : '');
                                    }}>
                                    {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                                  </button>
                                  {data?.hasOwnProperty('models_status') &&
                                    data.models_status == 3 && (
                                      <button
                                        className={style.arIconbtn}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openinnewtab(`pmapp://explore?${data?._id}`);
                                        }}>
                                        <img src={ARIcon} className={style.arIcon} />
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <span
                                style={{
                                  opacity: 1,
                                  color: '#000000',
                                  fontWeight: 700,
                                  fontSize: '16px',
                                  marginLeft: '7px'
                                }}>
                                {uniqueProducts(data ? data : 0)} Products
                              </span>
                            </div>
                            <div>
                              {data?.hasOwnProperty('models_status') && data.models_status == 3 && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openinnewtab(`pmapp://explore?${data?._id}`);
                                  }}
                                  style={{ backgroundColor: '#ffffff' }}
                                  className="mr-2">
                                  <img src={ARIcon} style={{ width: '30px' }} />
                                </button>
                              )}
                              <button
                                style={{
                                  borderRadius: '30px',
                                  backgroundColor: '#DB5353',
                                  color: '#FFFFFF',
                                  fontSize: '12px',
                                  border: 'none',
                                  padding: '0px 15px 0px 15px',
                                  height: '30px'
                                }}
                                disabled={savedMoodBoardIds.includes(data?._id)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddToBoard(data ? data : '');
                                }}>
                                {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}

        <SliderComponent
          sliderData={childOfRootTags.length > 0 ? childOfRootTags[0].child : []}
          tittle={childOfRootTags.length > 0 ? childOfRootTags[0].name : ''}
          type={'tags'}
          allTags={allTags}
        />
        {loadingBuilder ? (
          <div className="d-flex justify-content-center mt-4">
            <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
          </div>
        ) : (
          <div>
            {dimension[0] > 500 && (
              <div>
                {builderMoodboard.length > 0 &&
                  builderMoodboard.map((data, index) => {
                    return (
                      <>
                        <div className={classnames('row')}>
                          <div className={classnames('col-4')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[0] ? style.item : 'd-none'}>
                                  <img
                                    src={data[0]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[0]?._id)
                                          : history.push(`/pre-explore-detail/${data[0]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[0] ? data[0] : 0)} Products
                                        </span>
                                        {data[0]?.hasOwnProperty('model_url') &&
                                          data[0]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[0]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[0]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[0] ? data[0] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[0]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[0]?.hasOwnProperty('models_status') &&
                                          data[0].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[0]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[1] ? style.item : 'd-none'}>
                                  <img
                                    src={data[1]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[1]?._id)
                                          : history.push(`/pre-explore-detail/${data[1]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[1] ? data[1] : 0)} Products
                                        </span>
                                        {data[1]?.hasOwnProperty('model_url') &&
                                          data[1]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[1]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[1]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[1] ? data[1] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[1]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[1]?.hasOwnProperty('models_status') &&
                                          data[1].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[1]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-8')}>
                            <div className={classnames('row h-100')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[2] ? style.item : 'd-none'}>
                                  <img
                                    src={data[2]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[2]?._id)
                                          : history.push(`/pre-explore-detail/${data[2]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[2] ? data[2] : 0)} Products
                                        </span>
                                        {data[2]?.hasOwnProperty('model_url') &&
                                          data[2]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[2]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[2]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[2]);
                                          }}>
                                          {savedMoodBoardIds.includes(data[2]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[2]?.hasOwnProperty('models_status') &&
                                          data[2].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[2]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={classnames('row')}>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[3] ? style.item : 'd-none'}>
                                  <img
                                    src={data[3]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[3]?._id)
                                          : history.push(`/pre-explore-detail/${data[3]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[3] ? data[3] : 0)} Products
                                        </span>
                                        {data[3]?.hasOwnProperty('model_url') &&
                                          data[3]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[3]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[3]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[3] ? data[3] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[3]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[3]?.hasOwnProperty('models_status') &&
                                          data[3].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[3]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[4] ? style.item : 'd-none'}>
                                  <img
                                    src={data[4]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1">
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[4] ? data[4] : 0)} Products
                                        </span>
                                        {data[4]?.hasOwnProperty('model_url') &&
                                          data[4]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[4]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[4]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[4] ? data[4] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[4]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[4]?.hasOwnProperty('models_status') &&
                                          data[4].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[4]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
            {dimension[0] < 500 && (
              <>
                {builderMoodboard.length > 0 &&
                  builderMoodboard[0]?.map((data, index) => {
                    return (
                      <div
                        className={classnames('row')}
                        key={index}
                        style={{ marginTop: '3.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className={classnames('col-12')}>
                          <div
                            style={{
                              marginBottom: '5px',
                              height: '100%'
                            }}
                            className={data ? style.item : 'd-none'}>
                            <img src={data?.preview_url} height={'100%'} width={'100%'} alt="" />
                            <div className={style.content}>
                              <div
                                style={{ cursor: 'pointer' }}
                                className="d-flex justify-content-between w-100 mx-2 mt-1"
                                onClick={() =>
                                  auth.isAuthenticated
                                    ? handleMoodBoardProducts(data?._id)
                                    : history.push(`/pre-explore-detail/${data?._id}`)
                                }>
                                <div>
                                  <span
                                    style={{
                                      opacity: 1,
                                      color: '#ffffff',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      marginLeft: '7px'
                                    }}>
                                    {uniqueProducts(data ? data : 0)} Products
                                  </span>
                                  {data?.hasOwnProperty('model_url') && data?.model_url != null && (
                                    <button
                                      className={style._3dIconbtn}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openinnewtab(`/3droom/${data?._id}`);
                                      }}>
                                      <img
                                        src={_3DIcon}
                                        className={style.Icon3d}
                                        style={{ width: '50px', height: '40px' }}
                                      />
                                    </button>
                                  )}
                                </div>
                                <div>
                                  <button
                                    style={{
                                      borderRadius: '30px',
                                      backgroundColor: '#DB5353',
                                      color: '#FFFFFF',
                                      fontSize: '12px',
                                      border: 'none',
                                      padding: '0px 15px 0px 15px',
                                      height: '30px'
                                    }}
                                    disabled={savedMoodBoardIds.includes(data?._id)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddToBoard(data ? data : '');
                                    }}>
                                    {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                                  </button>
                                  {data?.hasOwnProperty('models_status') &&
                                    data.models_status == 3 && (
                                      <button
                                        className={style.arIconbtn}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openinnewtab(`pmapp://explore?${data?._id}`);
                                        }}>
                                        <img src={ARIcon} className={style.arIcon} />
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <span
                                style={{
                                  opacity: 1,
                                  color: '#000000',
                                  fontWeight: 700,
                                  fontSize: '16px',
                                  marginLeft: '7px'
                                }}>
                                {uniqueProducts(data ? data : 0)} Products
                              </span>
                            </div>
                            <div>
                              {data?.hasOwnProperty('models_status') && data.models_status == 3 && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openinnewtab(`pmapp://explore?${data?._id}`);
                                  }}
                                  style={{ backgroundColor: '#ffffff' }}
                                  className="mr-2">
                                  <img src={ARIcon} style={{ width: '30px' }} />
                                </button>
                              )}
                              <button
                                style={{
                                  borderRadius: '30px',
                                  backgroundColor: '#DB5353',
                                  color: '#FFFFFF',
                                  fontSize: '12px',
                                  border: 'none',
                                  padding: '0px 15px 0px 15px',
                                  height: '30px'
                                }}
                                disabled={savedMoodBoardIds.includes(data?._id)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddToBoard(data ? data : '');
                                }}>
                                {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
        <SliderComponent
          sliderData={childOfRootTags.length > 1 ? childOfRootTags[1].child : []}
          tittle={childOfRootTags.length > 1 ? childOfRootTags[1].name : []}
          type={'tags'}
          allTags={allTags}
        />
        {loadingInspiration ? (
          <div className="d-flex justify-content-center mt-4">
            <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
          </div>
        ) : (
          <div>
            {dimension[0] > 500 && (
              <div>
                {inspirationMoodboard.length > 0 &&
                  inspirationMoodboard.map((data, index) => {
                    return (
                      <>
                        <div className={classnames('row')}>
                          <div className={classnames('col-8')}>
                            <div className={classnames('row h-100')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[0] ? style.item : 'd-none'}>
                                  <img
                                    src={data[0]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[0]?._id)
                                          : history.push(`/pre-explore-detail/${data[0]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[0] ? data[0] : 0)} Products
                                        </span>
                                        {data[0]?.hasOwnProperty('model_url') &&
                                          data[0]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[0]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[0]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[0]);
                                          }}>
                                          {savedMoodBoardIds.includes(data[0]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[0]?.hasOwnProperty('models_status') &&
                                          data[0].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[0]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-4')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[1] ? style.item : 'd-none'}>
                                  <img
                                    src={data[1]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[1]?._id)
                                          : history.push(`/pre-explore-detail/${data[1]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[1] ? data[1] : 0)} Products
                                        </span>
                                        {data[1]?.hasOwnProperty('model_url') &&
                                          data[1]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[1]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[1]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[1] ? data[1] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[1]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[1]?.hasOwnProperty('models_status') &&
                                          data[1].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[1]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[2] ? style.item : 'd-none'}>
                                  <img
                                    src={data[2]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[2]?._id)
                                          : history.push(`/pre-explore-detail/${data[2]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[2] ? data[2] : 0)} Products
                                        </span>
                                        {data[2]?.hasOwnProperty('model_url') &&
                                          data[2]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[2]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[2]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[2] ? data[2] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[2]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[2]?.hasOwnProperty('models_status') &&
                                          data[2].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[2]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={classnames('row')}>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[3] ? style.item : 'd-none'}>
                                  <img
                                    src={data[3]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[3]?._id)
                                          : history.push(`/pre-explore-detail/${data[3]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[3] ? data[3] : 0)} Products
                                        </span>
                                        {data[3]?.hasOwnProperty('model_url') &&
                                          data[3]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[3]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[3]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[3] ? data[3] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[3]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[3]?.hasOwnProperty('models_status') &&
                                          data[3].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[3]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[4] ? style.item : 'd-none'}>
                                  <img
                                    src={data[4]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1">
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[4] ? data[4] : 0)} Products
                                        </span>
                                        {data[4]?.hasOwnProperty('model_url') &&
                                          data[4]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[4]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[4]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[4] ? data[4] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[4]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[4]?.hasOwnProperty('models_status') &&
                                          data[4].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[4]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
            {dimension[0] < 500 && (
              <>
                {inspirationMoodboard.length > 0 &&
                  inspirationMoodboard[0]?.map((data, index) => {
                    return (
                      <div
                        className={classnames('row')}
                        key={index}
                        style={{ marginTop: '3.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className={classnames('col-12')}>
                          <div
                            style={{
                              marginBottom: '5px',
                              height: '100%'
                            }}
                            className={data ? style.item : 'd-none'}>
                            <img src={data?.preview_url} height={'100%'} width={'100%'} alt="" />
                            <div className={style.content}>
                              <div
                                style={{ cursor: 'pointer' }}
                                className="d-flex justify-content-between w-100 mx-2 mt-1"
                                onClick={() =>
                                  auth.isAuthenticated
                                    ? handleMoodBoardProducts(data?._id)
                                    : history.push(`/pre-explore-detail/${data?._id}`)
                                }>
                                <div>
                                  <span
                                    style={{
                                      opacity: 1,
                                      color: '#ffffff',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      marginLeft: '7px'
                                    }}>
                                    {uniqueProducts(data ? data : 0)} Products
                                  </span>
                                  {data?.hasOwnProperty('model_url') && data?.model_url != null && (
                                    <button
                                      className={style._3dIconbtn}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openinnewtab(`/3droom/${data?._id}`);
                                      }}>
                                      <img
                                        src={_3DIcon}
                                        className={style.Icon3d}
                                        style={{ width: '50px', height: '40px' }}
                                      />
                                    </button>
                                  )}
                                </div>
                                <div>
                                  <button
                                    style={{
                                      borderRadius: '30px',
                                      backgroundColor: '#DB5353',
                                      color: '#FFFFFF',
                                      fontSize: '12px',
                                      border: 'none',
                                      padding: '0px 15px 0px 15px',
                                      height: '30px'
                                    }}
                                    disabled={savedMoodBoardIds.includes(data?._id)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddToBoard(data ? data : '');
                                    }}>
                                    {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                                  </button>
                                  {data?.hasOwnProperty('models_status') &&
                                    data.models_status == 3 && (
                                      <button
                                        className={style.arIconbtn}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openinnewtab(`pmapp://explore?${data?._id}`);
                                        }}>
                                        <img src={ARIcon} className={style.arIcon} />
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <span
                                style={{
                                  opacity: 1,
                                  color: '#000000',
                                  fontWeight: 700,
                                  fontSize: '16px',
                                  marginLeft: '7px'
                                }}>
                                {uniqueProducts(data ? data : 0)} Products
                              </span>
                            </div>
                            <div>
                              {data?.hasOwnProperty('models_status') && data.models_status == 3 && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openinnewtab(`pmapp://explore?${data?._id}`);
                                  }}
                                  style={{ backgroundColor: '#ffffff' }}
                                  className="mr-2">
                                  <img src={ARIcon} style={{ width: '30px' }} />
                                </button>
                              )}
                              <button
                                style={{
                                  borderRadius: '30px',
                                  backgroundColor: '#DB5353',
                                  color: '#FFFFFF',
                                  fontSize: '12px',
                                  border: 'none',
                                  padding: '0px 15px 0px 15px',
                                  height: '30px'
                                }}
                                disabled={savedMoodBoardIds.includes(data?._id)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddToBoard(data ? data : '');
                                }}>
                                {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
        {dimension[0] > 500 && (
          <>
            <h5
              style={{
                fontFamily: 'Quicksand',
                textTransform: 'uppercase',
                fontWeight: 600,
                fontSize: '18px',
                marginBottom: '20px',
                padding: '0px 15px',
                marginTop: '50px'
              }}>
              TOP PICKS FROM OUR INTERIOR STYLISTS
            </h5>
            <div className={style.banner}>
              <img src={BannerImg} className={style.BannerImg} />
              <BannerSliderComponent sliderData={roomsData} />
            </div>
          </>
        )}

        {dimension[0] < 500 && (
          <div className={style.mobileBannerContainer}>
            <h5
              style={{
                fontFamily: 'Quicksand',
                textTransform: 'uppercase',
                fontWeight: 600,
                fontSize: '18px',
                marginBottom: '20px',
                padding: '0px 15px',
                marginTop: '50px',
                textAlign: 'center'
              }}>
              TOP PICKS FROM OUR INTERIOR STYLISTS
            </h5>
            <img src={BannerImgMob} className={style.BannerImg} />
            <BannerSliderComponentMobile sliderData={roomsData} />
          </div>
        )}

        {loadingTopPicks ? (
          <div className="d-flex justify-content-center mt-4">
            <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
          </div>
        ) : (
          <div>
            {dimension[0] > 500 && (
              <div>
                {topPicksMoodboard.length > 0 &&
                  topPicksMoodboard.map((data, index) => {
                    return (
                      <>
                        <div className={classnames('row')}>
                          <div className={classnames('col-8')}>
                            <div className={classnames('row h-100')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[0] ? style.item : 'd-none'}>
                                  <img
                                    src={data[0]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[0]?._id)
                                          : history.push(`/pre-explore-detail/${data[0]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[0] ? data[0] : 0)} Products
                                        </span>
                                        {data[0]?.hasOwnProperty('model_url') &&
                                          data[0]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[0]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[0]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[0]);
                                          }}>
                                          {savedMoodBoardIds.includes(data[0]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[0]?.hasOwnProperty('models_status') &&
                                          data[0].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[0]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-4')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[1] ? style.item : 'd-none'}>
                                  <img
                                    src={data[1]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[1]?._id)
                                          : history.push(`/pre-explore-detail/${data[1]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[1] ? data[1] : 0)} Products
                                        </span>
                                        {data[1]?.hasOwnProperty('model_url') &&
                                          data[1]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[1]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[1]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[1] ? data[1] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[1]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[1]?.hasOwnProperty('models_status') &&
                                          data[1].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[1]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[2] ? style.item : 'd-none'}>
                                  <img
                                    src={data[2]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[2]?._id)
                                          : history.push(`/pre-explore-detail/${data[2]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[2] ? data[2] : 0)} Products
                                        </span>
                                        {data[2]?.hasOwnProperty('model_url') &&
                                          data[2]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[2]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[2]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[2] ? data[2] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[2]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[2]?.hasOwnProperty('models_status') &&
                                          data[2].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[2]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={classnames('row')}>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[3] ? style.item : 'd-none'}>
                                  <img
                                    src={data[3]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1"
                                      onClick={() =>
                                        auth.isAuthenticated
                                          ? handleMoodBoardProducts(data[3]?._id)
                                          : history.push(`/pre-explore-detail/${data[3]?._id}`)
                                      }>
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[3] ? data[3] : 0)} Products
                                        </span>
                                        {data[3]?.hasOwnProperty('model_url') &&
                                          data[3]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[3]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[3]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[3] ? data[3] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[3]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[3]?.hasOwnProperty('models_status') &&
                                          data[3].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[3]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classnames('col-6')}>
                            <div className={classnames('row')}>
                              <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                                <div
                                  style={{
                                    marginBottom: '15px',
                                    height: '100%'
                                  }}
                                  className={data[4] ? style.item : 'd-none'}>
                                  <img
                                    src={data[4]?.preview_url}
                                    height={'100%'}
                                    width={'100%'}
                                    alt=""
                                  />
                                  <div className={style.content}>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      className="d-flex justify-content-between w-100 mx-2 mt-1">
                                      <div>
                                        <span
                                          style={{
                                            opacity: 1,
                                            color: '#ffffff',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            marginLeft: '7px'
                                          }}>
                                          {uniqueProducts(data[4] ? data[4] : 0)} Products
                                        </span>
                                        {data[4]?.hasOwnProperty('model_url') &&
                                          data[4]?.model_url != null && (
                                            <button
                                              className={style._3dIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openinnewtab(`/3droom/${data[4]?._id}`);
                                              }}>
                                              <img
                                                src={_3DIcon}
                                                className={style.Icon3d}
                                                style={{ width: '50px', height: '40px' }}
                                              />
                                            </button>
                                          )}
                                      </div>
                                      <div>
                                        <button
                                          style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#DB5353',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            border: 'none',
                                            padding: '0px 15px 0px 15px',
                                            height: '30px'
                                          }}
                                          disabled={savedMoodBoardIds.includes(data[4]?._id)}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToBoard(data[4] ? data[4] : '');
                                          }}>
                                          {savedMoodBoardIds.includes(data[4]?._id)
                                            ? 'Saved'
                                            : 'Save'}
                                        </button>
                                        {data[4]?.hasOwnProperty('models_status') &&
                                          data[4].models_status == 3 && (
                                            <button
                                              className={style.arIconbtn}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleQr(data[4]?._id);
                                              }}>
                                              <img src={ARIcon} className={style.arIcon} />
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
            {dimension[0] < 500 && (
              <>
                {topPicksMoodboard.length > 0 &&
                  topPicksMoodboard[0]?.map((data, index) => {
                    return (
                      <div
                        className={classnames('row')}
                        key={index}
                        style={{ marginTop: '3.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className={classnames('col-12')}>
                          <div
                            style={{
                              marginBottom: '5px',
                              height: '100%'
                            }}
                            className={data ? style.item : 'd-none'}>
                            <img src={data?.preview_url} height={'100%'} width={'100%'} alt="" />
                            <div className={style.content}>
                              <div
                                style={{ cursor: 'pointer' }}
                                className="d-flex justify-content-between w-100 mx-2 mt-1"
                                onClick={() =>
                                  auth.isAuthenticated
                                    ? handleMoodBoardProducts(data?._id)
                                    : history.push(`/pre-explore-detail/${data?._id}`)
                                }>
                                <div>
                                  <span
                                    style={{
                                      opacity: 1,
                                      color: '#ffffff',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      marginLeft: '7px'
                                    }}>
                                    {uniqueProducts(data ? data : 0)} Products
                                  </span>
                                  {data?.hasOwnProperty('model_url') && data?.model_url != null && (
                                    <button
                                      className={style._3dIconbtn}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openinnewtab(`/3droom/${data?._id}`);
                                      }}>
                                      <img
                                        src={_3DIcon}
                                        className={style.Icon3d}
                                        style={{ width: '50px', height: '40px' }}
                                      />
                                    </button>
                                  )}
                                </div>
                                <div>
                                  <button
                                    style={{
                                      borderRadius: '30px',
                                      backgroundColor: '#DB5353',
                                      color: '#FFFFFF',
                                      fontSize: '12px',
                                      border: 'none',
                                      padding: '0px 15px 0px 15px',
                                      height: '30px'
                                    }}
                                    disabled={savedMoodBoardIds.includes(data?._id)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddToBoard(data ? data : '');
                                    }}>
                                    {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                                  </button>
                                  {data?.hasOwnProperty('models_status') &&
                                    data.models_status == 3 && (
                                      <button
                                        className={style.arIconbtn}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openinnewtab(`pmapp://explore?${data?._id}`);
                                        }}>
                                        <img src={ARIcon} className={style.arIcon} />
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <span
                                style={{
                                  opacity: 1,
                                  color: '#000000',
                                  fontWeight: 700,
                                  fontSize: '16px',
                                  marginLeft: '7px'
                                }}>
                                {uniqueProducts(data ? data : 0)} Products
                              </span>
                            </div>
                            <div>
                              {data?.hasOwnProperty('models_status') && data.models_status == 3 && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openinnewtab(`pmapp://explore?${data?._id}`);
                                  }}
                                  style={{ backgroundColor: '#ffffff' }}
                                  className="mr-2">
                                  <img src={ARIcon} style={{ width: '30px' }} />
                                </button>
                              )}
                              <button
                                style={{
                                  borderRadius: '30px',
                                  backgroundColor: '#DB5353',
                                  color: '#FFFFFF',
                                  fontSize: '12px',
                                  border: 'none',
                                  padding: '0px 15px 0px 15px',
                                  height: '30px'
                                }}
                                disabled={savedMoodBoardIds.includes(data?._id)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddToBoard(data ? data : '');
                                }}>
                                {savedMoodBoardIds.includes(data?._id) ? 'Saved' : 'Save'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

ExploreTags.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { set_explore_filter })(withRouter(ExploreTags));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
