import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import Header from '../../components/Header/Header';
import style from './Shopdetails.module.css';
import style1 from './Shop.module.css';
import './Shop.css';
import classnames from 'classnames';
import { Drawer, Grid } from '@material-ui/core';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { categoryAll, rooms } from './constant';
import { GrClose } from 'react-icons/gr';
import InputBase from '@mui/material/InputBase';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import QRScanner from '../../components/layout/QrScanner';
import { makeStyles } from '@material-ui/core/styles';
import ReactPaginate from 'react-paginate';
import customerApiInstance from '../../utils/CustomerApiInstance';
import getCartDataCount from '../../utils/CartDetails';
import { storeCartCount } from '../../store/actions/sampleaction';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import arrow from '../../assets/images/Iconionic-ios.png';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { openin } from '../../utils/utils';
import { CloseOutlined } from '@material-ui/icons';
import ConsoleHelper from '../../utils/ConsoleHelper';
import ProductCard from '../../components/cards/ProductCard';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-root': {
    width: '83%'
  },
  '& .MuiInputBase-input': {
    '&:focus': {
      borderRadius: 23
    },
    borderRadius: 23,
    boxShadow: '0px 3px 6px #00000029',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '5px 17px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}));

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: theme.shadows[5]
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: 'none',
    width: 800,
    height: 500,
    padding: theme.spacing(2, 4, 3)
  },
  papers: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    width: '70%',
    height: 500,
    position: 'relative',
    outline: 'none'
  },
  displayrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  roombox: {
    fontSize: '15px',
    marginTop: '16px',
    fontWeight: 600,
    marginBottom: '51px'
  },
  drawerHeader: {
    float: 'right'
  }
}));

const ProductSearchList = ({ history, auth, storeCartCount }) => {
  const classes = useStyles();
  const params = useParams();
  // console.log(JSON.parse((localStorage.getItem('search'))))
  const { productId } = useParams();
  const dimension = useMediaQuery();
  const productListViewRef = useRef(null);
  const [totalPages, setTotalPages] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tempProductsList, setTempProductsList] = useState([]);
  const [searchInput] = useState('');
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [prodQuantity] = useState(1);
  const [savedProductIds, setSavedProductIds] = useState([]);
  let [vendors, setVendors] = useState([]);
  const [savedProducts, setSavedProducts] = useState([]);
  const [brandClicked, setBrandClicked] = useState(false);
  const [showMore] = useState(false);
  const [brandExpanded, setBrandExpanded] = useState(false);
  const [colorExpanded, setColorExpanded] = useState(false);
  const [materialExpanded, setMaterialExpanded] = useState(false);
  const [colors, setColors] = useState(['Brown', 'Red']);
  const [material, setMaterial] = useState([]);
  const [likesChecked, setLikesChecked] = useState(false);
  const [arChecked, setarChecked] = useState(true);
  const [colorFilter, setColorFilter] = useState([]);
  const [colorClicked] = useState('');
  const [filterOption, setFilterOption] = useState({
    AR: true,
    sortBy: 'view_count:desc',
    brand: params.brand,
    primary: true
  });
  const searchquery = JSON.parse(localStorage.getItem('search'));
  const searchquery2 = (localStorage.getItem('search2'));
  //   console.log(searchquery)
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [prodLoading, setProdLoading] = useState(false);
  const [bannerlist, setbannerlist] = useState([]);
  const [sortby, setsortby] = useState('');
  const [selectedsubcategory, setselectedsubcategory] = useState('');

  const [subcategory, setsubcategory] = useState([]);
  const [shopType, setShopType] = useState(params?.roomtype ? params.roomtype : 1);
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();
  const [budgetFilter, setBudgetFilter] = useState(['', '']);
  const shop_type = [
    { id: 1, value: 'living_room', type: 'Living Room' },
    { id: 2, value: 'dining_room', type: 'Dining Room' },
    { id: 3, value: 'bed_room', type: 'Bed Room' },
    { id: 4, value: 'kids_room', type: 'Kids Room' },
    { id: 5, value: 'puja_room', type: 'Puja Room' },
    { id: 6, value: 'foyer', type: 'Foyer' },
    { id: 7, value: 'balcony', type: 'Balcony' },
    {
      id: 8,
      value: 'study_entertainment_room',
      type: 'Study Entertainment Room'
    },
    { id: 9, value: 'other', type: 'Other' }
  ];
  let prevPage = useRef(0);
  let numberOfItems = 10;
  let filterApplied = useRef(false);

  function openinnewtab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  if (categories && categories.length > 0) {
    numberOfItems = showMore ? categories.length : 10;
  }

  const handleBudgetFilter = () => {
    if (budgetFilter[0] !== '' && budgetFilter[1] !== '') {
      setFilterOption({
        ...filterOption,
        price_from: budgetFilter[0],
        price_to: budgetFilter[1]
      });
    } else {
      setSnack(true);
      setSnackMessage('Enter a valid Min and Max budget');
    }
  };

  const handleQr = (productIds) => {
    ConsoleHelper('productIds', productIds);
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = (productIds) => {
    history.push(`/3d-model/${productIds}`);
  };
  const getProductDetails = async (page, filters, search) => {
    if (filters && filters.hasOwnProperty('brand')) {
      let vendor = vendors.filter(ven => ven.name === filters.brand);
      if (vendor.length > 0) {
        filters.vendor_id = vendor[0]._id;
      }
    }
    setProdLoading(true);
    // const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
    //   params: { page, ...filters }
    // });
    // setProdLoading(false);
    // if (Object.keys('filters') === 0) {
        //   const location1 = history.location;
    //   let filterRooms = [];
    //   let filterStyles = [];
    //   let stylesFilter = [];
    //   let roomsFilter = [];
    //   let stylesUpper;
    //   if (params) {
    //     filterRooms = params.rooms;
    //     filterStyles = params.styles;
    //     roomsFilter = filterRooms.split(',');
    //     stylesFilter = filterStyles.split(',');
    //     stylesUpper = stylesFilter.map(s1 => {
    //       return s1
    //         .replace('_', ' ')
    //         .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    //     });
    //     let styleFilterProducts = res.data.results.filter(prod => stylesUpper.includes(prod.style));
    //     let roomFilterProducts = res.data.result.filter(prod =>
    //       prod.room_type.filter(room => roomsFilter.includes(room))
    //     );
    //     let styleAppliedProds = [...styleFilterProducts, ...roomFilterProducts];
    //     styleAppliedProds = styleAppliedProds.filter(
    //       (v, i, a) => a.findIndex(t => t._id === v._id) === i
    //     );
    //     setProductsList([...styleAppliedProds]);
    //   }
    // }
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/product/search`, {
      body: searchquery,
      searchinput : searchquery2,
      params: { ...filters },
      page : page
    });
    setProdLoading(false);
    // console.log([...res.data.results]);
    setProductsList([...res.data.results]);
    setTempProductsList([...res.data.results]);
    setTotalRecords(res.data.totalResults);
    setTotalPages(res.data.totalPages);
    
    setLimit(res.data.limit);
    loadTableData();
    
    if (filterApplied.current && page !== 1) {
        return;
    }
    filterApplied.current = false;
};

const filterData = async (filters) => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/product/search`, {
        params: { ...filters  },
        searchinput : searchquery2,
      page : {page},
      body: { searchquery }
    });
  };

  const loadTableData = () => {
    if (page === prevPage.current) {
      setPage((prev1State) => prev1State + 1);
    }
  };

  const addToCart = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const buyNow = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push('/cart');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
        history.push('/cart');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const renderColumn = () => {
    return (
      <div className="row sm-pl-3">
        {productsList && productsList.length > 0 ? (
          productsList
            .filter((data) => {
              if (data.price === 0) {
                return false;
              } else {
                return true;
              }
            })
            .map((data, index) => (
              <>
                {data?.stock > 0 ? (
                  <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">
                    <ProductCard
                      key={index}
                      data={data}
                      heart={auth && auth.user}
                      heartIsActive={savedProductIds?.includes(data._id)}
                      arIcon={data && data['3d_models']?.glbFileUrl}
                      // tag={'NEW LAUNCH'}
                      vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                      productImage={data.image[0]}
                      productName={data.name}
                      price={data.price}
                      pnmPrice={data.pixel_price}
                      onClickCart={() => addToCart(data)}
                      onClickBuyNow={() => buyNow(data)}
                      onClickHeart={() => handleFavourites(data)}
                      onClicAr={() =>
                        dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                      }
                      viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                      sx={{ margin: 'auto', width: '100%', maxWidth: '295px' }} // sx will apply style to the outermost container of Product Card (To over ride css)
                    />
                  </div>
                ) : null}
                {index === 8 ? (
                  <Grid container>
                    <Grid item xs={12} className={style1.imgtxtcontainer}>
                      <img
                        onClick={() =>
                          bannerlist[2]?.routing_url && openin(bannerlist[2].routing_url)
                        }
                        src={
                          bannerlist[2]?.img_url
                            ? bannerlist[2].img_url
                            : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/shutterstock_496612132.png'
                        }
                        alt=""
                        className={classnames(style.bannerImg, 'imagefitinsize mb-5 mt-3')}
                        style={{ width: '100%' }}
                      />
                      {dimension[0].width > 500 ? (
                        <p className={style1.txtoverimgrightlist1}>
                          {bannerlist[2]?.content
                            ? bannerlist[2].content
                            : `Get interiors
                designed online
                with the power of A.R.`}
                        </p>
                      ) : (
                        <p className={style1.txtoverimgrightlist1}>
                          {bannerlist[2]?.content
                            ? bannerlist[2].content
                            : `Get interiors
                designed online
                with the power of A.R.`}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </>
            ))
        ) : (
          <p>No Products Available </p>
        )}
      </div>
    );
  };

  const addToFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        ConsoleHelper('response shop likesChecked', res.data);
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = (product) => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds((prev) => [...prev, product._id]);
      setSavedProducts([...savedProducts, product]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter((filter) => filter !== product._id));
      const removefav = savedProducts.filter((sav) => sav._id !== product._id);
      setSavedProducts([...removefav]);
      removeFromFavourites(product);
    }
  };

  const getgenricbanner = async () => {
    const res = await axios.get(`${process.env.REACT_APP_CMS_URL}/api/v1/user/genricbanner`);
    if (res.status == 200 && res?.data?.data?.length > 0) {
      let responsedata = res.data.data;
      responsedata.sort(function (a, b) {
        if (a.bannername < b.bannername) {
          return -1;
        }
        if (a.bannername > b.bannername) {
          return 1;
        }
        return 0;
      });
      setbannerlist([...responsedata]);
    } else {
      setbannerlist([]);
    }
  };

  const getP = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${productId}`);
  };

  const fetchData = async (page, filters) => {
    setLoading(true);
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/filterOptions`);
    setLoading(false);
    if (res.data.colors) {
      setColors([...res.data.colors]);
    }
    if (res.data.material) {
      setMaterial([...res.data.material]);
    }
    if (res.data.brands) {
      setBrands([...res.data.brands]);
    }
  };

  const checkfilterKeys = (filters) => {
    let valueFlag = Object.keys(filters).some((k) => filters[k].length > 0);
    return valueFlag;
  };
  const handleChangeInput = (e) => {
    let value = e.target.value;
    setsortby(value);
    if (value !== '') {
      setFilterOption({
        ...filterOption,
        sortBy: value
      });
    }
  };

  const handleChangeshoptype = (event) => {
    setShopType(event.target.value);
    let roomtype = shop_type.find((item) => item.id == event.target.value).type;
    history.push({
      pathname: `/shop-details/${roomtype}/${false}`
    });
  };

  const fetchFavouriteData = async () => {
    try {
      if (auth && auth.user) {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
        );
        let savedIds = [];
        if (likesChecked) {
          if (res?.data?.favProducts?.length > 0) {
            setProductsList([...res.data.favProducts]);
          }
        } else {
          setProductsList([...tempProductsList]);
        }
        if (res.data.favourites.length !== 0) {
          res.data.favourites.map((dat) => {
            savedIds = dat.products.map((m) => {
              return m.product_id;
            });
          });
          setSavedProductIds([...savedIds]);
          const favouriteProductData = productsList.filter((prod) => savedIds.includes(prod._id));
          setSavedProducts([...favouriteProductData]);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (filterOption?.room_type?.length > 0) {
      let filters = { ...filterOption };
      ConsoleHelper(filterOption, 'filterOption');
      let search = searchInput;

      getProductDetails(page, filters, search);
    }
  }, [page, filterOption, searchInput, sortby, vendors]);

  useEffect(() => {
    const vendorDetails = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendor/list`);
      if (res.data && res.data.length > 0) {
        setVendors([...res.data]);
      }
    };
    vendorDetails();
  }, [brandClicked]);

  useEffect(() => {
    const body = {};
    if (colorFilter.length > 0) {
      if (colorFilter.includes('colorClicked')) {
        colorFilter = colorFilter.filter((cl) => cl === colorClicked);
        setColorFilter([...colorFilter]);
        body.color = colorClicked;
      } else {
        body.color = colorClicked;
      }
      const data = {
        body: body
      };
      filterData(data);
    }
  }, [colorFilter]);

  useEffect(() => {
    fetchFavouriteData();
  }, [likesChecked]);

  useEffect(() => {
    if (shopType) {
      let room = shop_type?.find((item) => item.id === shopType)?.type;
      if (filterOption.hasOwnProperty('room_type')) {
        let index = filterOption.room_type.findIndex((rm) => rm === room);
        if (index < 0) {
          setFilterOption({
            ...filterOption,
            room_type: [room]
          });
        } else {
          filterOption.room_type = filterOption.room_type.filter((rm) => rm !== room);
          setFilterOption({ ...filterOption });
        }
      } else {
        setFilterOption({ ...filterOption, room_type: [room] });
      }
      let data = [];
      data = rooms
        .find((item) => item.id == shopType)
        ?.category?.find((sub) => sub.title == params.category)?.subcategory;
      setsubcategory(data);
    }
  }, [shopType]);

  useEffect(() => {
    if (selectedsubcategory !== '') {
      let roomtitle = rooms.find((item) => item.id == params.roomtype);
      setFilterOption({
        ...filterOption,
        category: [params.category],
        room_type: [roomtitle.title],
        subcategory: [selectedsubcategory]
      });
    }
  }, [selectedsubcategory]);

  useEffect(() => {
    fetchData();
    productId && getP();
    getgenricbanner();
    if (params?.roomtype) {
      let data = [];
      data = rooms
        .find((item) => item.id == params.roomtype)
        ?.category?.find((sub) => sub.title == params.category)?.subcategory;
      setsubcategory(data);
      let roomtitle = rooms.find((item) => item.id == params.roomtype);
      if (roomtitle?.title && params?.styletype) {
        setFilterOption({
          ...filterOption,
          room_type: [roomtitle.title],
          category: [params.category],
          style: [params.styletype]
        });
      } else if (roomtitle?.title) {
        setFilterOption({
          ...filterOption,
          room_type: [roomtitle.title],
          category: [params.category]
        });
      }
    }
  }, []);
  return (
    <>
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snack}
          onClose={() => setSnack(false)}
          message={snackMessage}
          autoHideDuration={2000}
        />
        <Header />
        <div style={{ backgroundColor: 'white' }}>
          <div className={style.mainContainer}>
            {dimension[0] > 600 ? (
              <div className="row-lg-12 row-sm-12 row-xs-12 d-flex">
                <>
                  <div className="col-md-3 col-lg-3 col-xs-12 p-0">
                    <div className="row mb-3 ml-1" style={{ paddingTop: '110px' }}>
                      <h5
                        style={{
                          fontFamily: 'Quicksand',
                          fontSize: '20px',
                          fontWeight: 'bold'
                        }}>
                        Filters
                      </h5>
                      {(filterOption.hasOwnProperty('brand') ||
                        filterOption.hasOwnProperty('material') ||
                        filterOption.hasOwnProperty('color')) &&
                        checkfilterKeys(filterOption) && (
                          <button
                            className={style.filterButton}
                            style={{ height: '26px' }}
                            onClick={() => {
                              setBrandClicked(false);
                              if (params?.roomtype) {
                                let data = [];
                                data = rooms
                                  .find((item) => item.id == params.roomtype)
                                  ?.category?.find(
                                    (sub) => sub.title == params.category
                                  )?.subcategory;
                                setsubcategory(data);
                                let roomtitle = rooms.find((item) => item.id == params.roomtype);
                                if (roomtitle?.title && params?.styletype) {
                                  setFilterOption({
                                    room_type: [roomtitle.title],
                                    category: [params.category],
                                    style: [params.styletype]
                                  });
                                } else if (roomtitle?.title) {
                                  setFilterOption({
                                    room_type: [roomtitle.title],
                                    category: [params.category]
                                  });
                                }
                              }
                              setBrandClicked(false);
                            }}>
                            <GrClose size={10} style={{ marginLeft: '5px' }} />
                            <span
                              style={{
                                color: 'black',
                                fontFamily: 'Quicksand',
                                fontSize: '14px',
                                marginLeft: '5px',
                                width: '50%'
                              }}>
                              Clear All
                            </span>
                          </button>
                        )}
                    </div>
                    {loading && (
                      <div>
                        <Loader
                          type="ThreeDots"
                          visible={true}
                          color="#000000"
                          height={50}
                          width={50}
                        />
                      </div>
                    )}
                    {!loading && (
                      <>
                        {params.brand ? null : (
                          <div>
                            <Accordion allowZeroExpanded allowMultipleExpanded>
                              <AccordionItem>
                                <AccordionItemHeading
                                  onClick={() => {
                                    setBrandExpanded(!brandExpanded);
                                  }}>
                                  <AccordionItemButton>
                                    <div className="d-flex flex-row justify-content-right align-items-right">
                                      <Grid container>
                                        <Grid item xs={6}>
                                          <p
                                            className={style.filterHead}
                                            style={{ marginBottom: '10px' }}
                                            onClick={() => {
                                              setBrandExpanded(!brandExpanded);
                                            }}>
                                            Brand
                                          </p>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <div>
                                            <FontAwesomeIcon
                                              size={10}
                                              style={{
                                                alignItems: 'center',
                                                marginLeft: '10px'
                                              }}
                                              icon={
                                                brandExpanded === true
                                                  ? faChevronDown
                                                  : faChevronRight
                                              }
                                              onClick={() => {
                                                setBrandExpanded(!brandExpanded);
                                              }}
                                            />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  {/* {console.log('brand', brands)} */}
                                  {brands &&
                                    brands.map((brand) => (
                                      <div className="col p-0" style={{ marginRight: '10px' }}>
                                        <button
                                          className={
                                            filterOption.hasOwnProperty('brand') &&
                                            filterOption.brand === brand
                                              ? style1.listButton1
                                              : style1.listButton
                                          }
                                          style={{
                                            cursor: 'pointer',
                                            fontFamily: 'Quicksand'
                                          }}
                                          onClick={() => {
                                            setTimeout(() => {
                                              if (filterOption.brand == brand) {
                                                setFilterOption(() => {
                                                  let temp = { ...filterOption };
                                                  delete temp.brand;
                                                  return { ...temp };
                                                });
                                                setBrandClicked(false);
                                              } else {
                                                setFilterOption({
                                                  ...filterOption,
                                                  brand: brand
                                                });
                                                setBrandClicked(true);
                                              }
                                            }, 4000);
                                          }}>
                                          {brand}
                                        </button>
                                      </div>
                                    ))}
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        )}

                        <div>
                          <Accordion allowZeroExpanded allowMultipleExpanded>
                            <AccordionItem>
                              <AccordionItemHeading
                                onClick={() => {
                                  setMaterialExpanded(!materialExpanded);
                                }}>
                                <AccordionItemButton>
                                  <div className="d-flex flex-row justify-content-right align-items-right">
                                    <Grid container>
                                      <Grid item xs={6}>
                                        <p
                                          className={style.filterHead}
                                          style={{ marginBottom: '10px' }}
                                          onClick={() => {
                                            setMaterialExpanded(!materialExpanded);
                                          }}>
                                          Material
                                        </p>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <div>
                                          <FontAwesomeIcon
                                            size={10}
                                            style={{
                                              alignItems: 'center',
                                              marginLeft: '10px'
                                            }}
                                            icon={
                                              materialExpanded === true
                                                ? faChevronDown
                                                : faChevronRight
                                            }
                                            onClick={() => {
                                              setMaterialExpanded(!materialExpanded);
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                {material &&
                                  material.map((mt) => (
                                    <div className="col p-0" style={{ marginRight: '10px' }}>
                                      <button
                                        className={
                                          filterOption.hasOwnProperty('material') &&
                                          filterOption.material.includes(mt)
                                            ? style1.listButton1
                                            : style1.listButton
                                        }
                                        style={{
                                          cursor: 'pointer',
                                          fontFamily: 'Quicksand'
                                        }}
                                        onClick={() => {
                                          setTimeout(() => {
                                            if (filterOption.hasOwnProperty('material')) {
                                              let index = filterOption.material.findIndex(
                                                (mtr) => mtr === mt
                                              );
                                              if (index < 0) {
                                                setFilterOption({
                                                  ...filterOption,
                                                  material: [...filterOption.material, mt]
                                                });
                                              } else {
                                                filterOption.material =
                                                  filterOption.material.filter((mtr) => mtr !== mt);
                                                setFilterOption({
                                                  ...filterOption
                                                });
                                              }
                                            } else {
                                              setFilterOption({
                                                ...filterOption,
                                                material: [mt]
                                              });
                                            }
                                          }, 4000);
                                        }}>
                                        {mt}
                                      </button>
                                    </div>
                                  ))}
                              </AccordionItemPanel>
                            </AccordionItem>
                          </Accordion>
                        </div>
                        <div>
                          <Accordion allowZeroExpanded allowMultipleExpanded>
                            <AccordionItem>
                              <AccordionItemHeading
                                onClick={() => {
                                  setColorExpanded(!colorExpanded);
                                }}>
                                <AccordionItemButton>
                                  <div className="d-flex flex-row justify-content-right align-items-right">
                                    <Grid container>
                                      <Grid item xs={6}>
                                        <p
                                          className={style.filterHead}
                                          style={{ marginBottom: '10px' }}
                                          onClick={() => {
                                            setColorExpanded(!colorExpanded);
                                          }}>
                                          Color
                                        </p>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <div>
                                          <FontAwesomeIcon
                                            size={10}
                                            style={{
                                              alignItems: 'center',
                                              marginLeft: '10px'
                                            }}
                                            icon={
                                              colorExpanded === true
                                                ? faChevronDown
                                                : faChevronRight
                                            }
                                            onClick={() => {
                                              setColorExpanded(!colorExpanded);
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                {colors &&
                                  colors.map((clr) => (
                                    <div className="col p-0" style={{ marginRight: '10px' }}>
                                      <button
                                        className={
                                          filterOption.hasOwnProperty('color') &&
                                          filterOption.color.includes(clr)
                                            ? style1.listButton1
                                            : style1.listButton
                                        }
                                        style={{
                                          cursor: 'pointer',
                                          fontFamily: 'Quicksand'
                                        }}
                                        onClick={() => {
                                          setTimeout(() => {
                                            if (filterOption.hasOwnProperty('color')) {
                                              let index = filterOption.color.findIndex(
                                                (cl) => cl === clr
                                              );
                                              if (index < 0) {
                                                setFilterOption({
                                                  ...filterOption,
                                                  color: [...filterOption.color, clr]
                                                });
                                              } else {
                                                filterOption.color = filterOption.color.filter(
                                                  (cl) => cl !== clr
                                                );
                                                setFilterOption({
                                                  ...filterOption
                                                });
                                              }
                                            } else {
                                              setFilterOption({
                                                ...filterOption,
                                                color: [clr]
                                              });
                                            }
                                          }, 4000);
                                        }}>
                                        {clr}
                                      </button>
                                    </div>
                                  ))}
                              </AccordionItemPanel>
                            </AccordionItem>
                          </Accordion>
                        </div>
                        <div className="d-flex m-auto">
                          <div className={style.sliderBox}>
                            <div className="d-flex">
                              <span className={style.sliderBoxInnerValue}>Rs.</span>
                              <input
                                value={budgetFilter[0]}
                                className={style.sliderInput}
                                type="number"
                                placeholder="Min"
                                onChange={(e) => {
                                  setBudgetFilter([e.target.value, budgetFilter[1]]);
                                }}
                              />
                            </div>
                          </div>

                          <div className={style.sliderBox}>
                            <div className="d-flex">
                              <span className={style.sliderBoxInnerValue}>Rs.</span>
                              <input
                                value={budgetFilter[1]}
                                className={style.sliderInput}
                                type="number"
                                placeholder="Max"
                                onChange={(e) => {
                                  setBudgetFilter([budgetFilter[0], e.target.value]);
                                }}
                              />
                            </div>
                          </div>

                          <div>
                            <button className={style.budgetFilterBtn} onClick={handleBudgetFilter}>
                              Go
                            </button>
                          </div>
                        </div>

                        <div className="d-flex flex-row mt-2 hr" style={{ fontSize: '12px' }}>
                          <input
                            type="checkbox"
                            style={{
                              marginTop: '2px',
                              marginRight: '3px',
                              fontFamily: 'quicksand'
                            }}
                            onClick={() => {
                              setLikesChecked(!likesChecked);
                            }}></input>
                          <span>My Likes</span>
                        </div>
                        <div className="d-flex flex-row mt-2 " style={{ fontSize: '12px' }}>
                          <input
                            type="checkbox"
                            style={{
                              marginTop: '2px',
                              marginRight: '3px',
                              fontFamily: 'quicksand'
                            }}
                            onClick={() => {
                              if (!arChecked) {
                                setFilterOption({
                                  ...filterOption,
                                  AR: true
                                });
                                setarChecked(!arChecked);
                              } else {
                                setFilterOption(() => {
                                  let temp = { ...filterOption };
                                  delete temp.AR;
                                  return { ...temp };
                                });
                                setarChecked(!arChecked);
                              }
                            }}
                            checked={arChecked}></input>
                          <span>AR</span>
                        </div>
                      </>
                    )}
                  </div>
                </>
                <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                  {params.category === 'Shop' ? (
                    <div className={style.typesSectionwom}>
                      <div className={classNames(style.roomType, classes.displayrow)}>
                        <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}>
                          {params.category}
                        </div>
                        <div className={classes.displayrow}>
                          <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                          <div style={{ cursor: 'pointer', color: 'gray' }}>{params?.tag}</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={style.typesSectionwom}>
                      {/* <Select
                        value={shopType}
                        onChange={handleChangeshoptype}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className={style.selectStyle}
                        xs={{ '& .MuiSvgIcon-root': { color: 'white' } }}>
                        {shop_type &&
                          shop_type.map((item) => {
                            return <MenuItem value={item.id}>{item.type}</MenuItem>;
                          })}
                      </Select> */}
                      {/* <div className={classNames(style.roomType, classes.displayrow)}> */}
                        {/* <div
                          className={classNames(classes.imgtag, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}
                          onClick={() => {
                            history.push({
                              pathname: `/shop`
                            });
                          }}>
                          Shop
                        </div> */}
                        {/* {params?.styletype && params?.styletype !== 'false' ? (
                          <div
                            className={classNames(classes.displayrow, 'mr-3')}
                            style={{ cursor: 'pointer', color: 'gray' }}
                            onClick={() => {
                              history.replace({
                                pathname: `/shopbystyle/room/true/${params.styletype}`
                              });
                            }}>
                            <img
                              src={arrow}
                              className={classNames(classes.imgtag, 'mr-3')}
                              style={{ color: 'gray' }}
                            />
                            <div>{params?.styletype}</div>
                          </div>
                        ) : null} */}
                        {/* <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}
                          onClick={() => history.goBack()}>
                          <img
                            src={arrow}
                            className={classNames(classes.imgtag, 'mr-3')}
                            style={{ color: 'gray' }}
                          />
                          {shopType && shop_type.find((item) => item.id == shopType)?.type}
                        </div>
                        {params?.category ? (
                          <div
                            className={classNames(classes.displayrow, 'mr-3')}
                            style={{ cursor: 'pointer', color: 'gray' }}>
                            <img
                              src={arrow}
                              className={classNames(classes.imgtag, 'mr-3')}
                              style={{ color: 'gray' }}
                            />
                            <div>{params?.category}</div>
                          </div>
                        ) : null}
                        {params?.brand ? (
                          <div
                            className={classes.displayrow}
                            style={{ cursor: 'pointer', color: 'gray' }}>
                            <img
                              src={arrow}
                              className={classNames(classes.imgtag, 'mr-3')}
                              style={{ color: 'gray' }}
                            />
                            <div>{params?.brand}</div>
                          </div>
                        ) : null} */}
                      {/* </div> */}
                      <div className="row">
                        <ResponsiveMasonry
                          className={style.mesonryRooms}
                          style={{ margin: '0px auto', width: '100%' }}
                          columnsCountBreakPoints={{
                            250: 2,
                            750: 2,
                            900: 4
                          }}>
                          <Masonry>
                            {subcategory?.map((category, i) => (
                              <div style={{ position: 'relative', height: '220px' }} key={i}>
                                <div
                                  className="d-flex flex-column align-items-center justify-content-evenly"
                                  style={{
                                    cursor: 'pointer',
                                    marginBottom: '38px',
                                    textAlign: 'center'
                                  }}
                                  onClick={() => {
                                    setselectedsubcategory(category.title);
                                    productListViewRef.current.scrollIntoView({
                                      behavior: 'smooth'
                                    });
                                  }}>
                                  <div className={style.designIconContainer}>
                                    <img
                                      className={style.designIcon}
                                      src={category.image}
                                      alt="room"
                                      style={{
                                        width: '90%',
                                        border:
                                          category.title == selectedsubcategory
                                            ? '1px solid #DEDEDE'
                                            : 'none'
                                      }}
                                    />
                                  </div>
                                  <p className={classnames('mt-2', style.dataName)}>
                                    {category.title}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </Masonry>
                        </ResponsiveMasonry>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-9" ref={productListViewRef}>
                      <p
                        className="mt-3"
                        style={{
                          cursor: 'pointer',
                          fontFamily: 'Quicksand',
                          fontSize: '20px',
                          textAlign: 'left',
                          fontWeight: 'bold'
                        }}
                        onClick={() => {
                          setBrandClicked(false);
                          setFilterOption({});
                        }}>
                        All Products &nbsp;
                        {page === totalPages
                          ? totalRecords + '/' + totalRecords
                          : productsList?.length === 0
                          ? '0'
                          : limit * page + '/' + totalRecords}
                      </p>
                    </div>
                    <div className="col-3 justify-content-end ">
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={sortby}
                        onChange={handleChangeInput}
                        displayEmpty
                        input={<BootstrapInput />}
                        className={style1.selectSort}>
                        <MenuItem value=""> Sort by </MenuItem>
                        <MenuItem value={'price:desc'}>High to Low</MenuItem>
                        <MenuItem value={'price:asc'}>Low to High</MenuItem>
                        <MenuItem value={'view_count:desc'}>Popularity</MenuItem>
                      </Select>
                    </div>
                  </div>
                  {prodLoading && (
                    <div>
                      <Loader
                        type="ThreeDots"
                        visible={true}
                        color="#000000"
                        height={50}
                        width={50}
                      />
                    </div>
                  )}
                  {!prodLoading && renderColumn()}
                  <ReactPaginate
                    forcePage={0}
                    onPageChange={({ selected }) => {
                      setPage(selected + 1);
                      window.scrollTo(0, 0);
                    }}
                    pageCount={totalPages}
                    breakLabel="..."
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    containerClassName={
                      ' justify-content-end pagination react-paginate pagination-sm p-1 mt-1'
                    }
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    previousLabel={productsList?.length > 0 && '<'}
                    nextLabel={productsList?.length > 0 && '>'}
                    breakClassName={'break-me'}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={style.nav_container} style={{ paddingTop: '80px' }}>
                  <div
                    className="row"
                    style={{
                      backgroundColor: '#F9FEFF',
                      padding: '8px 0px',
                      marginBottom: '16px'
                    }}>
                    <p
                      className="col-4 mb-0 d-flex justify-content-start"
                      style={{ fontWeight: 600, cursor: 'pointer' }}
                      onClick={() => setDrawer(true)}>
                      <img
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Group+3000.png"
                        className={style.toggleImg}
                      />
                      Refine
                    </p>
                    <p className=" col-7 p-0 d-flex justify-content-end">
                      <div className={classNames(style.roomType, classes.displayrow)}>
                        {/* <div
                          className={classNames(classes.imgtag, 'mr-1')}
                          style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}
                          onClick={() => {
                            history.push({
                              pathname: `/shop`
                            });
                          }}>
                          Shop
                        </div> */}
                        {params?.styletype && params?.styletype !== 'false' ? (
                          <div
                            className={classes.displayrow}
                            onClick={() =>
                              history.replace({
                                pathname: `/shopbystyle/room/true/${params.styletype}`
                              })
                            }>
                            {/* <img
                              src={arrow}
                              className={classNames(classes.imgtag, 'mr-3')}
                              style={{ color: 'gray' }}
                            /> */}
                            {/* <div>{params?.styletype}</div> */}
                          </div>
                        ) : null}
                        {/* <img
                          src={arrow}
                          className={classNames(classes.imgtag, 'mr-1')}
                          style={{ color: 'gray' }}
                        /> */}
                        <div
                          className={classNames(classes.displayrow, 'mr-1')}
                          style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}
                          onClick={() => history.goBack()}>
                          {/* {shopType && shop_type.find((item) => item.id == shopType)?.type} */}
                        </div>
                        {params?.category ? (
                          <div className={classes.displayrow}>
                            <img
                              src={arrow}
                              className={classNames(classes.imgtag, 'mr-1')}
                              style={{ color: 'gray' }}
                            />
                            <div style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}>
                              {/* {params?.category} */}
                            </div>
                          </div>
                        ) : null}
                        {params?.brand ? (
                          <div className={classes.displayrow}>
                            <img
                              src={arrow}
                              className={classNames(classes.imgtag, 'mr-3')}
                              style={{ color: 'gray' }}
                            />
                            <div>{params?.brand}</div>
                          </div>
                        ) : null}
                      </div>
                    </p>
                  </div>

                  <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                    <div className={style.typesSectionwom}>
                      {/* <Select
                        value={shopType}
                        onChange={handleChangeshoptype}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className={style.selectStyle}
                        xs={{ '& .MuiSvgIcon-root': { color: 'white' } }}>
                        {shop_type &&
                          shop_type.map((item, index) => {
                            return (
                              <MenuItem key={`shoptype${index}`} value={item.id}>
                                {item.type}
                              </MenuItem>
                            );
                          })}
                      </Select> */}

                      <div className="row mt-5">
                        <ResponsiveMasonry
                          className={style.mesonryRooms}
                          style={{ margin: '0px auto', width: '100%' }}
                          columnsCountBreakPoints={{
                            250: 2,
                            750: 2,
                            900: 4
                          }}>
                          <Masonry>
                            {subcategory?.map((category, i) => (
                              <div style={{ position: 'relative' }} key={i}>
                                <div
                                  className="d-flex flex-column align-items-center justify-content-evenly"
                                  style={{
                                    position: 'relative',
                                    height: '180px',
                                    cursor: 'pointer',
                                    marginBottom: dimension[0] > 600 ? '38px' : '10px',
                                    textAlign: 'center'
                                  }}
                                  onClick={() => {
                                    setselectedsubcategory(category.title);
                                    productListViewRef.current.scrollIntoView({
                                      behavior: 'smooth'
                                    });
                                  }}>
                                  <div className={style.designIconContainer}>
                                    <img
                                      className={style.designIcon}
                                      src={category.image}
                                      alt="room"
                                      style={{
                                        width: '100%',
                                        border:
                                          category.title == selectedsubcategory
                                            ? '1px solid #DEDEDE'
                                            : 'none'
                                      }}
                                    />
                                  </div>
                                  <p className={classnames('mt-2', style.dataName)}>
                                    {category.title}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </Masonry>
                        </ResponsiveMasonry>
                      </div>
                    </div>
                  </div>
                </div>

                <Drawer
                  anchor={'left'}
                  open={drawer}
                  docked={false}
                  onClose={() => setDrawer(false)}>
                  <div className={classnames(style.drawerContainer, ' mb-2 ml-2')}>
                    <>
                      <div className="row mt-3 mb-3 ml-1">
                        <h5
                          style={{
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            fontWeight: 'bold'
                          }}>
                          Filters
                        </h5>
                        <CloseOutlined
                          onClick={() => setDrawer(false)}
                          style={{ marginLeft: 'auto', marginRight: 0 }}
                        />
                        {(filterOption.hasOwnProperty('brand') ||
                          filterOption.hasOwnProperty('material') ||
                          filterOption.hasOwnProperty('color')) &&
                          checkfilterKeys(filterOption) && (
                            <button
                              className={style.filterButton}
                              style={{ height: '26px' }}
                              onClick={() => {
                                setBrandClicked(false);
                                setDrawer(false);
                                if (params?.roomtype) {
                                  let data = [];
                                  data = rooms
                                    .find((item) => item.id == params.roomtype)
                                    ?.category?.find(
                                      (sub) => sub.title == params.category
                                    )?.subcategory;
                                  setsubcategory(data);
                                  let roomtitle = rooms.find((item) => item.id == params.roomtype);
                                  if (params?.roomtype) {
                                    let data = [];
                                    data = rooms
                                      .find((item) => item.id == params.roomtype)
                                      ?.category?.find(
                                        (sub) => sub.title == params.category
                                      )?.subcategory;
                                    setsubcategory(data);
                                    let roomtitle = rooms.find(
                                      (item) => item.id == params.roomtype
                                    );
                                    if (roomtitle?.title && params?.styletype) {
                                      setFilterOption({
                                        room_type: [roomtitle.title],
                                        category: [params.category],
                                        style: [params.styletype]
                                      });
                                    } else if (roomtitle?.title) {
                                      setFilterOption({
                                        room_type: [roomtitle.title],
                                        category: [params.category]
                                      });
                                    }
                                  }
                                }
                              }}>
                              <GrClose size={10} style={{ marginLeft: '5px' }} />
                              <span
                                style={{
                                  color: 'black',
                                  fontFamily: 'Quicksand',
                                  fontSize: '14px',
                                  marginLeft: '5px',
                                  width: '50%'
                                }}>
                                Clear All
                              </span>
                            </button>
                          )}
                      </div>
                      <div>
                        {loading && (
                          <div>
                            <Loader
                              type="ThreeDots"
                              visible={true}
                              color="#000000"
                              height={50}
                              width={50}
                            />
                          </div>
                        )}
                        {!loading && (
                          <>
                            {params.brand ? null : (
                              <div>
                                <Accordion allowZeroExpanded allowMultipleExpanded>
                                  <AccordionItem>
                                    <AccordionItemHeading
                                      onClick={() => {
                                        setBrandExpanded(!brandExpanded);
                                      }}>
                                      <AccordionItemButton>
                                        <div className="d-flex flex-row justify-content-right align-items-right">
                                          <p
                                            className={style.filterHead}
                                            style={{ marginBottom: '10px' }}
                                            onClick={() => {
                                              setBrandExpanded(!brandExpanded);
                                            }}>
                                            Brand
                                          </p>
                                          <div>
                                            <FontAwesomeIcon
                                              size={10}
                                              style={{
                                                alignItems: 'center',
                                                marginLeft: '10px'
                                              }}
                                              icon={
                                                brandExpanded === true
                                                  ? faChevronDown
                                                  : faChevronRight
                                              }
                                              onClick={() => {
                                                setBrandExpanded(!brandExpanded);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      {brands &&
                                        brands.map((brand) => (
                                          <div className="col p-0" style={{ marginRight: '10px' }}>
                                            <button
                                              className={
                                                filterOption.hasOwnProperty('brand') &&
                                                filterOption.brand === brand
                                                  ? style1.listButton1
                                                  : style1.listButton
                                              }
                                              style={{
                                                cursor: 'pointer',
                                                fontFamily: 'Quicksand'
                                              }}
                                              onClick={() => {
                                                // setBrandClicked(true);
                                                // setDrawer(false);
                                                // setFilterOption({
                                                //   ...filterOption,
                                                //   brand: brand,
                                                // });
                                                if (filterOption.brand == brand) {
                                                  setFilterOption(() => {
                                                    let temp = { ...filterOption };
                                                    delete temp.brand;
                                                    return { ...temp };
                                                  });
                                                  setBrandClicked(false);
                                                } else {
                                                  setFilterOption({
                                                    ...filterOption,
                                                    brand: brand
                                                  });
                                                  setBrandClicked(false);
                                                }
                                                setDrawer(false);
                                              }}>
                                              {brand}
                                            </button>
                                          </div>
                                        ))}
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </Accordion>
                              </div>
                            )}
                            <div>
                              <Accordion allowZeroExpanded allowMultipleExpanded>
                                <AccordionItem>
                                  <AccordionItemHeading
                                    onClick={() => {
                                      setMaterialExpanded(!materialExpanded);
                                    }}>
                                    <AccordionItemButton>
                                      <div className="d-flex flex-row justify-content-right align-items-right">
                                        <p
                                          className={style.filterHead}
                                          style={{ marginBottom: '10px' }}
                                          onClick={() => {
                                            setMaterialExpanded(!materialExpanded);
                                          }}>
                                          Material
                                        </p>
                                        <div>
                                          <FontAwesomeIcon
                                            size={10}
                                            style={{
                                              alignItems: 'center',
                                              marginLeft: '10px'
                                            }}
                                            icon={
                                              materialExpanded === true
                                                ? faChevronDown
                                                : faChevronRight
                                            }
                                            onClick={() => {
                                              setMaterialExpanded(!materialExpanded);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    {material &&
                                      material.map((mt) => (
                                        <div className="col p-0" style={{ marginRight: '10px' }}>
                                          <button
                                            className={
                                              filterOption.hasOwnProperty('material') &&
                                              filterOption.material.includes(mt)
                                                ? style1.listButton1
                                                : style1.listButton
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              fontFamily: 'Quicksand'
                                            }}
                                            onClick={() => {
                                              if (filterOption.hasOwnProperty('material')) {
                                                let index = filterOption.material.findIndex(
                                                  (mtr) => mtr === mt
                                                );
                                                if (index < 0) {
                                                  setFilterOption({
                                                    ...filterOption,
                                                    material: [...filterOption.material, mt]
                                                  });
                                                } else {
                                                  filterOption.material =
                                                    filterOption.material.filter(
                                                      (mtr) => mtr !== mt
                                                    );
                                                  setFilterOption({
                                                    ...filterOption
                                                  });
                                                }
                                              } else {
                                                setFilterOption({
                                                  ...filterOption,
                                                  material: [mt]
                                                });
                                              }
                                            }}>
                                            {mt}
                                          </button>
                                        </div>
                                      ))}
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </Accordion>
                            </div>
                            <div>
                              <Accordion allowZeroExpanded allowMultipleExpanded>
                                <AccordionItem>
                                  <AccordionItemHeading
                                    onClick={() => {
                                      setBrandExpanded(!brandExpanded);
                                    }}>
                                    <AccordionItemButton>
                                      <div className="d-flex flex-row justify-content-right align-items-right">
                                        <p
                                          className={style.filterHead}
                                          style={{ marginBottom: '10px' }}
                                          onClick={() => {
                                            setColorExpanded(!colorExpanded);
                                          }}>
                                          Color
                                        </p>
                                        <div>
                                          <FontAwesomeIcon
                                            size={10}
                                            style={{
                                              alignItems: 'center',
                                              marginLeft: '10px'
                                            }}
                                            icon={
                                              colorExpanded === true
                                                ? faChevronDown
                                                : faChevronRight
                                            }
                                            onClick={() => {
                                              setColorExpanded(!colorExpanded);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    {colors &&
                                      colors.map((clr, index) => (
                                        <div
                                          key={`color${index}`}
                                          className="col p-0"
                                          style={{ marginRight: '10px' }}>
                                          <button
                                            className={
                                              filterOption.hasOwnProperty('color') &&
                                              filterOption.color.includes(clr)
                                                ? style1.listButton1
                                                : style1.listButton
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              fontFamily: 'Quicksand'
                                            }}
                                            onClick={() => {
                                              if (filterOption.hasOwnProperty('color')) {
                                                let index = filterOption.color.findIndex(
                                                  (cl) => cl === clr
                                                );
                                                if (index < 0) {
                                                  setFilterOption({
                                                    ...filterOption,
                                                    color: [...filterOption.color, clr]
                                                  });
                                                } else {
                                                  filterOption.color = filterOption.color.filter(
                                                    (cl) => cl !== clr
                                                  );
                                                  setFilterOption({
                                                    ...filterOption
                                                  });
                                                }
                                              } else {
                                                setFilterOption({
                                                  ...filterOption,
                                                  color: [clr]
                                                });
                                              }
                                            }}>
                                            {clr}
                                          </button>
                                        </div>
                                      ))}
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </Accordion>
                            </div>
                            <div className="d-flex m-auto">
                              <div className={style.sliderBox}>
                                <div className="d-flex">
                                  <span className={style.sliderBoxInnerValue}>Rs.</span>
                                  <input
                                    value={budgetFilter[0]}
                                    className={style.sliderInput}
                                    type="number"
                                    placeholder="Min"
                                    onChange={(e) => {
                                      setBudgetFilter([e.target.value, budgetFilter[1]]);
                                    }}
                                  />
                                </div>
                              </div>

                              <div className={style.sliderBox}>
                                <div className="d-flex">
                                  <span className={style.sliderBoxInnerValue}>Rs.</span>
                                  <input
                                    value={budgetFilter[1]}
                                    className={style.sliderInput}
                                    type="number"
                                    placeholder="Max"
                                    onChange={(e) => {
                                      setBudgetFilter([budgetFilter[0], e.target.value]);
                                    }}
                                  />
                                </div>
                              </div>

                              <div>
                                <button
                                  className={style.budgetFilterBtn}
                                  onClick={handleBudgetFilter}>
                                  Go
                                </button>
                              </div>
                            </div>
                            <div className="d-flex flex-row mt-2" style={{ fontSize: '12px' }}>
                              <input
                                type="checkbox"
                                style={{
                                  marginTop: '2px',
                                  marginRight: '3px'
                                }}
                                onClick={() => {
                                  setLikesChecked(!likesChecked);
                                }}></input>
                              <span>My Likes</span>
                            </div>
                            <div className="d-flex flex-row mt-2 " style={{ fontSize: '12px' }}>
                              <input
                                type="checkbox"
                                style={{
                                  marginTop: '2px',
                                  marginRight: '3px',
                                  fontFamily: 'quicksand'
                                }}
                                onClick={() => {
                                  if (!arChecked) {
                                    setFilterOption({
                                      ...filterOption,
                                      AR: true
                                    });
                                    setarChecked(!arChecked);
                                  } else {
                                    setFilterOption(() => {
                                      let temp = { ...filterOption };
                                      delete temp.AR;
                                      return { ...temp };
                                    });
                                    setarChecked(!arChecked);
                                  }
                                }}
                                checked={arChecked}></input>
                              <span>AR</span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </div>
                </Drawer>
                <div className="row">
                  <div className="col-sm-12 col-xs-12">
                    <div className="row" style={{ marginTop: dimension[0] < 600 ? '25px' : '' }}>
                      <div className="col-6" ref={productListViewRef}>
                        <p
                          className={classnames(style.product, 'mt-1')}
                          style={{
                            cursor: 'pointer',
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 'bold'
                          }}
                          onClick={() => {
                            setBrandClicked(false);
                            setFilterOption({});
                          }}>
                          All Products&nbsp;
                          {page === totalPages
                            ? totalRecords + '/' + totalRecords
                            : productsList?.length === 0
                            ? '0'
                            : limit * page + '/' + totalRecords}
                        </p>
                      </div>
                      <div className="col-6 justify-content-end ">
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={sortby}
                          onChange={handleChangeInput}
                          displayEmpty
                          input={<BootstrapInput />}
                          className={style1.selectSort}>
                          <MenuItem value=""> Sort by</MenuItem>
                          <MenuItem value={'price:desc'}>High to Low</MenuItem>
                          <MenuItem value={'price:asc'}>Low to High</MenuItem>
                          <MenuItem value={'view_count:desc'}>Popularity</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div className="container-fluid ml-lg-3 pl-lg-2 ml-md-3 pl-md-2">
                      {prodLoading && (
                        <div>
                          <Loader
                            type="ThreeDots"
                            visible={true}
                            color="#000000"
                            height={50}
                            width={50}
                          />
                        </div>
                      )}
                      {!prodLoading && renderColumn()}
                      <ReactPaginate
                        forcePage={0}
                        onPageChange={({ selected }) => {
                          setPage(selected + 1);
                          window.scrollTo(0, 0);
                        }}
                        pageCount={totalPages}
                        breakLabel="..."
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        containerClassName={
                          ' justify-content-center pagination react-paginate pagination-sm p-1 mt-1'
                        }
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        previousLabel={'<'}
                        nextLabel={'>'}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <Footer />
        </div>
      </>
      {modalOpen && <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(ProductSearchList));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
