import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Shoppage.module.css';
import FilterBadge from '../../components/badge/FilterBadge';
import Slider from '../../components/slider/Slider';
import PnMButton from '../../components/button/PnMButton';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import customerApiInstance from '../../utils/CustomerApiInstance';
import getCartDataCount from '../../utils/CartDetails';
import { storeCartCount } from '../../store/actions/sampleaction';
import classnames from 'classnames';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import RoomCard from '../../components/cards/RoomCard';
import { rooms } from './constant';
import { openin } from '../../utils/utils';
import axios from 'axios';
import ProductCard from '../../components/cards/ProductCard';
import Carousel from '../../components/carousel/Carousel';
import illustration from '../../assets/homepage/illustration.png';
import illustrationMob from '../../assets/homepage/illustrationMob.png';
import Snackbar from '@material-ui/core/Snackbar';
import QRScanner from '../../components/layout/QrScanner';
import ShopbyVendor from './ShopbyBrand';
import Loader from 'react-loader-spinner';
import CuratedDesignsMasonry from '../../components/Masonry/CuratedDesignsMasonry';

const roomsFilter = [
  { id: 1, value: 'living_room', type: 'Living Room' },
  { id: 2, value: 'dining_room', type: 'Dining Room' },
  { id: 3, value: 'bed_room', type: 'Bed Room' },
  { id: 4, value: 'kids_room', type: 'Kids Room' },
  { id: 5, value: 'puja_room', type: 'Puja Room' },
  { id: 6, value: 'foyer', type: 'Foyer' },
  { id: 7, value: 'balcony', type: 'Balcony' },
  {
    id: 8,
    value: 'study_entertainment_room',
    type: 'Study Entertainment Room'
  }
];

function ShopPage({ history, auth, storeCartCount }) {
  const dimension = useMediaQuery();
  const [value, setValue] = useState([0, 150000]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [bannerlist, setbannerlist] = useState([]);
  const [newArrivalProducts, setNewArrivalProducts] = useState([]);
  const [bestSellersProducts, setBestSellersProducts] = useState([]);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();
  const [applyBtnDisabled, setApplyBtnDisabled] = useState(false);
  const [prodLoading, setProdLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moodBoards, setMoodBoards] = useState([]);

  useEffect(() => {
    getMasonryMoodboardsList();
  }, []);

  const getMasonryMoodboardsList = async () => {
    setLoading(true);
    try {
      let result = [];
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/explore?tags[]=Designer Pick&Page=1`
      );
      result = res.data.results;
      setMoodBoards(result);
      setLoading(false);
    } catch (err) {
      //ConsoleHelper('error', err);
      setLoading(false);
      setMoodBoards([]);
    }
  };

  //temp code to mock banner data
  // const [moodBoard, setMoodBoard] = useState(null);
  // console.log(moodBoard);
  // useEffect(() => {
  //   const id = '64c2373ba3679f0012254551';
  //   const fetchData = async () => {
  //     try {
  //       const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/${id}`);
  //       console.log(res);
  //       setMoodBoard(res.data.moodBoard);
  //       // setRooms(res1.data.room_3d[0]);
  //       // setProducts([...res1.data.moodBoardProducts]);
  //       // setTotalPrice(res1.data.totalPrice);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // const renderBannerHotspot = (content) => {
  //   if (Object.keys(content).length > 0) {
  //     const hotspot = content
  //       .filter((cont, index) => {
  //         return Object.keys(cont).includes('productId') && cont.productId != '';
  //       })
  //       .map((filteredContent, index) => (
  //         <div
  //           key={index}
  //           className={style.bannerHotspot}
  //           style={{
  //             top: filteredContent.top + (filteredContent.height * filteredContent.scaleY) / 2,
  //             left: filteredContent.left + (filteredContent.width * filteredContent.scaleX) / 2
  //           }}></div>
  //       ));
  //     return hotspot;
  //   } else {
  //     return null;
  //   }
  // };
  useEffect(() => {
    const getbanners = async () => {
      const res = await axios.get(`${process.env.REACT_APP_CMS_URL}/api/v1/user/genricbanner`);
      if (res.status == 200 && res?.data?.data?.length > 0) {
        let responsedata = res.data.data;
        responsedata.sort(function (a, b) {
          if (a.bannername < b.bannername) {
            return -1;
          }
          if (a.bannername > b.bannername) {
            return 1;
          }
          return 0;
        });
        setbannerlist([...responsedata]);
      } else {
        setbannerlist([]);
      }
    };
    getbanners();
  }, []);

  useEffect(() => {
    const getNewArrivalProducts = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
          params: { page: 1, tags: 'New Arrival' }
        });
        if (res?.status == 200) {
          setNewArrivalProducts(res?.data?.results);
        } else {
          setNewArrivalProducts([]);
        }
      } catch (err) {
        console.log(err);
        setNewArrivalProducts([]);
      }
    };

    getNewArrivalProducts();
  }, []);

  useEffect(() => {
    const getBestSellersProducts = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
          params: { page: 1, tags: 'Best Seller' }
        });
        if (res?.status == 200) {
          setBestSellersProducts(res?.data?.results);
        } else {
          setBestSellersProducts([]);
        }
      } catch (err) {
        console.log(err);
        setBestSellersProducts([]);
      }
    };

    getBestSellersProducts();
  }, []);

  useEffect(() => {
    const fetchFavouriteData = async () => {
      try {
        if (auth && auth.user) {
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
          );
          let savedIds = [];

          if (res.data.favourites.length !== 0) {
            res.data.favourites.map((dat) => {
              savedIds = dat.products.map((m) => {
                return m.product_id;
              });
            });
            setSavedProductIds([...savedIds]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFavouriteData();
  }, []);

  const addToCart = async (productData) => {
    let userId;
    let prodBody;

    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: 1,
              rate: productData.price || productData.pixel_price,
              total_price: 1 * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
        setSnack(true);
          setSnackMessage('Product added to cart successfully');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };
  const buyNow = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: 1,
              rate: productData.price || productData.pixel_price,
              total_price: 1 * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push('/cart');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
        history.push('/cart');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const addToFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = (product) => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds((prev) => [...prev, product._id]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter((filter) => filter !== product._id));
      removeFromFavourites(product);
    }
  };

  const handleQr = (productIds) => {
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = (productIds) => {
    history.push(`/3d-model/${productIds}`);
  };

  function openinnewtab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {modalOpen && <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />}
      <div className={style.shopMainCont}>
        {/* <div className={style.filterCont}>
          <div className={style.filterInnerCont}>
            <div style={{ flex: '1.5' }} className="p-2">
              <div className={style.filterInnerHeading}>What's your dream space?</div>
              <div className={style.badgeContainer}>
                {roomsFilter.map((room, index) => (
                  <FilterBadge
                    key={index}
                    name={room.type}
                    active={selectedRoom?.type == room.type}
                    hover={true}
                    onClick={() => {
                      setSelectedRoom(selectedRoom?.type == room.type ? null : room);
                    }}
                  />
                ))}
              </div>
            </div>
            {dimension[0] > 600 && (
              <>
                <div style={{ flex: '1' }} className={classnames(style.budgetContainer, 'p-2')}>
                  <div
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      color: '#171717',
                      marginBottom: '1rem'
                    }}>
                    SET YOUR BUDGET
                  </div>
                  <div>
                    <Slider
                      value={value}
                      handleChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      disabled={selectedRoom == null}
                    />
                    <div className="d-flex">
                      <div className={style.sliderBox}>
                        <p className={style.sliderBoxInner}>Min.</p>
                        <div className="d-flex">
                          <span className={style.sliderBoxInnerValue}>Rs.</span>
                          <input
                            value={value[0]}
                            className={style.sliderInput}
                            type="number"
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) >= 0 &&
                                parseInt(e.target.value) <= 150000
                              ) {
                                setValue([parseInt(e.target.value), value[1]]);
                                setApplyBtnDisabled(false);
                              } else {
                                setValue([e.target.value, value[1]]);
                                setApplyBtnDisabled(true);
                                setSnack(true);
                                setSnackMessage('Please enter value between 0 - 1.5 lakh');
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className={style.sliderBox}>
                        <p className={style.sliderBoxInner}>Max.</p>
                        <div className="d-flex">
                          <span className={style.sliderBoxInnerValue}>Rs.</span>
                          <input
                            value={value[1]}
                            type="number"
                            className={style.sliderInput}
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) >= 0 &&
                                parseInt(e.target.value) <= 150000
                              ) {
                                setValue([value[0], parseInt(e.target.value)]);
                                setApplyBtnDisabled(false);
                              } else {
                                setValue([value[0], e.target.value]);
                                setApplyBtnDisabled(true);
                                setSnack(true);
                                setSnackMessage('Please enter value between 0 - 1.5 lakh');
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: '1' }} className={classnames(style.filterBtnCont, 'p-2')}>
                  <PnMButton
                    type={'outlined'}
                    name={'Clear All'}
                    size={'sm'}
                    sx={{ marginRight: '10px' }}
                    onClick={() => {
                      setValue([0, 150000]);
                      setSelectedRoom(null);
                    }}
                    disabled={selectedRoom == null}
                  />
                  <PnMButton
                    type={'filled'}
                    name={'Apply'}
                    size={'sm'}
                    onClick={() =>
                      history.push(
                        // history.push(
                        {
                          pathname: '/mainproduct',
                          state: {
                            room_type: selectedRoom.type,
                            price_from: value[0],
                            price_to: value[1]
                          }
                        }
                        // `/product_list_budget/${selectedRoom.id}/budget=min:${value[0]}&max:${value[1]}`
                        // )
                      )
                    }
                    disabled={selectedRoom == null || applyBtnDisabled}
                  />
                </div>
              </>
            )}
            {dimension[0] < 600 && selectedRoom != null && (
              <>
                <div
                  style={{ flex: '1' }}
                  className={classnames(style.budgetContainer, 'pl-2 pr-2')}>
                  <div
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      color: '#171717',
                      marginBottom: '1rem'
                    }}>
                    SET YOUR BUDGET
                  </div>
                  <div>
                    <Slider
                      value={value}
                      handleChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      disabled={selectedRoom == null}
                    />
                    <div className="d-flex">
                      <div className={style.sliderBox}>
                        <p className={style.sliderBoxInner}>Min.</p>
                        <div className="d-flex">
                          <span className={style.sliderBoxInnerValue}>Rs.</span>
                          <input
                            value={value[0]}
                            className={style.sliderInput}
                            type="number"
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) >= 0 &&
                                parseInt(e.target.value) <= 150000
                              ) {
                                setValue([parseInt(e.target.value), value[1]]);
                                setApplyBtnDisabled(false);
                              } else {
                                setValue([e.target.value, value[1]]);
                                setApplyBtnDisabled(true);
                                setSnack(true);
                                setSnackMessage('Please enter value between 0 - 1.5 lakh');
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className={style.sliderBox}>
                        <p className={style.sliderBoxInner}>Max.</p>
                        <div className="d-flex">
                          <span className={style.sliderBoxInnerValue}>Rs.</span>
                          <input
                            value={value[1]}
                            type="number"
                            className={style.sliderInput}
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) >= 0 &&
                                parseInt(e.target.value) <= 150000
                              ) {
                                setValue([value[0], parseInt(e.target.value)]);
                                setApplyBtnDisabled(false);
                              } else {
                                setValue([value[0], e.target.value]);
                                setApplyBtnDisabled(true);
                                setSnack(true);
                                setSnackMessage('Please enter value between 0 - 1.5 lakh');
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: '1' }} className={classnames(style.filterBtnCont, 'pl-2 pr-2')}>
                  <PnMButton
                    type={'outlined'}
                    name={'Clear All'}
                    size={'sm'}
                    sx={{ marginRight: '6px' }}
                    onClick={() => {
                      setValue([0, 150000]);
                      setSelectedRoom(null);
                    }}
                    disabled={selectedRoom == null}
                  />
                  <PnMButton
                    type={'filled'}
                    name={'Apply'}
                    size={'sm'}
                    onClick={() =>
                      history.push(
                        {
                          pathname: '/mainproduct',
                          state: {
                            room_type: selectedRoom.type,
                            price_from: value[0],
                            price_to: value[1]
                          }
                        }
                        // history.push(
                        //   `/product_list_budget/${selectedRoom.id}/budget=min:${value[0]}&max:${value[1]}`
                        // )
                      )
                    }
                    disabled={selectedRoom == null || applyBtnDisabled}
                  />
                </div>
              </>
            )}
          </div>
        </div> */}
        <div className={style.shopCont2}>
          {selectedRoom == null && (
            <div className={style.categoryContainer}>
              <p className="text-center" style={{ fontSize: '28px', fontWeight: '700' }}>
                Shop by categories
              </p>
              <ResponsiveMasonry
                className={style.mesonryRooms}
                style={{ margin: '0px auto', width: '100%' }}
                columnsCountBreakPoints={{
                  200: 1,
                  300: 2,
                  500: 3,
                  750: 4,
                  900: 5,
                  1200: 6,
                  1400: 7,
                  1600: 8
                }}>
                <Masonry gutter={'1rem'}>
                  {rooms.map((room, index) => (
                    <div className="d-flex justify-content-center w-100 " key={index}>
                      <RoomCard
                        bg={'#ffffff'}
                        title={room.name}
                        height={'150px'}
                        width={'150px'}
                        image={room.image}
                        hoverImage={room.hoverImage}
                        hover={true}
                        onClick={() => history.push(`/shop-details/${room.title}/false`)}
                      />
                    </div>
                  ))}
                  <div
                    className="d-flex justify-content-center w-100 "
                    style={{ minWidth: '10px' }}>
                    <RoomCard
                      bg={'#ffffff'}
                      title={'View All'}
                      height={'150px'}
                      width={'150px'}
                      image={
                        'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Frame+1171281391g.png'
                      }
                      hoverImage={
                        'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Frame+1171281391.png'
                      }
                      hover={true}
                      onClick={() => history.push(`/categories`)}
                    />
                  </div>
                </Masonry>
              </ResponsiveMasonry>
            </div>
          )}
          {/* Old banner code (do not remove for now) */}
          {/* <div className={style.bannerCont}>
            <img
              src={
                bannerlist[2]?.img_url
                  ? bannerlist[2].img_url
                  : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/shutterstock_496612132.png'
              }
              className={style.bannerImage}
            />
            <div className={style.bannerContent}>
              <p className={style.bannerText}>
                {bannerlist[2]?.content
                  ? bannerlist[2].content
                  : `Get interiors
                designed online
                with the power of A.R.`}
              </p>
              <PnMButton
                type={'filled'}
                name={'View Products'}
                size={'sm'}
                sx={{ backgroundColor: '#ffffff', color: '#000000' }}
                onClick={() => bannerlist[2]?.routing_url && openin(bannerlist[2].routing_url)}
              />
            </div>
          </div> */}
          {/* New banner code with hotspot */}
          {/* <div className={style.bannerCont}>
            <img
             
              src={moodBoard?.preview_url}
              className={style.bannerImage}
            />
           

            {moodBoard && renderBannerHotspot(moodBoard?.content)}

            <div className={style.bannerContent}>
              <p className={style.bannerText}>
                {bannerlist[2]?.content
                  ? bannerlist[2].content
                  : `Get interiors
                designed online
                with the power of A.R.`}
              </p>
              <PnMButton
                type={'filled'}
                name={'View Products'}
                size={'sm'}
                sx={{ backgroundColor: '#ffffff', color: '#000000' }}
                onClick={() => bannerlist[2]?.routing_url && openin(bannerlist[2].routing_url)}
              />
            </div>
          </div> */}
          <div className={style.newArrivalContainer}>
            <p className="text-center" style={{ fontSize: '28px', fontWeight: '700' }}>
              New Arrivals
            </p>
            <div style={{ width: '100%' }}>
              {newArrivalProducts.length > 0 && (
                <Carousel>
                  {newArrivalProducts.map((data, index) => (
                    <ProductCard
                      key={index}
                      data={data}
                      heart={auth && auth.user}
                      heartIsActive={savedProductIds?.includes(data._id)}
                      arIcon={data && data['3d_models']?.glbFileUrl}
                      tag={'NEW LAUNCH'}
                      vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                      productImage={data.image[0]}
                      productName={data.name}
                      price={data.price}
                      pnmPrice={data.pixel_price}
                      onClickCart={() => addToCart(data)}
                      onClickBuyNow={() => buyNow(data)}
                      onClickHeart={() => handleFavourites(data)}
                      onClicAr={() =>
                        dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                      }
                      viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                      sx={{ margin: 'auto' }}
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
        {/* <div className={style.shopCont3}>
            YET TO BE DEVELOPED
        </div> */}
        <div className={style.shopCont4}>
          <div className={style.moodboardCont}>
            <p className={style.moodboardContTitle}>Shop from Trending Designs</p>
            {/* <p className={style.moodboardContSubTitle}>
              A collection of curated designs for the home.
            </p> */}
            {moodBoards.length == 0 &&
              (loading ? (
                <div className="d-flex justify-content-center mt-4 w-100">
                  <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-4 w-100">
                  No Moodboards available
                </div>
              ))}
            {/*Curated design for web and tab */}
            {moodBoards.length > 0 && !loading && dimension[0] > 600 && (
              <CuratedDesignsMasonry
                moodboardsArr={moodBoards}
                columns={3}
                gap={'25px'}
                autoScroll={true}
                homePage={true}
              />
            )}
            {/*Curated design for mobile */}
            {moodBoards.length > 0 && !loading && dimension[0] < 600 && (
              <CuratedDesignsMasonry
                moodboardsArr={moodBoards}
                columns={2}
                gap={'20px'}
                autoScroll={true}
                homePage={true}
              />
            )}
          </div>
          <div className={style.bestSellersContainer}>
            <p className="text-center" style={{ fontSize: '28px', fontWeight: '700' }}>
              Top Selling Products
            </p>
            <div style={{ width: '100%' }}>
              {bestSellersProducts.length > 0 && (
                <Carousel>
                  {bestSellersProducts.map((data, index) => (
                    <ProductCard
                      key={index}
                      data={data}
                      heart={auth && auth.user}
                      heartIsActive={savedProductIds?.includes(data._id)}
                      arIcon={data && data['3d_models']?.glbFileUrl}
                      tag={'BEST SELLERS'}
                      vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                      productImage={data.image[0]}
                      productName={data.name}
                      price={data.price}
                      pnmPrice={data.pixel_price}
                      onClickCart={() => addToCart(data)}
                      onClickBuyNow={() => buyNow(data)}
                      onClickHeart={() => handleFavourites(data)}
                      onClicAr={() =>
                        dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                      }
                      viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                      sx={{ margin: 'auto' }}
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
        <ShopbyVendor loading={prodLoading} categories={categories} rooms={rooms} />
        {/*Hire designer banner for web screen starts*/}
        <div className={classnames('row w-80', style.hireDesignerBanner)}>
          <div
            className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end h-100"
            style={{ position: 'relative' }}>
            <img src={illustration} style={{ position: 'absolute', bottom: '0', right: '40px' }} />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
            <p style={{ fontWeight: '700', fontSize: '28px', lineHeight: '1' }} className="mb-1">
              {' '}
              Work with our designers
            </p>
            <p style={{ fontWeight: '500', fontSize: '18px' }} className="mb-2">
              {' '}
              to create your space.
            </p>
            <PnMButton
              type={'filled'}
              name={'Hire a Designer'}
              onClick={() => history.push('/hire-designer')}
            />
          </div>
        </div>
        {/*Hire designer banner for web screen ends*/}
        {/*Hire designer banner for mob screen starts*/}
        <div
          className={style.hireDesignerBannerMob}
          style={{ backgroundImage: `url(${illustrationMob})` }}>
          <p style={{ fontWeight: '700', fontSize: '28px', lineHeight: '1' }} className="mb-2 mt-4">
            {' '}
            Work with our designers
          </p>
          <p style={{ fontWeight: '500', fontSize: '18px' }} className="mb-4">
            {' '}
            to create your space.
          </p>
          <PnMButton
            type={'filled'}
            name={'Hire a Designer'}
            onClick={() => history.push('/hire-designer')}
          />
        </div>
        {/*Hire designer banner for mob screen ends*/}
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(ShopPage));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
