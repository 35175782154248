import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Review.module.css';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import Rating from 'react-rating';
import { Carousel } from 'react-responsive-carousel';
import { storeMx, storeMxAns } from '../../store/actions/sampleaction';
import 'react-multi-carousel/lib/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

const Reviews = ({ history, auth, storeMx, storeMxAns }) => {
  const dimentions = useMediaQuery();

  const reviews = [
    {
      name: 'Anusha',
      title: 'Great Designer',
      content:
        'P&M did an amazing job!! They listened to my vision and brought it to life. They are very responsive and accommodating. P&M stayed within budget and gave honest feedback about my ideas. It was absolutely amazing to work with and I will definitely work with them again!',
      conclusion: 'Took Classic Package'
    },
    {
      name: 'Anusha',
      title: 'Great Designer',
      content:
        'P&M did an amazing job!! They listened to my vision and brought it to life. They are very responsive and accommodating. P&M stayed within budget and gave honest feedback about my ideas. It was absolutely amazing to work with and I will definitely work with them again!',
      conclusion: 'Took Classic Package'
    },
    {
      name: 'Anusha',
      title: 'Great Designer',
      content:
        'P&M did an amazing job!! They listened to my vision and brought it to life. They are very responsive and accommodating. P&M stayed within budget and gave honest feedback about my ideas. It was absolutely amazing to work with and I will definitely work with them again!',
      conclusion: 'Took Classic Package'
    },
    {
      name: 'Anusha',
      title: 'Great Designer',
      content:
        'P&M did an amazing job!! They listened to my vision and brought it to life. They are very responsive and accommodating. P&M stayed within budget and gave honest feedback about my ideas. It was absolutely amazing to work with and I will definitely work with them again!',
      conclusion: 'Took Classic Package'
    },
    {
      name: 'Anusha',
      title: 'Great Designer',
      content:
        'P&M did an amazing job!! They listened to my vision and brought it to life. They are very responsive and accommodating. P&M stayed within budget and gave honest feedback about my ideas. It was absolutely amazing to work with and I will definitely work with them again!',
      conclusion: 'Took Classic Package'
    },
    {
      name: 'Anusha',
      title: 'Great Designer',
      content:
        'P&M did an amazing job!! They listened to my vision and brought it to life. They are very responsive and accommodating. P&M stayed within budget and gave honest feedback about my ideas. It was absolutely amazing to work with and I will definitely work with them again!',
      conclusion: 'Took Classic Package'
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className={style.container}>
        {dimentions[0] > 500 ? (
          <div className={style.wholeReview}>
            <h2 className={style.reviewHeader} style={{ fontWeight: 400 }}>
              Pixel & Mortar Reviews <br></br>
              from Real Customers
            </h2>
            <h6 className={style.subText}>
              Check out reviews from past customers about their<br></br> P&M experience.
            </h6>
          </div>
        ) : (
          <div className={style.wholeBannerPart}>
            <img
              src="https://d3izcfql7lesks.cloudfront.net/Group_2947_rqo8h9.png"
              className={style.imageBanner}
              alt="project image"
            />

            <div className={style.wholeTxtPart}>
              <h2 className={style.reviewHeader} style={{ fontWeight: 400 }}>
                Pixel & Mortar Reviews <br></br>
                from Real Customers
              </h2>
              <h6 className={style.subText}>
                Check out reviews from past customers about their<br></br> P&M experience.
              </h6>
            </div>
          </div>
        )}
      </div>
      <div className={classnames('container mt-md-5 mt-3', style.mw)}>
        <div className="row justify-content-end align-items-center" style={{ marginRight: '10%' }}>
          <p className={style.sort}>Sort by</p>
          <br></br>
          <select className={style.selectSort}>
            <option>New to old</option>
            <option>Packages</option>
            <option>Rating</option>
          </select>
          {/* <DropdownButton id="dropdown-basic-button" title="Sort By" className={style.dropdown}>
                    <Dropdown.Item href="#/action-1">New to Old</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Packages</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Rating</Dropdown.Item>
                </DropdownButton> */}
        </div>
        <div className="row d-flex justify-content-center align-items-center mt-5">
          {reviews.map((review, index) => (
            <div className={classnames('col-xs', style.reviewContainer)}>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className={classnames('d-flex flex-column text-left', style.coloums)}>
                  <span className={style.name}>{review.name}</span>
                  <Rating
                    style={{ marginLeft: '-10%' }}
                    emptySymbol={
                      <img
                        alt=""
                        src="https://d3izcfql7lesks.cloudfront.net/Path_1252_ixllee.svg"
                        className="pl-1"
                      />
                    }
                    fullSymbol={
                      <img
                        alt=""
                        src="https://d3izcfql7lesks.cloudfront.net/Path_1252_dp7bmx.svg"
                        className="pl-1"
                      />
                    }
                  />
                </div>

                <span style={{ textDecoration: 'underline', marginTop: '-4%' }}>
                  <img
                    alt=""
                    className={style.waveImg}
                    style={{ width: '38px', paddingRight: '5px' }}
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/review+page+photo.svg"
                  />
                  <span className={style.viewProject}>View Project</span>
                </span>
              </div>
              <div className={style.cardContent}>
                <h5 className={style.reviewTitle} style={{ color: '#EAB146' }}>
                  <b>{review.title}</b>
                </h5>
                <span style={{ fontSize: '13px' }}>{review.content}</span>
              </div>
              <h6 className={style.packageTxt}>{review.conclusion}</h6>
            </div>
          ))}
          <div className={classnames('col-xs', style.pagesPart)}>
            <Pagination className={style.pagination}>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{10}</Pagination.Item>
            </Pagination>
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center mt-5"></div>
      </div>

      {dimentions[0] < 500 && (
        <div>
          <img
            alt=""
            src="https://d3izcfql7lesks.cloudfront.net/Group_2948_f7urh6_myf3bq.png"
            style={{ width: '100%' }}></img>
        </div>
      )}
      <div className={style.container2}>
        <div className={style.subContainer2}>
          <h2 className={style.spaceTxt} style={{ fontWeight: 400 }}>
            From ordinary spaces
            <br></br>
            to happy places.
          </h2>
          <button
            className={style.designButton}
            onClick={() => {
              storeMx({});
              storeMxAns({});
              history.push(
                auth.isAuthenticated
                  ? '/questionnaire'
                  : history.push({
                      pathname: '/signup',
                      state: { redirect: '/questionnaire' }
                    })
              );
            }}>
            See Design
          </button>
        </div>
      </div>
      {dimentions[0] < 500 && (
        <div style={{ width: '580px', margin: 'auto' }}>
          <Carousel
            autoPlay={false}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            swipeable={true}
            swipeScrollTolerance={0}
            emulateTouch={true}
            interval={2000}
            stopOnHover={true}
            infiniteLoop={true}>
            <div
              className="d-flex flex-row justify-content-around align-items-end"
              style={{ height: '250px' }}
              onClick={() => {
                storeMxAns({});
                storeMx({});
                history.push({
                  pathname: '/signup',
                  state: { redirect: '/questionnaire' }
                });
              }}>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Living+1.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Boho</p>
              </div>

              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Bed+Room.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Modern</p>
              </div>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Glam_Dining01_002.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Contemporary</p>
              </div>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Industrial+1+Living2.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Rustic</p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-around align-items-end"
              style={{ height: '250px' }}
              onClick={() => {
                storeMxAns({});
                storeMx({});
                history.push({
                  pathname: '/signup',
                  state: { redirect: '/questionnaire' }
                });
              }}>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Living+1.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">North Indian</p>
              </div>

              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Bed+Room.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">South Indian</p>
              </div>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Glam_Dining01_002.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Classic</p>
              </div>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Industrial+1+Living2.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Minimalistic</p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-around align-items-end"
              style={{ height: '250px' }}
              onClick={() => {
                storeMxAns({});
                storeMx({});
                history.push({
                  pathname: '/signup',
                  state: { redirect: '/questionnaire' }
                });
              }}>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Living+1.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Mid Century</p>
              </div>

              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Bed+Room.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Eclectic</p>
              </div>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Glam_Dining01_002.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Pop Art</p>
              </div>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Industrial+1+Living2.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Art Deco</p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-around align-items-end"
              style={{ height: '250px' }}
              onClick={() => {
                storeMxAns({});
                storeMx({});
                history.push({
                  pathname: '/signup',
                  state: { redirect: '/questionnaire' }
                });
              }}>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Living+1.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Industrial</p>
              </div>

              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Bed+Room.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Gothic</p>
              </div>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Glam_Dining01_002.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Farm house</p>
              </div>
              <div className={style.cardHover}>
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Industrial+1+Living2.png'
                  }
                  alt=""
                  style={{ width: '120px', height: '140px' }}
                />
                <p className="text-left">Coastal</p>
              </div>
            </div>
          </Carousel>
        </div>
      )}
      {dimentions[0] < 500 && (
        <div className="row">
          <div className=" col-12 pl-5 pr-5 pt-2 d-flex flex-column justify-content-center align-items-center">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <h3>Select the spaces that speak to you.</h3>
              <span>
                Explore curated design ideas meant to inspire you, help you save, or shop directly
                from
              </span>
            </ScrollAnimation>
            <div className="d-flex justify-content-start align-items-start w-100 mb-5">
              <button
                className={style.designButton}
                onClick={() => {
                  history.push('/questionnaire');
                }}>
                Find your style
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <div className={style.container3}>
                <div>
                    <h2 className="mb-3" style={{ fontWeight: 400 }}>
                        Select the spaces that <br></br>
                        speak to you.
                    </h2>
                    <span className="mt-3 mb-3">
                        Here we’ve curated design ideas to inspire you, that you can <br></br> explore, save or even shop directly from.
                    </span>
                    <br></br>
                        <button className={classnames(style.designButton, 'mt-3')} onClick={()=> history.push('/questionnaire')} >Find your style</button>
                </div>
            </div> */}
      {dimentions[0] > 500 && (
        <div
          className="row d-flex flex-row justify-content-center align-items-center w-100"
          style={{ marginTop: '10%', marginBottom: '10%' }}>
          <div
            className={classnames(
              'col-6 text-left d-flex flex-column justify-content-center align-items-center p-0',
              style.ordinarySpace
            )}>
            {/* <img style={{ width: '60%', position: 'absolute' }} src={'https://d3izcfql7lesks.cloudfront.net/Group_81_gbmqkc.png'} alt='' /> */}
            <div className="d-flex flex-column justify-content-start align-items-start">
              <h3
                style={{
                  fontSize: '2vw',
                  paddingBottom: '2vh',
                  fontWeight: 600
                }}>
                Select the spaces that <br></br>speak to you.
              </h3>
              <h3
                style={{
                  fontSize: '13px',
                  paddingBottom: '2vh',
                  lineHeight: '18px'
                }}>
                Explore curated design ideas meant to inspire you, <br></br>
                help you save, or shop directly from
              </h3>

              <button
                className={style.designButton}
                style={{
                  marginTop: '7%'
                }}
                onClick={() => {
                  storeMx({});
                  storeMxAns({});
                  auth.isAuthenticated
                    ? history.push('/questionnaire')
                    : history.push({
                        pathname: '/signup',
                        state: { redirect: '/questionnaire' }
                      });
                }}>
                Find you style
              </button>
            </div>
          </div>
          <div className="col-6 p-1  text-left">
            <div style={{ maxWidth: '700px', margin: 'auto' }}>
              <Carousel
                autoPlay={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                showArrows={false}
                swipeable={true}
                swipeScrollTolerance={0}
                emulateTouch={true}
                interval={2000}
                stopOnHover={true}
                infiniteLoop={true}>
                <div
                  className="d-flex flex-row justify-content-around align-items-center"
                  style={{ height: '300px' }}
                  onClick={() => {
                    storeMxAns({});
                    storeMx({});
                    history.push({
                      pathname: '/signup',
                      state: { redirect: '/questionnaire' }
                    });
                  }}>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Living+1.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Boho</p>
                  </div>

                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Bed+Room.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Modern</p>
                  </div>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Glam_Dining01_002.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Contemporary</p>
                  </div>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Industrial+1+Living2.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Rustic</p>
                  </div>
                </div>
                <div
                  className="d-flex flex-row justify-content-around align-items-center"
                  style={{ height: '300px' }}
                  onClick={() => {
                    storeMxAns({});
                    storeMx({});
                    history.push({
                      pathname: '/signup',
                      state: { redirect: '/questionnaire' }
                    });
                  }}>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Living+1.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">North Indian</p>
                  </div>

                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Bed+Room.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">South Indian</p>
                  </div>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Glam_Dining01_002.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Classic</p>
                  </div>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Industrial+1+Living2.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Minimalistic</p>
                  </div>
                </div>
                <div
                  className="d-flex flex-row justify-content-around align-items-center"
                  style={{ height: '300px' }}
                  onClick={() => {
                    storeMxAns({});
                    storeMx({});
                    history.push({
                      pathname: '/signup',
                      state: { redirect: '/questionnaire' }
                    });
                  }}>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Living+1.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Mid Century</p>
                  </div>

                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Bed+Room.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Eclectic</p>
                  </div>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Glam_Dining01_002.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Pop Art</p>
                  </div>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Industrial+1+Living2.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Art Deco</p>
                  </div>
                </div>
                <div
                  className="d-flex flex-row justify-content-around align-items-center"
                  style={{ height: '300px' }}
                  onClick={() => {
                    storeMxAns({});
                    storeMx({});
                    history.push({
                      pathname: '/signup',
                      state: { redirect: '/questionnaire' }
                    });
                  }}>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Living+1.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Industrial</p>
                  </div>

                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Mid+Century+Modern_Bed+Room.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Gothic</p>
                  </div>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Glam_Dining01_002.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />
                    <p className="text-left">Farm house</p>
                  </div>
                  <div className={style.cardHover}>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Industrial+1+Living2.png'
                      }
                      alt=""
                      style={{ width: '120px', height: '140px' }}
                    />

                    <p className="text-left">Coastal</p>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

Reviews.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  storeMx,
  storeMxAns
})(withRouter(Reviews));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
