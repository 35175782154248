const initialState = {
  active: 'questionnaire',
  questionnaire1: 'pending',
  questionnaire2: 'pending',
  package: 'pending',
  data: {},
  page_number: 0,
  page_number2: 0,
  MxSkip: '',
  tab: '',
  MxData: {},
  MxBData: [],
  MxAns: {},
  MxRetake: '',
  Packages: {}
  // name: '',
  // MX_A_SPACE: [],
  // MX_A_STYLE: [],
  // MX_A_COLOR_TONE: '',
  // MX_A_DESIGN: '',
  // MX_A_ROOMS: [],
  // MX_A_INITAL_ROOM: ''
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_QUESTIONNAIRE':
      return {
        ...state,
        active: payload
      };
    case 'POST_QUESTIONNAIRE':
      return {
        ...state,
        data: payload,
        questionnaire1: 'resultCalculated'
      };

    case 'RESET_ANSWERS':
      return {
        ...state,
        questionnaire1: 'questionnaire'
      };

    case 'RESET_QUESTIONNAIRE':
      return {
        active: 'questionnaire',
        questionnaire1: 'pending',
        questionnaire2: 'pending',
        package: 'pending',
        data: {},
        page_number: 0,
        page_number2: 0,
        MxSkip: '',
        tab: '',
        MxData: {},
        MxBData: [],
        MxAns: {},
        MxRetake: '',
        Packages: {}
      };

    case 'PAGE_COUNT':
      return {
        ...state,
        page_number: payload
      };
    case 'PAGE_COUNT2':
      return {
        ...state,
        page_number2: payload
      };
    case 'STORE_MX':
      return {
        ...state,
        MxData: payload
      };
    case 'STORE_MXB':
      return {
        ...state,
        MxBData: payload
      };
    case 'STORE_PACKAGE':
      return {
        ...state,
        Packages: payload
      };
    case 'MX_ANS': {
      return {
        ...state,
        MxAns: payload
      };
    }
    case 'STORE_TAB':
      return {
        ...state,
        tab: payload
      };
    case 'STORE_MXSkip':
      return {
        ...state,
        MxSkip: payload
      };
    case 'STORE_MXRetake':
      return {
        ...state,
        MxRetake: payload
      };

    default:
      return state;
  }
}
