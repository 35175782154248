import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import Rating from 'react-rating';
import { withRouter, useParams } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cancelReasons, overallTags, designerTags, enhancedFeatures } from './constants';
import InterActiveMoodboard from './interActiveMoodboard';
import style from './Review.module.css';
import classnames from 'classnames';
import * as Icon from 'react-bootstrap-icons';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Modal as Modal1 } from 'react-bootstrap';
import { Drawer } from '@material-ui/core';
import customerApiInstance from '../../../utils/CustomerApiInstance';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RatingBox from './RatingBox';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import ReactAudioPlayer from 'react-audio-player';
import { ChevronRight } from 'react-bootstrap-icons';
import { storeCartCount } from '../../../store/actions/sampleaction';
import { storeMoodboardProduct } from '../../../store/actions/sampleaction';
import { storeMoodBoardData } from '../../../store/actions/sampleaction';

import getCartDataCount from '../../../utils/CartDetails';
//import { UnityContext } from "react-unity-webgl";
import ProductList from './ProductList';

import sitBackWeb from '../../../assets/sitBackWeb.png';
import sitBackMob from '../../../assets/sitBackMob.png';
import ratingPending from '../../../assets/ratingPending.png';
import ratingPendingMob from '../../../assets/ratingPendingMob.png';
import moodboardRated from '../../../assets/moodboardRated.png';
import moodboardRatedMob from '../../../assets/moodboardRatedMob.png';
import sorryImg from '../../../assets/sorryImg.png';
import sorryImgMob from '../../../assets/sorryImgMob.png';

// const unityContext = new UnityContext({
//   loaderUrl: "/unity/Build/3DViewer.loader.js",
//   dataUrl: "/unity/Build/3DViewer.data",
//   frameworkUrl: "/unity/Build/3DViewer.framework.js",
//   codeUrl: "/unity/Build/3DViewer.wasm",
// });

const Review = ({
  page,
  auth,
  history,
  storeCartCount,
  storeMoodboardProduct,
  storeMoodBoardData,
  setProjectDataRooms,
  projectDataRooms,
  setShopAwayDisable
}) => {
  const { id } = useParams();
  // const [overlay, setOverlay] = useState("");
  // const [cardOverlay, setCardOverlay] = useState();
  // const [drawer, setDrawer] = useState(false);
  const [upgradeDrawer, setUpgradeDrawer] = useState(false);
  const [moodBoards, setMoodBoards] = useState([]);
  // const [products, setProducts] = useState([]);
  // const [likesChecked, setLikesChecked] = useState(false);
  const dimension = useMediaQuery();
  const [data, setData] = useState();
  //  const [tempProducts, setTempProducts] = useState([]);
  //  const [enabledProductsData, setEnabledProductsData] = useState([]);
  //  const [disabledProductsData, setDisabledProductsData] = useState([]);
  //  const anchor = useRef();
  //  const [show, setShow] = useState(false);
  const [projectData, setProjectData] = useState({});
  //  const [similarShow, setSimilarShow] = useState(false);
  //  const [categories, setCategories] = useState({});
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [savedProducts, setSavedProducts] = useState([]);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  //  const [open, setOpen] = useState({});
  //  const [currentProduct, setCurrentProduct] = useState({});
  //  const [shareModal, setShareModal] = useState(false);
  //  const copy = useRef();
  //  const [modal, setModal] = useState({ modal: false, name: "", id: null });
  //  const [copiedUrl, setCopiedUrl] = useState("");

  //  const [similarExpanded, setSimilarExpanded] = useState(false);
  //  const [shopIndex, setShopIndex] = useState();
  //  const [similarProductsShow, setSimilarProductsshow] = useState([]);
  const [customerRating, setCustomerRating] = useState(0);
  const [moodboardProductsData, setMoodboardProductsData] = useState([]);
  //  const [confirmModal, setConfirmModal] = useState(false);
  //  const [addedCol, setAddedCol] = useState(0);
  //  const [divider, setDivider] = useState(1);
  const [zoomModal, setZoomModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [overallTags1, setOverallTags1] = useState([]);
  const [designerTags1, setDesignerTags1] = useState([]);
  const [reasonInput, setReasonInput] = useState({});
  const [thanksModal, setThanksModal] = useState(false);
  const [finalModal, setFinalModal] = useState(false);
  const [projectFeedback, setProjectFeedback] = useState({});
  const [projectRating, setProjectRating] = useState('');
  const [designerRating, setDesignerRating] = useState('');
  const [finalComplete, setFinalComplete] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [overallFilteredTags, setOverallFilteredTags] = useState([]);
  const [designerFilteredTags, setDesignerFilteredTags] = useState([]);
  const [currentView, setCurrentView] = useState('2d');
  const [blobURL, setblobURL] = useState('');
  const [recordstate, setrecordstate] = useState('NONE');
  const [audioModal, setAudioModal] = useState(false);
  //  const [audioRecorded, setAudioRecorded] = useState(false);
  const [startAudio, setStartAudio] = useState(false);
  //  let [audioUrl, setAudioUrl] = useState([]);
  let [currentValue, setCurrentValue] = useState('');
  let [upgradedPackage, setUpgradedPackage] = useState('false');
  let [saveReviewMoodboard, setSaveReviewMoodboard] = useState(false);
  const [finalBoard, setFinalBoard] = useState(true);
  //  const [crumbFlag, setCrumbFlag] = useState(false);
  const [crumbId, setCrumbId] = useState(-1);
  //  const [crumbBoardData, setCrumbBoardData] = useState({});
  const [modal3d, setModal3d] = useState(false);
  const [addToCartProducts, setAddToCartProducts] = useState([]);
  const [moodBoardAvailable, setMoodBoardAvailable] = useState(true);
  const [reviewConceptRejected, setReviewConceptRejected] = useState(false);
  const [ratePrompt, setRatePrompt] = useState(false);
  const [disable, setDisable] = useState({
    reviewYes: false,
    reviewNo: false,
    finaliseRate: false
  });

  useEffect(() => {
    storeMoodboardProduct(addToCartProducts);
  }, [addToCartProducts]);

  const start = () => {
    setStartAudio(true);
    setrecordstate(RecordState.START);
  };
  const reset = () => {
    setStartAudio(true);
    setblobURL('');
    setrecordstate(RecordState.START);
  };

  const stop = () => {
    setStartAudio(false);
    setrecordstate(RecordState.STOP);
  };

  const onStop = async audioData => {
    //  setAudioRecorded(true);
    var d = new Date();
    var file = new File([audioData.blob], d.valueOf(), { type: 'audio/wav' });
    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData);
    if (res.data && res.data.message === 'success') {
      setSnack(true);
      setSnackMessage('Audio uploaded sucessfully.');
    }
    let audioResult = res.data.data;
    let audioObj = {};

    audioObj['fieldName'] = currentValue;
    if (audioResult && Array.isArray(audioResult)) {
      audioObj['audio'] = audioResult[0].Location;
    } else {
      audioObj['audio'] = audioResult.Location;
    }
    if (currentValue === 'mbFeedback') {
      setReasonInput({ ...reasonInput, feedbackAudio: audioObj['audio'] });
    }
    if (currentValue === 'finalReview') {
      setProjectFeedback({ ...projectFeedback, audio: audioObj['audio'] });
    }
    //  audioUrl.push(audioObj);
    setblobURL(audioData.url);
  };

  let byChecked = false;
  if (
    history.location.state &&
    history.location.state.check &&
    history.location.state.check === true
  ) {
    byChecked = true;
  }
  let headerShow = false;
  if (
    history.location.state &&
    history.location.state.showHeader &&
    history.location.state.showHeader === true
  ) {
    headerShow = true;
  }
  let prodQuantity = 1;

  // const breakPoints = [
  //   { width: 1, itemsToShow: 1 },
  //   { width: 550, itemsToShow: 1, itemsToScroll: 1 },
  //   { width: 768, itemsToShow: 3 },
  //   { width: 1200, itemsToShow: 3 },
  // ];

  // useEffect(() => {
  //   setShow(true);
  // }, []);

  useEffect(() => {
    let upgraded = JSON.parse(localStorage.getItem('packageUpgraded'));
    setUpgradedPackage(upgraded);
  }, []);

  const reasonChange = e => {
    setReasonInput({ ...reasonInput, feedbackText: e.target.value });
  };

  useEffect(() => {
    if (finalBoard === true) {
      const getMoodboard = async () => {
        let pageType = {
          status: page
        };
        if (page === 'SHOP') {
          pageType = {
            status: 'FINALIZE_CONCEPT'
          };
        }
        const moodboard = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/concept/${id}`,
          pageType
        );
        if (moodboard && moodboard.data.moodBoard) {
          setMoodBoardAvailable(true);
          setShopAwayDisable(false);
          storeMoodBoardData(moodboard.data);
          setData({ ...moodboard.data });
          //  setTempProducts([...moodboard.data.moodBoardProducts]);
          setMoodBoards(moodboard.data.moodBoard);
          if (moodboard.data.moodBoard.customer_rating) {
            setCustomerRating(moodboard.data.moodBoard.customer_rating);
          }
          if (moodboard.data.moodBoard.reason_for_cancellation) {
            setReasons(moodboard.data.moodBoard.reason_for_cancellation);
          }
          if (
            moodboard.data.moodBoard.feedback &&
            moodboard.data.moodBoard.feedback.feedbackText &&
            moodboard.data.moodBoard.feedback.feedbackAudio
          ) {
            setReasonInput({
              ...reasonInput,
              feedbackAudio: moodboard.data.moodBoard.feedback.feedbackAudio,
              feedbackText: moodboard.data.moodBoard.feedback.feedbackText
            });
          }
          if (
            moodboard.data.moodBoard.feedback &&
            moodboard.data.moodBoard.feedback.feedbackAudio &&
            (!moodboard.data.moodBoard.feedback.feedbackText ||
              moodboard.data.moodBoard.feedback.feedbackText === '')
          ) {
            setReasonInput({
              ...reasonInput,
              feedbackAudio: moodboard.data.moodBoard.feedback.feedbackAudio
            });
          }
          if (
            moodboard.data.moodBoard.feedback &&
            moodboard.data.moodBoard.feedback.feedbackText &&
            (!moodboard.data.moodBoard.feedback.feedbackAudio ||
              moodboard.data.moodBoard.feedback.feedbackAudio === '')
          ) {
            setReasonInput({
              ...reasonInput,
              feedbackText: moodboard.data.moodBoard.feedback.feedbackText
            });
          }
          let productsArray = [];
          let productsData = [];
          let disabledProducts = [];
          let moodboardProducts = moodboard.data.moodBoardProducts;
          let moodboardData = moodboard.data.moodBoard;
          if (
            moodboard.data.moodBoard &&
            moodboard.data.moodBoard.hasOwnProperty('shared_to_customer')
          ) {
            productsArray = moodboard.data.moodBoard.shared_to_customer.products;
            productsArray.map(prod => {
              prod.map(prod1 => {
                if (prod1.visible === true) {
                  productsData.push(prod1);
                }
              });
            });
          }
          //  setProducts([...productsData]);
          let groupEnabledProducts = productsData.reduce(function(obj, item) {
            obj[item.id] = obj[item.id] || [];
            if (item.enabled === true) obj[item.id].push(item.product_id);
            return obj;
          }, {});

          let enabledProducts = Object.keys(groupEnabledProducts).map(function(key) {
            return { id: key, product_ids: groupEnabledProducts[key] };
          });
          let groupDisabledProducts = productsData.reduce(function(obj, item) {
            obj[item.id] = obj[item.id] || [];
            if (item.enabled === false) obj[item.id].push(item.product_id);
            return obj;
          }, {});

          if (Object.keys(groupDisabledProducts).length) {
            disabledProducts = Object.keys(groupDisabledProducts).map(function(key) {
              return { id: key, product_ids: groupDisabledProducts[key] };
            });
          }
          let enabledProdsTemp = [];
          let disabledProdsTemp = [];
          const map = new Map();
          let productIds = [];
          moodboardData.shared_to_customer &&
            moodboardData.shared_to_customer.products.map((item, index) => {
              if (item instanceof Array && item.length > 0) {
                item.map((data, key) => {
                  if (data.visible === true) {
                    productIds = [...productIds, data.product_id];
                  }
                });
              }
            });
          productIds.forEach(item => {
            if (map.has(item)) {
              map.get(item).quantity++;
            } else {
              map.set(item, {
                item,
                quantity: 1
              });
            }
          });
          const result = Array.from(map.values());
          for (let i = 0; i < moodboardProducts.length; i++) {
            for (let j = 0; j < result.length; j++) {
              if (moodboardProducts[i]._id === result[j].item) {
                moodboardProducts[i].quantity = result[j].quantity;
                moodboardProducts[i].final_price = moodboardProducts[i].price
                  ? moodboardProducts[i].price * result[j].quantity
                  : moodboardProducts[i].pixel_price * result[j].quantity;
              }
            }
          }
          // setProductsDisplay([...moodboardProducts]);
          setMoodboardProductsData([...moodboardProducts]);
          // setProductsDisplay([...enabledProdsTemp])
          if (disabledProducts.length > 0) {
            for (let i = 0; i < moodboardProducts.length; i++) {
              for (let j = 0; j < disabledProducts.length; j++) {
                disabledProducts[j].product_ids.map(disabledProdId => {
                  if (disabledProdId === moodboardProducts[i]._id) {
                    disabledProdsTemp.push({
                      ...moodboardProducts[i],
                      uuid: disabledProducts[j].group_id
                    });
                  }
                });
              }
            }
            //   setDisabledProductsData([...disabledProdsTemp]);
          }
          for (let i = 0; i < moodboardProducts.length; i++) {
            for (let j = 0; j < enabledProducts.length; j++) {
              enabledProducts[j].product_ids.map(enabledProdId => {
                if (enabledProdId === moodboardProducts[i]._id) {
                  enabledProdsTemp.push({
                    ...moodboardProducts[i],
                    uuid: enabledProducts[j].group_id
                  });
                }
              });
            }
          }
          let category = enabledProdsTemp.map(data => {
            return data.category;
          });
          const categoriesCount = {};
          category.forEach(x => {
            categoriesCount[x] = (categoriesCount[x] || 0) + 1;
          });
          //  setCategories({ ...categoriesCount });
          //  setEnabledProductsData([...enabledProdsTemp]);
        } else {
          // setSnack(true);
          // setSnackMessage("No moodboard available");
          setMoodBoardAvailable(false);
          setShopAwayDisable(true);
        }
      };

      getMoodboard();
    }
  }, [page, finalBoard]);

  const getRevisionBoardProducts = async crumbBoardData1 => {
    let productIds1 = [];
    crumbBoardData1 &&
      crumbBoardData1.products &&
      crumbBoardData1.products.map((item, index) => {
        if (item instanceof Array && item.length > 0) {
          item.map((data, key) => {
            productIds1 = [...productIds1, data.product_id];
          });
        }
      });
    const res1 = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/bulkProducts`,
      productIds1
    );
    if (res1.data) {
      setMoodBoardAvailable(true);
      setShopAwayDisable(false);
      let productsArray = [];
      let productsData = [];
      let disabledProducts = [];
      let moodboardProducts = res1.data;
      if (crumbBoardData1.products) {
        productsArray = crumbBoardData1.products;
        productsArray.map(prod => {
          prod.map(prod1 => {
            if (prod1.visible === true) {
              productsData.push(prod1);
            }
          });
        });
      }
      //  setProducts([...productsData]);
      let groupEnabledProducts = productsData.reduce(function(obj, item) {
        obj[item.id] = obj[item.id] || [];
        if (item.enabled === true) obj[item.id].push(item.product_id);
        return obj;
      }, {});

      let enabledProducts = Object.keys(groupEnabledProducts).map(function(key) {
        return { id: key, product_ids: groupEnabledProducts[key] };
      });
      let groupDisabledProducts = productsData.reduce(function(obj, item) {
        obj[item.id] = obj[item.id] || [];
        if (item.enabled === false) obj[item.id].push(item.product_id);
        return obj;
      }, {});

      if (Object.keys(groupDisabledProducts).length) {
        disabledProducts = Object.keys(groupDisabledProducts).map(function(key) {
          return { id: key, product_ids: groupDisabledProducts[key] };
        });
      }
      let enabledProdsTemp = [];
      let disabledProdsTemp = [];
      const map = new Map();
      let productIds = [];
      crumbBoardData1.products &&
        crumbBoardData1.products.map((item, index) => {
          if (item instanceof Array && item.length > 0) {
            item.map((data, key) => {
              if (data.visible === true) {
                productIds = [...productIds, data.product_id];
              }
            });
          }
        });
      productIds.forEach(item => {
        if (map.has(item)) {
          map.get(item).quantity++;
        } else {
          map.set(item, {
            item,
            quantity: 1
          });
        }
      });

      const result = Array.from(map.values());
      for (let i = 0; i < moodboardProducts.length; i++) {
        for (let j = 0; j < result.length; j++) {
          if (moodboardProducts[i]._id === result[j].item) {
            moodboardProducts[i].quantity = result[j].quantity;
            moodboardProducts[i].final_price = moodboardProducts[i].price
              ? moodboardProducts[i].price * result[j].quantity
              : moodboardProducts[i].pixel_price * result[j].quantity;
          }
        }
      }
      // setProductsDisplay([...moodboardProducts]);
      setMoodboardProductsData([...moodboardProducts]);
      // setProductsDisplay([...enabledProdsTemp])
      if (disabledProducts.length > 0) {
        for (let i = 0; i < moodboardProducts.length; i++) {
          for (let j = 0; j < disabledProducts.length; j++) {
            disabledProducts[j].product_ids.map(disabledProdId => {
              if (disabledProdId === moodboardProducts[i]._id) {
                disabledProdsTemp.push({
                  ...moodboardProducts[i],
                  uuid: disabledProducts[j].group_id
                });
              }
            });
          }
        }
        //  setDisabledProductsData([...disabledProdsTemp]);
      }
      for (let i = 0; i < moodboardProducts.length; i++) {
        for (let j = 0; j < enabledProducts.length; j++) {
          enabledProducts[j].product_ids.map(enabledProdId => {
            if (enabledProdId === moodboardProducts[i]._id) {
              enabledProdsTemp.push({
                ...moodboardProducts[i],
                uuid: enabledProducts[j].group_id
              });
            }
          });
        }
      }
      let category = enabledProdsTemp.map(data => {
        return data.category;
      });
      const categoriesCount = {};
      category.forEach(x => {
        categoriesCount[x] = (categoriesCount[x] || 0) + 1;
      });
      //  setCategories({ ...categoriesCount });
      //  setEnabledProductsData([...enabledProdsTemp]);
    } else {
      // setSnack(true);
      // setSnackMessage("No moodboard available");
      setMoodBoardAvailable(false);
      setShopAwayDisable(true);
    }
  };

  const addMoodBoardProductsToCart = async productData => {
    let userId;
    let prodBody;

    try {
      if (auth && auth.user) {
        userId = auth.user._id;
        const moodBoardProduct = [];
        addToCartProducts.map((item, index) => {
          productData.map((list, index) => {
            if (item.productId === list._id) {
              moodBoardProduct.push(list);
            }
          });
        });
        const isArray = moodBoardProduct instanceof Array;
        if (!isArray) {
          prodBody = {
            user_id: userId,
            products: [
              {
                product_id: moodBoardProduct._id,
                quantity: moodBoardProduct.quantity,
                rate: moodBoardProduct.price || moodBoardProduct.pixel_price,
                total_price:
                  moodBoardProduct.quantity *
                  (moodBoardProduct.price || moodBoardProduct.pixel_price),
                project_id: id
              }
            ]
          };
        } else {
          let allProducts = [];
          for (let i = 0; i < moodBoardProduct.length; i++) {
            allProducts.push({
              product_id: moodBoardProduct[i]._id,
              quantity: 1,
              rate: moodBoardProduct[i].price,
              total_price: 1 * (moodBoardProduct[i].price || moodBoardProduct[i].pixel_price),
              project_id: id
            });
          }

          prodBody = {
            user_id: userId,
            products: [...allProducts]
          };
        }
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push({
            pathname: '/cart'
          });
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }
    storeCartCount(await getCartDataCount(auth));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [addMoodBoardProductsToCart]);

  const addToCart = async productData => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        const isArray = productData instanceof Array;
        if (!isArray) {
          prodBody = {
            user_id: userId,
            products: [
              {
                product_id: productData._id,
                quantity: productData.quantity,
                rate: productData.price || productData.pixel_price,
                total_price: productData.quantity * (productData.price || productData.pixel_price),
                project_id: id
              }
            ]
          };
        } else {
          let allProducts = [];
          for (let i = 0; i < productData.length; i++) {
            allProducts.push({
              product_id: productData[i]._id,
              quantity: 1,
              rate: productData[i].price,
              total_price: 1 * (productData[i].price || productData[i].pixel_price),
              project_id: id
            });
          }

          prodBody = {
            user_id: userId,
            products: [...allProducts]
          };
        }
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }
    storeCartCount(await getCartDataCount(auth));
  };

  const addToFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            project_id: id
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            project_id: id
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = product => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds(prev => [...prev, product._id]);
      setSavedProducts([...savedProducts, product]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter(filter => filter !== product._id));
      const removefav = savedProducts.filter(sav => sav._id !== product._id);
      setSavedProducts([...removefav]);
      removeFromFavourites(product);
    }
  };

  // const handleHover = (value) => {
  //   setOverlay(value);
  // };

  // const getSimilarProducts = (data) => {
  //   let otherProducts = moodboardProductsData.filter(
  //     (prod) => prod._id !== data._id
  //   );
  //   let styleFilterProducts = otherProducts.filter(
  //     (prod) => prod.style === data.style
  //   );
  //   let roomFilterProducts = otherProducts.filter((prod) =>
  //     prod.room_type.filter((room) => data.room_type.includes(room))
  //   );
  //   let styleAppliedProds = [...styleFilterProducts, ...roomFilterProducts];
  //   styleAppliedProds = styleAppliedProds.filter(
  //     (v, i, a) => a.findIndex((t) => t._id === v._id) === i
  //   );
  //   setSimilarProductsshow([...styleAppliedProds]);
  // };

  // useEffect(() => {
  //   if (dimension[0] > 1 && dimension[0] < 550) {
  //     setDivider(1);
  //   }
  //   if (dimension[0] > 550 && dimension[0] < 768) {
  //     setDivider(2);
  //   }
  //   if (dimension[0] > 850 && dimension[0] < 1600) {
  //     setDivider(3);
  //   }
  //   if (dimension[0] > 1600 && dimension[0] < 2000) {
  //     setDivider(4);
  //   }
  //   if (dimension[0] > 2000) {
  //     setDivider(5);
  //   }
  // }, [dimension]);

  // const getCompute = (index, isLastIndex) => {
  //   if (isLastIndex) {
  //     let add = index + 1;

  //     setAddedCol(add);
  //   } else {
  //     let add = Math.ceil((index + 1) / divider) * divider;

  //     setAddedCol(add);
  //   }
  // };

  useEffect(() => {
    const getProject = async () => {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );
      let projectDetails = { ...res.data };
      if (projectDetails.hasOwnProperty('designer_rating')) {
        setDesignerRating(projectDetails.designer_rating);
      }
      if (projectDetails.hasOwnProperty('project_rating')) {
        setProjectRating(projectDetails.project_rating);
      }
      if (projectDetails.hasOwnProperty('designer_feedback')) {
        setDesignerTags1(projectDetails.designer_feedback);
      }
      if (projectDetails.hasOwnProperty('project_feedback')) {
        setOverallTags1(projectDetails.project_feedback);
      }
      if (projectDetails.hasOwnProperty('project_review')) {
        setProjectFeedback(projectDetails.project_review);
      }
      setProjectData({ ...res.data });
      if (
        projectDetails.job === 'REVIEW_CONCEPT' &&
        projectDetails.hasOwnProperty('customer_feedback') &&
        projectDetails.customer_feedback == 'REVIEW_REQUESTED'
      ) {
        setReviewConceptRejected(true);
      } else if (
        projectDetails.job === 'REVIEW_CONCEPT' &&
        projectDetails.hasOwnProperty('customer_feedback') &&
        projectDetails.customer_feedback == 'PENDING'
      ) {
        setReviewConceptRejected(false);
      }

      if (
        projectDetails.job === 'FINALIZE_CONCEPT' &&
        page == 'FINALIZE_CONCEPT' &&
        moodBoardAvailable
      ) {
        setTimeout(() => {
          setRatePrompt(true);
        }, 2000);
      }
      if (
        projectDetails.job === 'REVIEW_CONCEPT' &&
        projectDetails.hasOwnProperty('moodboard_revisions') &&
        projectDetails.moodboard_revisions.length > 1
      ) {
        setDisable({ reviewYes: false, reviewNo: true, finaliseRate: false });
      } else if (projectDetails.job === 'FINALIZE_CONCEPT') {
        setDisable({ reviewYes: true, reviewNo: true, finaliseRate: false });
      } else if (projectDetails.job === 'COMPLETED') {
        setDisable({ reviewYes: true, reviewNo: true, finaliseRate: true });
      }
      if (res.data.job === 'FINALIZE_CONCEPT' && byChecked === false) {
        history.push({
          pathname: `/rooms/${id}`,
          state: { type: 'finalise', showHeader: headerShow }
        });
      }
      if (res.data.job === 'COMPLETED' && byChecked === false) {
        history.push({ pathname: `/rooms/${id}`, state: { type: 'shop' } });
      }
    };
    getProject();
  }, [byChecked]);

  useEffect(() => {
    if (projectRating > 0) {
      // let tags1 = [];
      let filteredTags = overallTags.find(tag => tag.star.toString() === projectRating.toString());
      if (Object.keys(filteredTags).length) {
        setOverallFilteredTags([...filteredTags.tags]);
      }
    }
  }, [projectRating]);

  useEffect(() => {
    if (designerRating > 0) {
      // let tags1 = [];
      let filteredTags = designerTags.find(
        tag => tag.star.toString() === designerRating.toString()
      );
      if (Object.keys(filteredTags).length) {
        setDesignerFilteredTags([...filteredTags.tags]);
      }
    }
  }, [designerRating]);

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      boxShadow: theme.shadows[5]
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      border: 'none',
      width: 800,
      height: 500,
      padding: theme.spacing(2, 4, 3)
    },
    paper1: {
      backgroundColor: '#F5F0EC',
      boxShadow: theme.shadows[5],
      border: 'none',
      width: '90%',
      maxHeight: 'fit-content',
      padding: theme.spacing(2, 4, 3),
      outline: 'none'
    }
  }));

  const classes = useStyles();

  const useStyles1 = makeStyles({
    paper: {
      width: 450,
      backgroundImage: `url(${'https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4851.png'})`
    }
  });
  const classes1 = useStyles1();

  const useStyles2 = makeStyles({
    paper: {
      width: 350,
      backgroundImage: `url(${'https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4851.png'})`
    }
  });
  const classes2 = useStyles2();

  //For No in Review Concept
  const handleRequestSubmit = async () => {
    const moodboardBoard = {
      customer_rating: customerRating,
      reason_for_cancellation: reasons,
      feedback: reasonInput
    };
    let moodboard_fields = {
      content: moodBoards.shared_to_customer.content,
      preview_url: moodBoards.shared_to_customer.preview_url,
      products: moodBoards.shared_to_customer.products,
      shared_time: moodBoards.shared_to_customer.shared_time,
      moodboard_id: moodBoards._id,
      moodboard_styles: moodBoards.styles,
      customer_rating: moodBoards.customer_rating ? moodBoards.customer_rating : 0,
      admin_rating: moodBoards.admin_rating ? moodBoards.admin_rating : 0,
      moodboard_title: moodBoards.title,
      moodboard_updated_at: moodBoards.updated_at,
      status: 'REJECTED',
      reason_for_cancellation: reasons,
      feedback: reasonInput
    };
    const projectDetails = {
      customer_feedback: 'REVIEW_REQUESTED',
      moodboard_revisions: projectData.hasOwnProperty('moodboard_revisions')
        ? [...projectData.moodboard_revisions, moodboard_fields]
        : [moodboard_fields]
    };
    const res1 = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/update/${moodBoards._id}`,
      moodboardBoard
    );
    const res = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${id}`,
      projectDetails
    );
    if (res1 && res) setReviewConceptRejected(true);
    setDeclineModal(false);
    setThanksModal(true);
    setSnack(true);
    setSnackMessage('Please stay on this page and refresh after few minutes');
  };

  //For YES in Review Concept
  const handleCompleteSubmit = async () => {
    setSaveReviewMoodboard(true);
    const moodboardBoard = {
      customer_rating: customerRating
    };
    const projectData = {
      customer_feedback: 'COMPLETED',
      job: 'FINALIZE_CONCEPT',
      status: 'COMPLETED'
    };
    await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/update/${moodBoards._id}`,
      moodboardBoard
    );
    const res = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${id}`,
      projectData
    );
    if (res.data) {
      try {
        let designerId = projectData.designer_id;
        if (designerId) {
          await axios.patch(
            `${process.env.REACT_APP_ADMIN_URL}/v1/designer/update/designer-levels/${designerId}`
          );
        }
      } catch (e) {}
    }
    if (res.data) {
      if (res.data.job === 'FINALIZE_CONCEPT') {
        setProjectDataRooms({ ...projectDataRooms, level: 4 });
        history.push({ pathname: `/rooms/${id}`, state: { type: 'finalise' } });
      } else {
        setSnack(true);
        setSnackMessage('Please stay on this page and refresh after few minutes');
      }
    }
  };

  // for Finalise concept Submit button while Rating
  const handleFinalSubmit = async () => {
    const moodboardBoardData = {
      customer_rating: customerRating
    };
    await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/update/${moodBoards._id}`,
      moodboardBoardData
    );
    const projectData = {
      status: 'COMPLETED',
      customer_feedback: 'COMPLETED',
      job: 'COMPLETED',
      designer_rating: designerRating,
      project_rating: projectRating,
      designer_feedback: designerTags1,
      project_feedback: overallTags1,
      project_review: projectFeedback,
      completedAt: Date.now()
    };
    const res = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${id}`,
      projectData
    );
    setDesignerRating('');
    setDesignerTags1([]);
    setProjectRating('');
    setProjectFeedback({});
    setFinalComplete(true);
    setFinalModal(false);
    setProjectData({ ...res.data });
    setOverallTags1([]);
    if (res.data) {
      if (res.data.job === 'COMPLETED') {
        setProjectDataRooms({ ...projectDataRooms, level: 5 });
        history.push({ pathname: `/rooms/${id}`, state: { type: 'shop' } });
      } else {
        setSnack(true);
        setSnackMessage('Please stay on this page and refresh after few minutes');
      }
    }
  };

  const handlePackageUpgrade = () => {
    if (
      projectData.questionnaire &&
      projectData.questionnaire.PACKAGE &&
      projectData.questionnaire.PACKAGE.title !== 'Enhanced'
    ) {
      setUpgradeDrawer(true);
    }
  };

  const [second, setSecond] = useState('00');
  const [minute, setMinute] = useState('00');
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter(counter => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  const stopTimer = () => {
    setIsActive(false);
    setCounter(0);
    setSecond('00');
    setMinute('00');
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />

      <div style={{ backgroundColor: page !== 'SHOP' ? '#FAFAFA' : '' }}>
        {/*WEB Waiting page for Review concept*/}
        {page === 'REVIEW_CONCEPT' && !moodBoardAvailable&&  dimension[0] > 500 && (
          <div className={style.placeholderContainer}>
            <div className={style.placeholderCentre}>
              <div className={style.innerContText}>
                <div
                  style={{
                    textAlign: 'left',
                    fontSize: '24px',
                    fontWeight: '700'
                  }}>
                  Thankyou for rating your mood boards.
                  <br />
                  <br />
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '18px',
                      fontWeight: '600'
                    }}>
                    Your designer is working on a design for your room.
                    <br />
                    Based on your style & budget
                  </div>
                </div>

                <div>
                  Our designer will get back to you shortly. <br />
                  If you have any queries, call us on +91-9176976111
                </div>
              </div>
              <div className={style.innerCont}>
                <img
                  src={moodboardRated}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {/*Mobile Waiting page for Review concept*/}
        {page === 'REVIEW_CONCEPT' && !moodBoardAvailable && dimension[0] < 500 && (
          <div
            style={{
              backgroundColor: '#F5F0EC',
              minHeight: '90vh',
              padding: '10px'
            }}>
            <img
              src={moodboardRated}
              style={{
                height: '50%',
                width: '100%',
                objectFit: 'contain'
              }}
            />
            <div
              style={{
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: '700'
              }}>
              Thankyou for rating your mood boards.
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: '600'
                }}>
                <p>
                  {' '}
                  Your designer is working on a design for your room. Based on your style & budget
                </p>
              </div>
            </div>

            <div
              className={'mt-1'}
              style={{
                textAlign: 'center'
              }}>
              Our designer will get back to you shortly. <br />
              If you have any queries, call us on +91-9176976111
            </div>
          </div>
        )}

        {/*WEB Page for Review concept*/}
        {page === 'REVIEW_CONCEPT' &&
          moodBoardAvailable &&
          !reviewConceptRejected &&
          dimension[0] > 500 && (
            <div
              style={{ backgroundColor: '#ffffff' }}
              className={classnames(style.topContainer, 'pb-5')}>
              <div className="row m-auto" style={{ width: '90%' }}>
                <div className="col-1 "></div>
                {currentView === '2d' && (
                  <div className="col-11 ">
                    <div className="m-3">
                      {projectData.moodboard_revisions &&
                        projectData.moodboard_revisions.length > 0 &&
                        projectData.moodboard_revisions.map((revision, index) => {
                          return data?.moodBoard?._id === revision.moodboard_id ? null : (
                            <a
                              style={{
                                color: 'black',
                                fontSize: index === 0 ? '16px' : index === 1 ? '14px' : '12px',
                                cursor: 'pointer'
                              }}
                              className="m-2 p-2"
                              to="/about"
                              onClick={() => {
                                // setCrumbFlag(true);
                                setCrumbId(index);
                                setFinalBoard(false);
                                // setCrumbBoardData(
                                //   projectData.moodboard_revisions[index]
                                // );
                                getRevisionBoardProducts(projectData.moodboard_revisions[index]);
                              }}>
                              {`Revision ${index + 1}`}
                              <span>
                                <ChevronRight size={12} style={{ marginLeft: '1%' }} />
                              </span>
                            </a>
                          );
                        })}
                      {projectData.moodboard_revisions &&
                        projectData.moodboard_revisions.length > 0 && (
                          <a
                            style={{
                              color: 'black',
                              fontSize: '12px',
                              cursor: 'pointer'
                            }}
                            className="m-2 p-2"
                            onClick={() => {
                              setFinalBoard(true);
                              setCrumbId(-1);
                            }}>{`Revision ${projectData.moodboard_revisions.length + 1}`}</a>
                        )}

                      {crumbId !== -1 && (
                        <div className={`col-8`} style={{ border: '1px solid #C3C3C3' }}>
                          <div className="row px-3" style={{ position: 'relative' }}>
                            <div
                              className="my-3"
                              style={{ width: '100%', height: '100%' }}
                              id="fabric-canvas-wrapper">
                              <img
                                style={{ width: '100%', position: 'relative' }}
                                src={
                                  projectData.moodboard_revisions &&
                                  projectData.moodboard_revisions[crumbId]
                                    ? projectData.moodboard_revisions[crumbId].preview_url
                                    : null
                                }
                                alt=" "
                              />
                            </div>
                            <div
                              className="cursor-pointer "
                              style={{
                                position: 'absolute',
                                right: '25px',
                                top: '20px'
                              }}
                              onClick={() => setZoomModal(true)}>
                              <Icon.ArrowsFullscreen />
                            </div>
                          </div>
                        </div>
                      )}
                      {finalBoard && (
                        <InterActiveMoodboard
                          data={data}
                          id={id}
                          projectData={projectData}
                          saveReviewMoodboard={saveReviewMoodboard}
                          handleCompleteSubmit={handleCompleteSubmit}
                          setDeclineModal={setDeclineModal}
                          page={page}
                          auth={auth}
                          history={history}
                          addToCartProducts={addToCartProducts}
                          setAddToCartProducts={setAddToCartProducts}
                          dimension={dimension}
                          savedProductIds={savedProductIds}
                          handleFavourites={handleFavourites}
                          disable={disable}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

        {/*Mobile Page for Review concept*/}
        {page === 'REVIEW_CONCEPT' &&
          moodBoardAvailable &&
          !reviewConceptRejected &&
          dimension[0] < 500 && (
            <div className="row w-100 ml-auto mr-auto mt-3">
              {finalBoard && (
                <InterActiveMoodboard
                  data={data}
                  id={id}
                  projectData={projectData}
                  saveReviewMoodboard={saveReviewMoodboard}
                  handleCompleteSubmit={handleCompleteSubmit}
                  setDeclineModal={setDeclineModal}
                  page={page}
                  auth={auth}
                  history={history}
                  addToCartProducts={addToCartProducts}
                  setAddToCartProducts={setAddToCartProducts}
                  dimension={dimension}
                  savedProductIds={savedProductIds}
                  handleFavourites={handleFavourites}
                  disable={disable}
                />
              )}
            </div>
          )}

        {/*WEB Page for Review concept rejected*/}
        {page === 'REVIEW_CONCEPT' &&
          moodBoardAvailable &&
          reviewConceptRejected &&
          dimension[0] > 500 && (
            <div className={style.placeholderContainer}>
              <div className={style.placeholderCentre}>
                <div className={style.innerContText}>
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '24px',
                      fontWeight: '700'
                    }}>
                    We are sorry we did not meet your expectations.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: 'left',
                        fontSize: '18px',
                        fontWeight: '600'
                      }}>
                      Our designer will work on your feedback
                      <br />
                      and get back with a design we are sure you will love.
                    </div>
                  </div>

                  <div>
                    Our designer will get back to you shortly. <br />
                    If you have any queries, call us on +91-9176976111
                  </div>
                </div>
                <div className={style.innerCont}>
                  <img
                    src={sorryImg}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </div>
              </div>
            </div>
          )}

        {/*Mobile Page for Review concept rejected*/}
        {page === 'REVIEW_CONCEPT' &&
          moodBoardAvailable &&
          reviewConceptRejected &&
          dimension[0] < 500 && (
            <div
              style={{
                backgroundColor: '#F5F0EC',
                minHeight: '90vh',
                padding: '10px'
              }}>
              <img
                src={sorryImgMob}
                style={{
                  height: '50%',
                  width: '100%',
                  objectFit: 'contain'
                }}
              />
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '22px',
                  fontWeight: '700'
                }}>
                We are sorry we did not meet your expectations.
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}>
                  <p>
                    {' '}
                    Our designer will work on your feedback and get back with a design we are sure
                    you will love.
                  </p>
                </div>
              </div>

              <div
                className={'mt-1'}
                style={{
                  textAlign: 'center'
                }}>
                Our designer will get back to you shortly. <br />
                If you have any queries, call us on +91-9176976111
              </div>
            </div>
          )}

        {/*WEB Page for Review concept accepted and Waiting page for Finalize concept*/}
        {page === 'FINALIZE_CONCEPT' && !moodBoardAvailable && dimension[0] > 500 && (
          <div className={style.placeholderContainer}>
            <div className={style.placeholderCentre}>
              <div className={style.innerContText}>
                <div
                  style={{
                    textAlign: 'left',
                    fontSize: '24px',
                    fontWeight: '700'
                  }}>
                  We are glad you liked your design.
                  <br />
                  <br />
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '18px',
                      fontWeight: '600'
                    }}>
                    Sit back while we create your shopping list.
                    <br />
                  </div>
                </div>

                <div>
                  Our designer will get back to you shortly. <br />
                  If you have any queries, call us on +91-9176976111
                </div>
              </div>
              <div className={style.innerCont}>
                <img
                  src={sitBackWeb}
                  style={{
                    height: '85%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {/*Mpbole Page for Review concept accepted and Waiting page for Finalize concept*/}
        {page === 'FINALIZE_CONCEPT' && !moodBoardAvailable && dimension[0] < 500 && (
          <div
            style={{
              backgroundColor: '#F5F0EC',
              minHeight: '90vh',
              padding: '10px'
            }}>
            <img
              src={sitBackMob}
              style={{
                height: '50%',
                width: '100%',
                objectFit: 'contain'
              }}
            />
            <div
              style={{
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: '700'
              }}>
              We are glad you liked your design.
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: '600'
                }}>
                <p> Sit back while we create your shopping list.</p>
              </div>
            </div>

            <div
              className={'mt-1'}
              style={{
                textAlign: 'center'
              }}>
              Our designer will get back to you shortly. <br />
              If you have any queries, call us on +91-9176976111
            </div>
          </div>
        )}

        {/*WEB Page for Finalize concept shared*/}
        {page === 'FINALIZE_CONCEPT' && moodBoardAvailable && dimension[0] > 500 && (
          <>
            {upgradedPackage && upgradedPackage === true ? (
              <>
                <div className={style.black}>
                  <div className="row d-flex justify-content-end align-items-end m-2">
                    <Icon.XCircle
                      color={'white'}
                      size={dimension[0] > 500 ? 30 : 16}
                      onClick={() => {
                        localStorage.removeItem('packageUpgraded');
                        setUpgradedPackage(false);
                      }}
                    />
                  </div>
                  <img
                    className={style.padd}
                    src={'https://d3izcfql7lesks.cloudfront.net/Group_2219_r48lnr.svg'}
                    alt=""
                  />

                  <div className="row d-flex justify-content-center">
                    <p
                      style={{
                        fontSize: dimension[0] > 500 ? '20px' : '14px'
                      }}>
                      Thank you for your purchase! You have chosen the Premium Package!
                    </p>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <h6 className={style.email}>
                      We have emailed your order to {auth && auth.user ? auth.user.email : ''}
                    </h6>
                  </div>
                </div>
              </>
            ) : null}
            <div className="m-0 p-0" style={{ backgroundColor: 'white', cursor: 'pointer' }}>
              <div
                className=" item"
                style={{
                  backgroundColor: 'white',
                  paddingTop: '10px',
                  paddingBottom: dimension[0] > 500 ? '10px' : '20px'
                }}>
                <div className="row m-auto" style={{ width: '90%' }}>
                  {process.env.REACT_APP_BACKEND_URL ===
                  `https://dev-customer-api.pixelandmortar.com` ? (
                    <div className="col-1">
                      <div className="row pl-3 pr-3 pt-3 justify-content-end  align-items-end">
                        <Box
                          color="white"
                          bgcolor={currentView === 'AR' ? '#DEF8FF' : '#FAFAFA'}
                          style={{ width: '30%', padding: '3%' }}
                          className="d-flex justify-content-center align-items-center">
                          <img
                            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/project-icons/AR.png"
                            onClick={() => {
                              setCurrentView('AR');
                              handlePackageUpgrade();
                            }}
                          />
                        </Box>
                      </div>
                      <div className="row justify-content-end">
                        <span style={{ fontSize: '12px', paddingRight: '5%' }}>view in AR</span>
                      </div>

                      <div className="row pl-3 pr-3 pt-3 mt-1 p-1 justify-content-end">
                        {projectData?.space ? (
                          <a /* href={`${process.env.REACT_APP_3D_URL}?projectId=${id}&spaceId=${projectData?.space?._id}`} target='_blank' */
                          >
                            <Box
                              color="white"
                              bgcolor={currentView === '3d' ? '#DEF8FF' : '#FAFAFA'}
                              style={{ width: '30%', padding: '3%' }}
                              className="d-flex justify-content-center align-items-center">
                              <img
                                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/project-icons/3D.png"
                                onClick={() => {
                                  setCurrentView('3d');
                                  setModal3d(true);
                                }}
                              />
                            </Box>
                          </a>
                        ) : (
                          <Box
                            color="white"
                            bgcolor={currentView === '3d' ? '#DEF8FF' : '#FAFAFA'}
                            style={{ width: '30%', padding: '3%' }}
                            className="d-flex justify-content-center align-items-center">
                            <img
                              src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/project-icons/3D.png"
                              onClick={() => {
                                setSnack(true);
                                setSnackMessage('3d is not shared yet');
                              }}
                            />
                          </Box>
                        )}
                      </div>
                      <div className="row justify-content-end">
                        <span style={{ fontSize: '12px', paddingRight: '5%' }}>view in 3D</span>
                      </div>
                      <div className="row pl-3 pr-3 pt-3 mt-1 p-1 justify-content-end">
                        <Box
                          color="white"
                          bgcolor={currentView === '2d' ? '#DEF8FF' : '#FAFAFA'}
                          style={{ width: '30%', padding: '3%' }}
                          className="d-flex justify-content-center align-items-center">
                          <img
                            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/project-icons/2D.png"
                            onClick={() => {
                              setCurrentView('2d');
                            }}
                          />
                        </Box>
                      </div>
                      <div className="row justify-content-end">
                        <span style={{ fontSize: '12px', paddingRight: '5%' }}>view in 2D</span>
                      </div>
                    </div>
                  ) : null}
                  {currentView === '2d' && (
                    <div className="col-11 ">
                      <div className="m-3">
                        {finalBoard && (
                          <InterActiveMoodboard
                            data={data}
                            id={id}
                            projectData={projectData}
                            saveReviewMoodboard={saveReviewMoodboard}
                            handleCompleteSubmit={handleCompleteSubmit}
                            setDeclineModal={setDeclineModal}
                            page={page}
                            auth={auth}
                            history={history}
                            addToCartProducts={addToCartProducts}
                            setAddToCartProducts={setAddToCartProducts}
                            dimension={dimension}
                            savedProductIds={savedProductIds}
                            handleFavourites={handleFavourites}
                            disable={disable}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {currentView === '3d' /* projectData?.space && */ && (
                    <div className="col-10">
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        // className={classes.modal}
                        open={modal3d}
                        backdropClassName="backdrop"
                        onClose={() => {
                          setModal3d(false);
                          setCurrentView('2d');
                        }}
                        className={classnames(' mt-4')}
                        closeAfterTransition>
                        <div
                          className="row justify-content-center  my-0 mx-auto"
                          style={{
                            height: `${window.innerHeight * 0.7}px`,
                            width: `${window.innerHeight * 0.7 * 1.77}px`
                          }}>
                          <div
                            className="container item"
                            style={{
                              backgroundColor: '#2C2C2C',
                              paddingTop: '10px',
                              position: 'relative',
                              paddingBottom: dimension[0] > 500 ? '10px' : '20px'
                            }}>
                            <div
                              className={classnames('d-flex justify-content-end', style.closeBtn)}>
                              <img
                                src={
                                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                }
                                onClick={() => {
                                  setModal3d(false);
                                  setCurrentView('2d');
                                }}
                                alt=""
                              />
                            </div>
                            <iframe
                              src={`${process.env.REACT_APP_3D_URL}?projectId=${id}&spaceId=${
                                projectData?.space?._id
                              }&height=${window.innerHeight * 0.7 -
                                20}px&width=${window.innerHeight * 0.7 * 1.77 - 30}px`}
                              height={`${window.innerHeight * 0.7 - 20}px`}
                              width={`${window.innerHeight * 0.7 * 1.77 - 30}px`}></iframe>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {/*Mobile Page for Finalize concept shared*/}
        {page === 'FINALIZE_CONCEPT' && moodBoardAvailable && dimension[0] < 500 && (
          <div className="row w-100 ml-auto mr-auto mt-3">
            {finalBoard && (
              <InterActiveMoodboard
                data={data}
                id={id}
                projectData={projectData}
                saveReviewMoodboard={saveReviewMoodboard}
                handleCompleteSubmit={handleCompleteSubmit}
                setDeclineModal={setDeclineModal}
                page={page}
                auth={auth}
                history={history}
                addToCartProducts={addToCartProducts}
                setAddToCartProducts={setAddToCartProducts}
                dimension={dimension}
                savedProductIds={savedProductIds}
                handleFavourites={handleFavourites}
                disable={disable}
              />
            )}
          </div>
        )}
        {dimension[0] > 500 ? (
          <>
            {/*Web Page for Finalize concept bottom section*/}
            {page === 'FINALIZE_CONCEPT' && moodBoardAvailable && (
              <div className=" row w-100 mt-4 d-flex justify-content-center align-items-center">
                <RatingBox
                  setFinalModal={setFinalModal}
                  finalModal={finalModal}
                  setProjectRating={setProjectRating}
                  projectRating={projectRating}
                  flag={'project'}
                  designerRating={designerRating}
                  setDesignerRating={setDesignerRating}
                  disable={disable}
                />
              </div>
            )}

            {page === 'FINALIZE_CONCEPT' && moodBoardAvailable ? (
              <div
                className="container mt-5 d-flex flex-column text-center justify-content-center align-items-center"
                style={{ width: '70%' }}>
                <h5>Products from the Revised Concepts</h5>
                <span style={{ fontWeight: '500', color: '#868686' }}>
                  These items have been chosen especially for your room by our Designers, based on
                  your style, preferences and budget. Rate each product to let us know what you
                  think. Also, if anything falls short of your expectations, just let our Design
                  Team know and we’ll get you some new suggestions.
                </span>
              </div>
            ) : null}
            {page === 'FINALIZE_CONCEPT' && moodBoardAvailable && (
              <>
                <ProductList pageType="FINALIZE_CONCEPT" productsOnMoodboard={addToCartProducts} />
                <div className="container mt-5" style={{ width: '80%' }}>
                  <div className={style.container}>
                    <div className="d-flex flex-row text-left justify-content-between align-items-center">
                      <div style={{ width: '70%' }}>
                        <h5 style={{ fontWeight: '600' }}>Not yet in love with it?</h5>
                        <p style={{ fontWeight: '500' }}>
                          All of the curated and recommended products can be added directly to your
                          cart.
                          <br /> You may also remove, add and modify the quantity of items you wish
                          to purchase.
                        </p>
                      </div>
                      <button
                        className={style.shopButton}
                        onClick={() => {
                          addMoodBoardProductsToCart(moodboardProductsData);
                        }}>
                        Shop All Products
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {/*Mobile Page for Finalize concept bottom section*/}
            {page === 'FINALIZE_CONCEPT' && moodBoardAvailable && (
              <div className=" row w-100 mt-4 mr-auto ml-auto d-flex justify-content-center align-items-center">
                <RatingBox
                  setFinalModal={setFinalModal}
                  finalModal={finalModal}
                  setProjectRating={setProjectRating}
                  projectRating={projectRating}
                  flag={'project'}
                  designerRating={designerRating}
                  setDesignerRating={setDesignerRating}
                  disable={disable}
                />
              </div>
            )}

            {page === 'FINALIZE_CONCEPT' ? (
              <>
                <div
                  className="container mt-3 d-flex flex-column text-align-left align-items-center"
                  style={{ width: '100%' }}>
                  <h5 style={{ fontWeight: '700', textAlign: 'left' }}>
                    Products from the Revised Concepts
                  </h5>
                  <span
                    style={{
                      fontWeight: '500',
                      color: '#868686',
                      textAlign: 'left'
                    }}>
                    These items have been chosen especially for your room by our Designers, based on
                    your style, preferences and budget. Rate each product to let us know what you
                    think. Also, if anything falls short of your expectations, just let our Design
                    Team know and we’ll get you some new suggestions.
                  </span>
                </div>
                <ProductList pageType="FINALIZE_CONCEPT" productsOnMoodboard={addToCartProducts} />
                <div className="container mt-5" style={{ width: '100%' }}>
                  <div className={style.container}>
                    <div className="col-sm-12 col-lg-6 text-center">
                      <h5 style={{ fontWeight: '700' }}>Not yet in love with it?</h5>
                      <p style={{ fontWeight: '500' }}>
                        All of the curated and recommended products can be added directly to your
                        cart.
                        <br /> You may also remove, add and modify the quantity of items you wish to
                        purchase.
                      </p>
                    </div>
                    <div className="col-sm-12 col-lg-6 text-center">
                      <button
                        className={style.shopButton}
                        onClick={() => {
                          addToCart(moodboardProductsData);
                        }}>
                        Shop All Products
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>

      <Modal
        className={style.modal}
        aria-labelledby="simple-modal-title"
        onClose={() => setDeclineModal(false)}
        aria-describedby="simple-modal-description"
        open={declineModal === true}>
        <div className={style.modalContainer1} style={{ height: 'auto' }}>
          <div
            className="row  w-100 p-2"
            style={{
              borderBottom: '1px solid rgba(0,0,0,0.2)',
              backgroundColor: '#E5F9FF'
            }}>
            <div className="col-11 pr-0 d-flex justify-content-center align-items-center">
              <h5 className="m-0 p-0 ">
                {' '}
                <center>Tell us what went wrong</center>
              </h5>
            </div>
            <div className="col-1 p-0 d-flex justify-content-end align-items-end">
              <img
                src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'}
                onClick={() => setDeclineModal(false)}
                alt=""
              />
            </div>
          </div>
          <div className="p-5">
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <img
                  src={
                    moodBoards &&
                    moodBoards.shared_to_customer &&
                    moodBoards.shared_to_customer.preview_url
                      ? moodBoards.shared_to_customer.preview_url
                      : ''
                  }
                  alt=""
                  className={style.boardImage}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-sm-3">
                <div className="row">
                  {cancelReasons.map((reason, i) => {
                    return (
                      <button
                        id={`reason${i}`}
                        className={style.reasonButton}
                        onClick={() => {
                          if (reasons.includes(reason)) {
                            let filterReason = reasons.filter(r => r !== reason);
                            setReasons([...filterReason]);
                          } else {
                            setReasons([...reasons, reason]);
                          }
                        }}>
                        {reasons.includes(reason) ? (
                          <Icon.CheckCircleFill className="mr-1" />
                        ) : null}
                        {reason}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={classnames('col-12 mt-3', style.containerText)}>
              <textarea
                className="p-2"
                name="body"
                style={{
                  width: '100%',
                  height: dimension[0] > 500 ? '15vh' : '10vh',
                  marginTop: dimension[0] > 500 ? '20px' : '5px',
                  outline: 'none'
                }}
                onChange={reasonChange}
                defaultValue={
                  reasonInput && reasonInput.feedbackText ? reasonInput.feedbackText : ''
                }
                placeholder="Want to tell something?"
              />
              <Icon.MicFill
                className={style.microphoneIco1}
                onClick={() => {
                  setCurrentValue('mbFeedback');
                  setAudioModal(true);
                  setblobURL('');
                }}></Icon.MicFill>

              {reasonInput && reasonInput.feedbackAudio && (
                <div className="row">
                  <Icon.Trash
                    className={style.Trash}
                    onClick={() => {
                      reasonInput.feedbackAudio = '';
                      setReasonInput({ ...reasonInput });
                    }}></Icon.Trash>{' '}
                </div>
              )}
              {reasonInput && reasonInput.feedbackAudio && (
                <div className="row d-flex justify-content-start mt-2">
                  <ReactAudioPlayer
                    name="plan"
                    className={style.audio}
                    src={reasonInput && reasonInput.feedbackAudio ? reasonInput.feedbackAudio : ''}
                    controls
                  />
                </div>
              )}
            </div>
            <div className="col-12 d-flex justify-content-end align-items-end">
              <button
                className={classnames(style.buttonReason, 'mt-2 mb-2')}
                onClick={() => {
                  handleRequestSubmit();
                }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal1
        style={{ backgroundColor: 'transparent' }}
        show={thanksModal === true}
        onHide={() => setThanksModal(false)}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.thanks}>
          <div className={style.back}>
            <div
              className={classnames(
                'row mt-2 d-flex justify-content-end align-items-end ',
                style.modalHeader
              )}>
              {/* <img style={{ width: '14px', height: '14px', cursor: 'pointer' }} onClick={() => setThanksModal(false)} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'} alt='' /> */}
              <Icon.XCircle size="20" onClick={() => setThanksModal(false)} />
            </div>
            <div className="row mt-5 d-flex justify-content-center align-items-center">
              <Icon.CheckCircle size="30" />
            </div>
            <div className="row m-5 ">
              {finalComplete === true ? (
                <p
                  className={classnames(
                    ' col-12 d-flex justify-content-center align-items-center',
                    style.thanksText
                  )}>
                  Thank you for your rating.
                </p>
              ) : (
                <p
                  className={classnames(
                    ' col-12 d-flex justify-content-center align-items-center',
                    style.thanksText
                  )}>
                  Thank you for your feedback. Your designer will get back to you shortly
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal1>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // className={classes.modal}
        open={zoomModal}
        backdropClassName="backdrop"
        onClose={() => setZoomModal(false)}
        className={classnames(' mt-4')}
        closeAfterTransition>
        <div className="row justify-content-center">
          <div
            className="container item"
            style={{
              backgroundColor: '#2C2C2C',
              paddingTop: '10px',
              paddingBottom: dimension[0] > 500 ? '10px' : '20px'
            }}>
            <Box bgcolor="#2C2C2C">
              <div className="row">
                <div className="col-1">
                  <div className="row pl-3 pr-3 pt-3 d-flex justify-content-end  align-items-end">
                    <Box
                      color="white"
                      style={{
                        width: '50%',
                        padding: '3%',
                        opacity: currentView === 'AR' ? '' : '0.5'
                      }}
                      className="d-flex justify-content-center align-items-center">
                      <img
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/project-icons/AR+white.png"
                        onClick={() => {
                          setCurrentView('AR');
                        }}
                      />
                    </Box>
                  </div>
                  <div className="row d-flex justify-content-end">
                    <span
                      style={{
                        color: currentView === 'AR' ? 'white' : '#040505',
                        fontSize: '12px'
                      }}>
                      view in AR
                    </span>
                  </div>

                  <div className="row pl-3 pr-3 pt-3 mt-1 d-flex p-1 justify-content-end">
                    {projectData?.space ? (
                      <a
                        href={`${process.env.REACT_APP_3D_URL}?projectId=${id}&spaceId=${projectData?.space?._id}`}
                        target="_blank"
                        rel="noreferrer">
                        <Box
                          color="white"
                          style={{
                            width: '50%',
                            padding: '3%',
                            opacity: currentView === '3d' ? '' : '0.5'
                          }}
                          className="d-flex justify-content-center align-items-center">
                          <img
                            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/project-icons/3D+white.png"
                            onClick={() => {
                              setCurrentView('3d');
                              setModal3d(true);
                            }}
                          />
                        </Box>
                      </a>
                    ) : (
                      <Box
                        color="white"
                        style={{
                          width: '50%',
                          padding: '3%',
                          opacity: currentView === '3d' ? '' : '0.5'
                        }}
                        className="d-flex justify-content-center align-items-center">
                        <img
                          src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/project-icons/3D+white.png"
                          onClick={() => {
                            setSnack(true);
                            setSnackMessage('3d is not shared yet');
                          }}
                        />
                      </Box>
                    )}
                  </div>
                  <div className="row d-flex justify-content-end">
                    <span
                      style={{
                        color: currentView === '3d' ? 'white' : '#040505',
                        fontSize: '12px'
                      }}>
                      view in 3D
                    </span>
                  </div>
                  <div className="row pl-3 pr-3 pt-3 mt-1 d-flex p-1 justify-content-end">
                    <Box
                      color="white"
                      style={{
                        width: '50%',
                        padding: '3%',
                        opacity: currentView === '2d' ? '' : '0.5'
                      }}
                      className="d-flex justify-content-center align-items-center">
                      <img
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/project-icons/2D+white.png"
                        onClick={() => {
                          setCurrentView('2d');
                        }}
                      />
                    </Box>
                  </div>
                  <div className="row d-flex justify-content-end">
                    <span
                      style={{
                        color: currentView === '2d' ? 'white' : '#040505',
                        fontSize: '12px'
                      }}>
                      view in 2D
                    </span>
                  </div>
                </div>
                <div className="col-11">
                  <img
                    style={{ width: '80%', position: 'relative' }}
                    src={
                      moodBoards.shared_to_customer
                        ? moodBoards.shared_to_customer.preview_url
                        : moodBoards.preview_url
                    }
                    alt=" "
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = moodBoards.customer_preview_url;
                    }}
                  />
                </div>
              </div>
            </Box>
          </div>
        </div>
      </Modal>
      <Modal
        className="d-flex justify-content-center"
        aria-labelledby="simple-modal-title"
        onClose={() => setFinalModal(false)}
        aria-describedby="simple-modal-description"
        open={finalModal === true}
        style={{ overflow: 'scroll' }}>
        <div
          className={style.modalContainer3}
          style={{
            zIndex: 10,
            position: 'absolute'
          }}>
          <Box bgcolor="#E5F9FF" style={{ width: '100%', padding: '2%' }}>
            <div className="row p-2">
              <div className="col-8 d-flex justify-content-center align-items-center">
                <h5 className="m-0 p-0 ">
                  {' '}
                  <center>Feedback for Final Concept</center>
                </h5>
              </div>
              <div className="col-3 d-flex justify-content-end align-items-end">
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                  }
                  onClick={() => setFinalModal(false)}
                  alt=""
                />
              </div>
            </div>
          </Box>
          <div className="p-lg-5 p-md-5 p-sm-2">
            <div className="row d-flex justify-content-center align-items-center">
              <img
                src={
                  moodBoards &&
                  moodBoards.shared_to_customer &&
                  moodBoards.shared_to_customer.preview_url
                    ? moodBoards.shared_to_customer.preview_url
                    : ''
                }
                alt=""
                className={classnames(style.boardImage1, 'mb-4')}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = moodBoards.customer_preview_url;
                }}
              />
            </div>
            <div className=" row w-100 m-auto d-flex justify-content-center align-items-center">
              <RatingBox
                setFinalModal={setFinalModal}
                finalModal={finalModal}
                setProjectRating={setProjectRating}
                projectRating={projectRating}
                flag={'projectpop'}
                designerRating={designerRating}
                setDesignerRating={setDesignerRating}
                disable={disable}
              />
            </div>
            <div className="row w-80 m-auto pt-3 d-flex justify-content-center align-items-center">
              {overallFilteredTags.map((reason, i) => {
                return (
                  <button
                    id={`reason${i}`}
                    className={style.reasonButton1}
                    onClick={() => {
                      if (overallTags1.includes(reason)) {
                        let filterReason = overallTags1.filter(r => r !== reason);
                        setOverallTags1([...filterReason]);
                      } else {
                        setOverallTags1([...overallTags1, reason]);
                      }
                    }}>
                    {overallTags1.includes(reason) ? (
                      <Icon.CheckCircleFill className="mr-1" />
                    ) : null}
                    {reason}
                  </button>
                );
              })}
            </div>
            <div className="row mt-5 d-flex justify-content-center align-items-center">
              <h6 style={{ fontWeight: '700' }}>Designer Rating</h6>
            </div>
            <div className=" row mt-4  d-flex justify-content-center align-items-center">
              <Box color="white" bgcolor="#F5F0EC" className={style.box1}>
                <div className="row d-flex justify-content-center">
                  <span className={style.textBox}>
                    How would you like to rate the overall experience?
                  </span>
                </div>
                <div className="row mt-1 d-flex align-items-center justify-content-center">
                  <Rating
                    name="moodboardRating"
                    emptySymbol={
                      <img
                        src="https://d3izcfql7lesks.cloudfront.net/Path_1252_ixllee.svg"
                        style={{ width: '40px' }}
                        className="pl-3"
                      />
                    }
                    fullSymbol={
                      <img
                        src="https://d3izcfql7lesks.cloudfront.net/Path_1252_dp7bmx.svg"
                        style={{ width: '40px' }}
                        className="pl-3"
                      />
                    }
                    onClick={value => {
                      if (!disable.finaliseRate) setDesignerRating(value);
                    }}
                    initialRating={designerRating}
                  />
                </div>
              </Box>
            </div>
            <div className="row w-80 m-auto pt-3 d-flex justify-content-center align-items-center">
              {designerFilteredTags.map((reason, i) => {
                return (
                  <button
                    id={`reason${i}`}
                    className={style.reasonButton1}
                    onClick={() => {
                      if (designerTags1.includes(reason)) {
                        let filterReason = designerTags1.filter(r => r !== reason);
                        setDesignerTags1([...filterReason]);
                      } else {
                        setDesignerTags1([...designerTags1, reason]);
                      }
                    }}>
                    {designerTags1.includes(reason) ? (
                      <Icon.CheckCircleFill className="mr-1" />
                    ) : null}
                    {reason}
                  </button>
                );
              })}
            </div>

            <div className="row mt-3 d-flex justify-content-center">
              <h6 style={{ fontWeight: '700', textAlign: 'left' }}>Create a review</h6>
              <Box className={style.textAreabox}>
                <div className="row pr-2  d-flex justify-content-end align-items-end">
                  <small>
                    {projectFeedback && projectFeedback.text ? projectFeedback.text.length : 0}
                    /40 Characters
                  </small>
                </div>

                <div className={classnames('', style.containerText)}>
                  <textarea
                    name="body"
                    maxLength={40}
                    style={{
                      borderColor: 'transparent',
                      overflow: 'auto',
                      outline: 'none',
                      borderStyle: 'none',
                      width: dimension[0] > 500 ? '100%' : '80%',
                      height: dimension[0] > 500 ? '15vh' : '10vh',
                      marginTop: dimension[0] > 500 ? '0' : '5px'
                    }}
                    onChange={e => {
                      setProjectFeedback({
                        ...projectFeedback,
                        text: e.target.value
                      });
                    }}
                    defaultValue={
                      projectFeedback && projectFeedback.text ? projectFeedback.text : ''
                    }
                    placeholder="Want to tell something ?"
                  />
                  <Icon.MicFill
                    className={style.microphoneIco1}
                    onClick={() => {
                      setCurrentValue('finalReview');
                      setAudioModal(true);
                      setblobURL('');
                    }}></Icon.MicFill>

                  {projectFeedback && projectFeedback.audio && (
                    <div className="row">
                      <Icon.Trash
                        className={style.Trash}
                        onClick={() => {
                          projectFeedback.audio = '';
                          setProjectFeedback({ ...projectFeedback });
                        }}></Icon.Trash>{' '}
                    </div>
                  )}
                  {projectFeedback && projectFeedback.audio && (
                    <div className="row d-flex justify-content-start mt-2">
                      <ReactAudioPlayer
                        name="plan"
                        className={style.audio}
                        src={projectFeedback && projectFeedback.audio ? projectFeedback.audio : ''}
                        controls
                      />
                    </div>
                  )}
                </div>
              </Box>
            </div>
            <div className="col-12 d-flex justify-content-end align-items-end">
              <button
                className={style.buttonReason}
                onClick={() => {
                  handleFinalSubmit();
                }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className={style.modal}
        aria-labelledby="simple-modal-title"
        onClose={() => setAudioModal(false)}
        aria-describedby="simple-modal-description"
        open={audioModal === true}>
        <div
          className={style.modalContainer}
          style={{
            paddingTop: '10px'
          }}>
          <div className="col-12 d-flex justify-content-end">
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'}
              onClick={() => {
                stopTimer();
                setrecordstate(false);
                //  setAudioRecorded(false);
                setAudioModal(false);
              }}
              alt=""
            />
          </div>
          <AudioReactRecorder
            canvasHeight={startAudio ? 200 : 0}
            canvasWidth={startAudio ? 300 : 0}
            className={style.audioContainer}
            state={recordstate}
            onStop={onStop}
          />
          <div className={classnames('row d-flex justify-content-center')}>
            <Box className="pl-5 pr-5 pt-2 pb-2 " style={{ backgroundColor: 'grey' }}>
              <span className="minute">{minute}</span>
              <span>:</span>
              <span className="second">{second}</span>
            </Box>
          </div>
          <div className="row " style={{ marginRight: '5px' }}>
            <div
              className="col-sm-4 col-lg-4 col-md-4 col-xl-4 col-4 d-flex justify-content-start p-3"
              style={{ textAlign: 'center' }}>
              <button
                style={{ marginTop: '2%' }}
                className={classnames(style.wishlistButton, '')}
                onClick={() => {
                  setIsActive(true);
                  start();
                }}>
                Start
              </button>
              <br></br>
            </div>
            <div
              className="col-sm-4 col-lg-4 col-md-4 col-xl-4 col-4 d-flex justify-content-start p-3"
              style={{ textAlign: 'center' }}>
              <button
                style={{ marginTop: '2%' }}
                className={classnames(style.wishlistButton, '')}
                onClick={() => {
                  setIsActive(false);
                  stop();
                }}>
                Stop
              </button>
              <br></br>
            </div>
            <div
              className="col-sm-4 col-lg-4 col-md-4 col-xl-4 col-4 d-flex justify-content-start p-3"
              style={{ textAlign: 'center' }}>
              <button
                style={{ marginTop: '2%' }}
                className={classnames(style.wishlistButton, '')}
                onClick={() => {
                  stopTimer();
                  setIsActive(true);
                  reset();
                }}>
                Reset
              </button>
              <br></br>
            </div>
          </div>
          <ReactAudioPlayer src={blobURL} controls />
        </div>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={ratePrompt === true}
        onClose={() => {
          setRatePrompt(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        style={{ display: moodBoardAvailable ? 'flex' : 'none' }}>
        <Fade in={ratePrompt === true}>
          <div
            className={classnames(classes.paper1, style.modalContainer)}
            style={{ height: dimension[0] > 500 ? '' : '95vh' }}>
            <div className={classnames(style.ShareModalHeader, 'justify-content-end h-auto')}>
              <div
                className={classnames('mr-2 mt-1')}
                style={{ cursor: 'pointer' }}
                onClick={() => setRatePrompt(false)}>
                <Icon.X
                  className=""
                  style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}
                  //onClick={() => setModal({ ...modal, modal: false, name: "" })}
                />
              </div>
            </div>
            {dimension[0] > 500 ? (
              <div className={style.placeholderContainer} style={{ width: '100%', height: '100%' }}>
                <div className={style.placeholderCentre} style={{ height: '100%', width: '100%' }}>
                  <div className={style.innerContText}>
                    <div
                      style={{
                        textAlign: 'left',
                        fontSize: '24px',
                        fontWeight: '700'
                      }}>
                      Your feedback and rating <br />
                      would mean the world to us.
                      <br />
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '18px',
                          fontWeight: '600'
                        }}>
                        (And will make us better)
                        <br />
                        <br />
                        It would only take a minute
                      </div>
                    </div>

                    <div>
                      Our designer will get back to you shortly. <br />
                      If you have any queries, call us on +91-9176976111
                    </div>
                  </div>
                  <div className={style.innerCont}>
                    <img
                      src={ratingPending}
                      style={{
                        height: '95%',
                        width: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <img
                  src={ratingPendingMob}
                  style={{
                    height: '50%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '22px',
                    fontWeight: '700'
                  }}>
                  Your feedback and rating would mean the world to us.
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: '600'
                    }}>
                    <p>(And will make us better)</p>
                    <br />
                    It would only take a minute
                  </div>
                </div>

                <div
                  className={'mt-1'}
                  style={{
                    textAlign: 'center'
                  }}>
                  Our designer will get back to you shortly. <br />
                  If you have any queries, call us on +91-9176976111
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <Drawer
        anchor={'right'}
        open={upgradeDrawer}
        classes={{
          paper: dimension[0] > 500 ? classes1.paper : classes2.paper
        }}>
        <div className="">
          <div className="row d-flex col-12 justify-content-end">
            <Icon.X
              size={30}
              color="white"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setUpgradeDrawer(false);
              }}
            />
          </div>
          {viewDetails === false ? (
            <>
              <div className="row d-flex justify-content-center align-items-center">
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4849+(2).png"
                  style={{ width: '80%' }}
                />
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <span className={style.upgradeText}>Want to see your moodboard in AR</span>
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <button
                  className={style.upgradebtn}
                  onClick={() => {
                    history.push({
                      pathname: `/package-payment/${projectData._id}`,
                      state: {
                        navbar: true,
                        project_id: projectData._id,
                        upgrade: true
                      }
                    });
                  }}>
                  Upgrade
                </button>
              </div>
              <div className="row mt-3 d-flex justify-content-center align-items-center">
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '20px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setViewDetails(true);
                  }}>
                  <small>
                    <u>View details</u>
                  </small>
                </span>
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="row d-flex justify-content-center">
                <Card
                  style={{
                    width: '80%',
                    backgroundColor: 'white',
                    borderRadius: '30px',
                    marginTop: '20%'
                  }}>
                  <CardContent>
                    <div className="row mt-3 d-flex justify-content-center">
                      <span className={style.packageTitle}>
                        <bold>
                          <u>Enhanced</u>
                        </bold>
                      </span>
                    </div>
                    <div className="row mt-2 d-flex justify-content-center">
                      <span className={style.packageSubTitle}>Rs.3000 /-</span>
                    </div>
                    <div className="row p-2 m-2 d-flex justify-content-center">
                      <span className={style.packageDesc}>
                        Give your blank canvas the VIP treatment with a personalised, detailed
                        transformation.
                      </span>
                    </div>
                    {[0, 1, 2].map(item => (
                      <div className="row p-2 m-2 " style={{ borderBottom: '1px groove' }}>
                        <Icon.CheckCircle color="#1AD537"></Icon.CheckCircle>{' '}
                        <span className={classnames('ml-2', style.packageDesc)}>
                          {enhancedFeatures[item]}
                        </span>
                      </div>
                    ))}
                    <div className="row mt-3 d-flex justify-content-center align-items-center">
                      <button
                        className={style.upgradebtn}
                        onClick={() => {
                          history.push({
                            pathname: `/package-payment/${projectData._id}`,
                            state: {
                              navbar: true,
                              project_id: projectData._id,
                              upgrade: true
                            }
                          });
                        }}>
                        Upgrade
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

Review.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  rooms: state.questionnaireData.data
});

export default connect(mapStateToProps, {
  storeCartCount,
  storeMoodboardProduct,
  storeMoodBoardData
})(withRouter(Review));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
