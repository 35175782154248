import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { page2, questionnaire, storeTab } from '../../store/actions/sampleaction';
import style from '../../../src/pages/Rooms/Rooms.module.css';
import classnames from 'classnames';
import Radio from '@material-ui/core/Radio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import ConsoleHelper from '../../utils/ConsoleHelper';

const theme = createTheme({
  palette: {
    primary: { main: '#FF4E00' }, // Purple and green play nicely together.
    secondary: { main: '#11cb5f' } // This is just green.A700 as hex.
  }
});
const Tabs = ({ active, questionnaire, storeTab, page2 }) => {
  const [level, setLevel] = useState(0);

  useEffect(() => {
    if (active === 'questionnaire') {
      setLevel(1);
    } else if (active == 'questionnaire2') {
      setLevel(2);
    } else if (active == 'package') {
      setLevel(3);
    }
  }, [active]);

  // ConsoleHelper(level, "level");
  return (
    <MuiThemeProvider theme={theme}>
      <div
        style={{
          height: '53px',
          backgroundColor: '#F5F5F5',
          overflow: 'hidden',
          marginBottom: '-5px',
          marginTop: '48px'
        }}>
        <div
          className={classnames(
            style.stepperContainer,
            'd-flex flex-row justify-content-center align-items-center'
          )}
          style={{ zIndex: '10' }}>
          <div
            className={active === 'questionnaire' && 'tabs-active'}
            style={{
              paddingTop: '5px',
              fontSize: '13px',
              fontWeight: 700,
              fontFamily: 'Quicksand',
              cursor: 'pointer'
            }}
            onClick={() => {
              questionnaire('questionnaire');
              storeTab('questionnaire');
            }}>
            {level > 0 ? (
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontFamily: 'Quicksand',
                  paddingRight: '8px'
                }}
                onClick={() => {
                  questionnaire('questionnaire');
                  storeTab('questionnaire');
                }}
              />
            ) : (
              <Radio
                checked={active === 'questionnaire'}
                color="primary"
                className={classnames(style.navbarRadioBtn)}
                onClick={() => {
                  questionnaire('questionnaire');
                  storeTab('questionnaire');
                }}
              />
            )}
            STYLE
          </div>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>--------</div>
          <div
            className={active === 'questionnaire2' && 'tabs-active'}
            style={{
              paddingTop: '5px',
              fontSize: '13px',
              fontWeight: 700,
              fontFamily: 'Quicksand',
              cursor: 'pointer'
            }}
            onClick={() => {
              questionnaire('questionnaire2');
              storeTab('questionnaire2');
              page2(0);
            }}>
            {level > 2 ? (
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontFamily: 'Quicksand',
                  paddingRight: '8px'
                }}
                onClick={() => {
                  questionnaire('questionnaire2');
                  storeTab('questionnaire2');
                  page2(0);
                }}
              />
            ) : (
              <Radio
                checked={active === 'questionnaire2'}
                color="primary"
                className={classnames(style.navbarRadioBtn)}
                onClick={() => {
                  questionnaire('questionnaire2');
                  storeTab('questionnaire2');
                  page2(0);
                }}
              />
            )}{' '}
            TIME
          </div>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>--------</div>
          <div
            className={active === 'package' && 'tabs-active'}
            style={{
              paddingTop: '5px',
              fontSize: '13px',
              fontWeight: 700,
              fontFamily: 'Quicksand',
              cursor: 'pointer'
            }}
            onClick={() => {
              questionnaire('package');
              storeTab('package');
            }}>
            <Radio
              checked={active === 'package'}
              color="primary"
              className={classnames(style.navbarRadioBtn)}
              onClick={() => {
                questionnaire('package');
                storeTab('package');
              }}
            />{' '}
            PACKAGE
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

Tabs.propTypes = {
  questionnaire: PropTypes.string
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaireData.active,
  active: state.questionnaireData.active
});

export default connect(mapStateToProps, { questionnaire, storeTab, page2 })(Tabs);
