import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
//import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import style from './Search.module.css';
import axios from 'axios';
//import { event } from 'react-ga';

const theme = createTheme({
  palette: {
    primary: {
      main: '#EEEAE6' // Set your desired color here
    }
  }
});

// const useStyles = makeStyles((theme) => ({
//   customOption: {
//     listStyleType: 'none',
//     paddingLeft: '4px',
//     paddingBottom: '4px',
//     fontWeight: '400',
//     color: '#171717',
//     backgroundColor: '#EEEAE6', // Background color for each option
//     '&:hover': {
//       backgroundColor: '#FFFFFF',
//       cursor: 'pointer' // Background color on hover
//     }
//   }
// }));

export const Searchbar = ({ history, searchparams }) => {
  // const classes = useStyles();
  // const [value, setValue] = useState([]);
  const [searchParams, setsearchParams] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [tag, setTag] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [catid, setCatid] = useState('');
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  // useEffect(() => {
  //   const tagdata = async () => {
  //     const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/v1/tags`);
  //     // setTag(res.data.data);
  //     // console.log(res.data.data)
  //     const tg = [];
  //     for (const dat of res.data.data) {
  //       console.log(dat);
  //       if (dat.type == 'shop' && dat.parent_id != '0') {
  //         tg.push(dat);
  //       }
  //     }
  //     // console.log(tg)
  //     setTag(tg);
  //   };

  //   tagdata();
  // }, []);
  // console.log(inputValue, searchParams);

  useEffect(() => {
    if (searchParams.length > 0) {
      setIsOptionsOpen(true);
    } else {
      setIsOptionsOpen(false);
    }
  }, [searchParams]);

  useEffect(() => {
    const tagdata = async () => {
      const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/v1/tags`);

      const tg = [];
      const alltags = [];
      for (const dat of res.data.data) {
        alltags.push(dat);
        if (dat.parent_id == '0' && dat.name == 'category') {
          setCatid(dat._id);
        }
        if (dat.type == 'shop' && dat.parent_id != '0') {
          if (dat.parent_id == catid) {
            tg.unshift(dat);
          } else {
            tg.push(dat);
          }
        }
      }
      setTag(tg);
      setAllTags(alltags);
    };
    tagdata();
  }, [catid]);

  const handleChange = (newValue) => {
    // setValue([...newValue]);
    setsearchParams([...newValue]);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchpage();
    }
  };
  const searchpage = () => {
    // console.log(searchParams[0].parent_id, searchParams[0]._id)
    // console.log(allTags.find((tg)=> tg._id === searchParams[0].parent_id))
    // var searchinput = { search: inputValue };
    var searchinput = {};
    var input = inputValue.toLowerCase();
    if (input.includes('under')) {
      var searchval = input.split(' ');
      var temp_input = [];
      searchval.map((sv) => {
        if (sv !== 'under' && isNaN(parseInt(sv))) {
          temp_input.push(sv);
        } else if (!isNaN(parseInt(sv))) {
          searchinput['price_to'] = sv;
        }
      });
      if (temp_input.join(' ') != ''){
        searchinput['search'] = temp_input.join(' ');
      }
    }else if (inputValue !== ''){
      searchinput['search'] = inputValue
    }
    // console.log(searchinput);
    var to_search = {};
    searchParams.forEach((srchprms) => {
      var value = {};
      var temp = allTags.find((tg) => tg._id === srchprms.parent_id);
      if (temp.parent_id == 0) {
        value[temp.name] = srchprms.name;
      } else {
        value['subcategory[]'] = srchprms.name;
      }
      to_search = { ...to_search, ...value };
    });
    // if (inputValue !== '') {
      to_search = { ...to_search, ...searchinput };
    // }
    console.log(to_search)
    const loc = new URL(window.location.href).pathname.split('/');
    // console.log(loc)
    if (loc[1] === 'mainproduct') {
      // var urlsearchparam = new URLSearchParams(window.location.search)
      // console.log(searchparams)
      const del = ['vendor_id','search', 'material[]',"material","color", 'style[]', 'color[]', 'price_from', 'price_to' ,"category","subcategory[]" , "subcategory", "room_type"];
      del.forEach((d) => searchparams.delete(d));

      for (const key in to_search) {
        if (to_search.hasOwnProperty(key)) {
          searchparams.set(key, to_search[key]);
        }
      }
      // console.log(searchparams.toString())
      window.history.replaceState(null, '', `?${searchparams.toString()}`);
      window.location.reload()
    }else{
      // console.log(to_search)
      history.push(
        {
          pathname : '/mainproduct',
          state : {...to_search}
        }
      )
    }
    // localStorage.setItem('search', JSON.stringify(searchParams));
    // localStorage.setItem('search2', inputValue);
    // history.push('/productsearch');
  };

  // const optionStyle = (provided, state) => ({
  //   ...provided,
  //   backgroundColor: state.isSelected ? '#7B8569' : state.isFocused ? '#E6E6E6' : 'white',
  //   color: state.isSelected ? 'white' : 'black'
  // });

  // const options = tag.map((option) => ({
  //   value: option.name,
  //   label: option.name
  // }));
  // console.log(options);
  return (
    <div className={style.searchContainer}>
      <ThemeProvider theme={theme}>
        <Autocomplete
          className={style.searchbar}
          multiple
          placeholder="Search"
          id="tags-standard"
          //open={true}
          onOpen={() => setIsOptionsOpen(true)}
          options={tag}
          getOptionLabel={(option) => option.name}
          onKeyDown={handleKeyDown}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          onInputChange={(event, newInputvalue) => {
            setInputValue(newInputvalue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                style={{
                  backgroundColor: '#7B8569',
                  color: 'fffff',
                  height: '25px',
                  color: ' #ffffff',
                  borderRadius: '5px'
                }}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          ListboxComponent={(props) => (
            <Paper {...props} style={{ margin: '0px', padding: '0px', borderRadius: '0' }} />
          )} // Use the custom PaperComponent here
          renderOption={(props, option) => (
            <li {...props} className={style.option}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <div
              className={style.inputTxt}
              style={{
                backgroundColor: isOptionsOpen ? '#EEEAE6' : 'transparent',
                display: 'flex',
                alignItems: 'center'
              }}>
              <TextField
                {...params}
                variant="standard"
                placeholder="I'm looking for..."
                inputProps={{
                  ...params.inputProps,
                  style: {
                    color: isOptionsOpen ? '#171717' : '#C8C8C8',
                    paddingLeft: '5px',
                    borderBottom: 'none',
                    outline: 'none'
                  }
                }}
              />
              <img
                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/search.png"
                alt="search"
                className={style.searchLogo}
                onClick={() => searchpage()}
              />
            </div>
          )}
        />
      </ThemeProvider>
    </div>
  );
};
