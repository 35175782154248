import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import style from './Review.module.css';
import classnames from 'classnames';
import BlueTick from '../../../assets/blueTick.svg';
import Modal from '@material-ui/core/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import emailjs from '@emailjs/browser';
import { useHistory } from 'react-router';
import QRScanner from '../../../components/layout/QrScanner';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon
} from 'react-share';
import * as Icon from 'react-bootstrap-icons';
import Shareurl from '../../../components/Shareurl';
import ConsoleHelper from '../../../utils/ConsoleHelper';

const ProductListing = props => {
  const userName = localStorage.getItem('userName');
  const [open, setOpen] = useState({});
  const copy = useRef();
  const dimension = useMediaQuery();
  const [currentProduct, setCurrentProduct] = useState({});
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [productIds, setProductId] = useState();
  const [modalOpen, setModalOpen] = useState();
  const [copiedUrl, setCopiedUrl] = useState('');
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  const [pageNo, setPageNo] = useState(0);
  const history = useHistory();
  const form = useRef();

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      boxShadow: theme.shadows[5],
      outline: 'none'
    },
    papers: {
      outline: 'none'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      border: 'none',
      width: 800,
      height: 500,
      padding: theme.spacing(2, 4, 3),
      outline: 'none'
    }
  }));

  const classes = useStyles();
  const index = props.products.findIndex(p => p._id === props.activeObject.productId);
  let product = [{ ...props.products[index], ...props.activeObject }];
  let alternateProducts = [];
  const findAlternateProducts = props.moodboard.filter((item, index) => {
    if (item.uuid === props.activeObject.uuid && item.visible === false) {
      return {
        item
      };
    }
  });
  findAlternateProducts.map((item, index) => {
    props.products.map((list, index) => {
      if (item.productId === list._id) {
        alternateProducts = [...alternateProducts, { ...list, ...item }];
      }
    });
  });

  props.products.map((list, index) => {
    if (props.activeObject.productId === list._id) {
      product = [...product, { ...props.activeObject, ...list }];
    }
  });

  const handleShareProduct = () => {
    let url = window.location.href.split('/');
    setCopiedUrl(`${url[0]}//${url[2]}/product/${open?.data?._id}`);
  };
  const handleClose = () => {
    setOpen({ ...open, modal: false });
  };

  const handleQr = productIds => {
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = productIds => {
    history.push(`/3d-model/${productIds}`);
  };

  const sendEmail = e => {
    e.preventDefault();

    emailjs.sendForm('service_dc6bf0u', 'template_byt0qp9', form.current, 'u-JUBaCmUc6toe0bh').then(
      () => {
        setSnack(true);
        setSnackMessage('Request AR send to Mail Successfully');
      },
      error => {
        setSnack(false);
        setSnackMessage('Request AR Error');

        ConsoleHelper(error.text);
      }
    );
  };
  useEffect(() => {
    handleShareProduct();
  }, [open]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {props.dimension[0] > 500 ? (
        <div
          style={{
            fontSize: '13px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: props.height
          }}
          className={style.customScroll}>
          <div className="row w-100 m-auto">
            <div
              className="row mt-2 w-100"
              style={{
                height: '95px',
                borderRadius: '4px',
                backgroundColor: '#ffffff',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}>
              <div className="col-4 p-2 h-100 d-flex justify-content-center align-items-center">
                <img
                  src={product[0]?.src}
                  style={{
                    height: '90px',
                    width: '90px',
                    objectFit: 'cover',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setCurrentProduct(product[0]);
                    setOpen({ ...open, modal: true, data: product[0] });
                  }}
                />
              </div>
              <div className="col-8 pl-0 pr-2 d-flex align-items-center">
                <div
                  className="d-flex w-100 flex-column"
                  style={{ fontSize: '14px', fontWeight: '700' }}>
                  <div
                    className={style.ellipsis}
                    title={product[0]?.name}
                    onClick={() => {
                      setCurrentProduct(product[0]);
                      setOpen({ ...open, modal: true, data: product[0] });
                    }}
                    style={{ cursor: 'pointer' }}>
                    {product[0]?.name}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#9E9E9E'
                    }}>
                    {product[0]?.vendor_id?.name}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>{new Intl.NumberFormat('en-IN').format(product[0]?.price)}</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(!alternateProducts?.length && (
              <div className="ml-2">
                <p>No Alternate Product available.</p>
              </div>
            )) ||
              alternateProducts?.map(item => (
                <div
                  className="row mt-2 w-100"
                  style={{
                    height: '95px',
                    borderRadius: '4px',
                    backgroundColor: '#ffffff',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}>
                  <div className="col-4 p-2 h-100 d-flex justify-content-center align-items-center">
                    <img
                      src={item?.src}
                      style={{
                        height: '90px',
                        width: '90px',
                        objectFit: 'contain',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setOpen({ ...open, modal: true, data: item });
                      }}
                    />
                  </div>
                  <div className="col-8 pl-0 pr-2 d-flex align-items-center">
                    <div
                      className="d-flex w-100 flex-column"
                      style={{ fontSize: '14px', fontWeight: '700' }}>
                      <div
                        className={style.ellipsis}
                        title={item?.name}
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOpen({ ...open, modal: true, data: item });
                        }}>
                        {item?.name}
                      </div>
                      <div
                        style={{
                          fontSize: '12px',
                          fontWeight: '600',
                          color: '#9E9E9E'
                        }}>
                        {item?.vendor_id?.name}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>{new Intl.NumberFormat('en-IN').format(item?.price)}</div>
                        <div
                          style={{
                            fontSize: '12px',
                            fontWeight: '700',
                            backgroundColor: '#4895D9',
                            color: '#FFFFFF',
                            padding: '5px 7px',
                            borderRadius: '3px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            props.handleVisibleAction(item.productId, item.uuid, item.layerId);
                          }}>
                          Try this item
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <>
          <div className="row w-100 m-auto">
            <Carousel
              controls={false}
              indicators={false}
              touch={true}
              onSlide={(event, direction) => {
                if (direction === 'left') {
                  if (pageNo - 1 < 0) {
                    setPageNo(alternateProducts?.length - 1);
                  } else {
                    setPageNo(pageNo - 1);
                  }
                } else if (direction === 'right') {
                  if (pageNo + 1 > alternateProducts?.length - 1) {
                    setPageNo(0);
                  } else {
                    setPageNo(pageNo + 1);
                  }
                }
              }}
              swipeable={true}>
              <Carousel.Item interval={1000000}>
                <div
                  className="row mt-2 w-100"
                  style={{
                    height: '95px',
                    borderRadius: '4px',
                    backgroundColor: '#ffffff',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}>
                  {/* {ConsoleHelper(product[0], "selected")} */}
                  <div className="col-4 p-2 h-100 d-flex justify-content-center align-items-center">
                    <img
                      src={product[0]?.src}
                      style={{
                        height: '90px',
                        width: '90px',
                        objectFit: 'cover'
                      }}
                    />
                  </div>
                  <div className="col-8 pl-0 pr-2 d-flex align-items-center">
                    <div
                      className="d-flex w-100 flex-column"
                      style={{ fontSize: '14px', fontWeight: '700' }}>
                      <div
                        className={style.ellipsis}
                        title={product[0]?.name}
                        onClick={() => {
                          setCurrentProduct(product[0]);
                          setOpen({ ...open, modal: true, data: product[0] });
                        }}>
                        {product[0]?.name}
                      </div>
                      <div
                        style={{
                          fontSize: '12px',
                          fontWeight: '600',
                          color: '#9E9E9E'
                        }}>
                        {product[0]?.vendor_id?.name}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>{new Intl.NumberFormat('en-IN').format(product[0]?.price)}</div>
                        <div>
                          <img src={BlueTick} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              {alternateProducts?.map(item => (
                <Carousel.Item interval={1000000}>
                  <div
                    className="row mt-2 w-100"
                    style={{
                      height: '95px',
                      borderRadius: '4px',
                      backgroundColor: '#ffffff',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}>
                    <div className="col-4 p-2 h-100 d-flex justify-content-center align-items-center">
                      <img
                        src={item?.src}
                        style={{
                          height: '90px',
                          width: '90px',
                          objectFit: 'contain',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOpen({ ...open, modal: true, data: item });
                        }}
                      />
                    </div>
                    <div className="col-8 pl-0 pr-2 d-flex align-items-center">
                      <div
                        className="d-flex w-100 flex-column"
                        style={{ fontSize: '14px', fontWeight: '700' }}>
                        <div
                          className={style.ellipsis}
                          title={item?.name}
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setOpen({ ...open, modal: true, data: item });
                          }}>
                          {item?.name}
                        </div>
                        <div
                          style={{
                            fontSize: '12px',
                            fontWeight: '600',
                            color: '#9E9E9E'
                          }}>
                          {item?.vendor_id?.name}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>{new Intl.NumberFormat('en-IN').format(item?.price)}</div>
                          <div
                            style={{
                              fontSize: '12px',
                              fontWeight: '700',
                              backgroundColor: '#4895D9',
                              color: '#FFFFFF',
                              padding: '5px 7px',
                              borderRadius: '3px',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              props.handleVisibleAction(item.productId, item.uuid, item.layerId);
                            }}>
                            Try this item
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          {alternateProducts?.length > 0 && (
            <div className="row w-100 m-auto p-2">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="d-none">{alternateProducts.push(product[0])}</div>

                {alternateProducts.map((item, index) => (
                  <div
                    // onClick={() => {
                    //   setPageNo(index);
                    // }}
                    className={
                      'ml-2 ' +
                      (pageNo == index
                        ? classnames(style.paginationDotActive)
                        : classnames(style.paginationDot))
                    }></div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open.modal}>
          <div
            className={classnames(classes.paper, style.modalContainer)}
            style={{ height: props.dimension[0] > 500 ? '' : '100vh' }}>
            <div className="d-flex justify-content-between align-items-center w-100">
              <div
                className="d-flex justify-content-around align-items-center flex-row "
                style={{ display: 'flex' }}>
                <div
                  className={style.heart}
                  style={{
                    marginTop: '-1%',
                    backgroundPosition:
                      props.auth &&
                      props.auth.user &&
                      props.savedProductIds?.includes(open.data?._id)
                        ? 'right'
                        : 'left',
                    display: props.dimension[0] > 500 ? '' : 'none'
                  }}
                  onClick={() => {
                    //  setAnimation(!animation);
                    props.handleFavourites(open.data);
                  }}></div>
                {/* <img
                  onClick={() => {
                    handleShareProduct();
                    setShareModal(true);
                    setModal({ ...modal, modal: true, name: "share" });
                  }}
                  style={{ display: props.dimension[0] > 500 ? "" : "none" }}
                  src={
                    "https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2425.svg"
                  }
                  alt=""
                /> */}
                <div
                  style={{
                    marginTop: '2%',
                    marginLeft: '10px'
                  }}>
                  {' '}
                  <Shareurl fabdirection="right" copiedUrl={copiedUrl} />
                </div>
              </div>
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen({ ...open, modal: false })}
                src={
                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2420.svg'
                }
                alt=""
              />
            </div>
            <input
              ref={copy}
              type="hidden"
              value={`${window.location.host}/product/${open.data?._id}`}
            />
            <div
              className="container"
              style={{
                width: props.dimension[0] > 500 ? '80%' : '100%',
                marginTop: props.dimension[0] < 500 ? '-1%' : '5%',
                padding: props.dimension[0] > 500 ? '' : '0px'
              }}>
              <div className="row">
                <div className="col-sm-12 col-lg-5">
                  <img src={open.data?.image[0]} style={{ width: '100%' }} alt="" />
                </div>
                <div
                  className="row"
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    zIndex: '1000',
                    marginTop: '-1.8em',
                    marginLeft: '0',
                    display: props.dimension[0] < 500 ? '' : 'none'
                  }}>
                  <div
                    className={style.heart}
                    style={{
                      marginTop: '2%',
                      backgroundPosition:
                        props.auth &&
                        props.auth.user &&
                        props.savedProductIds?.includes(open.data?._id)
                          ? 'right'
                          : 'left',
                      display: props.dimension[0] < 500 ? '' : 'none',
                      backgroundColor: 'white'
                    }}
                    onClick={() => {
                      // setAnimation(!animation);
                      props.handleFavourites(open.data);
                    }}></div>
                  <div
                    style={{
                      display: props.dimension[0] < 500 ? '' : 'none',
                      marginTop: '2%',
                      marginLeft: '10px'
                    }}>
                    {' '}
                    <Shareurl fabdirection="right" copiedUrl={copiedUrl} />
                  </div>
                  {/* <img
                    onClick={() => {
                      //  handleShareProduct();
                      //  setShareModal(true);
                      //  setModal({ ...modal, modal: true, name: 'share' });
                    }}
                    // onClick={() => {
                    //   // handleShareProduct();
                    //   // setShareModal(true);
                    //   // setModal({ ...modal, modal: true, name: "share" });
                    // }}
                    style={{ display: props.dimension[0] < 500 ? "" : "none" }}
                    src={
                      "https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2425.svg"
                    }
                    alt=""
                  /> */}
                </div>
                <div
                  className="col-sm-12 col-lg-7"
                  style={{
                    textAlign: props.dimension[0] < 500 ? 'center' : ''
                  }}>
                  <h4>{open.data?.name}</h4>
                  <h4>
                    <b>Rs. {new Intl.NumberFormat('en-IN').format(open.data?.price)} </b>
                    <span style={{ opacity: 0.7 }}>
                      <s>Rs. {new Intl.NumberFormat('en-IN').format(open.data?.price)}</s>
                    </span>{' '}
                  </h4>
                  <br></br>
                  <p>Color: {open.data?.color}</p>
                  <p>Material: {open.data?.material}</p>
                  <p>
                    Dimensions: {open.data?.dimensions.l} {" '' "}L * {open.data?.dimensions.b}{' '}
                    {" '' "}W{' '}
                  </p>
                  {open?.data && open.data['3d_models']?.glbFileUrl ? (
                    <button
                      // onClick={() => dimension[0] > 500 ? handleQr(data._id) : mobileHandle3D(data._id)}
                      onClick={() =>
                        dimension[0] > 500
                          ? handleQr(currentProduct?._id)
                          : mobileHandle3D(currentProduct?._id)
                      }
                      className={style.arPart}>
                      <img
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Arpath.png"
                        className={style.arImg}
                      />
                      <p className={style.view}>View in AR</p>
                    </button>
                  ) : (
                    <>
                      <form ref={form} onSubmit={sendEmail}>
                        {/* <button
                          type="submit"
                          value="Send"
                          className={style.arPart}
                        >
                          <img
                            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Arpath.png"
                            className={style.arImg}
                          />
                          <p className={style.view}>Request in AR</p>
                        </button> */}
                      </form>

                      <form
                        ref={form}
                        onSubmit={sendEmail}
                        style={{ visibility: 'hidden', position: 'absolute' }}>
                        <input type="text" value={currentProduct?.name} name="name" />
                        <input type="text" value={currentProduct?._id} name="productId" />
                        <input type="text" value={currentProduct?.category} name="category" />
                        <input type="text" value={currentProduct?.price} name="price" />
                        <input type="text" value={userName} name="userName" />
                      </form>
                    </>
                  )}
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <button
                      className={style.buttonWhite}
                      onClick={() => props.history.push(`/product/${open.data?._id}`)}
                      style={{
                        whiteSpace: props.dimension[0] < 500 ? 'nowrap' : ''
                      }}>
                      View Full Details
                    </button>
                    <button
                      className={classnames(style.buttonBlack, 'ml-2')}
                      onClick={() => {
                        props.handleVisibleAction(
                          open.data?.productId,
                          open.data?.uuid,
                          open.data?.layerId
                        );
                        handleClose();
                      }}>
                      Try this Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal.name === 'share'}
        onClose={() => setModal({ ...modal, modal: false, name: '' })}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
        <div className={style.modalImage}>
          <div className={classnames(style.ShareModalHeader, 'justify-content-end h-auto')}>
            <div
              className={classnames('mr-2 mt-1')}
              style={{ cursor: 'pointer' }}
              onClick={() => setModal({ ...modal, modal: false, name: '' })}>
              <Icon.X
                className=""
                style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}
                onClick={() => setModal({ ...modal, modal: false, name: '' })}
              />
            </div>
          </div>
          <div className={classnames(style.ShareModalHeader, 'justify-content-center h-auto')}>
            <h4 className="mt-1">Share Your Products</h4>

            {/* <img className="mr-5 mt-4" style={{ width: '14px', height: '14px', cursor: 'pointer' }} onClick={() => setModal({ ...modal, modal: false, name: "" })} src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'} alt='' />
             */}
          </div>
          <div className={classnames('row', style.modalRow_share)}>
            <div className="w-100 py-4 d-flex justify-content-between mx-5">
              {/* <Popper className={style.socialMediaPopper} open={true}> */}
              <div>
                <FacebookShareButton
                  url={copiedUrl}
                  // title={open?.data?.name}
                  // metadescription={open?.data?.description}
                  // image={open?.data?.image}
                  // quote={"CampersTribe - World is yours to explore facebook"}
                  // hashtag="#PixelandMordar"
                >
                  <FacebookIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </FacebookShareButton>
              </div>
              <div>
                <TwitterShareButton
                  url={copiedUrl}
                  // title={open?.data?.name}
                  // description={open?.data?.description}
                  // image={open?.data?.image[0]}
                  // quote={"CampersTribe - World is yours to explore"}
                  // hashtag="#PixelandMordar"
                >
                  <TwitterIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </TwitterShareButton>
              </div>
              <div>
                <EmailShareButton url={copiedUrl}>
                  <EmailIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </EmailShareButton>
              </div>
              <div>
                <WhatsappShareButton
                  url={copiedUrl}
                  //  image="https://pixel-mortar.s3.ap-south-1.amazonaws.com/brands/alankaram/wbg/PXM_ALA_0b8e32f8_cbd3_01.jpg"
                  //                         separator=":: "
                  //                         title={open?.data?.name}
                  //                         description={open?.data?.description}
                  //                         quote="CampersTribe - World is yours to explore facebbok"
                  //                         hashtag="#PixelandMordar"
                >
                  <WhatsappIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </WhatsappShareButton>
              </div>
              {/* </Popper>  */}
            </div>

            <div className="my-3 mx-3" style={{ width: '90%' }}>
              <div className={style.shareLink}>
                <p className={classnames(style.shareContent, 'p-2 mr-2')}>{copiedUrl}</p>
                <button
                  className={style.copyLinkBtn}
                  onClick={() => {
                    navigator.clipboard.writeText(copiedUrl);
                    setSnack(true);
                    setSnackMessage('Product link successfully copied');
                  }}>
                  Copy Link
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div
                className='pt-5 pb-5 pl-5 pr-2'
                style={{ backgroundColor: 'white' }}>
                <InlineShareButtons
                  config={{
                    // alignment: 'center',
                    color: 'social',
                    enabled: true,
                    font_size: 16,
                    labels: 'cta',
                    language: 'en',
                    networks: [
                      // 'whatsapp',
                      'linkedin',
                      'messenger',
                      'facebook',
                      'twitter',
                      'instagram',
                    ],
                    // padding: 12,
                    radius: 4,
                    // show_total: true,
                    size: 30,

                    url: copiedUrl,
                    image: 'https://bit.ly/2CMhCMC',
                    description: 'custom text',
                    title: 'custom title',
                    message: 'custom email text',
                    subject: 'custom email subject',
                    username: 'custom twitter handle',
                  }}
                />
                <div
                  className='d-flex flex-column justify-content-center'
                  style={{ height: '70px' }}>
                  <div className={style.shareLink}>
                    <p className={style.link}>
                      <a>{copiedUrl}</a>
                    </p>
                    <button className={style.copyLinkBtn}>Linkss</button>
                  </div>
                </div>
              </div> */}
      </Modal>
      {modalOpen && <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />}
    </>
  );
};

export default ProductListing;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
