import React from 'react';
import './MyOrders.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';

function Order({ history }) {
  const image_url = 'https://d3izcfql7lesks.cloudfront.net/Group_2953_2x_e5f9xn.png';
  const mystyle = {
    backgroundSize: '100%',
    backgroundImage: `url(${image_url})`
  };
  return (
    <>
      <Header />

      <div className="black1">
        <h3>
          <strong>
            <span style={{ textDecoration: 'underline' }}>Rs.2000</span> has been add to your P & M
            account.
          </strong>
        </h3>
        <h6>You can use this to purchase decor elements from </h6>
        <h6>countless sellers in our online store.</h6>
        <button className="getStartedButton1">Find your style</button>
      </div>
      {/* <img className="will" src={'https://d3izcfql7lesks.cloudfront.net/shutterstock_1465769315_xurzls.png'} alt='' />
       */}
      <div
        className="home-card-view flex-center d-flex justify-content-center align-items-center flex-colum"
        style={mystyle}></div>

      <Footer />
    </>
  );
}

export default Order;
