export const rooms = [
  {
    id: 0,
    title: 'Room Type'
  },
  {
    id: 1,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2516_zlmecz.png',
    title: 'Living Room'
  },
  {
    id: 2,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2483_mbimcy.png',
    title: 'Dining Room'
  },
  {
    id: 3,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2474_deqke7.png',
    title: 'Bed Room'
  },
  {
    id: 4,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2946_liznui.png',
    title: 'Kids Room'
  },
  {
    id: 5,
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/pujaImg.jpeg',
    title: 'Puja Room'
  },
  {
    id: 6,
    image: 'https://d3izcfql7lesks.cloudfront.net/Path_1983_xi5m4r.png',
    title: 'Foyer'
  },
  {
    id: 7,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2956_hiznv5.png',
    title: 'Balcony'
  },
  {
    id: 8,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2707_noyqsc.png',
    title: 'Study Entertainment Room'
  },
  {
    id: 9,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2720_zicljy.png',
    title: 'Others'
  }
];

export const headers = [
  { page: 0, title: 'space' },
  { page: 1, title: 'style' },
  { page: 2, title: 'color' },
  { page: 3, title: 'design' },
  { page: 4, title: 'rooms' },
  { page: 5, title: 'intialRoom' },
  { page: 6, title: 'name' },
  { page: 7, title: 'email' },
  { page: 8, title: 'result' }
];
export const headers2 = [
  { page: 0, title: 'home' },
  { page: 1, title: 'hometype' },
  { page: 2, title: 'location' },
  { page: 3, title: 'stage' },
  { page: 4, title: 'room' },
  { page: 5, title: 'budget' }
];
