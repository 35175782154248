import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import style from './Shopdetails.module.css';
import style1 from './Shop.module.css';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import arrow from '../../assets/images/Iconionic-ios.png';
import Paper from '@material-ui/core/Paper';
import SliderContainer from 'react-slick';
import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import { Image } from 'semantic-ui-react';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import classnames from 'classnames';
import BestSeller from './BestSeller';
import shutterstock_72 from '../../assets/images/shop/shutterstock_72.png';
import { Grid } from '@material-ui/core';
import { categoryAll, rooms } from './constant';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { openin } from '../../utils/utils';
import ConsoleHelper from '../../utils/ConsoleHelper';
import Header from '../../components/Header/Header';
import getCartDataCount from '../../utils/CartDetails';
import { storeCartCount } from '../../store/actions/sampleaction';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: '50px',
      marginBottom: '50px'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '7px',
        height: '11px',
        marginLeft: '11px'
      }
    },
    paper: {
      borderRadius: '10px',
      maxHeight: '460px',
      height: '330px',
      width: '90%',
      border: '0.5px solid rgba(112,112,112,0.4 )',
      overflow: 'hidden',
      margin: '0px auto',
      [theme.breakpoints.down('sm')]: {
        height: '300px'
      }
    },
    imagefit: {
      objectFit: 'contain'
    },

    displayrow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline'
    },
    paper1: {
      width: '100%',
      border: 'none',
      margin: '0px auto',
      padding: '0px 12px',
      position: 'relative',
      maxWidth: '100%',
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
      maxHeight: '460px',
      borderRadius: '7px',
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        padding: '43px 11px'
      }
    }
  })
);
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowPrev} alt="prevArrow" {...props} />
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowNext} alt="nextArrow" {...props} />
);

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,

  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        centerMode: true
      }
    }
  ]
};

const ShopbybrandFilter = ({ auth, deviceType, history }) => {
  const params = useParams();

  let filterRooms = [];
  if (params) {
    filterRooms = params.roomtype?.title;
  }

  const dimension = useMediaQuery();
  const classes = useStyles();
  const shop_type = [
    { id: 1, value: 'living room', type: 'Living Room' },
    { id: 2, value: 'dining room', type: 'Dining Room' },
    { id: 3, value: 'bed room', type: 'Bed Room' },
    { id: 4, value: 'kids room', type: 'Kids Room' },
    { id: 5, value: 'puja room', type: 'Puja Room' },
    { id: 6, value: 'foyer', type: 'Foyer' },
    { id: 7, value: 'balcony', type: 'Balcony' },
    {
      id: 8,
      value: 'study_entertainment_room',
      type: 'Study and Entertainment Room'
    }
  ];

  const [numberOfItems, setnumberOfItems] = useState(10);
  const [shopType] = React.useState(null);
  const [categories] = useState([...categoryAll]);
  const [showMore, setShowMore] = useState(false);
  const [filteredMoodBoards, setFilteredMoodBoards] = useState([]);
  const [page, setPage] = useState(1);
  const [filterOption, setFilterOption] = useState({
    // tags: 'Best Seller',
    brand: params.brand
  });
  const [searchInput] = useState('');
  const [tempProductsList, setTempProductsList] = useState([]);
  const [prodLoading, setProdLoading] = useState(false);
  const [cardlist] = useState([]);
  const [bannerlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vendorsList, setVendorsList] = useState([]);

  let filterApplied = useRef(false);
  let prevPage = useRef(0);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendor/list`);
        if (res.data.length !== 0) {
          setVendorsList(res.data);
        }
      } catch (err) {
        ConsoleHelper('error', err);
      }
    };
    fetchVendorData();
  }, []);
  useEffect(() => {
    let filters = { ...filterOption };
    let search = searchInput;
    if (vendorsList.length > 0) {
      getProductDetails(page, filters, search);
    }
  }, [page, filterOption, searchInput, vendorsList]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        let result = [];
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/list`);
        for (let i = 0; i < result.length; i++) {
          let temprooms = [];
          if (result[i].rooms.length > 0) {
            for (let j = 0; j < result[i].rooms.length; j++) {
              temprooms.push(result[i].rooms[j].toLowerCase());
            }
          }
          result[i].rooms = temprooms;
        }

        for (let i = 0; i < result.length; i++) {
          let tempstyles = [];
          if (result[i].rooms.length > 0) {
            for (let j = 0; j < result[i].styles.length; j++) {
              tempstyles.push(result[i].styles[j].toLowerCase());
            }
          }
          result[i].styles = tempstyles;
        }
        const fromShop = history?.location?.pathname;
        const changeShop = shop_type?.find((item) => item.id === shopType)?.type;
        const the_arr = fromShop?.split('/');
        const styleFilter = the_arr?.pop();
        if (the_arr[3] === 'true') {
          const filteredArray = result.filter((data) =>
            data?.styles.find((style) => style === styleFilter.toLowerCase() && data.preview_url)
          );
          setLoading(false);
          setFilteredMoodBoards(filteredArray);
        } else {
          const filter = result.filter(
            (data) => data.rooms[0] === changeShop.toLowerCase() && data.preview_url
          );
          setLoading(false);
          setFilteredMoodBoards(filter);
        }
      } catch (err) {
        ConsoleHelper('error', err);
      }
    };

    fetchData();
  }, [shopType]);
  const loadTableData = () => {
    if (page === prevPage.current) {
      setPage((prev1State) => prev1State + 1);
    }
  };
  const [prod, setProd] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [brandImage, setBrandImage] = useState([]);
  const [brandlogo, setBrandLogo] = useState([]);
  const [brandId, setBrandId] = useState([]);
  const [hover, setHover] = useState(false); // initial false
  const getProductDetails = async (page, filters, search) => {
    if (filters) {
      let vendor = vendorsList.filter((ven) => ven.name === filters.brand);
      if (vendor.length > 0) {
        filters.vendor_id = vendor[0]._id;
      }
    }
    // if (params.roomtype) {
    //   filters.room_type = params.roomtype;
    // }
    setProdLoading(true);
    const res1 = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/filterOptions`, {
      params: { page, ...filters }
    });
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
      params: { page, ...filters }
    });

    setProdLoading(false);
    setTempProductsList([...res.data.results]);
    const categoryList = [...res1.data.brandCategoryList];
    // console.log(...res1.data.room_type)
    setCategoryList(categoryList);
    // console.log(categoryList)
    const prodList = [...res.data.results];
    setProdList(prodList);
    const vendorid = [];
    for (let i = 0; i < prodList.length; i++) {
      vendorid.push(prodList[i].vendor_id.id);
    }
    const uniid = new Set(vendorid);
    const uniqueID = [...uniid];

    let matchingId = null;
    let brandImage = null;
    let brandLogo = null;
    for (const vendor of vendorsList) {
      if (Array.isArray(uniqueID) && uniqueID.includes(vendor._id)) {
        matchingId = vendor._id;
        setBrandId(vendor._id);
        break;
      }
    }

    // let matchingCoverImg = null;

    for (const vendor of vendorsList) {
      if (vendor._id === matchingId) {
        brandImage = vendor.cover_img;
        break;
      }
    }
    setBrandImage(brandImage);

    for (const vendor of vendorsList) {
      if (vendor._id === matchingId) {
        brandLogo = vendor.bit_img;
        break;
      }
    }
    setBrandLogo(brandLogo);

    loadTableData();
    const prod = [];
    for (let i = 0; i < prodList.length; i++) {
      prod.push(prodList[i].category);
    }
    setProd([...prod]);
    if (filterApplied.current && page !== 1) {
      return;
    }
    filterApplied.current = false;
  };

  const uniProd = new Set(prod);
  const uniqueProd = [...uniProd];

  const HoverData = 'Click On AR';

  const onHover = (e) => {
    e.preventDefault();
    setHover(true); // turn true
  };

  const onHoverOver = (e) => {
    e.preventDefault(); // turn false
    setHover(false);
  };

  const handleClickProducts = () => {
    // history.push(`/product_brand_list/${params?.brand}/${brandId}`);
    history.push({
      pathname: '/mainproduct',
      state: { vendor_id: brandId, brand: params?.brand },
      data: { brandimage: brandImage, brandlogo: brandlogo }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      // console.log("het hi helli")
    });
  }, []);

  return (
    <div>
      <Header />
      <div style={{ background: '#F5F5F5' }}>
        {/* <Navbar /> */}
        <div className={style.typesSection}>
          <>
            <div>
              <div className={classNames(style.roomType, classes.displayrow)}>
                <div
                  className={classNames(classes.displayrow)}
                  style={{ cursor: 'pointer', color: 'gray' }}
                  onClick={() =>
                    history.push({
                      pathname: `/shop`
                    })
                  }>
                  Shop
                </div>
                <div className={classes.displayrow}>
                  <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                  <div>{params?.brand}</div>
                </div>
              </div>
            </div>

            <div className={style.brand}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{ width: '30%' }}>
                  <img
                    className={`${style.design} img-fluid`}
                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+48098563.png"
                    alt=""
                  />
                </div>
                <div className={style.brandbanner}>
                  <div className={style.brandLogo}>
                    <img className={style.img} src={brandlogo} alt=""></img>
                  </div>
                  <div className={style.textcont}>
                    <div className={style.text}>
                      Shop {params?.brand} Products <br />
                      With The Power Of AR
                    </div>
                  </div>
                  <div className={style.shop}>
                    <button
                      className={style.shop_button}
                      type="button"
                      onClick={handleClickProducts}>
                      Shop Now
                    </button>
                    <div>
                      <img
                        onMouseOver={(e) => onHover(e)}
                        onMouseOut={(e) => onHoverOver(e)}
                        className={style.ARbutton}
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/Vector.png"
                        alt="AR"
                      />

                      {hover && (
                        <span className={style.tooltip}>
                          <span>
                            Click On Ar Button <br />
                            <b style={{ backgroundColor: '#F6F6F6' }}> View in AR</b> <br />
                            To view product in your room
                          </span>
                          <img
                            className={style.tooltipimg}
                            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/Vector.png"
                            alt="Tooltip Image"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img className={style.brandimage} src={brandImage} alt="" />
              </div>
            </div>
          </>
          {/* {categories.length > 10 && (
          <div>
          <h6
          className="mb-2 "
          style={{
            cursor: 'pointer',
            fontFamily: 'Quicksand',
            float: 'right'
          }}
          onClick={() => {
            showMore ? setnumberOfItems(10) : setnumberOfItems(categories.length);
            setShowMore(!showMore);
          }}>
          <b>{showMore ? 'Show less...' : 'Show more...'}</b>
          </h6>
          </div>
        )} */}
        </div>
        <div className={style.bestSelller}>
          <BestSeller
            loading={prodLoading}
            productsList={tempProductsList}
            auth={auth}
            roomtype={shop_type?.find((item) => item.id === shopType)?.type}
            dimension={dimension}
            tag={'BEST SELLER'}
            storeCartCount={storeCartCount}
          loadTableData={() => loadTableData()}

            // page={page}
            // storeCartCount={storeCartCount}
          />
        </div>

        <div className={style.shopbycategorybrand}>
          <h1 className={style.category_text}>Shop By Category</h1>
          <ResponsiveMasonry
            className={style.mesonryRooms}
            style={{ margin: '0px auto', width: '100%' }}
            columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 6 }}>
            <Masonry>
              {categoryList.map((category, i) => {
                const room = rooms.find((room) => room.category.some((c) => c.title === category));
                const roomCategories = room ? room.category : [];
                const matchedCategory = roomCategories.find((c) => c.title === category);
                const categoryImage = matchedCategory ? matchedCategory.image : '';
                const imageUrl = room
                  ? room.category
                      .flatMap((c) => c.subcategory || [])
                      .find((sc) => sc.title === category)?.image || room.image
                  : '';

                return categoryImage ? (
                  <div style={{ position: 'relative' }} key={i}>
                    <div
                      className="d-flex flex-column align-items-center justify-content-evenly"
                      style={{ cursor: 'pointer', marginBottom: '38px' }}
                      onClick={() => {
                        if (filterOption.hasOwnProperty('room_type')) {
                          let index = filterOption.room_type.findIndex((rm) => rm === category);
                          if (index < 0) {
                            setFilterOption({
                              ...filterOption,
                              room_type: [...filterOption.room_type, category]
                            });
                          } else {
                            filterOption.room_type = filterOption.room_type.filter(
                              (rm) => rm !== category
                            );
                            setFilterOption({ ...filterOption });
                          }
                        } else {
                          setFilterOption({
                            ...filterOption,
                            room_type: [category]
                          });
                          // console.log('509',filterOption)
                        }
                        history.replace({
                          pathname: '/mainproduct',
                          state: { vendor_id: brandId, category: category, brand: params.brand },
                          data: { brandimage: brandImage, brandlogo: brandlogo }

                          // pathname: `/product_list/${room.id}/${category}/false/${params.brand}/${brandId}`
                        });
                        // console.log('pa',params)
                      }}>
                      <div className={style.designIconContainer1}>
                        <img
                          className={style.designIcon1}
                          src={categoryImage}
                          alt="room"
                          style={{ width: '90%' }}
                        />
                      </div>
                      <p className="mt-2">{category}</p>
                    </div>
                  </div>
                ) : null;
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>

        <div className={style.mustHave}>
          <BestSeller
            loading={prodLoading}
            productsList={tempProductsList}
            auth={auth}
            dimension={dimension}
            title={`MUST HAVE IN YOUR ${
              shop_type.find((item) => item.id === shopType)?.type
                ? shop_type.find((item) => item.id === shopType).type
                : 'LIVING ROOM'
            }`}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};


// export default ShopbybrandFilter;
export default connect(mapStateToProps, { storeCartCount })(withRouter(ShopbybrandFilter));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
