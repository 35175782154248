// import Livingroom from '../../assets/images/shop/Livingroom.png';
// import Poojaroom from '../../assets/images/shop/Poojaroom.png';
// import KidsRoom from '../../assets/images/shop/kidsroom.png';
// import Bedroom from '../../assets/images/shop/Bedroom.png';
// import Balcony from '../../assets/images/shop/Balcony.png';
// import Diningroom from '../../assets/images/shop/Diningroom.png';
// import Foyer from '../../assets/images/shop/Foyer.png';
// import Entertainment from '../../assets/images/shop/Entertainment.png';
// import Others from '../../assets/images/shop/Others.png';
export const rooms = [
  {
    id: 1,
    title: 'Living Room',
    name: 'Living Room',
    hoverImage:
      'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Living+Room(white).png',
    image: 'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Living+Room.png',
    category: [
      {
        id: 2,
        title: 'Tables',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Tables/Pixel-Living+room-Tables.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Mounted Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Tables/Pixel-Living+room-Tables/Pixel-Living+room-Tables-Wall+Mounted+Tables.svg'
          },
          {
            id: 2,
            title: 'Study Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Tables/Pixel-Living+room-Tables/Pixel-Living+room-Tables-Study+Tables.svg'
          },
          {
            id: 3,
            title: 'Side and End Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Tables/Pixel-Living+room-Tables/Pixel-Living+room-Tables-Side+and+End+Tables.svg'
          },
          {
            id: 4,
            title: 'Laptop Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Tables/Pixel-Living+room-Tables/Pixel-Living+room-Tables-Laptop+Tables.svg'
          },
          {
            id: 5,
            title: 'Console Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Tables/Pixel-Living+room-Tables/Pixel-Living+room-Tables-Console+Tables.svg'
          },
          {
            id: 6,
            title: 'Coffee Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Tables/Pixel-Living+room-Tables/Pixel-Living+room-Tables-Coffee+Tables.svg'
          }
        ]
      },
      {
        id: 3,
        title: 'Storage',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Storage/Pixel-Living+room-Storage.svg',
        subcategory: [
          {
            id: 1,
            title: 'Tv Unit',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Storage/Pixel-Living+room-Storage/Pixel-Living+room-Storage-TV+Units.svg'
          },
          {
            id: 2,
            title: 'Shoe Cabinet',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Storage/Pixel-Living+room-Storage/Pixel-Living+room-Storage-Shoe+Cabinet.svg'
          },
          {
            id: 3,
            title: 'Chest Of Drawers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Storage/Pixel-Living+room-Storage/Pixel-Living+room-Storage-Chest+Of+drawers.svg'
          },
          {
            id: 4,
            title: 'Cabinet And Sideboards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Storage/Pixel-Living+room-Storage/Pixel-Living+room-Storage-Cabinet+and+Sideboards.svg'
          },
          {
            id: 5,
            title: 'Book Case',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Storage/Pixel-Living+room-Storage/Pixel-Living+room-Storage-Bookshelves.svg'
          },
          {
            id: 6,
            title: 'Bar Units',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Storage/Pixel-Living+room-Storage/Pixel-Living+room-Storage-Bar+Units.svg'
          }
        ]
      },
      {
        id: 4,
        title: 'Soft Furnishings',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings.svg',
        subcategory: [
          {
            id: 1,
            title: 'Throws',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings-Throws.svg'
          },
          {
            id: 2,
            title: 'Table Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings-Table+Runners.svg'
          },
          {
            id: 3,
            title: 'Rugs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings-Rugs.jpg'
          },
          {
            id: 4,
            title: 'Quilts',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings-Quilts.svg'
          },
          {
            id: 5,
            title: 'Floor Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings-Floor+Runners.svg'
          },
          {
            id: 6,
            title: 'Cushions',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings-Cushions.svg'
          },
          {
            id: 7,
            title: 'Curtains',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings/Pixel-Living+room-Soft+Furnishings-Curtains.svg'
          }
        ]
      },
      {
        id: 5,
        title: 'Sofa',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa.svg',
        subcategory: [
          // {
          //   id: 1,
          //   title: 'Sofa',
          //   image:
          //     'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Sofa.svg',
          // },
          {
            id: 2,
            title: 'Sofa Cum Bed',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa-Sofa+Cum+Bed.svg'
          },
          {
            id: 3,
            title: 'Sectional',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa-Sectional.svg'
          },
          {
            id: 4,
            title: 'Recliners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa-Reclainers.svg'
          },
          {
            id: 5,
            title: 'Love Seat',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa-Loveseats.svg'
          },
          {
            id: 6,
            title: 'L Shaped',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa-L+Shaped.svg'
          },
          {
            id: 7,
            title: 'Futons',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa-Futons.svg'
          },
          {
            id: 8,
            title: 'Chesterfield',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa/Pixel-Living-room-Sofa-Chesterfield.jpg'
          },
          {
            id: 9,
            title: 'Chaise Lounge',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa/Pixel-Living+room-Sofa-Chaise+Lounge.svg'
          }
        ]
      },
      {
        id: 6,
        title: 'Seating',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating.svg',
        subcategory: [
          {
            id: 1,
            title: 'Swings',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating/Pixel-Living+room-Seating-Swings.svg'
          },
          {
            id: 2,
            title: 'Rocking Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating/Pixel-Living+room-Seating-Rocking+Chairs.svg'
          },
          {
            id: 3,
            title: 'Metal Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating/Pixel-Living+room-Seating-Metal+Chairs.svg'
          },
          {
            id: 4,
            title: 'Lounge Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating/Pixel-Living+room-Seating-Lounge+Chairs.svg'
          },
          {
            id: 5,
            title: 'Ergonomic Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating/Pixel-Living+room-Seating-ergonomic+chairs.svg'
          },
          {
            id: 6,
            title: 'Bar Stools',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating/Pixel-Living+room-Seating-Bar+Stool.svg'
          },
          {
            id: 7,
            title: 'Arm Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating/Pixel-Living+room-Seating-Arm+Chairs.svg'
          },
          {
            id: 8,
            title: 'Accent Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Seating/Pixel-Living+room-Seating/Pixel-Living+room-Seating-Accent+Chairs.svg'
          }
        ]
      },
      {
        id: 7,
        title: 'Lighting',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting.svg',
        subcategory: [
          {
            id: 1,
            title: 'Table Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting-Table+Lamps.svg'
          },
          {
            id: 2,
            title: 'Sconces',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting-Sconces.svg'
          },
          {
            id: 3,
            title: 'Pendants',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting-Pendants.svg'
          },
          {
            id: 4,
            title: 'Floor Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting-Floor+Lamps.svg'
          },
          {
            id: 5,
            title: 'Chandeliers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting/Pixel-Living+room-Lighting-Chandeliers.svg'
          }
        ]
      },
      {
        id: 8,
        title: 'Decor',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Shelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Wall+shelves.svg'
          },
          {
            id: 2,
            title: 'Wall Plates',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Wall+Plates.svg'
          },
          {
            id: 3,
            title: 'Wall Mirrors',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Wall+Mirrors.png'
          },
          {
            id: 4,
            title: 'Wall Clocks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Wall+Clocks.svg'
          },
          {
            id: 5,
            title: 'Wall Art',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Wall+Art.svg'
          },
          {
            id: 6,
            title: 'Vases',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Vases.svg'
          },
          {
            id: 7,
            title: 'Sculptures',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Sculpture.svg'
          },
          {
            id: 8,
            title: 'Room Dividers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Room+Dividers.svg'
          },
          {
            id: 9,
            title: 'Planters',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Planters.svg'
          },
          {
            id: 10,
            title: 'Photoframe',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Photoframe.svg'
          },
          {
            id: 11,
            title: 'Key Holder',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Living+room/Pixel-Living+room-Decor/Pixel-Living+room-Decor/Pixel-Living+room-Decor-Key+Holder.svg'
          }
        ]
      }
    ]
  },
  {
    id: 2,
    title: 'Dining Room',
    name: 'Dining Room',
    hoverImage:
      'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Dining+Room(white).png',
    image: 'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Dining+Room.png',
    category: [
      {
        id: 2,
        title: 'Storage',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Storage/Pixel-Dining-Storage.svg',
        subcategory: [
          {
            id: 1,
            title: 'Crockery Unit',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Storage/Pixel-Dining-Storage/Pixel-Dining-Storage-Crockery+Unit.svg'
          },
          {
            id: 2,
            title: 'Book Case',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Storage/Pixel-Dining-Storage/Pixel-Dining-Storage-Book+Shelf.svg'
          },
          {
            id: 3,
            title: 'Cabinet And Sideboards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Storage/Pixel-Dining-Storage/Pixel-Dining-Storage-Cabinet-Sideboards.svg'
          },
          {
            id: 4,
            title: 'Wine Racks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Storage/Pixel-Dining-Storage/Pixel-Dining-Storage-Wine-Racks.svg'
          }
        ]
      },
      // {
      //   id: 3,
      //   title: "Soft Furnishings",
      //   image:
      //     "https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Soft+Furnishings/Pixel-Dining-Soft+Furnishings.svg",
      //   subcategory: [
      //     {
      //       id: 1,
      //       title: "Curtains",
      //       image:
      //         "https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Soft+Furnishings/Pixel-Dining-Soft+Furnishings/Pixel-Dining-Soft+Furnishings-Curtains.svg",
      //     },
      //     {
      //       id: 2,
      //       title: "Table Runners",
      //       image:
      //         "https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Soft+Furnishings/Pixel-Dining-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Table+Runners.svg",
      //     },
      //   ],
      // },
      {
        id: 4,
        title: 'Seating',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Seating/Pixel-Dining-Seating.svg',
        subcategory: [
          {
            id: 1,
            title: 'Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Seating/Pixel-Dining-Seating/Pixel-Dining-Seating-Dining+Chairs.svg'
          },
          {
            id: 2,
            title: 'Benches',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Seating/Pixel-Dining-Seating/Pixel-Dining-Seating-Benches.svg'
          },
          {
            id: 3,
            title: 'Bar Stools',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Seating/Pixel-Dining-Seating/Pixel-Dining-Seating-Bar+Stool.svg'
          }
        ]
      },
      {
        id: 5,
        title: 'Lighting',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Lighting/Pixel-Dining-Lighting.svg',
        subcategory: [
          {
            id: 1,
            title: 'Table Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Lighting/Pixel-Dining-Lighting/Pixel-Dining-Lighting-Table+Lamps.svg'
          },
          {
            id: 2,
            title: 'Sconces',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Lighting/Pixel-Dining-Lighting/Pixel-Dining-Lighting-Sconces.svg'
          },
          {
            id: 3,
            title: 'Pendants',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Lighting/Pixel-Dining-Lighting/Pixel-Dining-Lighting-Pendants.svg'
          },
          {
            id: 4,
            title: 'Floor Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Lighting/Pixel-Dining-Lighting/Pixel-Dining-Lighting-Floor+Lamps.svg'
          },
          {
            id: 5,
            title: 'Chandeliers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Lighting/Pixel-Dining-Lighting/Pixel-Dining-Lighting-Chandeliers.svg'
          }
        ]
      },
      {
        id: 6,
        title: 'Dining Table',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Dining+Table/Pixel-Dining-Dining+Table.svg',
        subcategory: [
          {
            id: 1,
            title: 'High Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Dining+Table/Pixel-Dining-Dining+Table/Pixel-Dining-Dining+Table+-High+Tables.svg'
          },
          {
            id: 2,
            title: 'Dining Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Dining+Table/Pixel-Dining-Dining+Table/Pixel-Dining-Dining+Table+-Dining+Table.svg'
          },
          {
            id: 3,
            title: 'Dining Table Sets',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Dining+Table/Pixel-Dining-Dining+Table/Pixel-Dining-Dining+Table+-Dining+Sets.svg'
          }
        ]
      },
      {
        id: 7,
        title: 'Decor',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Shelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Wall+shelves.svg'
          },
          {
            id: 2,
            title: 'Wall Plates',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Wall+Plates.svg'
          },
          {
            id: 3,
            title: 'Wall Mirrors',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Wall+Mirrors.png'
          },
          {
            id: 4,
            title: 'Wall Clocks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Wall+Clocks.svg'
          },
          {
            id: 5,
            title: 'Wall Art',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Wall+Art.svg'
          },
          {
            id: 6,
            title: 'Vases',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Vases.svg'
          },
          {
            id: 7,
            title: 'Sculptures',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Sculpture.svg'
          },
          {
            id: 8,
            title: 'Room Dividers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Room+Dividers.svg'
          },
          {
            id: 9,
            title: 'Planters',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Planters.svg'
          },
          {
            id: 10,
            title: 'Photoframe',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Dining/Pixel-Dining-Decor/Pixel-Dining-Decor/Pixel-Dining-Decor-Photoframe.svg'
          }
        ]
      }
    ]
  },
  {
    id: 3,
    title: 'Bed Room',
    name: 'Bed Room',
    image: 'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Bed+Room.png',
    hoverImage: 'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Bed+Room(white).png',
    category: [
      {
        id: 1,
        title: 'Wardrobes',
        image:
          ' https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Hung',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes-Wall+hung.svg'
          },
          {
            id: 2,
            title: 'Swing Shutter Door',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes-Swing+Shutter+Door.svg'
          },
          {
            id: 3,
            title: 'Sliding Door',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes-Sliding+Door.svg'
          },
          {
            id: 4,
            title: 'Open',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes-Open.svg'
          },
          {
            id: 5,
            title: 'Corner',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes/Pixel-Bedroom-Wardrobes-Corner.svg'
          }
        ]
      },

      {
        id: 3,
        title: 'Storage',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage.svg',
        subcategory: [
          {
            id: 1,
            title: 'Tv Unit',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage-TV+Units.svg'
          },
          {
            id: 2,
            title: 'Trunk And Blanket Boxes',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage-Trunk+and+Blanket+boxes.svg'
          },
          {
            id: 3,
            title: 'Dressing Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage-Dressing+Tables.svg'
          },
          {
            id: 4,
            title: 'Chest Of Drawers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage-Chest+Of+drawers.svg'
          },
          {
            id: 5,
            title: 'Cabinet And Sideboards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage-Cabinet+and+Sideboards.svg'
          },
          {
            id: 6,
            title: 'Bookshelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage-Bookshelves.svg'
          },
          {
            id: 7,
            title: 'Bedside Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage-Bedside+Tables.svg'
          },
          {
            id: 8,
            title: 'Bar Units',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage/Pixel-Bedroom-Storage-Bar+Units.svg'
          }
        ]
      },
      {
        id: 4,
        title: 'Soft Furnishings',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings.svg',
        subcategory: [
          {
            id: 1,
            title: 'Throws',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Throws.svg'
          },
          {
            id: 2,
            title: 'Table Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Table+Runners.svg'
          },
          {
            id: 3,
            title: 'Rugs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Rugs.jpg'
          },
          {
            id: 4,
            title: 'Quilts',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Quilts.svg'
          },
          {
            id: 5,
            title: 'Floor Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Floor+Runners.svg'
          },
          {
            id: 6,
            title: 'Cushions',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Cushions.svg'
          },
          {
            id: 7,
            title: 'Curtains',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Curtains.svg'
          },
          {
            id: 8,
            title: 'Bed Sheets',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings/Pixel-Bedroom-Soft+Furnishings-Bed+Sheets.svg'
          }
        ]
      },
      {
        id: 5,
        title: 'Sofa',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa.svg',
        subcategory: [
          {
            id: 1,
            title: 'Sofa',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Sofa.jpg'
          },
          {
            id: 2,
            title: 'Sofa Cum Bed',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Sofa+Cum+Bed.svg'
          },
          {
            id: 3,
            title: 'Sectional',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Sectional.svg'
          },
          {
            id: 4,
            title: 'Recliners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Reclainers.svg'
          },
          {
            id: 5,
            title: 'Love Seat',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Loveseats.svg'
          },
          {
            id: 6,
            title: 'L Shaped',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-L+Shaped.svg'
          },
          {
            id: 7,
            title: 'Futons',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Futons.svg'
          },
          {
            id: 8,
            title: 'Chesterfield',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Chesterfield.jpg'
          },
          {
            id: 9,
            title: 'Chaise Lounge',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa/Pixel-Bedroom-Sofa-Chaise+Lounge.svg'
          }
        ]
      },
      {
        id: 6,
        title: 'Seating',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating.svg',
        subcategory: [
          {
            id: 1,
            title: 'Swings',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Swings.svg'
          },
          {
            id: 2,
            title: 'Rocking Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Rocking+Chairs.svg'
          },
          {
            id: 3,
            title: 'Pouf',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Pouf.svg'
          },
          {
            id: 4,
            title: 'Plastic Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Plastic+Chairs.svg'
          },
          {
            id: 5,
            title: 'Ottoman',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Ottomans.jpg'
          },
          {
            id: 6,
            title: 'Metal Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Metal+Chairs.svg'
          },
          {
            id: 7,
            title: 'Lounge Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Lounge+Chairs.svg'
          },
          {
            id: 8,
            title: 'Ergonomic Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-ergonomic+chairs.svg'
          },
          {
            id: 9,
            title: 'Diwans',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Diwans.svg'
          },
          {
            id: 10,
            title: 'Benches',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Benches.svg'
          },
          {
            id: 11,
            title: 'Bean Bags',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Bean+Bags.svg'
          },
          {
            id: 12,
            title: 'Bar Stools',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Bar+Stool.svg'
          },
          {
            id: 13,
            title: 'Arm Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Arm+Chairs.jpg'
          },
          {
            id: 14,
            title: 'Accent Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating/Pixel-Bedroom-Seating-Accent+Chairs.svg'
          }
        ]
      },
      {
        id: 7,
        title: 'Lighting',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting.svg',
        subcategory: [
          {
            id: 1,
            title: 'Table Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting-Table+Lamps.svg'
          },
          {
            id: 2,
            title: 'Sconces',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting-Sconces.svg'
          },
          {
            id: 3,
            title: 'Pendants',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting-Pendants.svg'
          },
          {
            id: 4,
            title: 'Floor Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting-Floor+Lamps.svg'
          },
          {
            id: 5,
            title: 'Chandeliers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting/Pixel-Bedroom-Lighting-Chandeliers.svg'
          }
        ]
      },
      {
        id: 8,
        title: 'Decor',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Shelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Wall+shelves.svg'
          },
          {
            id: 2,
            title: 'Wall Plates',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Wall+Plates.svg'
          },
          {
            id: 3,
            title: 'Wall Mirrors',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Wall+Mirrors.png'
          },
          {
            id: 4,
            title: 'Wall Clocks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Wall+Clocks.svg'
          },
          {
            id: 5,
            title: 'Wall Art',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Wall+Art.svg'
          },
          {
            id: 6,
            title: 'Vases',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Vases.svg'
          },
          {
            id: 7,
            title: 'Sculptures',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Sculpture.svg'
          },
          {
            id: 8,
            title: 'Room Dividers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Room+Dividers.svg'
          },
          {
            id: 9,
            title: 'Planters',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Planters.svg'
          },
          {
            id: 10,
            title: 'Photoframe',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Photoframe.svg'
          },
          {
            id: 11,
            title: 'Cloth Holder',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor/Pixel-Bedroom-Decor-Cloth+Holder.svg'
          }
        ]
      },
      {
        id: 9,
        title: 'Bed',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wooden Bed',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed-Wooden+Bed.svg'
          },
          {
            id: 2,
            title: 'Upholstered Beds',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed-Upholstered+Beds.svg'
          },
          {
            id: 3,
            title: 'Trundle Beds',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed-Trundle+Beds.svg'
          },

          {
            id: 4,
            title: 'Metal Beds',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed-Metal+Beds.svg'
          },
          {
            id: 5,
            title: 'Folding Beds',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed-Folding+Beds.svg'
          },
          {
            id: 6,
            title: 'Bunk Beds',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed-Bunk+Beds.svg'
          },
          {
            id: 7,
            title: 'Bed Sets',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Bedroom/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed/Pixel-Bedroom-Bed-Bed+Sets.svg'
          }
        ]
      }
    ]
  },
  {
    id: 4,
    title: 'Kids Room',
    name: 'Kids Room',
    image: 'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Kids+Room.png',
    hoverImage: 'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Kids+Room(white).png',
    category: [
      {
        id: 1,
        title: 'Wardrobes',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Hung',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes-Wall+hung.svg'
          },
          {
            id: 2,
            title: 'Swing Shutter Door',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes-Swing+Shutter+Door.svg'
          },
          {
            id: 3,
            title: 'Sliding Door',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes-Sliding+Door.svg'
          },
          {
            id: 4,
            title: 'Open',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes-Open.svg'
          },
          {
            id: 5,
            title: 'Corner',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes/Pixel-Kids+Bedroom-Wardrobes-Corner.svg'
          }
        ]
      },

      {
        id: 3,
        title: 'Tables',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table.svg',
        subcategory: [
          {
            id: 1,
            title: 'Kids Writing Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table-Writing+Table.svg'
          },
          {
            id: 2,
            title: 'Wall Mounted Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table-Wall+Mounted+Table.svg'
          },
          {
            id: 3,
            title: 'Study Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table-Study+Table.svg'
          },
          {
            id: 4,
            title: 'Side Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table-Side+Table.svg'
          },
          {
            id: 5,
            title: 'Laptop Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table/Pixel-Kids+Bedroom-Table-Laptop+Table.svg'
          }
        ]
      },
      {
        id: 4,
        title: 'Storage',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage.svg',
        subcategory: [
          {
            id: 1,
            title: 'Trunk And Blanket Boxes',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage-Trunk+_+Blanket+Box.svg'
          },

          {
            id: 2,
            title: 'Chest Of Drawers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage-Chest+Of+Drawer.svg'
          },
          {
            id: 3,
            title: 'Changing Station',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage-Changing+Station.svg'
          },
          {
            id: 4,
            title: 'Book Case',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage-Bookshelf.svg'
          },
          {
            id: 5,
            title: 'Bedside Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage/Pixel-Kids+Bedroom-Storage-Bedside+Table.svg'
          }
        ]
      },
      {
        id: 5,
        title: 'Soft Furnishings',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings.svg',
        subcategory: [
          {
            id: 1,
            title: 'Rugs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings-Rugs.jpg'
          },
          {
            id: 2,
            title: 'Quilts',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings-Quilts.svg'
          },
          {
            id: 3,
            title: 'Floor Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings-Floor+Runners.svg'
          },
          {
            id: 4,
            title: 'Cushions',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings/Pixel-Kids+Bedroom-Soft+Furnishings-Cushions.svg'
          }
        ]
      },
      {
        id: 6,
        title: 'Sofa',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa.svg',
        subcategory: [
          {
            id: 1,
            title: 'Sofa',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-Sofa.jpg'
          },
          {
            id: 2,
            title: 'Sofa Cum Bed',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-Sofa+Cum+Bed.svg'
          },
          {
            id: 3,
            title: 'Sectional',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-Sectional.svg'
          },
          {
            id: 4,
            title: 'Recliners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-Reclainers.svg'
          },
          {
            id: 5,
            title: 'Love Seat',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-Loveseats.svg'
          },
          {
            id: 6,
            title: 'L Shaped',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-L+Shaped.svg'
          },
          {
            id: 7,
            title: 'Futons',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-Futons.svg'
          },
          {
            id: 8,
            title: 'Chesterfield',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-Chesterfield.jpg'
          },
          {
            id: 9,
            title: 'Chaise Lounge',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa/Pixel-Kids+Bedroom-Sofa-Chaise+Lounge.svg'
          }
        ]
      },
      {
        id: 7,
        title: 'Seating',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating.svg',
        subcategory: [
          {
            id: 1,
            title: 'Swings',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating-Swings.svg'
          },
          {
            id: 2,
            title: 'Stool',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating-Stools.svg'
          },
          {
            id: 3,
            title: 'Rocking Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating-Rocking+Chairs.svg'
          },

          {
            id: 4,
            title: 'Pouf',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating-Poufs.svg'
          },
          {
            id: 5,
            title: 'Ottoman',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating-Ottoman.jpg'
          },

          {
            id: 7,
            title: 'Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating-Chairs.svg'
          },
          {
            id: 8,
            title: 'Bean Bags',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating/Pixel-Kids+Bedroom-Seating-Bean+Bags.svg'
          }
        ]
      },
      {
        id: 8,
        title: 'Lighting',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting.svg',
        subcategory: [
          {
            id: 1,
            title: 'Table Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting-Table+Lamps.svg'
          },
          {
            id: 2,
            title: 'Sconces',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting-Sconces.svg'
          },
          {
            id: 3,
            title: 'Pendants',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting-Pendants.svg'
          },
          {
            id: 4,
            title: 'Floor Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting/Pixel-Kids+Bedroom-Lighting-Floor+Lamps.svg'
          }
        ]
      },
      {
        id: 9,
        title: 'Bed',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds.svg',
        subcategory: [
          {
            id: 1,
            title: 'Trundle Beds',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds-Trundle+Beds.svg'
          },
          {
            id: 2,
            title: 'Single Beds',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds-Single+Beds.svg'
          },
          {
            id: 3,
            title: 'Cribs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds-Cribs.svg'
          },
          {
            id: 4,
            title: 'Bunk Beds',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds/Pixel-Kids+Bedroom-Kid+Beds-Bunk+Beds.svg'
          }
        ]
      },
      {
        id: 10,
        title: 'Decor',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Shelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Wall+shelves.svg'
          },
          {
            id: 2,
            title: 'Wall Plates',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Wall+Plates.svg'
          },
          {
            id: 3,
            title: 'Wall Mirrors',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Wall+Mirrors.svg'
          },
          {
            id: 4,
            title: 'Wall Clocks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Wall+Clocks.svg'
          },
          {
            id: 5,
            title: 'Wall Art',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Wall+Art.svg'
          },
          {
            id: 6,
            title: 'Toys',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Toys.svg'
          },
          {
            id: 7,
            title: 'Tents',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Tents.svg'
          },
          {
            id: 8,
            title: 'Room Dividers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Room+Dividers.svg'
          },
          {
            id: 9,
            title: 'Planters',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Room+Dividers.svg'
          },
          {
            id: 10,
            title: 'Photoframe',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Photoframe.svg'
          },
          {
            id: 11,
            title: 'Height Trackers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Height+Trackers.svg'
          },
          {
            id: 12,
            title: 'Cloth Holder',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Cloth+Holder.svg'
          },
          {
            id: 13,
            title: 'Bulletin Boards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor-Bulletin+Boards.svg'
          },
          {
            id: 14,
            title: 'Sculptures',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Kids+Bedroom/Pixel-Kids+Bedroom-Decor/Pixel-Kids+Bedroom-Decor/Pixel-Dining-Decor-Sculpture.svg'
          }
        ]
      }
    ]
  },
  {
    id: 5,
    title: 'Puja Room',
    name: 'Puja Room',
    image: 'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Puja+Room.png',
    hoverImage: 'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Puja+Room(white).png',
    category: [
      {
        id: 2,
        title: 'Tables',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Tables/Pixel-Puja-Tables.svg',
        subcategory: [
          {
            id: 1,
            title: 'Side Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Tables/Pixel-Puja-Tables/Pixel-Puja-Tables-Side+Tables.svg'
          },
          {
            id: 2,
            title: 'Console Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Tables/Pixel-Puja-Tables/Pixel-Puja-Tables-Console+Table.svg'
          }
        ]
      },
      {
        id: 3,
        title: 'Storage',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Storage/Pixel-Puja-Storage.svg',
        subcategory: [
          {
            id: 1,
            title: 'Puja Unit',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Storage/Pixel-Puja-Storage/Pixel-Puja-Storage-Puja+Unit.svg'
          },
          {
            id: 2,
            title: 'Chest Of Drawers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Storage/Pixel-Puja-Storage/Pixel-Puja-Storage-Chest+Of+Drawers.svg'
          },
          {
            id: 3,
            title: 'Cabinet And Sideboards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Storage/Pixel-Puja-Storage/Pixel-Puja-Storage-Cabinet+and+Sideboards.svg'
          }
        ]
      },
      {
        id: 4,
        title: 'Soft Furnishings',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Soft+Furnishings/Pixel-Puja-Soft+Furnishings.svg',
        subcategory: [
          {
            id: 1,
            title: 'Rugs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Soft+Furnishings/Pixel-Puja-Soft+Furnishings/Pixel-Puja-Soft+Furnishings-Rugs.jpg'
          },
          {
            id: 2,
            title: 'Floor Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Soft+Furnishings/Pixel-Puja-Soft+Furnishings/Pixel-Puja-Soft+Furnishings-Floor+Runners.svg'
          },
          {
            id: 3,
            title: 'Curtains',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Soft+Furnishings/Pixel-Puja-Soft+Furnishings/Pixel-Puja-Soft+Furnishings-Curtains.svg'
          }
        ]
      },
      {
        id: 5,
        title: 'Seating',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Seating/Pixel-Puja-Seating.svg',
        subcategory: [
          {
            id: 1,
            title: 'Stool',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Seating/Pixel-Puja-Seating/Pixel-Puja-Seating-Stool.svg'
          },
          {
            id: 2,
            title: 'Pouf',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Seating/Pixel-Puja-Seating/Pixel-Puja-Seating-Pouf.svg'
          },
          {
            id: 3,
            title: 'Ottoman',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Seating/Pixel-Puja-Seating/Pixel-Puja-Seating-Ottoman.jpg'
          },
          {
            id: 4,
            title: 'Diwan',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Seating/Pixel-Puja-Seating/Pixel-Puja-Seating-Diwan.svg'
          },
          {
            id: 5,
            title: 'Benches',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Seating/Pixel-Puja-Seating/Pixel-Puja-Seating-Benches.svg'
          }
        ]
      },
      {
        id: 6,
        title: 'Lighting',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Lighting/Pixel-Puja-Lighting.svg',
        subcategory: [
          {
            id: 1,
            title: 'Sconces',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Lighting/Pixel-Puja-Lighting/Pixel-Puja-Lighting-Sconces.svg'
          },
          {
            id: 2,
            title: 'Pendants',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Lighting/Pixel-Puja-Lighting/Pixel-Puja-Lighting-Pendants.svg'
          }
        ]
      },
      {
        id: 7,
        title: 'Decor',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Shelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor/Pixel-Puja-Decor-Wall+shelves.svg'
          },
          {
            id: 2,
            title: 'Wall Plates',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor/Pixel-Puja-Decor-Wall+Plates.svg'
          },
          {
            id: 3,
            title: 'Wall Mirrors',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor/Pixel-Puja-Decor-Wall+Mirrors.png'
          },
          {
            id: 4,
            title: 'Wall Art',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor/Pixel-Puja-Decor-Wall+Art.svg'
          },
          {
            id: 5,
            title: 'Sculptures',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor/Pixel-Puja-Decor-Sculpture.svg'
          },
          {
            id: 6,
            title: 'Room Dividers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor/Pixel-Puja-Decor-Room+Dividers.svg'
          },
          {
            id: 7,
            title: 'Planters',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor/Pixel-Puja-Decor-Planters.svg'
          },
          {
            id: 8,
            title: 'Photoframe',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Puja/Pixel-Puja-Decor/Pixel-Puja-Decor/Pixel-Puja-Decor-Photoframe.svg'
          }
        ]
      }
    ]
  },
  {
    id: 6,
    title: 'Foyer',
    name: 'Foyer',
    image: 'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Foyer.png',
    hoverImage: 'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Foyer(white).png',

    category: [
      {
        id: 2,
        title: 'Tables',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Tables/Pixel-Foyer-Tables.svg',
        subcategory: [
          {
            id: 1,
            title: 'Side and End Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Tables/Pixel-Foyer-Tables/Pixel-Foyer-Tables-Side+and+End+Tables.svg'
          },
          {
            id: 2,
            title: 'Console Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Tables/Pixel-Foyer-Tables/Pixel-Foyer-Tables-Console+Tables.svg'
          }
        ]
      },
      {
        id: 3,
        title: 'Storage',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Tables/Pixel-Foyer-Tables/Pixel-Foyer-Tables-Console+Tables.svg',
        subcategory: [
          {
            id: 1,
            title: 'Shoe Cabinet',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Storage/Pixel-Foyer-Storage/Pixel-Foyer-Storage-Shoe+Cabinet.svg'
          },
          {
            id: 2,
            title: 'Chest Of Drawers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Storage/Pixel-Foyer-Storage/Pixel-Foyer-Storage-Chest+Of+Drawers.svg'
          },
          {
            id: 3,
            title: 'Cabinet And Sideboards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Storage/Pixel-Foyer-Storage/Pixel-Foyer-Storage-Cabinet+and+Sideboards.svg'
          },
          {
            id: 4,
            title: 'Book Case',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Storage/Pixel-Foyer-Storage/Pixel-Foyer-Storage-Book+Case.svg'
          }
        ]
      },
      {
        id: 4,
        title: 'Soft Furnishings',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings.svg',
        subcategory: [
          {
            id: 1,
            title: 'Throws',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings-Throws.svg'
          },
          {
            id: 2,
            title: 'Rugs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings-Rugs.jpg'
          },
          {
            id: 3,
            title: 'Floor Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings-Floor+Runners.svg'
          },
          {
            id: 4,
            title: 'Cushions',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings-Cushions.svg'
          },
          {
            id: 5,
            title: 'Curtains',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings/Pixel-Foyer-Soft+Furnishings-Curtains.svg'
          }
        ]
      },
      {
        id: 5,
        title: 'Seating',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating.svg',
        subcategory: [
          {
            id: 1,
            title: 'Stool',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Stools1.svg'
          },
          {
            id: 2,
            title: 'Rocking Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Rocking+Chairs.svg'
          },
          {
            id: 3,
            title: 'Pouf',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Pouf.svg'
          },
          {
            id: 4,
            title: 'Plastic Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Plastic+Chairs.svg'
          },
          {
            id: 5,
            title: 'Ottoman',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Ottoman.jpg'
          },
          {
            id: 6,
            title: 'Metal Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Metal+Chairs.svg'
          },
          {
            id: 7,
            title: 'Lounge Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Lounge+Chairs.svg'
          },
          {
            id: 8,
            title: 'Diwan',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Diwans.svg'
          },
          {
            id: 9,
            title: 'Benches',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Benches.svg'
          },
          {
            id: 10,
            title: 'Bean Bags',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Bean+Bags.svg'
          },

          {
            id: 11,
            title: 'Arm Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Arm+Chairs.svg'
          },
          {
            id: 12,
            title: 'Accent Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Seating/Pixel-Foyer-Seating/Pixel-Foyer-Seating-Accent+Chairs.svg'
          }
        ]
      },
      {
        id: 6,
        title: 'Lighting',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting.svg',
        subcategory: [
          {
            id: 1,
            title: 'Table Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting-Table+Lamps.svg'
          },
          {
            id: 2,
            title: 'Sconces',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting-Sconces.svg'
          },
          {
            id: 3,
            title: 'Pendants',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting-Pendants.svg'
          },
          {
            id: 4,
            title: 'Floor Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting-Floor+Lamps.svg'
          },
          {
            id: 5,
            title: 'Chandeliers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting/Pixel-Foyer-Lighting-Chandeliers.svg'
          }
        ]
      },
      {
        id: 7,
        title: 'Decor',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Shelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Wall+shelves.svg'
          },
          {
            id: 2,
            title: 'Wall Plates',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Wall+Plates.svg'
          },
          {
            id: 3,
            title: 'Wall Mirrors',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Wall+Mirrors.png'
          },
          {
            id: 4,
            title: 'Wall Clocks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Wall+Clocks.svg'
          },
          {
            id: 5,
            title: 'Wall Art',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Wall+Art.svg'
          },
          {
            id: 6,
            title: 'Vases',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Vases.svg'
          },
          {
            id: 7,
            title: 'Sculptures',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Sculpture.svg'
          },
          {
            id: 8,
            title: 'Room Dividers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Room+Dividers.svg'
          },
          {
            id: 9,
            title: 'Planters',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Planters.svg'
          },
          {
            id: 10,
            title: 'Photoframe',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Photoframe.svg'
          },
          {
            id: 11,
            title: 'Key Holder',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Key+Holder.svg'
          },
          {
            id: 12,
            title: 'Cloth Holder',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Foyer/Pixel-Foyer-Decor/Pixel-Foyer-Decor/Pixel-Foyer-Decor-Cloth+Holder.svg'
          }
        ]
      }
    ]
  },
  {
    id: 7,
    title: 'Balcony',
    name: 'Balcony',
    image: 'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Balcony.png',
    hoverImage: 'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Balcony(white).png',

    category: [
      {
        id: 1,
        title: 'Tables',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Tables/Pixel-Balcony-Tables.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Mounted Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Tables/Pixel-Balcony-Tables/Pixel-Balcony-Tables-Wall+Mounted+Tables.svg'
          },
          {
            id: 2,
            title: 'Side Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Tables/Pixel-Balcony-Tables/Pixel-Balcony-Tables-Side+Tables.svg'
          },

          {
            id: 3,
            title: 'Console Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Tables/Pixel-Balcony-Tables/Pixel-Balcony-Tables-Console+Tables.svg'
          },
          {
            id: 4,
            title: 'Coffee Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Tables/Pixel-Balcony-Tables/Pixel-Balcony-Tables-Coffee+Tables.svg'
          }
        ]
      },

      {
        id: 2,
        title: 'Storage',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Storage/Pixel-Balcony-Storage.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wine Racks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Storage/Pixel-Balcony-Storage/Pixel-Balcony-Storage-Wine+Racks.svg'
          },
          {
            id: 2,
            title: 'Cabinet And Sideboards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Storage/Pixel-Balcony-Storage/Pixel-Balcony-Storage-Cabinet+and+Sideboards.svg'
          },

          {
            id: 3,
            title: 'Bar Trolleys',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Storage/Pixel-Balcony-Storage/Pixel-Balcony-Storage-Bar+Trolleys.svg'
          },
          {
            id: 4,
            title: 'Bar Cabinets',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Storage/Pixel-Balcony-Storage/Pixel-Balcony-Storage-Bar+Cabinets.svg'
          }
        ]
      },
      {
        id: 3,
        title: 'Soft Furnishings',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Soft Furnishings/Pixel-Balcony-Soft Furnishings.png',
        subcategory: [
          {
            id: 1,
            title: 'Throws',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Soft+Furnishings/Pixel-Balcony-Soft+Furnishings/Pixel-Balcony-Soft+Furnishings-Throws.svg'
          },
          {
            id: 2,
            title: 'Rugs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Soft+Furnishings/Pixel-Balcony-Soft+Furnishings/Pixel-Balcony-Soft+Furnishings-Rugs.jpg'
          },

          {
            id: 3,
            title: 'Cushions',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Soft+Furnishings/Pixel-Balcony-Soft+Furnishings/Pixel-Balcony-Soft+Furnishings-Cushions.svg'
          },
          {
            id: 4,
            title: 'Curtains',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Soft+Furnishings/Pixel-Balcony-Soft+Furnishings/Pixel-Balcony-Soft+Furnishings-Curtains.svg'
          }
        ]
      },
      {
        id: 4,
        title: 'Seating',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating.svg',
        subcategory: [
          {
            id: 1,
            title: 'Swings',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Swings.svg'
          },
          {
            id: 2,
            title: 'Stool',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Stools1.svg'
          },
          {
            id: 3,
            title: 'Rocking Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Rocking+Chairs.svg'
          },
          {
            id: 4,
            title: 'Pouf',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Pouf.svg'
          },
          {
            id: 5,
            title: 'Plastic Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Plastic+Chairs.svg'
          },
          {
            id: 6,
            title: 'Ottoman',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Ottomans.jpg'
          },

          {
            id: 7,
            title: 'Metal Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Metal+Chairs.svg'
          },
          {
            id: 8,
            title: 'Lounge Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Lounge+Chairs.svg'
          },
          {
            id: 9,
            title: 'Diwan',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Diwans.svg'
          },
          {
            id: 10,
            title: 'Benches',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Benches.svg'
          },
          {
            id: 11,
            title: 'Bean Bags',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Bean+Bags.svg'
          },
          {
            id: 12,
            title: 'Bar Stools',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Bar+Stool.svg'
          },

          {
            id: 13,
            title: 'Arm Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Arm+Chairs.jpg'
          },
          {
            id: 14,
            title: 'Accent Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Seating/Pixel-Balcony-Seating/Pixel-Balcony-Seating-Accent+Chairs.svg'
          }
        ]
      },
      {
        id: 5,
        title: 'Outdoor Furniture',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Outdoor+Furniture/Pixel-Balcony-Outdoor+Furniture.svg',
        subcategory: [
          {
            id: 1,
            title: 'Outdoor Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Outdoor+Furniture/Pixel-Balcony-Outdoor+Furniture/Pixel-Balcony-Outdoor+Furniture-Outdoor+Table.svg'
          },
          {
            id: 2,
            title: 'Outdoor Sets',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Outdoor+Furniture/Pixel-Balcony-Outdoor+Furniture/Pixel-Balcony-Outdoor+Furniture-Outdoor+Sets.svg'
          },
          {
            id: 3,
            title: 'Outdoor Seating',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Outdoor+Furniture/Pixel-Balcony-Outdoor+Furniture/Pixel-Balcony-Outdoor+Furniture-Outdoor+Seating.svg'
          }
        ]
      },
      {
        id: 6,
        title: 'Lighting',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Lighting/Pixel-Balcony-Lighting.svg',
        subcategory: [
          {
            id: 1,
            title: 'Sconces',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Lighting/Pixel-Balcony-Lighting/Pixel-Balcony-Lighting-Sconces.svg'
          },
          {
            id: 2,
            title: 'Pendants',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Lighting/Pixel-Balcony-Lighting/Pixel-Balcony-Lighting-Pendants.svg'
          },
          {
            id: 3,
            title: 'Floor Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Lighting/Pixel-Balcony-Lighting/Pixel-Balcony-Lighting-Floor+Lamps.svg'
          }
        ]
      },
      {
        id: 7,
        title: 'Decor',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Decor/Pixel-Balcony-Decor.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Shelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Decor/Pixel-Balcony-Decor/Pixel-Balcony-Decor-Wall+shelves.svg'
          },
          {
            id: 2,
            title: 'Wall Plates',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Decor/Pixel-Balcony-Decor/Pixel-Balcony-Decor-Wall+Plates.svg'
          },
          {
            id: 3,
            title: 'Wall Mirrors',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Decor/Pixel-Balcony-Decor/Pixel-Balcony-Decor-Wall+Mirrors.png'
          },
          {
            id: 4,
            title: 'Wall Art',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Decor/Pixel-Balcony-Decor/Pixel-Balcony-Decor-Wall+Art.svg'
          },
          {
            id: 5,
            title: 'Sculptures',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Decor/Pixel-Balcony-Decor/Pixel-Balcony-Decor-Sculpture.svg'
          },
          {
            id: 6,
            title: 'Planters',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Decor/Pixel-Balcony-Decor/Pixel-Balcony-Decor-Planters.svg'
          },
          {
            id: 7,
            title: 'Photoframe',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Balcony/Pixel-Balcony-Decor/Pixel-Balcony-Decor/Pixel-Balcony-Decor-Photoframe.svg'
          }
        ]
      }
    ]
  },
  {
    id: 8,
    title: 'Study Entertainment Room',
    name: 'Entertainment',
    image:
      'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Study+Entertainment+Room.png',
    hoverImage:
      'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Study+Entertainment+Room(white).png',
    category: [
      {
        id: 2,
        title: 'Tables',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Mounted Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables-Wall+Mounted+Tables.svg'
          },
          {
            id: 2,
            title: 'Study Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables-Study+Table.svg'
          },
          {
            id: 3,
            title: 'Side and End Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables-Side+and+End+Tables.svg'
          },
          {
            id: 4,
            title: 'Laptop Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables-Laptop+Tables.svg'
          },
          {
            id: 5,
            title: 'Console Table',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables-Console+Tables.svg'
          },
          {
            id: 6,
            title: 'Coffee Tables',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables-Coffee+Tables.svg'
          }
        ]
      },
      {
        id: 3,
        title: 'Storage',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables/Pixel-Study+and+Entertainment-Tables-Coffee+Tables.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wine Racks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage-Wine+Racks.svg'
          },
          {
            id: 2,
            title: 'Tv Unit',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage-TV+Unit.svg'
          },
          {
            id: 3,
            title: 'Crockery Unit',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage-Crockery+Units.svg'
          },
          {
            id: 4,
            title: 'Chest Of Drawers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage-Chest+Of+Drawers.svg'
          },
          {
            id: 5,
            title: 'Cabinet And Sideboards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage-Cabinet+and+Sideboards.svg'
          },
          {
            id: 6,
            title: 'Book Case',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage-Book+Case.svg'
          },
          {
            id: 7,
            title: 'Bar Trolleys',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage-Bar+Trolleys.svg'
          },
          {
            id: 8,
            title: 'Bar Cabinets',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage/Pixel-Study+and+Entertainment-Storage-Bar+Cabinets.svg'
          }
        ]
      },
      {
        id: 4,
        title: 'Soft Furnishings',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings.svg',
        subcategory: [
          {
            id: 1,
            title: 'Throws',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings-Throws.svg'
          },
          {
            id: 2,
            title: 'Table Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings-Table+Runners.svg'
          },
          {
            id: 3,
            title: 'Rugs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings-Rugs.jpg'
          },
          {
            id: 4,
            title: 'Quilts',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings-Quilts.svg'
          },
          {
            id: 5,
            title: 'Floor Runners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings-Floor+Runners.svg'
          },
          {
            id: 6,
            title: 'Cushions',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings-Cushions.svg'
          },
          {
            id: 7,
            title: 'Curtains',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings/Pixel-Study+and+Entertainment-Soft+Furnishings-Curtains.svg'
          }
        ]
      },
      {
        id: 5,
        title: 'Sofa',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa.svg',
        subcategory: [
          {
            id: 1,
            title: 'Sofa',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-Sofa.jpg'
          },
          {
            id: 2,
            title: 'Sofa Cum Bed',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-Sofa+Cum+Bed.svg'
          },
          {
            id: 3,
            title: 'Sectional',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-Sectional.svg'
          },
          {
            id: 4,
            title: 'Recliners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-Reclainers.svg'
          },
          {
            id: 5,
            title: 'Love Seat',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-Loveseats.svg'
          },
          {
            id: 6,
            title: 'L Shaped',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-L+Shaped.svg'
          },
          {
            id: 7,
            title: 'Futons',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-Futons.svg'
          },
          {
            id: 8,
            title: 'Chesterfield',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-Chesterfield.jpg'
          },
          {
            id: 9,
            title: 'Chaise Lounge',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa/Pixel-Study+and+Entertainment-Sofa-Chaise+Lounge.svg'
          }
        ]
      },
      {
        id: 6,
        title: 'Seating',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating.svg',
        subcategory: [
          {
            id: 1,
            title: 'Rocking Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating-Rocking+Chairs.svg'
          },
          {
            id: 2,
            title: 'Recliners',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating-Recliners.svg'
          },
          {
            id: 3,
            title: 'Pouf',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating-Pouf.svg'
          },
          {
            id: 4,
            title: 'Ottoman',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating-Ottomans.svg'
          },
          {
            id: 5,
            title: 'Ergonomic Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating-Ergonomic+Chairs.svg'
          },
          {
            id: 6,
            title: 'Bean Bags',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating-Bean+Bags.svg'
          },
          {
            id: 7,
            title: 'Arm Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating-Arm+Chairs.svg'
          },
          {
            id: 8,
            title: 'Accent Chairs',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating/Pixel-Study+and+Entertainment-Seating-Accent+Chairs.svg'
          }
        ]
      },
      {
        id: 7,
        title: 'Lighting',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting.svg',
        subcategory: [
          {
            id: 1,
            title: 'Table Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting-Table+Lamps.svg'
          },
          {
            id: 2,
            title: 'Sconces',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting-Sconces.svg'
          },
          {
            id: 3,
            title: 'Pendants',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting-Pendants.svg'
          },
          {
            id: 4,
            title: 'Floor Lamps',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting/Pixel-Study+and+Entertainment-Lighting-Floor+Lamps.svg'
          }
        ]
      },
      {
        id: 8,
        title: 'Decor',
        image:
          'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor.svg',
        subcategory: [
          {
            id: 1,
            title: 'Wall Shelves',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Wall+shelves.svg'
          },
          {
            id: 2,
            title: 'Wall Plates',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Wall+Plates.svg'
          },
          {
            id: 3,
            title: 'Wall Mirrors',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Wall+Mirrors.png'
          },
          {
            id: 4,
            title: 'Wall Clocks',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Wall+Clocks.svg'
          },
          {
            id: 5,
            title: 'Wall Art',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Wall+Art.svg'
          },

          {
            id: 6,
            title: 'Sculptures',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Sculpture.svg'
          },
          {
            id: 7,
            title: 'Room Dividers',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Room+Dividers.svg'
          },
          {
            id: 8,
            title: 'Planters',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Planters.svg'
          },
          {
            id: 9,
            title: 'Photoframe',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Photoframe.svg'
          },
          {
            id: 10,
            title: 'Bulletin Boards',
            image:
              'https://d3izcfql7lesks.cloudfront.net/shopPage/Pixel-Study+and+Entertainment/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor/Pixel-Study+and+Entertainment-Decor-Bulletin+Boards.svg'
          }
        ]
      }
    ]
  }
];
export const categoryAll = [
  'Armchair',
  'Accent Chair',
  'Sofa',
  'Dining Table',
  'Sectional',
  'Chair',
  'Coffee table',
  'Crockery Unit',
  'Side Table',
  'Bed',
  'Swing',
  'Dressing Unit',
  'Tv Unit',
  'Chest Of Drawers',
  'Rug',
  'Crib',
  'Floor Lamp',
  'Changing Station',
  'Table Lamp',
  'Storage',
  'Chandelier',
  'Puja Unit',
  'Pendant',
  'Shoe Cabinet',
  'Wall Sconces',
  'Mail Box',
  'Bookcase',
  'Key Hanger',
  'Bar Unit',
  'Outdoor Seater',
  'Console Table',
  'Swing',
  'Planters',
  'Fans',
  'Pouf',
  'LED Tube lights',
  'Benches',
  'Decor',
  'Recliners',
  'Curtain',
  'Laptop Table',
  'Wall Treatments',
  'Study Table',
  'Wall Shelf'
];
// export const newArrivals = [
//   {
//     id: 1,
//     name: 'Wooden Bed',
//     key: 'Wooden_Bed',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/WoodenBed.png'
//   },
//   {
//     id: 2,
//     name: 'Upholstered Beds',
//     key: 'Upholstered_Beds',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Upholstered+Beds.png'
//   },
//   {
//     id: 3,
//     name: 'Wall Mirrors',
//     key: 'Wall_Mirrors',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Wall+Mirrors.png'
//   },
//   {
//     id: 4,
//     name: 'Dining Tables',
//     key: 'Dining_Tables',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Dining+Tables.png'
//   },
//   {
//     id: 5,
//     name: 'Floor Lamps',
//     key: 'Floor_Lamps',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Floor+Lamps.png'
//   },
//   {
//     id: 6,
//     name: 'Pendants',
//     key: 'Pendants',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Pendants.png'
//   },
//   {
//     id: 7,
//     name: 'Sofa',
//     key: 'Sofa',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Sofa.png'
//   },
//   {
//     id: 8,
//     name: 'Accent Chairs',
//     key: 'Accent_Chairs',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Accent+Chairs.png'
//   },
//   {
//     id: 9,
//     name: 'Arm Chairs',
//     key: 'Arm_Chairs',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Arm+Chairs.png'
//   },
//   {
//     id: 10,
//     name: 'Cushions',
//     key: 'Cushions',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Cushions.png'
//   },
//   {
//     id: 11,
//     name: 'Rugs',
//     key: 'Rugs',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Rugs.png'
//   },
//   {
//     id: 12,
//     name: 'Cabinet And Sideboards',
//     key: 'Cabinet_And_Sideboards',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Cabinets+and+Sideboards.png'
//   },
//   {
//     id: 13,
//     name: 'Coffee Tables',
//     key: 'Coffee_Tables',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Coffee+Tables.png'
//   },
//   {
//     id: 14,
//     name: 'Laptop Tables',
//     key: 'Laptop_Tables',
//     bannerimg: 'https://d3izcfql7lesks.cloudfront.net/shop/Laptop+Tables.png'
//   }
// ];
// export const newarrivalcategory = [
//   {
//     id: 1,
//     value: 'Wooden Bed',
//     type: 'Wooden Bed'
//   },
//   {
//     id: 2,
//     value: 'Upholstered Beds',
//     type: 'Upholstered Beds'
//   },
//   {
//     id: 3,
//     value: 'Wall Mirrors',
//     type: 'Wall Mirrors'
//   },
//   {
//     id: 4,
//     value: 'Dining Tables',
//     type: 'Dining Tables'
//   },
//   {
//     id: 5,
//     value: 'Floor Lamps',
//     type: 'Floor Lamps'
//   },
//   {
//     id: 6,
//     value: 'Pendants',
//     type: 'Pendants'
//   },
//   {
//     id: 7,
//     value: 'Sofa',
//     type: 'Sofa'
//   },
//   {
//     id: 8,
//     value: 'Accent Chairs',
//     type: 'Accent Chairs'
//   },
//   {
//     id: 9,
//     value: 'Arm Chairs',
//     type: 'Arm Chairs'
//   },
//   {
//     id: 10,
//     value: 'Cushions',
//     type: 'Cushions'
//   },
//   {
//     id: 11,
//     value: 'Rugs',
//     type: 'Rugs'
//   },
//   {
//     id: 12,
//     value: 'Cabinet And Sideboards',
//     type: 'Cabinet And Sideboards'
//   },
//   {
//     id: 13,
//     value: 'Coffee Tables',
//     type: 'Coffee Tables'
//   },
//   {
//     id: 14,
//     value: 'Laptop Tables',
//     type: 'Laptop Tables'
//   }
// ];

export const PER_PAGE = 36;
