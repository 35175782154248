import React, { useState, useLayoutEffect, useEffect } from 'react';
import style from './Questionaaire2.module.css';
import Footer from './Footer';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import classnames from 'classnames';
import Header from '../Header/Header';
import Tabs from './Tabs';
import Snackbar from '@material-ui/core/Snackbar';
import './questionnaire2.css';
import { withRouter, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  questionnaire,
  postDate,
  post_questionnaire,
  page,
  storeMx,
  storeTab,
  storeMxAns,
  page2
} from '../../store/actions/sampleaction';

import moment from 'moment';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { makeStyles } from '@material-ui/styles';
import ConsoleHelper from '../../utils/ConsoleHelper';

const useStyles = makeStyles(() => ({
  Snackbar: {
    bottom: '71px !important'
  }
}));

const Questionaire2 = ({
  history,
  data,
  auth,
  questionnaireData,
  questionnaire,

  page2,
  storeMx,
  storeTab,
  storeMxAns,
  locationparam,
  historyAction
}) => {
  const classes = useStyles();
  const dimension = useMediaQuery();
  let [pg, setPg] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState();

  const [inputs, setInputs] = useState({
    home: questionnaireData.MxData.hasOwnProperty('MX_A_HOME')
      ? questionnaireData.MxData.MX_A_HOME
      : '',
    hometype: questionnaireData.MxData.hasOwnProperty('MX_A_HOME_TYPE')
      ? questionnaireData.MxData.MX_A_HOME_TYPE
      : '',
    location: {
      state:
        questionnaireData.MxData.hasOwnProperty('MX_A_LOCATION') &&
        questionnaireData.MxData.MX_A_LOCATION.state
          ? questionnaireData.MxData.MX_A_LOCATION.state
          : '',
      city:
        questionnaireData.MxData.hasOwnProperty('MX_A_LOCATION') &&
        questionnaireData.MxData.MX_A_LOCATION.city
          ? questionnaireData.MxData.MX_A_LOCATION.city
          : ''
    },
    stage: questionnaireData.MxData.hasOwnProperty('MX_A_STAGE')
      ? questionnaireData.MxData.MX_A_STAGE
      : '',
    room: {
      value:
        questionnaireData.MxData.hasOwnProperty('MX_A_ROOM_READY') &&
        questionnaireData.MxData.MX_A_ROOM_READY.value
          ? questionnaireData.MxData.MX_A_ROOM_READY.value
          : '',
      expected_date:
        questionnaireData.MxData.hasOwnProperty('MX_A_ROOM_READY') &&
        questionnaireData.MxData.MX_A_ROOM_READY.expected_date
          ? questionnaireData.MxData.MX_A_ROOM_READY.expected_date
          : ''
    },
    budget: questionnaireData.MxData.hasOwnProperty('MX_A_BUDGET')
      ? questionnaireData.MxData.MX_A_BUDGET
      : ''
  });

  const [citiesData, setCitiesData] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_LOCATION') &&
      questionnaireData.MxData.MX_A_LOCATION.state
      ? questionnaireData.MxData.MX_A_LOCATION.state
      : ''
  );

  useEffect(() => {
    setCurrentData(data[pg]);
  }, [pg]);

  const { id } = useParams();
  let currentProjectId = id;
  let addonValue;
  if (history.location.state && history.location.state.addon) {
    addonValue = history.location.state.addon;
  }

  useEffect(() => {
    if (currentProjectId) {
      history.push(`/questionnaire/${currentData.type}/${currentProjectId}`);
    } else {
      history.push(`/questionnaire/${currentData.type}`);
    }
  }, [currentData, currentProjectId]);

  useEffect(() => {
    if (historyAction && locationparam) {
      if (historyAction === 'POP') {
        if (pg === 0) {
          questionnaire('questionnaire');
          storeTab('questionnaire');
          if (currentProjectId) {
            history.replace(`/questionnaire/result/${currentProjectId}`);
          } else {
            history.replace('/questionnaire/space');
          }
        } else {
          pg = pg - 1;
          page2(pg);
          setPg(pg);
          history.replace(locationparam /* the new state */);
        }
      }
    }
  }, [historyAction, locationparam, currentProjectId]);

  const homeData = [
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2149_byo5u9.png',
      title: 'House'
    },
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2144_cfis1n.png',
      title: 'Apartment'
    },
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2147_igvcie.png',
      title: 'Villa'
    },
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2146_emmvyo.png',
      title: 'Row house'
    }
  ];

  const hometypeData = [
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2148_o1rfc0.png',
      title: 'Owned'
    },
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2142_gdxsyy.png',
      title: 'Rented'
    }
  ];

  const stageData = [
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_3157_qq58pl.png',
      title: 'Blank state'
    },
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_3156_t2xity.png',
      title: 'Halfway point'
    },
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_31566_p1xru3.png',
      title: 'Final Phase '
    }
  ];

  const roomData = [
    {
      img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3030.png',
      title: 'Sooner the better',
      subtitle: '7-14 days',
      minDay: 7,
      maxDay: 14,
      days: moment(new Date()).add(15, 'days').format('YYYY-MM-DD')
    },
    {
      img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3032.png',
      title: 'We have some time',
      subtitle: '15-30 days',
      minDay: 15,
      maxDay: 30,
      days: moment(new Date()).add(30, 'days').format('YYYY-MM-DD')
    },
    {
      img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3033.png',
      title: 'No hurry',
      subtitle: '30-60 days',
      minDay: 30,
      maxDay: 60,
      days: moment(new Date()).add(60, 'days').format('YYYY-MM-DD')
    }
  ];

  const budjetData = [
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2208_pseyip.png',
      title: 'Economical'
    },
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2210_jkgdww.png',
      title: 'Balanced'
    },
    {
      img: 'https://d3izcfql7lesks.cloudfront.net/Group_2211_ouu8dm.png',
      title: 'Give me the best'
    }
  ];

  useEffect(() => {
    if (pg === 1 && inputs.room.value !== '') {
      handleSubmit();
    }
  }, [pg, inputs]);

  useEffect(() => {
    if (questionnaireData.page_number2 >= 0) {
      setPg(questionnaireData.page_number2);

      populate();
    }
  }, [questionnaireData.page_number2]);
  const populate = () => {
    setInputs({
      home: questionnaireData.MxData.hasOwnProperty('MX_A_HOME')
        ? questionnaireData.MxData.MX_A_HOME
        : '',
      hometype: questionnaireData.MxData.hasOwnProperty('MX_A_HOME_TYPE')
        ? questionnaireData.MxData.MX_A_HOME_TYPE
        : '',
      location: {
        state:
          questionnaireData.MxData.hasOwnProperty('MX_A_LOCATION') &&
          questionnaireData.MxData.MX_A_LOCATION.state
            ? questionnaireData.MxData.MX_A_LOCATION.state
            : '',
        city:
          questionnaireData.MxData.hasOwnProperty('MX_A_LOCATION') &&
          questionnaireData.MxData.MX_A_LOCATION.city
            ? questionnaireData.MxData.MX_A_LOCATION.city
            : ''
      },
      stage: questionnaireData.MxData.hasOwnProperty('MX_A_STAGE')
        ? questionnaireData.MxData.MX_A_STAGE
        : '',
      room: {
        value:
          questionnaireData.MxData.hasOwnProperty('MX_A_ROOM_READY') &&
          questionnaireData.MxData.MX_A_ROOM_READY.value
            ? questionnaireData.MxData.MX_A_ROOM_READY.value
            : '',
        expected_date:
          questionnaireData.MxData.hasOwnProperty('MX_A_ROOM_READY') &&
          questionnaireData.MxData.MX_A_ROOM_READY.expected_date
            ? questionnaireData.MxData.MX_A_ROOM_READY.expected_date
            : ''
      },
      budget: questionnaireData.MxData.hasOwnProperty('MX_A_BUDGET')
        ? questionnaireData.MxData.MX_A_BUDGET
        : ''
    });
    setSelectedState(
      questionnaireData.MxData.hasOwnProperty('MX_A_LOCATION') &&
        questionnaireData.MxData.MX_A_LOCATION.state
        ? questionnaireData.MxData.MX_A_LOCATION.state
        : ''
    );
  };
  const handleSubmit = async (e) => {
    let userId;
    if (auth && auth.user) {
      userId = auth.user._id;
    } else {
      const getCurrentUser = JSON.parse(localStorage.getItem('MX-A'));
      if (getCurrentUser.hasOwnProperty('_id')) {
        userId = getCurrentUser._id;
      }
    }
    try {
      let body = {
        _id: userId,
        questionnaire: inputs
      };
      let mx1 = JSON.parse(localStorage.getItem('MX-A-answers'));
      let mxQns = { ...mx1.questionnaire, ...body.questionnaire };
      mx1.questionnaire = { ...mxQns };
      localStorage.setItem('MX-A-answers', JSON.stringify(mx1));
      let reduxMx;
      if (questionnaireData.hasOwnProperty('MxAns')) {
        reduxMx = questionnaireData.MxAns;
        if (reduxMx.hasOwnProperty('questionnaire')) {
          reduxMx.questionnaire = {
            ...reduxMx.questionnaire,
            ...body.questionnaire
          };
          storeMxAns({ ...reduxMx });
        }
      }
      let MX_A2 = {
        questionnaire: {
          MX_A_HOME: inputs.home,
          MX_A_HOME_TYPE: inputs.hometype,
          MX_A_LOCATION: inputs.location,
          MX_A_STAGE: inputs.stage,
          MX_A_ROOM_READY: inputs.room,
          MX_A_BUDGET: inputs.budget
        }
      };
      if (addonValue) {
        MX_A2.questionnaire.addon = false;
      }
      let qns1 = JSON.parse(localStorage.getItem('MX-A-questionnaire'));
      let qns = { ...qns1.questionnaire, ...MX_A2.questionnaire };
      qns1.questionnaire = { ...qns };
      localStorage.setItem('MX-A-questionnaire', JSON.stringify(qns1));
      // ConsoleHelper(MX_A2);
      if (currentProjectId) {
        await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${currentProjectId}`,
          MX_A2
        );
      }
      if (addonValue == true) {
        history.push({
          pathname: `/order/${currentProjectId}`,
          state: { projectId: currentProjectId, showHeader: false }
        });
      } else {
        storeTab('package');
        questionnaire('package');
      }
    } catch (err) {
      ConsoleHelper(err);
      setSnack(true);
      setSnackMessage('Something went wrong');
    }
  };

  useEffect(() => {
    if (currentData.type === 'home' && inputs.home === '') {
      setDisabled(true);
    }
    if (inputs.home !== '') {
      setDisabled(false);
    }
    if (currentData.type === 'hometype' && inputs.hometype === '') {
      setDisabled(true);
    }
    if (inputs.hometype !== '') {
      setDisabled(false);
    }
    if (
      currentData.type === 'location' &&
      inputs.location.state === '' &&
      inputs.location.city === ''
    ) {
      setDisabled(true);
    }
    if (inputs.location.state !== '' && inputs.location.city !== '') {
      setDisabled(false);
    }
    if (currentData.type === 'stage' && inputs.stage === '') {
      setDisabled(true);
    }
    if (inputs.stage !== '') {
      setDisabled(false);
    }
    if (
      currentData.type === 'room' &&
      inputs.room.value === '' &&
      inputs.room.expected_date === ''
    ) {
      setDisabled(true);
    }
    if (inputs.room.value !== '' && inputs.room.expected_date !== '') {
      setDisabled(false);
    }
    if (currentData.type === 'budget' && inputs.budget === '') {
      setDisabled(true);
    }
    if (inputs.budget !== '') {
      setDisabled(false);
    }
  }, [pg, currentData, inputs]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const countryApi = {
          key: 'X-CSCAPI-KEY',
          baseurl: 'TXFBUUNjaDIxV3QwcHFQd1B2YzJpNkZYWXJtUEJtWGpsUnhjdXZpaQ=='
        };
        let headers = new Headers();
        headers.append(countryApi.key, countryApi.baseurl);
        var requestOptions = {
          method: 'GET',
          headers: headers,
          redirect: 'follow'
        };

        fetch('https://api.countrystatecity.in/v1/countries/IN/states', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            // ConsoleHelper({ result });
            for (let i = 0; i < result.length; i++) {
              states.push({ name: result[i].name, stateCode: result[i].iso2 });
            }
            setStates([...states]);
          })
          .catch((error) => ConsoleHelper('error', error));
      } catch (err) {}
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const countryApi = {
          key: 'X-CSCAPI-KEY',
          baseurl: 'TXFBUUNjaDIxV3QwcHFQd1B2YzJpNkZYWXJtUEJtWGpsUnhjdXZpaQ=='
        };
        let headers = new Headers();
        headers.append(countryApi.key, countryApi.baseurl);
        var requestOptions = {
          method: 'GET',
          headers: headers,
          redirect: 'follow'
        };
        let selectedStateDetails = states.find((state) => state.name === selectedState);

        fetch(
          `https://api.countrystatecity.in/v1/countries/IN/states/${selectedStateDetails.stateCode}/cities`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            ConsoleHelper({ result });
            for (let i = 0; i < result.length; i++) {
              citiesData.push(result[i].name);
            }
            setCitiesData([...citiesData]);
          })
          .catch((error) => ConsoleHelper('error', error));
      } catch (err) {}
    };
    fetchCities();
  }, [selectedState]);

  return (
    <div className={style.Questionaire2Container}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        classes={{ root: classes.Snackbar }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header />

      <div
        style={{
          marginTop: dimension[0] < 500 ? '100px' : '110px'
        }}>
        <Tabs />
      </div>
      <div className="container" style={{ maxWidth: '100%', backgroundColor: 'white' }}>
        <div className="row" style={{ backgroundColor: 'white' }}>
          <div
            className={classnames(
              'col-sm-10 col-lg-6 col-md-8 d-flex flex-column align-items-center justify-content-center',
              style.headerContainer
            )}>
            <h1 className={style.header}>{currentData.title}</h1>
          </div>

          <div
            className={classnames(
              'col-sm-12 col-lg-6 col-md-8 d-flex flex-column align-items-center justify-content-center p-0',
              style.imageContainer
            )}
            style={{
              height: dimension[1] - 150
            }}>
            {currentData.type === 'home' && (
              <ResponsiveMasonry
                className={style.mesonryDesign}
                columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 2 }}>
                <Masonry>
                  {homeData.map((image, i) => (
                    <div style={{ position: 'relative', cursor: 'pointer' }} key={i}>
                      {inputs.home.includes(image.title) && (
                        <img
                          className={style.designClick}
                          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                          alt="tick"
                        />
                      )}
                      <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <div
                          className={classnames(
                            style.designIconContainer,
                            inputs.home.includes(image.title) && style.designIconContainerActive
                          )}
                          onClick={() => {
                            storeMx({
                              ...questionnaireData.MxData,
                              MX_A_HOME: image.title
                            });
                            setInputs({ ...inputs, home: image.title });
                          }}>
                          <img className={style.designIcon} src={image.img} alt="" />
                        </div>
                        <p className="mt-2">{image.title}</p>
                      </div>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}

            {currentData.type === 'hometype' && (
              <ResponsiveMasonry
                className={style.mesonryDesign}
                columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 2 }}>
                <Masonry>
                  {hometypeData.map((image, i) => (
                    <div style={{ position: 'relative', cursor: 'pointer' }} key={i}>
                      {inputs.hometype.includes(image.title) && (
                        <img
                          className={style.designClick}
                          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                          alt="tick"
                        />
                      )}
                      <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <div
                          className={classnames(
                            style.designIconContainer,
                            inputs.hometype.includes(image.title) && style.designIconContainerActive
                          )}
                          onClick={() => {
                            storeMx({
                              ...questionnaireData.MxData,
                              MX_A_HOME_TYPE: image.title
                            });
                            setInputs({ ...inputs, hometype: image.title });
                          }}>
                          <img className={style.designIcon} src={image.img} alt="" />
                        </div>
                        <p className="mt-2">{image.title}</p>
                      </div>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}

            {currentData.type === 'location' && (
              <div className={classnames('row justify-content-center', style.locationContainer)}>
                <div className="col-sm-12 col-md-6 text-center">
                  <select
                    required={true}
                    placeholder="Select State"
                    className={style.locationSelect}
                    value={inputs.location.state}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      storeMx({
                        ...questionnaireData.MxData,
                        MX_A_LOCATION: { state: e.target.value }
                      });
                      setInputs({
                        ...inputs,
                        location: { ...inputs.location, state: e.target.value }
                      });
                    }}>
                    <option style={{ borderRadius: '10px' }}>Select state</option>
                    {states.length > 0 &&
                      states.map((state) => <option value={state.name}>{state.name}</option>)}
                  </select>
                </div>
                <div className="col-sm-12 col-md-6 text-center">
                  <select
                    required={true}
                    placeholder="Select State"
                    className={style.locationSelect}
                    value={inputs.location.city}
                    onChange={(e) => {
                      storeMx({
                        ...questionnaireData.MxData,
                        MX_A_LOCATION: {
                          ...questionnaireData.MxData.MX_A_LOCATION,
                          city: e.target.value
                        }
                      });
                      setInputs({
                        ...inputs,
                        location: { ...inputs.location, city: e.target.value }
                      });
                    }}>
                    <option>Select city</option>
                    {citiesData.length > 0 &&
                      citiesData.map((city) => <option value={city}>{city}</option>)}
                  </select>
                </div>
              </div>
            )}

            {currentData.type === 'stage' && (
              <ResponsiveMasonry
                className={style.mesonryStage}
                columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 3 }}>
                <Masonry>
                  {stageData.map((image, i) => (
                    <div style={{ position: 'relative', cursor: 'pointer' }} key={i}>
                      {inputs.stage.includes(image.title) && (
                        <img
                          className={style.designClick}
                          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                          alt="tick"
                        />
                      )}
                      <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <div
                          className={classnames(
                            style.designIconContainer,
                            inputs.stage.includes(image.title) && style.designIconContainerActive
                          )}
                          onClick={() => {
                            storeMx({
                              ...questionnaireData.MxData,
                              MX_A_STAGE: image.title
                            });
                            setInputs({ ...inputs, stage: image.title });
                          }}>
                          <img className={style.designIcon} src={image.img} alt="" />
                        </div>
                        <p className="mt-2">{image.title}</p>
                      </div>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}

            {currentData.type === 'room' && (
              <>
                <ResponsiveMasonry
                  className={style.mesonryStage}
                  columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 3 }}>
                  <Masonry>
                    {roomData.map((image, i) => (
                      <div style={{ position: 'relative', cursor: 'pointer' }} key={i}>
                        <div className="d-flex flex-column align-items-center justify-content-evenly">
                          <div
                            className={classnames(
                              style.designIconContainer,
                              inputs.room.value &&
                                inputs.room.value.includes(image.title) &&
                                style.designIconContainerActive
                            )}
                            onClick={() => {
                              storeMx({
                                ...questionnaireData.MxData,
                                MX_A_ROOM_READY: {
                                  value: image.title,
                                  expected_date: image.days
                                }
                              });
                              setInputs({
                                ...inputs,
                                room: {
                                  ...inputs.room,
                                  value: image.title,
                                  expected_date: image.days
                                }
                              });
                            }}>
                            <img className={style.designIcon} src={image.img} alt="" />
                          </div>
                          <p className="mt-2 mb-0">{image.title}</p>
                          <span style={{ color: '#777777', fontSize: '11px' }}>
                            {image.subtitle}
                          </span>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </>
            )}

            {currentData.type === 'budget' && (
              <ResponsiveMasonry
                className={style.mesonryStage}
                columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 3 }}>
                <Masonry>
                  {budjetData.map((image, i) => (
                    <div style={{ position: 'relative', cursor: 'pointer' }} key={i}>
                      {inputs.budget.includes(image.title) && (
                        <img
                          className={style.designClick}
                          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                          alt="tick"
                        />
                      )}
                      <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <div
                          className={classnames(
                            style.designIconContainer,
                            inputs.budget.includes(image.title) && style.designIconContainerActive
                          )}
                          onClick={() => {
                            storeMx({
                              ...questionnaireData.MxData,
                              MX_A_BUDGET: image.title
                            });
                            setInputs({ ...inputs, budget: image.title });
                          }}>
                          <img className={style.designIcon} src={image.img} alt="" />
                        </div>
                        <p className="mt-2">{image.title}</p>
                      </div>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}
          </div>
        </div>
      </div>

      <Footer pg={pg} setPg={setPg} disable={disabled} />
    </div>
  );
};

Questionaire2.propTypes = {
  auth: PropTypes.object.isRequired,
  questionnaireData: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, {
  questionnaire,
  postDate,
  post_questionnaire,
  page,
  storeMx,
  storeTab,
  storeMxAns,
  page2
})(withRouter(Questionaire2));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
