export const styleData = [
  {
    title: 'South Indian',
    value: 'south_indian',
    content:
      "A style that stems from a love of traditional South Indian culture, mixed with aspirations to dwell in modern spaces. It's a cohesive blend of exotic, vibrant and interesting details, showcasing the traditional and cultural influences of each element.",
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/SouthIndian.jpg',
    priority: 1
  },
  {
    title: 'Classic Indian',
    value: 'classic_indian',
    content:
      'Deeply influenced by the classical period, its aesthetics and Western traditions, this style is all about geometry, symmetry and timelessness. Warm hues, signature decor and luxurious materials create a balanced and welcoming space that is luxurious and artistic yet tasteful.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Classic.jpg',
    priority: 2
  },
  {
    title: 'North Indian',
    value: 'north_indian',
    content:
      'North Indian-styled spaces have their own charm from distressed wood elements, natural light play, textile display, motifs, vibrant colours and more. Desi touches are everywhere like carved Mughal archways, windows, lanterns and a little kitsch thrown in for good measure.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/NorthIndian.jpg',
    priority: 3
  },
  {
    title: 'Minimalistic',
    value: 'minimalistic',
    content:
      'Think clean, modern lines, a tight edit, a concise colour palette, and ‘less is more’ silhouettes, layered into a warm, rich, and inviting home with a zen-like setting. This style is characterised by simplicity and a monochromatic palette with accents',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Minimal.jpg',
    priority: 4
  },
  {
    title: 'Contemporary',
    value: 'contemporary',
    content:
      'Defined by simplicity, subtle sophistication, deliberate use of texture, and clean lines, contemporary interiors are comfortable, welcoming and tend to highlight space rather than things. It also borrows many elements from other styles for an unique look',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Contemporary.jpg',
    priority: 5
  },
  {
    title: 'Mid Century modern',
    value: 'mid_century_modern',
    content:
      'Simple lines, pure forms, natural hues and grounded essentials define Mid-Century Modern design. It’s all about natural style and ease of living, with minimal ornamentation, and high functionality elements.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Mid+century+modern.jpg',
    priority: 6
  },
  {
    title: 'Eclectic',
    value: 'eclectic',
    content:
      'Bringing in elements from different periods, mixed textures, and complementary colors are wonderful staples of eclectic flair. It’s all about that quirky aesthetic, cool character and warmth that comes with layering, experimentation and contrast play.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Eclectic.jpg',
    priority: 7
  },
  {
    title: 'Pop Art',
    value: 'pop_art',
    content:
      'As an emotional and energetic style pop art-styled interiors include rainbow colours, flashy shapes, the use of plastic, repetitive elements. Decor inspired by the style looks unique, extraordinary, bold and with a keen sense of humor',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Pop+Art.jpg',
    priority: 8
  },
  {
    title: 'Art Deco',
    value: 'art_deco',
    content:
      'One of the 20th century’s most iconic styles, it’s a symbol of old-school glamour and sophistication. This inherently luxurious style displays a love for drama, geometry, symmetry, jewel tones and ornate embellishments',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Art+Deco.jpg',
    priority: 9
  },
  {
    title: 'Industrial',
    value: 'industrial',
    content:
      'The main element of Industrial style is the embracing of features that are typically concealed, like pipes and ducts, creating the signature ‘unfinished’ and ‘raw’ look. Contrasts define this style and it creates an understated depth, rustic charm, and a coarse lived-in feel.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Industrial.jpg',
    priority: 10
  },
  {
    title: 'Gothic',
    value: 'gothic',
    content:
      'Inspired by the Victorian era and Gothic churches, this design style is intricate and classic and defined by light play, ornate decorations and grandeur. It’s identifiable through dramatic carvings and patterns on all surfaces, moldings, spirals, stained glass and rich, dark colours.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Gothic.jpg',
    priority: 11
  },
  {
    title: 'Rustic',
    value: 'rustic',
    content:
      'With an emphasis on natural, aged and distressed elements, this style is approachable and relaxed, with a muted, traditional colour palette. It consists of textured and rugged spaces with earthy and handmade pieces that shape a warm, welcoming atmosphere.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Rustic.jpg',
    priority: 12
  },
  {
    title: 'Farm House',
    value: 'farm_house',
    content:
      'A balanced mix of straight lines, modern curves and iconic arches contrasted by the gradual curves, symmetry and functionalism found in nature.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/farm+house+03.jpg',
    priority: 13
  },
  {
    title: 'Bohemian',
    value: 'boho',
    content:
      'Bohemian design likes coloring outside the lines, it’s vibrant, carefree, full of personality and doesn’t have any hard and fast rules. This style is perfect for travelers and adventure-seekers who want to fill their homes with life, culture and interesting items.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Boho.jpg',
    priority: 14
  },
  {
    title: 'Coastal',
    value: 'coastal',
    content:
      'A beach-inspired interior style, it relies on natural light, a soft palette with more whites, neutrals and ocean hues, an airy feel, natural textures and a few maritime motifs. It creates a relaxed, breezy appearance all year round.',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Coastall.jpg',
    priority: 15
  }
];
