import React, { useEffect, useLayoutEffect, useState } from 'react';
import style from './Questionaire.module.css';
import './Questionairelayout.css';
import classnames from 'classnames';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { resultImages } from './images';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  post_questionnaire,
  questionnaire,
  page,
  storeMx,
  storeMxSkip,
  storeTab,
  storeMxAns,
  storeMxRetake
} from '../../store/actions/sampleaction';

import { Doughnut } from 'react-chartjs-2';
import Loader from 'react-loader-spinner';
import axios from 'axios';

const StyleResult = ({
  data,
  storeTab,
  questionnaireData,
  questionnaire,
  auth,
  scrollPosition,
  post_questionnaire,
  page,
  storeMx,
  storeMxSkip,
  storeMxAns,
  storeMxRetake,
  locationparam,
  historyAction
}) => {
  const [finalResponse] = useState('');

  const dimension = useMediaQuery();

  const [algoResponse, setAlgoResponse] = useState({});
  const [styleResultImage, setStyleResultImage] = useState('');
  const [resultStyleDescription, setResultStyleDescription] = useState('');
  const { userId, styleResultId } = useParams();
  const getUserData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/no-auth/user/${userId}`
      );
      if (res.status === 200) {
        const { share_style_result: styleResult } = res.data;
        setStyleResultImage(styleResult.image);
        setResultStyleDescription(styleResult.description);
        setAlgoResponse(styleResult);
      }
    } catch (err) {}
  };
  const getStyleResultData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/no-auth/style-result/${styleResultId}`
      );
      if (res.status === 200) {
        const { share_style_result: styleResult } = res.data.styleResult;
        setStyleResultImage(styleResult.image);
        setResultStyleDescription(styleResult.description);
        setAlgoResponse(styleResult);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (userId && userId != '0') {
      getUserData();
    } else {
      getStyleResultData();
    }
  }, [userId]);
  const imageSrc = (algo, index) => {
    const algo1 = algo.subStyles[index];
    let currentImage = resultImages.filter(function(obj) {
      return obj.tags[0] === algo1.name.toLowerCase();
    });

    if (currentImage) return currentImage[0].src;
  };

  const getDonutData = () => {
    let data1 = [];
    let sub1;
    let sub2;
    let sub3;
    if (finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated') {
      sub1 = algoResponse?.subStyles[0]?.name
        ?.replace('_', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    if (
      algoResponse.subStyles[1] &&
      (finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated')
    ) {
      sub2 = algoResponse?.subStyles[1]?.name
        ?.replace('_', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    if (
      algoResponse.subStyles[2] &&
      (finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated')
    ) {
      sub3 = algoResponse?.subStyles[2]?.name
        ?.replace('_', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    let val1 =
      finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
        ? ((algoResponse.subStyles[0]?.value / algoResponse.total) * 100).toFixed(0)
        : 33;
    let val2 =
      finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
        ? ((algoResponse.subStyles[1]?.value / algoResponse.total) * 100).toFixed(0)
        : 33;
    let val3 =
      finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
        ? ((algoResponse.subStyles[2]?.value / algoResponse.total) * 100).toFixed(0)
        : 33;
    let lab = [sub1];
    let vals = [val1];
    data1 = [
      {
        x: `${val1 + '% ' + sub1}`,
        y: parseInt(val1)
      }
    ];
    if (val2 > 0) {
      lab.push(sub2);
      vals.push(val2);
      data1 = [...data1, { x: `${val2 + '% ' + sub2}`, y: parseInt(val2) }];
    }
    if (val3 > 0) {
      lab.push(sub3);
      vals.push(val3);
      data1 = [...data1, { x: `${val3 + '% ' + sub3}`, y: parseInt(val3) }];
    }

    let data4 = {
      labels: lab,
      datasets: [
        {
          label: 'Sales',
          backgroundColor: ['#AA381C', '#386D73,', '#313131'],
          // borderColor: 'rgba(0,0,0,1)',
          borderWidth: 1,
          innerWidth: '2px',
          data: vals
        }
      ]
    };
    return data4;
  };
  return Object.keys(algoResponse).length ? (
    <div className={style.resultContainer1}>
      <form className="form-row">
        <div className="row  m-2">
          {dimension[0] > 500 && (
            <div className="col-lg-6 d-flex flex-column pt-4 d-flex flex-column align-items-center justify-content-center">
              <img
                src={styleResultImage}
                className="image-fluid"
                style={{ width: '80%', height: 'auto' }}
                alt=""
              />
            </div>
          )}
          <div className="col-sm-12  col-lg-6 d-flex flex-column ">
            {dimension[0] > 500 && (
              <>
                <div className="row form-row mt-1 ">
                  <div
                    className="col-6 pt-4 d-flex flex-column align-items-center justify-content-center"
                    style={{ height: '100%' }}>
                    {/* <div> */}
                    <div
                      style={{
                        backgroundColor: 'rgb(222 201 191)',
                        opacity: '1',
                        borderRadius: '10px',
                        height: '100%'
                      }}>
                      {algoResponse && algoResponse.subStyles[0] && (
                        <div>
                          <img
                            src={imageSrc(algoResponse, 0)}
                            className="justify-content-center mt-4 p-2 "
                            style={{
                              width: '35%',
                              height: 'auto',
                              alignItems: 'center',
                              textAlign: 'center',
                              opacity: '1',
                              marginTop: '45px',
                              marginLeft: '30%',
                              marginRight: '30%'
                            }}
                            alt=""
                          />
                        </div>
                      )}
                      <div>
                        <span
                          className="d-flex  justify-content-center"
                          style={{ opacity: '1', color: '#151515' }}>
                          {' '}
                          {}
                          {finalResponse !== null ||
                          questionnaireData.questionnaire1 === 'resultCalculated'
                            ? algoResponse?.subStyles[0]?.name
                                ?.replace('_', ' ')
                                .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                            : 'North indian'}
                        </span>
                      </div>
                      <h4>
                        <span
                          className="d-flex mt-2  justify-content-center"
                          style={{
                            color: '#A04D24',
                            opacity: '1',
                            fontFamily: 'QuickSand'
                          }}>
                          YOUR DOMINANT STYLE
                        </span>
                      </h4>
                      <p
                        className="pl-4 pr-4 pb-3"
                        style={{
                          textAlign: 'left',
                          fontSize: '14px',
                          opacity: '1',
                          fontFamily: 'Quicksand'
                        }}>
                        {resultImages.map(function(obj, i) {
                          if (obj.tags[0] === algoResponse?.subStyles[1]?.name)
                            return obj.description;
                        })}
                      </p>
                    </div>
                    {/* </div> */}
                  </div>
                  <div className=" pl-4 pt-4 col-6 ">
                    <div className="row justify-content-center d-flex">
                      <div style={{ height: '100%', width: '75%' }}>
                        <Doughnut
                          data={getDonutData()}
                          width={25}
                          height={50}
                          innerRadius={10}
                          options={{
                            cutout: 70,
                            cutoutPercentage: 90,
                            plugins: {
                              legend: {
                                display: false
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="row mt-2 justify-content-center align-items-center"
                      style={{
                        color: '#A04D24',
                        fontSize: 'inherit',
                        fontFamily: 'Quicksand'
                      }}>
                      {finalResponse !== null ||
                      questionnaireData.questionnaire1 === 'resultCalculated'
                        ? ((algoResponse.subStyles[0]?.value / algoResponse.total) * 100).toFixed(0)
                        : '33'}
                      %{' '}
                      {finalResponse !== null ||
                      questionnaireData.questionnaire1 === 'resultCalculated'
                        ? algoResponse?.subStyles[0]?.name
                            ?.replace('_', ' ')
                            .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        : 'North indian'}
                    </div>
                    <div
                      className="row mt-2 justify-content-center align-items-center"
                      style={{
                        color: '#627274',
                        fontSize: 'inherit',
                        fontFamily: 'Quicksand'
                      }}>
                      {finalResponse !== null ||
                      questionnaireData.questionnaire1 === 'resultCalculated'
                        ? ((algoResponse.subStyles[1]?.value / algoResponse.total) * 100).toFixed(0)
                        : '33'}
                      %{' '}
                      {finalResponse !== null ||
                      questionnaireData.questionnaire1 === 'resultCalculated'
                        ? algoResponse?.subStyles[1]?.name
                            ?.replace('_', ' ')
                            .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        : 'North indian'}
                    </div>
                    {algoResponse.subStyles[2] && (
                      <div
                        className="row  mt-2 justify-content-center align-items-center"
                        style={{
                          color: '#313131',
                          fontSize: 'inherit',
                          fontFamily: 'Quicksand'
                        }}>
                        {finalResponse !== null ||
                        questionnaireData.questionnaire1 === 'resultCalculated'
                          ? ((algoResponse.subStyles[2]?.value / algoResponse.total) * 100).toFixed(
                              0
                            )
                          : '33'}
                        %{' '}
                        {finalResponse !== null
                          ? algoResponse?.subStyles[2]?.name
                              ?.replace('_', ' ')
                              .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                          : 'North indian'}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </form>

      <div className="row mt-4 p-2 mb-5">
        <div className="col-lg-6">
          <h4
            style={{
              textAlign: 'left',
              marginLeft: '34px',
              fontFamily: 'Quicksand',
              fontSize: '25px'
            }}>
            Based on all the information gathered, we’ve deduced that your main style is{' '}
            <span style={{ color: 'brown' }}>
              {algoResponse?.bucket} {'!!'}
            </span>
          </h4>
          {dimension[0] < 500 && (
            <div className="col-sm-12 d-flex flex-column p-1 mt-3 d-flex flex-column align-items-center justify-content-center mb-3">
              <img src={styleResultImage} style={{ width: '70%', height: '90%' }} alt="" />
            </div>
          )}
          <div
            className="row"
            style={{
              textAlign: 'left',
              marginLeft: '34px',
              fontFamily: 'Quicksand',
              fontSize: '25px'
            }}>
            <h4>More about your style</h4>
          </div>

          <div
            style={{
              textAlign: 'left',
              marginLeft: '34px',
              fontFamily: 'Quicksand',
              fontSize: '17'
            }}>
            {resultStyleDescription}
          </div>
        </div>
        {dimension[0] < 500 && (
          <>
            <div className="row">
              <div className="col  ml-5 d-flex flex-column p-1 d-flex flex-column align-items-right justify-content-right mb-3">
                {/* <div style={{ "height": "100%", width: "75%" }}> */}
                <Doughnut
                  data={getDonutData()}
                  width={100}
                  height={70}
                  innerRadius={10}
                  options={{
                    cutoutPercentage: 90,
                    plugins: {
                      legend: {
                        display: false
                      }
                    }
                  }}
                />
                {/* </div> */}
              </div>
            </div>

            <div className="col-6 mt-2">
              <div
                className="row  ml-2"
                style={{
                  color: '#A04D24',
                  fontSize: 'inherit',
                  fontFamily: 'Quicksand'
                }}>
                {finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
                  ? ((algoResponse.subStyles[0]?.value / algoResponse.total) * 100).toFixed(0)
                  : '33'}
                %{' '}
                {finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
                  ? algoResponse?.subStyles[0]?.name
                      ?.replace('_', ' ')
                      .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                  : 'North indian'}
              </div>

              <div
                className="row  ml-2"
                style={{
                  color: '#627274',
                  fontSize: 'inherit',
                  fontFamily: 'Quicksand'
                }}>
                {finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
                  ? ((algoResponse.subStyles[1]?.value / algoResponse.total) * 100).toFixed(0)
                  : '33'}
                %{' '}
                {finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
                  ? algoResponse?.subStyles[1]?.name
                      ?.replace('_', ' ')
                      .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                  : 'North indian'}
              </div>
              <div
                className="row  ml-2"
                style={{
                  color: '#313131',
                  fontSize: 'inherit',
                  fontFamily: 'Quicksand'
                }}>
                {finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
                  ? ((algoResponse.subStyles[2]?.value / algoResponse.total) * 100).toFixed(0)
                  : '33'}
                %{' '}
                {finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
                  ? algoResponse?.subStyles[2]?.name
                      ?.replace('_', ' ')
                      .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                  : 'North indian'}
              </div>
            </div>
            <div
              className="row justify-content-center ml-3 mr-3 d-flex flex-column mt-2 p-1 d-flex flex-column align-items-right justify-content-right mb-3"
              style={{ width: '100%', height: '50%', paddingLeft: '10px' }}>
              <div
                class=" p-0 "
                style={{
                  backgroundColor: 'rgb(222 201 191)',
                  borderRadius: '10px',
                  height: '94%'
                }}>
                {algoResponse && algoResponse.subStyles[0] && (
                  <div>
                    <img
                      src={imageSrc(algoResponse, 0)}
                      className="justify-content-center"
                      style={{
                        width: '25%',
                        height: 'auto',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginTop: '45px',
                        marginLeft: '40%',
                        marginRight: '30%'
                      }}
                      alt=""
                    />
                  </div>
                )}
                <div>
                  <span className="d-flex  justify-content-center">
                    {' '}
                    {}
                    {finalResponse !== null ||
                    questionnaireData.questionnaire1 === 'resultCalculated'
                      ? algoResponse?.subStyles[0]?.name
                          ?.replace('_', ' ')
                          .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                      : 'North indian'}
                  </span>
                </div>
                <h4>
                  <span
                    className="d-flex mt-2 pl-4  justify-content-center"
                    style={{ color: '#A04D24', fontFamily: 'QuickSand' }}>
                    YOUR DOMINANT STYLE
                  </span>
                </h4>
                <p
                  className="pl-4 pr-4"
                  style={{
                    textAlign: 'left',
                    fontSize: '14px',
                    fontFamily: 'Quicksand'
                  }}>
                  {resultImages.map(function(obj, i) {
                    if (obj.tags[0] === algoResponse?.subStyles[1]?.name) return obj.description;
                  })}
                </p>
              </div>
              <hr></hr>
            </div>

            <div className="row">
              {algoResponse.subStyles[1] && (
                <div className={classnames('col ', style.resultList)}>
                  <div class="row form-row justify-content-center">
                    <div
                      className={classnames(
                        'col-7 pr-0 pt-1  mr-0 justify-content-center align-items-center',
                        style.mx5
                      )}>
                      {algoResponse && algoResponse.subStyles[1] && (
                        <img
                          src={imageSrc(algoResponse, 1)}
                          className="justify-content-center"
                          class="img-responsive  fit-image "
                          style={{ width: '70%', borderRadius: '10px' }}
                          alt=""
                        />
                      )}
                      <div></div>
                      <div className="row justify-content-center align-items-center">
                        <span
                          className="d-flex"
                          style={{ fontSize: '17px', fontFamily: 'Quicksand' }}>
                          {' '}
                          {}
                          {finalResponse !== null ||
                          questionnaireData.questionnaire1 === 'resultCalculated'
                            ? algoResponse?.subStyles[1]?.name
                                ?.replace('_', ' ')
                                .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                            : 'North indian'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center ml-4 mr-4">
                    <p
                      style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        fontFamily: 'Quicksand'
                      }}>
                      {resultImages.map(function(obj, i) {
                        if (obj.tags[0] === algoResponse?.subStyles[1]?.name)
                          return obj.description;
                      })}
                    </p>
                  </div>
                </div>
              )}
              <hr></hr>
            </div>

            <div className="row">
              {algoResponse.subStyles[1] && (
                <div className={classnames('col ', style.resultList)}>
                  <div class="row form-row justify-content-center">
                    <div
                      className={classnames(
                        'col-7 pr-0 pt-1  mr-0 justify-content-center align-items-center',
                        style.mx5
                      )}>
                      {algoResponse && algoResponse.subStyles[2] && (
                        <img
                          src={imageSrc(algoResponse, 2)}
                          className="justify-content-center"
                          class="img-responsive  fit-image "
                          style={{ width: '70%', borderRadius: '10px' }}
                          alt=""
                        />
                      )}
                      <div></div>
                      <div className="row justify-content-center align-items-center">
                        <span
                          className="d-flex"
                          style={{ fontSize: '17px', fontFamily: 'Quicksand' }}>
                          {' '}
                          {}
                          {finalResponse !== null ||
                          questionnaireData.questionnaire1 === 'resultCalculated'
                            ? algoResponse?.subStyles[2]?.name
                                ?.replace('_', ' ')
                                .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                            : 'North indian'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center ml-4 mr-4">
                    <p
                      style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        fontFamily: 'Quicksand'
                      }}>
                      {resultImages.map(function(obj, i) {
                        if (obj.tags[0] === algoResponse?.subStyles[2]?.name)
                          return obj.description;
                      })}
                    </p>
                  </div>
                </div>
              )}
              <hr></hr>
            </div>
          </>
        )}
        {dimension[0] > 500 && (
          <div className="col-lg-6 ">
            <div className="row">
              {algoResponse.subStyles[1] && (
                <div className={classnames('col ', style.resultList)}>
                  <div class="row form-row" style={{ height: '45px' }}>
                    <div
                      className={classnames(
                        'col-4 pr-0 pt-1 ml-2 mr-0 justify-content-center align-items-center',
                        style.mx5
                      )}>
                      {algoResponse && algoResponse.subStyles[1] && (
                        <img
                          src={imageSrc(algoResponse, 1)}
                          class="img-responsive  fit-image "
                          style={{ width: '100%', borderRadius: '10px' }}
                          alt=""
                        />
                      )}
                      <div>
                        <span
                          className="d-flex  justify-content-start p-2"
                          style={{ fontSize: '17px', fontFamily: 'Quicksand' }}>
                          {' '}
                          {}
                          {finalResponse !== null ||
                          questionnaireData.questionnaire1 === 'resultCalculated'
                            ? algoResponse?.subStyles[1]?.name
                                ?.replace('_', ' ')
                                .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                            : 'North indian'}
                        </span>
                      </div>
                    </div>
                    <div className="col-7 mt-2 pl-3">
                      <p
                        style={{
                          textAlign: 'left',
                          fontSize: '14px',
                          fontFamily: 'Quicksand'
                        }}>
                        {resultImages.map(function(obj, i) {
                          if (obj.tags[0] === algoResponse?.subStyles[1]?.name)
                            return obj.description;
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {algoResponse.subStyles[2] && (
                <div className={classnames('col ', style.resultList)}>
                  <div class="row form-row" style={{ height: '45px' }}>
                    <div
                      className={classnames(
                        'col-4 pt-1 mr-0 pr-0 justify-content-center align-items-center',
                        style.mx5
                      )}>
                      {algoResponse && algoResponse.subStyles[2] && (
                        <img
                          src={imageSrc(algoResponse, 2)}
                          class="img-responsive fit-image"
                          style={{ width: '100%', borderRadius: '10px' }}
                          alt=""
                        />
                      )}
                      <div>
                        <span
                          className="d-flex  pl-3 justify-content-center p-2"
                          style={{ fontSize: '17px', fontFamily: 'Quicksand' }}>
                          {finalResponse !== null ||
                          questionnaireData.questionnaire1 === 'resultCalculated'
                            ? algoResponse?.subStyles[2]?.name
                                ?.replace('_', ' ')
                                .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                            : 'North indian'}
                        </span>
                      </div>
                    </div>
                    <div className="col-7 mt-2 pl-3 ">
                      <p
                        className=" ml-0"
                        style={{
                          textAlign: 'left',
                          fontSize: '14px',
                          fontFamily: 'Quicksand'
                        }}>
                        {resultImages.map(function(obj, i) {
                          if (obj.tags[0] === algoResponse?.subStyles[2]?.name)
                            return obj.description;
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData,
    pageValue: state.questionnaireData.page_number
  };
};
export default connect(mapStateToProps, {
  post_questionnaire,
  questionnaire,
  page,
  storeMx,
  storeMxSkip,
  storeTab,
  storeMxAns,
  storeMxRetake
})(withRouter(trackWindowScroll(StyleResult)));
function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
