import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import style from './Explore.module.css';
import Header from '../../components/Header/Header';
import { useHistory } from 'react-router';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Image } from 'semantic-ui-react';
import arrow from '../../assets/images/Iconionic-ios.png';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: '50px'
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '6px',
        height: '10px',
        marginLeft: '11px'
      }
    },
    paper: {
      width: '90%',
      overflow: 'hidden',
      margin: '0px auto'
    },
    imagefit: {
      width: '200px',
      height: '200px',
      objectFit: 'contain',
      cursor: 'pointer'
    }
  })
);

let roomsData = [
  {
    id: 1,
    name: 'Living Room',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/LivingRoom.svg'
  },
  {
    id: 2,
    name: 'Dining Room',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/DiningRoom.svg'
  },
  {
    id: 3,
    name: 'Bed room',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/BedRoom.svg'
  },
  {
    id: 4,
    name: 'Kids Room',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/KidsRooms.svg'
  },
  {
    id: 5,
    name: 'Study Entertainment Room',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/StudyEntertainmentRoom.svg'
  },
  {
    id: 6,
    name: 'Puja Room',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/PujaRoom.svg'
  },
  {
    id: 7,
    name: 'Foyer',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/Foyer.svg'
  },
  {
    id: 8,
    name: 'Balcony',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/style-icons/Balcony.svg'
  }
];

let stylesData = [
  {
    id: 1,
    name: 'South Indian',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/south+indian.svg'
  },
  {
    id: 2,
    name: 'North Indian',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/north+indian.svg'
  },
  {
    id: 3,
    name: 'Classic Indian',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/classical.svg'
  },
  {
    id: 4,
    name: 'Minimalistic',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/minimalistic.svg'
  },
  {
    id: 5,
    name: 'Mid Century',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/mid-century+modern.svg'
  },
  {
    id: 6,
    name: 'Art Deco',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/art+deco.svg'
  },
  {
    id: 7,
    name: 'Pop Art',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/pop-art.svg'
  },
  {
    id: 8,
    name: 'Industrial',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/industrial.svg'
  },
  {
    id: 9,
    name: 'Rustic',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/rustic.svg'
  },
  {
    id: 10,
    name: 'Gothic',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/gothic.svg'
  },
  {
    id: 11,
    name: 'Boho',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/bohemian.svg'
  },
  {
    id: 12,
    name: 'Farm House',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/farm+house.svg'
  },
  {
    id: 13,
    name: 'Coastal',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/coastal.svg'
  },
  {
    id: 14,
    name: 'Eclectic',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/eclectic.svg'
  },
  {
    id: 15,
    name: 'Contemporary',
    img: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Icons/contemporary.svg'
  }
];

function ExploreCategory() {
  const { category } = useParams();
  const dimension = useMediaQuery();
  const history = useHistory();
  const classes = useStyles();
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    if (category == 'styles') {
      setCategoryData(stylesData);
    } else if (category == 'rooms') {
      setCategoryData(roomsData);
    }
  }, []);

  return (
    <>
      <Header />
      <div style={{ minHeight: '100vh' }}>
        <div className={classnames('container', style.container)}>
          <div className={classnames(style.menu)}>
            <span
              onClick={() => {
                history.push(`/explore`);
              }}>
              Home
            </span>{' '}
            {'>'} <span>{category && category.charAt(0).toUpperCase() + category.slice(1)}</span>
          </div>
          <div className={classnames(style.categoryHeading)}>
            {category && category.toUpperCase()}
          </div>
          <div style={{ width: dimension[0] > 700 ? '50%' : '100%' }}>
            Make room for some furniture Inspiration! Find everything for your home under one roof.
            From your bedroom to your balcony, we've got you covered!..
          </div>
          <div className="row" style={{ marginTop: '1.5rem' }}>
            {categoryData.map((data, index) => {
              return (
                <>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-6" style={{ paddingTop: '15px' }}>
                    <div
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/explore/${category.toLowerCase()}/${data.name}`);
                      }}>
                      <Paper className={classes.paper}>
                        <Image
                          draggable={false}
                          className={classes.imagefit}
                          src={data?.img ? data.img : ''}
                        />
                      </Paper>
                      <p
                        style={{ fontFamily: 'Quicksand', cursor: 'pointer' }}
                        className={classnames(style.displayrow, 'tagname')}>
                        {data.name} <img src={arrow} className={classes.imgtag} />
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExploreCategory;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
