import React, { useState } from 'react';
import '../../assets/css/common.css';
import { NavLink, useHistory } from 'react-router-dom';
import { page, storeMx } from '../../store/actions/sampleaction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';

const TopBar = ({ setPg, pg, skip, page, back, setBack, storeMx, questionnaireData }) => {
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState();
  const history = useHistory();
  // ConsoleHelper("page", pg)
  return (
    <div className="topbar">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <div className="left" style={{ display: 'inline-flex' }}>
        <img
          src={'https://d3izcfql7lesks.cloudfront.net/Group_3156_bk1s3d.svg'}
          style={{ height: '50px' }}
          alt=""
        />
        {pg >= 0 && (
          <button
            className="d-flex flex-row align-items-center"
            onClick={() => {
              if (pg === 0) {
                history.push('/');
              } else if (
                pg === 2 &&
                questionnaireData.MxData.hasOwnProperty('MX_A_SPACE') &&
                questionnaireData.MxData.MX_A_SPACE.length > 0
              ) {
                setPg(0);
                page(0);
              } else if (
                pg === 2 &&
                questionnaireData.MxData.hasOwnProperty('MX_A_STYLE') &&
                questionnaireData.MxData.MX_A_STYLE.length > 0
              ) {
                setPg(1);
                page(1);
              }
              // else if (questionnaireData.hasOwnProperty('MxSkip') && questionnaireData.MXSkip !== 'space' && pg === 2) {
              //     setPg(0)
              //     page(0)
              // }
              else if (
                pg === 5 &&
                questionnaireData.MxData.hasOwnProperty('MX_A_ROOMS') &&
                questionnaireData.MxData.MX_A_ROOMS.length > 1
              ) {
                setPg(pg - 1);
                page(pg - 1);
              } else if (
                pg === 5 &&
                questionnaireData.MxData.hasOwnProperty('MX_A_ROOMS') &&
                questionnaireData.MxData.MX_A_ROOMS.length < 2
              ) {
                setPg(pg - 2);
                page(pg - 2);
              } else if (pg === 7) {
                setPg(pg - 2);
                page(pg - 2);
              } else if (pg === 9) {
                setPg(pg - 2);
                page(pg - 2);
              } else if (
                pg === 1 &&
                questionnaireData.MxData.hasOwnProperty('MX_A_STYLE') &&
                questionnaireData.MxData.MX_A_STYLE.length < 1
              ) {
                setPg(0);
                page(0);
              } else if (
                pg === 1 &&
                questionnaireData.MxData.hasOwnProperty('MX_A_STYLE') &&
                questionnaireData.MxData.MX_A_STYLE.length > 0
              ) {
                setPg(pg);
                page(pg);
                setSnack(true);
                setSnackMessage('Please deselect style images to go back');
              } else {
                setPg(pg - 1);
                page(pg - 1);
              }
            }}
            style={{ backgroundColor: 'transparent', border: 'none' }}
            // disabled={pg === 0}
            // onClick={() => setBack(true)}
          >
            <img
              className="ml-3"
              style={{ cursor: 'pointer', height: '20px' }}
              src={'https://d3izcfql7lesks.cloudfront.net/Group_1917_yle8iw.svg'}
              alt=""
            />
            <p className="mt-3 ml-2">Go Back</p>
          </button>
        )}
      </div>
      <NavLink to="/">
        <img
          src={'https://d3izcfql7lesks.cloudfront.net/Group_2350_kcg9xv.svg'}
          alt=""
          style={{ height: '25px', margin: '15px 15px 0px 0px' }}
          onClick={() => {
            localStorage.setItem('MX-A-Complete', false);
            page(pg);
          }}
        />
      </NavLink>
    </div>
  );
};
TopBar.propTypes = {
  questionnaire: PropTypes.string,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    questionnaire: state.questionnaireData.active,
    active: state.questionnaireData.active,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, { page, storeMx })(TopBar);
