import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './ExploreDetail.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Modal as Modal1 } from 'react-bootstrap';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import customerApiInstance from '../../utils/CustomerApiInstance';
import Carousel from 'react-grid-carousel';
import * as Icon from 'react-bootstrap-icons';
import { storeCartCount } from '../../store/actions/sampleaction';
import getCartDataCount from '../../utils/CartDetails';
import Shareurl from '../../components/Shareurl';
import ARIcon from '../../assets/ARexpIcon.png';
import _3DIcon from '../../assets/3d-viewer.svg';
import QRScanner from '../../components/layout/QrScanner';
import ConsoleHelper from '../../utils/ConsoleHelper';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: theme.shadows[5]
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: 'none',
    width: 800,
    height: 500,
    padding: theme.spacing(2, 4, 3)
  }
}));

const ExploreDetail = ({ history, auth, storeCartCount }) => {
  const classes = useStyles();
  const { id } = useParams();
  const dimension = useMediaQuery();
  const [overlay, setOverlay] = useState(null);
  const [animation, setAnimation] = useState(false);
  const [products, setProducts] = useState([]);
  const [moodBoard, setMoodBoard] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [recommendedMoodBoards, setRecommendedMoodBoards] = useState([]);
  const [moodBoardChanged, setMoodBoardChanged] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const chevronWidth = 40;
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [prodQuantity] = useState(1);
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [savedMoodBoardIds, setSavedMoodBoardIds] = useState([]);
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  const [copiedUrl, setCopiedUrl] = useState('');
  const [allBoards, setAllBoards] = useState([]);
  const [currentData, setCurrentData] = useState('');
  const [newBoardCreated, setNewBoardCreated] = useState(false);
  const [open, setOpen] = useState({ modal: false });
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [productIds, setProductId] = useState();

  const addToFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
      ConsoleHelper(e);
    }
  };
  const removeFromFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
      ConsoleHelper(e);
    }
  };
  const fetchProductlistData = async () => {
    try {
      if (auth && auth.user) {
        let boards1 = [];
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
        );
        let result = [...res.data];
        for (let i = 0; i < result.length; i++) {
          boards1.push({
            id: result[i]._id,
            moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
            title: result[i].name,
            moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
            products:
              result[i].products && result[i].products.length > 0 ? [...result[i].products] : []
          });
        }
        setAllBoards([...boards1]);
      }
    } catch (err) {
      ConsoleHelper(err);
    }
  };
  const fetchFavouriteData = async () => {
    try {
      if (auth && auth.user) {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
        );
        let savedIds = [];

        if (res.data.favourites[0].length !== 0) {
          res.data.favourites.map(dat => {
            savedIds = dat.products.map(m => {
              return m.product_id;
            });
          });
          setSavedProductIds([...savedIds]);
        }
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
    }
  };
  const handleOver = e => {
    setOverlay(e);
  };
  function openinnewtab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }
  const renderColumns = numCols => {
    let cols = [];
    let rows = [];
    products.map((data, index) => {
      cols.push(
        <div
          key={`product${index}`}
          className=" col-lg-3 col-md-4 col-sm-12 mb-md-5 mb-md-0 mb-lg-0">
          <div
            onMouseOver={() => handleOver(data._id)}
            onMouseLeave={() => setOverlay(null)}
            className={style.renderColumns_container}
            style={{ position: 'relative' }}>
            {overlay === data._id && (
              <div className={style.overlay}>
                <div>
                  <button
                    className={classnames('d-flex justify-content-end ', style.saveBtn)}
                    onClick={() => {
                      setCurrentProduct({ ...data });
                      setModal({ ...modal, modal: true, name: 'save' });
                    }}>
                    Save
                  </button>
                </div>

                <div
                  style={{ height: '50%', position: 'relative' }}
                  className={classnames('col-12 d-flex justify-content-center ', '')}>
                  <button
                    style={{
                      background: 'transparent',
                      color: 'white',
                      marginTop: '80px',
                      marginBottom: '50px',
                      fontSize: '14px',
                      border: '1.5px solid #ffffff',
                      borderRadius: '0',
                      fontWeight: '500',
                      alignSelf: 'center'
                    }}
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      openinnewtab(`/product/${data?._id}`);
                    }}>
                    View Details
                  </button>
                </div>
              </div>
            )}
            <img
              src={data.image[0]}
              alt=""
              style={{
                width: '100%',
                height: '14em',
                objectFit: 'contain'
              }}
            />
          </div>
          <div
            className="row mb-5 mt-2"
            style={{
              display: 'flex',
              fontWeight: 600,
              justifyContent: 'space-between'
            }}>
            <div
              className={`col-6 ${classnames(style.textEliptic)}`}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {data.name}
            </div>
            <div
              className={`col-6 d-flex ${
                dimension[0] < 500 ? 'justify-content-end' : 'justify-content-end'
              }`}
              style={{ paddingRight: dimension[0] < 500 ? '9%' : '9%' }}>
              <p>
                {dimension[0] < 500
                  ? `₹ ${new Intl.NumberFormat('en-IN').format(data.price)}`
                  : `₹ ${new Intl.NumberFormat('en-IN').format(data.price)}`}
              </p>
            </div>
          </div>
        </div>
      );
    });
    if (cols.length) {
      rows.push(
        <div className="row d-flex align-items-center" key={rows.length} style={{ width: '100%' }}>
          {cols}
        </div>
      );
    }
    return rows;
  };
  const handleClose = () => {
    setOpen({ ...open, modal: false });
  };
  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
  const addToCart = async productData => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        const isArray = productData instanceof Array;
        if (!isArray) {
          prodBody = {
            user_id: userId,
            products: [
              {
                product_id: productData._id,
                quantity: prodQuantity,
                rate: productData.price || productData.pixel_price,
                total_price: prodQuantity * (productData.price || productData.pixel_price)
              }
            ]
          };
        } else {
          let allProducts = [];
          for (let i = 0; i < productData.length; i++) {
            allProducts.push({
              product_id: productData[i]._id,
              quantity: 1,
              rate: productData[i].price,
              total_price: 1 * (productData[i].price || productData[i].pixel_price)
            });
          }

          prodBody = {
            user_id: userId,
            products: [...allProducts]
          };
        }
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products;
        const isArray = productData instanceof Array;
        if (!isArray) {
          products = [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ];
        } else {
          let allProducts = [];
          for (let i = 0; i < productData.length; i++) {
            allProducts.push({
              product_id: productData[i]._id,
              quantity: 1,
              rate: productData[i].price,
              total_price: 1 * (productData[i].price || productData[i].pixel_price)
            });
          }
          products = [...allProducts];
        }
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
        setSnack(true);
        setSnackMessage('Please Login/Signup to add products to your cart');
        setTimeout(() => {
          history.push('/login');
        }, 1000);
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }
    storeCartCount(await getCartDataCount(auth));
  };
  const handleFavourites = product => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds(prev => [...prev, product._id]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter(filter => filter !== product._id));
      removeFromFavourites(product);
    }
  };
  const handleAddToBoard = async moodBoardData => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to save the Boards');
      return false;
    }
    setSavedMoodBoardIds([...savedMoodBoardIds, moodBoardData._id]);
    let productIds = [];
    moodBoardData.products &&
      moodBoardData.products.map(prodArray => {
        prodArray.map(product => {
          productIds.push(product.product_id);
        });
      });
    productIds = [...new Set(productIds)];
    const addToBoardData = {
      moodboard_id: moodBoardData._id,
      name: moodBoardData.title,
      products: productIds
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/add`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('MoodBoard addedd to my Board successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const fetchBoardData = async () => {
    try {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/list/${auth.user._id}`
      );
      let savedIds = [];

      res.data.map(dat => {
        savedIds = [...savedIds, dat.moodboard_id];
      });
      setSavedMoodBoardIds([...savedIds]);
    } catch (err) {
      ConsoleHelper('errorrrr', err);
    }
  };
  const getImage = () => {
    if (Object.keys(currentProduct).length && currentProduct.image.length > 0) {
      return currentProduct.image[0];
    }
  };
  const checkProdsSaved = board => {
    let allProdIds = [];
    let otherBoards = [];
    let existingProd;
    otherBoards = allBoards.filter(allbrd => allbrd.id !== board.id);
    otherBoards.map(brd =>
      brd.products.map(prd => {
        allProdIds.push(prd._id);
      })
    );
    existingProd = allProdIds.some(x => x === currentProduct._id);
    return existingProd ? true : false;
  };
  const removeFromBoard = async boardData => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to remove from the Boards');
      return false;
    }

    let prodIds = [];
    boardData.products.map(pd => {
      prodIds.push(pd._id);
    });
    prodIds = prodIds.filter(pd => pd !== currentProduct._id);
    const removeBoardData = {
      moodboard_id: boardData.moodBoard_id,
      products: [...prodIds],
      name: boardData.title,
      user_id: auth.user._id
    };
    allBoards.forEach(board => {
      if (board.id === boardData.id) {
        board.products = board.products.filter(pd => pd._id !== currentProduct._id);
      }
    });
    for (let i = 0; i < allBoards.length; i++) {
      if (allBoards[i].id === boardData.id) {
        allBoards[i].products = allBoards[i].products.filter(pd => pd._id !== currentProduct._id);
      }
    }
    setAllBoards([...allBoards]);

    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${boardData.id}`,
        removeBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('Product removed from Board successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const saveToBoard = async boardData => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to save the Boards');
      return false;
    }
    let prodIds = [];
    boardData.products.map(pd => {
      prodIds.push(pd._id);
    });
    const addToBoardData = {
      moodboard_id: boardData.moodBoard_id,
      products: [...prodIds, currentProduct._id],
      name: boardData.title,
      user_id: auth.user._id
    };
    for (let i = 0; i < allBoards.length; i++) {
      if (allBoards[i].id === boardData.id) {
        allBoards[i].products.push(currentProduct);
      }
    }
    setAllBoards([...allBoards]);
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${boardData.id}`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('Product saved to Board successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const createNewBoard = async () => {
    const addToBoardData = {
      products: currentProduct._id,
      name: currentData,
      user_id: auth.user._id
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/add`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setNewBoardCreated(true);
        setModal({ ...modal, modal: false, name: '' });
        setSnack(true);
        setSnackMessage('Board created successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (newBoardCreated) {
          let boards1 = [];
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
          );
          let result = [...res.data];
          for (let i = 0; i < result.length; i++) {
            boards1.push({
              id: result[i]._id,
              moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
              title: result[i].name,
              moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
              products:
                result[i].products && result[i].products.length > 0 ? [...result[i].products] : []
            });
          }
          setAllBoards([...boards1]);
          setNewBoardCreated(false);
        }
      } catch (err) {
        ConsoleHelper(err);
      }
    };
    fetchData();
  }, [newBoardCreated]);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const res1 = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/${id}`);
        setMoodBoard(res1.data.moodBoard);
        setRooms(res1.data.room_3d[0]);
        setProducts([...res1.data.moodBoardProducts]);
        setTotalPrice(res1.data.totalPrice);
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/list`);
        let allMoodBd = res.data;
        allMoodBd = allMoodBd.filter(board => board._id !== id && board.explore === true);
        allMoodBd = allMoodBd.filter(brd =>
          brd.styles.map(st => res1.data.moodBoard.styles.includes(st))
        );
        allMoodBd = allMoodBd.filter(board =>
          board.styles.some(r => res1.data.moodBoard.styles.includes(r))
        );
        setRecommendedMoodBoards([...allMoodBd]);
        setMoodBoardChanged(false);
      } catch (err) {
        ConsoleHelper(err);
      }
    };
    fetchData();
  }, [moodBoardChanged]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [open]);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchFavouriteData();
    fetchProductlistData();
    fetchBoardData();
    setRecommendedMoodBoards(shuffle(recommendedMoodBoards));
    let url = window.location.href;
    setCopiedUrl(url);
  }, []);

  const handleQr = productIds => {
    setQrModalOpen(true);
    setProductId(productIds);
  };

  function openArinnewtab(url) {
    ConsoleHelper(url);
    window.location.assign(url);
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {qrModalOpen && (
        <QRScanner
          id={productIds}
          modalOpen={qrModalOpen}
          setModalOpen={setQrModalOpen}
          type={'Moodboard'}
        />
      )}
      <Header />
      <div
        className={style.carouselContainer}
        style={{ marginTop: '130px', display: 'flex', justifyContent: 'center' }}>
        <img
          className="img-responsive "
          style={{
            width: '80%',
            display: 'block'
          }}
          src={moodBoard.preview_url}
        />
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            bottom: 0,
            marginLeft: dimension[0] > 500 ? '15%' : '',
            alignItems: dimension[0] > 500 ? '' : 'end'
          }}>
          {rooms?.hasOwnProperty('model_url') && rooms.model_url !== '' && (
            <button
              style={{
                position: dimension[0] > 500 ? '' : '',
                right: dimension[0] > 500 ? '' : '47px',
                bottom: dimension[0] > 500 ? '2%' : '6%',
                marginRight: '2%',
                borderRadius: dimension[0] < 500 ? '' : '40px',
                marginBottom: dimension[0] > 500 ? '' : '10px',
                width: dimension[0] < 500 ? '' : 'auto',
                height: dimension[0] < 500 ? '' : '40px',
                border: 'none',
                background: 'none'
              }}
              onClick={() => {
                openinnewtab(`/3droom/${rooms.moodboard_id}`);
              }}>
              <img
                src={_3DIcon}
                className={style.Icon3d}
                style={{ width: '35px', height: '35px' }}
              />
            </button>
          )}

          {moodBoard?.hasOwnProperty('models_status') && moodBoard.models_status == 3 && (
            <button
              style={{
                position: dimension[0] > 500 ? '' : '',
                right: dimension[0] > 500 ? '' : '47px',
                bottom: dimension[0] > 500 ? '2%' : '6%',
                marginRight: '2%',
                marginBottom: dimension[0] > 500 ? '' : '10px',
                width: dimension[0] < 500 ? '' : 'auto',
                height: dimension[0] < 500 ? '' : '40px',
                border: 'none',
                background: 'none'
              }}
              onClick={e => {
                e.stopPropagation();
                dimension[0] > 500
                  ? handleQr(moodBoard?._id)
                  : openArinnewtab(`pmapp://explore?${moodBoard?._id}`);
              }}>
              <img src={ARIcon} className={style.arIcon} />
            </button>
          )}

          <button
            style={{
              position: dimension[0] > 500 ? '' : '',
              right: dimension[0] > 500 ? '' : '47px',
              bottom: dimension[0] > 500 ? '2%' : '6%',
              marginRight: '2%',
              marginBottom: dimension[0] > 500 ? '' : '10px',
              width: dimension[0] < 500 ? '85px' : 'auto',
              height: dimension[0] < 500 ? '32px' : '40px',
              borderRadius: 30,
              paddingLeft: dimension[0] > 500 ? '25px' : '',
              paddingRight: dimension[0] > 500 ? '25px' : '',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              backgroundColor: '#ffffff'
            }}
            disabled={savedMoodBoardIds.includes(moodBoard._id)}
            onClick={() => {
              handleAddToBoard(moodBoard);
            }}>
            {savedMoodBoardIds.includes(moodBoard._id) ? 'Saved' : 'Save'}
          </button>
          <div style={{ bottom: '2%' }}>
            <Shareurl fabdirection={dimension[0] > 500 ? 'right' : 'up'} copiedUrl={copiedUrl} />
          </div>
        </div>
      </div>
      <div className={classnames(style.mainContainer, 'container justify-content-center')}>
        <div className="row justify-content-center mt-5">
          <h2 className={style.header}>{moodBoard.title}</h2>
          <br></br>
          <p
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 600
            }}>
            {moodBoard.description}.
          </p>
          <br></br>
        </div>
        {products && products.length > 0 ? (
          <>
            <span
              className="row justify-content-center "
              style={{
                fontSize: dimension[0] > 500 ? '20px' : '12px',
                textDecoration: 'underline'
              }}>
              Shop {moodBoard.title} look for ₹ {new Intl.NumberFormat('en-IN').format(totalPrice)}
            </span>
            <br></br>

            <div
              className="row justify-content-center mt-5"
              style={{ width: dimension[0] > 500 ? '100%' : '' }}>
              {renderColumns()}
            </div>
            <div className="row justify-content-center">
              <div
                className={classnames(
                  style.shopContainer,
                  'd-flex align-items-center justify-content-center'
                )}>
                <div
                  className="row w-100 m-sm-5 m-lg-3 m-md-3 m-xl-5 m-xs-3"
                  style={{ height: 'auto' }}>
                  <div className="col-sm-12 col-lg-6 text-center  d-flex align-items-center justify-content-center">
                    <h5 className={classnames(style.headerSty)}>
                      Shop {moodBoard.title} look for ₹ {' '}
                      {new Intl.NumberFormat('en-IN').format(totalPrice)}
                    </h5>
                  </div>
                  <div className="col-sm-12 col-lg-6 text-center">
                    <button
                      className={classnames(style.shop_button, 'btn btn-rounded')}
                      onClick={() => {
                        addToCart(products);
                      }}>
                      Shop All Products
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <span className=" row mt-10 justify-content-center">
            {/* <h2 className={style.header}>Products not available</h2> */}
            <br></br>
          </span>
        )}
      </div>
      {recommendedMoodBoards && recommendedMoodBoards.length > 0 && (
        <>
          <div className={style.container3} style={{ padding: '10vh 5vw 10vh 5vw' }}>
            <h5 className="pl-3">You might also like this</h5>
            <div
              className=" mt-5"
              style={{
                padding: `0 ${chevronWidth}px`,
                marginTop: '50px',
                paddingLeft: dimension[0] < 500 ? '' : '0',
                paddingRight: dimension[0] < 500 ? '0' : '',
                cursor: 'pointer'
              }}>
              {dimension[0] > 500 ? (
                <Carousel cols={4} rows={1} gap={20} loop autoplay={5000} hideArrow={true}>
                  {recommendedMoodBoards &&
                    recommendedMoodBoards.map((board, index) => (
                      <Carousel.Item key={`moodboard${index}`}>
                        <div style={{ position: 'relative' }}>
                          <img
                            style={{ borderRadius: '10px', width: '100%' }}
                            className={style.container2Image}
                            src={board.preview_url}
                            alt=""
                          />
                          <button
                            onClick={() => {
                              setMoodBoardChanged(true);
                              auth.isAuthenticated
                                ? history.push(`/explore-detail/${board._id}`)
                                : history.push(`/pre-explore-detail/${board._id}`);
                            }}
                            className={style.viewBtnss}>
                            View
                          </button>
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              ) : (
                <Carousel cols={1} rows={1} gap={20} loop autoplay={5000}>
                  {recommendedMoodBoards &&
                    recommendedMoodBoards.map((board, index) => (
                      <Carousel.Item>
                        <img
                          style={{ borderRadius: '6px', width: '100%' }}
                          className={style.container3Image}
                          src={board.preview_url}
                          alt=""
                          onClick={() => {
                            setMoodBoardChanged(true);
                            history.push(`/explore-detail/${board._id}`);
                          }}
                        />
                      </Carousel.Item>
                    ))}
                </Carousel>
              )}
            </div>
          </div>
        </>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open?.modal}>
          <div
            className={classnames(classes.paper, style.modalContainer)}
            style={{ height: dimension[0] > 500 ? '' : '100vh' }}>
            <div className="d-flex justify-content-between align-items-center">
              <div
                className="d-flex justify-content-around align-items-center flex-row"
                style={{ display: 'flex' }}>
                <div
                  className={style.heart}
                  style={{
                    marginTop: '-1%',
                    backgroundPosition:
                      auth && auth.user && savedProductIds?.includes(open.data?._id)
                        ? 'right'
                        : 'left',
                    display: dimension[0] > 500 ? '' : 'none'
                  }}
                  onClick={() => {
                    setAnimation(!animation);
                    handleFavourites(open.data);
                  }}></div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '2%',
                    right: dimension[0] > 500 ? '54%' : '53%'
                  }}>
                  <Shareurl fabdirection="up" copiedUrl={copiedUrl} />
                </div>
              </div>
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen({ ...open, modal: false })}
                src={
                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2420.svg'
                }
                alt=""
              />
            </div>
            <div
              className="container"
              style={{
                width: dimension[0] > 500 ? '85%' : '100%',
                marginTop: dimension[0] < 500 ? '-1%' : '10%',
                padding: dimension[0] > 500 ? '' : '0px'
              }}>
              <div className="row">
                <div className="col-sm-12 col-lg-5">
                  <img src={open.data?.image[0]} className={style.productImg} alt="" />
                </div>
                <div
                  className="row"
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    zIndex: '1000',
                    marginTop: '-1.8em',
                    marginLeft: '0',
                    display: dimension[0] < 500 ? '' : 'none'
                  }}>
                  <div
                    className={style.heart}
                    style={{
                      marginTop: '2%',
                      backgroundPosition:
                        auth && auth.user && savedProductIds?.includes(open.data?._id)
                          ? 'right'
                          : 'left',
                      display: dimension[0] < 500 ? '' : 'none',
                      backgroundColor: 'white'
                    }}
                    onClick={() => {
                      setAnimation(!animation);
                      handleFavourites(open.data);
                    }}></div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '2%',
                      right: dimension[0] > 500 ? '54%' : '53%'
                    }}>
                    <Shareurl fabdirection="up" copiedUrl={copiedUrl} />
                  </div>
                </div>
                <div
                  className="col-sm-12 col-lg-7 pl-4"
                  style={{ textAlign: dimension[0] < 500 ? 'center' : '' }}>
                  <h4 className={style.nameTitle}>{open.data?.name}</h4>
                  <h4 className={style.price}>
                    <b>
                      Rs.
                      {new Intl.NumberFormat('en-IN').format(open.data?.pixel_price)}
                    </b>
                    <span className={style.prePrice} style={{ opacity: 0.7, marginLeft: '15px' }}>
                      <s>
                        Rs.
                        {new Intl.NumberFormat('en-IN').format(open.data?.price)}
                      </s>
                    </span>
                  </h4>
                  <br></br>
                  <p>Color: {open.data?.color}</p>
                  <p>Material: {open.data?.material}</p>
                  <p>
                    Dimensions: {open.data?.dimensions.l} {" '' "}L *{open.data?.dimensions.b}{' '}
                    {" '' "}W
                  </p>
                  <div
                    className={classnames(
                      'd-flex justify-content-start align-items-center mt-3',
                      style.btnsStyle
                    )}>
                    <button
                      className={style.buttonWhite}
                      onClick={() => history.push(`/product/${open.data?._id}`)}>
                      View Full Details
                    </button>
                    <button
                      className={classnames(style.buttonBlack, 'ml-2')}
                      onClick={() => {
                        addToCart(open.data);
                      }}>
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal1
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'save'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal modalSave'}
        keyboard={false}>
        <div className={style.modalHeader}>
          <h4 className={style.saveBoards}>Save to Board</h4>
          <img
            className="mr-5 mt-4"
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setModal({ ...modal, modal: false, name: '' })}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>

        <div className={classnames('row justify-content-center', style.modalRow)}>
          <div
            className={classnames(
              'col-md-6 pt-5 col-xs-12 pt-5 d-flex justify-content-center',
              style.imageCol
            )}>
            <img
              src={getImage()}
              alt=""
              style={{ width: '100%', minHeight: '70%', maxHeight: '80%' }}
            />
          </div>
          <div className={classnames('col-md-6 col-xs-12 ', style.createCol)}>
            <div className="row ">
              <span
                className={classnames(' col-6 p-0 pt-4 pl-3  justify-content-start', style.save1)}>
                Save to Board
              </span>
              <span className=" col-6 d-flex  mt-3 p-sm-0 p-xs-0  justify-content-end ">
                <button
                  className={classnames('mr-lg-3 mr-md-3', style.createBoardButton)}
                  onClick={() => setModal({ ...modal, modal: true, name: 'create' })}>
                  Create Board
                </button>
              </span>
            </div>
            <hr className="p-0"></hr>
            {allBoards.map((board, index) => {
              return (
                <div className="row mb-2" key={`board${index}`}>
                  <div className="col-6">
                    <img
                      src={
                        board.hasOwnProperty('moodBoard') && board.moodBoard
                          ? board.moodBoard.preview_url
                          : null
                      }
                      style={{
                        width: '100%',
                        minHeight: '70%',
                        maxHeight: '80%'
                      }}
                      alt=""
                    />
                  </div>
                  <div className="col-4 d-flex justify-content-left align-items-left">
                    <span style={{ fontSize: '12px', fontFamily: 'QuickSand' }}>{board.title}</span>
                  </div>
                  <div className="col-2 d-flex justify-content-center align-items-center">
                    {auth && auth.user && board.products.some(x => x._id === currentProduct._id) ? (
                      <Icon.HeartFill
                        style={{ fill: '#FFC4C4', borderColor: 'black' }}
                        onClick={() => {
                          if (checkProdsSaved(board)) {
                            removeFromBoard(board);
                          } else {
                            setSnack(true);
                            setSnackMessage('Please save the product to other boards');
                          }
                        }}></Icon.HeartFill>
                    ) : (
                      <Icon.Heart
                        onClick={() => {
                          saveToBoard(board);
                        }}></Icon.Heart>
                    )}
                  </div>
                  <hr></hr>
                </div>
              );
            })}
          </div>
        </div>
      </Modal1>
      <Modal1
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'create'}
        onHide={() => setModal({ ...modal, modal: false, name: '' })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalHeader}>
          <h4 className={style.createBoard}>Create Your Board</h4>
          <img
            className="mr-5 mt-4"
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setModal({ ...modal, modal: false, name: '' })}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>

        <div className={classnames('row justify-content-center', style.modalRow)}>
          <div className={classnames('col-md-4 col-xs-12 pt-5', style.imageCol)}>
            <img
              src={getImage()}
              alt=""
              style={{ width: '100%', minHeight: '70%', maxHeight: '80%' }}
            />
          </div>
          <div className={classnames('col-md-8 col-xs-12', style.createCol)}>
            <h6>Board Name</h6>
            <input
              type="text"
              placeholder="Enter Board name"
              onChange={e => setCurrentData(e.target.value)}
            />
            <button
              className={style.createBoardButton1}
              onClick={() => {
                createNewBoard();
              }}>
              Create Board
            </button>
          </div>
        </div>
      </Modal1>

      <Footer />
    </>
  );
};

ExploreDetail.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(ExploreDetail));
function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
