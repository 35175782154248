import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import style from './HireADesigner.module.css';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import stepsImage from '../../assets/stepsImage.png';
import PnMButton from '../../components/button/PnMButton';

const steps = [
  {
    id: 1,
    no: '01.',
    title: 'Find your style',
    subTitle:
      'Answer a few simple questions by clicking on images that visually appeal to you and your unique style.'
  },
  {
    id: 2,
    no: '02.',
    title: 'Preview furniture in your space with our AR viewer',
    subTitle:
      'This tool helps you visually compare products virtually in your room, so you know you"re picking the best piece for your space.'
  },
  {
    id: 3,
    no: '03.',
    title: `Buy with confidence and revamp your space with our designer's expertise`,
    subTitle:
      'Your personal designer will share 3 mood-boards where you can toggle and choose what works best for you.'
  },
  {
    id: 4,
    no: '04.',
    title: 'Buy and get the new look',
    subTitle:
      'Add to cart, add to home! We work with the best in business. Versatile brands, hundreds of deals, and thousands of furniture waiting for you'
  }
];

const Workings = ({ history }) => {
  // const dimension = useMediaQuery();
  const [activeStep, setActiveStep] = useState(0);
  const [animationLineWidth, setAnimationLineWidth] = useState(0);
  const activeStepRef = useRef(activeStep);
  const animationLineWidthRef = useRef(animationLineWidth);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const animationTime = 3000; //change animationTime in ms to increase or decrease the animation time
    const interval = setInterval(() => {
      setAnimationLineWidth(0); // Start from 0 at the beginning of the interval
      startAnimation(animationTime);
      if (activeStepRef.current === 4) {
        setActiveStep(1);
        activeStepRef.current = 1; // Update the ref value for the next iteration
      } else {
        setActiveStep((prevStep) => {
          const newStep = prevStep + 1;
          activeStepRef.current = newStep; // Update the ref value for the next iteration
          return newStep;
        });
      }
    }, animationTime);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const startAnimation = (animationTime) => {
    let animationInterval;
    let animationStartTime;
    animationInterval = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - animationStartTime;
      const progress = elapsedTime / animationTime; // animationTime in milliseconds

      if (progress >= 1) {
        clearInterval(animationInterval);
        setAnimationLineWidth(100); // Set to 100 at the end of the interval
        animationLineWidthRef.current = 100; // Update the ref value for the next iteration
      } else {
        setAnimationLineWidth(progress * 100);
        animationLineWidthRef.current = progress * 100; // Update the ref value for the next iteration
      }
    }, 10); // Update the state every 10ms for smoother animation

    animationStartTime = Date.now();
  };

  return (
    <>
      <Header />
      <div className={style.mainContainer}>
        <div className={style.videoPlayerContainer}>
          <div className="row d-flex justify-content-center flex-column text-center align-items-center m-auto">
            <span className={style.videoPlayerHeading}>HOW IT WORKS</span>
            <div className={style.videoPlayerSubHeading}>
              Your new home can be ready in 4 simple steps
            </div>
          </div>

          <div class={classNames(style.videoPlayerRow, 'row w-100 m-auto')}>
            <div className=" col-xl-8 col-lg-8 col-md-12 col-sm-12 p-1 m-auto">
              <div className={classNames(style.videoPlayerWrapper, 'player-wrapper')}>
                <ReactPlayer
                  url="https://pixel-mortar.s3.ap-south-1.amazonaws.com/Pixel+%26+Mortar.mp4"
                  playing
                  className="react-player"
                  pip={true}
                  stopOnUnmount={false}
                  width="100%"
                  height="100%"
                  controls={true}
                  light={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/placeholderForVideo.png'}
                />
              </div>
            </div>
          </div>
        </div>
        <div class={classNames(style.stepsRow, 'row w-100 m-auto')}>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 p-1 m-auto">
            <div className={style.stepsContainer}>
              <div className={style.steps}>
                {steps.map((step, index) => (
                  <div className={style.step} key={index}>
                    <div className={style.stepLeft}>
                      <div
                        className={activeStep == step.id ? style.stepNoBoxActive : style.stepNoBox}>
                        {step.no}
                      </div>
                    </div>
                    <div className={style.stepRight}>
                      <div
                        className={activeStep == step.id ? style.stepTitleActive : style.stepTitle}>
                        {step.title}
                      </div>
                      <div
                        className={style.stepSubTitle}
                        style={{ display: activeStep != step.id && 'none' }}>
                        {step.subTitle}
                      </div>
                    </div>
                    <div
                      className={style.stepAnimationLine}
                      style={{
                        width: `${animationLineWidth}%`,
                        display: activeStep != step.id && 'none'
                      }}></div>
                  </div>
                ))}
              </div>
              <div className={style.stepsImage}>
                <img
                  src={stepsImage}
                  style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row m-auto">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 p-1 m-auto">
            <PnMButton
              type={'filled'}
              name={'Get Started'}
              size={'lg'}
              sx={{ marginTop: '1.5rem' }}
              onClick={() => history.push(history.push('/questionnaire'))}
            />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default withRouter(Workings);
// function useMediaQuery() {
//   const [screenSize, setScreenSize] = useState([0, 0]);

//   useLayoutEffect(() => {
//     function updateScreenSize() {
//       setScreenSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateScreenSize);
//     updateScreenSize();
//     return () => window.removeEventListener('resize', updateScreenSize);
//   }, []);

//   return screenSize;
// }
