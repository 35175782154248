import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './categories.module.css';
import axios from 'axios';
import { Tags } from '../../components/Tags/tags';
import arrow from '../../assets/images/Iconionic-ios.png';
import classNames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    displayrow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline'
    }
  })
);

export default function Categories({ history }) {
  const [catList, setCatList] = useState([]);
  const classes = useStyles();

  const fetchCategories = async () => {
    const tags = await Tags();
    setCatList(tags);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <div>
        <Header />
      </div>
      {catList.length > 0 && (
        <>
          <div className={style.typesSection}>
            <div>
              <div className={classNames(style.roomType, classes.displayrow)}>
                <div
                  className={classNames(classes.displayrow)}
                  style={{ cursor: 'pointer', color: '#171717', padding: '10px' }}
                  onClick={() =>
                    history.push({
                      pathname: `/shop`
                    })
                  }>
                  Shop
                </div>
                <div className={classes.displayrow}>
                  <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                  <div>Categories</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {catList.map((category, index) => (
              <div className={style.category} key={index}>
                <div
                  className={style.categoryImageContainer}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    history.push({
                      pathname: `/mainproduct`,
                      state: { 'category[]': category.name }
                    })
                  }>
                  <img src={category.image} alt="" className={style.categoryImage} />
                  <h4 className={style.categoryTitle}>{category.name}</h4>
                </div>
                <div className={style.subcategoryContainer}>
                  <div className={style.subcategories}>
                    {category.subcat.map((subcategory, subIndex) => (
                      <div
                        key={subIndex}
                        className={style.subcategory}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          history.push({
                            pathname: `/mainproduct`,
                            state: {
                              'category[]': category.name,
                              'subcategory[]': subcategory.name
                            }
                          })
                        }>
                        <img src={subcategory.image} alt="" className={style.subcategoryImage} />
                        <p className={style.subcategoryTitle}>{subcategory.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div>
        <Footer />
      </div>
    </>
  );
}
