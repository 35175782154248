import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './BoardDetail.module.css';
import { Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classnames from 'classnames';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Snackbar from '@material-ui/core/Snackbar';
import { styleData } from './constants';
import { InlineShareButtons } from 'sharethis-reactjs';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal1 from '@material-ui/core/Modal';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { storeCartCount } from '../../store/actions/sampleaction';
import getCartDataCount from '../../utils/CartDetails';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon
} from 'react-share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const BoardDetail = ({ history, auth, storeCartCount }) => {
  const [overlay, setOverlay] = useState(null);
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  const dimension = useMediaQuery();
  let [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const [allBoards, setAllBoards] = useState([]);
  const [currentBoard, setCurrentBoard] = useState({});
  const [snackMessage, setSnackMessage] = useState('');
  const [snack, setSnack] = useState(false);
  const [currentData, setCurrentData] = useState('');
  const [newBoardCreated, setNewBoardCreated] = useState(false);
  const copy = useRef();
  const [copiedUrl, setCopiedUrl] = useState('');
  const [open, setOpen] = useState({});
  const [recommendedMoodBoards, setRecommendedMoodBoards] = useState([]);
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [prodQuantity] = useState(1);
  const [animation, setAnimation] = useState(false);
  const [modal1, setModal1] = useState({ modal1: false, name: '', id: null });
  const [currentBoardTemp, setCurrentBoardTemp] = useState({});
  const [productsTemp, setProductsTemp] = useState([]);
  const [deleteFavProduct, setDeleteFavProduct] = useState(false);
  const [alert1, setAlert1] = useState(true);
  const [alert2, setAlert2] = useState(true);
  const alertRef = useRef(alert1);
  const alertRef1 = useRef(alert2);
  alertRef.current = alert1;
  alertRef1.current = alert2;

  const Msg = ({ closeToast }) => (
    <>
      <div>Product Deleted, do you want to undo?</div>
      <div className="row mt-4 mb-0 justify-content-center">
        <div className="col-6">
          <button
            onClick={() => {
              setAlert1(true);
              if (deleteFavProduct === true) {
                finalDeleteFavouriteProduct(currentProduct);
              } else {
                finalDeleteProduct(currentProduct);
              }
            }}
            className={style.cancelBtn}>
            No
          </button>
        </div>
        <div className="col-6">
          <button
            className={style.deleteBtn}
            onClick={() => {
              setAlert1(false);

              undoProduct(currentProduct);
              closeToast();
            }}>
            Yes
          </button>
        </div>
      </div>
    </>
  );

  const Msg1 = ({ closeToast }) => (
    <>
      <div>Board Deleted, do you want to undo?</div>
      <div className="row mt-4 mb-0 justify-content-center">
        <div className="col-6">
          <button
            onClick={() => {
              setAlert2(true);
              finalDeleteBoard();
            }}
            className={style.cancelBtn}>
            No
          </button>
        </div>
        <div className="col-6">
          <button
            className={style.deleteBtn}
            onClick={() => {
              setAlert2(false);
              undoBoard();
              closeToast();
            }}>
            Yes
          </button>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    const fetchBoard = async () => {
      let boards1 = {};
      try {
        if (history.location.state && history.location.state.hasOwnProperty('id')) {
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/board/${history.location.state.id}`
          );
          let result = { ...res.data };
          boards1 = {
            id: result._id,
            moodBoard_id: result.moodboard_id ? result.moodboard_id._id : null,
            title: result.name,
            moodBoard: result.moodboard_id,
            products: result.products && result.products.length > 0 ? [...result.products] : []
          };
          setCurrentBoard({ ...boards1 });
          setCurrentBoardTemp({ ...boards1 });
          setProducts([...result.products]);
          setProductsTemp([...result.products]);
        } else {
          if (auth && auth.user) {
            const res = await customerApiInstance.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
            );
            if (res.data.favProducts.length > 0) {
              setCurrentBoard({
                title: 'Things I Like',
                products: res.data.favProducts
              });
              setProducts([...res.data.favProducts]);
            }
          }
        }
      } catch (err) {}
    };
    fetchBoard();
  }, [history]);

  const handleClose1 = () => {
    setOpen({ ...open, modal1: false });
  };

  useEffect(() => {
    const fetchBoards = async () => {
      try {
        if (auth && auth.user) {
          let moodbrds = [];
          let relatedmoodbrds = [];
          let temp = [];
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/list`
          );

          moodbrds = [...res.data];

          if (currentBoard.products && currentBoard.products.length > 0) {
            temp = [...currentBoard.products];
            for (let i = 0; i < temp.length; i++) {
              for (let j = 0; j < styleData.length; j++) {
                if (temp[i].style.toLowerCase() === styleData[j].title.toLowerCase()) {
                  temp[i].priority = styleData[j].priority;
                }
              }
            }
            temp = temp.sort((a, b) => (b['priority'] < a['priority'] ? 1 : -1));
          }
          for (let i = 0; i < temp.length; i++) {
            let x = moodbrds.findIndex(m => m.styles.includes(temp[i].style));
            if (x >= 0) {
              relatedmoodbrds.push({ ...moodbrds[x] });
            }
          }
          let results = relatedmoodbrds.filter(
            md => !allBoards.some(all => all.moodBoard_id === md._id)
          );
          if (results.length > 4) {
            results = results.slice(0, 3);
          }
          setRecommendedMoodBoards([...results]);
        }
      } catch (err) {}
    };
    fetchBoards();
  }, [currentBoard, allBoards]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        let boards1 = [];
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
        );
        let result = [...res.data];
        for (let i = 0; i < result.length; i++) {
          boards1.push({
            id: result[i]._id,
            moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
            title: result[i].name,
            moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
            products:
              result[i].products && result[i].products.length > 0 ? [...result[i].products] : []
          });
        }
        setAllBoards([...boards1]);
      } catch (err) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (newBoardCreated) {
          let boards1 = [];
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
          );
          let result = [...res.data];
          for (let i = 0; i < result.length; i++) {
            boards1.push({
              id: result[i]._id,
              moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
              title: result[i].name,
              moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
              products:
                result[i].products && result[i].products.length > 0 ? [...result[i].products] : []
            });
          }
          setAllBoards([...boards1]);
          setNewBoardCreated(false);
        }
      } catch (err) {}
    };
    fetchData();
  }, [newBoardCreated]);

  const handleOver = e => {
    setOverlay(e);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEll, setAnchorEll] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickHeader = event => {
    setAnchorEll(event.currentTarget);
  };

  const addToFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = product => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds(prev => [...prev, product._id]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter(filter => filter !== product._id));
      removeFromFavourites(product);
    }
  };

  const addToCart = async productData => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        const isArray = productData instanceof Array;
        if (!isArray) {
          prodBody = {
            user_id: userId,
            products: [
              {
                product_id: productData._id,
                quantity: prodQuantity,
                rate: productData.price || productData.pixel_price,
                total_price: prodQuantity * (productData.price || productData.pixel_price)
              }
            ]
          };
        } else {
          let allProducts = [];
          for (let i = 0; i < productData.length; i++) {
            allProducts.push({
              product_id: productData[i]._id,
              quantity: 1,
              rate: productData[i].price,
              total_price: 1 * (productData[i].price || productData[i].pixel_price)
            });
          }

          prodBody = {
            user_id: userId,
            products: [...allProducts]
          };
        }
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);

          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products;
        const isArray = productData instanceof Array;
        if (!isArray) {
          products = [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ];
        } else {
          let allProducts = [];
          for (let i = 0; i < productData.length; i++) {
            allProducts.push({
              product_id: productData[i]._id,
              quantity: 1,
              rate: productData[i].price,
              total_price: 1 * (productData[i].price || productData[i].pixel_price)
            });
          }
          products = [...allProducts];
        }
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }
    storeCartCount(await getCartDataCount(auth));
  };

  const handleClose = value => {
    setModal({ ...modal, name: value });
    setAnchorEl(null);
    setAnchorEll(null);
  };

  const handleShareProduct = () => {
    const url1 = copy.current.value;
    navigator.clipboard.writeText(url1);
    setCopiedUrl(url1);
    setSnack(true);
    setSnackMessage('Product link successfully copied');
  };

  const handleShareBoard = () => {
    const url1 = copy.current.value;
    navigator.clipboard.writeText(url1);

    setCopiedUrl(url1);
    setSnack(true);
    setSnackMessage('Moodboard link successfully copied');
  };

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      boxShadow: theme.shadows[5]
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      border: 'none',
      width: 800,
      height: 500,
      padding: theme.spacing(2, 4, 3)
    }
  }));

  const classes = useStyles();
  function openinnewtab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }
  const renderColumns = numCols => {
    let cols = [];
    let rows = [];
    products.map((data, index) => {
      cols.push(
        <div className={classnames(style.renderCol, 'col-12 col-md-3 ')}>
          {dimension[0] > 500 ? (
            <div
              id={`card${index}`}
              onMouseOver={() => {
                handleOver(data._id);
              }}
              onMouseLeave={() => setOverlay(null)}
              className={style.renderContainer}>
              {overlay === data._id && (
                <div className={style.overlay}>
                  <DeleteOutlineIcon
                    className={style.deleteIcon}
                    onClick={() => {
                      setCurrentProduct({ ...data });
                      if (currentBoard.hasOwnProperty('id') && currentBoard.id) {
                        setModal({
                          ...modal,
                          modal: true,
                          name: 'deleteProduct'
                        });
                      } else {
                        setDeleteFavProduct(true);
                        setModal({
                          ...modal,
                          modal: true,
                          name: 'deleteProduct'
                        });
                      }
                    }}
                  />

                  <button
                    className={style.saveBtn}
                    onClick={() => {
                      setCurrentProduct({ ...data });
                      setModal({ ...modal, modal: true, name: 'save' });
                    }}>
                    Save
                  </button>
                  <button
                    style={{
                      background: 'transparent',
                      color: 'white',

                      fontSize: '14px',
                      border: '1.5px solid #ffffff',
                      borderRadius: '0',
                      fontWeight: '500',
                      alignSelf: 'center',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '50%'
                    }}
                    type="button"
                    class="btn btn-light"
                    onClick={() => {
                      openinnewtab(`/product/${data?._id}`);
                    }}>
                    View Details
                  </button>
                </div>
              )}

              <img
                src={data.image[0]}
                alt=""
                style={{
                  width: '100%',
                  maxHeight: '100%',
                  objectFit: 'cover'
                }}
              />
            </div>
          ) : (
            <img
              src={data.image[0]}
              className={style.imgsView}
              alt=""
              style={{ width: '100%', maxHeight: '100%' }}
              onClick={() => {
                setCurrentProduct({ ...data });
                setOpen({ ...open, modal1: true, data: data });
              }}
            />
          )}

          {dimension[0] > 500 ? (
            <div className="row justify-content-between mt-2">
              <div
                className="col-7 text-left"
                style={{ fontSize: '15px', textOverflow: 'ellipsis' }}>
                <span className={style.header}> {data.name}</span>
              </div>
              <div className="col-5 text-right">
                <span className={style.price}>
                  Rs. {new Intl.NumberFormat('en-IN').format(data.price)}
                </span>
              </div>
            </div>
          ) : (
            <div
              className="row justify-content-between"
              style={{ marginTop: dimension[0] < 500 ? '20px' : '' }}>
              <div
                className="col-8 text-left"
                style={{ fontSize: '15px', textOverflow: 'ellipsis' }}>
                <span className={style.header}> {data.name}</span> <br></br>
                <span className={style.price}>
                  Rs. {new Intl.NumberFormat('en-IN').format(data.price)}
                </span>
              </div>
              <div className="col-3 text-right mr-md-3">
                <img
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={e => {
                    setCurrentProduct({ ...data });
                    handleClick(e);
                  }}
                  className={style.imgOption}
                  style={{
                    zIndex: 0,
                    width: '30%',
                    transform: 'translate(60%,-100%)'
                  }}
                  src={'https://d3izcfql7lesks.cloudfront.net/Group_2980_dvwzmf.png'}
                  alt=""
                />

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  dense={false}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <MenuItem
                    className="justify-content-center"
                    style={{ width: '90px' }}
                    onClick={() => handleClose('save')}>
                    Save
                  </MenuItem>
                  <MenuItem
                    className="justify-content-center"
                    style={{ width: '90px' }}
                    onClick={() => {
                      setModal({
                        ...modal,
                        modal: true,
                        name: 'deleteProduct'
                      });
                      setAnchorEl(null);
                      setAnchorEll(null);
                    }}>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            </div>
          )}
        </div>
      );
    });
    if (cols.length) {
      rows.push(<Row key={rows.length}>{cols}</Row>);
    }
    return rows;
  };

  const getImage = () => {
    if (Object.keys(currentProduct).length && currentProduct.image.length > 0) {
      return currentProduct.image[0];
    }
  };
  const getMoodBoardImage = currentBoard => {
    if (Object.keys(currentBoard).length && currentBoard.hasOwnProperty('moodBoard')) {
      return currentBoard.moodBoard ? currentBoard.moodBoard.preview_url : '';
    } else {
      return ' ';
    }
  };

  const saveToBoard = async boardData => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to save the Boards');
      return false;
    }

    let prodIds = [];
    boardData.products.map(pd => {
      prodIds.push(pd._id);
    });
    const addToBoardData = {
      moodboard_id: boardData.moodBoard_id,

      products: [...prodIds, currentProduct._id],

      name: boardData.title,
      user_id: auth.user._id
    };
    for (let i = 0; i < allBoards.length; i++) {
      if (allBoards[i].id === boardData.id) {
        allBoards[i].products.push(currentProduct);
      }
    }
    setAllBoards([...allBoards]);
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${boardData.id}`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('Product saved to Board successfully');
      }
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };

  const checkProdsSaved = board => {
    let allProdIds = [];
    let otherBoards = [];
    let existingProd;
    otherBoards = allBoards.filter(allbrd => allbrd.id !== board.id);
    otherBoards.map(brd =>
      brd.products.map(prd => {
        allProdIds.push(prd._id);
      })
    );
    existingProd = allProdIds.some(x => x === currentProduct._id);
    return existingProd ? true : false;
  };

  const finalDeleteFavouriteProduct = async prod => {
    if (alertRef.current === true) {
      try {
        if (auth && auth.user) {
          const body = {
            user_id: auth.user._id,
            products: {
              product_id: prod._id
            }
          };
          const res = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
            body
          );
          if (res.data) {
            let temp = productsTemp.filter(pd => pd._id !== prod._id);
            setProducts([...temp]);
            setSnack(true);
            setModal({ ...modal, modal: false, name: '' });
            setSnackMessage('Product deleted from favourites successfully');
          }
        }
      } catch (e) {
        setSnack(true);
        setSnackMessage('Error in  deleting  Product from favourites');
      }
    }
  };

  const finalDeleteProduct = async prod => {
    if (alertRef.current === true) {
      let prodIds = [];
      productsTemp.map(pd => {
        prodIds.push(pd._id);
      });
      prodIds = prodIds.filter(pd => pd !== prod._id);
      const removeBoardData = {
        moodboard_id: currentBoard.moodBoard_id,
        products: [...prodIds],

        name: currentBoard.title,
        user_id: auth.user._id
      };
      try {
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${currentBoard.id}`,
          removeBoardData
        );
        if (res.data && Object.keys(res.data).length !== 0) {
          let productstemp1 = productsTemp.filter(prod1 => prod1._id !== prod._id);
          setProducts([...productstemp1]);
          setSnack(true);
          setSnackMessage('Product removed from Board successfully');
          setModal({ ...modal, modal: false, name: '' });
        }
      } catch (err) {
        setSnack(true);
        setSnackMessage(err);
      }
    }
  };

  const deleteProduct = async prod => {
    let productstemp1 = products.filter(prod1 => prod1._id !== prod._id);
    setProducts([...productstemp1]);
    setAlert1(true);

    setTimeout(() => {
      if (deleteFavProduct === true) {
        finalDeleteFavouriteProduct(currentProduct);
      } else {
        finalDeleteProduct(currentProduct);
      }
    }, 5000);
    setModal({ ...modal, modal: false, name: '' });
  };
  const undoProduct = async prod => {
    let productstemp1 = productsTemp.filter(prod1 => prod1._id === prod._id);
    if (productstemp1 && productstemp1.length > 0) {
      setProducts([...products, ...productstemp1]);
    }
    setModal({ ...modal, modal: false, name: '' });
  };
  const removeFromBoard = async boardData => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to remove from the Boards');
      return false;
    }

    if (boardData.id === currentBoard.id) {
      let productsTemp = products.filter(prod => prod._id !== currentProduct._id);
      setProducts([...productsTemp]);
    }

    let prodIds = [];
    boardData.products.map(pd => {
      prodIds.push(pd._id);
    });
    prodIds = prodIds.filter(pd => pd !== currentProduct._id);
    const removeBoardData = {
      moodboard_id: boardData.moodBoard_id,
      products: [...prodIds],
      name: boardData.title,
      user_id: auth.user._id
    };
    let updateBoards = allBoards.forEach(board => {
      if (board.id === boardData.id) {
        board.products = board.products.filter(pd => pd._id !== currentProduct._id);
      }
    });
    for (let i = 0; i < allBoards.length; i++) {
      if (allBoards[i].id === boardData.id) {
        allBoards[i].products = allBoards[i].products.filter(pd => pd._id !== currentProduct._id);
      }
    }
    setAllBoards([...allBoards]);

    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${boardData.id}`,
        removeBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('Product removed from Board successfully');
      }
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };

  const handleEditBoardName = async editTitle => {
    const titleUpdate = {
      name: editTitle
    };
    currentBoard.title = editTitle;
    setCurrentBoard({ ...currentBoard });
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${currentBoard.id}`,
        titleUpdate
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setModal({ ...modal, modal: false, name: '' });
        setSnack(true);
        setSnackMessage('Board renamed successfully');
      }
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };

  const deleteBoard = async () => {
    setProducts([]);
    setCurrentBoard({});
    setAlert2(true);

    setTimeout(() => {
      finalDeleteBoard();
    }, 5000);
    setModal({ ...modal, modal: false, name: '' });
  };

  const finalDeleteBoard = async () => {
    if (alertRef1.current === true) {
      try {
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/delete/${currentBoardTemp.id}`
        );
        if (res.data && Object.keys(res.data).length !== 0) {
          setProducts([]);
          setCurrentBoard({});
          setSnack(true);
          setSnackMessage('Board deleted successfully');
        }
        setModal({ ...modal, modal: false, name: '' });
        history.push('/boards');
      } catch (err) {
        setSnack(true);
        setSnackMessage(err);
      }
    }
  };

  const undoBoard = async () => {
    setCurrentBoard({ ...currentBoardTemp });
    setProducts([...productsTemp]);
    setModal({ ...modal, modal: false, name: '' });
  };

  const createNewBoard = async () => {
    const addToBoardData = {
      products: currentProduct._id,
      name: currentData,
      user_id: auth.user._id
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/add`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setNewBoardCreated(true);
        setModal({ ...modal, modal: false, name: '' });
        setSnack(true);
        setSnackMessage('Board created successfully');
      }
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };

  useEffect(() => {
    const fetchFavouriteData = async () => {
      try {
        if (auth && auth.user) {
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
          );
          let savedIds = [];

          if (res.data.favourites[0].length !== 0) {
            res.data.favourites.map(dat => {
              savedIds = dat.products.map(m => {
                return m.product_id;
              });
            });
            setSavedProductIds([...savedIds]);
          }
        }
      } catch (err) {}
    };
    fetchFavouriteData();
  }, []);

  return (
    <div
      style={{
        fontFamily: 'Quicksand',
        overflowX: 'hidden',
        overflowY: 'hidden'
      }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header />
      <div
        className="row justify-content-center "
        style={{
          position: 'relative',
          marginTop: '100px',
          marginBottom: '13px'
        }}>
        <h3 className={style.header1}>{currentBoard.title}</h3>
        <div
          style={{
            float: 'right',
            position: 'absolute',
            right: '12%',
            display: 'flex'
          }}>
          {dimension[0] > 500 ? (
            <>
              <button
                className={style.header_btn}
                onClick={() => {
                  if (currentBoard.hasOwnProperty('id') && currentBoard.id) {
                    setModal({ ...modal, modal: true, name: 'edit' });
                  } else {
                    setSnack(true);
                    setSnackMessage('This board cannot be editted');
                  }
                }}>
                {' '}
                Edit{' '}
              </button>

              <button
                className={style.header_btn}
                onClick={() => {
                  if (currentBoard.hasOwnProperty('id') && currentBoard.id) {
                    setModal({ ...modal, modal: true, name: 'delete' });
                  } else {
                    setSnack(true);
                    setSnackMessage('This board cannot be deleted');
                  }
                }}>
                {' '}
                Delete{' '}
              </button>
            </>
          ) : (
            <div>
              <img
                style={{ marginTop: '-10px' }}
                aria-controls="simple-menu-id"
                aria-haspopup="true"
                onClick={handleClickHeader}
                src={'https://d3izcfql7lesks.cloudfront.net/Group_2980_dvwzmf.png'}
                alt=""
              />
              <Menu
                id="simple-menu-id"
                anchorEl={anchorEll}
                keepMounted
                dense={false}
                open={Boolean(anchorEll)}
                onClose={handleClose}>
                <MenuItem
                  className="justify-content-center"
                  style={{ width: '90px' }}
                  onClick={() => {
                    if (currentBoard.hasOwnProperty('id') && currentBoard.id) {
                      handleClose('edit');
                    } else {
                      setSnack(true);
                      setSnackMessage('This board cannot be editted');
                    }
                  }}>
                  Edit
                </MenuItem>
                <MenuItem
                  className="justify-content-center"
                  style={{ width: '90px' }}
                  onClick={() => {
                    handleShareBoard();
                    handleClose('share');
                  }}>
                  Share
                </MenuItem>
                <MenuItem
                  className="justify-content-center"
                  style={{ width: '90px' }}
                  onClick={() => {
                    if (currentBoard.hasOwnProperty('id') && currentBoard.id) {
                      handleClose('delete');
                    } else {
                      setSnack(true);
                      setSnackMessage('This board cannot be deleted');
                    }
                  }}>
                  Delete
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </div>

      <div
        className=" container justify-content-center"
        style={{
          maxWidth: '80%',
          fontFamily: 'Quicksand',
          marginBottom: '80px'
        }}>
        {renderColumns()}
      </div>
      <div className={style.container2}>
        <div className="container" style={{ maxWidth: '90%', height: 'auto' }}>
          <h4>You might also like</h4>
          <div className={`row ${dimension[0] > 500 ? 'mt-5' : 'mt-3'}`}>
            {recommendedMoodBoards.map(md => {
              return (
                <>
                  <div className="col-lg-3 col-md-6 col-sm-12 text-center p-3">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                      <img
                        style={{
                          position: 'relative',
                          borderRadius: '10px',
                          width: '100%',
                          minHeight: '200px',
                          display: 'flex',
                          justifyContent: ' center',
                          alignItems: 'center'
                        }}
                        src={md.preview_url}
                        alt=""
                      />
                      <div
                        id="my_button"
                        className={style.view}
                        style={{
                          zIndex: '1',
                          cursor: 'pointer',
                          marginTop: '-25px',
                          padding: '10px',
                          backgroundColor: 'white',
                          borderRadius: '50px',
                          fontFamily: 'QuickSand',
                          fontSize: '16px'
                        }}
                        onClick={() => {
                          history.push(`/explore-detail/${md._id}`);
                        }}>
                        View
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        style={{ backgroundColor: 'transparent', paddingBottom: '20px' }}
        show={modal.name === 'edit'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalHeader}>
          <h4 className={style.editBoard}>Edit Your Board</h4>
          <img
            className="mr-5 mt-4"
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setModal({ ...modal, modal: false, name: '' })}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>

        <div className={classnames('row justify-content-center ', style.modalRow)}>
          <div className={classnames('col-md-4 col-xs-12', style.imageCol)}>
            <img src={getMoodBoardImage(currentBoard)} style={{ width: '100%' }} alt="" />
          </div>
          <div className={classnames('col-md-8 col-xs-12', style.createCol)}>
            <div className={style.wholeBoards}>
              <h6>Board Name</h6>
              <input
                type="text"
                placeholder="Enter Board name"
                defaultValue={currentBoard.title || ''}
                onChange={e => setCurrentData(e.target.value)}
              />
              <button
                className={style.saveButton}
                onClick={() => {
                  handleEditBoardName(currentData);
                }}>
                Edit Board
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'create'}
        onHide={() => setModal({ ...modal, modal: false, name: '' })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalHeader}>
          <h4 className={style.createBoard}>Create Your Board</h4>
          <img
            className="mr-5 mt-4"
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setModal({ ...modal, modal: false, name: '' })}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>

        <div className={classnames('row justify-content-center', style.modalRow)}>
          <div className={classnames('col-md-4 col-xs-12 pt-5', style.imageCol)}>
            <img
              src={getImage()}
              alt=""
              style={{ width: '100%', minHeight: '70%', maxHeight: '80%' }}
            />
          </div>
          <div className={classnames('col-md-8 col-xs-12', style.createCol)}>
            <h6>Board Name</h6>
            <input
              type="text"
              placeholder="Enter Board name"
              onChange={e => setCurrentData(e.target.value)}
            />
            <button
              className={style.createBoardButton1}
              onClick={() => {
                createNewBoard();
              }}>
              Create Board
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'save'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal modalSave'}
        keyboard={false}>
        <div className={style.modalHeader}>
          <h4 className={style.saveBoards}>Save to Board</h4>
          <img
            className="mr-5 mt-4"
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setModal({ ...modal, modal: false, name: '' })}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>
        <div className={classnames('row justify-content-center', style.modalRows)}>
          <div
            className={classnames(
              'col-md-6 pt-5 col-xs-12 pt-5 d-flex justify-content-center',
              style.imageCol
            )}>
            <img
              src={getImage()}
              alt=""
              style={{ width: '100%', minHeight: '70%', maxHeight: '80%' }}
            />
          </div>
          <div className={classnames('col-md-6 col-xs-12 ', style.createCol)}>
            <div className="row ">
              <span
                className={classnames(' col-6 p-0 pt-4 pl-3  justify-content-start', style.save1)}>
                Save to Board
              </span>
              <span className=" col-6 d-flex  mt-3 p-sm-0 p-xs-0  justify-content-end ">
                <button
                  className={classnames('mr-lg-3 mr-md-3', style.createBoardButton)}
                  onClick={() => setModal({ ...modal, modal: true, name: 'create' })}>
                  Create Board
                </button>
              </span>
            </div>
            <hr className="p-0"></hr>
            {allBoards.map(board => {
              return (
                <div className="row mb-2">
                  <div className="col-6">
                    <img
                      src={
                        board.hasOwnProperty('moodBoard') && board.moodBoard
                          ? board.moodBoard.preview_url
                          : null
                      }
                      style={{
                        width: '100%',
                        minHeight: '70%',
                        maxHeight: '80%'
                      }}
                      alt=""
                    />
                  </div>
                  <div className="col-4 d-flex justify-content-left align-items-left">
                    <span
                      className={style.boardTiles}
                      style={{ fontSize: '12px', fontFamily: 'QuickSand' }}>
                      {' '}
                      {board.title}{' '}
                    </span>
                  </div>
                  <div className="col-2 d-flex justify-content-center align-items-center">
                    {auth && auth.user && board.products.some(x => x._id === currentProduct._id) ? (
                      <Icon.HeartFill
                        style={{ fill: '#FFC4C4', borderColor: 'black' }}
                        onClick={() => {
                          if (checkProdsSaved(board)) {
                            removeFromBoard(board);
                          } else {
                            setSnack(true);
                            setSnackMessage('Please save the product to other boards');
                          }
                        }}></Icon.HeartFill>
                    ) : (
                      <Icon.Heart
                        onClick={() => {
                          saveToBoard(board);
                        }}></Icon.Heart>
                    )}
                  </div>
                  <hr></hr>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'share'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalImage}>
          <div className={classnames(style.ShareModalHeader, 'justify-content-end h-auto')}>
            <div
              className={classnames('mr-2 mt-1')}
              style={{ cursor: 'pointer' }}
              onClick={() => setModal({ ...modal, modal: false, name: '' })}>
              <Icon.X
                className=""
                style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}
                onClick={() => setModal({ ...modal, modal: false, name: '' })}
              />
            </div>
          </div>
          <div className={classnames(style.ShareModalHeader, 'justify-content-center h-auto')}>
            <h4 className="mt-1">Share Your Board</h4>
          </div>
          <div className={classnames('row', style.modalRow_share)}>
            <div className="w-100 py-4 d-flex justify-content-between mx-5">
              <div>
                <FacebookShareButton url={copiedUrl}>
                  <FacebookIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </FacebookShareButton>
              </div>
              <div>
                <TwitterShareButton url={copiedUrl}>
                  <TwitterIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </TwitterShareButton>
              </div>
              <div>
                <EmailShareButton url={copiedUrl}>
                  <EmailIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </EmailShareButton>
              </div>
              <div>
                <WhatsappShareButton url={copiedUrl}>
                  <WhatsappIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </WhatsappShareButton>
              </div>
            </div>
            <div className="my-3 mx-3" style={{ width: '90%' }}>
              <div className={style.shareLink}>
                <p className={classnames(style.shareContent, 'p-2 mr-2')}>{copiedUrl}</p>
                <button className={style.copyLinkBtn} onClick={() => {}}>
                  Copy Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'delete'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={classnames('row justify-content-center', style.modalRow_delete)}>
          <div className=" row justify-content-center w-100 mt-5" style={{ height: '50px' }}>
            <h4>Are you sure you want to delete your board?</h4>
            <p className={style.deleteTxt}>Once you delete, you cannot undo the action.</p>
          </div>
          <div className=" row justify-content-center w-100"></div>
          <div className="row justify-content-center p-2" style={{ height: '100px' }}>
            <div className="col-6">
              <button onClick={() => setModal({ ...modal, name: '' })} className={style.cancelBtn}>
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                className={style.deleteBtn}
                onClick={() => {
                  deleteBoard();
                  toast(<Msg1 />);
                }}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'undo'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={classnames('row justify-content-center', style.modalRow_delete)}>
          <div className=" row justify-content-center w-100 mt-5" style={{ height: '50px' }}>
            <span className={style.deleteConfirm}>The board has been deleted</span>
          </div>
          <div className=" row justify-content-center w-100">
            <span className={style.deleteInfo}>do you want to undo the last delete?</span>
          </div>
          <div className="row justify-content-center" style={{ height: '100px' }}>
            <div className="col-6">
              <button
                onClick={() => {
                  finalDeleteBoard();
                }}
                className={style.cancelBtn}>
                No
              </button>
            </div>
            <div className="col-6">
              <button
                className={style.deleteBtn}
                onClick={() => {
                  undoBoard();
                }}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'deleteProduct'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={classnames('row justify-content-center', style.modalRow_delete)}>
          <div className=" row justify-content-center w-100 mt-5" style={{ height: '50px' }}>
            <h4>Are you sure you want to delete this product from the board?</h4>
          </div>
          <div className="row justify-content-center p-2" style={{ height: '100px' }}>
            <div className="col-6">
              <button onClick={() => setModal({ ...modal, name: '' })} className={style.cancelBtn}>
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                className={style.deleteBtn}
                onClick={() => {
                  deleteProduct(currentProduct);
                  toast(<Msg />);
                }}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'undoProduct'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={classnames('row justify-content-center', style.modalRow_delete)}>
          <div className=" row justify-content-center w-100 mt-5" style={{ height: '50px' }}>
            <span className={style.deleteConfirm}>The product has been deleted from the board</span>
          </div>
          <div className=" row justify-content-center w-100">
            <span className={style.deleteInfo}>do you want to undo the last delete?</span>
          </div>
          <div className="row justify-content-center" style={{ height: '100px' }}>
            <div className="col-6">
              <button
                onClick={() => {
                  if (deleteFavProduct === true) {
                    finalDeleteFavouriteProduct(currentProduct);
                  } else {
                    finalDeleteProduct(currentProduct);
                  }
                }}
                className={style.cancelBtn}>
                No
              </button>
            </div>
            <div className="col-6">
              <button
                className={style.deleteBtn}
                onClick={() => {
                  undoProduct(currentProduct);
                }}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal1
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open.modal1}
        onClose={handleClose1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open.modal1}>
          <div
            className={classnames(classes.paper, style.modalContainer)}
            style={{ height: dimension[0] > 500 ? '' : '100vh' }}>
            <div className="d-flex justify-content-between align-items-center">
              <div
                className="d-flex justify-content-around align-items-center flex-row"
                style={{ display: 'flex' }}>
                <div
                  className={style.heart}
                  style={{
                    marginTop: '-1%',
                    backgroundPosition:
                      auth && auth.user && savedProductIds?.includes(open.data?._id)
                        ? 'right'
                        : 'left',
                    display: dimension[0] > 500 ? '' : 'none'
                  }}
                  onClick={() => {
                    setAnimation(!animation);
                    handleFavourites(open.data);
                  }}></div>
                <img
                  onClick={() => {
                    handleShareProduct();

                    setModal1({ ...modal1, modal1: true, name: 'share' });
                  }}
                  style={{ display: dimension[0] > 500 ? '' : 'none' }}
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2425.svg'
                  }
                  alt=""
                />
              </div>
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen({ ...open, modal1: false })}
                src={
                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2420.svg'
                }
                alt=""
              />
            </div>
            <input
              ref={copy}
              type="hidden"
              value={`${window.location.host}/product/${open.data?._id}`}
            />
            <div
              className="container"
              style={{
                width: dimension[0] > 500 ? '80%' : '100%',
                marginTop: dimension[0] < 500 ? '-1%' : '10%',
                padding: dimension[0] > 500 ? '' : '0px'
              }}>
              <div className="row">
                <div className="col-sm-12 col-lg-5">
                  <img
                    src={open.data?.image[0]}
                    style={{ width: '100%' }}
                    alt=""
                    className={style.productImg}
                  />
                </div>
                <div
                  className="row"
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    zIndex: '1000',
                    marginTop: '-1.8em',
                    marginLeft: '0',
                    display: dimension[0] < 500 ? '' : 'none'
                  }}>
                  <div
                    className={style.heart}
                    style={{
                      marginTop: '2%',
                      backgroundPosition:
                        auth && auth.user && savedProductIds?.includes(open.data?._id)
                          ? 'right'
                          : 'left',
                      display: dimension[0] < 500 ? '' : 'none',
                      backgroundColor: 'white'
                    }}
                    onClick={() => {
                      setAnimation(!animation);
                      handleFavourites(open.data);
                    }}></div>
                  <img
                    onClick={() => {
                      handleShareProduct();

                      setModal1({ ...modal1, modal1: true, name: 'share' });
                    }}
                    style={{ display: dimension[0] < 500 ? '' : 'none' }}
                    src={
                      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2425.svg'
                    }
                    alt=""
                  />
                </div>
                <div
                  className="col-sm-12 col-lg-7"
                  style={{ textAlign: dimension[0] < 500 ? 'center' : '' }}>
                  <h4 className={style.nameTitle}>{open.data?.name}</h4>
                  <h4 className={style.prices}>
                    <b>Rs. {new Intl.NumberFormat('en-IN').format(open.data?.pixel_price)} </b>
                    <span style={{ opacity: 0.7 }}>
                      <s>Rs. {new Intl.NumberFormat('en-IN').format(open.data?.price)}</s>
                    </span>{' '}
                  </h4>
                  <br></br>
                  <p>Color: {open.data?.color}</p>
                  <p>Material: {open.data?.material}</p>
                  <p>
                    Dimensions: {open.data?.dimensions.l} {" '' "}L * {open.data?.dimensions.b}{' '}
                    {" '' "}W{' '}
                  </p>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <button
                      className={style.buttonWhite}
                      onClick={() => {
                        history.push(`/product/${open.data?._id}`);
                      }}
                      style={{ whiteSpace: dimension[0] < 500 ? 'nowrap' : '' }}>
                      View Full Details
                    </button>
                    <button
                      className={classnames(style.buttonBlack, 'ml-2')}
                      onClick={() => addToCart(open.data)}>
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal1>
      <Modal1
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal1.name === 'share'}
        onClose={() => setModal1({ ...modal1, modal1: false, name: '' })}
        closeAfterTransition>
        <div className="pt-5 pb-5 pl-3 pr-2" style={{ backgroundColor: 'white' }}>
          <InlineShareButtons
            config={{
              color: 'social',
              enabled: true,
              font_size: 16,
              labels: 'cta',
              language: 'en',
              networks: ['linkedin', 'messenger', 'facebook', 'twitter', 'instagram'],
              radius: 4,
              size: dimension[0] > 500 ? 30 : 20,

              url: copiedUrl,
              image: 'https://bit.ly/2CMhCMC',
              description: 'custom text',
              title: 'custom title',
              message: 'custom email text',
              subject: 'custom email subject',
              username: 'custom twitter handle'
            }}
          />
          <div className="d-flex flex-column justify-content-center" style={{ height: '70px' }}>
            <div className={style.shareLink}>
              <p className={style.link}>
                <a>{copiedUrl}</a>
              </p>
              <button className={style.copyLinkBtn}>Link</button>
            </div>
          </div>
        </div>
      </Modal1>
    </div>
  );
};

BoardDetail.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(BoardDetail));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
