import ConsoleHelper from '../../utils/ConsoleHelper';
const initialState = {
  sampleData: 'random',
  questionnaire: 'questionnaire',
  date: null,
  exploreFilter: null,
  cartCount: null,
  moodBoardProduct: null,
  moodBoardData: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SAMPLE_DATA':
      return {
        ...state,
        sampleData: payload
      };

    case 'POST_DATE':
      return {
        ...state,
        date: payload
      };

    case 'STORE_CART_COUNT':
      return {
        ...state,
        cartCount: payload
      };

    case 'STORE_MOODBOARD_PRODUCT':
      return {
        ...state,
        moodBoardProduct: payload
      };

    case 'STORE_MOODBOARD_DATA':
      ConsoleHelper(state);
      return {
        ...state,
        moodBoardData: payload
      };

    case 'EXPLORE_FILTER':
      return {
        ...state,
        exploreFilter: payload
      };

    default:
      return state;
  }
}
