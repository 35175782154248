import React, { useRef, useState, useEffect } from 'react';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import style from './Customstackedcarousel.module.css';
import { useHistory } from 'react-router-dom';

function CustomStackedCarousel(props) {
  const ref = useRef();

  const [currentActiveSlide, setCurrentActiveSlide] = useState(0);

  // useEffect(() => {
  //   const paginationInterval = setInterval(() => {
  //     if (ref && ref.current && typeof ref.current.goNext === 'function') {
  //       ref.current.goNext(6);
  //     }
  //   }, 4000);

  //   return () => {
  //     clearInterval(paginationInterval);
  //   };
  // }, []);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div className={style.stackedCarouselDetails}>
        <div>{props?.data?.[currentActiveSlide]?.title}</div>
        <div>
          {currentActiveSlide + 1} / {props?.data?.length}
        </div>
      </div>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
          // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
          // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
          // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
          let currentVisibleSlide = props?.data?.length;
          //   if (parentWidth <= 1440) currentVisibleSlide = 3;
          //   if (parentWidth <= 1080) currentVisibleSlide = 1;
          return (
            <StackedCarousel
              ref={carouselRef}
              height={'500px'}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={props.data}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={props?.data?.length}
              useGrabCursor
              onActiveSlideChange={(newCenterDataIndex) =>
                setCurrentActiveSlide(newCenterDataIndex)
              }
            />
          );
        }}
      />

      <img
        src={sliderArrowPrev}
        alt="prevArrow"
        {...props}
        className={style.leftArr}
        onClick={() => {
          if (ref && ref.current && typeof ref.current.goBack === 'function') {
            ref.current.goBack(6);
          }
        }}
      />

      <img
        src={sliderArrowNext}
        alt="nextArrow"
        className={style.rightArr}
        onClick={() => {
          if (ref && ref.current && typeof ref.current.goNext === 'function') {
            ref.current.goNext(6);
          }
        }}
      />
    </div>
  );
}

// Very importand to memoize your Slide component otherwise there might be performance issue
// At minimum your should do a simple React.memo(SlideComponent)
// If you want the absolute best performance then pass in a custom comparator function like below
export const Card = React.memo(function (props) {
  const history = useHistory();
  const { data, dataIndex } = props;
  const { cover, _id } = data[dataIndex];
  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        userSelect: 'none'
      }}
      className="my-slide-component"
      onClick={() => history.push(`/explore-detail/${_id}`)}>
      <img
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          borderRadius: 0
        }}
        draggable={false}
        src={cover}
      />
    </div>
  );
});

export default CustomStackedCarousel;
