import React, { useEffect, useState } from 'react';
//import { createStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import UAParser from 'ua-parser-js';
//import SliderContainer from 'react-slick';
// import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
// import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import style from './Shop.module.css';
//import style1 from './Shopdetails.module.css';
import './Shop.css';
//import classnames from 'classnames';
//import { Button } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import customerApiInstance from '../../utils/CustomerApiInstance';
import getCartDataCount from '../../utils/CartDetails';
//import { storeCartCount } from '../../store/actions/sampleaction';
//import classNames from 'classnames';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
//import Heart from 'react-heart';

//import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
//import emailjs from '@emailjs/browser';
import QRScanner from '../../components/layout/QrScanner';
import { openinnewtab } from '../../utils/utils';
//import ConsoleHelper from '../../utils/ConsoleHelper';
import ProductCard from '../../components/cards/ProductCard';
import Carousel from '../../components/carousel/Carousel';

// const useStyles = makeStyles(theme =>
//   createStyles({
//     imgtag: {
//       marginLeft: '15px',
//       width: '7px',
//       height: '12px'
//     },
//     papers: {
//       maxHeight: '460px',
//       height: '330px',
//       width: '90%',
//       overflow: 'hidden',
//       margin: '0px auto',
//       [theme.breakpoints.down('sm')]: {
//         height: '300px'
//       }
//     },
//     imagefit: {
//       objectFit: 'cover',
//       width: '100%',
//       height: '100%'
//     },
//     modal: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       border: 'none',
//       boxShadow: theme.shadows[5]
//     },
//     paper: {
//       backgroundColor: theme.palette.background.paper,
//       boxShadow: theme.shadows[5],
//       border: 'none',
//       width: 800,
//       height: 500,
//       padding: theme.spacing(2, 4, 3)
//     },
//     displayrow: {
//       display: 'flex',
//       flexDirection: 'row',
//       alignItems: 'baseline'
//     },
//     roombox: {
//       fontSize: '15px',
//       marginTop: '16px',
//       fontWeight: 600,
//       marginBottom: '51px'
//     }
//   })
// );
// const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
//   <img src={sliderArrowPrev} alt="prevArrow" {...props} />
// );

// const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
//   <img src={sliderArrowNext} alt="nextArrow" {...props} />
// );

export default function BestSeller({
  loading,
  productsList,
  auth,
  roomtype,
  title,
  dimension,
  tag,
  storeCartCount
}) {
  // const userName = localStorage.getItem('userName');
  // const classes = useStyles();
  const history = useHistory();
  //const copy = useRef();
  //const [isHovered, setHover] = useState(false);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [prodQuantity] = useState(1);
  // const [overlay, setOverlay] = useState(null);
  //const [open, setOpen] = useState({ modal: false });
  const [savedProductIds, setSavedProductIds] = useState([]);

  // const [modal, setModal] = useState({ modal: false, name: '', id: null });
  // const [animation, setAnimation] = useState(false);
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();

  useEffect(() => {
    const fetchFavouriteData = async () => {
      try {
        if (auth && auth.user) {
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
          );
          let savedIds = [];

          if (res.data.favourites.length !== 0) {
            res.data.favourites.map(dat => {
              savedIds = dat.products.map(m => {
                return m.product_id;
              });
            });
            setSavedProductIds([...savedIds]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFavouriteData();
  }, []);

  //const form = useRef();
  // let settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: productsList?.length >= 4 ? 4 : productsList.length,
  //   slidesToScroll: productsList?.length >= 4 ? 4 : productsList.length,
  //   nextArrow: <SlickArrowRight />,
  //   prevArrow: <SlickArrowLeft />,
  //   responsive: [
  //     {
  //       breakpoint: 2000,
  //       settings: {
  //         slidesToShow: productsList?.length >= 4 ? 4 : productsList.length,
  //         slidesToScroll: productsList?.length >= 4 ? 4 : productsList.length,
  //         infinite: true,
  //         dots: false
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 500,
  //       settings: {
  //         dots: false,
  //         arrows: false,
  //         infinite: true,
  //         slidesToShow: 1,
  //         centerMode: true
  //       }
  //     }
  //   ]
  // };

  // const sendEmail = e => {
  //   e.preventDefault();
  //   emailjs.sendForm('service_dc6bf0u', 'template_byt0qp9', form.current, 'u-JUBaCmUc6toe0bh').then(
  //     result => {
  //       setSnack(true);
  //       setSnackMessage('Request AR send to Mail Successfully');
  //     },
  //     error => {
  //       setSnack(false);
  //       setSnackMessage('Request AR Error');
  //       ConsoleHelper(error.text);
  //     }
  //   );
  // };

  const addToFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = product => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds(prev => [...prev, product._id]);

      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter(filter => filter !== product._id));

      removeFromFavourites(product);
    }
  };

  // const handleClose = () => {
  //   setOpen({ ...open, modal: false });
  // };

  // const handleShareProduct = () => {
  //   const url1 = copy.current.value;
  //   navigator.clipboard.writeText(url1);
  //   setSnack(true);
  //   setSnackMessage('Product link successfully copied');
  // };

  const handleQr = productIds => {
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = productIds => {
    history.push(`/3d-model/${productIds}`);
  };

  const addToCart = async productData => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
        setSnack(true);
          setSnackMessage('Product added to cart successfully');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }
    storeCartCount(await getCartDataCount(auth));
  };

  const buyNow = async productData => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: 1,
              rate: productData.price || productData.pixel_price,
              total_price: 1 * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push('/cart');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
        history.push('/cart');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  // useEffect(() => {
  //   settings = {
  //     dots: false,
  //     infinite: false,
  //     speed: 500,
  //     slidesToShow: productsList?.length >= 4 ? 4 : productsList.length,
  //     slidesToScroll: productsList?.length >= 4 ? 4 : productsList.length,
  //     nextArrow: <SlickArrowRight />,
  //     prevArrow: <SlickArrowLeft />,
  //     responsive: [
  //       {
  //         breakpoint: 2000,
  //         settings: {
  //           slidesToShow: productsList?.length >= 4 ? 4 : productsList.length,
  //           slidesToScroll: 1,
  //           infinite: true,
  //           dots: false
  //         }
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //           initialSlide: 2
  //         }
  //       },
  //       {
  //         breakpoint: 500,
  //         settings: {
  //           dots: false,
  //           arrows: false,
  //           infinite: true,
  //           slidesToShow: 1,
  //           centerMode: true
  //         }
  //       }
  //     ]
  //   };
  // }, [productsList]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open.modal}>
          <div
            className={classnames(classes.paper, style.modalContainer)}
            style={{ height: dimension[0] > 500 ? '' : '100vh' }}>
            <div className="d-flex justify-content-between align-items-center">
              <div
                className="d-flex justify-content-around align-items-center flex-row"
                style={{ display: 'flex' }}>
                <div
                  className={style.heart}
                  style={{
                    marginTop: '-1%',
                    backgroundPosition:
                      auth && auth.user && savedProductIds?.includes(open.data?._id)
                        ? 'right'
                        : 'left',
                    display: dimension[0] > 500 ? '' : 'none'
                  }}
                  onClick={() => {
                    // setAnimation(!animation);
                    handleFavourites(open.data);
                  }}></div>
                <img
                  onClick={() => {
                    handleShareProduct();

                    setModal({ ...modal, modal: true, name: 'share' });
                  }}
                  style={{ display: dimension[0] > 500 ? '' : 'none' }}
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2425.svg'
                  }
                  alt=""
                />
              </div>
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen({ ...open, modal: false })}
                src={
                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2420.svg'
                }
                alt=""
              />
            </div>
            <input
              ref={copy}
              type="hidden"
              value={`${window.location.host}/product/${open.data?._id}`}
            />
            <div
              className="container"
              style={{
                width: dimension[0] > 500 ? '80%' : '100%',
                marginTop: dimension[0] < 500 ? '-1%' : '10%',
                padding: dimension[0] > 500 ? '' : '0px'
              }}>
              <div className="row">
                <div className="col-sm-12 col-lg-5">
                  <img src={open.data?.image[0]} style={{ width: '100%' }} alt="" />
                </div>
                <div
                  className="row"
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    zIndex: '1000',
                    marginTop: '-1.8em',
                    marginLeft: '0',
                    display: dimension[0] < 500 ? '' : 'none'
                  }}>
                  <div
                    className={style.heart}
                    style={{
                      marginTop: '2%',
                      backgroundPosition:
                        auth && auth.user && savedProductIds?.includes(open.data?._id)
                          ? 'right'
                          : 'left',
                      display: dimension[0] < 500 ? '' : 'none',
                      backgroundColor: 'white'
                    }}
                    onClick={() => {
                      setAnimation(!animation);
                      handleFavourites(open.data);
                    }}></div>
                  <img
                    onClick={() => {
                      handleShareProduct();

                      setModal({ ...modal, modal: true, name: 'share' });
                    }}
                    style={{ display: dimension[0] < 500 ? '' : 'none' }}
                    src={
                      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2425.svg'
                    }
                    alt=""
                  />
                </div>
                <div
                  className="col-sm-12 col-lg-7"
                  style={{
                    textAlign: dimension[0] < 500 ? 'center' : ''
                  }}>
                  <h4>{open.data?.name}</h4>
                  <h4>
                    <b>Rs. {new Intl.NumberFormat('en-IN').format(open.data?.pixel_price)} </b>
                    <span style={{ opacity: 0.7 }}>
                      <s>Rs. {new Intl.NumberFormat('en-IN').format(open.data?.price)}</s>
                    </span>
                  </h4>
                  <br></br>
                  <p>Color: {open.data?.color}</p>
                  <p>Material: {open.data?.material}</p>
                  <p>
                    Dimensions: {open.data?.dimensions.l} {" '' "}L * {open.data?.dimensions.b}
                    {" '' "}W
                  </p>
                  {open?.data && open.data['3d_models']?.glbFileUrl ? (
                    <button
                      // onClick={() => dimension[0] > 500 ? handleQr(data._id) : mobileHandle3D(data._id)}
                      onClick={() =>
                        dimension[0] > 500
                          ? handleQr(open?.data?._id)
                          : mobileHandle3D(open?.data?._id)
                      }
                      className={style1.arPart}>
                      <img
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Arpath.png"
                        className={style1.arImg}
                      />
                      <p className={style1.view}>View in AR</p>
                    </button>
                  ) : (
                    <>
                      <form
                        ref={form}
                        onSubmit={sendEmail}
                        style={{ visibility: 'hidden', position: 'absolute' }}>
                        <input type="text" value={open.data?.name} name="name" />
                        <input type="text" value={open.data?._id} name="productId" />
                        <input type="text" value={open.data?.category} name="category" />
                        <input type="text" value={open.data?.price} name="price" />
                        <input type="text" value={userName} name="userName" />
                      </form>
                    </>
                  )}
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <button
                      className={style.buttonWhite}
                      onClick={() => history.push(`/product/${open.data?._id}`)}
                      style={{
                        whiteSpace: dimension[0] < 500 ? 'nowrap' : ''
                      }}>
                      View Full Details
                    </button>
                    <button
                      className={classnames(style.buttonBlack, 'ml-2')}
                      onClick={() => {
                        addToCart(open.data);
                        // let x = getCartDataCount(auth);
                        storeCartCount(getCartDataCount(auth));
                      }}>
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal> */}
      <div>
        {productsList.length > 0 && (
          <div
            className={style.displayrow}
            style={{
              // marginBottom: '39px',
              marginTop:"20px",
              padding: '0px 16px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              // justifyContent:'left'
            }}>
            <h5 className={style.bestSell}>{title ? title : 'Best sellers'}</h5>

            {roomtype ? <h5 className={style.bestRoom}>{roomtype}</h5> : null}
          </div>
        )}
        {loading ? (
          <div className="text-center">
            <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
          </div>
        ) : (
          <div>
            {productsList.length > 0 && (
              <Carousel>
                {productsList.map((data, index) => (
                  <ProductCard
                    key={index}
                    data={data}
                    heart={auth && auth.user}
                    heartIsActive={savedProductIds?.includes(data._id)}
                    arIcon={data && data['3d_models']?.glbFileUrl}
                    tag={tag}
                    vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                    productImage={data.image[0]}
                    productName={data.name}
                    price={data.price}
                    pnmPrice={data.pixel_price}
                    onClickCart={() => addToCart(data)}
                    onClickBuyNow={() => buyNow(data)}
                    onClickHeart={() => handleFavourites(data)}
                    onClicAr={() =>
                      dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                    }
                    viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                    sx={{ margin: 'auto' }}
                  />
                ))}
              </Carousel>
            )}
          </div>
        )}
        {modalOpen && (
          <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />
        )}
      </div>
    </>
  );
}
BestSeller.getInitialProps = ({ req }) => {
  let userAgent;
  if (req) {
    userAgent = req.headers['user-agent'];
  } else {
    userAgent = navigator.userAgent;
  }
  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || 'desktop';
  return { deviceType };
};
