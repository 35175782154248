import React, { useEffect, useState, useLayoutEffect } from 'react';
import Header from '../Header/Header';
import style from './package.module.css';
import classnames from 'classnames';
import { withRouter, useParams } from 'react-router-dom';
import Tabs from './Tabs';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import customerApiInstance from '../../utils/CustomerApiInstance';
import {
  reset_answers,
  storeMx,
  page,
  page2,
  storeTab,
  storeMxAns,
  questionnaire,
  storePackage
} from '../../store/actions/sampleaction';
import ScrollAnimation from 'react-animate-on-scroll';
import image1 from '../../assets/Group 6489@2x.png';
import image2 from '../../assets/Group 6495@2x.png';
import image3 from '../../assets/Group 6491@2x.png';
import image4 from '../../assets/Group 6494@2x.png';
import image5 from '../../assets/Group 6493@2x.png';
import ConsoleHelper from '../../utils/ConsoleHelper';

const Package = ({
  history,
  auth,
  reset_answers,
  storeMx,
  page,
  page2,
  questionnaireData,
  storeTab,
  storeMxAns,
  locationparam,
  historyAction,
  pagetype,
  questionnaire,
  storePackage
}) => {
  const dimension = useMediaQuery();
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState();
  const { id } = useParams();
  let currentProjectId = '';
  currentProjectId = id;
  if (currentProjectId) {
    localStorage.removeItem('projectid');
  }
  const [data, setData] = useState([
    {
      title: 'Enhanced',
      price: 7500,
      sub: 'Give your blank canvas the VIP treatment with a personalised, detailed transformation.',
      description: [
        '1:1 online consultation with designers',
        'Collaborate on 3 initial ideas delivered within 2 days',
        'Personalised design concept with hand-curated design picks',
        'Use our online tools to perfect your layout',
        'Unlimited revisions and access to the Design Team',
        'Online shopping list',
        'Purchasing service, access to hundreds of brands',
        'Personal Ordering Team',
        'Shoppable 3D views of layout',
        'Custom floor plan'
      ],
      borderColor: '#EAB146'
    }
  ]);

  const priceData = [
    {
      id: 1,
      image: image1,
      title: 'Personal Designer',
      content: 'Get appointed a dedicated designer with web and phone support.'
    },
    {
      id: 2,
      image: image2,
      title: 'Custom Interior Designs',
      content:
        'Every interior design concept is individually tailored to your needs. (upto 3 different styles and revisions)'
    },
    {
      id: 3,
      image: image3,
      title: 'View in AR',
      subtitle: '(coming soon)',
      content: 'View your desired design in AR to see what your new interiors would look like.'
    },
    {
      id: 4,
      image: image4,
      title: 'Special Discounts',
      content: 'Every Pixel & Mortar member gets exclusive discounts on furniture.'
    },
    {
      id: 5,
      image: image5,
      title: 'Even more coming soon',
      content:
        "We're constantly working on providing more value with additional add-on services and features."
    }
  ];

  useEffect(() => {
    if (pagetype) {
      if (
        questionnaireData.hasOwnProperty('MxData') &&
        questionnaireData.MxData.projId &&
        questionnaireData.MxData.projId !== ''
      ) {
        storeTab('package');
        questionnaire('package');
        history.push(`/questionnaire/${pagetype}/${questionnaireData.MxData.projId}`);
      } else {
        storeTab('package');
        questionnaire('package');
        history.push(`/questionnaire/${pagetype}`);
      }
    }
  }, []);

  useEffect(() => {
    if (historyAction && locationparam) {
      if (historyAction === 'POP') {
        questionnaire('questionnaire2');
        storeTab('questionnaire2');
        page2(5);
        if (
          questionnaireData.hasOwnProperty('MxData') &&
          questionnaireData.MxData.projId &&
          questionnaireData.MxData.projId !== ''
        ) {
          history.replace(`/questionnaire/budget/${questionnaireData.MxData.projId}`);
        } else if (localStorage.getItem('projectid')) {
          let p = localStorage.getItem('projectid');
          history.replace(`/packages/${p}`);
        } else {
          history.replace('/questionnaire/space');
        }
      }
    }
  }, [historyAction, locationparam, questionnaireData]);

  useEffect(() => {
    const fetchProject = async () => {
      if (auth && auth.user && currentProjectId !== '') {
        await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/project/${currentProjectId}`
        );
      }
      let data1;
      if (
        questionnaireData.hasOwnProperty('MxData') &&
        questionnaireData.MxData.projId &&
        questionnaireData.MxData.projId !== ''
      ) {
        data1 = data.filter(dt => dt.title !== 'Express');
        if (data1.length > 0) {
          setData([...data1]);
        }
      }
    };
    currentProjectId && fetchProject();
  }, [currentProjectId, questionnaireData]);

  const handleSubmit = async data => {
    try {
      let res2;
      let res;
      let packageData = data;
      let userId;
      if (auth && auth.user) {
        userId = auth.user._id;
      } else {
        const getCurrentUser = JSON.parse(localStorage.getItem('MX-A'));
        ConsoleHelper(getCurrentUser);
        if (getCurrentUser?.hasOwnProperty('_id')) {
          userId = getCurrentUser._id;
        }
      }
      const body = {
        _id: userId,
        questionnaire: {
          package: data,
          purchased: false
        }
      };

      let mx1 = JSON.parse(localStorage.getItem('MX-A-answers'));
      let mxQns = { ...mx1.questionnaire, ...body.questionnaire };
      mx1.questionnaire = { ...mxQns };
      let reduxMx = questionnaireData.hasOwnProperty('MxAns') && questionnaireData.MxAns;
      if (reduxMx.hasOwnProperty('questionnaire')) {
        reduxMx.questionnaire = {
          ...reduxMx.questionnaire,
          ...body.questionnaire
        };
        storeMxAns({ ...reduxMx });
      }

      storeMx({ ...questionnaireData.MxData, MX_A_PACKAGE: packageData });
      let MX_A3 = {
        questionnaire: {
          PACKAGE: data
        }
      };
      let qns1 = JSON.parse(localStorage.getItem('MX-A-questionnaire'));
      let qns = { ...qns1.questionnaire, ...MX_A3.questionnaire };
      qns1.questionnaire = { ...qns };
      localStorage.setItem('MX-A-questionnaire', JSON.stringify(qns1));
      if (currentProjectId) {
        mx1.project_id = currentProjectId;
      }
      localStorage.setItem('MX-A-answers', JSON.stringify(mx1));
      if (auth && auth.user) {
        storePackage({ ...mx1 });
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`, {
          ...mx1,
          user_id: body._id
        });
      } else {
        storePackage({ ...mx1 });
        let res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/list`, {
          params: { email: mx1.email }
        });
        let existingUser = res.data;
        if (existingUser && existingUser.length > 0) {
          storeTab('questionnaire');
          history.push({
            pathname: '/login',
            state: { callFrom: 'questionnaire1' }
          });
          localStorage.setItem('RedirectToLogin', JSON.stringify(true));
        } else {
          storeTab('questionnaire');
          history.push('/signup');
          localStorage.setItem('RedirectToSignup', JSON.stringify(true));
        }
      }

      if (res && res.status === 200) {
        let rooms = [];
        let styles = [];
        rooms = [...res.data.questionnaire.rooms];
        if (res.data.questionnaire.space && res.data.questionnaire.space.length > 0) {
          res.data.questionnaire.space.map(sp => {
            styles = [...styles, sp.tag];
          });
        } else if (res.data.questionnaire.style && res.data.questionnaire.style.length > 0) {
          res.data.questionnaire.style.map(st => {
            styles = [...styles, st.tag];
          });
        }
        styles = styles.flat();
        styles = styles.filter((v, i, a) => a.indexOf(v) === i);
        setSnack(true);
        setSnackMessage('Package updated successfully, enjoy exploring!!');
        if (auth && auth.isAuthenticated) {
          if (packageData.title !== 'Express') {
            if (currentProjectId) {
              let MX_A3 = {
                questionnaire: {
                  PACKAGE: packageData
                }
              };

              await customerApiInstance.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${currentProjectId}`,
                MX_A3
              );
            }
            if (currentProjectId && packageData.title !== 'Express') {
              history.push({
                pathname: `/package-payment/${currentProjectId}`,
                state: { projectId: currentProjectId }
              });
            } else {
              history.push('/package-payment');
            }
          } else {
            if (packageData.title === 'Express') {
              let qns1 = JSON.parse(localStorage.getItem('MX-A-questionnaire'));
              qns1.status = 'PENDING_ALLOCATION';
              qns1.designer_allocation = 'UNASSIGNED';
              qns1.name = 'My' + ' ' + qns1.questionnaire.MX_A_INITAL_ROOM;
              res2 = await customerApiInstance.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
                qns1
              );
              page(0);
              page2(0);
              storeMx({});
              storeTab('questionnaire');
              localStorage.setItem('projectid', res2.data.project._id);

              history.push(`/explore?rooms=${rooms}&styles=${styles}`);
            }
          }
        }
        reset_answers();
      }
    } catch (err) {
      ConsoleHelper(err);
      setSnack(true);
      setSnackMessage('Something went wrong');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={style.PackageContainer}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header className="m-0 p-0" />
      <div
        style={{
          marginTop: '110px'
        }}>
        <Tabs />
      </div>
      <div
        className="container-fluid d-flex flex-column justify-content-center align-items-center"
        style={{
          maxWidth: '100%',
          paddingLeft: '0px',
          paddingRight: '0px',
          backgroundSize: '99%',
          backgroundColor: '#F7F7F7',
          marginTop: dimension[0] > 2000 && '4vh',
          backgroundPosition: '30% 80%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url("https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-icons/Group+2947.png")'
        }}>
        <div style={{ width: '75%' }}>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <h3 className={classnames('pt-5 pb-4', style.header3)} style={{ textAlign: 'center' }}>
              Interior design you’ve always wanted,<br></br> at the price you never thought was
              possible.
            </h3>
            <p className={style.textSub}>Transform your room and watch it come alive in AR</p>
            <div className={style.prices}>
              <div className={style.strick}>₹ 10,000</div>
              <div className={style.original}>₹ 7,500</div>
            </div>
          </ScrollAnimation>
        </div>

        <div
          className={classnames(
            'container-fluid d-flex flex-column align-items-center justify-content-center'
          )}>
          <div
            className={classnames(
              'row d-flex flex-row align-items-start justify-content-center mt-2',
              style.packages
            )}
            style={{ width: dimension[0] > 500 ? '80%' : '100%' }}>
            <h5 className={style.included}>What included?</h5>

            {priceData?.map(item => (
              <div
                className={classnames(
                  'cardss d-flex align-items-center justify-content-center text-center mt-3',
                  style.cardss
                )}>
                <div className={classnames('card', style.card)}>
                  <img src={item.image} className={style.images} />
                  <h4 className={style.title}>{item.title}</h4>
                  <p className={style.content}>{item.content}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center align-items-start w-100 mb-5">
            <button
              className={style.designButton}
              onClick={() => {
                handleSubmit(data[0]);
              }}
              style={{ fontWeight: 700 }}>
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Package.propTypes = {
  auth: PropTypes.object.isRequired,
  questionnaireData: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData
  };
};
export default connect(mapStateToProps, {
  reset_answers,
  storeMx,
  page,
  page2,
  storeTab,
  storeMxAns,
  questionnaire,
  storePackage
})(withRouter(Package));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
