import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import style from '../../Shop/Shopdetails.module.css';
import style1 from '../../Shop/Shop.module.css';
import '../../Shop/Shop.css';
import classnames from 'classnames';
import { Drawer, Grid } from '@material-ui/core';
import axios from 'axios';
import * as Icon from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { rooms } from '../../Shop/constant';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import Carousel from 'react-elastic-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import '@google/model-viewer';
import { QRCodeSVG } from 'qrcode.react';
import { Spinner } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import customerApiInstance from '../../../utils/CustomerApiInstance';
import getCartDataCount from '../../../utils/CartDetails';
import { storeCartCount } from '../../../store/actions/sampleaction';
import { useParams } from 'react-router-dom';
import Heart from 'react-heart';
import emailjs from '@emailjs/browser';

import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon
} from 'react-share';
import QRScanner from '../../../components/layout/QrScanner';
import { store } from '../../../store/store';
import Shareurl from '../../../components/Shareurl';
import { GrClose } from 'react-icons/gr';
import { CloseOutlined } from '@material-ui/icons';
import ConsoleHelper from '../../../utils/ConsoleHelper';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: theme.shadows[5]
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: 'none',
    width: 800,
    height: 500,
    padding: theme.spacing(2, 4, 3)
  },
  papers: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    width: '70%',
    height: 500,
    position: 'relative',
    outline: 'none'
  },
  displayrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  roombox: {
    fontSize: '15px',
    marginTop: '16px',
    fontWeight: 600,
    marginBottom: '51px'
  },
  drawerHeader: {
    float: 'right'
  }
}));

const ProductList = ({ history, auth, storeCartCount, roomtype, pageType, setMoodboardUrl }) => {
  const userName = localStorage.getItem('userName');
  const form = useRef();
  const params = useParams();
  const { id } = useParams();
  const classes = useStyles();
  const dimension = useMediaQuery();

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
  ];

  const shop_type = [
    { id: 1, value: 'living_room', type: 'Living Room' },
    { id: 2, value: 'dining_room', type: 'Dining Room' },
    { id: 3, value: 'bed_room', type: 'Bed Room' },
    { id: 4, value: 'kids_room', type: 'Kids Room' },
    { id: 5, value: 'puja_room', type: 'Puja Room' },
    { id: 6, value: 'foyer', type: 'Foyer' },
    { id: 7, value: 'balcony', type: 'Balcony' },
    {
      id: 8,
      value: 'study_entertainment_room',
      type: 'Study / Entertainment Room'
    },
    { id: 9, value: 'other', type: 'Other' }
  ];

  const [animation, setAnimation] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [isHovered, setHover] = useState(false);
  const [brands, setBrands] = useState([]);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [prodQuantity] = useState(1);
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [savedProducts, setSavedProducts] = useState([]);
  const [overlay, setOverlay] = useState(null);
  const [brandClicked, setBrandClicked] = useState(false);
  const [brandImage] = useState();
  const [currentBrand] = useState({});

  const [brandExpanded, setBrandExpanded] = useState(false);
  const [colorExpanded, setColorExpanded] = useState(false);
  const [materialExpanded, setMaterialExpanded] = useState(false);
  const [colors, setColors] = useState(['Brown', 'Red']);
  const [material, setMaterial] = useState([]);
  const [likesChecked] = useState(false);
  let [colorFilter, setColorFilter] = useState([]);
  const [colorClicked] = useState('');
  const [filterOption, setFilterOption] = useState({});
  const [open, setOpen] = useState({});
  const [currentProduct, setCurrentProduct] = useState({});
  const copy = useRef();
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  const [copiedUrl, setCopiedUrl] = useState('');
  const [page] = useState(1);
  const [loading] = useState(false);
  const [prodLoading] = useState(false);
  // const [selectedsubcategory, setselectedsubcategory] = useState("");
  const [qrOpen, setQrOpen] = useState(false);
  const { productId } = useParams();
  const [productData, setProductData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [shopType] = useState(roomtype ? roomtype : 1);
  const [productsDisplay, setProductsDisplay] = useState([]);
  const [moodboardProductsData, setMoodboardProductsData] = useState([]);
  const [similarShow, setSimilarShow] = useState(false);
  const [similarExpanded, setSimilarExpanded] = useState(false);
  const [shopIndex, setShopIndex] = useState();
  const [similarProductsShow, setSimilarProductsshow] = useState([]);
  const [divider, setDivider] = useState(1);
  const [addedCol, setAddedCol] = useState(0);
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();
  const [newFiltersState, setNewFiltersState] = useState({});

  useEffect(() => {
    let defaultProductsList = [];

    // default products list
    let moodboardProducts = store.getState().sample.moodBoardProduct;
    moodboardProductsData.forEach(element => {
      moodboardProducts.forEach(item => {
        if (element._id == item.productId) defaultProductsList.push(element);
      });
    });

    // filter brand
    if (newFiltersState.brand) {
      defaultProductsList = defaultProductsList.filter(
        product => product?.vendor_id?.name === newFiltersState.brand
      );
    }

    // filter material
    if (newFiltersState.material) {
      defaultProductsList = defaultProductsList.filter(product =>
        product.material.some(material => newFiltersState.material.includes(material))
      );
    }

    // filter color
    if (newFiltersState.color) {
      defaultProductsList = defaultProductsList.filter(product =>
        newFiltersState.color.includes(product.color)
      );
    }

    // filter favourites
    if (newFiltersState.likesChecked) {
      defaultProductsList = defaultProductsList.filter(product =>
        savedProductIds?.includes(product['_id'])
      );
    }

    // filter AR
    if (newFiltersState.arChecked) {
      defaultProductsList = defaultProductsList.filter(product => {
        return product['3d_models']?.glbFileUrl;
      });
    }

    filterProducts(defaultProductsList);
  }, [newFiltersState]);

  useEffect(() => {
    filterProducts(moodboardProductsData);
  }, [store.getState().sample.moodBoardProduct]);

  useEffect(() => {
    if (Object.keys(filterOption).length > 0) {
      handleCloseAnimation();
    }
  }, [filterOption]);

  useEffect(() => {
    const vendorDetails = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendor/list`);
    };
    vendorDetails();
  }, [brandClicked]);

  useEffect(() => {
    if (dimension[0] > 1 && dimension[0] < 550) {
      setDivider(1);
    }
    if (dimension[0] > 550 && dimension[0] < 768) {
      setDivider(2);
    }
    if (dimension[0] > 768) {
      setDivider(3);
    }
  }, [dimension]);

  useEffect(() => {
    const body = {};
    if (colorFilter.length > 0) {
      if (colorFilter.includes('colorClicked')) {
        colorFilter = colorFilter.filter(cl => cl === colorClicked);
        setColorFilter([...colorFilter]);
        body.color = colorClicked;
      } else {
        body.color = colorClicked;
      }
      const data = {
        body: body
      };
      filterData(data);
    }
  }, [colorFilter]);

  useEffect(() => {
    fetchFavouriteData();
  }, [likesChecked]);

  useEffect(() => {
    if (shopType) {
      let room = shop_type?.find(item => item.id === shopType)?.type;
      if (filterOption.hasOwnProperty('room_type')) {
        let index = filterOption.room_type.findIndex(rm => rm === room);
        if (index < 0) {
          setFilterOption({
            ...filterOption,
            room_type: [room]
          });
        } else {
          filterOption.room_type = filterOption.room_type.filter(rm => rm !== room);
          setFilterOption({ ...filterOption });
        }
      } else {
        setFilterOption({ ...filterOption, room_type: [room] });
      }
      let data = [];
      data = rooms
        .find(item => item.id == shopType)
        ?.category?.find(sub => sub.title == params.category)?.subcategory;
      //setsubcategory(data);
    }
  }, [shopType]);

  // useEffect(() => {
  //   if (selectedsubcategory !== "") {
  //     let roomtitle = rooms.find((item) => item.id == params.roomtype);
  //     setFilterOption({
  //       ...filterOption,
  //       category: [params.category],
  //       room_type: [roomtitle.title],
  //       subcategory: [selectedsubcategory],
  //     });
  //   }
  // }, [selectedsubcategory]);

  useEffect(() => {
    // fetchData();
    productId && getP();
    getProductDetails();
    if (params?.roomtype) {
      // let data = [];
      // data = rooms
      //   .find((item) => item.id == params.roomtype)
      //   ?.category?.find((sub) => sub.title == params.category)?.subcategory;
      // setsubcategory(data);
      let roomtitle = rooms.find(item => item.id == params.roomtype);
      if (roomtitle?.title) {
        setFilterOption({
          ...filterOption,
          room_type: [roomtitle.title],
          category: [params.category]
        });
      }
    }
  }, []);

  useEffect(() => {
    handleShareProduct();
  }, [open]);

  const filterProducts = products => {
    let moodboardProducts = store.getState().sample.moodBoardProduct;

    let finalProducts = [];
    products.forEach(element => {
      moodboardProducts.forEach(item => {
        if (element._id == item.productId) finalProducts.push(element);
      });
    });
    setProductsDisplay(finalProducts);
  };

  const sendEmail = e => {
    e.preventDefault();
    emailjs.sendForm('service_dc6bf0u', 'template_byt0qp9', form.current, 'u-JUBaCmUc6toe0bh').then(
      result => {
        setSnack(true);
        setSnackMessage('Request AR send to Mail Successfully');
      },
      error => {
        setSnack(false);
        setSnackMessage('Request AR Error');
        ConsoleHelper(error.text);
      }
    );
  };

  const handleQr = productIds => {
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = productIds => {
    history.push(`/3d-model/${productIds}`);
  };

  const handleQrClose = () => {
    setQrOpen(false);
  };

  const handleClose = () => {
    setOpen({ ...open, modal: false });
  };

  const getProductDetails = async () => {
    let apiPageType = {};
    apiPageType = {
      status: 'FINALIZE_CONCEPT'
    };

    const moodboard = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/concept/${id}`,
      apiPageType
    );

    if (moodboard && moodboard.data.moodBoard) {
      if (pageType == 'SHOP') setMoodboardUrl(moodboard.data.moodBoard.preview_url);

      let productsArray = [];
      let productsData = [];
      let disabledProducts = [];
      let moodboardProducts = moodboard.data.moodBoardProducts;
      let moodboardData = moodboard.data.moodBoard;
      if (
        moodboard.data.moodBoard &&
        moodboard.data.moodBoard.hasOwnProperty('shared_to_customer')
      ) {
        productsArray = moodboard.data.moodBoard.shared_to_customer.products;
        productsArray.map(prod => {
          prod.map(prod1 => {
            if (prod1.visible === true) {
              productsData.push(prod1);
            }
          });
        });
      }

      let groupEnabledProducts = productsData.reduce(function(obj, item) {
        obj[item.id] = obj[item.id] || [];
        if (item.enabled === true) obj[item.id].push(item.product_id);
        return obj;
      }, {});

      let enabledProducts = Object.keys(groupEnabledProducts).map(function(key) {
        return { id: key, product_ids: groupEnabledProducts[key] };
      });
      let groupDisabledProducts = productsData.reduce(function(obj, item) {
        obj[item.id] = obj[item.id] || [];
        if (item.enabled === false) obj[item.id].push(item.product_id);
        return obj;
      }, {});
      if (Object.keys(groupDisabledProducts).length) {
        disabledProducts = Object.keys(groupDisabledProducts).map(function(key) {
          return { id: key, product_ids: groupDisabledProducts[key] };
        });
      }
      let enabledProdsTemp = [];
      let disabledProdsTemp = [];
      const map = new Map();
      let productIds = [];
      moodboardData.shared_to_customer &&
        moodboardData.shared_to_customer.products.map((item, index) => {
          if (item instanceof Array && item.length > 0) {
            item.map((data, key) => {
              if (data.visible === true) {
                productIds = [...productIds, data.product_id];
              }
            });
          }
        });
      productIds.forEach(item => {
        if (map.has(item)) {
          map.get(item).quantity++;
        } else {
          map.set(item, {
            item,
            quantity: 1
          });
        }
      });
      const result = Array.from(map.values());
      for (let i = 0; i < moodboardProducts.length; i++) {
        for (let j = 0; j < result.length; j++) {
          if (moodboardProducts[i]._id === result[j].item) {
            moodboardProducts[i].quantity = result[j].quantity;
            moodboardProducts[i].final_price = moodboardProducts[i].price
              ? moodboardProducts[i].price * result[j].quantity
              : moodboardProducts[i].pixel_price * result[j].quantity;
          }
        }
      }
      filterProducts([...moodboardProducts]);
      setMoodboardProductsData([...moodboardProducts]);
      if (disabledProducts.length > 0) {
        for (let i = 0; i < moodboardProducts.length; i++) {
          for (let j = 0; j < disabledProducts.length; j++) {
            disabledProducts[j].product_ids.map(disabledProdId => {
              if (disabledProdId === moodboardProducts[i]._id) {
                disabledProdsTemp.push({
                  ...moodboardProducts[i],
                  uuid: disabledProducts[j].group_id
                });
              }
            });
          }
        }
      }
      for (let i = 0; i < moodboardProducts.length; i++) {
        for (let j = 0; j < enabledProducts.length; j++) {
          enabledProducts[j].product_ids.map(enabledProdId => {
            if (enabledProdId === moodboardProducts[i]._id) {
              enabledProdsTemp.push({
                ...moodboardProducts[i],
                uuid: enabledProducts[j].group_id
              });
            }
          });
        }
      }
      let category = moodboardProducts.map(data => {
        return data.category;
      });
      let colorTemp = [];
      let materialTemp = [];
      let brandsTemp = [];
      let brandsdataTemp = [];
      moodboardProducts.map(data => {
        colorTemp.push(data.color);
        materialTemp.push(data.material);
        if (data.vendor_id && data.vendor_id.name) {
          brandsTemp.push(data.vendor_id.name);
        }
        if (data.vendor_id && data.vendor_id._id) {
          brandsdataTemp.push(data.vendor_id._id);
        }
      });
      materialTemp = materialTemp.flat();
      setBrands([...new Set(brandsTemp)]);
      setMaterial([...new Set(materialTemp)]);
      setColors([...new Set(colorTemp)]);

      const categoriesCount = {};
      category.forEach(x => {
        categoriesCount[x] = (categoriesCount[x] || 0) + 1;
      });
    } else {
    }
  };

  const getSimilarProducts = data => {
    let alternateProducts = [];
    let alternateProductIds = [];
    let alternateProductGroup = store.getState().sample.moodBoardData.moodBoard.products;
    alternateProductGroup.forEach(item => {
      let index = item.findIndex(function(innerItem) {
        return innerItem.product_id == data._id;
      });
      if (index > -1) {
        item.forEach(elem => {
          if (elem.product_id != data._id) alternateProductIds.push(elem.product_id);
        });
      }
    });
    moodboardProductsData.map(product => {
      alternateProductIds.map(id => {
        if (product._id == id) alternateProducts.push(product);
      });
    });
    setSimilarProductsshow(alternateProducts);
  };

  const getCompute = (index, isLastIndex) => {
    if (isLastIndex) {
      let add = index + 1;
      setAddedCol(add);
    } else {
      let add = Math.ceil((index + 1) / divider) * divider;
      setAddedCol(add);
    }
  };

  const handleHover = value => {
    setOverlay(value);
  };

  const filterData = async filters => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
      params: { page },
      body: { ...filters }
    });
  };

  const addToCart = async productData => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;
        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);

          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }
    storeCartCount(await getCartDataCount(auth));
  };

  const renderColumn = () => {
    let cols = [];
    {
      if (productsDisplay && productsDisplay.length > 0) {
        productsDisplay.map((data, index) => {
          cols.push(
            <>
              {dimension[0] > 500 ? (
                <>
                  <div className="col-6 col-lg-4 col-md-6 col-xl-4  mb-4">
                    <div className="card-deck h-100">
                      <div
                        className="card h-100 mb-2"
                        id={`card${index}`}
                        style={{
                          height: 'auto',
                          'border-radius': '10px',
                          zIndex: `${isHovered && '10px'}`,
                          postion: `${isHovered ? 'absolute' : 'relative'}`
                        }}
                        onMouseOver={() => {
                          setHover(true);
                        }}
                        onMouseLeave={() => setHover(false)}>
                        <div className={style.cardcontainer1}>
                          <div className={'mr-3 mt-3 d-flex  align-items-end justify-content-end'}>
                            {auth && auth.user && (
                              <div style={{ width: '1rem' }}>
                                <Heart
                                  isActive={savedProductIds?.includes(data._id) ? true : false}
                                  onClick={() => handleFavourites(data)}
                                  animationScale={1.2}
                                  animationTrigger="both"
                                  animationDuration={0.2}
                                  style={{
                                    fill: savedProductIds?.includes(data._id)
                                      ? 'rgb(255, 196, 196)'
                                      : '#fff'
                                  }}
                                />
                              </div>
                            )}
                            {data && data.stock !== 0 && data['3d_models']?.glbFileUrl && (
                              <button
                                className={style.arIconbtn}
                                onClick={() => handleQr(data._id)}>
                                <img
                                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/ARIcon.png"
                                  className={style.arIcon}
                                />
                              </button>
                            )}
                          </div>
                          <div className={style.container1} style={{ width: '100%' }}>
                            <div
                              className={classnames('d-flex mb-2', style.buttonImg)}
                              style={{
                                width: '100%',
                                position: 'relative',
                                justifyContent: 'center'
                              }}
                              onMouseOver={() => handleHover(index)}
                              onMouseLeave={() => setOverlay(null)}>
                              <div style={{ position: 'relative', width: '100%' }}>
                                {overlay === index && (
                                  <div className={style1.overlay}>
                                    <div
                                      style={{
                                        margin: '0px auto',

                                        backgroundColor: 'rgb(0 0 0 / 60%)',
                                        height: '100%'
                                      }}>
                                      <div className="d-flex justify-content-center align-items-center">
                                        <button
                                          style={{
                                            background: 'transparent',
                                            color: 'white',
                                            marginTop: '80px',
                                            marginBottom: '50px',
                                            fontSize: '14px',
                                            border: '1.5px solid #ffffff',
                                            borderRadius: '0',
                                            fontWeight: '500',
                                            alignSelf: 'center'
                                          }}
                                          type="button"
                                          className="btn btn-light"
                                          onClick={() => {
                                            setCurrentProduct({ ...data });
                                            setOpen({
                                              ...open,
                                              modal: true,
                                              data: data
                                            });
                                          }}>
                                          VIEW DETAILS{' '}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <img
                                  key={`prod-${index}`}
                                  src={data?.image[0]}
                                  alt=""
                                  className={style.imageFit}
                                  style={{
                                    width: '100%'
                                  }}
                                />
                              </div>
                            </div>
                            <div className={classnames(style.middle, '')}></div>
                          </div>

                          <div className="mt-2">
                            <center>
                              {data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                            </center>
                          </div>
                          <div className=" mt-2 mr-5 ml-5 d-flex justify-content-center ">
                            <div className={style.dataName}>{data.name}</div>
                          </div>
                          <p className={style.priceData}>
                            <center>
                              Rs. {new Intl.NumberFormat('en-IN').format(data.price)}{' '}
                            </center>
                          </p>
                          {data.stock !== 0 ? (
                            <Button
                              size="md"
                              className={classnames(
                                style1.cartButton,
                                ' mb-2  justify-content-center'
                              )}
                              onClick={() => addToCart(data)}>
                              <span style={{ textAlign: 'center' }}> Add to Cart</span>
                            </Button>
                          ) : null}

                          {data.stock === 0 ? (
                            <>
                              <p
                                className={style.dataName}
                                style={{
                                  color: '#FF0000',
                                  marginTop: '-40px',
                                  marginInlineEnd: 'auto',
                                  marginBottom: '20px',
                                  fontSize: '14px',
                                  marginLeft: 'auto',
                                  marginRight: 'auto'
                                }}>
                                OUT OF STOCK
                              </p>
                              <div
                                className={style1.similarButton1}
                                onClick={() => {
                                  setSimilarShow(true);
                                  setSimilarExpanded(true);
                                  setShopIndex(index);
                                  getSimilarProducts(data);

                                  getCompute(index, productsDisplay.length - 1 === index);
                                }}>
                                <span>DESIGNER ALTERNATIVES</span>
                                {similarShow && shopIndex === index && (
                                  <div className={style.shopArrow}></div>
                                )}
                                <FontAwesomeIcon
                                  size={10}
                                  style={{
                                    alignItems: 'center',
                                    marginLeft: '10px'
                                  }}
                                  icon={
                                    similarExpanded === true && index === shopIndex
                                      ? faChevronDown
                                      : faChevronRight
                                  }
                                />
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-lg-4 col-md-4 col-xl-4 mb-4">
                    <div className="card-deck h-100">
                      <div
                        className="card h-100 mb-2"
                        id={`card${index}`}
                        style={{
                          height: 'auto',
                          'border-radius': '10px',
                          zIndex: `${isHovered && '10px'}`,
                          postion: `${isHovered ? 'absolute' : 'relative'}`
                        }}
                        onMouseOver={() => {
                          setHover(true);
                        }}
                        onMouseLeave={() => setHover(false)}>
                        <div className={style.cardcontainer1}>
                          <div
                            className={classnames(
                              'mr-3 mt-3 d-flex  align-items-end justify-content-end',
                              style.heartAlign
                            )}
                            style={{
                              borderBottom: savedProductIds?.includes(data._id)
                                ? '1px solid rgba(0,0,0,.125)'
                                : ''
                            }}>
                            {auth && auth.user && (
                              <div style={{ width: '1rem' }}>
                                <Heart
                                  isActive={savedProductIds?.includes(data._id) ? true : false}
                                  onClick={() => handleFavourites(data)}
                                  animationScale={1.2}
                                  animationTrigger="both"
                                  animationDuration={0.2}
                                  style={{
                                    fill: savedProductIds?.includes(data._id)
                                      ? 'rgb(255, 196, 196)'
                                      : '#fff'
                                  }}
                                />
                              </div>
                            )}
                            {data && data.stock !== 0 && data['3d_models']?.glbFileUrl && (
                              <button
                                className={style.arIconbtn}
                                onClick={() => mobileHandle3D(data._id)}>
                                <img
                                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/ARIcon.png"
                                  className={style.arIcon}
                                />
                              </button>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <div className={style.container1} style={{ width: '100%' }}>
                                <div
                                  className={style.buttonImg}
                                  style={{
                                    width: '100%',
                                    position: 'relative',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                  }}
                                  onMouseOver={() => handleHover(index)}
                                  onMouseLeave={() => setOverlay(null)}>
                                  <div style={{ position: 'relative' }}>
                                    {overlay === index && (
                                      <div
                                        className={style1.overlay}
                                        style={{
                                          height: 'auto',
                                          top: '0px'
                                        }}>
                                        <div
                                          style={{
                                            margin: '0px auto',

                                            backgroundColor: 'rgb(0 0 0 / 60%)',
                                            height: '100%'
                                          }}>
                                          <button
                                            style={{
                                              background: 'transparent',
                                              color: 'white',
                                              marginTop: 'auto',
                                              marginBottom: 'auto',
                                              fontSize: '8px',
                                              border: '1.5px solid rgb(255, 255, 255)',
                                              borderRadius: '0px',
                                              fontWeight: '500',
                                              alignSelf: 'center',
                                              marginTop: '38px',
                                              width: '92%',
                                              marginLeft: '4px'
                                            }}
                                            type="button"
                                            className="btn btn-light"
                                            onClick={() => {
                                              setCurrentProduct({ ...data });
                                              setOpen({
                                                ...open,
                                                modal: true,
                                                data: data
                                              });
                                            }}>
                                            VIEW DETAILS{' '}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                    <img
                                      key={`prod-${index}`}
                                      src={data.image[0]}
                                      alt=""
                                      className={style.imageFit}
                                    />
                                  </div>

                                  {data.stock !== 0 ? (
                                    <Button
                                      size="md"
                                      className={classnames(
                                        style1.cartButton,
                                        ' mb-2  justify-content-center'
                                      )}
                                      onClick={() => addToCart(data)}
                                      style={{
                                        width: '70%',
                                        marginTop: '15px'
                                      }}>
                                      <span style={{ textAlign: 'center' }}> Add</span>
                                    </Button>
                                  ) : null}
                                </div>
                                <div className={classnames(style.middle, '')}></div>
                              </div>
                            </div>

                            <div
                              className="col-7"
                              style={{
                                marginBottom: 'auto',
                                marginTop: 'auto'
                              }}>
                              <div className="mt-2">
                                {data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                              </div>
                              <div className="mt-0">
                                <div className={style.dataNamess}>{data.name}</div>
                              </div>
                              <p className={style.priceData}>
                                Rs. {new Intl.NumberFormat('en-IN').format(data.price)}{' '}
                              </p>

                              {data.stock === 0 ? (
                                <>
                                  <p className={style.dataNamess} style={{ color: 'FF0000' }}>
                                    OUT OF STOCK
                                  </p>

                                  <div
                                    className={style1.similarButton2}
                                    onClick={() => {
                                      setSimilarShow(true);
                                      setSimilarExpanded(true);
                                      setShopIndex(index);
                                      getSimilarProducts(data);

                                      getCompute(index, productsDisplay.length - 1 === index);
                                    }}>
                                    <span>DESIGNER ALTERNATIVES</span>
                                    <FontAwesomeIcon
                                      size={10}
                                      style={{
                                        alignItems: 'center',
                                        marginLeft: '10px'
                                      }}
                                      icon={
                                        similarExpanded === true && index === shopIndex
                                          ? faChevronDown
                                          : faChevronRight
                                      }
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          );

          if (index === addedCol - 1) {
            similarShow &&
              cols.splice(
                addedCol + 1,
                0,
                <div
                  className={style1.container3}
                  style={{
                    padding: '15vh 5vw 10vh 5vw;',
                    paddingBottom: '25px'
                  }}>
                  <div>
                    <div className="row mt-3 w-100 justify-content-end">
                      <Icon.XLg
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          filterProducts([...moodboardProductsData]);
                          setSimilarShow(false);
                        }}></Icon.XLg>
                    </div>
                    {similarProductsShow && similarProductsShow.length > 0 ? (
                      <Carousel
                        breakPoints={breakPoints}
                        pagination={false}
                        showArrows={dimension[0] < 500 ? false : true}>
                        {similarProductsShow.map((data, index) => (
                          <div key={index} className={style.cardWhole}>
                            {dimension[0] > 500 ? (
                              <div
                                className="card-deck h-100"
                                style={{
                                  overflowY: 'hidden',
                                  overflowX: 'hidden',
                                  justifyContent: 'center'
                                }}>
                                <div
                                  className="card h-100 mb-2"
                                  id={`card${index}`}
                                  style={{
                                    height: 'auto',
                                    'border-radius': '10px',
                                    zIndex: `${isHovered && '10px'}`,
                                    postion: `${isHovered && 'absolute'}`,
                                    maxWidth: '280px'
                                  }}
                                  onMouseOver={() => {
                                    setHover(true);
                                  }}
                                  onMouseLeave={() => setHover(false)}>
                                  <div className={style1.cardcontainer1}>
                                    <div
                                      className={
                                        'mr-3 mt-3 d-flex  align-items-end justify-content-end'
                                      }>
                                      {auth && auth.user && (
                                        <div style={{ width: '1rem' }}>
                                          <Heart
                                            isActive={
                                              savedProductIds?.includes(data._id) ? true : false
                                            }
                                            onClick={() => handleFavourites(data)}
                                            animationScale={1.2}
                                            animationTrigger="both"
                                            animationDuration={0.2}
                                            style={{
                                              fill: savedProductIds?.includes(data._id)
                                                ? 'rgb(255, 196, 196)'
                                                : '#fff'
                                            }}
                                          />
                                        </div>
                                      )}
                                      {data && data['3d_models']?.glbFileUrl && (
                                        <button
                                          className={style.arIconbtn}
                                          onClick={() => handleQr(data._id)}>
                                          <img
                                            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/ARIcon.png"
                                            className={style.arIcon}
                                          />
                                        </button>
                                      )}
                                    </div>
                                    <div className={style.container1} style={{ width: '100%' }}>
                                      <div
                                        className={classnames('d-flex mb-2', style.buttonImg)}
                                        style={{
                                          width: '100%',
                                          position: 'relative'
                                        }}
                                        onMouseOver={() => handleHover(index)}
                                        onMouseLeave={() => setOverlay(null)}>
                                        <div
                                          style={{
                                            position: 'relative',
                                            width: '100%'
                                          }}>
                                          {overlay === index && (
                                            <div className={style1.overlay}>
                                              <div
                                                style={{
                                                  margin: '0px auto',

                                                  backgroundColor: 'rgb(0 0 0 / 60%)',
                                                  height: '100%'
                                                }}>
                                                <div className="d-flex justify-content-center align-items-center">
                                                  <button
                                                    style={{
                                                      background: 'transparent',
                                                      color: 'white',
                                                      marginTop: '80px',
                                                      marginBottom: '50px',
                                                      fontSize: '14px',
                                                      border: '1.5px solid #ffffff',
                                                      borderRadius: '0',
                                                      fontWeight: '500',
                                                      alignSelf: 'center'
                                                    }}
                                                    type="button"
                                                    className="btn btn-light"
                                                    onClick={() => {
                                                      setCurrentProduct({
                                                        ...data
                                                      });
                                                      setOpen({
                                                        ...open,
                                                        modal: true,
                                                        data: data
                                                      });
                                                    }}>
                                                    VIEW DETAILS{' '}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          <img
                                            key={`prod-${index}`}
                                            src={data.image[0]}
                                            alt=""
                                            className={style.imageFit}
                                          />
                                        </div>
                                      </div>
                                      <div className={classnames(style.middle, '')}></div>
                                    </div>

                                    <div className="mt-2">
                                      <center>
                                        {data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                                      </center>
                                    </div>
                                    <div className=" mt-2 mr-5 ml-5 d-flex justify-content-center ">
                                      <div className={style.dataName}>{data.name}</div>
                                    </div>
                                    <p className={style.priceData}>
                                      <center>
                                        Rs. {new Intl.NumberFormat('en-IN').format(data.price)}{' '}
                                      </center>
                                    </p>
                                    <Button
                                      size="md"
                                      className={classnames(
                                        style1.cartButton,
                                        ' mb-2  justify-content-center'
                                      )}
                                      onClick={() => addToCart(data)}>
                                      <span style={{ textAlign: 'center' }}> Add to Cart</span>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="card-deck h-100">
                                <div
                                  className="card h-100 mb-2"
                                  id={`card${index}`}
                                  style={{
                                    height: 'auto',
                                    'border-radius': '10px',
                                    zIndex: `${isHovered && '10px'}`,
                                    postion: `${isHovered ? 'absolute' : 'relative'}`
                                  }}
                                  onMouseOver={() => {
                                    setHover(true);
                                  }}
                                  onMouseLeave={() => setHover(false)}>
                                  <div className={style.cardcontainer1}>
                                    <div
                                      className={classnames(
                                        'mr-3 mt-3 d-flex  align-items-end justify-content-end',
                                        style.heartAlign
                                      )}
                                      style={{
                                        borderBottom: savedProductIds?.includes(data._id)
                                          ? '1px solid rgba(0,0,0,.125)'
                                          : ''
                                      }}>
                                      {auth && auth.user && (
                                        <div style={{ width: '1rem' }}>
                                          <Heart
                                            isActive={
                                              savedProductIds?.includes(data._id) ? true : false
                                            }
                                            onClick={() => handleFavourites(data)}
                                            animationScale={1.2}
                                            animationTrigger="both"
                                            animationDuration={0.2}
                                            style={{
                                              fill: savedProductIds?.includes(data._id)
                                                ? 'rgb(255, 196, 196)'
                                                : '#fff'
                                            }}
                                          />
                                        </div>
                                      )}
                                      {data && data.stock !== 0 && data['3d_models']?.glbFileUrl && (
                                        <button
                                          className={style.arIconbtn}
                                          onClick={() => mobileHandle3D(data._id)}>
                                          <img
                                            src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/ARIcon.png"
                                            className={style.arIcon}
                                          />
                                        </button>
                                      )}
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className={style.container1} style={{ width: '100%' }}>
                                          <div
                                            className={style.buttonImg}
                                            style={{
                                              width: '100%',
                                              position: 'relative',
                                              justifyContent: 'center',
                                              textAlign: 'center'
                                            }}
                                            onMouseOver={() => handleHover(index)}
                                            onMouseLeave={() => setOverlay(null)}>
                                            <div
                                              style={{
                                                position: 'relative'
                                              }}>
                                              {overlay === index && (
                                                <div
                                                  className={style1.overlay}
                                                  style={{
                                                    height: 'auto',
                                                    top: '0px'
                                                  }}>
                                                  <div
                                                    style={{
                                                      margin: '0px auto',

                                                      backgroundColor: 'rgb(0 0 0 / 60%)',
                                                      height: '100%'
                                                    }}>
                                                    <button
                                                      style={{
                                                        background: 'transparent',
                                                        color: 'white',
                                                        marginTop: 'auto',
                                                        marginBottom: 'auto',
                                                        fontSize: '8px',
                                                        border: '1.5px solid rgb(255, 255, 255)',
                                                        borderRadius: '0px',
                                                        fontWeight: '500',
                                                        alignSelf: 'center',
                                                        marginTop: '38px',
                                                        width: '92%',
                                                        marginLeft: '4px'
                                                      }}
                                                      type="button"
                                                      className="btn btn-light"
                                                      onClick={() => {
                                                        setCurrentProduct({
                                                          ...data
                                                        });
                                                        setOpen({
                                                          ...open,
                                                          modal: true,
                                                          data: data
                                                        });
                                                      }}>
                                                      VIEW DETAILS{' '}
                                                    </button>
                                                  </div>
                                                </div>
                                              )}
                                              <img
                                                key={`prod-${index}`}
                                                src={data.image[0]}
                                                alt=""
                                                className={style.imageFit}
                                              />
                                            </div>

                                            {data.stock !== 0 ? (
                                              <Button
                                                size="md"
                                                className={classnames(
                                                  style1.cartButton,
                                                  ' mb-2  justify-content-center'
                                                )}
                                                onClick={() => addToCart(data)}
                                                style={{
                                                  width: '70%',
                                                  marginTop: '15px'
                                                }}>
                                                <span
                                                  style={{
                                                    textAlign: 'center'
                                                  }}>
                                                  {' '}
                                                  Add
                                                </span>
                                              </Button>
                                            ) : null}
                                          </div>
                                          <div className={classnames(style.middle, '')}></div>
                                        </div>
                                      </div>

                                      <div
                                        className="col-7"
                                        style={{
                                          marginBottom: 'auto',
                                          marginTop: 'auto'
                                        }}>
                                        <div className="mt-2">
                                          {data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                                        </div>
                                        <div className="mt-0">
                                          <div className={style.dataNamess}>{data.name}</div>
                                        </div>
                                        <p className={style.priceData}>
                                          Rs. {new Intl.NumberFormat('en-IN').format(data.price)}{' '}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <b>No similar Products</b>
                      </div>
                    )}
                  </div>
                </div>
              );
          }
        });
      } else {
        cols.push(<p>No Products Available </p>);
      }
    }
    return <div className="row sm-pl-3">{cols}</div>;
  };

  const addToFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = product => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds(prev => [...prev, product._id]);
      setSavedProducts([...savedProducts, product]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter(filter => filter !== product._id));
      const removefav = savedProducts.filter(sav => sav._id !== product._id);
      setSavedProducts([...removefav]);
      removeFromFavourites(product);
    }
  };

  const handleShareProduct = () => {
    let url = window.location.href.split('/');
    setCopiedUrl(`${url[0]}//${url[2]}/product/${open?.data?._id}`);
  };

  const getP = async () => {
    setIsLoading(true);
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${productId}`);
    setProductData(res.data);
    setIsLoading(false);
  };

  const getFilterData = () => {
    let filterFinal = [];
    let filterColors = [];
    let filterBrands = [];
    let filterMaterial = [];
    let duplicateProducts = productsDisplay;

    if (Object.keys(filterOption).length > 0) {
      if (filterOption.hasOwnProperty('color')) {
        filterColors = duplicateProducts.filter(product =>
          filterOption.color.includes(product.color)
        );
        filterFinal.push([...filterColors]);
      }
      if (filterOption.hasOwnProperty('brand')) {
        filterBrands = duplicateProducts.map(product => {
          if (product?.vendor_id?.name === filterOption.brand[0]) return product;
        });
        filterFinal.push([...filterBrands]);
      }
      if (
        (filterOption.hasOwnProperty('brand') && filterOption.brand === '') ||
        !filterOption.hasOwnProperty('brand')
      ) {
        setBrandClicked(false);
      }
      if (filterOption.hasOwnProperty('material')) {
        for (let i = 0; i < duplicateProducts.length; i++) {
          for (let j = 0; j < duplicateProducts[i].material.length; j++) {
            if (filterOption.material.includes(duplicateProducts[i].material[j])) {
              filterMaterial.push(duplicateProducts[i]);
            }
          }
        }
        filterFinal.push([...filterMaterial]);
      }
      filterFinal = filterFinal.flat();
      filterFinal = filterFinal.filter(
        (ele, ind) =>
          ind === filterFinal.findIndex(elem => elem?._id && ele?._id && elem._id === ele._id)
      );
      filterProducts([...filterFinal]);
    }
  };

  const handleCloseAnimation = () => {
    getFilterData();
  };

  const fetchFavouriteData = async () => {
    try {
      if (auth && auth.user) {
        if (likesChecked === true) {
          const getProject = async () => {
            let likedProducts = [];
            let likedProdIds = [];
            const res = await customerApiInstance.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
            );
            let project = { ...res.data };
            if (project && project.hasOwnProperty('product_reactions')) {
              likedProdIds = project.product_reactions.liked;
            }
            likedProducts = moodboardProductsData.filter(prd => likedProdIds.includes(prd._id));
            setSavedProductIds([...likedProdIds]);
            filterProducts([...likedProducts]);
          };
          getProject();
        } else {
          filterProducts([...moodboardProductsData]);
        }
      }
    } catch (err) {}
  };

  const updateNewMaterialFilter = mt => {
    if (!mt) return;
    if (!newFiltersState?.material) {
      // case 1: first time selecting material
      setNewFiltersState({
        ...newFiltersState,
        material: [mt]
      });
    }
    // not case 1: state contains previously selected materials
    else {
      const filterExists = newFiltersState.material.includes(mt);
      if (!filterExists) {
        // case 2: clicked material is not previously selected, hence adding to state
        setNewFiltersState({
          ...newFiltersState,
          material: [...newFiltersState.material, mt]
        });
      }
      // not case 2: clicked material is previously selected, hence removing from state
      else {
        if (newFiltersState.material.length === 1) {
          // case 3: clicked material is the only selected material
          let newFiltersStateCpy = {
            ...newFiltersState
          };
          delete newFiltersStateCpy.material;
          setNewFiltersState(newFiltersStateCpy);
        }
        // not case 3: state contains other materials
        else {
          setNewFiltersState({
            ...newFiltersState,
            material: newFiltersState.material.filter(material => material !== mt)
          });
        }
      }
    }
  };

  const updateNewColorFilter = clr => {
    if (!newFiltersState?.color) {
      // case 1: first time selecting color
      setNewFiltersState({
        ...newFiltersState,
        color: [clr]
      });
    }
    // not case 1: state contains previously selected colors
    else {
      const filterExists = newFiltersState.color.includes(clr);
      if (!filterExists) {
        // case 2: clicked color is not previously selected, hence adding to state
        setNewFiltersState({
          ...newFiltersState,
          color: [...newFiltersState.color, clr]
        });
      }
      // not case 2: clicked color is previously selected, hence removing from state
      else {
        if (newFiltersState.color.length === 1) {
          // case 3: clicked color is the only selected color
          let newFiltersStateCpy = {
            ...newFiltersState
          };
          delete newFiltersStateCpy.color;
          setNewFiltersState(newFiltersStateCpy);
        }
        // not case 3: state contains other colors
        else {
          setNewFiltersState({
            ...newFiltersState,
            color: newFiltersState.color.filter(color => color !== clr)
          });
        }
      }
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <div
        style={{
          backgroundColor: pageType == 'FINALIZE_CONCEPT' ? 'rgb(250, 250, 250)' : 'white',
          margin: '0px auto',
          width: '100%'
        }}>
        <div className={classnames(style.mainContainer, 'container')}>
          {dimension[0] > 500 ? (
            <div className="row-lg-12 row-sm-12 row-xs-12 d-flex">
              <>
                <div
                  className="col-md-3 col-lg-3 col-xs-12 p-0"
                  style={{
                    position: 'sticky',
                    top: '60px',
                    height: '100vh'
                  }}>
                  <div className="row mb-3 ml-1" style={{ paddingTop: '105px' }}>
                    <h5
                      style={{
                        fontFamily: 'Quicksand',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}>
                      Filters
                    </h5>
                    {(newFiltersState.brand ||
                      newFiltersState.material ||
                      newFiltersState.color ||
                      newFiltersState.arChecked ||
                      newFiltersState.likesChecked) && (
                      <button
                        className={style.filterButton}
                        style={{ height: '26px' }}
                        onClick={() => {
                          setNewFiltersState({});
                        }}>
                        <GrClose size={10} style={{ marginLeft: '5px' }} />
                        <span
                          style={{
                            color: 'black',
                            fontFamily: 'Quicksand',
                            fontSize: '14px',
                            marginLeft: '5px',
                            width: '50%'
                          }}>
                          Clear All
                        </span>
                      </button>
                    )}
                  </div>
                  {loading && (
                    <div>
                      <Loader
                        type="ThreeDots"
                        visible={true}
                        color="#000000"
                        height={50}
                        width={50}
                      />
                    </div>
                  )}
                  {!loading && (
                    <>
                      <div>
                        <Accordion allowZeroExpanded allowMultipleExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="d-flex flex-row justify-content-right align-items-right">
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <p
                                        className={style.filterHead}
                                        style={{ marginBottom: '10px' }}
                                        onClick={() => {
                                          setBrandExpanded(!brandExpanded);
                                        }}>
                                        Brand
                                      </p>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        <FontAwesomeIcon
                                          size={10}
                                          style={{
                                            alignItems: 'center',
                                            marginLeft: '10px'
                                          }}
                                          icon={
                                            brandExpanded === true ? faChevronDown : faChevronRight
                                          }
                                          onClick={() => {
                                            setBrandExpanded(!brandExpanded);
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              {brands &&
                                brands.map((brand, brandIndex) => (
                                  <div
                                    className="col p-0"
                                    style={{ marginRight: '10px' }}
                                    key={brandIndex}>
                                    <button
                                      className={
                                        newFiltersState && newFiltersState.brand === brand
                                          ? style1.listButton1
                                          : style1.listButton
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        fontFamily: 'Quicksand'
                                      }}
                                      onClick={() => {
                                        setBrandClicked(true);
                                        if (newFiltersState && newFiltersState.brand === brand) {
                                          let newFiltersStateCpy = {
                                            ...newFiltersState
                                          };
                                          delete newFiltersStateCpy.brand;
                                          setNewFiltersState(newFiltersStateCpy);
                                        } else {
                                          setNewFiltersState({
                                            ...newFiltersState,
                                            brand: brand
                                          });
                                        }
                                      }}>
                                      {brand}
                                    </button>
                                  </div>
                                ))}
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                      <div>
                        <Accordion allowZeroExpanded allowMultipleExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="d-flex flex-row justify-content-right align-items-right">
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <p
                                        className={style.filterHead}
                                        style={{ marginBottom: '10px' }}
                                        onClick={() => {
                                          setMaterialExpanded(!materialExpanded);
                                        }}>
                                        Material
                                      </p>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        <FontAwesomeIcon
                                          size={10}
                                          style={{
                                            alignItems: 'center',
                                            marginLeft: '10px'
                                          }}
                                          icon={
                                            materialExpanded === true
                                              ? faChevronDown
                                              : faChevronRight
                                          }
                                          onClick={() => {
                                            setMaterialExpanded(!materialExpanded);
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              {material &&
                                material.map((mt, materialIndex) => (
                                  <div
                                    className="col p-0"
                                    style={{ marginRight: '10px' }}
                                    key={materialIndex}>
                                    <button
                                      className={
                                        newFiltersState && newFiltersState?.material?.includes(mt)
                                          ? style1.listButton1
                                          : style1.listButton
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        fontFamily: 'Quicksand'
                                      }}
                                      onClick={() => {
                                        updateNewMaterialFilter(mt);
                                      }}>
                                      {mt}
                                    </button>
                                  </div>
                                ))}
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                      <div>
                        <Accordion allowZeroExpanded allowMultipleExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="d-flex flex-row justify-content-right align-items-right">
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <p
                                        className={style.filterHead}
                                        style={{ marginBottom: '10px' }}
                                        onClick={() => {
                                          setColorExpanded(!colorExpanded);
                                        }}>
                                        Color
                                      </p>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        <FontAwesomeIcon
                                          size={10}
                                          style={{
                                            alignItems: 'center',
                                            marginLeft: '10px'
                                          }}
                                          icon={
                                            colorExpanded === true ? faChevronDown : faChevronRight
                                          }
                                          onClick={() => {
                                            setColorExpanded(!colorExpanded);
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              {colors &&
                                colors.map((clr, colorIndex) => (
                                  <div
                                    className="col p-0"
                                    style={{ marginRight: '10px' }}
                                    key={colorIndex}>
                                    <button
                                      className={
                                        newFiltersState && newFiltersState?.color?.includes(clr)
                                          ? style1.listButton1
                                          : style1.listButton
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        fontFamily: 'Quicksand'
                                      }}
                                      onClick={() => {
                                        updateNewColorFilter(clr);
                                      }}>
                                      {clr}
                                    </button>
                                  </div>
                                ))}
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>

                      <div className="d-flex flex-row mt-2 hr" style={{ fontSize: '12px' }}>
                        <input
                          type="checkbox"
                          style={{
                            marginTop: '2px',
                            marginRight: '3px',
                            fontFamily: 'quicksand'
                          }}
                          checked={newFiltersState.likesChecked || false}
                          onClick={() => {
                            if (newFiltersState && newFiltersState.likesChecked) {
                              let newFiltersStateCpy = {
                                ...newFiltersState
                              };
                              delete newFiltersStateCpy.likesChecked;
                              setNewFiltersState(newFiltersStateCpy);
                            } else {
                              setNewFiltersState({
                                ...newFiltersState,
                                likesChecked: true,
                                arChecked: false
                              });
                            }
                          }}></input>
                        <span>My Likes</span>
                      </div>
                      <div className="d-flex flex-row mt-2 " style={{ fontSize: '12px' }}>
                        <input
                          type="checkbox"
                          style={{
                            marginTop: '2px',
                            marginRight: '3px',
                            fontFamily: 'quicksand'
                          }}
                          checked={newFiltersState.arChecked || false}
                          onClick={() => {
                            if (newFiltersState.arChecked) {
                              let newFiltersStateCpy = {
                                ...newFiltersState
                              };
                              delete newFiltersStateCpy.arChecked;
                              setNewFiltersState(newFiltersStateCpy);
                            } else {
                              setNewFiltersState({
                                ...newFiltersState,
                                arChecked: true,
                                likesChecked: false
                              });
                            }
                          }}></input>
                        <span>AR</span>
                      </div>
                    </>
                  )}
                </div>
              </>
              <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                <div className="row mb-3 ml-1">
                  <div className="col-9">
                    <p
                      className="mt-3"
                      style={{
                        cursor: 'pointer',
                        fontFamily: 'Quicksand',
                        fontSize: '20px',
                        textAlign: 'left',
                        fontWeight: 'bold'
                      }}
                      onClick={() => {
                        setBrandClicked(false);
                        setFilterOption({});
                      }}>
                      In-room Products
                    </p>
                  </div>
                  <div className="col-3 justify-content-end "></div>
                </div>

                {prodLoading && (
                  <div>
                    <Loader
                      type="ThreeDots"
                      visible={true}
                      color="#000000"
                      height={50}
                      width={50}
                    />
                  </div>
                )}
                {!prodLoading && renderColumn()}
              </div>
            </div>
          ) : (
            <>
              <div className={style.nav_container} style={{ paddingTop: '70px' }}>
                <div
                  className="row"
                  style={{
                    backgroundColor: '#F9FEFF',
                    padding: '8px 0px',
                    marginBottom: '16px'
                  }}>
                  <p
                    className="col-5 mb-0 d-flex justify-content-start"
                    style={{ fontWeight: 600, cursor: 'pointer' }}
                    onClick={() => setDrawer(true)}>
                    <img
                      src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Group+3000.png"
                      className={style.toggleImg}
                    />{' '}
                    Refine
                  </p>
                </div>
                <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                  <div className={style.typesSectionwom}>
                    <div className="row mt-5"></div>
                  </div>
                </div>

                <Drawer
                  anchor={'left'}
                  open={drawer}
                  docked={false}
                  onClose={() => setDrawer(false)}>
                  <div className={classnames(style.drawerContainer, ' mb-2 ml-2')}>
                    <>
                      <div className="row mt-3 mb-3 ml-1">
                        <h5
                          style={{
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            fontWeight: 'bold'
                          }}>
                          Filters
                        </h5>
                        <CloseOutlined
                          onClick={() => setDrawer(false)}
                          style={{ marginLeft: 'auto', marginRight: 0 }}
                        />
                        {(newFiltersState.brand ||
                          newFiltersState.material ||
                          newFiltersState.color ||
                          newFiltersState.arChecked ||
                          newFiltersState.likesChecked) && (
                          <button
                            className={style.filterButton}
                            style={{ height: '26px' }}
                            onClick={() => {
                              setDrawer(false);
                              setNewFiltersState({});
                            }}>
                            <GrClose size={10} style={{ marginLeft: '5px' }} />
                            <span
                              style={{
                                color: 'black',
                                fontFamily: 'Quicksand',
                                fontSize: '14px',
                                marginLeft: '5px',
                                width: '50%'
                              }}>
                              Clear All
                            </span>
                          </button>
                        )}
                      </div>
                      <div>
                        {loading && (
                          <div>
                            <Loader
                              type="ThreeDots"
                              visible={true}
                              color="#000000"
                              height={50}
                              width={50}
                            />
                          </div>
                        )}
                        {!loading && (
                          <>
                            <div>
                              <Accordion allowZeroExpanded allowMultipleExpanded>
                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <div className="d-flex flex-row justify-content-right align-items-right">
                                        <p
                                          className={style.filterHead}
                                          style={{ marginBottom: '10px' }}
                                          onClick={() => {
                                            setBrandExpanded(!brandExpanded);
                                          }}>
                                          Brand
                                        </p>
                                        <div>
                                          <FontAwesomeIcon
                                            size={10}
                                            style={{
                                              alignItems: 'center',
                                              marginLeft: '10px'
                                            }}
                                            icon={
                                              brandExpanded === true
                                                ? faChevronDown
                                                : faChevronRight
                                            }
                                            onClick={() => {
                                              setBrandExpanded(!brandExpanded);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    {brands &&
                                      brands.map((brand, brandIndex) => (
                                        <div
                                          className="col p-0"
                                          style={{ marginRight: '10px' }}
                                          keys={brandIndex}>
                                          <button
                                            className={
                                              newFiltersState && newFiltersState.brand === brand
                                                ? style1.listButton1
                                                : style1.listButton
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              fontFamily: 'Quicksand'
                                            }}
                                            onClick={() => {
                                              setBrandClicked(true);
                                              if (
                                                newFiltersState &&
                                                newFiltersState.brand === brand
                                              ) {
                                                let newFiltersStateCpy = {
                                                  ...newFiltersState
                                                };
                                                delete newFiltersStateCpy.brand;
                                                setNewFiltersState(newFiltersStateCpy);
                                              } else {
                                                setNewFiltersState({
                                                  ...newFiltersState,
                                                  brand: brand
                                                });
                                              }
                                            }}>
                                            {brand}
                                          </button>
                                        </div>
                                      ))}
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </Accordion>
                            </div>
                            <div>
                              <Accordion allowZeroExpanded allowMultipleExpanded>
                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <div className="d-flex flex-row justify-content-right align-items-right">
                                        <p
                                          className={style.filterHead}
                                          style={{ marginBottom: '10px' }}
                                          onClick={() => {
                                            setMaterialExpanded(!materialExpanded);
                                          }}>
                                          Material
                                        </p>
                                        <div>
                                          <FontAwesomeIcon
                                            size={10}
                                            style={{
                                              alignItems: 'center',
                                              marginLeft: '10px'
                                            }}
                                            icon={
                                              materialExpanded === true
                                                ? faChevronDown
                                                : faChevronRight
                                            }
                                            onClick={() => {
                                              setMaterialExpanded(!materialExpanded);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    {material &&
                                      material.map((mt, materialIndex) => (
                                        <div
                                          className="col p-0"
                                          style={{ marginRight: '10px' }}
                                          key={materialIndex}>
                                          <button
                                            className={
                                              newFiltersState &&
                                              newFiltersState?.material?.includes(mt)
                                                ? style1.listButton1
                                                : style1.listButton
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              fontFamily: 'Quicksand'
                                            }}
                                            onClick={() => {
                                              updateNewMaterialFilter(mt);
                                            }}>
                                            {mt}
                                          </button>
                                        </div>
                                      ))}
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </Accordion>
                            </div>
                            <div>
                              <Accordion allowZeroExpanded allowMultipleExpanded>
                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <div className="d-flex flex-row justify-content-right align-items-right">
                                        <p
                                          className={style.filterHead}
                                          style={{ marginBottom: '10px' }}
                                          onClick={() => {
                                            setColorExpanded(!colorExpanded);
                                          }}>
                                          Color
                                        </p>
                                        <div>
                                          <FontAwesomeIcon
                                            size={10}
                                            style={{
                                              alignItems: 'center',
                                              marginLeft: '10px'
                                            }}
                                            icon={
                                              colorExpanded === true
                                                ? faChevronDown
                                                : faChevronRight
                                            }
                                            onClick={() => {
                                              setColorExpanded(!colorExpanded);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    {colors &&
                                      colors.map((clr, colorIndex) => (
                                        <div
                                          className="col p-0"
                                          style={{ marginRight: '10px' }}
                                          key={colorIndex}>
                                          <button
                                            className={
                                              newFiltersState &&
                                              newFiltersState?.color?.includes(clr)
                                                ? style1.listButton1
                                                : style1.listButton
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              fontFamily: 'Quicksand'
                                            }}
                                            onClick={() => {
                                              updateNewColorFilter(clr);
                                            }}>
                                            {clr}
                                          </button>
                                        </div>
                                      ))}
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </Accordion>
                            </div>
                            <div className="d-flex flex-row mt-2" style={{ fontSize: '12px' }}>
                              <input
                                type="checkbox"
                                style={{
                                  marginTop: '2px',
                                  marginRight: '3px'
                                }}
                                checked={newFiltersState.likesChecked || false}
                                onClick={() => {
                                  if (newFiltersState && newFiltersState.likesChecked) {
                                    let newFiltersStateCpy = {
                                      ...newFiltersState
                                    };
                                    delete newFiltersStateCpy.likesChecked;
                                    setNewFiltersState(newFiltersStateCpy);
                                  } else {
                                    setNewFiltersState({
                                      ...newFiltersState,
                                      likesChecked: true,
                                      arChecked: false
                                    });
                                  }
                                }}></input>
                              <span>My Likes</span>
                            </div>
                            <div className="d-flex flex-row mt-2 " style={{ fontSize: '12px' }}>
                              <input
                                type="checkbox"
                                style={{
                                  marginTop: '2px',
                                  marginRight: '3px',
                                  fontFamily: 'quicksand'
                                }}
                                checked={newFiltersState.arChecked || false}
                                onClick={() => {
                                  if (newFiltersState.arChecked) {
                                    let newFiltersStateCpy = {
                                      ...newFiltersState
                                    };
                                    delete newFiltersStateCpy.arChecked;
                                    setNewFiltersState(newFiltersStateCpy);
                                  } else {
                                    setNewFiltersState({
                                      ...newFiltersState,
                                      arChecked: true,
                                      likesChecked: false
                                    });
                                  }
                                }}></input>
                              <span>AR</span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </div>
                </Drawer>
              </div>
              <div className="row">
                <div className="col-sm-12 col-xs-12">
                  {brandClicked && (
                    <div>
                      <div className="row">
                        <div
                          className=" col-12 d-flex flex-column justify-content-center align-items-start"
                          style={{
                            paddingTop: '20px',
                            paddingLeft: '5vw'
                          }}>
                          <h1 style={{ fontWeight: 500, fontSize: '20px' }}>{currentBrand.name}</h1>
                          <p>{currentBrand.description} </p>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-12"
                          style={{
                            backgroundImage: `url(${brandImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            height: 'auto',
                            maxWidth: '100%',
                            overflowX: 'hidden'
                          }}></div>
                      </div>
                    </div>
                  )}

                  <div
                    className="row mt-3 mb-3 ml-1"
                    style={{ marginTop: dimension[0] < 500 ? '25px' : '' }}>
                    <div className="col-6 ">
                      <p
                        className={classnames(style.product, 'mt-1')}
                        style={{
                          cursor: 'pointer',
                          fontFamily: 'Quicksand',
                          fontSize: '20px',
                          textAlign: 'left',
                          fontWeight: 'bold'
                        }}
                        onClick={() => {
                          setBrandClicked(false);
                          setFilterOption({});
                        }}>
                        In-room Products
                      </p>
                    </div>
                    <div className="col-6 justify-content-end "></div>
                  </div>

                  <div className="container-fluid ml-lg-3 pl-lg-2 ml-md-3 pl-md-2">
                    {prodLoading && (
                      <div>
                        <Loader
                          type="ThreeDots"
                          visible={true}
                          color="#000000"
                          height={50}
                          width={50}
                        />
                      </div>
                    )}
                    {!prodLoading && renderColumn()}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={qrOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <Fade in={qrOpen}>
            <div
              className={classnames(classes.papers, style.modalContainer)}
              style={{ height: dimension[0] > 500 ? '' : '100vh' }}>
              <button className={style.closeBtn} onClick={() => handleQrClose()}>
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/close.png"
                  className={style.closeBtn}
                />
              </button>
              <div className="row">
                <div className="col-6">
                  {dimension[0] > 600 ? (
                    <center>
                      <div>
                        Please, scan the QR code below in your phone/tablet to enter the AR
                        experience
                      </div>
                      <div style={{ marginTop: '20px' }}>
                        <QRCodeSVG
                          value={`${process.env.REACT_APP_CUSTOMER_URL}/3d-model/${productId}`}
                        />
                      </div>
                    </center>
                  ) : (
                    <div style={{ margin: 20 }}>
                      {isLoading ? (
                        <Spinner as="span" size="sm" role="status" animation="border" />
                      ) : productData && Object.keys(productData).length ? (
                        <>
                          <div>Product: {productData && productData.name}</div>
                          <div className="d-flex justify-content-center">
                            {productData && productData['3d_models'].glbFileUrl ? (
                              <model-viewer
                                src={productData['3d_models'].glbFileUrl}
                                alt="A 3D model of an astronaut"
                                ar
                                ar-modes="webxr scene-viewer quick-look"
                                environment-image="neutral"
                                auto-rotate
                                camera-controls
                                style={{
                                  height: '400px'
                                }}
                                shadow-intensity="1"
                                ios-src={productData['3d_models'].usdzFileUrl}
                                ar-scale="fixed"
                              />
                            ) : (
                              <p
                                className="d-flex align-items-center"
                                style={{
                                  height: '400px'
                                }}>
                                USDZ file is not supported by browsers, please upload a GLB file to
                                preview 3D model
                              </p>
                            )}
                          </div>
                        </>
                      ) : (
                        <div>Product doesn't exist</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={qrOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <Fade in={qrOpen}>
            <div
              className={classnames(classes.papers, style.modalContainer)}
              style={{ height: dimension[0] > 500 ? '' : '100vh' }}>
              <button className={style.closeBtn} onClick={() => handleQrClose()}>
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/close.png"
                  className={style.closeBtn}
                />
              </button>
              <div className="row">
                <div className="col-6"></div>

                <div className="col-6">
                  <div className={style.QrLeft}>
                    <img
                      src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Mask+Group+20.png"
                      style={{ height: '100%' }}
                    />
                    <div className={style.txtSec}>
                      <h4>Scan here to view selected item in AR mode</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open.modal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <Fade in={open.modal}>
            <div
              className={classnames(classes.paper, style.modalContainer)}
              style={{ height: dimension[0] > 500 ? '' : '100vh' }}>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="d-flex justify-content-around align-items-center flex-row"
                  style={{ display: 'flex' }}>
                  <div
                    className={style.heart}
                    style={{
                      marginTop: '-1%',
                      backgroundPosition:
                        auth && auth.user && savedProductIds?.includes(open.data?._id)
                          ? 'right'
                          : 'left',
                      display: dimension[0] > 500 ? '' : 'none'
                    }}
                    onClick={() => {
                      setAnimation(!animation);
                      handleFavourites(open.data);
                    }}></div>

                  <div
                    style={{
                      marginTop: '2%',
                      marginLeft: '10px'
                    }}>
                    {' '}
                    <Shareurl fabdirection="right" copiedUrl={copiedUrl} />
                  </div>
                </div>
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpen({ ...open, modal: false })}
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2420.svg'
                  }
                  alt=""
                />
              </div>
              <input
                ref={copy}
                type="hidden"
                value={`${window.location.host}/product/${open.data?._id}`}
              />
              <div
                className="container"
                style={{
                  width: dimension[0] > 500 ? '80%' : '100%',
                  marginTop: dimension[0] < 500 ? '-1%' : '5%',
                  padding: dimension[0] > 500 ? '' : '0px'
                }}>
                <div className="row">
                  <div
                    className="col-sm-12 col-lg-5"
                    style={{ alignItems: 'center', display: 'flex' }}>
                    <img
                      src={open.data?.image[0]}
                      style={{ width: '100%', marginTop: '10px' }}
                      alt=""
                    />
                  </div>
                  <div
                    className="row"
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      zIndex: '1000',
                      marginTop: '-1.8em',
                      marginLeft: '0',
                      display: dimension[0] < 500 ? '' : 'none'
                    }}>
                    <div
                      className={style.heart}
                      style={{
                        marginTop: '2%',
                        backgroundPosition:
                          auth && auth.user && savedProductIds?.includes(open.data?._id)
                            ? 'right'
                            : 'left',
                        display: dimension[0] < 500 ? '' : 'none',
                        backgroundColor: 'white'
                      }}
                      onClick={() => {
                        setAnimation(!animation);
                        handleFavourites(open.data);
                      }}></div>
                    <img
                      onClick={() => {
                        handleShareProduct();
                        setModal({ ...modal, modal: true, name: 'share' });
                      }}
                      style={{ display: dimension[0] < 500 ? '' : 'none' }}
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2425.svg'
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className="col-sm-12 col-lg-7"
                    style={{
                      textAlign: dimension[0] < 500 ? 'center' : ''
                    }}>
                    <h4 className={style.headerName}>{open.data?.name}</h4>
                    <h4 className={style.priceTxt}>
                      <b>Rs. {new Intl.NumberFormat('en-IN').format(open.data?.pixel_price)} </b>
                      <span style={{ opacity: 0.7 }}>
                        <s>Rs. {new Intl.NumberFormat('en-IN').format(open.data?.price)}</s>
                      </span>{' '}
                    </h4>
                    <p className={style.TxtClr}>Color: {open.data?.color}</p>

                    <p className={style.TxtClr}>Material: {open.data?.material}</p>
                    <p className={style.TxtClr}>
                      Dimensions: {open.data?.dimensions.l} {" '' "}L * {open.data?.dimensions.b}{' '}
                      {" '' "}W{' '}
                    </p>
                    {open?.data && open.data['3d_models']?.glbFileUrl ? (
                      <button
                        onClick={() =>
                          dimension[0] > 500
                            ? handleQr(currentProduct?._id)
                            : mobileHandle3D(currentProduct?._id)
                        }
                        className={style.arPart}>
                        <img
                          src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Arpath.png"
                          className={style.arImg}
                        />
                        <p className={style.view}>View in AR</p>
                      </button>
                    ) : (
                      <>
                        <form ref={form} onSubmit={sendEmail}>
                          {/* <button
                            type="submit"
                            value="Send"
                            className={style.arPart}
                          >
                            <img
                              src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Arpath.png"
                              className={style.arImg}
                            />
                            <p className={style.view}>Request in AR</p>
                          </button> */}
                        </form>

                        <form
                          ref={form}
                          onSubmit={sendEmail}
                          style={{
                            visibility: 'hidden',
                            position: 'absolute'
                          }}>
                          <input type="text" value={currentProduct?.name} name="name" />
                          <input type="text" value={currentProduct?._id} name="productId" />
                          <input type="text" value={currentProduct?.category} name="category" />
                          <input type="text" value={currentProduct?.price} name="price" />
                          <input type="text" value={userName} name="userName" />
                        </form>
                      </>
                    )}

                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <button
                        className={style.buttonWhite}
                        onClick={() => history.push(`/product/${open.data?._id}`)}
                        style={{
                          whiteSpace: dimension[0] < 500 ? 'nowrap' : ''
                        }}>
                        View Full Details
                      </button>
                      <button
                        className={classnames(style.buttonBlack, 'ml-2')}
                        onClick={() => {
                          addToCart(open.data);
                          storeCartCount(getCartDataCount(auth));
                        }}>
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modal.name === 'share'}
          onClose={() => setModal({ ...modal, modal: false, name: '' })}
          closeAfterTransition>
          <div className={style.modalImage}>
            <div className={classnames(style.ShareModalHeader, 'justify-content-end h-auto')}>
              <div
                className={classnames('mr-2 mt-1')}
                style={{ cursor: 'pointer' }}
                onClick={() => setModal({ ...modal, modal: false, name: '' })}>
                <Icon.X
                  className=""
                  style={{
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px'
                  }}
                  onClick={() => setModal({ ...modal, modal: false, name: '' })}
                />
              </div>
            </div>
            <div className={classnames(style.ShareModalHeader, 'justify-content-center h-auto')}>
              <h4 className="mt-1">Share Your Products</h4>
            </div>
            <div className={classnames('row', style.modalRow_share)}>
              <div className="w-100 py-4 d-flex justify-content-between mx-5">
                <div>
                  <FacebookShareButton
                    url={copiedUrl}
                    title={open?.data?.name}
                    metadescription={open?.data?.description}
                    image={open?.data?.image}
                    quote={'CampersTribe - World is yours to explore facebook'}
                    hashtag="#PixelandMordar">
                    <FacebookIcon
                      size={dimension[0] > 500 ? 36 : 32}
                      round={true}
                      bgStyle={{ fill: 'black' }}
                    />
                  </FacebookShareButton>
                </div>
                <div>
                  <TwitterShareButton
                    url={copiedUrl}
                    title={open?.data?.name}
                    description={open?.data?.description}
                    image={open?.data?.image[0]}
                    quote={'CampersTribe - World is yours to explore'}
                    hashtag="#PixelandMordar">
                    <TwitterIcon
                      size={dimension[0] > 500 ? 36 : 32}
                      round={true}
                      bgStyle={{ fill: 'black' }}
                    />
                  </TwitterShareButton>
                </div>
                <div>
                  <EmailShareButton url={copiedUrl}>
                    <EmailIcon
                      size={dimension[0] > 500 ? 36 : 32}
                      round={true}
                      bgStyle={{ fill: 'black' }}
                    />
                  </EmailShareButton>
                </div>
                <div>
                  <WhatsappShareButton
                    separator=":: "
                    url={copiedUrl}
                    title={open?.data?.name}
                    description={open?.data?.description}
                    image={
                      'https://www.figma.com/file/P259KeFpapUk2Tkvs66SJ8/thumbnail?ver=thumbnails/a9c21f06-a852-45c6-bf2d-c403a7646887'
                    }
                    quote="CampersTribe - World is yours to explore facebbok"
                    hashtag="#PixelandMordar">
                    <WhatsappIcon
                      size={dimension[0] > 500 ? 36 : 32}
                      round={true}
                      bgStyle={{ fill: 'black' }}
                    />
                  </WhatsappShareButton>
                </div>
              </div>

              <div className="my-3 mx-3" style={{ width: '90%' }}>
                <div className={style.shareLink}>
                  <p className={classnames(style.shareContent, 'p-2 mr-2')}>{copiedUrl}</p>
                  <button
                    className={style.copyLinkBtn}
                    onClick={() => {
                      navigator.clipboard.writeText(copiedUrl);
                      setSnack(true);
                      setSnackMessage('Product link successfully copied');
                    }}>
                    Copy Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {modalOpen && (
          <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(ProductList));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);
  useEffect(() => {}, [screenSize]);
  return screenSize;
}
