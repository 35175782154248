import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './MyBoard.module.css';
import { Modal } from 'react-bootstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Loader from 'react-loader-spinner';
import * as Icon from 'react-bootstrap-icons';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon
} from 'react-share';
import placeholderImage from '../../assets/images/placeholder/placeholder.png';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyBoards = ({ history, auth }) => {
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  let [boards, setBoards] = useState([]);
  const [currentBoard, setCurrentBoard] = useState({});
  const [snackMessage, setSnackMessage] = useState('');
  const [snack, setSnack] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState('');
  const [anchorEll, setAnchorEll] = React.useState(null);
  const [create, setCreate] = React.useState(false);
  const [alert1, setAlert1] = useState(true);
  const [loading, setLoading] = useState(false);
  const alertRef = useRef(alert1);
  alertRef.current = alert1;

  const Msg1 = ({ closeToast }) => (
    <>
      <div>Board Deleted, do you want to undo?</div>
      <div className="row mt-4 mb-0 justify-content-center">
        <div className="col-6">
          <button
            onClick={() => {
              setAlert1(true);
              finalDeleteBoard();
            }}
            className={style.cancelBtn}>
            No
          </button>
        </div>
        <div className="col-6">
          <button
            className={style.deleteBtn}
            onClick={() => {
              setAlert1(false);
              undoBoard();
              closeToast();
            }}>
            Yes
          </button>
        </div>
      </div>
    </>
  );

  const handleClickHeader = event => {
    setAnchorEll(event.currentTarget);
  };

  const handleClose = (value, data1) => {
    setModal({ ...modal, modal: true, name: value, id: data1.title });
    setAnchorEll(null);
  };

  const dimension = useMediaQuery();

  const [currentData, setCurrentData] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let boards1 = [];
    setLoading(true);
    const fetchBoardData = async () => {
      try {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
        );
        let result = [...res.data];
        if (result && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            boards1.push({
              id: result[i]._id,
              moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
              title: result[i].name,
              moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
              products: result[i].products ? [...result[i].products] : []
            });
          }
        }
        if (auth && auth.user) {
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
          );
          if (res.data && res.data.favProducts && res.data.favProducts.length > 0) {
            boards1.push({
              title: 'Things I Like',
              products: res.data.favProducts
            });
          }
        }
        setBoards([...boards1]);
        setLoading(false);
      } catch (err) {}
    };
    fetchBoardData();
  }, []);

  const handleShareBoard = boardId => {
    const url1 = `${window.location.host}/board-view/${boardId}`;
    setCopiedUrl(url1);
    setSnack(true);
    setSnackMessage('Board link successfully copied');
  };
  useEffect(() => {}, [boards]);

  const renderColumns = () => {
    let cols = [];
    let rows = [];

    boards.map((data, index) => {
      cols.push(
        <>
          <div
            className={classnames('col-lg-6 col-md-12 col-sm-12 ')}
            style={{
              height: dimension[0] > 800 ? '70vh' : '45vh',
              marginTop: '5rem'
            }}>
            <div className={classnames('row h-100 m-auto')} style={{ width: '95%' }}>
              <div className={classnames('col-4 d-flex flex-column p-0')}>
                <div style={{ height: '50%' }}>
                  <img
                    alt=""
                    src={
                      data.products?.length > 0 && data.products[0]?.image[0].length > 0
                        ? data.products[0].image[0]
                        : placeholderImage
                    }
                    onClick={() => {
                      if (data.id) {
                        history.push({
                          pathname: `/board-view/${data.id}`,
                          state: data
                        });
                      } else {
                        history.push({
                          pathname: `/board-view/${index}`,
                          state: data
                        });
                      }
                    }}
                    className={style.spaceGirdImg}
                  />
                </div>
                <div style={{ height: '50%', paddingTop: '0.5rem' }}>
                  <img
                    alt=""
                    src={
                      data.products?.length > 0 && data.products[1]?.image[0].length > 0
                        ? data.products[1].image[0]
                        : placeholderImage
                    }
                    onClick={() => {
                      if (data.id) {
                        history.push({
                          pathname: `/board-view/${data.id}`,
                          state: data
                        });
                      } else {
                        history.push({
                          pathname: `/board-view/${index}`,
                          state: data
                        });
                      }
                    }}
                    className={style.spaceGirdImg}
                  />
                </div>
              </div>
              <div className={classnames('col-4 d-flex flex-column pl-2 pr-2')}>
                <div style={{ height: '60%' }}>
                  <img
                    alt=""
                    src={
                      data.products?.length > 0 && data.products[2]?.image[0].length > 0
                        ? data.products[2].image[0]
                        : placeholderImage
                    }
                    onClick={() => {
                      if (data.id) {
                        history.push({
                          pathname: `/board-view/${data.id}`,
                          state: data
                        });
                      } else {
                        history.push({
                          pathname: `/board-view/${index}`,
                          state: data
                        });
                      }
                    }}
                    className={style.spaceGirdImg}
                  />
                </div>
                <div style={{ height: '40%', paddingTop: '0.5rem' }}>
                  <img
                    alt=""
                    src={
                      data.products?.length > 0 && data.products[3]?.image[0].length > 0
                        ? data.products[3].image[0]
                        : placeholderImage
                    }
                    onClick={() => {
                      if (data.id) {
                        history.push({
                          pathname: `/board-view/${data.id}`,
                          state: data
                        });
                      } else {
                        history.push({
                          pathname: `/board-view/${index}`,
                          state: data
                        });
                      }
                    }}
                    className={style.spaceGirdImg}
                  />
                </div>
              </div>
              <div className={classnames('col-4 d-flex flex-column p-0 ')}>
                <div style={{ height: '50%' }}>
                  <img
                    alt=""
                    src={
                      data.products?.length > 0 && data.products[4]?.image[0].length > 0
                        ? data.products[4].image[0]
                        : placeholderImage
                    }
                    onClick={() => {
                      if (data.id) {
                        history.push({
                          pathname: `/board-view/${data.id}`,
                          state: data
                        });
                      } else {
                        history.push({
                          pathname: `/board-view/${index}`,
                          state: data
                        });
                      }
                    }}
                    className={style.spaceGirdImg}
                  />
                </div>
                <div style={{ height: '50%', paddingTop: '0.5rem' }}>
                  <img
                    alt=""
                    src={
                      data.products?.length > 0 && data.products[5]?.image[0].length > 0
                        ? data.products[5].image[0]
                        : placeholderImage
                    }
                    onClick={() => {
                      if (data.id) {
                        history.push({
                          pathname: `/board-view/${data.id}`,
                          state: data
                        });
                      } else {
                        history.push({
                          pathname: `/board-view/${index}`,
                          state: data
                        });
                      }
                    }}
                    className={style.spaceGirdImg}
                  />
                </div>
              </div>
            </div>
            <div
              className="row justify-content-between mt-1"
              style={{ width: '95%', margin: 'auto' }}>
              <div className="col-xs-auto col-md-6 col-8 p-0 ">
                <h6 className={style.header}>{data.title}</h6>
              </div>
              <div className="col-xs-auto col-md-6 col-4 p-0  text-right">
                {dimension[0] > 500 ? (
                  <div className={classnames(style.menus)} style={{ width: '63%', float: 'right' }}>
                    <div className="text-left">
                      <p
                        onClick={() => {
                          if (data.hasOwnProperty('id') && data.id) {
                            setCurrentBoard({ ...data });
                            setModal({
                              ...modal,
                              modal: true,
                              name: 'edit',
                              id: data.title
                            });
                          } else {
                            setSnack(true);
                            setSnackMessage('This board cannot be editted');
                          }
                        }}>
                        Edit
                      </p>
                    </div>
                    <div className="text-left">
                      <p
                        onClick={() => {
                          setCurrentBoard({ ...data });
                          if (data.hasOwnProperty('id') && data.id) {
                            handleShareBoard(data.id);
                          } else {
                            handleShareBoard(index);
                          }
                          setModal({ ...modal, modal: true, name: 'share' });
                        }}>
                        Share
                      </p>
                    </div>
                    <div className="text-left">
                      <p
                        onClick={() => {
                          if (data.hasOwnProperty('id') && data.id) {
                            setCurrentBoard({ ...data });
                            setModal({
                              ...modal,
                              modal: true,
                              name: 'delete',
                              id: data.title
                            });
                          } else {
                            setSnack(true);
                            setSnackMessage('This board cannot be deleted');
                          }
                        }}>
                        Delete
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="justify-content-center">
                    <img
                      id={`menu-id${index}`}
                      style={{
                        transform: dimension[0] > 500 ? 'translateY(-80%)' : '',
                        marginTop: dimension[0] > 500 ? '' : '-15px'
                      }}
                      aria-controls="simple-menu-id"
                      aria-haspopup="true"
                      onClick={e => {
                        setCurrentBoard({ ...data });
                        handleClickHeader(e);
                      }}
                      src={'https://d3izcfql7lesks.cloudfront.net/Group_2980_dvwzmf.png'}
                      alt=""
                    />
                    <Menu
                      id={`simple-menu-id${index}`}
                      anchorEl={anchorEll}
                      keepMounted
                      dense={false}
                      open={Boolean(anchorEll)}
                      onClose={handleClose}
                      style={{ borderRadius: '20px' }}>
                      <MenuItem
                        className="justify-content-center"
                        style={{ width: '90px' }}
                        onClick={() => {
                          if (currentBoard.hasOwnProperty('id') && currentBoard.id) {
                            handleClose('edit', currentBoard);
                          } else {
                            setSnack(true);
                            setSnackMessage('This board cannot be editted');
                          }
                        }}>
                        Edit
                      </MenuItem>
                      <MenuItem
                        className="justify-content-center"
                        style={{ width: '90px' }}
                        onClick={() => {
                          if (currentBoard.hasOwnProperty('id') && currentBoard.id) {
                            handleShareBoard(currentBoard.id);
                          } else {
                            handleShareBoard(index);
                          }
                          handleClose('share', currentBoard);
                        }}>
                        Share
                      </MenuItem>
                      <MenuItem
                        className="justify-content-center"
                        style={{ width: '90px' }}
                        onClick={() => {
                          if (currentBoard.hasOwnProperty('id') && currentBoard.id) {
                            handleClose('delete', currentBoard);
                          } else {
                            setSnack(true);
                            setSnackMessage('This board cannot be deleted');
                          }
                        }}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      );
    });

    if (cols.length) {
      rows.push(
        <div className="row" key={rows.length}>
          {cols}
        </div>
      );
    }
    return rows;
  };
  const handleEditBoardName = async (editTitle, currentBoardData) => {
    const titleUpdate = {
      name: editTitle
    };

    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${currentBoardData.id}`,
        titleUpdate
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        let index = boards.findIndex(brd => brd.id === currentBoardData.id);
        if (index >= 0) {
          boards[index].title = editTitle;
        }
        setBoards([...boards]);
        setModal({ ...modal, modal: false, name: '' });
        setSnack(true);
        setSnackMessage('Board renamed successfully');
      }
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };

  const getMoodBoardImage = currentBoard => {
    if (Object.keys(currentBoard).length && currentBoard.hasOwnProperty('moodBoard')) {
      return currentBoard.moodBoard ? currentBoard.moodBoard.preview_url : '';
    } else {
      return ' ';
    }
  };

  const finalDeleteBoard = async () => {
    if (alertRef.current === true) {
      try {
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/delete/${currentBoard.id}`
        );
        if (res.data && Object.keys(res.data).length !== 0) {
          setCurrentBoard({});
          if (res.data && Object.keys(res.data).length !== 0) {
            let boards1 = boards.filter(brd => brd.id !== currentBoard.id);
            setBoards([...boards1]);
          }
          setModal({ ...modal, modal: false, name: '' });
          setSnack(true);
          setSnackMessage('Board deleted successfully');
        }
        setModal({ ...modal, name: '' });
      } catch (err) {
        setSnack(true);
        setSnackMessage(err);
      }
    }
  };
  const deleteBoard = async () => {
    try {
      let boards1 = boards.filter(brd => brd.id !== currentBoard.id);
      setBoards([...boards1]);
      setAlert1(true);
      setTimeout(() => {
        finalDeleteBoard();
      }, 6000);
      setModal({ ...modal, modal: false, name: '' });
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };

  const undoBoard = async () => {
    setCurrentBoard({ ...currentBoard });
    setModal({ ...modal, modal: false, name: '' });
  };

  const createNewBoard = async () => {
    const addToBoardData = {
      name: currentData,
      user_id: auth.user._id
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/add`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setBoards(prev => [...prev, { id: res.data._id, title: currentData }]);
        setModal({ ...modal, modal: false, name: '' });
        setSnack(true);
        setSnackMessage('Board created successfully');
      }
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header />
      <div>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center "
            style={{ minHeight: '100vh' }}>
            <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
          </div>
        ) : (
          <>
            <div
              className={classnames('container', style.container)}
              style={{ marginTop: '75px', marginBottom: '15vh' }}>
              {renderColumns()}
            </div>
            <button
              style={{ zIndex: create && 10000, position: create && 'fixed' }}
              className={style.button}
              onClick={() => setCreate(!create)}>
              <Icon.PlusLg className="mr-2" />
              Create
            </button>
          </>
        )}
      </div>
      {create && (
        <div
          className={style.fullScreen}
          onClick={() => {
            setCreate(!create);
          }}>
          <div>
            <div className={style.fullScreen_top}>
              <button
                className={style.buttonWhite}
                onClick={() => {
                  history.push('/project');
                }}>
                Design Project
              </button>
            </div>
            <div className={style.fullScreen_bottom}>
              <button
                className={style.buttonWhite}
                onClick={() => setModal({ ...modal, modal: true, name: 'create' })}>
                Inspiration Board
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'create'}
        onHide={() => setModal({ ...modal, modal: false, name: '' })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalHeader}>
          <h4 className={style.createBoard}>Create Your Board</h4>
          <img
            className="mr-5 mt-4"
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setModal({ ...modal, modal: false, name: '' })}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>

        <div className={classnames('row justify-content-center', style.modalRow)}>
          <div className={classnames('col-md-4 col-xs-12', style.imageCol)}></div>
          <div className={classnames('col-md-8 col-xs-12', style.createCol)}>
            <h6>Board Name</h6>
            <input
              type="text"
              placeholder="Enter Board name"
              onChange={e => setCurrentData(e.target.value)}
            />
            <button
              className={style.createBoardButton}
              onClick={() => {
                createNewBoard();
              }}>
              Create Board
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'share'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        dialogClassName={classnames(style.modal, 'd-flex justify-content-center')}
        keyboard={false}
        size="sm">
        <div className={style.modalImage}>
          <div className={classnames(style.ShareModalHeader, 'justify-content-end h-auto')}>
            <div
              className={classnames('mr-2 mt-1')}
              style={{ cursor: 'pointer' }}
              onClick={() => setModal({ ...modal, modal: false, name: '' })}>
              <Icon.X
                className=""
                style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}
                onClick={() => setModal({ ...modal, modal: false, name: '' })}
              />
            </div>
          </div>
          <div className={classnames(style.ShareModalHeader, 'justify-content-center h-auto')}>
            <h4 className="mt-1">Share Your Board</h4>
          </div>
          <div className={classnames('row', style.modalRow_share)}>
            <div className="w-100 py-4 d-flex justify-content-between mx-5">
              <div>
                <FacebookShareButton url={copiedUrl}>
                  <FacebookIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </FacebookShareButton>
              </div>
              <div>
                <TwitterShareButton url={copiedUrl}>
                  <TwitterIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </TwitterShareButton>
              </div>
              <div>
                <EmailShareButton url={copiedUrl}>
                  <EmailIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </EmailShareButton>
              </div>
              <div>
                <WhatsappShareButton url={copiedUrl}>
                  <WhatsappIcon
                    size={dimension[0] > 500 ? 36 : 32}
                    round={true}
                    bgStyle={{ fill: 'black' }}
                  />
                </WhatsappShareButton>
              </div>
            </div>
            <div className="my-3 mx-3" style={{ width: '90%' }}>
              <div className={style.shareLink}>
                <p className={classnames(style.shareContent, 'p-2 mr-2')}>{copiedUrl}</p>
                <button className={style.copyLinkBtn} onClick={() => {}}>
                  Copy Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'delete'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={classnames('row justify-content-center', style.modalRow_delete)}>
          <div className=" row justify-content-center w-100 mt-5" style={{ height: '50px' }}>
            <span className={style.deleteConfirm}>Are you sure you want to delete your board?</span>
          </div>
          <div className="row justify-content-center" style={{ height: '100px' }}>
            <div className="col-6">
              <button onClick={() => setModal({ ...modal, name: '' })} className={style.cancelBtn}>
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                className={style.deleteBtn}
                onClick={() => {
                  deleteBoard();
                  toast(<Msg1 />);
                }}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'undo'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={classnames('row justify-content-center', style.modalRow_delete)}>
          <div className=" row justify-content-center w-100 mt-5" style={{ height: '50px' }}>
            <span className={style.deleteConfirm}>The board has been deleted</span>
          </div>
          <div className=" row justify-content-center w-100">
            <span className={style.deleteInfo}>do you want to undo the last delete?</span>
          </div>
          <div className="row justify-content-center" style={{ height: '100px' }}>
            <div className="col-6">
              <button
                onClick={() => {
                  finalDeleteBoard();
                }}
                className={style.cancelBtn}>
                No
              </button>
            </div>
            <div className="col-6">
              <button
                className={style.deleteBtn}
                onClick={() => {
                  undoBoard();
                }}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'edit'}
        onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalHeader}>
          <h4 className={style.editBoard}>Edit Your Board</h4>
          <img
            className={`${dimension[0] > 500 ? 'mr-5' : 'mr-3'} mt-4`}
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setModal({ ...modal, modal: false, name: '' })}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>
        <div className={classnames('row justify-content-center', style.modalRow)}>
          <div className={classnames('col-md-4 col-xs-12 pt-5', style.imageCol)}>
            <img src={getMoodBoardImage(currentBoard)} style={{ width: '100%' }} alt="" />
          </div>
          <div className={classnames('col-md-8 col-xs-12', style.createCol)}>
            <div className={style.wholeBoards}>
              <h6>Board Name</h6>
              <input
                type="text"
                placeholder="Enter Board name"
                defaultValue={boards.find(d => d.title === modal.id)?.title || ''}
                onChange={e => setCurrentData(e.target.value)}
              />
              <button
                className={style.saveButton}
                onClick={() => {
                  handleEditBoardName(currentData, currentBoard);
                }}>
                Edit Board
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {!create && <Footer />}
    </>
  );
};

MyBoards.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps)(withRouter(MyBoards));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
