import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import style from './Signup.module.css';
import classnames from 'classnames';
import { registerUser, loginUser, clearError } from '../../store/actions/authapi';
import { withRouter } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gooleLogo from '../../assets/images/googlelogo.png';
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-web-js/recipe/thirdparty';
import ConsoleHelper from '../../utils/ConsoleHelper';

const Signup = ({ auth, history, registerUser, clearError, location, questionnaireData }) => {
  const dispatch = useDispatch();
  const history1 = useHistory();
  const [display, setDisplay] = useState('signup');
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [inputs, setInputs] = useState({});
  const [login] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  const [showPass, setShowPass] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState(false);
  const [emailUsed, setEmailUsed] = useState('');
  const [error, setError] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  async function googleSignInClicked() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        providerId: 'google',

        // This is where Google should redirect the user back after login or error.
        // This URL goes on the Google's dashboard as well.

        authorisationURL: `${process.env.REACT_APP_URL}/auth/callback/google`
      });

      // we redirect the user to google for auth.
      window.location.assign(authUrl);
    } catch (err) {
      ConsoleHelper(err);
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  const handleChangeSignup = e => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'phone_number') {
      const onlyNums = value.replace(/[^0-9]/g, '');
      setInputs({
        ...inputs,
        [name]: onlyNums
      });
      if (value.length !== 10) {
        return setError('phone_number');
      } else {
        return setError('');
      }
    } else if (name === 'password') {
      setInputs({
        ...inputs,
        [name]: value
      });

      if (
        value.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+=_'<>.^(),#/{}|";:-])[A-Za-z\d@$!%*?&+=_'<>.^(),#/{}|";:-]{8,}$/
        )
      ) {
        return setError('');
      } else {
        return setError('password');
      }
    } else if (name === 'email') {
      setInputs({
        ...inputs,
        [name]: value
      });
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(value) === false) {
        setError('email');
      } else {
        setError('');
      }
    } else {
      setInputs({
        ...inputs,
        [name]: value
      });
    }
  };

  useEffect(() => {
    if (code) {
      setInputs({ ...inputs, referral: code });
    }
    let redirected = JSON.parse(localStorage.getItem('RedirectToSignup'));
    if (redirected === true) {
      setDefaultEmail(true);
      if (
        questionnaireData &&
        questionnaireData.hasOwnProperty('MxAns') &&
        questionnaireData.MxAns.hasOwnProperty('email')
      ) {
        setEmailUsed(questionnaireData.MxAns.email);
        setInputs({ ...inputs, email: questionnaireData.MxAns.email });
      }
    }
  }, []);

  const sendOtp = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/sendotp`, {
        email: inputs.email,
        name: inputs.name
      });

      if (response && response.data && response.data.type == 'Success') {
        setSnack(true);
        setSnackMessage(response.data.message);
        setDisplay('otp');
        setLoading(false);
      } else {
        setSnack(true);
        setSnackMessage(response.data.message);
        setLoading(false);
      }
      ConsoleHelper(response);
    } catch (err) {
      ConsoleHelper(err);
      setLoading(false);
    }
  };

  const handleSubmitRegister = () => {
    if (inputs.otp != '') {
      let mx = {};
      let redirectedFrom = false;
      let mxAnsData = {};
      clearError();
      setLoading(true);
      setDefaultEmail(false);
      setEmailUsed('');
      let redirect = JSON.parse(localStorage.getItem('RedirectToSignup'));
      if (
        (redirect && redirect === true) ||
        (questionnaireData &&
          questionnaireData.hasOwnProperty('MxAns') &&
          questionnaireData.MxAns.hasOwnProperty('questionnaire') &&
          questionnaireData.MxAns.questionnaire.hasOwnProperty('purchased') &&
          questionnaireData.MxAns.questionnaire.purchased === false)
      ) {
        if (questionnaireData && questionnaireData.hasOwnProperty('MxAns')) {
          mx = { ...questionnaireData.MxAns };
          redirectedFrom = true;
          mxAnsData = { ...mx };
          registerUser(inputs, history, location.state, redirectedFrom, mxAnsData, () => {
            setLoading(false);
          });
        }
      } else {
        ConsoleHelper(inputs);
        registerUser(inputs, history, location.state, redirectedFrom, mxAnsData, () => {
          setLoading(false);
        });
      }
    } else {
      setSnack(true);
      setSnackMessage('Enter OTP sent to your mail');
    }
  };

  useEffect(() => {
    if (auth.errors?.type === 'ERROR') {
      setLoading(false);
      setTimeout(() => {
        alert(auth.errors?.message);
      }, 500);
      clearError();
    }
  }, [auth]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <ToastContainer />
      {loading && (
        <div style={{ position: 'fixed', top: '40%', left: '50%', opacity: 1 }}>
          <Loader type="ThreeDots" visible={true} color="#000000" height={100} width={100} />
        </div>
      )}
      <div className={style.wholeContainer} style={{ opacity: loading ? 0.5 : 1 }}>
        <Header />
        <div className={classnames('container-fluid')} style={{ marginTop: '75px' }}>
          <div className="row mt-0">
            <div className={classnames('col-lg-6 ', style.imgContainer)}></div>

            <div className={classnames('col-lg-6', style.container)}>
              <div
                className={classnames(
                  'container pl-1 pr-1 d-flex flex-column align-items-left justify-content-center',
                  style.innerContainer
                )}>
                {display === 'signup' ? (
                  <form
                    className="d-flex flex-column"
                    onSubmit={e => {
                      sendOtp(e);
                    }}>
                    <h2 className={classnames(style.signup_header)}>
                      Hello and welcome <br></br>
                      (to your almost new) home
                    </h2>
                    <h2 className={style.signup_text}>Signup</h2>
                    <p
                      onClick={() => history1.push('/login')}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        fontWeight: 600
                      }}>
                      or login to your account.
                    </p>
                    <input
                      className={style.signup_input}
                      name="email"
                      value={defaultEmail === true && emailUsed ? emailUsed : inputs.email || ''}
                      required
                      onChange={e => {
                        if (defaultEmail === false) {
                          handleChangeSignup(e);
                        }
                      }}
                      placeholder="Email"
                    />
                    {error === 'email' && (
                      <span style={{ color: 'red', fontSize: '11px' }}>Enter a valid mail</span>
                    )}
                    <input
                      className={style.signup_input}
                      name="name"
                      required
                      value={inputs.name || ''}
                      onChange={handleChangeSignup}
                      placeholder="Name"
                    />

                    <div>
                      <input
                        type={showPass ? 'text' : 'password'}
                        className={style.signup_input}
                        required
                        value={inputs.password || ''}
                        name="password"
                        onChange={handleChangeSignup}
                        placeholder="Password"
                      />
                      <span style={{ marginLeft: '-3em' }}>
                        {(!showPass && (
                          <FontAwesomeIcon icon={faEye} onClick={() => setShowPass(!showPass)} />
                        )) || (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => setShowPass(!showPass)}
                          />
                        )}
                      </span>
                    </div>
                    {error === 'password' && (
                      <span style={{ color: 'red', fontSize: '11px' }}>
                        Password must contains atleast one capital letter, number and special
                        character
                      </span>
                    )}

                    {display === 'signup' && <div id="recaptcha"></div>}
                    <button
                      className={style.signup_button}
                      style={{ cursor: 'pointer' }}
                      disabled={error !== ''}
                      type="submit">
                      SIGN UP
                    </button>
                    <span className="mt-2 text-sm">
                      By creating an account, I accept the{' '}
                      <span
                        onClick={() => history1.push('/terms')}
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline'
                        }}>
                        Terms & Conditions
                      </span>
                    </span>

                    <button
                      className={style.signupwithgoogle_button}
                      style={{ cursor: 'pointer' }}
                      type="button"
                      onClick={() => {
                        googleSignInClicked();
                      }}>
                      <img src={gooleLogo} alt="" /> Sign up with Google
                    </button>
                  </form>
                ) : display === 'otp' ? (
                  <>
                    <h2 className={style.signup_header}>
                      Hello and welcome <br></br>
                      (to your almost new) home
                    </h2>
                    <h2 className={style.signup_text} style={{ cursor: 'pointer' }}>
                      Signup
                    </h2>
                    <p
                      onClick={() => history1.push('/login')}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline'
                      }}>
                      or login to your account.
                    </p>
                    <input
                      className={style.signup_input}
                      value={inputs.email}
                      placeholder="Email"
                      disabled
                    />
                    <input
                      className={style.signup_input}
                      placeholder="One TIme Password"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      type="password"
                      value={inputs.otp}
                      onChange={e => setInputs({ ...inputs, otp: e.target.value })}
                    />
                    {display === 'otp' && <div id="recaptcha"></div>}
                    <p
                      onClick={e => sendOtp(e)}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        textAlign: 'end',
                        marginTop: '0.2rem'
                      }}>
                      Resend OTP?
                    </p>
                    <button
                      className={style.signup_button}
                      onClick={() => {
                        handleSubmitRegister();
                      }}>
                      VERIFY OTP
                    </button>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, {
  registerUser,
  loginUser,
  clearError
})(withRouter(Signup));
