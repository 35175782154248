import {
  GET_CUSTOMER_ACCOUNT_DATA,
  UPDATE_CUSTOMER_ACCOUNT_DATA,
  RESET_CUSTOMER
} from '../constants';

const initialState = {
  customerAccount: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CUSTOMER_ACCOUNT_DATA:
      return {
        ...state,
        customerAccount: payload
      };
    case UPDATE_CUSTOMER_ACCOUNT_DATA:
      if (Object.keys(payload).length) {
        Object.assign(state.customerAccount, payload);
      }
      return {
        ...state
      };
    case RESET_CUSTOMER:
      return {
        ...state,
        customerAccount: {}
      };
    default:
      return state;
  }
}
