import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import style from './Questionaire.module.css';
import './Questionairelayout.css';
import TopBar from './TopBar';
import Footer from './Footer';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import classnames from 'classnames';
import axios from 'axios';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { space, resultImages, bucket, rooms, color, styleData } from './images';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  post_questionnaire,
  questionnaire,
  page,
  storeMx,
  storeMxSkip,
  storeTab,
  storeMxAns,
  storeMxRetake
} from '../../store/actions/sampleaction';
import validator from 'validator';
import { Doughnut } from 'react-chartjs-2';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { Modal, Row } from 'react-bootstrap';
import Close from '../../assets/images/close.png';
import 'react-lazy-load-image-component/src/effects/blur.css';
import welcomeAnimation from '../lottie/WelcomeLottie.json';
import pleaseWaitAnimation from '../lottie/pleaseWaitLottie.json';
import pleaseFewmoreAnimation from '../lottie/FewLottie.json';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon
} from 'react-share';
import Snackbar from '@material-ui/core/Snackbar';
import Loader from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import Lottie from 'react-lottie';
import parse from 'html-react-parser';
import Shareurl from '../Shareurl';
import ConsoleHelper from '../../utils/ConsoleHelper';

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
const Questionairelayout = ({
  data,
  storeTab,
  questionnaireData,
  questionnaire,
  auth,
  scrollPosition,
  post_questionnaire,
  page,
  storeMx,
  storeMxSkip,
  storeMxAns,
  storeMxRetake,
  locationparam,
  historyAction
}) => {
  const dimension = useMediaQuery();
  const childRef = useRef();
  const copy = useRef();
  const history = useHistory();
  const { id } = useParams();
  //const [width] = useMediaQuery();
  let projectId = id;
  const [tileData, setTileData] = useState(space);
  const [styleResultId, setStyleResultId] = useState('');
  const [shareModal, setShareModal] = useState(false);
  // const [qnsPosted, setQnsPosted] = useState(false);
  const [spaceClickedData, setSpaceClickedData] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_SPACE_CLICKED')
      ? questionnaireData.MxData.MX_A_SPACE_CLICKED
      : []
  );
  const [disabled, setDisabled] = useState(true);
  //const [total, setTotal] = useState(0);
  const [styleResultLoading, setStyleResultLoading] = useState(false);

  // const [lottie, setLottie] = useState(true);
  const [copiedUrl, setCopiedUrl] = useState('');
  const [designClicked] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_DESIGN')
      ? questionnaireData.MxData.MX_A_DESIGN
      : ''
  );
  const [styleClickedData, setStyleClickedData] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_STYLE_CLICKED')
      ? questionnaireData.MxData.MX_A_STYLE_CLICKED
      : []
  );
  const [snackMessage, setSnackMessage] = useState('');
  const [snack, setSnack] = useState(false);
  const [styleResultImage, setStyleResultImage] = useState(
    questionnaireData.MxData.hasOwnProperty('styleImage') ? questionnaireData.MxData.styleImage : ''
  );
  // const [finalCount, setFinalCount] = useState({});
  const [resultStyleDescription, setResultStyleDescription] = useState(
    questionnaireData.MxData.hasOwnProperty('styleDescription')
      ? questionnaireData.MxData.styleDescription
      : ''
  );
  const [colorSelected, setColorSelected] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_COLOR_TONE')
      ? questionnaireData.MxData.MX_A_COLOR_TONE
      : ''
  );
  const [emailError, setEmailError] = useState('');
  const [error1, setError1] = useState(false);
  const [styleClicked, setStyleClicked] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_STYLE') ? questionnaireData.MxData.MX_A_STYLE : []
  );
  const [algoResponse, setAlgoResponse] = useState(
    questionnaireData.MxData.hasOwnProperty('algorithm')
      ? questionnaireData.MxData.algorithm
      : questionnaireData?.data?.questionnaire?.styleResult
      ? {
          ...questionnaireData.data.questionnaire.styleResult,
          subStyles: questionnaireData.data.questionnaire.styleResult.styles
        }
      : {}
  );
  const [finalStyleResponse, setFinalStyleResponse] = useState({});
  const [finalResponse, setFinalResponse] = useState(null);
  const [styleResponse, setStyleResponse] = useState([]);
  const [spaceResponse, setSpaceResponse] = useState([]);
  const [skip, setSkip] = useState('');
  const [moreOptionClicked, setMoreOptionClicked] = useState(true);
  const [colorClicked, setColorClicked] = useState('');
  const [spaceClicked, setSpaceClick] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_SPACE') ? questionnaireData.MxData.MX_A_SPACE : []
  );
  let [pg, setPg] = useState(questionnaireData.page_number || 0);
  const [currentData, setCurrentData] = useState({ ...data[pg] });
  const [intialRoomsClicked, setInitalRoomsClicked] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_INITAL_ROOM')
      ? questionnaireData.MxData.MX_A_INITAL_ROOM
      : ''
  );
  const [roomsClicked, setRoomsClicked] = useState(
    questionnaireData.MxData.hasOwnProperty('MX_A_ROOMS') ? questionnaireData.MxData.MX_A_ROOMS : []
  );
  const [email, setEmail] = useState(
    auth && auth.user
      ? auth.user.email
      : questionnaireData.MxData.hasOwnProperty('email')
      ? questionnaireData.MxData.email
      : ''
  );
  const [name, setName] = useState();
  // auth && auth.user
  //   ? auth.user.name
  //   : questionnaireData.MxData.hasOwnProperty('name')
  //   ? questionnaireData.MxData.name
  //   : ''

  useEffect(() => {
    if (spaceResponse?.length !== 0 || styleResponse?.length !== 0) {
      var response = [...spaceResponse, ...styleResponse];
      let ethnicCount = 0;
      let modernCount = 0;
      let glamCount = 0;
      let rusticCount = 0;
      let organicCount = 0;
      response.forEach((key, i) => {
        if (key.name === 'ethnic') {
          ethnicCount = ethnicCount + key.count;
        } else if (key.name === 'modern') {
          modernCount = modernCount + key.count;
        } else if (key.name === 'glam') {
          glamCount = glamCount + key.count;
        } else if (key.name === 'rustic') {
          rusticCount = rusticCount + key.count;
        } else if (key.name === 'organic') {
          organicCount = organicCount + key.count;
        }
      });
      var finalArr = [
        { name: 'Ethnic', count: ethnicCount },
        { name: 'Modern', count: modernCount },
        { name: 'Glam', count: glamCount },
        { name: 'Rustic', count: rusticCount },
        { name: 'Organic', count: organicCount }
      ];
      setFinalResponse(finalArr.sort((a, b) => (b['count'] > a['count'] ? 1 : -1)));

      var count = {};
      response
        .map(d => d.sub)
        .flat(1)
        .forEach(function(i) {
          count[i] = (count[i] || 0) + 1;
        });
      if (pg > 4) {
        setFinalStyleResponse(
          Object.keys(count)
            .sort((a, b) => count[b] - count[a])
            .reduce(
              (_sortedObj, key) => ({
                ..._sortedObj,
                [key]: count[key]
              }),
              {}
            )
        );
      }
      // setFinalCount(count);
    }
  }, [spaceResponse, styleResponse]);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/result/questionnaire`,
          {
            tags: styleClicked
          }
        );
        setStyleResponse([...res.data]);
      } catch (err) {}
    };

    if (spaceClicked.length === 0 && styleClicked?.length > 0) {
      FetchData();
    } else if (spaceClicked.length === 0) {
      setDisabled(true);
    }
  }, [spaceClicked, styleClicked]);

  useEffect(() => {
    setCurrentData(data[pg]);
    if (pg === 0 && spaceClicked.length !== 0) {
      setSkip('space');
      storeMxSkip('space');
    } else {
      setSkip('');
      storeMxSkip('');
    }

    if (pg === 0 && spaceClicked.length < 4) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    // if (pg === 7 && (spaceClicked.length > 0 || styleClicked.length > 0)) {
    //   // setQnsPosted(true);
    //   handleSubmit();
    // }
    if (pg === 3 && roomsClicked.length < 2) {
      setSkip('rooms');
    } else {
      setSkip('');
    }
    if (
      pg === 3 &&
      questionnaireData.MxData.hasOwnProperty('MX_A_ROOMS') &&
      questionnaireData.MxData.MX_A_ROOMS.length < 2
    ) {
      setSkip('rooms');
    } else {
      setSkip('');
    }
    if (pg === 8 && (spaceClicked.length > 0 || styleClicked.length > 0)) {
      //  setQnsPosted(true);
      handleSubmit();
    }
  }, [
    pg,
    spaceClicked,
    questionnaireData.page_number,
    roomsClicked
    //questionnaireData,
  ]);
  useEffect(() => {
    if (pg == 9) {
      onShareClick();
    }
  }, [pg]);
  useEffect(() => {
    if (historyAction && locationparam) {
      if (historyAction === 'POP') {
        if (spaceClicked && spaceClicked.length > 0 && pg === 2) {
          pg = pg - 2;
          page(pg);
          setPg(pg);
          // history.replace(locationparam, /* the new state */);
        } else if (styleClicked && styleClicked.length > 0 && pg === 1) {
          setSnack(true);
          setSnackMessage('Please deselect the style list to go to space ');
        } else if (roomsClicked && roomsClicked.length === 1 && pg === 6) {
          pg = pg - 2;
          page(pg);
          setPg(pg);
          // history.replace(locationparam, /* the new state */);
        } else {
          if (pg - 1 >= 0) {
            pg = pg - 1;
            page(pg);
            setPg(pg);
          }
        }

        history.replace(locationparam /* the new state */);
      }
    }
  }, [historyAction, locationparam]);
  useEffect(() => {
    var data = tileData
      .filter(filter => spaceClicked.includes(filter.id))
      .map(r => r.tags)
      .flat(1);

    const FetchData = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/result/questionnaire`,
          {
            tags: data
          }
        );
        setSpaceResponse(res.data);
      } catch (err) {}
    };
    if (data?.length > 0) {
      FetchData();
    }
  }, [spaceClicked]);
  useEffect(() => {
    if (currentData && currentData.type === 'space' && spaceClicked.length === 0) {
      setDisabled(true);
    }
    if (spaceClicked.length >= 5) {
      setDisabled(false);
    }

    if (currentData && currentData.type === 'style' && styleClicked.length === 0) {
      setDisabled(true);
    }
    if (styleClicked.length !== 0) {
      setDisabled(false);
    }
    if (currentData && currentData.type === 'color' && colorClicked.length === 0) {
      setDisabled(true);
    }
    if (colorClicked) {
      setDisabled(false);
    }

    if (currentData && designClicked) {
      setDisabled(false);
    }
    if (currentData && currentData.type === 'intialRoom' && intialRoomsClicked.length === 0) {
      setDisabled(true);
    }
    if (currentData && currentData.type === 'rooms' && roomsClicked.length === 0) {
      setDisabled(true);
    }
    if (currentData && currentData.type === 'name' && name === '') {
      setDisabled(true);
    }
    if (name && name !== '') {
      setDisabled(false);
    }
    if (currentData && currentData.type === 'email' && email === '') {
      setDisabled(true);
    }
    if (email && email !== '') {
      setDisabled(false);
    }
    if (currentData && currentData.type === 'rooms' && roomsClicked.length === 0) {
      setDisabled(true);
    }

    if (roomsClicked.length !== 0) {
      setDisabled(false);
    }

    if (intialRoomsClicked) {
      setDisabled(false);
    }

    if (currentData && currentData.type === 'result') {
      setDisabled(false);
    }
    if (projectId && currentData) {
      history.push(`/questionnaire/${currentData.type}/${projectId}`);
    } else if (currentData) {
      history.push(`/questionnaire/${currentData.type}`);
    }
  }, [
    currentData,
    intialRoomsClicked,
    roomsClicked,
    email,
    name,
    colorClicked,
    styleClicked,
    spaceClicked,
    projectId,
    historyAction
  ]);
  useEffect(() => {
    if (styleResponse?.length !== 0 || styleClicked.length > 0) {
      var response = [...styleResponse];

      let ethnicCount = 0;
      let modernCount = 0;
      let glamCount = 0;
      let rusticCount = 0;
      let organicCount = 0;
      let ethnicSubStyles = [],
        modernSubStyles = [],
        glamSubStyles = [],
        rusticSubStyles = [],
        organicSubStyles = [];
      response.forEach((key, i) => {
        if (key.name === 'ethnic') {
          ethnicCount = ethnicCount + key.count;
          ethnicSubStyles = [...key.sub];
          ethnicSubStyles = ethnicSubStyles.map(function(x) {
            return {
              name: x,
              priority: 0
            };
          });
          for (let i = 0; i < ethnicSubStyles.length; i++) {
            for (let j = 0; j < styleData.length; j++) {
              if (ethnicSubStyles[i].name === styleData[j].value) {
                ethnicSubStyles[i].priority = styleData[j].priority;
              }
            }
          }
        } else if (key.name === 'modern') {
          modernCount = modernCount + key.count;
          modernSubStyles = [...key.sub];
          modernSubStyles = modernSubStyles.map(function(x) {
            return {
              name: x,
              priority: 0
            };
          });
          for (let i = 0; i < modernSubStyles.length; i++) {
            for (let j = 0; j < styleData.length; j++) {
              if (modernSubStyles[i].name === styleData[j].value) {
                modernSubStyles[i].priority = styleData[j].priority;
              }
            }
          }
        } else if (key.name === 'glam') {
          glamCount = glamCount + key.count;
          glamSubStyles = [...key.sub];
          glamSubStyles = glamSubStyles.map(function(x) {
            return {
              name: x,
              priority: 0
            };
          });
          for (let i = 0; i < glamSubStyles.length; i++) {
            for (let j = 0; j < styleData.length; j++) {
              if (glamSubStyles[i].name === styleData[j].value) {
                glamSubStyles[i].priority = styleData[j].priority;
              }
            }
          }
        } else if (key.name === 'rustic') {
          rusticCount = rusticCount + key.count;
          rusticSubStyles = [...key.sub];
          rusticSubStyles = rusticSubStyles.map(function(x) {
            return {
              name: x,
              priority: 0
            };
          });
          for (let i = 0; i < rusticSubStyles.length; i++) {
            for (let j = 0; j < styleData.length; j++) {
              if (rusticSubStyles[i].name === styleData[j].value) {
                rusticSubStyles[i].priority = styleData[j].priority;
              }
            }
          }
        } else if (key.name === 'organic') {
          organicCount = organicCount + key.count;
          organicSubStyles = [...key.sub];
          organicSubStyles = organicSubStyles.map(function(x) {
            return {
              name: x,
              priority: 0
            };
          });
          for (let i = 0; i < organicSubStyles.length; i++) {
            for (let j = 0; j < styleData.length; j++) {
              if (organicSubStyles[i].name === styleData[j].value) {
                organicSubStyles[i].priority = styleData[j].priority;
              }
            }
          }
        }
      });
      var finalArr = [
        {
          name: 'Ethnic',
          count: ethnicCount,
          subStyles1: ethnicSubStyles.sort((a, b) => (b['priority'] < a['priority'] ? 1 : -1)),
          priority: 1
        },
        {
          name: 'Modern',
          count: modernCount,
          subStyles1: modernSubStyles.sort((a, b) => (b['priority'] < a['priority'] ? 1 : -1)),
          priority: 2
        },
        {
          name: 'Glam',
          count: glamCount,
          subStyles1: glamSubStyles.sort((a, b) => (b['priority'] < a['priority'] ? 1 : -1)),
          priority: 3
        },
        {
          name: 'Rustic',
          count: rusticCount,
          subStyles1: rusticSubStyles.sort((a, b) => (b['priority'] < a['priority'] ? 1 : -1)),
          priority: 4
        },
        {
          name: 'Organic',
          count: organicCount,
          subStyles1: organicSubStyles.sort((a, b) => (b['priority'] < a['priority'] ? 1 : -1)),
          priority: 5
        }
      ];
      let sortedArray = finalArr.sort((a, b) => (b['count'] > a['count'] ? 1 : -1));
      if (styleResponse?.length !== 0 || styleClicked.length > 0) {
        let cnt = 0;
        let arraysWithCount = sortedArray.filter(sorted => sorted.count > 0);
        const allEqual = arraysWithCount.every(function(element, index) {
          if (element.count === 1) return true;
          else return false;
        });
        if (allEqual) {
          arraysWithCount = arraysWithCount.sort((a, b) =>
            b['priority'] < a['priority'] ? 1 : -1
          );
        }

        arraysWithCount.map(array => {
          cnt = cnt + array.subStyles1.length;
        });
        if (arraysWithCount.length === 1 && cnt === 1) {
          let bucket1 = bucket.find(item => item.title === arraysWithCount[0].name);
          bucket1 = bucket1.style.filter(b => b !== arraysWithCount[0].subStyles1[0].name);
          arraysWithCount[0].subStyles1[0].value = 0.8;
          bucket1.map(b => arraysWithCount[0].subStyles1.push({ name: b, value: 0.1 }));
          const bucketName = bucket.find(item => item.title === arraysWithCount[0].name);
          let algorithm = {
            ...algoResponse,
            bucket: bucketName['title'],
            subStyles: arraysWithCount[0].subStyles1.map(sty => {
              return {
                name: sty.name,
                value: sty.value
              };
            }),
            total: styleClicked.length
          };
          setAlgoResponse({ ...algorithm });
        } else if (arraysWithCount.length === 1 && cnt === 2) {
          let bucket1 = bucket.find(item => item.title === arraysWithCount[0].name);
          bucket1 = bucket1.style.filter(
            b =>
              b !== arraysWithCount[0].subStyles1[0].name &&
              b !== arraysWithCount[0].subStyles1[1].name
          );
          arraysWithCount[0].subStyles1[0].value = 0.9;
          arraysWithCount[0].subStyles1[1].value = 0.9;

          bucket1.map(b => arraysWithCount[0].subStyles1.push({ name: b, value: 0.2 }));
          const bucketName = bucket.find(item => item.title === arraysWithCount[0].name);
          let algorithm = {
            ...algoResponse,
            bucket: bucketName['title'],
            subStyles: arraysWithCount[0].subStyles1.map(sty => {
              return {
                name: sty.name,
                value: sty.value
              };
            }),
            total: styleClicked.length
          };
          setAlgoResponse({ ...algorithm });
        } else if (arraysWithCount.length === 1 && cnt === 3) {
          const bucketName = bucket.find(item => item.title === arraysWithCount[0].name);
          let algorithm = {
            ...algoResponse,
            bucket: bucketName['title'],
            subStyles: arraysWithCount[0].subStyles1.map(sty => {
              return {
                name: sty.name,
                value: 1
              };
            }),
            total: styleClicked.length
          };
          setAlgoResponse({ ...algorithm });
        } else if (styleClicked.length !== 1 && styleClicked.length === arraysWithCount.length) {
          const y1 = [];
          for (let i = 0; i < arraysWithCount.length; i++) {
            for (let j = 0; j < arraysWithCount[i].subStyles1.length; j++) {
              y1.push({
                name: arraysWithCount[i].subStyles1[j].name,
                value: 1
              });
            }
          }

          if (y1.length === 2) {
            let substituteStyle = bucket.filter(b => b.style.includes(y1[0].name));
            substituteStyle = substituteStyle[0].style;
            substituteStyle = substituteStyle.filter(s => s !== y1[0].name);
            y1.push({ name: substituteStyle[0], value: 0.5 });
          }

          let algorithm = {
            ...algoResponse,
            bucket: 'Transitional',
            subStyles: y1.map(sty => {
              return {
                name: sty.name,
                value: sty.value
              };
            }),
            total: y1
              .filter((d, i) => i < 3)
              ?.reduce(function(acc, obj) {
                return acc + obj.value;
              }, 0)
          };
          setAlgoResponse({ ...algorithm });
        } else if (styleClicked.length > arraysWithCount.length && arraysWithCount.length !== 1) {
          const y1 = [];
          for (let i = 0; i < arraysWithCount.length; i++) {
            for (let j = 0; j < arraysWithCount[i].subStyles1.length; j++) {
              y1.push({
                name: arraysWithCount[i].subStyles1[j].name,
                value: 1
              });
            }
          }
          let algorithm = {
            bucket: arraysWithCount.length > 0 ? arraysWithCount[0].name : '',
            subStyles: y1.map(sty => {
              return {
                name: sty.name,
                value: sty.value
              };
            }),
            total: styleClicked.length
          };
          setAlgoResponse({ ...algoResponse, ...algorithm });
        }
      }
    }
  }, [styleResponse, styleClicked]);
  useEffect(() => {
    let array_elements = [];
    if (spaceClicked && spaceClicked.length > 4) {
      array_elements = space.filter(d => spaceClicked.includes(d.id)).map(f => f.tags[0]);

      const counts = {};
      array_elements.forEach(function(x) {
        counts[x] = (counts[x] || 0) + 1;
      });
      const arr = Object.keys(counts)
        .map(d => ({ name: d, value: counts[d] }))
        .sort((a, b) => (a.value < b.value ? 1 : -1));
      let subs = getSubStyles(arr);
      if (
        bucket.find(d => d.style.includes(subs[0]?.name))?.title ===
        bucket.find(d => d.style.includes(subs[1]?.name))?.title
      ) {
        return setAlgoResponse({
          ...algoResponse,
          bucket: bucket.find(d => d.style.includes(subs[1]?.name))?.title,

          subStyles: subs.filter((d, i) => i < 3),
          total: subs
            .filter((d, i) => i < 3)
            ?.reduce(function(acc, obj) {
              return acc + obj.value;
            }, 0)
        });
      }
      // if ((bucket.find((d) => d.style.includes(subs[1]?.name))?.title === bucket.find((d) => d.style.includes(subs[2]?.name))?.title)) {
      //   return setAlgoResponse({
      //     ...algoResponse,
      //     bucket: bucket.find((d) => d.style.includes(subs[1]?.name))?.title,
      //     // subStyles: arr.filter((d, i) => i < 3),
      //     subStyles: subs.filter((d, i) => i < 3),
      //     total: subs.filter((d, i) => i < 3)?.reduce(function (acc, obj) { return acc + obj.value; }, 0)
      //   })
      // }
      // if (bucket.find((d) => d.style.includes(arr[0]?.name))?.title === bucket.find((d) => d.style.includes(arr[2]?.name))?.title) {
      //   return setAlgoResponse({
      //     ...algoResponse,
      //     bucket: bucket.find((d) => d.style.includes(subs[0]?.name))?.title,
      //     // subStyles: arr.filter((d, i) => i < 3),
      //     subStyles: subs.filter((d, i) => i < 3),
      //     total: subs.filter((d, i) => i < 3)?.reduce(function (acc, obj) { return acc + obj.value; }, 0)
      //   })
      // }

      if (arr[0]?.value === arr[1]?.value) {
        return setAlgoResponse({
          ...algoResponse,
          bucket: 'Transitional',
          subStyles: subs.filter((d, i) => i < 3),
          total: subs
            .filter((d, i) => i < 3)
            ?.reduce(function(acc, obj) {
              return acc + obj.value;
            }, 0)
        });
      }
      if (arr.length === 1) {
        let a = bucket
          .find(d => d.style.includes(arr[0]?.name))
          .style.map(d => ({ name: d, value: 1 }));
        return setAlgoResponse({
          ...algoResponse,
          bucket: bucket.find(d => d.style.includes(subs[0]?.name))?.title,
          subStyles: subs.filter((d, i) => i < 3),
          total: subs
            .filter((d, i) => i < 3)
            ?.reduce(function(acc, obj) {
              return acc + obj.value;
            }, 0)
        });
      }
      setAlgoResponse({
        ...algoResponse,
        bucket: bucket.find(d => d.style.includes(subs[0]?.name))?.title,
        subStyles: subs.filter((d, i) => i < 3),
        total: subs
          .filter((d, i) => i < 3)
          ?.reduce(function(acc, obj) {
            return acc + obj.value;
          }, 0)
      });
    }
  }, [spaceClicked]);

  useEffect(() => {
    var sum = 0;
    // var arr = [];
    Object.values(finalStyleResponse).forEach((data, index) => {
      if (index <= 2) {
        sum = sum + data;
      }
      // setTotal(sum);
    });
  }, [finalStyleResponse]);
  useEffect(() => {
    const src = bucket.reduce((imageUrl, obj) => {
      if (obj.title === algoResponse?.bucket) {
        imageUrl = obj.src;
      }
      return imageUrl;
    }, '');
    storeMx({ ...questionnaireData.MxData, styleImage: src });
    setStyleResultImage(src);
    bucket.map(function(obj, i) {
      if (obj.title === algoResponse?.bucket) setResultStyleDescription(obj.description);
      storeMx({
        ...questionnaireData.MxData,
        styleDescription: obj.description
      });
    });
  }, [algoResponse]);
  useEffect(() => {
    if (history.location.state && history.location.state.redirectTo) {
      if (
        questionnaireData.hasOwnProperty('MxData') &&
        questionnaireData.MxData.projId &&
        questionnaireData.MxData.projId !== ''
      ) {
        storeTab('package');
        questionnaire('package');
        history.push(`/questionnaire/package/${questionnaireData.MxData.projId}`);
      } else {
        storeTab('package');
        questionnaire('package');
        history.push(`/questionnaire/package`);
      }
    }
    setTileData(shuffle(tileData));
    if (questionnaireData.hasOwnProperty('tab') && questionnaireData.tab) {
      questionnaire(questionnaireData.tab);
    }
    if (
      questionnaireData.page_number2 >= 0 &&
      questionnaireData.tab !== 'questionnaire' &&
      questionnaireData.MxData.hasOwnProperty('MX_A_HOME')
    ) {
      questionnaire('questionnaire2');
    }
    setName(
      auth?.user?.name
        ? auth.user.name
        : questionnaireData.MxData.hasOwnProperty('name')
        ? questionnaireData.MxData.name
        : ''
    );
  }, []);

  const defaultWelcomeLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: welcomeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const defaultWaitLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: pleaseWaitAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const defaultFewmoreLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: pleaseFewmoreAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      // setEmailError('Valid Email')
      setDisabled(false);
      setError1(false);
      return true;
    } else {
      setEmailError('Please Enter a Valid Email ID');
      setDisabled(true);
      setError1(true);
      return false;
    }
  };

  const handleSpaceSelected = value => {
    if (!spaceClicked.includes(value)) {
      // if (spaceClicked?.length > 15) return
      //storeMx({ MX_A_SPACE: spaceClicked, MX_A_STYLE: styleClicked, MX_A_COLOR_TONE: colorSelected, MX_A_DESIGN: designClicked, MX_A_ROOMS: roomsClicked, MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked, name: e.target.value, email: e.target.value })
      storeMx({
        MX_A_SPACE: [...spaceClicked, value],
        MX_A_STYLE: styleClicked,
        MX_A_COLOR_TONE: colorSelected,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: roomsClicked,
        MX_A_SPACE_CLICKED: spaceClickedData,
        MX_A_STYLE_CLICKED: styleClickedData,
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });

      setSpaceClick(prev => [...prev, value]);
    } else {
      storeMx({
        MX_A_SPACE:
          questionnaireData.MxData.MX_A_SPACE &&
          questionnaireData.MxData.MX_A_SPACE.filter(filter => filter !== value),
        MX_A_COLOR_TONE: colorSelected,
        MX_A_SPACE_CLICKED: spaceClickedData,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: roomsClicked,
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        name: name,
        email: email,
        MX_A_STYLE: styleClicked,
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      setSpaceClick(spaceClicked.filter(filter => filter !== value));
    }
  };

  const handleSpaceClicked = data => {
    let index = spaceClickedData.findIndex(sp => sp.id === data.id);
    if (index >= 0) {
      storeMx({
        MX_A_SPACE: spaceClicked,
        MX_A_SPACE_CLICKED:
          questionnaireData.MxData.MX_A_SPACE_CLICKED &&
          questionnaireData.MxData.MX_A_SPACE_CLICKED.filter(filter => filter.id !== data.id),
        MX_A_STYLE: styleClicked,
        MX_A_STYLE_CLICKED: styleClickedData,
        MX_A_COLOR_TONE: colorSelected,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: roomsClicked,
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      setSpaceClickedData(spaceClickedData.filter(filter => filter.id !== data.id));
    } else {
      storeMx({
        MX_A_SPACE: spaceClicked,
        MX_A_SPACE_CLICKED: [...spaceClickedData, { id: data.id, tag: data.tags, src: data.src }],
        MX_A_STYLE: styleClicked,
        MX_A_COLOR_TONE: colorSelected,
        MX_A_STYLE_CLICKED: styleClickedData,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: roomsClicked,
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      setSpaceClickedData([...spaceClickedData, { id: data.id, tag: data.tags, src: data.src }]);
    }
  };

  const handleStyleClicked = data => {
    let index = styleClickedData.findIndex(sp => sp.tag === data.value);
    if (index >= 0) {
      let st2 =
        questionnaireData.MxData.MX_A_STYLE_CLICKED &&
        questionnaireData.MxData.MX_A_STYLE_CLICKED.filter(filter => filter.tag !== data.value);
      storeMx({
        MX_A_SPACE: spaceClicked,
        MX_A_STYLE: styleClicked,
        MX_A_SPACE_CLICKED: spaceClickedData,
        MX_A_STYLE_CLICKED: [...st2],
        MX_A_COLOR_TONE: colorSelected,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: roomsClicked,
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      let st3 = styleClickedData.filter(filter => filter.tag !== data.value);
      setStyleClickedData([...st3]);
    } else {
      storeMx({
        MX_A_SPACE: spaceClicked,
        MX_A_STYLE: styleClicked,
        MX_A_SPACE_CLICKED: spaceClickedData,
        MX_A_STYLE_CLICKED: [...styleClickedData, { tag: data.value, src: data.img }],
        MX_A_COLOR_TONE: colorSelected,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: roomsClicked,
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      setStyleClickedData([...styleClickedData, { tag: data.value, src: data.img }]);
    }
  };

  const handleStyleSelected = value => {
    if (!styleClicked.includes(value)) {
      // if (styleClicked?.length > 3) return
      storeMx({
        MX_A_SPACE: spaceClicked,
        MX_A_STYLE: [...styleClicked, value],
        MX_A_STYLE_CLICKED: styleClickedData,
        MX_A_COLOR_TONE: colorSelected,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: roomsClicked,
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      setStyleClicked(prev => [...prev, value]);
    } else {
      let st1 =
        questionnaireData.MxData.MX_A_STYLE &&
        questionnaireData.MxData.MX_A_STYLE.filter(filter => filter !== value);
      storeMx({
        MX_A_SPACE: spaceClicked,
        MX_A_STYLE: [...st1],
        MX_A_STYLE_CLICKED: styleClickedData,
        MX_A_COLOR_TONE: colorSelected,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: roomsClicked,
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      let st = styleClicked.filter(filter => filter !== value);
      setStyleClicked([...st]);
    }
  };

  const handleRoomsSelected = value => {
    if (!roomsClicked.includes(value)) {
      storeMx({
        MX_A_SPACE: spaceClicked,
        MX_A_STYLE: styleClicked,
        MX_A_COLOR_TONE: colorSelected,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS: [...roomsClicked, value],
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        MX_A_SPACE_CLICKED: spaceClickedData,
        MX_A_STYLE_CLICKED: styleClickedData,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      // if (questionnaireData.MxData.MX_A_ROOMS && questionnaireData.MxData.MX_A_ROOMS.length === 1) {
      //   storeMx({ MX_A_SPACE: spaceClickedData, MX_A_STYLE: styleClickedData, MX_A_COLOR_TONE: colorSelected, MX_A_DESIGN: designClicked, MX_A_ROOMS: [...roomsClicked, value], MX_A_INITAL_ROOM: questionnaireData.MxData.MX_A_ROOMS[0] })
      // }
      setRoomsClicked(prev => [...prev, value]);
    } else {
      storeMx({
        MX_A_SPACE: spaceClicked,
        MX_A_STYLE: styleClicked,
        MX_A_COLOR_TONE: colorSelected,
        MX_A_DESIGN: designClicked,
        MX_A_ROOMS:
          questionnaireData.MxData.MX_A_ROOMS &&
          questionnaireData.MxData.MX_A_ROOMS.filter(filter => filter !== value),
        MX_A_INITAL_ROOM: roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
        MX_A_SPACE_CLICKED: spaceClickedData,
        MX_A_STYLE_CLICKED: styleClickedData,
        name: name,
        email: email,
        projId: projectId ? projectId : '',
        styleDescription: resultStyleDescription,
        styleImage: styleResultImage
      });
      // if (questionnaireData.MxData.MX_A_ROOMS && questionnaireData.MxData.MX_A_ROOMS.length === 1) {
      //   storeMx({ MX_A_SPACE: spaceClickedData, MX_A_STYLE: styleClickedData, MX_A_COLOR_TONE: colorSelected, MX_A_DESIGN: designClicked, MX_A_ROOMS: [...roomsClicked, value], MX_A_INITAL_ROOM: questionnaireData.MxData.MX_A_ROOMS[0] })
      // }
      setRoomsClicked(roomsClicked.filter(filter => filter !== value));
    }
  };

  const getSpaceTags = arr => {
    let tags = [];
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr[i].tags.length; j++) {
        tags.push(arr[i].tags[j]);
      }
    }
    return tags;
  };

  const getSubStyles = arr => {
    let subs = [];
    let x = [...arr];
    let medium;
    let low;
    let unique = [...new Set(arr.map(item => item.value))];
    if (unique.length > 3) {
      unique = unique.slice(0, 3);
    }
    let high = x.filter(y => y.value === unique[0]);
    if (arr.length > 1) {
      medium = x.filter(y => y.value === unique[1]);
    }
    if (arr.length > 2) {
      low = x.filter(y => y.value === unique[2]);
    }
    if (high.length === 1) {
      subs = [...high];
      if (medium && medium.length === 1) {
        if (low && low.length > 0) {
          for (let i = 0; i < low.length; i++) {
            for (let j = 0; j < styleData.length; j++) {
              if (low[i].name === styleData[j].value) {
                low[i].priority = styleData[j].priority;
              }
            }
          }
          low.sort((a, b) => Number(a.priority) - Number(b.priority));
          subs = [...subs, medium, ...low];
        } else {
          subs = [...subs, medium];
          if (subs.length === 2) {
            subs = subs.flat();
            subs[0].value = 5;
            subs[1].value = 4;
            // let i = 1;
            let tags = [];
            // let addStyles = {};
            // let styles2;
            for (let j = 0; j < bucket.length; j++) {
              if (bucket[j].style.includes(subs[0].name)) {
                tags = [...bucket[j].style];
              }
            }
            tags = tags.filter(t => t !== subs[0].name);
            tags = tags.filter(t => t !== subs[1].name);
            tags.forEach(t => {
              {
                subs.push({ name: t, value: 1 });
              }
            });
          }
        }
      } else if (medium && medium.length > 1) {
        for (let i = 0; i < medium.length; i++) {
          for (let j = 0; j < styleData.length; j++) {
            if (medium[i].name === styleData[j].value) {
              medium[i].priority = styleData[j].priority;
            }
          }
        }
        medium.sort((a, b) => Number(a.priority) - Number(b.priority));
        subs = [...subs, ...medium];
      } else if (!medium) {
        subs[0].value = 8;
        // let i = 1;
        let tags = [];
        // let addStyles = {};
        // let styles2;
        for (let j = 0; j < bucket.length; j++) {
          if (bucket[j].style.includes(subs[0].name)) {
            tags = [...bucket[j].style];
          }
        }
        tags = tags.filter(t => t !== subs[0].name);
        tags.forEach(t => {
          {
            subs.push({ name: t, value: 1 });
          }
        });
      }
    } else if (high.length === 2) {
      for (let i = 0; i < high.length; i++) {
        for (let j = 0; j < styleData.length; j++) {
          if (high[i].name === styleData[j].value) {
            high[i].priority = styleData[j].priority;
          }
        }
      }
      high.sort((a, b) => Number(a.priority) - Number(b.priority));
      if (medium && medium.length > 0) {
        for (let i = 0; i < medium.length; i++) {
          for (let j = 0; j < styleData.length; j++) {
            if (medium[i].name === styleData[j].value) {
              medium[i].priority = styleData[j].priority;
            }
          }
        }
        medium.sort((a, b) => Number(a.priority) - Number(b.priority));
        subs = [...high, ...medium];
      } else {
        let substituteStyle = bucket.filter(b => b.style.includes(high[0].name));
        substituteStyle = substituteStyle[0].style;
        substituteStyle = substituteStyle.filter(s => s !== high[0].name);

        subs = [...high];
        subs.push({ name: substituteStyle[0], value: 1 });
      }
    } else if (high.length > 2) {
      for (let i = 0; i < high.length; i++) {
        for (let j = 0; j < styleData.length; j++) {
          if (high[i].name === styleData[j].value) {
            high[i].priority = styleData[j].priority;
          }
        }
      }
      high.sort((a, b) => Number(a.priority) - Number(b.priority));
      subs = [...high];
    }
    subs = subs.flat();
    return subs;
  };
  // pg,
  //   spaceClicked,
  //   questionnaireData.page_number,
  //   roomsClicked,
  //   questionnaireData,

  //   setQnsPosted(true);
  //   storeTab("questionnaire2");
  //   post_questionnaire(body);

  const handleSubmit = async e => {
    try {
      storeMx({ ...questionnaireData.MxData, algorithm: { ...algoResponse } });
      // const tags = getSpaceTags(
      //   tileData.filter((d) => spaceClicked.includes(+d.id)),
      // );
      // let tagss = [...new Set(tags)];
      const body = {
        name: name,
        email: email,
        questionnaire: {
          space: spaceClickedData,
          style: styleClickedData,
          design: designClicked,
          rooms: roomsClicked,
          color: colorSelected,
          initial_room: roomsClicked.length < 2 ? roomsClicked[0] : intialRoomsClicked,
          styleResult: {
            bucketName: algoResponse.bucket,
            styles: algoResponse.subStyles,
            total: algoResponse.total,
            image: styleResultImage,
            description: resultStyleDescription
          }
        }
      };
      if (auth && auth.user) {
        body._id = auth.user._id;
      } else {
        body.name = name;
        body.email = email;
      }
      localStorage.setItem('MX-A-answers', JSON.stringify(body));
      storeMxAns({ ...body });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`,
        body
      );
      let MX_A = {
        questionnaire: {
          MX_A_SPACE: spaceClickedData,
          MX_A_STYLE: styleClickedData,
          MX_A_COLOR_TONE: colorSelected,
          MX_A_DESIGN: designClicked,
          MX_A_ROOMS: roomsClicked,
          MX_A_INITAL_ROOM: roomsClicked.length < 2 ? roomsClicked[0] : intialRoomsClicked,
          MX_A_STYLE_RESULT: {
            bucketName: algoResponse.bucket,
            styles: algoResponse.subStyles,
            total: algoResponse.total,
            image: styleResultImage,
            description: resultStyleDescription
          }
        }
      };
      if (auth && auth.user) {
        MX_A.user_id = auth.user._id;
        MX_A.customer_name = auth.user.name;
      }
      localStorage.setItem('MX-A-questionnaire', JSON.stringify(MX_A));
      if (projectId) {
        await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${projectId}`,
          MX_A
        );
      }
      // setQnsPosted(true);
      storeTab('questionnaire2');
      post_questionnaire(body);
    } catch (err) {
      alert(err);
    }
  };

  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
  const imageSrc = (algo, index) => {
    const algo1 = algo.subStyles[index];
    let currentImage = resultImages.filter(function(obj) {
      return obj.tags[0] === algo1.name.toLowerCase();
    });

    if (currentImage) return currentImage[0].src;
  };

  const getDonutData = () => {
    let data = [];
    let data1 = [];
    let sub1;
    let sub2;
    let sub3;
    // ConsoleHelper(questionnaireData);
    // ConsoleHelper(algoResponse);
    if (finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated') {
      sub1 = algoResponse?.subStyles[0]?.name
        ?.replace('_', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    if (
      algoResponse?.subStyles[1] &&
      (finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated')
    ) {
      sub2 = algoResponse?.subStyles[1]?.name
        ?.replace('_', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    if (
      algoResponse?.subStyles[2] &&
      (finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated')
    ) {
      sub3 = algoResponse?.subStyles[2]?.name
        ?.replace('_', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    let val1 =
      finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
        ? ((algoResponse?.subStyles[0]?.value / algoResponse.total) * 100).toFixed(0)
        : 33;
    let val2 =
      finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
        ? ((algoResponse?.subStyles[1]?.value / algoResponse.total) * 100).toFixed(0)
        : 33;
    let val3 =
      finalResponse !== null || questionnaireData.questionnaire1 === 'resultCalculated'
        ? ((algoResponse?.subStyles[2]?.value / algoResponse.total) * 100).toFixed(0)
        : 33;
    let lab = [sub1];
    let vals = [val1];
    data = [
      {
        type: 'pie',
        indexLabel: '{label}: {y}%',
        startAngle: -90,
        dataPoints: [
          { y: 20, label: 'Airfare' },
          { y: 24, label: 'Food & Drinks' },
          { y: 20, label: 'Accomodation' },
          { y: 14, label: 'Transportation' },
          { y: 12, label: 'Activities' },
          { y: 10, label: 'Misc' }
        ]
      }
    ];
    data1 = [
      {
        x: `${val1 + '% ' + sub1}`,
        y: parseInt(val1)
      }
    ];
    if (val2 > 0) {
      lab.push(sub2);
      vals.push(val2);
      data1 = [...data1, { x: `${val2 + '% ' + sub2}`, y: parseInt(val2) }];
    }
    if (val3 > 0) {
      lab.push(sub3);
      vals.push(val3);
      data1 = [...data1, { x: `${val3 + '% ' + sub3}`, y: parseInt(val3) }];
    }

    let data4 = {
      labels: lab,
      pointBorderColor: '#444',
      pointBackgroundColor: '#444',
      datasets: [
        {
          // label: 'Sales',
          backgroundColor: ['#AA381C', '#386D73', '#313131'],
          // pointStyle : line,
          // borderColor: 'rgba(0,0,0,1)',
          borderWidth: 1,
          innerWidth: '1px',
          data: vals
        }
      ]
    };
    return data4;
  };

  const onShareClick = async () => {
    setStyleResultLoading(true);
    let url = auth?.user?._id
      ? `${window.location.host}/share-style-result/${auth?.user?._id}/0`
      : `${window.location.host}/share-style-result/0/${styleResultId}`;

    setCopiedUrl(url);
    // setShareModal(true);
    if (auth?.user?._id) {
      const shareStyleResult = {
        ...algoResponse,
        image: styleResultImage,
        description: resultStyleDescription
      };
      try {
        await customerApiInstance.put(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/share-style/${auth.user._id}`,
          { shareStyleResult }
        );
        setCopiedUrl(`${window.location.host}/share-style-result/${auth?.user?._id}/0`);
      } catch (err) {}
      setStyleResultLoading(false);
    } else {
      const shareStyleResult = {
        ...algoResponse,
        image: styleResultImage,
        description: resultStyleDescription
      };
      try {
        ConsoleHelper('IM in', questionnaireData.MxData.email, questionnaireData.MxData.name);
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/share-style`, {
          shareStyleResult,
          name,
          email
        });
        if (res.status === 200) {
          const { styleResult } = res.data;
          setStyleResultId(styleResult._id);
          setCopiedUrl(`${window.location.host}/share-style-result/0/${styleResult._id}`);
        }
      } catch (err) {}
      setStyleResultLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <div className={style.container}>
        <TopBar pg={pg} setPg={setPg} skip={skip} />
        {currentData && currentData.type === 'lottie_welcome' && (
          <>
            <Lottie
              options={defaultWelcomeLottieOptions}
              height={dimension[0] > 500 ? '80vh' : '100%'}
              width={dimension[0] > 500 ? '500px' : '100%'}
            />
            <p className={style.welcomeLottie}>{name}</p>
          </>
        )}

        {currentData && currentData.type === 'lottie_wait' && (
          <div style={{ marginTop: '6%' }}>
            <Lottie
              options={defaultWaitLottieOptions}
              height={dimension[0] > 500 ? '60vh' : '100%'}
              width={dimension[0] > 500 ? '350px' : '100%'}
            />
            <p style={{ textAlign: 'center', fontSize: '16px' }}>
              <i>Please wait while your style result is loading...</i>
            </p>
          </div>
        )}

        {/* {currentData && currentData.type === 'lottie_fewmore' && (
          <>
            {dimension[0] > 500 ? (
              <div style={{ margin: '60px auto 0 auto' }}>
                <Lottie
                  options={defaultFewmoreLottieOptions}
                  height={dimension[0] > 500 ? '80vh' : '100%'}
                  width={dimension[0] > 500 ? '800px' : '100%'}
                />
              </div>
            ) : (
              <div style={{ margin: 'auto' }}>
                <Lottie
                  options={defaultFewmoreLottieOptions}
                  height={dimension[0] > 500 ? '80vh' : '100%'}
                  width={dimension[0] > 500 ? '800px' : '100%'}
                />
              </div>
            )}

            <p className={dimension[0] > 500 ? style.fewMore : style.fewMoreMobile}>
              <i>We're going to ask you a few more questions to find just the right fit.</i>
            </p>
          </>
        )} */}
        {currentData &&
          currentData.type &&
          currentData.type === 'lottie_fewmore' &&
          (dimension && dimension.length > 0 && dimension[0] > 500 ? (
            <div style={{ margin: '60px auto 0 auto' }}>
              <Lottie
                options={defaultFewmoreLottieOptions}
                height={dimension[0] > 500 ? '80vh' : '100%'}
                width={dimension[0] > 500 ? '800px' : '100%'}
              />
              <p className={dimension[0] > 500 ? style.fewMore : style.fewMoreMobile}>
                <i>We're going to ask you a few more questions to find just the right fit.</i>
              </p>
            </div>
          ) : (
            <div style={{ margin: 'auto' }}>
              <Lottie
                options={defaultFewmoreLottieOptions}
                height={dimension[0] > 500 ? '80vh' : '100%'}
                width={dimension[0] > 500 ? '800px' : '100%'}
              />
              <p className={dimension[0] > 500 ? style.fewMore : style.fewMoreMobile}>
                <i>We're going to ask you a few more questions to find just the right fit.</i>
              </p>
            </div>
          ))}

        {currentData && currentData.type === 'result' ? (
          <>
            <div className={style.resultContainer1}>
              <form className="form-row">
                <div className="row  m-2">
                  {dimension[0] > 500 && (
                    <div className="col-lg-6 d-flex flex-column pt-4 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src={bucket.reduce((imageUrl, obj) => {
                          if (obj.title === algoResponse?.bucket) {
                            imageUrl = obj.src;
                          }
                          return imageUrl;
                        }, '')}
                        className="image-fluid"
                        style={{ width: '80%', height: 'auto' }}
                        alt=""
                      />
                    </div>
                  )}
                  <div className="col-sm-12  col-lg-6 d-flex flex-column ">
                    {dimension[0] > 500 && (
                      <>
                        <div className="row form-row mt-1 ">
                          <div
                            className="col-6 pt-4 d-flex flex-column align-items-center justify-content-center"
                            style={{ height: '100%' }}>
                            <div
                              style={{
                                backgroundColor: 'rgb(160 77 36 / 13%)',
                                borderRadius: '10px',
                                height: '100%'
                              }}>
                              {algoResponse && algoResponse.subStyles[0] && (
                                <div>
                                  <img
                                    src={imageSrc(algoResponse, 0)}
                                    className="justify-content-center mt-4 p-2 "
                                    style={{
                                      width: '35%',
                                      height: 'auto',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      opacity: '1',
                                      marginTop: '45px',
                                      marginLeft: '30%',
                                      marginRight: '30%'
                                    }}
                                    alt=""
                                  />
                                </div>
                              )}
                              <div>
                                <span
                                  className="d-flex  justify-content-center"
                                  style={{ opacity: '1', color: '#151515' }}>
                                  {' '}
                                  {}
                                  {finalResponse !== null ||
                                  questionnaireData.questionnaire1 === 'resultCalculated'
                                    ? algoResponse?.subStyles[0]?.name
                                        ?.replace('_', ' ')
                                        .replace(/(^\w{1})|(\s+\w{1})/g, letter =>
                                          letter.toUpperCase()
                                        )
                                    : 'North indian'}
                                </span>
                              </div>
                              <h4>
                                <span
                                  className="d-flex mt-2  justify-content-center"
                                  style={{
                                    color: '#A04D24',
                                    opacity: '1',
                                    fontFamily: 'QuickSand',
                                    fontSize: '15px',
                                    marginBottom: '15px'
                                  }}>
                                  YOUR DOMINANT STYLE
                                </span>
                              </h4>
                              <p
                                className="pl-4 pr-4 pb-3"
                                style={{
                                  textAlign: 'left',
                                  fontSize: '14px',
                                  opacity: '1',
                                  fontFamily: 'Quicksand'
                                }}>
                                {resultImages.map(function(obj, i) {
                                  if (obj.tags[0] === algoResponse?.subStyles[0]?.name) {
                                    // ConsoleHelper(
                                    //   { obj },
                                    //   algoResponse?.subStyles[0]?.name
                                    // );
                                    return obj.description;
                                  } else {
                                    return null;
                                  }
                                })}
                              </p>
                            </div>
                            {/* </div> */}
                          </div>
                          <div className=" pl-4 pt-4 col-6 ">
                            <div className="row justify-content-center d-flex">
                              <div style={{ height: '100%', width: '75%' }}>
                                <Doughnut
                                  data={getDonutData()}
                                  width={25}
                                  height={50}
                                  innerRadius={10}
                                  options={{
                                    animationEnabled: true,
                                    exportEnabled: true,
                                    // plugins : ,
                                    cutout: 100,
                                    cutoutPercentage: 150,
                                    plugins: {
                                      legend: {
                                        display: false,
                                        usePointStyle: true
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="row mt-2 justify-content-center align-items-center"
                              style={{
                                color: '#A04D24',
                                fontSize: 'inherit',
                                fontFamily: 'Quicksand'
                              }}>
                              {finalResponse !== null ||
                              questionnaireData.questionnaire1 === 'resultCalculated'
                                ? (
                                    (algoResponse.subStyles[0]?.value / algoResponse.total) *
                                    100
                                  ).toFixed(0)
                                : '33'}
                              %{' '}
                              {finalResponse !== null ||
                              questionnaireData.questionnaire1 === 'resultCalculated'
                                ? algoResponse?.subStyles[0]?.name
                                    ?.replace('_', ' ')
                                    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                                : 'North indian'}
                            </div>
                            <div
                              className="row mt-2 justify-content-center align-items-center"
                              style={{
                                color: '#627274',
                                fontSize: 'inherit',
                                fontFamily: 'Quicksand'
                              }}>
                              {finalResponse !== null ||
                              questionnaireData.questionnaire1 === 'resultCalculated'
                                ? (
                                    (algoResponse.subStyles[1]?.value / algoResponse.total) *
                                    100
                                  ).toFixed(0)
                                : '33'}
                              %{' '}
                              {finalResponse !== null ||
                              questionnaireData.questionnaire1 === 'resultCalculated'
                                ? algoResponse?.subStyles[1]?.name
                                    ?.replace('_', ' ')
                                    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                                : 'North indian'}
                            </div>
                            {algoResponse.subStyles[2] && (
                              <div
                                className="row  mt-2 justify-content-center align-items-center"
                                style={{
                                  color: '#313131',
                                  fontSize: 'inherit',
                                  fontFamily: 'Quicksand'
                                }}>
                                {finalResponse !== null ||
                                questionnaireData.questionnaire1 === 'resultCalculated'
                                  ? (
                                      (algoResponse.subStyles[2]?.value / algoResponse.total) *
                                      100
                                    ).toFixed(0)
                                  : '33'}
                                %{' '}
                                {finalResponse !== null
                                  ? algoResponse?.subStyles[2]?.name
                                      ?.replace('_', ' ')
                                      .replace(/(^\w{1})|(\s+\w{1})/g, letter =>
                                        letter.toUpperCase()
                                      )
                                  : 'North indian'}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </form>
              {auth?.user?._id ? (
                <input
                  ref={copy}
                  type="hidden"
                  value={`${window.location.host}/share-style-result/${auth.user._id}/0`}
                />
              ) : styleResultId ? (
                <input
                  ref={copy}
                  type="hidden"
                  value={`${window.location.host}/pre-login/share-style-result/0/${styleResultId}`}
                />
              ) : null}

              <div className="row mt-4 p-2 mb-5">
                <div className="col-lg-6">
                  <div className={style.basedHead} style={{ width: '80%', margin: '0px auto' }}>
                    <h4
                      style={{
                        textAlign: 'left',
                        marginLeft: '0px',
                        fontFamily: 'Quicksand',
                        fontSize: '25px'
                      }}>
                      Based on all the information gathered, we’ve deduced that your main style is{' '}
                      <span style={{ color: 'brown' }}>
                        {algoResponse?.bucket} {'!!'}
                      </span>
                    </h4>
                    {dimension[0] < 500 && (
                      <div className="col-sm-12 d-flex flex-column p-1 mt-3 d-flex flex-column align-items-center justify-content-center mb-3">
                        <img
                          className={style.subBanner}
                          src={bucket.reduce((imageUrl, obj) => {
                            if (obj.title === algoResponse?.bucket) {
                              imageUrl = obj.src;
                            }
                            return imageUrl;
                          }, '')}
                          style={{ width: '70%', height: '90%' }}
                          alt=""
                        />
                      </div>
                    )}

                    <div
                      className="row"
                      style={{
                        textAlign: 'left',
                        marginLeft: '0px',
                        fontFamily: 'Quicksand',
                        fontSize: '25px'
                      }}>
                      <h4>More about your style</h4>
                    </div>

                    <div
                      className={style.moreTxt}
                      style={{
                        textAlign: 'left',
                        marginLeft: '0px',
                        fontFamily: 'Quicksand',
                        fontSize: '17'
                      }}>
                      {bucket.map(function(obj, i) {
                        if (obj.title === algoResponse?.bucket) return obj.description;
                      })}
                    </div>

                    <div
                      className="row mt-4"
                      style={{ textAlign: 'left', fontFamily: 'Quicksand' }}
                      // onClick={() => onShareClick()}
                    >
                      <p
                        style={{
                          marginLeft: '15px'
                        }}>
                        Share your style
                      </p>

                      {/* <div>
                      <img
                        onClick={() => onShareClick()}
                        style={{ display: dimension[0] > 500 ? '' : 'none' }}
                        src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2425.svg'} alt='' />
                    </div> */}
                      {/* <div
                        style={{ marginLeft: "20px" }}
                        onClick={() => onShareClick()}
                      >
                        <img
                          style={{ width: "8px" }}
                          src={
                            "https://d3izcfql7lesks.cloudfront.net/Icon_awesome-facebook-f_ua3ucy.svg"
                          }
                          alt=""
                        />
                        <img
                          style={{ width: "14px", marginLeft: "10px" }}
                          src={
                            "https://d3izcfql7lesks.cloudfront.net/Icon_awesome-linkedin-in_w7y4qi.svg"
                          }
                          alt=""
                        />
                      </div> */}
                      <div style={{ marginLeft: '10px' }}>
                        <Shareurl fabdirection="right" copiedUrl={copiedUrl} />
                      </div>
                    </div>
                  </div>
                </div>
                {dimension[0] < 500 && (
                  <>
                    <div className="row">
                      <div className="col  ml-5 d-flex flex-column p-1 d-flex flex-column align-items-right justify-content-right mb-3">
                        {/* <div style={{ "height": "100%", width: "75%" }}> */}
                        <Doughnut
                          data={getDonutData()}
                          width={100}
                          height={70}
                          innerRadius={10}
                          options={{
                            cutout: 40,
                            cutoutPercentage: 90,
                            plugins: {
                              legend: {
                                display: false
                              }
                            }
                          }}
                        />
                        {/* </div> */}
                      </div>
                    </div>

                    <div className="col-6 mt-2">
                      <div
                        className="row  ml-2"
                        style={{
                          color: '#A04D24',
                          fontSize: 'inherit',
                          fontFamily: 'Quicksand'
                        }}>
                        {finalResponse !== null ||
                        questionnaireData.questionnaire1 === 'resultCalculated'
                          ? ((algoResponse.subStyles[0]?.value / algoResponse.total) * 100).toFixed(
                              0
                            )
                          : '33'}
                        %{' '}
                        {finalResponse !== null ||
                        questionnaireData.questionnaire1 === 'resultCalculated'
                          ? algoResponse?.subStyles[0]?.name
                              ?.replace('_', ' ')
                              .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                          : 'North indian'}
                      </div>

                      <div
                        className="row  ml-2"
                        style={{
                          color: '#627274',
                          fontSize: 'inherit',
                          fontFamily: 'Quicksand'
                        }}>
                        {finalResponse !== null ||
                        questionnaireData.questionnaire1 === 'resultCalculated'
                          ? ((algoResponse.subStyles[1]?.value / algoResponse.total) * 100).toFixed(
                              0
                            )
                          : '33'}
                        %{' '}
                        {finalResponse !== null ||
                        questionnaireData.questionnaire1 === 'resultCalculated'
                          ? algoResponse?.subStyles[1]?.name
                              ?.replace('_', ' ')
                              .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                          : 'North indian'}
                      </div>
                      <div
                        className="row  ml-2"
                        style={{
                          color: '#313131',
                          fontSize: 'inherit',
                          fontFamily: 'Quicksand'
                        }}>
                        {finalResponse !== null ||
                        questionnaireData.questionnaire1 === 'resultCalculated'
                          ? ((algoResponse.subStyles[2]?.value / algoResponse.total) * 100).toFixed(
                              0
                            )
                          : '33'}
                        %{' '}
                        {finalResponse !== null ||
                        questionnaireData.questionnaire1 === 'resultCalculated'
                          ? algoResponse?.subStyles[2]?.name
                              ?.replace('_', ' ')
                              .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                          : 'North indian'}
                      </div>
                    </div>
                    <div
                      className="row justify-content-center ml-3 mr-3 d-flex flex-column mt-2 p-1 d-flex flex-column align-items-right justify-content-right mb-3"
                      style={{
                        width: '100%',
                        height: '50%',
                        paddingLeft: '10px'
                      }}>
                      <div
                        className=" p-0 "
                        style={{
                          backgroundColor: 'rgb(222 201 191)',
                          borderRadius: '10px',
                          height: '94%'
                        }}>
                        {algoResponse && algoResponse.subStyles[0] && (
                          <div className={style.boxImg}>
                            <img
                              src={imageSrc(algoResponse, 0)}
                              className="justify-content-center"
                              style={{
                                width: '25%',
                                height: 'auto',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginTop: '45px',
                                marginLeft: '40%',
                                marginRight: '30%'
                              }}
                              alt=""
                            />
                          </div>
                        )}
                        <div>
                          <span className="d-flex  justify-content-center">
                            {' '}
                            {}
                            {finalResponse !== null ||
                            questionnaireData.questionnaire1 === 'resultCalculated'
                              ? algoResponse?.subStyles[0]?.name
                                  ?.replace('_', ' ')
                                  .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                              : 'North indian'}
                          </span>
                        </div>
                        <h4>
                          <span
                            className={`d-flex mt-2 pl-4  justify-content-center ${style.headDomain}`}
                            style={{
                              color: '#A04D24',
                              fontFamily: 'QuickSand'
                            }}>
                            YOUR DOMINANT STYLE
                          </span>
                        </h4>
                        <p
                          className={`pl-4 pr-4 pb-3 ${style.paraTxt}`}
                          style={{
                            textAlign: 'left',
                            fontSize: '14px',
                            fontFamily: 'Quicksand'
                          }}>
                          {resultImages.map(function(obj, i) {
                            if (obj.tags[0] === algoResponse?.subStyles[1]?.name)
                              return obj.description;
                          })}
                        </p>
                      </div>
                      <hr></hr>
                    </div>

                    <div className="row">
                      {algoResponse.subStyles[1] && (
                        <div className={classnames('col ', style.resultList)}>
                          <div className="row form-row justify-content-center">
                            <div
                              className={classnames(
                                'col-7 pr-0 pt-1  mr-0 justify-content-center align-items-center',
                                style.mx5
                              )}>
                              {algoResponse && algoResponse.subStyles[1] && (
                                <img
                                  src={imageSrc(algoResponse, 1)}
                                  className="justify-content-center img-responsive  fit-image"
                                  style={{ width: '70%', borderRadius: '10px' }}
                                  alt=""
                                />
                              )}
                              <div></div>
                              <div className="row justify-content-center align-items-center">
                                <span
                                  className={`d-flex justify-content-center ${style.span}`}
                                  style={{
                                    fontSize: '17px',
                                    fontFamily: 'Quicksand'
                                  }}>
                                  {' '}
                                  {}
                                  {finalResponse !== null ||
                                  questionnaireData.questionnaire1 === 'resultCalculated'
                                    ? algoResponse?.subStyles[1]?.name
                                        ?.replace('_', ' ')
                                        .replace(/(^\w{1})|(\s+\w{1})/g, letter =>
                                          letter.toUpperCase()
                                        )
                                    : 'North indian'}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center ml-4 mr-4">
                            <p
                              style={{
                                textAlign: 'left',
                                fontSize: '14px',
                                fontFamily: 'Quicksand'
                              }}>
                              {resultImages.map(function(obj, i) {
                                if (obj.tags[0] === algoResponse?.subStyles[1]?.name)
                                  return obj.description;
                              })}
                            </p>
                          </div>
                        </div>
                      )}
                      <hr></hr>
                    </div>

                    <div className="row">
                      {algoResponse.subStyles[1] && (
                        <div className={classnames('col ', style.resultList)}>
                          <div className="row form-row justify-content-center">
                            <div
                              className={classnames(
                                'col-7 pr-0 pt-1  mr-0 justify-content-center align-items-center',
                                style.mx5
                              )}>
                              {algoResponse && algoResponse.subStyles[2] && (
                                <img
                                  src={imageSrc(algoResponse, 2)}
                                  className="justify-content-center img-responsive  fit-image"
                                  style={{ width: '70%', borderRadius: '10px' }}
                                  alt=""
                                />
                              )}
                              <div></div>
                              <div className="row justify-content-center align-items-center">
                                <span
                                  className={`d-flex ${style.span}`}
                                  style={{
                                    fontSize: '17px',
                                    fontFamily: 'Quicksand'
                                  }}>
                                  {' '}
                                  {}
                                  {finalResponse !== null ||
                                  questionnaireData.questionnaire1 === 'resultCalculated'
                                    ? algoResponse?.subStyles[2]?.name
                                        ?.replace('_', ' ')
                                        .replace(/(^\w{1})|(\s+\w{1})/g, letter =>
                                          letter.toUpperCase()
                                        )
                                    : 'North indian'}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center ml-4 mr-4">
                            <p
                              style={{
                                textAlign: 'left',
                                fontSize: '14px',
                                fontFamily: 'Quicksand'
                              }}>
                              {resultImages.map(function(obj, i) {
                                if (obj.tags[0] === algoResponse?.subStyles[2]?.name)
                                  return obj.description;
                              })}
                            </p>
                          </div>
                        </div>
                      )}
                      <hr></hr>
                    </div>
                  </>
                )}
                {dimension[0] > 500 && (
                  <div className="col-lg-6 ">
                    <div className="row">
                      {algoResponse.subStyles[1] && (
                        <div className={classnames('col ', style.resultList)}>
                          <div className="row form-row" style={{ height: '45px' }}>
                            <div
                              className={classnames(
                                'col-4 pr-0 pt-1 justify-content-center align-items-center',
                                style.mx5
                              )}>
                              {algoResponse && algoResponse.subStyles[1] && (
                                <img
                                  src={imageSrc(algoResponse, 1)}
                                  className="img-responsive  fit-image "
                                  style={{
                                    width: '100%',
                                    borderRadius: '10px'
                                  }}
                                  alt=""
                                />
                              )}
                              <div>
                                <span
                                  className="d-flex  justify-content-center p-2"
                                  style={{
                                    fontSize: '17px',
                                    fontFamily: 'Quicksand'
                                  }}>
                                  {' '}
                                  {}
                                  {finalResponse !== null ||
                                  questionnaireData.questionnaire1 === 'resultCalculated'
                                    ? algoResponse?.subStyles[1]?.name
                                        ?.replace('_', ' ')
                                        .replace(/(^\w{1})|(\s+\w{1})/g, letter =>
                                          letter.toUpperCase()
                                        )
                                    : 'North indian'}
                                </span>
                              </div>
                            </div>
                            <div className="col-8 mt-2 pl-3">
                              <p
                                style={{
                                  textAlign: 'left',
                                  fontSize: '14px',
                                  fontFamily: 'Quicksand'
                                }}>
                                {resultImages.map(function(obj, i) {
                                  if (obj.tags[0] === algoResponse?.subStyles[1]?.name)
                                    return obj.description;
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {algoResponse.subStyles[2] && (
                        <div className={classnames('col ', style.resultList)}>
                          <div className="row form-row" style={{ height: '45px' }}>
                            <div
                              className={classnames(
                                'col-4 pt-1 mr-0 pr-0 justify-content-center align-items-center',
                                style.mx5
                              )}>
                              {algoResponse && algoResponse.subStyles[2] && (
                                <img
                                  src={imageSrc(algoResponse, 2)}
                                  className="img-responsive fit-image"
                                  style={{
                                    width: '100%',
                                    borderRadius: '10px'
                                  }}
                                  alt=""
                                />
                              )}
                              <div>
                                <span
                                  className="d-flex  pl-3 justify-content-center p-2"
                                  style={{
                                    fontSize: '17px',
                                    fontFamily: 'Quicksand'
                                  }}>
                                  {finalResponse !== null ||
                                  questionnaireData.questionnaire1 === 'resultCalculated'
                                    ? algoResponse?.subStyles[2]?.name
                                        ?.replace('_', ' ')
                                        .replace(/(^\w{1})|(\s+\w{1})/g, letter =>
                                          letter.toUpperCase()
                                        )
                                    : 'North indian'}
                                </span>
                              </div>
                            </div>
                            <div className="col-7 mt-2 pl-3 ">
                              <p
                                className=" ml-0"
                                style={{
                                  textAlign: 'left',
                                  fontSize: '14px',
                                  fontFamily: 'Quicksand'
                                }}>
                                {resultImages.map(function(obj, i) {
                                  if (obj.tags[0] === algoResponse?.subStyles[2]?.name)
                                    return obj.description;
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* } */}
          </>
        ) : currentData.type === 'lottie_wait' ||
          currentData.type === 'lottie_welcome' ||
          currentData.type === 'lottie_fewmore' ? (
          ''
        ) : (
          <div className="container" style={{ maxWidth: '100%', backgroundColor: 'white' }}>
            <div
              className="row"
              style={{
                height:
                  currentData &&
                  currentData.type !== 'space' &&
                  currentData &&
                  currentData.type !== 'style' &&
                  currentData &&
                  currentData.type !== 'color' &&
                  '87vh'
              }}>
              <div
                className="col-sm-12 col-md-12 col-lg-6  d-flex flex-column align-items-center justify-content-center"
                style={{ height: dimension[0] < 500 ? '150px' : 'auto' }}>
                {currentData && currentData.type !== 'result' ? (
                  <div className={style.header}>
                    <h1> {currentData.title && currentData.title} </h1>
                    <span className={style.subtitle}>{currentData.content}</span>
                  </div>
                ) : null}
              </div>

              <div
                className={classnames(
                  'col-sm-12 col-lg-6 d-flex flex-column  align-items-center justify-content-center',
                  style.imageContainer
                )}
                style={{
                  marginTop:
                    currentData &&
                    currentData.type === ('name' || 'email') &&
                    dimension[0] < 500 &&
                    '50vh !important',
                  height: dimension[1] - 100,

                  backgroundColor:
                    ((currentData && currentData.type === 'name') ||
                      (currentData && currentData.type === 'email')) &&
                    '#E5F9FF'
                }}>
                {currentData && currentData.type === 'space' && (
                  <ResponsiveMasonry
                    className={style.mesonry}
                    columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 3 }}>
                    <Masonry>
                      {tileData.map((image, i) => (
                        <div style={{ position: 'relative' }} key={i}>
                          {spaceClicked.includes(image.id) && (
                            <img
                              style={{
                                position: 'absolute',
                                right: dimension[0] > 500 ? '1vw' : '3vw',
                                top: 10,
                                height: 18
                              }}
                              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                              alt="tick"
                            />
                          )}

                          <LazyLoadImage
                            key={i}
                            loading="lazy"
                            src={image.src}
                            className={style.spaceGirdImg}
                            scrollPosition={scrollPosition}
                            style={{ cursor: 'pointer' }}
                            alt=""
                            onClick={() => {
                              handleSpaceClicked(image);
                              handleSpaceSelected(image.id);
                            }}
                          />
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                )}

                {currentData && currentData.type === 'style' && (
                  <div className={style.styleContainer}>
                    {styleData.map((styl, i) => (
                      <div
                        key={i}
                        style={{ cursor: 'pointer' }}
                        className={classnames(
                          'row pt-3 pb-3',
                          styleClicked.includes(styl.value) && style.styleAcive
                        )}
                        onClick={() => {
                          handleStyleClicked(styl);
                          handleStyleSelected(styl.value);
                        }}>
                        {styleClicked.includes(styl.value) && (
                          <img
                            className={style.styleTick}
                            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                            alt="tick"
                          />
                        )}

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          className="col-sm-12 col-md-5 p-0 text-center ml-md-3">
                          <img
                            src={styl.img}
                            className={style.styleImg}
                            alt={styl.title}
                            style={{
                              cursor: 'pointer',
                              objectFit: 'cover'
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mt-3 text-left mt-2">
                          <h4 style={{ fontWeight: 400, marginBottom: '10px' }}>{styl.title}</h4>
                          <div style={{ fontSize: 14 }}>{parse(styl.content)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {currentData && currentData.type === 'color' && (
                  <div className={style.colorContainer}>
                    <div className="row d-flex align-items-center justify-content-center m-0 w-100">
                      <div
                        className="col-6 text-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setColorSelected(color[0].name);
                          setColorClicked(color[0].img);
                          storeMx({
                            MX_A_SPACE: spaceClicked,
                            MX_A_STYLE: styleClicked,
                            MX_A_COLOR_TONE: color[0].name,
                            MX_A_DESIGN: designClicked,
                            MX_A_ROOMS: roomsClicked,
                            MX_A_SPACE_CLICKED: spaceClickedData,
                            MX_A_STYLE_CLICKED: styleClickedData,
                            MX_A_INITAL_ROOM:
                              roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
                            name: name,
                            email: email,
                            projId: projectId ? projectId : '',
                            styleDescription: resultStyleDescription,
                            styleImage: styleResultImage
                          });
                        }}>
                        {colorSelected === color[0].name && (
                          <img
                            className={style.roomDesignClick}
                            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                            alt="tick"
                          />
                        )}
                        <img src={color[0].img} alt="" className={style.colorImg} />
                        <p className="mt-2">Warm</p>
                      </div>
                      <div
                        className="col-6 text-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setColorSelected(color[1].name);
                          setColorClicked(color[1].img);
                          storeMx({
                            MX_A_SPACE: spaceClicked,
                            MX_A_STYLE: styleClicked,
                            MX_A_COLOR_TONE: color[1].name,
                            MX_A_SPACE_CLICKED: spaceClickedData,
                            MX_A_STYLE_CLICKED: styleClickedData,
                            MX_A_DESIGN: designClicked,
                            MX_A_ROOMS: roomsClicked,
                            MX_A_INITAL_ROOM:
                              roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
                            name: name,
                            email: email,
                            projId: projectId ? projectId : '',
                            styleDescription: resultStyleDescription,
                            styleImage: styleResultImage
                          });
                        }}>
                        {colorSelected === color[1].name && (
                          <img
                            className={style.roomDesignClick}
                            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                            alt="tick"
                          />
                        )}
                        <img src={color[1].img} alt="" className={style.colorImg} />
                        <p className="mt-2">Cool</p>
                      </div>
                    </div>
                    <div className=" row d-flex align-items-center justify-content-center m-0 w-100">
                      <div
                        className="col-6 text-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setColorSelected(color[2].name);
                          setColorClicked(color[2].img);
                          storeMx({
                            MX_A_SPACE: spaceClicked,
                            MX_A_STYLE: styleClicked,
                            MX_A_COLOR_TONE: color[2].name,
                            MX_A_DESIGN: designClicked,
                            MX_A_SPACE_CLICKED: spaceClickedData,
                            MX_A_STYLE_CLICKED: styleClickedData,
                            MX_A_ROOMS: roomsClicked,
                            MX_A_INITAL_ROOM:
                              roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
                            name: name,
                            email: email,
                            projId: projectId ? projectId : '',
                            styleDescription: resultStyleDescription,
                            styleImage: styleResultImage
                          });
                        }}>
                        {colorSelected === color[2].name && (
                          <img
                            className={style.roomDesignClick}
                            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                            alt="tick"
                          />
                        )}
                        <img src={color[2].img} alt="" className={style.colorImg} />
                        <p className="mt-2">Neutrals</p>
                      </div>
                      <div
                        className="col-6 text-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setColorSelected(color[3].name);
                          setColorClicked(color[3].img);
                          storeMx({
                            MX_A_SPACE: spaceClicked,
                            MX_A_STYLE: styleClicked,
                            MX_A_COLOR_TONE: color[3].name,
                            MX_A_DESIGN: designClicked,
                            MX_A_ROOMS: roomsClicked,
                            MX_A_SPACE_CLICKED: spaceClickedData,
                            MX_A_STYLE_CLICKED: styleClickedData,
                            MX_A_INITAL_ROOM:
                              roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
                            name: name,
                            email: email,
                            projId: projectId ? projectId : '',
                            styleDescription: resultStyleDescription,
                            styleImage: styleResultImage
                          });
                        }}>
                        {colorSelected === color[3].name && (
                          <img
                            className={style.roomDesignClick}
                            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png'}
                            alt="tick"
                          />
                        )}
                        <img src={color[3].img} alt="" className={style.colorImg} />
                        <p className="mt-2">Pops</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* {currentData && currentData.type === "design" && (
                  <ResponsiveMasonry
                    className={style.mesonryDesign}
                    columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 3 }}
                  >
                    <Masonry>
                      {design.map((image, i) => (
                        <div style={{ position: "relative" }} key={i}>
                          {designClicked === image.title && (
                            <img
                              className={style.designClick}
                              src={
                                "https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png"
                              }
                              alt="tick"
                            />
                          )}
                          <div className="d-flex flex-column align-items-center justify-content-evenly">
                            <div
                              className={classnames(
                                style.designIconContainer,
                                designClicked === image.title &&
                                  style.designIconContainerActive
                              )}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // setDesignClicked(image.title);
                                setDesignClicked("");
                                storeMx({
                                  MX_A_SPACE: spaceClicked,
                                  MX_A_STYLE: styleClicked,
                                  MX_A_COLOR_TONE: colorSelected,
                                  MX_A_DESIGN: image.title,
                                  MX_A_ROOMS: roomsClicked,
                                  MX_A_INITAL_ROOM:
                                    roomsClicked.length === 1
                                      ? roomsClicked[0]
                                      : intialRoomsClicked,
                                  name: name,
                                  MX_A_SPACE_CLICKED: spaceClickedData,
                                  MX_A_STYLE_CLICKED: styleClickedData,
                                  email: email,
                                  projId: projectId ? projectId : "",
                                  styleDescription: resultStyleDescription,
                                  styleImage: styleResultImage,
                                });
                              }}
                            >
                              <img
                                className={style.designIcon}
                                src={image.image}
                                alt=""
                              />
                            </div>
                            <p className="mt-2">{image.title}</p>
                          </div>
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                )} */}

                {currentData && currentData.type === 'rooms' && (
                  <ResponsiveMasonry
                    className={style.mesonryRooms}
                    columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 4 }}>
                    <Masonry>
                      {rooms.map((image, i) => (
                        <div style={{ position: 'relative' }} key={i}>
                          {/* {roomsClicked.includes(image.title) && (
                            <img
                              className={style.designClick}
                              src={
                                "https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png"
                              }
                              alt="tick"
                            />
                          )} */}
                          <div
                            className="d-flex flex-column align-items-center justify-content-evenly "
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleRoomsSelected(image.title);
                              // if (roomsClicked.length === 1) {
                              //   // MxData.questionnaire.MX_A_INITAL_ROOM = roomsClicked[0]
                              //   storeMx({ MX_A_SPACE: spaceClickedData, MX_A_STYLE: styleClickedData, MX_A_COLOR_TONE: colorSelected, MX_A_DESIGN: designClicked, MX_A_ROOMS: roomsClicked, MX_A_INITAL_ROOM: roomsClicked[0] })
                              // } else {
                              //   storeMx({ MX_A_SPACE: spaceClickedData, MX_A_STYLE: styleClickedData, MX_A_COLOR_TONE: colorSelected, MX_A_DESIGN: designClicked, MX_A_ROOMS: roomsClicked })
                              // }
                            }}>
                            <div
                              className={classnames(
                                style.designIconContainer,
                                roomsClicked.includes(image.title) &&
                                  style.designIconContainerActive
                              )}>
                              <img
                                className={style.designIcon}
                                src={image.image}
                                alt="dsfdsfsdfdsfdsfdsfdsfsdfdfd"
                                style={{ width: '90%' }}
                              />
                            </div>
                            <p className="mt-2">{image.title}</p>
                          </div>
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                )}

                {roomsClicked.length > 1 && currentData && currentData.type === 'intialRoom' && (
                  <ResponsiveMasonry
                    className={style.mesonryInitalRoom}
                    columnsCountBreakPoints={{ 250: 2, 750: 2, 900: 3 }}>
                    <Masonry>
                      {rooms
                        .filter(f => roomsClicked.includes(f.title))
                        // .filter((filter) => moreOptionClicked ? (filter.image.length !==0) : filter)
                        .map((image, i) => (
                          <div style={{ position: 'relative' }} key={i}>
                            {/* {intialRoomsClicked === image.title && (
                                <img
                                  className={style.designClick}
                                  src={
                                    "https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+3149.png"
                                  }
                                  alt="tick"
                                />
                              )} */}
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <div
                                className={classnames(
                                  style.designIconContainer,
                                  intialRoomsClicked === image.title &&
                                    style.designIconContainerActive
                                )}
                                onClick={() => {
                                  setInitalRoomsClicked(image.title);
                                  storeMx({
                                    MX_A_SPACE: spaceClicked,
                                    MX_A_STYLE: styleClicked,
                                    MX_A_SPACE_CLICKED: spaceClickedData,
                                    MX_A_STYLE_CLICKED: styleClickedData,
                                    MX_A_COLOR_TONE: colorSelected,
                                    MX_A_DESIGN: designClicked,
                                    MX_A_ROOMS: roomsClicked,
                                    MX_A_INITAL_ROOM: image.title,
                                    name: name,
                                    email: email,
                                    projId: projectId ? projectId : '',
                                    styleDescription: resultStyleDescription,
                                    styleImage: styleResultImage
                                  });
                                }}>
                                <img className={style.designIcon} src={image.image} />
                              </div>
                              <p className="mt-2">{image.title}</p>
                            </div>
                          </div>
                        ))}
                    </Masonry>
                    <p className="w-100 text-center mt-5" style={{ cursor: 'pointer' }}>
                      {moreOptionClicked && (
                        <u
                          onClick={() => {
                            setPg(prev => prev - 1);
                            setMoreOptionClicked(false);
                          }}>
                          View all options
                        </u>
                      )}
                    </p>
                  </ResponsiveMasonry>
                )}

                {currentData && currentData.type === 'name' && (
                  <div className={style.nameContainer}>
                    <div className="row justify-content-center">
                      <input
                        placeholder="Enter your name"
                        required={true}
                        value={name || ''}
                        onChange={e => {
                          setName(e.target.value);
                          storeMx({
                            MX_A_SPACE: spaceClicked,
                            MX_A_STYLE: styleClicked,
                            MX_A_SPACE_CLICKED: spaceClickedData,
                            MX_A_STYLE_CLICKED: styleClickedData,
                            MX_A_COLOR_TONE: colorSelected,
                            MX_A_DESIGN: designClicked,
                            MX_A_ROOMS: roomsClicked,
                            MX_A_INITAL_ROOM: roomsClicked[0],
                            name: e.target.value,
                            email: email,
                            projId: projectId ? projectId : '',
                            styleDescription: resultStyleDescription,
                            styleImage: styleResultImage
                          });
                        }}
                      />
                    </div>
                  </div>
                )}

                {currentData && currentData.type === 'email' && (
                  <>
                    <div className={style.nameContainer}>
                      <div className="row justify-content-center">
                        <form className={style.emailTextStyle}>
                          <input
                            placeholder="Enter your email id"
                            type="text"
                            required={true}
                            // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                            value={email || ''}
                            style={{ width: '100%' }}
                            onChange={e => {
                              // validateEmail()
                              emailError !== '' && setEmailError('');
                              setEmail(e.target.value);
                              storeMx({
                                MX_A_SPACE: spaceClicked,
                                MX_A_STYLE: styleClicked,
                                MX_A_COLOR_TONE: colorSelected,
                                MX_A_SPACE_CLICKED: spaceClickedData,
                                MX_A_STYLE_CLICKED: styleClickedData,
                                MX_A_DESIGN: designClicked,
                                MX_A_ROOMS: roomsClicked,
                                MX_A_INITAL_ROOM:
                                  roomsClicked.length === 1 ? roomsClicked[0] : intialRoomsClicked,
                                name: name,
                                email: e.target.value,
                                projId: projectId ? projectId : '',
                                styleDescription: resultStyleDescription,
                                styleImage: styleResultImage
                              });
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') e.preventDefault();
                            }}
                          />

                          <h6
                            style={{
                              fontWeight: 'bold',
                              color: 'red'
                            }}>
                            {emailError}
                          </h6>
                        </form>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <Footer
          ref={childRef}
          space={spaceClicked}
          style={styleClicked}
          pg={pg}
          setPg={setPg}
          complete={{}}
          disable={disabled}
          skip={skip}
          error1={error1}
          roomsClicked={roomsClicked}
          spaceClickedData={spaceClickedData}
          styleClickedData={styleClickedData}
          colorSelected={colorSelected}
          designClicked={designClicked}
          intialRoomsClicked={roomsClicked.length < 2 ? roomsClicked[0] : intialRoomsClicked}
          name={name}
          email={email}
          currentData={currentData}
          validateEmail={validateEmail}
        />
      </div>

      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={shareModal}
        onHide={() => setShareModal(!shareModal)}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}
        size="sm">
        <div>
          <Row className="d-flex justify-content-end mr-3 mt-3">
            <img src={Close} onClick={() => setShareModal(false)} />
          </Row>
        </div>
        {styleResultId || auth?.user?._id ? (
          <div>
            <Row className="d-flex justify-content-center">
              <h4>Share your style result</h4>
            </Row>

            <div className={classnames('row justify-content-center', style.modalRow_share)}>
              <div
                className={classnames(
                  'py-4 d-flex justify-content-between mx-5',
                  style.modal_share
                )}>
                <div>
                  <FacebookShareButton url={copiedUrl}>
                    <FacebookIcon
                      size={dimension[0] > 500 ? 36 : 32}
                      round={true}
                      bgStyle={{ fill: 'black' }}
                    />
                  </FacebookShareButton>
                </div>
                <div>
                  <TwitterShareButton url={copiedUrl} title="P&M" hashtag="#PixelandMordar">
                    <TwitterIcon
                      size={dimension[0] > 500 ? 36 : 32}
                      round={true}
                      bgStyle={{ fill: 'black' }}
                    />
                  </TwitterShareButton>
                </div>
                <div>
                  <EmailShareButton url={copiedUrl} body={copiedUrl} subject={'P&M'}>
                    <EmailIcon
                      size={dimension[0] > 500 ? 36 : 32}
                      round={true}
                      bgStyle={{ fill: 'black' }}
                    />
                  </EmailShareButton>
                </div>
                <div>
                  <WhatsappShareButton url={copiedUrl} title="P&M">
                    <WhatsappIcon
                      size={dimension[0] > 500 ? 36 : 32}
                      round={true}
                      bgStyle={{ fill: 'black' }}
                    />
                  </WhatsappShareButton>
                </div>
              </div>
              <div className="my-3 mx-3" style={{ width: '90%' }}>
                <div className={style.shareLink}>
                  <p className={classnames(style.shareContent, 'p-2 mr-2')}>{copiedUrl}</p>
                  <button
                    className={style.copyLinkBtn}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        auth?.user?._id
                          ? `${window.location.host}/share-style-result/${auth?.user?._id}/0`
                          : `${window.location.host}/share-style-result/0/${styleResultId}`
                      );
                      setSnack(true);
                      setSnackMessage('Style link successfully copied');
                    }}>
                    Copy Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : styleResultLoading ? (
          <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
        ) : (
          <div>
            <Row>
              <h5>Something went wrong on saving style results.</h5>
            </Row>
          </div>
        )}
      </Modal>
      <ToastContainer autoClose={5000} pauseOnFocusLoss={false} />
    </>
  );
};

Questionairelayout.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData,
    pageValue: state.questionnaireData.page_number
  };
};

export default connect(mapStateToProps, {
  post_questionnaire,
  questionnaire,
  page,
  storeMx,
  storeMxSkip,
  storeTab,
  storeMxAns,
  storeMxRetake
})(withRouter(trackWindowScroll(Questionairelayout)));
