import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './DesignDairies.module.css';

const DesignDairies = () => {
  const dimension = useMediaQuery();
  return (
    <div>
      <Header />
      <div style={{ height: '100vh', backgroundColor: '#f5f0ec' }}>
        <div className={style.container_design}>
          <div className="row">
            <div
              className="col-md-6"
              style={{
                marginTop: dimension[0] > 500 && 'auto',
                marginBottom: dimension[0] > 500 && 'auto'
              }}>
              <div className={style.underDesignTxt}>
                <h1 style={{ fontFamily: 'QuickSand' }}>
                  {' '}
                  This page is<br></br> under maintenance{' '}
                </h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                </p>
                <button>Go Back</button>
              </div>
            </div>
            <div className="col-md-6">
              {dimension[0] < 500 ? (
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern1.png'
                  }
                  className={style.patternIm}
                />
              ) : (
                <img
                  src={
                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern2.png'
                  }
                  className={style.patternChair}
                />
              )}
              <img
                src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/Group2.png'}
                className={style.mainImg}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DesignDairies;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    // ConsoleHelper(screenSize);
  }, [screenSize]);

  return screenSize;
}
