import customerApiInstance from './CustomerApiInstance';

const getCartDataCount = async auth => {
  let totalProducts = 0;
  const token = localStorage.getItem('token');
  if (auth && auth.user && token) {
    try {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/cart/list`
      );
      if (res.data && res.data.cart.length > 0 && res.data.cartProducts) {
        totalProducts = res.data.cartProducts.length;
        return totalProducts;
      } else {
        return 0;
      }
    } catch (error) {
      // ConsoleHelper("error")
    }
  } else if (auth && !auth.isAuthenticated) {
    const cartProducts = JSON.parse(localStorage.getItem('cartProducts'));
    if (cartProducts) return cartProducts.length;
  }
};

export default getCartDataCount;
