import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '@google/model-viewer';
import { Spinner } from 'react-bootstrap';
import ConsoleHelper from '../../utils/ConsoleHelper';

const RoomModel = () => {
  const { id } = useParams();
  const [roomData, setroomData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id !== '') {
      const getroom = async () => {
        setIsLoading(true);
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/${id}`);
        ConsoleHelper('res', res);
        setroomData(res.data.room_3d[0]);
        setIsLoading(false);
      };
      ConsoleHelper(roomData);
      getroom();
    }
  }, []);

  return (
    <>
      <div style={{ margin: 20 }}>
        {isLoading ? (
          <Spinner as="span" size="sm" role="status" animation="border" />
        ) : roomData && Object.keys(roomData) ? (
          <>
            <div className="justify-content-center">
              {roomData && roomData?.hasOwnProperty('model_url') ? (
                <model-viewer
                  src={roomData['model_url']}
                  alt="A 3D model of an astronaut"
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  environment-image="neutral"
                  max-camera-orbit="auto 75deg auto"
                  auto-rotate
                  camera-controls
                  style={{
                    width: 'auto',
                    height: '550px'
                  }}
                  shadow-intensity="1"
                  ar-scale="fixed"
                />
              ) : (
                <p
                  className="d-flex align-items-center"
                  style={{
                    height: '400px'
                  }}>
                  USDZ file is not supported by browsers, please upload a GLB file to preview 3D
                  room
                </p>
              )}
            </div>
          </>
        ) : (
          <div>MoodBoard doesn't exist</div>
        )}
      </div>
    </>
  );
};
export default RoomModel;
