import React from 'react';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import parse from 'html-react-parser';

const themes = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: '#ffffff !important',
    marginBottom: '1rem !important',
    '&$expanded': {
      marginBottom: '1rem !important'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  typo: {
    fontFamily: 'Quicksand',
    width: '80%',
    [themes.breakpoints.only('xs')]: {
      width: '95%',
      fontSize: '13px'
    }
  },
  typoHeader: {
    fontFamily: 'Quicksand',
    [themes.breakpoints.only('xs')]: {
      width: '95%',
      fontSize: '13px'
    }
  },
  faqBtn: {
    textAlign: 'center',
    marginBottom: '3em',
    marginTop: '3em',
    [themes.breakpoints.only('xs')]: {
      marginTop: '1em',
      marginBottom: '3em'
    }
  },
  '& .Mui-expanded': {
    marginBottom: 0
  },
  expanded: {
    marginBottom: 0
  },
  '& .MuiAccordionSummary-content': {
    marginBottom: 0
  }
}));

function FaqAccordion({ id, header, description, setExpanded, expanded }) {
  const classes = useStyles();
  return (
    <div>
      <Accordion
        square
        expanded={Boolean(expanded === id)}
        className={classes.root}
        onChange={() => setExpanded(prevState => (prevState === id ? null : id))}>
        <AccordionSummary
          aria-controls="panel1d-content"
          style={{ minHeight: '37px' }}
          id="panel1d-header">
          <Typography className={classes.typoHeader}>
            <b>{header}</b>
          </Typography>
          <div>
            {expanded !== id && (
              <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
            )}
            {expanded === id && (
              <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className="mb-3">
          <Typography className={classes.typo}>{parse(description)}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FaqAccordion;
