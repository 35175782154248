import React from 'react';
import style from './InteriorDesign.module.css';
import classnames from 'classnames';
import TopBar from './TopBar';

const InteriorDesign = ({ history }) => {
  return (
    <div>
      <TopBar />
      <img
        style={{ position: 'absolute', width: '35%', top: 0, left: 0 }}
        src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+2811.png'}
        alt=""
      />
      <div
        className={classnames(
          style.container,
          'd-flex text-center flex-column justify-content-center align-items-center'
        )}>
        <img
          style={{ marginLeft: '7%', width: '8%', marginBottom: '1%' }}
          src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/Group+2810.png'}
          alt=""
        />
        <h1 style={{ fontSize: '2vw' }}>Find Your Interior Design Style</h1>
        <br />
        <p style={{ width: '40%', fontSize: '1vw' }}>
          Take our decorating style quiz to discover your unique style, so <br /> you can start
          designing your dream home.
          <br />
          <br />
          At Pixel & Mortar, we believe your home should reflect you. We’ll match you with the
          perfect designer—no matter what your style or budget is.
        </p>
        <br></br>
        <div
          className={classnames(
            'd-flex text-center flex-row justify-content-center align-items-center'
          )}>
          <button
            className={style.button1}
            onClick={() => {
              history.push('/questionnaire/space');
            }}>
            Find your style
          </button>

          <button
            className={style.button2}
            onClick={() =>
              history.push({ pathname: '/signup', state: { redirect: '/questionnaire/space' } })
            }>
            SIGN IN
          </button>
        </div>
      </div>
    </div>
  );
};

export default InteriorDesign;
