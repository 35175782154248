import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import image from '../../assets/images/shop/shutterstock_17.png';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import '@google/model-viewer';

import { Spinner } from 'react-bootstrap';

const TabBar = styled(Tabs)(() => ({
  borderBottom: 'none',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff'
  },
  '& .MuiTabs-scroller': {
    width: 'auto'
  },
  '& .MuiTabs-flexContainer': {
    margin: '0px auto',
    width: '200px',
    backgroundColor: '#F3F3F3',
    justifyContent: 'center',
    marginTop: '20px',
    borderRadius: '6px',
    padding: '6px 0px'
  }
}));

const TabInside = styled(Tab)(() => ({
  '& .Mui-selected': {
    color: '#fff',
    backgroundColor: '#000000',
    borderRadius: '11px'
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Ar3dMobileView() {
  const [value, setValue] = useState(0);
  const [bg, setBg] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setBg(!bg);
  };

  const { productId } = useParams();
  const [productData, setProductData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getP = async () => {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${productId}`);
      setProductData(res.data);
      setIsLoading(false);
    };
    getP();
  }, []);

  return (
    <div
      style={{
        background: bg ? `url(${image})` : 'white',
        height: '100vh',
        backgroundRepeat: bg && 'no-repeat',
        backgroundSize: bg && 'cover'
      }}>
      <button
        style={{
          position: 'absolute',
          left: '17px',
          zIndex: '1',
          width: '27px',
          top: '13px'
        }}
        onClick={history.goBack}>
        <img
          src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/close.png"
          style={{
            position: 'absolute',
            left: '1px',
            zIndex: '1',
            width: '27px',
            top: '1px'
          }}
        />
      </button>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabBar value={value} onChange={handleChange} aria-label="basic tabs example">
            <TabInside label="AR" {...a11yProps(0)} />
            <TabInside label="Object" {...a11yProps(1)} />
          </TabBar>
        </Box>
        <TabPanel value={value} index={0}>
          <div style={{ margin: 20 }}>
            {isLoading ? (
              <Spinner as="span" size="sm" role="status" animation="border" />
            ) : productData && Object.keys(productData).length ? (
              <>
                <div style={{ textAlign: 'center' }}>
                  Product: {productData && productData.name}
                </div>
                <div className="d-flex justify-content-center">
                  {productData && productData['3d_models']?.glbFileUrl ? (
                    <model-viewer
                      src={productData['3d_models']?.glbFileUrl}
                      alt="A 3D model of an astronaut"
                      ar
                      ar-modes="webxr scene-viewer quick-look"
                      environment-image="neutral"
                      auto-rotate
                      camera-controls
                      style={{
                        height: '400px'
                      }}
                      shadow-intensity="1"
                      ios-src={productData['3d_models'].usdzFileUrl}
                      ar-scale="fixed"
                    />
                  ) : (
                    <p
                      className="d-flex"
                      style={{
                        height: '400px'
                      }}>
                      USDZ file is not supported by browsers, please upload a GLB file to preview 3D
                      model
                    </p>
                  )}
                </div>
              </>
            ) : (
              <div>Product doesn't exist</div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div style={{ margin: 20 }}>
            {isLoading ? (
              <Spinner as="span" size="sm" role="status" animation="border" />
            ) : productData && Object.keys(productData).length ? (
              <>
                <div>Product: {productData && productData.name}</div>
                <div className="d-flex justify-content-center">
                  {productData && productData['3d_models']?.glbFileUrl ? (
                    <model-viewer
                      src={productData['3d_models']?.glbFileUrl}
                      alt="A 3D model of an astronaut"
                      ar
                      ar-modes="webxr scene-viewer quick-look"
                      environment-image="neutral"
                      auto-rotate
                      camera-controls
                      style={{
                        height: '400px'
                      }}
                      shadow-intensity="1"
                      ios-src={productData['3d_models'].usdzFileUrl}
                      ar-scale="fixed"
                    />
                  ) : (
                    <p
                      className="d-flex"
                      style={{
                        height: '400px'
                      }}>
                      USDZ file is not supported by browsers, please upload a GLB file to preview 3D
                      model
                    </p>
                  )}
                </div>
              </>
            ) : (
              <div>Product doesn't exist</div>
            )}
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
