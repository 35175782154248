import React, { useEffect } from 'react';
import { signInAndUp } from 'supertokens-web-js/recipe/thirdparty';
import { registerUser, loginUser, clearError } from '../../store/actions/authapi';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { storeMxAns } from '../../store/actions/sampleaction';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { withRouter } from 'react-router-dom';
import ConsoleHelper from '../../utils/ConsoleHelper';

function AuthGoogleLogin({ auth, history, registerUser, clearError, location, questionnaireData }) {
  const dispatch = useDispatch();

  useEffect(() => {
    async function handleGoogleCallback() {
      try {
        const response = await signInAndUp();

        if (response.status === 'OK') {
          ConsoleHelper(response);
          if (response.createdNewUser) {
            // sign up successful
            onGoogleSuccess(response);
          } else {
            onGoogleSuccess(response);
            // sign in successful
          }
          //window.location.assign("/");
        } else {
          // SuperTokens requires that the third party provider
          // gives an email for the user. If that's not the case, sign up / in
          // will fail.

          // As a hack to solve this, you can override the backend functions to create a fake email for the user.

          window.alert('No email provided by social login. Please use another form of login');
          window.location.assign('/login'); // redirect back to login page
        }
      } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          window.alert(err.message);
        } else {
          window.alert('Oops! Something went wrong.');
        }
      }
    }

    handleGoogleCallback();
  }, []);

  const onGoogleSuccess = async response => {
    // ConsoleHelper("body", response, response.tokenId)
    // const token = response.tokenId; thirdParty
    const token = response.user.thirdParty.userId;
    const email = response.user.email;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/google-login`,
        { tokenId: token, email: email }
      );

      let mx = {};
      let redirectedFrom = false;
      let mxAnsData = {};
      let redirect = JSON.parse(localStorage.getItem('RedirectToLogin'));
      if (
        (redirect && redirect === true) ||
        (questionnaireData &&
          questionnaireData.hasOwnProperty('MxAns') &&
          questionnaireData.MxAns.hasOwnProperty('questionnaire') &&
          questionnaireData.MxAns.questionnaire.hasOwnProperty('purchased') &&
          questionnaireData.MxAns.questionnaire.purchased === false)
      ) {
        if (questionnaireData && questionnaireData.hasOwnProperty('MxAns')) {
          mx = { ...questionnaireData.MxAns };
          redirectedFrom = true;
          mxAnsData = { ...mx };
          handleGoogleLogin(history, location.state, redirectedFrom, mxAnsData, response);
        }
        history.push('/');
      } else {
        handleGoogleLogin(history, location.state, redirectedFrom, mxAnsData, response);
        history.push('/');
      }
    } catch (err) {
      ConsoleHelper({ err });
    }
  };

  const handleGoogleLogin = async (history, redirect, redirectedFrom, mxAnsData, res) => {
    try {
      let res1;
      if (
        res.data.message === 'Success' &&
        (redirectedFrom === true || Object.keys(mxAnsData).length > 0)
      ) {
        let body = { ...mxAnsData };
        body._id = res.data._id;
        res1 = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`,
          body
        );
      }
      if (
        res.data.message === 'Success' &&
        ((mxAnsData.hasOwnProperty('questionnaire') &&
          mxAnsData.questionnaire.hasOwnProperty('purchase') &&
          mxAnsData.questionnaire.purchase !== true) ||
          Object.keys(mxAnsData).length === 0)
      ) {
        toast('Login successful');
        dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
        dispatch({ type: 'SET_QUESTIONNAIRE', payload: 'questionnaire' });
        const customerAuthenticationCart = localStorage.getItem('authentication');
        const customerCart = JSON.parse(localStorage.getItem('cartProducts'));
        let styles = [];
        let rooms = [];
        if (
          res.data.user.questionnaire &&
          Object.keys(res.data.user.questionnaire).length !== 0 &&
          res.data.user.questionnaire
        ) {
          rooms = [...res.data.user.questionnaire.rooms];
          if (
            res.data.user.questionnaire.hasOwnProperty('space') &&
            res.data.user.questionnaire.space.length > 0
          ) {
            res.data.user.questionnaire.space.map(sp => {
              styles = [...styles, sp.tag];
            });
          } else if (
            res.data.user.questionnaire.hasOwnProperty('style') &&
            res.data.user.questionnaire.style.length > 0
          ) {
            res.data.user.questionnaire.style.map(st => {
              styles = [...styles, st.tag];
            });
          }
          styles = styles.flat();
          styles = styles.filter((v, i, a) => a.indexOf(v) === i);
        }
        if (redirect !== undefined) {
          history.push(redirect.redirect);
        }
        if (redirect === undefined) {
          if (
            (redirect !== undefined || redirect === undefined) &&
            customerAuthenticationCart === 'PENDING' &&
            customerCart.length > 0
          ) {
            const cartBody = {
              user_id: res.data.user._id,
              products: customerCart
            };
            const res1 = await customerApiInstance.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
              cartBody
            );
            if (res1.data) {
              localStorage.removeItem('authentication');
              localStorage.removeItem('cartProducts');
              history.push('/cart');
            }
          } else if (
            res.data.user.questionnaire &&
            Object.keys(res.data.user.questionnaire).length !== 0 &&
            res.data.user.questionnaire
          ) {
            history.push(`explore/rooms/${rooms}`);
            storeMxAns({});
            localStorage.removeItem('RedirectToLogin');
          }
        }
      } else if (res.data.message === 'Success' && res1.data) {
        toast('Login successful');
        // ConsoleHelper("going here")
        dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
        dispatch({ type: 'SET_QUESTIONNAIRE', payload: 'questionnaire' });
        if (res1.data.questionnaire.package.title !== 'Express') {
          history.push('/package-payment');
          storeMxAns({});
          localStorage.removeItem('RedirectToLogin');
        } else {
          let qns1 = JSON.parse(localStorage.getItem('MX-A-questionnaire'));
          qns1.status = 'PENDING_ALLOCATION';
          qns1.designer_allocation = 'UNASSIGNED';
          qns1.name = 'My' + ' ' + qns1.questionnaire.MX_A_INITAL_ROOM;
          let res2 = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
            qns1
          );
          let styles = [];
          let rooms = [];
          if (
            res2 &&
            res2.data &&
            mxAnsData.questionnaire &&
            Object.keys(mxAnsData.questionnaire).length !== 0
          ) {
            rooms = [...mxAnsData.questionnaire.rooms];
            if (
              mxAnsData.questionnaire.hasOwnProperty('space') &&
              mxAnsData.questionnaire.space.length > 0
            ) {
              mxAnsData.questionnaire.space.map(sp => {
                styles = [...styles, sp.tag];
              });
            } else if (
              mxAnsData.questionnaire.hasOwnProperty('style') &&
              mxAnsData.questionnaire.style.length > 0
            ) {
              mxAnsData.questionnaire.style.map(st => {
                styles = [...styles, st.tag];
              });
            }
            styles = styles.flat();
            styles = styles.filter((v, i, a) => a.indexOf(v) === i);
            storeMxAns({});
            localStorage.removeItem('RedirectToLogin');
            history.push(`explore/rooms/${rooms}`);
          }
        }
      }
      if (res.data.message !== 'Success') {
        dispatch({ type: 'AUTH_ERROR', payload: res.data });
      }
    } catch (err) {
      ConsoleHelper({ err });
    }
  };

  return <div>Wait.. while we redirect you to pixelandmortar.com</div>;
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, {
  registerUser,
  loginUser,
  clearError
})(withRouter(AuthGoogleLogin));
