import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SliderContainer from 'react-slick';
import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import style from './Carousel.module.css';
import '../../index.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: '20px',
      marginBottom: '50px'
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '6px',
        height: '10px',
        marginLeft: '11px'
      }
    },
    paper: {
      borderRadius: '10px',
      maxHeight: '460px',
      height: '330px',
      width: '90%',
      border: '0.5px solid rgba(112,112,112,0.4 )',
      overflow: 'hidden',
      margin: '0px auto',
      [theme.breakpoints.down('sm')]: {
        height: '300px'
      }
    },
    imagefit: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  })
);
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowPrev} alt="prevArrow" {...props} className={style.leftArr} />
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowNext} alt="nextArrow" {...props} className={style.rightArr} />
);

const defaultSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  responsive: [
    {
      breakpoint: 3000,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 6
        //centerMode: true
      }
    },
    {
      breakpoint: 2500,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 5
        //centerMode: true
      }
    },
    {
      breakpoint: 2000,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 4
        //centerMode: true
      }
    },
    {
      breakpoint: 1400,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
        //centerMode: true
      }
    },
    {
      breakpoint: 1100,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
        // centerMode: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 400,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 350,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    }
  ]
};
export default function Carousel(props) {
  const classes = useStyles();
  const [config, setConfig] = useState({});
  useEffect(() => {
    if (props.config) {
      const newConfig = props.config;
      newConfig.nextArrow = <SlickArrowRight />;
      newConfig.prevArrow = <SlickArrowLeft />;
      setConfig(newConfig);
    } else {
      setConfig(defaultSettings);
    }
  }, []);

  return (
    <div className={classes.root}>
      {Object.keys(config).length > 0 && (
        <SliderContainer {...config}>{props.children}</SliderContainer>
      )}
    </div>
  );
}
