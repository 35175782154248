import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Cart.module.css';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import * as Icon from 'react-bootstrap-icons';
import Snackbar from '@material-ui/core/Snackbar';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { storePackage, storeCartCount } from '../../store/actions/sampleaction';
import getCartDataCount from '../../utils/CartDetails';
import { Button, Spinner } from 'react-bootstrap';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from 'react-loader-spinner';
import { InputGroup, FormControl } from 'react-bootstrap';
import ConsoleHelper from '../../utils/ConsoleHelper';
import { openinnewtab } from '../../utils/utils';

const Cart = ({ auth, questionnaireData, storePackage, storeCartCount }) => {
  const history1 = useHistory();
  const dimension = useMediaQuery();
  const [display, setDisplay] = useState('cart');
  const [shipmentData, setShipmentData] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  let [cartDetailsProducts, setCartDetailsProducts] = useState([]);
  let [nonStockDetailsProducts, setNonStockDetailsProducts] = useState([]);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  let [savedForLaterId, setSavedforLaterId] = useState([]);
  const [inputs, setInputs] = useState({});
  let [favDetailsProducts, setFavDetailsProducts] = useState([]);
  const [favProducts, setFavProducts] = useState([]);
  const [addressdata, setAddressData] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentAddressId, setCurrentAddressId] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [promo, setPromo] = useState(false);
  const [paymentMode, setPaymentMode] = useState('ONLINE');
  const [orderResponse, setOrderResponse] = useState({ data: '' });
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [billingAddress, setBillingAddress] = useState('address1');
  const [newBillingAddress, setNewBillingAddress] = useState(false);
  const [billingAddressId, setBillingAddressId] = useState('');
  const [latestAddressId, setLatestAddressId] = useState('');
  const [addressType, setAddressType] = useState();
  const [gstinValue, setGstinValue] = useState('');
  const [gstBusiness, setGstBusiness] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [gstError] = useState(false);
  const [error, setError] = useState('');
  const [gstEdit, setGstEdit] = useState(false);
  const [addGst, setAddGst] = useState(false);
  const [gstModal, setGstModal] = useState(false);
  const [addressDataTemp, setAddressDataTemp] = useState([]);
  const [packagesData, setPackagesData] = useState({});
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [couponDrawer, setCouponDrawer] = useState(false);
  let [availableOffers, setAvailableOffers] = useState([]);
  const [offerSelectedValue, setOfferSelectedValue] = useState({});
  const [promotionalDiscount, setPromotionalDiscount] = useState(0);
  const [promotionAdded, setPromotionAdded] = useState(false);
  const [couponsLoading, setCouponsLoading] = useState(false);
  const [addClicked] = useState(false);
  const [offerType, setOfferType] = useState('');
  const [offerProducts, setOfferProducts] = useState([]);
  const [addressError, setAddressError] = useState({});
  const [deliverToAddress] = useState({});
  const [cities, setCities] = useState({});
  const [addCredit, setAddCredit] = useState(false);
  const [creditsvalue, setcreditsvalue] = useState(0);
  const [promocode, setpromocode] = useState('');
  const [promovalue, setpromovalue] = useState(0);
  const [promovalue_unit, setvalue_unit] = useState('');
  const [promoids, setpromoids] = useState([]);
  const [applyload, setapplyload] = useState(false);

  const useStyles1 = makeStyles({
    paper: {
      width: 450,
      backgroundImage: `url(${'https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4851.png'})`
    }
  });
  const classes1 = useStyles1();

  const useStyles2 = makeStyles({
    paper: {
      width: 350,
      backgroundImage: `url(${'https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4851.png'})`
    }
  });
  const classes2 = useStyles2();

  const filterQuantityAndPrice = productData => {
    let obj = {};
    productData[0].products.forEach(item => {
      if (obj[item.product_id]) {
        obj[item.product_id].quantity = obj[item.product_id].quantity + item.quantity;
        obj[item.product_id].total_price = obj[item.product_id].total_price + item.total_price;
      } else {
        obj[item.product_id] = item;
      }
    });

    let valuesArr = Object.values(obj);
    productData[0].products = [...valuesArr];
    return productData;
  };

  const getSavedItems = async () => {
    let savedData;
    let savedIds = [];
    if (auth && auth.user) {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/saved-product/list/${auth.user._id}`
      );
      if (res.data.savedProducts && res.data.savedProducts.length !== 0) {
        res.data.savedProducts.map(dat => {
          savedIds = dat.products.map(m => {
            return m.product_id;
          });
        });
        setSavedforLaterId([...savedIds]);
      }
      if (res.data.savedProducts.length !== 0) {
        savedData = [...res.data.savedProducts];

        let obj = {};

        savedData[0].products.forEach(item => {
          if (obj[item.product_id]) {
            obj[item.product_id].quantity = obj[item.product_id].quantity + item.quantity;
            obj[item.product_id].total_price = obj[item.product_id].total_price + item.total_price;
          } else {
            obj[item.product_id] = item;
          }
        });
        let valuesArr = Object.values(obj);
        savedData[0].products = [...valuesArr];

        setFavProducts([...res.data.products]);
        setFavDetailsProducts([...savedData[0].products]);
      }
    }
  };

  const getName = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    return filteredProduct.length > 0 ? filteredProduct[0].name : 'Nil';
  };
  const getMrp = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    return filteredProduct.length > 0 ? Math.round(filteredProduct[0].price) : '00';
  };
  const getPixelPrice = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    return filteredProduct.length > 0 ? Math.round(filteredProduct[0].pixel_price) : '00';
  };
  const getPrice = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    return filteredProduct.length > 0 ? Math.round(filteredProduct[0].price) : '00';
  };
  const getSavedName = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    return filteredProduct.length > 0 ? filteredProduct[0].name : 'Nil';
  };

  const updateCart = async data => {
    try {
      if (auth && auth.user) {
        const prodBody = {
          user_id: auth.user._id,
          products: [data]
        };
        await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/update`,
          prodBody
        );
      } else {
        let products = [data];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
      }
    } catch (e) {}
    storeCartCount(await getCartDataCount(auth));
  };
  const updateFavourites = async data => {
    try {
      if (auth && auth.user) {
        const prodBody = {
          user_id: auth.user._id,
          products: data
        };
        await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/saved-product/update`,
          prodBody
        );
      }
    } catch (e) {}
  };

  const removeFromCart = async data => {
    try {
      if (auth && auth.user) {
        const prodBody = {
          user_id: auth.user._id,
          products: [data]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/remove`,
          prodBody
        );
        if (res.data) {
          cartDetailsProducts = cartDetailsProducts.filter(d => d.product_id !== data.product_id);
          setCartDetailsProducts([...cartDetailsProducts]);
          setCartDetails([...cartDetailsProducts]);
        }
      } else {
        let products = [data];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
      }
    } catch (e) {}
    storeCartCount(await getCartDataCount(auth));
  };
  const removeFromFavourites = async data => {
    try {
      if (auth && auth.user) {
        const prodBody = {
          user_id: auth.user._id,
          products: data
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/saved-product/delete`,
          prodBody
        );
        if (res.data) {
          favDetailsProducts = favDetailsProducts.filter(d => d.product_id !== data.product_id);
          setFavDetailsProducts([...favDetailsProducts]);
        }
      }
    } catch (e) {}
  };

  const addUnavailableQuantity = data => {
    if (auth && auth.user) {
      data.quantity = data.quantity + 1;
      data.total_price = data.quantity * data.rate;
      let objIndex = nonStockDetailsProducts.findIndex(obj => obj.product_id == data.product_id);
      nonStockDetailsProducts[objIndex].quantity = data.quantity;
      nonStockDetailsProducts[objIndex].total_price = data.total_price;
      setNonStockDetailsProducts([...nonStockDetailsProducts]);
      updateCart(data);
    }
  };

  const reduceUnavailableQuantity = data => {
    if (data.quantity > 1) {
      data.quantity = data.quantity - 1;
      data.total_price = data.quantity * data.rate;
      let objIndex = nonStockDetailsProducts.findIndex(obj => obj.product_id == data.product_id);
      nonStockDetailsProducts[objIndex].quantity = data.quantity;
      nonStockDetailsProducts[objIndex].total_price = data.total_price;
      setNonStockDetailsProducts([...nonStockDetailsProducts]);
      updateCart(data);
    }
  };

  const addQuantity = data => {
    let stock = 0;
    const d = cartProducts.filter(cp => cp._id === data.product_id);
    if (d.length > 0 && d[0].stock) {
      stock = d[0].stock;
    }
    if (auth && auth.user) {
      if (data.quantity < stock) {
        data.quantity = data.quantity + 1;
        data.total_price = data.quantity * data.rate;
        let objIndex = cartDetailsProducts.findIndex(obj => obj.product_id == data.product_id);
        cartDetailsProducts[objIndex].quantity = data.quantity;
        cartDetailsProducts[objIndex].total_price = data.total_price;
        setCartDetails([...cartDetailsProducts]);
        setCartDetailsProducts([...cartDetailsProducts]);
        updateCart(data);
      } else {
        setSnack(true);
        setSnackMessage('Only ' + `${stock}` + ' stocks available');
      }
    } else {
      for (let i = 0; i < cartDetailsProducts.length; i++) {
        if (cartDetailsProducts[i].product_id === data.product_id) {
          if (cartDetailsProducts[i].quantity < stock) {
            cartDetailsProducts[i].quantity = cartDetailsProducts[i].quantity + 1;
            cartDetailsProducts[i].total_price =
              cartDetailsProducts[i].quantity * cartDetailsProducts[i].rate;
          } else {
            setSnack(true);
            setSnackMessage('Only ' + `${stock}` + ' stocks available');
          }
        }
      }
      localStorage.setItem('cartProducts', JSON.stringify(cartDetailsProducts));
      setCartDetailsProducts([...cartDetailsProducts]);
      setCartDetails([...cartDetailsProducts]);
    }
  };

  const reduceSaveQuantity = data => {
    if (data.quantity > 1) {
      data.quantity = data.quantity - 1;
      data.total_price = data.quantity * data.rate;
      let objIndex = favDetailsProducts.findIndex(obj => obj.product_id == data.product_id);
      favDetailsProducts[objIndex].quantity = data.quantity;
      favDetailsProducts[objIndex].total_price = data.total_price;
      setFavDetailsProducts([...favDetailsProducts]);
      updateFavourites(data);
    }
  };
  const addSaveQuantity = data => {
    data.quantity = data.quantity + 1;
    data.total_price = data.quantity * data.rate;
    let objIndex = favDetailsProducts.findIndex(obj => obj.product_id == data.product_id);
    favDetailsProducts[objIndex].quantity = data.quantity;
    favDetailsProducts[objIndex].total_price = data.total_price;
    setFavDetailsProducts([...favDetailsProducts]);
    updateFavourites(data);
  };

  const reduceQuantity = data => {
    if (data.quantity > 1) {
      if (auth && auth.user) {
        data.quantity = data.quantity - 1;
        data.total_price = data.quantity * data.rate;
        let objIndex = cartDetailsProducts.findIndex(obj => obj.product_id == data.product_id);
        cartDetailsProducts[objIndex].quantity = data.quantity;
        cartDetailsProducts[objIndex].total_price = data.total_price;
        setCartDetails([...cartDetailsProducts, data]);
        setCartDetailsProducts([...cartDetailsProducts]);
        updateCart(data);
      } else {
        for (let i = 0; i < cartDetailsProducts.length; i++) {
          if (cartDetailsProducts[i].product_id === data.product_id) {
            cartDetailsProducts[i].quantity = cartDetailsProducts[i].quantity - 1;
            cartDetailsProducts[i].total_price =
              cartDetailsProducts[i].quantity * cartDetailsProducts[i].rate;
          }
        }
        localStorage.setItem('cartProducts', JSON.stringify(cartDetailsProducts));
        setCartDetailsProducts([...cartDetailsProducts]);
        setCartDetails([...cartDetailsProducts]);
      }
    }
  };

  const getVendor = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    if (filteredProduct.length > 0 && filteredProduct[0].vendor_id) {
      return filteredProduct[0].vendor_id.name;
    } else {
      return 'Default';
    }
  };
  const getImage = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    if (filteredProduct.length > 0 && filteredProduct[0].image) {
      return filteredProduct[0].image.length > 0
        ? filteredProduct[0].image[0]
        : filteredProduct[0].image;
    }
    return '';
  };

  const getSavedImage = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    if (filteredProduct.length > 0 && filteredProduct[0].image) {
      return filteredProduct[0].image.length > 0
        ? filteredProduct[0].image[0]
        : filteredProduct[0].image;
    }
    return '';
  };

  const getSavedVendor = data1 => {
    const filteredProduct = allProducts.filter(prod => prod._id === data1.product_id);
    if (filteredProduct.length > 0 && filteredProduct[0].vendor_id) {
      return filteredProduct[0].vendor_id.name;
    } else {
      return 'Default';
    }
  };

  const addToFavourites = async productData => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData.product_id,
            quantity: productData.quantity,
            rate: productData.rate,
            total_price: productData.total_price
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/saved-product/add`,
          body
        );
        if (res.data) {
          setFavDetailsProducts([...favDetailsProducts, productData]);
          cartDetailsProducts = cartDetailsProducts.filter(
            d => d.product_id !== productData.product_id
          );
          setCartDetailsProducts([...cartDetailsProducts]);
          setCartDetails([...cartDetailsProducts]);
          getSavedItems();
          setSnack(true);
          setSnackMessage('Product Saved for later successfully');
        } else {
          setSnack(true);
          setSnackMessage('Please Login to add the Products to favourites');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  saving  Product for later ');
    }
  };

  const addToCart = async productData => {
    try {
      if (auth && auth.user) {
        const prodBody = {
          user_id: auth.user._id,
          products: [
            {
              product_id: productData.product_id,
              quantity: productData.quantity,
              rate: productData.rate,
              total_price: productData.total_price
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          favDetailsProducts = favDetailsProducts.filter(
            d => d.product_id !== productData.product_id
          );
          setFavDetailsProducts([...favDetailsProducts]);
          setCartDetailsProducts([...cartDetailsProducts, productData]);
          setCartDetails([...cartDetailsProducts, productData]);
          let favProds = favProducts.filter(fav => fav._id === productData.product_id);
          setCartProducts([...cartProducts, ...favProds]);
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }
    storeCartCount(await getCartDataCount(auth));
  };

  const onlinePayment = async (response, razorpayId) => {
    const orderData = {
      product: cartDetailsProducts.map(function(a) {
        return a.product_id;
      }),
      status: 'NEW',
      user_id: auth.user._id,
      order_value: promotionAdded ? getTotal() : getTotal1(),
      shipping_charge: 0,
      payment_status: 'PAID',
      address_id: addressdata[currentAddressId]._id,
      billing_id: billingAddressId,
      payment_mode: paymentMode,
      promotion_code: promotionAdded ? offerSelectedValue.coupon_code : '',
      discount_amount: Math.round(promotionalDiscount),
      pixel_discount_amount: getPMdiscount(),
      offer_type: offerType,
      razor_pay_payment_id: response.razorpay_payment_id,
      razor_pay_order_id: razorpayId,
      points: addCredit ? creditsvalue : 0
    };
    let res =
      Object.keys(orderResponse.data).length < 2 &&
      (await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/create`,
        orderData
      ));
    if (Object.keys(res).length) {
      orderResponse.data = res.data;
      setOrderResponse(orderResponse);
    }
    const data = {
      user_id: auth.user._id,
      razor_pay_payment_id: response.razorpay_payment_id,
      payment_status: 'PAID',
      product: cartDetailsProducts.map(function(a) {
        return a.product_id;
      })
    };
    let result = await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/order/update-payment-status/${orderResponse.data.neworder._id}`,
      data
    );
    if (result) {
      try {
        await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/product-order/send-invoice/${orderResponse.data.neworder._id}`
        );
      } catch (e) {}
      setSnack(true);
      setSnackMessage('Order placed successfully');
      if (promotionAdded) {
        try {
          const redemData = {
            transaction_id: response.razorpay_payment_id,
            value_used: promotionalDiscount,
            coupon_id: offerSelectedValue.id
          };
          await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/offer/redemption/create`,
            redemData
          );
        } catch (e) {}
      }
      if (promoids?.length > 0) {
        let promosaved_value = 0;
        promoids.map(id => {
          cartDetailsProducts.map(product => {
            if (product.product_id == id) {
              promosaved_value += (getPixelPrice(product) * promovalue) / 100;
            }
          });
        });
        const res = axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/vouchers/${promocode}/redeem`,
          {
            user_id: auth.user._id,
            user_email: auth.user.email,
            user_phone: auth.user.phone_number,
            transaction_id: result.data.order_id,
            value_used: promosaved_value
          }
        );
      }
      history1.push({
        pathname: `/order-confirmation/${orderResponse.data.neworder._id}`,
        state: {
          order_id: orderResponse.data.neworder.order_id,
          email: auth.user.email
        }
      });
    }
  };

  const offlinePayment = async () => {
    const orderData = {
      product: cartDetailsProducts.map(function(a) {
        return a.product_id;
      }),
      status: 'NEW',
      user_id: auth.user._id,
      order_value: promotionAdded ? getTotal() : getTotal1(),
      shipping_charge: 0,
      payment_status: 'PENDING',
      address_id: addressdata[addressdata.length - 1]._id,
      billing_id: billingAddressId,
      payment_mode: paymentMode,
      promotion_code: promotionAdded ? offerSelectedValue.coupon_code : '',
      discount_amount: Math.round(promotionalDiscount),
      pixel_discount_amount: getPMdiscount(),
      offer_type: offerType
    };
    let res =
      Object.keys(orderResponse.data).length < 2 &&
      (await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/create`,
        orderData
      ));
    if (Object.keys(res).length) {
      orderResponse.data = res.data;
      setOrderResponse(orderResponse);
    }
    await customerApiInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/product-order/send-invoice/${orderResponse.data.neworder._id}`
    );
    setSnack(true);
    setSnackMessage('Order placed successfully');
    if (promotionAdded) {
      try {
        const redemData = {
          value_used: promotionalDiscount,
          coupon_id: offerSelectedValue.id
        };
        await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/offer/redemption/create`,
          redemData
        );
      } catch (e) {}
    }
    if (promoids?.length > 0) {
      let promosaved_value = 0;
      promoids.map(id => {
        cartDetailsProducts.map(product => {
          if (product.product_id == id) {
            promosaved_value += (getPixelPrice(product) * promovalue) / 100;
          }
        });
      });
      const res = axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/vouchers/${promocode}/redeem`,
        {
          user_id: auth.user._id,
          user_email: auth.user.email,
          user_phone: auth.user.phone_number,
          value_used: promosaved_value
        }
      );
    }
    history1.push({
      pathname: `/order-confirmation/${orderResponse.data.neworder._id}`,
      state: {
        order_id: orderResponse.data.neworder.order_id,
        email: auth.user.email
      }
    });
  };
  const placeOrderNew = async () => {
    setLoading(true);
    const orderValue = promotionAdded ? getTotal() : getTotal1();
    try {
      if (paymentMode === 'ONLINE' && orderValue > 0) {
        const orderResult = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/order/razorpay-order`,
          { order_value: orderValue }
        );

        var options = {
          key_id: `${process.env.REACT_APP_RAZOR_PAY_KEY}`, // Enter the Key ID generated from the Dashboard
          amount: `${Math.ceil(orderValue) * 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'Pixel & mortar',
          image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/common-images/P%26M+logo.svg',
          // order_id: `${orderResponse.data.order.id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          order_id: orderResult.data.order.id,
          handler: async function(response) {
            await onlinePayment(response, orderResult.data.order.id);
          },
          prefill: {
            name: `${auth.user.name}`,
            email: `${auth.user.email}`,
            contact: `${auth.user.phone_number}`
          },
          notes: {},
          theme: {
            color: '#3399cc'
          }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      } else {
        await offlinePayment();
      }
    } catch (e) {
      history1.push({ pathname: '/order-failed/' });
    }
    setLoading(false);
    storeCartCount(await getCartDataCount(auth));
  };

  const getSubTotal = () => {
    let sum = 0;
    let cartprods = cartDetailsProducts.filter(
      elem => !savedForLaterId.find(id => elem.product_id === id)
    );
    if (cartprods.length > 0) {
      cartDetails.products = [...cartprods];
    } else {
      cartDetails.products = [...cartDetailsProducts];
    }
    cartDetails.products.map(prod => {
      sum = sum + prod.total_price;
    });
    return Math.round(sum);
  };

  const getProductPixel = prod => {
    let pmProductDiscount = 0;
    if (cartProducts && cartProducts.length > 0) {
      let productTemp = cartProducts.find(ct => ct._id === prod.product_id);

      if (Object.keys(productTemp).length) {
        pmProductDiscount = 1 * productTemp.pixel_price * prod.quantity;
      }
    }
    return Math.round(pmProductDiscount);
  };
  const getPMdiscount = () => {
    let pmdiscount = 0;
    let pixelsum = 0;
    let cartprods = cartDetailsProducts.filter(
      elem => !savedForLaterId.find(id => elem.product_id === id)
    );
    if (cartprods.length > 0) {
      cartDetails.products = [...cartprods];
    } else {
      cartDetails.products = [...cartDetailsProducts];
    }
    cartDetails.products.map(prod => {
      pixelsum = getProductPixel(prod);
      pmdiscount = pmdiscount + pixelsum;
    });
    let differenceSum = getSubTotal() - pmdiscount;
    return Math.round(differenceSum);
  };

  const handleChangeGstin = value => {
    setGstinValue(value);
    if (
      value.match(
        /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/
      )
    ) {
      return setError('');
    } else {
      return setError('gstin');
    }
  };
  const handleChangeDelivery = e => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'work') {
      name = 'type';
      value = 'work';
    }
    if (name === 'home') {
      name = 'type';
      value = 'home';
    }
    if (name === 'phone') {
      setInputs({
        ...inputs,
        [name]: value
      });
      if (value.length !== 10) {
        return setError('phone');
      } else {
        return setError('');
      }
    }
    if (name === 'postcode') {
      setInputs({
        ...inputs,
        [name]: value
      });
      if (value.length !== 6) {
        return setError('postcode');
      } else {
        return setError('');
      }
    }
    if (name === 'gstin') {
      setInputs({
        ...inputs,
        [name]: value
      });
      if (
        value.match(
          /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/
        )
      ) {
        return setError('');
      } else {
        return setError('gstin');
      }
    }

    setInputs({
      ...inputs,
      [name]: value
    });
  };

  const setCurrenAddressData = (addressId, addressArr) => {
    if (addressArr.length > 0) setInputs({ ...addressArr[addressId].address });
  };

  const handleSubmitAddress = async () => {
    setEditModal(false);
    let addressErrorData = {};
    if (Object.keys(inputs).length !== 0) {
      if (!inputs.firstname) {
        addressErrorData.firstname = 'first name required';
      }
      if (!inputs.lastname) {
        addressErrorData.lastname = 'last name required';
      }
      if (!inputs.phone) {
        addressErrorData.phone = 'phone is required';
      } else {
        if (inputs.phone.length !== 10) addressErrorData.phone = 'phone should have 10 digit';
      }
      if (!inputs.postcode) {
        addressErrorData.postcode = 'postcode is required';
      } else {
        if (inputs.postcode.length !== 6)
          addressErrorData.postcode = 'postcode should have 6 digit';
      }
      if (!inputs.line1) {
        addressErrorData.line1 = 'address line1 is required';
      }
      if (!inputs.line2) {
        addressErrorData.line2 = 'address line2 is required';
      }
      if (!inputs.city) {
        addressErrorData.city = 'city is required';
      }
      if (!inputs.state) {
        addressErrorData.state = 'state is required';
      }
      setAddressError(addressErrorData);
      if (!Object.keys(addressErrorData).length) {
        try {
          const data = {
            user_id: auth.user._id,
            address: inputs
          };

          const res = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/address`,
            data
          );
          if (res.data) {
            setSnack(true);
            setEditModal(false);

            setModal(false);
            setSnackMessage('Address Added successfully');

            setInputs({});
            if (!newBillingAddress) {
              setEditModal(false);
              let newAddressArr = [...addressDataTemp, res.data.data];
              setAddressData([...addressDataTemp, res.data.data]);

              setLatestAddressId(newAddressArr.length > 0 ? newAddressArr.length - 1 : 0);
              setDeliveryAddress(true);
              if (newAddressArr.length > 0) {
                setCurrenAddressData(newAddressArr.length - 1, newAddressArr);
              } else {
                setCurrenAddressData(0, []);
              }

              setCurrentAddressId(newAddressArr.length > 0 ? newAddressArr.length - 1 : 0);
            }
          }
          if (newBillingAddress) {
            setBillingAddressId(res.data.data._id);
          }
        } catch (err) {}
      }
    } else {
      setSnack(true);
      setSnackMessage('Please enter all the fields');
    }
  };

  const handleDeleteAddress = async id => {
    try {
      const res = await customerApiInstance.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/address/${id}`
      );

      if (res.data) {
        setInputs({});
        setSnack(true);
        setModal(false);
        setSnackMessage('Address deleted successfully');
        let addressdata1 = addressdata.filter(filter => filter._id !== id);
        setAddressData([...addressdata1]);
        setAddressDataTemp([...addressdata1]);
      }
    } catch (err) {}
  };

  const handleUpdateAddress = async () => {
    let empty = 0;
    if (Object.keys(inputs).length !== 0) {
      for (let key in inputs) {
        if (inputs[key] === '') {
          empty = empty + 1;
        }
      }

      if (empty > 0) {
        setSnack(true);
        setSnackMessage('Please enter all the fields');
      } else {
        try {
          const data = {
            _id: addressDataTemp[currentAddressId]._id,
            address: inputs
          };

          const res = await customerApiInstance.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/address`,
            data
          );
          addressdata[currentAddressId].address = { ...inputs };

          if (res.data) {
            setAddressData([...addressDataTemp]);
            setInputs({});
            setSnack(true);
            setSnackMessage('Address Updated successfully');
            setModal(false);
            setEditModal(false);
          }
        } catch (err) {
          ConsoleHelper(err);
        }
      }
    } else {
      setSnack(true);
      setSnackMessage('Please enter all the fields');
    }
  };

  const getAddressValues = address => {
    let add = '';
    if (address) {
      add =
        address?.line1 +
        ' , ' +
        ' ' +
        address?.line2 +
        ' , ' +
        ' ' +
        address?.city +
        ' , ' +
        ' ' +
        address?.state +
        ' , ' +
        ' ' +
        address?.postcode +
        '.';
    } else {
      add = '';
    }
    return add;
  };

  const handleSubmitGSTIN = async () => {
    const data = {
      gstin: gstinValue,
      gst_business_name: gstBusiness
    };
    try {
      const res = await customerApiInstance.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/updateUserDetails/${auth.user._id}`,
        data
      );
      if (Object.keys(res).length) {
        if (res.data.verified === false) {
          setGstModal(false);
          setGstEdit(false);
          setSnack(true);
          setSnackMessage(res.data.message);
        } else {
          setGstBusiness(res.data.gst_business_name);
          setGstinValue(res.data.gstin);
          setUserDetails(res.data);
          if (gstEdit) {
            setSnack(true);
            setSnackMessage('GST updated successfully');
          } else {
            setSnack(true);
            setSnackMessage('GST added successfully');
          }
          setGstModal(false);
          setGstEdit(false);
        }
      }
    } catch (err) {
      setGstModal(false);
      setGstEdit(false);
      setSnack(true);
      setSnackMessage('Something went wrong');
    }
  };

  const getPackageImage = data => {
    let packageValue = {};
    packageValue = packages.find(pack => pack.title === data.questionnaire.package?.title);
    if (packageValue) {
      return packageValue.image;
    } else {
      return '';
    }
  };

  const calculatePromoReduction = offerSelected => {
    let cartTotal = Math.round(getSubTotal() - getPMdiscount());
    let discount = 0;
    let value = 0;
    let calculation1 = 0;
    let productIds = [];
    let offerAvailProducts = [];
    let offerProductsSum = 0;
    if (
      offerSelected.custom_conditions &&
      offerSelected.custom_conditions.length > 0 &&
      offerProducts.length > 0
    ) {
      for (let i = 0; i < offerProducts.length; i++) {
        productIds.push(offerProducts[i]._id);
      }
      productIds = [...new Set(productIds)];
      offerAvailProducts = cartDetailsProducts.filter(ct => productIds.includes(ct.product_id));
      if (offerAvailProducts.length > 0) {
        offerProductsSum = offerAvailProducts
          .map(o => o.total_price)
          .reduce((a, c) => {
            return a + c;
          });
      }
      if (
        offerSelected.minimum_transaction_value &&
        offerProductsSum >= parseInt(offerSelected.minimum_transaction_value)
      ) {
        if (offerSelected.value_unit === 'percentage') {
          value = offerSelected.value ? parseInt(offerSelected.value) : 0;
          calculation1 = offerProductsSum * (value / 100);
          if (parseInt(offerSelected.maximum_discount_value) > 0) {
            if (
              offerSelected.maximum_discount_value &&
              calculation1 > parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = parseInt(offerSelected.maximum_discount_value);
            } else if (
              offerSelected.maximum_discount_value &&
              calculation1 <= parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = calculation1;
            }
          } else {
            discount = calculation1;
          }
        }
        if (offerSelected.value_unit === 'value') {
          value = offerSelected.value ? parseInt(offerSelected.value) : 0;
          calculation1 = value;
          if (parseInt(offerSelected.maximum_discount_value) > 0) {
            if (
              offerSelected.maximum_discount_value &&
              calculation1 > parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = parseInt(offerSelected.maximum_discount_value);
            } else if (
              offerSelected.maximum_discount_value &&
              calculation1 <= parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = calculation1;
            }
          } else {
            discount = calculation1;
          }
        }
      } else {
        setSnack(true);
        setSnackMessage('Not minimum transcation amount');
      }
    } else {
      if (
        offerSelected.minimum_transaction_value &&
        cartTotal >= parseInt(offerSelected.minimum_transaction_value)
      ) {
        if (offerSelected.value_unit === 'percentage') {
          value = offerSelected.value ? parseInt(offerSelected.value) : 0;
          calculation1 = cartTotal * (value / 100);
          if (parseInt(offerSelected.maximum_discount_value) > 0) {
            if (
              offerSelected.maximum_discount_value &&
              calculation1 > parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = parseInt(offerSelected.maximum_discount_value);
            } else if (
              offerSelected.maximum_discount_value &&
              calculation1 <= parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = calculation1;
            }
          } else {
            discount = calculation1;
          }
        }
        if (offerSelected.value_unit === 'value') {
          value = offerSelected.value ? parseInt(offerSelected.value) : 0;
          calculation1 = value;
          if (parseInt(offerSelected.maximum_discount_value) > 0) {
            if (
              offerSelected.maximum_discount_value &&
              calculation1 > parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = parseInt(offerSelected.maximum_discount_value);
            } else if (
              offerSelected.maximum_discount_value &&
              calculation1 <= parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = calculation1;
            }
          } else {
            discount = calculation1;
          }
        }
      } else {
        setSnack(true);
        setSnackMessage('Not minimum transcation amount');
      }
    }
    setPromotionalDiscount(discount);
    setPromotionAdded(true);
  };
  const getTotalSavings = () => {
    let savings = promotionalDiscount + getPMdiscount();
    if (addCredit) savings = savings + creditsvalue;
    if (promovalue > 0 && promovalue_unit == 'percentage' && promoids.length > 0) {
      promoids.map(id => {
        cartDetailsProducts.map(product => {
          if (product.product_id == id) {
            savings = savings + (getPixelPrice(product) * promovalue) / 100;
          }
        });
      });
    }
    return Math.round(savings);
  };
  const getpromoSavings = () => {
    let savings = promotionalDiscount;
    if (promovalue > 0 && promovalue_unit == 'percentage' && promoids.length > 0) {
      promoids.map(id => {
        cartDetailsProducts.map(product => {
          if (product.product_id == id) {
            savings = savings + (getPixelPrice(product) * promovalue) / 100;
          }
        });
      });
    }
    return Math.round(savings);
  };

  const getTotal = () => {
    let sub = Math.round(getSubTotal() - getPMdiscount());
    let finalValue = sub - promotionalDiscount;
    if (addCredit) finalValue = finalValue - creditsvalue;

    if (promovalue > 0 && promovalue_unit == 'percentage' && promoids.length > 0) {
      promoids.map(id => {
        cartDetailsProducts.map(product => {
          if (product.product_id == id) {
            finalValue = finalValue - (getPixelPrice(product) * promovalue) / 100;
          }
        });
      });
    }
    return Math.round(finalValue);
  };

  const getTotal1 = () => {
    let sub = Math.round(getSubTotal() - getPMdiscount());
    if (addCredit) sub = sub - creditsvalue;
    if (promovalue > 0 && promovalue_unit == 'percentage' && promoids.length > 0) {
      promoids.map(id => {
        cartDetailsProducts.map(product => {
          if (product.product_id == id) {
            sub = sub - (getPixelPrice(product) * promovalue) / 100;
          }
        });
      });
    }
    return Math.round(sub);
  };

  // const checkInputCouponValidity = async (inputValue) => {
  //   setCouponValidateLoading(true);
  //   try {
  //     const res = await customerApiInstance.get(
  //       `${process.env.REACT_APP_BACKEND_URL}/api/coupon/validate/${inputValue}`
  //     );
  //     let result = res.data.coupon;
  //     if (
  //       result &&
  //       result.validation_status &&
  //       result.validation_status.status === 1000
  //     ) {
  //      // setInputCouponValid(true);
  //       setOfferSelectedValue(res.data.coupon.data);
  //       calculatePromoReduction(res.data.coupon.data);
  //     } else if (
  //       result &&
  //       result.validation_status &&
  //       result.validation_status.status === 1002
  //     ) {
  //       setSnack(true);
  //       setSnackMessage(result.validation_status.message);
  //     } else if (
  //       result &&
  //       result.validation_status &&
  //       result.validation_status.status === 1003
  //     ) {
  //       setSnack(true);
  //       setSnackMessage(result.validation_status.message);
  //     } else if (result && result.validation_status) {
  //       setSnack(true);
  //       setSnackMessage("Not a valid coupon");
  //     } else if (result && !result.validation_status && result.error) {
  //       setSnack(true);
  //       setSnackMessage("Wrong Promo code. Please enter a valid one");
  //     }
  //   } catch (e) {}
  //   setCouponValidateLoading(false);
  // };

  const couponsFunction = offer => {
    setOfferSelectedValue(offer);
    calculatePromoReduction(offer);

    setOfferType('coupon');
  };

  const handleCoupons = async offer => {
    setCouponDrawer(false);
    couponsFunction(offer);
  };
  const handleapplypromocode = async () => {
    setapplyload(true);
    if (promocode !== '') {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/vouchars/validate/${promocode}`
        );
        if (res.status == 200 && res.data.data) {
          if (getTotal() < res.data.data.minimum_transaction_value) {
            setSnack(true);
            setSnackMessage('Minimum transaction amount not met');
          } else if (res?.data?.data?.tag?.length > 0) {
            let tags = res.data.data.tag.map(string => {
              return string
                .split('_')
                .slice(1)
                .join(' ');
            });
            let ids = [];
            // ConsoleHelper(cartDetailsProducts, tags);

            cartDetailsProducts.map(item => {
              tags.map(tag => {
                var room = item.room_type.find(
                  element => element?.split(' ')[0].toLowerCase() == tag
                );
                //  ConsoleHelper(room?.split(' ')[0].toLowerCase(), tag);
                if (
                  tag == room?.split(' ')[0].toLowerCase() ||
                  tag == getVendor(item).toLowerCase() ||
                  tag == item.category.toLowerCase() ||
                  tag == item.style.toLowerCase()
                )
                  ids.push(item.product_id);
              });
            });
            setpromoids([...ids]);
            setpromovalue(Number(res.data.data.value));
            setvalue_unit(res.data.data.value_unit);
            setSnack(true);
            setSnackMessage('Promocode applied');
          }
          // do check tags and compare product apply offer only for particular product
          else {
            setpromovalue(Number(res.data.data.value));
            setvalue_unit(res.data.data.value_unit);
            setSnack(true);
            setSnackMessage('Promocode applied');
          }
        }
      } catch (error) {
        ConsoleHelper(error);
        if (error.response.status == 400) {
          setSnack(true);
          setSnackMessage(error.response.data.message);
        }
      }
    } else {
      setSnack(true);
      setSnackMessage('Enter valid promocode');
    }
    setapplyload(false);
  };
  const handlechangepromocode = e => {
    setpromocode(e.target.value);
  };
  const ordersummary = () => {
    return (
      <>
        <div className={classnames('row justify-content-between m-0', style.summary_headings)}>
          <span>Sub Total</span>
          <span>Rs. {new Intl.NumberFormat('en-IN').format(getSubTotal())}</span>
        </div>
        <div className={classnames('row justify-content-between m-0', style.summary_headings)}>
          <span>P & M Discount</span>
          <span>Rs. {new Intl.NumberFormat('en-IN').format(getPMdiscount())}</span>
        </div>
        <div className={classnames('row justify-content-between m-0', style.summary_headings)}>
          <span>Promotional Discount</span>
          <span>Rs. {getpromoSavings()}</span>
        </div>
        <div className={classnames('row justify-content-between m-0', style.summary_headings)}>
          <span>P & M Credit</span>
          <span>{addCredit ? creditsvalue : 0}</span>
        </div>
        <div
          className={'row justify-content-between m-0 mt-2'}
          style={{
            fontWeight: 900,
            paddingTop: '2vh',
            fontSize: '13px'
          }}>
          <span style={{ color: '#25B513' }}>Total Savings</span>
          <span style={{ color: '#25B513' }}>
            Rs. {new Intl.NumberFormat('en-IN').format(getTotalSavings())}
          </span>
        </div>

        <div
          className={'row justify-content-between m-0 mt-2'}
          style={{
            fontWeight: 900,
            paddingTop: '2vh',
            fontSize: '13px'
          }}>
          <span>Total</span>
          <span>
            {promotionAdded
              ? `Rs. ${new Intl.NumberFormat('en-IN').format(getTotal())}`
              : `Rs. ${new Intl.NumberFormat('en-IN').format(getTotal1())}`}
          </span>
        </div>
      </>
    );
  };
  const addpromocode = () => {
    return (
      <>
        {addClicked === false ? (
          !(offerSelectedValue && offerSelectedValue.coupon_code) ? (
            <p className={style.promoBtn}>
              Do you have promo code?{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  color: 'blue',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setPromo(!promo);
                }}>
                Add it here
              </span>
            </p>
          ) : null
        ) : (
          <>
            <div className="col-lg-8 col-sm-6 pt-2 font-weight-medium">
              <input
                type="text"
                name="promoCode"
                onChange={e => {
                  //   setInputPromo(e.target.value);
                }}></input>
            </div>
            <div className="col-lg-4 col-sm-6 pt-2 font-weight-medium">
              <button
                className={style.applyButton}
                onClick={() => {
                  // checkInputCouponValidity(inputPromo);
                }}>
                Apply
              </button>
            </div>
          </>
        )}
        {promo ? (
          <div className="mt-3">
            <InputGroup className="mb-3">
              <FormControl
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                onChange={e => handlechangepromocode(e)}
                value={promocode}
              />
              {applyload ? (
                <Loader type="Oval" visible={true} color="#000000" height={30} width={45} />
              ) : (
                <InputGroup.Text
                  id="basic-addon2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleapplypromocode()}>
                  Apply
                </InputGroup.Text>
              )}
            </InputGroup>
          </div>
        ) : null}
      </>
    );
  };
  useEffect(() => {
    async function fetchData() {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      document.body.appendChild(script);
      if (auth && auth.user) {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth.user._id}/credits`
        );
        if (res.status == 200 && res.data.message == 'Success' && res?.data?.data?.points) {
          setcreditsvalue(res.data.data.points);
        }
      }
    }
    fetchData();
    const fetchaddressData = async () => {
      try {
        if (auth && auth.user) {
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/address/${auth.user._id}`
          );
          if (res.data.length > 0) {
            setLatestAddressId(res.data.length - 1);
          }
          setAddressData([...res.data]);
          setAddressDataTemp([...res.data]);
        }
      } catch (err) {}
    };
    fetchaddressData();
    const getCartData = async () => {
      let cartData;
      let calculateOutofstock;
      if (auth && auth.user) {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/list`
        );
        if (res.data.cart.length !== 0) {
          cartData = [...res.data.cart];
          calculateOutofstock = [...res.data.cart];
          setCartProducts([...res.data.cartProducts]);
          calculateOutofstock[0].products = [...res.data.cart[0].products];
          setCartDetails([...res.data.cart]);
          let obj = {};
          let cartProducts1 = [...res.data.cartProducts];
          let stockData = cartData[0].products.filter(o1 =>
            cartProducts1.some(o2 => o1.product_id === o2._id && o2.stock !== 0)
          );
          let unStockData = calculateOutofstock[0].products.filter(o1 =>
            res.data.cartProducts.some(o2 => o1.product_id === o2._id && o2.stock === 0)
          );
          if (unStockData && unStockData.length > 0) {
            calculateOutofstock[0].products = [...unStockData];
            calculateOutofstock = filterQuantityAndPrice(calculateOutofstock);
            // setNonStockData({ ...calculateOutofstock });
            setNonStockDetailsProducts([...calculateOutofstock[0].products]);
          }
          cartData[0].products = [...stockData];
          cartData[0].products.forEach(item => {
            if (obj[item.product_id]) {
              obj[item.product_id].quantity = obj[item.product_id].quantity + item.quantity;
              obj[item.product_id].total_price =
                obj[item.product_id].total_price + item.total_price;
              obj[item.product_id].category = obj[item.product_id].category;
            } else {
              obj[item.product_id] = item;
            }
          });

          let valuesArr = Object.values(obj);
          cartData[0].products = [...valuesArr];
          res.data.cartProducts.map(item => {
            cartData[0].products.map((product, i) => {
              if (item._id == product.product_id) {
                cartData[0].products[i].category = item.category;
                cartData[0].products[i].style = item.style;
                cartData[0].products[i].room_type = item.room_type;
              }
              return;
            });
          });
          setCartDetails({ ...cartData[0] });
          setCartProducts([...res.data.cartProducts]);
          setCartDetailsProducts([...cartData[0].products]);
        }
      } else {
        let localCartProds = JSON.parse(localStorage.getItem('cartProducts'));
        let localDetailedCartProds = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
        if (localCartProds) {
          cartData = [{ products: [...localCartProds] }];
          calculateOutofstock = [{ products: [...localCartProds] }];
          setCartProducts([...localDetailedCartProds]);
          calculateOutofstock[0].products = [...localCartProds];
          setCartDetails([{ products: [...localCartProds] }]);
          let obj = {};
          let cartProducts1 = [...localDetailedCartProds];
          let stockData = cartData[0].products.filter(o1 =>
            cartProducts1.some(o2 => o1.product_id === o2._id && o2.stock !== 0)
          );
          let unStockData = calculateOutofstock[0].products.filter(o1 =>
            localDetailedCartProds.some(o2 => o1.product_id === o2._id && o2.stock === 0)
          );
          if (unStockData && unStockData.length > 0) {
            calculateOutofstock[0].products = [...unStockData];
            calculateOutofstock = filterQuantityAndPrice(calculateOutofstock);
            setNonStockDetailsProducts([...calculateOutofstock[0].products]);
          }
          cartData[0].products = [...stockData];
          cartData[0].products.forEach(item => {
            if (obj[item.product_id]) {
              obj[item.product_id].quantity = obj[item.product_id].quantity + item.quantity;
              obj[item.product_id].total_price =
                obj[item.product_id].total_price + item.total_price;
              obj[item.product_id].category = obj[item.product_id].category;
            } else {
              obj[item.product_id] = item;
            }
          });

          let valuesArr = Object.values(obj);
          cartData[0].products = [...valuesArr];
          localDetailedCartProds.map(item => {
            cartData[0].products.map((product, i) => {
              if (item._id == product.product_id) {
                cartData[0].products[i].category = item.category;
                cartData[0].products[i].style = item.style;
                cartData[0].products[i].room_type = item.room_type;
              }
              return;
            });
          });
          setCartDetails({ ...cartData[0] });
          setCartProducts([...localDetailedCartProds]);
          setCartDetailsProducts([...cartData[0].products]);
        }
      }
    };

    const getPackageData = () => {
      let packages = [];
      if (questionnaireData.Packages) {
        packages = questionnaireData.Packages;
        setPackagesData({ ...packages });
      }
    };
    getCartData();
    getSavedItems();
    getPackageData();
    setEditModal(false);
    const fetchPackages = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/packages/list`);
      let paidPackages = res.data.filter(pack => pack.identifier !== 'EXPRESS');
      setPackages([{ _id: 0, title: 'Design Package', price: 0 }, ...paidPackages]);
    };
    fetchPackages();
    const fetchCities = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/cities`);
        setCities(res.data);
      } catch (err) {}
    };
    fetchCities();
    const fetchUserDetail = async () => {
      if (auth && auth.user) {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth.user._id}`
        );

        if (res.data) {
          setUserDetails(res.data);
          if (res.data.gst_business_name) {
            setGstBusiness(res.data.gst_business_name);
          }
          if (res.data.gstin) {
            setGstinValue(res.data.gstin);
          }
        }
        setInputs({ ...inputs });
      }
    };
    fetchUserDetail();
  }, []);

  useEffect(() => {
    setAllProducts([...favProducts, ...cartProducts]);
  }, [favProducts, cartProducts]);

  useEffect(() => {
    if (!cartDetailsProducts.length > 0) {
      setPromotionAdded(false);
      setPromotionalDiscount(0);
    }
    const fetchOffers = async () => {
      if (cartDetailsProducts.length > 0) {
        setCouponsLoading(true);
        let notequalvalues = [];
        let tempArray = [];

        let finalCartProds = [];
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/offers`
        );
        let currentOffers = res.data.availableOffers;
        let filterOffers = [];
        if (currentOffers && currentOffers.length > 0) {
          for (let i = 0; i < currentOffers.length; i++) {
            if (
              currentOffers[i].hasOwnProperty('tag') &&
              currentOffers[i].tag.length > 0 &&
              currentOffers[i].tag.includes('product')
            ) {
              filterOffers.push(currentOffers[i]);
            }
          }
        }
        currentOffers = [...filterOffers];
        let tempOffers = [];
        for (let p = 0; p < cartDetailsProducts.length; p++) {
          let productValue = allProducts.find(
            prod => prod._id === cartDetailsProducts[p].product_id
          );
          finalCartProds.push(productValue);
        }
        for (let i = 0; i < currentOffers.length; i++) {
          if (currentOffers[i].custom_conditions && currentOffers[i].custom_conditions.length > 0) {
            let equalValues = [];
            for (let j = 0; j < currentOffers[i].custom_conditions.length; j++) {
              if (currentOffers[i].custom_conditions[j].operator === 'ne') {
                tempArray = currentOffers[i].custom_conditions[j].value.split(';');
                notequalvalues = [...notequalvalues, ...tempArray];
              }
              if (currentOffers[i].custom_conditions[j].operator === 'eq') {
                tempArray = currentOffers[i].custom_conditions[j].value.split(';');
                equalValues = [...equalValues, ...tempArray];
              }
            }
            let mappedProducts = [];
            if (finalCartProds.length > 0 && equalValues.length > 0) {
              for (let k = 0; k < equalValues.length; k++) {
                for (let i = 0; i < finalCartProds.length; i++) {
                  finalCartProds[i].category = finalCartProds[i].category.replace(/\W+/g, '');
                  finalCartProds[i].style = finalCartProds[i].style.replace(/\W+/g, '');
                  if (finalCartProds[i].vendor_id && finalCartProds[i].vendor_id.name) {
                    finalCartProds[i].vendor_id.name = finalCartProds[i].vendor_id.name.replace(
                      /\W+/g,
                      ''
                    );
                  }
                  if (
                    finalCartProds[i].category.toLowerCase() === equalValues[k].toLowerCase() ||
                    finalCartProds[i].style.toLowerCase() === equalValues[k].toLowerCase() ||
                    (finalCartProds[i].vendor_id &&
                      finalCartProds[i].vendor_id.name.toLowerCase() ===
                        equalValues[k].toLowerCase())
                  ) {
                    mappedProducts.push(finalCartProds[i]);
                  }
                }
              }
            }
            let uniqueObjArray = [
              ...new Map(mappedProducts.map(item => [item['_id'], item])).values()
            ];
            let offerProds = [...uniqueObjArray];
            if (offerProds.length > 0) {
              tempOffers.push(currentOffers[i]);
              setOfferProducts([...offerProducts, ...offerProds]);
            }
          } else {
            tempOffers.push(currentOffers[i]);
          }
        }
        setAvailableOffers([...tempOffers]);
        // setExpiredOffers([...res.data.expiredOffers]);
        //  setNotActivatedOffers([...res.data.notActivatedOffers]);
        setCouponsLoading(false);
      }
    };
    fetchOffers();
  }, [cartDetailsProducts, allProducts]);

  return (
    <div className={style.pageContainer} style={{ backgroundColor: '#F5F0EC' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header />
      <div className={classnames('container', style.mainContainer)} style={{ paddingTop: '3.8%' }}>
        {display === 'cart' ? (
          <>
            <div className="row ">
              <div className="col-lg-8">
                {dimension[0] > 500 ? (
                  <h3 className={style.myCart}>My Cart({cartDetailsProducts.length})</h3>
                ) : (
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <h3 className={style.myCart}>My Cart({cartDetailsProducts.length})</h3>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <p
                        className={style.continueShop}
                        style={{ marginTop: '15px', marginLeft: '10px' }}
                        onClick={() => history1.push('/shop')}>
                        Continue Shopping
                      </p>
                    </div>
                  </div>
                )}
                {dimension[0] > 500
                  ? cartDetailsProducts.map((data, index) => (
                      <div className={style.card}>
                        <div className="row">
                          <div className="col-2">
                            <img className="img-fluid" src={getImage(data)} alt="" />
                          </div>
                          <div className="col-4 pl-2">
                            <a
                              href=""
                              onClick={() => {
                                openinnewtab(`/product/${data.product_id}`);
                              }}>
                              <h5>{getName(data)}</h5>{' '}
                            </a>
                            <div className={classnames(style.cartData, 'mt-3')}>
                              Ships from: {getVendor(data)}
                            </div>
                            <div className={style.cartData}>ETA: Ships in 7-10 business days</div>
                          </div>
                          <div className="col-md-3 col-lg-2">
                            <div
                              className="pb-3"
                              style={{
                                fontSize: '13px',
                                paddingRight: '20px'
                              }}>
                              Qty
                            </div>
                            <button
                              className={classnames(style.qtyBtnss, 'd-flex')}
                              style={{
                                borderRadius: 30,
                                border: '1px solid #707070',
                                backgroundColor: '#ffffff'
                              }}>
                              <Icon.Dash
                                size={20}
                                className=" mr-3"
                                onClick={() => {
                                  //   setCurrentProduct(data);
                                  reduceQuantity(data);
                                }}
                              />
                              {data.quantity}
                              <Icon.Plus
                                size={20}
                                className="ml-3"
                                onClick={() => {
                                  //   setCurrentProduct(data);
                                  addQuantity(data);
                                }}
                              />
                            </button>
                          </div>
                          <div className="col-4 text-right">
                            <h5>
                              Rs. {new Intl.NumberFormat('en-IN').format(getPixelPrice(data))}
                            </h5>
                            <h5>
                              <s style={{ color: '#DB0000' }}>
                                Rs. {new Intl.NumberFormat('en-IN').format(getPrice(data))}
                              </s>
                            </h5>

                            <br></br>
                            <div>
                              <div className="row w-100 m-0 mt-5">
                                <div
                                  className="col-6 p-0 text-right"
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '15px'
                                  }}
                                  onClick={() => {
                                    if (auth && auth.user) {
                                      setSavedforLaterId([...savedForLaterId, data.product_id]);
                                      addToFavourites(data);
                                      removeFromCart(data);
                                    } else {
                                      setSnack(true);
                                      setSnackMessage('Please login to save items for later');
                                    }
                                  }}>
                                  <u>
                                    {savedForLaterId.includes(data.product_id)
                                      ? 'Item Saved for Later'
                                      : 'Save for later'}
                                  </u>
                                </div>
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '15px'
                                  }}
                                  className="col-6 p-0 text-right"
                                  onClick={() => {
                                    if (auth && auth.user) {
                                      removeFromCart(data);
                                    } else {
                                      let c1 = cartDetailsProducts.filter(
                                        prod => prod.product_id !== data.product_id
                                      );
                                      let c2 = cartProducts.filter(
                                        prod => prod._id !== data.product_id
                                      );
                                      localStorage.setItem('cartProducts', JSON.stringify(c1));
                                      localStorage.setItem(
                                        'cartProductsWithDetails',
                                        JSON.stringify(c2)
                                      );
                                      setCartDetailsProducts([...c1]);
                                      setCartProducts([...c2]);
                                    }
                                  }}>
                                  <u>Remove Item</u>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : cartDetailsProducts.map((data, index) => (
                      <div className={style.card} style={{ height: dimension[0] < 500 && 'auto' }}>
                        <div className="row">
                          <div className="col-3 m-0">
                            <img className="img-fluid" src={getImage(data)} alt="" />
                          </div>
                          <div className="col-8 m-0 ml-0">
                            <a
                              href=""
                              onClick={() => {
                                openinnewtab(`/product/${data.product_id}`);
                              }}>
                              <h5>{getName(data)}</h5>{' '}
                            </a>
                            <h6>
                              Rs. {new Intl.NumberFormat('en-IN').format(getPixelPrice(data))}
                            </h6>
                            <h6>
                              <s style={{ color: '#DB0000' }}>
                                Rs. {new Intl.NumberFormat('en-IN').format(getPrice(data))}
                              </s>
                            </h6>
                            <span>Ships from: {getVendor(data)}</span>
                            <br></br>
                            <span>ETA: Ships in 7-10 business days</span>
                            <div className="row justify-content-left m-0 mt-3">
                              <span
                                style={{
                                  fontSize: '13px',
                                  paddingRight: '20px'
                                }}>
                                Qty
                              </span>
                              <button
                                className="d-flex"
                                style={{
                                  borderRadius: 30,
                                  fontSize: '14px',
                                  border: '1px solid #707070',
                                  backgroundColor: '#ffffff'
                                }}>
                                <Icon.Dash
                                  size={20}
                                  className=" mr-3"
                                  onClick={() => {
                                    //   setCurrentProduct(data);
                                    reduceQuantity(data);
                                  }}
                                />
                                {data.quantity}
                                <Icon.Plus
                                  size={20}
                                  className="ml-3"
                                  onClick={() => {
                                    //  setCurrentProduct(data);
                                    addQuantity(data);
                                  }}
                                />
                              </button>
                            </div>
                            <div className={classnames(style.priceContainer)}>
                              <span
                                style={{ cursor: 'pointer', fontSize: '12px' }}
                                onClick={() => {
                                  setSavedforLaterId([...savedForLaterId, data.product_id]);
                                  addToFavourites(data);
                                }}>
                                {savedForLaterId.includes(data.product_id)
                                  ? 'Item Saved for Later'
                                  : 'Save for later'}
                              </span>
                              <span
                                style={{ cursor: 'pointer', fontSize: '12px' }}
                                className="ml-3"
                                onClick={() => {
                                  if (auth && auth.user) {
                                    removeFromCart(data);
                                  } else {
                                    let c1 = cartDetailsProducts.filter(
                                      prod => prod.product_id !== data.product_id
                                    );
                                    let c2 = cartProducts.filter(
                                      prod => prod._id !== data.product_id
                                    );
                                    localStorage.setItem('cartProducts', JSON.stringify(c1));
                                    localStorage.setItem(
                                      'cartProductsWithDetails',
                                      JSON.stringify(c2)
                                    );
                                    setCartDetailsProducts([...c1]);
                                    setCartProducts([...c2]);
                                  }
                                }}>
                                Remove Item
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                {dimension[0] > 500
                  ? packagesData &&
                    Object.keys(packagesData).length > 0 && (
                      <div className={style.card}>
                        <div className="row">
                          <div className="col-2">
                            <img className="img-fluid" src={getPackageImage(packagesData)} alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-8 pl-2">
                            Room Type
                            <div className={style.heading}>
                              {packagesData?.questionnaire?.initial_room}
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-4">
                            Package
                            <div className={style.heading}>
                              {packagesData.questionnaire?.package?.title}
                            </div>
                          </div>
                          <div className="col-4">
                            <h5 style={{ float: 'right' }}>
                              Rs.{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                packagesData?.questionnaire?.package?.price
                              )}
                            </h5>
                            <br></br>

                            <div>
                              <div className="row w-100 m-0 mt-5">
                                <div
                                  className="col-6 p-0 text-right"
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '15px'
                                  }}
                                  onClick={() => {
                                    if (auth && auth.user) {
                                      if (packagesData.project_id) {
                                        history1.push({
                                          pathname: `/package-payment/${packagesData.project_id}`,
                                          state: {
                                            projectId: packagesData.project_id
                                          }
                                        });
                                      } else {
                                        history1.push('package-payment');
                                      }
                                    } else {
                                      setSnack(true);
                                      setSnackMessage('Please login to buy the packages');
                                    }
                                  }}>
                                  <u>Checkout</u>
                                </div>
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '15px'
                                  }}
                                  className="col-6 p-0 text-right"
                                  onClick={() => {
                                    storePackage({});
                                    setPackagesData({});
                                  }}>
                                  <u>Remove Item</u>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  : packagesData &&
                    Object.keys(packagesData).length > 0 && (
                      <div className={style.card} style={{ height: dimension[0] < 500 && '200px' }}>
                        <div className="row">
                          <div className="col-3 m-0">
                            <img className="img-fluid" src={getPackageImage(packagesData)} alt="" />
                          </div>
                          <div className="col-8 m-0 ml-0">
                            <div className="col-lg-4 col-md-4 col-sm-8">
                              Room Type
                              <div className={style.heading}>
                                {packagesData?.questionnaire?.initial_room}
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4">
                              Package
                              <div className={style.heading}>
                                {packagesData.questionnaire?.package?.title}
                              </div>
                            </div>
                            <div>
                              <h5>
                                Rs.{' '}
                                {new Intl.NumberFormat('en-IN').format(
                                  packagesData?.questionnaire?.package?.price
                                )}
                              </h5>
                            </div>
                            <div className={classnames(style.priceContainer)}>
                              <span
                                style={{
                                  cursor: 'pointer',
                                  fontSize: '12px'
                                }}
                                onClick={() => {
                                  if (auth && auth.user) {
                                    if (packagesData.project_id) {
                                      history1.push({
                                        pathname: `/package-payment/${packagesData.project_id}`,
                                        state: {
                                          projectId: packagesData.project_id
                                        }
                                      });
                                    } else {
                                      history1.push('package-payment');
                                    }
                                  } else {
                                    setSnack(true);
                                    setSnackMessage('Please login to buy the packages');
                                  }
                                }}>
                                {/* Checkout */}
                              </span>
                              <span
                                style={{
                                  cursor: 'pointer',
                                  fontSize: '12px'
                                  // fontWeight: "bold",
                                }}
                                className="ml-3"
                                onClick={() => {
                                  storePackage({});
                                  setPackagesData({});
                                }}>
                                Remove Item
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
              </div>
              <div className="col-lg-4 mt-md-5">
                <div className={classnames(style.summaryContainer)}>
                  <div className={style.orderSum}>
                    <h3>Order Summary</h3>
                  </div>
                  <div className={style.summarySubContainer}>
                    {ordersummary()}
                    <div className="row justify-content-center m-0">
                      <button
                        onClick={() => {
                          if (auth && auth.user && cartProducts.length !== 0) {
                            setDisplay('checkout');
                          } else if (auth && auth.user && cartProducts.length === 0) {
                            history1.push('/shop');
                            setSnackMessage('Add Products to Cart');
                            setSnack(true);
                          } else {
                            setSnackMessage('Please Login/Sign up to Checkout the products');
                            setSnack(true);
                            history1.push('/login');
                          }
                        }}
                        className={style.checkoutBtn}>
                        Continue
                      </button>
                    </div>
                    {/*  Will implement later */}
                    <div className="row m-0 mt-2">{addpromocode()}</div>

                    {promotionAdded === true && offerType === 'coupon' ? (
                      <div className="row m-0 ">
                        <button className={style.couponBtn}>
                          <div className="row">
                            <div className="col-9  d-flex justify-content-center">
                              {offerSelectedValue && offerSelectedValue.coupon_code
                                ? offerSelectedValue.coupon_code
                                : 'offer'}{' '}
                              Applied
                            </div>
                            <div className="col-2 d-flex justify-content-end">
                              <img
                                className="d-flex justify-contnetn-end"
                                src={
                                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                }
                                style={{ width: '90%' }}
                                onClick={() => {
                                  setPromotionAdded(false);
                                  setOfferSelectedValue({});
                                  setOfferType('');
                                  setPromotionalDiscount(0);
                                }}
                              />
                            </div>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div className="row m-0 ">
                        <button
                          className={style.couponBtn}
                          onClick={() => {
                            let totalTemp = getSubTotal();
                            let availableOffers1 = availableOffers.filter(
                              avl => totalTemp >= avl.minimum_transaction_value
                            );
                            setAvailableOffers([...availableOffers1]);
                            setCouponDrawer(true);
                          }}>
                          Apply Coupon Code
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {nonStockDetailsProducts && nonStockDetailsProducts.length !== 0 ? (
              <div className="row ">
                <div className="col-lg-8">
                  <h3 className="mt-5" style={{ color: 'red' }}>
                    Out of Stock
                  </h3>
                  {dimension[0] > 500
                    ? nonStockDetailsProducts.map((data, index) => (
                        <div className={style.card}>
                          <div className="row">
                            <div className="col-2">
                              <img className="img-fluid" src={getImage(data)} alt="" />
                            </div>
                            <div className="col-4 pl-2">
                              <h5>{getName(data)}</h5>
                              <br></br>
                              <div className="mt-3">Ships from: {getVendor(data)}</div>
                              <div>ETA: Ships in 7-10 business days</div>
                            </div>
                            <div className="col-md-3 col-lg-2">
                              <div
                                className="pb-3"
                                style={{
                                  fontSize: '13px',
                                  paddingRight: '20px'
                                }}>
                                Qty
                              </div>
                              <button
                                className="d-flex"
                                style={{
                                  borderRadius: 30,
                                  border: '1px solid #707070',
                                  backgroundColor: '#ffffff'
                                }}>
                                <Icon.Dash
                                  size={20}
                                  className="mr-3"
                                  onClick={() => {
                                    reduceUnavailableQuantity(data);
                                  }}
                                />
                                {data.quantity}
                                <Icon.Plus
                                  size={20}
                                  className="ml-3"
                                  onClick={() => {
                                    addUnavailableQuantity(data);
                                  }}
                                />
                              </button>
                            </div>
                            <div className="col-4">
                              <h5 style={{ float: 'right' }}>
                                Rs. {new Intl.NumberFormat('en-IN').format(getPixelPrice(data))}
                              </h5>
                              <br></br>
                              <div>
                                <div className="row w-100 m-0 mt-5">
                                  <div
                                    className="col-6 p-0 text-right"
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '15px'
                                    }}
                                    onClick={() => {
                                      if (auth && auth.user) {
                                        setSavedforLaterId([...savedForLaterId, data.product_id]);
                                        addToFavourites(data);
                                        removeFromCart(data);
                                        nonStockDetailsProducts = nonStockDetailsProducts.filter(
                                          d => d.product_id !== data.product_id
                                        );
                                        setNonStockDetailsProducts([...nonStockDetailsProducts]);
                                      } else {
                                        setSnack(true);
                                        setSnackMessage('Please login to save items for later');
                                      }
                                    }}>
                                    <u>
                                      {savedForLaterId.includes(data.product_id)
                                        ? 'Item Saved for Later'
                                        : 'Save for later'}
                                    </u>
                                  </div>
                                  <div
                                    className="col-6  p-0 text-right "
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '15px'
                                    }}
                                    onClick={() => {
                                      if (!auth || !auth.user) {
                                        nonStockDetailsProducts = nonStockDetailsProducts.filter(
                                          prod => prod.product_id !== data.product_id
                                        );
                                        localStorage.setItem(
                                          'cartProducts',
                                          JSON.stringify(cartDetailsProducts)
                                        );
                                        setNonStockDetailsProducts([...nonStockDetailsProducts]);
                                      } else {
                                        removeFromCart(data);
                                        nonStockDetailsProducts = nonStockDetailsProducts.filter(
                                          d => d.product_id !== data.product_id
                                        );
                                        setNonStockDetailsProducts([...nonStockDetailsProducts]);
                                      }
                                    }}>
                                    <u>Remove Item</u>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : nonStockDetailsProducts.map((data, index) => (
                        <div
                          className={style.card}
                          style={{ height: dimension[0] < 500 && '200px' }}>
                          <div className="row">
                            <div className="col-3 m-0">
                              <img className="img-fluid" src={getImage(data)} alt="" />
                            </div>
                            <div className="col-8 m-0 ml-4">
                              <h5>{getName(data)}</h5>
                              <h6>
                                Rs. {new Intl.NumberFormat('en-IN').format(getPixelPrice(data))}
                              </h6>
                              <span>Ships from: {getVendor(data)}</span>
                              <br></br>
                              <span>ETA: Ships in 7-10 business days</span>
                              <div className="row justify-content-left m-0 mt-3">
                                <span
                                  style={{
                                    fontSize: '13px',
                                    paddingRight: '20px'
                                  }}>
                                  Qty
                                </span>
                                <button
                                  className="d-flex"
                                  style={{
                                    borderRadius: 30,
                                    border: '1px solid #707070',
                                    backgroundColor: '#ffffff'
                                  }}>
                                  <Icon.Dash
                                    size={20}
                                    className=" mr-3"
                                    onClick={() => {
                                      reduceUnavailableQuantity(data);
                                    }}
                                  />
                                  {data.quantity}
                                  <Icon.Plus
                                    size={20}
                                    className="ml-3"
                                    onClick={() => {
                                      addUnavailableQuantity(data);
                                    }}
                                  />
                                </button>
                              </div>
                              <div className={classnames(style.priceContainer)}>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    fontWeight: 'bold'
                                  }}
                                  onClick={() => {
                                    if (auth && auth.user) {
                                      setSavedforLaterId([...savedForLaterId, data.product_id]);
                                      addToFavourites(data);
                                      removeFromCart(data);
                                      nonStockDetailsProducts = nonStockDetailsProducts.filter(
                                        d => d.product_id !== data.product_id
                                      );
                                      setNonStockDetailsProducts([...nonStockDetailsProducts]);
                                    } else {
                                      setSnack(true);
                                      setSnackMessage('Please login to save items for later');
                                    }
                                  }}>
                                  {savedForLaterId.includes(data.product_id)
                                    ? 'Item Saved for Later'
                                    : 'Save for later'}
                                </span>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    fontWeight: 'bold'
                                  }}
                                  className="ml-3"
                                  onClick={() => {
                                    if (!auth || !auth.user) {
                                      nonStockDetailsProducts = nonStockDetailsProducts.filter(
                                        prod => prod.product_id !== data.product_id
                                      );
                                      localStorage.setItem(
                                        'cartProducts',
                                        JSON.stringify(cartDetailsProducts)
                                      );
                                      setNonStockDetailsProducts([...nonStockDetailsProducts]);
                                    } else {
                                      removeFromCart(data);
                                      nonStockDetailsProducts = nonStockDetailsProducts.filter(
                                        d => d.product_id !== data.product_id
                                      );
                                      setNonStockDetailsProducts([...nonStockDetailsProducts]);
                                    }
                                  }}>
                                  Remove Item
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            ) : null}
            {favDetailsProducts && favDetailsProducts.length !== 0 ? (
              <div className="row mb-3 ">
                <div className="col-lg-8">
                  <h3 className="mt-5">Save for later</h3>
                  {dimension[0] > 500
                    ? favProducts &&
                      favProducts.length > 0 &&
                      favDetailsProducts.map((data, index) => (
                        <div className={style.card}>
                          <div className="row">
                            <div className="col-2">
                              <img src={getSavedImage(data)} alt="" />
                            </div>
                            <div className="col-4 pl-2">
                              <h5>{getSavedName(data)}</h5>
                              <div className="mt-3">Ships from: {getSavedVendor(data)}</div>
                              <div>ETA: Ships in 7-10 business days</div>
                            </div>
                            <div className="col-2 ">
                              <div
                                className="pb-3"
                                style={{
                                  fontSize: '13px',
                                  paddingRight: '20px'
                                }}>
                                Qty
                              </div>
                              <button
                                className="d-flex"
                                style={{
                                  borderRadius: 30,
                                  border: '1px solid #707070',
                                  backgroundColor: '#ffffff'
                                }}>
                                <Icon.Dash
                                  size={20}
                                  className="mr-3"
                                  onClick={() => {
                                    reduceSaveQuantity(data);
                                  }}
                                />
                                {data.quantity}
                                <Icon.Plus
                                  size={20}
                                  className="ml-3"
                                  onClick={() => {
                                    addSaveQuantity(data);
                                  }}
                                />
                              </button>
                            </div>
                            <div className="col-4">
                              <h5 style={{ float: 'right' }}>
                                Rs. {new Intl.NumberFormat('en-IN').format(getPixelPrice(data))}
                              </h5>
                              <br></br>
                              <div>
                                <div className="row w-100 mt-5 m-0">
                                  <div
                                    className="col-6 p-0 text-right"
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '15px'
                                    }}
                                    onClick={() => {
                                      savedForLaterId = savedForLaterId.filter(
                                        d => d !== data.product_id
                                      );
                                      setSavedforLaterId([...savedForLaterId]);
                                      addToCart(data);
                                      removeFromFavourites(data);
                                    }}>
                                    <u>Move to Cart</u>
                                  </div>
                                  <div
                                    className="col-6 p-0 text-right"
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '15px'
                                    }}
                                    onClick={() => {
                                      removeFromFavourites(data);
                                    }}>
                                    <u>Remove Item</u>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : favProducts &&
                      favProducts.length > 0 &&
                      favDetailsProducts.map((data, index) => (
                        <div className={style.card1} key={index}>
                          <div className="row">
                            <div className="col-3 m-0">
                              <img src={getSavedImage(data)} alt="" />
                            </div>
                            <div className="col-8 m-0 ml-4">
                              <h5>{getSavedName(data)}</h5>
                              <h6>
                                Rs. {new Intl.NumberFormat('en-IN').format(getPixelPrice(data))}
                              </h6>
                              <span>Ships from: {getSavedVendor(data)}</span>
                              <br></br>
                              <span>ETA: Ships in 7-10 business days</span>
                              <div className="row justify-content-left m-0 mt-3">
                                <span
                                  style={{
                                    fontSize: '13px',
                                    paddingRight: '20px'
                                  }}>
                                  Qty
                                </span>
                                <button
                                  className="d-flex"
                                  style={{
                                    borderRadius: 30,
                                    border: '1px solid #707070',
                                    backgroundColor: '#ffffff'
                                  }}>
                                  <Icon.Dash
                                    size={20}
                                    className=" mr-3"
                                    onClick={() => {
                                      reduceSaveQuantity(data);
                                    }}
                                  />
                                  {data.quantity}
                                  <Icon.Plus
                                    size={20}
                                    className="ml-3"
                                    onClick={() => {
                                      addSaveQuantity(data);
                                    }}
                                  />
                                </button>
                              </div>
                              <div className={classnames(style.priceContainer)}>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    fontWeight: 'bold'
                                  }}
                                  onClick={() => {
                                    savedForLaterId = savedForLaterId.filter(
                                      d => d !== data.product_id
                                    );
                                    setSavedforLaterId([...savedForLaterId]);
                                    addToCart(data);
                                    removeFromFavourites(data);
                                  }}>
                                  <u>Move to Cart</u>
                                </span>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    fontWeight: 'bold'
                                  }}
                                  className="ml-3"
                                  onClick={() => {
                                    removeFromFavourites(data);
                                  }}>
                                  <u>Remove Item</u>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            ) : null}
          </>
        ) : display === 'checkout' ? (
          <>
            <div className="row">
              <div className="col-lg-8">
                {dimension[0] > 500 ? (
                  <h3 style={{ fontWeight: 600 }}>Checkout</h3>
                ) : (
                  <div>
                    <div className="d-flex flex-row justify-content-right align-items-right">
                      <div>
                        <FontAwesomeIcon style={{ alignItems: 'center' }} icon={faChevronLeft} />
                      </div>
                      <p
                        style={{ marginLeft: '10px', marginBottom: '0px' }}
                        onClick={() => setDisplay('cart')}>
                        Back to my Cart
                      </p>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                      <h3
                        style={{
                          fontSize: '16px',
                          marginTop: '6px',
                          fontWeight: 500
                        }}>
                        Checkout{' '}
                      </h3>
                    </div>
                  </div>
                )}
                <div>
                  <>
                    <div className={classnames(style.checkoutContainer)}>
                      <h5>User Details</h5>
                      <div className="row justify-content-left m-0">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                          <span>Email*</span>
                          <br></br>
                          <input
                            className={style.checkoutInput}
                            name="email"
                            value={auth.user.email || ''}
                            required
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-left m-0 mt-3">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                          <span>First Name*</span>
                          <br></br>
                          <input
                            className={style.checkoutInput}
                            name="firstname"
                            value={
                              deliveryAddress &&
                              addressdata[latestAddressId] &&
                              addressdata[latestAddressId].address &&
                              addressdata[latestAddressId].address.firstname
                                ? addressdata[latestAddressId].address.firstname
                                : inputs.firstname || ''
                            }
                            required
                            onChange={handleChangeDelivery}
                            placeholder="First Name"
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                          <span>Last Name*</span>
                          <br></br>
                          <input
                            className={style.checkoutInput}
                            name="lastname"
                            value={
                              deliveryAddress &&
                              addressdata[latestAddressId] &&
                              addressdata[latestAddressId].address &&
                              addressdata[latestAddressId].address.lastname
                                ? addressdata[latestAddressId].address.lastname
                                : inputs.lastname || ''
                            }
                            required
                            onChange={handleChangeDelivery}
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>

                    {addressdata.length > 0 ? (
                      <>
                        <div className={classnames(style.checkoutContainer)}>
                          <div className="row">
                            <div className="col-6 d-flex justify-content-start">
                              <h5>
                                {addressdata.length > 0 ? 'Most Recently used' : 'Shipping Address'}
                              </h5>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                              <p
                                style={{
                                  fontSize: '17px',
                                  cursor: 'pointer',
                                  fontWeight: '400',
                                  textDecoration: 'underline'
                                }}
                                onClick={() => {
                                  setCurrenAddressData(latestAddressId, addressdata);
                                  setCurrentAddressId(latestAddressId);
                                  setModal(true);
                                  setAddNewAddress(false);
                                }}>
                                Edit
                              </p>
                            </div>
                          </div>

                          {addressdata.map((address, i) => {
                            return (
                              <div className={classnames('row ml-2', style.addressPart)} key={i}>
                                <div
                                  className="col-lg-7 col-md-7 col-sm-12 col-xs-12 p-1"
                                  id={`address${i}`}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`flexRadioDefault${i}`}
                                    id={`flexRadioDefault${i}`}
                                    checked={currentAddressId === i}
                                    onClick={() => {
                                      setLatestAddressId(i);
                                      setDeliveryAddress(true);
                                      setCurrenAddressData(i, addressdata);
                                      setCurrentAddressId(i);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`flexRadioDefault${i}`}
                                    style={{ marginLeft: '0px' }}>
                                    <p
                                      style={{
                                        fontSize: '15px',
                                        fontWeight: '500'
                                      }}>
                                      {getAddressValues(address.address)}
                                    </p>
                                  </label>
                                </div>

                                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12 "></div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={classnames(style.checkoutContainer)}>
                          <h5>Shipping Address</h5>
                          <div className="row justify-content-left m-0 mt-3">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                              <span>Address Line 1</span>
                              <br></br>
                              <input
                                className={style.checkoutInput}
                                name="line1"
                                value={
                                  deliveryAddress
                                    ? addressdata[addressdata.length - 1].address.line1
                                    : inputs.line1 || ''
                                }
                                required
                                onChange={handleChangeDelivery}
                                placeholder="Address Line 1"
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                              <span>Address Line 2</span>
                              <br></br>
                              <input
                                className={style.checkoutInput}
                                name="line2"
                                value={
                                  deliveryAddress
                                    ? addressdata[addressdata.length - 1].address.line2
                                    : inputs.line2 || ''
                                }
                                required
                                onChange={handleChangeDelivery}
                                placeholder="Address Line 2"
                              />
                            </div>
                          </div>
                          <div className="row justify-content-left m-0 mt-3">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                              <span>Postalcode</span>
                              <br></br>
                              <input
                                className={style.checkoutInput}
                                name="postcode"
                                type="number"
                                value={
                                  deliveryAddress
                                    ? addressdata[addressdata.length - 1].address.postcode
                                    : inputs.postcode || ''
                                }
                                required
                                onChange={handleChangeDelivery}
                                placeholder="Postal Code"
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                              <span>City/ District</span>
                              <br></br>
                              <input
                                className={style.checkoutInput}
                                name="city"
                                value={
                                  deliveryAddress
                                    ? addressdata[addressdata.length - 1].address.city
                                    : inputs.city || ''
                                }
                                required
                                onChange={handleChangeDelivery}
                                placeholder="city/district"
                              />
                            </div>
                          </div>
                          <div className="row justify-content-left m-0 mt-3">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                              <span>State</span>
                              <br></br>
                              <select
                                name="state"
                                placeholder="Select State"
                                className={style.checkoutInput}
                                value={
                                  deliveryAddress
                                    ? addressdata[addressdata.length - 1].address.state
                                    : inputs.state || ''
                                }
                                onChange={handleChangeDelivery}>
                                <option>Select state</option>
                                {Object.keys(cities).map(state => (
                                  <option value={state}>{state}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                              <span>Phone*</span>
                              <br></br>
                              <input
                                className={style.checkoutInput}
                                type="number"
                                name="phone"
                                value={
                                  deliveryAddress
                                    ? addressdata[addressdata.length - 1].address.phone
                                    : inputs.phone || ''
                                }
                                required
                                onChange={handleChangeDelivery}
                                placeholder="Phone"
                              />
                              {error === 'phone' && (
                                <span style={{ color: 'red', fontSize: '11px' }}>
                                  Number must be 10 digits
                                </span>
                              )}
                            </div>
                          </div>
                          <h5 className={classnames(style.addBtn, 'mt-3')}>Address Time</h5>
                          <div className="row  m-0 mt-3">
                            <div className=" col-5 ">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="home"
                                id="flexRadioDefault1"
                                checked={addressType === 'home' || false}
                                onChange={e => {
                                  setAddressType('home');
                                  handleChangeDelivery(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                                style={{
                                  marginLeft: '0px',
                                  fontSize: '14px',
                                  fontFamily: 'QuickSand',
                                  fontWeight: '500'
                                }}>
                                Home (All day Delivery)
                              </label>
                            </div>
                            <div className=" col-5 ">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="work"
                                id="flexRadioDefault1"
                                checked={addressType === 'work' || false}
                                onChange={e => {
                                  setAddressType('work');
                                  handleChangeDelivery(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                                style={{
                                  marginLeft: '0px',
                                  fontSize: '14px',
                                  fontFamily: 'QuickSand',
                                  fontWeight: '500'
                                }}>
                                Work(delivery between 10AM - 5 PM)
                              </label>
                            </div>
                          </div>
                          {!deliveryAddress && (
                            <div className="d-flex justify-content-start mt-2">
                              <button
                                className={style.deliveryBtn}
                                style={{
                                  paddingLeft: '30px',
                                  paddingRight: '30px'
                                }}
                                onClick={() => {
                                  handleSubmitAddress();
                                  if (addressdata.length > 0) {
                                    setDeliveryAddress(true);
                                  }
                                }}>
                                Save and Deliver here
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {addressdata.length > 0 && (
                      <div className={classnames(style.checkoutContainer)}>
                        <div className="row justify-content-between">
                          <h5
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setModal(true);
                              setInputs({});
                              setAddNewAddress(true);
                            }}>
                            <Icon.Plus size={30} />{' '}
                            <span
                              style={{
                                position: 'relative',
                                top: '2px',
                                fontSize: '18px',
                                fontWeight: 700,
                                marginBottom: '0px'
                              }}>
                              Add New Address
                            </span>
                          </h5>
                        </div>
                      </div>
                    )}
                    <div className={classnames(style.checkoutContainer, 'mb-3')}>
                      {userDetails.hasOwnProperty('gstin') && userDetails.gstin !== '' && (
                        <>
                          <div className="d-flex flex-row mt-2" style={{ fontSize: '14px' }}>
                            <div className="col-lg-4 col-md-4 col-sm-8 ml-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={addGst ? true : false}
                                onClick={() => setAddGst(!addGst)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                                style={{ marginLeft: '0px' }}>
                                Create GST invoice
                              </label>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-6">
                              <span>
                                {userDetails.gstin} | {userDetails.gst_business_name}
                              </span>
                            </div>
                            <div className={classnames('col-6 d-flex justify-content-end')}>
                              <button
                                className={style.applyButton}
                                onClick={() => {
                                  setGstModal(true);
                                  setGstEdit(true);
                                }}>
                                Change{' '}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {(!userDetails.hasOwnProperty('gstin') || userDetails.gstin === '') && (
                        <div className="d-flex flex-row mt-2" style={{ fontSize: '14px' }}>
                          <div className={classnames('col-md-4')}>
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                              style={{
                                marginLeft: '0px',
                                fontWeight: 700,
                                fontSize: '17px'
                              }}>
                              Add GST
                            </label>
                          </div>
                          <div className={classnames('col-md-7 d-flex justify-content-end')}>
                            <button
                              className={style.applyButton}
                              onClick={() => {
                                setGstModal(true);
                              }}>
                              Add{' '}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </div>
              <div className="col-lg-4 mt-5">
                <div className={classnames(style.summaryContainer)}>
                  <div className={style.orderSum}>
                    <h3>Order Summary</h3>
                  </div>
                  <div className={style.summarySubContainer}>
                    {ordersummary()}
                    <div className="row justify-content-center m-0">
                      <button
                        onClick={() => {
                          if (deliveryAddress && deliverToAddress) {
                            setDisplay('shipment');
                            setShipmentData([...cartDetailsProducts]);
                          } else {
                            setSnack(true);
                            setSnackMessage('Please select a address to checkout');
                          }
                        }}
                        className={style.checkoutBtn}>
                        Continue
                      </button>
                    </div>
                    {/* Will implement later */}
                    {addpromocode()}

                    {promotionAdded === true && offerType === 'coupon' ? (
                      <div className="row m-0 ">
                        <button className={style.couponBtn}>
                          <div className="row">
                            <div className="col-9  d-flex justify-content-center">
                              {offerSelectedValue && offerSelectedValue.coupon_code
                                ? offerSelectedValue.coupon_code
                                : 'offer'}{' '}
                              Applied
                            </div>
                            <div className="col-2 d-flex justify-content-end">
                              <img
                                className="d-flex justify-contnetn-end"
                                src={
                                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                }
                                style={{ width: '90%' }}
                                onClick={() => {
                                  setPromotionAdded(false);
                                  setOfferSelectedValue({});
                                  setOfferType('');
                                  setPromotionalDiscount(0);
                                }}
                              />
                            </div>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div className="row m-0 ">
                        <button
                          className={style.couponBtn}
                          onClick={() => {
                            let totalTemp = getSubTotal();
                            let availableOffers1 = availableOffers.filter(
                              avl => totalTemp >= avl.minimum_transaction_value
                            );
                            setAvailableOffers([...availableOffers1]);
                            setCouponDrawer(true);
                          }}>
                          Apply Coupon Code
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-4 mb-2">
                <div className="col-lg-9 col-12">
                  <div className={style.checkoutRecentContainer}>
                    {dimension[0] > 500
                      ?
                      <div className="row justify-content-between">
                        <div className="col-6">
                          <h5>Most Recently used</h5>

                          <p style={{ fontSize: '12px' }}>{currentAddress ? currentAddress : "No added address"}.</p>

                        </div>
                        <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                          <button className={style.deliveryBtn} onClick={() => {
                            if (addressdata.length > 0) {
                              setDeliveryAddress(true)

                            } else {
                              setSnack(true)
                              setSnackMessage("Please add an address to continue")
                            }
                          }}>
                            Deliver to this address
                          </button>
                          <div className={style.buttonContainer}>
                            <button className={style.edit_btn} onClick={() => {
                              setCurrenAddressData(addressdata.length - 1)
                              setCurrentAddressId(addressdata.length - 1)
                              setModal(true)
                            }}>
                              Edit
                            </button>
                            <button className={style.delete_btn} onClick={() => {
                              setCurrentAddress(addressdata[addressdata.length - 2].address.line1 + " ," + addressdata[addressdata.length - 2].address.line2 + " ," + addressdata[addressdata.length - 2].address.city + " ," + addressdata[addressdata.length - 2].address.postcode + " ," + addressdata[addressdata.length - 2].address.state)
                              handledeleteaddress(addressdata[addressdata.length - 1]._id)
                            }}>
                              Delete
                            </button>
                          </div>
                         
                        </div>
                      </div>
                      :
                      <div className="row d-flex flex-column justify-content-left">
                        <h5>Most Recently used</h5>
                        <p className="mt-3">
                          {currentAddress ? currentAddress : "No added address"}.
                        </p>
                        <div className="d-flex flex-column w-100">
                          <button className={style.deliveryBtn} style={{ width: '100%' }} onClick={() => {
                            if (addressdata.length > 0) {
                              setDeliveryAddress(true)
                             
                            } else {
                              setSnack(true)
                              setSnackMessage("Please add an address to continue")
                            }
                          }}>
                            Deliver to this address
                          </button>
                          <div className="d-flex flex-row w-100 mt-3">

                            <button style={{ width: '100%', height: '40px' }} className={style.edit_btn} onClick={() => {
                              setCurrenAddressData(addressdata.length - 1)
                              setCurrentAddressId(addressdata.length - 1)
                              setModal(true)
                            }}
                            >
                              Edit
                            </button>
                            <button style={{ width: '100%', height: '40px' }} className={style.delete_btn} onClick={() => handledeleteaddress(addressdata[addressdata.length - 1]._id)}>
                              Delete
                            </button>

                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div> */}

            {/* <div className="row mt-4 mb-2">
                <div className="col-lg-9 col-12">
                  <div className={style.checkoutRecentContainer}>
                    {dimension[0] > 500
                      ?
                      <div className="row justify-content-between">
                        <div className='col-6'>
                          <h5 style={{ cursor: 'pointer' }} onClick={() => {
                            setModal(true)
                            setAddNewAddress(true)
                          }}>Add GST Details</h5>
                          <div className='col-6'>
                            <button style={{ width: '100%', height: '40px' }} className={style.edit_btn} onClick={() => {
                              setCurrenAddressData(addressdata.length - 1)
                              setCurrentAddressId(addressdata.length - 1)
                              // setGSTModal(true)
                            }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="row d-flex flex-column justify-content-left">
                        <h5>Most Recently used</h5>
                        <p className="mt-3">
                          {currentAddress ? currentAddress : "No added address"}.
                        </p>
                        <div className="d-flex flex-column w-100">
                          <button className={style.deliveryBtn} style={{ width: '100%' }} onClick={() => {
                            if (addressdata.length > 0) {
                              setDeliveryAddress(true)

                            } else {
                              setSnack(true)
                              setSnackMessage("Please add an address to continue")
                            }
                          }}>
                            Deliver to this address
                          </button>
                          <div className="d-flex flex-row w-100 mt-3">

                            <button style={{ width: '100%', height: '40px' }} className={style.edit_btn} onClick={() => {
                              setCurrenAddressData(addressdata.length - 1)
                              setCurrentAddressId(addressdata.length - 1)
                              setModal(true)
                            }}
                            >
                              Edit
                            </button>
                            <button style={{ width: '100%', height: '40px' }} className={style.delete_btn} onClick={() => handledeleteaddress(addressdata[addressdata.length - 1]._id)}>
                              Delete
                            </button>

                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div> */}
          </>
        ) : (
          display === 'shipment' && (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <>
                    {dimension[0] > 500 ? (
                      <h3>Shipment</h3>
                    ) : (
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <h3 className={style.myCart}>Shipment</h3>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <FontAwesomeIcon icon={faChevronLeft} />
                          <p
                            className={style.continueShop}
                            style={{ marginTop: '15px', marginLeft: '10px' }}
                            onClick={() => setDisplay('cart')}>
                            Continue Shopping
                          </p>
                        </div>
                      </div>
                    )}
                    {dimension[0] > 500
                      ? shipmentData.map((data, index) => {
                          return (
                            <div className={style.card}>
                              <div className="row">
                                <div className="col-2">
                                  <img className="img-fluid" src={getImage(data)} alt="" />
                                </div>
                                <div className="d-flex flex-column col-5 pl-2">
                                  <h5 style={{ fontSize: '18px' }}>
                                    Shipment {index + 1} of {cartDetailsProducts?.length}
                                  </h5>
                                  <span style={{ fontWeight: 500 }}>
                                    Shipping from {getVendor(data)}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      marginTop: '12px',
                                      fontWeight: '400'
                                    }}>
                                    Deliver to:{' '}
                                    {getAddressValues(
                                      deliverToAddress?.address ||
                                        addressdata[currentAddressId].address
                                    ) || ''}
                                  </span>
                                  <h5 className="mt-3">{getName(data)}</h5>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: '400'
                                    }}>
                                    Sold by: {getVendor(data)}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: '400'
                                    }}>
                                    ETA: Ships in 7-10 business days
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: '400'
                                    }}>
                                    Quantity: {data.quantity}
                                  </span>
                                  <h5 className="mt-3">
                                    <s style={{ fontWeight: 100 }}> Rs. {getMrp(data)}</s>
                                    &nbsp;&nbsp;Rs.{' '}
                                    {new Intl.NumberFormat('en-IN').format(getPixelPrice(data))}
                                  </h5>
                                </div>

                                <div
                                  className="offset-2 col-3 text-center"
                                  onClick={() => setDisplay('cart')}>
                                  <h5
                                    style={{
                                      float: 'right',
                                      textDecoration: 'underline'
                                    }}>
                                    Edit
                                  </h5>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : shipmentData.map((data, index) => {
                          return (
                            <div className={style.card}>
                              <div className="row">
                                <div className="col-3 m-0">
                                  <img className="img-fluid" src={getImage(data)} alt="" />
                                </div>
                                <div className="d-flex flex-column col-8 m-0 ml-0">
                                  <h5 style={{ fontSize: '16px', margin: '0px' }}>
                                    Shipment {index + 1} of {shipmentData?.length}
                                  </h5>
                                  <span className="mt-2" style={{ fontWeight: 500 }}>
                                    Shipping from {getVendor(data)}
                                  </span>
                                  <span className="mt-2" style={{ fontSize: '10px' }}>
                                    Deliver to:{' '}
                                    {getAddressValues(
                                      deliverToAddress?.address ||
                                        addressdata[currentAddressId].address
                                    ) || ''}
                                  </span>
                                  <h5 className="mt-4">{getName(data)}</h5>
                                  <h6 className="mt-2" style={{ fontWeight: 700 }}>
                                    <s style={{ fontWeight: 100 }}>Rs. {getMrp(data)}</s>
                                    &nbsp;&nbsp;Rs.{' '}
                                    {new Intl.NumberFormat('en-IN').format(getPixelPrice(data))}
                                  </h6>
                                  <span>Sold by: {getVendor(data)}</span>
                                  <span>ETA: Ships in 7-10 business days</span>
                                  <span>Quantity: {data.quantity}</span>
                                  <h5
                                    className="mt-3"
                                    style={{
                                      float: 'right',
                                      textDecoration: 'underline',
                                      fontWeight: 300
                                    }}>
                                    Edit
                                  </h5>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    <div className={classnames('', style.card)}>
                      <>
                        <div className="row">
                          <div className="col-6 d-flex flex-column align-items-start justify-content-start">
                            <h5>Shipping Address</h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            <p
                              style={{
                                cursor: 'pointer',
                                fontWeight: 600,
                                fontSize: '15px'
                              }}
                              className={style.editAddress}
                              onClick={() => {
                                setCurrenAddressData(latestAddressId, addressdata);
                                setCurrentAddressId(latestAddressId);
                                setEditModal(true);
                                setAddNewAddress(false);
                              }}>
                              <u> Edit</u>
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6 d-flex flex-column align-items-start justify-content-start">
                            <p style={{ fontSize: '15px', fontWeight: '500' }}>
                              {getAddressValues(addressdata[latestAddressId].address)}
                            </p>
                          </div>
                          {/* <div className="col-md-6 d-flex flex-column align-items-end justify-content-end">
                            <button
                              className={style.deliveryBtn}
                              onClick={() => {
                                if (addressdata.length > 0) {
                                  setDeliveryAddress(true);
                                  setDeliverToAddress(
                                    addressdata[latestAddressId]
                                  );
                                } else {
                                  setSnack(true);
                                  setSnackMessage(
                                    "Please add an address to continue"
                                  );
                                }
                              }}
                            >
                              Deliver to this address
                            </button>
                          </div> */}
                        </div>
                      </>
                    </div>
                    <div className={classnames('', style.card)}>
                      <>
                        <div className="row">
                          <div className="col-6 d-flex flex-column align-items-start justify-content-start">
                            <h5>Billing Address</h5>
                          </div>
                        </div>

                        <div className="row  m-1 mt-3">
                          <div className="col-md-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="address1"
                              checked={billingAddress === 'address1' || false}
                              onClick={() => {
                                setBillingAddress('address1');
                                setDeliveryAddress(false);
                                setNewBillingAddress(false);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                              style={{
                                marginLeft: '0px',
                                fontSize: '14px',
                                fontFamily: 'QuickSand'
                              }}>
                              Same as shipping adddress
                            </label>
                          </div>
                          <div className="col-md-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="address2"
                              checked={billingAddress === 'address2' || false}
                              onClick={() => {
                                setBillingAddress('address2');
                                setNewBillingAddress(true);
                                setDeliveryAddress(false);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                              style={{
                                marginLeft: '0px',
                                fontSize: '14px',
                                fontFamily: 'QuickSand'
                              }}>
                              Use different address
                            </label>
                          </div>
                        </div>

                        {newBillingAddress ? (
                          <>
                            <div className={classnames(style.card)}>
                              <div className="row justify-content-left m-0 mt-3">
                                <div className="col-6 p-0">
                                  <span>Address Line 1</span>
                                  <br></br>
                                  <input
                                    className={style.checkoutInput}
                                    name="line1"
                                    value={inputs.line1 || ''}
                                    required
                                    onChange={handleChangeDelivery}
                                    placeholder="Address Line 1"
                                  />
                                </div>
                                <div className="col-6">
                                  <span>Address Line 2</span>
                                  <br></br>
                                  <input
                                    className={style.checkoutInput}
                                    name="line2"
                                    value={inputs.line2 || ''}
                                    required
                                    onChange={handleChangeDelivery}
                                    placeholder="Address Line 2"
                                  />
                                </div>
                              </div>
                              <div className="row justify-content-left m-0 mt-3">
                                <div className="col-6 p-0">
                                  <span>Postalcode</span>
                                  <br></br>
                                  <input
                                    className={style.checkoutInput}
                                    name="postcode"
                                    type="number"
                                    value={inputs.postcode || ''}
                                    required
                                    onChange={handleChangeDelivery}
                                    placeholder="Postal Code"
                                  />
                                </div>
                                <div className="col-6">
                                  <span>City/ District</span>
                                  <br></br>
                                  <input
                                    className={style.checkoutInput}
                                    name="city"
                                    value={inputs.city || ''}
                                    required
                                    onChange={handleChangeDelivery}
                                    placeholder="city/district"
                                  />
                                </div>
                              </div>
                              <div className="row justify-content-left m-0 mt-3">
                                <div className="col-6 p-0">
                                  <span>State</span>
                                  <br></br>
                                  <select
                                    name="state"
                                    placeholder="Select State"
                                    className={style.checkoutInput}
                                    value={inputs.state || ''}
                                    onChange={handleChangeDelivery}>
                                    <option>Select state</option>
                                    {Object.keys(cities).map(state => (
                                      <option value={state}>{state}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-6">
                                  <span>Phone*</span>
                                  <br></br>
                                  <input
                                    className={style.checkoutInput}
                                    type="number"
                                    name="phone"
                                    value={inputs.phone || ''}
                                    required
                                    onChange={handleChangeDelivery}
                                    placeholder="Phone"
                                  />
                                  {error === 'phone' && (
                                    <span
                                      style={{
                                        color: 'red',
                                        fontSize: '11px'
                                      }}>
                                      Number must be 10 digits
                                    </span>
                                  )}
                                </div>
                              </div>

                              {!deliveryAddress && (
                                <div className="d-flex justify-content-center mt-5">
                                  <button
                                    className={style.deliveryBtn}
                                    style={{
                                      paddingLeft: '30px',
                                      paddingRight: '30px'
                                    }}
                                    onClick={() => {
                                      handleSubmitAddress();
                                      if (addressdata.length > 0) {
                                        setDeliveryAddress(true);
                                      }
                                    }}>
                                    Save Address
                                  </button>
                                </div>
                              )}
                            </div>
                          </>
                        ) : null}
                      </>
                    </div>
                    <div className={classnames('', style.card)}>
                      <div className="row m-1 mb-2">
                        Your Order Information will be sent to {auth.user && auth.user.email}
                      </div>
                    </div>
                  </>
                </div>
                <div className="col-lg-4 mt-md-5 mb-2">
                  <div className={classnames(style.summaryContainer)}>
                    <div className={style.orderSum}>
                      <h3>Order Summary</h3>
                    </div>
                    <div className={style.summarySubContainer}>
                      {ordersummary()}
                      <div className="row justify-content-center m-0">
                        <button onClick={() => setDisplay('payment')} className={style.checkoutBtn}>
                          Checkout
                        </button>
                      </div>
                      {/* Will implement soon */}
                      {addpromocode()}
                      {promotionAdded === true && offerType === 'coupon' ? (
                        <div className="row m-0 ">
                          <button className={style.couponBtn}>
                            <div className="row">
                              <div className="col-9  d-flex justify-content-center">
                                {offerSelectedValue && offerSelectedValue.coupon_code
                                  ? offerSelectedValue.coupon_code
                                  : 'offer'}{' '}
                                Applied
                              </div>
                              <div className="col-2 d-flex justify-content-end">
                                <img
                                  className="d-flex justify-contnetn-end"
                                  src={
                                    'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                  }
                                  style={{ width: '90%' }}
                                  onClick={() => {
                                    setPromotionAdded(false);
                                    setOfferSelectedValue({});
                                    setOfferType('');
                                    setPromotionalDiscount(0);
                                  }}
                                />
                              </div>
                            </div>
                          </button>
                        </div>
                      ) : (
                        <div className="row m-0 ">
                          <button
                            className={style.couponBtn}
                            onClick={() => {
                              let totalTemp = getSubTotal();
                              let availableOffers1 = availableOffers.filter(
                                avl => totalTemp >= avl.minimum_transaction_value
                              );
                              setAvailableOffers([...availableOffers1]);
                              setCouponDrawer(true);
                            }}>
                            Apply Coupon Code
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        )}
        {display === 'payment' && (
          <>
            <div className="row">
              <div className="col-lg-8">
                {dimension[0] > 500 ? (
                  <h3>Payment</h3>
                ) : (
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <h3 className={style.myCart}>Payment</h3>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <p
                        className={style.continueShop}
                        style={{ marginTop: '15px', marginLeft: '10px' }}
                        onClick={() => setDisplay('cart')}>
                        Continue Shopping
                      </p>
                    </div>
                  </div>
                )}

                <div className={style.card}>
                  <div className="row">
                    <div className="col-12 font-weight-medium">
                      <p>Select a mode of payment</p>
                    </div>
                    <div className="col-12 pl-2">
                      <div className="row ml-2">
                        {/* <div class="form-check" className="ml-1">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={paymentMode === "COD" || false}
                            onClick={() => setPaymentMode("COD")}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                            style={{ marginLeft: "0px" }}
                          >
                            Cash on Delivery
                          </label>
                        </div> */}
                        <div className="ml-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={paymentMode === 'ONLINE' || false}
                            onClick={() => setPaymentMode('ONLINE')}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                            style={{ marginLeft: '0px' }}>
                            Online
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style.card}>
                  <div className="font-weight-medium">
                    <h5>Your Pixel and Mortar credit</h5>
                  </div>
                  <div className="col-12 pl-5">
                    <div className="col-12 ml-2">
                      <div className="ml-1 form-check">
                        {creditsvalue > 0 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={addCredit ? true : false}
                            onClick={() => setAddCredit(!addCredit)}
                          />
                        )}
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault"
                          style={{ marginLeft: '0px' }}>
                          Use Your {creditsvalue} pixel and mortar credit
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {!(offerSelectedValue && offerSelectedValue.coupon_code) ? (
                  <div
                    className={classnames(" font-weight-medium", style.card)}
                  >
                    <div className="row">
                      <div className="col-lg-5 col-sm-12 pt-2 font-weight-medium">
                        <p>Enter promotional code</p>
                      </div>
                      <div className="col-lg-4 col-sm-4 pt-2 font-weight-medium">
                        <input type="text"></input>
                      </div>
                      <div className="col-lg-3 col-sm-4 pt-2 font-weight-medium">
                        <button className={style.applyButton}>Apply</button>
                      </div>
                    </div>
                  </div>
                ) : null} */}
              </div>
              <div className="col-lg-4 mt-5 mb-2">
                <div className={classnames(style.summaryContainer)}>
                  <div className={style.orderSum}>
                    <h3>Order Summary</h3>
                  </div>
                  <div className={style.summarySubContainer}>
                    {ordersummary()}
                    <div className="row justify-content-center m-0">
                      <Button
                        variant="dark"
                        disabled={error !== '' || loading}
                        className={style.checkoutBtn}
                        onClick={() => {
                          placeOrderNew();
                        }}>
                        {loading && (
                          <Spinner as="span" size="sm" role="status" animation="border" />
                        )}
                        {paymentMode === 'COD' ? 'Place Order' : 'Proceed payment'}
                      </Button>
                    </div>
                    {/* Will implement soon */}
                    {addpromocode()}
                    {promotionAdded === true && offerType === 'coupon' ? (
                      <div className="row m-0 ">
                        <button className={style.couponBtn}>
                          <div className="row">
                            <div className="col-9  d-flex justify-content-center">
                              {offerSelectedValue && offerSelectedValue.coupon_code
                                ? offerSelectedValue.coupon_code
                                : 'offer'}{' '}
                              Applied
                            </div>
                            <div className="col-2 d-flex justify-content-end">
                              <img
                                className="d-flex justify-contnetn-end"
                                src={
                                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                }
                                style={{ width: '90%' }}
                                onClick={() => {
                                  setPromotionAdded(false);
                                  setOfferSelectedValue({});
                                  setOfferType('');
                                  setPromotionalDiscount(0);
                                }}
                              />
                            </div>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div className="row m-0 ">
                        <button
                          className={style.couponBtn}
                          onClick={() => {
                            let totalTemp = getSubTotal();
                            availableOffers = availableOffers.filter(
                              avl => totalTemp >= avl.minimum_transaction_value
                            );
                            setAvailableOffers([...availableOffers]);
                            setCouponDrawer(true);
                          }}>
                          Apply Coupon Code
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal show={editModal} centered>
        <div className={style.modalContainer}>
          <h5>{addNewAddress === true ? 'Add New Address' : 'Edit Address'}</h5>
          <img
            style={{
              position: 'absolute',
              width: dimension[0] ? '3%' : '6%',
              right: dimension[0] > 500 ? '5%' : '10%',
              top: dimension[0] < 500 ? '-3%' : '0%',
              cursor: 'pointer'
            }}
            onClick={() => setEditModal(false)}
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
            alt=""
          />

          <div
            className={classnames(
              style.modal_input_container,
              'row d-flex align-items-center justify-content-center'
            )}>
            <div className="col-md-6">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
                style={{ fontWeight: 500 }}>
                First Name*
              </label>
              <br></br>
              <input
                type="text"
                className={style.modal_input}
                value={inputs.firstname || (auth && auth.user && auth.user.name)}
                onChange={handleChangeDelivery}
                name="firstname"></input>
              <p className="text-red" style={{ fontSize: 10 }}>
                {addressError.firstname}
              </p>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
                style={{ fontWeight: 500 }}>
                Last Name*
              </label>
              <br></br>
              <input
                type="text"
                className={style.modal_input}
                value={inputs.lastname}
                onChange={handleChangeDelivery}
                name="lastname"
              />
              <p className="text-red" style={{ fontSize: 10 }}>
                {addressError.lastname}
              </p>
            </div>
          </div>
          <div
            className={classnames(
              style.modal_input_container,
              'row d-flex align-items-center justify-content-center'
            )}>
            <div className="col-md-6">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
                style={{ fontWeight: 500 }}>
                Address Line 1
              </label>
              <br></br>
              <input
                type="text"
                className={style.modal_input}
                value={inputs.line1}
                onChange={handleChangeDelivery}
                name="line1"
              />
              <p className="text-red" style={{ fontSize: 10 }}>
                {addressError.line1}
              </p>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
                style={{ fontWeight: 500 }}>
                Address Line 2
              </label>
              <br></br>
              <input
                type="text"
                className={style.modal_input}
                value={inputs.line2}
                onChange={handleChangeDelivery}
                name="line2"
              />
              <p className="text-red" style={{ fontSize: 10 }}>
                {addressError.line2}
              </p>
            </div>
          </div>
          <div
            className={classnames(
              style.modal_input_container,
              'row d-flex align-items-center justify-content-center'
            )}>
            <div className="col-md-6">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
                style={{ fontWeight: 500 }}>
                Postcode
              </label>
              <br></br>
              <input
                type="number"
                className={style.modal_input}
                value={inputs.postcode}
                onChange={handleChangeDelivery}
                name="postcode"
              />
              <p className="text-red" style={{ fontSize: 10 }}>
                {addressError.postcode}
              </p>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
                style={{ fontWeight: 500 }}>
                City/District
              </label>
              <br></br>
              <input
                type="text"
                className={style.modal_input}
                value={inputs.city}
                onChange={handleChangeDelivery}
                name="city"
              />
              <p className="text-red" style={{ fontSize: 10 }}>
                {addressError.city}
              </p>
            </div>
          </div>
          <div
            className={classnames(
              style.modal_input_container,
              'row d-flex align-items-center justify-content-center'
            )}>
            <div className="col-md-6">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
                style={{ fontWeight: 500 }}>
                State
              </label>
              <br></br>
              {/* <input type="text" className={style.modal_input} value={inputs.state} onChange={handleChangeDelivery} name="state" /> */}
              <select
                name="state"
                placeholder="Select State"
                className={style.modal_input}
                value={inputs.state}
                onChange={handleChangeDelivery}>
                <option>Select state</option>
                {Object.keys(cities).map((state, index) => (
                  <option key={`city${index}`} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <p className="text-red" style={{ fontSize: 10 }}>
                {addressError.state}
              </p>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
                style={{ fontWeight: 500 }}>
                Phone*
              </label>
              <br></br>
              <input
                type="number"
                className={style.modal_input}
                value={inputs.phone}
                onChange={handleChangeDelivery}
                name="phone"
              />
              {/* {error === 'phone' && <span style={{ color: 'red', fontSize: '11px' }}>Number must be 10 digits</span>} */}
              <p className="text-red" style={{ fontSize: 10 }}>
                {addressError.phone}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button
              className={style.paymentBtn}
              style={{
                paddingLeft: '30px',
                paddingRight: '30px',
                marginBottom: '25px'
              }}
              onClick={() => {
                setModal(false);
                if (addNewAddress === true) {
                  handleSubmitAddress();
                } else {
                  handleUpdateAddress();
                  // setEditModal(false);
                  // setModal(false);
                }
              }}>
              {addNewAddress === true ? 'Add  Address' : 'Edit Address'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={modal}
        className={`${style.modalss} sm-mt-5`}
        centered
        style={{ overflow: 'initial' }}>
        {dimension[0] > 500 ? (
          <div className={style.modalContainer}>
            <h5 className={style.modalheads}>
              {addNewAddress === true ? 'Add New Address' : 'Edit Address'}
            </h5>
            <img
              style={{
                position: 'absolute',
                width: dimension[0] > 500 ? '3%' : '6%',
                right: dimension[0] > 500 ? '5%' : '10%',
                top: dimension[0] < 500 ? '3%' : '0%',
                cursor: 'pointer'
              }}
              onClick={() => setModal(false)}
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
              alt=""
            />
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  First Name*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.firstname}
                  onChange={handleChangeDelivery}
                  name="firstname"></input>
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.firstname}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Last Name*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.lastname}
                  onChange={handleChangeDelivery}
                  name="lastname"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.lastname ? addressError.lastname : ''}
                </p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Address Line 1*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.line1}
                  onChange={handleChangeDelivery}
                  name="line1"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.line1}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Address Line 2*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.line2}
                  onChange={handleChangeDelivery}
                  name="line2"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.line2}
                </p>
              </div>
            </div>
            {/* <div
              className={classnames(
                style.modal_input_container,
                "row d-flex align-items-center justify-content-center"
              )}
            >
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  for="exampleFormControlInput1"
                  class="form-label"
                  style={{ fontWeight: 500 }}
                >
                  Address Line 1
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.line1}
                  onChange={handleChangeDelivery}
                  name="line1"
                />
                <p className="text-red" style={{ fontSize: 10 }}>
                  {addressError.line1}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  for="exampleFormControlInput1"
                  class="form-label"
                  style={{ fontWeight: 500 }}
                >
                  Address Line 2
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.line2}
                  onChange={handleChangeDelivery}
                  name="line2"
                />
              </div>
            </div> */}
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Postcode*
                </label>
                <br></br>
                <input
                  type="number"
                  className={style.modal_input}
                  value={inputs.postcode}
                  onChange={handleChangeDelivery}
                  name="postcode"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.postcode}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  City/District*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.city}
                  onChange={handleChangeDelivery}
                  name="city"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.city}
                </p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  State*
                </label>
                <br></br>
                {/* <input type="text" className={style.modal_input} value={inputs.state} onChange={handleChangeDelivery} name="state" /> */}
                <select
                  name="state"
                  placeholder="Select State"
                  className={style.modal_input}
                  value={inputs.state}
                  onChange={handleChangeDelivery}>
                  <option>Select state</option>
                  {Object.keys(cities).map((state, index) => (
                    <option key={`city${index}`} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.state}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Phone*
                </label>
                <br></br>
                <input
                  type="number"
                  className={style.modal_input}
                  value={inputs.phone}
                  onChange={handleChangeDelivery}
                  name="phone"
                />
                {/* {error === 'phone' && <span style={{ color: 'red', fontSize: '11px' }}>Number must be 10 digits</span>} */}
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.phone}
                </p>
              </div>
            </div>
            {addNewAddress ? (
              <>
                <h5 className="row mt-1 ml-4.5" style={{ marginLeft: '27px', fontSize: '17px' }}>
                  Address Time
                </h5>
                <div className="pl-1 pr-3">
                  <div className="row  m-0 mt-3 ml-3">
                    <div className=" col-5 ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="home"
                        id="home-radio"
                        checked={addressType === 'home' || false}
                        onClick={e => {
                          setAddressType('home');
                          handleChangeDelivery(e);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="home-radio"
                        style={{
                          marginLeft: '0px',
                          fontSize: '15px',
                          fontFamily: 'QuickSand'
                        }}>
                        Home (All day Delivery)
                      </label>
                    </div>
                    <div className=" col-7 ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="work"
                        id="work-radio"
                        checked={addressType === 'work' || false}
                        onClick={e => {
                          setAddressType('work');
                          handleChangeDelivery(e);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="work-radio"
                        style={{
                          marginLeft: '0px',
                          fontSize: '15px',
                          fontFamily: 'QuickSand'
                        }}>
                        Work (Between 10 AM - 5 PM)
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {addNewAddress === true ? (
              <div className="d-flex justify-content-center mt-4  mb-4">
                <button
                  className={style.paymentBtn}
                  style={{ paddingLeft: '30px', paddingRight: '30px' }}
                  onClick={() => {
                    // setModal(false);
                    handleSubmitAddress();
                  }}>
                  Save and deliver here
                </button>
              </div>
            ) : (
              <>
                <div className="row d-flex justify-content-center mt-5 mb-5">
                  <div className="col-6">
                    <button
                      className={style.paymentBtn}
                      style={{ paddingLeft: '30px', paddingRight: '30px' }}
                      onClick={() => {
                        handleUpdateAddress();
                      }}>
                      Edit Address
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className={style.paymentBtn}
                      style={{ paddingLeft: '30px', paddingRight: '30px' }}
                      onClick={() => {
                        setLatestAddressId(addressdata.length - 2);
                        handleDeleteAddress(addressdata[currentAddressId]._id);
                      }}>
                      Delete Address
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={classnames(style.modalContainer, 'm-1')}>
            <div className="row mt-2 m-1 mb-2">
              <div className="col-8 d-flex justify-content-start align-items-center">
                <h5 className={style.addNew}>
                  {addNewAddress === true ? 'Add New Address' : 'Edit Address'}
                </h5>
              </div>
              <div className="col-4 d-flex justify-content-end align-items-center">
                <img
                  style={{ cursor: 'pointer', width: '25%' }}
                  onClick={() => setModal(false)}
                  src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
                  alt=""
                />
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="text"
                  placeholder="First Name"
                  className={style.modal_input}
                  value={inputs.firstname}
                  onChange={handleChangeDelivery}
                  name="firstname"></input>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="text"
                  placeholder="Last Name "
                  className={style.modal_input}
                  value={inputs.lastname}
                  onChange={handleChangeDelivery}
                  name="lastname"
                />
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="text"
                  placeholder="Address Line 1"
                  className={style.modal_input}
                  value={inputs.line1}
                  onChange={handleChangeDelivery}
                  name="line1"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="text"
                  placeholder="Address Line 2"
                  className={style.modal_input}
                  value={inputs.line2}
                  onChange={handleChangeDelivery}
                  name="line2"
                />
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="number"
                  placeholder="Postcode"
                  className={style.modal_input}
                  value={inputs.postcode}
                  onChange={handleChangeDelivery}
                  name="postcode"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="text"
                  placeholder="City/District"
                  className={style.modal_input}
                  value={inputs.city}
                  onChange={handleChangeDelivery}
                  name="city"
                />
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <select
                  name="state"
                  placeholder="Select State"
                  className={style.modal_input}
                  value={inputs.state}
                  onChange={handleChangeDelivery}>
                  <option>Select state</option>
                  {Object.keys(cities).map(state => (
                    <option value={state}>{state}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="number"
                  placeholder="Phone"
                  className={style.modal_input}
                  value={inputs.phone}
                  onChange={handleChangeDelivery}
                  name="phone"
                />
                {error === 'phone' && (
                  <span style={{ color: 'red', fontSize: '11px' }}>Number must be 10 digits</span>
                )}
              </div>
            </div>
            {addNewAddress ? (
              <>
                <h5 className=" row ml-3 mt-3">Address Time</h5>
                <div className="row ml-3 pl-3  m-0 mt-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="home"
                    id="flexRadioDefault1"
                    checked={addressType === 'home' || false}
                    onClick={e => {
                      setAddressType('home');
                      handleChangeDelivery(e);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                    style={{
                      marginLeft: '0px',
                      fontSize: '14px',
                      fontFamily: 'QuickSand'
                    }}>
                    Home (All day Delivery)
                  </label>
                  {/* </div> */}
                </div>
                <div className="row ml-3 pl-3  m-0 mt-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="work"
                    id="flexRadioDefault1"
                    checked={addressType === 'work' || false}
                    onClick={e => {
                      setAddressType('work');
                      handleChangeDelivery(e);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                    style={{
                      marginLeft: '0px',
                      fontSize: '14px',
                      fontFamily: 'QuickSand'
                    }}>
                    Work(delivery between 10AM - 5 PM)
                  </label>
                </div>
              </>
            ) : null}
            {addNewAddress === true ? (
              <div className="d-flex justify-content-center mt-5  mb-5">
                <button
                  className={style.paymentBtn}
                  style={{ paddingLeft: '30px', paddingRight: '30px' }}
                  onClick={() => {
                    //  setModal(false);
                    handleSubmitAddress();
                  }}>
                  Save and deliver here
                </button>
              </div>
            ) : (
              <>
                <div className="row d-flex justify-content-center mt-5 mb-5">
                  <button
                    className={style.paymentBtn}
                    style={{ paddingLeft: '30px', paddingRight: '30px' }}
                    onClick={() => {
                      handleUpdateAddress();
                    }}>
                    Edit Address
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
      <Modal
        style={{
          backgroundColor: 'transparent',
          width: dimension[0] > 500 ? '400px' : '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          right: 0
        }}
        show={gstModal}
        onHide={() => setGstModal(false)}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalContainer} style={{ padding: '0px 31px' }}>
          <div>
            <h5 className={style.gstTxt}>Your GST Information</h5>
            <img
              style={{
                position: 'absolute',
                width: dimension[0] > 500 ? '3%' : '6%',
                right: dimension[0] > 500 ? '5%' : '10%',
                top: dimension[0] < 500 ? '-10%' : '0%',
                cursor: 'pointer'
              }}
              onClick={() => setGstModal(false)}
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
              alt=""
            />

            <div className={style.inputGst}>
              <input
                type="text"
                className={gstError ? style.modal_input1 : style.modal_input}
                value={gstinValue}
                placeholder="GSTIN"
                onChange={e => {
                  handleChangeGstin(e.target.value);
                }}
                name="gstin"></input>
              {error === 'gstin' && <span style={{ color: 'red', fontSize: '11px' }}>Invalid</span>}
            </div>

            <div className={style.inputGst}>
              <input
                type="text"
                className={style.modal_input}
                value={gstBusiness}
                placeholder="Business Name"
                onChange={e => {
                  setGstBusiness(e.target.value);
                }}
                name="business_name"
              />
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <Icon.ExclamationCircleFill />
              <label
                htmlFor="exampleFormControlInput1"
                className="pl-2"
                style={{
                  fontSize: dimension[0] > 500 ? '10px' : '7px',
                  marginTop: '8px',
                  fontWeight: '600'
                }}>
                Incorrect GSTIN details will lead to order cancellation
              </label>
              <br></br>
            </div>
            <div className="d-flex justify-content-center mt-3 mb-2">
              <button
                className={style.paymentBtn}
                style={{
                  width: '92%',
                  marginBottom: '17px',
                  padding: ' 12px 0px',
                  borderRadius: '0px'
                }}
                onClick={() => {
                  setModal(false);

                  handleSubmitGSTIN();
                }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Drawer
        anchor={'right'}
        open={couponDrawer}
        classes={{
          paper: dimension[0] > 500 ? classes1.paper : classes2.paper
        }}>
        <div className={style.coupenDra}>
          <div className="row d-flex col-12 justify-content-end">
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'}
              onClick={() => {
                setCouponDrawer(false);
              }}
            />
          </div>
          <div className="mt-5 ml-3">
            <b>Coupons</b>
          </div>
          {couponsLoading ? (
            <div>
              <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
            </div>
          ) : (
            <>
              {availableOffers.length > 0 &&
                availableOffers.map((offer, i) => {
                  return (
                    <div className="row ml-3 mt-3 d-flex justify-content-start">
                      <Card
                        style={{
                          width: '80%',
                          backgroundColor: 'white'
                          // borderRadius: '30px',
                          // marginTop: '20%'
                        }}>
                        <CardContent>
                          <div className="row mt-3  ml-3 d-flex justify-content-start">
                            <span>Available</span>
                          </div>
                          <div className="row mt-3  ml-3 d-flex justify-content-start">
                            <span className={style.couponTitle}>{offer.coupon_code}</span>
                          </div>
                          <div className="row mt-2 ml-3 d-flex justify-content-start">
                            <span className={style.couponDesc}>{offer.notes}</span>
                          </div>
                          <div className="row mt-3 ml-3 d-flex justify-content-start align-items-start">
                            <button
                              className={style.applyBtn}
                              onClick={() => {
                                if (cartDetailsProducts && cartDetailsProducts.length > 0) {
                                  handleCoupons(offer);
                                }
                              }}>
                              Apply
                            </button>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              {availableOffers.length <= 0 && (
                <div className="mt-4">
                  <h4>No offers availabe right now</h4>
                </div>
              )}
              {/* {notActivatedOffers.length > 0 && notActivatedOffers.map((offer, i) => {
                return (
                  <div className='row ml-3 mt-3 d-flex justify-content-start'>
                    <Card
                      style={{
                        width: '80%',
                        backgroundColor: "white",
                        // borderRadius: '30px',
                        // marginTop: '20%'
                      }}
                    >
                      <CardContent>
                        <div className='row mt-3  ml-3 d-flex justify-content-start'>
                          <span>Not Available</span>
                        </div>
                        <div className='row mt-3  ml-3 d-flex justify-content-start'>
                          <span className={style.couponTitle}>{offer.coupon_code}</span>
                        </div>
                        <div className='row mt-2 ml-3 d-flex justify-content-start'>
                          <span className={style.couponDesc}>{offer.notes}</span>
                        </div>
                        <div className="row mt-3 ml-3 d-flex justify-content-start align-items-start">
                          <button className={style.disableBtn} disabled={true} >Apply</button>
                        </div>

                      </CardContent>
                    </Card>
                  </div>)
              })}
              {expiredOffers.length > 0 && expiredOffers.map((offer, i) => {
                return (
                  <div className='row ml-3 mt-3 d-flex justify-content-start'>
                    <Card
                      style={{
                        width: '80%',
                        backgroundColor: "white",
                      }}
                    >
                      <CardContent>
                        <div className='row mt-3  ml-3 d-flex justify-content-start'>
                          <span>Expired</span>
                        </div>
                        <div className='row mt-3  ml-3 d-flex justify-content-start'>
                          <span className={style.couponTitle}>{offer.coupon_code}</span>
                        </div>
                        <div className='row mt-2 ml-3 d-flex justify-content-start'>
                          <span className={style.couponDesc}>{offer.notes}</span>
                        </div>
                        <div className="row mt-3 ml-3 d-flex justify-content-start align-items-start">
                          <button className={style.disableBtn} disabled={true}>Apply</button>
                        </div>

                      </CardContent>
                    </Card>
                  </div>)
              })} */}
            </>
          )}
        </div>
      </Drawer>
      {/* <Modal
        show={animationModal.modalOpen} centered
      >

        <div className={classnames('row justify-content-center mr-3 ml-3')}>
          <div>
            {!animationData && <div>Loading...</div>}
            <Lottie animationData={animationData} play
              style={{ width: 400, height: 400 }} />;
          </div>
        </div>
      </Modal> */}

      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, { storePackage, storeCartCount })(withRouter(Cart));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {}, [screenSize]);

  return screenSize;
}
