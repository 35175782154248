import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import style from './Homepage.module.css';
import classnames from 'classnames';
import { storeMxAns, storeMx } from '../../store/actions/sampleaction';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import FilterBadge from '../../components/badge/FilterBadge';
import Slider from '../../components/slider/Slider';
import PnMButton from '../../components/button/PnMButton';
import Backdrop from '@mui/material/Backdrop';
import illustration from '../../assets/homepage/illustration.png';
import illustrationMob from '../../assets/homepage/illustrationMob.png';
import RoomCard from '../../components/cards/RoomCard';
import ProductCard from '../../components/cards/ProductCard';
import Carousel from '../../components/carousel/Carousel';
import { rooms } from '../Shop/constant';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import axios from 'axios';
import customerApiInstance from '../../utils/CustomerApiInstance';
import getCartDataCount from '../../utils/CartDetails';
import { storeCartCount } from '../../store/actions/sampleaction';
import Snackbar from '@material-ui/core/Snackbar';
import QRScanner from '../../components/layout/QrScanner';
import CuratedDesignsMasonry from '../../components/Masonry/CuratedDesignsMasonry';
import Loader from 'react-loader-spinner';
import CustomStackedCarousel from '../../components/carousel/CustomStackedCarousel';

const roomsFilter = [
  { id: 1, value: 'living_room', type: 'Living Room' },
  { id: 2, value: 'dining_room', type: 'Dining Room' },
  { id: 3, value: 'bed_room', type: 'Bed Room' },
  { id: 4, value: 'kids_room', type: 'Kids Room' },
  { id: 5, value: 'puja_room', type: 'Puja Room' },
  { id: 6, value: 'foyer', type: 'Foyer' },
  { id: 7, value: 'balcony', type: 'Balcony' },
  {
    id: 8,
    value: 'study_entertainment_room',
    type: 'Study Entertainment Room'
  }
];

export const HomePage = ({ auth, history, storeCartCount }) => {
  const dimension = useMediaQuery();
  const [value, setValue] = useState([0, 450000]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const containerRef = useRef(null);
  const containerMobRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [newArrivalProducts, setNewArrivalProducts] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();
  const [shopNowDisabled, setShopNowDisabled] = useState(false);
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moodBoards, setMoodBoards] = useState([]);
  const [loadingCarousel, setLoadingCarousel] = useState(false);
  const [carouselMoodBoards, setCarouselMoodBoards] = useState([]);
  const [selectedMoodboardRoom, setSelectedMoodboardRoom] = useState(roomsFilter[0]);

  useEffect(() => {
    getMasonryMoodboardsList();
  }, []);

  const getMasonryMoodboardsList = async () => {
    setLoading(true);
    try {
      let result = [];
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/explore?tags[]=Designer Pick&Page=1`
      );
      result = res.data.results;
      setMoodBoards(result);
      setLoading(false);
    } catch (err) {
      //ConsoleHelper('error', err);
      setLoading(false);
      setMoodBoards([]);
    }
  };

  useEffect(() => {
    const getStackedCarauselMoodboardsList = async () => {
      setLoadingCarousel(true);
      try {
        let result = [];
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/explore?tags[]=Get Inspired&Page=1&rooms[]=${selectedMoodboardRoom.type}`
        );
        result = res?.data?.results?.map((elem) => {
          elem.cover = elem.preview_url;
          return elem;
        });

        if (result.length >= 5) {
          setCarouselMoodBoards(result.slice(0, 5));
        } else {
          if (result.length % 2 === 1) {
            setCarouselMoodBoards(result);
          } else {
            if (result.length > 0) {
              result.push({
                cover:
                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/64476ed6c1d903001329b3bd.png',
                title: 'Rustic living',
                _id: '0'
              });
            }
            setCarouselMoodBoards(result);
          }
        }
        //console.log(result);
        setLoadingCarousel(false);
      } catch (err) {
        setLoadingCarousel(false);
        setCarouselMoodBoards([]);
      }
    };
    getStackedCarauselMoodboardsList();
  }, [selectedMoodboardRoom]);

  useEffect(() => {
    const getNewArrivalProducts = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
          params: { page: 1, tags: 'New Arrival' }
        });
        if (res?.status == 200) {
          setNewArrivalProducts(res?.data?.results);
        } else {
          setNewArrivalProducts([]);
        }
      } catch (err) {
        console.log(err);
        setNewArrivalProducts([]);
      }
    };

    getNewArrivalProducts();
  }, []);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendor/list`);
        if (res.data.length !== 0) {
          setVendorsList([...res.data]);
        }
      } catch (err) {
        // ConsoleHelper('errorrrr', err);
      }
    };
    fetchVendorData();
  }, []);

  useEffect(() => {
    const fetchFavouriteData = async () => {
      try {
        if (auth && auth.user) {
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
          );
          let savedIds = [];

          if (res.data.favourites.length !== 0) {
            res.data.favourites.map((dat) => {
              savedIds = dat.products.map((m) => {
                return m.product_id;
              });
            });
            setSavedProductIds([...savedIds]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFavouriteData();
  }, []);

  const handleTransition = (expanded) => {
    if (expanded) {
      setContainerHeight(containerRef.current.scrollHeight);
    } else {
      setContainerHeight(0);
    }
  };

  const handleTransitionMob = (expanded) => {
    if (expanded) {
      setContainerHeight(containerMobRef.current.scrollHeight);
    } else {
      setContainerHeight(0);
    }
  };

  const addToCart = async (productData) => {
    let userId;
    let prodBody;

    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: 1,
              rate: productData.price || productData.pixel_price,
              total_price: 1 * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const buyNow = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: 1,
              rate: productData.price || productData.pixel_price,
              total_price: 1 * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push('/cart');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
        history.push('/cart');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const addToFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: 1,
            rate: productData.price || productData.pixel_price,
            total_price: 1 * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = (product) => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds((prev) => [...prev, product._id]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter((filter) => filter !== product._id));
      removeFromFavourites(product);
    }
  };

  const handleQr = (productIds) => {
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = (productIds) => {
    history.push(`/3d-model/${productIds}`);
  };

  function openinnewtab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);
  return (
    <>
      <Header />
      <Backdrop
        sx={{ color: '#fff', zIndex: 4 }}
        open={selectedRoom ? true : false}
        onClick={() => {
          setSelectedRoom(null);
          handleTransition(false);
        }}></Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {modalOpen && <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />}
      <div className={style.homeContainer}>
        <div className={style.banner}>
          <video
            src={'https://d3izcfql7lesks.cloudfront.net/02+AR+Section.mp4'}
            width="100%"
            autoPlay
            muted
            loop
            style={{ objectFit: 'fill' }}
            className={style.bannerVideo}
          />
          <div className={style.bannerOverlay}>
            <div className={style.bannerHeading}>
              Reimagining your <br />
              Space with AR
            </div>
            <div className={style.bannerSubHeading}>
              Use our AR feature to visualise our products in your space
            </div>
          </div>
          {/* Budget filter section for web starts */}
          <div
            className={classnames('row w-100 justify-content-center m-auto', style.filterRowWeb)}>
            <div
              className={classnames('col-xl-6 col-lg-8 col-md-10 col-sm-12', style.filterCol)}
              style={{
                bottom: `${-(60 + containerHeight)}px`,
                zIndex: selectedRoom ? 5 : 0
              }}>
              <div className={style.filterInner}>
                <div className={style.filterInnerHeading}>What's your dream space?</div>
                <div className={style.badgeContainer}>
                  {roomsFilter.map((room, index) => (
                    <FilterBadge
                      key={index}
                      name={room.type}
                      active={selectedRoom?.type == room.type}
                      hover={true}
                      onClick={() => {
                        setSelectedRoom(selectedRoom?.type == room.type ? null : room);
                        handleTransition(selectedRoom?.type == room.type ? false : true);
                      }}
                    />
                  ))}
                </div>
                <div
                  className={style.budgetContainer}
                  ref={containerRef}
                  style={{ height: `${containerHeight}px` }}>
                  <div
                    style={{
                      fontWeight: '700',
                      fontSize: '16px',
                      color: '#171717',
                      marginBottom: '1rem'
                    }}>
                    Set your budget
                  </div>

                  <div className="row w-100 m-auto">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                      <Slider
                        value={value}
                        handleChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        maxval={450000}
                      />
                      <div className="d-flex">
                        <div className={style.sliderBox}>
                          <p className={style.sliderBoxInner}>Min.</p>
                          <div className="d-flex">
                            <span className={style.sliderBoxInnerValue}>Rs.</span>
                            <input
                              value={value[0]}
                              className={style.sliderInput}
                              type="number"
                              onChange={(e) => {
                                if (
                                  parseInt(e.target.value) >= 0 &&
                                  parseInt(e.target.value) <= 450000
                                ) {
                                  setValue([parseInt(e.target.value), value[1]]);
                                  setShopNowDisabled(false);
                                } else {
                                  setValue([e.target.value, value[1]]);
                                  setShopNowDisabled(true);
                                  setSnack(true);
                                  setSnackMessage('Please enter value between 0 - 4.5 lakh');
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className={style.sliderBox}>
                          <p className={style.sliderBoxInner}>Max.</p>
                          <div className="d-flex">
                            <span className={style.sliderBoxInnerValue}>Rs.</span>
                            <input
                              value={value[1]}
                              type="number"
                              className={style.sliderInput}
                              onChange={(e) => {
                                if (
                                  parseInt(e.target.value) >= 0 &&
                                  parseInt(e.target.value) <= 450000
                                ) {
                                  setValue([value[0], parseInt(e.target.value)]);
                                  setShopNowDisabled(false);
                                } else {
                                  setValue([value[0], e.target.value]);
                                  setShopNowDisabled(true);
                                  setSnack(true);
                                  setSnackMessage('Please enter value between 0 - 4.5 lakh');
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classnames(
                        'col-xl-6 col-lg-6 col-md-12 col-sm-12  d-flex p-0',
                        style.budgetBtnContainer
                      )}>
                      <PnMButton
                        type={'filled'}
                        name={'Explore Spaces'}
                        disabled={shopNowDisabled}
                        onClick={() =>
                          // history.push(
                            history.push(
                              `/curated-designs?room=${selectedRoom.type}&minPrice=${value[0]}&maxPrice=${value[1]}`
                            )
                          // )
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={style.filterText}
                    style={{ display: containerHeight > 0 ? 'flex' : 'none' }}>
                    <strong>30K+</strong> design ideas to inspire for your space
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Budget filter section for web ends */}
          {/* Budget filter section for Mob starts */}
          <div
            className={classnames('row w-100 justify-content-center m-auto', style.filterRowMob)}>
            <div
              className={classnames('col-12', style.filterCol)}
              style={{
                zIndex: selectedRoom ? 5 : 0
              }}>
              <div className={style.filterInner}>
                <div className={style.filterInnerHeading}>What's your dream space?</div>
                <div className={style.badgeContainer}>
                  {roomsFilter.map((room, index) => (
                    <FilterBadge
                      key={index}
                      name={room.type}
                      active={selectedRoom?.type == room.type}
                      hover={true}
                      onClick={() => {
                        setSelectedRoom(selectedRoom?.type == room.type ? null : room);
                        handleTransitionMob(selectedRoom?.type == room.type ? false : true);
                      }}
                    />
                  ))}
                </div>
                <div
                  className={style.budgetContainer}
                  ref={containerMobRef}
                  style={{ height: `${containerHeight}px` }}>
                  <div
                    style={{
                      fontWeight: '700',
                      fontSize: '16px',
                      color: '#171717',
                      marginBottom: '1rem'
                    }}>
                    Set your budget
                  </div>

                  <div className="row w-100 m-auto">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                      <Slider
                        value={value}
                        handleChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        maxval={450000}
                      />
                      <div className="d-flex">
                        <div className={style.sliderBox}>
                          <p className={style.sliderBoxInner}>Min.</p>
                          <div className="d-flex">
                            <span className={style.sliderBoxInnerValue}>Rs.</span>
                            <input
                              value={value[0]}
                              className={style.sliderInput}
                              type="number"
                              onChange={(e) => {
                                if (
                                  parseInt(e.target.value) >= 0 &&
                                  parseInt(e.target.value) <= 450000
                                ) {
                                  setValue([parseInt(e.target.value), value[1]]);
                                  setShopNowDisabled(false);
                                } else {
                                  setValue([e.target.value, value[1]]);
                                  setShopNowDisabled(true);
                                  setSnack(true);
                                  setSnackMessage('Please enter value between 0 - 4.5 lakh');
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className={style.sliderBox}>
                          <p className={style.sliderBoxInner}>Max.</p>
                          <div className="d-flex">
                            <span className={style.sliderBoxInnerValue}>Rs.</span>
                            <input
                              value={value[1]}
                              type="number"
                              className={style.sliderInput}
                              onChange={(e) => {
                                if (
                                  parseInt(e.target.value) >= 0 &&
                                  parseInt(e.target.value) <= 450000
                                ) {
                                  setValue([value[0], parseInt(e.target.value)]);
                                  setShopNowDisabled(false);
                                } else {
                                  setValue([value[0], e.target.value]);
                                  setShopNowDisabled(true);
                                  setSnack(true);
                                  setSnackMessage('Please enter value between 0 - 4.5 lakh');
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classnames(
                        'col-xl-6 col-lg-6 col-md-12 col-sm-12  d-flex p-0',
                        style.budgetBtnContainer
                      )}>
                      <PnMButton
                        type={'filled'}
                        name={'Explore Spaces'}
                        disabled={shopNowDisabled}
                        onClick={() =>
                          history.push(
                            // history.push(
                              `/curated-designs?room=${selectedRoom.type}&minPrice=${value[0]}&maxPrice=${value[1]}`
                            // )
                          )
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={style.filterText}
                    style={{ display: containerHeight > 0 ? 'flex' : 'none' }}>
                    <strong>30K+</strong> design ideas to inspire for your space
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Budget filter section for Mob ends */}
        </div>
        <div className={style.homeContainer2}>
          <div className={style.moodboardCont}>
            <p className={style.moodboardContTitle}>Curated Designs</p>
            <p className={style.moodboardContSubTitle}>
              A collection of curated designs for the home.
            </p>
            {moodBoards.length == 0 &&
              (loading ? (
                <div className="d-flex justify-content-center mt-4 w-100">
                  <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-4 w-100">
                  No Moodboards available
                </div>
              ))}
            {/*Curated design for web and tab */}
            {moodBoards.length > 0 && !loading && dimension[0] > 600 && (
              <CuratedDesignsMasonry
                moodboardsArr={moodBoards}
                columns={3}
                gap={'25px'}
                autoScroll={true}
                homePage={true}
              />
            )}
            {/*Curated design for mobile */}
            {moodBoards.length > 0 && !loading && dimension[0] < 600 && (
              <CuratedDesignsMasonry
                moodboardsArr={moodBoards}
                columns={2}
                gap={'20px'}
                autoScroll={true}
                homePage={true}
              />
            )}
          </div>
          {/*Hire designer banner for web screen starts*/}
          <div className={classnames('row w-100 m-auto', style.hireDesignerBanner)}>
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end h-100"
              style={{ position: 'relative' }}>
              <img
                src={illustration}
                style={{ position: 'absolute', bottom: '0', right: '40px' }}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
              <p style={{ fontWeight: '700', fontSize: '28px', lineHeight: '1' }} className="mb-1">
                {' '}
                Work with our designers
              </p>
              <p style={{ fontWeight: '500', fontSize: '18px' }} className="mb-2">
                {' '}
                to create your space.
              </p>
              <PnMButton
                type={'filled'}
                name={'Hire a Designer'}
                onClick={() => history.push('/hire-designer')}
              />
            </div>
          </div>
          {/*Hire designer banner for web screen ends*/}
          {/*Hire designer banner for mob screen starts*/}
          <div
            className={style.hireDesignerBannerMob}
            style={{ backgroundImage: `url(${illustrationMob})` }}>
            <p
              style={{ fontWeight: '700', fontSize: '28px', lineHeight: '1' }}
              className="mb-2 mt-4">
              {' '}
              Work with our designers
            </p>
            <p style={{ fontWeight: '500', fontSize: '18px' }} className="mb-4">
              {' '}
              to create your space.
            </p>
            <PnMButton
              type={'filled'}
              name={'Hire a Designer'}
              onClick={() => history.push('/hire-designer')}
            />
          </div>
          {/*Hire designer banner for mob screen ends*/}
          <div className={style.categoryContainer}>
            <p className="text-center" style={{ fontSize: '28px', fontWeight: '700' }}>
              Shop by categories
            </p>
            <ResponsiveMasonry
              className={style.mesonryRooms}
              style={{ margin: '0px auto', width: '100%' }}
              columnsCountBreakPoints={{
                200: 1,
                300: 2,
                500: 3,
                750: 4,
                900: 5,
                1200: 6,
                1400: 7,
                1600: 8
              }}>
              <Masonry gutter={'1rem'}>
                {rooms.map((room, index) => (
                  <div
                    className="d-flex justify-content-center w-100 "
                    style={{ minWidth: '10px' }}
                    key={index}
                    //ref={index === 0 ? masonryRef : null}
                  >
                    <RoomCard
                      bg={'#ffffff'}
                      title={room.name}
                      height={'150px'}
                      width={'150px'}
                      image={room.image}
                      hoverImage={room.hoverImage}
                      hover={true}
                      onClick={() => history.push(`/shop-details/${room.title}/false`)}
                    />
                  </div>
                ))}
                <div
                    className="d-flex justify-content-center w-100 "
                    style={{ minWidth: '10px' }}
                  >
                  <RoomCard
                    bg={'#ffffff'}
                    title={'View All'}
                    height={'150px'}
                    width={'150px'}
                    image={'https://d3izcfql7lesks.cloudfront.net/room_icons/grey+icons/Frame+1171281391g.png'}
                    hoverImage={'https://d3izcfql7lesks.cloudfront.net/room_icons/white+icons/Frame+1171281391.png'}
                    hover={true}
                    onClick={() => history.push(`/categories`)}
                    />
                    </div>
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
        <div className={style.homeContainer3}>
          <div className={style.newArrivalContainer}>
            <p className="text-center" style={{ fontSize: '28px', fontWeight: '700' }}>
              New Arrivals
            </p>
            <div style={{ width: '100%' }}>
              {newArrivalProducts.length > 0 && (
                <Carousel>
                  {newArrivalProducts.map((data, index) => (
                    <ProductCard
                      key={index}
                      data={data}
                      heart={auth && auth.user}
                      heartIsActive={savedProductIds?.includes(data._id)}
                      arIcon={data && data['3d_models']?.glbFileUrl}
                      tag={'NEW LAUNCH'}
                      vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                      productImage={data.image[0]}
                      productName={data.name}
                      price={data.price}
                      pnmPrice={data.pixel_price}
                      onClickCart={() => addToCart(data)}
                      onClickBuyNow={() => buyNow(data)}
                      onClickHeart={() => handleFavourites(data)}
                      onClicAr={() =>
                        dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                      }
                      viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                      sx={{ margin: 'auto' }}
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
        <div className={style.homeContainer4}>
          <div className={style.stackedCarouselContainer}>
            <div className={style.stackedCarouselContainerInner}>
              <div className={style.stackedCarouselContTittle}>Trending Designs</div>
              <div className={style.stackedCarouselBadgeContainer}>
                {roomsFilter.map((room, index) => (
                  <FilterBadge
                    key={index}
                    name={room.type}
                    active={selectedMoodboardRoom?.type == room.type}
                    onClick={() => {
                      setSelectedMoodboardRoom(
                        selectedMoodboardRoom?.type == room.type ? null : room
                      );
                    }}
                    sxActive={{
                      backgroundColor: '#ffffff',
                      color: '#171717',
                      border: '1px solid #E3E3E3',
                      boxShadow: '0px 3px 8px 0px #00000014'
                    }}
                  />
                ))}
              </div>
            </div>
            <div className={style.stackedCarouselContainerInnerMost}>
              {carouselMoodBoards.length == 0 &&
                (loadingCarousel ? (
                  <div className="d-flex justify-content-center mt-4 w-100">
                    <Loader
                      type="ThreeDots"
                      visible={true}
                      color="#000000"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-4 w-100">
                    No Moodboards available
                  </div>
                ))}
              {carouselMoodBoards.length > 0 && !loadingCarousel && (
                <CustomStackedCarousel data={carouselMoodBoards} />
              )}
            </div>
          </div>
        </div>
        <div className={style.homeContainer5}>
          <div className={style.partnersContainer}>
            <div className={style.partnerLeft}>
              <div className={style.partnerHeader}>Big.Small.Medium sized</div>
              <div className={style.partnerSubHeader}>
                Our brands cater to all budgets,aesthetics and style needs.
              </div>
              <button
                className={style.shop_button}
                type="button"
                onClick={() =>
                  history.push({
                    pathname: `/shop`
                  })
                }>
                Shop Now
              </button>
            </div>
            <div className={style.partnerRight}>
              {vendorsList && vendorsList.length > 0 && (
                <div className={style.vendorAutoScroll}>
                  {vendorsList.map((vendor, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-center"
                      style={{ height: '150px', width: '100%' }}>
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                        src={vendor.bit_img}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
  questionnaireData: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  questionnaireData: state.questionnaireData
});

export default connect(mapStateToProps, {
  storeMxAns,
  storeMx,
  storeCartCount
})(withRouter(HomePage));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
