// import { baseUrl } from '../../config/config';
// import axios from 'axios';
import { GET_CUSTOMER_ACCOUNT_DATA, UPDATE_CUSTOMER_ACCOUNT_DATA } from '../constants';
import customerApiInstance from '../../utils/CustomerApiInstance';
import ConsoleHelper from '../../utils/ConsoleHelper';

export const getCustomerAccount = values => dispatch => {
  customerApiInstance
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/user/${values}`)
    .then(res => {
      dispatch({
        type: GET_CUSTOMER_ACCOUNT_DATA,
        payload: res.data
      });
    })
    .catch(err => {
      ConsoleHelper({ err });
    });
};
export const updateCustomerAccount = values => dispatch => {
  dispatch({
    type: UPDATE_CUSTOMER_ACCOUNT_DATA,
    payload: values
  });
};
