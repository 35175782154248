import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './DesignDairies.module.css';
import Lottie from 'react-lottie';
import errorAnimation from '../../components/lottie/error.json';

const DesignDairies = () => {
  const [lottie, setLottie] = useState(true);
  const history = useHistory();

  // useEffect(() => {
  //   setLottie(true);
  //   setTimeout(() => {
  //     setLottie(false);
  //   }, 5000);
  // }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      history.push('/home'); 
    }, 5000);
    return () => clearTimeout(timeoutId); 
  }, [history]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const dimension = useMediaQuery();

  return (
    <>
      {/* {lottie ? (
        <div style={{ marginTop: '70px' }}>
          <Lottie options={defaultOptions} height="70vh" width={500} />
        </div>
      ) : (
        <div> */}
          <Header />
          <div style={{ height: '100vh', backgroundColor: '#f5f0ec' }}>
            <div className={style.container_design}>
              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    marginTop: dimension[0] > 500 && 'auto',
                    marginBottom: dimension[0] > 500 && 'auto'
                  }}>
                  <div className={style.designTxt}>
                    <h1 style={{ fontFamily: 'QuickSand' }}> Sorry! </h1>
                    <p>We can’t seem to find the Page you’re looking for, taking you back to Home</p>
                  </div>
                </div>
                <div className="col-md-6">
                  {dimension[0] < 500 ? (
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern1.png'
                      }
                      className={style.patternIm}
                    />
                  ) : (
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern2.png'
                      }
                      className={style.patternChair}
                    />
                  )}
                  <img
                    src={
                      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/Group1.png'
                    }
                    className={style.mainImg}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        {/* </div>
      )} */}
    </>
  );
};

export default DesignDairies;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    // ConsoleHelper(screenSize);
  }, [screenSize]);

  return screenSize;
}
