import ConsoleHelper from '../../utils/ConsoleHelper';
const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
  initialUser: null,
  selectedCamera: '',
  errors: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  ConsoleHelper(action);
  switch (type) {
    case 'USER_LOADED':
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        initialUser: payload
      };

    case 'LOADING':
      return {
        ...state,
        loading: true
      };
    case 'REGISTER_SUCCESS':
      localStorage.setItem('token', `Bearer ${payload.token}`);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.user
      };
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', `Bearer ${payload.token}`);
      return {
        ...state,
        ...payload,
        user: payload.user,
        isAuthenticated: true,
        loading: false
      };
    case 'ALERT_CONFIG':
      let obj = { ...state.user };
      obj.alertConfig = payload;
      return {
        ...state,
        user: obj
      };
    case 'REGISTER_FAIL':
    case 'AUTH_ERROR':
      return {
        ...state,
        errors: payload
      };
    case 'LOGIN_FAIL':
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('cartProducts');
      localStorage.clear();
      localStorage.removeItem('persist:root');
      return {
        state: [],
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        initialUser: null,
        selectedCamera: ''
      };
    default:
      return state;
  }
}
