import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import { Image } from 'semantic-ui-react';
import arrow from '../../assets/images/Iconionic-ios.png';
import Paper from '@material-ui/core/Paper';
import SliderContainer from 'react-slick';
import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import style from './ExploreTags.module.css';
import classnames from 'classnames';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: '50px'
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '6px',
        height: '10px',
        marginLeft: '11px'
      }
    },
    paper: {
      width: '90%',
      overflow: 'hidden',
      margin: '0px auto'
    },
    imagefit: {
      width: '200px',
      height: '200px',
      objectFit: 'contain',
      cursor: 'pointer'
    }
  })
);
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowPrev} alt="prevArrow" {...props} />
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowNext} alt="nextArrow" {...props} />
);

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false
      }
    },
    {
      breakpoint: 500,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        centerMode: true
      }
    }
  ]
};

function SliderComponent({ sliderData, tittle, type, allTags }) {
  const classes = useStyles();
  const history = useHistory();
  function getChildTag(alltagArr, tagId, type) {
    let child = alltagArr.filter(tag => {
      return tag.parent_id == tagId && tag.type.indexOf(type) > -1;
    });
    return child;
  }
  return (
    <div className={classes.root}>
      <h5
        style={{
          fontFamily: 'Quicksand',
          textTransform: 'uppercase',
          fontWeight: 600,
          fontSize: '18px',
          marginBottom: '20px',
          padding: '0px 15px'
        }}>
        {' '}
        EXPLORE  {tittle != '' && tittle.toUpperCase()}
      </h5>
      {!sliderData.length > 0 ? (
        <div className="text-center">
          <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
        </div>
      ) : (
        <div className={classes.flex}>
          <SliderContainer {...settings}>
            {sliderData.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (type == 'tags') {
                      if (getChildTag(allTags, data._id, 'explore').length > 0) {
                        history.push(`/explore-tag/${tittle + '&' + data.name}/${data._id}`);
                      } else {
                        history.push(`/explore-tag/${tittle + '&' + data.name}`);
                      }
                    } else if (type == 'other') {
                      history.push(`/explore/${tittle.toLowerCase()}/${data.name}`);
                    }
                  }}>
                  <Paper className={classes.paper}>
                    <Image
                      draggable={false}
                      className={classes.imagefit}
                      src={data?.image ? data.image : ''}
                    />
                  </Paper>
                  <p
                    style={{ fontFamily: 'Quicksand', cursor: 'pointer' }}
                    className={classnames(style.displayrow, 'tagname')}>
                    {data.name} <img src={arrow} className={classes.imgtag} />
                  </p>
                </div>
              );
            })}
          </SliderContainer>
        </div>
      )}
    </div>
  );
}

export default SliderComponent;
