import React, { useEffect, useState, useRef, useLayoutEffect, createRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import style from './RoomProfile.module.css';
import classnames from 'classnames';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RoomProfileXl from './RoomProfileXl';
import Modal from '@material-ui/core/Modal';
import ReactPlayer from 'react-player';
import AddIcon from '@mui/icons-material/Add';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import ReactAudioPlayer from 'react-audio-player';
import * as Icon from 'react-bootstrap-icons';
import Snackbar from '@material-ui/core/Snackbar';
import Dropzone from 'react-dropzone';
import { Annotation } from 'react-mark-image';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { storeMxB } from '../../store/actions/sampleaction';
import Box from '@material-ui/core/Box';
import Arrow from '../../assets/arrow.png';
import SideBarPattern from '../../assets/sidebarPattern.png';
import welcomeAnimation from '../../components/lottie/Designer.json';
import Lottie from 'react-lottie';
import { furniture_images, FURNITURE_ROOM_OBJECT } from './Furniture1';
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Popupstyle from '../../pages/Shop/Shopdetails.module.css';
import ConsoleHelper from '../../utils/ConsoleHelper';

const useStyles = makeStyles(theme => ({
  topbar: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: 10,
    backgroundColor: 'white'
  },
  justify: {
    display: 'inline-flex',
    justifyContent: 'left'
  },
  button: {
    borderRadius: 30,
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 30,
    border: 0,
    paddingRight: '10px',
    backgroundColor: 'black',
    color: 'white'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: theme.shadows[5]
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: 'none',
    width: 800,
    height: 500,
    padding: theme.spacing(2, 4, 3)
  },
  papers: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    width: '70%',
    height: 500,
    position: 'relative',
    outline: 'none',
    opacity: 1,
    visibility: 'show'
  },
  displayrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  roombox: {
    fontSize: '15px',
    marginTop: '16px',
    fontWeight: 600,
    marginBottom: '51px'
  }
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      style={{ display: 'none' }}
      className={classnames('', style.slider__info)}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

const PrettoSlider = withStyles({
  root: {
    color: '#EDEDED',
    height: 4,
    padding: '16px 0 6px 0',
    alignSelf: 'end'
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#000',
    border: '2px solid currentColor',
    marginLeft: -12,
    '&:focus, &:hover': {
      boxShadow: '0 0 0 8px rgba(0, 0, 0, 0.16)'
    }
  },
  active: {
    backgroundColor: '#000'
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
    color: 'black'
  },
  track: {
    height: 10,
    borderRadius: 4,
    backgroundColor: '#EDEDED',
    color: '#EDEDED'
  },
  rail: {
    height: 10,
    borderRadius: 4,
    backgroundColor: '#EDEDED',
    color: '#EDEDED'
  }
})(Slider);

const RoomProfile = ({ auth, history, storeMxB, questionnaireData }) => {
  const tabs = [
    'About your space',
    'Your inspiration',
    'Your Style',
    'Your Budget',
    'Your Contact'
  ];
  const { id } = useParams();
  const [uploadError, setUploadError] = useState('');
  const [projectIndex, setProjectIndex] = useState(
    questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData.findIndex(mx => mx.projectId === id) >= 0
      ? questionnaireData.MxBData.findIndex(mx => mx.projectId === id)
      : ''
  );
  const [annotations, setAnnotations] = useState([]);
  const [activeTab, setActiveTab] = useState('About your space');
  const reference = useRef(null);
  const dropzoneRef = createRef();
  const wishlistRef = useRef(null);
  const budgetRef = useRef(null);
  const fileInputRef = useRef();
  const [modal, setModal] = useState(1);
  const [audioModal, setAudioModal] = useState(false);
  const [furnitureRooms, setFurnitureRooms] = useState([]);
  const [spaceImages, setSpaceImages] = useState(
    questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_SPACE_IMAGES')
      ? questionnaireData.MxBData[projectIndex].MX_B_SPACE_IMAGES
      : []
  );
  const [inspirationImages, setInspirationImages] = useState(
    questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_INSPIRATION_IMAGES')
      ? questionnaireData.MxBData[projectIndex].MX_B_INSPIRATION_IMAGES
      : []
  );
  const [furnitureImages, setFurnitureImages] = useState(
    questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_DECOR_IMAGES')
      ? questionnaireData.MxBData[projectIndex].MX_B_DECOR_IMAGES
      : []
  );

  const [currentSpaceImage, setCurrentSpaceImage] = useState('');
  const [spaceComment, setSpaceComment] = useState([]);
  const [startAudio, setStartAudio] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [currentField, setCurrentField] = useState('');
  let [audioUrl] = useState([]);
  //const [edit, setEdit] = useState(false);
  const dimension = useMediaQuery();
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [validPageCount, setValidPageCount] = useState(0);
  const [spaceError, setSpaceError] = useState(false);
  const [personsError, setPersonsError] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [visionError, setVisionError] = useState(false);
  const [itemError, setItemError] = useState(false);
  const [artsError, setArtsError] = useState(false);
  const [materialsError, setMaterialsError] = useState(false);
  const [palletsError, setPalletsError] = useState(false);
  const [patternsError, setPatternsError] = useState(false);
  const [dimesnionsError, setDimensionsError] = useState(false);
  const [budgetError, setBudgetError] = useState(false);
  // const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [projectQuestionnaire, setProjectQuestionnaire] = useState({});
  const [lottie, setLottie] = useState(false);
  // const [imageDimensions, setImageDimensions] = useState({});
  const [dimensionFile, setDimensionFile] = useState(
    questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_DIMENSIONS') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].MX_B_DIMENSIONS.hasOwnProperty('file')
      ? questionnaireData.MxBData[projectIndex].MX_B_DIMENSIONS.file
      : {}
  );
  const [submitted, setSubmitted] = useState(
    questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('submitted')
      ? questionnaireData.MxBData[projectIndex].submitted
      : false
  );
  // const [annotateState, setAnnotateState] = useState({
  //   annotations: [],
  //   annotation: {},
  // });
  // const [audioState, setAudioState] = useState({
  //   audioDetails: {
  //     url: null,
  //     blob: null,
  //     chunks: null,
  //     duration: {
  //       h: null,
  //       m: null,
  //       s: null,
  //     },
  //   },
  // });

  const [blobURL, setblobURL] = useState('');
  const [recordstate, setrecordstate] = useState('NONE');
  const [selecteddeleteid, setselecteddeleteid] = useState(null);
  const [selecteddeletestatus, setselecteddeletestatus] = useState(false);

  useEffect(() => {
    const itemsFirstWishlist = [
      {
        id: 1,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2960_ugsgcf.png',
        title: 'Sofa'
      },
      {
        id: 2,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2961_bssrpx.png',
        title: 'Sectional'
      },
      {
        id: 3,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2301_swmeip.png',
        title: 'Chairs'
      },
      {
        id: 4,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2959_clhzkh.png',
        title: 'Coffee Table'
      },
      {
        id: 5,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2962_k8zugc.png',
        title: 'Accent Seating'
      },
      {
        id: 6,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2965_qtexuu.png',
        title: 'Media Console'
      },
      {
        id: 7,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2966_v7grn1.png',
        title: 'Window Treatments'
      },
      {
        id: 8,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2290_urjvpz.png',
        title: 'Desk'
      },
      {
        id: 9,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2286_vix2s8.png',
        title: 'Desk Chair'
      },
      {
        id: 10,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2294_unwgyi.png',
        title: 'Cushions'
      },
      {
        id: 11,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2967_kxpyab.png',
        title: 'Accessories'
      },
      {
        id: 12,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2968_ewjl0v.png',
        title: 'Rug'
      },
      {
        id: 13,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2969_xo3knr.png',
        title: 'Floor Lamp'
      },
      {
        id: 14,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2302_ysynef.png',
        title: 'Overhead Lighting'
      },
      {
        id: 15,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2303_q2m4e9.png',
        title: 'Table Lamps'
      },
      {
        id: 16,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2972_j1xqjl.png',
        title: 'Sconces'
      },
      {
        id: 17,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2971_axdtec.png',
        title: 'Book Case'
      },
      {
        id: 18,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2970_flz1wl.png',
        title: 'Small Storage'
      }
    ];
    setFurnitureRooms(itemsFirstWishlist);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: welcomeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const fetchData = async () => {
    if (id) {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );
      setProjectQuestionnaire(res.data.questionnaire);
      let room = res.data.questionnaire.MX_A_INITAL_ROOM;
      let furnitureData = [];
      if (Object.keys(FURNITURE_ROOM_OBJECT).includes(room)) {
        furnitureData = FURNITURE_ROOM_OBJECT[room];
      } else {
        furnitureData = FURNITURE_ROOM_OBJECT['Others'];
      }
      setFurnitureRooms(furniture_images.filter(d => furnitureData.includes(d.title)));
      let projectData = res.data.questionnaire;
      if (projectData.hasOwnProperty('MX_B_SPACE_IMAGES')) {
        setSpaceImages([...projectData.MX_B_SPACE_IMAGES]);
      }
      if (projectData.hasOwnProperty('MX_B_DECOR_IMAGES')) {
        setFurnitureImages([...projectData.MX_B_DECOR_IMAGES]);
      }
      if (projectData.hasOwnProperty('MX_B_PLAN')) {
        inputs.plan = projectData.MX_B_PLAN;
      }
      if (projectData.hasOwnProperty('MX_B_LIVE')) {
        inputs.live = projectData.MX_B_LIVE;
      }
      if (projectData.hasOwnProperty('NO_OF_PERSONS')) {
        inputs.no_of_person = projectData.NO_OF_PERSONS;
      }
      if (projectData.hasOwnProperty('MX_B_INSPIRATIONS')) {
        setInspirationImages([...projectData.MX_B_INSPIRATIONS]);
      }
      if (projectData.hasOwnProperty('MX_B_COLLABORATOR')) {
        inputs.collaborator = projectData.MX_B_COLLABORATOR;
      }
      if (projectData.hasOwnProperty('MX_B_PROJECT')) {
        inputs.project_vision = projectData.MX_B_PROJECT;
      }
      if (projectData.hasOwnProperty('MX_B_ROOMS')) {
        inputs.mx_b_rooms = projectData.MX_B_ROOMS;
      }
      if (projectData.hasOwnProperty('MX_B_ARTWORK')) {
        inputs.mx_b_artworks = projectData.MX_B_ARTWORK;
      }
      if (projectData.hasOwnProperty('MX_B_MATERIALS')) {
        inputs.mx_b_materials = projectData.MX_B_MATERIALS;
      }
      if (projectData.hasOwnProperty('MX_B_PALLETES')) {
        inputs.mx_b_palettes = projectData.MX_B_PALLETES;
      }
      if (projectData.hasOwnProperty('MX_B_PATTERNS')) {
        inputs.mx_b_patterns = projectData.MX_B_PATTERNS;
      }
      if (projectData.hasOwnProperty('MX_B_DESIGN')) {
        inputs.design = projectData.MX_B_DESIGN;
      }
      if (projectData.hasOwnProperty('MX_B_DIMENSIONS')) {
        inputs.dimensions = projectData.MX_B_DIMENSIONS;
      }
      if (projectData.hasOwnProperty('MX_B_OCCUPANTS')) {
        inputs.occupants = projectData.MX_B_OCCUPANTS;
      }
      if (projectData.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
        inputs.tentative_budget = projectData.MX_B_TENTATIVE_BUDGET;
      }
      if (projectData.hasOwnProperty('MX_B_PHONE_NUMBER')) {
        inputs.phone_number = projectData.MX_B_PHONE_NUMBER;
      }
      if (projectData.hasOwnProperty('MX_B_COUNTRY_CODE')) {
        inputs.country_code = projectData.MX_B_COUNTRY_CODE;
      }
      if (projectData.hasOwnProperty('MX_B_BUDGET_DETAILS')) {
        inputs.budget = projectData.MX_B_BUDGET_DETAILS;
      }
    }
  };
  useEffect(() => {
    if (questionnaireData.hasOwnProperty('MxBData')) {
      let data = [...questionnaireData.MxBData];

      let index = data.findIndex(mx => mx.projectId === id);
      if (index >= 0) {
        setProjectIndex(index);
      }
    }
  }, []);
  useEffect(() => {
    id && fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (submitted === true) {
      if (spaceImages.length < 4) {
        setSpaceError(true);
      }
      if (Object.keys(inputs.plan).length < 1) {
        setPlanError(true);
      }
      if (inputs.no_of_person === '') {
        setPersonsError(true);
      }

      if (inputs.mx_b_artworks.length < 3) {
        setArtsError(true);
      }
      if (inputs.mx_b_palettes.length < 3) {
        setPalletsError(true);
      }
      if (inputs.mx_b_patterns.length < 3) {
        setPatternsError(true);
      }
      if (inputs.mx_b_materials.length < 3) {
        setMaterialsError(true);
      }
      if (Object.keys(inputs.dimensions).length < 3) {
        setDimensionsError(true);
      }
      if (inputs.tentative_budget === '') {
        setBudgetError(true);
      }
    }
  }, [activeTab, submitted]);

  // useEffect(() => {
  //   if (activeTab === "About your space") {
  //     scrollToRef(reference);
  //   } else if (activeTab === "Interior Inspiration") {
  //     scrollToRef(refInspiration);
  //   } else if (activeTab === "Wishlist") {
  //     scrollToRef(wishlistRef);
  //   } else if (activeTab === "Budget") {
  //     scrollToRef(budgetRef);
  //   }
  //   ConsoleHelper(activeTab);
  // else if (Object.keys(inputs.project_vision).length < 0) {
  //   setActiveTab('Interior Inspiration')
  // } else if (Object.keys(inputs.mx_b_artworks).length < 0 || Object.keys(inputs.mx_b_palettes).length < 0 || Object.keys(inputs.mx_b_patterns).length < 0) {
  //   setActiveTab('Wishlist')
  // } else if (Object.keys(inputs.tentative_budget).length < 0) {
  //   setActiveTab('Budget')
  // }
  // }, [activeTab]);

  let [inputs, setInputs] = useState({
    occupants:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('occupants')
        ? questionnaireData.MxBData[projectIndex].occupants
        : [],
    mx_b_rooms:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_rooms')
        ? questionnaireData.MxBData[projectIndex].mx_b_rooms
        : [],
    tentative_budget:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('tentative_budget')
        ? questionnaireData.MxBData[projectIndex].tentative_budget
        : 0,
    phone_number:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('phone_number')
        ? questionnaireData.MxBData[projectIndex].phone_number
        : 0,
    country_code:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('country_code')
        ? questionnaireData.MxBData[projectIndex].country_code
        : 0,
    mx_b_artworks:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_artworks')
        ? questionnaireData.MxBData[projectIndex].mx_b_artworks
        : [],
    mx_b_materials:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_materials')
        ? questionnaireData.MxBData[projectIndex].mx_b_materials
        : [],
    mx_b_palettes:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_palettes')
        ? questionnaireData.MxBData[projectIndex].mx_b_palettes
        : [],
    mx_b_patterns:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_patterns')
        ? questionnaireData.MxBData[projectIndex].mx_b_patterns
        : [],
    no_of_person:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('no_of_person')
        ? questionnaireData.MxBData[projectIndex].no_of_person
        : 0,
    plan:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('plan')
        ? questionnaireData.MxBData[projectIndex].plan
        : {},
    live:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('live')
        ? questionnaireData.MxBData[projectIndex].live
        : {},
    // project_vision:
    //   questionnaireData.hasOwnProperty("MxBData") &&
    //   questionnaireData.MxBData[projectIndex] &&
    //   questionnaireData.MxBData[projectIndex].hasOwnProperty("project_vision")
    //     ? questionnaireData.MxBData[projectIndex].project_vision
    //     : {},
    design:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('design')
        ? questionnaireData.MxBData[projectIndex].design
        : {},
    budget:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('budget')
        ? questionnaireData.MxBData[projectIndex].budget
        : {},
    dimensions:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_DIMENSIONS')
        ? questionnaireData.MxBData[projectIndex].MX_B_DIMENSIONS
        : {},
    collaborator:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_COLLABORATOR')
        ? questionnaireData.MxBData[projectIndex].MX_B_COLLABORATOR
        : ''
  });

  const incrementCount = () => {
    setInputs(params => ({
      ...params,
      no_of_person: params?.no_of_person + 1
    }));
  };

  const decrementCount = () => {
    if (inputs?.no_of_person > 1) {
      setInputs(params => ({
        ...params,
        no_of_person: params?.no_of_person - 1
      }));
    }
  };

  const arts = [
    {
      id: 1,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/abst%402x.png',
      title: 'Abstract'
    },
    {
      id: 2,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/digi+Illust%402x.png',
      title: 'Digital Illustrations'
    },
    {
      id: 3,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Ethnic%402x.png',
      title: 'Ethnic'
    },
    {
      id: 4,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/floral%402x.png',
      title: 'Floral'
    },
    {
      id: 5,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/masculine%402x.png',
      title: 'Masculine'
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Modern%402x.png',
      title: 'Modern'
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/neo+art+deco%402x.png',
      title: 'Neo art deco'
    },
    {
      id: 7,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Retro%402x.png',
      title: 'Retro'
    },
    {
      id: 8,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Scandinavian%402x.png',
      title: 'Scandinavian'
    },
    {
      id: 9,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Vintage%402x.png',
      title: ' Vintage'
    }
  ];

  const materials = [
    {
      id: 1,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Bamboo%402x.png',
      title: 'Bamboo'
    },
    {
      id: 2,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Black+metal%402x.png',
      title: 'Black Metal'
    },
    {
      id: 3,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Brass%402x.png',
      title: 'Brass'
    },
    {
      id: 4,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Cement%402x.png',
      title: 'Cement'
    },
    {
      id: 5,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/fabric%402x.png',
      title: 'Fabric'
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Glass%402x.png',
      title: 'Glass'
    },
    {
      id: 7,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/gold%402x.png',
      title: 'Gold'
    },
    {
      id: 8,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/INLAY%402x.png',
      title: 'Inlay'
    },
    {
      id: 9,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/jute%402x.png',
      title: 'Jute'
    },
    {
      id: 10,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/leather%402x.png',
      title: 'Leather'
    },
    {
      id: 11,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/light+wood%402x.png',
      title: 'Light Wood'
    },
    {
      id: 12,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/marble%402x.png',
      title: 'Marble'
    },
    {
      id: 13,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Rattan%402x.png',
      title: 'Rattan'
    },
    {
      id: 14,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/silver+metal%402x.png',
      title: 'Silver Metal'
    },
    {
      id: 15,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/wood%402x.png',
      title: 'Wood'
    }
  ];

  const palettes = [
    {
      id: 1,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(2).png',
      title: 'Warm tones',
      colors: ['Yellow', 'brown', 'beige', 'white']
    },
    {
      id: 2,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(4).png',
      title: 'Pastel Palette ',
      colors: ['Pink', 'Purple', 'Green', 'yellow', 'blue', 'white']
    },
    {
      id: 3,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(3).png',
      title: 'Organic Palette',
      colors: ['Brown', 'Beige', 'white']
    },
    {
      id: 4,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(1).png',
      title: 'Light Neutrals',
      colors: ['brown', 'grey']
    },
    {
      id: 5,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x.png',
      title: 'Jewel tones',
      colors: ['Green', 'purple', 'yellow', 'maroon', 'green', 'blue']
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(7).png',
      title: 'Feminine',
      colors: ['pink', 'magenta', 'purple']
    },
    {
      id: 7,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(5).png',
      title: 'Bold Primary',
      colors: ['red', 'blue', 'green', 'yellow']
    },
    {
      id: 8,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(6).png',
      title: 'Accent Palette',
      colors: ['white', 'beige', 'green', 'blue', 'green', 'red', 'yellow']
    }
  ];

  const patterns = [
    {
      id: 1,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Morrocan.jpg',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/01.jpg',
      title: 'Moroccan'
    },
    {
      id: 2,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Abstract%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/09.jpg',
      title: 'Abstract'
    },
    {
      id: 3,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Animal+Prints%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/08.jpg',
      title: 'Animal Prints'
    },
    {
      id: 4,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Argyle%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/07.jpg',
      title: 'Argyle'
    },
    {
      id: 5,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Chevron%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/06.jpg',
      title: 'Chevron'
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Floral%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/05.jpg',
      title: 'Floral'
    },
    {
      id: 7,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Geometric%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/04.jpg',
      title: 'Geometric'
    },
    {
      id: 8,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Indian%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/03.jpg',
      title: 'Indian'
    },
    {
      id: 9,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Intricate%402x.jpg',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/11.jpg',
      title: 'Intricate'
    }
  ];

  const handleInputs = e => {
    if (
      e.target.name === 'plan' ||
      e.target.name === 'live' ||
      e.target.name === 'design' ||
      e.target.name === 'budget'
    ) {
      if (inputs[e.target.name]) {
        setInputs({
          ...inputs,
          [e.target.name]: {
            ...inputs[e.target.name],
            text: e.target.value
          }
        });
        storeMxB(
          reduxValue([e.target.name], {
            ...inputs[e.target.name],
            text: e.target.value
          })
        );
      } else {
        setInputs({
          ...inputs,
          [e.target.name]: {
            text: e.target.value
          }
        });
        storeMxB(
          reduxValue([e.target.name], {
            text: e.target.value
          })
        );
      }
    } else if (e.target.name === 'no_of_person') {
      if (e.target.value <= 99 && e.target.value !== 'e') {
        setInputs({
          ...inputs,
          [e.target.name]: Math.abs(e.target.value)
        });
        storeMxB(reduxValue([e.target.name], Math.abs(e.target.value)));
      }
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      });
    }
  };

  // if (index < 0) {
  //   setInputs((prev) => ({
  //     ...prev,
  //     [name]: [...prev[name], { title: value.title, img: value.image }],
  //   }));
  //   storeMxB(
  //     reduxValue(name, [
  //       ...inputs[name],
  //       { title: value.title, img: value.image },
  //     ])
  //   );
  // }
  // else {

  //   if (name === "mx_b_materials" || name === "mx_b_artworks") {
  //  if(starRating === 0){
  //   ConsoleHelper("wrrrrkkkkkk correct not data save unselected")
  //     setInputs({
  //       ...inputs,
  //       [name]: inputs[name].filter((filter) => filter.title !== value.title),
  //     });
  //     storeMxB(
  //       reduxValue(
  //         name,
  //         inputs[name].filter((filter) => filter.title !== value.title)
  //       )
  //     );
  //       }
  //       else{
  //         ConsoleHelper("wrrrrkkkkkk correct data save")
  //         setInputs((prev) => ({
  //           ...prev,
  //           [name]: [
  //             ...prev[name],
  //             { title: value.title, customer_rating: rating1, img: value.image },
  //           ],
  //         }));

  //         storeMxB(
  //           reduxValue(name, [
  //             ...inputs[name],
  //             { title: value.title, customer_rating: rating1, img: value.image },
  //           ])
  //         );
  //         }

  //       } else if (name === "mx_b_palettes") {

  //         setInputs((prev) => ({
  //           ...prev,
  //           [name]: [
  //             ...prev[name],
  //             {
  //               title: value.title,
  //               colors: value.colors,
  //               customer_rating: rating1,
  //               img: value.image,
  //             },
  //           ],
  //         }));
  //         storeMxB(
  //           reduxValue(name, [
  //             ...inputs[name],
  //             {
  //               title: value.title,
  //               colors: value.colors,
  //               customer_rating: rating1,
  //               img: value.image,
  //             },
  //           ])
  //         );
  //       }
  //     // setRating(false);
  // }

  const setCustomerRating = (value, name, rating1) => {
    let index = inputs[name].findIndex(f => f.title === value.title);

    if (index >= 0) {
      inputs[name][index]['customer_rating'] = rating1;

      setInputs({
        ...inputs,
        [name]: inputs[name].filter(filter => filter.title !== value.title)
      });
      storeMxB(
        reduxValue(
          name,
          inputs[name].filter(filter => filter.title !== value.title)
        )
      );
    } else {
      if (name === 'mx_b_materials' || name === 'mx_b_artworks') {
        setInputs(prev => ({
          ...prev,
          [name]: [
            ...prev[name],
            { title: value.title, customer_rating: rating1, img: value.image }
          ]
        }));
        storeMxB(
          reduxValue(name, [
            ...inputs[name],
            { title: value.title, customer_rating: rating1, img: value.image }
          ])
        );
      } else if (name === 'mx_b_palettes') {
        setInputs(prev => ({
          ...prev,
          [name]: [
            ...prev[name],
            {
              title: value.title,
              colors: value.colors,
              customer_rating: rating1,
              img: value.image
            }
          ]
        }));
        storeMxB(
          reduxValue(name, [
            ...inputs[name],
            {
              title: value.title,
              colors: value.colors,
              customer_rating: rating1,
              img: value.image
            }
          ])
        );
      }
    }
    // setRating(false);
  };

  const handleClickHome = (value, name, e) => {
    if (name === 'mx_b_materials' || name === 'mx_b_artworks') {
      let index = inputs[name].findIndex(f => f.title === value);
      if (index < 0) {
        setInputs(prev => ({
          ...prev,
          [name]: [...prev[name], { title: value, customer_rating: 0 }]
        }));
        storeMxB(reduxValue(name, [...inputs[name], { title: value, customer_rating: 0 }]));
      } else {
        setInputs({
          ...inputs,
          [name]: inputs[name].filter(filter => filter.title !== value)
        });
        storeMxB(
          reduxValue(
            name,
            inputs[name].filter(filter => filter.title !== value)
          )
        );
      }
    } else {
      if (!inputs[name].includes(value)) {
        setInputs(prev => ({ ...prev, [name]: [...prev[name], value] }));
        storeMxB(reduxValue(name, [...inputs[name], value]));
      } else {
        setInputs({
          ...inputs,
          [name]: inputs[name].filter(filter => filter !== value)
        });
        storeMxB(
          reduxValue(
            name,
            inputs[name].filter(filter => filter.title !== value)
          )
        );
      }
    }
  };

  const handleClickHomePatterns = (value, name) => {
    let index = inputs[name].findIndex(f => f.title === value.title);
    if (index < 0) {
      setInputs(prev => ({
        ...prev,
        [name]: [...prev[name], { title: value.title, img: value.image }]
      }));
      storeMxB(reduxValue(name, [...inputs[name], { title: value.title, img: value.image }]));
    } else {
      setInputs({
        ...inputs,
        [name]: inputs[name].filter(filter => filter.title !== value.title)
      });
      storeMxB(
        reduxValue(
          name,
          inputs[name].filter(filter => filter.title !== value.title)
        )
      );
    }
  };

  const removeCurrentProject = () => {
    questionnaireData.MxBData.splice(projectIndex, 1);
    return [...questionnaireData.MxBData];
  };

  const handleSubmit = async e => {
    let userData;

    ConsoleHelper('inputs', inputs);
    inputs = {
      ...inputs,
      mx_b_space_images: spaceImages,
      mx_b_decor_images: furnitureImages,
      mx_b_inspirations: inspirationImages
    };
    e.preventDefault();
    if (
      (activeTab === 'Your Contact' &&
        inputs.phone_number &&
        inputs.country_code &&
        // inputs.country_code !== "" &&
        inputs.phone_number !== '' &&
        inputs.tentative_budget &&
        inputs.tentative_budget !== '' &&
        inputs.mx_b_space_images &&
        inputs.mx_b_space_images.length >= 4 &&
        inputs.plan &&
        Object.keys(inputs.plan).length > 0 &&
        inputs.no_of_person &&
        inputs.no_of_person !== '' &&
        // inputs.project_vision &&
        // Object.keys(inputs.project_vision).length > 0 &&
        inputs.mx_b_artworks &&
        inputs.mx_b_artworks.length >= 3 &&
        inputs.mx_b_palettes &&
        inputs.mx_b_palettes.length >= 3 &&
        inputs.mx_b_patterns &&
        inputs.mx_b_patterns.length >= 3 &&
        inputs.dimensions &&
        Object.keys(inputs.dimensions).length >= 3 &&
        inputs.mx_b_materials &&
        inputs.mx_b_materials.length >= 3) ||
      false
    ) {
      try {
        if (id) {
          await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`,
            {
              _id: auth.user?._id,
              questionnaire: inputs
            }
          );
          const projectData = {
            questionnaire: {
              MX_B_SPACE_IMAGES: inputs.mx_b_space_images,
              MX_B_DECOR_IMAGES: inputs.mx_b_decor_images,
              // "MX_B_FLOOR_IMAGES": [],
              MX_B_PLAN: inputs.plan,
              MX_B_LIVE: inputs.live,
              NO_OF_PERSONS: inputs.no_of_person,
              MX_B_INSPIRATIONS: inputs.mx_b_inspirations,
              MX_B_COLLABORATOR: inputs.collaborator,
              // MX_B_PROJECT: inputs.project_vision,
              MX_B_ROOMS: inputs.mx_b_rooms,
              MX_B_ARTWORK: inputs.mx_b_artworks,
              MX_B_MATERIALS: inputs.mx_b_materials,
              MX_B_PALLETES: inputs.mx_b_palettes,
              MX_B_PATTERNS: inputs.mx_b_patterns,
              MX_B_DESIGN: inputs.design,
              MX_B_DIMENSIONS: inputs.dimensions,
              MX_B_OCCUPANTS: inputs.occupants,
              MX_B_TENTATIVE_BUDGET: inputs.tentative_budget,
              MX_B_PHONE_NUMBER: inputs.phone_number,
              MX_B_COUNTRY_CODE: inputs.country_code,
              MX_B_BUDGET_DETAILS: inputs.budget
            },
            status: 'PENDING_ALLOCATION',
            designer_allocation: 'UNASSIGNED',
            user_id: auth.user?._id,
            customer_name: auth.user.name,
            designer_id: ''
          };
          const projectResult = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${id}`,
            projectData
          );

          setLottie(true);
          setTimeout(() => {
            setLottie(false);
            history.push({
              pathname: `/rooms/${projectResult?.data?._id}`, //              pathname: `/rooms/${projectResult?.data?.project?._id}`,
              state: { type: 'getting' }
            });
          }, 4000);

          // history.push({
          //   pathname: `/rooms/${id}`,
          //   state: { type: "getting" },
          // });
        } else {
          const res = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`,
            {
              _id: auth.user._id,
              questionnaire: inputs
            }
          );
          userData = { ...res.data };
          // ConsoleHelper("projectData", res.data);
          if (res.data) {
            const projectData = {
              user_id: auth.user?._id,
              customer_name: auth.user.name,
              status: 'PENDING_ALLOCATION',
              designer_allocation: 'UNASSIGNED',
              designer_id: '',
              questionnaire: {
                MX_A_SPACE: userData.questionnaire.space,
                MX_A_STYLE: userData.questionnaire.style,
                MX_A_COLOR_TONE: userData.questionnaire.hasOwnProperty('color')
                  ? userData.questionnaire.color
                  : null,
                MX_A_DESIGN: userData.questionnaire.design,
                MX_A_ROOMS: userData.questionnaire.rooms,
                MX_A_INITAL_ROOM: userData.questionnaire.hasOwnProperty('initial_room')
                  ? userData.questionnaire.initial_room
                  : null,
                MX_A_STYLE_RESULT: userData.questionnaire.styleResult,
                MX_A_HOME: userData.questionnaire.home,
                MX_A_HOME_TYPE: userData.questionnaire.hometype,
                MX_A_LOCATION: userData.questionnaire.location,
                MX_A_STAGE: userData.questionnaire.stage,
                MX_A_ROOM_READY: userData.questionnaire.room,
                MX_A_BUDGET: userData.questionnaire.budget,
                PACKAGE: userData.questionnaire.package,
                MX_B_SPACE_IMAGES: inputs.mx_b_space_images,
                MX_B_DECOR_IMAGES: inputs.mx_b_decor_images,
                // "MX_B_FLOOR_IMAGES": [],
                MX_B_PLAN: inputs.plan,
                MX_B_LIVE: inputs.live,
                NO_OF_PERSONS: inputs.no_of_person,
                MX_B_INSPIRATIONS: inputs.mx_b_inspirations,
                MX_B_COLLABORATOR: inputs.collaborator,
                // MX_B_PROJECT: inputs.project_vision,
                MX_B_ROOMS: inputs.mx_b_rooms,
                MX_B_ARTWORK: inputs.mx_b_artworks,
                MX_B_MATERIALS: inputs.mx_b_materials,
                MX_B_PALLETES: inputs.mx_b_palettes,
                MX_B_PATTERNS: inputs.mx_b_patterns,
                MX_B_DESIGN: inputs.design,
                MX_B_DIMENSIONS: inputs.dimensions,
                MX_B_OCCUPANTS: inputs.occupants,
                MX_B_TENTATIVE_BUDGET: inputs.tentative_budget,
                MX_B_PHONE_NUMBER: inputs.phone_number,
                MX_B_COUNTRY_CODE: inputs.country_code,
                MX_B_BUDGET_DETAILS: inputs.budget
                // "MX_B_WISHLIST": {
                //   "plan": "Tell us how you plan to use the space and your thoughts about it in general",
                //   "live": "How would you describe the way you live?",
                //   "no_of_people": 23,
                //   "inspiration_images": [],
                //   "pinterest_collaborator": "Have a Pinterest board for your room? Add us as a collabrator.",
                //   "project": "What do you have in mind for this project? Tell us more about your vision"
                // }
              }
            };
            const res2 = await customerApiInstance.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
              projectData
            );
            storeMxB(removeCurrentProject());
            // ConsoleHelper("elseeee")
            setLottie(true);
            setTimeout(() => {
              setLottie(false);
              history.push({
                pathname: `/rooms/${res2.data.project?._id}`,
                state: { type: 'getting' }
              });
            }, 7000);

            // history.push({
            //   pathname: `/rooms/${res2.data.project._id}`,
            //   state: { type: "getting" },
            // });
          }
        }
        setSubmitted(false);
      } catch (err) {
        ConsoleHelper(err);
      }
    } else {
      setSnackMessage('Please fill all mandatory fields');
      setSnack(true);
      storeMxB(reduxValue('submitted', true));
      setSubmitted(true);

      if (
        inputs.mx_b_space_images.length < 4 ||
        Object.keys(inputs.plan).length < 1 ||
        inputs.no_of_person === ''
      ) {
        setActiveTab('About your space');
      } else if (Object.keys(inputs.project_vision).length >= 0) {
        setActiveTab('Your inspiration');
      } else if (
        inputs.mx_b_artworks.length < 3 ||
        inputs.mx_b_palettes.length < 3 ||
        inputs.mx_b_patterns.length < 3 ||
        inputs.mx_b_materials.length < 3 ||
        Object.keys(inputs.dimensions).length < 3
      ) {
        setActiveTab('Your Style');
      } else if (inputs.tentative_budget === '') {
        setActiveTab('Budget');
      }
    }
  };

  // const hiddenFileInput = React.useRef(null);
  // const hiddenFileInput2 = React.useRef(null);
  // const hiddenFileInput3 = React.useRef(null);

  const submitSpaceComment = () => {
    let annotate = [];
    let index = spaceImages.findIndex(space => space.img === currentSpaceImage.img);
    if (index >= 0) {
      spaceImages[index]['comment'] = spaceComment;
      if (annotations && annotations.length > 0) {
        for (let i = 0; i < annotations.length; i++) {
          annotate.push(annotations[i]);
        }
      }
      spaceImages[index]['annotations'] = annotate;
      // setAnnotateState([]);
      setAnnotations([]);
      setSpaceComment('');
      storeMxB(reduxValue('MX_B_SPACE_IMAGES', [...spaceImages]));
      // storeMxB({ ...questionnaireData.MxBData, MX_B_SPACE_IMAGES: [...spaceImages] })
      setSpaceImages([...spaceImages]);
    }
  };

  const start = () => {
    // setAudio({
    //   recordState: RecordState.START
    // })
    setStartAudio(true);
    setrecordstate(RecordState.START);
  };
  const reset = () => {
    // setAudio({
    //   recordState: RecordState.START
    // })
    setStartAudio(true);
    setblobURL('');
    setrecordstate(RecordState.START);
  };

  const stop = () => {
    // setAudio({
    //   recordState: RecordState.STOP
    // })
    setStartAudio(false);
    setrecordstate(RecordState.STOP);
  };

  const handleInputs1 = inputObject => {
    if (inputs && inputs.hasOwnProperty(inputObject.fieldName)) {
      inputs[inputObject.fieldName] = {
        ...inputs[inputObject.fieldName],
        audio: inputObject.audio
      };
      storeMxB(
        reduxValue(inputObject.fieldName, {
          ...inputs[inputObject.fieldName],
          audio: inputObject.audio
        })
      );
      setInputs({ ...inputs });
    } else {
      setInputs({
        ...inputs,
        [inputObject.fieldName]: {
          audio: inputObject.audio
        }
      });
      storeMxB(reduxValue(inputObject.fieldName, { audio: inputObject.audio }));
    }
  };

  //audioData contains blob and blobUrl
  const onStop = async audioData => {
    // setAudioRecorded(true);
    var d = new Date();
    var file = new File([audioData.blob], d.valueOf(), { type: 'audio/wav' });
    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData);
    if (res.data && res.data.message === 'success') {
      setSnack(true);
      setSnackMessage('Audio uploaded sucessfully.');
    }
    let audioResult = res.data.data;
    let audioObj = {};
    audioObj['fieldName'] = currentField;
    if (audioResult && Array.isArray(audioResult)) {
      audioObj['audio'] = audioResult[0].Location;
    } else {
      audioObj['audio'] = audioResult.Location;
    }
    handleInputs1(audioObj);
    audioUrl.push(audioObj);
    setblobURL(audioData.url);
  };

  //console req to debug in dev env

  // const getAudioUrl = (field) => {
  //   if (audioUrl && audioUrl.length > 0) {
  //     let index = audioUrl.findIndex((ad) => ad.fieldName === field);
  //     if (index >= 0) {
  //       return audioUrl[index].audio;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  let imageLink = [];

  const recursion = async (files, cb) => {
    if (files.length) {
      const i = 0;
      let formData = new FormData();
      // for (let i = 0; i < fileUploaded.length; i++) {
      formData.append('file', files[i]);
      // }
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData);
      if (res.data && res.data.message === 'success') {
        let uploadImages = res.data.data;
        imageLink.push(uploadImages[0]);
      }
      const [, ...newArray] = files;
      recursion([...newArray], cb);
    } else {
      cb();
      imageLink = [];
    }
  };
  const handleChange = async files => {
    // const fileUploaded = files;
    recursion(files, () => {
      for (let i = 0; i < imageLink.length; i++) {
        spaceImages.push({
          img: imageLink[i].Location,
          fileType: imageLink[i].fileType
        });
      }
      storeMxB(reduxValue('MX_B_SPACE_IMAGES', [...spaceImages]));
      setSpaceImages([...spaceImages]);
    });
  };

  const handleInspirationChange = async files => {
    //const fileUploaded = files;
    recursion(files, () => {
      for (let i = 0; i < imageLink.length; i++) {
        inspirationImages.push({
          img: imageLink[i].Location,
          fileType: imageLink[i].fileType
        });
      }
      storeMxB(reduxValue('MX_B_INSPIRATION_IMAGES', [...inspirationImages]));
      setInspirationImages([...inspirationImages]);
    });
  };

  const handleFurnitureChange = async files => {
    recursion(files, () => {
      for (let i = 0; i < imageLink.length; i++) {
        furnitureImages.push({
          img: imageLink[i].Location,
          fileType: imageLink[i].fileType
        });
      }
      storeMxB(reduxValue('MX_B_DECOR_IMAGES', [...furnitureImages]));
      setFurnitureImages([...furnitureImages]);
    });
  };

  // const getRatingValue = (title) => {
  //   let x;
  //   if (
  //     inputs &&
  //     inputs.hasOwnProperty("mx_b_artworks") &&
  //     inputs.mx_b_artworks.length > 0
  //   ) {
  //     x = inputs.mx_b_artworks.findIndex((x) => x.title === title);
  //     if (x === -1) return 0;
  //     else {
  //       return inputs.mx_b_artworks[x].customer_rating;
  //     }
  //   } else return 0;
  // };

  // const getMaterialsRatingValue = (title) => {
  //   let x;
  //   if (
  //     inputs &&
  //     inputs.hasOwnProperty("mx_b_materials") &&
  //     inputs.mx_b_materials.length > 0
  //   ) {
  //     x = inputs.mx_b_materials.findIndex((x) => x.title === title);
  //     if (x === -1) return 0;
  //     else {
  //       return inputs.mx_b_materials[x].customer_rating;
  //     }
  //   } else return 0;
  // };

  // const getPalettesRatingValue = (title) => {
  //   let x;
  //   if (
  //     inputs &&
  //     inputs.hasOwnProperty("mx_b_palettes") &&
  //     inputs.mx_b_palettes.length > 0
  //   ) {
  //     x = inputs.mx_b_palettes.findIndex((x) => x.title === title);
  //     if (x === -1) return 0;
  //     else {
  //       return inputs.mx_b_palettes[x].customer_rating;
  //     }
  //   } else return 0;
  // };

  // const getComment = (currentSpaceImage) => {
  //   let space = spaceImages.find((space) => space.img === currentSpaceImage);
  //   if (space && space.comment) return space.comment;
  // };

  const classes = useStyles();
  const checkFileSizes = files => {
    return files.every(file => {
      return (
        (file.type.match(/video/i) !== null && file.size <= 10000000) ||
        (file.type.match(/image/i) !== null && file.size <= 5000000)
      );
    });
  };

  const onDrop = acceptedFiles => {
    setUploadError({});
    if (checkFileSizes(acceptedFiles)) {
      if (spaceImages.length + acceptedFiles.length > 8) {
        setSnack(true);
        if (spaceImages.length >= 8) {
          setSnackMessage('only 8 images can be uploaded');
        } else {
          setSnackMessage(
            'Only' + ' ' + `${8 - spaceImages.length}` + ' ' + 'images can be selected '
          );
        }
      } else {
        handleChange(acceptedFiles);
        setImagePopup(false);
      }
    } else {
      setUploadError({ drop: 'max file size image 5MB and video 10MB' });
    }
  };

  const onDrop1 = acceptedFiles => {
    setUploadError({});
    if (checkFileSizes(acceptedFiles)) {
      if (furnitureImages.length + acceptedFiles.length > 8) {
        setSnack(true);
        if (furnitureImages.length >= 8) {
          setSnackMessage('only 8 images can be uploaded');
        } else {
          setSnackMessage(
            'Only' + ' ' + `${8 - furnitureImages.length}` + ' ' + 'images can be selected '
          );
        }
      } else {
        handleFurnitureChange(acceptedFiles);
      }
    } else {
      setUploadError({ drop1: 'max file size image 5MB and video 10MB' });
    }
  };

  const onDrop2 = acceptedFiles => {
    setUploadError({});
    if (checkFileSizes(acceptedFiles)) {
      if (inspirationImages.length + acceptedFiles.length > 8) {
        setSnack(true);
        if (inspirationImages.length >= 8) {
          setSnackMessage('only 8 images can be uploaded');
        } else {
          setSnackMessage(
            'Only' + ' ' + `${8 - inspirationImages.length}` + ' ' + 'images can be selected '
          );
        }
      } else {
        handleInspirationChange(acceptedFiles);
      }
    } else {
      setUploadError({ drop2: 'max file size image 5MB and video 10MB' });
    }
  };

  useEffect(() => {
    if (spaceImages.length >= 4) {
      setSpaceError(false);
    }
  }, [spaceImages]);

  useEffect(() => {
    if (inputs.hasOwnProperty('plan') && inputs.plan !== '') {
      setPlanError(false);
    }
    if (inputs.hasOwnProperty('no_of_person') && inputs.no_of_person !== '') {
      setPersonsError(false);
    }
    if (inputs.hasOwnProperty('project_vision')) {
      setVisionError(false);
    }
    if (inputs.mx_b_rooms && inputs.mx_b_rooms.length >= 1) {
      setItemError(false);
    }
    if (inputs.mx_b_artworks && inputs.mx_b_artworks.length >= 3) {
      setArtsError(false);
    }
    if (inputs.mx_b_materials && inputs.mx_b_materials.length >= 3) {
      setMaterialsError(false);
    }
    if (inputs.mx_b_palettes && inputs.mx_b_palettes.length >= 3) {
      setPalletsError(false);
    }
    if (inputs.mx_b_patterns && inputs.mx_b_patterns.length >= 3) {
      setPatternsError(false);
    }
    if (
      inputs.dimensions &&
      inputs.dimensions.length &&
      inputs.dimensions.width &&
      inputs.dimensions.ceiling_height
    ) {
      setDimensionsError(false);
    }
    if (inputs.tentative_budget && inputs.tentative_budget !== ' ') {
      setBudgetError(false);
    }
    // if (inputs.phone_number && phone_number !== " ") {
    //   setPhoneNumberError(false);
    // }
  }, [inputs]);

  const getInteriorCTA = () => {
    if (
      spaceImages.length >= 4 &&
      spaceImages.length <= 8 &&
      inputs.hasOwnProperty('no_of_person') &&
      inputs.no_of_person !== '' &&
      inputs.hasOwnProperty('plan') &&
      inputs.plan !== ''
    ) {
      setValidPageCount(validPageCount + 1);
      setActiveTab('Your inspiration');
    } else if (spaceImages.length < 4) {
      setSpaceError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 4 space images');
    } else if (spaceImages.length > 8) {
      setSpaceError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select not more than 8 space images');
    } else if (!inputs.hasOwnProperty('plan') || inputs.plan === '') {
      setPlanError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please fill how do you plan to live');
    } else if (!inputs.hasOwnProperty('no_of_person') || inputs.no_of_person === '') {
      setPersonsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please fill number of persons');
    }
  };

  const getWishlistCTA = () => {
    setValidPageCount(validPageCount + 1);
    setActiveTab('Your Style');
  };

  const getBudgetCTA = () => {
    if (!inputs.mx_b_rooms || inputs.mx_b_rooms.length < 1) {
      setItemError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 1 room item image');
    } else if (!inputs.mx_b_artworks || inputs.mx_b_artworks.length < 3) {
      setArtsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 3 arts images');
    } else if (!inputs.mx_b_materials || inputs.mx_b_materials.length < 3) {
      setMaterialsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 3 materials images');
    } else if (!inputs.mx_b_palettes || inputs.mx_b_palettes.length < 3) {
      setPalletsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 3 pallets images');
    } else if (!inputs.mx_b_patterns || inputs.mx_b_patterns.length < 3) {
      setPatternsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 3 patterns images');
    } else if (
      inputs.dimensions &&
      inputs.dimensions.length &&
      inputs.dimensions.width &&
      inputs.dimensions.ceiling_height
    ) {
      setValidPageCount(validPageCount + 1);
      setActiveTab('Your Budget');
    } else {
      setDimensionsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please fill the dimensions fields');
    }
  };
  const getContactCTA = () => {
    if (inputs.tentative_budget && inputs.tentative_budget !== ' ') {
      setActiveTab('Your Contact');
    } else {
      setBudgetError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please fill the budget field');
    }
  };

  // const [pageSize, setPageSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });
  // const onResize = () => {
  //   setPageSize({ width: window.innerWidth, height: window.innerHeight });
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", onResize);
  //   return () => window.removeEventListener("resize", onResize);
  // }, []);

  const reduxValue = (key1, value) => {
    let currentValue = questionnaireData.MxBData.splice(projectIndex, 1);
    let addedValue = { ...currentValue[0], [key1]: value };
    return [...questionnaireData.MxBData, addedValue];
    // ...questionnaireData.MxBData, questionnaireData.MxBData[projectIndex]['MX_B_PERSONS'] = e.target.value
  };

  const removeAudio = key1 => {
    inputs.plan.audio = '';
    if (questionnaireData?.MxBData[projectIndex]?.hasOwnProperty(key1)) {
      questionnaireData.MxBData[projectIndex][key1].audio = '';
      return [...questionnaireData.MxBData];
    }
    return [...questionnaireData.MxBData];
  };

  const handleChangeFile = async e => {
    const fileUploaded = e.target.files;
    let error = false;
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 5000000) {
        error = true;
        break;
      }
    }

    if (!error) {
      let fileName = fileUploaded[0].name;
      const formData = new FormData();
      for (let i = 0; i < fileUploaded.length; i++) {
        formData.append('file', fileUploaded[i]);
      }
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData);
      if (res.data && res.data.message === 'success') {
        let uploadFiles = res.data.data;
        if (uploadFiles.length === 1) {
          setDimensionFile({
            fileLocation: uploadFiles[0].Location,
            fileType: uploadFiles[0].fileType,
            name: fileName
          });
          setInputs({
            ...inputs,
            dimensions: {
              ...inputs.dimensions,
              file: {
                fileLocation: uploadFiles[0].Location,
                fileType: uploadFiles[0].fileType,
                name: fileName
              }
            }
          });
          storeMxB(
            reduxValue('MX_B_DIMENSIONS', {
              ...inputs.dimensions,
              file: {
                fileLocation: uploadFiles[0].Location,
                fileType: uploadFiles[0].fileType,
                name: fileName
              }
            })
          );
        }
      }
    } else {
      setUploadError({ upload: 'max file image size 5MB' });
    }
  };

  const loadImage = imageUrl => {
    const img = new Image();
    img.src = imageUrl;
    // img.onload = () => {
    //   setImageDimensions({
    //     height: img.height,
    //     width: img.width,
    //   });
    // };
    img.onerror = err => {
      console.error(err);
    };
  };

  const [second, setSecond] = useState('00');
  const [minute, setMinute] = useState('00');
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter(counter => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  const stopTimer = () => {
    setIsActive(false);
    setCounter(0);
    setSecond('00');
    setMinute('00');
  };

  // const updateNotAllowed = () => {
  //   setSnack(true);
  //   setSnackMessage("Edit not allowed");
  // };

  return (
    <>
      {lottie ? (
        <>
          <p className={style.perfectStyle} style={{ textAlign: 'center', fontSize: '25px' }}>
            <i>No pressure: We will find the perfect designer for you.</i>
          </p>
          <Lottie options={defaultOptions} height="90vh" width={900} />
        </>
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snack}
            onClose={() => setSnack(false)}
            message={snackMessage}
            autoHideDuration={2000}
          />
          {dimension[0] >= 600 && (
            <>
              <div className={classnames('', style.main__desktop__viewer)}>
                <div className={classes.topbar}>
                  <div className={classes.justify}>
                    <img
                      src={'https://d3izcfql7lesks.cloudfront.net/Group_2456_mc74fd.png'}
                      alt=""
                    />
                    <h5
                      style={{
                        padding: '13px'
                      }}>{`${projectQuestionnaire?.MX_A_INITAL_ROOM} profile`}</h5>
                  </div>
                </div>
                <div className={classnames(' m-0 mt-5 justify-content-center row')}>
                  <div
                    className={classnames('col-md-2 pr-0', style.sideBar)}
                    style={{
                      backgroundImage: `url(${SideBarPattern})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '0 80%'
                    }}>
                    {tabs.map((tab, index) => (
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '20px',
                          cursor: 'pointer',
                          justifyContent: 'space-between'
                        }}
                        onClick={() => {
                          setActiveTab(tab);
                        }}>
                        <h6
                          style={{ fontSize: '1vw' }}
                          className={activeTab === tab && style.active}>
                          {tab}
                        </h6>
                        {activeTab === tab && (
                          <img
                            style={{
                              objectFit: 'contain',
                              alignSelf: 'flex-start'
                            }}
                            src={Arrow}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  {activeTab === 'About your space' && (
                    <div className={classnames('col-md-10', style.container)}>
                      <h5
                        ref={reference}
                        className={classnames(
                          'font-weight-800 mt-5',
                          spaceError ? style.error1 : ' '
                        )}>
                        Upload Photos or videos of your space
                      </h5>
                      <span className={classnames('mt-5', spaceError ? style.error1 : ' ')}>
                        {uploadError.drop ? (
                          <span style={{ fontSize: 10, color: 'red' }}>{uploadError.drop}</span>
                        ) : null}
                      </span>

                      <div className="row mt-5">
                        {spaceImages &&
                          spaceImages.length > 0 &&
                          spaceImages.map((data, index) => {
                            return data?.fileType &&
                              (data.fileType === 'png' ||
                                data.fileType === 'jpeg' ||
                                data.fileType === 'jpg') ? (
                              <div
                                className={classnames(
                                  'col-3 d-flex flex-column justify-content-center align-items-center',
                                  style.imageRow
                                )}
                                style={{
                                  paddingLeft: '0px',
                                  paddingRight: '0px'
                                }}>
                                <img
                                  src={data.img}
                                  alt=""
                                  width="100%"
                                  height="100%"
                                  style={{
                                    objectFit: 'contain',
                                    width: 'inherit'
                                  }}
                                />
                                {selecteddeletestatus && selecteddeleteid === index ? (
                                  <></>
                                ) : (
                                  <div
                                    style={{
                                      width: 'inherit',
                                      backgroundColor: 'rgba(0,0,0, 0.7)',
                                      padding: '8px',
                                      color: '#ffffff',
                                      height: '40px',
                                      position: 'absolute',
                                      bottom: 0,
                                      paddingBottom: '10px',
                                      display: 'flex',
                                      flexDirection: 'row'
                                    }}>
                                    <div
                                      style={{ width: '90%' }}
                                      onClick={() => {
                                        setCurrentSpaceImage(data);
                                        loadImage(data.img);

                                        setModal(2);
                                      }}>
                                      + Add Comment
                                    </div>
                                    <div style={{ width: '10%' }}>
                                      <DeleteIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          setselecteddeletestatus(true);
                                          setselecteddeleteid(index);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                                {selecteddeletestatus && selecteddeleteid === index && (
                                  <div
                                    style={{
                                      padding: '8px',
                                      flex: 1,
                                      position: 'absolute',
                                      left: 0,
                                      top: 0,
                                      opacity: 0.8,
                                      backgroundColor: 'black',
                                      height: '100%',
                                      width: '100%'
                                    }}
                                    className={classnames(
                                      'd-flex flex-column justify-content-center align-items-center'
                                    )}>
                                    <div
                                      style={{
                                        color: '#ffffff',
                                        textAlign: 'center'
                                      }}>
                                      {' '}
                                      Are you sure you want <br />
                                      to delete this?
                                    </div>
                                    <div className="row mt-4 mb-0 justify-content-center">
                                      <div className="col-6 pr-0 pl-0">
                                        <button
                                          className={style.deleteBtn}
                                          onClick={() => {
                                            let temp = spaceImages;
                                            temp.splice(index, 1);
                                            setSpaceImages(prev => {
                                              return temp.length > 0 ? [...temp] : [];
                                            });
                                            storeMxB(reduxValue('MX_B_SPACE_IMAGES', [...temp]));
                                            setselecteddeletestatus(false);
                                            setselecteddeleteid(null);
                                          }}>
                                          Yes
                                        </button>
                                      </div>{' '}
                                      <div className="col-6 pr-0">
                                        <button
                                          className={style.cancelBtn}
                                          onClick={() => {
                                            setselecteddeletestatus(false);
                                            setselecteddeleteid(null);
                                          }}>
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                className={classnames(
                                  'col-3 d-flex flex-column justify-content-center align-items-center ',
                                  style.imageRow
                                )}
                                style={{
                                  width: '100%'
                                }}>
                                <ReactPlayer
                                  url={data?.img ? data.img : ''}
                                  width="100%"
                                  height="100%"
                                  controls
                                />
                                <div
                                  style={{
                                    width: '95%',
                                    backgroundColor: 'rgba(0,0,0, 0.7)',
                                    padding: '8px',
                                    color: '#ffffff',
                                    height: '40px',
                                    position: 'absolute',
                                    bottom: 0,
                                    paddingBottom: '10px'
                                  }}
                                  onClick={() => {
                                    setCurrentSpaceImage(data);
                                    setModal(2);
                                  }}>
                                  + Add Comment
                                </div>
                              </div>
                            );
                          })}
                        {!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                          <>
                            <div
                              className={classnames(
                                'col-3 d-flex flex-column justify-content-center align-items-center',
                                style.imageRow
                              )}>
                              <Dropzone
                                onDrop={onDrop}
                                multiple={true}
                                maxFiles={8 - spaceImages.length}
                                ref={dropzoneRef}>
                                {({ getRootProps, getInputProps }) => (
                                  <>
                                    <div
                                      {...getRootProps()}
                                      className={classnames(
                                        'd-flex flex-column justify-content-evenly align-items-center p-2',
                                        style.dropBox
                                      )}
                                      onClick={() => {
                                        if (spaceImages.length == 0) {
                                          setImagePopup(true);
                                        } else {
                                          if (dropzoneRef.current) {
                                            dropzoneRef.current.open();
                                          }
                                        }
                                      }}>
                                      <div
                                        className={classnames(
                                          'd-flex flex-column justify-content-evenly align-items-center pt-0',
                                          style.borderImg
                                        )}>
                                        <img
                                          src={
                                            'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6237%402x.png'
                                          }
                                          alt=""
                                          className={style.thumbImg}
                                        />
                                        <img
                                          src={
                                            'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6240%402x.png'
                                          }
                                          alt=""
                                          className={style.thumbImg1}
                                        />

                                        <div className={style.dropTxt}>
                                          Drop your photo or video here
                                          <input {...getInputProps()} />
                                        </div>
                                      </div>
                                    </div>
                                    {/* Popup modal for About your space*/}
                                    <Modal
                                      aria-labelledby="transition-modal-title"
                                      aria-describedby="transition-modal-description"
                                      className={classes.modal}
                                      open={imagePopup}
                                      onClose={() => {
                                        setImagePopup(false);
                                      }}
                                      closeAfterTransition
                                      BackdropComponent={Backdrop}
                                      BackdropProps={{
                                        timeout: 500
                                      }}>
                                      <Fade in={imagePopup}>
                                        <div
                                          className={classnames(
                                            classes.papers,
                                            Popupstyle.modalContainer
                                          )}
                                          style={{
                                            height: dimension[0] > 500 ? '' : '100vh',
                                            opacity: 1,
                                            visibility: 'visible'
                                          }}>
                                          <button
                                            className={Popupstyle.closeBtn}
                                            onClick={() => {
                                              setImagePopup(false);
                                            }}>
                                            <img
                                              src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/close.png"
                                              className={Popupstyle.closeBtn}
                                            />
                                          </button>
                                          <div className="row">
                                            <div className="col-6 d-flex justify-content-center align-items-center flex-column pr-0">
                                              <img
                                                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/room_illustration.svg"
                                                alt="room_illustration"
                                                style={{
                                                  width: '95%'
                                                }}
                                              />
                                              <h6 className="mt-3">
                                                Capture your room as per reference photo above.
                                              </h6>
                                            </div>

                                            <div className="col-6 pl-0">
                                              <div className={Popupstyle.QrLeft}>
                                                <img
                                                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Mask+Group+20.png"
                                                  style={{
                                                    width: '100%',
                                                    height: '100%'
                                                  }}
                                                />
                                                <div className={Popupstyle.txtSec}>
                                                  <h4 className="mb-3">
                                                    Follow these steps to enhance your experience
                                                  </h4>
                                                  <ul
                                                    style={{
                                                      listStyle: 'disc',
                                                      fontSize: '14px',
                                                      fontWeight: '500'
                                                    }}>
                                                    <li className="mb-2">
                                                      These images are for scale and reference for
                                                      your mood boards.
                                                    </li>
                                                    <li className="mb-2">
                                                      Please ensure a well-lit photo of the room, at
                                                      eye level, from a distance that covers all
                                                      ground for accurate visual representation and
                                                      best results.
                                                    </li>
                                                    <button
                                                      className={Popupstyle.uploadBtn}
                                                      onClick={() => {
                                                        if (dropzoneRef.current) {
                                                          dropzoneRef.current.open();
                                                        }
                                                      }}>
                                                      Upload Image
                                                    </button>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Fade>
                                    </Modal>
                                  </>
                                )}
                              </Dropzone>
                            </div>
                          </>
                        )}
                      </div>
                      <h5 className="mt-5">
                        <span className="font-weight-800">
                          Share pictures of things you would like to retain
                        </span>{' '}
                        {uploadError.drop1 ? (
                          <span style={{ fontSize: 10, color: 'red' }}>{uploadError.drop1}</span>
                        ) : null}
                      </h5>

                      <div className="row mt-5">
                        {furnitureImages &&
                          furnitureImages.map(data =>
                            data.fileType === 'png' ||
                            data.fileType === 'jpeg' ||
                            data.fileType === 'jpg' ? (
                              <div
                                className={classnames(
                                  'col-3 d-flex flex-column justify-content-center align-items-center ',
                                  style.imageRow
                                )}>
                                <img
                                  src={data.img}
                                  alt=""
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: 'contain' }}
                                />
                                <div
                                  style={{
                                    width: '95%',
                                    backgroundColor: 'rgba(0,0,0, 0.7)',
                                    padding: '8px',
                                    color: '#ffffff',
                                    height: '40px',
                                    position: 'absolute',
                                    bottom: 0,
                                    paddingBottom: '10px'
                                  }}
                                  onClick={() => {
                                    setCurrentSpaceImage(data);
                                    loadImage(data.img);

                                    setModal(2);
                                  }}>
                                  + Add Comment
                                </div>
                              </div>
                            ) : (
                              <div
                                className={classnames(
                                  'col-3 d-flex flex-column justify-content-center align-items-center ',
                                  style.imageRow
                                )}
                                style={{
                                  width: '100%'
                                }}>
                                <ReactPlayer url={data.img} width="100%" height="100%" controls />
                                <div
                                  style={{
                                    width: '95%',
                                    backgroundColor: 'rgba(0,0,0, 0.7)',
                                    padding: '8px',
                                    color: '#ffffff',
                                    height: '40px',
                                    position: 'absolute',
                                    bottom: 0,
                                    paddingBottom: '10px'
                                  }}
                                  onClick={() => {
                                    setCurrentSpaceImage(data);
                                    loadImage(data.img);

                                    setModal(2);
                                  }}>
                                  + Add Comment
                                </div>
                              </div>
                            )
                          )}

                        {!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                          <>
                            <div className="col-3">
                              <Dropzone
                                onDrop={onDrop1}
                                multiple={true}
                                maxFiles={8 - furnitureImages.length}>
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps()}
                                    className={classnames(
                                      'd-flex flex-column justify-content-evenly align-items-center p-2',
                                      style.dropBox
                                    )}>
                                    <div
                                      className={classnames(
                                        'd-flex flex-column justify-content-evenly align-items-center pt-0',
                                        style.borderImg
                                      )}>
                                      <img
                                        src={
                                          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6237%402x.png'
                                        }
                                        alt=""
                                        className={style.thumbImg}
                                      />
                                      <img
                                        src={
                                          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6240%402x.png'
                                        }
                                        alt=""
                                        className={style.thumbImg1}
                                      />

                                      <div className={style.dropTxt}>
                                        Drop your photo or video here
                                        <input {...getInputProps()} />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                          </>
                        )}
                      </div>

                      <h4 className={classnames('mt-5', planError ? style.error1 : ' ')}>
                        <span className="font-weight-800">
                          Tell us how you plan to use the space and your thoughts about it in
                          general.*
                        </span>
                      </h4>
                      <span style={{ fontSize: '15px' }}>
                        Example: I’d like to see a lot of plants and wooden furniture around me. A
                        TV facing the bed to spend my nights comfortable after work would just be
                        perfect!
                      </span>

                      <div className={style.containerText}>
                        <textarea
                          style={{
                            width: '70%',
                            height: '20vh',
                            marginTop: '20px',
                            padding: '15px'
                          }}
                          name="plan"
                          placeholder="Tell us here"
                          onChange={e => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET'))
                              handleInputs(e);
                          }}
                          value={inputs.plan && inputs.plan.text ? inputs.plan.text : ''}
                          className={planError ? style.error : ' '}></textarea>
                        <Icon.MicFill
                          className={style.microphoneIco1}
                          onClick={() => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              setCurrentField('plan');
                              setAudioModal(true);
                              setblobURL('');
                            }
                          }}></Icon.MicFill>
                        {inputs.plan &&
                          inputs.plan.audio &&
                          !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                            <Icon.Trash
                              className={style.Trash}
                              onClick={() => {
                                inputs.plan.audio = '';
                                storeMxB(removeAudio('plan'));
                                setInputs({ ...inputs });
                              }}></Icon.Trash>
                          )}
                        {inputs.plan && inputs.plan.audio && (
                          <div>
                            <ReactAudioPlayer
                              name="plan"
                              className={style.audio}
                              src={inputs.plan && inputs.plan.audio ? inputs.plan.audio : ''}
                              controls
                            />
                          </div>
                        )}
                      </div>

                      <h4 className="mt-5 font-weight-800 d-none">
                        How would you describe the way you live?
                      </h4>
                      <div className={classnames(style.containerText, 'd-none')}>
                        <textarea
                          style={{
                            width: '70%',
                            height: '20vh',
                            marginTop: '20px',
                            padding: '15px'
                          }}
                          name="live"
                          onChange={e => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET'))
                              handleInputs(e);
                          }}
                          value={
                            inputs.live && inputs.live.text ? inputs.live.text : ''
                          }></textarea>
                        <Icon.MicFill
                          className={style.microphoneIco1}
                          onClick={() => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              setCurrentField('live');
                              setAudioModal(true);
                              setblobURL('');
                            }
                          }}></Icon.MicFill>
                        {inputs.live &&
                          inputs.live.audio &&
                          !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                            <Icon.Trash
                              className={style.Trash}
                              onClick={() => {
                                inputs.live.audio = '';
                                storeMxB(removeAudio('live'));
                                setInputs({ ...inputs });
                              }}></Icon.Trash>
                          )}
                        {inputs.live && inputs.live.audio && (
                          <ReactAudioPlayer
                            name="live"
                            className={style.audio}
                            src={inputs.live && inputs.live.audio ? inputs.live.audio : ''}
                            controls
                          />
                        )}
                      </div>
                      <h4 className={classnames('mt-5', personsError ? style.error1 : ' ')}>
                        {' '}
                        <span className="font-weight-800">
                          The number of people who reside in the house.*
                        </span>{' '}
                      </h4>
                      <div className={style.wholeBtn}>
                        <button className={style.buttonStyle} onClick={decrementCount}>
                          -
                        </button>
                        <input
                          readOnly
                          style={{
                            width: '48px',
                            height: '31px',
                            border: 'none',
                            textAlign: 'center',
                            fontWeight: 600
                          }}
                          type="number"
                          name="no_of_person"
                          value={inputs.no_of_person ? inputs.no_of_person : 0}
                          onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                          onChange={e => {
                            if (
                              e.target.value <= 99 &&
                              e.target.value !== 'e' &&
                              !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')
                            ) {
                              handleInputs(e);
                            }
                          }}
                          className={personsError ? style.error : ' '}
                        />
                        <button className={style.buttonStyle} onClick={incrementCount}>
                          +
                        </button>
                      </div>
                      <div className="row ">
                        <button
                          className={classnames('', style.saveButton)}
                          onClick={() => {
                            getInteriorCTA();
                          }}>
                          Go to Inspiration
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === 'Your inspiration' && (
                    <div className={classnames('col-md-10', style.container)}>
                      <h5 className="font-weight-800 mt-5">Inspirations</h5>
                      <span>
                        Upload pictures, links, Pinterest boards, magazine cutouts, bookmarked
                        furniture, paint swatches, everything that makes you giddy with joy.{' '}
                        {uploadError.drop2 ? (
                          <span style={{ fontSize: 10, color: 'red' }}>{uploadError.drop2}</span>
                        ) : null}
                      </span>

                      <div className="row mt-5">
                        {inspirationImages &&
                          inspirationImages.map(data =>
                            data.fileType === 'png' ||
                            data.fileType === 'jpeg' ||
                            data.fileType === 'jpg' ? (
                              <div
                                className={classnames(
                                  'col-3 d-flex flex-column justify-content-center align-items-center ',
                                  style.imageRow
                                )}>
                                <img
                                  src={data.img}
                                  alt=""
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: 'contain' }}
                                />
                                <div
                                  className={classnames('', style.photos__btn2)}
                                  onClick={() => {
                                    setCurrentSpaceImage(data);
                                    loadImage(data.img);

                                    setModal(2);
                                  }}>
                                  + Add Comment
                                </div>
                              </div>
                            ) : (
                              <div
                                className={classnames(
                                  'col-3 d-flex flex-column justify-content-center align-items-center ',
                                  style.imageRow
                                )}
                                style={{
                                  width: '100%'
                                }}>
                                <ReactPlayer url={data.img} width="100%" height="100%" controls />
                                <div
                                  style={{
                                    width: '95%',
                                    backgroundColor: 'rgba(0,0,0, 0.7)',
                                    padding: '8px',
                                    color: '#ffffff',
                                    height: '40px',
                                    position: 'absolute',
                                    bottom: 0,
                                    paddingBottom: '10px'
                                  }}
                                  onClick={() => {
                                    setCurrentSpaceImage(data);
                                    loadImage(data.img);

                                    setModal(2);
                                  }}>
                                  + Add Comment
                                </div>
                              </div>
                            )
                          )}

                        {!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                          <>
                            <div className="col-3">
                              <Dropzone onDrop={onDrop2} multiple={true}>
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps()}
                                    className={classnames(
                                      'd-flex flex-column justify-content-evenly align-items-center p-2',
                                      style.dropBox
                                    )}>
                                    <div
                                      className={classnames(
                                        'd-flex flex-column justify-content-evenly align-items-center pt-0',
                                        style.borderImg
                                      )}>
                                      <img
                                        src={
                                          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6237%402x.png'
                                        }
                                        alt=""
                                        className={style.thumbImg}
                                      />
                                      <img
                                        src={
                                          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6240%402x.png'
                                        }
                                        alt=""
                                        className={style.thumbImg1}
                                      />

                                      <div className={style.dropTxt}>
                                        Drop your photo or video here
                                        <input {...getInputProps()} />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                          </>
                        )}
                      </div>
                      <h4 className="mt-5">
                        <span className="font-weight-800">Pinterest </span>
                      </h4>
                      <input
                        placeholder="Paste link here"
                        type="text"
                        style={{
                          width: '50%',
                          border: 'none',
                          borderBottom: '1px solid #E0E0E0',
                          marginTop: 20
                        }}
                        name="collaborator"
                        value={inputs.collaborator ? inputs.collaborator : ''}
                        onChange={e => {
                          if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                            handleInputs(e);
                            storeMxB(reduxValue('MX_B_COLLABORATOR', e.target.value));
                          }
                        }}
                      />

                      <h4
                        className={classnames(
                          'mt-5 font-weight-800 d-none',
                          visionError ? style.error1 : ' '
                        )}>
                        What do you have in mind for this project? Tell us more about your vision.*
                      </h4>
                      <div className={style.containerText} style={{ display: 'none' }}>
                        <textarea
                          name="project_vision"
                          className={visionError ? style.error : ' '}
                          style={{
                            width: '70%',
                            height: '20vh',
                            marginTop: '20px',
                            padding: '15px'
                          }}
                          onChange={e => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET'))
                              handleInputs(e);
                          }}
                          value={
                            inputs.project_vision && inputs.project_vision.text
                              ? inputs.project_vision.text
                              : ''
                          }></textarea>
                        <Icon.MicFill
                          className={style.microphoneIco1}
                          onClick={() => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              setCurrentField('project_vision');
                              setAudioModal(true);
                              setblobURL('');
                            }
                          }}></Icon.MicFill>
                        {inputs.project_vision &&
                          inputs.project_vision.audio &&
                          !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                            <Icon.Trash
                              className={style.Trash}
                              onClick={() => {
                                inputs.project_vision.audio = '';
                                storeMxB(removeAudio('project_vision'));
                                setInputs({ ...inputs });
                              }}></Icon.Trash>
                          )}
                        {inputs.project_vision && inputs.project_vision.audio && (
                          <ReactAudioPlayer
                            name="project_vision"
                            className={style.audio}
                            src={
                              inputs.project_vision && inputs.project_vision.audio
                                ? inputs.project_vision.audio
                                : ''
                            }
                            controls
                          />
                        )}
                      </div>
                      <h4 className="mt-5" ref={wishlistRef}>
                        <span className="font-weight-800">
                          {' '}
                          That is one good-looking room! <br></br>
                          Now let’s jump right in to what you need for your room.
                        </span>
                      </h4>
                      <button
                        className={style.wishlistButton}
                        style={{ marginBottom: '50px', marginTop: '50px' }}
                        onClick={() => {
                          getWishlistCTA();
                        }}>
                        Create Wishlist
                      </button>

                      <br></br>
                    </div>
                  )}
                  {activeTab === 'Your Style' && (
                    <div className={classnames('col-md-10', style.container)}>
                      <h4
                        className={classnames(
                          'font-weight-800 mt-5',
                          itemError ? style.error1 : ' '
                        )}>
                        Select all the items you want help with in your space*.
                      </h4>
                      <span style={{ fontSize: '15px' }}>
                        Choose the furniture and decor you want to see in your space{' '}
                      </span>
                      <div className="row" style={{ width: '85%', marginTop: '4%' }}>
                        {furnitureRooms.map((data, index) => (
                          <div className="col-xl-2 col-lg-3">
                            <div
                              className={classnames(
                                style.itemsBox,
                                'd-flex flex-column justify-content-center align-items-center'
                              )}
                              onClick={e => {
                                e.preventDefault();
                                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                  handleClickHome(data.title, 'mx_b_rooms', e);
                                }
                              }}>
                              {inputs.mx_b_rooms?.includes(data.title) && (
                                <>
                                  <img
                                    className={style.materialClick}
                                    src={
                                      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'
                                    }
                                    alt="tick"
                                  />
                                  <div className={style.overLayerRoom}></div>
                                </>
                              )}
                              <img
                                src={data.image}
                                style={{
                                  objectFit: 'contain',
                                  width: '50%',
                                  height: '50%'
                                }}
                                alt=""
                              />
                              <span className="p-2">{data.title}</span>
                            </div>
                          </div>
                        ))}
                      </div>

                      <h4
                        className={classnames(
                          'font-weight-800 mt-5',
                          artsError ? style.error1 : ' '
                        )}>
                        What are the art styles that speak to you the most{' '}
                      </h4>
                      <span style={{ fontSize: '15px' }}>Select a minimum of 3</span>
                      <div className="row" style={{ width: '85%' }}>
                        {arts.map((data, index) => (
                          <div className="col-4">
                            <div
                              id={`art-${index}`}
                              className={classnames(
                                style.ArtsBox,
                                'd-flex flex-column justify-content-center align-items-center'
                              )}
                              onClick={() => {
                                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                  if (inputs.mx_b_rooms && inputs.mx_b_rooms.length >= 1) {
                                    setCustomerRating(data, 'mx_b_artworks', 5);
                                  } else {
                                    setSnack(true);
                                    setItemError(true);
                                    setSnackMessage(
                                      'Please select atleast 1 item from items field'
                                    );
                                  }
                                } else {
                                  setSnack(true);
                                  setSnackMessage('The field cannot be editted');
                                }
                              }}>
                              <div style={{ position: 'relative' }}>
                                {inputs.mx_b_artworks.findIndex(x => x.title === data.title) !==
                                  -1 && (
                                  <>
                                    <img
                                      className={style.materialClick}
                                      src={
                                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'
                                      }
                                      alt="tick"
                                    />
                                    <div className={style.overLayerRoom}></div>
                                  </>
                                )}

                                <img
                                  style={{
                                    objectFit: 'contain',
                                    maxWidth: '100%'
                                  }}
                                  src={data.image}
                                  alt=""
                                />
                              </div>
                              <span
                                className={style.titleTxt}
                                style={{ display: 'flex', marginTop: '5px' }}>
                                {data.title}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <h4 className={classnames(' mt-5', materialsError ? style.error1 : ' ')}>
                        <span className="font-weight-800">
                          What materials would you like to see?*
                        </span>{' '}
                      </h4>
                      <span style={{ fontSize: '15px' }}>
                        Select a minimum of three items to curate your Mood Board.
                      </span>
                      <div className="row mt-4" style={{ width: '85%' }}>
                        {materials.map((data, index) => (
                          <div className="col-4">
                            <div
                              className={classnames(
                                style.ArtsBox,
                                'd-flex flex-column justify-content-center align-items-center'
                              )}
                              onClick={e => {
                                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                  if (inputs.mx_b_artworks && inputs.mx_b_artworks.length >= 3) {
                                    setCustomerRating(data, 'mx_b_materials', 5);
                                  } else {
                                    setArtsError(true);
                                    setSnack(true);
                                    setSnackMessage('Please fill atleast 3 arts images');
                                  }
                                } else {
                                  setSnack(true);
                                  setSnackMessage('The field cannot be editted');
                                }
                              }}>
                              <div style={{ position: 'relative' }}>
                                {inputs.mx_b_materials.findIndex(x => x.title === data.title) !==
                                  -1 && (
                                  <>
                                    <img
                                      className={style.materialClick}
                                      src={
                                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'
                                      }
                                      alt="tick"
                                    />
                                    <div className={style.overLayerRoom}></div>
                                  </>
                                )}
                                <img
                                  style={{
                                    objectFit: 'contain',
                                    maxWidth: '100%'
                                  }}
                                  src={data.image}
                                  alt=""
                                  className={style.imgMaterial}
                                />
                              </div>
                              <span
                                className={style.titleTxt}
                                style={{ display: 'flex', marginTop: '5px' }}>
                                {data.title}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>

                      <h4 className={classnames('mt-5', palletsError ? style.error1 : ' ')}>
                        <span className="font-weight-800">
                          {' '}
                          What colour palette make you feel more at home*
                        </span>{' '}
                      </h4>
                      <span style={{ fontSize: '15px' }}>
                        Select a minimum of three items to curate your Mood Board.
                      </span>
                      <div className="row" style={{ width: '85%' }}>
                        {palettes.map((data, index) => (
                          <div className="col-4">
                            <div
                              className={classnames(
                                style.ArtsBox,
                                'd-flex flex-column justify-content-center align-items-center'
                              )}
                              onClick={() => {
                                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                  if (inputs.mx_b_materials && inputs.mx_b_materials.length >= 3) {
                                    // setRating(true);
                                    setCustomerRating(data, 'mx_b_palettes', 5);
                                  } else {
                                    setMaterialsError(true);
                                    setSnack(true);
                                    setSnackMessage('Please fill atleast 3 materials images');
                                  }
                                } else {
                                  setSnack(true);
                                  setSnackMessage('The field cannot be editted');
                                }
                              }}>
                              <div style={{ position: 'relative' }}>
                                {inputs.mx_b_palettes.findIndex(x => x.title === data.title) !==
                                  -1 && (
                                  <>
                                    <img
                                      className={style.materialClick}
                                      src={
                                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'
                                      }
                                      alt="tick"
                                    />
                                    <div className={style.overLayerRoom}></div>
                                  </>
                                )}
                                <img
                                  style={{
                                    objectFit: 'contain',
                                    maxWidth: '100%'
                                  }}
                                  src={data.image}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <h4 className={classnames('mt-5', patternsError ? style.error1 : ' ')}>
                        <span className="font-weight-800">Choose all your favourite patterns*</span>
                      </h4>
                      <span style={{ fontSize: '15px' }}>
                        Select a minimum of three items to curate your Mood Board.
                      </span>
                      <div className="row" style={{ width: '85%' }}>
                        {patterns.map((data, index) => (
                          <div className="col-4">
                            <div
                              className={classnames(
                                style.ArtsBox,
                                'd-flex flex-column justify-content-center align-items-center'
                              )}
                              // style={{height:'250px'}}
                              onClick={e => {
                                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                  if (inputs.mx_b_palettes && inputs.mx_b_palettes.length >= 3) {
                                    handleClickHomePatterns(data, 'mx_b_patterns');
                                  } else {
                                    setPalletsError(true);
                                    setSnack(true);
                                    setSnackMessage('Please fill atleast 3 pallets images');
                                  }
                                }
                              }}>
                              <div style={{ position: 'relative' }}>
                                {inputs.mx_b_patterns.findIndex(x => x.title === data.title) !==
                                  -1 && (
                                  <>
                                    <img
                                      className={style.materialClick}
                                      src={
                                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'
                                      }
                                      alt="tick"
                                    />
                                    <div className={style.overLayerRoom}></div>
                                  </>
                                )}
                                <img
                                  style={{
                                    objectFit: 'contain',
                                    maxWidth: '100%',
                                    width: '100%',
                                    height: '100%'
                                  }}
                                  src={data.image}
                                  alt=""
                                  className={style.oriImg}
                                />
                                {/* <div className={style.hoverImgs}> */}
                                <img
                                  style={{
                                    objectFit: 'contain',
                                    maxWidth: '100%',
                                    width: '100%',
                                    height: '100%'
                                  }}
                                  src={data.hoverImg}
                                  alt=""
                                  className={style.hoverImg}
                                />
                              </div>
                              {/* </div> */}
                              {/* <span>{data.title}</span> */}
                              <span
                                className={style.titleTxt}
                                style={{ display: 'flex', marginTop: '5px' }}>
                                {data.title}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>

                      <h4 className="mt-5">
                        <span className="font-weight-800">
                          {' '}
                          Is there anything in particular you’d like in your design?
                        </span>
                      </h4>
                      <span style={{ fontSize: '15px' }}>
                        Eg: I'd like to have a study table designed with a wooden top and table
                        lamps in my living room.
                      </span>
                      <div className={style.containerText}>
                        <textarea
                          className={style.textArea}
                          style={{ height: '20vh', padding: '15px' }}
                          name="design"
                          onChange={e => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET'))
                              handleInputs(e);
                          }}
                          value={
                            inputs.design && inputs.design.text ? inputs.design.text : ''
                          }></textarea>
                        <Icon.MicFill
                          className={style.microphoneIco1}
                          onClick={() => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              setCurrentField('design');
                              setAudioModal(true);
                              setblobURL('');
                            }
                          }}></Icon.MicFill>
                        {inputs.design &&
                          inputs.design.audio &&
                          !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                            <Icon.Trash
                              className={style.Trash}
                              onClick={() => {
                                inputs.design.audio = '';
                                storeMxB(removeAudio('design'));
                                setInputs({ ...inputs });
                              }}></Icon.Trash>
                          )}
                        {inputs.design && inputs.design.audio && (
                          <ReactAudioPlayer
                            name="design"
                            className={style.audio}
                            src={inputs.design && inputs.design.audio ? inputs.design.audio : ''}
                            controls
                          />
                        )}
                      </div>

                      <h4
                        className={classnames('', dimesnionsError ? style.error1 : ' ')}
                        style={{ marginTop: '100px' }}>
                        <span className="font-weight-800"> What are your room’s dimensions?*</span>
                        {uploadError.upload ? (
                          <span style={{ fontSize: 10, color: 'red' }}>{uploadError.upload}</span>
                        ) : null}
                      </h4>
                      <span style={{ fontSize: '15px' }}>
                        The exact measurements will help us design your room to the' T'
                      </span>
                      <div className="row col-6 mt-4">
                        <div className="col-3">
                          <p style={{ fontSize: '13px' }}>Length</p>
                          <input
                            type="number"
                            style={{ width: '100%', height: '50px' }}
                            placeholder="Ft"
                            name="length"
                            value={
                              inputs.dimensions && inputs.dimensions.length
                                ? inputs.dimensions.length
                                : ''
                            }
                            onChange={e => {
                              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                setInputs({
                                  ...inputs,
                                  dimensions: {
                                    ...inputs.dimensions,
                                    length: e.target.value,
                                    file: dimensionFile
                                  }
                                });
                                storeMxB(
                                  reduxValue('MX_B_DIMENSIONS', {
                                    ...inputs.dimensions,
                                    width: e.target.value,
                                    file: dimensionFile
                                  })
                                );
                                // storeMxB({ ...questionnaireData.MxBData, MX_B_DIMENSIONS: { ...questionnaireData.MxBData.MX_B_DIMENSIONS, length: e.target.value } })
                              }
                            }}
                          />
                        </div>
                        <div className="col-3">
                          <p style={{ fontSize: '13px' }}>Width</p>
                          <input
                            type="number"
                            style={{ width: '100%', height: '50px' }}
                            placeholder="Ft"
                            name="width"
                            value={
                              inputs.dimensions && inputs.dimensions.width
                                ? inputs.dimensions.width
                                : ''
                            }
                            onChange={e => {
                              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                setInputs({
                                  ...inputs,
                                  dimensions: {
                                    ...inputs.dimensions,
                                    width: e.target.value,
                                    file: dimensionFile
                                  }
                                });
                                storeMxB(
                                  reduxValue('MX_B_DIMENSIONS', {
                                    ...inputs.dimensions,
                                    width: e.target.value,
                                    file: dimensionFile
                                  })
                                );
                                // storeMxB({ ...questionnaireData.MxBData, MX_B_DIMENSIONS: { ...questionnaireData.MxBData.MX_B_DIMENSIONS, width: e.target.value } })
                              }
                            }}
                          />
                        </div>
                        <div className="col-3">
                          <p style={{ fontSize: '12px' }}>Ceiling Height</p>
                          <input
                            type="number"
                            style={{ width: '100%', height: '50px' }}
                            placeholder="Ft"
                            name="ceiling_height"
                            value={
                              inputs.dimensions && inputs.dimensions.ceiling_height
                                ? inputs.dimensions.ceiling_height
                                : ''
                            }
                            onChange={e => {
                              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                setInputs({
                                  ...inputs,
                                  dimensions: {
                                    ...inputs.dimensions,
                                    ceiling_height: e.target.value,
                                    file: dimensionFile
                                  }
                                });
                                storeMxB(
                                  reduxValue('MX_B_DIMENSIONS', {
                                    ...inputs.dimensions,
                                    ceiling_height: e.target.value,
                                    file: dimensionFile
                                  })
                                );
                                // storeMxB({ ...questionnaireData.MxBData, MX_B_DIMENSIONS: { ...questionnaireData.MxBData.MX_B_DIMENSIONS, ceiling_height: e.target.value } })
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-4 col-12 d-flex flex-column justify-content-center">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          style={{ maxWidth: 200 }}>
                          <p
                            style={{
                              fontSize: '15px',
                              margin: '5px 0px',
                              fontWeight: 600
                            }}>
                            Upload floor plan file
                          </p>
                          <button
                            className={style.attachFileButton}
                            onClick={() => {
                              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                fileInputRef.current.click();
                              }
                            }}>
                            <AddIcon />
                            Attach File
                          </button>
                        </div>
                        <input
                          onChange={e => {
                            handleChangeFile(e);
                          }}
                          multiple={false}
                          ref={fileInputRef}
                          accept="application/pdf, image/*"
                          type="file"
                          style={{ display: 'none' }}
                        />
                        <span className="pl-3 ml-3">{dimensionFile.name}</span>
                      </div>

                      {/* <div className="row d-flex justify-content-start align-items-start p-0 m-0"> */}
                      {/* <span
  style={{
    textDecoration: "underline",
    fontSize: "16px",
    marginTop: "8px",
    float: 'left',
    paddingLeft: '16px'
  }}
>
  Use AR App to Measure
</span> */}
                      {/* </div> */}
                      <h4 style={{ marginTop: '100px' }}>
                        Any occupants we should keep in mind while designing?
                      </h4>
                      <div className="row mt-5">
                        <div className="col-2 d-flex flex-row align-items-baseline">
                          <input
                            className="mt-1 mr-3"
                            type="checkbox"
                            checked={inputs.occupants.includes('elderly')}
                            onChange={e => {
                              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                handleClickHome('elderly', 'occupants');
                                // storeMxB({ ...questionnaireData.MxBData, MX_B_OCCUPANTS: 'elderly' })
                              }
                            }}
                          />
                          <h5 style={{ fontSize: '17px' }}>Elderly</h5>
                        </div>
                        <div className="col-3 d-flex flex-row align-items-baseline">
                          <input
                            className="mt-1 mr-3"
                            type="checkbox"
                            checked={inputs.occupants.includes('wheelchair')}
                            onChange={e => {
                              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                handleClickHome('wheelchair', 'occupants');
                                // storeMxB({ ...questionnaireData.MxBData, MX_B_OCCUPANTS: 'wheelchair' })
                              }
                            }}
                          />
                          <h5 style={{ fontSize: '17px' }}>Wheelchair-access</h5>
                        </div>
                        <div className="col-2 d-flex flex-row align-items-baseline">
                          <input
                            className="mt-1 mr-3"
                            type="checkbox"
                            checked={inputs.occupants.includes('pets')}
                            onChange={e => {
                              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                handleClickHome('pets', 'occupants');
                                // storeMxB({ ...questionnaireData.MxBData, MX_B_OCCUPANTS: 'pets' })
                              }
                            }}
                          />
                          <h5 style={{ fontSize: '17px' }}>Pets</h5>
                        </div>
                        <div className="col-5 d-flex flex-row align-items-baseline">
                          <input
                            className="mt-1 mr-3"
                            type="checkbox"
                            checked={inputs.occupants.includes('children')}
                            onChange={e => {
                              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                handleClickHome('children', 'occupants');
                                // storeMxB({ ...questionnaireData.MxBData, MX_B_OCCUPANTS: 'children' })
                              }
                            }}
                          />
                          <h5 style={{ fontSize: '17px' }}>Small children (under 10 years)</h5>
                        </div>
                        <div className="col-4" />
                      </div>

                      <h4 ref={budgetRef} className="font-weight-800 mt-4">
                        You’re nearly done! Let’s discuss your budget.
                      </h4>
                      <button
                        className={style.saveButton}
                        onClick={() => {
                          getBudgetCTA();
                        }}>
                        Go to Budget
                      </button>
                    </div>
                  )}

                  {activeTab === 'Your Budget' && (
                    <div className={classnames(' col-10', style.container1)}>
                      <h4 style={{ marginTop: '40px' }}>
                        {' '}
                        <span
                          className={classnames(
                            'font-weight-800',
                            budgetError ? style.error1 : ' '
                          )}>
                          {' '}
                          Before we get into specifics, what’s your tentative budget?*
                        </span>
                      </h4>
                      <small>
                        This range gives us a framework to work within and guide our shopping
                        decisions. You can modify the amount whenever if needed.
                      </small>
                      <div className="d-flex flex-row w-50 justify-content-between mt-5">
                        <p>Min: &#8377; 0</p>
                        <p>Max :&#8377; 10,00,000</p>
                      </div>

                      <div className="d-flex flex-row" style={{ width: '70%' }}>
                        <PrettoSlider
                          // valueLabelDisplay="auto"
                          aria-label="pretto slider"
                          min={0}
                          max={1000000}
                          value={inputs.tentative_budget}
                          type="number"
                          name="tentative_budget"
                          onChange={(event, value) => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              setInputs({ ...inputs, tentative_budget: value });
                              storeMxB(reduxValue('tentative_budget', value));
                            } else {
                              setSnack(true);
                              setSnackMessage('The field cannot be editted');
                            }
                          }}
                        />
                        <span style={{ margin: '0 10px 0 20px', fontSize: '18px' }}>&#8377;</span>
                        <input
                          type="text"
                          style={{ width: '150px' }}
                          className={classnames(
                            'justify-content-center pl-5',
                            budgetError ? style.priceButton1 : style.priceButton
                          )}
                          placeholder="&#x20B9; 0"
                          onChange={(e, value) => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              if (e.target.value < 1000000 && e.target.value > 0) {
                                setInputs({
                                  ...inputs,
                                  tentative_budget: e.target.value
                                });

                                storeMxB(reduxValue('tentative_budget', e.target.value));
                              } else {
                                setSnack(true);
                                setSnackMessage('Please enter a value between 0 and 1000000');
                              }
                            }
                          }}
                          value={
                            // inputs.tentative_budget !== 0 &&
                            new Intl.NumberFormat('en-IN').format(inputs.tentative_budget)
                          }
                          disabled
                        />
                      </div>

                      <h4 className="mt-5 d-none">
                        <span className="font-weight-800">
                          {' '}
                          Would you like to tell us something more about your budget?{' '}
                        </span>
                        (optional)
                      </h4>
                      <div className={style.containerText} style={{ display: 'none' }}>
                        <textarea
                          className={style.textArea}
                          style={{ padding: '15px' }}
                          type="number"
                          name="budget"
                          value={inputs.budget && inputs.budget.text ? inputs.budget.text : ''}
                          onChange={e => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              handleInputs(e);
                            }
                          }}></textarea>
                        <Icon.MicFill
                          className={style.microphoneIco1}
                          onClick={() => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              setCurrentField('budget');
                              setAudioModal(true);
                              setblobURL('');
                            }
                          }}></Icon.MicFill>
                        {inputs.budget &&
                          inputs.budget.audio &&
                          !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                            <Icon.Trash
                              className={style.Trash}
                              onClick={() => {
                                inputs.budget.audio = '';
                                storeMxB(removeAudio('budget'));
                                setInputs({ ...inputs });
                              }}></Icon.Trash>
                          )}
                        {inputs.budget && inputs.budget.audio && (
                          <ReactAudioPlayer
                            name="budget"
                            className={style.audio}
                            src={inputs.budget && inputs.budget.audio ? inputs.budget.audio : ''}
                            controls
                          />
                        )}
                      </div>

                      <button
                        className={style.saveButton}
                        onClick={() => {
                          getContactCTA();
                        }}>
                        Go to Contact
                      </button>
                    </div>
                  )}
                  {activeTab === 'Your Contact' && (
                    <div className={classnames(' col-10', style.container1)}>
                      <h4 style={{ marginTop: '40px' }}>
                        Please leave us with your Contact Information
                      </h4>
                      <small>
                        Our designer will use this number to personally reach out to you. We prefer
                        a WhatsApp number if you have one{' '}
                      </small>
                      <h6 className="mt-5">Enter Phone Number</h6>
                      <div className="row col-6 mt-4">
                        <PhoneInput
                          countryCodeEditable={false}
                          country={'in'}
                          value={inputs.phone_number}
                          name="phone_number"
                          onChange={(value, e) => {
                            if (
                              !projectQuestionnaire.hasOwnProperty('MX_B_PHONE_NUMBER') &&
                              !projectQuestionnaire.hasOwnProperty('MX_B_COUNTRY_CODE')
                              // &&
                              // isPossiblePhoneNumber('+'+value, e.countryCode.toUpperCase()) === true
                            ) {
                              setInputs({
                                ...inputs,
                                phone_number: value,
                                country_code: e.dialCode
                              });
                              storeMxB(
                                reduxValue('phone_number', value, 'country_code', e.dialCode)
                              );
                            }
                            // else {
                            //   setSnack(true);
                            //   setSnackMessage("Enter a valid phone numbersss");
                            // }
                          }}
                          // value={this.state.phone}
                          // onChange={phone => this.setState({ phone })}
                        />

                        {/* <TextField
                            variant="outlined"
                            className="col-6"

                            type="number"
                            style={{ width: "50%", height: "50px" }}
                            
                           name="phone_number"
                         
                            value={
                              inputs.phone_number
                              
                            }
                            onChange={(e, value) => {
                              if (
                                !projectQuestionnaire.hasOwnProperty(
                                  "MX_B_PHONE_NUMBER"
                                )&&
                                (  e.target.value.length<=10)
                              ) {
                                setInputs({
                                  ...inputs,
                                  phone_number: e.target.value,
                                });
                                storeMxB(
                                  reduxValue("phone_number", e.target.value)
                                )
                              } else {
                                setSnack(true);
                                setSnackMessage("Enter a valid phone numberss");
                              }
                            }}
                           
                          /> */}
                      </div>

                      <h4 style={{ marginTop: '100px' }}>Yay! Everything looks good! </h4>
                      <h4 className="font-weight-800">
                        Now kick back and relax while we get to work!
                      </h4>
                      <button
                        className={style.saveButton}
                        type="submit"
                        onClick={e => {
                          // if (
                          //   isPossiblePhoneNumber('+'+inputs.phone_number) === true
                          // inputs.phone_number&& inputs.phone_number.length>=12

                          // ) {
                          handleSubmit(e);
                          // } else {
                          //   setPhoneNumberError(true);
                          //   window.scrollTo(0, 0);
                          //   setSnack(true);
                          //   setSnackMessage("Enter Valid Phone Number");
                          // }
                        }}>
                        Begin my project
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <Modal
                className={style.modal}
                aria-labelledby="simple-modal-title"
                onClose={() => setModal(0)}
                aria-describedby="simple-modal-description"
                open={modal === 1}>
                <div className={style.modalContainer}>
                  <div className={style.modal_container}>
                    <h6
                      style={{
                        width: '70%',
                        textAlign: 'center',
                        marginTop: '3%',
                        fontSize: 'small'
                      }}>
                      Please keep in mind that each step of the design process is final. Once you
                      finalize a particular section, you cannot return to make changes later.
                    </h6>
                  </div>
                  <h4 className="mt-4 font-weight-800">Things you will need</h4>
                  <div
                    className="d-flex flex-row justify-content-around align-items  mt-4"
                    style={{ width: '80%' }}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <img
                        style={{ width: '25%' }}
                        src={
                          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+2984.svg'
                        }
                        alt=""
                      />
                      <p>Smart Phone</p>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <img
                        style={{ width: '35%' }}
                        src={
                          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/noun_Ruler_2257287.svg'
                        }
                        alt=""
                      />
                      <p>Measuring Tape</p>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <img
                        style={{ width: '30%' }}
                        src={
                          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+2244.svg'
                        }
                        alt=""
                      />
                      <p>Inspirations</p>
                    </div>
                  </div>
                  <button
                    style={{ marginTop: '2%', marginBottom: '5%' }}
                    className={style.wishlistButton}
                    onClick={() => setModal(0)}>
                    Get Started
                  </button>
                </div>
              </Modal>
              <Modal
                className={style.modalAnnotate}
                aria-labelledby="simple-modal-title"
                onClose={() => setModal(0)}
                aria-describedby="simple-modal-description"
                open={modal === 2}>
                <div className={style.modalContainer1}>
                  <div
                    className="d-flex justify-content-between align-items-center w-100 p-2"
                    style={{
                      borderBottom: '1px solid rgba(0,0,0,0.2)',
                      backgroundColor: '#fff'
                    }}>
                    <h5 className="m-0 p-0">COMMENTS</h5>
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                      }
                      onClick={() => setModal(0)}
                      alt=""
                    />
                  </div>
                  <div className="row w-100">
                    <div className="col-9 m-0 p-0">
                      {currentSpaceImage.fileType === 'png' ||
                      currentSpaceImage.fileType === 'jpeg' ||
                      currentSpaceImage.fileType === 'jpg' ? (
                        <>
                          <div
                            className="d-flex justify-content-start align-items-center m-3"
                            style={{ height: '5vh' }}>
                            <img
                              src={
                                'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3157.svg'
                              }
                              atl=""
                            />
                            <p className="m-0 ml-2">Markup</p>
                          </div>

                          <div className={classnames('p-2', style.dot)}>
                            <Annotation
                              src={currentSpaceImage.img}
                              // className={style.annotateImg}
                              alt="space"
                              onClick={() => {
                                if (projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                                  setModal(0);
                                }
                              }}
                              annotations={
                                currentSpaceImage.hasOwnProperty('annotations')
                                  ? currentSpaceImage.annotations
                                  : annotations
                              }
                              onAnnotationsUpdate={setAnnotations}
                              allowedShapes={['Oval', 'Rectangle']}
                            />
                          </div>
                        </>
                      ) : (
                        /* <ReactPictureAnnotation
                      image={currentSpaceImage.img}
                      onSelect={onSelect}
                      onChange={onChange}
                    width={pageSize.width}
                    height={pageSize.height}
                    /> :
                    <Annotation
                      src={currentSpaceImage.img}
                      alt='Two pebbles anthropomorphized holding hands'
   
                      annotations={annotateState.annotations}
   
                      type={annotateState.type}
                      value={annotateState.annotation}
                      onChange={onChange}
                      onSubmit={onSubmit}
                      allowTouch
                    />
                    :
                    <ReactImageAnnotate
                      labelImages
                      regionClsList={["Alpha", "Beta", "Charlie", "Delta"]}
                      regionTagList={["tag1", "tag2", "tag3"]}
                      images={[
                        {
                          src: "https://placekitten.com/408/287",
                          name: "Image 1",
                          regions: []
                        }
                      ]}
                    /> :
                     <img style={{ width: '100%', margin: '10px' }} src={currentSpaceImage.img} alt='' /> : */
                        <ReactPlayer
                          url={currentSpaceImage.img}
                          style={{ margin: '10px' }}
                          width="100%"
                          height="75%"
                          controls
                        />
                      )}
                    </div>
                    <div className="col-3" style={{ background: '#ffffff' }}>
                      <div
                        style={{ paddingTop: '100px' }}
                        className="w-100 d-flex justify-content-end align-items-end flex-column">
                        <textarea
                          style={{
                            width: '100%',
                            borderRadius: '10px',
                            height: '215px',
                            padding: '15px'
                          }}
                          placeholder="Please add your comment here "
                          onChange={e => {
                            if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                              setSpaceComment(e.target.value);
                            }
                          }}
                          value={
                            currentSpaceImage.hasOwnProperty('comment')
                              ? currentSpaceImage.comment
                              : spaceComment
                          }></textarea>
                        <button
                          className={style.button}
                          style={{ width: '100px', marginTop: '20px' }}
                          onClick={() => {
                            submitSpaceComment();
                            setModal(0);
                          }}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                className={style.modal}
                aria-labelledby="simple-modal-title"
                onClose={() => setAudioModal(false)}
                aria-describedby="simple-modal-description"
                open={audioModal === true}>
                <div className={style.modalContainer} style={{ paddingTop: '10px' }}>
                  <div className="col-12 d-flex justify-content-end">
                    <img
                      src={
                        'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                      }
                      onClick={() => {
                        stopTimer();
                        setrecordstate(false);
                        //setAudioRecorded(false);
                        setAudioModal(false);
                      }}
                      alt=""
                    />
                  </div>
                  <AudioReactRecorder
                    canvasHeight={startAudio ? 200 : 0}
                    canvasWidth={startAudio ? 300 : 0}
                    className={style.audioContainer}
                    state={recordstate}
                    onStop={onStop}
                  />
                  <div className={classnames('row d-flex justify-content-center')}>
                    <Box className="pl-5 pr-5 pt-2 pb-2 " style={{ backgroundColor: 'grey' }}>
                      <span className="minute">{minute}</span>
                      <span>:</span>
                      <span className="second">{second}</span>
                    </Box>
                  </div>
                  <div className="row ">
                    <div className="col-4 d-flex justify-content-start p-3">
                      <button
                        style={{ marginTop: '2%' }}
                        className={classnames(style.wishlistButton, '')}
                        onClick={() => {
                          setIsActive(true);
                          start();
                        }}>
                        Start
                      </button>
                      <br></br>
                    </div>
                    <div className="col-4 d-flex justify-content-end p-3">
                      <button
                        style={{ marginTop: '2%' }}
                        className={classnames(style.wishlistButton, '')}
                        onClick={() => {
                          setIsActive(false);
                          stop();
                        }}>
                        Stop
                      </button>
                      <br></br>
                    </div>
                    <div className="col-4 d-flex justify-content-end p-3">
                      <button
                        style={{ marginTop: '2%' }}
                        className={classnames(style.wishlistButton, '')}
                        onClick={() => {
                          stopTimer();
                          setIsActive(true);
                          reset();
                        }}>
                        Reset
                      </button>
                      <br></br>
                    </div>
                  </div>
                  <ReactAudioPlayer src={blobURL} controls />
                </div>
              </Modal>
            </>
          )}

          {dimension[0] < 600 && <RoomProfileXl id={id} />}
        </>
      )}
    </>
  );
};

RoomProfile.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    rooms: state.questionnaireData.data,
    questionnaireData: state.questionnaireData
  };
};
export default connect(mapStateToProps, { storeMxB })(withRouter(RoomProfile));
function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
