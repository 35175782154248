import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import { useParams } from 'react-router-dom';
import './OrderConfirmation.css';
import * as Icon from 'react-bootstrap-icons';
import getCartDataCount from '../../utils/CartDetails';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { storeCartCount } from '../../store/actions/sampleaction';

const OrderConfirmation = ({ history, auth, storeCartCount }) => {
  const { id } = useParams();
  const [email, setemail] = useState('');
  const [order_id, setorder_id] = useState('');

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.email) {
        setemail(history.location.state.email);
      }
      if (history.location.state.order_id) {
        setorder_id(history.location.state.order_id);
      }
    }
    const getCartCount = async () => {
      storeCartCount(await getCartDataCount(auth));
    };
    getCartCount();
  }, []);
  return (
    <div style={{ overflow: 'hidden' }}>
      <Header />
      <>
        <div className="backConfirm">
          <div className="content" style={{ paddingTop: '125px', paddingBottom: '15%' }}>
            <div>
              <div className="check d-flex justify-content-center align-items-center flex-colum">
                {' '}
                <Icon.CheckCircle size="25" />
              </div>
              <h3 className="thanks mt-3 d-flex justify-content-center align-items-center flex-colum">
                Thanks for placing an order
              </h3>
            </div>
            <div className="placeOrder">
              <h5 className="email mt-3 d-flex justify-content-center align-items-center flex-colum">
                {' '}
                Order Number: {order_id}
              </h5>
              <h5 className="email mt-3 d-flex justify-content-center align-items-center flex-colum">
                We have emailed your order to {email}
              </h5>
              <h5 className="email mt-2 d-flex justify-content-center align-items-center flex-colum">
                For further enquires contact us at: info@pixelandmortar.com
              </h5>
            </div>
            <div className="row mb-2">
              <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-lg-end align-items-lg-end justify-content-md-end align-items-md-end justify-content-center">
                <button
                  className="order"
                  onClick={() => {
                    history.push({
                      pathname: `/orders/${id}`,
                      state: { redirectFrom: 'order-confirmation', orderId: id, order_id }
                    });
                  }}>
                  View Order
                </button>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-lg-start align-items-lg-start justify-content-md-start align-items-md-start justify-content-center">
                <button
                  className="continue"
                  onClick={() => {
                    history.push('/shop');
                  }}>
                  Continue Shopping
                </button>
              </div>
              <div className="row mt-5">
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/order-confirmation/order-static.png"
                  style={{ width: '98%', height: '130%' }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(OrderConfirmation));
