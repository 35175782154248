import React, { useLayoutEffect, useEffect, useState } from 'react';
import classnames from 'classnames';
import style from './newPackage.module.css';
import image1 from '../../assets/Group 6489@2x.png';
import image2 from '../../assets/Group 6495@2x.png';
import image3 from '../../assets/Group 6491@2x.png';
import image4 from '../../assets/Group 6494@2x.png';
import image5 from '../../assets/Group 6493@2x.png';

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    // ConsoleHelper(screenSize);
  }, [screenSize]);

  return screenSize;
}

const NewPackage = () => {
  const dimension = useMediaQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const priceData = [
    {
      id: 1,
      image: image1,
      title: 'Personal Designer',
      content: 'Get appointed a dedicated designer with web and phone support.'
    },
    {
      id: 2,
      image: image2,
      title: 'Custom Interior Designs',
      content:
        'Every interior design concept is individually tailored to your needs. (upto 3 different styles and revisions)'
    },
    {
      id: 3,
      image: image3,
      title: 'View in AR',
      subtitle: '(coming soon)',
      content: 'View your desired design in AR to see what your new interiors would look like.'
    },
    {
      id: 4,
      image: image4,
      title: 'Special Discounts',
      content: 'Every Pixel & Mortar member gets exclusive discounts on furniture.'
    },
    {
      id: 5,
      image: image5,
      title: 'Even more coming soon',
      content:
        "We're constantly working on providing more value with additional add-on services and features."
    }
  ];

  return (
    <>
      <div
        className={classnames(
          'container-fluid d-flex flex-column align-items-center justify-content-center'
        )}>
        <div
          className={classnames(
            'row d-flex flex-row align-items-start justify-content-center mt-2',
            style.packages
          )}
          style={{ width: dimension[0] > 500 ? '80%' : '100%' }}>
          <h5 className={style.included}>What included?</h5>

          {priceData?.map((item, index) => (
            <div
              key={`pricedata_${index}`}
              className={classnames(
                'cardss d-flex align-items-center justify-content-center text-center mt-3',
                style.cardss
              )}>
              <div
                className={classnames('card', style.card)}
                // style={{ borderTop: `7px solid ${d.borderColor}` }}
                // onMouseEnter={() => setCardHover(d.title)}
                // onMouseLeave={() => setCardHover("")}
              >
                <img src={item.image} className={style.images} alt="" />
                <h4 className={style.title}>{item.title}</h4>
                <p className={style.content}>{item.content}</p>
              </div>
            </div>
          ))}

          {/* {data?.map((d) => (
            <div className="col-md-4 col-sm-12 d-flex align-items-center justify-content-center text-center mt-3">
              <div
                className={classnames("card", style.card)}
                style={{ borderTop: `7px solid ${d.borderColor}` }}
                onMouseEnter={() => setCardHover(d.title)}
                onMouseLeave={() => setCardHover("")}
              >
                <div
                  className={classnames(
                    "card-title mt-5 mb-2",
                    style.card_title
                  )}
                >
                  <div
                    className={classnames(
                      d.title == cardHover ? `${style[cardHover]}` : ""
                    )}
                  >
                    {d.title}
                  </div>
                </div>
                <div className="card-body d-flex flex-column justify-content-start align-items-center p-0">
                  <span className={style.price}>{d.price}</span>
                  {dimension[0] > 500 && <br></br>}
                  <div
                    style={{ height: "7vh", width: "80%" }}
                    className={classnames(style.cardSubText, "mb-4")}
                  >
                    <span className={classnames(style.sub, "mt-5")}>
                      {d.sub}
                    </span>
                  </div>
                  <Link to={{ pathname: "/questionnaire" }}>
                    <button className={classnames(style.button, "mt-3")}>
                      Start your project
                    </button>
                  </Link>

                  <div
                    className="mt-5"
                    style={{
                      maxWidth: "100%",
                      width: "90%",
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    {d.description.map((des, i) => (
                      <div>
                        <p
                          className="mt-2"
                          style={{ fontSize: "12px", fontWeight: 900 }}
                        >
                          {des}
                        </p>
                        {d.description.length - 1 !== i && (
                          <div
                            style={{
                              width: "100%",
                              borderWidth: "0.5px",
                              opacity: 0.1,
                              borderStyle: "solid",
                              borderColor: "#000000",
                            }}
                          ></div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>

        {/* 
<div className="d-flex justify-content-center align-items-start w-100 mb-5">
<Link to={{ pathname: "/questionnaire" }}>
                <button
                  className={style.designButton}
                  onClick={() => {
                    history.push("/questionnaire");
                  }}
                  style={{fontWeight: 700}}
                >
                 Get Started
                </button>
                </Link>
              </div>
               */}
      </div>
    </>
  );
};

export default NewPackage;
