import { baseUrl } from '../../config/config';
import axios from 'axios';

export const sampleFunc = () => async (dispatch) => {
  dispatch({ type: 'SAMPLE_DATA', payload: 'simple function' });
};

export const questionnaire = (data) => (dispatch) => {
  dispatch({ type: 'SET_QUESTIONNAIRE', payload: data });
};

export const post_questionnaire = (data) => (dispatch) => {
  localStorage.setItem('MX-A', JSON.stringify(data));
  dispatch({
    type: 'POST_QUESTIONNAIRE',
    payload: data
  });
};

export const reset_answers = () => (dispatch) => {
  dispatch({
    type: 'RESET_ANSWERS'
  });
};

export const page = (data) => (dispatch) => {
  dispatch({
    type: 'PAGE_COUNT',
    payload: data
  });
};

export const page2 = (data) => (dispatch) => {
  dispatch({
    type: 'PAGE_COUNT2',
    payload: data
  });
};

export const storeMx = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_MX',
    payload: data
  });
};

export const storeMxB = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_MXB',
    payload: data
  });
};

export const storeTab = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_TAB',
    payload: data
  });
};
export const storeMxAns = (data) => (dispatch) => {
  dispatch({
    type: 'MX_ANS',
    payload: data
  });
};

export const storeMxSkip = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_MxSkip',
    payload: data
  });
};
export const storeMxRetake = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_MxRetake',
    payload: data
  });
};

export const fetchQuestionnaire = () => (dispatch) => {
  axios.get(`${baseUrl}/ping`);
};

export const postDate = (date) => (dispatch) => {
  dispatch({
    type: 'POST_DATE',
    payload: date
  });
};

export const set_explore_filter = (data) => (dispatch) => {
  dispatch({
    type: 'EXPLORE_FILTER',
    payload: data
  });
};

export const storePackage = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_PACKAGE',
    payload: data
  });
};

export const storeCartCount = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_CART_COUNT',
    payload: data
  });
};

export const storeMoodboardProduct = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_MOODBOARD_PRODUCT',
    payload: data
  });
};

export const storeMoodBoardData = (data) => (dispatch) => {
  dispatch({
    type: 'STORE_MOODBOARD_DATA',
    payload: data
  });
};
