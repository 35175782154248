export const cancelReasons = [
  'Layout is off',
  'Furniture doesn’t work ',
  'Doesn’t match my style',
  'Colours can be better',
  'Not practical'
];

export const overallTags = [
  {
    star: 1,
    tags: ['Too many steps', 'Poor customer support', 'Lacked attention']
  },
  {
    star: 2,
    tags: ['Lacked attention', 'Platform was slow', 'Poor customer support']
  },
  {
    star: 3,
    tags: [
      'Affordable Products',
      'Affordable Package',
      'Loved the process',
      'Lacked attention',
      'Design made easy',
      'Great features'
    ]
  },
  {
    star: 4,
    tags: [
      'Loved the process',
      'Great deals',
      'Quick Turnaround',
      'Affordable Products',
      'Affordable Package'
    ]
  },
  {
    star: 5,
    tags: [
      'Great features',
      'Design made easy',
      'Good Customer Support',
      'Quick Turnaround',
      'Loved the process',
      'Great deals'
    ]
  }
];

export const designerTags = [
  {
    star: 1,
    tags: [
      'Not a fan',
      'Failed to understand needs',
      'Poor taste',
      'Poor communication',
      'Quick response',
      'Good taste '
    ]
  },
  {
    star: 2,
    tags: [
      'Rude',
      'Not a fan',
      'Failed to understand needs',
      'Poor communication',
      'Quick response',
      'good taste '
    ]
  },
  {
    star: 3,
    tags: [
      'Impatient',
      'Poor communication',
      'Great suggestions',
      'Quick responses',
      'Good Taste',
      'Needs a little patience'
    ]
  },
  {
    star: 4,
    tags: [
      'Great suggestions',
      'Quick responses',
      'Good Taste',
      'Needs a little patience',
      'Needs Better communication',
      'Cordial',
      'Meticulous, Polite',
      'Great suggestions'
    ]
  },
  {
    star: 5,
    tags: [
      'Diligent',
      'Quick turn-around',
      'Cordial',
      'Meticulous',
      'Polite',
      'Great suggestions',
      'Quick responses',
      'Good Taste'
    ]
  }
];

export const enhancedFeatures = [
  'Personal Ordering Team',
  'Shoppable 3D views of layout',
  'Custom floor plan'
];
