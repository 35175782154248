import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { registerUser, loginUser, clearError } from '../../store/actions/authapi';
import { toast } from 'react-toastify';
import { storeMxAns } from '../../store/actions/sampleaction';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { withRouter } from 'react-router-dom';
import ConsoleHelper from '../../utils/ConsoleHelper';

function AuthPasswordless({
  history,

  location,
  questionnaireData
}) {
  const { deviceId, preAuthSessionId, redirectLink } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      deviceId: deviceId.replaceAll('%2F', '/'),
      preAuthSessionId: preAuthSessionId.replaceAll('%2F', '/'),
      userInputCode: '123abcd'
    };
    let redirectUrl = redirectLink.replaceAll('%2F', '/');
    ConsoleHelper(data);
    ConsoleHelper(redirectUrl);
    handlePasswordlessSignin(data, redirectUrl);
  }, []);

  async function handlePasswordlessSignin(data, redirectUrl) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/signinup/code/consume`,
        data
      );
      if (
        response.data.status &&
        response.data.status === 'OK' &&
        response.data.user &&
        response.data.user.data.message == 'Success'
      ) {
        let mx = {};
        let redirectedFrom = false;
        let mxAnsData = {};
        let redirect = JSON.parse(localStorage.getItem('RedirectToLogin'));
        let customRes = {
          data: {
            message: response.data.user.data.message,
            token: response.data.user.data.token,
            user: response.data.user.data.user
          }
        };
        if (
          (redirect && redirect === true) ||
          (questionnaireData &&
            questionnaireData.hasOwnProperty('MxAns') &&
            questionnaireData.MxAns.hasOwnProperty('questionnaire') &&
            questionnaireData.MxAns.questionnaire.hasOwnProperty('purchased') &&
            questionnaireData.MxAns.questionnaire.purchased === false)
        ) {
          if (questionnaireData && questionnaireData.hasOwnProperty('MxAns')) {
            mx = { ...questionnaireData.MxAns };
            redirectedFrom = true;
            mxAnsData = { ...mx };
            handlePasswordlessLogin(
              history,
              location.state,
              redirectedFrom,
              mxAnsData,
              customRes,
              redirectUrl
            );
          }
        } else {
          handlePasswordlessLogin(
            history,
            location.state,
            redirectedFrom,
            mxAnsData,
            customRes,
            redirectUrl
          );
        }
      } else {
        // ConsoleHelper(response);
        window.alert('Something Went Wrong');
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  const handlePasswordlessLogin = async (
    history,
    redirect,
    redirectedFrom,
    mxAnsData,
    res,
    redirectUrl
  ) => {
    //ConsoleHelper(res);
    try {
      let res1;
      if (
        res.data.message === 'Success' &&
        (redirectedFrom === true || Object.keys(mxAnsData).length > 0)
      ) {
        let body = { ...mxAnsData };
        body._id = res.data.user._id;
        res1 = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`,
          body
        );
      }
      if (
        res.data.message === 'Success' &&
        ((mxAnsData.hasOwnProperty('questionnaire') &&
          mxAnsData.questionnaire.hasOwnProperty('purchase') &&
          mxAnsData.questionnaire.purchase !== true) ||
          Object.keys(mxAnsData).length === 0)
      ) {
        toast('Login successful');
        dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
        dispatch({ type: 'SET_QUESTIONNAIRE', payload: 'questionnaire' });
        const customerAuthenticationCart = localStorage.getItem('authentication');
        const customerCart = JSON.parse(localStorage.getItem('cartProducts'));
        let styles = [];
        //let rooms = [];
        if (
          res.data.user.questionnaire &&
          Object.keys(res.data.user.questionnaire).length !== 0 &&
          res.data.user.questionnaire
        ) {
          // rooms = [...res.data.user.questionnaire.rooms];
          if (
            res.data.user.questionnaire.hasOwnProperty('space') &&
            res.data.user.questionnaire.space.length > 0
          ) {
            res.data.user.questionnaire.space.map(sp => {
              styles = [...styles, sp.tag];
            });
          } else if (
            res.data.user.questionnaire.hasOwnProperty('style') &&
            res.data.user.questionnaire.style.length > 0
          ) {
            res.data.user.questionnaire.style.map(st => {
              styles = [...styles, st.tag];
            });
          }
          styles = styles.flat();
          styles = styles.filter((v, i, a) => a.indexOf(v) === i);
        }
        if (redirect !== undefined) {
          window.location.assign(redirectUrl);
        }
        if (redirect === undefined) {
          if (
            (redirect !== undefined || redirect === undefined) &&
            customerAuthenticationCart === 'PENDING' &&
            customerCart.length > 0
          ) {
            const cartBody = {
              user_id: res.data.user._id,
              products: customerCart
            };
            const res1 = await customerApiInstance.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
              cartBody
            );
            if (res1.data) {
              localStorage.removeItem('authentication');
              localStorage.removeItem('cartProducts');
              window.location.assign(redirectUrl);
            }
          } else if (
            res.data.user.questionnaire &&
            Object.keys(res.data.user.questionnaire).length !== 0 &&
            res.data.user.questionnaire
          ) {
            window.location.assign(redirectUrl);
            // history.push({
            //   pathname: `/explore/?rooms=${rooms}/styles=${styles}`,
            //   state: { rooms: `${rooms}`, styles: `${styles}` },
            // });
            storeMxAns({});
            localStorage.removeItem('RedirectToLogin');
          }
        }
      } else if (res.data.message === 'Success' && res1.data) {
        toast('Login successful');
        // ConsoleHelper("going here")
        dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
        dispatch({ type: 'SET_QUESTIONNAIRE', payload: 'questionnaire' });
        if (res1.data.questionnaire.package.title !== 'Express') {
          // history.push('/package-payment');
          window.location.assign(redirectUrl);
          storeMxAns({});
          localStorage.removeItem('RedirectToLogin');
        } else {
          let qns1 = JSON.parse(localStorage.getItem('MX-A-questionnaire'));
          qns1.status = 'PENDING_ALLOCATION';
          qns1.designer_allocation = 'UNASSIGNED';
          qns1.name = 'My' + ' ' + qns1.questionnaire.MX_A_INITAL_ROOM;
          let res2 = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
            qns1
          );
          let styles = [];
          //let rooms = [];
          if (
            res2 &&
            res2.data &&
            mxAnsData.questionnaire &&
            Object.keys(mxAnsData.questionnaire).length !== 0
          ) {
            // rooms = [...mxAnsData.questionnaire.rooms];
            if (
              mxAnsData.questionnaire.hasOwnProperty('space') &&
              mxAnsData.questionnaire.space.length > 0
            ) {
              mxAnsData.questionnaire.space.map(sp => {
                styles = [...styles, sp.tag];
              });
            } else if (
              mxAnsData.questionnaire.hasOwnProperty('style') &&
              mxAnsData.questionnaire.style.length > 0
            ) {
              mxAnsData.questionnaire.style.map(st => {
                styles = [...styles, st.tag];
              });
            }
            styles = styles.flat();
            styles = styles.filter((v, i, a) => a.indexOf(v) === i);
            storeMxAns({});
            localStorage.removeItem('RedirectToLogin');
            window.location.assign(redirectUrl);
            // history.push({
            //   pathname: `/explore/?rooms=${rooms}/styles=${styles}`,
            //   state: { rooms: `${rooms}`, styles: `${styles}` },
            // });
          }
        }
      }
      if (res.data.message !== 'Success') {
        dispatch({ type: 'AUTH_ERROR', payload: res.data });
      }
    } catch (err) {
      ConsoleHelper({ err });
    }
  };

  return <div>Wait... While we redirect you to pixelandmortar.com</div>;
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, {
  registerUser,
  loginUser,
  clearError
})(withRouter(AuthPasswordless));
