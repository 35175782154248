import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './DesignDairies.module.css';

const DesignDairies = () => {
  return (
    <div>
      <Header />
      <div>
        <div
          className="container "
          style={{
            height: 'auto',
            maxWidth: '100%',
            paddingLeft: '0px',
            paddingRight: '0px',
            marginTop: '75px'
          }}>
          <img
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/coming-soon/coming-soon3.png'}
            className={style.layerImg}
            alt=""
            style={{ width: '100%' }}
          />

          <div className={style.center__text}>
            <h1 style={{ fontFamily: 'QuickSand' }}> Coming Soon </h1>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DesignDairies;
