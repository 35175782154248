import React, { useEffect, useState, useRef, useLayoutEffect, createRef } from 'react';
import style from './RoomProfileXl.module.css';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Annotation } from 'react-mark-image';
import Modal from '@material-ui/core/Modal';
import AddIcon from '@mui/icons-material/Add';
import { withRouter } from 'react-router-dom';
import ReactPlayer from 'react-player';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import ReactAudioPlayer from 'react-audio-player';
import * as Icon from 'react-bootstrap-icons';
import Snackbar from '@material-ui/core/Snackbar';
import { storeMxB } from '../../store/actions/sampleaction';
import Tooltip from '@material-ui/core/Tooltip';
import customerApiInstance from '../../utils/CustomerApiInstance';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { furniture_images, FURNITURE_ROOM_OBJECT } from './Furniture1';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Popupstyle from '../../pages/Shop/Shopdetails.module.css';
import Dropzone from 'react-dropzone';
import ConsoleHelper from '../../utils/ConsoleHelper';
import axios from 'axios';

const useStyles = makeStyles({
  topbar: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: 10,
    backgroundColor: 'white'
  },
  justify: {
    display: 'inline-flex',
    justifyContent: 'left'
  },
  button: {
    borderRadius: 30,
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 30,
    border: 0,
    paddingRight: '10px',
    backgroundColor: 'black',
    color: 'white'
  }
});

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      style={{ display: 'none' }}
      className={classnames('', style.slider__info)}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

const PrettoSlider = withStyles({
  root: {
    height: 8,
    alignSelf: 'end'
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#000',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#EDEDED',
    color: '#EDEDED'
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#EDEDED',
    color: '#EDEDED'
  }
})(Slider);

const RoomProfileXl = ({ auth, history, id, storeMxB, questionnaireData }) => {
  const arts = [
    {
      id: 1,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/abst%402x.png',
      title: 'Abstract'
    },
    {
      id: 2,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/digi+Illust%402x.png',
      title: 'Digital Illustrations'
    },
    {
      id: 3,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Ethnic%402x.png',
      title: 'Ethnic'
    },
    {
      id: 4,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/floral%402x.png',
      title: 'Floral'
    },
    {
      id: 5,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/masculine%402x.png',
      title: 'Masculine'
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Modern%402x.png',
      title: 'Modern'
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/neo+art+deco%402x.png',
      title: 'Neo art deco'
    },
    {
      id: 7,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Retro%402x.png',
      title: 'Retro'
    },
    {
      id: 8,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Scandinavian%402x.png',
      title: 'Scandinavian'
    },
    {
      id: 9,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Vintage%402x.png',
      title: ' Vintage'
    }
  ];

  const materials = [
    {
      id: 1,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Bamboo%402x.png',
      title: 'Bamboo'
    },
    {
      id: 2,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Black+metal%402x.png',
      title: 'Black Metal'
    },
    {
      id: 3,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Brass%402x.png',
      title: 'Brass'
    },
    {
      id: 4,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Cement%402x.png',
      title: 'Cement'
    },
    {
      id: 5,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/fabric%402x.png',
      title: 'Fabric'
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Glass%402x.png',
      title: 'Glass'
    },
    {
      id: 7,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/gold%402x.png',
      title: 'Gold'
    },

    {
      id: 9,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/jute%402x.png',
      title: 'Jute'
    },
    {
      id: 10,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/leather%402x.png',
      title: 'Leather'
    },

    {
      id: 12,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/marble%402x.png',
      title: 'Marble'
    },
    {
      id: 13,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Rattan%402x.png',
      title: 'Rattan'
    },

    {
      id: 15,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/wood%402x.png',
      title: 'Wood'
    }
  ];

  const palettes = [
    {
      id: 1,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(2).png',
      title: 'Warm tones',
      colors: ['Yellow', 'brown', 'beige', 'white']
    },
    {
      id: 2,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(4).png',
      title: 'Pastel Palette ',
      colors: ['Pink', 'Purple', 'Green', 'yellow', 'blue', 'white']
    },
    {
      id: 3,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(3).png',
      title: 'Organic Palette',
      colors: ['Brown', 'Beige', 'white']
    },
    {
      id: 4,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(1).png',
      title: 'Light Neutrals',
      colors: ['brown', 'grey']
    },
    {
      id: 5,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x.png',
      title: 'Jewel tones',
      colors: ['Green', 'purple', 'yellow', 'maroon', 'green', 'blue']
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(7).png',
      title: 'Feminine',
      colors: ['pink', 'magenta', 'purple']
    },
    {
      id: 7,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(5).png',
      title: 'Bold Primary',
      colors: ['red', 'blue', 'green', 'yellow']
    },
    {
      id: 8,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6235%402x+(6).png',
      title: 'Accent Palette',
      colors: ['white', 'beige', 'green', 'blue', 'green', 'red', 'yellow']
    }
  ];

  const patterns = [
    {
      id: 1,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Morrocan.jpg',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/01.jpg',
      title: 'Moroccan'
    },
    {
      id: 2,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Abstract%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/09.jpg',
      title: 'Abstract'
    },
    {
      id: 3,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Animal+Prints%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/08.jpg',
      title: 'Animal Prints'
    },
    {
      id: 4,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Argyle%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/07.jpg',
      title: 'Argyle'
    },
    {
      id: 5,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Chevron%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/06.jpg',
      title: 'Chevron'
    },
    {
      id: 6,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Floral%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/05.jpg',
      title: 'Floral'
    },
    {
      id: 7,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Geometric%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/04.jpg',
      title: 'Geometric'
    },
    {
      id: 8,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Indian%402x.png',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/03.jpg',
      title: 'Indian'
    },
    {
      id: 9,
      image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Intricate%402x.jpg',
      hoverImg: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/11.jpg',
      title: 'Intricate'
    }
  ];

  const [projectIndex, setProjectIndex] = useState(
    questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData.findIndex(mx => mx.projectId === id) >= 0
      ? questionnaireData.MxBData.findIndex(mx => mx.projectId === id)
      : ''
  );

  const [planError] = useState(false);

  const dropzoneRef = createRef();
  const [imagePopup, setImagePopup] = useState(false);
  const [annotations, setAnnotations] = useState([]);

  const fileInputRef = useRef();
  const [modal, setModal] = useState(1);
  const [audioModal, setAudioModal] = useState(false);
  const [furnitureRooms, setFurnitureRooms] = useState([]);
  const [spaceImages, setSpaceImages] = useState([]);
  const [inspirationImages, setInspirationImages] = useState([]);
  const [furnitureImages, setFurnitureImages] = useState([]);

  const [currentSpaceImage, setCurrentSpaceImage] = useState('');
  const [spaceComment, setSpaceComment] = useState('');

  const [startAudio, setStartAudio] = useState(false);

  let [currentField, setCurrentField] = useState('');
  let [audioUrl] = useState([]);

  const [uploadError, setUploadError] = useState('');
  const dimension = useMediaQuery();
  const [snack, setSnack] = useState(false);

  const [snackMessage, setSnackMessage] = useState('');
  const [validPageCount, setValidPageCount] = useState(0);

  const [dimensionFile, setDimensionFile] = useState(
    questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_DIMENSIONS') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].MX_B_DIMENSIONS.hasOwnProperty('file')
      ? questionnaireData.MxBData[projectIndex].MX_B_DIMENSIONS.file
      : {}
  );

  const reduxValue = (key1, value) => {
    let currentValue = questionnaireData.MxBData.splice(projectIndex, 1);
    let addedValue = { ...currentValue[0], [key1]: value };
    return [...questionnaireData.MxBData, addedValue];
  };

  useEffect(() => {
    const itemsFirstWishlist = [
      {
        id: 1,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2960_ugsgcf.png',
        title: 'Sofa'
      },
      {
        id: 2,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2961_bssrpx.png',
        title: 'Sectional'
      },
      {
        id: 3,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2301_swmeip.png',
        title: 'Chairs'
      },
      {
        id: 4,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2959_clhzkh.png',
        title: 'Coffee Table'
      },
      {
        id: 5,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2962_k8zugc.png',
        title: 'Accent Seating'
      },
      {
        id: 6,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2965_qtexuu.png',
        title: 'Media Console'
      },
      {
        id: 7,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2966_v7grn1.png',
        title: 'Window Treatments'
      },
      {
        id: 8,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2290_urjvpz.png',
        title: 'Desk'
      },
      {
        id: 9,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2286_vix2s8.png',
        title: 'Desk Chair'
      },
      {
        id: 10,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2294_unwgyi.png',
        title: 'Cushions'
      },
      {
        id: 11,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2967_kxpyab.png',
        title: 'Accessories'
      },
      {
        id: 12,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2968_ewjl0v.png',
        title: 'Rug'
      },
      {
        id: 13,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2969_xo3knr.png',
        title: 'Floor Lamp'
      },
      {
        id: 14,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2302_ysynef.png',
        title: 'Overhead Lighting'
      },
      {
        id: 15,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2303_q2m4e9.png',
        title: 'Table Lamps'
      },
      {
        id: 16,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2972_j1xqjl.png',
        title: 'Sconces'
      },
      {
        id: 17,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2971_axdtec.png',
        title: 'Book Case'
      },
      {
        id: 18,
        image: 'https://d3izcfql7lesks.cloudfront.net/Group_2970_flz1wl.png',
        title: 'Small Storage'
      }
    ];
    setFurnitureRooms(itemsFirstWishlist);
  }, []);

  const [blobURL, setblobURL] = useState('');
  const [recordstate, setrecordstate] = useState('NONE');

  const [visionError] = useState(false);
  const [projectQuestionnaire, setProjectQuestionnaire] = useState({});

  const fetchData = async () => {
    if (id) {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );
      setProjectQuestionnaire(res.data.questionnaire);
      let room = res.data.questionnaire.MX_A_INITAL_ROOM;
      let furnitureData = [];
      if (Object.keys(FURNITURE_ROOM_OBJECT).includes(room)) {
        furnitureData = FURNITURE_ROOM_OBJECT[room];
      } else {
        furnitureData = FURNITURE_ROOM_OBJECT['Others'];
      }
      setFurnitureRooms(furniture_images.filter(d => furnitureData.includes(d.title)));
      let projectData = res.data.questionnaire;
      if (projectData.hasOwnProperty('MX_B_SPACE_IMAGES')) {
        setSpaceImages([...projectData.MX_B_SPACE_IMAGES]);
      }
      if (projectData.hasOwnProperty('MX_B_DECOR_IMAGES')) {
        setFurnitureImages([...projectData.MX_B_DECOR_IMAGES]);
      }
      if (projectData.hasOwnProperty('MX_B_PLAN')) {
        inputs.plan = projectData.MX_B_PLAN;
      }
      if (projectData.hasOwnProperty('MX_B_LIVE')) {
        inputs.live = projectData.MX_B_LIVE;
      }
      if (projectData.hasOwnProperty('NO_OF_PERSONS')) {
        inputs.no_of_person = projectData.NO_OF_PERSONS;
      }
      if (projectData.hasOwnProperty('MX_B_INSPIRATIONS')) {
        setInspirationImages([...projectData.MX_B_INSPIRATIONS]);
      }
      if (projectData.hasOwnProperty('MX_B_COLLABORATOR')) {
        inputs.collaborator = projectData.MX_B_COLLABORATOR;
      }
      if (projectData.hasOwnProperty('MX_B_PROJECT')) {
        inputs.project_vision = projectData.MX_B_PROJECT;
      }
      if (projectData.hasOwnProperty('MX_B_ROOMS')) {
        inputs.mx_b_rooms = projectData.MX_B_ROOMS;
      }
      if (projectData.hasOwnProperty('MX_B_ARTWORK')) {
        inputs.mx_b_artworks = projectData.MX_B_ARTWORK;
      }
      if (projectData.hasOwnProperty('MX_B_MATERIALS')) {
        inputs.mx_b_materials = projectData.MX_B_MATERIALS;
      }
      if (projectData.hasOwnProperty('MX_B_PALLETES')) {
        inputs.mx_b_palettes = projectData.MX_B_PALLETES;
      }
      if (projectData.hasOwnProperty('MX_B_PATTERNS')) {
        inputs.mx_b_patterns = projectData.MX_B_PATTERNS;
      }
      if (projectData.hasOwnProperty('MX_B_DESIGN')) {
        inputs.design = projectData.MX_B_DESIGN;
      }
      if (projectData.hasOwnProperty('MX_B_DIMENSIONS')) {
        inputs.dimensions = projectData.MX_B_DIMENSIONS;
      }
      if (projectData.hasOwnProperty('MX_B_OCCUPANTS')) {
        inputs.occupants = projectData.MX_B_OCCUPANTS;
      }
      if (projectData.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
        inputs.tentative_budget = projectData.MX_B_TENTATIVE_BUDGET;
      }
      if (projectData.hasOwnProperty('MX_B_PHONE_NUMBER')) {
        inputs.phone_number = projectData.MX_B_PHONE_NUMBER;
      }
      if (projectData.hasOwnProperty('MX_B_COUNTRY_CODE')) {
        inputs.country_code = projectData.MX_B_COUNTRY_CODE;
      }
      if (projectData.hasOwnProperty('MX_B_BUDGET_DETAILS')) {
        inputs.budget = projectData.MX_B_BUDGET_DETAILS;
      }
    }
  };
  useEffect(() => {
    if (questionnaireData.hasOwnProperty('MxBData')) {
      let data = [...questionnaireData.MxBData];

      let index = data.findIndex(mx => mx.projectId === id);
      if (index >= 0) {
        setProjectIndex(index);
      }
    }
  }, []);
  useEffect(() => {
    id && fetchData();
  }, []);

  const loadImage = imageUrl => {
    const img = new Image();
    img.src = imageUrl;
    // img.onload = () => {
    //   setImageDimensions({
    //     height: img.height,
    //     width: img.width,
    //   });
    // };
    img.onerror = err => {
      console.error(err);
    };
  };

  const removeAudio = key1 => {
    inputs.plan.audio = '';
    if (questionnaireData.MxBData[projectIndex].hasOwnProperty(key1)) {
      questionnaireData.MxBData[projectIndex][key1].audio = '';
      return [...questionnaireData.MxBData];
    }
    return [...questionnaireData.MxBData];
  };

  const handleInputs = e => {
    if (
      e.target.name === 'plan' ||
      e.target.name === 'live' ||
      e.target.name === 'design' ||
      e.target.name === 'budget'
    ) {
      if (inputs[e.target.name]) {
        setInputs({
          ...inputs,
          [e.target.name]: {
            ...inputs[e.target.name],
            text: e.target.value
          }
        });
      } else {
        setInputs({
          ...inputs,
          [e.target.name]: {
            text: e.target.value
          }
        });
      }
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleChangeFile = async e => {
    const fileUploaded = e.target.files;
    let error = false;
    for (let i = 0; i < e.target.files.length; i++) {
      ConsoleHelper('each file', e.target.files[i]);
      if (e.target.files[i].size > 5000000) {
        error = true;
        break;
      }
    }

    if (!error) {
      let fileName = fileUploaded[0].name;
      const formData = new FormData();
      for (let i = 0; i < fileUploaded.length; i++) {
        formData.append('file', fileUploaded[i]);
      }
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData);
      if (res.data && res.data.message === 'success') {
        ConsoleHelper('res.data.data', res.data);
        let uploadFiles = res.data.data;
        if (uploadFiles.length === 1) {
          setDimensionFile({
            fileLocation: uploadFiles[0].Location,
            fileType: uploadFiles[0].fileType,
            name: fileName
          });
          setInputs({
            ...inputs,
            dimensions: {
              ...inputs.dimensions,
              file: {
                fileLocation: uploadFiles[0].Location,
                fileType: uploadFiles[0].fileType,
                name: fileName
              }
            }
          });
        }
      }
    } else {
      setUploadError({ upload: 'max file image size 5MB' });
    }
  };

  const onDrop = acceptedFiles => {
    setUploadError({});
    if (checkFileSizes(acceptedFiles)) {
      if (spaceImages.length + acceptedFiles.length > 8) {
        setSnack(true);
        if (spaceImages.length >= 8) {
          setSnackMessage('only 8 images can be uploaded');
        } else {
          setSnackMessage(
            'Only' + ' ' + `${8 - spaceImages.length}` + ' ' + 'images can be selected '
          );
        }
      } else {
        handleChange(acceptedFiles);
        setImagePopup(false);
      }
    } else {
      setUploadError({ drop: 'max file size image 5MB and video 10MB' });
    }
  };
  const onDrop1 = acceptedFiles => {
    setUploadError({});
    if (checkFileSizes(acceptedFiles)) {
      if (furnitureImages.length + acceptedFiles.length > 8) {
        setSnack(true);
        if (furnitureImages.length >= 8) {
          setSnackMessage('only 8 images can be uploaded');
        } else {
          setSnackMessage(
            'Only' + ' ' + `${8 - furnitureImages.length}` + ' ' + 'images can be selected '
          );
        }
      } else {
        handleFurnitureChange(acceptedFiles);
      }
    } else {
      setUploadError({ drop1: 'max file size image 5MB and video 10MB' });
    }
  };

  const setCustomerRating = (value, name, rating1) => {
    let index = inputs[name].findIndex(f => f.title === value.title);
    if (index >= 0) {
      inputs[name][index]['customer_rating'] = rating1;
      setInputs({ ...inputs });
    } else {
      if (name === 'mx_b_materials' || name === 'mx_b_artworks') {
        setInputs(prev => ({
          ...prev,
          [name]: [...prev[name], { title: value.title, customer_rating: rating1 }]
        }));
      } else if (name === 'mx_b_palettes') {
        setInputs(prev => ({
          ...prev,
          [name]: [
            ...prev[name],
            {
              title: value.title,
              colors: value.colors,
              customer_rating: rating1
            }
          ]
        }));
      }
    }
  };

  const handleClickHome = (value, name, e) => {
    if (name === 'mx_b_materials' || name === 'mx_b_artworks') {
      let index = inputs[name].findIndex(f => f.title === value);
      if (index < 0) {
        setInputs(prev => ({
          ...prev,
          [name]: [...prev[name], { title: value, customer_rating: 0 }]
        }));
      } else {
        setInputs({
          ...inputs,
          [name]: inputs[name].filter(filter => filter.title !== value)
        });
      }
    } else {
      if (!inputs[name].includes(value)) {
        setInputs(prev => ({ ...prev, [name]: [...prev[name], value] }));
      } else {
        setInputs({
          ...inputs,
          [name]: inputs[name].filter(filter => filter !== value)
        });
      }
    }
  };

  const handleSubmit = async e => {
    let userData;
    inputs = {
      ...inputs,
      mx_b_space_images: spaceImages,
      mx_b_decor_images: furnitureImages,
      mx_b_inspirations: inspirationImages
    };
    e.preventDefault();
    if (
      (inputs.phone_number &&
        inputs.phone_number !== '' &&
        inputs.country_code &&
        inputs.country_code !== '' &&
        inputs.tentative_budget &&
        inputs.tentative_budget !== '' &&
        inputs.mx_b_space_images &&
        inputs.mx_b_space_images.length >= 4 &&
        inputs.plan &&
        Object.keys(inputs.plan).length > 0 &&
        inputs.no_of_person &&
        inputs.no_of_person !== '' &&
        // inputs.project_vision &&
        // Object.keys(inputs.project_vision).length > 0 &&
        inputs.mx_b_artworks &&
        inputs.mx_b_artworks.length >= 3 &&
        inputs.mx_b_palettes &&
        inputs.mx_b_palettes.length >= 3 &&
        inputs.mx_b_patterns &&
        inputs.mx_b_patterns.length >= 3 &&
        // inputs.design &&
        // inputs.occupants &&
        // inputs.tentative_budget &&
        inputs.dimensions &&
        Object.keys(inputs.dimensions).length >= 3 &&
        inputs.mx_b_materials &&
        inputs.mx_b_materials.length >= 3) ||
      false
    ) {
      try {
        if (id) {
          const res1 = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`,
            {
              _id: auth.user._id,
              questionnaire: inputs
            }
          );
          ConsoleHelper('ress', res1.data);
          const projectData = {
            questionnaire: {
              MX_B_SPACE_IMAGES: inputs.mx_b_space_images,
              MX_B_DECOR_IMAGES: inputs.mx_b_decor_images,
              // "MX_B_FLOOR_IMAGES": [],
              MX_B_PLAN: inputs.plan,
              MX_B_LIVE: inputs.live,
              NO_OF_PERSONS: inputs.no_of_person,
              MX_B_INSPIRATIONS: inputs.mx_b_inspirations,
              MX_B_COLLABORATOR: inputs.collaborator,
              // MX_B_PROJECT: inputs.project_vision,
              MX_B_ROOMS: inputs.mx_b_rooms,
              MX_B_ARTWORK: inputs.mx_b_artworks,
              MX_B_MATERIALS: inputs.mx_b_materials,
              MX_B_PALLETES: inputs.mx_b_palettes,
              MX_B_PATTERNS: inputs.mx_b_patterns,
              MX_B_DESIGN: inputs.design,
              MX_B_DIMENSIONS: inputs.dimensions,
              MX_B_OCCUPANTS: inputs.occupants,
              MX_B_TENTATIVE_BUDGET: inputs.tentative_budget,
              MX_B_PHONE_NUMBER: inputs.phone_number,
              MX_B_COUNTRY_CODE: inputs.country_code,
              MX_B_BUDGET_DETAILS: inputs.budget
            },
            status: 'PENDING_ALLOCATION',
            designer_allocation: 'UNASSIGNED',
            designer_id: '',
            user_id: auth.user_id,
            customer_name: auth.user.name
          };
          await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${id}`,
            projectData
          );
          history.push({
            pathname: `/rooms/${id}`,
            state: { type: 'getting' }
          });
        } else {
          const res = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/questionnaire`,
            {
              _id: auth.user._id,
              questionnaire: inputs
            }
          );
          ConsoleHelper('ress', res.data);
          userData = { ...res.data };

          if (res.data) {
            const projectData = {
              user_id: auth.user._id,
              customer_name: auth.user.name,
              status: 'PENDING_ALLOCATION',
              designer_allocation: 'UNASSIGNED',
              designer_id: '',
              questionnaire: {
                MX_A_SPACE: userData.questionnaire.space,
                MX_A_STYLE: userData.questionnaire.style,
                MX_A_COLOR_TONE: userData.questionnaire.hasOwnProperty('color')
                  ? userData.questionnaire.color
                  : null,
                MX_A_DESIGN: userData.questionnaire.design,
                MX_A_ROOMS: userData.questionnaire.rooms,
                MX_A_INITAL_ROOM: userData.questionnaire.hasOwnProperty('initial_room')
                  ? userData.questionnaire.initial_room
                  : null,
                MX_A_STYLE_RESULT: userData.questionnaire.styleResult,
                MX_A_HOME: userData.questionnaire.home,
                MX_A_HOME_TYPE: userData.questionnaire.hometype,
                MX_A_LOCATION: userData.questionnaire.location,
                MX_A_STAGE: userData.questionnaire.stage,
                MX_A_ROOM_READY: userData.questionnaire.room,
                MX_A_BUDGET: userData.questionnaire.budget,
                PACKAGE: userData.questionnaire.package,
                MX_B_SPACE_IMAGES: inputs.mx_b_space_images,
                MX_B_DECOR_IMAGES: inputs.mx_b_decor_images,
                // "MX_B_FLOOR_IMAGES": [],
                MX_B_PLAN: inputs.plan,
                MX_B_LIVE: inputs.live,
                NO_OF_PERSONS: inputs.no_of_person,
                MX_B_INSPIRATIONS: inputs.mx_b_inspirations,
                MX_B_COLLABORATOR: inputs.collaborator,
                // MX_B_PROJECT: inputs.project_vision,
                MX_B_ROOMS: inputs.mx_b_rooms,
                MX_B_ARTWORK: inputs.mx_b_artworks,
                MX_B_MATERIALS: inputs.mx_b_materials,
                MX_B_PALLETES: inputs.mx_b_palettes,
                MX_B_PATTERNS: inputs.mx_b_patterns,
                MX_B_DESIGN: inputs.design,
                MX_B_DIMENSIONS: inputs.dimensions,
                MX_B_OCCUPANTS: inputs.occupants,
                MX_B_TENTATIVE_BUDGET: inputs.tentative_budget,
                MX_B_PHONE_NUMBER: inputs.phone_number,
                MX_B_COUNTRY_CODE: inputs.country_code,
                MX_B_BUDGET_DETAILS: inputs.budget
                // "MX_B_BUDGET_DETAILS": "Would you like to tell us something more about your budget?",
                // "MX_B_WISHLIST": {
                //   "plan": "Tell us how you plan to use the space and your thoughts about it in general",
                //   "live": "How would you describe the way you live?",
                //   "no_of_people": 23,
                //   "inspiration_images": [],
                //   "pinterest_collaborator": "Have a Pinterest board for your room? Add us as a collabrator.",
                //   "project": "What do you have in mind for this project? Tell us more about your vision"
                // }
              }
            };
            const res2 = await customerApiInstance.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
              projectData
            );
            history.push({
              pathname: `/rooms/${res2.data.project._id}`,
              state: { type: 'getting' }
            });
          }
        }
        // }
      } catch (err) {
        ConsoleHelper(err);
      }
    } else {
      setSnackMessage('Please fill mandatory fields');
      setSnack(true);
    }
  };

  let [inputs, setInputs] = useState({
    occupants:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('occupants')
        ? questionnaireData.MxBData[projectIndex].occupants
        : [],
    mx_b_rooms:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_rooms')
        ? questionnaireData.MxBData[projectIndex].mx_b_rooms
        : [],
    tentative_budget:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('tentative_budget')
        ? questionnaireData.MxBData[projectIndex].tentative_budget
        : 0,
    phone_number:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('phone_number')
        ? questionnaireData.MxBData[projectIndex].phone_number
        : 0,
    country_code:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('country_code')
        ? questionnaireData.MxBData[projectIndex].country_code
        : 0,
    mx_b_artworks:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_artworks')
        ? questionnaireData.MxBData[projectIndex].mx_b_artworks
        : [],
    mx_b_materials:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_materials')
        ? questionnaireData.MxBData[projectIndex].mx_b_materials
        : [],
    mx_b_palettes:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_palettes')
        ? questionnaireData.MxBData[projectIndex].mx_b_palettes
        : [],
    mx_b_patterns:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('mx_b_patterns')
        ? questionnaireData.MxBData[projectIndex].mx_b_patterns
        : [],
    no_of_person:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('no_of_person')
        ? questionnaireData.MxBData[projectIndex].no_of_person
        : 0,
    plan:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('plan')
        ? questionnaireData.MxBData[projectIndex].plan
        : {},
    live:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('live')
        ? questionnaireData.MxBData[projectIndex].live
        : {},
    // project_vision:
    //   questionnaireData.hasOwnProperty("MxBData") &&
    //   questionnaireData.MxBData[projectIndex] &&
    //   questionnaireData.MxBData[projectIndex].hasOwnProperty("project_vision")
    //     ? questionnaireData.MxBData[projectIndex].project_vision
    //     : {},
    design:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('design')
        ? questionnaireData.MxBData[projectIndex].design
        : {},
    budget:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('budget')
        ? questionnaireData.MxBData[projectIndex].budget
        : {},
    dimensions:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_DIMENSIONS')
        ? questionnaireData.MxBData[projectIndex].MX_B_DIMENSIONS
        : {},
    collaborator:
      questionnaireData.hasOwnProperty('MxBData') &&
      questionnaireData.MxBData[projectIndex] &&
      questionnaireData.MxBData[projectIndex].hasOwnProperty('MX_B_COLLABORATOR')
        ? questionnaireData.MxBData[projectIndex].MX_B_COLLABORATOR
        : ''
  });

  const checkFileSizes = files => {
    return files.every(file => {
      return (
        (file.type.match(/video/i) !== null && file.size <= 10000000) ||
        (file.type.match(/image/i) !== null && file.size <= 5000000)
      );
    });
  };

  // const hiddenFileInput = React.useRef(null);
  // const hiddenFileInput2 = React.useRef(null);
  // const hiddenFileInput3 = React.useRef(null);

  // const handleClickSpace = (event) => {
  //   hiddenFileInput.current.click();
  // };
  // const handleClickFurniture = (event) => {
  //   hiddenFileInput2.current.click();
  // };
  // const handleClickInspiration = (event) => {
  //   hiddenFileInput3.current.click();
  // };
  // const handleChange = async (event) => {
  //   const fileUploaded = event.target.files[0];
  //   const formData = new FormData();
  //   formData.append("file", fileUploaded);
  //   const res = await axios.post(
  //     `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
  //     formData
  //   );
  //   // ConsoleHelper(res, res.data.data, res.data.data[0].Location)
  //   spaceImages.push({
  //     img: res.data.data[0].Location,
  //     fileType: res.data.data[0].fileType,
  //   });
  //   setSpaceImages([...spaceImages]);
  //   // ConsoleHelper(spaceImages);
  // };

  let imageLink = [];
  const recursion = async (files, cb) => {
    if (files.length) {
      const i = 0;
      let formData = new FormData();
      // for (let i = 0; i < fileUploaded.length; i++) {
      formData.append('file', files[i]);
      // }
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData);
      if (res.data && res.data.message === 'success') {
        let uploadImages = res.data.data;
        imageLink.push(uploadImages[0]);
      }
      const [, ...newArray] = files;
      recursion([...newArray], cb);
    } else {
      cb();
      imageLink = [];
    }
  };

  const handleChange = async files => {
    // const fileUploaded = files;
    recursion(files, () => {
      for (let i = 0; i < imageLink.length; i++) {
        spaceImages.push({
          img: imageLink[i].Location,
          fileType: imageLink[i].fileType
        });
      }
      storeMxB(reduxValue('MX_B_SPACE_IMAGES', [...spaceImages]));
      setSpaceImages([...spaceImages]);
    });
  };

  const handleInspirationChange = async files => {
    // const fileUploaded = files;
    recursion(files, () => {
      for (let i = 0; i < imageLink.length; i++) {
        inspirationImages.push({
          img: imageLink[i].Location,
          fileType: imageLink[i].fileType
        });
      }
      storeMxB(reduxValue('MX_B_INSPIRATION_IMAGES', [...inspirationImages]));
      setInspirationImages([...inspirationImages]);
    });
  };

  const handleFurnitureChange = async files => {
    recursion(files, () => {
      for (let i = 0; i < imageLink.length; i++) {
        furnitureImages.push({
          img: imageLink[i].Location,
          fileType: imageLink[i].fileType
        });
      }
      storeMxB(reduxValue('MX_B_DECOR_IMAGES', [...furnitureImages]));
      setFurnitureImages([...furnitureImages]);
    });
  };

  const handleClickHomePatterns = (value, name) => {
    let index = inputs[name].findIndex(f => f.title === value.title);
    if (index < 0) {
      setInputs(prev => ({
        ...prev,
        [name]: [...prev[name], { title: value.title, img: value.image }]
      }));
      storeMxB(reduxValue(name, [...inputs[name], { title: value.title, img: value.image }]));
    } else {
      setInputs({
        ...inputs,
        [name]: inputs[name].filter(filter => filter.title !== value.title)
      });
      storeMxB(
        reduxValue(
          name,
          inputs[name].filter(filter => filter.title !== value.title)
        )
      );
    }
  };

  const onDrop2 = acceptedFiles => {
    setUploadError({});
    if (checkFileSizes(acceptedFiles)) {
      if (inspirationImages.length + acceptedFiles.length > 8) {
        setSnack(true);
        if (inspirationImages.length >= 8) {
          setSnackMessage('only 8 images can be uploaded');
        } else {
          setSnackMessage(
            'Only' + ' ' + `${8 - inspirationImages.length}` + ' ' + 'images can be selected '
          );
        }
      } else {
        handleInspirationChange(acceptedFiles);
      }
    } else {
      setUploadError({ drop2: 'max file size image 5MB and video 10MB' });
    }
  };

  const submitSpaceComment = () => {
    let annotate = [];
    let index = spaceImages.findIndex(space => space.img === currentSpaceImage.IMG);
    // ConsoleHelper("indexx", index, currentSpaceImage, spaceImages);
    if (index >= 0) {
      // ConsoleHelper("indexx", index);
      spaceImages[index]['comment'] = spaceComment;
      if (annotations && annotations.length > 0) {
        // ConsoleHelper("inside ann", annotations);
        for (let i = 0; i < annotations.length; i++) {
          // ConsoleHelper("xx", annotations[i], annotations[i]);
          annotate.push(annotations[i]);
        }
      }
      // ConsoleHelper("annotae", annotate);
      spaceImages[index]['annotations'] = annotate;
      // ConsoleHelper("spc comm", spaceImages[index]);
      //setAnnotateState([]);
      setAnnotations([]);
      setSpaceComment('');
      setSpaceImages([...spaceImages]);
    }
  };

  const getWishlistCTA = () => {
    setValidPageCount(validPageCount + 1);
    //setActiveTab("Your Style");
  };

  const getBudgetCTA = () => {
    if (!inputs.mx_b_rooms || inputs.mx_b_rooms.length < 1) {
      // setItemError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 1 room item image');
    } else if (!inputs.mx_b_artworks || inputs.mx_b_artworks.length < 3) {
      //setArtsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 3 arts images');
    } else if (!inputs.mx_b_materials || inputs.mx_b_materials.length < 3) {
      //setMaterialsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 3 materials images');
    } else if (!inputs.mx_b_palettes || inputs.mx_b_palettes.length < 3) {
      // setPalletsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 3 pallets images');
    } else if (!inputs.mx_b_patterns || inputs.mx_b_patterns.length < 3) {
      //setPatternsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please select atleast 3 patterns images');
    } else if (
      inputs.dimensions &&
      inputs.dimensions.length &&
      inputs.dimensions.width &&
      inputs.dimensions.ceiling_height
    ) {
      setValidPageCount(validPageCount + 1);
      //setActiveTab("Your Budget");
    } else {
      //setDimensionsError(true);
      window.scrollTo(0, 0);
      setSnack(true);
      setSnackMessage('Please fill the dimensions fields');
    }
  };

  // const handleInspirationChange = async (event) => {
  //   const fileUploaded = event.target.files[0];
  //   const formData = new FormData();
  //   formData.append("file", fileUploaded);
  //   const res = await axios.post(
  //     `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
  //     formData
  //   );
  //   inspirationImages.push({
  //     img: res.data.data[0].Location,
  //     fileType: res.data.data[0].fileType,
  //   });
  //   setInspirationImages([...inspirationImages]);
  // };
  // const handleFurnitureChange = async (event) => {
  //   const fileUploaded = event.target.files[0];
  //   const formData = new FormData();
  //   formData.append("file", fileUploaded);
  //   const res = await axios.post(
  //     `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
  //     formData
  //   );
  //   furnitureImages.push({
  //     img: res.data.data[0].Location,
  //     fileType: res.data.data[0].fileType,
  //   });
  //   setFurnitureImages([...furnitureImages]);
  // };

  // const getRatingValue = (title) => {
  //   let x;
  //   if (
  //     inputs &&
  //     inputs.hasOwnProperty("mx_b_artworks") &&
  //     inputs.mx_b_artworks.length > 0
  //   ) {
  //     x = inputs.mx_b_artworks.findIndex((x) => x.title === title);
  //     if (x === -1) return 0;
  //     else {
  //       return inputs.mx_b_artworks[x].customer_rating;
  //     }
  //   } else return 0;
  // };

  const start = () => {
    setStartAudio(true);
    setrecordstate(RecordState.START);
  };

  const reset = () => {
    setStartAudio(true);
    setrecordstate(RecordState.START);
  };

  const stop = () => {
    setStartAudio(false);
    setrecordstate(RecordState.STOP);
  };

  const handleInputs1 = inputObject => {
    if (inputs && inputs.hasOwnProperty(inputObject.fieldName)) {
      inputs[inputObject.fieldName] = {
        ...inputs[inputObject.fieldName],
        audio: inputObject.audio
      };
      setInputs({ ...inputs });
    } else {
      setInputs({
        ...inputs,
        [inputObject.fieldName]: {
          audio: inputObject.audio
        }
      });
    }
  };

  //audioData contains blob and blobUrl
  const onStop = async audioData => {
    var d = new Date();
    var file = new File([audioData.blob], d.valueOf(), { type: 'audio/wav' });
    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData);
    if (res.data && res.data.message === 'success') {
      setSnack(true);
      setSnackMessage('Audio uploaded sucessfully.');
    }
    let audioObj = {};
    audioObj['fieldName'] = currentField;

    audioObj['audio'] = res.data.data[0].Location;
    handleInputs1(audioObj);
    audioUrl.push(audioObj);
    setblobURL(audioData.url);
  };

  useEffect(() => {
    const fetchProject = async () => {
      let space = [];
      let furniture = [];
      let inpirations = [];
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );

      if (
        res.data.questionnaire.MX_B_SPACE_IMAGES &&
        res.data.questionnaire.MX_B_SPACE_IMAGES.length > 0
      ) {
        space = [...spaceImages, ...res.data.questionnaire.MX_B_SPACE_IMAGES];
        space.flat();
        setSpaceImages([...space]);
      }
      if (
        res.data.questionnaire.MX_B_DECOR_IMAGES &&
        res.data.questionnaire.MX_B_DECOR_IMAGES.length > 0
      ) {
        furniture = [...furnitureImages, ...res.data.questionnaire.MX_B_DECOR_IMAGES];
        furniture.flat();
        setFurnitureImages([...space]);
      }
      if (
        res.data.questionnaire.MX_B_INSPIRATIONS &&
        res.data.questionnaire.MX_B_INSPIRATIONS.length > 0
      ) {
        inpirations = [...inspirationImages, ...res.data.questionnaire.MX_B_INSPIRATIONS];
        inpirations.flat();
        setInspirationImages([...inpirations]);
      }
    };
    id && fetchProject();
  }, []);

  // const getMaterialsRatingValue = (title) => {
  //   let x;
  //   if (
  //     inputs &&
  //     inputs.hasOwnProperty("mx_b_materials") &&
  //     inputs.mx_b_materials.length > 0
  //   ) {
  //     x = inputs.mx_b_materials.findIndex((x) => x.title === title);
  //     if (x === -1) return 0;
  //     else {
  //       // ConsoleHelper("xxxxxxx", x);
  //       // ConsoleHelper("inptsss", inputs);
  //       return inputs.mx_b_materials[x].customer_rating;
  //     }
  //   } else return 0;
  // };

  // const getPalettesRatingValue = (title) => {
  //   let x;
  //   if (
  //     inputs &&
  //     inputs.hasOwnProperty("mx_b_palettes") &&
  //     inputs.mx_b_palettes.length > 0
  //   ) {
  //     x = inputs.mx_b_palettes.findIndex((x) => x.title === title);
  //     if (x === -1) return 0;
  //     else {
  //       // ConsoleHelper("xxxxxxx", x);
  //       // ConsoleHelper("inptsss", inputs);
  //       return inputs.mx_b_palettes[x].customer_rating;
  //     }
  //   } else return 0;
  // };

  const incrementCount = () => {
    // ConsoleHelper("increment", inputs?.no_of_person + 1);

    setInputs(params => ({
      ...params,
      no_of_person: params?.no_of_person + 1
    }));
  };

  const decrementCount = () => {
    // ConsoleHelper("decrement", inputs?.no_of_person);
    if (inputs?.no_of_person > 1) {
      setInputs(params => ({
        ...params,
        no_of_person: params?.no_of_person - 1
      }));
    }
  };

  const classes = useStyles();
  return (
    <>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <div className={classnames('', style.main__cont)}>
        <div className="mt-5">
          <div className={classnames('', style.about__cont)}>
            <div className={classnames('', style.about__text)}>About your space</div>
          </div>
          <div className={classnames('', style.pagediv__margin)}>
            <h6 className={style.headerTxt}> Upload Photos and videos of your space</h6>
            {/* <div className={classnames("", style.photos__text2)}>
              Help us visualise better with pictures of the space
            </div>
            <div className={classnames("", style.photos__text2)}>
              Multiple angles preferably*
            </div> */}
          </div>
          {/* <div className={classnames("row p-0 col-12 m-0")}>
            {[0, 1, 2, 3].map((_) => (
              <div className={classnames("col-6 p-0", style.photos__div)}>
                <img
                  src={
                    "https://d3izcfql7lesks.cloudfront.net/Path_1348_qkeivo.png"
                  }
                  alt=""
                />
                <div
                  className={classnames("", style.photos__btn)}
                  onClick={handleClickSpace}
                >
                  Upload video & photos
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                    required
                  />
                </div>
              </div>
            ))}
          </div> */}
          <div className={classnames('row p-0 col-12 m-0')}>
            {spaceImages &&
              spaceImages.length > 0 &&
              spaceImages.map((data, index) =>
                data.fileType === 'png' || data.fileType === 'jpeg' || data.fileType === 'jpg' ? (
                  <div
                    style={
                      {
                        // backgroundImage: `url(${data.img})`,
                        // backgroundRepeat: "no-repeat",
                        // width: "100%",
                      }
                    }
                    className={classnames('col-6 p-0 mt-2', style.photos__div3)}
                    onClick={() => {
                      setCurrentSpaceImage(data.img);
                      setModal(2);
                    }}>
                    <img
                      src={data.img}
                      alt=""
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'contain' }}
                    />
                    <div className={classnames('', style.photos__btn2)}>+ Add Comment</div>

                    {/* {selecteddeletestatus &&
                                selecteddeleteid === index ? (
                                  <></>
                                ) : (
                                  <div
                                    style={{
                                      width: "inherit",
                                      backgroundColor: "rgba(0,0,0, 0.7)",
                                      padding: "8px",
                                      color: "#ffffff",
                                      height: "40px",
                                      position: "absolute",
                                      bottom: 0,
                                      paddingBottom: "10px",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{ width: "90%" }}
                                      onClick={() => {
                                        setCurrentSpaceImage(data);
                                        loadImage(data.img);
                                        // setSpaceComment(data.hasOwnProperty('comment') ? data.comment : '')
                                        // setAnnotations(data.hasOwnProperty('annotations') ? data.annotations : [])
                                        setModal(2);
                                      }}
                                    >
                                      + Add Comment
                                    </div>
                                    <div style={{ width: "10%" }}>
                                      <DeleteIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setselecteddeletestatus(true);
                                          setselecteddeleteid(index);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )} */}
                    {/* {selecteddeletestatus &&
                                  selecteddeleteid === index && (
                                    <div
                                      style={{
                                        padding: "8px",
                                        flex: 1,
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        opacity: 0.8,
                                        backgroundColor: "black",
                                        height: "100%",
                                        width: "100%",
                                      }}
                                      className={classnames(
                                        "d-flex flex-column justify-content-center align-items-center"
                                      )}
                                    >
                                      <div
                                        style={{
                                          color: "#ffffff",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        Are you sure you want <br />
                                        to delete this?
                                      </div>
                                      <div className="row mt-4 mb-0 justify-content-center">
                                        <div className="col-6 pr-0 pl-0">
                                          <button
                                            className={style.deleteBtn}
                                            onClick={() => {
                                              let temp = spaceImages;
                                              temp.splice(index, 1);
                                              setSpaceImages((prev) => {
                                                return temp.length > 0
                                                  ? [...temp]
                                                  : [];
                                              });
                                              storeMxB(
                                                reduxValue(
                                                  "MX_B_SPACE_IMAGES",
                                                  [...temp]
                                                )
                                              );
                                              setselecteddeletestatus(false);
                                              setselecteddeleteid(null);
                                            }}
                                          >
                                            Yes
                                          </button>
                                        </div>{" "}
                                        <div className="col-6 pr-0">
                                          <button
                                            className={style.cancelBtn}
                                            onClick={() => {
                                              setselecteddeletestatus(false);
                                              setselecteddeleteid(null);
                                            }}
                                          >
                                            No
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )} */}
                  </div>
                ) : (
                  <div
                    className={classnames('col-12 mt-2 ', style.photos__div3)}
                    style={{
                      width: '100%'
                    }}>
                    <ReactPlayer url={data.img} width="100%" height="100%" controls />
                    <div
                      style={{
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        padding: '8px',
                        color: '#ffffff',
                        height: '40px',
                        position: 'absolute',
                        bottom: 0,
                        paddingBottom: '10px'
                      }}
                      onClick={() => {
                        setCurrentSpaceImage(data.img);
                        setModal(2);
                      }}>
                      + Add Comment
                    </div>
                  </div>
                )
              )}

            {!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
              <>
                {/* {[0, 1, 2, 3].map((data) => ( */}
                <div
                  className={classnames('col-6 p-0', style.photos__div)}
                  // className={classnames(
                  //   "col-3 d-flex flex-column justify-content-center align-items-center",
                  //   style.imageRow
                  // )}
                >
                  <Dropzone
                    onDrop={onDrop}
                    multiple={true}
                    maxFiles={8 - spaceImages.length}
                    ref={dropzoneRef}>
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div
                          {...getRootProps()}
                          className={classnames(
                            'd-flex flex-column justify-content-evenly align-items-center p-2',
                            style.dropBox
                          )}
                          onClick={() => {
                            if (spaceImages.length == 0) {
                              setImagePopup(true);
                            } else {
                              if (dropzoneRef.current) {
                                dropzoneRef.current.open();
                              }
                            }
                          }}>
                          <div
                            className={classnames(
                              'd-flex flex-column justify-content-evenly align-items-center pt-0',
                              style.borderImg
                            )}>
                            <img
                              src={
                                'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6237%402x.png'
                              }
                              alt=""
                              className={style.thumbImg}
                            />
                            <img
                              src={
                                'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6240%402x.png'
                              }
                              alt=""
                              className={style.thumbImg1}
                            />

                            <div className={style.dropTxt}>
                              {/* <button className={style.uploadButton}> */}
                              Drop your photo or video here
                              {/* </button> */}
                              <input {...getInputProps()} />
                            </div>

                            {/* <p className="mt-4 small">Or drop here</p> */}
                          </div>
                        </div>
                        {/* Popup modal for About your space*/}
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          className={classes.modal}
                          style={{ overflowY: 'auto' }}
                          open={imagePopup}
                          onClose={() => {
                            setImagePopup(false);
                          }}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500
                          }}>
                          <Fade in={imagePopup}>
                            <div
                              className={classnames(classes.papers, Popupstyle.modalContainer)}
                              style={{
                                height: dimension[0] > 500 ? '' : '100vh',
                                opacity: 1,
                                visibility: 'visible'
                              }}>
                              <button
                                className={Popupstyle.closeBtn}
                                onClick={() => {
                                  setImagePopup(false);
                                }}>
                                <img
                                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/close.png"
                                  className={Popupstyle.closeBtn}
                                />
                              </button>
                              <div className="row m-1" style={{ height: '100%' }}>
                                <div
                                  className="col-12 d-flex justify-content-center align-items-center flex-column"
                                  style={{
                                    height: '50%',
                                    backgroundColor: '#ffffff'
                                  }}>
                                  <img
                                    src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/room_illustration.svg"
                                    alt="room_illustration"
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      top: '25px'
                                    }}
                                  />
                                  <h6 className="mt-5">
                                    Capture your room as per reference photo above.
                                  </h6>
                                </div>

                                <div
                                  className={classnames(
                                    'col-12 d-flex justify-content-center align-items-center',
                                    Popupstyle.QrBottomMob
                                  )}
                                  // className="col-12 d-flex justify-content-center align-items-center"
                                  style={{ height: '50%' }}>
                                  <div style={{ width: '90%', margin: 'auto' }}>
                                    <h4 className="mb-3">
                                      Follow these steps to enhance your experience
                                    </h4>
                                    <ul
                                      style={{
                                        listStyle: 'disc',
                                        fontSize: '16px',
                                        fontWeight: '600'
                                      }}>
                                      <li className="mb-2">
                                        These images are for scale and reference for your mood
                                        boards.
                                      </li>
                                      <li className="mb-2">
                                        Please ensure a well-lit photo of the room, at eye level,
                                        from a distance that covers all ground for accurate visual
                                        representation and best results.
                                      </li>
                                      <button
                                        className={Popupstyle.uploadBtn}
                                        onClick={() => {
                                          if (dropzoneRef.current) {
                                            dropzoneRef.current.open();
                                          }
                                        }}>
                                        Upload Image
                                      </button>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fade>
                        </Modal>
                      </>
                    )}
                  </Dropzone>
                </div>
                {/* ))} */}
              </>
            )}

            {/* <div className={classnames("col-6 p-0", style.photos__div)}>
              <div
                className={classnames(
                  "d-flex flex-column justify-content-evenly align-items-center pt-0",
                  style.borderImg
                )}
              >
                <img
                  src={
                    "https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6237%402x.png"
                  }
                  alt=""
                  className={style.thumbImg}
                />
                <img
                  src={
                    "https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6240%402x.png"
                  }
                  alt=""
                  className={style.thumbImg1}
                />
                <div
                  className={classnames("", style.photos__btn)}
                  onClick={handleClickSpace}
                >
                  Drop your photo or video here
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                    required
                  />
                </div>
              </div>
            </div> */}
          </div>

          <div className={classnames('', style.pagediv__margin)}>
            <div style={{ fontSize: '14px' }}>
              <strong> Share pictures of things you would like to retain </strong>
              {uploadError.drop1 ? (
                <span style={{ fontSize: 10, color: 'red' }}>{uploadError.drop1}</span>
              ) : null}
            </div>
          </div>
          {/* <div className={classnames("row p-0 col-12 m-0")}>
            {[0, 1, 2, 3].map((_) => (
              <div className={classnames("col-6 p-0", style.photos__div)}>
                <img
                  src={
                    "https://d3izcfql7lesks.cloudfront.net/Path_1348_qkeivo.png"
                  }
                  alt=""
                />
                <div
                  className={classnames("", style.photos__btn)}
                  onClick={handleClickFurniture}
                >
                  Upload video & photos
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput2}
                  onChange={handleFurnitureChange}
                  style={{ display: "none" }}
                />
              </div>
            ))}
          </div> */}

          <div className={classnames('row p-0 col-12 m-0')}>
            {furnitureImages &&
              furnitureImages.length > 0 &&
              furnitureImages.map(data =>
                data.fileType === 'png' || data.fileType === 'jpeg' || data.fileType === 'jpg' ? (
                  <div
                    className={classnames('col-6 p-0 mt-2', style.photos__div3)}
                    style={
                      {
                        // backgroundImage: `url(${data.img})`,
                        // backgroundRepeat: "no-repeat",
                        // width: "100%",
                      }
                    }
                    onClick={() => {
                      setCurrentSpaceImage(data.img);
                      loadImage(data.img);
                      setModal(2);
                    }}>
                    <img
                      src={data.img}
                      alt=""
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'contain' }}
                    />
                    <div className={classnames('', style.photos__btn2)}>+ Add Comment</div>
                  </div>
                ) : (
                  <div
                    className={classnames(' col-12 mt-2 ', style.photos__div3)}
                    style={{
                      width: '100%'
                    }}>
                    <ReactPlayer url={data.img} width="100%" height="100%" controls />
                    <div
                      style={{
                        width: '95%',
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        padding: '8px',
                        color: '#ffffff',
                        height: '40px',
                        position: 'absolute',
                        bottom: 0,
                        paddingBottom: '10px'
                      }}
                      onClick={() => {
                        setCurrentSpaceImage(data);
                        loadImage(data.img);
                        // setSpaceComment(data.hasOwnProperty('comment') ? data.comment : '')
                        // setAnnotations(data.hasOwnProperty('annotations') ? data.annotations : [])
                        setModal(2);
                      }}>
                      + Add Comment
                    </div>
                  </div>
                )
              )}
            {!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
              <>
                {/* {[0, 1, 2, 3].map((data) => ( */}
                <div className={classnames('col-6 p-0', style.photos__div)}>
                  <Dropzone onDrop={onDrop1} multiple={true} maxFiles={8 - furnitureImages.length}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className={classnames(
                          'd-flex flex-column justify-content-evenly align-items-center p-2',
                          style.dropBox
                        )}>
                        <div
                          className={classnames(
                            'd-flex flex-column justify-content-evenly align-items-center pt-0',
                            style.borderImg
                          )}>
                          <img
                            src={
                              'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6237%402x.png'
                            }
                            alt=""
                            className={style.thumbImg}
                          />
                          <img
                            src={
                              'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6240%402x.png'
                            }
                            alt=""
                            className={style.thumbImg1}
                          />

                          <div className={style.dropTxt}>
                            {/* <button className={style.uploadButton}> */}
                            Drop your photo or video here
                            {/* </button> */}
                            <input {...getInputProps()} />
                          </div>

                          {/* <p className="mt-4 small">Or drop here</p> */}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                {/* ))} */}
              </>
            )}
            {/* <div className={classnames("col-6 p-0", style.photos__div)}>
              <div
                className={classnames(
                  "d-flex flex-column justify-content-evenly align-items-center pt-0",
                  style.borderImg
                )}
              >
                <img
                  src={
                    "https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6237%402x.png"
                  }
                  alt=""
                  className={style.thumbImg}
                />
                <img
                  src={
                    "https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6240%402x.png"
                  }
                  alt=""
                  className={style.thumbImg1}
                />
                <div
                  className={classnames("", style.photos__btn)}
                  onClick={handleClickFurniture}
                >
                  Drop your video & photos
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput2}
                  onChange={handleFurnitureChange}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          <div className={classnames('', style.pagediv__margin)}>
            {/* <div className={classnames("", style.roomwise__heading)}>
              Room-wise Requirements
            </div>
            <div style={{ fontSize: "14px" }}>
              To make sure our design measurements are perfect.
            </div> */}
            <h6
              className={classnames('mt-4', planError ? style.error1 : ' ')}
              style={{ fontSize: '15px' }}>
              <span className="font-weight-800">
                Tell us how you plan to use the space and your thoughts about it in general.*
              </span>
            </h6>
            <span style={{ fontSize: '12px' }}>
              Example: I’d like to see a lot of plants and wooden furniture around me. A TV facing
              the bed to spend my nights comfortable after work would just be perfect!
            </span>

            <div className={style.containerText}>
              <textarea
                // className={style.textField}
                multiline
                placeholder="Tell us here"
                rows={5}
                name="plan"
                value={inputs.plan && inputs.plan.text ? inputs.plan.text : ''}
                // variant="outlined"
                style={{ width: '100%' }}
                // onChange={handleInputs}
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET'))
                    handleInputs(e);
                }}
                required
              />
              <Icon.MicFill
                className={style.microphoneIco1}
                onClick={() => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    setCurrentField('plan');
                    setAudioModal(true);
                    setblobURL('');
                  }
                }}
                // onClick={() => {
                //   setCurrentField("plan");
                //   setAudioModal(true);
                //   setblobURL("");
                // }}
              ></Icon.MicFill>
              {/* {inputs.plan &&
                inputs.plan.audio &&
                (
                  
                )} */}
              {inputs.plan &&
                inputs.plan.audio &&
                !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                  <div>
                    {/* <ReactAudioPlayer
                    name="plan"
                    className={style.audio}
                    src={
                      inputs.plan && inputs.plan.audio ? inputs.plan.audio : ""
                    }
                    controls
                  /> */}
                    <Icon.Trash
                      className={style.Trash}
                      onClick={() => {
                        inputs.plan.audio = '';
                        storeMxB(removeAudio('plan'));
                        setInputs({ ...inputs });
                      }}
                      // onClick={() => {
                      //   // ConsoleHelper("inside trash");
                      //   inputs.plan.audio = "";
                      //   // ConsoleHelper("inside trash", inputs);
                      //   setInputs({ ...inputs });
                      // }}
                    ></Icon.Trash>
                  </div>
                )}
              {inputs.plan && inputs.plan.audio && (
                <div>
                  <ReactAudioPlayer
                    name="plan"
                    className={style.audio}
                    src={inputs.plan && inputs.plan.audio ? inputs.plan.audio : ''}
                    controls
                  />
                </div>
              )}
            </div>
            <h6 className="mt-5 d-none" style={{ fontSize: '15px' }}>
              How would you describe the way you live?
            </h6>
            <div className={style.containerText} style={{ display: 'none' }}>
              <textarea
                multiline
                rows={5}
                value={inputs.live && inputs.live.text ? inputs.live.text : ''}
                variant="outlined"
                style={{ width: '100%' }}
                name="live"
                // onChange={handleInputs}
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET'))
                    handleInputs(e);
                }}
              />
              <Icon.MicFill
                className={style.microphoneIco1}
                onClick={() => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    setCurrentField('live');
                    setAudioModal(true);
                    setblobURL('');
                  }
                }}
                // onClick={() => {
                //   setCurrentField("live");
                //   setAudioModal(true);
                //   setblobURL("");
                // }}
              ></Icon.MicFill>
              {/* {inputs.live &&
                inputs.live.audio &&(
                )} */}
              {inputs.live &&
                inputs.live.audio &&
                !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                  <div>
                    {/* <ReactAudioPlayer
                    name="live"
                    className={style.audio}
                    src={
                      inputs.live && inputs.live.audio ? inputs.live.audio : ""
                    }
                    controls
                  /> */}
                    <Icon.Trash
                      className={style.Trash}
                      // onClick={() => {
                      //   inputs.live.audio = "";
                      //   setInputs({ ...inputs });
                      // }}
                      onClick={() => {
                        inputs.live.audio = '';
                        storeMxB(removeAudio('live'));
                        setInputs({ ...inputs });
                      }}></Icon.Trash>
                  </div>
                )}
              {inputs.live && inputs.live.audio && (
                <ReactAudioPlayer
                  name="live"
                  className={style.audio}
                  src={inputs.live && inputs.live.audio ? inputs.live.audio : ''}
                  controls
                />
              )}
            </div>

            <h6 className="mt-5">
              <strong>The number of people who resides in the house*</strong>
            </h6>

            <div className={style.wholeBtn}>
              <button className={style.buttonStyle} onClick={decrementCount}>
                -
              </button>
              <input
                readOnly
                style={{
                  width: '48px',
                  height: '31px',
                  border: 'none',
                  textAlign: 'center',
                  fontWeight: 600
                }}
                type="number"
                name="no_of_person"
                onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                value={inputs.no_of_person ? inputs.no_of_person : 0}
                onChange={e => {
                  // ConsoleHelper("eeee", e.target);
                  if (e.target.value <= 99 && e.target.value !== 'e') {
                    handleInputs(e);
                  }
                }}
                // className={personsError ? style.error : " "}
              />

              {/* <TextField
                      readOnly
                      style={{
                        width: '48px',
                        height: '31px',
                        border: 'none',
                        textAlign:'center',
                        fontWeight: 600
                      }}
                label=""
                variant="outlined"
                type="number"
                name="no_of_person"
                required
                value={inputs.no_of_person ? inputs.no_of_person : ""}
                onChange={(e) => {
                  ConsoleHelper("eeee", e.target);
                  if (
                    e.target.value <= 99 &&
                    e.target.value !== "e"
                  ) {
                    handleInputs(e)
                  }
                }}
                xs={{border:'none'}}
              /> */}

              <button className={style.buttonStyle} onClick={incrementCount}>
                +
              </button>
            </div>

            {/* <div>
              <TextField
                label=""
                variant="outlined"
                type="number"
                name="no_of_person"
                required
                value={inputs.no_of_person ? inputs.no_of_person : ""}
                onChange={(e) => {
                  ConsoleHelper("eeee", e.target);
                  if (
                    e.target.value <= 99 &&
                    e.target.value !== "e"
                  ) {
                    handleInputs(e)
                  }
                }}
              />
            </div> */}
          </div>

          <div className={classnames('', style.about__cont)}>
            <div className={classnames('', style.about__text)}>Your Inspiration</div>
          </div>
          <h6 className={classnames('mb-5 mt-3')} style={{ fontSize: '13px', lineHeight: '23px' }}>
            Upload pictures, links, Pinterest boards, magazine cutouts, bookmarked furniture, paint
            swatches, everything that makes you giddy with joy.
            {uploadError.drop2 ? (
              <span style={{ fontSize: 10, color: 'red' }}>{uploadError.drop2}</span>
            ) : null}
          </h6>

          {/* <div className={classnames("row p-0 col-12 m-0")}>
            {[0, 1, 2, 3].map((_) => (
              <div className={classnames("col-6 p-0", style.photos__div)}>
                <img
                  src={
                    "https://d3izcfql7lesks.cloudfront.net/Path_1348_qkeivo.png"
                  }
                  alt=""
                />
                <div
                  className={classnames("", style.photos__btn)}
                  onClick={handleClickInspiration}
                >
                  Upload video & photos
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput3}
                  onChange={handleInspirationChange}
                  style={{ display: "none" }}
                  required
                />
              </div>
            ))}
          </div> */}
          <div className="row p-0 m-0">
            {inspirationImages &&
              inspirationImages.map(data =>
                data.fileType === 'png' || data.fileType === 'jpeg' || data.fileType === 'jpg' ? (
                  <div
                    className={classnames('col-12 p-0 mt-2', style.photos__div3)}
                    style={
                      {
                        // backgroundImage: `url(${data.img})`,
                        // backgroundRepeat: "no-repeat",
                        // width: "100%",
                      }
                    }>
                    <img
                      src={data.img}
                      alt=""
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'contain' }}
                    />
                    <div
                      className={classnames('', style.photos__btn2)}
                      onClick={() => {
                        setCurrentSpaceImage(data);
                        loadImage(data.img);
                        // setSpaceComment(data.hasOwnProperty('comment') ? data.comment : '')
                        // setAnnotations(data.hasOwnProperty('annotations') ? data.annotations : [])
                        setModal(2);
                      }}>
                      + Add Comment
                    </div>
                  </div>
                ) : (
                  // <div
                  //   className={classnames("col-12 mt-2 ", style.photos__div3)}
                  //   style={{
                  //     width: "100%",
                  //   }}
                  // >
                  //   <ReactPlayer
                  //     url={data.img}
                  //     width="100%"
                  //     height="100%"
                  //     controls
                  //   />
                  // </div>

                  <div
                    className={classnames(
                      'col-3 d-flex flex-column justify-content-center align-items-center ',
                      style.imageRow
                    )}
                    style={{
                      width: '100%'
                    }}>
                    <ReactPlayer url={data.img} width="100%" height="100%" controls />
                    <div
                      style={{
                        width: '95%',
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        padding: '8px',
                        color: '#ffffff',
                        height: '40px',
                        position: 'absolute',
                        bottom: 0,
                        paddingBottom: '10px'
                      }}
                      onClick={() => {
                        setCurrentSpaceImage(data);
                        loadImage(data.img);
                        // setSpaceComment(data.hasOwnProperty('comment') ? data.comment : '')
                        // setAnnotations(data.hasOwnProperty('annotations') ? data.annotations : [])
                        setModal(2);
                      }}>
                      + Add Comment
                    </div>
                  </div>
                )
              )}
            {!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
              <>
                <div className={classnames('col-6 p-0', style.photos__div)}>
                  <Dropzone
                    onDrop={onDrop2}
                    multiple={true}
                    // maxFiles={8 - furnitureImages.length}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className={classnames(
                          'd-flex flex-column justify-content-evenly align-items-center p-2',
                          style.dropBox
                        )}>
                        <div
                          className={classnames(
                            'd-flex flex-column justify-content-evenly align-items-center pt-0',
                            style.borderImg
                          )}>
                          <img
                            src={
                              'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6237%402x.png'
                            }
                            alt=""
                            className={style.thumbImg}
                          />
                          <img
                            src={
                              'https://pixel-mortar.s3.ap-south-1.amazonaws.com/mxb/Group+6240%402x.png'
                            }
                            alt=""
                            className={style.thumbImg1}
                          />
                          <div className={style.dropTxt}>
                            {/* <button className={style.uploadButton}> */}
                            Drop your photo or video here
                            {/* </button> */}
                            <input {...getInputProps()} />
                          </div>
                          {/* <input
                  type="file"
                  ref={hiddenFileInput3}
                  onChange={handleInspirationChange}
                  style={{ display: "none" }}
                  required
                /> */}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </>
            )}
          </div>
          <div className={classnames('mt-3 mb-3')} style={{ fontSize: '14px' }}>
            <strong>Pinterest</strong>
          </div>
          <TextField
            variant="standard"
            label="Paste link here"
            style={{ width: '100%' }}
            name="collaborator"
            // onChange={handleInputs}
            value={inputs.collaborator ? inputs.collaborator : ''}
            onChange={e => {
              if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                handleInputs(e);
                storeMxB(reduxValue('MX_B_COLLABORATOR', e.target.value));
              }
            }}
          />

          <div
            className={classnames('mt-5 mb-2 d-none', visionError ? style.error1 : ' ')}
            style={{ fontSize: '14px', fontWeight: 'bold' }}>
            What do you have in mind for this project? Tell us more about your vision.*
          </div>
          <div className={style.containerText} style={{ display: 'none' }}>
            <textarea
              variant="outlined"
              multiline
              rows={5}
              className={visionError ? style.error : ' '}
              style={{ width: '100%' }}
              name="project_vision"
              // onChange={handleInputs}
              onChange={e => {
                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) handleInputs(e);
              }}
              value={
                inputs.project_vision && inputs.project_vision.text
                  ? inputs.project_vision.text
                  : ''
              }
            />
            <Icon.MicFill
              className={style.microphoneIco1}
              onClick={() => {
                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                  setCurrentField('project_vision');
                  setAudioModal(true);
                  setblobURL('');
                }
              }}
              // onClick={() => {
              //   setCurrentField("project_vision");
              //   setAudioModal(true);
              //   setblobURL("");
              // }}
            ></Icon.MicFill>
            {/* {inputs.project_vision && inputs.project_vision.audio && (
              )} */}
            {/* {inputs.project_vision && inputs.project_vision.audio && (
              <div>
                <ReactAudioPlayer
                  name="project_vision"
                  className={style.audio}
                  src={
                    inputs.project_vision && inputs.project_vision.audio
                      ? inputs.project_vision.audio
                      : ""
                  }
                  controls
                />
                <Icon.Trash
                  className={style.Trash}
                  onClick={() => {
                    // ConsoleHelper("inside trash");
                    inputs.project_vision.audio = "";
                    ConsoleHelper("inside trash", inputs);
                    setInputs({ ...inputs });
                  }}
                ></Icon.Trash>
              </div>
            )} */}
            {inputs.project_vision &&
              inputs.project_vision.audio &&
              !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                <Icon.Trash
                  className={style.Trash}
                  onClick={() => {
                    inputs.project_vision.audio = '';
                    storeMxB(removeAudio('project_vision'));
                    setInputs({ ...inputs });
                  }}></Icon.Trash>
              )}
            {inputs.project_vision && inputs.project_vision.audio && (
              <ReactAudioPlayer
                name="project_vision"
                className={style.audio}
                src={
                  inputs.project_vision && inputs.project_vision.audio
                    ? inputs.project_vision.audio
                    : ''
                }
                controls
              />
            )}
          </div>

          <div className={classnames('mt-5 mb-5')}>
            <strong>
              That is one good-looking room! <br></br>
              Now let’s jump right in to what you need for your room.
            </strong>
          </div>
          {/* <button
            className={style.wishlist__btn}
            // style={{ marginBottom: "50px", marginTop: "50px" }}
            onClick={() => {
              getWishlistCTA();
            }}>
            Create Wishlist
          </button> */}
          {/* <div className={classnames("", style.wishlist__btn)}>
            Create Wishlist
          </div> */}

          <div className={classnames('mt-5', style.about__cont)}>
            <div className={classnames('', style.about__text)}>Your Style</div>
          </div>

          <h5
            className={classnames('  mt-5')}
            style={{
              fontSize: '17px',
              fontWeight: 'bold',
              lineHeight: '21px'
            }}>
            Select all the items you want help with in your space.
          </h5>
          <span style={{ fontSize: '12px' }}>
            Choose the furniture and decor you want to see in your space
          </span>

          <div className={classnames('row col-12 m-0 p-0 mt-5')}>
            {/* {items.map((data, index) => ( */}
            {furnitureRooms.map((data, index) => (
              <div
                className={classnames('col-6', style.wishlist__item)}
                // onClick={(e) => {
                //   e.preventDefault();
                //   handleClickHome(data.title, "mx_b_rooms", e);
                // }}
                onClick={e => {
                  e.preventDefault();
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    handleClickHome(data.title, 'mx_b_rooms', e);
                  }
                }}>
                {inputs.mx_b_rooms?.includes(data.title) && (
                  <>
                    <img
                      className={style.designClick}
                      src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'}
                      alt="tick"
                    />
                    <div className={style.overLayerRoom}></div>
                  </>
                )}
                <img
                  src={data.image}
                  alt=""
                  style={{
                    objectFit: 'contain',
                    width: '50%',
                    height: '50%'
                  }}
                />
                <div
                  style={{
                    fontSize: '13px',
                    marginTop: '9px',
                    fontWeight: 500,
                    textAlign: 'center'
                  }}>
                  {data.title}
                </div>
              </div>
            ))}
          </div>

          <div style={{ fontSize: '18px', fontWeight: 'bold' }} className={classnames('mt-5')}>
            What are the art styles that speak to you the most
          </div>
          <span style={{ fontSize: '12px' }}>Select a minimum of 3.</span>

          <div className={classnames('row col-12 m-0 p-0 mt-5 mb-5')}>
            {arts.map((data, index) => (
              <div
                className={classnames('col-6', style.art__img)}
                // onClick={(e) => handleClickHome(data.title, "mx_b_artworks")}
                onClick={() => {
                  // ConsoleHelper("idex", data.id, "index", Index, index+1, data.id === index+1 );
                  // setIndex(index);
                  // if(starRating === 5 && data.id === Index + 1 ){
                  //   setStarRating(0);
                  // }
                  // else if(starRating === 0 && data.id === Index + 1){
                  //   setStarRating(5);
                  // }

                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    if (inputs.mx_b_rooms && inputs.mx_b_rooms.length >= 1) {
                      // starRating === 5 &&
                      // ConsoleHelper("inside rating", starRating)
                      // setRating(true);
                      setCustomerRating(data, 'mx_b_artworks', 5);
                    } else {
                      // setPalletsError(true);
                      setSnack(true);
                      // setItemError(true);
                      setSnackMessage('Please select atleast 1 item from items field');
                    }
                  } else {
                    setSnack(true);
                    setSnackMessage('The field cannot be editted');
                  }
                }}>
                <div style={{ position: 'relative' }}>
                  {/* {starRating === 5 && ( data.id === Index + 1) && */}
                  {inputs.mx_b_artworks.findIndex(x => x.title === data.title) !== -1 && (
                    <>
                      <img
                        className={style.materialClick}
                        src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'}
                        alt="tick"
                      />
                      <div className={style.overLayerRoom}></div>
                    </>
                  )}

                  {/* {inputs.mx_b_artworks.findIndex(
                  (x) => x.title === data.title
                ) !== -1
                } */}
                  <img src={data.image} alt="" style={{ width: '100%', borderRadius: '5px' }} />
                </div>
                <span className={style.titleTxt} style={{ marginTop: '5px' }}>
                  {data.title}
                </span>
                {/* <Rating
                  name="artRating"
                  // id={`art-${index}`}
                  emptySymbol={
                    <img
                      src="https://d3izcfql7lesks.cloudfront.net/Path_1252_ixllee.svg"
                      className="pl-3"
                    />
                  }
                  fullSymbol={
                    <img
                      src="https://d3izcfql7lesks.cloudfront.net/Path_1252_dp7bmx.svg"
                      className="pl-3"
                    />
                  }
                  onClick={(value) => {
                    ConsoleHelper("eventtt", value);
                    // event.stopPropagation()
                    setRating(true);
                    setCustomerRating(data, "mx_b_artworks", value);
                    // setArtRate(value)
                  }}
                  initialRating={getRatingValue(data.title)}
                /> */}
              </div>
            ))}
          </div>

          <h5 className="mt-5">
            <span className="font-weight-800">What materials would you like to see?*</span>
          </h5>
          <span style={{ fontSize: '12px' }}>
            Select a minimum of three items to curate your Mood Board.
          </span>

          <div className={classnames('row col-12 m-0 p-0 mt-5 mb-5')}>
            {materials.map((data, index) => (
              <div
                className={classnames('col-6', style.art__img)}
                onClick={e => {
                  // e.preventDefault();
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    if (inputs.mx_b_artworks && inputs.mx_b_artworks.length >= 3) {
                      // setRating(true);
                      setCustomerRating(data, 'mx_b_materials', 5);
                    } else {
                      //setArtsError(true);
                      setSnack(true);
                      setSnackMessage('Please fill atleast 3 arts images');
                    }
                  } else {
                    setSnack(true);
                    setSnackMessage('The field cannot be editted');
                  }
                }}
                // onClick={(e) => {
                //   if (
                //     inputs.mx_b_artworks &&
                //     inputs.mx_b_artworks.length >= 3
                //   ) {
                //     handleClickHome(data.title, "mx_b_materials");
                //   } else {
                //     setSnack(true);
                //     setSnackMessage("Please fill atleast 3 arts images");
                //   }
                // }}
              >
                <div style={{ position: 'relative' }}>
                  {/* {starRating === 5 && ( data.id === Index + 1) && */}
                  {inputs.mx_b_materials.findIndex(x => x.title === data.title) !== -1 && (
                    <>
                      <img
                        className={style.materialClick}
                        src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'}
                        alt="tick"
                      />
                      <div className={style.overLayerRoom}></div>
                    </>
                  )}
                  {/* {inputs.mx_b_materials.findIndex(
                  (x) => x.title === data.title
                ) !== -1
                } */}
                  <img src={data.image} style={{ width: '100%', borderRadius: '5px' }} alt="" />
                </div>
                <span className={style.titleTxt} style={{ marginTop: '5px' }}>
                  {data.title}
                </span>
                {/* <Rating
                  emptySymbol={
                    <img
                      src="https://d3izcfql7lesks.cloudfront.net/Path_1252_ixllee.svg"
                      className="pl-3"
                    />
                  }
                  fullSymbol={
                    <img
                      src="https://d3izcfql7lesks.cloudfront.net/Path_1252_dp7bmx.svg"
                      className="pl-3"
                    />
                  }
                  onClick={(value) => {
                    ConsoleHelper("eventtt", value);
                    // event.stopPropagation()
                    setRating(true);
                    setCustomerRating(data, "mx_b_materials", value);
                  }}
                  initialRating={getMaterialsRatingValue(data.title)}
                /> */}
              </div>
            ))}
          </div>

          <h5 className="mt-5">
            <span className="font-weight-800">
              What colour palette make you feel more at home*{' '}
            </span>
          </h5>
          <span style={{ fontSize: '12px' }}>
            Select a minimum of three items to curate your Mood Board.
          </span>
          <div className={classnames('row col-12 m-0 p-0 mt-5 mb-5')}>
            {palettes.map((data, index) => (
              <div
                className={classnames('col-6', style.art__img)}
                onClick={() => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    if (inputs.mx_b_materials && inputs.mx_b_materials.length >= 3) {
                      // setRating(true);
                      setCustomerRating(data, 'mx_b_palettes', 5);
                    } else {
                      //setMaterialsError(true);
                      setSnack(true);
                      setSnackMessage('Please fill atleast 3 materials images');
                    }
                  } else {
                    setSnack(true);
                    setSnackMessage('The field cannot be editted');
                  }
                }}
                // onClick={(e) => {
                //   if (
                //     inputs.mx_b_materials &&
                //     inputs.mx_b_materials.length >= 3
                //   ) {
                //     handleClickHomePalettes(data, "mx_b_palettes");
                //   } else {
                //     setSnack(true);
                //     setSnackMessage("Please fill atleast 3 materials images");
                //   }
                // }}
              >
                {/* {inputs.mx_b_palettes.findIndex(
                  (x) => x.title === data.title
                ) !== -1
                } */}
                <div style={{ position: 'relative' }}>
                  {/* {starRating === 5 && ( data.id === Index + 1) && */}
                  {inputs.mx_b_palettes.findIndex(x => x.title === data.title) !== -1 && (
                    <>
                      <img
                        className={style.materialClick}
                        src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'}
                        alt="tick"
                      />
                      <div className={style.overLayerRoom}></div>
                    </>
                  )}
                  <img src={data.image} style={{ width: '100%', borderRadius: '5px' }} alt="" />
                </div>
                <span className={style.titleTxt} style={{ marginTop: '5px' }}>
                  {data.title}
                </span>
                {/* <Rating
                  emptySymbol={
                    <img
                      src="https://d3izcfql7lesks.cloudfront.net/Path_1252_ixllee.svg"
                      className="pl-3"
                    />
                  }
                  fullSymbol={
                    <img
                      src="https://d3izcfql7lesks.cloudfront.net/Path_1252_dp7bmx.svg"
                      className="pl-3"
                    />
                  }
                  onClick={(value) => {
                    ConsoleHelper("eventtt", value);
                    // event.stopPropagation()
                    setRating(true);
                    setCustomerRating(data, "mx_b_palettes", value);
                  }}
                  initialRating={getPalettesRatingValue(data.title)}
                /> */}
              </div>
            ))}
          </div>

          <h5 className="mt-5">
            <span className="font-weight-800">Choose all your favourite patterns*</span>
          </h5>
          <span style={{ fontSize: '12px' }}>
            Select a minimum of three items to curate your Mood Board.
          </span>

          <div className={classnames('row col-12 m-0 p-0 mt-5 mb-5')}>
            {patterns.map((data, index) => (
              <div
                className={classnames('col-6', style.art__img)}
                onClick={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    if (inputs.mx_b_palettes && inputs.mx_b_palettes.length >= 3) {
                      handleClickHomePatterns(data, 'mx_b_patterns');
                    } else {
                      //setPalletsError(true);
                      setSnack(true);
                      setSnackMessage('Please fill atleast 3 pallets images');
                    }
                  }
                }}
                // onClick={(e) => {
                //   if (
                //     inputs.mx_b_palettes &&
                //     inputs.mx_b_palettes.length >= 3
                //   ) {
                //     handleClickHome(data.title, "mx_b_patterns");
                //   } else {
                //     setSnack(true);
                //     setSnackMessage("Please fill atleast 3 pallets images");
                //   }
                // }}
              >
                <div style={{ position: 'relative' }}>
                  {/* {inputs.mx_b_patterns?.includes(data.title) && ( */}
                  {inputs.mx_b_patterns.findIndex(x => x.title === data.title) !== -1 && (
                    <>
                      <img
                        className={style.materialClick}
                        src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/blue_tick.png'}
                        alt="tick"
                      />
                      <div className={style.overLayerRoom}></div>
                    </>
                  )}
                  <img
                    style={{
                      objectFit: 'contain',
                      maxWidth: '100%',
                      width: '100%',
                      height: '100%'
                    }}
                    src={data.image}
                    alt=""
                    className={style.oriImg}
                  />
                  {/* <div className={style.hoverImgs}> */}
                  <img
                    style={{
                      objectFit: 'contain',
                      maxWidth: '100%',
                      width: '100%',
                      height: '100%'
                    }}
                    src={data.hoverImg}
                    alt=""
                    className={style.hoverImg}
                  />
                </div>
                <span className={style.titleTxt} style={{ marginTop: '5px' }}>
                  {data.title}
                </span>
                {/* {inputs.mx_b_patterns?.includes(data.title) && (
                  <img
                    className={style.materialClick}
                    src={
                      "https://pixel-mortar.s3.ap-south-1.amazonaws.com/Cover+Images/Group+2339.svg"
                    }
                    alt="tick"
                  />
                )} */}
              </div>
            ))}
          </div>

          <h5 className="mt-5">
            <span className="font-weight-800">
              {' '}
              Is there anything in particular you’d like in your design?
            </span>
          </h5>
          <span style={{ fontSize: '12px' }}>
            Eg: I'd like to have a study table designed with a wooden top and table lamps in my
            living room.
          </span>
          <div className={style.containerText}>
            <textarea
              multiline
              rows={5}
              variant="outlined"
              name="design"
              // onChange={handleInputs}
              onChange={e => {
                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) handleInputs(e);
              }}
              style={{ width: '100%' }}
              value={inputs.design && inputs.design.text ? inputs.design.text : ''}
            />
            {/* <Icon.MicFill
              className={style.microphoneIco1}
              onClick={() => {
                setCurrentField("design");
                setAudioModal(true);
                setblobURL("");
              }}
            ></Icon.MicFill> */}
            <Icon.MicFill
              className={style.microphoneIco1}
              onClick={() => {
                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                  setCurrentField('design');
                  setAudioModal(true);
                  setblobURL('');
                }
              }}></Icon.MicFill>
            {/* {inputs.design && inputs.design.audio && ( */}
            {inputs.design && inputs.design.audio && (
              <div>
                {/* <ReactAudioPlayer
                  name="design"
                  className={style.audio}
                  src={
                    inputs.design && inputs.design.audio
                      ? inputs.design.audio
                      : ""
                  }
                  controls
                /> */}

                {/* <Icon.Trash
                  className={style.Trash}
                  onClick={() => {
                    // ConsoleHelper("inside trash");
                    inputs.design.audio = "";
                    ConsoleHelper("inside trash", inputs);
                    setInputs({ ...inputs });
                  }}
                ></Icon.Trash> */}

                {inputs.design &&
                  inputs.design.audio &&
                  !projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET') && (
                    <Icon.Trash
                      className={style.Trash}
                      onClick={() => {
                        inputs.design.audio = '';
                        storeMxB(removeAudio('design'));
                        setInputs({ ...inputs });
                      }}></Icon.Trash>
                  )}
              </div>
            )}
            {inputs.design && inputs.design.audio && (
              <ReactAudioPlayer
                name="design"
                className={style.audio}
                src={inputs.design && inputs.design.audio ? inputs.design.audio : ''}
                controls
              />
            )}
          </div>

          <h5 style={{ marginTop: '100px' }}>
            <span className="font-weight-800">What are your room’s dimensions?*</span>
          </h5>

          <div className={classnames('mt-3')}>
            <div className={classnames('row m-0 mb-2')}>
              <div className="col-6">Length</div>
              <TextField
                variant="outlined"
                className="col-6"
                type="number"
                placeholder="Ft"
                name="length"
                value={
                  inputs.dimensions && inputs.dimensions.length ? inputs.dimensions.length : ''
                }
                required
                // onChange={(e) =>
                //   setInputs({
                //     ...inputs,
                //     dimensions: {
                //       ...inputs.dimensions,
                //       length: e.target.value,
                //     },
                //   })
                // }
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    setInputs({
                      ...inputs,
                      dimensions: {
                        ...inputs.dimensions,
                        length: e.target.value,
                        file: dimensionFile
                      }
                    });
                    storeMxB(
                      reduxValue('MX_B_DIMENSIONS', {
                        ...inputs.dimensions,
                        width: e.target.value,
                        file: dimensionFile
                      })
                    );
                    // storeMxB({ ...questionnaireData.MxBData, MX_B_DIMENSIONS: { ...questionnaireData.MxBData.MX_B_DIMENSIONS, length: e.target.value } })
                  }
                }}
              />
            </div>

            <div className={classnames('row m-0 mb-2')}>
              <div className="col-6">Width</div>
              <TextField
                variant="outlined"
                className="col-6"
                type="number"
                placeholder="Ft"
                name="width"
                required
                value={inputs.dimensions && inputs.dimensions.width ? inputs.dimensions.width : ''}
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    setInputs({
                      ...inputs,
                      dimensions: {
                        ...inputs.dimensions,
                        width: e.target.value,
                        file: dimensionFile
                      }
                    });
                    storeMxB(
                      reduxValue('MX_B_DIMENSIONS', {
                        ...inputs.dimensions,
                        width: e.target.value,
                        file: dimensionFile
                      })
                    );
                    // storeMxB({ ...questionnaireData.MxBData, MX_B_DIMENSIONS: { ...questionnaireData.MxBData.MX_B_DIMENSIONS, width: e.target.value } })
                  }
                }}
                // onChange={(e) =>
                //   setInputs({
                //     ...inputs,
                //     dimensions: { ...inputs.dimensions, width: e.target.value },
                //   })
                // }
              />
            </div>

            <div className={classnames('row m-0 mb-2')}>
              <div className="col-6">Ceiling Height</div>
              <TextField
                variant="outlined"
                className="col-6"
                type="number"
                placeholder="Ft"
                name="ceiling_height"
                required
                value={
                  inputs.dimensions && inputs.dimensions.ceiling_height
                    ? inputs.dimensions.ceiling_height
                    : ''
                }
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    setInputs({
                      ...inputs,
                      dimensions: {
                        ...inputs.dimensions,
                        ceiling_height: e.target.value,
                        file: dimensionFile
                      }
                    });
                    storeMxB(
                      reduxValue('MX_B_DIMENSIONS', {
                        ...inputs.dimensions,
                        ceiling_height: e.target.value,
                        file: dimensionFile
                      })
                    );
                    // storeMxB({ ...questionnaireData.MxBData, MX_B_DIMENSIONS: { ...questionnaireData.MxBData.MX_B_DIMENSIONS, ceiling_height: e.target.value } })
                  }
                }}
                // onChange={(e) =>
                //   setInputs({
                //     ...inputs,
                //     dimensions: {
                //       ...inputs.dimensions,
                //       ceiling_height: e.target.value,
                //     },
                //   })
                // }
              />
            </div>
          </div>

          <div className="row col-12 mt-4 d-flex flex-column justify-content-center align-items-center">
            <p
              style={{
                fontSize: '15px',
                margin: '5px 0px',
                fontWeight: 600
              }}>
              Upload floor plan file
            </p>
            <button
              className={style.attachFileButton}
              onClick={() => {
                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                  fileInputRef.current.click();
                }
              }}
              // onClick={() => {
              //   fileInputRef.current.click();
              // }}
            >
              <AddIcon />
              Attach File
            </button>
            <input
              onChange={e => {
                handleChangeFile(e);
              }}
              multiple={false}
              ref={fileInputRef}
              accept="application/pdf,image/*"
              type="file"
              style={{ display: 'none' }}
            />
          </div>

          <span className={style.filename}>{dimensionFile.name}</span>

          {/* <div className="mt-4 text-center">
            <span style={{ textDecoration: "underline", fontSize: "12px" }}>
              Use AR App to Measure
            </span>
          </div> */}

          <h5 style={{ marginTop: '60px' }}>
            Any occupants we should keep in mind while designing?
          </h5>

          <div className={classnames('mt-')}>
            <div className={classnames('row m-0 mb-2')}>
              <input
                className="mt-1 mr-1 col-2"
                style={{ width: '20px', height: '20px' }}
                type="checkbox"
                checked={inputs.occupants.includes('elderly')}
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    handleClickHome('elderly', 'occupants');
                    // storeMxB({ ...questionnaireData.MxBData, MX_B_OCCUPANTS: 'elderly' })
                  }
                }}
              />
              {/* <input
                className="mt-1 mr-3 col-2"
                type="checkbox"
                checked={inputs.occupants.includes("elderly")}
                onChange={(e) => handleClickHome("elderly", "occupants")}
              /> */}
              <div className="col-9">Elderly</div>
            </div>
            <div className={classnames('row m-0 mb-2')}>
              {/* <div className="mt-1 mr-3 col-2"> */}
              <input
                className="mt-1 mr-1 col-2"
                style={{ width: '20px', height: '20px' }}
                type="checkbox"
                checked={inputs.occupants.includes('wheelchair')}
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    handleClickHome('wheelchair', 'occupants');
                    // storeMxB({ ...questionnaireData.MxBData, MX_B_OCCUPANTS: 'wheelchair' })
                  }
                }}
              />
              {/* <input
                  className="mt-1 mr-3"
                  style={{ width: "20px", height: "20px" }}
                  type="checkbox"
                  checked={inputs.occupants.includes("wheelchair")}
                  onChange={(e) => handleClickHome("wheelchair", "occupants")}
                /> */}
              {/* </div> */}
              <div className="col-9">Wheelchair-access</div>
            </div>
            <div className={classnames('row m-0 mb-2')}>
              {/* <div className="mt-1 mr-3 col-2"> */}
              <input
                style={{ width: '20px', height: '20px' }}
                className="mt-1 mr-1 col-2"
                type="checkbox"
                checked={inputs.occupants.includes('pets')}
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    handleClickHome('pets', 'occupants');
                    // storeMxB({ ...questionnaireData.MxBData, MX_B_OCCUPANTS: 'pets' })
                  }
                }}
              />
              {/* <input
                  className="mt-1 mr-3"
                  style={{ width: "20px", height: "20px" }}
                  type="checkbox"
                  checked={inputs.occupants.includes("pets")}
                  onChange={(e) => handleClickHome("pets", "occupants")}
                /> */}
              {/* </div> */}
              <div className="col-9">Pets</div>
            </div>
            <div className={classnames('row m-0 mb-2')}>
              {/* <div className="mt-1 mr-3 col-2"> */}
              <input
                className="mt-1 mr-1 col-2"
                style={{ width: '20px', height: '20px' }}
                type="checkbox"
                checked={inputs.occupants.includes('children')}
                onChange={e => {
                  if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                    handleClickHome('children', 'occupants');
                    // storeMxB({ ...questionnaireData.MxBData, MX_B_OCCUPANTS: 'children' })
                  }
                }}
              />
              {/* <input
                  className="mt-1 mr-3"
                  style={{ width: "20px", height: "20px" }}
                  type="checkbox"
                  checked={inputs.occupants.includes("childrens")}
                  onChange={(e) => handleClickHome("childrens", "occupants")}
                /> */}
              {/* </div> */}
              <div className="col-9">Small Children (under 10 years)</div>
            </div>
          </div>

          <h5
            // ref={budgetRef}
            style={{ marginTop: '100px', marginBottom: '30px' }}
            className="font-weight-800">
            You’re nearly done! Let’s discuss your budget.
          </h5>

          {/* <div className={classnames("", style.wishlist__btn)}>
            Go to budget
          </div> */}
          {/* <button
            className={style.wishlist__btn}
            onClick={() => {
              getBudgetCTA();
            }}>
            Go to Budget
          </button> */}

          <div className={classnames('mt-5', style.about__cont)}>
            <div className={classnames('', style.about__text)}>Your Budget</div>
          </div>

          <h5 style={{ marginTop: '100px' }}>
            <span className="font-weight-800">
              {' '}
              Before we get into specifics what’s your tentative budget?*
            </span>
          </h5>

          <small>
            This range gives us a framework to work within and guide our shopping decisions. You can
            modify the amount whenever if needed.
          </small>

          <div
            className="row mt-5"
            style={{ justifyContent: 'space-between', padding: '0px 20px' }}>
            <p>Min: 0</p>
            <p>Max: 10,00,000</p>
          </div>

          <div className="d-flex flex-column w-100 ">
            <PrettoSlider
              // valueLabelDisplay="auto"
              aria-label="pretto slider"
              min={0}
              max={1000000}
              value={inputs.tentative_budget}
              type="number"
              name="tentative_budget"
              onChange={(event, value) => {
                if (!projectQuestionnaire.hasOwnProperty('MX_B_TENTATIVE_BUDGET')) {
                  setInputs({ ...inputs, tentative_budget: value });
                  storeMxB(reduxValue('tentative_budget', value));
                } else {
                  setSnack(true);
                  setSnackMessage('The field cannot be editted');
                }
              }}
            />
            {/* <PrettoSlider
              ValueLabelComponent={ValueLabelComponent}
              track={false}
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              min={0}
              max={1000000}
              defaultValue={20}
              type="number"
              required
              value={inputs.tentative_budget ? inputs.tentative_budget : ""}
              name="tentative_budget"
              //   onChange={(event, value) =>
              //     setInputs({ ...inputs, tentative_budget: value })
              //   }
              onChange={(event, value) => {
                ConsoleHelper(inputs);
                setInputs({ ...inputs, tentative_budget: value });
              }}
            /> */}
            <div style={{ width: '150px' }} className={style.priceButton}>
              {new Intl.NumberFormat('en-IN').format(inputs.tentative_budget)}
            </div>
          </div>

          <h5 className="mt-5 d-none">
            <span className="font-weight-800">
              {' '}
              Would you like to tell us something more about your budget?{' '}
            </span>
            (optional)
          </h5>
          <div className={style.containerText} style={{ display: 'none' }}>
            <textarea
              multiline
              rows={5}
              variant="outlined"
              style={{ width: '100%' }}
              type="number"
              name="budget"
              value={inputs.budget && inputs.budget.text ? inputs.budget.text : ''}
              onChange={handleInputs}
            />
            <Icon.MicFill
              className={style.microphoneIco1}
              onClick={() => {
                setCurrentField('budget');
                setAudioModal(true);
                setblobURL('');
              }}></Icon.MicFill>
            {inputs.budget && inputs.budget.audio && (
              <div>
                <ReactAudioPlayer
                  name="budget"
                  className={style.audio}
                  src={inputs.budget && inputs.budget.audio ? inputs.budget.audio : ''}
                  controls
                />
                <Icon.Trash
                  className={style.Trash}
                  onClick={() => {
                    inputs.budget.audio = '';
                    setInputs({ ...inputs });
                  }}></Icon.Trash>
              </div>
            )}
          </div>

          <div className={classnames('mt-5', style.about__cont)}>
            <div className={classnames('', style.about__text)}>Your Contact </div>
          </div>
          <h5 style={{ marginTop: '100px' }}>
            <span className="font-weight-800"> Please leave us with your Contact Information</span>
          </h5>

          <small>
            Our designer will use this number to personally reach out to you. We prefer a WhatsApp
            number if you have one{' '}
          </small>
          <h6 className="mt-5">Enter Phone Number</h6>

          <div
            className="row mt-5"
            style={{ justifyContent: 'space-between', padding: '0px 20px' }}>
            <PhoneInput
              countryCodeEditable={false}
              country={'in'}
              value={inputs.phone_number}
              style={{ width: '100%' }}
              name="phone_number"
              onChange={(value, e) => {
                if (
                  !projectQuestionnaire.hasOwnProperty('MX_B_PHONE_NUMBER') &&
                  !projectQuestionnaire.hasOwnProperty('MX_B_COUNTRY_CODE')
                  // &&
                  // isPossiblePhoneNumber('+'+value, e.countryCode.toUpperCase()) === true
                ) {
                  setInputs({
                    ...inputs,
                    phone_number: value,
                    country_code: e.dialCode
                  });
                  storeMxB(reduxValue('phone_number', value, 'country_code', e.dialCode));
                }
                // else {
                //   setSnack(true);
                //   setSnackMessage("Enter a valid phone numbersss");
                // }
              }}

              // onChange={(value, e) => {
              //   // if  ( e.target.value.length<=10){
              //   setInputs({ ...inputs, phone_number: value, country_code: e.dialCode });
              //   // }
              //   // else {
              //   //   setSnack(true);
              //   //   setSnackMessage("Enter a valid phone number");
              //   // }
              // }}
              // value={this.state.phone}
              // onChange={phone => this.setState({ phone })}
            />

            {/* <TextField
                variant="outlined"
                className="col-6"
                            type="number"
                            style={{ width: "50%", height: "50px" }}
                            
                           name="phone_number"
                         
                            value={
                              inputs.phone_number
                              
                            }
                            onChange={( e,value) => {
                            if  (  e.target.value.length<=10){
                              setInputs({ ...inputs, phone_number: e.target.value });
                            }
                            else {
                              setSnack(true);
                              setSnackMessage("Enter a valid phone number");
                            }
                            }
                            
                          }
                           
                          /> */}
          </div>

          <h5 className="font-weight-800" style={{ marginTop: '100px' }}>
            Yay! Everything looks good!{' '}
          </h5>
          <h5 className="font-weight-800">Now kick back and relax while we get to work!</h5>

          {/* <Link to={{ pathname: '/rooms', state: { type: 'concept' } }}> */}
          <button
            className={classnames('', style.wishlist__btn)}
            onClick={e => {
              ConsoleHelper(e);
              // if (inputs.phone_number && inputs.phone_number.length >= 10) {
              handleSubmit(e);
              // } else {
              //   setSnack(true);
              //   setSnackMessage('Enter Valid Phone Number');
              // }
            }}>
            Begin my project
          </button>
          {/* </Link> */}
        </div>
      </div>
      <Modal
        className={style.modal}
        aria-labelledby="simple-modal-title"
        onClose={() => setModal(0)}
        aria-describedby="simple-modal-description"
        open={modal === 1}>
        <div className={style.modalContainer}>
          <div className={style.modal_container}>
            <h6 style={{ width: '70%', textAlign: 'center', marginTop: '3%' }}>
              Communication is key. Here are some things we need you to send us to{' '}
              <b>complete your room profile.</b>
            </h6>
          </div>
          <h4 className="mt-4 font-weight-800">Things you will need</h4>
          <div className="d-flex flex-row justify-content-around align-items w-80 mt-4">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                style={{ width: '25%' }}
                src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+2984.svg'}
                alt=""
              />
              <p>Smart Phone</p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                style={{ width: '30%' }}
                src={
                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/noun_Ruler_2257287.svg'
                }
                alt=""
              />
              <p>Measuring Tape</p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                style={{ width: '30%' }}
                src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+2244.svg'}
                alt=""
              />
              <p>Inspirations</p>
            </div>
          </div>
          <button
            style={{ marginTop: '2%' }}
            className={style.wishlistButton}
            onClick={() => setModal(0)}>
            Get Started
          </button>
        </div>
      </Modal>
      <Modal
        className={style.modal}
        aria-labelledby="simple-modal-title"
        onClose={() => setModal(0)}
        aria-describedby="simple-modal-description"
        open={modal === 2}>
        <div className={style.modalContainer1}>
          <div
            className="d-flex justify-content-between align-items-center w-100 p-2"
            style={{
              borderBottom: '1px solid rgba(0,0,0,0.2)',
              backgroundColor: '#fff'
            }}>
            <h5 className="m-0 p-0">COMMENTS</h5>
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'}
              onClick={() => setModal(0)}
              alt=""
            />
          </div>
          <div className="row w-100">
            <div
              className="d-flex justify-content-start align-items-center m-3"
              style={{ height: '5vh' }}>
              <img
                src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3157.svg'}
                atl=""
              />
              <p className="m-0 ml-2">Markup</p>
            </div>
          </div>
          <div className={classnames('p-2', style.dot)}>
            <Annotation
              src={currentSpaceImage}
              // className={style.annotateImg}
              alt="space"
              onClick={() => {
                setModal(0);
              }}
              annotations={
                currentSpaceImage.hasOwnProperty('annotations')
                  ? currentSpaceImage.annotations
                  : annotations
              }
              onAnnotationsUpdate={setAnnotations}
              allowedShapes={['Oval', 'Rectangle']}
            />
          </div>
          <div className="mt-2 p-2 row w-100 d-flex justify-content-end align-items-end">
            <textarea
              style={{ width: '100%', borderRadius: '10px', height: '100px' }}
              placeholder="Please add your comment here "
              onChange={e => {
                setSpaceComment(e.target.value);
              }}
              value={
                currentSpaceImage.hasOwnProperty('comment')
                  ? currentSpaceImage.comment
                  : spaceComment
              }></textarea>
          </div>
          <div className="row">
            <button
              className={style.wishlistButton}
              style={{ width: '100px' }}
              onClick={() => {
                // ConsoleHelper("inside submit");
                submitSpaceComment();
                setModal(0);
              }}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        className={style.modal}
        aria-labelledby="simple-modal-title"
        onClose={() => setAudioModal(false)}
        aria-describedby="simple-modal-description"
        open={audioModal === true}>
        <div className={style.modalContainer} style={{ paddingTop: '10px' }}>
          <div className="col-12 d-flex justify-content-end">
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'}
              onClick={() => setAudioModal(false)}
              alt=""
            />
          </div>
          <AudioReactRecorder
            canvasHeight={startAudio ? 200 : 0}
            canvasWidth={startAudio ? 300 : 0}
            className={style.audioContainer}
            state={recordstate}
            onStop={onStop}
          />
          {/* <ReactAudioPlayer src={blobURL} controls /> */}
          <div className="row ">
            <div className="col-4 d-flex justify-content-start p-3">
              <button
                style={{ marginTop: '2%' }}
                className={classnames(style.wishlistButton, '')}
                onClick={start}>
                Start
              </button>
              <br></br>
            </div>
            <div className="col-4 d-flex justify-content-end p-3">
              <button
                style={{ marginTop: '2%' }}
                className={classnames(style.wishlistButton, '')}
                onClick={stop}>
                Stop
              </button>
              <br></br>
            </div>
            <div className="col-4 d-flex justify-content-end p-3">
              <button
                style={{ marginTop: '2%' }}
                className={classnames(style.wishlistButton, '')}
                onClick={reset}>
                Reset
              </button>
              <br></br>
            </div>
          </div>
          <ReactAudioPlayer src={blobURL} controls />
        </div>
      </Modal>
      <Footer />
    </>
  );
};

RoomProfileXl.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  rooms: state.questionnaireData.data,
  questionnaireData: state.questionnaireData
});

export default connect(mapStateToProps, { storeMxB })(withRouter(RoomProfileXl));
function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
