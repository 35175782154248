import React from 'react';
import AuthSamplePage from './pages/authsamplepage';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Mainbuilder from './authmainbuilder';
import PrivateRoute from './utils/privateRoute';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearError } from './store/actions/authapi';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { gapi } from 'gapi-script';
import Signup from './components/Auth/Signup';
import HomePage from './pages/Home/HomePage';
import Pricing from './pages/Pricing/Pricing';
import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import ExploreTags from './pages/Explore/ExploreTags';
import ExploreCategory from './pages/Explore/ExploreCategory';
import ExploreTagsCategory from './pages/Explore/ExploreTagsCategory';
import ExploreMoodboards from './pages/Explore/ExploreMoodboards';
import ExploreTagsMoodboards from './pages/Explore/ExploreTagsMoodboards';
import ExploreDetail from './pages/Explore/ExploreDetail';
import Reviews from './pages/Reviews/Reviews';
import Giftcards from './pages/Giftcards/Giftcard';
import AboutUs from './pages/Aboutus/Aboutus';
import Careers from './pages/Careers/Careers';
import ProductReframe from './pages/Explore/ProductReframe';
import ShopPage from './pages/Shop/ShopPage';
import Cart from './pages/Cart/Cart';
import BecomeAPartner from './pages/BecomeAPartner/BecomeAPartner';
import Order from './pages/Orders/Order';
import Wallet from './pages/Orders/Wallet';
import DesignDairies from './pages/DesignDairies/DesignDairies';
import ComingSoon from './pages/DesignDairies/ComingSoon';
import ErrorPage from './pages/DesignDairies/ErrorPage';
import UnderMaintain from './pages/DesignDairies/UnderMaintain';
import CareerApply from './pages/Careers/CareerApply';
import GiftCheckout from './pages/Giftcards/GiftCheckout';
import Referral from './pages/Referral/Referral';
import ReferralInduction from './pages/Referral/ReferralInduction';
import InteriorDesign from './components/layout/InteriorDesign';
import QueryForm from './pages/Help/QueryForm';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import StyleResult from './components/layout/StyleResult';
import ShopDetails from './pages/Shop/ShopDetails';
import ProductList from './pages/Shop/ProductList';
import ProductListBudget from './pages/Shop/ProductListBudget';
import QRScanner from './components/layout/QrScanner';
import Ar3dMobileView from './components/layout/Ar3dMobileView';
import AuthGoogleLogin from './components/Auth/GoogleLogin';
import AuthPasswordless from './components/Auth/AuthPasswordless';
import Errorpage from './pages/DesignDairies/ErrorPage';
import RoomModel from './components/layout/Room3dView';
import CuratedPage from './pages/CuratedDesigns/CuratedPage';
import HireADesigner from './pages/HireADesigner/HireADesigner';
import shopbybrandfilter from './pages/Shop/shopbybrandfilter';
import ProductBrandList from './pages/Shop/ProductBrandList';
import ProductSearchList from './pages/Shop/ProductSearchList';
import Categories from './pages/Shop/Categories';
import MainProductlist from './pages/Shop/Mainproductlist';

const App = ({ auth, history, clearError }) => {
  return (
    <>
      <Switch>
        <Route path={['/questionnaire/:type/:id']} component={AuthSamplePage} />
        <Route path="/signup" component={Signup} />
        <Route exact path="/login" component={Login} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/share-style-result/:userId/:styleResultId" component={StyleResult} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route exact path="/cart" component={Cart} />
        <Route path="/reset-password/:id" component={ResetPassword} />
        <Route path="/reset-password?token=:token" component={ResetPassword} />
        <Route path="/product/:id" component={ProductReframe} />
        <Route path="/reframeproduct/:id" component={ProductReframe} />
        <Route path="/signup?code=" component={Signup} />
        <Route exact path="/curated-designs" component={CuratedPage} />
        <Route exact path="/explore" component={ExploreTags} />
        <Route exact path="/explore/:category" component={ExploreCategory} />
        <Route exact path="/explore/:category/:categorytype" component={ExploreMoodboards} />
        <Route exact path="/explore-tag/:tags/:tagid" component={ExploreTagsCategory} />
        <Route exact path="/explore-tag/:tags" component={ExploreTagsMoodboards} />
        <Route path="/pre-explore-detail/:id" component={ExploreDetail} />
        <Route path="/explore-detail/:id" component={ExploreDetail} />
        <Route exact path="/pre-design-dairies" component={DesignDairies} />
        <Route exact path="/coming-soon" component={ComingSoon} />
        <Route exact path="/error-page" component={ErrorPage} />
        <Route exact path="/under-maintain" component={UnderMaintain} />
        <Route path="/reviews" component={Reviews} />
        <Route path="/3droom/:id" component={RoomModel} />
        <Route path="/become-partner" component={BecomeAPartner} />
        <Route path="/gifts" component={Giftcards} />
        <Route path="/order/:id" component={Order} />
        <Route path="/wallet" component={Wallet} />
        <Route path="/gift-checkout" component={GiftCheckout} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/careers" component={Careers} />
        <Route path="/careers-apply" component={CareerApply} />
        <Redirect exact from="/" to="/home" />
        <Route path="/home" component={HomePage} />
        <Route path="/referral" component={Referral} />
        <Route path="/referral-new" component={ReferralInduction} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/pre-questionnaire" component={InteriorDesign} />
        <Route path="/questionnaire" component={AuthSamplePage} />
        <Route path="/code/:productId" component={QRScanner} />
        <Route path="/3d-model/:productId" component={Ar3dMobileView} />
        <Route path={['/questionnaire/:type']} component={AuthSamplePage} />
        <Route exact path="/shop" component={ShopPage} />
        <Route exact path="/mainproduct" component={MainProductlist} />
        <Route path = "/categories" component={Categories}/>
        <Route
          path="/shop-details/:roomtype/:stylestatus/:styletype?/:brand?"
          render={(props) => {
            const {
              match: {
                params: { roomstylestatus, stylestatus, styletype }
              }
            } = props;
            return (
              <ShopDetails
                key={`roomstylestatus=${roomstylestatus}&stylestatus=${stylestatus}&styletype=${styletype}`}
                {...props}
              />
            );
          }}
        />{' '}
        <Route
          path="/shopbrand-details/:roomtype/:stylestatus/:brand?"
          render={(props) => {
            const {
              match: {
                params: { roomstylestatus, stylestatus, brand }
              }
            } = props;
            return (
              <ShopDetails
                key={`roomstylestatus=${roomstylestatus}&stylestatus=${stylestatus}&brand=${brand}`}
                {...props}
              />
            );
          }}
        />
        <Route path="/shopbybrandFilter/:roomtype/:brand" component={shopbybrandfilter} />
        <Route path="/product_brand_list/:brand?/:id" component={ProductBrandList} />
        <Route path="/productsearch" component={ProductSearchList} />
        <Route
          path="/product_list_budget/:category/budget=min::min&max::max"
          component={ProductListBudget}
          exact
        />
        <Route
          path="/product_list/:roomtype/:category/:styletype?/:brand?/:id?"
          component={ProductList}
        />
        {/* <Route
          path="/product_list/:category/:brand?"
          component={ProductList}
        /> */}
        <Route path="/productlist/:roomtype/:category/:tag" component={ProductList} />
        <Route path="/hire-designer" component={HireADesigner} />
        <Route path="/query" component={QueryForm} />
        <Route path="/auth/callback/google" component={AuthGoogleLogin} />
        <Route
          path="/login/preAuthSessionId=:preAuthSessionId/deviceId=:deviceId/redir=:redirectLink"
          component={AuthPasswordless}
        />
        <PrivateRoute path="/" component={Mainbuilder} />
        <PrivateRoute path="/home" component={HomePage} />
        <PrivateRoute exact path="/" component={HomePage} />
        <Route exact path="*" component={Errorpage} />
      </Switch>
    </>
  );
};
App.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

gapi.load('client:auth2', () => {
  gapi.client.init({
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    plugin_name: 'chat'
  });
});
export default connect(mapStateToProps, { clearError })(withRouter(App));
