import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import style from './Signup.module.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser, loginUser, clearError, logoutUser } from '../../store/actions/authapi';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gooleLogo from '../../assets/images/googlelogo.png';
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-web-js/recipe/thirdparty';
import ConsoleHelper from '../../utils/ConsoleHelper';

const Login = ({ history, auth, loginUser, clearError, location, questionnaireData }) => {
  const history1 = useHistory();
  const dimension = useMediaQuery();
  const [snack, setSnack] = useState(false);
  const snackMessage = '';
  const [inputs, setInputs] = useState({});
  const [login, setLogin] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  // var redirectFrom;
  // if (history1 && history1.location && history1.location.state) {
  //   redirectFrom = history1.location.state;
  // }

  async function googleSignInClicked() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        providerId: 'google',

        // This is where Google should redirect the user back after login or error.
        // This URL goes on the Google's dashboard as well.
        // authorisationURL:
        //   "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow",
        authorisationURL: `${process.env.REACT_APP_URL}/auth/callback/google`
      });

      /*
        Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
        */

      // we redirect the user to google for auth.
      // window.open(authUrl, "_blank");
      window.location.assign(authUrl);
      // history.push(authUrl);
    } catch (err) {
      ConsoleHelper(err);
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  const handleSubmitLogin = () => {
    let mx = {};
    let redirectedFrom = false;
    let mxAnsData = {};
    clearError();
    setLoading(true);
    let redirect = JSON.parse(localStorage.getItem('RedirectToLogin'));
    if (
      (redirect && redirect === true) ||
      (questionnaireData &&
        questionnaireData.hasOwnProperty('MxAns') &&
        questionnaireData.MxAns.hasOwnProperty('questionnaire') &&
        questionnaireData.MxAns.questionnaire.hasOwnProperty('purchased') &&
        questionnaireData.MxAns.questionnaire.purchased === false)
    ) {
      if (questionnaireData && questionnaireData.hasOwnProperty('MxAns')) {
        mx = { ...questionnaireData.MxAns };
        redirectedFrom = true;
        mxAnsData = { ...mx };
        loginUser(login, history, location.state, redirectedFrom, mxAnsData, () => {
          setLoading(false);
        });
      }
    } else {
      loginUser(login, history, location.state, redirectedFrom, mxAnsData, () => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && auth.isAuthenticated && auth.token) {
      setLoading(false);
      history.push('/');
    } else if (!token) {
      logoutUser(history);
    }
    if (auth.errors?.type === 'ERROR') {
      setLoading(false);
      setTimeout(() => {
        alert(auth.errors?.message);
      }, 500);
      clearError();
    }
  }, [auth]);

  const handleChangeLogin = e => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'email') {
      setInputs({
        ...inputs,
        [name]: value
      });
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(value) === false) {
        setError('email');
      } else {
        setError('');
      }
    }
    setLogin({
      ...login,
      [name]: value
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {loading && (
        <div style={{ position: 'fixed', top: '40%', left: '50%', opacity: 1 }}>
          <Loader type="ThreeDots" visible={true} color="#000000" height={100} width={100} />
        </div>
      )}
      <div
        className={style.wholeContainer}
        style={{
          opacity: loading ? 0.5 : 1
        }}>
        <Header />
        <div className={classnames('container-fluid')}>
          <div className="row mt-0">
            <div className={classnames('col-lg-6 ', style.imgContainer)}></div>

            <div className={classnames('col-lg-6', style.container)}>
              <div
                className={classnames(
                  'container  pl-1 pr-1 d-flex flex-column align-items-left justify-content-center',
                  dimension[0] > 2000 ? style.innerContainer1 : style.innerContainer
                )}
                style={{ height: dimension[1] - 80 }}>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleSubmitLogin();
                  }}>
                  <h2 className={style.signup_text}>Login</h2>
                  <p
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline'
                    }}
                    onClick={() => history1.push('/signup')}>
                    or create an account
                  </p>
                  <input
                    className={style.signup_input}
                    name="email"
                    value={login.email || ''}
                    onChange={handleChangeLogin}
                    required
                    placeholder="Email"
                  />
                  <div>
                    {error === 'email' && (
                      <span style={{ color: 'red', fontSize: '11px' }}>Enter a valid mail</span>
                    )}
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      width: dimension[0] > 800 ? 'max-content' : 'auto'
                    }}>
                    <input
                      className={style.signup_input}
                      maxLength="60"
                      name="password"
                      value={login.password || ''}
                      onChange={handleChangeLogin}
                      required
                      type={(!showPassword && 'password') || 'text'}
                      placeholder="Enter Password"
                    />
                    <span style={{ marginLeft: '-3em' }}>
                      {(!showPassword && (
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      )) || (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <div style={{ width: dimension[0] > 500 ? '411px' : '100%' }}>
                    <span
                      style={{
                        float: 'right',
                        textDecoration: 'underline',
                        fontWeight: 900,
                        cursor: 'pointer',
                        color: 'black'
                      }}
                      onClick={() => history1.push('/forgot-password')}>
                      Forgot password
                    </span>
                  </div>
                  <button className={style.signup_button} type="submit">
                    LOGIN
                  </button>
                  <div>
                    <button
                      className={style.signupwithgoogle_button}
                      style={{ cursor: 'pointer' }}
                      type="button"
                      onClick={() => {
                        googleSignInClicked();
                      }}>
                      <img src={gooleLogo} alt="" /> Sign in with Google
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  questionnaireData: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, {
  registerUser,
  loginUser,
  clearError,
  logoutUser
})(withRouter(Login));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
