import { combineReducers } from 'redux';

// import authReducer from "./auth";
import sampleReducer from './sample';
import questionnaireReducer from './questionnaire';
import auth from './auth';
import customerReducer from './customer';

const reducer = combineReducers({
  sample: sampleReducer,
  questionnaireData: questionnaireReducer,
  auth: auth,
  customer: customerReducer
});
export default reducer;
