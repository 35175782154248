import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../store/actions/authapi';
import { connect } from 'react-redux';

const requestHandler = request => {
  const authToken = localStorage.getItem('token');
  request.headers.Authorization = authToken;
  return request;
};

const responseHandler = response => {
  if (response.status === 401) {
    alert('session timed out please login');
    localStorage.removeItem('token');
    localStorage.removeItem('cartProducts');
    localStorage.removeItem('userName');
    window.location = '/login';
  } else {
    return response;
  }
};

const errorHandler = error => {
  return Promise.reject(error);
};

const customerApiInstance = axios.create({
  headers: {
    'content-type': 'application/json'
  }
  // timeout: 10000
});

customerApiInstance.interceptors.request.use(
  request =>
    // handleParameter(request.url, request.data, request),
    requestHandler(request),
  error => errorHandler(error)
);

customerApiInstance.interceptors.response.use(
  response => responseHandler(response),
  error => {
    if (
      error &&
      error.hasOwnProperty('response') &&
      error.response.hasOwnProperty('status') &&
      error.response.status === 401
    ) {
      //   alert('session timed out please login');
      //   localStorage.removeItem('token');
      //   localStorage.removeItem('cartProducts');
      //   localStorage.removeItem('userName');
      //   window.location = '/login';
    }
    let errorMessage = {
      message: 'Something went wrong'
    };
    if (error && error.hasOwnProperty('response') && error.response.hasOwnProperty('statusText')) {
      errorMessage = error.response.statusText;
    }
    return errorHandler(errorMessage);
  }
);

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, { logoutUser })(withRouter(customerApiInstance));
