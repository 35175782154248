import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Questionaire from '../components/layout/Questionairelayout';
import Questionaire2 from '../components/layout/Questionaaire2';
import Package from '../components/layout/Package';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  questionnaire,
  storeTab,
  storeMx,
  page,
  page2,
  storeMxAns
} from '../store/actions/sampleaction';

const AuthSamplePage = ({
  active,
  questionnaire,
  questionnaireData,
  auth,
  storeTab,
  storeMx,
  page,
  page2,
  storeMxAns
}) => {
  const history = useHistory();
  const { id } = useParams();
  let currentProjectId = id;

  useEffect(() => {
    let parentSite = '';
    if (history.location.state && history.location.state.hasOwnProperty('parentPage')) {
      parentSite = history.location.state.parentPage;
      questionnaire(questionnaireData.tab);
    } else if (parentSite === '') {
      storeTab('questionnaire');
      page(0);
      page2(0);
      questionnaire('questionnaire');
    }
  }, []);
  const data = [
    {
      title: 'Click on the images that speak to you.',
      content: 'Please select five or more spaces to understand your style better',
      image: '',
      type: 'space'
    },
    {
      title: 'What style feels most like you?',
      content: 'You can choose more than one.',
      image: '',
      type: 'style'
    },
    {
      title: 'Which colour tones do you prefer for your home?*',
      image: '',
      type: 'color'
    },
    // { title: 'How much do you know about interior design?*', type: 'design' },
    { title: 'Which rooms need work?', type: 'rooms' },
    { title: 'Select the rooms you want us to design?*', type: 'intialRoom' },
    {
      title: 'Your Name*',
      subtitle: 'Now that we’ve got the basics out of the way, it’s nice to meet you.',
      type: 'name'
    },
    {
      type: 'lottie_welcome'
    },
    {
      title: 'Your Email id*',
      subtitle: 'Now that we’ve got the basics out of the way, it’s nice to meet you.',
      type: 'email'
    },
    {
      type: 'lottie_wait'
    },
    {
      title:
        'Based on all the information gathered, we’ve deduced that your main style is Bohemian',
      type: 'result'
    },
    {
      type: 'lottie_fewmore'
    }
  ];

  const data2 = [
    // { title: "What kinda home do you live in?", type: "home" },
    // { title: "Do you rent or own Your place?", type: "hometype" },
    // { title: "Where is your home located? ", type: "location" },
    // { title: "What stage is your home or space currently at?", type: "stage" },
    { title: 'When do you need your room to be ready?', type: 'room' },
    // {
    //   title: "Let’s discuss numbers. What’s your budget like?",
    //   type: "budget",
    // },
    { title: '', type: '' }
  ];

  return (
    <div>
      {active === 'questionnaire' && (
        <Questionaire data={data} locationparam={history.location} historyAction={history.action} />
      )}
      {active === 'questionnaire2' && (
        <Questionaire2
          data={data2}
          locationparam={history.location}
          historyAction={history.action}
        />
      )}
      {active === 'package' && (
        <Package
          navbar={true}
          topbar={true}
          header={true}
          locationparam={history.location}
          historyAction={history.action}
          pagetype={'package'}
          currentProjectId1={currentProjectId}
        />
      )}
    </div>
  );
};

AuthSamplePage.propTypes = {
  active: PropTypes.string,
  auth: PropTypes.object.isRequired,
  questionnaireData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  active: state.questionnaireData.active,
  questionnaireData: state.questionnaireData
});

export default connect(mapStateToProps, {
  questionnaire,
  storeTab,
  storeMx,
  page,
  page2,
  storeMxAns
})(AuthSamplePage);
