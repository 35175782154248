import React from 'react';
import style from './Review.module.css';
import Box from '@material-ui/core/Box';
import Rating from 'react-rating';

const RatingBox = ({
  setFinalModal,
  finalModal,
  setProjectRating,
  projectRating,
  flag,
  setDesignerRating,
  designerRating,
  disable
}) => {
  return (
    <>
      <Box
        color="white"
        bgcolor="#F5F0EC"
        className={finalModal === false ? style.box2 : style.box1}>
        <div
          className="row d-flex justify-content-center"
          style={{ marginRight: '0px', marginLeft: '0px' }}>
          <span className={style.textBox}>How would you like to rate the overall experience?</span>
        </div>
        <div className="row mt-1 d-flex align-items-center justify-content-center">
          <Rating
            quiet={disable.finaliseRate}
            readonly={disable.finaliseRate}
            name="moodboardRating"
            emptySymbol={
              <img
                src="https://d3izcfql7lesks.cloudfront.net/Path_1252_ixllee.svg"
                style={{ width: '40px' }}
                className="pl-3"
                alt="star"
              />
            }
            fullSymbol={
              <img
                src="https://d3izcfql7lesks.cloudfront.net/Path_1252_dp7bmx.svg"
                style={{ width: '40px' }}
                className="pl-3"
                alt="star"
              />
            }
            onClick={value => {
              if (flag === 'project' && !disable.finaliseRate) {
                setProjectRating(value);
                setFinalModal(true);
              }
              if (flag === 'projectpop' && !disable.finaliseRate) {
                setProjectRating(value);
              }
              if (flag === 'designer' && !disable.finaliseRates) {
                setDesignerRating(value);
              }
            }}
            initialRating={
              flag === 'project'
                ? projectRating
                : flag === 'projectpop'
                ? projectRating
                : flag === 'designer'
                ? designerRating
                : 0
            }
          />
        </div>
      </Box>
    </>
  );
};
export default RatingBox;
