import React, { useEffect, useState, useLayoutEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import { useParams } from 'react-router-dom';
import style from './Payment.module.css';
import classnames from 'classnames';
import axios from 'axios';
import { rooms } from './constant';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { reset_answers, storePackage } from '../../store/actions/sampleaction';
import {
  questionnaire,
  page,
  storeMx,
  page2,
  storeTab,
  storeMxAns
} from '../../store/actions/sampleaction';
import { Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { Button, Spinner } from 'react-bootstrap';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from 'react-loader-spinner';
import { InputGroup, FormControl } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import Lottie from 'react-lottie';
import couponAnimation from '../../components/lottie/Coupon_Code.json';
import { useHistory } from 'react-router-dom';
import ConsoleHelper from '../../utils/ConsoleHelper';

const PaymentPackage = ({
  history,
  auth,
  page,
  storeMx,
  page2,
  storeTab,
  storeMxAns,
  questionnaireData,
  storePackage
}) => {
  const { id } = useParams();
  const history1 = useHistory();
  let currentProjectId = id;
  let count = 0;
  const dimension = useMediaQuery();
  const [packages, setPackages] = useState([]);
  const [userRoomDetails, setUserRoomDetails] = useState();
  const [editPackage, setEditPackage] = useState(false);
  const [addCredit, setAddCredit] = useState(false);
  const [creditsvalue, setcreditsvalue] = useState(0);
  const [addGst, setAddGst] = useState(false);
  const [lottie, setLottie] = useState(false);
  const [addedRooms] = useState(
    questionnaireData.Packages && questionnaireData.Packages.hasOwnProperty('additionalRooms')
      ? questionnaireData.Packages.additionalRooms
      : []
  );
  const [orderResponse, setOrderResponse] = useState({ data: '' });
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const [display, setDisplay] = useState('package');
  const [addressdata, setAddressData] = useState([]);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [inputs, setInputs] = useState({});
  const [gstModal, setGstModal] = useState(false);
  const [currentAddressId, setCurrentAddressId] = useState();

  const [addNewAddress, setAddNewAddress] = useState(false);
  const [addressType, setAddressType] = useState();
  const [newBillingAddress] = useState(false);

  const [latestAddressId, setLatestAddressId] = useState(0);
  const [deliveryAddress, setDeliveryAddress] = useState(false);
  const [finalPackages, setFinalPackages] = useState([]);

  const [gstinValue, setGstinValue] = useState('');
  const [gstBusiness, setGstBusiness] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [gstError] = useState(false);
  const [error, setError] = useState('');
  const [gstEdit, setGstEdit] = useState(false);

  const [addressDataTemp, setAddressDataTemp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [couponDrawer, setCouponDrawer] = useState(false);

  const [availableOffers, setAvailableOffers] = useState([]);

  const [offerSelectedValue, setOfferSelectedValue] = useState({});
  const [promotionalDiscount, setPromotionalDiscount] = useState(0);
  const [promotionAdded, setPromotionAdded] = useState(false);
  const [couponsLoading, setCouponsLoading] = useState(false);
  const [offerType, setOfferType] = useState('');
  const [offerProducts, setOfferProducts] = useState([]);
  const [addressError, setAddressError] = useState({});
  const [promo, setPromo] = useState(false);

  const [packageIds, setPackageIds] = useState([]);
  const [cities, setCities] = useState({});
  const [promocode, setpromocode] = useState('');
  const [promovalue, setpromovalue] = useState(0);
  const [promovalue_unit, setvalue_unit] = useState('');
  const [promoids, setpromoids] = useState([]);
  const [applyload, setapplyload] = useState(false);
  const [disablepromocode, setdisablepromocode] = useState(false);

  let upgradePackage = false;
  if (
    history.location.state &&
    history.location.state.upgrade &&
    history.location.state.upgrade === true
  ) {
    upgradePackage = history.location.state.upgrade;
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: couponAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const useStyles1 = makeStyles({
    paper: {
      width: 450,
      backgroundImage: `url(${'https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4851.png'})`
    }
  });
  const classes1 = useStyles1();

  const useStyles2 = makeStyles({
    paper: {
      width: 350,
      backgroundImage: `url(${'https://pixel-mortar.s3.ap-south-1.amazonaws.com/package-upgrade-images/Group+4851.png'})`
    }
  });
  const classes2 = useStyles2();
  const handleapplypromocode = async () => {
    setapplyload(true);
    if (promocode !== '') {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/vouchars/validate/${promocode}`
        );
        if (res.status == 200 && res.data.data) {
          if (getTotal() < res.data.data.minimum_transaction_value) {
            setSnack(true);
            setSnackMessage('Minimum transaction amount not met');
          } else if (res?.data?.data?.tag?.length > 0) {
            let tags = res.data.data.tag.map(string => {
              return string
                .split('_')
                .slice(1)
                .join(' ');
            });
            let ids = [];

            tags.map(tag => {
              if (
                tag == userRoomDetails.room.toLowerCase() ||
                tag == userRoomDetails.package.identifier.toLowerCase() ||
                tag.concat(' room') == userRoomDetails.room.toLowerCase()
              )
                ids.push(userRoomDetails.package._id);
            });
            setpromoids([...ids]);
            setpromovalue(Number(res.data.data.value));
            setvalue_unit(res.data.data.value_unit);
            setSnack(true);
            setSnackMessage('Promocode applied');
          } else {
            setpromovalue(Number(res.data.data.value));
            setvalue_unit(res.data.data.value_unit);
            setSnack(true);
            setSnackMessage('Promocode applied');
          }
        }
      } catch (error) {
        if (error.response.status == 400) {
          setSnack(true);
          setSnackMessage(error.response.data.message);
        }
      }
    } else {
      setSnack(true);
      setSnackMessage('Enter valid promocode');
    }
    setapplyload(false);
  };
  const handlechangepromocode = e => {
    setpromocode(e.target.value);
  };

  const calculatePromoReduction = offerSelected => {
    let cartTotal = getSubTotal();
    let discount = 0;
    let value = 0;
    let calculation1 = 0;
    let productIds = [];
    let offerAvailProducts = [];
    let offerProductsSum = 0;
    if (
      offerSelected.custom_conditions &&
      offerSelected.custom_conditions.length > 0 &&
      offerProducts.length > 0
    ) {
      for (let i = 0; i < offerProducts.length; i++) {
        productIds.push(
          offerProducts[i].package._id ? offerProducts[i].package._id : offerProducts[i].package.id
        );
      }
      productIds = [...new Set(productIds)];
      let finalPack = [userRoomDetails, ...addedRooms];
      offerAvailProducts = finalPack.filter(ct =>
        productIds.includes(ct.package._id || ct.package.id)
      );
      if (offerAvailProducts.length > 0) {
        offerProductsSum = offerAvailProducts
          .map(o => parseInt(o.package.price))
          .reduce((a, c) => {
            return a + c;
          });
      }
      if (
        offerSelected.minimum_transaction_value &&
        offerProductsSum >= parseInt(offerSelected.minimum_transaction_value)
      ) {
        if (offerSelected.value_unit === 'percentage') {
          value = offerSelected.value ? parseInt(offerSelected.value) : 0;
          calculation1 = offerProductsSum * (value / 100);
          if (parseInt(offerSelected.maximum_discount_value) > 0) {
            if (
              offerSelected.maximum_discount_value &&
              calculation1 > parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = parseInt(offerSelected.maximum_discount_value);
            } else if (
              offerSelected.maximum_discount_value &&
              calculation1 <= parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = calculation1;
            }
          } else {
            discount = calculation1;
          }
        }
        if (offerSelected.value_unit === 'value') {
          value = offerSelected.value ? parseInt(offerSelected.value) : 0;
          calculation1 = value;
          if (parseInt(offerSelected.maximum_discount_value) > 0) {
            if (
              offerSelected.maximum_discount_value &&
              calculation1 > parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = parseInt(offerSelected.maximum_discount_value);
            } else if (
              offerSelected.maximum_discount_value &&
              calculation1 <= parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = calculation1;
            }
          } else {
            discount = calculation1;
          }
        }
      } else {
        setSnack(true);
        setSnackMessage('Not minimum transcation amount');
      }
    } else {
      if (
        offerSelected.minimum_transaction_value &&
        cartTotal >= parseInt(offerSelected.minimum_transaction_value)
      ) {
        if (offerSelected.value_unit === 'percentage') {
          value = offerSelected.value ? parseInt(offerSelected.value) : 0;
          calculation1 = cartTotal * (value / 100);
          if (parseInt(offerSelected.maximum_discount_value) > 0) {
            if (
              offerSelected.maximum_discount_value &&
              calculation1 > parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = parseInt(offerSelected.maximum_discount_value);
            } else if (
              offerSelected.maximum_discount_value &&
              calculation1 <= parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = calculation1;
            }
          } else {
            discount = calculation1;
          }
        }
        if (offerSelected.value_unit === 'value') {
          value = offerSelected.value ? parseInt(offerSelected.value) : 0;
          calculation1 = value;
          if (parseInt(offerSelected.maximum_discount_value) > 0) {
            if (
              offerSelected.maximum_discount_value &&
              calculation1 > parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = parseInt(offerSelected.maximum_discount_value);
            } else if (
              offerSelected.maximum_discount_value &&
              calculation1 <= parseInt(offerSelected.maximum_discount_value)
            ) {
              discount = calculation1;
            }
          } else {
            discount = calculation1;
          }
        }
      } else {
        setSnack(true);
        setSnackMessage('Not minimum transcation amount');
      }
    }
    setPromotionalDiscount(discount);
    setPromotionAdded(true);
  };
  const getTotal = () => {
    let sub = getSubTotal();
    let finalValue = sub - promotionalDiscount;
    if (addCredit) finalValue = finalValue - creditsvalue;
    if (promovalue > 0 && promovalue_unit == 'percentage' && promoids.length > 0) {
      promoids.map(id => {
        if (userRoomDetails.package._id == id) {
          finalValue = finalValue - (finalValue * promovalue) / 100;
        }
      });
    }
    return Math.round(finalValue);
  };

  const gettotalsavings = () => {
    let totalsavings = promotionalDiscount;
    let sub = getSubTotal();
    if (addCredit) totalsavings = totalsavings + creditsvalue;
    if (promovalue > 0 && promovalue_unit == 'percentage' && promoids.length > 0) {
      totalsavings = totalsavings + (sub * promovalue) / 100;
    }
    return totalsavings;
  };
  const getpromosavings = () => {
    let totalsavings = promotionalDiscount;
    const userPackagePrice =
      userRoomDetails?.package?.price === 'Free' ? 10 : userRoomDetails.package.price;
    let totalOrderPrice = parseInt(userPackagePrice) + getOtherRoomPrices();
    if (promovalue > 0 && promovalue_unit == 'percentage' && promoids.length > 0) {
      totalsavings = totalsavings + (totalOrderPrice * promovalue) / 100;
    }
    return Math.round(totalsavings);
  };
  const setCurrenAddressData = (addressId, addressArr) => {
    if (addressArr.length > 0) setInputs({ ...addressArr[addressId].address });
  };

  const handleSubmitGSTIN = async () => {
    // ConsoleHelper("called");
    const data = {
      gstin: gstinValue,
      gst_business_name: gstBusiness
    };
    try {
      const res = await customerApiInstance.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/updateUserDetails/${auth.user._id}`,
        data
      );

      if (Object.keys(res).length) {
        if (res.data.verified === false) {
          setGstModal(false);
          setGstEdit(false);
          setSnack(true);
          setSnackMessage(res.data.message);
        } else {
          setGstBusiness(res.data.gst_business_name);
          setGstinValue(res.data.gstin);
          setInputs({ ...inputs });
          setUserDetails(res.data);
          if (gstEdit) {
            setSnack(true);
            setSnackMessage('GST updated successfully');
          } else {
            setSnack(true);
            setSnackMessage('GST added successfully');
          }
          setGstModal(false);
          setGstEdit(false);
        }
      }
    } catch (err) {
      ConsoleHelper('Err', err);
      setGstModal(false);
      setGstEdit(false);
      setSnack(true);
      setSnackMessage('Something went wrong');
    }
  };

  const getSubTotal = () => {
    const userPackagePrice =
      userRoomDetails?.package?.price === 'Free' ? 10 : userRoomDetails.package.price;
    let totalOrderPrice = parseInt(userPackagePrice) + getOtherRoomPrices();
    return Math.round(totalOrderPrice);
  };

  const getOtherRoomPrices = () => {
    let sum = 0;
    let temp = 0;
    let roomsfinal = [...addedRooms];
    if (roomsfinal && roomsfinal.length > 0) {
      roomsfinal.map(ad => {
        if (ad.hasOwnProperty('package')) {
          if (parseInt(ad.package.price) === 0) {
            temp = 10;
          } else {
            temp = parseInt(ad.package.price);
          }
          sum = sum + temp;
        }
      });
    }
    return sum;
  };

  const totalPackageDetails = () => {
    let finalPackages = [];
    let pack = packages.filter(pk => pk?.title === userRoomDetails.package?.title);
    finalPackages.push({
      title: userRoomDetails.package.title,
      price: userRoomDetails.package.price,
      room: userRoomDetails.room,
      _id: pack[0]._id
    });
    packageIds.push(pack[0]._id);
    if (addedRooms && addedRooms.length > 0) {
      for (let i = 0; i < addedRooms.length; i++) {
        pack = packages.filter(pk => pk?.title === addedRooms[i].package?.title);
        finalPackages.push({
          title: addedRooms[i].package?.title,
          price: addedRooms[i].package.price,
          room: addedRooms[i].room,
          _id: pack[0]._id
        });
        packageIds.push(pack[0]._id);
      }
    }
    setPackageIds([...packageIds]);
    setFinalPackages([...finalPackages]);
  };

  const getOrderSubId = (packageOrder, questionniareValue) => {
    if (
      packageOrder.order_items &&
      packageOrder.order_items.length > 0 &&
      questionniareValue.MX_A_INITAL_ROOM &&
      questionniareValue.PACKAGE
    ) {
      let mappedValue = packageOrder.order_items.filter(
        pk =>
          pk.room === questionniareValue.MX_A_INITAL_ROOM &&
          pk?.title === questionniareValue.PACKAGE?.title
      );

      if (mappedValue.length > 0) {
        return mappedValue[0].order_sub_id;
      }
    }
  };

  const getOrderSubIdRooms = (packageOrder, addedValue) => {
    if (
      packageOrder.order_items &&
      packageOrder.order_items.length > 0 &&
      addedValue.room &&
      addedValue.package
    ) {
      let mappedValue = packageOrder.order_items.filter(
        pk => pk.room === addedValue.room && pk?.title === addedValue.package?.title
      );

      if (mappedValue.length > 0) {
        return mappedValue[0].order_sub_id;
      }
    }
  };

  const postOrderPalcement = async () => {
    let qns1 = {};
    qns1 = JSON.parse(localStorage.getItem('MX-A-questionnaire'));
    qns1.status = 'PENDING_ALLOCATION';
    qns1.designer_allocation = 'UNASSIGNED';
    qns1.name = 'My' + ' ' + qns1.questionnaire.MX_A_INITAL_ROOM;
    qns1.questionnaire.paid = true;
    qns1.order_id = orderResponse.data.packageOrderData.order_id;

    localStorage.setItem('MX-A-questionnaire', JSON.stringify(qns1));
    let otherRoomsProject = [];
    let projectIdentifier;
    let res1;
    let res2;
    let packageValue = packages.find(pk => pk?.title === 'Enhanced');
    if (currentProjectId) {
      let MX_A3 = {};
      if (upgradePackage && upgradePackage === true) {
        MX_A3 = {
          questionnaire: {
            PACKAGE: packageValue,
            paid: true,
            upgrade: true
          },
          order_id: orderResponse.data.packageOrderData.order_id,
          order_sub_id:
            orderResponse.data.packageOrderData &&
            orderResponse.data.packageOrderData.order_items.length > 0 &&
            orderResponse.data.packageOrderData.order_items[0].order_sub_id
              ? orderResponse.data.packageOrderData.order_items[0].order_sub_id
              : null
        };
      } else {
        MX_A3 = {
          questionnaire: {
            PACKAGE: qns1.questionnaire.PACKAGE,
            paid: true
          },
          order_id: orderResponse.data.packageOrderData.order_id,

          order_sub_id: getOrderSubId(orderResponse.data.packageOrderData, qns1.questionnaire),
          status: 'PENDING_ALLOCATION',
          designer_allocation: 'UNASSIGNED',

          name: 'My' + ' ' + qns1.questionnaire.MX_A_INITAL_ROOM
        };
      }
      res1 = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/update/${currentProjectId}`,
        MX_A3
      );
      projectIdentifier = res1.data._id;
      if (addedRooms && addedRooms.length > 0) {
        addedRooms.map((add, i) => {
          let questionnaire1 = {
            PACKAGE: add.package,
            MX_A_INITAL_ROOM: add.room,
            addon: true,
            paid: true
          };
          let projectName = 'My' + ' ' + questionnaire1.MX_A_INITAL_ROOM;
          let order_sub_id = getOrderSubIdRooms(orderResponse.data.packageOrderData, add);
          otherRoomsProject[i] = {
            user_id: auth.user._id,
            name: projectName,
            questionnaire: questionnaire1,
            status: 'PENDING_ALLOCATION',
            designer_allocation: 'UNASSIGNED',
            addon: true,
            order_id: orderResponse.data.packageOrderData.order_id,
            order_sub_id: order_sub_id
          };
        });
        if (otherRoomsProject && otherRoomsProject.length > 0) {
          await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/project/bulkCreate`,
            otherRoomsProject
          );
        }
      }
    } else {
      qns1.status = 'PENDING_ALLOCATION';
      qns1.designer_allocation = 'UNASSIGNED';

      qns1.name = 'My' + ' ' + qns1.questionnaire.MX_A_INITAL_ROOM;
      qns1.order_sub_id = getOrderSubId(orderResponse.data.packageOrderData, qns1.questionnaire);
      res2 = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
        qns1
      );
      projectIdentifier = res2.data.project._id;
      if (addedRooms && addedRooms.length > 0) {
        addedRooms.map((add, i) => {
          let questionnaire1 = {
            PACKAGE: add.package,
            MX_A_INITAL_ROOM: add.room,
            addon: true,
            paid: true
          };
          let order_sub_id = getOrderSubIdRooms(orderResponse.data.packageOrderData, add);
          let projectName = 'My' + ' ' + questionnaire1.MX_A_INITAL_ROOM;
          otherRoomsProject[i] = {
            user_id: auth.user._id,
            name: projectName,
            questionnaire: questionnaire1,
            status: 'PENDING_ALLOCATION',
            designer_allocation: 'UNASSIGNED',
            addon: true,
            order_id: orderResponse.data.packageOrderData.order_id,
            order_sub_id: order_sub_id
          };
        });
        if (otherRoomsProject && otherRoomsProject.length > 0) {
          await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/project/bulkCreate`,
            otherRoomsProject
          );
        }
      }
    }
    page(0);
    page2(0);
    storeMx({});
    storeTab('questionnaire');
    storeMxAns({});
    localStorage.removeItem('RedirectToLogin');
    let questionnaireDetails = { ...userDetails.questionnaire };
    questionnaireDetails.purchased = true;
    storePackage({});
    if (upgradePackage === false) {
      await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/updateQuestionnaire/${userDetails._id}`,
        questionnaireDetails
      );
      history.push({
        pathname: `/order/${projectIdentifier}`,
        state: { projectId: projectIdentifier, showHeader: true }
      });
    } else if (upgradePackage === true) {
      history.push({
        pathname: `/rooms/${projectIdentifier}`,
        state: { type: 'finalise', showHeader: true }
      });
      localStorage.setItem('packageUpgraded', JSON.stringify(true));
    }
  };

  const placePackageOrder = async () => {
    setLoading(true);
    setdisablepromocode(true);
    try {
      const userPackagePrice =
        userRoomDetails.package.price === 'Free' ? 10 : userRoomDetails.package.price;
      // const totalOrderPrice = parseInt(userPackagePrice) + getOtherRoomPrices();
      const orderData = {
        user_id: auth.user._id,
        order_value: getTotal(),
        payment_status: 'PENDING',
        payment_mode: 'ONLINE',
        package_id: packageIds,
        order_items: finalPackages,
        address_id: addressdata[latestAddressId]._id,
        discount_amount: Math.round(gettotalsavings()),
        offer_type: offerType,
        promotion_code: promotionAdded ? offerSelectedValue.coupon_code : '',
        points: addCredit ? creditsvalue : 0
      };
      let res =
        Object.keys(orderResponse.data).length < 2 &&
        (await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/package-order`,
          orderData
        ));
      if (Object.keys(res).length) {
        orderResponse.data = res.data;
        setOrderResponse(orderResponse);
      }
      if (orderData.order_value > 0) {
        var options = {
          key_id: `${process.env.REACT_APP_RAZOR_PAY_KEY}`, // Enter the Key ID generated from the Dashboard
          amount: `${orderResponse.data.order.amount * 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'Pixel & mortar',
          image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/common-images/P%26M+logo.svg',
          order_id: `${orderResponse.data.order.id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: async function(response) {
            const data = {
              user_id: auth.user._id,
              razor_pay_payment_id: response.razorpay_payment_id,
              payment_status: 'PAID',
              final_packages: finalPackages
            };
            let result = await customerApiInstance.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/package-order/update-payment-status/${orderResponse.data.packageOrderData._id}`,
              data
            );
            if (result.data) {
              try {
                await customerApiInstance.post(
                  `${process.env.REACT_APP_BACKEND_URL}/api/package-order/send-invoice/${orderResponse.data.packageOrderData._id}`,
                  data
                );
              } catch (e) {
                // ConsoleHelper("error, e");
              }
              if (promotionAdded) {
                try {
                  const redemData = {
                    transaction_id: response.razorpay_payment_id,
                    value_used: promotionalDiscount,
                    coupon_id: offerSelectedValue.id
                  };
                  await customerApiInstance.post(
                    `${process.env.REACT_APP_BACKEND_URL}/api/offer/redemption/create`,
                    redemData
                  );
                } catch (e) {
                  ConsoleHelper('error', e);
                }
              }
              //do redem
              if (promoids?.length > 0) {
                let sub = getSubTotal();
                const res = axios.post(
                  `${process.env.REACT_APP_BACKEND_URL}/api/vouchers/${promocode}/redeem`,
                  {
                    user_id: auth.user._id,
                    user_email: auth.user.email,
                    user_phone: auth.user.phone_number,
                    transaction_id: result.data.order_id,
                    value_used: (sub * promovalue) / 100
                  }
                );
              }
              postOrderPalcement();
              setSnack(true);
              setSnackMessage('Order placed successfully');

              history1.push({
                pathname: `/order-confirmation/${orderResponse.data.packageOrderData._id}`,
                state: {
                  order_id: orderResponse.data.packageOrderData.order_id,
                  email: auth.user.email
                }
              });
            }
          },
          prefill: {
            name: `${auth.user.name}`,
            email: `${auth.user.email}`,
            contact: `${auth.user.phone_number}`
          },
          notes: {},
          theme: {
            color: '#3399cc'
          }
        };

        let rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', async function(response) {
          const data = {
            user_id: auth.user._id,
            payment_status: 'PAYMENT_FAILED'
          };
          const packageupdate = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/package-order/update-payment-status/${orderResponse.data.packageOrderData._id}`,
            data
          );
          setLoading(false);
          setSnack(true);
          setSnackMessage('Payment failed for this order');
          setdisablepromocode(false);
        });
        rzp1.open();
      } else {
        try {
          await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/package-order/send-invoice/${orderResponse.data.packageOrderData._id}`
          );
        } catch (e) {
          ConsoleHelper('error, e');
        }
        if (promotionAdded) {
          try {
            const redemData = {
              // transaction_id: response.razorpay_payment_id,
              value_used: promotionalDiscount,
              coupon_id: offerSelectedValue.id
            };
            await customerApiInstance.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/offer/redemption/create`,
              redemData
            );
          } catch (e) {
            ConsoleHelper('error', e);
          }
        }
        //do redem
        if (promoids?.length > 0) {
          let sub = getSubTotal();

          const res = axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/vouchers/${promocode}/redeem`,
            {
              user_id: auth.user._id,
              user_email: auth.user.email,
              user_phone: auth.user.phone_number,
              value_used: (sub * promovalue) / 100
            }
          );
          // ConsoleHelper(res.status, res.data, "KK");
        }
        postOrderPalcement();
        setSnack(true);
        setSnackMessage('Order placed successfully');

        history1.push({
          pathname: `/order-confirmation/${orderResponse.data.neworder._id}`,
          state: {
            order_id: orderResponse.data.neworder.order_id,
            email: auth.user.email
          }
        });
        setLoading(false);
      }
    } catch (e) {
      setdisablepromocode(false);
      setLoading(false);
      setSnack(true);
      setSnackMessage('Order failed to create');
    }
  };

  const handleChangeGstin = value => {
    setGstinValue(value);
    if (
      value.match(
        /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/
      )
    ) {
      return setError('');
    } else {
      return setError('gstin');
    }
  };

  const handleChangeDelivery = e => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'work') {
      name = 'type';
      value = 'work';
    } else if (name === 'home') {
      name = 'type';
      value = 'home';
    }
    if (name === 'phone') {
      setInputs({
        ...inputs,
        [name]: value
      });
      if (value.length !== 10) {
        return setError('phone');
      } else {
        return setError('');
      }
    }
    if (name === 'postcode') {
      setInputs({
        ...inputs,
        [name]: value
      });
      if (value.length !== 10) {
        return setError('phone');
      } else {
        return setError('');
      }
    }
    if (name === 'gstin') {
      setInputs({
        ...inputs,
        [name]: value
      });
      //    if( value.length > 8 && value.match(/[a-z]/g) && value.match(/[A-Z]/g) && value.match(/[0-9]/g) && value.match(/[!@#$%^&*_]/) ){
      if (
        value.match(
          /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/
        )
      ) {
        return setError('');
      } else {
        return setError('gstin');
      }
    }

    setInputs({
      ...inputs,
      [name]: value
    });
  };

  const handleSubmitAddress = async () => {
    let addressErrorData = {};
    if (Object.keys(inputs).length !== 0) {
      if (!inputs.project_pin) {
        addressErrorData.project_pin = 'Project Pin is required';
      }
      if (!inputs.phone) {
        addressErrorData.phone = 'phone is required';
      } else {
        if (inputs.phone.length !== 10) addressErrorData.phone = 'phone should have 10 digit';
      }
      if (!inputs.postcode) {
        addressErrorData.postcode = 'postcode is required';
      } else {
        if (inputs.postcode.length !== 6)
          addressErrorData.postcode = 'postcode should have 6 digit';
      }
      if (!inputs.line1) {
        addressErrorData.line1 = 'address line1 is required';
      }
      if (!inputs.line2) {
        addressErrorData.line2 = 'address line2 is required';
      }
      if (!inputs.city) {
        addressErrorData.city = 'city is required';
      }
      if (!inputs.state) {
        addressErrorData.state = 'state is required';
      }
      setAddressError(addressErrorData);
      if (!Object.keys(addressErrorData).length) {
        try {
          const data = {
            user_id: auth.user._id,
            address: inputs
          };

          const res = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/address`,
            data
          );
          if (res.data) {
            setSnack(true);
            setModal(false);

            setSnackMessage('Address Added successfully');

            setInputs({});

            if (!newBillingAddress) {
              setModal(false);
              let newAddressArr = [...addressDataTemp, res.data.data];
              setAddressData([...addressDataTemp, res.data.data]);

              setLatestAddressId(newAddressArr.length > 0 ? newAddressArr.length - 1 : 0);
              setDeliveryAddress(true);
              if (newAddressArr.length > 0) {
                setCurrenAddressData(newAddressArr.length - 1, newAddressArr);
              } else {
                setCurrenAddressData(0, []);
              }

              setCurrentAddressId(newAddressArr.length > 0 ? newAddressArr.length - 1 : 0);
            }
          }
        } catch (err) {}
      }
    } else {
      setSnack(true);
      setSnackMessage('Please enter all the fields');
    }
  };

  const handleUpdateAddress = async () => {
    let empty = 0;
    if (Object.keys(inputs).length !== 0) {
      for (let key in inputs) {
        if (inputs[key] === '') {
          empty = empty + 1;
        }
      }

      if (empty > 0) {
        setSnack(true);
        setSnackMessage('Please enter all the fields');
      } else {
        try {
          const data = {
            _id: addressDataTemp[currentAddressId]._id,
            address: inputs
          };

          await customerApiInstance.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/address`,
            data
          );
          addressDataTemp[currentAddressId].address = { ...inputs };
          setAddressData([...addressDataTemp]);
          setSnack(true);
          setSnackMessage('Address Updated successfully');
          setModal(false);
        } catch (err) {}
      }
    } else {
      setSnack(true);
      setSnackMessage('Please enter all the fields');
    }
  };

  const handleDeleteAddress = async id => {
    try {
      const res = await customerApiInstance.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/address/${id}`
      );

      if (res.data) {
        setInputs({});
        setSnack(true);
        setModal(false);
        setSnackMessage('Address deleted successfully');
        let addressdata1 = addressdata.filter(filter => filter._id !== id);
        setAddressData([...addressdata1]);
        setAddressDataTemp([...addressdata1]);
      }
    } catch (err) {}
  };

  const getImage = (packages, pack) => {
    if (packages.length > 0) {
      const packageData = packages?.find(pk => pk?.title === pack?.title);
      if (Object.keys(packageData).length) {
        return packageData.image;
      } else {
        return '';
      }
    }
  };

  const getAddressValues = address => {
    let add =
      address.line1 +
      ' ,' +
      ' ' +
      address.line2 +
      ' ,' +
      ' ' +
      address.city +
      ' ,' +
      ' ' +
      address.state +
      ' ,' +
      ' ' +
      address.postcode +
      '.';
    return add;
  };

  const couponsFunction = offer => {
    setOfferSelectedValue(offer);
    calculatePromoReduction(offer);

    setOfferType('coupon');
  };

  const handleCoupons = async offer => {
    setCouponDrawer(false);

    setLottie(true);
    setTimeout(() => {
      setLottie(false);
      couponsFunction(offer);
    }, 5000);
  };

  const ordersummary = () => {
    return (
      <>
        <div className={classnames('row justify-content-between m-0', style.summary_headings)}>
          <span>Sub Total</span>
          {userRoomDetails && userRoomDetails.hasOwnProperty('package') && (
            <span>{`Rs. ${new Intl.NumberFormat('en-IN').format(getSubTotal())}`}</span>
          )}
        </div>
        <div className={classnames('row justify-content-between m-0', style.summary_headings)}>
          <span>P & M Discount</span>
          <span>0</span>
        </div>
        <div className={classnames('row justify-content-between m-0', style.summary_headings)}>
          <span>Promotional Discount</span>
          {userRoomDetails && userRoomDetails.hasOwnProperty('package') && (
            <span>Rs.{new Intl.NumberFormat('en-IN').format(getpromosavings())}</span>
          )}
        </div>
        <div className={classnames('row justify-content-between m-0', style.summary_headings)}>
          <span>P & M Credit</span>
          <span>{addCredit ? creditsvalue : 0}</span>
        </div>
        <div
          className={'row justify-content-between m-0 mt-2'}
          style={{
            fontWeight: 900,
            paddingTop: '2vh',
            fontSize: '13px'
          }}>
          <span style={{ color: '#25B513' }}>Total Savings</span>
          {userRoomDetails && userRoomDetails.hasOwnProperty('package') && (
            <span style={{ color: '#25B513' }}>
              Rs. {new Intl.NumberFormat('en-IN').format(gettotalsavings())}
            </span>
          )}
        </div>
        <div
          className={'row justify-content-between m-0 mt-2'}
          style={{
            fontWeight: 900,
            paddingTop: '2vh',
            fontSize: '13px'
          }}>
          <span>Total</span>
          {userRoomDetails && userRoomDetails.hasOwnProperty('package') && (
            <span>
              Rs.
              {new Intl.NumberFormat('en-IN').format(getTotal())}
            </span>
          )}
        </div>
      </>
    );
  };
  const addpromocode = () => {
    return (
      <>
        {' '}
        {disablepromocode ? (
          <></>
        ) : (
          <>
            <div className="row justify-content-center m-0 mt-3">
              <p className={style.promoBtn}>
                Do you have promo code?{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    color: 'blue',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setPromo(!promo);
                  }}>
                  Add it here
                </span>
              </p>
            </div>
            {promo ? (
              <div className="mt-3">
                <InputGroup className="mb-3">
                  <FormControl
                    aria-label="Promocode"
                    aria-describedby="basic-addon2"
                    onChange={e => handlechangepromocode(e)}
                    value={promocode}
                  />
                  {applyload ? (
                    <Loader type="Oval" visible={true} color="#000000" height={30} width={45} />
                  ) : (
                    <InputGroup.Text
                      id="basic-addon2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleapplypromocode()}>
                      Apply
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </div>
            ) : null}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/cities`);
        setCities(res.data);
      } catch (err) {}
    };
    fetchCities();
    async function fetchData() {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth.user._id}/credits`
      );
      if (res.status == 200 && res.data.message == 'Success') {
        setcreditsvalue(res?.data?.data?.points ? res.data.data.points : 0);
      }
    }
    fetchData();

    const fetchaddressData = async () => {
      try {
        if (auth && auth.user) {
          const res = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/address/${auth.user._id}`
          );
          if (res.data.length > 0) {
            setLatestAddressId(res.data.length - 1);
          }

          setAddressData([...res.data]);
          setAddressDataTemp([...res.data]);
        }
      } catch (err) {
        ConsoleHelper(err);
      }
    };
    fetchaddressData();
    const fetchPackages = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/packages/list`);
      let paidPackages = res.data.filter(pack => pack.identifier !== 'EXPRESS');
      setPackages([{ _id: 0, title: 'Design Package', price: 0 }, ...paidPackages]);
    };
    fetchPackages();
    setModal(false);
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    const fetchOffers = async () => {
      setCouponsLoading(true);
      let tempArray = [];
      const res = await customerApiInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/offers`);
      let currentOffers = res.data.availableOffers;
      let filterOffers = [];
      let tempOffers = [];
      if (currentOffers && currentOffers.length > 0) {
        for (let i = 0; i < currentOffers.length; i++) {
          if (
            currentOffers[i].hasOwnProperty('tag') &&
            currentOffers[i].tag.length > 0 &&
            currentOffers[i].tag.includes('package')
          ) {
            filterOffers.push(currentOffers[i]);
          }
        }
      }
      currentOffers = [...filterOffers];
      let packagesAdded = [];
      if (userRoomDetails && Object.keys(userRoomDetails).length) {
        packagesAdded.push(userRoomDetails);
      }
      packagesAdded = [...packagesAdded, ...addedRooms];
      for (let i = 0; i < currentOffers.length; i++) {
        if (currentOffers[i].custom_conditions && currentOffers[i].custom_conditions.length > 0) {
          let equalValues = [];
          for (let j = 0; j < currentOffers[i].custom_conditions.length; j++) {
            if (currentOffers[i].custom_conditions[j].operator === 'eq') {
              tempArray = currentOffers[i].custom_conditions[j].value.split(';');
              equalValues = [...equalValues, ...tempArray];
            }
          }
          let mappedProducts = [];
          if (packagesAdded.length > 0 && equalValues.length > 0) {
            for (let k = 0; k < equalValues.length; k++) {
              for (let i = 0; i < packagesAdded.length; i++) {
                if (packagesAdded[i].package && packagesAdded[i].package?.title) {
                  packagesAdded[i].package.title = packagesAdded[i].package?.title.replace(
                    /\W+/g,
                    ''
                  );
                  if (
                    packagesAdded[i].package?.title.toLowerCase() === equalValues[k].toLowerCase()
                  ) {
                    mappedProducts.push(packagesAdded[i]);
                  }
                }
              }
            }
          }
          let uniqueObjArray = [
            ...new Map(mappedProducts.map(item => [item['_id'], item])).values()
          ];
          let offerProds = [...uniqueObjArray];
          if (offerProds.length > 0) {
            tempOffers.push(currentOffers[i]);
            setOfferProducts([...offerProducts, ...offerProds]);
          }
        } else {
          tempOffers.push(currentOffers[i]);
        }
      }
      setAvailableOffers([...tempOffers]);

      setCouponsLoading(false);
    };
    fetchOffers();
  }, [userRoomDetails, addedRooms]);

  useEffect(() => {
    if (promotionAdded && Object.keys(offerSelectedValue).length) {
      calculatePromoReduction(offerSelectedValue);
    }
  }, [addedRooms]);

  useEffect(() => {
    const fetchUserDetail = async () => {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth.user._id}`
      );
      if (res.data) {
        setUserDetails(res.data);
        if (res.data.gst_business_name) {
          setGstBusiness(res.data.gst_business_name);
        }
        if (res.data.gstin) {
          setGstinValue(res.data.gstin);
        }
      }
      setInputs({ ...inputs });
      if (packages && packages.length > 0) {
        if (currentProjectId) {
          const res1 = await customerApiInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/project/${currentProjectId}`
          );

          let index = rooms.findIndex(rm => rm?.title === res1.data.questionnaire.MX_A_INITAL_ROOM);
          let packageValue = packages.find(
            pk => pk?.title === res1.data.questionnaire.PACKAGE?.title
          );
          if (upgradePackage && upgradePackage === true) {
            let pack2 = packages.find(pk => pk?.title === 'Enhanced');

            if (pack2) {
              let x = pack2.price - packageValue.price;
              let pack3 = { ...pack2, price: x };
              setUserRoomDetails({
                room: res1.data.questionnaire.MX_A_INITAL_ROOM,
                package: pack3,
                room_id: index,
                initial_created: true,
                upgrade: true
              });
            }
          } else if (index >= 0 && packageValue) {
            setUserRoomDetails({
              room: res1.data.questionnaire.MX_A_INITAL_ROOM,
              package: packageValue,
              room_id: index,
              initial_created: true
            });
          }
        } else {
          let index = rooms.findIndex(rm => rm?.title === res.data.questionnaire.initial_room);
          let packageValue = packages.find(
            pk => pk?.title === res.data.questionnaire.package?.title
          );

          if (index >= 0 && packageValue) {
            setUserRoomDetails({
              room: res.data.questionnaire.initial_room,
              package: packageValue,
              room_id: index,
              initial_created: true
            });
          }
        }
      }
    };
    fetchUserDetail();
  }, [packages]);
  return (
    <>
      {lottie ? (
        <div style={{ marginTop: '70px' }}>
          <p
            className={style.perfectStyle}
            style={{
              textAlign: 'center',
              fontSize: '20px',
              position: 'absolute',
              top: '40px',
              left: 0,
              right: 0,
              zIndex: 100
            }}>
            <i>Coupon Applied</i>
          </p>
          <Lottie options={defaultOptions} height="70vh" width={500} />
        </div>
      ) : (
        <div className={style.pageContainer} style={{ backgroundColor: '#F5F0EC' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snack}
            onClose={() => setSnack(false)}
            message={snackMessage}
            autoHideDuration={2000}
          />
          <div>
            <Header className="m-0 p-0" />
          </div>
          <div className={classnames('container', style.container)}>
            {display === 'package' && (
              <>
                {/* {upgradePackage === false ? (
                  <div className="d-flex flex-row justify-content-right align-items-right mb-3">
                    <div>
                      <FontAwesomeIcon
                        size={10}
                        style={{ alignItems: "center" }}
                        icon={faChevronLeft}
                      />
                    </div>
                    <p
                      className={style.subHeading}
                      style={{ marginLeft: "10px", marginBottom: "0px" }}
                      onClick={() => {
                        if (currentProjectId) {
                          // storeTab('package')
                          // questionnaire('package')
                          // history.push({ pathname: `/questionnaire/package/${currentProjectId}`, state: { navbar: true, project_id: currentProjectId, redirectTo: 'package' } })
                          history.push({
                            pathname: `/packages/${currentProjectId}`,
                            state: {
                              navbar: true,
                              project_id: currentProjectId,
                            },
                          });
                        } else {
                          // storeTab('package')
                          // questionnaire('package')
                          // history.push({ pathname: '/questionnaire/package', state: { navbar: true, redirectTo: 'package' } })
                          history.push({
                            pathname: `/packages/`,
                            state: { navbar: true },
                          });
                        }
                      }}
                    >
                      Select different package
                    </p>
                  </div>
                ) : null} */}

                <div className="row">
                  <div className="col-sm-12 col-lg-8 col-md-8 col-xl-8">
                    <div className={classnames(style.roomtype1, 'row')}>
                      <div className="col-6 d-flex justify-content-start">
                        <span className={style.heading1}> In Cart</span>
                      </div>
                    </div>
                    {editPackage === false && (
                      <div>
                        <div
                          className={classnames(
                            ' container',
                            style.roomContainer,
                            style.roomtype1
                          )}>
                          {/* {upgradePackage === false ? (
                          <div className="row">
                            <div
                              className="col-12 d-flex justify-content-end"
                              onClick={() => {
                                setEditPackage(true);
                                setCurrentRoom(userRoomDetails);
                              }}
                            >
                              <span className={style.editStyle}>
                                <u>
                                  <small>Editd</small>
                                </u>
                              </span>
                            </div>
                          </div>
                        ) : null} */}
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-8 col-4">
                              <img
                                className="img-fluid"
                                src={
                                  packages &&
                                  userRoomDetails &&
                                  getImage(packages, userRoomDetails.package)
                                }
                                alt=""
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-8 col-4">
                              <div className={style.textTrans}>Package</div>
                              <div>
                                <span className={style.heading}>
                                  {userRoomDetails &&
                                    userRoomDetails.package &&
                                    userRoomDetails.package?.title}
                                </span>
                              </div>
                              {userRoomDetails && userRoomDetails.package && (
                                <div className={style.price}>
                                  Rs.{' '}
                                  {userRoomDetails.package.price === 'Free'
                                    ? 0
                                    : new Intl.NumberFormat('en-IN').format(
                                        userRoomDetails.package.price
                                      )}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-8 col-4">
                              <div className={style.textTrans}>Room Type</div>
                              <div className={style.roomtype}>
                                {' '}
                                <span className={style.heading}>
                                  {' '}
                                  {userRoomDetails && userRoomDetails.room}{' '}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {addedRooms &&
                          addedRooms.length > 0 &&
                          addedRooms.map((data, index) => {
                            return (
                              data.hasOwnProperty('package') &&
                              data.hasOwnProperty('room') && (
                                <div
                                  className={classnames(
                                    ' container',
                                    style.roomContainer,
                                    style.roomtype1
                                  )}>
                                  <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-8 col-4">
                                      <img
                                        className="img-fluid"
                                        src={packages && getImage(packages, data.package)}
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-8 col-4">
                                      <div className={style.textTrans}>Package</div>
                                      <div>
                                        <span className={style.heading}>
                                          {data && data.package?.title}
                                        </span>
                                      </div>
                                      {data && data.package && (
                                        <div className={style.price}>
                                          Rs.{' '}
                                          {data.package.price === 'Free'
                                            ? 0
                                            : new Intl.NumberFormat('en-IN').format(
                                                data.package.price
                                              )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-8 col-4">
                                      <div className={style.textTrans}>Room Type</div>
                                      <div>
                                        {' '}
                                        <span className={style.heading}> {data && data.room} </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-lg-4 col-md-4 col-xl-4">
                    <div className={classnames(style.summaryContainer)}>
                      <div className={style.orderSum}>
                        <h3>Order Summary</h3>
                      </div>
                      <div className={style.summarySubContainer}>
                        {ordersummary()}
                        <div className="row justify-content-center m-0">
                          <button
                            onClick={() => {
                              if (auth && auth.user) {
                                setDisplay('address');
                                totalPackageDetails();
                                setPromo(false);
                              }
                            }}
                            className={style.checkoutBtn}>
                            Next
                          </button>
                        </div>
                        {/* Will Implement soon */}
                        {addpromocode()}
                        {promotionAdded === true && offerType === 'coupon' ? (
                          <div className="row m-0 ">
                            <button className={style.couponBtn}>
                              <div className="row">
                                <div className="col-9  d-flex justify-content-center">
                                  {offerSelectedValue && offerSelectedValue.coupon_code
                                    ? offerSelectedValue.coupon_code
                                    : 'offer'}{' '}
                                  Applied
                                </div>
                                <div className="col-2 d-flex justify-content-end">
                                  <img
                                    className="d-flex justify-contnetn-end"
                                    src={
                                      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                    }
                                    style={{ width: '90%' }}
                                    onClick={() => {
                                      setPromotionAdded(false);
                                      setOfferSelectedValue({});
                                      setOfferType('');
                                      setPromotionalDiscount(0);
                                    }}
                                  />
                                </div>
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div className="row m-0 ">
                            <button
                              className={style.couponBtn}
                              onClick={() => {
                                setCouponDrawer(true);
                              }}>
                              Apply Coupon Code
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {display === 'address' && (
              <>
                <div className="row">
                  <div className="col-sm-12 col-lg-8 col-md-8 col-xl-8">
                    <div className="row">
                      <p
                        className={classnames(
                          style.heading1,
                          'col-6 d-flex justify-content-start'
                        )}>
                        Checkout
                      </p>
                    </div>
                    <div className={classnames(' container', style.roomContainer, style.roomtype1)}>
                      <span className="row pl-3 p-2" style={{ fontSize: '18px' }}>
                        User Details
                      </span>
                      <div className=" col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 justify-content-left m-0">
                        <span>Email*</span>
                        <br></br>
                        <input
                          className={style.checkoutInput}
                          name="email"
                          defaultValue={auth.user.email || ''}
                          required
                          placeholder="Email"
                        />
                      </div>
                      <div className="row justify-content-left m-0 mt-3">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                          <span>First Name*</span>
                          <br></br>
                          <input
                            className={style.checkoutInput}
                            name="firstname"
                            value={
                              deliveryAddress &&
                              addressdata[latestAddressId] &&
                              addressdata[latestAddressId].address &&
                              addressdata[latestAddressId].address.firstname
                                ? addressdata[latestAddressId].address.firstname
                                : inputs.firstname || ''
                            }
                            required
                            onChange={handleChangeDelivery}
                            placeholder="First Name"
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                          <span>Last Name*</span>
                          <br></br>
                          <input
                            className={style.checkoutInput}
                            name="lastname"
                            value={
                              deliveryAddress &&
                              addressdata[latestAddressId] &&
                              addressdata[latestAddressId].address &&
                              addressdata[latestAddressId].address.lastname
                                ? addressdata[latestAddressId].address.lastname
                                : inputs.lastname || ''
                            }
                            required
                            onChange={handleChangeDelivery}
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>
                    {addressdata.length > 0 ? (
                      <div
                        className={classnames(' container', style.roomContainer, style.roomtype1)}>
                        <div className="row">
                          <div className="col-6 d-flex justify-content-start">
                            <h5>
                              {addressdata.length > 0 ? 'Most Recently used' : 'Shipping Address'}
                            </h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            <p
                              style={{
                                fontSize: '17px',
                                cursor: 'pointer',
                                fontWeight: '400',
                                textDecoration: 'underline'
                              }}
                              onClick={() => {
                                setCurrenAddressData(latestAddressId, addressdata);
                                setCurrentAddressId(latestAddressId);
                                setModal(true);
                                setAddNewAddress(false);
                              }}>
                              Edit
                            </p>
                          </div>
                        </div>

                        {addressdata.map((address, i) => {
                          return (
                            <div className="row ml-2" key={`address${i}`}>
                              <div className="form-check col-7" id={`address${i}`}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`flexRadioDefault${i}`}
                                  id={`flexRadioDefault1${i}`}
                                  checked={currentAddressId === i}
                                  // checked={(paymentMode === "COD") || false}
                                  onChange={() => {
                                    setLatestAddressId(i);
                                    setDeliveryAddress(true);
                                    setCurrenAddressData(i, addressdata);
                                    setCurrentAddressId(i);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexRadioDefault1${i}`}
                                  style={{ marginLeft: '0px' }}>
                                  <p
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '500'
                                    }}>
                                    {getAddressValues(address.address)}
                                  </p>
                                </label>
                              </div>

                              <hr />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        className={classnames(' container', style.roomContainer, style.roomtype1)}>
                        <div className="row justify-content-left m-0 mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                            <span>Billing address</span>
                            <br></br>
                          </div>
                        </div>
                        <div className="row justify-content-left m-0 mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                            <span>Project Pincode</span>
                            <br></br>
                            <input
                              className={style.checkoutInput}
                              name="project_pin"
                              placeholder="Project Pincode"
                              value={
                                deliveryAddress &&
                                addressdata[addressdata.length - 1].address.project_pin
                                  ? addressdata[addressdata.length - 1].address.project_pin
                                  : inputs.project_pin || ''
                              }
                              required
                              onChange={handleChangeDelivery}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-left m-0 mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 ">
                            <span>Address Line 1</span>
                            <br></br>
                            <input
                              className={style.checkoutInput}
                              name="line1"
                              placeholder="Address Line 1"
                              value={
                                deliveryAddress
                                  ? addressdata[addressdata.length - 1].address.line1
                                  : inputs.line1 || ''
                              }
                              required
                              onChange={handleChangeDelivery}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                            <span>Address Line 2</span>
                            <br></br>
                            <input
                              className={style.checkoutInput}
                              name="line2"
                              placeholder="Address Line 2"
                              value={
                                deliveryAddress
                                  ? addressdata[addressdata.length - 1].address.line2
                                  : inputs.line2 || ''
                              }
                              required
                              onChange={handleChangeDelivery}
                            />
                          </div>
                        </div>

                        <div className="row justify-content-left m-0 mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                            <span>Postalcode</span>
                            <br></br>
                            <input
                              className={style.checkoutInput}
                              name="postcode"
                              type="number"
                              placeholder="Postal Code"
                              value={
                                deliveryAddress
                                  ? addressdata[addressdata.length - 1].address.postcode
                                  : inputs.postcode || ''
                              }
                              required
                              onChange={handleChangeDelivery}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                            <span>City/ District</span>
                            <br></br>
                            <input
                              className={style.checkoutInput}
                              name="city"
                              placeholder="city/district"
                              value={
                                deliveryAddress
                                  ? addressdata[addressdata.length - 1].address.city
                                  : inputs.city || ''
                              }
                              required
                              onChange={handleChangeDelivery}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-left m-0 mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                            <span>State</span>
                            <br></br>
                            <select
                              className={style.selectTypes}
                              name="state"
                              placeholder="Select State"
                              value={
                                deliveryAddress
                                  ? addressdata[addressdata.length - 1].address.state
                                  : inputs.state || ''
                              }
                              onChange={handleChangeDelivery}>
                              <option>Select state</option>
                              {Object.keys(cities).map((state, index) => (
                                <option key={`city${index}`} value={state}>
                                  {state}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                            <span>Phone*</span>
                            <br></br>
                            <input
                              className={style.checkoutInput}
                              type="number"
                              name="phone"
                              placeholder="Phone"
                              value={
                                deliveryAddress
                                  ? addressdata[addressdata.length - 1].address.phone
                                  : inputs.phone || ''
                              }
                              required
                              onChange={handleChangeDelivery}
                            />
                            {error === 'phone' && (
                              <span style={{ color: 'red', fontSize: '11px' }}>
                                Number must be 10 digits
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row justify-content-left m-0 mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                            <button
                              className={style.checkoutBtn}
                              onClick={() => {
                                handleSubmitAddress();
                                if (addressdata.length > 0) {
                                  setDeliveryAddress(true);
                                }
                              }}>
                              Save
                            </button>
                            <br></br>
                          </div>
                        </div>
                      </div>
                    )}
                    {addressdata.length > 0 && (
                      <div
                        className={classnames('container', style.roomContainer, style.roomtype1)}>
                        <div className="row justify-content-between pl-2">
                          <h5
                            style={{
                              cursor: 'pointer',
                              cursor: 'pointer',
                              fontSize: '16px',
                              fontWeight: 700,
                              marginBottom: '0px'
                            }}
                            onClick={() => {
                              setModal(true);
                              setAddNewAddress(true);
                              setInputs({});
                            }}>
                            {' '}
                            <Icon.Plus size={30} />
                            <span
                              style={{
                                position: 'relative',
                                top: '2px',
                                fontSize: '18px',
                                fontWeight: 700,
                                marginBottom: '0px'
                              }}>
                              Add New Address
                            </span>
                          </h5>
                        </div>
                      </div>
                    )}
                    <div className={classnames(' container', style.roomContainer, style.roomtype1)}>
                      {userDetails.hasOwnProperty('gstin') && userDetails.gstin !== '' && (
                        <>
                          <div className="d-flex flex-row mt-2" style={{ fontSize: '14px' }}>
                            <div className="form-check col-lg-4 col-md-4 col-sm-8">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={addGst ? true : false}
                                onClick={() => setAddGst(!addGst)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                                style={{ marginLeft: '0px' }}>
                                Create GST invoice
                              </label>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-6">
                              <span>
                                {userDetails.gstin} | {userDetails.gst_business_name}
                              </span>
                            </div>
                            <div className={classnames('col-6 d-flex justify-content-end')}>
                              <button
                                className={style.applyButton}
                                onClick={() => {
                                  setGstModal(true);
                                  setGstEdit(true);
                                }}>
                                Change{' '}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {(!userDetails.hasOwnProperty('gstin') || userDetails.gstin === '') && (
                        <div className="d-flex flex-row mt-2" style={{ fontSize: '14px' }}>
                          <div className={classnames('col-4')}>
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                              style={{
                                marginLeft: '0px',
                                marginLeft: '0px',
                                fontWeight: 700,
                                fontSize: '17px'
                              }}>
                              Add GST
                            </label>
                          </div>
                          <div className={classnames('col-7 d-flex justify-content-end')}>
                            <button
                              className={style.applyButton}
                              onClick={() => {
                                setGstModal(true);
                              }}>
                              Add{' '}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 col-md-4 col-xl-4">
                    <div
                      className={classnames(style.summaryContainer)}
                      style={{ marginTop: '45px' }}>
                      <div className={style.orderSum}>
                        <h3>Order Summary</h3>
                      </div>
                      <div className={style.summarySubContainer}>
                        {ordersummary()}
                        <div className="row justify-content-center m-0">
                          <button
                            onClick={() => {
                              if (auth && auth.user) {
                                if (addressdata && addressdata.length > 0) {
                                  setDisplay('summary');
                                  setPromo(false);
                                } else {
                                  setSnack(true);
                                  setSnackMessage('Please add an address to proceed');
                                }
                              }
                            }}
                            className={style.checkoutBtn}>
                            Next
                          </button>
                        </div>
                        {/* Will Implement Soon */}
                        {addpromocode()}
                        {promotionAdded === true && offerType === 'coupon' ? (
                          <div className="row m-0 ">
                            <button className={style.couponBtn}>
                              <div className="row">
                                <div className="col-9  d-flex justify-content-center">
                                  {offerSelectedValue && offerSelectedValue.coupon_code
                                    ? offerSelectedValue.coupon_code
                                    : 'offer'}{' '}
                                  Applied
                                </div>
                                <div className="col-2 d-flex justify-content-end">
                                  <img
                                    className="d-flex justify-contnetn-end"
                                    src={
                                      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                    }
                                    style={{ width: '90%' }}
                                    onClick={() => {
                                      setPromotionAdded(false);
                                      setOfferSelectedValue({});
                                      setOfferType('');
                                      setPromotionalDiscount(0);
                                    }}
                                  />
                                </div>
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div className="row m-0 ">
                            <button
                              className={style.couponBtn}
                              onClick={() => {
                                setCouponDrawer(true);
                              }}>
                              Apply Coupon Code
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {display === 'summary' && (
              <>
                <div className="row">
                  <div className="col-sm-12 col-lg-8 col-md-8 col-xl-8">
                    <div className={classnames(style.roomtype1, 'row')}>
                      <div className="col-6 d-flex justify-content-start">
                        <span className={style.heading1}> Order Summary</span>
                      </div>
                    </div>

                    {editPackage === false && (
                      <div>
                        <div
                          className={classnames(
                            ' container',
                            style.roomContainer,
                            style.roomtype1
                          )}>
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-8 col-4">
                              <img
                                className="img-fluid"
                                src={
                                  packages &&
                                  userRoomDetails &&
                                  getImage(packages, userRoomDetails.package)
                                }
                                alt=""
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-8 col-4">
                              <div className={style.textTrans}>Package</div>
                              <div>
                                <span className={style.heading}>
                                  {userRoomDetails && userRoomDetails?.package?.title}
                                </span>
                              </div>
                              {userRoomDetails && userRoomDetails.package && (
                                <div className={style.price}>
                                  Rs.{' '}
                                  {userRoomDetails.package.price === 'Free'
                                    ? 0
                                    : new Intl.NumberFormat('en-IN').format(
                                        userRoomDetails.package.price
                                      )}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-8 col-4">
                              <div className={style.textTrans}>Room Type</div>
                              <div>
                                {' '}
                                <span className={style.heading}>
                                  {' '}
                                  {userRoomDetails && userRoomDetails.room}{' '}
                                </span>
                              </div>
                            </div>
                            {upgradePackage === false ? (
                              <div
                                className="col-lg-2 col-md-2 col-sm-4 d-flex justify-content-end"
                                onClick={() => {
                                  setEditPackage(true);
                                }}>
                                <u>Edit Room</u>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {addedRooms &&
                          addedRooms.length > 0 &&
                          addedRooms.map((data, index) => {
                            return (
                              data.hasOwnProperty('package') &&
                              data.hasOwnProperty('room') && (
                                <div
                                  className={classnames(
                                    ' container',
                                    style.roomContainer,
                                    style.roomtype1
                                  )}>
                                  <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-8 col-4">
                                      <img
                                        className="img-fluid"
                                        src={packages && getImage(packages, data.package)}
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-8 col-4">
                                      <div className={style.textTrans}>Package</div>
                                      <div>
                                        <span className={style.heading}>
                                          {data && data?.package?.title}
                                        </span>
                                      </div>
                                      {data && data.package && (
                                        <div className={style.price}>
                                          Rs.{' '}
                                          {data.package.price === 'Free'
                                            ? 0
                                            : new Intl.NumberFormat('en-IN').format(
                                                data.package.price
                                              )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-8 col-4">
                                      <div className={style.textTrans}>Room Type</div>
                                      <div>
                                        {' '}
                                        <span className={style.heading}> {data && data.room} </span>
                                      </div>
                                    </div>
                                    <div
                                      className="col-lg-2 col-md-2 col-sm-4 d-flex justify-content-end"
                                      onClick={() => {
                                        setEditPackage(true);
                                      }}>
                                      <u>Edit Room</u>
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })}
                      </div>
                    )}

                    <div className={classnames(' container', style.roomContainer, style.roomtype2)}>
                      <>
                        <div className="row">
                          <div className="col-6 d-flex flex-column align-items-start justify-content-start">
                            <h5>Shipping Address</h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            <p
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setCurrenAddressData(latestAddressId, addressdata);
                                setCurrentAddressId(latestAddressId);
                                setModal(true);
                                setAddNewAddress(false);
                              }}>
                              <u> Edit</u>
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-6 d-flex flex-column align-items-start justify-content-start">
                            <p style={{ fontSize: '15px', fontWeight: '500' }}>
                              {getAddressValues(addressdata[latestAddressId]?.address)}
                            </p>
                          </div>
                        </div>
                      </>
                    </div>
                    <div className={classnames(' container', style.roomContainer, style.roomtype2)}>
                      <div
                        className="row"
                        style={{
                          padding: ' 0px 19px',
                          fontSize: '16px',
                          fontWeight: 600
                        }}>
                        Your Order Information will be sent to {auth.user && auth.user.email}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 col-md-4 col-xl-4">
                    <div className={classnames(style.summaryContainer)}>
                      <div className={style.orderSum}>
                        <h3>Order Summary</h3>
                      </div>
                      <div className={style.summarySubContainer}>
                        {ordersummary()}
                        <div className="row justify-content-center m-0">
                          <button
                            onClick={() => {
                              if (auth && auth.user) {
                                setDisplay('payment');
                                setPromo(false);
                              }
                            }}
                            className={style.checkoutBtn}>
                            Continue to payment
                          </button>
                        </div>
                        {addpromocode()}
                        {promotionAdded === true && offerType === 'coupon' ? (
                          <div className="row m-0 ">
                            <button className={style.couponBtn}>
                              <div className="row">
                                <div className="col-9  d-flex justify-content-center">
                                  {offerSelectedValue && offerSelectedValue.coupon_code
                                    ? offerSelectedValue.coupon_code
                                    : 'offer'}{' '}
                                  Applied
                                </div>
                                <div className="col-2 d-flex justify-content-end">
                                  <img
                                    className="d-flex justify-contnetn-end"
                                    src={
                                      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                    }
                                    style={{ width: '90%' }}
                                    onClick={() => {
                                      setPromotionAdded(false);
                                      setOfferSelectedValue({});
                                      setOfferType('');
                                      setPromotionalDiscount(0);
                                    }}
                                  />
                                </div>
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div className="row m-0 ">
                            <button
                              className={style.couponBtn}
                              onClick={() => {
                                setCouponDrawer(true);
                              }}>
                              Apply Coupon Code
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {display === 'payment' && (
              <>
                <div className="row">
                  <div className="col-sm-12 col-lg-8 col-md-8 col-xl-8">
                    <div className={classnames(style.roomtype1, 'row')}>
                      <div className="col-6 d-flex justify-content-start">
                        <span className={style.heading1}> Order Summary</span>
                      </div>
                    </div>

                    <div className={classnames(' container', style.roomContainer, style.roomtype2)}>
                      <>
                        <div className="col-12 font-weight-medium">
                          <h5>Your Pixel and Mortar credit</h5>
                        </div>

                        <div className="col-12 ml-2">
                          <div className="form-check ml-1">
                            {creditsvalue > 0 && (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={addCredit ? true : false}
                                onChange={() => setAddCredit(!addCredit)}
                              />
                            )}
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                              style={{ marginLeft: '0px' }}>
                              Use Your {creditsvalue} pixel and mortar credit
                            </label>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 col-md-4 col-xl-4">
                    <div
                      className={classnames(style.summaryContainer)}
                      style={{ marginTop: '20px' }}>
                      <div className={style.orderSum}>
                        <h3>Order Summary</h3>
                      </div>
                      <div className={style.summarySubContainer}>
                        {ordersummary()}
                        <div className="row justify-content-center m-0">
                          <Button
                            variant="dark"
                            disabled={error !== '' || loading}
                            className={style.checkoutBtn}
                            type="submit"
                            onClick={() => {
                              if (auth && auth.user) {
                                placePackageOrder();
                              }
                            }}>
                            {loading && (
                              <Spinner as="span" size="sm" role="status" animation="border" />
                            )}
                            Checkout
                          </Button>
                        </div>
                        {addpromocode()}

                        {promotionAdded === true && offerType === 'coupon' ? (
                          <div className="row m-0 ">
                            <button className={style.couponBtn}>
                              <div className="row">
                                <div className="col-9  d-flex justify-content-center">
                                  {offerSelectedValue && offerSelectedValue.coupon_code
                                    ? offerSelectedValue.coupon_code
                                    : 'offer'}{' '}
                                  Applied
                                </div>
                                <div className="col-2 d-flex justify-content-end">
                                  <img
                                    className="d-flex justify-contnetn-end"
                                    src={
                                      'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'
                                    }
                                    style={{ width: '90%' }}
                                    onClick={() => {
                                      setPromotionAdded(false);
                                      setOfferSelectedValue({});
                                      setOfferType('');
                                      setPromotionalDiscount(0);
                                    }}
                                  />
                                </div>
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div className="row m-0 ">
                            <button
                              className={style.couponBtn}
                              onClick={() => {
                                setCouponDrawer(true);
                              }}>
                              Apply Coupon Code
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      )}

      <Modal
        show={modal}
        centered
        className={`${style.modalss} sm-mt-5`}
        style={{ overflow: 'scroll' }}>
        {dimension[0] > 500 ? (
          <div className={style.modalContainer}>
            <h5 className={style.addNew}>
              {addNewAddress === true ? 'Add New Address' : 'Edit Address'}
            </h5>
            <img
              style={{
                position: 'absolute',
                width: '3%',
                right: dimension[0] > 500 ? '5%' : '10%',
                top: dimension[0] < 500 ? '3%' : '0%',
                cursor: 'pointer'
              }}
              onClick={() => setModal(false)}
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
              alt=""
            />

            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div style={{ width: '100%' }}>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                  <label className="form-label" style={{ fontWeight: 500 }}>
                    Project Pincode
                  </label>
                  <input
                    className={style.checkoutInput}
                    name="project_pin"
                    placeholder="Project Pincode"
                    value={inputs.project_pin}
                    onChange={handleChangeDelivery}
                  />
                  <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                    {addressError.project_pin}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Address Line 1
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.line1}
                  onChange={handleChangeDelivery}
                  name="line1"
                  placeholder="Address line1"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.line1}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Address Line 2
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.line2}
                  onChange={handleChangeDelivery}
                  name="line2"
                  placeholder="Address line2"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.line2}
                </p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Postcode
                </label>
                <br></br>
                <input
                  type="number"
                  className={style.modal_input}
                  value={inputs.postcode}
                  onChange={handleChangeDelivery}
                  name="postcode"
                  placeholder="Postcode"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.postcode}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  City/District
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={inputs.city}
                  onChange={handleChangeDelivery}
                  name="city"
                  placeholder="City"
                />
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.city}
                </p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  State
                </label>
                <br></br>

                <select
                  name="state"
                  placeholder="Select State"
                  className={style.modal_input}
                  value={inputs.state}
                  onChange={handleChangeDelivery}>
                  <option>Select state</option>
                  {Object.keys(cities).map(state => (
                    <option key={`${state}`} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.state}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500 }}>
                  Phone*
                </label>
                <br></br>
                <input
                  type="number"
                  className={style.modal_input}
                  value={inputs.phone}
                  onChange={handleChangeDelivery}
                  name="phone"
                  placeholder="Phone Number"
                />

                <p className="text-red" style={{ fontSize: 10, color: 'red', fontWeight: 700 }}>
                  {addressError.phone}
                </p>
              </div>
            </div>
            {addNewAddress ? (
              <>
                <h5 className=" row m-0 mt-1 ml-3" style={{ fontSize: '17px' }}>
                  Address Time
                </h5>
                <div className="row  m-0 mt-3 ml-3">
                  <div className="col-6 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="home"
                      id="home-radio"
                      checked={addressType === 'home' || false}
                      onChange={e => {
                        setAddressType('home');
                        handleChangeDelivery(e);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="home-radio"
                      style={{
                        marginLeft: '0px',
                        fontSize: '14px',
                        fontFamily: 'QuickSand',
                        fontWeight: '500'
                      }}>
                      Home (All day Delivery)
                    </label>
                  </div>
                  <div className="form-check col-6">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="work"
                      id="work-radio"
                      checked={addressType === 'work' || false}
                      onClick={e => {
                        setAddressType('work');
                        handleChangeDelivery(e);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="work-radio"
                      style={{
                        marginLeft: '0px',
                        fontSize: '14px',
                        fontFamily: 'QuickSand',
                        fontWeight: '500'
                      }}>
                      Work (Between 10 AM - 5 PM)
                    </label>
                  </div>
                </div>
              </>
            ) : null}
            {addNewAddress === true ? (
              <div className="d-flex justify-content-center mt-5 mb-5">
                <button
                  className={style.paymentBtn}
                  style={{ fontSize: '14px', padding: '10px 30px' }}
                  onClick={() => {
                    handleSubmitAddress();
                  }}>
                  Save and deliver here
                </button>
              </div>
            ) : (
              <>
                <div className="row d-flex justify-content-between mt-4 mb-5">
                  <div className="col-12 col-md-6 pl-5">
                    <button
                      className={style.paymentBtn}
                      style={{ paddingLeft: '30px', paddingRight: '30px' }}
                      onClick={() => {
                        handleUpdateAddress();
                      }}>
                      Edit Address
                    </button>
                  </div>

                  <div className="col-12 col-md-6 pl-4">
                    <button
                      className={style.paymentBtn}
                      style={{ paddingLeft: '30px', paddingRight: '30px' }}
                      onClick={() => {
                        setModal(false);

                        setLatestAddressId(addressdata.length - 2);
                        handleDeleteAddress(addressdata[currentAddressId]._id);
                      }}>
                      Delete Address
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div
            className={classnames(style.modalContainer, 'm-1')}
            style={{ overflowY: 'scroll', height: '100%' }}>
            <div className="row mt-2 m-1 mb-2">
              <div className="col-8 d-flex justify-content-start align-items-center">
                <h5 className={style.addNew}>
                  {addNewAddress === true ? 'Add New Address' : 'Edit Address'}
                </h5>
              </div>
              <div className="col-4 d-flex justify-content-end align-items-center">
                <img
                  style={{ cursor: 'pointer', width: '25%' }}
                  onClick={() => setModal(false)}
                  src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
                  alt=""
                />
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              {addNewAddress ? (
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                  <input
                    placeholder="Project Pincode"
                    className={style.modal_input}
                    name="project_pin"
                    value={inputs.project_pin}
                    onChange={handleChangeDelivery}
                  />
                  <p className="text-danger"> {addressError.project_pin}</p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="text"
                  placeholder="Address Line1"
                  className={style.modal_input}
                  value={inputs.line1}
                  onChange={handleChangeDelivery}
                  name="line1"
                />
                <p className="text-danger">{addressError.line1}</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="text"
                  placeholder="Address Line2"
                  className={style.modal_input}
                  value={inputs.line2}
                  onChange={handleChangeDelivery}
                  name="line2"
                />
                <p className="text-danger">{addressError.line2}</p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="number"
                  placeholder="Postcode"
                  className={style.modal_input}
                  value={inputs.postcode}
                  onChange={handleChangeDelivery}
                  name="postcode"
                />
                <p className="text-danger">{addressError.postcode}</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="text"
                  placeholder="City/District"
                  className={style.modal_input}
                  value={inputs.city}
                  onChange={handleChangeDelivery}
                  name="city"
                />
                <p className="text-danger">{addressError.city}</p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <select
                  name="state"
                  placeholder="Select State"
                  className={style.modal_input}
                  value={inputs.state}
                  onChange={handleChangeDelivery}>
                  <option>Select state</option>
                  {Object.keys(cities).map(state => (
                    <option value={state}>{state}</option>
                  ))}
                </select>
                <p className="text-danger">{addressError.state}</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mb-2">
                <input
                  type="number"
                  placeholder="Phone"
                  className={style.modal_input}
                  value={inputs.phone}
                  onChange={handleChangeDelivery}
                  name="phone"
                />
                <p className="text-danger">{addressError.phone}</p>
              </div>
            </div>
            {addNewAddress ? (
              <>
                <h5 className=" row m-0 mt-3 ml-3">Address Time</h5>
                <div className=" row   m-0 mt-2">
                  <input
                    type="radio"
                    name="home"
                    id="flexRadioDefault1"
                    checked={addressType === 'home' || false}
                    onClick={e => {
                      setAddressType('home');
                      handleChangeDelivery(e);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                    style={{
                      marginLeft: '0px',
                      fontSize: '14px',
                      fontFamily: 'QuickSand',
                      fontWeight: '500'
                    }}>
                    Home (All day Delivery)
                  </label>
                </div>
                <div className=" row m-0 mt-2">
                  <input
                    type="radio"
                    name="work"
                    id="flexRadioDefault1"
                    checked={addressType === 'work' || false}
                    onClick={e => {
                      setAddressType('work');
                      handleChangeDelivery(e);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                    style={{
                      marginLeft: '0px',
                      fontSize: '14px',
                      fontFamily: 'QuickSand',
                      fontWeight: '500'
                    }}>
                    Work(delivery between 10AM - 5 PM)
                  </label>
                </div>
              </>
            ) : null}
            {addNewAddress === true ? (
              <div className="d-flex justify-content-left mt-5  mb-5">
                <button
                  className={style.paymentBtn}
                  style={{ fontSize: '14px', padding: '10px 30px' }}
                  onClick={() => {
                    handleSubmitAddress();
                  }}>
                  Save and deliver here
                </button>
              </div>
            ) : (
              <>
                <div className="row d-flex justify-content-between mt-5 mb-5">
                  <div className="col-6">
                    <button
                      className={style.paymentBtn}
                      style={{ paddingLeft: '30px', paddingRight: '30px' }}
                      onClick={() => {
                        handleUpdateAddress();
                      }}>
                      Edit Address
                    </button>
                  </div>

                  <div className="col-6">
                    <button
                      className={style.paymentBtn}
                      style={{ paddingLeft: '30px', paddingRight: '30px' }}
                      onClick={() => {
                        setModal(false);

                        setLatestAddressId(addressdata.length - 2);
                        handleDeleteAddress(addressdata[currentAddressId]._id);
                      }}>
                      Delete Address
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Modal>

      <Modal show={deleteModal} centered>
        <div className={classnames('row justify-content-center', style.modalRow_delete)}>
          <div className=" row justify-content-center w-100 mt-5" style={{ height: '50px' }}>
            <span className={style.deleteConfirm}>
              Are you sure you want to delete this Address?
            </span>
          </div>
          <div className=" row justify-content-center w-100">
            <span className={style.deleteInfo}>Once you delete, you cannot undo the action.</span>
          </div>
          <div className="row justify-content-center" style={{ height: '100px' }}>
            <div className="col-6">
              <button onClick={() => setDeleteModal(false)} className={style.cancelBtn}>
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button className={style.deleteBtn} onClick={() => {}}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        style={{
          backgroundColor: 'transparent',
          width: dimension[0] > 500 ? '400px' : '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          right: 0
        }}
        show={gstModal}
        onHide={() => setGstModal(false)}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalContainer} style={{ padding: '0px 31px' }}>
          <div>
            <h5 className={style.gstTxt}>Your GST Information</h5>
            <img
              style={{
                position: 'absolute',
                width: '3%',
                right: dimension[0] > 500 ? '5%' : '10%',
                top: dimension[0] < 500 ? '3%' : '0%',
                cursor: 'pointer'
              }}
              onClick={() => setGstModal(false)}
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
              alt=""
            />

            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className={style.inputGst}>
                <input
                  type="text"
                  className={gstError ? style.modal_input1 : style.modal_input}
                  value={gstinValue}
                  placeholder="GSTIN"
                  onChange={e => {
                    handleChangeGstin(e.target.value);
                  }}
                  name="gstin"></input>
                {error === 'gstin' && (
                  <span style={{ color: 'red', fontSize: '11px' }}>Invalid</span>
                )}
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className={style.inputGst}>
                <input
                  type="text"
                  className={style.modal_input}
                  value={gstBusiness}
                  placeholder="Business Name"
                  onChange={e => {
                    setGstBusiness(e.target.value);
                  }}
                  name="business_name"
                />
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-12 d-flex justify-content-center align-items-center">
                <Icon.ExclamationCircleFill />
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label pl-2"
                  style={{
                    fontWeight: 600,
                    fontSize: '10px',
                    marginTop: '8px'
                  }}>
                  <b>Incorrect GSTIN details will lead to order cancellation</b>
                </label>
                <br></br>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 mb-2">
              <button
                className={style.paymentBtn}
                style={{
                  padding: '12px 0px',
                  marginBottom: '17px',
                  borderRadius: '0px',
                  width: '92%'
                }}
                onClick={() => {
                  setModal(false);
                  handleSubmitGSTIN();
                }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Drawer
        anchor={'right'}
        open={couponDrawer}
        classes={{
          paper: dimension[0] > 500 ? classes1.paper : classes2.paper
        }}>
        <div className="">
          <div className="row d-flex col-12 justify-content-end">
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/rooms-assets/Group+3156.svg'}
              onClick={() => {
                setCouponDrawer(false);
              }}
            />
          </div>
          <div className="mt-5 ml-3">
            <b>Coupons</b>
          </div>
          {couponsLoading ? (
            <div>
              <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
            </div>
          ) : (
            <>
              {availableOffers.length > 0 &&
                availableOffers.map((offer, i) => {
                  return (
                    <div key={`offer${i}`} className="row ml-3 mt-3 d-flex justify-content-start">
                      <Card
                        style={{
                          width: '80%',
                          backgroundColor: 'white'
                        }}>
                        <CardContent>
                          <div className="row mt-3  ml-3 d-flex justify-content-start">
                            <span>Available</span>
                          </div>
                          <div className="row mt-3  ml-3 d-flex justify-content-start">
                            <span className={style.couponTitle}>{offer.coupon_code}</span>
                          </div>
                          <div className="row mt-2 ml-3 d-flex justify-content-start">
                            <span className={style.couponDesc}>{offer.notes}</span>
                          </div>
                          <div className="row mt-3 ml-3 d-flex justify-content-start align-items-start">
                            <button
                              className={style.applyBtn}
                              onClick={() => {
                                handleCoupons(offer);
                              }}>
                              Apply
                            </button>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              {availableOffers.length <= 0 && (
                <div className="mt-4">
                  <h4>No offers availabe right now</h4>
                </div>
              )}
            </>
          )}
        </div>
      </Drawer>

      <ToastContainer autoClose={5000} pauseOnFocusLoss={false} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, {
  storeTab,
  reset_answers,
  questionnaire,
  page,
  storeMx,
  page2,
  storeMxAns,
  storePackage
})(withRouter(PaymentPackage));
function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
