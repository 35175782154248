import React, { useEffect, useState } from 'react';
import './MyOrders.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import dateformat from 'dateformat';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import customerApiInstance from '../../utils/CustomerApiInstance';

const Order = ({ history, date, auth }) => {
  let userEmail;
  if (auth && auth.user) {
    userEmail = auth.user.email;
  } else {
    userEmail = 'nil';
  }
  const { id } = useParams();
  let projectId = id;
  let headerFlag;
  let statehistory = history.location.state;
  if (statehistory) {
    headerFlag = history.location.state.showHeader;
  }
  const [deliveryDate, setDeliveryDate] = useState('');
  const [project, setProject] = useState({});
  const image_url = 'https://d3izcfql7lesks.cloudfront.net/shutterstock_1465769315_xurzls.png';
  const mystyle = {
    backgroundImage: `url(${image_url})`
  };

  useEffect(() => {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 7);
    setDeliveryDate(dateformat(newDate, 'dddd, mmmm dS'));
  }, [date]);

  useEffect(() => {
    const fetchProject = async () => {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );
      setProject(res.data);
      if (res.data.questionnaire && res.data.questionnaire.MX_A_ROOM_READY) {
        if (res.data.questionnaire.MX_A_ROOM_READY.expected_date) {
          let newDate = new Date(res.data.questionnaire.MX_A_ROOM_READY.expected_date);
          newDate.setDate(newDate.getDate());
          setDeliveryDate(dateformat(newDate, 'dddd, mmmm dS'));
        } else {
          setDeliveryDate('30 Days');
        }
      }
    };

    fetchProject();
  }, []);

  return (
    <>
      <Header />
      {headerFlag && (
        <div className="black" style={{ paddingTop: '3.8%', paddingBottom: '15%' }}>
          <img
            className="padd"
            src={'https://d3izcfql7lesks.cloudfront.net/Group_2219_r48lnr.svg'}
            alt=""
          />

          <h3 className="thanks">
            Thank you for your purchase! You have chosen the Premium Package!
          </h3>
          <h6 className="email">We have emailed your order to {userEmail}</h6>
        </div>
      )}
      <>
        <div
          className="home-card-view flex-center d-flex justify-content-center align-items-center flex-colum"
          style={mystyle}>
          <div className="container d-flex justify-content-center align-items-center flex-column container-order">
            <p>
              <strong>STEP 2</strong>
            </p>
            <br />

            <p>
              {' '}
              <strong>ROOM PROFILE ( {project.name || 'My room'})</strong>
            </p>
            <p style={{ width: '80%' }}>
              We’re building an amazing game plan for you! Add more details about your space for a
              clearer idea of your room. Then we can begin your project on{' '}
              <span style={{ textDecoration: 'underline' }}>
                <strong>{deliveryDate}</strong>
              </span>
            </p>
            <br />
            <button
              className="getStartedButton2"
              style={{}}
              onClick={() => {
                history.push({
                  pathname: `/room-profile/${projectId}`,
                  state: { navbar: true, project_id: id }
                });
              }}>
              Complete your room profile
            </button>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

Order.propTypes = {
  date: PropTypes.string.isRequired,
  questionnaireData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  date: state.sample.date,
  auth: state.auth,
  sample: state.sample
});

export default connect(mapStateToProps)(withRouter(Order));
