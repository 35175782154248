import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import './OrderFailed.css';
import * as Icon from 'react-bootstrap-icons';

const OrderFailed = ({ history }) => {
  return (
    <div>
      <Header />
      <>
        <div className="back">
          <div className="content" style={{ paddingTop: '100px', paddingBottom: '15%' }}>
            <div>
              <div className="check d-flex justify-content-center align-items-center flex-colum">
                <Icon.XCircle size="25" />
              </div>
              <h3 className="thanks mt-3 d-flex justify-content-center align-items-center flex-colum">
                Oops! something went wrong.
              </h3>
            </div>
            <div className="failedOrder">
              <h5 className="email mt-3 d-flex justify-content-center align-items-center flex-colum">
                Your purchase was unsuccessful. Any amount credited will be refunded in 3 - 7
                working days.
              </h5>
              <h5 className="email mt-3 d-flex justify-content-center align-items-center flex-colum">
                For further enquires contact us at: info@pixelandmortar.com
              </h5>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 col-lg-12 col-sm-12 d-flex  align-items-center justify-content-center">
                <button
                  className="button1"
                  onClick={() => {
                    history.push('/cart');
                  }}>
                  Back To Checkout{' '}
                </button>
              </div>
              <div className="row mt-5">
                <img
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/order-confirmation/order-static.png"
                  style={{ width: '98%', height: '130%' }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
};

export default OrderFailed;
