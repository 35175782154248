import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import style from './Shop.module.css';
import classnames from 'classnames';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { categoryAll } from "./constant";
// import { makeStyles } from "@material-ui/core/styles";

import { useParams } from 'react-router-dom';
import customerApiInstance from '../../../utils/CustomerApiInstance';
import { storeCartCount } from '../../../store/actions/sampleaction';
// import getCartDataCount from "../../../utils/CartDetails";
import ProductList from './ProductList';

const Shop = ({ page, auth, history, storeCartCount }) => {
  const dimension = useMediaQuery();
  // const [animation, setAnimation] = useState(false);
  // const [productsList, setProductsList] = useState([]);
  // const [addedToFavourites, setAddedToFavourites] = useState(false);
  // const [isHovered, setHover] = useState(false);
  const [categories, setCategories] = useState([]);
  //const [brands, setBrands] = useState([]);
  // const [tempProductsList, setTempProductsList] = useState([]);
  // const [currentCardId, setCurrentCardId] = useState("");
  // const [currentCardFavId, setCurrentCardFavId] = useState("");
  // const [searchInput, setSearchInput] = useState("");
  // const [updateSearchInput, setUpdateSearchInput] = useState("");
  //const [snack, setSnack] = useState(false);
  // const [snackMessage, setSnackMessage] = useState("");
  // const [snackError, setSnackError] = useState(false);
  // const [prodQuantity] = useState(1);
  // const [savedProductIds, setSavedProductIds] = useState([]);
  // let [vendors, setVendors] = useState([]);
  // const [savedProducts, setSavedProducts] = useState([]);
  // const [brandsData, setBrandsData] = useState([]);
  // const [brandClicked, setBrandClicked] = useState(false);
  // const [brandImage, setBrandImage] = useState();
  //  const [currentBrand, setCurrentBrand] = useState({});
  const [showMore] = useState(false);
  // const [expanded, setExpanded] = useState(false);
  // const [roomExpanded, setRoomExpanded] = useState(false);
  // const [brandExpanded, setBrandExpanded] = useState(false);
  // const [colorExpanded, setColorExpanded] = useState(false);
  // const [materialExpanded, setMaterialExpanded] = useState(false);
  // const [styleExpanded, setStyleExpanded] = useState(false);
  // const [colors, setColors] = useState(["Brown", "Red"]);
  //const [material, setMaterial] = useState([]);
  // const [styles1, setStyles1] = useState();
  // stylesFilter.length > 0 ? [...stylesFilter] : []
  // const [divider, setDivider] = useState(1);
  const [moodboardProductsData, setMoodboardProductsData] = useState([]);
  // const [productsDisplay, setProductsDisplay] = useState([]);
  const [colorFilter, setColorFilter] = useState([]);
  const [colorClicked] = useState('');
  // const [filterOption, setFilterOption] = useState({});
  // const [colorApplied, setColorApplied] = useState(false);
  // const [open, setOpen] = useState({});
  // const [currentProduct, setCurrentProduct] = useState({});
  // const [shareModal, setShareModal] = useState(false);
  // const copy = useRef();
  // const [modal, setModal] = useState({ modal: false, name: "", id: null });
  // const [copiedUrl, setCopiedUrl] = useState("");
  // const [overlay, setOverlay] = useState("");
  //const [cardOverlay, setCardOverlay] = useState();
  //const [drawer, setDrawer] = useState(false);
  //const [upgradeDrawer, setUpgradeDrawer] = useState(false);
  const { id } = useParams();
  //const [moodBoards, setMoodBoards] = useState([]);
  //const [products, setProducts] = useState([]);
  // const [moodboardImages, setMoodboardImages] = useState([]);
  const [likesChecked] = useState(false);
  // const [data, setData] = useState();
  // const [setTempProducts] = useState([]);
  //const [setEnabledProductsData] = useState([]);
  // const [setDisabledProductsData] = useState([]);
  // const [similarShow, setSimilarShow] = useState(false);
  // const [similarExpanded, setSimilarExpanded] = useState(false);
  // const [shopIndex, setShopIndex] = useState();
  // const [similarProductsShow, setSimilarProductsshow] = useState([]);
  // const [customerRating, setCustomerRating] = useState(0);
  // const [confirmModal, setConfirmModal] = useState(false);
  //const [proceed, setProceed] = useState("");

  //  const [categoriesnew, setCategoriesnew] = useState({});
  //  const [filterOptions] = useState({});
  // const [loading, setLoading] = useState(false);
  // const [animationModal, setAnimationModal] = useState({
  //   modalOpen: false,
  // });
  // const [animationData, setAnimationData] = useState();
  const [userData, setUserData] = useState({ name: '', roomType: '' });
  const [moodboardUrl, setMoodboardUrl] = useState('');

  useEffect(() => {
    const getProject = async () => {
      const res = await customerApiInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );
      let project = res.data;
      if (project && project.hasOwnProperty('customer_name')) {
        setUserData({
          name: project.customer_name + "'s",
          roomType: project.questionnaire?.MX_A_INITAL_ROOM
        });
      }
    };
    id && getProject();
  }, []);

  // const breakPoints = [
  //   { width: 1, itemsToShow: 1 },
  //   { width: 550, itemsToShow: 1, itemsToScroll: 1 },
  //   { width: 768, itemsToShow: 3 },
  //   { width: 2000, itemsToShow: 3 },
  //   { width: 4000, itemsToShow: 6 },
  // ];

  //let [currentValue, setCurrentValue] = useState("");
  let numberOfItems = 10;

  // const rooms = [
  //   "Living Room",
  //   "Dining Room",
  //   "Bed Room",
  //   "Kids room",
  //   "Study Entertainment Room",
  //   "Puja Room",
  //   "Foyer",
  //   "Balcony",
  //   "Others",
  // ];

  if (categories && categories.length > 0) {
    numberOfItems = showMore ? categories.length : 10;
  }

  // const handleClose = () => {
  //   setOpen({ ...open, modal: false });
  // };

  // const handleHover = (value) => {
  //   setOverlay(value);
  // };
  // const getSimilarProducts = (data) => {
  //   // let similar = []
  //   // similar = disabledProductsData.filter((pd) => pd.uuid === dataId)
  //   // setSimilarProductsshow([...similar])
  //   let otherProducts = moodboardProductsData.filter(
  //     (prod) => prod._id !== data._id
  //   );
  //   let styleFilterProducts = otherProducts.filter(
  //     (prod) => prod.style === data.style
  //   );
  //   let roomFilterProducts = otherProducts.filter((prod) =>
  //     prod.room_type.filter((room) => data.room_type.includes(room))
  //   );
  //   let styleAppliedProds = [...styleFilterProducts, ...roomFilterProducts];
  //   styleAppliedProds = styleAppliedProds.filter(
  //     (v, i, a) => a.findIndex((t) => t._id === v._id) === i
  //   );
  //   setSimilarProductsshow([...styleAppliedProds]);
  // };
  // useEffect(() => {
  //   if (dimension[0] > 1 && dimension[0] < 550) {
  //     setDivider(1);
  //   }
  //   if (dimension[0] > 550 && dimension[0] < 768) {
  //     setDivider(2);
  //   }
  //   if (dimension[0] > 768) {
  //     setDivider(3);
  //   }
  // }, [dimension]);

  // const getCompute = (colLength) => {
  //   let add = Math.floor(colLength / divider) + divider;
  //   setAddedCol(add);
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //    // setLoading(true);
  //      await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URL}/api/filterOptions`
  //     );
  //     // const res1 = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/list`);
  //    // setLoading(false);
  //     // if (res.data.colors) {
  //     //   // ConsoleHelper("rsss", res.data.colors);
  //     //   setColors([...res.data.colors]);
  //     // }
  //     // if (res.data.material) {
  //     //   setMaterial([...res.data.material]);
  //     // }
  //     // if (res.data.style) {
  //     //   setStyles1([...res.data.style]);
  //     // }
  //     // if (res.data.brands) {
  //     // setBrands([...res.data.brands]);
  //     // }
  //     // if (res.data.brandsDataList) {
  //     //   setBrandsData([...res.data.brandsDataList]);
  //     // }
  //     setCategories([...categoryAll]);
  //   };
  //   // fetchData()
  // }, []);

  useEffect(() => {
    const vendorDetails = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendor/list`);
      // if (res.data && res.data.length > 0) {
      //   setVendors([...res.data]);
      // }
    };
    vendorDetails();
  }, []);

  useEffect(() => {
    const getMoodboardProducts = async () => {
      let pageType = {};
      pageType = {
        status: 'FINALIZE_CONCEPT'
      };
      // ConsoleHelper("pageV", pageType);
      const moodboard = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/concept/${id}`,
        pageType
      );
      if (moodboard && moodboard.data.moodBoard) {
        // setTempProducts([...moodboard.data.moodBoardProducts]);
        let productsArray = [];
        let productsData = [];
        let disabledProducts = [];
        let moodboardProducts = moodboard.data.moodBoardProducts;
        let moodboardData = moodboard.data.moodBoard;
        if (
          moodboard.data.moodBoard &&
          moodboard.data.moodBoard.hasOwnProperty('shared_to_customer')
        ) {
          productsArray = moodboard.data.moodBoard.shared_to_customer.products;
          productsArray.map(prod => {
            prod.map(prod1 => {
              if (prod1.visible === true) {
                productsData.push(prod1);
              }
            });
          });
        }
        //setProducts([...productsData]);
        let groupEnabledProducts = productsData.reduce(function(obj, item) {
          obj[item.id] = obj[item.id] || [];
          if (item.enabled === true) obj[item.id].push(item.product_id);
          return obj;
        }, {});

        let enabledProducts = Object.keys(groupEnabledProducts).map(function(key) {
          return { id: key, product_ids: groupEnabledProducts[key] };
        });
        let groupDisabledProducts = productsData.reduce(function(obj, item) {
          obj[item.id] = obj[item.id] || [];
          if (item.enabled === false) obj[item.id].push(item.product_id);
          return obj;
        }, {});

        if (Object.keys(groupDisabledProducts).length) {
          disabledProducts = Object.keys(groupDisabledProducts).map(function(key) {
            return { id: key, product_ids: groupDisabledProducts[key] };
          });
        }
        let enabledProdsTemp = [];
        let disabledProdsTemp = [];
        const map = new Map();
        let productIds = [];
        moodboardData.shared_to_customer &&
          moodboardData.shared_to_customer.products.map((item, index) => {
            if (item instanceof Array && item.length > 0) {
              item.map((data, key) => {
                if (data.visible === true) {
                  productIds = [...productIds, data.product_id];
                }
              });
            }
          });
        productIds.forEach(item => {
          if (map.has(item)) {
            map.get(item).quantity++;
          } else {
            map.set(item, {
              item,
              quantity: 1
            });
          }
        });

        const result = Array.from(map.values());
        for (let i = 0; i < moodboardProducts.length; i++) {
          for (let j = 0; j < result.length; j++) {
            if (moodboardProducts[i]._id === result[j].item) {
              moodboardProducts[i].quantity = result[j].quantity;
              moodboardProducts[i].final_price = moodboardProducts[i].price
                ? moodboardProducts[i].price * result[j].quantity
                : moodboardProducts[i].pixel_price * result[j].quantity;
            }
          }
        }
        // setProductsDisplay([...moodboardProducts]);
        setMoodboardProductsData([...moodboardProducts]);
        // setProductsDisplay([...enabledProdsTemp])
        if (disabledProducts.length > 0) {
          for (let i = 0; i < moodboardProducts.length; i++) {
            for (let j = 0; j < disabledProducts.length; j++) {
              disabledProducts[j].product_ids.map(disabledProdId => {
                if (disabledProdId === moodboardProducts[i]._id) {
                  disabledProdsTemp.push({
                    ...moodboardProducts[i],
                    uuid: disabledProducts[j].group_id
                  });
                }
              });
            }
          }
          // setDisabledProductsData([...disabledProdsTemp]);
        }
        for (let i = 0; i < moodboardProducts.length; i++) {
          for (let j = 0; j < enabledProducts.length; j++) {
            enabledProducts[j].product_ids.map(enabledProdId => {
              if (enabledProdId === moodboardProducts[i]._id) {
                enabledProdsTemp.push({
                  ...moodboardProducts[i],
                  uuid: enabledProducts[j].group_id
                });
              }
            });
          }
        }
        let category = moodboardProducts.map(data => {
          return data.category;
        });
        let colorTemp = [];
        let materialTemp = [];
        let brandsTemp = [];
        let brandsdataTemp = [];
        moodboardProducts.map(data => {
          colorTemp.push(data.color);
          materialTemp.push(data.material);
          if (data.vendor_id && data.vendor_id.name) {
            brandsTemp.push(data.vendor_id.name);
          }
          if (data.vendor_id && data.vendor_id._id) {
            brandsdataTemp.push(data.vendor_id._id);
          }
        });
        materialTemp = materialTemp.flat();
        // setBrands([...new Set(brandsTemp)]);
        // setMaterial([...new Set(materialTemp)]);
        //setColors([...new Set(colorTemp)]);
        //setBrandsData([...new Set(brandsdataTemp)]);
        const categoriesCount = {};
        category.forEach(x => {
          categoriesCount[x] = (categoriesCount[x] || 0) + 1;
        });
        // setCategoriesnew({ ...categoriesCount });
        // ConsoleHelper("categoriesCount", categoriesCount);
        // setEnabledProductsData([...enabledProdsTemp]);
      } else {
        // setSnack(true);
        // setSnackMessage("No moodboard available");
      }
    };
    getMoodboardProducts();
  }, []);

  useEffect(() => {
    // ConsoleHelper("inside filter");
    const body = {};
    if (colorFilter.length > 0) {
      if (colorFilter.includes('colorClicked')) {
        colorFilter = colorFilter.filter(cl => cl === colorClicked);
        setColorFilter([...colorFilter]);
        // body.color = [...colorFilter]
        body.color = colorClicked;
      } else {
        body.color = colorClicked;
        // body.color = [...colorFilter]
      }
      // ConsoleHelper("inside filter", body);
      let filters = { ...body };
      const data = {
        body: body
      };
      filterData(data);
    }
  }, [colorFilter]);

  const filterData = async filters => {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
      params: { page },
      body: { ...filters }
    });
    // ConsoleHelper("resss", res.data);
  };

  // const addToCart = async (productData) => {
  //   // ConsoleHelper("productData", productData);
  //   let userId;
  //   let prodBody;
  //   try {
  //     if (auth && auth.user) {
  //       userId = auth.user._id;

  //       prodBody = {
  //         user_id: userId,
  //         products: [
  //           {
  //             product_id: productData._id,
  //             quantity: productData.quantity,
  //             rate: productData.price || productData.pixel_price,
  //             total_price:
  //               productData.quantity *
  //               (productData.price || productData.pixel_price),
  //           },
  //         ],
  //       };
  //       const res = await customerApiInstance.post(
  //         `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
  //         prodBody
  //       );
  //       if (res.data) {
  //         setSnack(true);
  //         // setSnackError(false);
  //         setSnackMessage("Product added to cart successfully");
  //       }
  //     } else {
  //       let products = [
  //         {
  //           product_id: productData._id,
  //           quantity: prodQuantity,
  //           rate: productData.price || productData.pixel_price,
  //           total_price:
  //             prodQuantity * (productData.price || productData.pixel_price),
  //         },
  //       ];
  //       if (JSON.parse(localStorage.getItem("cartProducts"))) {
  //         const localProducts = JSON.parse(
  //           localStorage.getItem("cartProducts")
  //         );
  //         const addProducts = [...localProducts, ...products];
  //         localStorage.setItem("cartProducts", JSON.stringify(addProducts));
  //       } else {
  //         localStorage.setItem("authentication", "PENDING");
  //         localStorage.setItem("cartProducts", JSON.stringify(products));
  //       }
  //     }
  //   } catch (e) {
  //     // setSnackError(true);
  //     setSnack(false);
  //     setSnackMessage("Error in Product adding to cart");
  //     ConsoleHelper(e);
  //   }
  //   storeCartCount(await getCartDataCount(auth));
  // };

  // const filterSimilarProducts = (tempProducts, product1) => {
  //   const duplicateProducts = tempProducts;
  //   let filteredCategories = duplicateProducts.filter(
  //     (product) => product.category === product1.category
  //   );
  //   filteredCategories = filteredCategories.filter(
  //     (p) => p.style === product1.style
  //   );
  //   // setProductsDisplay([...filteredCategories]);
  // };

  // const renderColumns = (numCols) => {
  //   let cols = [];
  //   let rows = [];
  //   productsDisplay &&
  //     productsDisplay.length > 0 &&
  //     productsDisplay.map((data, index) => {
  //       cols.push(
  //         <>
  //           <div
  //             className={classnames(
  //               style.cardHoverScale,
  //               "d-flex flex-column  align-items-center"
  //             )}
  //             style={{
  //               width: "240px",
  //               height: "400px",
  //               margin: "15px",
  //               padding: "15px",
  //               backgroundColor: "white",
  //               borderRadius: "10px",
  //               border: "1px solid #A3A3A3",
  //             }}
  //             onMouseEnter={(e) => {
  //               if (similarShow !== true) setCardOverlay(index);
  //             }}
  //             onMouseLeave={(e) => {
  //               setCardOverlay(null);
  //             }}
  //           >
  //             <div className="row w-100 ">
  //               <div className="col">
  //                 <img
  //                   style={{ width: "20%" }}
  //                   src={
  //                     "https://d3izcfql7lesks.cloudfront.net/Group_2549_uk0upe.png"
  //                   }
  //                   alt=""
  //                 />
  //               </div>
  //               <div className="col text-right">
  //                 {auth && auth.user && savedProductIds?.includes(data._id) ? (
  //                   <Icon.StarFill
  //                     style={{ fill: "#FFC4C4", borderColor: "black" }}
  //                     onClick={() => {
  //                       handleFavourites(data);
  //                     }}
  //                   ></Icon.StarFill>
  //                 ) : (
  //                   <Icon.Star
  //                     onClick={() => {
  //                       handleFavourites(data);
  //                     }}
  //                   ></Icon.Star>
  //                 )}
  //               </div>
  //             </div>
  //             <div
  //               style={{ position: "relative" }}
  //               onMouseOver={() => handleHover(index)}
  //               onMouseLeave={() => setOverlay(null)}
  //             >
  //               <div
  //                 className={classnames(
  //                   style.hovered1,
  //                   "row d-flex justify-content-center m-auto"
  //                 )}
  //               >
  //                 <img
  //                   className={overlay === index ? style.overlay1 : null}
  //                   style={{ width: "100%", height: "100%" }}
  //                   src={data.image && data.image.length > 0 && data.image[0]}
  //                   alt=""
  //                 />{" "}
  //               </div>
  //               {overlay === index && (
  //                 <div className={style.overlay}>
  //                   <div className="d-flex justify-content-center align-items-center">
  //                     <button
  //                       style={{
  //                         background: "transparent",
  //                         color: "white",
  //                         marginTop: "80px",
  //                         marginBottom: "50px",
  //                         fontSize: "14px",
  //                         border: "1.5px solid #ffffff",
  //                         borderRadius: "0",
  //                         fontWeight: "500",
  //                         alignSelf: "center",
  //                       }}
  //                       type="button"
  //                       class="btn btn-light"
  //                       onClick={() => {
  //                         setCurrentProduct({ ...data });
  //                         setOpen({ ...open, modal: true, data: data });
  //                       }}
  //                     >
  //                       VIEW DETAILS{" "}
  //                     </button>
  //                   </div>
  //                 </div>
  //               )}
  //             </div>
  //             <div className="d-flex flex-column mt-3 justify-content-between align-items-center">
  //               <h6 style={{ fontSize: "13px", marginBottom: "0" }}>
  //                 {data.vendor_id && data.vendor_id.name
  //                   ? data.vendor_id.name
  //                   : "--"}
  //               </h6>
  //               <div
  //                 className="text-center"
  //                 style={{
  //                   fontSize: "16px",
  //                   fontWeight: 900,
  //                   overflow: "hidden",
  //                   textOverflow: "ellipsis",
  //                   height: "20px",
  //                   width: "180px",
  //                   whiteSpace: "nowrap",
  //                 }}
  //               >
  //                 {data.name}
  //               </div>
  //               <h5
  //                 style={{ fontSize: "14px", fontWeight: 200 }}
  //                 className="mt-3"
  //               >
  //                 Rs. {new Intl.NumberFormat("en-IN").format(data.price)}
  //               </h5>
  //             </div>

  //             {cardOverlay === index && (
  //               <div style={{ width: "100%" }}>
  //                 <Button
  //                   size="md"
  //                   className={classnames(
  //                     style.cartButton,
  //                     " mb-2  justify-content-center"
  //                   )}
  //                   onClick={() => addToCart(data)}
  //                 >
  //                   <span style={{ textAlign: "center" }}> ADD TO CART</span>
  //                 </Button>
  //                 <div
  //                   className={style.similarButton1}
  //                   onClick={() => {
  //                     setSimilarShow(!similarShow);
  //                     setSimilarExpanded(!similarExpanded);
  //                     setShopIndex(index);
  //                     getSimilarProducts(data);
  //                     setCardOverlay(index);
  //                     getCompute(index);
  //                   }}
  //                 >
  //                   <span>Shop similar Items</span>
  //                   <FontAwesomeIcon
  //                     size={10}
  //                     style={{ alignItems: "center", marginLeft: "10px" }}
  //                     icon={
  //                       similarExpanded === true && index === shopIndex
  //                         ? faChevronDown
  //                         : faChevronRight
  //                     }
  //                   />
  //                 </div>
  //               </div>
  //             )}
  //           </div>
  //         </>
  //       );
  //       if (index === addedCol - 1) {
  //         similarShow &&
  //           cols.splice(
  //             addedCol + 1,
  //             0,
  //             <div
  //               className={style.container3}
  //               style={{ padding: "10vh 5vw 10vh 5vw;" }}
  //             >
  //               <div>
  //                 <div className="row mt-3 w-100 justify-content-between">
  //                   <div className="col text-right">
  //                     <Icon.XLg
  //                       style={{ cursor: "pointer" }}
  //                       onClick={() => {
  //                         setProductsDisplay([...moodboardProductsData]);
  //                         setSimilarShow(false);
  //                       }}
  //                     ></Icon.XLg>
  //                   </div>
  //                 </div>
  //                 {similarProductsShow && similarProductsShow.length > 0 ? (
  //                   <Carousel breakPoints={breakPoints} pagination={false}>
  //                     {similarProductsShow.map((data, index) => {
  //                       return (
  //                         <>
  //                           <div
  //                             className="d-flex flex-column justify-content-between align-items-center"
  //                             style={{
  //                               width: "240px",
  //                               height: "400px",
  //                               margin: "10px",
  //                               backgroundColor: "white",
  //                               borderRadius: "10px",
  //                             }}
  //                             onMouseEnter={(e) => {
  //                               setCardOverlay(index);
  //                             }}
  //                             onMouseLeave={(e) => {
  //                               setCardOverlay(null);
  //                             }}
  //                           >
  //                             <div className="row mt-3 w-100 justify-content-between">
  //                               <div className="col">
  //                                 <img
  //                                   style={{ width: "20%" }}
  //                                   src={
  //                                     "https://d3izcfql7lesks.cloudfront.net/Group_2549_uk0upe.png"
  //                                   }
  //                                   alt=""
  //                                 />
  //                               </div>
  //                               <div className="col text-right">
  //                                 {auth &&
  //                                 auth.user &&
  //                                 savedProductIds?.includes(data._id) ? (
  //                                   <Icon.StarFill
  //                                     style={{
  //                                       fill: "#FFC4C4",
  //                                       borderColor: "black",
  //                                     }}
  //                                     onClick={() => {
  //                                       handleFavourites(data);
  //                                     }}
  //                                   ></Icon.StarFill>
  //                                 ) : (
  //                                   <Icon.Star
  //                                     onClick={() => {
  //                                       handleFavourites(data);
  //                                     }}
  //                                   ></Icon.Star>
  //                                 )}
  //                               </div>
  //                             </div>
  //                             <div
  //                               style={{ position: "relative" }}
  //                               onMouseOver={() => handleHover(index)}
  //                               onMouseLeave={() => setOverlay(null)}
  //                             >
  //                               <img
  //                                 style={{
  //                                   width: "100%",
  //                                   "padding-right": "50px",
  //                                   "padding-left": "50px",
  //                                 }}
  //                                 src={
  //                                   data.image &&
  //                                   data.image.length > 0 &&
  //                                   data.image[0]
  //                                 }
  //                                 alt=""
  //                               />
  //                               {overlay === index && (
  //                                 <div className={style.overlay}>
  //                                   <div className="d-flex justify-content-center align-items-center">
  //                                     <button
  //                                       style={{
  //                                         background: "transparent",
  //                                         color: "black",
  //                                       }}
  //                                       type="button"
  //                                       class="btn btn-light"
  //                                       onClick={() => {
  //                                         setCurrentProduct({ ...data });
  //                                         setOpen({
  //                                           ...open,
  //                                           modal: true,
  //                                           data: data,
  //                                         });
  //                                       }}
  //                                     >
  //                                       View Details{" "}
  //                                     </button>
  //                                   </div>
  //                                 </div>
  //                               )}
  //                             </div>
  //                             <div className="d-flex flex-column mb-4 justify-content-between align-items-center">
  //                               <h6 style={{ fontSize: "14px" }}>
  //                                 {data.vendor_id && data.vendor_id.name
  //                                   ? data.vendor_id.name
  //                                   : "--"}
  //                               </h6>
  //                               <h4
  //                                 style={{ fontSize: "18px", fontWeight: 900 }}
  //                               >
  //                                 <center>{data.name}</center>
  //                               </h4>
  //                               <h5
  //                                 style={{ fontSize: "14px", fontWeight: 200 }}
  //                               >
  //                                 Rs.{" "}
  //                                 {new Intl.NumberFormat("en-IN").format(
  //                                   data.price
  //                                 )}
  //                               </h5>
  //                             </div>
  //                           </div>
  //                         </>
  //                       );
  //                     })}
  //                   </Carousel>
  //                 ) : (
  //                   <div className="d-flex justify-content-center">
  //                     <b>No similar Products</b>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           );
  //       }
  //     });
  //   return (
  //     <div className="row d-flex justify-content-start align-items-start mb-5">
  //       {cols}
  //     </div>
  //   );
  // };
  // const renderColumnsMobile = (numCols) => {
  //   let cols = [];
  //   let rows = [];
  //   productsDisplay &&
  //     productsDisplay.length > 0 &&
  //     productsDisplay.map((data, index) => {
  //       cols.push(
  //         <div
  //           className="d-flex flex-column justify-content-between align-items-center"
  //           style={{
  //             width: "90%",
  //             height: "400px",
  //             margin: "30px",
  //             border: "0.5px solid #A3A3A3",
  //             backgroundColor: "white",
  //             borderRadius: "10px",
  //           }}
  //           onMouseEnter={(e) => {
  //             setCardOverlay(index);
  //           }}
  //           onMouseLeave={(e) => {
  //             setCardOverlay(null);
  //           }}
  //         >
  //           <div className="row mt-3 w-100 justify-content-between">
  //             <div className="col">
  //               <img
  //                 style={{ width: "17%" }}
  //                 src={
  //                   "https://d3izcfql7lesks.cloudfront.net/Group_3142_ql1ksg.svg"
  //                 }
  //                 alt=""
  //               />
  //             </div>
  //             <div className="col text-right">
  //               {auth && auth.user && savedProductIds?.includes(data._id) ? (
  //                 <Icon.StarFill
  //                   style={{ fill: "#FFC4C4", borderColor: "black" }}
  //                   onClick={() => {
  //                     handleFavourites(data);
  //                   }}
  //                 ></Icon.StarFill>
  //               ) : (
  //                 <Icon.Star
  //                   onClick={() => {
  //                     handleFavourites(data);
  //                   }}
  //                 ></Icon.Star>
  //               )}
  //             </div>
  //           </div>
  //           <div
  //             style={{ position: "relative" }}
  //             onMouseOver={() => handleHover(index)}
  //             onMouseLeave={() => setOverlay(null)}
  //           >
  //             <img
  //               style={{
  //                 width: "100%",
  //                 "padding-right": "50px",
  //                 "padding-left": "50px",
  //               }}
  //               src={data.image && data.image.length > 0 && data.image[0]}
  //               alt=""
  //             />
  //             {overlay === index && (
  //               <div className={style.overlay}>
  //                 <div className="d-flex justify-content-center align-items-center">
  //                   <button
  //                     style={{
  //                       background: "transparent",
  //                       color: "black",
  //                       marginTop: "50px",
  //                       marginBottom: "50px",
  //                     }}
  //                     type="button"
  //                     class="btn btn-light"
  //                     onClick={() => {
  //                       setCurrentProduct({ ...data });
  //                       setOpen({ ...open, modal: true, data: data });
  //                     }}
  //                   >
  //                     View Details{" "}
  //                   </button>
  //                 </div>
  //               </div>
  //             )}
  //           </div>
  //           <div className="d-flex flex-column mb-4 justify-content-between align-items-center">
  //             <h6 style={{ fontSize: "14px" }}>
  //               {data.vendor_id && data.vendor_id.name
  //                 ? data.vendor_id.name
  //                 : "--"}
  //             </h6>
  //             <h4 style={{ fontSize: "18px", fontWeight: 900 }}>
  //               <center>{data.name}</center>
  //             </h4>
  //             <h5 style={{ fontSize: "14px", fontWeight: 200 }}>
  //               Rs. {new Intl.NumberFormat("en-IN").format(data.price)}
  //             </h5>
  //           </div>
  //           {cardOverlay === index && (
  //             <div>
  //               <Button
  //                 size="md"
  //                 className={classnames(
  //                   style.cartButton,
  //                   " mb-2  justify-content-center"
  //                 )}
  //                 onClick={() => addToCart(data)}
  //               >
  //                 <span style={{ textAlign: "center" }}> Add to Cart</span>
  //               </Button>
  //               <div
  //                 className={style.similarButton1}
  //                 onClick={() => {
  //                   setSimilarShow(!similarShow);
  //                   setSimilarExpanded(!similarExpanded);
  //                   setShopIndex(index);
  //                   getSimilarProducts(data);
  //                   getCompute(index);
  //                   // displaySimilar(cols)
  //                 }}
  //               >
  //                 <span>Shop similar Items</span>
  //                 <FontAwesomeIcon
  //                   size={10}
  //                   style={{ alignItems: "center", marginLeft: "10px" }}
  //                   icon={
  //                     similarExpanded === true && index === shopIndex
  //                       ? faChevronDown
  //                       : faChevronRight
  //                   }
  //                 />
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //       );
  //       if (index === addedCol - 1) {
  //         similarShow &&
  //           cols.splice(
  //             addedCol + 1,
  //             0,
  //             <div
  //               className={style.container3}
  //               style={{ padding: "10vh 5vw 10vh 5vw;" }}
  //             >
  //               <div>
  //                 <div className="row mt-3 w-100 justify-content-between">
  //                   <div className="col text-right">
  //                     <Icon.XLg
  //                       style={{ cursor: "pointer" }}
  //                       onClick={() => {
  //                         setProductsDisplay([...moodboardProductsData]);
  //                         setSimilarShow(false);
  //                       }}
  //                     ></Icon.XLg>
  //                   </div>
  //                 </div>
  //                 {similarProductsShow && similarProductsShow.length > 0 ? (
  //                   <Carousel breakPoints={breakPoints} pagination={false}>
  //                     {similarProductsShow.map((data, index) => {
  //                       return (
  //                         <>
  //                           <div
  //                             className="d-flex flex-column justify-content-between align-items-center"
  //                             style={{
  //                               width: "240px",
  //                               height: "400px",
  //                               margin: "10px",
  //                               backgroundColor: "white",
  //                               borderRadius: "10px",
  //                             }}
  //                             onMouseEnter={(e) => {
  //                               setCardOverlay(index);
  //                             }}
  //                             onMouseLeave={(e) => {
  //                               setCardOverlay(null);
  //                             }}
  //                           >
  //                             <div className="row mt-3 w-100 justify-content-between">
  //                               <div className="col">
  //                                 <img
  //                                   style={{ width: "20%" }}
  //                                   src={
  //                                     "https://d3izcfql7lesks.cloudfront.net/Group_2549_uk0upe.png"
  //                                   }
  //                                   alt=""
  //                                 />
  //                               </div>
  //                               <div className="col text-right">
  //                                 {auth &&
  //                                 auth.user &&
  //                                 savedProductIds?.includes(data._id) ? (
  //                                   <Icon.StarFill
  //                                     style={{
  //                                       fill: "#FFC4C4",
  //                                       borderColor: "black",
  //                                     }}
  //                                     onClick={() => {
  //                                       handleFavourites(data);
  //                                     }}
  //                                   ></Icon.StarFill>
  //                                 ) : (
  //                                   <Icon.Star
  //                                     onClick={() => {
  //                                       handleFavourites(data);
  //                                     }}
  //                                   ></Icon.Star>
  //                                 )}
  //                               </div>
  //                             </div>
  //                             <div
  //                               style={{ position: "relative" }}
  //                               onMouseOver={() => handleHover(index)}
  //                               onMouseLeave={() => setOverlay(null)}
  //                             >
  //                               <img
  //                                 style={{
  //                                   width: "100%",
  //                                   "padding-right": "50px",
  //                                   "padding-left": "50px",
  //                                 }}
  //                                 src={
  //                                   data.image &&
  //                                   data.image.length > 0 &&
  //                                   data.image[0]
  //                                 }
  //                                 alt=""
  //                               />
  //                               {overlay === index && (
  //                                 <div className={style.overlay}>
  //                                   <div className="d-flex justify-content-center align-items-center">
  //                                     <button
  //                                       style={{
  //                                         background: "transparent",
  //                                         color: "black",
  //                                       }}
  //                                       type="button"
  //                                       class="btn btn-light"
  //                                       onClick={() => {
  //                                         setCurrentProduct({ ...data });
  //                                         setOpen({
  //                                           ...open,
  //                                           modal: true,
  //                                           data: data,
  //                                         });
  //                                       }}
  //                                     >
  //                                       View Details{" "}
  //                                     </button>
  //                                   </div>
  //                                 </div>
  //                               )}
  //                             </div>
  //                             <div className="d-flex flex-column mb-4 justify-content-between align-items-center">
  //                               <h6 style={{ fontSize: "14px" }}>
  //                                 {data.vendor_id && data.vendor_id.name
  //                                   ? data.vendor_id.name
  //                                   : "--"}
  //                               </h6>
  //                               <h4
  //                                 style={{ fontSize: "18px", fontWeight: 900 }}
  //                               >
  //                                 <center>{data.name}</center>
  //                               </h4>
  //                               <h5
  //                                 style={{ fontSize: "14px", fontWeight: 200 }}
  //                               >
  //                                 Rs.{" "}
  //                                 {new Intl.NumberFormat("en-IN").format(
  //                                   data.price
  //                                 )}
  //                               </h5>
  //                             </div>
  //                           </div>
  //                         </>
  //                       );
  //                     })}
  //                   </Carousel>
  //                 ) : (
  //                   <div className="d-flex justify-content-center">
  //                     <b>No similar Products</b>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           );
  //       }
  //     });

  //   return (
  //     <div className="row d-flex justify-content-center align-items-center">
  //       {cols}
  //     </div>
  //   );
  // };

  // const renderProductsBrand = (brand1) => {
  //   if (brand1.length > 0 && brand1[0].bit_img) {
  //     setBrandImage(brand1[0].bit_img);
  //     setCurrentBrand(brand1[0]);
  //   } else {
  //     setBrandImage(
  //       "https://d3izcfql7lesks.cloudfront.net/Group_3159_obwbmr.png"
  //     );
  //   }
  // };

  // const getBrandName = (brands, brandId) => {
  //     ConsoleHelper('brandsDataList', brandsDataList)
  //     const brand1 = brandsDataList.filter((br) => br.vendor_id._id === brandId)
  //     return brand1[0].vendor_id.name || "Vendor Name Nill"
  // }

  // const searchProducts = async (input) => {
  //   // setAnimationModal({
  //   //     modalOpen: true
  //   // })
  //   // setTimeout(() => {
  //   handleCloseAnimationSearch(input);
  //   // }, 5000)
  // };

  //const handleCloseAnimationSearch = (input) => {
  // setAnimationModal({
  //     modalOpen: false
  // })
  // getSearchProducts(input);
  // };
  // const getSearchProducts = (input) => {
  //   const searchProductsList = moodboardProductsData.filter((element) =>
  //     element.name.toUpperCase().includes(input.toUpperCase())
  //   );
  // setSearchInput(input);
  //   setProductsDisplay([...searchProductsList]);
  // };

  // useEffect(() => {
  //   const fetchFavouriteData = async () => {
  //     try {
  //       if (auth && auth.user) {
  //         const res = await customerApiInstance.get(
  //           `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
  //         );
  //         let savedIds = [];

  //         if (res.data.favourites[0].length !== 0) {
  //           res.data.favourites.map((dat) => {
  //             savedIds = dat.products.map((m) => {
  //               return m.product_id;
  //             });
  //           });
  //           setSavedProductIds([...savedIds]);
  //           const productsSaved = productsDisplay.filter((prod) =>
  //             savedIds.includes(prod._id)
  //           );
  //           setSavedProducts([...productsSaved]);
  //         }
  //       }
  //     } catch (err) {
  //       ConsoleHelper("errorrrr", err);
  //     }
  //   };
  //   fetchFavouriteData();
  // }, [productsDisplay]);

  // const addToFavourites = async (productData) => {
  //   ConsoleHelper("productData", productData);
  //   try {
  //     if (auth && auth.user) {
  //       const body = {
  //         user_id: auth.user._id,
  //         products: {
  //           product_id: productData._id,
  //           quantity: prodQuantity,
  //           rate: productData.price || productData.pixel_price,
  //           total_price:
  //             prodQuantity * (productData.price || productData.pixel_price),
  //         },
  //       };
  //       const res = await customerApiInstance.post(
  //         `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
  //         body
  //       );
  //       if (res.data) {
  //         setSnack(true);
  //         setSnackMessage("Product added to favourites successfully");
  //       }
  //     } else {
  //       setSnack(true);
  //       setSnackMessage("Please Login to add the Products to favourites");
  //     }
  //   } catch (e) {
  //     setSnack(true);
  //     setSnackMessage("Error in  adding  Product to favourites");
  //     ConsoleHelper(e);
  //   }
  // };

  // const removeFromFavourites = async (productData) => {
  //   try {
  //     if (auth && auth.user) {
  //       const body = {
  //         user_id: auth.user._id,
  //         products: {
  //           product_id: productData._id,
  //           quantity: prodQuantity,
  //           rate: productData.price || productData.pixel_price,
  //           total_price:
  //             prodQuantity * (productData.price || productData.pixel_price),
  //         },
  //       };
  //       const res = await customerApiInstance.post(
  //         `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
  //         body
  //       );
  //       if (res.data) {
  //         setSnack(true);
  //         setSnackMessage("Product deleted from favourites successfully");
  //       }
  //     }
  //   } catch (e) {
  //     setSnack(true);
  //     setSnackMessage("Error in  deleting  Product from favourites");
  //     ConsoleHelper(e);
  //   }
  // };

  // const handleFavourites = (product) => {
  //   ConsoleHelper("producttt", product);
  //   if (!savedProductIds.includes(product._id)) {
  //     setSavedProductIds((prev) => [...prev, product._id]);
  //     setSavedProducts([...savedProducts, product]);
  //     addToFavourites(product);
  //   } else {
  //     setSavedProductIds(
  //       savedProductIds.filter((filter) => filter !== product._id)
  //     );
  //     const removefav = savedProducts.filter((sav) => sav._id !== product._id);
  //     setSavedProducts([...removefav]);
  //     removeFromFavourites(product);
  //   }
  // };

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  // const getTags = (cat) => {
  //   let tags;
  //   let prods = productsDisplay.filter((pr) => pr.category === cat);
  //   if (prods && prods.length > 0) {
  //     tags = prods.map((prd) => {
  //       return prd.tags;
  //     });
  //   }
  //   if (tags && tags.length > 0) {
  //     tags = tags.flat();
  //     return tags[0];
  //   }
  // };

  // const handleShareProduct = () => {
  //   const url1 = copy.current.value;
  //   navigator.clipboard.writeText(url1);
  //   setCopiedUrl(url1);
  //   setSnack(true);
  //   setSnackMessage("Product link successfully copied");
  // };

  // const useStyles = makeStyles((theme) => ({
  //   modal: {
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     border: "none",
  //     boxShadow: theme.shadows[5],
  //   },
  //   paper: {
  //     backgroundColor: theme.palette.background.paper,
  //     boxShadow: theme.shadows[5],
  //     border: "none",
  //     width: 800,
  //     height: 500,
  //     padding: theme.spacing(2, 4, 3),
  //   },
  // }));

  // const classes = useStyles();
  // const renderProducts = (tempProducts, categoryValue) => {
  //   const duplicateProducts = tempProducts;
  //   const filteredCategories = duplicateProducts.filter(
  //     (product) => product.category === categoryValue
  //   );
  //   if (filteredCategories.length > 0)
  //     setProductsDisplay([...filteredCategories]);
  // };

  // const renderProductsStyles = (tempProducts, styleValue) => {
  //   const duplicateProducts = tempProducts;
  //   const filteredCategories = duplicateProducts.filter(
  //     (product) => product.style === styleValue
  //   );
  //   if (filteredCategories.length > 0)
  //     setProductsDisplay([...filteredCategories]);
  // };
  // const renderProductsColor = (tempProducts, colorValue) => {
  //   const duplicateProducts = tempProducts;
  //   const filteredCategories = duplicateProducts.filter(
  //     (product) => product.color === colorValue
  //   );
  //   if (filteredCategories.length > 0)
  //     setProductsDisplay([...filteredCategories]);
  // };
  // const renderProductsMaterial = (tempProducts, matr) => {
  //   const duplicateProducts = tempProducts;
  //   const filteredCategories = duplicateProducts.filter((product) =>
  //     product.material.includes(matr)
  //   );
  //   if (filteredCategories.length > 0)
  //     setProductsDisplay([...filteredCategories]);
  // };

  // const renderProductsRoom = (tempProducts, room) => {
  //   const duplicateProducts = tempProducts;
  //   const filteredCategories = duplicateProducts.filter((product) =>
  //     product.room_type.includes(room)
  //   );
  //   if (filteredCategories.length > 0)
  //     setProductsDisplay([...filteredCategories]);
  // };

  // useEffect(() => {
  //   if (Object.keys(filterOptions).length > 0) {
  //     ConsoleHelper("inside anim");
  //     // setAnimationModal({
  //     //     modalOpen: true
  //     // })
  //     // setTimeout(() => {
  //     handleCloseAnimation();
  //     // }, 5000)
  //   }
  // }, [filterOptions]);

  // const handleCloseAnimation = () => {
  //   // ConsoleHelper('handleClose')
  //   // setAnimationModal({
  //   //     modalOpen: false
  //   // });
  //   getFilterData();
  // };

  // const getFilterData = () => {
  //   // ConsoleHelper("inside fill");
  //   let filterFinal = [];
  //   let filteredCategories = [];
  //   let filterColors = [];
  //   let filterBrands = [];
  //   let filterMaterial = [];
  //   let duplicateProducts = moodboardProductsData;
  //   if (Object.keys(filterOptions).length) {
  //     if (filterOptions.hasOwnProperty("category")) {
  //       filteredCategories = duplicateProducts.filter((product) =>
  //         filterOptions.category.includes(product.category)
  //       );
  //       filterFinal.push([...filteredCategories]);
  //     }
  //     if (filterOptions.hasOwnProperty("color")) {
  //       filterColors = duplicateProducts.filter((product) =>
  //         filterOptions.color.includes(product.color)
  //       );
  //       filterFinal.push([...filterColors]);
  //     }
  //     if (filterOptions.hasOwnProperty("brand")) {
  //       filterBrands = duplicateProducts.filter((product) =>
  //         product.vendor_id && product.vendor_id.name
  //           ? product.vendor_id.name === filterOptions.brand
  //           : ""
  //       );
  //       filterFinal.push([...filterBrands]);
  //     }
  //     if (
  //       (filterOptions.hasOwnProperty("brand") && filterOptions.brand === "") ||
  //       !filterOptions.hasOwnProperty("brand")
  //     ) {
  //       setBrandClicked(false);
  //     }
  //     if (filterOptions.hasOwnProperty("material")) {
  //       for (let i = 0; i < duplicateProducts.length; i++) {
  //         for (let j = 0; j < duplicateProducts[i].material.length; j++) {
  //           if (
  //             filterOptions.material.includes(duplicateProducts[i].material[j])
  //           ) {
  //             filterMaterial.push(duplicateProducts[i]);
  //           }
  //         }
  //       }
  //       filterFinal.push([...filterMaterial]);
  //     }
  //     filterFinal = filterFinal.flat();
  //     filterFinal = filterFinal.filter(
  //       (ele, ind) =>
  //         ind === filterFinal.findIndex((elem) => elem._id === ele._id)
  //     );
  //     // setProductsDisplay([...filterFinal]);
  //   }
  // };

  // const getCategoriesCount = () => {
  //   let count = 0;
  //   {
  //     Object.keys(categoriesnew).map(
  //       (item, i) => (count = count + parseInt(categoriesnew[item]))
  //     );
  //   }
  //   return count;
  // };

  useEffect(() => {
    if (likesChecked === true) {
      const getProject = async () => {
        let likedProducts = [];
        let likedProdIds = [];
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
        );
        let project = { ...res.data };
        if (project && project.hasOwnProperty('product_reactions')) {
          likedProdIds = project.product_reactions.liked;
        }
        likedProducts = moodboardProductsData.filter(prd => likedProdIds.includes(prd._id));
        // setProductsDisplay([...likedProducts]);
      };
      getProject();
    } else {
      // setProductsDisplay([...moodboardProductsData]);
    }
  }, [likesChecked]);

  // const checkfilterKeys = (filters) => {
  //   let valueFlag = Object.keys(filters).some((k) => filters[k].length > 0);
  //   return valueFlag;
  // };

  return (
    <div style={{ backgroundColor: 'white' }}>
      {dimension[0] > 600 && (
        <div
          className={classnames(style.bannerContainer)}
          style={{
            backgroundImage:
              moodboardUrl != ''
                ? `url(${moodboardUrl})`
                : 'url(' +
                  'https://pixel-mortar.s3.ap-south-1.amazonaws.com/Mask+Group+54.png' +
                  ')'
          }}>
          <div className={classnames(style.bannerContainerMask)}>
            <div className="row w-100">
              <div className="col-lg-6 d-flex align-items-end col-md-6">
                <div style={{ paddingLeft: '10rem' }}>
                  <h1 style={{ color: 'white' }}>{userData.name}</h1>
                  <h1 style={{ color: 'white' }}>{userData.roomType}</h1>
                  <button className={classnames(style.bannerButton)}>Shop All products</button>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <img
                  style={{ width: '100%' }}
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4892.png"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={classnames(style.mainContainer, 'mt-4 d-flex justify-content-end')}
        style={{ width: dimension[0] < 500 && '100%' }}>
        <ProductList
          roomtype={userData.roomType}
          pageType="SHOP"
          setMoodboardUrl={setMoodboardUrl}
        />
      </div>
    </div>
  );
};

Shop.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(Shop));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    // ConsoleHelper(screenSize);
  }, [screenSize]);

  return screenSize;
}
