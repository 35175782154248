import React from 'react';
import style from './Productcard.module.css';
import ARLogo from '../../assets/ARLogo.png';
import PnMButton from '../../components/button/PnMButton';
import classnames from 'classnames';
import Heart from 'react-heart';

function ProductCard({
  data,
  tag,
  heart,
  heartIsActive,
  arIcon,
  vendorName,
  productImage,
  productName,
  price,
  pnmPrice,
  onClickCart,
  onClickBuyNow,
  onClickHeart,
  onClicAr,
  viewProduct,
  sx
}) {
  const roundOffPrice = price => {
    return price > 0 ? Math.round(price) : '00';
  };

  return (
    <div className={style.productCard} style={sx}>
      <div className={style.topContainer}>
        {tag && <div className={style.productCardTag}>{tag}</div>}
        {heart && (
          <Heart
            className={style.productCardHeart}
            isActive={heartIsActive}
            onClick={onClickHeart}
            animationScale={1.2}
            animationTrigger="both"
            animationDuration={0.2}
            style={{
              fill: heartIsActive ? 'rgb(255, 196, 196)' : '#fff'
            }}
          />
        )}
        {arIcon && (
          <div className={style.arContainer} onClick={onClicAr}>
            <img src={ARLogo} style={{ marginRight: '0.5rem' }} />
            View in AR
          </div>
        )}

        <img src={productImage} className={style.productImage} onClick={viewProduct} />
      </div>

      <div className={style.bottomContainer}>
        <div className={style.vendorName}>{vendorName}</div>
        <div className={style.productName} onClick={viewProduct}>
          {productName}
        </div>
        <div className={style.priceCont}>
          <div className={style.pnmPrice}>
            &#x20B9;{new Intl.NumberFormat('en-IN').format(roundOffPrice(pnmPrice))}
          </div>
          <div className={style.price}>
            <s>&#x20B9;{new Intl.NumberFormat('en-IN').format(roundOffPrice(price))}</s>
          </div>
        </div>
        <div className={classnames('row', style.btnContainer)}>
          <div className="col-6 pr-1 pl-0">
            <PnMButton
              type={'outlined'}
              name={'Add to Cart'}
              onClick={onClickCart}
              size={'sm'}
              sx={{ width: '100%', fontSize: '13px', padding: '10px 10px' }}
            />
          </div>
          <div className="col-6 pr-0 pl-1">
            <PnMButton
              type={'filled'}
              name={'Buy Now'}
              onClick={onClickBuyNow}
              size={'sm'}
              sx={{ width: '100%', fontSize: '13px', padding: '10px 10px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
