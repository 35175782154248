import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../../components/Header/Header';
import style from './Account.module.css';
import classnames from 'classnames';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { getCustomerAccount, updateCustomerAccount } from '../../store/actions/customerAction';
import ConsoleHelper from '../../utils/ConsoleHelper';

const Account = ({ auth }) => {
  const [activeTab, setActiveTab] = useState('Personal Info');
  const [mobileTab, setMobileTab] = useState('');

  const tabs = [
    'Personal Info',
    'P&M Credits',
    'My Rewards',
    'Password',
    'Addresses',
    'Email Preferences'
  ];
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [emailNotification, setEmailNotification] = useState('');
  const [modal, setModal] = useState(false);
  const dimension = useMediaQuery();
  const [inputs, setInputs] = useState({});
  const [addressError, setAddressError] = useState({});
  const [addressinputs, setAddressInputs] = useState({});
  const [addressdata, setaddressdata] = useState({});
  const [addressMode, setAddressMode] = useState({});
  const [states, setStates] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [totalCredit, setTotalCredit] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector(state => state.customer.customerAccount);
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const countryApi = {
          key: 'X-CSCAPI-KEY',
          baseurl: 'TXFBUUNjaDIxV3QwcHFQd1B2YzJpNkZYWXJtUEJtWGpsUnhjdXZpaQ=='
        };
        let headers = new Headers();
        headers.append(countryApi.key, countryApi.baseurl);
        var requestOptions = {
          method: 'GET',
          headers: headers,
          redirect: 'follow'
        };

        fetch('https://api.countrystatecity.in/v1/countries/IN/states', requestOptions)
          .then(response => response.json())
          .then(result => {
            for (let i = 0; i < result.length; i++) {
              states.push({ name: result[i].name, stateCode: result[i].iso2 });
            }
            setStates([...states]);
          })
          .catch(error => ConsoleHelper('error', error));
      } catch (err) {}
    };
    fetchStates();
  }, []);

  useEffect(() => {
    if (user && !Object.keys(user).length) {
      dispatch(getCustomerAccount(auth.user._id));
    }
  }, []);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      setName(user.name);
      setEmail(user.email);
      setEmailNotification(user.allowEmailNotification);
    }
  }, [activeTab, user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/address/${auth.user._id}`
        );
        setaddressdata(res.data);
      } catch (err) {
        ConsoleHelper(err);
      }
    };
    fetchData();

    const fetchTransactions = async () => {
      try {
        const response = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth.user._id}/credits/redemptions`
        );
        setTransactions(response?.data?.data?.data);
      } catch (err) {
        ConsoleHelper(err);
      }
    };
    fetchTransactions();

    const fetchTotalCredit = async () => {
      try {
        const response = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/${auth.user._id}/credits`
        );
        if (response?.data?.data) setTotalCredit(response?.data?.data?.points);
      } catch (err) {
        ConsoleHelper(err);
      }
    };
    fetchTotalCredit();
  }, []);

  const handleSubmit = async () => {
    try {
      if (inputs.confirmpassword === inputs.password) {
        const data = {
          _id: auth.user._id,
          oldpassword: inputs.oldpassword,
          password: inputs.password
        };
        await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/password`,
          data
        );
        setSnack(true);
        setSnackMessage('Password successfully saved');
      } else {
        setSnack(true);
        setSnackMessage("Password doesn't match!");
      }
    } catch (err) {}
  };

  const handleChange = e => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };
  const handleChangeAddress = e => {
    setAddressInputs({
      ...addressinputs,
      [e.target.name]: e.target.value
    });
  };

  const handleUpdateUserDetails = async () => {
    const data = {
      name,
      email
    };
    await customerApiInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/user/updateUserDetails/${auth.user._id}`,
      data
    );
    dispatch(updateCustomerAccount(data));
    setSnack(true);
    setSnackMessage('Personal Info successfully saved');
  };

  const handleUpdateUserNotification = async () => {
    const data = {
      allowEmailNotification: emailNotification
    };
    await customerApiInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/user/updateUserDetails/${auth.user._id}`,
      data
    );
    dispatch(updateCustomerAccount(data));
    setSnack(true);
    setSnackMessage('Email Notification Preference successfully saved');
  };

  const handleEditAddress = address => {
    setAddressInputs(address.address);
    addressinputs.id = address._id;
    setAddressInputs(prevState => ({
      ...prevState,
      ['id']: address._id
    }));
    setAddressMode('EDIT');
    setModal(true);
  };

  const handleCreateAddress = () => {
    setAddressInputs({});
    setAddressMode('CREATE');
    setModal(true);
  };

  const handleUpdateAddress = async () => {
    try {
      const data = {
        _id: addressinputs.id,
        address: addressinputs
      };
      const res = await customerApiInstance.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/address`,
        data
      );
      setSnack(true);
      setSnackMessage('Address successfully updated');
      setModal(false);
      const index = addressdata.findIndex(data => data._id === res.data.user1._id);
      addressdata[index] = res.data.user1;
      setaddressdata([...addressdata]);
    } catch (err) {
      setSnack(true);
      setSnackMessage('Something went wrong');
    }
  };

  const handleSubmitAddress = async () => {
    let addressErrorData = {};

    if (Object.keys(addressinputs).length !== 0) {
      if (!addressinputs.firstname) {
        addressErrorData.firstname = 'first name required';
      }
      if (!addressinputs.lastname) {
        addressErrorData.lastname = 'last name required';
      }
      if (!addressinputs.phone) {
        addressErrorData.phone = 'phone is required';
      } else {
        if (addressinputs.phone.length !== 10)
          addressErrorData.phone = 'phone should have 10 digit';
      }
      if (!addressinputs.postcode) {
        addressErrorData.postcode = 'postcode is required';
      } else {
        if (addressinputs.postcode.length !== 6)
          addressErrorData.postcode = 'postcode should have 6 digit';
      }
      if (!addressinputs.line1) {
        addressErrorData.line1 = 'address line1 is required';
      }
      if (!addressinputs.line2) {
        addressErrorData.line2 = 'address line2 is required';
      }
      if (!addressinputs.city) {
        addressErrorData.city = 'city is required';
      }
      if (!addressinputs.state) {
        addressErrorData.state = 'state is required';
      }
      setAddressError(addressErrorData);
      if (Object.keys(addressErrorData).length === 0) {
        try {
          const data = {
            user_id: auth.user._id,
            address: addressinputs
          };
          const res = await customerApiInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/address`,
            data
          );
          if (res.data) {
            setSnack(true);
            setSnackMessage('Address successfully saved');
            setModal(false);
            setaddressdata([...addressdata, res.data.data]);
          }
        } catch (err) {
          setSnack(true);
          setSnackMessage('Something went wrongg');
        }
      }
    } else {
      setSnack(true);
      setSnackMessage('Enter all Fieldss');
    }
  };

  useEffect(() => {
    setModal(false);
  }, []);

  const handledeleteaddress = async id => {
    try {
      await customerApiInstance.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/address/${id}`
      );
      setSnack(true);
      setSnackMessage('Address successfully deleted');
      const newAddress = addressdata.filter(filter => filter._id !== id);
      setaddressdata([...newAddress]);
    } catch (err) {
      setSnack(true);
      setSnackMessage('Something went wrong');
    }
  };
  const getNoAddressComponent = () => {
    return (
      <div style={{}}>
        <p
          style={{
            marginBottom: '1em',
            fontSize: '12px',
            fontWeight: 'bold',
            textAlign: 'center'
          }}>
          You currently do not have any address added to this account
        </p>
        <div className={style.divider} style={{ marginBottom: '1em' }}></div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            style={{ align: 'center' }}
            className={style.updateBtn}
            onClick={handleCreateAddress}>
            + Add New Address
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className={style.pageContainer}>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {dimension[0] > 500 && (
        <div className={style.sidebar} style={{ zIndex: '2' }}>
          <div className={style.sidebar_content}>
            {tabs.map(tab => (
              <h5 onClick={() => setActiveTab(tab)} className={activeTab === tab && style.active}>
                {tab}
              </h5>
            ))}
          </div>
        </div>
      )}
      {dimension[0] < 500 && !mobileTab && (
        <div className={style.mobileBackground} style={{ paddingTop: '75px' }}>
          <div className="container">
            <div className="card" style={{ marginTop: '1em' }}>
              <div className={classnames('card-header', style.cardHeader)}>My Accounts</div>
              <div className="card-body">
                {tabs.map(data => (
                  <div
                    className="d-flex justify-content-between"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setMobileTab(data)}>
                    <p>{data}</p>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {dimension[0] < 500 && mobileTab && (
        <>
          <div className={style.mobileBackground} style={{ paddingTop: '75px' }}>
            {mobileTab && (
              <div
                style={{
                  padding: '15px',
                  marginTop: '2.5em',
                  cursor: 'pointer',
                  marginBottom: '2em'
                }}
                onClick={() => setMobileTab('')}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '1em' }} />
                <span style={{ color: '#000000', fontWeight: '600' }}>Manage {`${mobileTab}`}</span>
              </div>
            )}
            {mobileTab === 'Personal Info' ? (
              <div className={classnames('container')}>
                <div className={classnames('card', style.cardDiv)} style={{ width: '100%' }}>
                  <div className={classnames('card-header', style.cardHeader)}>
                    Edit Personal Information
                  </div>
                  <div className="card-body p-5">
                    <label htmlFor="exampleFormControlInput1" className={style.label}>
                      Email
                    </label>
                    <br></br>
                    <input
                      type="email"
                      placeholder="name@example.com"
                      value={email}
                      className={style.card_input}
                      onChange={e => setEmail(e.target.value)}
                    />

                    <label
                      htmlFor="exampleFormControlInput1"
                      className={classnames(style.label, 'mt-5')}>
                      Name
                    </label>
                    <br></br>
                    <input
                      type="email"
                      placeholder="Neil Smith"
                      value={name}
                      className={style.card_input}
                      onChange={e => setName(e.target.value)}
                    />
                    <button className={style.updateBtn} onClick={handleUpdateUserDetails}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            ) : mobileTab === 'P&M Credits' ? (
              <div className={style.containerMob} style={{ display: 'grid' }}>
                <div
                  style={{
                    background: 'transparant',
                    width: '95%',
                    height: 'auto',
                    margin: 'auto'
                  }}>
                  <div
                    style={{
                      fontSize: '26px',
                      fontWeight: '600',
                      marginBottom: '15px'
                    }}>
                    P&M Credits{' '}
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    With Pixel and Mortar Credits you can purchase any product or packages we have.{' '}
                    <br /> Remaining Balance
                  </div>
                  <div style={{ fontSize: '26px', fontWeight: '600' }}>{totalCredit}</div>
                </div>
                <div className={classnames('card', style.cardDiv)} style={{ width: '100%' }}>
                  <div className={classnames('card-header', style.cardHeader)}>Transactions</div>
                  <div className={classnames('card-header', style.cardHeader1)}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                      <span>Earned</span>
                      <span>Amount</span>
                      <span>Status</span>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {transactions.length > 0 &&
                      transactions.map(transaction => (
                        <>
                          <div
                            className="row justify-content-between text-center m-3"
                            style={{ fontSize: '14px' }}
                            key={transaction.id}>
                            <div className="col-4 d-flex flex-column text-left p-0">
                              <span style={{ fontWeight: 700, fontSize: '14px' }}>
                                {transaction.transaction_type == 'credit'
                                  ? 'Added P&M Credit'
                                  : transaction.transaction_type == 'debit'
                                  ? 'Used P&M Credit'
                                  : ''}
                              </span>
                              <span>
                                {moment(transaction.created * 1000).format('Do MMMM, YYYY')}
                              </span>
                              <span>{transaction.description}</span>
                            </div>
                            <div className="col" style={{ fontWeight: 700 }}>
                              Rs. {transaction.points}
                            </div>
                            <div className="col d-flex flex-column text-right p-0">
                              <span style={{ color: '#0DA500', fontWeight: 800 }}></span>
                              <span></span>
                            </div>
                          </div>
                          <div className={style.divider}></div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            ) : mobileTab === 'Password' ? (
              <div className="container">
                <div className={classnames('card', style.cardDiv)} style={{ width: '100%' }}>
                  <div className={classnames('card-header', style.cardHeader)}>Update Password</div>
                  <div className="card-body p-5">
                    <label htmlFor="exampleFormControlInput1" className={style.label}>
                      Old Password
                    </label>
                    <br></br>
                    <input
                      type="password"
                      className={style.card_input}
                      name="oldpassword"
                      value={inputs.oldpassword || ''}
                      onChange={handleChange}
                    />
                    <br></br>
                    <br></br>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={style.label}
                      style={{ position: 'relative', top: '25px' }}>
                      New Password
                    </label>
                    <br></br>
                    <input
                      type="password"
                      className={style.card_input}
                      style={{ position: 'relative', top: '25px' }}
                      name="password"
                      value={inputs.password}
                      onChange={handleChange}
                    />

                    <label
                      htmlFor="exampleFormControlInput1"
                      className={classnames(style.label, 'mt-5')}>
                      Confirm Password
                    </label>
                    <br></br>
                    <input
                      type="password"
                      className={style.card_input}
                      name="confirmpassword"
                      value={inputs.confirmpassword}
                      onChange={handleChange}
                    />
                    <div className={style.button}>
                      <button
                        className={classnames(style.paymentBtn, 'mt-4')}
                        onClick={handleSubmit}>
                        Update Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : mobileTab === 'Addresses' ? (
              <div className="container">
                <div className={classnames('card', style.cardDiv)} style={{ width: '100%' }}>
                  <div className={classnames('card-header', style.cardHeader)}>Saved Addresses</div>
                  <div className="card-body p-5 justify-content-center align-items-center">
                    {(!addressdata.length && getNoAddressComponent()) ||
                      addressdata.map((address, i) => (
                        <div style={{ marginBottom: '1em' }}>
                          <h6>Address {i + 1}</h6>
                          <div className="row">
                            <div className="col-12">
                              <p style={{ fontSize: '12px' }}>
                                {address.address?.line1},{address.address?.line2},
                                {address.address?.city}- {address.address?.postcode},
                                {address.address?.state}.
                              </p>
                            </div>
                            <div className="col-12">
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  fontSize: '12px',
                                  cursor: 'pointer',
                                  fontWeight: 800
                                }}
                                onClick={() => handleEditAddress(address)}>
                                Edit
                              </span>
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  fontSize: '12px',
                                  marginLeft: '30px',
                                  cursor: 'pointer',
                                  fontWeight: 800
                                }}
                                onClick={() => handledeleteaddress(address._id)}>
                                Delete
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {(!addressdata.length && <></>) || (
                    <div
                      style={{ cursor: 'pointer' }}
                      className={style.addresses_new}
                      onClick={handleCreateAddress}>
                      + Add New Address
                    </div>
                  )}
                </div>
              </div>
            ) : mobileTab === 'Email Preferences' ? (
              <div className="container">
                <div className={classnames('card', style.cardDiv)} style={{ width: '100%' }}>
                  <div className={classnames('card-header', style.cardHeader)}>Email Settings</div>
                  <div className="card-body p-5 d-flex flex-column justify-content-center align-items-center">
                    <h5>Notifications</h5>
                    <p>Allow email notifications</p>
                    <div className="row justify-content-center">
                      <div className="ml-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={emailNotification === true || false}
                          onClick={() => setEmailNotification(true)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                          style={{ marginLeft: '0px' }}>
                          Yes
                        </label>
                      </div>
                      <div className="ml-5">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={emailNotification === false || false}
                          onClick={() => setEmailNotification(false)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                          style={{ marginLeft: '0px' }}>
                          No
                        </label>
                      </div>
                    </div>
                    <button
                      className={classnames(style.paymentBtn, 'mt-4')}
                      onClick={handleUpdateUserNotification}>
                      Update Email Settings
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      {dimension[0] > 500 && (
        <>
          {activeTab === 'Personal Info' ? (
            <div className={style.container}>
              <div className={classnames('card', style.cardDiv)}>
                <div className={classnames('card-header', style.cardHeader)}>
                  Edit Personal Information
                </div>
                <div className="card-body p-5">
                  <label htmlFor="exampleFormControlInput1" className={style.label}>
                    Email
                  </label>
                  <br></br>
                  <input
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    className={style.card_input}
                    onChange={e => setEmail(e.target.value)}
                  />

                  <label
                    htmlFor="exampleFormControlInput1"
                    className={classnames(style.label, 'mt-5')}>
                    Name
                  </label>
                  <br></br>
                  <input
                    type="email"
                    placeholder="Neil Smith"
                    value={name}
                    className={style.card_input}
                    onChange={e => setName(e.target.value)}
                  />
                  <button className={style.updateBtn} onClick={handleUpdateUserDetails}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          ) : activeTab === 'Payment Details' ? (
            <div className={style.container}>
              <div className={classnames('card', style.cardDiv)}>
                <div className={classnames('card-header', style.cardHeader)}>Payment Method</div>
                <div className="card-body p-5 d-flex flex-column justify-content-center align-items-center">
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}>
                    You currently do not have any card with this account
                  </span>
                  <br></br>
                  <button className={style.paymentBtn}>Add New Payment Method</button>
                </div>
              </div>
            </div>
          ) : activeTab === 'My Rewards' ? (
            <div className={style.container}>
              <div className={classnames('card', style.cardDiv)}>
                <div className={classnames('card-header', style.cardHeader)}>My Rewards</div>
                <div className="card-body p-5 d-flex flex-column justify-content-center align-items-center">
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}>
                    You currently do not have no rewards with this account
                  </span>
                </div>
              </div>
            </div>
          ) : activeTab === 'Addresses' ? (
            <div className={style.container}>
              <div className={classnames('card', style.cardDiv)}>
                <div className={classnames('card-header', style.cardHeader)}>Saved Addresses</div>
                <div className="card-body p-5 justify-content-center align-items-center">
                  {(!addressdata.length && getNoAddressComponent()) ||
                    addressdata.map((address, i) => (
                      <div style={{ marginBottom: '1em' }}>
                        <h6>Address {i + 1}</h6>
                        <div className="row">
                          <div className="col-12">
                            <p style={{ fontSize: '12px' }}>
                              {address.address?.line1}, {address.address?.line2},
                              {address.address?.city} -{address.address?.state},
                              {address.address?.postcode}.
                            </p>
                          </div>
                          <div className="col-12">
                            <span
                              style={{
                                textDecoration: 'underline',
                                fontSize: '12px',
                                cursor: 'pointer',
                                fontWeight: 800
                              }}
                              onClick={() => handleEditAddress(address)}>
                              Edit
                            </span>
                            <span
                              style={{
                                textDecoration: 'underline',
                                fontSize: '12px',
                                marginLeft: '30px',
                                cursor: 'pointer',
                                fontWeight: 800
                              }}
                              onClick={() => handledeleteaddress(address._id)}>
                              Delete
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {(!addressdata.length && <></>) || (
                  <div
                    style={{ cursor: 'pointer' }}
                    className={style.addresses_new}
                    onClick={handleCreateAddress}>
                    + Add New Address
                  </div>
                )}
              </div>
            </div>
          ) : activeTab === 'Password' ? (
            <div className={style.container}>
              <div className={classnames('card', style.cardDiv)}>
                <div className={classnames('card-header', style.cardHeader)}>Update Password</div>
                <div className="card-body p-5">
                  <label htmlFor="exampleFormControlInput1" className={style.label}>
                    Old Password
                  </label>
                  <br></br>
                  <input
                    type="password"
                    className={style.card_input}
                    name="oldpassword"
                    value={inputs.oldpassword || ''}
                    onChange={handleChange}
                  />
                  <br></br>
                  <br></br>
                  <label htmlFor="exampleFormControlInput1" className={style.label}>
                    New Password
                  </label>
                  <br></br>
                  <input
                    type="password"
                    className={style.card_input}
                    name="password"
                    value={inputs.password}
                    onChange={handleChange}
                  />

                  <label
                    htmlFor="exampleFormControlInput1"
                    className={classnames(style.label, 'mt-5')}>
                    Confirm Password
                  </label>
                  <br></br>
                  <input
                    type="password"
                    className={style.card_input}
                    name="confirmpassword"
                    value={inputs.confirmpassword}
                    onChange={handleChange}
                  />
                  <button className={classnames(style.paymentBtn, 'mt-4')} onClick={handleSubmit}>
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          ) : activeTab === 'Email Preferences' ? (
            <div className={style.container}>
              <div className={classnames('card', style.cardDiv)}>
                <div className={classnames('card-header', style.cardHeader)}>Email Settings</div>
                <div className="card-body p-5 d-flex flex-column justify-content-center align-items-center">
                  <h5>Notifications</h5>
                  <p>Allow email notifications</p>
                  <div className="row justify-content-center">
                    <div className="ml-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={emailNotification === true || false}
                        onClick={() => setEmailNotification(true)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                        style={{ marginLeft: '0px' }}>
                        Yes
                      </label>
                    </div>
                    <div className="ml-5">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={emailNotification === false || false}
                        onClick={() => setEmailNotification(false)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                        style={{ marginLeft: '0px' }}>
                        No
                      </label>
                    </div>
                  </div>
                  <button
                    className={classnames(style.paymentBtn, 'mt-4')}
                    onClick={handleUpdateUserNotification}>
                    Update Email Settings
                  </button>
                </div>
              </div>
            </div>
          ) : activeTab === 'P&M Credits' ? (
            <>
              <div className={style.container} style={{ display: 'grid' }}>
                <div
                  style={{
                    background: 'transparant',
                    width: '700px',
                    height: 'auto',
                    margin: 'auto',
                    marginTop: '135px'
                  }}>
                  <div
                    style={{
                      fontSize: '26px',
                      fontWeight: '600',
                      marginBottom: '15px'
                    }}>
                    P&M Credits{' '}
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    With Pixel and Mortar Credits you can purchase any product or packages we have.{' '}
                    <br /> Remaining Balance
                  </div>
                  <div style={{ fontSize: '26px', fontWeight: '600' }}>{totalCredit}</div>
                </div>
                <div className={classnames('card', style.cardDiv)} style={{ width: '600px' }}>
                  <div className={classnames('card-header', style.cardHeader)}>Transactions</div>
                  <div className={classnames('card-header', style.cardHeader1)}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '1vw'
                      }}>
                      <span>Earned</span>
                      <span>Amount</span>
                      <span>Status</span>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {transactions.length > 0 &&
                      transactions.map(transaction => (
                        <>
                          <div
                            className="row justify-content-between text-center m-3"
                            style={{ fontSize: '14px' }}
                            key={transaction.id}>
                            <div className="col-4 d-flex flex-column text-left p-0">
                              <span style={{ fontWeight: 700, fontSize: '14px' }}>
                                {transaction.transaction_type == 'credit'
                                  ? 'Added P&M Credit'
                                  : transaction.transaction_type == 'debit'
                                  ? 'Used P&M Credit'
                                  : ''}
                              </span>
                              <span>
                                {moment(transaction.created * 1000).format('Do MMMM, YYYY')}
                              </span>
                              <span>{transaction.description}</span>
                            </div>
                            <div className="col" style={{ fontWeight: 700 }}>
                              Rs. {transaction.points}
                            </div>
                            <div className="col d-flex flex-column text-right p-0">
                              <span style={{ color: '#0DA500', fontWeight: 800 }}></span>
                              <span></span>
                            </div>
                          </div>
                          <div className={style.divider}></div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </>
      )}

      {dimension[0] > 500 && (
        <Modal show={modal} centered>
          <div className={style.modalContainer}>
            <h5>Add an Address</h5>
            <img
              style={{
                position: 'absolute',
                width: '3%',
                right: dimension[0] > 500 ? '5%' : '10%',
                top: dimension[0] < 500 ? '3%' : '0%',
                cursor: 'pointer'
              }}
              onClick={() => setModal(false)}
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
              alt=""
            />

            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500, fontSize: '14px' }}>
                  First Name*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={addressinputs.firstname}
                  onChange={handleChangeAddress}
                  name="firstname"></input>
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.firstname}
                </p>
              </div>
              <div className="col-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500, fontSize: '14px' }}>
                  Last Name*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={addressinputs.lastname}
                  onChange={handleChangeAddress}
                  name="lastname"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.lastname}
                </p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500, fontSize: '14px' }}>
                  Address Line 1*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={addressinputs.line1}
                  onChange={handleChangeAddress}
                  name="line1"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.line1}
                </p>
              </div>
              <div className="col-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500, fontSize: '14px' }}>
                  Address Line 2*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={addressinputs.line2}
                  onChange={handleChangeAddress}
                  name="line2"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.line2}
                </p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500, fontSize: '14px' }}>
                  Postcode*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={addressinputs.postcode}
                  onChange={handleChangeAddress}
                  name="postcode"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.postcode}
                </p>
              </div>
              <div className="col-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500, fontSize: '14px' }}>
                  City/District*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={addressinputs.city}
                  onChange={handleChangeAddress}
                  name="city"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.city}
                </p>
              </div>
            </div>
            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}>
              <div className="col-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500, fontSize: '14px' }}>
                  State*
                </label>
                <br></br>
                <select
                  placeholder="Select State"
                  className={style.modal_input}
                  value={addressinputs.state}
                  onChange={handleChangeAddress}
                  name="state">
                  <option style={{ borderRadius: '10px' }}>Select state</option>
                  {states.length > 0 &&
                    states.map(state => <option value={state.name}>{state.name}</option>)}
                </select>
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.state}
                </p>
              </div>
              <div className="col-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                  style={{ fontWeight: 500, fontSize: '14px' }}>
                  Phone*
                </label>
                <br></br>
                <input
                  type="text"
                  className={style.modal_input}
                  value={addressinputs.phone}
                  onChange={handleChangeAddress}
                  name="phone"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.phone}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center my-5">
              <button
                className={style.paymentBtn}
                style={{ paddingLeft: '30px', paddingRight: '30px' }}
                onClick={addressMode === 'EDIT' ? handleUpdateAddress : handleSubmitAddress}>
                {addressMode === 'EDIT' ? 'Update Address' : 'Add Address'}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {dimension[0] < 500 && (
        <Modal show={modal} centered>
          <div className={style.modalContainer}>
            <h5>Add an Address</h5>
            <img
              style={{
                position: 'absolute',
                width: '5%',
                right: dimension[0] > 500 ? '5%' : '10%',
                top: dimension[0] < 500 ? '-3%' : '0%',
                cursor: 'pointer'
              }}
              onClick={() => setModal(false)}
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/close.png'}
              alt=""
            />

            <div
              className={classnames(
                style.modal_input_container,
                'row d-flex align-items-center justify-content-center'
              )}
              style={{ fontSize: '2.8vw' }}>
              <div className="col-12" style={{ marginBottom: '.5em' }}>
                <input
                  type="text"
                  placeholder="First Name*"
                  style={{ width: '100%', fontSize: '2.8vw' }}
                  className={style.modal_input}
                  value={addressinputs.firstname}
                  onChange={handleChangeAddress}
                  name="firstname"></input>
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.firstname}
                </p>
              </div>
              <div className="col-12" style={{ marginBottom: '.5em' }}>
                <input
                  type="text"
                  placeholder="Last Name*"
                  style={{ width: '100%', fontSize: '2.8vw' }}
                  className={style.modal_input}
                  value={addressinputs.lastname}
                  onChange={handleChangeAddress}
                  name="lastname"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.lastname}
                </p>
              </div>

              <div className="col-12" style={{ marginBottom: '.5em' }}>
                <input
                  type="text"
                  placeholder="Address Line 1"
                  style={{ width: '100%', fontSize: '2.8vw' }}
                  className={style.modal_input}
                  value={addressinputs.line1}
                  onChange={handleChangeAddress}
                  name="line1"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.line1}
                </p>
              </div>
              <div className="col-12" style={{ marginBottom: '.5em' }}>
                <input
                  type="text"
                  placeholder="Address Line 2"
                  style={{ width: '100%', fontSize: '2.8vw' }}
                  className={style.modal_input}
                  value={addressinputs.line2}
                  onChange={handleChangeAddress}
                  name="line2"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.line2}
                </p>
              </div>

              <div className="col-12" style={{ marginBottom: '.5em' }}>
                <input
                  type="text"
                  placeholder="Postcode"
                  style={{ width: '100%', fontSize: '2.8vw' }}
                  className={style.modal_input}
                  value={addressinputs.postcode}
                  onChange={handleChangeAddress}
                  name="postcode"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.postcode}
                </p>
              </div>
              <div className="col-12" style={{ marginBottom: '.5em' }}>
                <input
                  type="text"
                  placeholder="City / District"
                  style={{ width: '100%', fontSize: '2.8vw' }}
                  className={style.modal_input}
                  value={addressinputs.city}
                  onChange={handleChangeAddress}
                  name="city"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.city}
                </p>
              </div>

              <div className="col-12" style={{ marginBottom: '.5em' }}>
                <input
                  type="text"
                  placeholder="State"
                  style={{ width: '100%', fontSize: '2.8vw' }}
                  className={style.modal_input}
                  value={addressinputs.state}
                  onChange={handleChangeAddress}
                  name="state"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.state}
                </p>
              </div>
              <div className="col-12" style={{ marginBottom: '.5em' }}>
                <input
                  type="text"
                  placeholder="Phone*"
                  style={{ width: '100%', fontSize: '2.8vw' }}
                  className={style.modal_input}
                  value={addressinputs.phone}
                  onChange={handleChangeAddress}
                  name="phone"
                />
                <p className="text-danger" style={{ fontSize: 10 }}>
                  {addressError.phone}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center my-5">
              <button
                className={style.paymentBtn}
                style={{ paddingLeft: '30px', paddingRight: '30px' }}
                onClick={addressMode === 'EDIT' ? handleUpdateAddress : handleSubmitAddress}>
                {addressMode === 'EDIT' ? 'Update Address' : 'Add Address'}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

Account.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(withRouter(Account));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
