import React, { useEffect, useState } from 'react';
import { Chip, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import style from './headTags.module.css'
export const HeadTags = ({
  searchparams,
  getProductDetails,
  headkeys,
  setHeadkeys,
  filterApplied,
  setFilterApplied
}) => {
  const [keys, setKeys] = useState([]);
  const [ven_id, setVenID] = useState('');
  const [values, setValues] = useState([]);
  const [filter, setFilter] = useState(
    filterApplied
  );
  useEffect(() => {
    if (filterApplied) setFilter(filterApplied);
    console.log(filterApplied);
  }, [filterApplied]);
  // console.log(filter)
  const headTagValue = () => {
    var temp_keys = [];
    var temp_values = [];
    for (const [key, value] of searchparams.entries()) {
      var del = ['vendor_id', 'room_type', 'category', 'page', 'sortBy', 'price_from', 'price_to'];
      if (!del.includes(key)) {
        temp_keys.push(key);
        temp_values.push(value);
      }
      if (key == 'vendor_id') {
        setVenID(value);
      }
    }
    setKeys(temp_keys);
    setValues(temp_values);
  };

  useEffect(() => {
    headTagValue();
  }, [searchparams]);

  function deleteValueFromDictionary(dictionary, valueToDelete) {
    for (const key in dictionary) {
      if (dictionary.hasOwnProperty(key)) {
        const value = dictionary[key];

        if (Array.isArray(value)) {
          dictionary[key] = value.filter((item) => item !== valueToDelete);
          if (dictionary[key].length === 0) {
            delete dictionary[key];
          }
        } else if (value === valueToDelete) {
          delete dictionary[key];
        }
      }
    }
    return dictionary;
  }

  const handledelete = async (value) => {
    if (value === 'Clear All') {
      const searchParams = new URLSearchParams(window.location.search);

      const keysToRemove = [...keys, 'vendor_id'];
      const keysToKeep = Array.from(searchParams.keys()).filter(
        (key) => !keysToRemove.includes(key)
      );
      for (const value of values) {
        deleteValueFromDictionary(filter, value);
      }
      const newSearchParams = new URLSearchParams();

      keysToKeep.forEach((key) => {
        newSearchParams.set(key, searchParams.get(key));
      });
      setKeys([]);
      setValues([]);
      setHeadkeys([]);

      window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
      // window.history.replaceState(null, '', `?`)
    } else {
      deleteValueFromDictionary(filter, value);

      var tempparam = new URLSearchParams();
      // searchparams.set('page', 1);
      var search = searchparams.toString();
      if (keys[values.indexOf(value)] == 'brand') {
        tempparam.set('vendor_id', ven_id);
        console.log(tempparam.toString());
        search = search.replace(tempparam.toString(), '');
        tempparam.delete('vendor_id');
      }

      tempparam.set(keys[values.indexOf(value)], values[values.indexOf(value)]);
      keys.splice(values.indexOf(value), 1);
      values.splice(values.indexOf(value), 1);
      setKeys(keys);
      setValues(values);
      setHeadkeys(keys);
      // console.log({ keys: keys, values: values });
      var tempsearch = tempparam.toString();
      search = search.replace(`${tempsearch}`, '');
      while (search.startsWith('&')) {
        search = search.substring(1);
      }
      if (search.endsWith('&')) {
        search.slice(0, -1);
      }
      searchparams = search;
      console.log(searchparams);
      window.history.replaceState(null, '', `?${searchparams}`);
      // getProductDetails();
    }
  };
  return (
    <div className={style.headTagContainer}>
      <Stack direction={'row'} spacing={1} useFlexGap flexWrap="wrap">
        {values.length > 1 && (
          <Chip label={'Clear All'} style={{ color:"black"}} onClick={() => handledelete('Clear All')} />
        )}
        {values.map((value) => (
          <Chip
            label={value}
            onDelete={() => {
              handledelete(value);
            }}
            // color='primary'
            style={{backgroundColor:"#7B8569" , color:"white"}}
          />
        ))}
      </Stack>
    </div>
  );
};

// HeadTags.propTypes = {

// }
