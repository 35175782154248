import React, { useState, useEffect, useLayoutEffect, useRef, lazy, Suspense } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import axios from 'axios';
import SliderContainer from 'react-slick';
import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import styles from '../../components/carousel/Carousel.module.css';
import '../../index.css';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { Modal } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as Mui from '@mui/material';
import emailjs from '@emailjs/browser';
import '@google/model-viewer';
import Carousel from '../../components/carousel/Carousel';
// import ProductCard from '../../components/cards/ProductCard';
import QRScanner from '../../components/layout/QrScanner';
import Shareurl from '../../components/Shareurl';
import Header from '../../components/Header/Header';
import style from './ProductReframe.module.css';
import customerApiInstance from '../../utils/CustomerApiInstance';
import { storeCartCount } from '../../store/actions/sampleaction';
import getCartDataCount from '../../utils/CartDetails';
import './Product.css';
import ConsoleHelper from '../../utils/ConsoleHelper';
import Footer from '../../components/footer/Footer';
const LazyMoodBoardCard = lazy(() => import('../../components/cards/MoodBoardCard'));
const LazyProductCard = lazy(() => import('../../components/cards/ProductCard'));

const Accordion = Mui.styled(Mui.Accordion)({
  '& .MuiAccordion-root': {
    backgroundColor: '#fff !important'
  }
});
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: '20px',
      marginBottom: '50px',
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '6px',
        height: '10px',
        marginLeft: '11px'
      }
    },
    paper: {
      borderRadius: '10px',
      maxHeight: '460px',
      height: '330px',
      width: '90%',
      border: '0.5px solid rgba(112,112,112,0.4 )',
      overflow: 'hidden',
      margin: '0px auto',
      [theme.breakpoints.down('sm')]: {
        height: '300px'
      }
    },
    imagefit: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  })
);
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowPrev} alt="prevArrow" {...props} className={styles.leftArr} />
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowNext} alt="nextArrow" {...props} className={styles.rightArr} />
);

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  responsive: [
    {
      breakpoint: 3000,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 6
        //centerMode: true
      }
    },
    {
      breakpoint: 2500,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 5
        //centerMode: true
      }
    },
    {
      breakpoint: 2000,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 4
        //centerMode: true
      }
    },
    {
      breakpoint: 1400,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
        //centerMode: true
      }
    },
    {
      breakpoint: 1100,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
        // centerMode: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 400,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 350,
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    }
  ]
};

const CarouselComponenet = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SliderContainer {...settings.responsive}>{props.children}</SliderContainer>
    </div>
  );
};

const Product = ({ auth, storeCartCount, sx }) => {
  const getName = localStorage.getItem('MX-A');
  const userName = JSON.parse(getName);
  let userId;

  const { id } = useParams();
  const history = useHistory();
  const dimension = useMediaQuery();
  const form = useRef();
  const [productData, setProductData] = useState({});
  // const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('features');
  const [productDetails, setProductDetails] = useState({});
  const [prodQuantity, setProdQuantity] = useState(1);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  //const [snackError, setSnackError] = useState(false);
  const [vendorName, setVendorName] = useState();
  const [vendorId, setVendorId] = useState('');
  const [savedProductIds, setSavedProductIds] = useState([]);
  // const [similarProducts, setSimilarProducts] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  const [allBoards, setAllBoards] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const [currentData, setCurrentData] = useState('');
  const [newBoardCreated, setNewBoardCreated] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedpreview, setselectedpreview] = useState(0);
  const [shippingAddess, setShippingAddess] = useState('');
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();
  const [copiedUrl, setCopiedUrl] = useState('');
  const [newArrivalProducts, setNewArrivalProducts] = useState([]);
  const [show3DModel, setShow3DModel] = useState(false);
  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [vendorLocation, setVendorLocation] = useState();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [showComponent, setShowComponent] = useState(false);
  const [productMoodboards, setProductMoodboards] = useState([]);

  const handleExpandAccordian = (panel) => {
    console.log('true');
    setExpanded('panel1');
  };

  const TruncatedText = ({ text, maxChars, onExpandAccordian }) => {
    const [showFullText, setShowFullText] = useState(false);
    const contentRef = useRef(null);

    const toggleText = () => {
      if (onExpandAccordian) {
        contentRef.current.scrollIntoView({ behavior: 'smooth' });
        handleExpandAccordian();
        // setExpanded(expanded === 'panel1')
        // handleChange("panel1")
      }
    };

    const truncatedText = showFullText ? text : text?.slice(0, maxChars) + '...';
    const shouldTruncate = text?.length > maxChars;

    return (
      <div>
        <p>
          {truncatedText}&nbsp;
          {shouldTruncate && (
            <a style={{ color: 'blue' }} onClick={toggleText}>
              {showFullText ? 'Read Less' : 'Read More'}
            </a>
          )}
        </p>
        {<div ref={contentRef} />}
      </div>
    );
  };

  const getVariantProducts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/getvariants/${id}`
      );
      if (res?.status == 200) {
        setProduct(res.data);
        setSelectedProduct(res.data[0]);
      }
    } catch (err) {
      setProduct([]);
    }
  };

  useEffect(() => {
    const delay = 2500;
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [relatedProducts]);

  useEffect(() => {
    const getNewArrivalProducts = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
          params: { page: 1, tags: 'New Arrival' }
        });
        if (res?.status == 200) {
          setNewArrivalProducts(res?.data?.results);
        } else {
          setNewArrivalProducts([]);
        }
      } catch (err) {
        console.log(err);
        setNewArrivalProducts([]);
      }
    };

    getNewArrivalProducts();
  }, []);

  useEffect(() => {
    const getRelatedProducts = async () => {
      const product = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`);
      console.log(product?.data?.subcategory);
      const price_from = product?.data?.pixel_price * 0.5;
      const price_to = product?.data?.pixel_price * 2;
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
          params: {
            page: 1,
            subcategory: [`${product?.data?.subcategory}`],
            price_from: price_from,
            price_to: price_to
          }
        });
        if (res?.status == 200) {
          setRelatedProducts(res?.data?.results);
        } else {
          setRelatedProducts([]);
        }
      } catch (error) {
        console.log(error);
        setRelatedProducts([]);
      }
    };
    getRelatedProducts();
  }, []);

  useEffect(() => {
    const getCompleteRooms = async () => {
      try {
        const completeRooms = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/productMoodboards/${id}`
        );
        console.log(completeRooms.data);
        setProductMoodboards(completeRooms.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCompleteRooms();
  },[]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function openinnewtab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dc6bf0u', 'template_byt0qp9', form.current, 'u-JUBaCmUc6toe0bh').then(
      (result) => {
        // ConsoleHelper(result.text);
        setSnack(true);
        setSnackMessage('Request AR send to Mail Successfully');
      },
      (error) => {
        //setSnackError(true);
        setSnack(false);
        setSnackMessage('Request AR error');
        ConsoleHelper(error.text);
      }
    );
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`);
      if (res.data.vendor_id) {
        setVendorName(res.data.vendor_id.name);
        setVendorId(res.data.vendor_id.id);
        setVendorLocation(res.data.vendor_id.address['line1']);
        console.log(res.data.vendor_id.address['line1']);
        setShippingAddess(res?.data?.vendor_id?.shipping_returns_link);
      }
      setProductDetails({ ...res.data });
      setCurrentProduct({ ...res.data });
    } catch (err) {
      ConsoleHelper(err);
    }
  };
  const fetchFavouriteData = async () => {
    try {
      if (auth && auth.user) {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
        );
        let savedIds = [];

        if (res.data.favourites[0].length !== 0) {
          res.data.favourites.map((dat) => {
            savedIds = dat.products.map((m) => {
              return m.product_id;
            });
          });
          setSavedProductIds([...savedIds]);
        }
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
    }
  };
  const getCartData = async () => {
    let totalProducts = 0;
    if (auth && auth.user) {
      try {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/list`
        );
        if (res.data && res.data.cart.length > 0 && res.data.cart[0].products) {
          totalProducts = res.data.cart[0].products.length;
          localStorage.setItem('cartTotal', JSON.stringify(totalProducts));
        }
      } catch (error) {
        // ConsoleHelper("error")
      }
    }
  };
  const list_products = async () => {
    try {
      if (auth && auth.user) {
        let boards1 = [];
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
        );
        let result = [...res.data];
        for (let i = 0; i < result.length; i++) {
          // for (let j = 0; j < result[i].moodboards.length; j++) {
          boards1.push({
            id: result[i]._id,
            moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
            title: result[i].name,
            moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
            products:
              result[i].products && result[i].products.length > 0 ? [...result[i].products] : []
          }); // }
        }
        setAllBoards([...boards1]);
      }
    } catch (err) {
      ConsoleHelper(err);
    }
  };

  const getImage = () => {
    if (Object.keys(currentProduct).length && currentProduct.image.length > 0) {
      return currentProduct.image[0];
    }
  };

  const addToCart = async (productData) => {
    let prodBody;
    let userId;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;
        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixe_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          //setSnackError(false);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }
        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
      }
    } catch (e) {
      // setSnackError(true);
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
      ConsoleHelper(e);
    }
    storeCartCount(await getCartDataCount(auth));
  };

  const buyNow = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push('/cart');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
            // pixel_price: productData.pixel_price,
            // stock: productData.stock
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }

        history.push('/cart');
        // setSnack(true);
        // setSnackError(true);
        //setSnackMessage('Please login to buy this product');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const addQuantity = () => {
    return setProdQuantity(prodQuantity + 1);
  };

  const reduceQuantity = () => {
    if (prodQuantity === 1) {
      return setProdQuantity(1);
    } else {
      return setProdQuantity(prodQuantity - 1);
    }
  };

  const getP = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`);
    // let three_d_image = await axios.get(
    //   `${process.env.REACT_APP_BACKEND_URL}/api/product/61f8ce620b5f35fdba7c8441`
    // );
    setProductData(res.data);

    let imagearr = [];
    if (productDetails?.image) {
      if (productDetails?.image.length > 0) {
        for (let i = 0; i < productDetails.image.length; i++) {
          imagearr.push({
            original: productDetails.image[i],
            thumbnail: productDetails.image[i]
          });
        }
        // if (
        //   productDetails &&
        //   productDetails['3d_models'] &&
        //   productDetails['3d_models']?.glbFileUrl
        // ) {
        //   imagearr.push({
        //     thumbnail: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shopPage/360-view-icon.jpg'
        //   });
        // }
      }
    }
    setImagesArray([...imagearr]);
  };

  const productImage = (data) => {
    if (data?.image) {
      return data.image.length > 0 ? data.image[0] : data.image;
    }
    return '';
  };

  const getLength = (data) => {
    if (data?.dimensions) {
      return data?.dimensions.l;
    }
  };
  const getWidth = (data) => {
    if (data?.dimensions) {
      return data?.dimensions.b;
    }
  };
  const addToFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: userId,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
      ConsoleHelper(e);
    }
  };

  const removeFromFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
      ConsoleHelper(e);
    }
  };

  const handleQr = (productIds) => {
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = (productIds) => {
    history.push(`/3d-model/${productIds}`);
  };
  const handleFavourites = (product) => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds((prev) => [...prev, product._id]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter((filter) => filter !== product._id));
      removeFromFavourites(product);
    }
  };

  const checkProdsSaved = (board) => {
    let allProdIds = [];
    let otherBoards = [];
    let existingProd;
    otherBoards = allBoards.filter((allbrd) => allbrd.id !== board.id);
    otherBoards.map((brd) =>
      brd.products.map((prd) => {
        allProdIds.push(prd._id);
      })
    );
    existingProd = allProdIds.some((x) => x === currentProduct._id);
    return existingProd ? true : false;
  };
  const removeFromBoard = async (boardData) => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to remove from the Boards');
      return false;
    }
    let prodIds = [];
    boardData.products.map((pd) => {
      prodIds.push(pd._id);
    });
    prodIds = prodIds.filter((pd) => pd !== currentProduct._id);
    const removeBoardData = {
      moodboard_id: boardData.moodBoard_id,
      products: [...prodIds],
      name: boardData.title,
      user_id: auth.user._id
    };
    for (let i = 0; i < allBoards.length; i++) {
      if (allBoards[i].id === boardData.id) {
        allBoards[i].products = allBoards[i].products.filter((pd) => pd._id !== currentProduct._id);
      }
    }
    setAllBoards([...allBoards]);

    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${boardData.id}`,
        removeBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('Product removed from Board successfully');
      }
    } catch (err) {
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const saveToBoard = async (boardData) => {
    if ((Object.keys(auth).length && !auth.user?._id) || !auth) {
      setSnack(true);
      setSnackMessage('Please Login to save the Boards');
      return false;
    }

    let prodIds = [];
    boardData.products.map((pd) => {
      prodIds.push(pd._id);
    });
    const addToBoardData = {
      moodboard_id: boardData.moodBoard_id,

      products: [...prodIds, currentProduct._id],

      name: boardData.title,
      user_id: auth.user._id
    };
    for (let i = 0; i < allBoards.length; i++) {
      if (allBoards[i].id === boardData.id) {
        allBoards[i].products.push(currentProduct);
      }
    }
    setAllBoards([...allBoards]);
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/update/${boardData.id}`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setSnack(true);
        setSnackMessage('Product saved to Board successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const createNewBoard = async () => {
    const addToBoardData = {
      products: currentProduct._id,
      name: currentData,
      user_id: auth.user._id
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/board/add`,
        addToBoardData
      );
      if (res.data && Object.keys(res.data).length !== 0) {
        setNewBoardCreated(true);
        setModal({ ...modal, modal: false, name: '' });
        setSnack(true);
        setSnackMessage('Board created successfully');
      }
    } catch (err) {
      ConsoleHelper('errorrrr', err);
      setSnack(true);
      setSnackMessage(err);
    }
  };
  const newBoardCreated_listproduct = async () => {
    try {
      if (newBoardCreated) {
        let boards1 = [];
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/board/list_products/${auth.user._id}`
        );
        let result = [...res.data];
        for (let i = 0; i < result.length; i++) {
          boards1.push({
            id: result[i]._id,
            moodBoard_id: result[i].moodboard_id ? result[i].moodboard_id._id : null,
            title: result[i].name,
            moodBoard: result[i].moodboard_id ? result[i].moodboard_id : {},
            products:
              result[i].products && result[i].products.length > 0 ? [...result[i].products] : []
          });
        }
        setAllBoards([...boards1]);
        setNewBoardCreated(false);
      }
    } catch (err) {
      ConsoleHelper(err);
    }
  };
  const Share = (Url) => {
    setCopiedUrl(Url);
  };
  const roundOffPrice = (price) => {
    return price > 0 ? Math.round(price) : '00';
  };
  useEffect(() => {
    newBoardCreated_listproduct();
  }, [newBoardCreated]);

  useEffect(() => {
    fetchData();
    getCartData();
    fetchFavouriteData();
    list_products();
    const Url = `${process.env.REACT_APP_URL}/product/${productDetails._id}`;
    Share(Url);
    // let url = window.location.href;
    // setCopiedUrl(url);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (auth && auth.user) {
      userId = auth.user._id;
    }
  }, [auth]);

  useEffect(() => {
    window.scrollTo(0, 0);
    Share(productDetails);
    getP();
    getVariantProducts();
  }, [productDetails]);
  return (
    <>
      {dimension[0] < 1000 ? (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#F5F5F5',
              overflowX: 'hidden'
            }}>
            <div className={style.header} style={{ backgroundColor: '#0B0E15' }}>
              <Header />
            </div>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snack}
              onClose={() => setSnack(false)}
              message={snackMessage}
              autoHideDuration={2000}
            />
            <div style={{ width: '100%', height: '100%', backgroundColor: '#F5F5F5' }}>
              <div className={style.productBody}>
                <div
                  className={style.productNameMob}
                  style={{ fontWeight: 'bold', fontSize: 'large' }}>
                  {productDetails.name}
                </div>
                <div className={style.roomIcon} style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    className={style.brandName}
                    onClick={() => {
                      history.push({
                        pathname: `/shopbybrandFilter/brand/${vendorName}`
                      });
                    }}>
                    {vendorName}
                  </div>
                  {productDetails['3d_models']?.glbFileUrl ? (
                    <div
                      className={style.roomtag}
                      onClick={() => mobileHandle3D(productDetails?._id)}>
                      <div className={style.roomview}>View in your room</div>
                      <div className={style.arMob}>
                        <img
                          src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/ARIcon.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={style.productImageBody}>
                  <div className={style.productImages}>
                    <div
                      className={style.image_gallery_thumbnails_container}
                      aria-label="Thumbnail Navigation"
                      style={{
                        transform: 'translate3d(0px, 0px, 0px)',
                        transition: 'all 450ms ease-out 0s'
                      }}>
                      {imagesArray?.length > 0 &&
                        imagesArray.map((item, i) => (
                          <button
                            type="button"
                            tabIndex="0"
                            aria-pressed="true"
                            aria-label="Go to Slide 1"
                            className={`image-gallery-thumbnail ${
                              selectedpreview == i ? ' active' : ''
                            }`}
                            onClick={() => {
                              setselectedpreview(i);
                              setShow3DModel(false);
                            }}>
                            <span className="image-gallery-thumbnail-inner">
                              <img
                                className="image-gallery-thumbnail-image"
                                src={item.thumbnail}
                                onClick={() => setselectedpreview(i)}
                              />
                            </span>
                          </button>
                        ))}
                    </div>
                    {productDetails &&
                    productDetails['3d_models'] &&
                    productDetails['3d_models']?.glbFileUrl ? (
                      <div
                        className={`${style['arImage']} ${show3DModel ? style.active : ''}`}
                        onClick={() => {
                          setShow3DModel(true);
                          setselectedpreview();
                        }}>
                        <img
                          className={style.arIcon}
                          src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shopPage/360-view-icon.jpg"
                          alt=""
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className={style.productImage}>
                    {show3DModel && productDetails && productDetails['3d_models']?.glbFileUrl ? (
                      <div style={{ cursor: 'pointer' }} className={style.model}>
                        <model-viewer
                          className={style.modelviewer}
                          src={productDetails && productDetails['3d_models']?.glbFileUrl}
                          alt="A 3D model of an astronaut"
                          ar
                          ar-modes="webxr scene-viewer quick-look"
                          environment-image="neutral"
                          auto-rotate
                          camera-controls
                          style={{
                            height: '100%',
                            width: '100%'
                          }}
                          shadow-intensity="1"
                          ios-src={productDetails['3d_models']?.usdzFileUrl}
                          ar-scale="fixed"
                        />
                      </div>
                    ) : (
                      imagesArray?.length > 0 &&
                      imagesArray.map((item, i) => {
                        return (
                          selectedpreview === i && (
                            <div
                              key={`${i}`}
                              aria-label={`Go to Slide ${i}`}
                              tabIndex="-1"
                              className="image-gallery-slide  center "
                              role="button"
                              style={{
                                // paddingRight: '4px',
                                display: 'inherit',
                                width: '100%',
                                transform: 'translate3d(0%, 0px, 0px)',
                                justifyContent: 'center'
                              }}>
                              <img
                                className={style.mobImage}
                                src={item.original}
                                alt=""
                                srcSet=""
                                height=""
                                width=""
                                sizes=""
                                title=""
                                loading="eager"
                              />
                            </div>
                          )
                        );
                      })
                    )}
                  </div>
                </div>
                {product.length > 1 ? (
                  <>
                    <div className={style.choose}>You can also choose</div>
                    <div className={style.productVarients}>
                      {product.map((prod, index) => (
                        <img
                          style={{ width: '120px', height: '120px' }}
                          src={prod['image'][0]}
                          alt=""
                          onClick={() => {
                            setProductDetails(product[index]);
                            setSelectedProduct(product[index]);
                            setImagesArray(product[index]['images']);
                          }}
                        />
                      ))}
                    </div>
                  </>
                ) : null}
                <div className={style.price}>
                  <div className={style.pixelprice}>
                    Rs.{' '}
                    {new Intl.NumberFormat('en-IN').format(
                      roundOffPrice(productDetails.pixel_price)
                    )}
                  </div>
                  <div style={{ textDecoration: 'line-through' }}>
                    Rs. {new Intl.NumberFormat('en-IN').format(roundOffPrice(productDetails.price))}
                  </div>
                </div>
                <div className={style.buttons}>
                  <button className={style.button} onClick={() => addToCart(productDetails)}>
                    Add to Cart
                  </button>
                  <button className={style.buy} onClick={() => buyNow(productDetails)}>
                    Buy Now
                  </button>
                </div>
                <div className={style.dropdowns}>
                  <Accordion
                    square
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                    sx={{
                      backgroundColor: 'white !important',
                      width: '100%',
                      margin: '0px auto !important',
                      marginBottom: '11px !important'
                    }}>
                    <AccordionSummary
                      sx={{ paddingLeft: '0px !important' }}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography
                        sx={{ width: '100%', flexShrink: 0 }}
                        style={{ fontWeight: 'bold', display: 'flex', paddingLeft: '5px' }}>
                        Specification
                      </Typography>
                      <div>
                        {expanded !== 'panel1' && (
                          <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                        )}
                        {expanded === 'panel1' && (
                          <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        className="d-flex flex-column"
                        style={{
                          backgroundColor: '#ffffff',
                          height: 'auto',
                          width: '100%'
                        }}>
                        <p
                          className=""
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2px',
                            fontSize: 'medium'
                          }}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold' }}>SKU&nbsp;:&nbsp;</div>
                            {productDetails.SKU ? productDetails.SKU : 'Nil'}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold' }}>Material&nbsp;:&nbsp;</div>
                            {productDetails.material ? productDetails.material : 'Nil'}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold' }}>Dimensions&nbsp;:&nbsp;</div>
                            {productDetails.dimensions ? (
                              <>
                                <div>{JSON.stringify(productDetails.dimensions['l'])}mm</div>&nbsp;
                                <div>{JSON.stringify(productDetails.dimensions['b'])}mm</div>&nbsp;
                                <div>{JSON.stringify(productDetails.dimensions['h'])}mm</div>
                              </>
                            ) : null}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold' }}>Color&nbsp;:&nbsp;</div>
                            {productDetails.color ? productDetails.color : 'Nil'}
                          </div>
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    square
                    sx={{
                      backgroundColor: 'white !important',
                      width: '100%',
                      margin: '0px auto !important',
                      marginBottom: '11px !important'
                    }}
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}>
                    <AccordionSummary
                      sx={{ paddingLeft: '0px !important' }}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header">
                      <Typography
                        sx={{ width: '100%', flexShrink: 0 }}
                        style={{ fontWeight: 'bold', display: 'flex', paddingLeft: '5px' }}>
                        {' '}
                        Product Description
                      </Typography>
                      <div>
                        {expanded !== 'panel2' && (
                          <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                        )}
                        {expanded === 'panel2' && (
                          <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        className="d-flex flex-column align-items-start justify-content-start"
                        style={{
                          backgroundColor: '#ffffff',
                          height: 'auto',
                          width: '100%'
                        }}>
                        <p className="">{productDetails.description}</p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    square
                    sx={{
                      backgroundColor: 'white !important',
                      width: '100%',
                      margin: '0px auto !important',
                      marginBottom: '11px !important'
                    }}
                    expanded={expanded === 'panel3'}
                    onChange={handleChange('panel3')}>
                    <AccordionSummary
                      sx={{ paddingLeft: '0px !important' }}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header">
                      <Typography
                        sx={{ width: '100%', flexShrink: 0 }}
                        style={{ fontWeight: 'bold', display: 'flex', paddingLeft: '5px' }}>
                        Return & Cancellation Policy
                      </Typography>
                      <div>
                        {expanded !== 'panel3' && (
                          <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                        )}
                        {expanded === 'panel3' && (
                          <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        className="d-flex flex-column align-items-start justify-content-start"
                        style={{
                          backgroundColor: '#ffffff',
                          height: 'auto',
                          width: '100%'
                        }}>
                        <a href={shippingAddess} target="_blank" rel="noreferrer">
                          {shippingAddess}
                        </a>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div
                      className={style.share}
                      onClick={() => {
                        const Url = `${process.env.REACT_APP_URL}/product/${productDetails._id}`;
                        setSnack(true);
                        setSnackMessage('Url Copied');
                        Share(Url);
                        navigator.clipboard.writeText(Url);
                      }}>
                      <div style={{ width: '100%' }}>
                        <Typography
                          sx={{ width: '100%', flexShrink: 0 }}
                          style={{ fontWeight: 'bold', fontSize: 'large' }}>
                          Share this product
                        </Typography>
                      </div>
                      <div>
                        <img
                          src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/material-symbols_share.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {product.length > 1 && (
                      <div className={style.varientHeading}>
                        <Typography
                          sx={{ width: '100%', flexShrink: 0 }}
                          style={{ fontWeight: 'bold' }}>
                          Variants
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.variantDetails}>
                  {product.length > 1 && (
                    <Suspense
                      fallback={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: 'x-large',
                            fontWeight: 700
                          }}>
                          Loading...
                        </div>
                      }>
                      <Carousel>
                        {product.map((data, index) => (
                          <LazyProductCard
                            data={data}
                            heart={auth && auth.user}
                            arIcon={data && data['3d_models']?.glbFileUrl}
                            tag={'NEW LAUNCH'}
                            vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                            productImage={data.image[0]}
                            productName={data.name}
                            price={data.price}
                            pnmPrice={data.pixel_price}
                            onClickCart={() => addToCart(data)}
                            onClickBuyNow={() => buyNow(data)}
                            onClickHeart={() => console.log('heart')}
                            onClicAr={() =>
                              dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                            }
                            viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                            sx={{ margin: 'auto' }}
                          />
                        ))}
                      </Carousel>
                    </Suspense>
                  )}
                </div>
                <div className={style.relatedProducts}>
                  <div className={style.relatedProductsHead}>Related Products</div>
                  <div className={style.relatedProductsDetails}>
                    {relatedProducts.length > 0 && (
                      <Suspense
                        fallback={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              fontSize: 'x-large',
                              fontWeight: 700
                            }}>
                            Loading...
                          </div>
                        }>
                        {showComponent && (
                          <Carousel>
                            {relatedProducts.map((data, index) => (
                              <LazyProductCard
                                data={data}
                                heart={auth && auth.user}
                                arIcon={data && data['3d_models']?.glbFileUrl}
                                // tag={'NEW LAUNCH'}
                                vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                                productImage={data.image[0]}
                                productName={data.name}
                                price={data.price}
                                pnmPrice={data.pixel_price}
                                onClickCart={() => addToCart(data)}
                                onClickBuyNow={() => buyNow(data)}
                                onClickHeart={() => console.log('heart')}
                                onClicAr={() =>
                                  dimension[0] > 1000
                                    ? handleQr(data._id)
                                    : mobileHandle3D(data._id)
                                }
                                viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                                sx={{ margin: 'auto' }}
                              />
                            ))}
                          </Carousel>
                        )}
                      </Suspense>
                    )}
                  </div>
                </div>
                <div className={style.completeRoom}>
                  {productMoodboards && productMoodboards.length > 0 ? (
                    <>
                      <div className={style.variantsHeading}>Complete your room</div>
                      <Suspense
                        fallback={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              fontSize: 'x-large',
                              fontWeight: 700
                            }}>
                            Loading...
                          </div>
                        }>
                        {showComponent && (
                          <div className={style.completeRoomWeb}>
                            <div className={style.container2}>
                              <div
                                className="container"
                                style={{ maxWidth: '100%', height: 'auto' }}>
                                <div
                                  className={`row ${dimension[0] > 500 ? 'mt-4' : 'mt-3'}`}
                                  style={{ display: 'grid', gap: '12px' }}>
                                  {productMoodboards.map((moodboard) => (
                                    <LazyMoodBoardCard
                                      moodBoardImage={moodboard.preview_url}
                                      ViewMoodBoard={() =>
                                        openinnewtab(`/pre-explore-detail/${moodboard?._id}`)
                                      }
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Suspense>
                    </>
                  ) : null}
                </div>
                {/* <div className={style.relatedProducts}>
                  <div className={style.relatedProductsHead}>Previously Viewed products</div>
                  <div className={style.relatedProductsDetails}>
                    {newArrivalProducts.length > 0 && (
                      <Carousel>
                        {newArrivalProducts.map((data, index) => (
                          <ProductCard
                            data={data}
                            heart={auth && auth.user}
                            arIcon={data && data['3d_models']?.glbFileUrl}
                            tag={'NEW LAUNCH'}
                            vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                            productImage={data.image[0]}
                            productName={data.name}
                            price={data.price}
                            pnmPrice={data.pixel_price}
                            onClickCart={() => addToCart(data)}
                            onClickBuyNow={() => buyNow(data)}
                            onClickHeart={() => console.log('heart')}
                            onClicAr={() =>
                              dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                            }
                            viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                            sx={{ margin: 'auto' }}
                          />
                        ))}
                      </Carousel>
                    )}
                  </div>
                </div> */}
              </div>
              <Footer />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#F5F5F5',
              overflowX: 'hidden'
            }}>
            <div className={style.header} style={{ backgroundColor: '#0B0E15' }}>
              <Header />
            </div>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snack}
              onClose={() => setSnack(false)}
              message={snackMessage}
              autoHideDuration={2000}
            />
            <div className={style.breadCrumbs}>
              <div
                onClick={() => {
                  history.push({
                    pathname: `/shop`
                  });
                }}>
                Shop Now
              </div>
              <div>&nbsp;{'>'}&nbsp;</div>
              <div
                onClick={() => {
                  history.push({
                    pathname: `/shopbybrandFilter/brand/${vendorName}`
                  });
                }}>
                {vendorName}
              </div>
              {/* <div>&nbsp;{'>'}&nbsp;</div>
              <div
                onClick={() => {
                  history.push({
                    pathname: `/shop-details/${productDetails.room_type[0]}/false`
                  });
                }}>
                {productDetails.category}
              </div> */}
              <div>&nbsp;{'>'}&nbsp;</div>
              <div style={{ fontWeight: 'bold' }}>{productDetails.name}</div>
            </div>
            <div className={style.productWeb}>
              <div className={style.productBodyWeb}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '500px',
                    gap: '10px'
                    // width: '10%'
                  }}>
                  <div
                    className={style.image_gallery_web}
                    aria-label="Thumbnail Navigation"
                    style={{
                      transform: 'translate3d(0px, 0px, 0px)',
                      transition: 'all 450ms ease-out 0s'
                    }}>
                    {imagesArray?.length > 0 &&
                      imagesArray.map((item, i) => (
                        <button
                          type="button"
                          tabIndex="0"
                          aria-pressed="true"
                          aria-label="Go to Slide 1"
                          className={`image-gallery-thumbnail ${
                            selectedpreview == i ? ' active' : ''
                          }`}
                          onClick={() => {
                            setselectedpreview(i);
                            setShow3DModel(false);
                          }}>
                          <span className="image-gallery-thumbnail-inner">
                            <img
                              className="image-gallery-thumbnail-image"
                              src={item.thumbnail}
                              onClick={() => setselectedpreview(i)}
                            />
                          </span>
                        </button>
                      ))}
                  </div>
                  {productDetails &&
                  productDetails['3d_models'] &&
                  productDetails['3d_models']?.glbFileUrl ? (
                    <div
                      className={`${style['arImageWeb']} ${show3DModel ? style.active : ''}`}
                      onClick={() => {
                        setselectedpreview();
                        setShow3DModel(true);
                      }}>
                      <img
                        className={style.arIconWeb}
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shopPage/360-view-icon.jpg"
                        alt=""
                      />
                    </div>
                  ) : null}
                </div>
                <div className={style.ImageWeb}>
                  {show3DModel && productDetails && productDetails['3d_models']?.glbFileUrl ? (
                    <div style={{ cursor: 'pointer' }}>
                      <model-viewer
                        src={productDetails && productDetails['3d_models']?.glbFileUrl}
                        alt="A 3D model of an astronaut"
                        ar
                        ar-modes="webxr scene-viewer quick-look"
                        environment-image="neutral"
                        auto-rotate
                        camera-controls
                        style={{
                          height: '500px',
                          width: '100%'
                        }}
                        shadow-intensity="1"
                        ios-src={productDetails['3d_models']?.usdzFileUrl}
                        ar-scale="fixed"
                      />
                    </div>
                  ) : (
                    imagesArray?.length > 0 &&
                    imagesArray.map((item, i) => {
                      return (
                        selectedpreview === i && (
                          <div
                            key={`${i}`}
                            aria-label={`Go to Slide ${i}`}
                            tabIndex="-1"
                            className={`${style['image-gallery-slide']} ${style['center']} ']}`}
                            role="button"
                            style={{
                              display: 'inherit',
                              objectFit: 'contain',
                              transform: 'translate3d(0%, 0px, 0px)'
                            }}>
                            <img
                              className={style.renderImage}
                              src={item.original}
                              alt=""
                              srcSet=""
                              height=""
                              width=""
                              sizes=""
                              title=""
                              loading="eager"
                            />
                          </div>
                        )
                      );
                    })
                  )}
                </div>
                <div className={style.productDetailsLeft}>
                  <div className={style.productNameWeb}>{productDetails.name}</div>
                  <a
                    href={`/mainproduct?brand=${vendorName}&vendor_id=${vendorId}`}
                    className={style.vendorNameWeb}>
                    {vendorName}
                  </a>
                  <div className={style.pricesWeb}>
                    <div style={{ fontSize: 'larger', fontWeight: 'bold' }}>
                      Rs.{' '}
                      {new Intl.NumberFormat('en-IN').format(
                        roundOffPrice(productDetails.pixel_price)
                      )}
                    </div>
                    <div
                      style={{
                        fontSize: 'larger',
                        fontWeight: 'bold',
                        color: 'grey',
                        textDecoration: 'line-through'
                      }}>
                      Rs.{' '}
                      {new Intl.NumberFormat('en-IN').format(roundOffPrice(productDetails.price))}
                    </div>
                  </div>
                  <div className={style.productDescriptionWeb}>
                    <TruncatedText
                      text={productDetails?.description}
                      maxChars={180}
                      onExpandAccordian={handleExpandAccordian}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    {product.length > 1 ? (
                      <>
                        <div style={{ fontWeight: 600, fontSize: 'large' }}>
                          You can also choose
                        </div>
                        <div className={style.productVariantsWebList}>
                          {product.map((prod, index) => (
                            <button
                              type="button"
                              tabIndex="0"
                              aria-pressed="true"
                              aria-label={`Go to Slide ${index}`}
                              className={`${style['image_gallery_thumbnail']} ${
                                selectedProduct === index ? style.active : ''
                              }`}>
                              <img
                                style={{ width: '120px', height: '120px' }}
                                src={prod['image'][0]}
                                alt=""
                                onClick={() => {
                                  setProductDetails(product[index]);
                                  setSelectedProduct(product[index]);
                                  setImagesArray(product[index]['images']);
                                }}
                              />
                            </button>
                          ))}
                        </div>
                      </>
                    ) : null}
                    <div className={style.buttonsWeb}>
                      <button className={style.addWeb} onClick={() => addToCart(productDetails)}>
                        Add to Cart
                      </button>
                      <button className={style.buyWeb} onClick={() => buyNow(productDetails)}>
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.productDetailsRight}>
                  <div
                    className={style.shareWeb}
                    onClick={() => {
                      const Url = `${process.env.REACT_APP_URL}/product/${productDetails._id}`;
                      setSnack(true);
                      setSnackMessage('Url Copied');
                      Share(Url);
                      navigator.clipboard.writeText(Url);
                    }}>
                    <div style={{ width: '100%', fontWeight: '600', fontSize: 'large' }}>
                      Share This Product
                    </div>
                    <div>
                      <img
                        className={style.shareImage}
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/material-symbols_share.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={style.specifications}>
                    <div className={style.specHeading}>Specification</div>
                    <div className={style.bottomBorder}></div>
                    <div style={{ fontWeight: '500', fontSize: 'medium' }}>
                      <table
                        class="table-responsive table table-borderless"
                        style={{ borderBottom: '0px !important', overflowX: 'hidden' }}>
                        <tbody>
                          <tr>
                            <td style={{ padding: '0.5rem' }}>SKU:</td>
                            <td
                              style={{
                                display: 'block',
                                padding: '0.5rem',
                                maxWidth: '120px',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }}>
                              {productDetails.SKU}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: '0.5rem' }}>Dimension:</td>
                            <td
                              style={{
                                display: 'block',
                                padding: '0.5rem',
                                maxWidth: '130px',
                                fontSize: '16px',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }}>
                              {productDetails.dimensions ? (
                                <>
                                  <div>
                                    {JSON.parse(productDetails.dimensions['l'])}x
                                    {JSON.parse(productDetails.dimensions['b'])}x
                                    {JSON.parse(productDetails.dimensions['h'])}
                                    {productDetails.dimensions['unit']}
                                  </div>
                                </>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: '0.5rem' }}>Material:</td>
                            <td
                              style={{
                                display: 'block',
                                padding: '0.5rem',
                                maxWidth: '120px',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }}>
                              {productDetails.material}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: '0.5rem' }}>Color:</td>
                            <td
                              style={{
                                display: 'block',
                                padding: '0.5rem',
                                maxWidth: '120px',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }}>
                              {productDetails.color}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {productDetails['3d_models']?.glbFileUrl ? (
                    <div
                      className={style.roomViewButtonWeb}
                      onClick={() => handleQr(productDetails?._id)}>
                      <div style={{ fontWeight: '600', fontSize: 'large', width: '100%' }}>
                        View In Your Room
                      </div>
                      <div>
                        <img
                          className={style.shareImage}
                          src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/ARIcon.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className={style.vendorHouse}>
                    <div
                      style={{
                        fontWeight: '500',
                        fontSize: 'large',
                        width: '100%',
                        paddingLeft: '10px'
                      }}>
                      From the House of
                    </div>
                    <div className={style.bottomBorder}></div>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: 'large',
                        width: '100%',
                        paddingLeft: '12px'
                      }}>
                      {vendorName}
                    </div>
                    <div
                      style={{
                        display: 'block',
                        padding: '0.5rem',
                        maxWidth: '100%',
                        fontSize: '12px',
                        // whiteSpace: 'nowrap',
                        // textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontWeight: '500',
                        paddingLeft: '12px'
                      }}>
                      {vendorLocation}
                    </div>
                  </div>
                  <div className={style.returnPolicy}>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: 'large',
                        width: '100%',
                        paddingLeft: '12px'
                      }}>
                      <a href={shippingAddess} target="_blank" rel="noreferrer">
                        Return Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.productDescriptionContainer}>
                <Accordion
                  square
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                  sx={{
                    backgroundColor: 'white !important',
                    width: '100%',
                    margin: '0px auto !important',
                    marginBottom: '11px !important'
                  }}>
                  <AccordionSummary
                    sx={{ paddingLeft: '0px !important' }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Typography
                      sx={{ width: '100%', flexShrink: 0 }}
                      style={{
                        fontWeight: 'bold',
                        paddingLeft: '5px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px'
                      }}>
                      Product Description
                      <div>
                        {expanded !== 'panel1' && (
                          <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/plus+icon.svg" />
                        )}
                        {expanded === 'panel1' && (
                          <img src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/minus+icon.svg" />
                        )}
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: '#ffffff',
                        height: 'auto',
                        width: '100%'
                      }}>
                      <p className="">{productDetails.description}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              {product.length > 1 ? (
                <>
                  <div className={style.variantsHeading}>Variants</div>
                  <div className={style.variantsWeb}>
                    {product.length > 1 && (
                      <Suspense
                        fallback={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              fontSize: 'x-large',
                              fontWeight: 700
                            }}>
                            Loading...
                          </div>
                        }>
                        <Carousel>
                          {product.map((data, index) => (
                            <LazyProductCard
                              data={data}
                              heart={auth && auth.user}
                              arIcon={data && data['3d_models']?.glbFileUrl}
                              tag={'NEW LAUNCH'}
                              vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                              productImage={data.image[0]}
                              productName={data.name}
                              price={data.price}
                              pnmPrice={data.pixel_price}
                              onClickCart={() => addToCart(data)}
                              onClickBuyNow={() => buyNow(data)}
                              onClickHeart={() => console.log('heart')}
                              onClicAr={() =>
                                dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                              }
                              viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                              sx={{ margin: 'auto' }}
                            />
                          ))}
                        </Carousel>
                      </Suspense>
                    )}
                  </div>
                </>
              ) : null}
              <div className={style.variantsHeading}>Related Products</div>
              <div className={style.variantsWeb}>
                {relatedProducts.length > 0 && (
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontSize: 'x-large',
                          fontWeight: 700
                        }}>
                        Loading...
                      </div>
                    }>
                    {showComponent && (
                      <Carousel>
                        {relatedProducts.map((data, index) => (
                          <LazyProductCard
                            data={data}
                            heart={auth && auth.user}
                            arIcon={data && data['3d_models']?.glbFileUrl}
                            // tag={'NEW LAUNCH'}
                            vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                            productImage={data.image[0]}
                            productName={data.name}
                            price={data.price}
                            pnmPrice={data.pixel_price}
                            onClickCart={() => addToCart(data)}
                            onClickBuyNow={() => buyNow(data)}
                            onClickHeart={() => console.log('heart')}
                            onClicAr={() =>
                              dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                            }
                            viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                            sx={{ margin: 'auto' }}
                          />
                        ))}
                      </Carousel>
                    )}
                  </Suspense>
                )}
              </div>
              {productMoodboards && productMoodboards.length > 0 ? (
                <>
                  <div className={style.variantsHeading}>Complete your room</div>
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontSize: 'x-large',
                          fontWeight: 700
                        }}>
                        Loading...
                      </div>
                    }>
                    {showComponent && (
                      <div className={style.completeRoomWeb}>
                        <div className={style.container2}>
                          <div className="container" style={{ maxWidth: '100%', height: 'auto' }}>
                            <div className={`row ${dimension[0] > 500 ? 'mt-4' : 'mt-3'}`}>
                              {productMoodboards.map((moodboard) => (
                                <LazyMoodBoardCard
                                  moodBoardImage={moodboard.preview_url}
                                  ViewMoodBoard={() =>
                                    openinnewtab(`/pre-explore-detail/${moodboard?._id}`)
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Suspense>
                </>
              ) : null}
              {/* <div className={style.variantsHeading}>Previously Viewed Products</div>
              <div className={style.variantsWeb}>
                {newArrivalProducts.length > 0 && (
                  <Carousel>
                    {newArrivalProducts.map((data, index) => (
                      <ProductCard
                        data={data}
                        heart={auth && auth.user}
                        arIcon={data && data['3d_models']?.glbFileUrl}
                        tag={'NEW LAUNCH'}
                        vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                        productImage={data.image[0]}
                        productName={data.name}
                        price={data.price}
                        pnmPrice={data.pixel_price}
                        onClickCart={() => addToCart(data)}
                        onClickBuyNow={() => buyNow(data)}
                        onClickHeart={() => console.log('heart')}
                        onClicAr={() =>
                          dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                        }
                        viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                        sx={{ margin: 'auto' }}
                      />
                    ))}
                  </Carousel>
                )}
              </div> */}
            </div>
            <Footer />
            {modalOpen && (
              <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />
            )}
          </div>
        </>
      )}
    </>
  );
};
Product.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(Product));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
