import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import Header from '../../components/Header/Header';
import style from './Shopdetails.module.css';
import style1 from './Shop.module.css';
import './Shop.css';
import classnames from 'classnames';
import { Chip, Drawer, Grid } from '@material-ui/core';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { rooms } from './constant';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import QRScanner from '../../components/layout/QrScanner';
import { makeStyles } from '@material-ui/core/styles';
import ReactPaginate from 'react-paginate';
import customerApiInstance from '../../utils/CustomerApiInstance';
import getCartDataCount from '../../utils/CartDetails';
import { storeCartCount } from '../../store/actions/sampleaction';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import arrow from '../../assets/images/Iconionic-ios.png';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { openin } from '../../utils/utils';
import { CloseOutlined } from '@material-ui/icons';
import ConsoleHelper from '../../utils/ConsoleHelper';
import ProductCard from '../../components/cards/ProductCard';
import ProductFilter from '../../components/ProductFilter/ProductFilter';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Tags } from '../../components/Tags/tags';
import { HeadTags } from '../../components/Tags/headTags';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-root': {
    width: '83%'
  },
  '& .MuiInputBase-input': {
    '&:focus': {
      borderRadius: 23
    },
    borderRadius: 23,
    boxShadow: '0px 3px 6px #00000029',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '5px 17px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}));

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: theme.shadows[5]
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: 'none',
    width: 800,
    height: 500,
    padding: theme.spacing(2, 4, 3)
  },
  papers: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    width: '70%',
    height: 500,
    position: 'relative',
    outline: 'none'
  },
  displayrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  roombox: {
    fontSize: '15px',
    marginTop: '16px',
    fontWeight: 600,
    marginBottom: '51px'
  },
  drawerHeader: {
    float: 'right'
  }
}));

const shop_type = [
  { id: 1, value: 'living_room', type: 'Living Room' },
  { id: 2, value: 'dining_room', type: 'Dining Room' },
  { id: 3, value: 'bed_room', type: 'Bed Room' },
  { id: 4, value: 'kids_room', type: 'Kids Room' },
  { id: 5, value: 'puja_room', type: 'Puja Room' },
  { id: 6, value: 'foyer', type: 'Foyer' },
  { id: 7, value: 'balcony', type: 'Balcony' },
  {
    id: 8,
    value: 'study_entertainment_room',
    type: 'Study Entertainment Room'
  },
  { id: 9, value: 'other', type: 'Other' }
];

const MainProductList = ({ history, auth, storeCartCount }) => {
  const classes = useStyles();
  const location = useLocation();

  const params = useParams();
  const searchparams = new URLSearchParams(window.location.search);
  const [shprm, setShprm] = useState({});
  const [brandlogo, setBrandLogo] = useState('');
  const [brandimage, setBrandImage] = useState('');
  const [brandname, setBrandName] = useState('');
  useEffect(() => {
    let items = {};
    for (const [key, value] of searchparams.entries()) {
      items[key] = value;
    }
    items['page'] = page;
    setShprm(items);
  }, [selectedsubcategory, page]);
  useEffect(() => {
    if (location.state) {
      // console.log(location.state , "sort")

      for (const key in location.state) {
        if (location.state.hasOwnProperty(key)) {
          const value = location.state[key];
          // console.log(`Key: ${key}, Value: ${value}`);
          searchparams.set(key, value);
        }
      }
      searchparams.set('sortBy', 'view_count:desc');
    }
    // console.log(searchparams.toString())
    window.history.replaceState(null, '', `?${searchparams.toString()}`);
    const allItems = {};
    if (location.data) {
      setBrandImage(location.data.brandimage);
      setBrandLogo(location.data.brandlogo);
      setBrandName(searchparams.get('brand'));
    }
    // Loop through all keys in the search parameters and store them in the 'allItems' object
    for (const key of searchparams.keys()) {
      allItems[key] = searchparams.get(key);
    }

    setShprm(allItems);
  }, []);

  const dimension = useMediaQuery();
  const productListViewRef = useRef(null);
  const [filterOption, setFilterOption] = useState({
    AR: true,
    sortBy: 'view_count:desc',
    brand: params.brand,
    primary: true,
    vendor_id: params.id
  });
  const [totalPages, setTotalPages] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [tempProductsList, setTempProductsList] = useState([]);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [prodQuantity] = useState(1);
  const [savedProductIds, setSavedProductIds] = useState([]);
  const [savedProducts, setSavedProducts] = useState([]);
  const [likesChecked, setLikesChecked] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const [prodLoading, setProdLoading] = useState(false);
  const [bannerlist, setbannerlist] = useState([]);
  const [sortby, setsortby] = useState('view_count:desc');
  const [selectedsubcategory, setselectedsubcategory] = useState('');
  const [subcategory, setsubcategory] = useState([]);
  const [shopType, setShopType] = useState(params?.roomtype ? params.roomtype : 1);
  const [modalOpen, setModalOpen] = useState();
  const [productIds, setProductId] = useState();
  const [headkeys, setHeadkeys] = useState([]);

  const getsubcat = async () => {
    var subcat = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/filterOptions?category=${searchparams.get(
        'category'
      )}&room_type=${searchparams.get('room_type')}`
    );
    var subcat_data = subcat.data.sub_category;
    // console.log(subcat_data)
    var tags = await Tags();
    var temp_tag = tags.find((tag) => tag.name == searchparams.get('category'));
    var temp_subcat = temp_tag.subcat;
    // console.log(temp_subcat)
    var subcat_array = [];
    subcat_data.map((sub_cat) => {
      var temp = {};
      temp['title'] = sub_cat;
      var temp_sub = temp_subcat.find((sc) => sc.name == sub_cat);
      if (temp_sub != undefined) {
        temp['image'] = temp_sub.image;
      }
      subcat_array.push(temp);
    });
    setsubcategory(subcat_array);
  };
  useEffect(() => {
    getsubcat();
  }, []);

  // console.log(filterOption);

  let prevPage = useRef(0);
  //let filterApplied = useRef(false);

  function openinnewtab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  const handleQr = (productIds) => {
    setModalOpen(true);
    setProductId(productIds);
  };

  const mobileHandle3D = (productIds) => {
    history.push(`/3d-model/${productIds}`);
  };
  const getProductDetails = async (page, shprm) => {
    setProdLoading(true);
    // const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
    //   params: { page, ...shprm }
    // });
    // console.log(searchparams.toString());
    searchparams.set('primary', true);
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/products?${searchparams.toString()}`
    );
    setProdLoading(false);
    setProductsList([...res.data.results]);
    setTempProductsList([...res.data.results]);
    setTotalRecords(res.data.totalResults);
    setTotalPages(res.data.totalPages);
    setLimit(res.data.limit);
    // setPage(searchparams.get('page'))
    loadTableData();
  };

  const loadTableData = () => {
    if (page === prevPage.current) {
      setPage(searchparams.get('page'));
    }
  };
  const addToCart = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const buyNow = async (productData) => {
    let userId;
    let prodBody;
    try {
      if (auth && auth.user) {
        userId = auth.user._id;

        prodBody = {
          user_id: userId,
          products: [
            {
              product_id: productData._id,
              quantity: prodQuantity,
              rate: productData.price || productData.pixel_price,
              total_price: prodQuantity * (productData.price || productData.pixel_price)
            }
          ]
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cart/add`,
          prodBody
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to cart successfully');
          history.push('/cart');
        }
      } else {
        let products = [
          {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price),
            category: productData.category,
            room_type: productData.room_type,
            style: productData.style
          }
        ];
        if (JSON.parse(localStorage.getItem('cartProducts'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProducts'));
          const addProducts = [...localProducts, ...products];
          localStorage.setItem('cartProducts', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProducts', JSON.stringify(products));
        }

        if (JSON.parse(localStorage.getItem('cartProductsWithDetails'))) {
          const localProducts = JSON.parse(localStorage.getItem('cartProductsWithDetails'));
          const addProducts = [...localProducts, productData];
          localStorage.setItem('cartProductsWithDetails', JSON.stringify(addProducts));
        } else {
          localStorage.setItem('authentication', 'PENDING');
          localStorage.setItem('cartProductsWithDetails', JSON.stringify([productData]));
        }
        history.push('/cart');
      }
    } catch (e) {
      setSnack(false);
      setSnackMessage('Error in Product adding to cart');
    }

    storeCartCount(await getCartDataCount(auth));
  };

  const renderColumn = () => {
    return (
      <div className="row sm-pl-3">
        {productsList && productsList.length > 0 ? (
          productsList
            .filter((data) => {
              if (data.price === 0) {
                return false;
              } else {
                return true;
              }
            })
            .map((data, index) => (
              <>
                {data?.stock > 0 ? (
                  <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">
                    <ProductCard
                      key={index}
                      data={data}
                      heart={auth && auth.user}
                      heartIsActive={savedProductIds?.includes(data._id)}
                      arIcon={data && data['3d_models']?.glbFileUrl}
                      vendorName={data.vendor_id ? data.vendor_id.name : 'Pixels & Motor'}
                      productImage={data.image[0]}
                      productName={data.name}
                      price={data.price}
                      pnmPrice={data.pixel_price}
                      onClickCart={() => addToCart(data)}
                      onClickBuyNow={() => buyNow(data)}
                      onClickHeart={() => handleFavourites(data)}
                      onClicAr={() =>
                        dimension[0] > 1000 ? handleQr(data._id) : mobileHandle3D(data._id)
                      }
                      viewProduct={() => openinnewtab(`/product/${data?._id}`)}
                      sx={{ margin: 'auto', width: '100%', maxWidth: '295px' }} // sx will apply style to the outermost container of Product Card (To over ride css)
                    />
                  </div>
                ) : null}
                {index === 8 ? (
                  <Grid container>
                    <Grid item xs={12} className={style1.imgtxtcontainer}>
                      <img
                        onClick={() =>
                          bannerlist[2]?.routing_url && openin(bannerlist[2].routing_url)
                        }
                        src={
                          bannerlist[2]?.img_url
                            ? bannerlist[2].img_url
                            : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/shutterstock_496612132.png'
                        }
                        alt=""
                        className={classnames(style.bannerImg, 'imagefitinsize mb-5 mt-3')}
                        style={{ width: '100%' }}
                      />
                      {dimension[0].width > 500 ? (
                        <p className={style1.txtoverimgrightlist1}>
                          {bannerlist[2]?.content
                            ? bannerlist[2].content
                            : `Get interiors
                designed online
                with the power of A.R.`}
                        </p>
                      ) : (
                        <p className={style1.txtoverimgrightlist1}>
                          {bannerlist[2]?.content
                            ? bannerlist[2].content
                            : `Get interiors
                designed online
                with the power of A.R.`}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </>
            ))
        ) : (
          <p>No Products Available </p>
        )}
      </div>
    );
  };

  const addToFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/add`,
          body
        );
        ConsoleHelper('response shop likesChecked', res.data);
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product added to favourites successfully');
        }
      } else {
        setSnack(true);
        setSnackMessage('Please Login to add the Products to favourites');
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  adding  Product to favourites');
    }
  };

  const removeFromFavourites = async (productData) => {
    try {
      if (auth && auth.user) {
        const body = {
          user_id: auth.user._id,
          products: {
            product_id: productData._id,
            quantity: prodQuantity,
            rate: productData.price || productData.pixel_price,
            total_price: prodQuantity * (productData.price || productData.pixel_price)
          }
        };
        const res = await customerApiInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/delete`,
          body
        );
        if (res.data) {
          setSnack(true);
          setSnackMessage('Product deleted from favourites successfully');
        }
      }
    } catch (e) {
      setSnack(true);
      setSnackMessage('Error in  deleting  Product from favourites');
    }
  };

  const handleFavourites = (product) => {
    if (!savedProductIds.includes(product._id)) {
      setSavedProductIds((prev) => [...prev, product._id]);
      setSavedProducts([...savedProducts, product]);
      addToFavourites(product);
    } else {
      setSavedProductIds(savedProductIds.filter((filter) => filter !== product._id));
      const removefav = savedProducts.filter((sav) => sav._id !== product._id);
      setSavedProducts([...removefav]);
      removeFromFavourites(product);
    }
  };

  const getgenricbanner = async () => {
    const res = await axios.get(`${process.env.REACT_APP_CMS_URL}/api/v1/user/genricbanner`);
    if (res.status == 200 && res?.data?.data?.length > 0) {
      let responsedata = res.data.data;
      responsedata.sort(function (a, b) {
        if (a.bannername < b.bannername) {
          return -1;
        }
        if (a.bannername > b.bannername) {
          return 1;
        }
        return 0;
      });
      setbannerlist([...responsedata]);
    } else {
      setbannerlist([]);
    }
  };

  const handleChangeInput = (e) => {
    let value = e.target.value;
    setsortby(value);
    if (value !== '') {
      setFilterOption({
        ...filterOption,
        sortBy: value
      });
    }
  };

  // const handleChangeshoptype = (event) => {
  //   setShopType(event.target.value);
  //   let roomtype = shop_type.find((item) => item.id == event.target.value).type;
  //   history.push({
  //     pathname: `/shop-details/${roomtype}/${false}`
  //   });
  // };

  const fetchFavouriteData = async () => {
    try {
      if (auth && auth.user) {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/favourite/list/${auth.user._id}`
        );
        let savedIds = [];
        if (likesChecked) {
          if (res?.data?.favProducts?.length > 0) {
            setProductsList([...res.data.favProducts]);
          }
        } else {
          setProductsList([...tempProductsList]);
        }
        if (res.data.favourites.length !== 0) {
          res.data.favourites.map((dat) => {
            savedIds = dat.products.map((m) => {
              return m.product_id;
            });
          });
          setSavedProductIds([...savedIds]);
          const favouriteProductData = productsList.filter((prod) => savedIds.includes(prod._id));
          setSavedProducts([...favouriteProductData]);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (JSON.stringify(shprm) != '{}') {
      //   let filters = { ...filterOption };
      searchparams.set('page', page);
      searchparams.set('sortBy', sortby);
      // shprm["page"] = page
      window.history.replaceState(null, '', `?${searchparams.toString()}`);
      if (searchparams.get('subcategory[]') === null) {
        setselectedsubcategory('');
      }
      getProductDetails(page, shprm);
    }
  }, [page, filterOption, sortby, headkeys]);

  useEffect(() => {
    fetchFavouriteData();
  }, [likesChecked]);
  // console.log(filterOption,"filters")
  useEffect(() => {
    if (shopType) {
      let room = shop_type?.find((item) => item.id === shopType)?.type;
      if (filterOption.hasOwnProperty('room_type')) {
        let index = filterOption.room_type.findIndex((rm) => rm === room);
        if (index < 0) {
          setFilterOption({
            ...filterOption,
            room_type: [room]
          });
        } else {
          filterOption.room_type = filterOption.room_type.filter((rm) => rm !== room);
          setFilterOption({ ...filterOption });
        }
      } else {
        setFilterOption({ ...filterOption, room_type: [room] });
      }
      // let data = [];
      // data = rooms
      //   .find((item) => item.id == shopType)
      //   ?.category?.find((sub) => sub.title == params.category)?.subcategory;
      // setsubcategory(data);
    }
  }, [shopType]);

  // useEffect(() => {
  //   if (selectedsubcategory !== '') {
  //     let roomtitle = rooms.find((item) => item.id == params.roomtype);
  //     setFilterOption({
  //       ...filterOption,
  //       category: [params.category],
  //       room_type: [roomtitle.title],
  //       subcategory: [selectedsubcategory]
  //     });
  //   }
  // }, [selectedsubcategory]);
  function searchParamsToDictionary(searchParams) {
    const dictionary = {};

    for (const [key, value] of searchParams.entries()) {
      const modifiedKey = key.endsWith('[]') ? key.slice(0, -2) : key;

      if (dictionary.hasOwnProperty(modifiedKey)) {
        if (Array.isArray(dictionary[modifiedKey])) {
          dictionary[modifiedKey].push(value);
        } else {
          dictionary[modifiedKey] = [dictionary[modifiedKey], value];
        }
      } else {
        dictionary[modifiedKey] = value;
      }
    }
    for (const key in dictionary) {
      if (dictionary.hasOwnProperty(key)) {
        // console.log(`Key: ${key}, Value: ${value}`);
        var toarray = ['material', 'color', 'style'];
        if (toarray.includes(key)) {
          const value = dictionary[key];
          if (!Array.isArray(value)) {
            dictionary[key] = [value]; // Wrap the value in an array
          }
        }
      }
    }
    console.log(dictionary);
    return dictionary;
  }

  useEffect(() => {
    // productId && getP();
    getgenricbanner();
    getProductDetails();
    const urlfilter = searchParamsToDictionary(searchparams);
    setFilterOption({ ...filterOption, ...urlfilter });
    // if (params?.roomtype) {
    //   // let data = [];
    //   // data = rooms
    //   //   .find((item) => item.id == params.roomtype)
    //   //   ?.category?.find((sub) => sub.title == params.category)?.subcategory;
    //   // setsubcategory(data);
    //   let roomtitle = rooms.find((item) => item.id == params.roomtype);
    //   if (roomtitle?.title && params?.styletype) {
    //     setFilterOption({
    //       ...filterOption,
    //       room_type: [roomtitle.title],
    //       category: [params.category],
    //       style: [params.styletype]
    //     });
    //   } else if (roomtitle?.title) {
    //     setFilterOption({
    //       ...filterOption,
    //       room_type: [roomtitle.title],
    //       category: [params.category]
    //     });
    //   }
    // }
  }, []);

  // useEffect(() => {
  //   if (shprm.room_type) {
  //     let data = [];
  //     data = rooms
  //       .find((item) => item.title == shprm.room_type)
  //       ?.category?.find((sub) => sub.title == shprm.category)?.subcategory;

  //     setsubcategory(data);
  //   }
  // }, [shprm]);
  useEffect(() => {
    if (selectedsubcategory != '') {
      if (selectedsubcategory != searchparams.get('subcategory')) {
        // if (searchparams.get('page') !== null) {
        searchparams.set('page', 1);
        setPage(1);
        // }
        const del = [
          'style[]',
          'material[]',
          'vendor_id',
          'color[]',
          'price_from',
          'price_to',
          'brand'
        ];
        del.forEach((d) => searchparams.delete(d));
      }
      // if (searchparams.get("vedor_id")){
      // }
      searchparams.set('subcategory[]', selectedsubcategory);
      searchparams.set('sortBy', sortby);
      shprm['subcategory[]'] = selectedsubcategory;
      window.history.replaceState(null, '', `?${searchparams.toString()}`);
      getProductDetails(page, shprm);
    }
    // setShprm(shprm)
  }, [selectedsubcategory]);
  return (
    <>
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snack}
          onClose={() => setSnack(false)}
          message={snackMessage}
          autoHideDuration={2000}
        />
        <Header searchparams={searchparams} />
        <div style={{ backgroundColor: 'white' }}>
          <div className={style.mainContainer}>
            {brandname && (
              <div className={style.brand1}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <div style={{ width: '30%' }}>
                    <img
                      className={`${style.design} img-fluid`}
                      src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+48098563.png"
                      alt=""
                    />
                  </div>
                  <div className={style.brandbanner1}>
                    <div className={style.brandLogo1}>
                      <img className={style.img} src={brandlogo} alt=""></img>
                    </div>
                  </div>
                </div>
                <div>
                  <img className={style.brandimage1} src={brandimage} alt="" />
                </div>
              </div>
            )}
            {dimension[0] > 600 ? (
              <>
                {params.category === 'Shop' ? (
                  <div className={style.masonryContainer}>
                    <div className={classNames(style.roomType, classes.displayrow)}>
                      <div
                        className={classNames(classes.displayrow, 'mr-3')}
                        style={{ cursor: 'pointer', color: 'gray' }}>
                        {params.category}
                      </div>
                      <div className={classes.displayrow}>
                        <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                        <div style={{ cursor: 'pointer', color: 'gray' }}>{params?.tag}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={style.masonryContainer}>
                    <div className={classNames(style.roomType, classes.displayrow)}>
                      <div
                        className={classNames(classes.imgtag, 'mr-3')}
                        style={{ cursor: 'pointer', color: 'gray' }}
                        onClick={() => {
                          history.push({
                            pathname: `/shop`
                          });
                        }}>
                        Shop
                      </div>
                      {/* {params?.styletype && params?.styletype !== 'false' ? (
                        <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}
                          onClick={() => {
                            history.replace({
                              pathname: `/shopbystyle/room/true/${params.styletype}`
                            });
                          }}>
                          <img
                            src={arrow}
                            className={classNames(classes.imgtag, 'mr-3')}
                            style={{ color: 'gray' }}
                          />
                          <div>{params?.styletype}</div>
                        </div>
                      ) : null} */}
                      {searchparams.get('room_type') && (
                        <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}
                          onClick={() => history.goBack()}>
                          <img
                            src={arrow}
                            className={classNames(classes.imgtag, 'mr-3')}
                            style={{ color: 'gray' }}
                          />
                          {searchparams.get('room_type')}
                        </div>
                      )}
                      {searchparams.get('search') && (
                        <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}
                          onClick={() => history.goBack()}>
                          <img
                            src={arrow}
                            className={classNames(classes.imgtag, 'mr-3')}
                            style={{ color: 'gray' }}
                          />
                          Search
                        </div>
                      )}
                      {searchparams.get('brand') && (
                        <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}
                          onClick={() => history.goBack()}>
                          <img
                            src={arrow}
                            className={classNames(classes.imgtag, 'mr-3')}
                            style={{ color: 'gray' }}
                          />
                          {searchparams.get('brand')}
                        </div>
                      )}
                      {/* {shopType && shop_type.find((item) => item.id == shopType)?.type} */}
                      {searchparams.get('category') ? (
                        <div
                          className={classNames(classes.displayrow, 'mr-3')}
                          style={{ cursor: 'pointer', color: 'gray' }}>
                          <img
                            src={arrow}
                            className={classNames(classes.imgtag, 'mr-3')}
                            style={{ color: 'gray' }}
                          />
                          <div>{searchparams.get('category')}</div>
                        </div>
                      ) : null}
                      {params?.brand ? (
                        <div
                          className={classes.displayrow}
                          style={{ cursor: 'pointer', color: 'gray' }}>
                          <img
                            src={arrow}
                            className={classNames(classes.imgtag, 'mr-3')}
                            style={{ color: 'gray' }}
                          />
                          <div>{params?.brand}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <ResponsiveMasonry
                        className={style.mesonryRooms}
                        style={{ margin: '0px auto', width: '100%' }}
                        columnsCountBreakPoints={{
                          450: 2,
                          500: 3,
                          750: 4,
                          900: 5,
                          1000: 6
                        }}>
                        <Masonry>
                          {subcategory?.map((category, i) => (
                            <div style={{ position: 'relative', height: '240px' }} key={i}>
                              <div
                                className="d-flex flex-column align-items-center justify-content-evenly"
                                style={{
                                  cursor: 'pointer',
                                  // marginBottom: '38px',
                                  textAlign: 'center'
                                }}
                                onClick={() => {
                                  setselectedsubcategory(category.title);
                                  productListViewRef.current.scrollIntoView({
                                    behavior: 'smooth'
                                  });
                                }}>
                                <div className={style.designIconContainer}>
                                  <img
                                    className={style.designIcon}
                                    src={category.image}
                                    alt="room"
                                    style={{
                                      width: '90%',
                                      border:
                                        category.title == selectedsubcategory
                                          ? '1px solid #DEDEDE'
                                          : 'none'
                                    }}
                                  />
                                </div>
                                <p className={classnames('mt-2', style.dataName)}>
                                  {category.title}
                                </p>
                              </div>
                            </div>
                          ))}
                        </Masonry>
                      </ResponsiveMasonry>
                    </div>
                  </div>
                )}
                <div className="row d-flex">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12 pl-0">
                    {/* <div className="row" style={{ visibility: 'hidden' }}>
                      <div className="col-12">
                        <p
                          className="mt-3"
                          style={{
                            cursor: 'pointer',
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 'bold'
                          }}>
                          All Products &nbsp;
                        </p>
                      </div>
                    </div> */}
                    <div
                      style={{
                        // padding: '10px',
                        textAlign: 'center',
                        // display: 'flex',
                        // flexDirection: 'row',
                        justifyContent: 'space-between',
                        // height: '118px',
                        fontFamily: 'Quicksand',
                        fontSize: '20px',
                        textAlign: 'left',
                        fontWeight: 'bold'
                      }}>
                      <p className="mt-3 ">
                        <img
                          src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Group+3000.png"
                          className={style.toggleImg}
                        />
                        Filters
                      </p>
                      <div style={{ height: '53px' }}></div>
                    </div>
                    <ProductFilter
                      filterApplied={filterOption}
                      setFilterApplied={setFilterOption}
                      likesChecked={likesChecked}
                      setLikesChecked={setLikesChecked}
                      searchparams={searchparams}
                      getProductDetails={getProductDetails}
                    />
                  </div>

                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 pr-0">
                    <div className="row">
                      <div className="col-9" ref={productListViewRef}>
                        <p
                          className="mt-3"
                          style={{
                            cursor: 'pointer',
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 'bold'
                          }}>
                          All Products &nbsp;
                          {page === totalPages
                            ? totalRecords + '/' + totalRecords
                            : productsList?.length === 0
                            ? '0'
                            : limit * page + '/' + totalRecords}
                        </p>
                      </div>
                      <div
                        className="col-3 justify-content-end "
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={sortby}
                          onChange={handleChangeInput}
                          displayEmpty
                          input={<BootstrapInput />}
                          className={style1.selectSort}>
                          <MenuItem value=""> Sort by </MenuItem>
                          <MenuItem value={'price:desc'}>High to Low</MenuItem>
                          <MenuItem value={'price:asc'}>Low to High</MenuItem>
                          <MenuItem value={'view_count:desc'}>Popularity</MenuItem>
                        </Select>
                      </div>
                    </div>

                    <HeadTags
                      searchparams={searchparams}
                      getProductDetails={getProductDetails}
                      headkeys={headkeys}
                      setHeadkeys={setHeadkeys}
                      filterApplied={filterOption}
                      setFilterApplied={setFilterOption}
                    />
                    {prodLoading && (
                      <div>
                        <Loader
                          type="ThreeDots"
                          visible={true}
                          color="#000000"
                          height={50}
                          width={50}
                        />
                      </div>
                    )}
                    {!prodLoading && renderColumn()}
                    <ReactPaginate
                      forcePage={0}
                      onPageChange={({ selected }) => {
                        setPage(selected + 1);
                        window.scrollTo(0, 0);
                      }}
                      pageCount={totalPages}
                      breakLabel="..."
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={2}
                      containerClassName={
                        ' justify-content-end pagination react-paginate pagination-sm p-1 mt-1'
                      }
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                      previousLabel={productsList?.length > 0 && '<'}
                      nextLabel={productsList?.length > 0 && '>'}
                      breakClassName={'break-me'}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={style.nav_container} style={{ paddingTop: '115px' }}>
                  <div
                    className="row"
                    style={{
                      // backgroundColor: '#F9FEFF',
                      padding: '8px 0px'
                      // marginBottom: '16px'
                    }}>
                    {/* <p
                      className="col-4 mb-0 d-flex justify-content-start"
                      style={{ fontWeight: 600, cursor: 'pointer' }}
                      onClick={() => setDrawer(true)}>
                      <img
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Group+3000.png"
                        className={style.toggleImg}
                      />
                      Refine
                    </p> */}
                    <div className="col-6">
                      <p className=" col-7 p-0 d-flex justify-content-start">
                        <div className={classNames(style.roomType, classes.displayrow)}>
                          <div
                            className={classNames(classes.imgtag, 'mr-1')}
                            style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}
                            onClick={() => {
                              history.push({
                                pathname: `/shop`
                              });
                            }}>
                            Shop
                          </div>
                          {/* {params?.styletype && params?.styletype !== 'false' ? (
                          <div
                            className={classes.displayrow}
                            onClick={() =>
                              history.replace({
                                pathname: `/shopbystyle/room/true/${params.styletype}`
                              })
                            }>
                            <img
                              src={arrow}
                              className={classNames(classes.imgtag, 'mr-3')}
                              style={{ color: 'gray' }}
                            />
                            <div>{params?.styletype}</div>
                          </div>
                        ) : null} */}
                          {searchparams.get('room_type') && (
                            <div className={classes.displayrow}>
                              <img
                                src={arrow}
                                className={classNames(classes.imgtag, 'mr-1')}
                                style={{ color: 'gray' }}
                              />
                              <div
                                className={classNames(classes.displayrow, 'mr-1')}
                                style={{ cursor: 'pointer', color: 'gray', fontSize: '12px', whiteSpace:'nowrap',overflow:'hidden', textOverflow:"ellipsis",maxWidth:"70px" }}
                                onClick={() => history.goBack()}>
                                {searchparams.get('room_type')}
                              </div>
                            </div>
                          )}
                          {searchparams.get('category') && searchparams.get('brand') ? (
                            <div className={classes.displayrow}>
                              <img
                                src={arrow}
                                className={classNames(classes.imgtag, 'mr-1')}
                                style={{ color: 'gray' }}
                              />
                              <div
                                className={classNames(classes.displayrow, 'mr-1')}
                                style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}
                                onClick={() => history.goBack()}>
                                ...
                              </div>
                            </div>
                          ) : (
                            <div className={classes.displayrow}>
                              <img
                                src={arrow}
                                className={classNames(classes.imgtag, 'mr-1')}
                                style={{ color: 'gray' }}
                              />
                              <div style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}>
                                {searchparams.get('category')}
                              </div>
                            </div>
                          )}
                          {searchparams.get('brand') && (
                            <div className={classes.displayrow}>
                              <img
                                src={arrow}
                                className={classNames(classes.imgtag, 'mr-1')}
                                style={{ color: 'gray' }}
                              />
                              <div
                                className={classNames(classes.displayrow, 'mr-1')}
                                style={{ cursor: 'pointer', color: 'gray', fontSize: '12px' }}
                                onClick={() => history.goBack()}>
                                {searchparams.get('brand')}
                              </div>
                            </div>
                          )}
                          {/* {params?.brand ? (
                          <div className={classes.displayrow}>
                            <img
                              src={arrow}
                              className={classNames(classes.imgtag, 'mr-3')}
                              style={{ color: 'gray' }}
                            />
                            <div>{params?.brand}</div>
                          </div>
                        ) : null} */}
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                    <div className={style.typesSectionwom}>
                      {/* <Select
                          value={shopType}
                          onChange={handleChangeshoptype}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          className={style.selectStyle}
                          xs={{ '& .MuiSvgIcon-root': { color: 'white' } }}>
                          {shop_type &&
                            shop_type.map((item, index) => {
                              return (
                                <MenuItem key={`shoptype${index}`} value={item.id}>
                                  {item.type}
                                </MenuItem>
                              );
                            })}
                        </Select> */}

                      <div className="row">
                        <ResponsiveMasonry
                          className={style.mesonryRooms}
                          style={{ margin: '0px auto', width: '100%' }}
                          columnsCountBreakPoints={{
                            450: 3,
                            500: 3,
                            750: 4,
                            900: 5,
                            1000: 6
                          }}>
                          <Masonry>
                            {subcategory.map((category, i) => (
                              <div style={{ position: 'relative' }} key={i}>
                                <div
                                  className="d-flex flex-column align-items-center justify-content-evenly"
                                  style={{
                                    position: 'relative',
                                    height: '180px',
                                    cursor: 'pointer',
                                    marginBottom: dimension[0] > 600 ? '38px' : '10px',
                                    textAlign: 'center'
                                  }}
                                  onClick={() => {
                                    setselectedsubcategory(category.title);
                                    productListViewRef.current.scrollIntoView({
                                      behavior: 'smooth'
                                    });
                                  }}>
                                  <div className={style.designIconContainer}>
                                    <img
                                      className={style.designIcon}
                                      src={category.image}
                                      alt="room"
                                      style={{
                                        width: '100%',
                                        border:
                                          category.title == selectedsubcategory
                                            ? '1px solid #DEDEDE'
                                            : 'none'
                                      }}
                                    />
                                  </div>
                                  <p className={classnames('mt-2', style.dataName)}>
                                    {category.title}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </Masonry>
                        </ResponsiveMasonry>
                      </div>
                    </div>
                  </div>
                </div>

                <Drawer
                  anchor={'left'}
                  open={drawer}
                  docked={false}
                  onClose={() => setDrawer(false)}>
                  <div className={classnames(style.drawerContainer, ' mb-2 ml-2')}>
                    <>
                      <div className="row mt-3 mb-3 w-100">
                        <h5
                          style={{
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            fontWeight: 'bold'
                          }}>
                          Filters
                        </h5>
                        <CloseOutlined
                          onClick={() => setDrawer(false)}
                          style={{ marginLeft: 'auto', marginRight: 0 }}
                        />
                      </div>

                      <ProductFilter
                        filterApplied={filterOption}
                        setFilterApplied={setFilterOption}
                        likesChecked={likesChecked}
                        setLikesChecked={setLikesChecked}
                        setDrawer={setDrawer}
                        searchparams={searchparams}
                        getProductDetails={getProductDetails}
                      />
                    </>
                  </div>
                </Drawer>
                <div className="row">
                  <div
                    className="col-6"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <p
                      className=" d-flex justify-content-start"
                      style={{ fontWeight: 600, cursor: 'pointer' }}
                      onClick={() => setDrawer(true)}>
                      <img
                        src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Group+3000.png"
                        className={style.toggleImg}
                      />
                      Filter
                    </p>
                  </div>
                  <div className="col-6 justify-content-end ">
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={sortby}
                      onChange={handleChangeInput}
                      displayEmpty
                      input={<BootstrapInput />}
                      className={style1.selectSort}>
                      <MenuItem value=""> Sort by</MenuItem>
                      <MenuItem value={'price:desc'}>High to Low</MenuItem>
                      <MenuItem value={'price:asc'}>Low to High</MenuItem>
                      <MenuItem value={'view_count:desc'}>Popularity</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-xs-12">
                    <div className="row" style={{ marginTop: dimension[0] < 600 ? '25px' : '' }}>
                      <div className="col-6" ref={productListViewRef}>
                        <p
                          className={classnames(style.product, 'mt-1')}
                          style={{
                            cursor: 'pointer',
                            fontFamily: 'Quicksand',
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            paddingTop: '10px'
                          }}>
                          All Products&nbsp;
                          {page === totalPages
                            ? totalRecords + '/' + totalRecords
                            : productsList?.length === 0
                            ? '0'
                            : limit * page + '/' + totalRecords}
                        </p>
                      </div>
                      {/* <div className="col-6 justify-content-end ">
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={sortby}
                          onChange={handleChangeInput}
                          displayEmpty
                          input={<BootstrapInput />}
                          className={style1.selectSort}>
                          <MenuItem value=""> Sort by</MenuItem>
                          <MenuItem value={'price:desc'}>High to Low</MenuItem>
                          <MenuItem value={'price:asc'}>Low to High</MenuItem>
                          <MenuItem value={'view_count:desc'}>Popularity</MenuItem>
                        </Select>
                      </div> */}
                    </div>
                    <div className="container-fluid ml-lg-3 pl-lg-2 ml-md-3 pl-md-2">
                      {prodLoading && (
                        <div>
                          <Loader
                            type="ThreeDots"
                            visible={true}
                            color="#000000"
                            height={50}
                            width={50}
                          />
                        </div>
                      )}
                      <HeadTags
                        searchparams={searchparams}
                        getProductDetails={getProductDetails}
                        headkeys={headkeys}
                        setHeadkeys={setHeadkeys}
                        filterApplied={filterOption}
                        setFilterApplied={setFilterOption}
                      />

                      {!prodLoading && renderColumn()}
                      <ReactPaginate
                        forcePage={0}
                        onPageChange={({ selected }) => {
                          setPage(selected + 1);
                          window.scrollTo(0, 0);
                        }}
                        pageCount={totalPages}
                        breakLabel="..."
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        containerClassName={
                          ' justify-content-center pagination react-paginate pagination-sm p-1 mt-1'
                        }
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        previousLabel={'<'}
                        nextLabel={'>'}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <Footer />
        </div>
      </>
      {modalOpen && <QRScanner id={productIds} modalOpen={modalOpen} setModalOpen={setModalOpen} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(MainProductList));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);
  return screenSize;
}
