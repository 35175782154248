import axios from 'axios';

export async function Tags() {
  var temp = JSON.parse(localStorage.getItem('tags')); 
  if (temp === null || temp.expiry < new Date().getTime() ) {
    // console.log("if")
    const data = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/v1/tags`);
    const tags = data.data.data;

    const category_id = tags.find((tag) => tag.name === 'category');
    var cat_id = category_id._id;
    var categories = tags.filter((tag) => tag.parent_id === cat_id);
    const cats = [];
    // var category_dict = {}
    categories.map((category, index) => {
      var temp = {};
      temp['name'] = category.name;
      temp['id'] = category._id;
      temp['image'] = category.image;
      cats.push(temp);
    });
    cats.map((cat, index) => {
      const subcats = tags.filter((subcat) => subcat.parent_id === cat.id);
      var sct = [];
      subcats.map((sc) => {
        var sub_temp = {};
        sub_temp['name'] = sc.name;
        sub_temp['image'] = sc.image;
        sct.push(sub_temp);
      });
      // console.log(sct);
      cats[index]['subcat'] = sct;
    });
    //   console.log(cats);
    const tagwithexpiry = {
       "tags" : cats,
       // set expiry for three days
       "expiry" : new Date().getTime() + 259200000
    }
    localStorage.setItem('tags', JSON.stringify(tagwithexpiry));
    return cats;
  } else {
    // var cats = JSON.parse(temp);
    // console.log("else")
    return temp.tags;
  }
}
