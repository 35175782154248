import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import style from './Shopdetails.module.css';
import style1 from './Shop.module.css';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import arrow from '../../assets/images/Iconionic-ios.png';
import Paper from '@material-ui/core/Paper';
import SliderContainer from 'react-slick';
import sliderArrowPrev from '../../assets/images/sliderArrowPrev.png';
import sliderArrowNext from '../../assets/images/sliderArrowNext.png';
import { Image } from 'semantic-ui-react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import BestSeller from './BestSeller';
import shutterstock_72 from '../../assets/images/shop/shutterstock_72.png';
import { Grid } from '@material-ui/core';
import { categoryAll, rooms } from './constant';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { openin } from '../../utils/utils';
import ConsoleHelper from '../../utils/ConsoleHelper';
import { storeCartCount } from '../../store/actions/sampleaction';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tags } from '../../components/Tags/tags';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: '50px',
      marginBottom: '50px'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    imgtag: {
      marginLeft: '15px',
      width: '7px',
      height: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '7px',
        height: '11px',
        marginLeft: '11px'
      }
    },
    paper: {
      borderRadius: '10px',
      maxHeight: '460px',
      height: '330px',
      width: '90%',
      border: '0.5px solid rgba(112,112,112,0.4 )',
      overflow: 'hidden',
      margin: '0px auto',
      [theme.breakpoints.down('sm')]: {
        height: '300px'
      }
    },
    imagefit: {
      objectFit: 'contain'
    },
    displayrow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline'
    },
    paper1: {
      width: '100%',
      border: 'none',
      margin: '0px auto',
      padding: '0px 12px',
      position: 'relative',
      maxWidth: '100%',
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
      maxHeight: '460px',
      borderRadius: '7px',
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        padding: '43px 11px'
      }
    }
  })
);
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowPrev} alt="prevArrow" {...props} />
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <img src={sliderArrowNext} alt="nextArrow" {...props} />
);

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,

  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        centerMode: true
      }
    }
  ]
};

const shop_type = [
  { id: 1, value: 'living room', type: 'Living Room' },
  { id: 2, value: 'dining room', type: 'Dining Room' },
  { id: 3, value: 'bed room', type: 'Bed Room' },
  { id: 4, value: 'kids room', type: 'Kids Room' },
  { id: 5, value: 'puja room', type: 'Puja Room' },
  { id: 6, value: 'foyer', type: 'Foyer' },
  { id: 7, value: 'balcony', type: 'Balcony' },
  {
    id: 8,
    value: 'study_entertainment_room',
    type: 'Study Entertainment Room'
  }
];
const shop_style = [
  { id: 1, value: 'South Indian', type: 'South Indian' },
  { id: 2, value: 'North Indian', type: 'North Indian' },
  { id: 3, value: 'Classic Indian', type: 'Classic Indian' },
  { id: 4, value: 'Minimalistic', type: 'Minimalistic' },
  { id: 5, value: 'Contemporary', type: 'Contemporary' },
  { id: 6, value: 'Mid Century', type: 'Mid Century' },
  { id: 7, value: 'Eclectic', type: 'Eclectic' },
  { id: 8, value: 'Art Deco', type: 'Art Deco' },
  { id: 9, value: 'Pop Art', type: 'Pop Art' },
  { id: 10, value: 'Industrial', type: 'Industrial' },
  { id: 11, value: 'Rustic', type: 'Rustic' },
  { id: 12, value: 'Gothic', type: 'Gothic' },
  { id: 13, value: 'Boho', type: 'Boho' },
  { id: 14, value: 'Farm House', type: 'Farm House' },
  { id: 15, value: 'Coastal', type: 'Coastal' }
];

const ShopDetails = ({ auth, deviceType, history }) => {
  const params = useParams();
  var filterRooms = [];
  var roomsFilter = [];
  var styles1;
  if (params) {
    filterRooms = params.roomtype?.title;
    roomsFilter = filterRooms?.split(',');
    styles1 = filterRooms
      ?.replace('_', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }
  const dimension = useMediaQuery();
  const classes = useStyles();
  const historys = useHistory();

  const [numberOfItems, setnumberOfItems] = useState(10);
  const [shopType, setShopType] = React.useState(1);
  const [shopStyle, setShopStyle] = React.useState(1);
  const [categories, setCategories] = useState([...categoryAll]);
  const [showMore, setShowMore] = useState(false);
  const [filteredMoodBoards, setFilteredMoodBoards] = useState([]);
  const [page, setPage] = useState(1);
  const [filterOption, setFilterOption] = useState({
    tags: 'Best Seller',
    room_type: []
  });
  const [searchInput] = useState('');
  const [tempProductsList, setTempProductsList] = useState([]);
  const [prodLoading, setProdLoading] = useState(false);
  const [cardlist, setcardlist] = useState([]);
  const [bannerlist, setbannerlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vendorsList, setVendorsList] = useState([]);
  const getcat = async () => {
    var cats = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/filterOptions?room_type=${params?.roomtype}`)
    var category_array = []
    var cat_data = cats.data.category
    const tags = await(Tags())
    cat_data.map((cat, index)=>{
        var temp = {}
        temp["title"] = cat
        var temp_tag = tags.find((tag)=> tag.name == cat)
        if(temp_tag != undefined){
          temp["image"] = temp_tag.image
        }
        category_array.push(temp)
      }) 
    setCategories(category_array)
  } 
  useEffect(()=>{
    getcat()
  },[])

  let filterApplied = useRef(false);
  let prevPage = useRef(0);
  const nextpage = async (category) => {
    history.push({pathname:"/mainproduct",
  state:{"room_type":params?.roomtype,"category":category}})
    // srchprms.set("category",category)
  }
  const handleChangestyle = event => {
    setShopStyle(event.target.value);
    setShopType(event.target.value);
    history.push({
      pathname: `/shopbystyle/room/${params.stylestatus}/${
        shop_style?.find(item => item.id == event.target.value)?.type
      }`
    });

    if (filterOption.hasOwnProperty('style')) {
      let index = filterOption.style.findIndex(st => st === event.target.value);
      if (index < 0) {
        setFilterOption({
          ...filterOption,
          style: [event.target.value]
        });
      } else {
        filterOption.style = filterOption.style.filter(st => st !== event.target.value);
        setFilterOption({ ...filterOption });
      }
    } else {
      setFilterOption({ ...filterOption, style: [event.target.value] });
    }
  };
  const handleChange = event => {
    setShopType(event.target.value);
    params.styletype !== undefined
      ? history.push({
          pathname: `/shop-details/${
            shop_type?.find(item => item.id == event.target.value)?.type
          }/${params.stylestatus}/${params.styletype}`
        })
      : history.push({
          pathname: `/shop-details/${
            shop_type?.find(item => item.id == event.target.value)?.type
          }/${params.stylestatus}`
        });
  };
  const loadTableData = () => {
    if (page === prevPage.current) {
      setPage(prev1State => prev1State + 1);
    }
  };
  const getProductDetails = async (page, filters, search) => {
    if (filters && filters.hasOwnProperty('brand')) {
      let vendor = vendorsList.filter(ven => ven.name === filters.brand);
      if (vendor.length > 0) {
        filters.vendor_id = vendor[0]._id;
      }
    }
    delete filters.brand;
    setProdLoading(true);
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
      params: { page, ...filters }
    });
    setProdLoading(false);
    if (Object.keys('filters') === 0) {
      let filterRooms = [];
      let filterStyles = [];
      let stylesFilter = [];
      let roomsFilter = [];
      let stylesUpper;
      if (params) {
        filterRooms = params.roomtype;
        filterStyles = params.styles;
        roomsFilter = filterRooms.split(',');
        stylesFilter = filterStyles.split(',');
        stylesUpper = stylesFilter.map(s1 => {
          return s1
            .replace('_', ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        });
        let styleFilterProducts = res.data.results.filter(prod => stylesUpper.includes(prod.style));
        let roomFilterProducts = res.data.result.filter(prod =>
          prod.room_type.filter(room => roomsFilter.includes(room))
        );
        let styleAppliedProds = [...styleFilterProducts, ...roomFilterProducts];
        styleAppliedProds = styleAppliedProds.filter(
          (v, i, a) => a.findIndex(t => t._id === v._id) === i
        );
      }
    }
    setTempProductsList([...res.data.results]);
    loadTableData();
    if (filterApplied.current && page !== 1) {
      return;
    }
    filterApplied.current = false;
  };
  const handleMoodBoardProducts = moodBoardId => {
    historys.push(`/explore-detail/${moodBoardId}`);
  };
  const uniqueProducts = data1 => {
    let productIds = [];
    data1.products &&
      data1.products.map(prodArray => {
        prodArray.map(product => {
          productIds.push(product.product_id);
        });
      });
    let uniqueProductIds = [...new Set(productIds)];
    return uniqueProductIds.length;
  };
  const getbanners = async () => {
    const res = await axios.get(`${process.env.REACT_APP_CMS_URL}/api/v1/user/banner`);
    if (res.status == 200 && res?.data?.data?.length > 0) {
      let obj = res.data.data.find(item => item.room_type == params.roomtype);
      obj
        ? setcardlist(obj.card_list)
        : setcardlist(res.data.data.find(item => item.room_type == 'Living Room')?.card_list);
    } else {
      setcardlist([]);
    }
  };
  const getgenricbanner = async () => {
    const res = await axios.get(`${process.env.REACT_APP_CMS_URL}/api/v1/user/genricbanner`);
    if (res.status == 200 && res?.data?.data?.length > 0) {
      let responsedata = res.data.data;
      responsedata.sort(function(a, b) {
        if (a.bannername < b.bannername) {
          return -1;
        }
        if (a.bannername > b.bannername) {
          return 1;
        }
        return 0;
      });
      setbannerlist([...responsedata]);
    } else {
      setbannerlist([]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (shopType) {
    //   let data = [];
    //   data = rooms.find(item => item.id == shopType)?.category;
    //   setCategories(data);
    // }
  }, [shopType]);

  useEffect(() => {
    let shoptypeid =
      params.roomtype == 'Study Entertainment Room'
        ? 8
        : shop_type.find(item => item.type === params.roomtype)?.id;
    setShopType(shoptypeid);
    setShopStyle(shop_style.find(item => item.type === params.style)?.id);
  }, [shopStyle, shopStyle]);

  useEffect(() => {
    getgenricbanner();
    getbanners();
    let shoptypeid =
      params.roomtype == 'Study Entertainment Room'
        ? 8
        : shop_type.find(item => item.type === params.roomtype)?.id;
    setShopType(shoptypeid);
    setShopStyle(shop_style.find(item => item.type === params.style)?.id);

    let data = [];
    // if (params?.roomtype) {
    //   data = rooms.find(item => item.title === params.roomtype)?.category;
    //   setCategories(data ? data : []);
    // }
    params?.styletype && setFilterOption({ ...filterOption, style: [params.styletype] });
    fetchVendorData();
  }, []);
  const fetchVendorData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendor/list`);
      if (res.data.length !== 0) {
        setVendorsList(res.data);
      }
    } catch (err) {
      ConsoleHelper('error', err);
    }
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        let result = [];
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/moodBoards/list`);
        result = res.data;
        for (let i = 0; i < result.length; i++) {
          let temprooms = [];
          if (result[i].rooms.length > 0) {
            for (let j = 0; j < result[i].rooms.length; j++) {
              temprooms.push(result[i].rooms[j].toLowerCase());
            }
          }
          result[i].rooms = temprooms;
        }
        for (let i = 0; i < result.length; i++) {
          let tempstyles = [];
          if (result[i].rooms.length > 0) {
            for (let j = 0; j < result[i].styles.length; j++) {
              tempstyles.push(result[i].styles[j].toLowerCase());
            }
          }
          result[i].styles = tempstyles;
        }
        const fromShop = history?.location?.pathname;
        const changeShop = shop_type?.find(item => item.id === shopType)?.type;
        const the_arr = fromShop?.split('/');
        const styleFilter = the_arr?.pop();
        if (the_arr[3] === 'true') {
          const filteredArray = result.filter(data =>
            data?.styles.find(style => style === styleFilter.toLowerCase() && data.preview_url)
          );
          setLoading(false);
          setFilteredMoodBoards(filteredArray);
        } else {
          const filter = result.filter(
            data => data.rooms[0] === changeShop.toLowerCase() && data.preview_url
          );
          setLoading(false);
          setFilteredMoodBoards(filter);
        }
      } catch (err) {
        ConsoleHelper('error', err);
      }
    };
    fetchData();
    if (shopType) {
      let room = shop_type.find(item => item.id === shopType).type;
      if (filterOption.hasOwnProperty('room_type')) {
        let index = filterOption.room_type.findIndex(rm => rm === room);
        if (index < 0) {
          setFilterOption({
            ...filterOption,
            room_type: [room]
          });
        } else {
          filterOption.room_type = filterOption.room_type.filter(rm => rm !== room);
          setFilterOption({ ...filterOption });
        }
      } else {
        setFilterOption({ ...filterOption, room_type: [room] });
      }
    }
  }, [shopType]);

  useEffect(() => {
    let filters = { ...filterOption };
    let search = searchInput;
    if (params.brand) {
      if (vendorsList.length > 0) {
        filters.brand = params.brand;
        getProductDetails(page, filters, search);
      }
    } else getProductDetails(page, filters, search);
  }, [page, filterOption, searchInput, vendorsList]);

  return (
    <div>
      <Header />
      <div className={style.typesSection}>
        {params?.stylestatus == 'true' ? (
          <>
            {/* <Select
              value={shopStyle}
              onChange={handleChangestyle}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={style.selectStyle}
              style={{ marginBottom: '10px' }}
              xs={{ '& .MuiSvgIcon-root': { color: 'white' } }}>
              {shop_style &&
                shop_style.map(item => {
                  return (
                    <MenuItem key={`index${item.id}`} value={item.id}>
                      {item.type}
                    </MenuItem>
                  );
                })}
            </Select> */}
            <div>
              <div className={classNames(style.roomType, classes.displayrow)} >
                <div
                  className={classNames(classes.displayrow)}
                  style={{ cursor: 'pointer', color: 'gray' }}
                  onClick={() =>
                    history.push({
                      pathname: `/shop`
                    })
                  }>
                  Shop
                </div>
                <div className={classes.displayrow}>
                  <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                  <div>{params?.style}</div>
                </div>
                {params?.brand ? (
                  <div
                    className={classes.displayrow}
                    onClick={() =>
                      history.replace({
                        pathname: `/shopbybrand/room/${params.brand}`
                      })
                    }>
                    <img
                      src={arrow}
                      className={classNames(classes.imgtag, 'mr-3')}
                      style={{ color: 'gray' }}
                    />
                    <div>{params?.brand}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            {/* <Select
              value={shopType}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={style.selectStyle}
              xs={{ '& .MuiSvgIcon-root': { color: 'white' } }}>
              {shop_type &&
                shop_type.map(item => {
                  return (
                    <MenuItem key={`index${item.id}`} value={item.id}>
                      {item.type}
                    </MenuItem>
                  );
                })}
            </Select> */}
            <div>
              <div
                className={classNames(
                  style.roomType,
                  classes.displayrow,
                  dimension[0] < 500 ? 'mt-3 mb-3' : ''
                )}
                style={{paddingLeft:"20px"}}>
                <div
                  className={classNames(classes.displayrow)}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    history.push({
                      pathname: `/shop`
                    })
                  }>
                  Shop
                </div>
                {params?.styletype && (
                  <div className={classes.displayrow}>
                    <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.replace({
                          pathname: `/shopbystyle/${
                            shop_type?.find(item => item.id === shopType)?.type
                          }/true/${params.styletype}`
                        });
                      }}>
                      {params.styletype}
                    </div>
                  </div>
                )}
                <div className={classes.displayrow}>
                  <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                  <div>{shop_type?.find(item => item.id === shopType)?.type}</div>
                </div>
                {params?.brand && (
                  <div className={classes.displayrow}>
                    <img src={arrow} className={classNames(classes.imgtag, 'mr-3')} />
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.replace({
                          pathname: `/shop`
                        });
                      }}>
                      {params.brand}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="row">
          <ResponsiveMasonry
            className={style.mesonryRooms}
            style={{ margin: '0px auto', width: '100%' , marginLeft:'20px', marginRight:'20px' }}
            columnsCountBreakPoints={{ 250: 3, 750: 3, 900: 6 }}>
            <Masonry>
              {params?.stylestatus == 'false'
                ? categories.slice(0, numberOfItems).map((category, i) => (
                    <div style={{ position: 'relative' }} key={i}>
                      <div
                        className="d-flex flex-column align-items-center justify-content-evenly"
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                        // onclick={()=>{
                        //   history.push('/shop')
                        // }}
                        onClick={() => {
                          params?.styletype
                            ? nextpage(category.title)
                            : params.brand
                            ? nextpage(category.title)
                            : nextpage(category.title);
                        }}>
                        {/* > */}
                        <div className={style.designIconContainer}>
                          <img
                            className={style.designIcon}
                            src={category.image}
                            alt="room"
                            style={{ width: '90%' }}
                          />
                        </div>
                        <p className="mt-2" style={{ position: 'relative', width: '120px' }}>
                          {category.title}
                        </p>
                      </div>
                    </div>
                  ))
                : rooms.map((room, i) => (
                    <div style={{ position: 'relative' }} key={i}>
                      <div
                        className="d-flex flex-column align-items-center justify-content-evenly"
                        style={{ cursor: 'pointer', marginBottom: '38px' }}
                        onClick={() => {
                          if (filterOption.hasOwnProperty('room_type')) {
                            let index = filterOption.room_type.findIndex(rm => rm === room.title);
                            if (index < 0) {
                              setFilterOption({
                                ...filterOption,
                                room_type: [...filterOption.room_type, room.title]
                              });
                            } else {
                              filterOption.room_type = filterOption.room_type.filter(
                                rm => rm !== room.title
                              );
                              setFilterOption({ ...filterOption });
                            }
                          } else {
                            setFilterOption({
                              ...filterOption,
                              room_type: [room.title]
                            });
                          }
                          params.styletype !== undefined
                            ? history.replace({
                                pathname: `/shop-details/${room.title}/false/${params.styletype}`
                              })
                            : history.replace({
                                pathname: `/shop-details/${room.title}/false`
                              });
                        }}>
                        <div className={style.designIconContainer1}>
                          <img
                            className={style.designIcon1}
                            src={room.image}
                            alt="room"
                            style={{ width: '90%' }}
                          />
                        </div>
                        <p className="mt-2">{room.title}</p>
                      </div>
                    </div>
                  ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
        {categories.length > 10 && (
          <div>
            <h6
              className="mb-2 "
              style={{
                cursor: 'pointer',
                fontFamily: 'Quicksand',
                float: 'right'
              }}
              onClick={() => {
                showMore ? setnumberOfItems(10) : setnumberOfItems(categories.length);
                setShowMore(!showMore);
              }}>
              <b>{showMore ? 'Show less...' : 'Show more...'}</b>
            </h6>
          </div>
        )}
      </div>
      <div className={style.bestSelller}>
        <BestSeller
          loading={prodLoading}
          productsList={tempProductsList}
          auth={auth}
          roomtype={shop_type?.find(item => item.id === shopType)?.type}
          page={page}
          storeCartCount={storeCartCount}
          tag={'BEST SELLER'}
          loadTableData={() => loadTableData()}
          dimension={dimension}
        />
      </div>
      {/* <div
        className={style.getLookSection}
        onClick={() => bannerlist[3]?.routing_url && openin(bannerlist[3].routing_url)}>
        <div className={style.lookFirst}>
          {dimension[0] > 500 ? (
            <img
              src={
                bannerlist[3]?.img_url
                  ? bannerlist[3].img_url
                  : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/shutterstock_1510321364.png'
              }
              className={style.leftBannerImg}
            />
          ) : (
            <img
              src={
                bannerlist[3]?.img_url
                  ? bannerlist[3].img_url
                  : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/shutterstock_15103213641.png'
              }
              className={style.leftBannerImg}
            />
          )}
        </div>
        <div className={style.lookLast}>
          <img
            src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Mask+Group+20.png'}
            className={style.rightBannerImg}
          />

          <h3 className={style.look}>
            {bannerlist[3]?.content ? bannerlist[3].content : ' Get this look for  less than 20K'}
          </h3>
        </div>
      </div> */}
      <div className={style.bestSelller}>
        <BestSeller
          loading={prodLoading}
          productsList={tempProductsList}
          auth={auth}
          dimension={dimension}
          tag={'MUST HAVE'}
          storeCartCount={storeCartCount}
          title={`MUST HAVE IN YOUR ${
            shop_type.find(item => item.id === shopType)?.type
              ? shop_type.find(item => item.id === shopType).type
              : 'LIVING ROOM'
          }`}
        />
      </div>
      <div className={style.bestSelller}>
        {dimension[0] > 500 ? (
          <Grid container style={{ marginTop: '84px' }}>
            {cardlist?.length > 0 &&
              cardlist.map(item => {
                return (
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Paper
                      className={classes.paper1}
                      onClick={() =>
                        history.push({
                          pathname: `/productlist/1/Shop/${item.card_price_Type}`
                        })
                      }>
                      <div className={style1.bestBuy}>
                        <Image
                          style={{
                            maxHeight: '449px',
                            height: '350px',
                            borderRadius: '7px'
                          }}
                          className="imagefitinsizes"
                          src={item?.card_img_url ? item.card_img_url : shutterstock_72}
                        />
                        <div className={style1.bestBorder} style={{ background: '#f2bf60' }}>
                          {item.card_furniture_Type}
                          <br />
                          {item.card_price_Type}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
        ) : (
          cardlist?.length > 0 &&
          cardlist.map(item => {
            return (
              <SliderContainer {...settings}>
                <>
                  <Paper className={classes.paper1}>
                    <div className={style1.bestBuy}>
                      <Image
                        style={{ maxHeight: '449px', borderRadius: '7px' }}
                        className="imagefitinsizes"
                        src={item?.card_img_url ? item.card_img_url : shutterstock_72}
                      />
                      <div className={style1.bestBorder} style={{ background: '#f2bf60' }}>
                        {item.card_furniture_Type}
                        <br />
                        {item.card_price_Type}
                      </div>
                    </div>
                  </Paper>
                </>
              </SliderContainer>
            );
          })
        )}
      </div>
      <div className={style.modeIdea}>
        <h5 className={style.bestTxt}>More idea and inspiration</h5>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <Loader type="ThreeDots" visible={true} color="#000000" height={50} width={50} />
          </div>
        ) : filteredMoodBoards?.length > 0 ? (
          <div>
            <div>
              <>
                <div className={classnames('row')}>
                  <div className={classnames('col-12 col-md-8')}>
                    <div className={classnames('row h-100')}>
                      <div className={classnames('col-12')} style={{ paddingTop: '30px' }}>
                        <div
                          className={filteredMoodBoards[0] ? style.item : 'd-none'}
                          style={{
                            marginBottom: '15px',
                            height: '100%'
                          }}>
                          <img
                            src={filteredMoodBoards[0]?.preview_url}
                            height={'100%'}
                            width={'100%'}
                            alt=""
                          />
                          <div className={style.content}>
                            <div
                              style={{ cursor: 'pointer' }}
                              className="d-flex justify-content-between w-100 mx-2 mt-1"
                              onClick={() =>
                                auth?.isAuthenticated
                                  ? handleMoodBoardProducts(filteredMoodBoards[0]?._id)
                                  : historys.push(
                                      `/pre-explore-detail/${filteredMoodBoards[0]?._id}`
                                    )
                              }>
                              <div>
                                <span
                                  style={{
                                    opacity: 1,
                                    color: '#ffffff',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    marginLeft: '7px'
                                  }}>
                                  {uniqueProducts(
                                    filteredMoodBoards[0] ? filteredMoodBoards[0] : 0
                                  )}
                                  Products
                                </span>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classnames('col-12 col-md-4')}>
                    <div className={classnames('row')}>
                      <div className={classnames('col-6 col-md-12')} style={{ paddingTop: '30px' }}>
                        <div
                          style={{
                            marginBottom: '15px',
                            height: '100%'
                          }}
                          className={filteredMoodBoards[1] ? style.item : 'd-none'}>
                          <img
                            src={filteredMoodBoards[1]?.preview_url}
                            height={'100%'}
                            width={'100%'}
                            alt=""
                          />
                          <div className={style.content}>
                            <div
                              style={{ cursor: 'pointer' }}
                              className="d-flex justify-content-between w-100 mx-2 mt-1"
                              onClick={() =>
                                auth?.isAuthenticated
                                  ? handleMoodBoardProducts(filteredMoodBoards[1]?._id)
                                  : history.push(
                                      `/pre-explore-detail/${filteredMoodBoards[1]?._id}`
                                    )
                              }>
                              <div>
                                <span
                                  style={{
                                    opacity: 1,
                                    color: '#ffffff',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    marginLeft: '7px'
                                  }}>
                                  {uniqueProducts(
                                    filteredMoodBoards[1] ? filteredMoodBoards[1] : 0
                                  )}
                                  Products
                                </span>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classnames('col-6 col-md-12')} style={{ paddingTop: '30px' }}>
                        <div
                          style={{
                            marginBottom: '15px',
                            height: '100%'
                          }}
                          className={filteredMoodBoards[2] ? style.item : 'd-none'}>
                          <img
                            src={filteredMoodBoards[2]?.preview_url}
                            height={'100%'}
                            width={'100%'}
                            alt=""
                          />
                          <div className={style.content}>
                            <div
                              style={{ cursor: 'pointer' }}
                              className="d-flex justify-content-between w-100 mx-2 mt-1"
                              onClick={() =>
                                auth?.isAuthenticated
                                  ? handleMoodBoardProducts(filteredMoodBoards[2]?._id)
                                  : history.push(
                                      `/pre-explore-detail/${filteredMoodBoards[2]?._id}`
                                    )
                              }>
                              <div>
                                <span
                                  style={{
                                    opacity: 1,
                                    color: '#ffffff',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    marginLeft: '7px'
                                  }}>
                                  {uniqueProducts(
                                    filteredMoodBoards[2] ? filteredMoodBoards[2] : 0
                                  )}
                                  Products
                                </span>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        ) : (
          <div className={style.noProducts}>Product List Not Found.</div>
        )}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    sample: state.sample
  };
};

export default connect(mapStateToProps, { storeCartCount })(withRouter(ShopDetails));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
