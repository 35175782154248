import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './Project.module.css';
import { Modal } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import {
  reset_answers,
  storeMx,
  page,
  page2,
  storeMxB,
  storeTab
} from '../../store/actions/sampleaction';
import customerApiInstance from '../../utils/CustomerApiInstance';
import Loader from 'react-loader-spinner';
import DefaultPreviewImage from '../../assets/images/project_default_preview.jpeg';
import ConsoleHelper from '../../utils/ConsoleHelper';
const rooms = [
  {
    id: 1,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2516_zlmecz.png',
    title: 'Living Room'
  },
  {
    id: 2,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2483_mbimcy.png',
    title: 'Dining Room'
  },
  {
    id: 3,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2474_deqke7.png',
    title: 'Bed Room'
  },
  {
    id: 4,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2946_liznui.png',
    title: 'Kids Room'
  },
  {
    id: 5,
    image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/pujaImg.jpeg',
    title: 'Puja Room'
  },
  {
    id: 6,
    image: 'https://d3izcfql7lesks.cloudfront.net/Path_1983_xi5m4r.png',
    title: 'Foyer'
  },
  {
    id: 7,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2956_hiznv5.png',
    title: 'Balcony'
  },
  {
    id: 8,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2707_noyqsc.png',
    title: 'Study Entertainment Room'
  },
  {
    id: 9,
    image: 'https://d3izcfql7lesks.cloudfront.net/Group_2720_zicljy.png',
    title: 'Others'
  }
];

const Project = ({
  history,
  auth,
  reset_answers,
  storeMx,
  page,
  page2,
  storeMxB,
  questionnaireData,
  storeTab
}) => {
  const dimension = useMediaQuery();
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  const [projectName, setProjectName] = useState();
  const [reviewProjects, setReviewProjects] = useState([]);
  const history1 = useHistory();
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [mxb, setMxb] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState(rooms[0].title);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useMemo(() => {
    const getProjects = async () => {
      try {
        const res = await customerApiInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/project/user`
        );
        let tempProjects = [...res.data];

        // fetch moodboard images for projects if available
        const requests = tempProjects
          .filter(project =>
            ['CONCEPT_BOARD', 'REVIEW_CONCEPT', 'FINALIZE_CONCEPT', 'COMPLETED'].includes(
              project.job
            )
          )
          .map(project => {
            if (
              project.job === 'REVIEW_CONCEPT' &&
              project.customer_feedback != 'COMPLETED' &&
              !project.hasOwnProperty('product_reaction')
            ) {
              return customerApiInstance.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/concepts/${project?._id}`
              );
            } else {
              return customerApiInstance.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/moodBoard/concept/${project?._id}`,
                project.job === 'CONCEPT_BOARD'
                  ? {}
                  : project.job === 'COMPLETED'
                  ? { status: 'FINALIZE_CONCEPT' }
                  : { status: project.job }
              );
            }
          });

        // update project after all images fetched
        Promise.all(requests).then(results => {
          results.map(result => {
            ConsoleHelper(result);
            if (result.data.hasOwnProperty('moodBoards') && Array.isArray(result.data.moodBoards)) {
              const projectId = result.data?.moodBoards[0]?.project_id?._id;
              if (projectId) {
                const projIndex = tempProjects.findIndex(project => project._id === projectId);
                tempProjects[projIndex].preview = result.data?.moodBoards[0]?.preview_url;
                // tempProjects[projIndex].id = projectId;
              }
            } else {
              const projectId = result.data?.moodBoard?.project_id?._id;
              if (projectId) {
                const projIndex = tempProjects.findIndex(project => project._id === projectId);
                tempProjects[projIndex].preview = result.data?.moodBoard?.preview_url;
                // tempProjects[projIndex].id = projectId;
              }
            }
          });

          //adding data to determine projects card UI based on Project stage
          tempProjects.map((projData, index) => {
            tempProjects[index].cardUIData = addProjectData(projData);
          });

          ConsoleHelper(tempProjects);

          setReviewProjects(tempProjects);
          // remove the loading animation
          setProjectsLoading(false);
        });
      } catch (error) {
        ConsoleHelper('error', error);
      }
    };

    getProjects();
  }, []);

  const addProjectData = data => {
    if (!data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') && data.job == 'NEW') {
      return {
        btnText: 'Find my Style',
        btnDisable: false,
        tags: null,
        url:
          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/my_project_assets/find_your_style.png',
        stage: 'MXA'
      };
    }
    if (
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      !data.questionnaire.hasOwnProperty('PACKAGE') &&
      data.job == 'NEW'
    ) {
      return {
        btnText: 'Select Package',
        btnDisable: false,
        tags: null,
        url:
          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/my_project_assets/select_package.png',
        stage: 'MXA2'
      };
    }
    if (
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.hasOwnProperty('PACKAGE') &&
      !data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job == 'NEW'
    ) {
      return {
        btnText: 'Complete room',
        btnDisable: false,
        tags: null,
        url: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/my_project_assets/update_room.png',
        stage: 'MXB'
      };
    }
    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job == 'NEW' &&
      data.designer_allocation == 'UNASSIGNED' &&
      !data.hasOwnProperty('product_reactions')
    ) {
      return {
        btnText: 'Review',
        btnDisable: true,
        tags: null,
        url:
          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/my_project_assets/designer_allocation.png',
        stage: 'NEW_UNASSIGNED'
      };
    }
    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      (data.job == 'NEW' || data.job == 'ONGOING') &&
      (data.designer_allocation == 'REASSIGNED' || data.designer_allocation == 'ASSIGNED') &&
      !data.hasOwnProperty('product_reactions')
    ) {
      return {
        btnText: 'Review',
        btnDisable: false,
        tags: null,
        url:
          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/my_project_assets/designer_in_process.png',
        stage: 'NEW_ASSIGNED'
      };
    }

    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job == 'REVIEW_CONCEPT' &&
      data.hasOwnProperty('preview') &&
      !data.hasOwnProperty('product_reactions')
    ) {
      return {
        btnText: 'Review',
        btnDisable: false,
        tags: 'Rating Pending',
        url: data.preview,
        stage: 'CONCEPT_BOARD_SHARED'
      };
    }
    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job == 'REVIEW_CONCEPT' &&
      !data.hasOwnProperty('preview') &&
      data.hasOwnProperty('product_reactions')
    ) {
      return {
        btnText: 'Review',
        btnDisable: false,
        tags: '',
        url:
          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/my_project_assets/designer_in_process.png',
        stage: 'REVIEW_CONCEPT_NOT_SHARED'
      };
    }
    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job == 'REVIEW_CONCEPT' &&
      data.hasOwnProperty('preview') &&
      data.hasOwnProperty('product_reactions') &&
      data.hasOwnProperty('customer_feedback') &&
      data.customer_feedback == 'PENDING'
    ) {
      return {
        btnText: 'Review',
        btnDisable: false,
        tags: 'Pending Review',
        url: data.preview,
        stage: 'REVIEW_CONCEPT_SHARED'
      };
    }
    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job == 'REVIEW_CONCEPT' &&
      data.hasOwnProperty('preview') &&
      data.hasOwnProperty('product_reactions') &&
      data.hasOwnProperty('customer_feedback') &&
      data.customer_feedback == 'REVIEW_REQUESTED'
    ) {
      return {
        btnText: 'Review',
        btnDisable: true,
        tags: '',
        url:
          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/my_project_assets/designer_in_process.png',
        stage: 'REVIEW_CONCEPT_REJECTED'
      };
    }
    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job == 'FINALIZE_CONCEPT' &&
      !data.hasOwnProperty('preview')
    ) {
      return {
        btnText: 'Review',
        btnDisable: true,
        tags: '',
        url:
          'https://pixel-mortar.s3.ap-south-1.amazonaws.com/my_project_assets/designer_in_process.png',
        stage: 'FINALIZE_CONCEPT_NOT_SHARED'
      };
    }
    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job == 'FINALIZE_CONCEPT' &&
      data.hasOwnProperty('preview')
    ) {
      return {
        btnText: 'Review',
        btnDisable: false,
        tags: 'Pending Rating',
        url: data.preview,
        stage: 'FINALIZE_CONCEPT_SHARED'
      };
    }
    if (
      data.questionnaire &&
      data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
      data.questionnaire.MX_B_TENTATIVE_BUDGET &&
      data.job === 'COMPLETED'
    ) {
      return {
        btnText: 'Shop now',
        btnDisable: false,
        tags: 'Completed',
        url: data.preview,
        stage: 'COMPLETED'
      };
    }

    return { btnText: '', btnDisable: true, tags: '', url: '', stage: 'ERROR' };
  };

  const onChange = event => {
    const value = parseInt(event.target.value);
    let room1 = rooms.find(room => room.id === value);
    setSelectedRoom(room1.title);
  };

  const createProject = async () => {
    const projectData = {
      user_id: auth.user._id,
      customer_name: auth.user.name,
      name: projectName,
      questionnaire: {
        MX_A_INITAL_ROOM: selectedRoom
      }
    };
    try {
      const res = await customerApiInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/create`,
        projectData
      );
      let projectId = res.data.project._id;
      reset_answers();
      storeTab('questionnaire');
      storeMx({ projId: projectId });
      page(0);
      page2(0);
      history1.push({
        pathname: `/questionnaire/space/${projectId}`,
        state: { project_id: projectId, parentPage: 'projects' }
      });
      if (res.data) {
        setSnack(true);
        setSnackMessage('Project created  successfully');
      }
    } catch (e) {
      ConsoleHelper('error', e);
    }
  };

  useEffect(() => {
    if (questionnaireData.hasOwnProperty('MxBData')) {
      let data = questionnaireData.MxBData;
      setMxb([...data]);
    }
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      <Header />

      <div
        className={classnames(
          'container d-flex flex-column align-items-center justify-content-center',
          style.container
        )}
        style={{ paddingTop: '100px' }}>
        {projectsLoading ? (
          <div>
            <Loader type="ThreeDots" visible={true} color="#000000" height={500} width={50} />
          </div>
        ) : (
          <>
            {/* reviewProjects */}
            <div className="row d-flex align-items-center justify-content-center w-100">
              {reviewProjects &&
                reviewProjects.map((data, index) => (
                  <div
                    key={index}
                    className={classnames(
                      dimension[0] > 500 &&
                        {
                          /*!data.hasOwnProperty("approval")*/
                        }
                        ? style.renderContainer
                        : dimension[0] > 500 &&
                          data.hasOwnProperty('approval') &&
                          data.approval === 'pending'
                        ? style.renderContainer3
                        : style.renderContainer1,
                      ''
                    )}>
                    {data?.cardUIData?.tags && (
                      <div className="pt-2 d-flex justify-content-start position-absolute">
                        <button className={style.button3}>{data?.cardUIData?.tags}</button>
                      </div>
                    )}

                    <div className={`d-flex justify-content-center ${style.previewImgContainer}`}>
                      <img
                        key={index}
                        style={{ width: '100%', objectFit: 'cover' }}
                        src={data?.cardUIData?.url ? data.cardUIData.url : DefaultPreviewImage}
                        alt=""
                      />
                    </div>

                    <div className={style.headerContainer1}>
                      <div className="row p-2 align-items-center">
                        {typeof data.questionnaire.MX_A_INITAL_ROOM === 'string' ? (
                          <div
                            className="col-6  d-flex align-items-center"
                            style={{ fontSize: '16px', fontWeight: 900 }}>
                            {data.name
                              ? data.name
                              : data.questionnaire.MX_A_INITAL_ROOM
                              ? data.questionnaire.MX_A_INITAL_ROOM
                              : ' '}
                          </div>
                        ) : (
                          <div
                            className="col-6  d-flex align-items-center"
                            style={{ fontSize: '16px', fontWeight: 900 }}>
                            {' '}
                            {data.questionnaire.MX_A_INITAL_ROOM
                              ? data.questionnaire.MX_A_INITAL_ROOM.title
                              : ' '}
                          </div>
                        )}

                        <div
                          className={classnames(
                            'col-6 d-flex justify-content-end align-items-center'
                          )}>
                          <button
                            className={classnames(style.button1)}
                            style={{ margin: '5px 0px' }}
                            onClick={() => {
                              if (
                                data.questionnaire &&
                                data.questionnaire.MX_B_TENTATIVE_BUDGET &&
                                data.job !== 'COMPLETED'
                              ) {
                                history.push({
                                  pathname: `/rooms/${data._id}`,
                                  state: { type: 'getting' }
                                });
                              } else if (
                                data.questionnaire &&
                                data.questionnaire.MX_B_TENTATIVE_BUDGET &&
                                data.job === 'COMPLETED'
                              ) {
                                history.push({
                                  pathname: `/rooms/${data._id}`,
                                  state: { type: 'shop' }
                                });
                              } else if (
                                data.questionnaire &&
                                data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
                                data.questionnaire.hasOwnProperty('PACKAGE') &&
                                !data.questionnaire.MX_B_TENTATIVE_BUDGET
                              ) {
                                let index = mxb.findIndex(mx => mx.projectId === data._id);
                                if (index < 0) {
                                  if (questionnaireData.MxBData) {
                                    storeMxB([
                                      ...questionnaireData.MxBData,
                                      { projectId: data._id }
                                    ]);
                                  } else {
                                    storeMxB([{ projectId: data._id }]);
                                  }
                                }
                                history.push({
                                  pathname: `/room-profile/${data._id}`,
                                  state: {
                                    project_id: data._id,
                                    showHeader: false
                                  }
                                });
                              } else if (
                                data.questionnaire &&
                                data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
                                !data.questionnaire.hasOwnProperty('PACKAGE')
                              ) {
                                history.push({
                                  pathname: `/packages/${data._id}`,
                                  state: {
                                    navbar: true,
                                    project_id: data._id
                                  }
                                });
                              } else if (
                                data.questionnaire &&
                                data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
                                data.questionnaire.hasOwnProperty('PACKAGE') &&
                                data.questionnaire.PACKAGE.title !== 'Express'
                              ) {
                                history.push({
                                  pathname: `/package-payment/${data._id}`,
                                  state: {
                                    navbar: true,
                                    project_id: data._id
                                  }
                                });
                              } else if (
                                data.questionnaire &&
                                data.questionnaire.hasOwnProperty('MX_A_STYLE_RESULT') &&
                                data.questionnaire.hasOwnProperty('PACKAGE') &&
                                data.questionnaire.PACKAGE.title === 'Express'
                              ) {
                                history.push({
                                  pathname: `/packages/${data._id}`,
                                  state: {
                                    navbar: true,
                                    project_id: data._id
                                  }
                                });
                              } else {
                                reset_answers();
                                storeTab('questionnaire');
                                storeMx({ projId: data._id });
                                page(0);
                                page2(0);
                                history1.push({
                                  pathname: `/questionnaire/space/${data._id}`,
                                  state: {
                                    project_id: data._id,
                                    parentPage: 'projects'
                                  }
                                });
                              }
                            }}
                            disabled={data?.cardUIData?.btnDisable}>
                            {data?.cardUIData?.btnText}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="row d-flex justify-content-end p-2 ">
              <button
                className={style.buttonCreate}
                onClick={() => setModal({ ...modal, modal: true, name: 'create' })}>
                Create New Project
              </button>
            </div>
          </>
        )}
      </div>

      <Footer />

      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.name === 'create'}
        onHide={() => setModal({ ...modal, name: '' })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className={style.modalHeader}>
          <h4 className="mt-3 ml-5">Create Your Project</h4>
          <img
            className="mr-5 mt-4"
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={() => setModal({ ...modal, modal: false, name: '' })}
            src={'https://d3izcfql7lesks.cloudfront.net/Icon_ionic-ios-close_qwynjq.png'}
            alt=""
          />
        </div>

        <div className={classnames('row justify-content-center', style.modalRow)}>
          <div className={classnames('col-md-6 col-xs-12', style.createCol)}>
            <h6>Project Name</h6>
            <input
              type="text"
              placeholder="Enter Project name"
              onChange={e => setProjectName(e.target.value)}
            />
          </div>
          <div className={classnames('col-md-6 col-xs-12', style.imageCol)}>
            <h6>Room Name</h6>
            <select value={selectedRoom.id} onChange={onChange}>
              {rooms.map((item, i) => (
                <option value={item.id} key={i}>
                  {item.title}
                </option>
              ))}
            </select>
            <div className={classnames('row justify-content-between', style.buttonContainer)}>
              <button
                className={style.deleteBtn}
                onClick={() => {
                  reset_answers();
                  createProject();
                }}>
                Get Started
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

Project.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    questionnaireData: state.questionnaireData
  };
};

export default connect(mapStateToProps, {
  reset_answers,
  storeMx,
  page,
  page2,
  storeMxB,
  storeTab
})(withRouter(Project));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
