import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthSamplePage from './pages/authsamplepage';
import MyBoards from './pages/Boards/MyBoards';
import BoardView from './pages/Boards/BoardDetail';
import Project from './pages/Projects/Project';
import Cart from './pages/Cart/Cart';
import OrderConfirmation from './pages/Cart/OrderConfirmation';
import Signup from './components/Auth/Signup';
import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import ExploreDetail from './pages/Explore/ExploreDetail';
import Product from './pages/Explore/Product';
import Account from './pages/Account/Account';
import MyOrders from './pages/Orders/MyOrders';
import Rooms from './pages/Rooms/Room';
import RoomProfile from './pages/Rooms/RoomProfile';
import Package from './components/layout/Package';
import Pricing from './pages/Pricing/Pricing';
import Reviews from './pages/Reviews/Reviews';
import Giftcards from './pages/Giftcards/Giftcard';
import AboutUs from './pages/Aboutus/Aboutus';
import Careers from './pages/Careers/Careers';
import PackagePayment from './components/layout/Payment';
import Order from './pages/Orders/Order';
import Wallet from './pages/Orders/Wallet';
import CareerApply from './pages/Careers/CareerApply';
import GiftCheckout from './pages/Giftcards/GiftCheckout';
import Referral from './pages/Referral/Referral';
import ReferralInduction from './pages/Referral/ReferralInduction';
import DesignDairies from './pages/DesignDairies/DesignDairies';
import OrderFailed from './pages/Cart/OrderFailed';
import Errorpage from './pages/DesignDairies/ErrorPage';
import HireADesigner from './pages/HireADesigner/HireADesigner';
class AuthMainbuilder extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <>
        <Switch>
          <Route path={['/questionnaire/:type/:id']} component={AuthSamplePage} />
          <Route path={['/questionnaire/:type']} component={AuthSamplePage} />
          <Route exact path="/boards" component={MyBoards} />
          <Route path="/board-view/:id" component={BoardView} />
          <Route path="/project" component={Project} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/order-confirmation/:id" component={OrderConfirmation} />
          <Route exact path="/order-failed" component={OrderFailed} />
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/reset-password?token=:token" component={ResetPassword} />
          <Route path="/explore-detail/:id" component={ExploreDetail} />
          <Route path="/product/:id" component={Product} />
          <Route path="/product" component={Product} />
          <Route path="/packages/:id" component={Package} />
          <Route path="/packages" component={Package} />
          <Route path="/account" component={Account} />
          <Route path="/orders" component={MyOrders} />
          <Route path="/orders/:id" component={MyOrders} />
          <Route path="/rooms/:id" component={Rooms} />
          <Route path="/room-profile/:id" component={RoomProfile} />
          <Route path="/room-profile" component={RoomProfile} />
          <Route path="/pre-explore-detail/:id" component={ExploreDetail} />
          <Route path="/reviews" component={Reviews} />
          <Route path="/gifts" component={Giftcards} />
          <Route path="/order/:id" component={Order} />
          <Route path="/wallet" component={Wallet} />
          <Route path="/gift-checkout" component={GiftCheckout} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/careers" component={Careers} />
          <Route path="/careers-apply" component={CareerApply} />
          <Route path="/referral" component={Referral} />
          <Route path="/referral-new" component={ReferralInduction} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/design-dairies" component={DesignDairies} />
          <Route path="/hire-designer" component={HireADesigner} />
          <Route path="/package-payment/:id" component={PackagePayment} />
          <Route path="/package-payment/" component={PackagePayment} />
          <Route exact path="*" component={Errorpage} />
        </Switch>
      </>
    );
  }
}

export default AuthMainbuilder;
