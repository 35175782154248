import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/footer/Footer';
import style from './DesignDairies.module.css';

const DesignDairies = () => {
  return (
    <div>
      <Header />
      <div>
        <div
          className="container "
          style={{
            height: 'auto',
            maxWidth: '100%',
            paddingLeft: '0px',
            paddingRight: '0px',
            marginTop: '75px',
            backgroundColor: '#f5f0ec'
          }}>
          <div className={style.designTxt}>
            <h1 style={{ fontFamily: 'QuickSand' }}> Coming soon </h1>
            <p>
              We are beta testing our styling package. If you want be one among the first to get
              your space styled with us, reach out to us on <b>9176976111</b>
            </p>
          </div>
          <div className={style.designBg}>
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern1.png'}
              className={style.patternIm}
            />
            <img
              src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/Group3.png'}
              className={style.imgBgs}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DesignDairies;
