import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import style from './Signup.module.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import firebase from '../../firebase';
import { connect } from 'react-redux';
import { registerUser, loginUser, clearError } from '../../store/actions/authapi';

import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Modal } from 'react-bootstrap';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { sendPasswordResetEmail } from 'supertokens-web-js/recipe/emailpassword';
import ConsoleHelper from '../../utils/ConsoleHelper';

const ForgotPassword = ({ auth, history, registerUser, clearError, location }) => {
  // const query = useQuery()
  const history1 = useHistory();
  const dimension = useMediaQuery();
  const [display, setDisplay] = useState('options');
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const [inputs, setInputs] = useState({});
  // const [phone, setPhone] = useState("");
  // const [login, setLogin] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ modal: false, name: '', id: null });
  // const [otpScreen, setOtpScreen] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/');
    }
    let search = location.search;
    if (location.search.length !== 0) {
      setInputs({
        ...inputs,
        referral: search.slice(search.lastIndexOf('=')).replace('=', '')
      });
    }
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/questionnaire/space');
    }
    if (auth.errors?.type === 'ERROR') {
      setLoading(false);
      setTimeout(() => {
        alert(auth.errors?.message);
      }, 500);
      clearError();
    }
  }, [auth]);

  // useEffect(() => {
  //     setInputs(null)
  //     setLogin(null);
  // },[display, inputs, login])

  const getPhoneNumber = async () => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/findUser`, {
      email: inputs.email
    });
    let user = res.data;
    if (user.phone_number) {
      //  setPhone(user.phone_number);
      handleOTP(user.phone_number);
    } else {
      setSnack(true);
      setSnackMessage('No users found');
    }
  };

  const handleOTP = async phone_number => {
    var recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha', {
      size: 'invisible',
      callback: response => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
        if (display !== 'signup') {
          setModal({ ...modal, modal: true, id: response });
        }
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      }
    });
    var number = `+ 91${phone_number}`;
    firebase
      .auth()
      .signInWithPhoneNumber(number, recaptcha)
      .then(confirmationResult => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setSnack(true);
        setSnackMessage('Otp sent to your mobile number');
        // ...
      })
      .catch(error => {
        // Error; SMS not sent
        // ...
        setModal({ ...modal, modal: false });
        setSnack(true);
        setSnackMessage('Something went wrong');
        alert(error);
      });
  };

  const handleChangeSignup = e => {
    let name = e.target.name;
    let value = e.target.value;
    setInputs({
      ...inputs,
      [name]: value
    });
  };

  const resetPassword = async () => {
    try {
      let response = await sendPasswordResetEmail({
        formFields: [
          {
            id: 'email',
            value: inputs.email
          }
        ]
      });
      //ConsoleHelper(response);
      if (response.status === 'FIELD_ERROR') {
        // one of the input formFields failed validaiton
        response.formFields.forEach(formField => {
          if (formField.id === 'email') {
            // Email validation failed (for example incorrect email syntax).
            window.alert(formField.error);
          }
        });
      } else {
        // reset password email sent.
        window.alert('Please check your email for the password reset link');
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }

    // try {
    //   const res = await axios.post(
    //     `${process.env.REACT_APP_BACKEND_URL}/api/user/resetPasswordLink`,
    //     { email: inputs.email }
    //   );
    //   if (res.data.message === "success") {
    //     setSnack(true);
    //     setSnackMessage("Email sent successfully");
    //     setDisplay("emailSent");
    //   }
    // } catch (err) {
    //   setSnack(true);
    //   setSnackMessage("Something went wrong");
    // }
  };
  const handleSubmitRegister = () => {
    clearError();
    setLoading(true);
    registerUser(inputs, history, location.state);
  };

  const handleVerify = async e => {
    window.confirmationResult
      .confirm(inputs.otp)
      .then(function(result) {
        setSnackMessage('Otp verified successfully');
        setSnack(true);
        setModal({ ...modal, modal: false });
        if (display === 'otp') {
          history1.push({
            pathname: '/reset-password',
            state: inputs.email
          });
        } else return handleSubmitRegister();
      })
      .catch(err => ConsoleHelper(err));
  };
  const handleOTP1 = e => {
    e.preventDefault();
    var recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha1', {
      size: 'invisible',
      callback: response => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
        setModal({ ...modal, modal: true, id: response });
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      }
    });
    var number = `+ 91${inputs.phone_number}`;
    firebase
      .auth()
      .signInWithPhoneNumber(number, recaptcha)
      .then(confirmationResult => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch(error => {
        // Error; SMS not sent
        // ...
        setModal({ ...modal, modal: false });
        alert(error);
      });
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack}
        onClose={() => setSnack(false)}
        message={snackMessage}
        autoHideDuration={2000}
      />
      {loading && (
        <div style={{ position: 'fixed', top: '40%', left: '50%', opacity: 1 }}>
          <Loader type="ThreeDots" visible={true} color="#000000" height={100} width={100} />
        </div>
      )}
      <div className={style.wholeContainer} style={{ opacity: loading ? 0.5 : 1 }}>
        <Header />
        <div className={classnames('container-fluid' /*style.mainContainer*/)}>
          <div className="row mt-0">
            {/* {dimension[0] > 500 ? */}
            <div className={classnames('col-lg-6 ', style.imgContainer)}>
              {/* {dimension[0] > 500
                                ?
                                <img style={{ width: '100%' }} src={'https://d3izcfql7lesks.cloudfront.net/minh-pham-OtXADkUh3-I-unsplash_hndoz8.png'} alt='' />
                                :
                                <img style={{ width: '100%' }} src={'https://d3izcfql7lesks.cloudfront.net/minh-pham-OtXADkUh3-I-unsplash_l6vutx.png'} alt='' />
                            } */}
            </div>
            {/* : null}  */}

            <div className={classnames('col-lg-6', style.container)}>
              <div
                className={classnames(
                  'container pl-1 pr-1 d-flex flex-column align-items-left justify-content-center',
                  dimension[0] > 2000 ? style.innerContainer1 : style.innerContainer
                )}
                style={{ height: dimension[1] - 80 }}>
                {display === 'emailSent' ? (
                  <>
                    <h6>Please check your email for Reset Password Link</h6>
                    <p
                      style={{
                        float: 'right',
                        textDecoration: 'underline',
                        fontWeight: 900
                      }}
                      onClick={() => history1.push('/login')}>
                      {' '}
                      Go Back
                    </p>
                  </>
                ) : display === 'options' ? (
                  <>
                    <h2 className={style.signup_text}>Recover your password</h2>
                    <div>
                      {/* <button
                        className={style.signup_button}
                        onClick={() => {
                          setDisplay("otp");
                        }}
                      >
                        Reset By Otp
                      </button> */}
                      <button
                        className={style.signup_button}
                        onClick={() => {
                          setDisplay('emailLink');
                        }}>
                        Reset by Email
                      </button>
                    </div>
                  </>
                ) : display === 'emailLink' ? (
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      resetPassword();
                    }}>
                    <p>Enter your email to reset your</p>
                    <input
                      required
                      className={style.signup_input}
                      name="email"
                      value={inputs.email || ''}
                      onChange={handleChangeSignup}
                      type="email"
                      placeholder="Enter your Email"
                    />
                    <button className={style.signup_button} type="submit">
                      GET RESET LINK
                    </button>
                    <button
                      className={style.login_button}
                      type="button"
                      onClick={() => history1.push('/login')}>
                      Login using Password
                    </button>
                  </form>
                ) : display === 'otp' ? (
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      getPhoneNumber();
                      // if (phone) {

                      // }
                    }}>
                    <h2 className={style.signup_text}>Recover your password</h2>
                    <p>Enter your email to reset your</p>
                    <input
                      required
                      className={style.signup_input}
                      name="email"
                      value={inputs.email || ''}
                      onChange={handleChangeSignup}
                      type="email"
                      placeholder="Enter your Email"
                    />
                    {display === 'otp' && <div id="recaptcha"></div>}
                    <button className={style.signup_button} type="submit">
                      GET OTP
                    </button>
                    <button
                      className={style.login_button}
                      type="button"
                      onClick={() => history1.push('/login')}>
                      Login using Password
                    </button>
                  </form>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{ backgroundColor: 'transparent' }}
        show={modal.modal}
        // onHide={() => setModal({ ...modal, modal: false })}
        backdrop={true}
        backdropClassName="backdrop"
        centered
        className={'modal'}
        keyboard={false}>
        <div className="container pt-5 pb-5 d-flex flex-column justify-content-center align-items-center">
          <h3>OTP Verification</h3>
          <p
            style={{
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '20px'
            }}>
            One Time Password has been sent to your mobile number{' '}
            <span style={{ fontWeight: 800 }}>
              {inputs.phone_number && inputs.phone_number.toString().slice(0, 6)}
              ****
            </span>
            . <br></br> Kindly check and enter below.
          </p>
          <input
            type="number"
            placeholder="Enter OTP"
            className={style.otpInput}
            onChange={e => setInputs({ ...inputs, otp: e.target.value })}
          />
          <div className="row mt-5 d-flex flex-row justify-content-around">
            <button
              style={{ cursor: 'pointer' }}
              className={style.verifyButton}
              onClick={handleVerify}>
              {' '}
              VERIFY{' '}
            </button>

            <div id="recaptcha1"></div>
            <p className="mt-4 ml-4" onClick={handleOTP1} style={{ cursor: 'pointer' }}>
              Resend OTP
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  registerUser,
  loginUser,
  clearError
})(withRouter(ForgotPassword));

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
